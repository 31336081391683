import React from "react";
import { observer } from "mobx-react";

import plusIcon from "@difftone/assets/new-survey-button-plus.svg";
import {
    upsertSurveyToMapAndApiWithoutDebounce,
    getNewEmptySurveyFromTemplate,
    setLocation,
    showPermissionsAlert,
} from "@difftone/actions";
import { DifftoneButton } from "@difftone/shared-components";
import { sendEvent } from "@difftone/procedures";
import { getUserPermissionsType } from "@difftone/reducers";
import { SurveyClasses } from "@difftone/types";

import {
    new_survey_button,
    new_survey_button_icon,
} from "./new-survey-button.module.css";

export const NewSurveyButton = observer(() => {
    const userType = getUserPermissionsType();

    const newSurveyHandler = () => {
        if (userType === "DEFAULT") {
            showPermissionsAlert();
            return;
        }

        const newDraftSurvey: SurveyClasses = getNewEmptySurveyFromTemplate();
        upsertSurveyToMapAndApiWithoutDebounce(newDraftSurvey);
        setLocation(`/survey-wizard/${newDraftSurvey.uuid}`);

        sendEvent("CREATE_NEW_SURVEY_BUTTON_CLICK", newDraftSurvey.uuid);
    };

    return (
        <DifftoneButton
            icon={plusIcon}
            buttonText="New Survey"
            onClick={newSurveyHandler}
            classNameOverride={new_survey_button}
            iconClassName={new_survey_button_icon}
        />
    );
});
