import React from "react";

import {
    difftone_contact_info,
    title,
} from "./difftone-contact-info.module.css";

export type DifftoneContactInfoProps = { open: () => void };

export const DifftoneContactInfo = (props: DifftoneContactInfoProps) => {
    const { open } = props;
    const onClickHandler = () => {
        open();
    };
    return (
        <div onClick={onClickHandler} className={difftone_contact_info}>
            <div className={title}>Contact Us</div>
        </div>
    );
};
