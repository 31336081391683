import type {
    InvalidValuesUUIDsInArray,
    Tag,
    TagsNameUniquenessValidationType,
    ValidationResult,
} from "@difftone/types";

export const validateTagNameUniqueness = (
    tags: Tag[]
): ValidationResult<TagsNameUniquenessValidationType>[] => {
    const duplicatedNamesMap: InvalidValuesUUIDsInArray = {};

    tags.forEach((item) => {
        if (!duplicatedNamesMap[item.name]) {
            duplicatedNamesMap[item.name] = [item.uuid];
        } else {
            duplicatedNamesMap[item.name].push(item.uuid);
        }
    });

    for (let element in duplicatedNamesMap) {
        if (duplicatedNamesMap[element].length === 1) {
            delete duplicatedNamesMap[element];
        }
    }

    return Object.keys(duplicatedNamesMap).length === 0
        ? [{
            is_valid: true,
            validation_error_code:
                "TAG_NAME_DUPLICATION_VALIDATION",
            validation_error_type: { duplication_indexes: duplicatedNamesMap },
        }]
        : [{
            is_valid: false,
            validation_error_code:
                "TAG_NAME_DUPLICATION_VALIDATION",
            validation_error_type: { duplication_indexes: duplicatedNamesMap },
        }];
};
