import type {
    InvalidValuesUUIDsInArray,
    Category,
    CategoriesNameUniquenessValidationType,
    ValidationResult,
} from "@difftone/types";

export const validateCategoryNameUniqueness = (
    categories: Category[]
): ValidationResult<CategoriesNameUniquenessValidationType>[] => {
    const duplicatedNamesMap: InvalidValuesUUIDsInArray = {};

    categories.forEach((item) => {
        if (!duplicatedNamesMap[item.display_name]) {
            duplicatedNamesMap[item.display_name] = [item.uuid];
        } else {
            duplicatedNamesMap[item.display_name].push(item.uuid);
        }
    });

    for (let element in duplicatedNamesMap) {
        if (duplicatedNamesMap[element].length === 1) {
            delete duplicatedNamesMap[element];
        }
    }

    return Object.keys(duplicatedNamesMap).length === 0
        ? [{
            is_valid: true,
            validation_error_code:
                "CATEGORIES_NAME_DUPLICATION_VALIDATION",
            validation_error_type: { duplication_indexes: duplicatedNamesMap },
        }]
        : [{
            is_valid: false,
            validation_error_code:
                "CATEGORIES_NAME_DUPLICATION_VALIDATION",
            validation_error_type: { duplication_indexes: duplicatedNamesMap },
        }];
};
