import {
    IAuthProviderConnectionListService,
    IConnectionListFactory,
} from "../contracts";
import { GoogleConnectionListService } from "./google-connection-list-service";
import { MicrosoftConnectionListService } from "./microsoft-connection-list";

export class ConnectionListFactory implements IConnectionListFactory {
    public getConnectionListProviderByAuthProviderName(
        authProviderName: string
    ): IAuthProviderConnectionListService {
        switch (authProviderName.toLocaleUpperCase()) {
            case "GOOGLE":
                return new GoogleConnectionListService();
            case "MICROSOFT":
                return new MicrosoftConnectionListService();
            default:
                throw new Error(
                    `${authProviderName} provider is not supported!`
                );
        }
    }
}
