import {
    Answer,
    Question,
    QuestionType,
    RatingAnswer,
    SingleChoiceAnswer,
    SurveyResponse,
    ResultsFilter,
    BaseSurvey,
    EmailAddress,
    QuestionToAnswerPopularityMap,
    AppearanceMap,
} from "@difftone/types";
import {
    checkIfEmailIsGroupByNames,
    getEmailGroupMembersFromGoogle,
    getEmailGroupMembersFromMicrosoft,
    getSurveysResponsesFromApiBySurveyUUID,
} from "@difftone/services";

import { resultsDataStore } from "./results-data-store";
import { localstorageUtils } from "@difftone/procedures";
import { showDifftoneAlert } from "@difftone/actions";

export const updateSurveyResponses = async (selectedSurvey: BaseSurvey) => {
    if (!selectedSurvey) {
        resultsDataStore.surveyResponses = [];
        return;
    }

    const selectedSurveyUUID = selectedSurvey.uuid;
    const surveyResponses = await getSurveysResponsesFromApiBySurveyUUID(
        selectedSurveyUUID
    );

    resultsDataStore.surveyResponses = surveyResponses;
    return;
};

export const getQuestionToAnswerPopularityMap = (
    selectedSurvey: BaseSurvey,
    attributeFilters?: ResultsFilter[]
): QuestionToAnswerPopularityMap => {
    const popularityAnswerMap: any = {};

    selectedSurvey.questions.forEach((question) => {
        const appearanceMap = getAppearanceMapForQuestion(
            question,
            attributeFilters
        );
        popularityAnswerMap[question.uuid] = appearanceMap;
    });

    return popularityAnswerMap;
};

//TODO : correct types
const filterResponsesByAttribute = (
    response: SurveyResponse,
    attributeFilter: ResultsFilter
) => {
    const answer = response.attribute_answers.find(
        (answer) => answer.question_uuid === attributeFilter.filterKey.uuid
    );

    if (!answer) {
        return false;
    }

    switch (attributeFilter.filterKey.question_type_name) {
        case "SINGLE_CHOICE":
            return attributeFilter.filterValues.includes(
                (answer as Answer<SingleChoiceAnswer>).content.choice
            );

        default:
            return false;
    }
};

const filterResponsesByAttributeFilters = (
    response: SurveyResponse,
    attributeFilters: ResultsFilter[]
) => {
    return attributeFilters.every((attributeFilter: ResultsFilter) => {
        return filterResponsesByAttribute(response, attributeFilter);
    });
};

export const getAppearanceMapForQuestion = (
    question: Question<QuestionType>,
    attributeFilters?: ResultsFilter[]
): AppearanceMap | null => {
    const { surveyResponses } = resultsDataStore;

    if (!surveyResponses.length) {
        return null;
    }

    const _surveyResponses = !attributeFilters
        ? surveyResponses
        : surveyResponses.filter((response) =>
              filterResponsesByAttributeFilters(response, attributeFilters)
          );

    const answersMap = _surveyResponses.map((response) => response.answers);
    const questionUUID = question.uuid;

    const answersArray = answersMap.map((answersArray) => {
        return answersArray.find(
            (answer) => answer.question_uuid === questionUUID
        );
    });

    //TODO add filter by atribute

    switch (question.question_type_name) {
        case "DATE":
            return null;
        case "LONG_TEXT":
            return null;
        case "MULTIPLE_CHOICE":
            return null;
        case "RATING":
            // @ts-ignore
            return _appearanceCounterForSingleRatingAnswers(answersArray);
        case "SHORT_TEXT":
            return null;
        case "SINGLE_CHOICE":
            // @ts-ignore
            return _appearanceCounterForSingleChoiceAnswers(answersArray);
        case "TIME":
            return null;
        default:
            return {};
    }
};

const _appearanceCounterForSingleRatingAnswers = (
    answersArray: Answer<RatingAnswer>[]
): AppearanceMap => {
    const resultsMap: AppearanceMap = {};
    answersArray.forEach((answer) => {
        resultsMap[answer.content.value] = answersArray.filter(
            (singleChoiceAnswer) =>
                singleChoiceAnswer.content.value === answer.content.value
        ).length;
    });
    return resultsMap;
};

const _appearanceCounterForSingleChoiceAnswers = (
    answersArray: Answer<SingleChoiceAnswer>[]
): AppearanceMap => {
    const resultsMap: any = {};
    answersArray.forEach((answer) => {
        resultsMap[answer.content.choice] = answersArray.filter(
            (singleChoiceAnswer) =>
                singleChoiceAnswer.content.choice === answer.content.choice
        ).length;
    });
    return resultsMap;
};

export const checkIfEmailIsGroup = async (email: EmailAddress) => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    if (currentAuthData.issuer !== "GOOGLE") return false;

    const emailIsGroup = await checkIfEmailIsGroupByNames(email);
    if (emailIsGroup) {
        showDifftoneAlert(
            `Group emails are not currently supported`,
            "FAILURE"
        );
    }
    return emailIsGroup;
};
