import React from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import { inboxStore, publicProfileStore, surveyStore } from "@difftone/stores";
import {
    inboxSpinnerOff,
    inboxSpinnerOn,
    deleteAllAlertsOnDeleteSurvey,
    removeDeletedSurveysFromStore,
    showDifftoneAlert,
    getUniquePublicProfilesUUIDs,
    upsertSurveyToMapAndApiWithDebounce,
    cancelEmail,
} from "@difftone/actions";
import {
    getLastDateToFillDateEvent,
    getPublishedDateEvent,
    getStatus,
} from "@difftone/reducers";
import {
    Spinner,
    SpinnerOrTimeoutMessage,
    TableRowCell,
    TableRow,
    SurveyStatus,
    HumanizeEmail,
    ETA,
    ProgressBar,
    TableDate,
    ShowDifftoneTooltip,
    ActionIconItem,
    NameWithTooltip,
} from "@difftone/shared-components";
import { filteredSurveys } from "@difftone/reducers";
import { BaseSurvey, SimpleSurvey, SurveyClasses } from "@difftone/types";
import { constantAlertVariants } from "@difftone/constants";

import unarchiveIcon from "@difftone/assets/unarchive.svg";
import deleteIcon from "@difftone/assets/delete-survey.svg";

import {
    inbox_surveys_table,
    table_content_wrapper,
    overflow_padding_div,
    progress_class,
    time_left_class,
    last_date_class,
    issued_class,
    initiator_class,
    status_class,
    survey_name_class,
    actions_menu,
    ended_survey_text_color,
    progress_bar,
} from "./inbox-archived-surveys-table.module.css";

import { TableHeader } from "./components";

export const InboxArchivedSurveysTable = observer(() => {
    const { surveysTableSpinnerActive } = inboxStore;
    const publicProfileStartInitialization =
        publicProfileStore.startInitialization;

    if (!surveyStore.storeInitialize) {
        surveyStore.init();
        inboxSpinnerOn();
        return <Spinner />;
    }

    if (!surveyStore.storeArchivedInitialize) {
        surveyStore.loadArchivedSurveys();
        inboxSpinnerOn();
        return <Spinner />;
    }

    if (surveyStore.storeInitialize) {
        if (!publicProfileStartInitialization) {
            const uniquePublicProfiles = getUniquePublicProfilesUUIDs();

            publicProfileStore.init(uniquePublicProfiles);
        }
    }

    if (
        surveyStore.storeInitialize &&
        surveyStore.storeArchivedInitialize &&
        publicProfileStartInitialization
    ) {
        inboxSpinnerOff();
    }

    const sortedAndFilteredSurveys = filteredSurveys();

    const onDelete = async (survey: SurveyClasses) => {
        survey.deleted = true;
        upsertSurveyToMapAndApiWithDebounce(survey);

        if (survey.survey_class !== "ONGOING") {
            deleteAllAlertsOnDeleteSurvey(survey);
            survey.survey_events.forEach((surveyEvent) => {
                surveyEvent.event_emails?.forEach((emailUUID) => {
                    cancelEmail(emailUUID);
                });
            });
        }

        removeDeletedSurveysFromStore();
        showDifftoneAlert(
            `"${survey.survey_name}" ${constantAlertVariants.survey_deleted.text}`,
            constantAlertVariants.survey_deleted.variant
        );
        return;
    };

    const onUnarchive = async (survey: SurveyClasses) => {
        survey.archived = false;
        upsertSurveyToMapAndApiWithDebounce(survey);
        showDifftoneAlert(
            `"${survey.survey_name}" ${constantAlertVariants.survey_unarchived.text}`,
            constantAlertVariants.survey_unarchived.variant
        );
        return;
    };

    const onUnconfirmedDelete = (survey: SurveyClasses) => {
        const surveyStatus = getStatus(survey, "INITIATOR");
        if (surveyStatus === "ACTIVE") {
            showDifftoneAlert(
                constantAlertVariants.survey_warning_delete.text,
                constantAlertVariants.survey_warning_delete.variant,
                () => onDelete(survey),
                () => {},
                survey.survey_name
            );
            return;
        }
        showDifftoneAlert(
            constantAlertVariants.survey_delete.text,
            constantAlertVariants.survey_delete.variant,
            () => onDelete(survey),
            () => {},
            survey.survey_name
        );
    };

    const initiatorActions = {
        unarchive: onUnarchive,
        delete: onUnconfirmedDelete,
        noAction: () => {},
    };

    return (
        <div className={inbox_surveys_table}>
            <TableHeader />
            <div className={table_content_wrapper}>
                {surveyStore.archivedSurveysList.length > 0 ? (
                    sortedAndFilteredSurveys.map((displaySurvey) => {
                        const _survey = displaySurvey.survey as SurveyClasses;

                        const surveyStatus = getStatus(
                            displaySurvey.survey,
                            displaySurvey.role
                        );

                        const initiatorPublicProfile =
                            publicProfileStore.getPublicProfileByUuid(
                                _survey.initiator
                            );

                        const initiatorEmailTemp = initiatorPublicProfile
                            ? initiatorPublicProfile.email
                            : "unknown";

                        const getTableLastDateComponent = (
                            survey: BaseSurvey
                        ) => {
                            switch (survey.survey_class) {
                                case "ONGOING":
                                    return <TableDate miliseconds={Infinity} />;
                                case "SIMPLE":
                                default:
                                    const simpleSurvey = survey as SimpleSurvey;
                                    const lastDateToFillDateEvent =
                                        getLastDateToFillDateEvent(
                                            simpleSurvey
                                        );

                                    return (
                                        <TableDate
                                            surveyEvent={
                                                lastDateToFillDateEvent
                                            }
                                        />
                                    );
                            }
                        };

                        const getTablePublishedDateComponent = (
                            survey: BaseSurvey
                        ) => {
                            switch (survey.survey_class) {
                                case "ONGOING":
                                    return (
                                        <TableDate
                                            miliseconds={survey.created_at}
                                        />
                                    );
                                case "SIMPLE":
                                default:
                                    const simpleSurvey = survey as SimpleSurvey;
                                    const publishedDateEvent =
                                        getPublishedDateEvent(simpleSurvey);

                                    return (
                                        <TableDate
                                            surveyEvent={publishedDateEvent}
                                        />
                                    );
                            }
                        };

                        return (
                            <TableRow
                                key={displaySurvey.display_surveyUUID}
                                actionsClassName={actions_menu}
                                actions={
                                    displaySurvey.role !== "RESPONDER" ? (
                                        <>
                                            {displaySurvey.role ===
                                                "INITIATOR" && (
                                                <>
                                                    <ShowDifftoneTooltip
                                                        tooltipPosition="bottom"
                                                        tip="Unarchive"
                                                    >
                                                        <ActionIconItem
                                                            action={() =>
                                                                initiatorActions.unarchive(
                                                                    _survey
                                                                )
                                                            }
                                                            disabled={false}
                                                            icon={unarchiveIcon}
                                                        />
                                                    </ShowDifftoneTooltip>
                                                    <ShowDifftoneTooltip
                                                        tooltipPosition="bottom"
                                                        tip="Delete"
                                                    >
                                                        <ActionIconItem
                                                            action={() =>
                                                                initiatorActions.delete(
                                                                    _survey
                                                                )
                                                            }
                                                            disabled={false}
                                                            icon={deleteIcon}
                                                        />
                                                    </ShowDifftoneTooltip>
                                                </>
                                            )}
                                        </>
                                    ) : null
                                }
                                body={
                                    <>
                                        <TableRowCell
                                            className={clsx(
                                                survey_name_class,
                                                surveyStatus === "ENDED" &&
                                                    ended_survey_text_color,
                                                "user_select"
                                            )}
                                        >
                                            <NameWithTooltip
                                                text={_survey.survey_name}
                                            />
                                        </TableRowCell>
                                        <TableRowCell className={status_class}>
                                            <SurveyStatus
                                                surveyStatus={surveyStatus}
                                            />
                                        </TableRowCell>
                                        <TableRowCell
                                            className={clsx(
                                                initiator_class,
                                                surveyStatus === "ENDED" &&
                                                    ended_survey_text_color
                                            )}
                                        >
                                            {initiatorPublicProfile ? (
                                                <HumanizeEmail
                                                    humanizeEmail={
                                                        initiatorEmailTemp
                                                    }
                                                />
                                            ) : (
                                                <Spinner size="X-SMALL" />
                                            )}
                                        </TableRowCell>
                                        <TableRowCell
                                            className={
                                                surveyStatus === "ENDED"
                                                    ? [
                                                          issued_class,
                                                          ended_survey_text_color,
                                                      ].join(" ")
                                                    : issued_class
                                            }
                                        >
                                            {getTablePublishedDateComponent(
                                                displaySurvey.survey
                                            )}
                                        </TableRowCell>
                                        <TableRowCell
                                            className={
                                                surveyStatus === "ENDED"
                                                    ? [
                                                          last_date_class,
                                                          ended_survey_text_color,
                                                      ].join(" ")
                                                    : last_date_class
                                            }
                                        >
                                            {getTableLastDateComponent(
                                                displaySurvey.survey
                                            )}
                                        </TableRowCell>
                                        <TableRowCell
                                            className={time_left_class}
                                        >
                                            {displaySurvey.survey
                                                .survey_class === "SIMPLE" ? (
                                                <ETA
                                                    survey={
                                                        displaySurvey.survey as SimpleSurvey
                                                    }
                                                    surveyStatus={surveyStatus}
                                                />
                                            ) : null}
                                        </TableRowCell>
                                        <TableRowCell
                                            className={progress_class}
                                        >
                                            {displaySurvey.role ===
                                                "INITIATOR" && (
                                                <div className={progress_bar}>
                                                    <ProgressBar
                                                        survey={
                                                            displaySurvey.survey
                                                        }
                                                        surveyStatus={
                                                            surveyStatus
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </TableRowCell>
                                    </>
                                }
                            />
                        );
                    })
                ) : (
                    <SpinnerOrTimeoutMessage
                        spinnerSize="LARGE"
                        spinnerFlag={surveysTableSpinnerActive}
                        message="No Surveys To Show"
                    />
                )}
                <div className={overflow_padding_div} />
            </div>
        </div>
    );
});
