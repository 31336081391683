import axios from "axios";
import { errorHandlerProxy } from "@difftone/procedures";
import {
    getWebClientBaseUrl,
    getGoogleClientId,
    getGoogleClientSecret,
} from "@difftone/constants";

const SERVER_BASE_URL = `https://oauth2.googleapis.com/token`;

const REDIRECT_URI = getWebClientBaseUrl();

export const getGoogleOfflineAuthData = async (loginCode: string) => {
    try {
        const options = {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        };
        const response = await axios.post(
            `${SERVER_BASE_URL}?code=${loginCode}&client_id=${getGoogleClientId()}&client_secret=${getGoogleClientSecret()}&redirect_uri=${REDIRECT_URI}&grant_type=authorization_code`,
            {},
            options
        );

        if (response.data.error) {
            errorHandlerProxy({
                error: response.data.error,
                code: response.data.statusCode,
            });
        }
        const resource = response.data;
        return resource;
    } catch (error) {
        console.error((error as Error).message);
        throw error;
    }
};
