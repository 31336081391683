import React from "react";
import { observer } from "mobx-react";

import { isLoggedIn, setLocation, showDifftoneAlert } from "@difftone/actions";
import { loginStore, navigationStore, userStore } from "@difftone/stores";
import {
    ConatcInfoDialog,
    UserNotExistDialog,
} from "@difftone/shared-components";
import { constantAlertVariants, INBOX_URL } from "@difftone/constants";
import {
    HomePageFooter,
    DifftoneContactInfo,
    DifftoneLegal,
    DifftoneAbout,
    LoginDialog,
    AboutUsDialog,
} from "./components";
import {
    home_page,
    home_page_title,
    secondary_header,
    home_page_message,
    login_button,
} from "./home.module.css";

export type HomeProps = {};

export const Home = observer(() => {
    const { currentPage, currentSearch } = navigationStore;
    const { upsertUserToApi } = userStore;
    const { authDataInititalized, isOrganizationUser } = loginStore;

    const urlRefIndex = currentSearch.indexOf("?ref=");
    const refPartOfUrl = currentSearch.slice(urlRefIndex + "?ref=".length);

    if (isLoggedIn() && authDataInititalized) {
        upsertUserToApi();

        if (currentSearch.includes("?ref=")) {
            setLocation(refPartOfUrl);
        } else if (!currentPage.includes("/home")) {
            setLocation(currentPage + (currentSearch || ""));
        } else {
            setLocation(INBOX_URL);
        }
    }

    const openLoginDialog = () => {
        setLocation(
            `/home/login${urlRefIndex >= 0 ? `?ref=${refPartOfUrl}` : ""}`
        );
    };

    const openShowAboutUs = () => {
        setLocation(
            `/home/about-us${urlRefIndex >= 0 ? `?ref=${refPartOfUrl}` : ""}`
        );
    };

    const openContactInfo = () => {
        setLocation(
            `/home/contact-info${
                urlRefIndex >= 0 ? `?ref=${refPartOfUrl}` : ""
            }`
        );
    };

    const closeDialog = () => {
        setLocation(`/home${urlRefIndex >= 0 ? `?ref=${refPartOfUrl}` : ""}`);
    };

    return (
        <>
            <div className={home_page}>
                <div onClick={openLoginDialog} className={login_button}>
                    Log In
                </div>
                <DifftoneContactInfo open={openContactInfo} />
                <DifftoneLegal />
                <DifftoneAbout open={openShowAboutUs} />
                <div className={home_page_title}>difftone</div>
                <div className={secondary_header}>The easy way to ask</div>
                <div className={home_page_message}>
                    we offer the easiest way to ask, respond, analyze and track
                    all forms of surveys
                </div>
                <HomePageFooter />
            </div>
            {currentPage.startsWith("/home/login") ? (
                <LoginDialog closeDialog={closeDialog} />
            ) : null}
            {currentPage.startsWith("/home/about-us") ? (
                <AboutUsDialog close={closeDialog} />
            ) : null}
            {currentPage.startsWith("/home/contact-info") ? (
                <ConatcInfoDialog close={closeDialog} />
            ) : null}
        </>
    );
});
