import React from "react";
import { observer } from "mobx-react";

import { Spinner } from "@difftone/shared-components";
import { inboxStore } from "@difftone/stores";

import { spinner_or_message } from "./spinner-or-timeout-message.module.css";

export const SpinnerOrTimeoutMessage = observer(() => {
    const { surveysTableSpinnerActive } = inboxStore;

    return (
        <div className={spinner_or_message}>
            {surveysTableSpinnerActive ? (
                <Spinner />
            ) : (
                <span>No surveys to show</span>
            )}
        </div>
    );
});
