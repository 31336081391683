import React from "react";
import { observer } from "mobx-react";
import {
    BaseSurvey,
    DisplaySurvey,
    OngoingSurvey,
    SimpleSurvey,
} from "@difftone/types";
import { UiStatus } from "@difftone/frontend-common";
import { getStatus } from "@difftone/reducers";
import {
    ActionIconItem,
    ShowDifftoneTooltip,
} from "@difftone/shared-components";

import ongoingPlayIcon from "@difftone/assets/activate-ongoing-survey-grey-icon.svg";
import endOngoingSurveyIcon from "@difftone/assets/end-ongoing-survey-icon.svg";
import sendGreyOngoingPlainIcon from "@difftone/assets/send-grey-lightning-air-plain-icon.svg";
import sendIcon from "@difftone/assets/send-survey.svg";

export type DisplayActionsBySurveyClassProps = {
    displaySurvey: DisplaySurvey;
    initiatorActions: {
        send: (survey: SimpleSurvey) => void;
        sendOngoing: (survey: OngoingSurvey) => void;
        activateOngoing: (survey: OngoingSurvey) => void;
        endOngoingSurvey: (survey: OngoingSurvey) => void;
    };
};

export const DisplayActionsBySurveyClass = observer(
    (props: DisplayActionsBySurveyClassProps) => {
        const { displaySurvey, initiatorActions } = props;

        const surveyStatus: UiStatus = getStatus(
            displaySurvey.survey,
            displaySurvey.role
        );

        const onGoingActions = (onGoingSurvey: OngoingSurvey) => {
            return (
                <>
                    {surveyStatus !== "DRAFT" ? (
                        <>
                            <ShowDifftoneTooltip
                                tooltipPosition="bottom"
                                tip="Click-to-send"
                            >
                                <ActionIconItem
                                    action={() =>
                                        initiatorActions.sendOngoing(
                                            onGoingSurvey
                                        )
                                    }
                                    disabled={surveyStatus !== "ACTIVE"}
                                    icon={sendGreyOngoingPlainIcon}
                                />
                            </ShowDifftoneTooltip>
                            {surveyStatus === "READY" ? (
                                <ShowDifftoneTooltip
                                    tooltipPosition="bottom"
                                    tip="Activate"
                                >
                                    <ActionIconItem
                                        action={() =>
                                            initiatorActions.activateOngoing(
                                                onGoingSurvey
                                            )
                                        }
                                        disabled={surveyStatus !== "READY"}
                                        icon={ongoingPlayIcon}
                                    />
                                </ShowDifftoneTooltip>
                            ) : (
                                <ShowDifftoneTooltip
                                    tooltipPosition="bottom"
                                    tip="End now"
                                >
                                    <ActionIconItem
                                        action={() =>
                                            initiatorActions.endOngoingSurvey(
                                                onGoingSurvey
                                            )
                                        }
                                        disabled={surveyStatus !== "ACTIVE"}
                                        icon={endOngoingSurveyIcon}
                                    />
                                </ShowDifftoneTooltip>
                            )}
                        </>
                    ) : null}
                </>
            );
        };

        const simpleSurveyActions = (simpleSurvey: SimpleSurvey) => {
            return (
                <>
                    <ShowDifftoneTooltip
                        tooltipPosition="bottom"
                        tip="Send now"
                    >
                        <ActionIconItem
                            action={() => initiatorActions.send(simpleSurvey)}
                            disabled={surveyStatus !== "SAVED" ? true : false}
                            icon={sendIcon}
                        />
                    </ShowDifftoneTooltip>
                </>
            );
        };

        switch (displaySurvey.survey.survey_class) {
            case "ONGOING":
                const onGoingSurvey = displaySurvey.survey as OngoingSurvey;
                return onGoingActions(onGoingSurvey);
            case "SIMPLE":
            default:
                const simpleSurvey = displaySurvey.survey as SimpleSurvey;
                return simpleSurveyActions(simpleSurvey);
        }
    }
);
