import React from "react";
import md5 from "md5";
import { OpenQuestionAnswers, Question, QuestionType } from "@difftone/types";

import { TextAnswerItem } from "./components";
import {
    content_wrapper,
    all_items_wrapper,
} from "./answers-content.module.css";

export type AnswersContentProps = {
    openQuestionAnswers: OpenQuestionAnswers;
    question: Question<QuestionType>;
    selectedAnswers: string[];
    setSelectedAnswers: (answers: string[]) => void;
};

export const AnswersContent = (props: AnswersContentProps) => {
    const {
        openQuestionAnswers,
        selectedAnswers,
        setSelectedAnswers,
        question,
    } = props;

    return (
        <div className={all_items_wrapper}>
            <div className={content_wrapper}>
                {openQuestionAnswers.map((displayAnswer, index) => {
                    const stateKey = md5(`${displayAnswer}-${index}`);

                    return (
                        <TextAnswerItem
                            question={question}
                            setSelectedAnswers={setSelectedAnswers}
                            key={displayAnswer + question.uuid}
                            answer={displayAnswer}
                            stateKey={stateKey}
                            selectedAnswers={selectedAnswers}
                        />
                    );
                })}
            </div>
        </div>
    );
};
