import React from "react";

import { buttons_seperator } from "./buttons-seperator.module.css";

export const ButtonsSeperator = () => {
    return (
        <div className={buttons_seperator}>
            <div></div>
            <div>or</div>
            <div></div>
        </div>
    );
};
