import React from "react";
import { observer } from "mobx-react";
import { surveyStore } from "@difftone/stores";

import { AlterSurveyStartDate, Versions } from "./components";

import {
    autoFillSurvey,
    deleteAllMocksFromDB,
    deleteAllMocksByNamespaceFromDB,
} from "../../actions";

import { survey_auto_fill } from "./survey-auto-fill.module.css";
import { getUserEmail } from "@difftone/procedures";

export type SurveyAutoFillProps = {};

let errorCounter: number = 3;

export const SurveyAutoFill = observer(() => {
    const { surveysList } = surveyStore;

    const onClick = async () => {
        errorCounter = 3;
        await handleAutoFill();
    };

    const handleError = async (error: any) => {
        console.error(`handle error #${errorCounter}`);
        errorCounter--;
        const namespaceForDeletion = JSON.parse(error).namespace_for_deletion;
        if (namespaceForDeletion) {
            await deleteAllMocksByNamespaceFromDB(namespaceForDeletion);
        }
        if (errorCounter === 0) {
            window.alert(error);
            return;
        }
        await handleAutoFill();
    };

    const handleAutoFill = async () => {
        // @ts-ignore
        const numOfUsers = document.querySelector("#num-of-responders")?.value;
        // @ts-ignore
        const surveyUuid = document.querySelector("#survey-uuid")?.value;

        let results;
        try {
            if (!getUserEmail()) {
                return;
            }
            results = await autoFillSurvey(
                numOfUsers,
                getUserEmail(),
                surveyUuid
            );
        } catch (error) {
            await handleError(
                (error as any).response.data
                    ? (error as any).response.data.error
                    : (error as Error).message
            );
            return;
        }

        if (results?.data?.error) {
            await handleError(results?.data?.error);
            return;
        }

        window.confirm(`
      Survey Fulfill Successfully!
      Number of users: ${numOfUsers}
      Survey name: ${results.data.results.survey_name}
      Deletion namespace: ${results.data.results.namespace_for_deletion}
    `);
    };

    const onDeleteAllMocksFromDB = async () => {
        const results = await deleteAllMocksFromDB();

        if (results.data.error) {
            window.alert(`
      Error! Something went wrong!            
    `);
        } else {
            window.alert(` Notice:
      ${results.data.message}
    `);
        }
    };

    const onDeleteMocksByNamespace = async () => {
        const localNamespaceToDelete = document.querySelector(
            "#local-namespace-name" // @ts-ignore
        )?.value;
        const results = await deleteAllMocksByNamespaceFromDB(
            localNamespaceToDelete
        );

        if (results.data.error) {
            window.alert(`
      Error! Something went wrong!            
    `);
        } else {
            window.alert(` Notice:
      ${results.data.message}
    `);
        }
    };

    return (
        <div className={survey_auto_fill}>
            <h1>Survey Auto Fill</h1>
            <br />
            <Versions />
            <br />
            <br />
            <p>Enter Number Of Responders:</p>
            <input id="num-of-responders" type="number" />
            <p>Choose Survey From The List:</p>
            <select id="survey-uuid">
                {surveysList.map((survey) => (
                    <option key={survey.uuid} value={survey.uuid}>
                        {survey.survey_name}
                    </option>
                ))}
            </select>
            <br />
            <br />
            <button onClick={onClick} type="submit" value="Submit">
                Submit
            </button>
            <br />
            <br />
            <hr />
            <h3>Development tools:</h3>
            <br />
            <button
                onClick={onDeleteAllMocksFromDB}
                type="submit"
                value="Submit"
            >
                Delete All Mocks From DB
            </button>
            <br />
            <br />
            <span>Delete mocks by local namespace:</span>&emsp;
            <input id="local-namespace-name" type="string" />
            &emsp;
            <button
                onClick={onDeleteMocksByNamespace}
                type="submit"
                value="Submit"
            >
                Delete All Mocks by namespace
            </button>
            <hr />
            <h3>Alter survey start date:</h3>
            <br />
            <AlterSurveyStartDate />
        </div>
    );
});
