import React from "react";
import { TimelineEvent } from "@difftone/types";

import { EventDescription } from "./components";

import passedEventIcon from "@difftone/assets/wizard-event-icon-passed.svg";
import currentTimeIcon from "@difftone/assets/wizard-event-icon-current.svg";
import futureEventIcon from "@difftone/assets/wizard-event-icon-future.svg";

import {
    event_container,
    current_timeline_line,
    icon,
} from "./time-line-event-icon.module.css";

export type TimelineEventIconProps = {
    surveyEvent: TimelineEvent;
    eventTimelinePosition: "PASSED" | "CURRENT" | "FUTURE" | undefined;
    distanceMargin: number;
};

export const TimelineEventIcon = (props: TimelineEventIconProps) => {
    const { surveyEvent, eventTimelinePosition, distanceMargin } = props;

    let displayIcon = null;

    switch (eventTimelinePosition) {
        case "CURRENT":
            displayIcon = <img src={currentTimeIcon} alt="current" />;
            break;
        case "PASSED":
            displayIcon = <img src={passedEventIcon} alt="passed" />;
            break;
        default:
            displayIcon = <img src={futureEventIcon} alt="regular" />;
    }

    return (
        <div className={event_container}>
            {!surveyEvent.isCurrentTime ? (
                <EventDescription
                    eventTimelinePosition={eventTimelinePosition}
                    surveyEvent={surveyEvent}
                />
            ) : (
                <div className={current_timeline_line}></div>
            )}
            <div
                style={{
                    marginBottom: `${distanceMargin}px`,
                }}
                className={icon}
            >
                {displayIcon}
            </div>
        </div>
    );
};
