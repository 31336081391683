import { EmailAddress, Organization } from "@difftone/types";
import { makeAutoObservable, observable, ObservableMap } from "mobx";
import md5 from "md5";
import { getEmailsToAdd } from "./group-emails-store-internal-actions";
import { alertsMailingListGroupTitles } from "@difftone/constants";

export type GroupEmails = EmailAddress[];

class GroupEmailsStore {
    constructor() {
        makeAutoObservable<GroupEmailsStore, "_fetchedGroupEmailKeys">(this, {
            _fetchedGroupEmailKeys: false,
        });

        Object.keys(alertsMailingListGroupTitles).forEach((key) => {
            this._groupEmailsMap.merge({ [key]: [key] });
        });
    }

    private _fetchedGroupEmailKeys: string[] = [];

    private _groupEmailsMap: ObservableMap<string, GroupEmails | null> =
        observable.map({});

    public generateKey(email: EmailAddress, organization: Organization) {
        return md5(`${email}_${organization.uuid}`);
    }

    public getResolvedEmails(email: EmailAddress, organization: Organization) {
        const key = this.generateKey(email, organization);
        const results = this._groupEmailsMap.get(key);

        if (
            results === undefined &&
            !this._fetchedGroupEmailKeys.includes(key)
        ) {
            this._fetchedGroupEmailKeys.push(key);
            getEmailsToAdd([email], organization);
        }

        return results;
    }

    public setGroupEmailsMap(key: string, newGroupEmail: GroupEmails | null) {
        this._groupEmailsMap.merge({ [key]: newGroupEmail });
    }

    public clearStore = () => {
        this._groupEmailsMap = observable.map({});
    };
}

export const groupEmailsStore = new GroupEmailsStore();
