import { makeAutoObservable } from "mobx";
import {
    SimpleResultsFilterProfile,
    SimpleResultsSearchProfile,
    SimpleResultsSortingProfile,
    UUID,
} from "@difftone/types";

export const RESPONDENTS_SYSTEM_HEADER = "RESPONDENTS";
export const TEXT_ANSWER_SYSTEM_HEADER = "ANSWERS";
export const binaryFilterOptions = ["CHECKED", "UNCHECKED"];
export const FILTER_DIALOG_WIDTH_IN_PX = 220;

export type FilterDialogPositionOffset = {
    offsetLeft: number;
    offsetTop: number;
};

class SimpleSurveyResultsStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _selectedQuestion: UUID | null = null;
    get selectedQuestion() {
        return this._selectedQuestion;
    }
    set selectedQuestion(selectedUUID: UUID | null) {
        this._selectedQuestion = selectedUUID;
    }

    private _filterDialogPosition: FilterDialogPositionOffset = {
        offsetLeft: 0,
        offsetTop: 0,
    };
    get filterDialogPosition() {
        return this._filterDialogPosition;
    }
    set filterDialogPosition(newPosition: FilterDialogPositionOffset) {
        this._filterDialogPosition = newPosition;
    }

    private _filterProfile: SimpleResultsFilterProfile = {
        filterdColumnKey: "",
        focusedColumnKey: "",
        filterdProfilesMap: {},
    };
    get filterProfile() {
        return this._filterProfile;
    }
    set filterProfile(newFilterProfile: SimpleResultsFilterProfile) {
        this._filterProfile = newFilterProfile;
    }

    private _sortingProfile: SimpleResultsSortingProfile = {
        sortBy: null,
        direction: "DESCENDING",
    };
    get sortingProfile() {
        return this._sortingProfile;
    }
    set sortingProfile(newSortingProfile: SimpleResultsSortingProfile) {
        this._sortingProfile = newSortingProfile;
    }

    private _isFilterDialogOpen: boolean = false;
    get isFilterDialogOpen() {
        return this._isFilterDialogOpen;
    }
    set isFilterDialogOpen(isOpen: boolean) {
        this._isFilterDialogOpen = isOpen;
    }

    private _isSelectedQuestionInputFocused: boolean = false;
    get isSelectedQuestionInputFocused() {
        return this._isSelectedQuestionInputFocused;
    }
    set isSelectedQuestionInputFocused(isFocused: boolean) {
        this._isSelectedQuestionInputFocused = isFocused;
    }

    private _selectedQuestionFilterInput: string = "";
    get selectedQuestionFilterInput(): string {
        return this._selectedQuestionFilterInput;
    }
    set selectedQuestionFilterInput(newInput: string) {
        this._selectedQuestionFilterInput = newInput;
    }

    private _searchProfile: SimpleResultsSearchProfile = {};
    get searchProfile() {
        return this._searchProfile;
    }
    set searchProfile(newSearchProfile: SimpleResultsSearchProfile) {
        this._searchProfile = newSearchProfile;
    }

    public clearStore = () => {
        this._filterDialogPosition = { offsetLeft: 0, offsetTop: 0 };
        this._filterProfile = {
            filterdColumnKey: "",
            focusedColumnKey: "",
            filterdProfilesMap: {},
        };
        this._isFilterDialogOpen = false;
        this._isSelectedQuestionInputFocused = false;
        this._searchProfile = {};
        this._selectedQuestion = null;
        this._selectedQuestionFilterInput = "";
        this._sortingProfile = { sortBy: null, direction: "DESCENDING" };
    };
}

export const simpleSurveyResultsStore = new SimpleSurveyResultsStore();
