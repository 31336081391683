import { BaseSurvey } from "@difftone/types";
import { makeAutoObservable } from "mobx";

class InboxDisplayStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _messageSurvey: string = "";

    get messageSurvey(): string {
        return this._messageSurvey;
    }
    set messageSurvey(messageSurvey: string) {
        this._messageSurvey = messageSurvey;
    }

    private _newRespondents: string[] = [];

    get newRespondents(): string[] {
        return this._newRespondents;
    }
    set newRespondents(newRespondents: string[]) {
        this._newRespondents = newRespondents;
    }

    private _copySurvey: BaseSurvey | null | undefined = null;
    get copySurvey(): BaseSurvey | null | undefined {
        return this._copySurvey;
    }
    set copySurvey(newCopySurvey: BaseSurvey | null | undefined) {
        this._copySurvey = newCopySurvey;
    }

    private _isImportingForm: boolean = false;
    get isImportingForm(): boolean {
        return this._isImportingForm;
    }
    set isImportingForm(newValue: boolean) {
        this._isImportingForm = newValue;
    }

    private _isImportGoogleFormDialogOpened: boolean = false;
    get isImportGoogleFormDialogOpened(): boolean {
        return this._isImportGoogleFormDialogOpened;
    }
    set isImportGoogleFormDialogOpened(newValue: boolean) {
        this._isImportGoogleFormDialogOpened = newValue;
    }

    public clearStore = () => {
        this._copySurvey = null;
        this._newRespondents = [];
        this._messageSurvey = "";
    };
}

export const inboxDisplayStore = new InboxDisplayStore();
