import {
    ACTIVATE_FROM_EMAIL_PARAM,
    getWebClientBaseUrl,
    OPEN_SURVEY,
    PAGE_URLS,
} from "@difftone/constants";
import {
    replaceEmailTemplateValues,
    addOngoingSurveyRecipientsTemplate,
    surveyRecipientsEmailTemplate,
    sendOutSurveyReminderTemplate,
    surveyEndedEmailTemplate,
    sharedSurveyEmailTemplate,
    sharedResultEmailTemplate,
    customAlertReminderEmailTemplate,
} from "@difftone/frontend-common";
import {
    getUserEmail,
    getUserFullName,
    getUserUuid,
} from "@difftone/procedures";
import { EMAIL_TEMPLATE, emailsStore } from "@difftone/stores";
import { totalTimeInMiliSecondsFromString } from "@difftone/time-utils";
import {
    Email,
    EmailAddress,
    BaseSurvey,
    UUID,
    SurveyEvent,
    SimpleSurvey,
    SharedSurvey,
    SharedResult,
    SurveyEventAlert,
} from "@difftone/types";
import { action } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { showDifftoneAlert } from "./difftone-alert-actions";

export const scheduleNewOngoingRecipientsEmail = action(
    (
        recipients: EmailAddress[],
        survey: BaseSurvey,
        sendDate: number,
        message?: string
    ) => {
        const newEmail: Email = JSON.parse(JSON.stringify(EMAIL_TEMPLATE));
        newEmail.uuid = uuidv4();
        const initiatorName = getUserFullName();
        const initiatorEmail = getUserEmail();
        const emailContent = replaceEmailTemplateValues(
            addOngoingSurveyRecipientsTemplate,
            {
                initiator_name: initiatorName,
                survey_initiator_email: initiatorEmail,
                survey_name: survey.survey_name,
                welcome_message: message || null,
                button_title: "Go to Survey",
                link_url: survey.is_open_survey
                    ? `${getWebClientBaseUrl()}/survey/${OPEN_SURVEY}/${
                          survey.uuid
                      }`
                    : `${getWebClientBaseUrl()}/survey/${survey.uuid}`,
            }
        );
        newEmail.recipients = recipients;
        newEmail.content = emailContent;
        newEmail.dateToSend = sendDate;
        newEmail.sender = getUserUuid();
        newEmail.subject = `New Survey "${survey.survey_name}"`;
        emailsStore.upsertEmail(newEmail);
    }
);

export const scheduleNewRecipientsEmail = action((survey: SimpleSurvey) => {
    const newEmail: Email = JSON.parse(JSON.stringify(EMAIL_TEMPLATE));
    newEmail.uuid = uuidv4();
    const initiatorName = getUserFullName();
    const initiatorEmail = getUserEmail();
    const emailContent = replaceEmailTemplateValues(
        surveyRecipientsEmailTemplate,
        {
            initiator_name: initiatorName,
            survey_initiator_email: initiatorEmail,
            survey_name: survey.survey_name,
            welcome_message: survey.welcome_message || null,
            button_title: "Go to Survey",
            link_url: survey.is_open_survey
                ? `${getWebClientBaseUrl()}/survey/${OPEN_SURVEY}/${
                      survey.uuid
                  }`
                : `${getWebClientBaseUrl()}/survey/${survey.uuid}`,
        }
    );
    newEmail.recipients = survey.respondents;
    newEmail.content = emailContent;
    newEmail.dateToSend = Date.now();
    newEmail.sender = getUserUuid();
    newEmail.subject = `New Survey" ${survey.survey_name}"`;
    emailsStore.upsertEmail(newEmail);
});

export const scheduleSendOutSurveyReminderEmail = action(
    (survey: SimpleSurvey) => {
        const newEmail: Email = JSON.parse(JSON.stringify(EMAIL_TEMPLATE));
        newEmail.uuid = uuidv4();
        const initiatorName = getUserFullName();
        const initiatorEmail = getUserEmail();
        const publishedDateEvent: SurveyEvent | undefined =
            survey.survey_events.find(
                (surveyEvent) =>
                    surveyEvent.system_survey_event_type === "PUBLISHED_DATE"
            );
        const surveyStartDate = totalTimeInMiliSecondsFromString(
            publishedDateEvent?.date_utc!,
            publishedDateEvent?.day_utc_time!
        );
        const emailContent = replaceEmailTemplateValues(
            sendOutSurveyReminderTemplate,
            {
                initiator_name: initiatorName,
                survey_name: survey.survey_name,
                button_title: "Send out survey",
                link_url: `${getWebClientBaseUrl()}${
                    PAGE_URLS.INBOX_ALL
                }?${ACTIVATE_FROM_EMAIL_PARAM}=${survey.uuid}`,
            }
        );
        newEmail.recipients = [initiatorEmail];
        newEmail.content = emailContent;
        newEmail.dateToSend = surveyStartDate;
        newEmail.sender = getUserUuid();
        newEmail.subject = `Send out survey "${survey.survey_name}"`;
        publishedDateEvent?.event_emails.push(newEmail.uuid);
        emailsStore.upsertEmail(newEmail);
    }
);

export const scheduleShareSurveyEmail = action((sharedSurvey: SharedSurvey) => {
    const newEmail: Email = JSON.parse(JSON.stringify(EMAIL_TEMPLATE));
    newEmail.uuid = uuidv4();
    const initiatorName = getUserFullName();
    const initiatorEmail = getUserEmail();

    const emailContent = replaceEmailTemplateValues(sharedSurveyEmailTemplate, {
        initiator_name: initiatorName,
        initiator_email: initiatorEmail,
        survey_name: sharedSurvey.survey.survey_name,
        optional_message: sharedSurvey.message || null,
        button_title: "Go to Inbox",
        link_url: `${getWebClientBaseUrl()}${PAGE_URLS.INBOX_SHARED_WITH_ME}`,
    });
    newEmail.recipients = sharedSurvey.addressee;
    newEmail.content = emailContent;
    newEmail.dateToSend = Date.now();
    newEmail.sender = getUserUuid();
    newEmail.subject = `New shared survey`;
    emailsStore.upsertEmail(newEmail);
});

export const scheduleShareResultsEmail = action(
    (sharedResult: SharedResult) => {
        const newEmail: Email = JSON.parse(JSON.stringify(EMAIL_TEMPLATE));
        newEmail.uuid = uuidv4();
        const initiatorName = getUserFullName();
        const initiatorEmail = getUserEmail();

        const emailContent = replaceEmailTemplateValues(
            sharedResultEmailTemplate,
            {
                initiator_name: initiatorName,
                initiator_email: initiatorEmail,
                survey_name: sharedResult.survey_name,
                optional_message: sharedResult.message || null,
                button_title: "View Results",
                link_url: `${getWebClientBaseUrl()}/shared-results/${
                    sharedResult.uuid
                }`,
            }
        );
        newEmail.recipients = sharedResult.share_with;
        newEmail.content = emailContent;
        newEmail.dateToSend = Date.now();
        newEmail.sender = getUserUuid();
        newEmail.subject = `New shared result`;
        emailsStore.upsertEmail(newEmail);
    }
);

export const scheduleOrUpdateSurveyEndedEmail = action(
    (survey: SimpleSurvey) => {
        const surveyEndEvent: SurveyEvent | undefined =
            survey.survey_events.find(
                (surveyEvent) =>
                    surveyEvent.system_survey_event_type === "LAST_DATE_TO_FILL"
            );

        const surveyEndDate = totalTimeInMiliSecondsFromString(
            surveyEndEvent?.date_utc!,
            surveyEndEvent?.day_utc_time!
        );

        //If survey event is using old data sturcture,
        //add this property to it
        if (surveyEndEvent && !surveyEndEvent.event_emails) {
            surveyEndEvent.event_emails = [];
        }

        if (surveyEndEvent?.event_emails.length) {
            const emailToUpdate = emailsStore.getEmailByUuid(
                surveyEndEvent.event_emails[0]
            );
            if (emailToUpdate) {
                emailToUpdate.dateToSend = surveyEndDate;
                emailsStore.upsertEmail(emailToUpdate);
                return;
            }
        }

        const newEmail: Email = JSON.parse(JSON.stringify(EMAIL_TEMPLATE));
        newEmail.uuid = uuidv4();
        const initiatorName = getUserFullName();
        const initiatorEmail = getUserEmail();
        const emailContent = replaceEmailTemplateValues(
            surveyEndedEmailTemplate,
            {
                initiator_name: initiatorName,
                survey_name: survey.survey_name,
                button_title: "Go to results",
                link_url: `${getWebClientBaseUrl()}/results/${survey.uuid}`,
            }
        );
        newEmail.recipients = [initiatorEmail];
        newEmail.content = emailContent;
        newEmail.dateToSend = surveyEndDate;
        newEmail.sender = getUserUuid();
        newEmail.subject = `Survey ended "${survey.survey_name}"`;
        surveyEndEvent?.event_emails.push(newEmail.uuid);
        emailsStore.upsertEmail(newEmail);
    }
);

export const scheduleOrUpdateCustomAlertEmail = action(
    (
        survey: SimpleSurvey,
        surveyEventAlert: SurveyEventAlert,
        sendDate: number
    ) => {
        let emailToUpdate: Email | null | undefined;
        if (surveyEventAlert.email_uuid) {
            emailToUpdate = emailsStore.getEmailByUuid(
                surveyEventAlert.email_uuid
            );
        } else {
            emailToUpdate = JSON.parse(JSON.stringify(EMAIL_TEMPLATE)) as Email;
            emailToUpdate.uuid = uuidv4();
            emailToUpdate.sender = getUserUuid();
            emailToUpdate.subject = `Reminder regarding survey "${survey.survey_name}"`;
            emailToUpdate.sender = getUserUuid();
            surveyEventAlert.email_uuid = emailToUpdate.uuid;
        }

        if (emailToUpdate) {
            updateAlertEmail(
                emailToUpdate,
                surveyEventAlert.send_to,
                sendDate,
                survey,
                surveyEventAlert.content.message
            );
        }
    }
);

const updateAlertEmail = (
    email: Email,
    recipients: EmailAddress[],
    sendDate: number,
    survey: SimpleSurvey,
    message?: string
) => {
    email.recipients = recipients;
    email.dateToSend = sendDate;
    email.content = replaceEmailTemplateValues(
        customAlertReminderEmailTemplate,
        {
            message: message || null,
            survey_name: survey.survey_name,
            button_title: "Go to Survey",
            link_url: survey.is_open_survey
                ? `${getWebClientBaseUrl()}/survey/${OPEN_SURVEY}/${
                      survey.uuid
                  }`
                : `${getWebClientBaseUrl()}/survey/${survey.uuid}`,
        }
    );
    emailsStore.upsertEmail(email);
};

export const removeEmailAddressesFromEmail = action(
    (emailUUID: UUID, emailsToRemove: EmailAddress[]) => {
        const emailCopy: Email = JSON.parse(
            JSON.stringify(emailsStore.getEmailByUuid(emailUUID))
        );
        if (emailCopy) {
            emailCopy.recipients = emailCopy.recipients.filter((recipient) => {
                return !emailsToRemove.find(
                    (emailToRemove) => emailToRemove === recipient
                );
            });
            emailsStore.upsertEmail(emailCopy);
        } else {
            showDifftoneAlert(
                "Could not update the email. Please try again later.",
                "FAILURE"
            );
        }
    }
);

export const addEmailAddressesToEmail = action(
    (emailUUID: UUID, emailsToAdd: EmailAddress[]) => {
        const emailCopy: Email = JSON.parse(
            JSON.stringify(emailsStore.getEmailByUuid(emailUUID))
        );
        if (emailCopy) {
            emailCopy.recipients = [...emailCopy.recipients, ...emailsToAdd];
            emailsStore.upsertEmail(emailCopy);
        } else {
            showDifftoneAlert(
                "Could not update the email. Please try again later.",
                "FAILURE"
            );
        }
    }
);

export const updateEmailDateToSend = action(
    (emailUUID: UUID, newDate: number) => {
        const emailCopy: Email = JSON.parse(
            JSON.stringify(emailsStore.getEmailByUuid(emailUUID))
        );
        if (emailCopy) {
            emailCopy.dateToSend = newDate;
            emailsStore.upsertEmail(emailCopy);
        } else {
            showDifftoneAlert(
                "Could not update the email. Please try again later.",
                "FAILURE"
            );
        }
    }
);

export const cancelEmail = action((emailUUID: UUID) => {
    const email = emailsStore.getEmailByUuid(emailUUID);
    if (email) {
        email.cancelled = true;
        emailsStore.upsertEmail(email);
    } else {
        showDifftoneAlert(
            "Could not update the email. Please try again later.",
            "FAILURE"
        );
    }
});
