import React from "react";
import { observer } from "mobx-react";

import {
    UUID,
    QuestionToAnswerDescreteQuestion,
    ResultsMap,
} from "@difftone/types";
import {
    resultsDisplayStore,
    categoriesDisplayStore,
    surveyResponsesSummaryStore,
    sharedResultsDisplayStore,
    ResultsTable,
} from "@difftone/stores";
import {
    getCategoryFilterValuesAndActiveStatus,
    getNonFilterQuestionsFromSurvey,
    getResultsMapsBySharedResultsUuidAndCompare,
    selectedFiltersActiveAsCompare,
    selectedFiltersNotActiveAsCompare,
    sortQuestionsByType,
} from "@difftone/reducers";
import { showDifftoneAlert, setLocation } from "@difftone/actions";
import { constantAlertVariants, PAGE_URLS } from "@difftone/constants";
import {
    formatCategoriesQuestionsMap,
    formatCategoriesResultsRatingMap,
    getSortedCategoriesList,
} from "@difftone/reducers";
import { warningMessages } from "@difftone/constants";
import { createResultsTableFromResultsMaps } from "@difftone/frontend-common";
import { COLUMN_0 } from "@difftone/common-constants";

import {
    FavorableQuestionDisplay,
    FavorableCategoryDisplay,
    Spinner,
    ResultsFavorableQuestion,
    ResultsFavorableCategories,
} from "@difftone/shared-components";

import {
    results_questions,
    results_table_wrapper,
    results_row_spinner,
    results_questions_and_answers,
    results_favorable_spreadsheet,
    favorable_shared_question_wrapper,
} from "./shared-results-favorable-spreadsheet.module.css";

export type ResultsFavorableSpreadsheetProps = {};

export const SharedResultsFavorableSpreadsheet = observer(
    (props: ResultsFavorableSpreadsheetProps) => {
        const { categoriesQuestionsDisplayModeToggle } = resultsDisplayStore;

        const {
            selectedSharedResults,
            selectedSurvey,
            getFiltersActiveAsCompares,
            getFiltersNotActiveAsCompares,
        } = sharedResultsDisplayStore;

        const _selectedSurvey = selectedSurvey!;

        const surveyResponses =
            surveyResponsesSummaryStore.getSurveyResponseSummaryByFilterPlain(
                _selectedSurvey.uuid,
                []
            );

        if (surveyResponses === undefined) {
            return <Spinner size="LARGE" />;
        }

        if (surveyResponses === null) {
            setLocation(PAGE_URLS.INBOX_ALL);
            showDifftoneAlert(
                warningMessages.surveyResponseNotFound,
                "FAILURE"
            );
            return null;
        }

        const filterAsActive = selectedFiltersActiveAsCompare(
            getFiltersActiveAsCompares()
        );
        const filterNotActive = selectedFiltersNotActiveAsCompare(
            getFiltersNotActiveAsCompares()
        );

        const resultsMaps = getResultsMapsBySharedResultsUuidAndCompare(
            selectedSharedResults!.uuid,
            filterAsActive,
            filterNotActive
        );

        const _resultsMaps = resultsMaps as (null | undefined | ResultsMap)[];

        if (_resultsMaps.includes(undefined)) {
            return (
                <div className={results_row_spinner}>
                    <Spinner size="LARGE" />
                </div>
            );
        }

        if (_resultsMaps.includes(null)) {
            setLocation(PAGE_URLS.INBOX_ALL);
            showDifftoneAlert(
                constantAlertVariants.survey_results_failure.text,
                constantAlertVariants.survey_results_failure.variant
            );
            return null;
        }

        const safeResultsMap = _resultsMaps as ResultsMap[];

        const resultsTable = createResultsTableFromResultsMaps(safeResultsMap);

        const sortedNonFilterRatingQuestions = sortQuestionsByType(
            getNonFilterQuestionsFromSurvey(_selectedSurvey)
        ).filter((question) => question.question_type_name === "RATING");

        const categoriesQuestionsMap = formatCategoriesQuestionsMap(
            _selectedSurvey.questions || []
        );

        const categoriesList: UUID[] = getSortedCategoriesList(
            categoriesQuestionsMap
        );

        if (!categoriesDisplayStore.isStoreInitialized) {
            categoriesDisplayStore.initCategoriesDisplayMap(categoriesList);
            return <Spinner size="LARGE" />;
        }

        const _resultsTable = resultsTable as ResultsTable;

        const categoriesResultsMap = formatCategoriesResultsRatingMap(
            categoriesQuestionsMap,
            _resultsTable
        );

        const { categoryFilterValues, isCategoryFilterActive } =
            getCategoryFilterValuesAndActiveStatus();

        const isQuestionsTogglerView =
            categoriesQuestionsDisplayModeToggle === "QUESTIONS";

        return (
            <div className={results_favorable_spreadsheet}>
                <div className={results_table_wrapper}>
                    <div className={results_questions_and_answers}>
                        <div className={results_questions}>
                            {isQuestionsTogglerView &&
                                sortedNonFilterRatingQuestions.map(
                                    (question, index) => {
                                        return (
                                            <FavorableQuestionDisplay
                                                questionIndex={index}
                                                key={question.uuid}
                                                question={question}
                                                digestedResultsRow={
                                                    _resultsTable[question.uuid]
                                                }
                                                survey={_selectedSurvey}
                                            />
                                        );
                                    }
                                )}

                            {!isQuestionsTogglerView &&
                                categoriesList.map((categoryUUID) => {
                                    const { categoryName, questions } =
                                        categoriesQuestionsMap[categoryUUID];

                                    const ratingQuestions = questions.filter(
                                        (question) =>
                                            question.question_type_name ===
                                            "RATING"
                                    );
                                    if (
                                        !categoryFilterValues.includes(
                                            categoryName
                                        ) &&
                                        isCategoryFilterActive
                                    ) {
                                        return null;
                                    }

                                    return (
                                        <FavorableCategoryDisplay
                                            key={categoryUUID}
                                            categoryUUID={categoryUUID}
                                            categoryName={categoryName}
                                            questions={ratingQuestions}
                                            categoriesResultsRatingMap={
                                                categoriesResultsMap
                                            }
                                            questionsResults={_resultsTable}
                                            survey={_selectedSurvey}
                                        />
                                    );
                                })}
                        </div>
                        <div className={favorable_shared_question_wrapper}>
                            {isQuestionsTogglerView &&
                                sortedNonFilterRatingQuestions.map(
                                    (question) => {
                                        return (
                                            <ResultsFavorableQuestion
                                                key={question.uuid}
                                                questionResults={
                                                    _resultsTable[
                                                        question.uuid
                                                    ][
                                                        COLUMN_0
                                                    ] as QuestionToAnswerDescreteQuestion
                                                }
                                                scoreBarSize="MEDIUM"
                                            />
                                        );
                                    }
                                )}

                            {!isQuestionsTogglerView &&
                                categoriesList.map((categoryUUID) => {
                                    const { questions, categoryName } =
                                        categoriesQuestionsMap[categoryUUID];
                                    const categoryToggled =
                                        categoriesDisplayStore.getCategoryDisplayByUUID(
                                            categoryUUID
                                        );

                                    if (
                                        !categoryFilterValues.includes(
                                            categoryName
                                        ) &&
                                        isCategoryFilterActive
                                    ) {
                                        return null;
                                    }

                                    const ratingQuestions = questions.filter(
                                        (question) =>
                                            question.question_type_name ===
                                            "RATING"
                                    );

                                    return (
                                        <div key={categoryUUID}>
                                            <ResultsFavorableCategories
                                                questions={ratingQuestions}
                                                resultsRow={_resultsTable}
                                                scoreBarSize="MEDIUM"
                                            />
                                            {ratingQuestions.map((question) => {
                                                if (!categoryToggled) {
                                                    return null;
                                                }

                                                return (
                                                    <ResultsFavorableQuestion
                                                        scoreBarSize="SMALL"
                                                        key={question.uuid}
                                                        questionResults={
                                                            _resultsTable[
                                                                question.uuid
                                                            ][
                                                                COLUMN_0
                                                            ] as QuestionToAnswerDescreteQuestion
                                                        }
                                                    />
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);
