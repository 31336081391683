import type {
    EmailDuplicationValidationType,
    InvalidValuesIndexesInArray,
    ValidationResult,
    ValidationType,
} from "@difftone/types";

//EMAIL ADDRESS
export const validateEmailAddress = (email: string) => {
    const res: ValidationResult<ValidationType>[] = [];

    res.push(validateEmailRegex(email));

    return res;
};

export const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateEmailRegex = (
    email: string
): ValidationResult<ValidationType> => {
    return EMAIL_REGEX.test(email)
        ? {
              is_valid: true,
              validation_error_code: "EMAIL_ADDRESS_REGEX_ERROR",
              validation_error_type: { source_email: email },
          }
        : {
              is_valid: false,
              validation_error_code: "EMAIL_ADDRESS_REGEX_ERROR",
              validation_error_type: { source_email: email },
          };
};

//EMAIL ADDRESSES (Multiple)
export const validateEmailAddresses = (
    emails: string[]
): ValidationResult<ValidationType>[] => {
    const res: ValidationResult<ValidationType>[] = [];

    //Discreet Validations
    emails.forEach((email) => res.push(...validateEmailAddress(email)));

    //Grupe Validations
    res.push(validateEmailUniqeness(emails));

    return res;
};

const validateEmailUniqeness = (
    emails: string[]
): ValidationResult<EmailDuplicationValidationType> => {
    if (!emails) {
        return {
            is_valid: true,
            validation_error_code: "EMAIL_ADDRESS_DUPLICATION_ERROR",
            validation_error_type: { duplication_indexes: {} },
        };
    }

    if (emails.length < 2) {
        return {
            is_valid: true,
            validation_error_code: "EMAIL_ADDRESS_DUPLICATION_ERROR",
            validation_error_type: { duplication_indexes: {} },
        };
    }

    const duplicatedEmailsMap: InvalidValuesIndexesInArray = {};

    emails.forEach((email, index) => {
        if (!duplicatedEmailsMap[email]) {
            duplicatedEmailsMap[email] = [index];
        } else {
            duplicatedEmailsMap[email].push(index);
        }
    });

    for (let element in duplicatedEmailsMap) {
        if (duplicatedEmailsMap[element].length === 1) {
            delete duplicatedEmailsMap[element];
        }
    }

    return Object.keys(duplicatedEmailsMap).length === 0
        ? {
              is_valid: true,
              validation_error_code: "EMAIL_ADDRESS_DUPLICATION_ERROR",
              validation_error_type: {
                  duplication_indexes: duplicatedEmailsMap,
              },
          }
        : {
              is_valid: false,
              validation_error_code: "EMAIL_ADDRESS_DUPLICATION_ERROR",
              validation_error_type: {
                  duplication_indexes: duplicatedEmailsMap,
              },
          };
};
