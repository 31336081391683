import React from "react";
import { observer } from "mobx-react";
import { BaseSurvey } from "@difftone/types";
import { UiStatus } from "@difftone/frontend-common";
import { navigationStore } from "@difftone/stores";
import { getUserUuid } from "@difftone/procedures";

import {
    progress_bar,
    meta,
    bar,
    ended_text_color,
    ended_progress_color,
    progress_line,
} from "./progress-bar.module.css";
import { getSurveyResponseData } from "@difftone/reducers";

export type ProgressBarProps = {
    survey: BaseSurvey;
    surveyStatus: UiStatus;
};

export const ProgressBar = observer((props: ProgressBarProps) => {
    const { currentPage } = navigationStore;
    const pageLocation = currentPage.split("/")[1];

    const { survey, surveyStatus } = props;
    const isSurveyEnded = surveyStatus === "ENDED";
    const surveyResponseData = getSurveyResponseData(survey);
    return (
        <>
            {survey.initiator === getUserUuid() && (
                <div
                    className={
                        isSurveyEnded
                            ? [progress_bar, ended_text_color].join(" ")
                            : progress_bar
                    }
                >
                    {pageLocation === "inbox" ? (
                        <div
                            className={meta}
                        >{`${surveyResponseData.percentage}% (${surveyResponseData.completed}/${surveyResponseData.total})`}</div>
                    ) : null}
                    <div
                        className={
                            isSurveyEnded
                                ? [bar, ended_progress_color].join(" ")
                                : bar
                        }
                    >
                        <div
                            className={
                                isSurveyEnded
                                    ? ended_progress_color
                                    : progress_line
                            }
                            style={{
                                width: `${
                                    Number(surveyResponseData.percentage) > 100
                                        ? 100
                                        : surveyResponseData.percentage
                                }%`,
                            }}
                        ></div>
                    </div>
                </div>
            )}
        </>
    );
});
