import { showDifftoneAlert } from "@difftone/actions";
import {
    EventAlertsDisplay,
    BaseSurvey,
    UUID,
    SurveyEventAlert,
    EmailAddress,
    SurveyClasses,
} from "@difftone/types";
import { makeAutoObservable } from "mobx";
import { checkIfEmailIsGroup } from "./wizard-store-internal-actions";

class WizardDisplayStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _selectedEventUUID: UUID | null = null;

    get selectedEventUUID() {
        return this._selectedEventUUID;
    }

    set selectedEventUUID(newSelectedEventUUID: UUID | null) {
        this._selectedEventUUID = newSelectedEventUUID;
    }

    private _selectedInputEventTitle: UUID | null = null;
    get selectedInputEventTitle() {
        return this._selectedInputEventTitle;
    }

    set selectedInputEventTitle(newSelectedEventInputTitle: UUID | null) {
        this._selectedInputEventTitle = newSelectedEventInputTitle;
    }

    private _selectedDisplayEventAlert: EventAlertsDisplay | null = null;

    get selectedDisplayEventAlert() {
        return this._selectedDisplayEventAlert;
    }

    set selectedDisplayEventAlert(newEventAlert: EventAlertsDisplay | null) {
        this._selectedDisplayEventAlert = newEventAlert;
    }

    private _wasDifferentRatingScaleTooltipAlreadyShown = false;

    get wasDifferentRatingScaleTooltipAlreadyShown() {
        return this._wasDifferentRatingScaleTooltipAlreadyShown;
    }

    set wasDifferentRatingScaleTooltipAlreadyShown(value) {
        this._wasDifferentRatingScaleTooltipAlreadyShown = value;
    }

    private __isEditableSurvey = false;

    get isEditableSurvey() {
        return this.__isEditableSurvey;
    }

    set isEditableSurvey(value: boolean) {
        this.__isEditableSurvey = value;
    }

    private _isInitialQuestionsSectionScroll = true;

    get isInitialQuestionsSectionScroll() {
        return this._isInitialQuestionsSectionScroll;
    }

    set isInitialQuestionsSectionScroll(value) {
        this._isInitialQuestionsSectionScroll = value;
    }

    private _activeMailingList: "PARTICIPANTS" | "ADMINS" = "PARTICIPANTS";
    get activeMailingList() {
        return this._activeMailingList;
    }
    set activeMailingList(list) {
        this._activeMailingList = list;
    }

    private _firstClickOnMailingType = false;
    get firstClickOnMailingType() {
        return this._firstClickOnMailingType;
    }
    set firstClickOnMailingType(isClicked) {
        this._firstClickOnMailingType = isClicked;
    }

    private _surveyCopyToUpdate: SurveyClasses | null = null;
    get surveyCopyToUpdate() {
        return this._surveyCopyToUpdate;
    }

    set surveyCopyToUpdate(updatedSurvey: SurveyClasses | null) {
        this._surveyCopyToUpdate = updatedSurvey;
    }

    public getWizardSurveyCopy = () => {
        return wizardDisplayStore.surveyCopyToUpdate as SurveyClasses;
    };

    private _surveyAlertsDeleted: UUID[] = [];

    public getSurveyAlertsDeleted() {
        return this._surveyAlertsDeleted;
    }

    public addSurveyAlertToDeletedList(uuid: UUID) {
        this._surveyAlertsDeleted.push(uuid);
    }

    private _surveyCopyAlerts: {
        [key: string]: SurveyEventAlert | null;
    } = {};
    get surveyCopyAlerts() {
        return this._surveyCopyAlerts;
    }
    set surveyCopyAlerts(newAlerts: {
        [key: string]: SurveyEventAlert | null;
    }) {
        this._surveyCopyAlerts = newAlerts;
    }

    public getSurveyCopyAlertCopyByUuid(uuid: UUID): SurveyEventAlert | null {
        const surveyAlert = this._surveyCopyAlerts[uuid];

        if (!surveyAlert) {
            return null;
        }

        return JSON.parse(JSON.stringify(surveyAlert));
    }

    public insertCopyAlertToStore(alert: SurveyEventAlert) {
        this._surveyCopyAlerts[alert.uuid] = alert;
    }

    public async addEmailsToAlertCopy(
        emails: EmailAddress[],
        alert: SurveyEventAlert
    ) {
        const alertToUpdate = this._surveyCopyAlerts[alert.uuid];
        const emailsToAdd = await this.getEmailsToAddToAlert(emails);
        if (!emailsToAdd) {
            showDifftoneAlert(
                "Unable to retrieve group email members",
                "FAILURE"
            );
            return;
        } else if (!alertToUpdate) {
            showDifftoneAlert("Invalid alert", "FAILURE");
            return;
        }

        alertToUpdate.send_to = Array.from(
            new Set([...alertToUpdate.send_to, ...emailsToAdd])
        );
    }

    private async getEmailsToAddToAlert(
        emails: EmailAddress[]
    ): Promise<EmailAddress[] | null> {
        const processedEmailPromises = [];
        const processEmail = async (email: EmailAddress) => {
            const isGroupEmail = await checkIfEmailIsGroup(email);
            if (isGroupEmail) {
                return [];
            } else {
                return [email];
            }
        };
        for (const email of emails) {
            processedEmailPromises.push(processEmail(email));
        }

        const processedEmails = await Promise.all(processedEmailPromises);
        return processedEmails.flat();
    }

    public clearStore() {
        this._selectedEventUUID = null;
        this._selectedInputEventTitle = null;
        this._selectedDisplayEventAlert = null;
        this.__isEditableSurvey = false;
        this._surveyAlertsDeleted = [];
    }
}

export const wizardDisplayStore = new WizardDisplayStore();
