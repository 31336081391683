import React from "react";
import clsx from "clsx";

import { debounceUpsertSurveyToApi } from "@difftone/actions";
import { BaseSurvey } from "@difftone/types";
import { DropdownElements, SimpleDropdown } from "@difftone/shared-components";
import { wizardDisplayStore } from "@difftone/stores";

import {
    purpose_container,
    purpose_header,
    purpose_header_disabled,
    disabled_dropdown,
    disabled_text_value,
} from "./purpose.module.css";

export type PurposeType = {
    survey: BaseSurvey;
};

export const Purpose = (props: PurposeType) => {
    const { survey } = props;
    const { isEditableSurvey } = wizardDisplayStore;

    const onChange = (selectedValue: string) => {
        const value: ValueOf<BaseSurvey> = selectedValue;
        const key: keyof BaseSurvey = "purpose";
        debounceUpsertSurveyToApi(key, value.toUpperCase());
    };

    const purposeDropdownList: DropdownElements[] = [
        {
            displayValue: "Engagement",
            systemValue: "ENGAGEMENT",
        },
        {
            displayValue: "Feedback",
            systemValue: "FEEDBACK",
        },
        {
            displayValue: "Evoke Discussion",
            systemValue: "DISCUSSION",
        },
        {
            displayValue: "Other ",
            systemValue: "OTHER",
        },
    ];

    const disabledOptions = purposeDropdownList.map(
        (option) => option.systemValue
    );

    return (
        <div className={purpose_container}>
            <div
                className={clsx(purpose_header, {
                    [purpose_header_disabled]: !isEditableSurvey,
                })}
            >
                Survey Purpose
            </div>
            <SimpleDropdown
                wrapperClassName={!isEditableSurvey && disabled_dropdown}
                textValueClassName={!isEditableSurvey && disabled_text_value}
                disabled={!isEditableSurvey}
                onChange={onChange}
                selectedValue={survey.purpose as string}
                dropdownOptions={purposeDropdownList}
                disabledOptions={!isEditableSurvey ? disabledOptions : []}
            />
        </div>
    );
};
