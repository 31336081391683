import React from "react";
import { observer } from "mobx-react";
import { ShowDifftoneTooltip, Checkbox } from "@difftone/shared-components";
import { OptionalPropertiesForGraph } from "@difftone/types";

import {
    form_legend_scope,
    legend_scope,
    circle_scope,
    row_scope,
    row_text_scope,
    dots_line_scope,
} from "./legend.module.css";

export type LegendProps = {
    listText: string[];
    colors: any;
    generalDataForGraph: OptionalPropertiesForGraph;
    onUpdateLegendMap: (key: string) => void;
    onSelectAll: (selectAll: boolean) => void;
    onShowSelectedDots: (showSelectedDots: boolean) => void;
    onShowSelectedTrendLines: (showSelectedTrendLines: boolean) => void;
};

const COLOR_WHITE = "white";
const COLOR_BLACK = "black";

export const Legend = observer((props: LegendProps) => {
    const {
        listText,
        colors,
        generalDataForGraph,
        onUpdateLegendMap,
        onSelectAll,
        onShowSelectedDots,
        onShowSelectedTrendLines,
    } = props;

    const showOrHideByName = (key: string) => {
        onUpdateLegendMap(key);
    };

    const handleSelectAll = () => {
        onSelectAll(!generalDataForGraph.selectAll);
    };

    const handleShowSelectedDots = () => {
        onShowSelectedDots(!generalDataForGraph.showSelectedDots);
    };

    const handleShowSelectedTrendLines = () => {
        onShowSelectedTrendLines(!generalDataForGraph.showSelectedTrendLines);
    };

    const getStyleCircle = (key: string, index: number) => {
        return {
            background: generalDataForGraph.legendMap[key]
                ? colors(index)
                : COLOR_WHITE,
            borderColor: generalDataForGraph.legendMap[key]
                ? COLOR_WHITE
                : COLOR_BLACK,
        };
    };

    return (
        <div className={legend_scope}>
            <div className={form_legend_scope}>
                {listText.map((key, i) => (
                    <div key={key} onClick={() => showOrHideByName(key)}>
                        <ShowDifftoneTooltip tooltipPosition="bottom" tip={key}>
                            <div className={row_scope}>
                                <div
                                    className={circle_scope}
                                    style={getStyleCircle(key, i)}
                                ></div>
                                <div className={row_text_scope}>{key}</div>
                            </div>
                        </ShowDifftoneTooltip>
                    </div>
                ))}
            </div>

            <div className={dots_line_scope}>
                <div className={row_scope}>
                    <div className={circle_scope}>
                        <Checkbox
                            checked={generalDataForGraph.selectAll}
                            onClick={handleSelectAll}
                        />
                    </div>
                    <div>Select all</div>
                </div>
                <div className={row_scope}>
                    <div className={circle_scope}>
                        <Checkbox
                            checked={generalDataForGraph.showSelectedDots}
                            onClick={handleShowSelectedDots}
                        />
                    </div>
                    <div>Show dots</div>
                </div>
                <div className={row_scope}>
                    <div className={circle_scope}>
                        <Checkbox
                            checked={generalDataForGraph.showSelectedTrendLines}
                            onClick={handleShowSelectedTrendLines}
                        />
                    </div>
                    <div>Show trend lines</div>
                </div>
            </div>
        </div>
    );
});
