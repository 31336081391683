import React from "react";

import { surveyStore, wizardStore } from "@difftone/stores";
import {
    addNewEmptyQuestionToWizard,
    deleteQuestionFromWizard,
    duplicateQuestionInWizard,
    nullifyWizardValidationError,
    toggleWizardQuestionIsRequired,
    debounceUpsertSurveyToApi,
    validateWizardSurvey,
    setFocusedWizardInputCard,
} from "@difftone/actions";
import { validateQuestions } from "@difftone/validations";
import { SurveyClasses } from "@difftone/types";
import { getUserUuid } from "@difftone/procedures";
import { getSurveyFromStoreByURL } from "@difftone/reducers";
import {
    focusFirstInvalidQuestion,
    frontendValidations,
} from "@difftone/frontend-common/frontend-validations";

import duplicateQuestionIcon from "@difftone/assets/duplicate-question-floating-menu-icon.svg";
import deleteQuestionIcon from "@difftone/assets/delete-attribute-floating-menu-icon.svg";
import addQuestionIcon from "@difftone/assets/add-question-floating-menu-icon.svg";
import notRequiredIcon from "@difftone/assets/grayed-required-icon.svg";
import requiredIcon from "@difftone/assets/green-required-icon.svg";

import { mobile_questions_menu } from "./mobile-questions-menu.module.css";

export type MobileQuestionsMenuProps = {
    survey?: SurveyClasses;
};

export const MobileQuestionsMenu = (props: MobileQuestionsMenuProps) => {
    const { focusedWizardInputCard } = wizardStore;
    const survey = props.survey || getSurveyFromStoreByURL();

    const { surveysList } = surveyStore;
    const { validateSurvey } = frontendValidations;

    const surveyQuestionsUUIDs = survey.questions.map(
        (question) => question.uuid
    );
    const restOfSurveys = surveysList.filter(
        (_survey) =>
            _survey.initiator === getUserUuid() &&
            _survey.uuid !== survey.uuid &&
            _survey.status !== "DRAFT"
    );

    const onAddQuestionHandler = () => {
        const attributesAndQuestionsNameUniquenessValidations = validateSurvey(
            survey,
            restOfSurveys
        ).filter(
            (validationTResult) =>
                validationTResult.validation_error_code ===
                "QUESTION_NAME_DUPLICATION_VALIDATION"
        );

        const questionsValidations = validateQuestions(survey.questions);

        const allQuestionsValidations = [
            ...attributesAndQuestionsNameUniquenessValidations,
            ...questionsValidations,
        ];

        if (
            allQuestionsValidations.some((validation) => !validation.is_valid)
        ) {
            validateWizardSurvey();
            const invalidQuestion = allQuestionsValidations.find(
                (validation) => !validation.is_valid
            );
            if (!invalidQuestion) {
                return;
            }
            focusFirstInvalidQuestion(invalidQuestion, surveyQuestionsUUIDs);
            return;
        }

        addNewEmptyQuestionToWizard();
        nullifyWizardValidationError();

        setTimeout(() => {
            const allExistingQuestionElements: NodeListOf<HTMLDivElement> =
                document.querySelectorAll("div[data-wizard-question-card]");
            const lastAddedQuestion =
                allExistingQuestionElements[
                    allExistingQuestionElements.length - 1
                ];
            setFocusedWizardInputCard(lastAddedQuestion);
            debounceUpsertSurveyToApi();
        }, 0);
    };

    const onDuplicateQuestionHandler = () => {
        const attributesAndQuestionsNameUniquenessValidations = validateSurvey(
            survey,
            restOfSurveys
        ).filter(
            (validationTResult) =>
                validationTResult.validation_error_code ===
                "QUESTION_NAME_DUPLICATION_VALIDATION"
        );

        const questionsValidations = validateQuestions(survey.questions);

        const allQuestionsValidations = [
            ...attributesAndQuestionsNameUniquenessValidations,
            ...questionsValidations,
        ];

        if (
            allQuestionsValidations.some((validation) => !validation.is_valid)
        ) {
            validateWizardSurvey();
            const invalidQuestion = allQuestionsValidations.find(
                (validation) => !validation.is_valid
            );
            if (!invalidQuestion) {
                return;
            }
            focusFirstInvalidQuestion(invalidQuestion, surveyQuestionsUUIDs);
            return;
        }

        const questionNumber = focusedWizardInputCard?.getAttribute("index");
        //@ts-ignore
        const questionToCopy = survey?.questions![questionNumber];
        questionToCopy && duplicateQuestionInWizard(questionToCopy);

        setTimeout(() => {
            const allExistingQuestionElements: NodeListOf<HTMLDivElement> =
                document.querySelectorAll("div[data-wizard-question-card]");
            setFocusedWizardInputCard(
                allExistingQuestionElements[Number(questionNumber) + 1]
            );
            debounceUpsertSurveyToApi();
        }, 0);
    };

    const onDeleteQuestionHandler = () => {
        const questionNumber = parseInt(
            focusedWizardInputCard?.getAttribute("index")!
        );

        const questionToDelete = survey?.questions![questionNumber];
        questionToDelete && deleteQuestionFromWizard(questionToDelete);
        setTimeout(() => {
            const allExistingQuestionElements: NodeListOf<HTMLDivElement> =
                document.querySelectorAll("div[data-wizard-question-card]");
            if (questionNumber > 0) {
                const previousElement =
                    allExistingQuestionElements[questionNumber - 1];
                setFocusedWizardInputCard(previousElement);
            } else {
                setFocusedWizardInputCard(allExistingQuestionElements[0]);
            }
            debounceUpsertSurveyToApi();
        }, 0);
        validateWizardSurvey();
    };

    const onRequiredQuestionHandler = () => {
        //@ts-ignore
        const questionNumber = focusedWizardInputCard?.getAttribute("index");
        //@ts-ignore
        const questionToRequire = survey?.questions![questionNumber];
        questionToRequire && toggleWizardQuestionIsRequired(questionToRequire);
        setTimeout(() => {
            const allExistingQuestionElements: NodeListOf<HTMLDivElement> =
                document.querySelectorAll("div[data-wizard-question-card]");
            setFocusedWizardInputCard(
                allExistingQuestionElements[Number(questionNumber)]
            );
            debounceUpsertSurveyToApi();
        }, 0);
    };

    const requiredQuestion =
        //@ts-ignore
        survey?.questions[focusedWizardInputCard?.getAttribute("index")] &&
        //@ts-ignore
        survey?.questions[focusedWizardInputCard?.getAttribute("index")]
            .required;

    return (
        <div className={mobile_questions_menu}>
            <button onMouseDown={onAddQuestionHandler}>
                <img src={addQuestionIcon} alt="add-question" />
            </button>
            <button onMouseDown={onRequiredQuestionHandler}>
                <img
                    src={requiredQuestion ? requiredIcon : notRequiredIcon}
                    alt="not-required"
                />
            </button>
            <button onMouseDown={onDuplicateQuestionHandler}>
                <img src={duplicateQuestionIcon} alt="duplicate-question" />
            </button>
            <button onMouseDown={onDeleteQuestionHandler}>
                <img src={deleteQuestionIcon} alt="delete-question" />
            </button>
        </div>
    );
};
