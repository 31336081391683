import React, { useState } from "react";
import { observer } from "mobx-react";
import { Buffer } from "buffer";
import {
    DisplaySurvey,
    OngoingSurvey,
    SimpleSurvey,
    SurveyClasses,
} from "@difftone/types";
import { publicProfileStore } from "@difftone/stores";
import {
    HumanizeEmail,
    ProgressBar,
    SurveyStatus,
} from "@difftone/shared-components";
import {
    showDifftoneAlert,
    setLocation,
    filteredDisplaySurveyByActiveFilter,
    setWasSurveyResponseOnceSubmitted,
} from "@difftone/actions";
import { getUserEmail, getUserUuid } from "@difftone/procedures";

import {
    getInboxActiveFilterFromUrl,
    getStatus,
    getLastDateToFill,
} from "@difftone/reducers";
import { constantAlertVariants } from "@difftone/constants";

import { ETA, UserOptionsMenu, PermissionsStatus } from "./components";

import {
    survey_name,
    initiator_class,
    mobile_table_row,
    initiator_and_progress_bar,
    survey_name_and_menu_button,
    survey_status_and_survey_time_left,
    mobile_size_controller,
} from "./mobile-table-row.module.css";

export type MobileTableRowProps = {
    displaySurvey: DisplaySurvey;
};

export const MobileTableRow = observer((props: MobileTableRowProps) => {
    const { displaySurvey } = props;
    const { initiator } = displaySurvey.survey;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const surveyStatus = getStatus(displaySurvey.survey, displaySurvey.role);

    const survey = displaySurvey.survey as SurveyClasses;

    const initiatorPublicProfile =
        publicProfileStore.getPublicProfileByUuid(initiator);

    const initiatorEmailTemp = initiatorPublicProfile
        ? initiatorPublicProfile.email
        : "unknown";

    const openMenu = () => {
        if (initiator === getUserUuid()) {
            setIsMenuOpen(true);
        } else {
            showDifftoneAlert(
                constantAlertVariants.edit_survey_notification.text,
                constantAlertVariants.edit_survey_notification.variant
            );
        }
    };
    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const goToSurvey = (displaySurvey: DisplaySurvey) => {
        const entireParams = `${displaySurvey.survey.uuid}/${getUserEmail()}`;
        const base64Params = Buffer.from(entireParams).toString("base64");

        setWasSurveyResponseOnceSubmitted(false);
        setLocation(`/survey/${base64Params}`);
    };

    const responderClickHandler = (displaySurvey: DisplaySurvey) => {
        switch (displaySurvey.survey.survey_class) {
            case "ONGOING":
                const onGoingSurvey = displaySurvey.survey as OngoingSurvey;
                if (onGoingSurvey.manually_ended) {
                    return;
                }
                return goToSurvey(displaySurvey);

            case "SIMPLE":
            default:
                const lastDateToFill = getLastDateToFill(
                    displaySurvey.survey as SimpleSurvey
                );
                const canFillSurvey = lastDateToFill
                    ? lastDateToFill > Date.now()
                    : false;

                if (!canFillSurvey) {
                    return;
                }
                return goToSurvey(displaySurvey);
        }
    };

    const onResponderFill = (displaySurvey: DisplaySurvey) => {
        if (displaySurvey.role === "INITIATOR") {
            return;
        }

        responderClickHandler(displaySurvey);
    };

    const onTableRowClickedHandler = () => {
        switch (displaySurvey.role) {
            case "RESPONDER":
                onResponderFill(displaySurvey);
                return;
            case "INITIATOR":
                openMenu();
                return;
            default:
                return;
        }
    };

    const filteredDisplaySurvey = filteredDisplaySurveyByActiveFilter(
        displaySurvey,
        getInboxActiveFilterFromUrl()!
    );

    return (
        <>
            {filteredDisplaySurvey ? (
                <div
                    onClick={onTableRowClickedHandler}
                    tabIndex={0}
                    className={mobile_table_row}
                >
                    <div className={survey_name_and_menu_button}>
                        <PermissionsStatus
                            surveyStatus={surveyStatus}
                            survey={survey}
                        />
                        <div className={survey_name}>
                            {displaySurvey.survey.survey_name}
                        </div>
                    </div>
                    <div className={initiator_and_progress_bar}>
                        <div className={initiator_class}>
                            <HumanizeEmail humanizeEmail={initiatorEmailTemp} />
                        </div>
                        {displaySurvey.role === "RESPONDER" ? null : (
                            <div className={mobile_size_controller}>
                                <ProgressBar
                                    survey={displaySurvey.survey}
                                    surveyStatus={surveyStatus}
                                />
                            </div>
                        )}
                    </div>
                    <div className={survey_status_and_survey_time_left}>
                        <SurveyStatus surveyStatus={surveyStatus} />

                        {
                            //TODO: Add on going infinity icon to ongoing survey https://app.asana.com/0/1201346022025578/1202876528794837/f
                            // displaySurvey.role === "RESPONDER" ? null : (
                            //     <ETA survey={survey} surveyStatus={surveyStatus} />
                            // )
                        }
                    </div>
                    {isMenuOpen ? (
                        <UserOptionsMenu
                            survey={survey}
                            surveyStatus={surveyStatus}
                            closeMenu={closeMenu}
                        />
                    ) : null}
                </div>
            ) : null}
        </>
    );
});
