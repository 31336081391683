import { action } from "mobx";
import { ResultsFilter, BaseSurvey, SharedResult } from "@difftone/types";
import {
    getSharedResultsMapFromApi,
    getResultsMapFromApi,
} from "@difftone/services";
import { resultsMapStore, ResultsTable } from "@difftone/stores";
import { initTargetSharedViewFromUrl } from "@difftone/actions";
import { getUserEmail } from "@difftone/procedures";

export const setResultsMapSetter = action(
    (currentKey: string, resultsTable: ResultsTable | null) => {
        resultsMapStore.setResultsTableToMap(currentKey, resultsTable);
    }
);

//1
export const getResultsMapByRows = action(
    async (
        selectedSurvey: BaseSurvey,
        filtersNotActiveAsCompares: ResultsFilter[],
        filtersActiveAsCompares: ResultsFilter[],
        resultsTableMapKey: string,
        sharedView?: SharedResult
    ) => {
        const resultsTable = await getResultsMapByRowsFromApi(
            selectedSurvey,
            filtersNotActiveAsCompares,
            filtersActiveAsCompares,
            sharedView
        );

        //Prevent return empty objects
        //TODO: Fix in the BE
        if (JSON.stringify(resultsTable) === JSON.stringify({})) {
            setResultsMapSetter(resultsTableMapKey, null);
            return;
        }

        setResultsMapSetter(resultsTableMapKey, resultsTable);
    }
);

const resultsMapByFilterPlain = async (
    selectedSurvey: BaseSurvey,
    filtersNotActiveAsCompares: ResultsFilter[],
    sharedView?: SharedResult
) => {
    const resultsByFilterKey = resultsMapStore.extractKeyForColumn(
        selectedSurvey.uuid,
        filtersNotActiveAsCompares
    );

    if (resultsMapStore.resultsMaps[resultsByFilterKey] === undefined) {
        const resultsMap = await getResultsMapByFilterPlain(
            selectedSurvey,
            filtersNotActiveAsCompares,
            sharedView
        );
        setResultsMapSetter(resultsByFilterKey, resultsMap);
        return resultsMap;
    }
    return resultsMapStore.resultsMaps[resultsByFilterKey];
};

//2
const getResultsMapByRowsFromApi = action(
    async (
        selectedSurvey: BaseSurvey,
        filtersNotActiveAsCompares: ResultsFilter[],
        filtersActiveAsCompares: ResultsFilter[],
        sharedView?: SharedResult
    ) => {
        const tableAsRows: ResultsTable = {};

        const resultsMapTable = await getResultsMapTable(
            selectedSurvey,
            filtersNotActiveAsCompares,
            filtersActiveAsCompares,
            sharedView
        );

        if (resultsMapTable.includes(null)) {
            return null;
        }

        resultsMapTable.forEach((resultsMap, index) => {
            resultsMap &&
                Object.keys(resultsMap)?.forEach((questionUUID) => {
                    tableAsRows[questionUUID] = tableAsRows[questionUUID] || {};
                    const currentResultsMap = resultsMapTable[index];

                    tableAsRows[questionUUID][`Column_${index}`] =
                        currentResultsMap
                            ? currentResultsMap[questionUUID]
                            : null;
                });
        });
        return tableAsRows;
    }
);

export const getResultsMapByFilterPlain = async (
    survey: BaseSurvey,
    filtersNotActiveAsCompares: ResultsFilter[],
    sharedResultProp?: SharedResult
) => {
    const userEmail = getUserEmail();
    const currentKey = resultsMapStore.extractKeyForColumn(
        survey.uuid,
        filtersNotActiveAsCompares
    );
    let sharedResult = sharedResultProp;
    let resultsMapResponse;

    if (!sharedResult) {
        sharedResult = initTargetSharedViewFromUrl();
    }

    const isInitiator =
        sharedResult && userEmail === sharedResult.survey_initiator;

    if (sharedResult && !isInitiator) {
        resultsMapResponse = await getSharedResultsMapFromApi(
            sharedResult,
            filtersNotActiveAsCompares
        );
    }

    if ((!sharedResult && survey) || (isInitiator && survey)) {
        resultsMapResponse = await getResultsMapFromApi(
            survey,
            filtersNotActiveAsCompares
        );
    }

    setResultsMapSetter(currentKey, resultsMapResponse);
    return resultsMapResponse;
};

//3
const getResultsMapTable = async (
    selectedSurvey: BaseSurvey,
    filtersNotActiveAsCompares: ResultsFilter[],
    filtersActiveAsCompares: ResultsFilter[],
    sharedView?: SharedResult
) => {
    switch (filtersActiveAsCompares.length) {
        case 0:
            const resultsTable0 = await getResultsMapTableForNoCompareBy(
                selectedSurvey,
                filtersNotActiveAsCompares,
                sharedView
            );
            return [resultsTable0];
        case 1:
            const resultsTable1 = await getResultsMapTableForSingleCompareBy(
                selectedSurvey,
                filtersNotActiveAsCompares,
                filtersActiveAsCompares,
                sharedView
            );
            return resultsTable1;
        case 2:
            const resultsTable2 = await getResultsMapTableForDoubleCompareBy(
                selectedSurvey,
                filtersNotActiveAsCompares,
                filtersActiveAsCompares,
                sharedView
            );
            return resultsTable2;
        default:
            return [];
    }
};

const getResultsMapTableForNoCompareBy = async (
    selectedSurvey: BaseSurvey,
    filtersNotActiveAsCompares: ResultsFilter[],
    sharedView?: SharedResult
) => {
    const resultsMap = await resultsMapByFilterPlain(
        selectedSurvey,
        filtersNotActiveAsCompares,
        sharedView
    );
    return resultsMap;
};

const getResultsMapTableForSingleCompareBy = async (
    selectedSurvey: BaseSurvey,
    filtersNotActiveAsCompares: ResultsFilter[],
    filtersActiveAsCompares: ResultsFilter[],
    sharedView?: SharedResult
) => {
    const overallResultsMap = resultsMapByFilterPlain(
        selectedSurvey,
        //TODO: Test if the filters in the current order
        [...filtersActiveAsCompares, ...filtersNotActiveAsCompares],
        sharedView
    );

    const comparedByResultsMaps = filtersActiveAsCompares[0]?.filterValues.map(
        (val1) => {
            const cloneOfFirstComparedBy: ResultsFilter = JSON.parse(
                JSON.stringify(filtersActiveAsCompares[0])
            );

            cloneOfFirstComparedBy.filterValues = [val1];
            return resultsMapByFilterPlain(
                selectedSurvey,
                [...filtersNotActiveAsCompares, cloneOfFirstComparedBy],
                sharedView
            );
        }
    );

    return Promise.all([overallResultsMap, ...comparedByResultsMaps]);
};

const getResultsMapTableForDoubleCompareBy = async (
    selectedSurvey: BaseSurvey,
    filtersNotActiveAsCompares: ResultsFilter[],
    filtersActiveAsCompares: ResultsFilter[],
    sharedView?: SharedResult
) => {
    const overallResultsMap = resultsMapByFilterPlain(
        selectedSurvey,
        [...filtersActiveAsCompares, ...filtersNotActiveAsCompares],
        sharedView
    );
    const comparedByResultsMaps =
        filtersActiveAsCompares[0]?.filterValues.flatMap((val1) => {
            return filtersActiveAsCompares[1]?.filterValues.map((val2) => {
                const cloneOfFirstComparedBy: ResultsFilter = JSON.parse(
                    JSON.stringify(filtersActiveAsCompares[0])
                );
                const cloneOfSecondComparedBy: ResultsFilter = JSON.parse(
                    JSON.stringify(filtersActiveAsCompares[1])
                );
                cloneOfFirstComparedBy.filterValues = [val1];
                cloneOfSecondComparedBy.filterValues = [val2];

                const compareByFilters = [
                    cloneOfFirstComparedBy,
                    cloneOfSecondComparedBy,
                ];
                return resultsMapByFilterPlain(
                    selectedSurvey,
                    [...filtersNotActiveAsCompares, ...compareByFilters],
                    sharedView
                );
            });
        });

    const comparedByResultsMapOrEmpty = comparedByResultsMaps
        ? comparedByResultsMaps.concat()
        : [];

    return Promise.all([overallResultsMap, ...comparedByResultsMapOrEmpty]);
};
