import { BaseSurvey } from "@difftone/types";

export const BASE_SURVEY_TEMPLATE: Partial<BaseSurvey> = {
    version: "0.0.5",
    initiator: "",
    survey_name: "",
    title: "",
    default_survey_name: "",
    welcome_message: "",
    deleted: false,
    archived: false,
    admins: [],
    purpose: "ENGAGEMENT",
    anonimity: "NONE",
    survey_class: "ONGOING",
    responders_permission: "VIEW",
    response_duration: 0,
    tags: [],
    participant_introduction: "",
    from_template: null,
    is_open_survey: false,
};
