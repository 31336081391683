import { makeAutoObservable, observable, ObservableMap } from "mobx";
import { UUID, ResultsFilter, ResultsMap } from "@difftone/types";
import { getSharedResultsResultsMapFromApi } from "./internal-actions";

type SharedResultsResultsMapKeyObject = {
    sharedSurveyUid: UUID;
    resultsFilters: ResultsFilter[];
};

class SharedResultsResultsMapStore {
    constructor() {
        makeAutoObservable<
            SharedResultsResultsMapStore,
            "_fetchedSharedResultsMapUuids"
        >(this, {
            _fetchedSharedResultsMapUuids: false,
        });
    }

    private _sharedResultsResultsMap: ObservableMap<
        string,
        ResultsMap | undefined | null
    > = observable.map({});

    private _fetchedSharedResultsMapUuids: UUID[] = [];

    public extractKey = (
        sharedSurveyUid: UUID,
        resultsFilters: ResultsFilter[]
    ) => {
        const _keyObject: SharedResultsResultsMapKeyObject = {
            sharedSurveyUid,
            resultsFilters,
        };

        return JSON.stringify(_keyObject);
    };

    public getSharedResultsResultsMap = (key: string) => {
        const _selectedShredResultsResultsMap =
            this._sharedResultsResultsMap.get(key);

        if (
            _selectedShredResultsResultsMap === undefined &&
            !this._fetchedSharedResultsMapUuids.includes(key)
        ) {
            this._fetchedSharedResultsMapUuids.push(key);

            const _keyObject: SharedResultsResultsMapKeyObject =
                JSON.parse(key);

            getSharedResultsResultsMapFromApi(
                _keyObject.sharedSurveyUid,
                _keyObject.resultsFilters
            );
        }

        return _selectedShredResultsResultsMap;
    };

    public setSharedResultsToMap = (
        key: string,
        sharedResultsMap: ResultsMap
    ) => {
        this._sharedResultsResultsMap.merge({ [key]: sharedResultsMap });
    };

    public clearStore = () => {
        this._fetchedSharedResultsMapUuids = [];
        this._sharedResultsResultsMap = observable.map({});
    };
}

export const sharedResultsResultsMapStore = new SharedResultsResultsMapStore();
