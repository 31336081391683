import React, { useState } from "react";
import questionMarkIcon from "@difftone/assets/header-question-mark-icon.svg";
import { ConatcInfoDialog, AddBoxShadow } from "@difftone/shared-components";

import { InfoAndLegalDialog } from "./components";

import { difftone_info_and_leagl } from "./difftone-info-and-legal.module.css";

export type DifftoneInfoAndLegalProps = {};

export const DifftoneInfoAndLegal = () => {
    //[useState]:: show/hide dialog when user clicks icon or unfocuses the element
    const [showDialog, setShowDialog] = useState(false);
    //[useState]:: instead of url manupilation on "/info", using state
    const [showContactInfo, setShowContactInfo] = useState(false);

    const openDialogHandler = () => {
        setShowDialog(true);
    };

    const closeDialogHandler = () => {
        setShowDialog(false);
    };

    const difftoneInfoAndLegalClickHandler = () => {
        showDialog ? closeDialogHandler() : openDialogHandler();
    };

    const openContactInfoHandler = () => {
        setShowContactInfo(true);
    };

    const closeContactInfoHandler = () => {
        setShowContactInfo(false);
    };

    return (
        <>
            <div
                onBlur={closeDialogHandler}
                tabIndex={0}
                className={difftone_info_and_leagl}
            >
                <img
                    onClick={difftoneInfoAndLegalClickHandler}
                    src={questionMarkIcon}
                    alt="difftone-info"
                />
                {showDialog ? (
                    <AddBoxShadow>
                        <InfoAndLegalDialog
                            className=""
                            openContactInfo={openContactInfoHandler}
                            closeDialog={closeDialogHandler}
                        />
                    </AddBoxShadow>
                ) : null}
            </div>
            {showContactInfo ? (
                <ConatcInfoDialog close={closeContactInfoHandler} />
            ) : null}
        </>
    );
};
