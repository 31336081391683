import React, { useMemo, FC, useState, PropsWithChildren } from "react";
import { observer } from "mobx-react";
import { v4 as uuid } from "uuid";

import {
    Dialog,
    DialogProps,
    DifftoneButton,
    Spinner,
    SharedWith,
} from "@difftone/shared-components";

import {
    resultsDataStore,
    navigationStore,
    publicProfileStore,
    resultsDisplayStore,
    userProfileStore,
    difftoneAlertStore,
    timeRangesStore,
    resultsDisplayFilterStore,
} from "@difftone/stores";
import {
    getSelectedOrganizationOrThrow,
    getSurveyFromStoreByUUID,
    getTimeUnitFromUrlOrNone,
    isEmailFromOrganisation,
} from "@difftone/reducers";
import { getUserEmail, getUserUuid } from "@difftone/procedures";
import {
    removeEmailFromSharedResult,
    createShareResults,
    setShareResultName,
    clearEmailToSharedResultList,
    addAdminToSurvey,
    showDifftoneAlert,
    addMultipleEmailsToSharedResult,
    scheduleShareResultsEmail,
    addTimeCompareFilterToShareResultsBySurveyClass,
} from "@difftone/actions";
import {
    getDynamicAlertVariantForOrganizationEmailValidation,
    warningMessages,
} from "@difftone/constants";

import {
    EmailAddress,
    SharedResult,
    SurveyClasses,
    UUID,
} from "@difftone/types";

import {
    share_result_dialog_actions,
    share_result_dialog_button,
} from "./share-result-dialog.module.css";

export type ShareResultDialogProps = Omit<DialogProps, "title">;

export const ShareResultDialog: FC<PropsWithChildren<ShareResultDialogProps>> =
    observer(({ onClose, open, ...props }: ShareResultDialogProps) => {
        const { currentPage } = navigationStore;
        const userEmail = getUserEmail();
        const { shareResultEmailList } = resultsDataStore;
        const { shareResultsName } = resultsDisplayStore;
        const { compareBy } = resultsDisplayFilterStore;
        const { extractKeyForTimeRange, getTimeRangeByKey } = timeRangesStore;

        const selectedSurvey = useMemo<SurveyClasses>(() => {
            const surveyUuid = currentPage.split("/")[2];

            return getSurveyFromStoreByUUID(surveyUuid) as SurveyClasses;
        }, [currentPage]);

        const [message, setMessage] = useState<string>("");

        const timeRangeKey = extractKeyForTimeRange(
            selectedSurvey.uuid,
            getTimeUnitFromUrlOrNone()
        );

        const timeRanges = getTimeRangeByKey(timeRangeKey);

        if (!onClose) {
            throw new Error("onClose is not defined");
        }

        if (timeRanges === undefined) {
            return (
                <div>
                    <Spinner size="X-SMALL" />
                </div>
            );
        }

        if (timeRanges === null) {
            showDifftoneAlert(
                "Something went wrong, please reload and try again",
                "FAILURE"
            );
            return null;
        }

        const onEmailsEnter = (emails: EmailAddress[]) => {
            const organizationEmails: EmailAddress[] = [];
            const notOrganizationEmails: EmailAddress[] = [];

            emails.forEach((email: EmailAddress) => {
                if (!isEmailFromOrganisation(email)) {
                    notOrganizationEmails.push(email);
                    return;
                }
                organizationEmails.push(email);
            });

            if (notOrganizationEmails.length) {
                const organization = getSelectedOrganizationOrThrow();
                const organizationName = organization ? organization.name : "";

                showDifftoneAlert(
                    getDynamicAlertVariantForOrganizationEmailValidation(
                        organizationName,
                        notOrganizationEmails
                    ),
                    "FAILURE"
                );
            }

            let shareMailingList = [
                ...shareResultEmailList!,
                ...organizationEmails,
            ];

            if (shareMailingList.includes(userEmail)) {
                showDifftoneAlert(
                    warningMessages.surveyCannotShareInitiator,
                    "FAILURE"
                );
                shareMailingList = shareMailingList.filter(
                    (email: EmailAddress) => email !== userEmail
                );
            }

            addMultipleEmailsToSharedResult(shareMailingList);
        };

        const onEmailDelete = (email: EmailAddress) => {
            removeEmailFromSharedResult(email);
        };

        const onMessageChange = (message: string) => {
            setMessage(message);
        };

        const handleCleanup = () => {
            onClose();

            setTimeout(() => {
                setShareResultName("");
                setMessage("");
                clearEmailToSharedResultList();
            }, 0);
        };

        const handleSubmit = async () => {
            if (!shareResultEmailList.length) {
                showDifftoneAlert(
                    warningMessages.surveyCannotShareWithoutAdding,
                    "FAILURE"
                );
                return;
            }

            const initiator = publicProfileStore.getPublicProfileByUuid(
                selectedSurvey.initiator
            );

            handleCleanup();

            const comparesUUIDsPartOfCompare = compareBy.reduce(
                (compareUUID: UUID[], filter) => {
                    if (filter.isPartOfComparedPlain) {
                        compareUUID.push(filter.filterKey.uuid);
                    }
                    return compareUUID;
                },
                []
            );

            const sharedResult: SharedResult = {
                uuid: uuid(),
                survey_uuid: selectedSurvey.uuid,
                share_with: shareResultEmailList,
                filters: compareBy,
                compare_by: comparesUUIDsPartOfCompare,
                share_name: shareResultsName,
                sharer_uuid: getUserUuid(),
                sharer_email: getUserEmail(),
                survey_name: selectedSurvey.survey_name,
                created_at: Date.now(),
                survey_initiator: initiator ? initiator.email : "",
                is_deleted: false,
                message,
            };

            try {
                await createShareResults(sharedResult);
                await addAdminToSurvey(selectedSurvey, shareResultEmailList);

                await userProfileStore.addBookmark({
                    name: shareResultsName,
                    url: `${window.location.pathname}${window.location.search}`,
                    uuid: uuid(),
                });

                scheduleShareResultsEmail(sharedResult);

                showDifftoneAlert(
                    `${shareResultsName} has been shared successfully!`,
                    "SUCCESS"
                );
            } catch (e) {
                console.error(e);

                showDifftoneAlert(
                    `Hi! The person you are trying to share this file with is not a registered user`,
                    "FAILURE"
                );
            }
        };

        return (
            <Dialog
                isOutSideClose={false}
                isVisible={difftoneAlertStore.isVisible}
                title={`Share ${shareResultsName}`}
                onClose={handleCleanup}
                fullScreen={false}
                open={open}
                {...props}
            >
                <SharedWith
                    addressees={shareResultEmailList}
                    handleMultipleAddresseesChange={onEmailsEnter}
                    handleRespondentsDelete={onEmailDelete}
                    handleMessageChange={onMessageChange}
                />
                <div className={share_result_dialog_actions}>
                    <DifftoneButton
                        classNameOverride={share_result_dialog_button}
                        buttonText="Send"
                        onClick={handleSubmit}
                    />
                </div>
            </Dialog>
        );
    });
