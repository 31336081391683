import React from "react";
import { observer } from "mobx-react";
import { leftMenuStore } from "@difftone/stores";
import { toggleLeftMenu, updateFirstTimeWindowFlip } from "@difftone/actions";
import {
    MOBILE_WIDTH_BREAKPOINT,
    TEMP_MIDDLE_MOBILE_BREAKPOINT,
} from "@difftone/constants";

import { hamburger } from "./hamburger.module.css";

export const Hamburger = observer(() => {
    const { isOpen, firstTimeWindowFlip } = leftMenuStore;

    const windowMobileWidth = window.innerWidth;

    if (
        windowMobileWidth >= MOBILE_WIDTH_BREAKPOINT &&
        windowMobileWidth <= TEMP_MIDDLE_MOBILE_BREAKPOINT
    ) {
        if (isOpen && firstTimeWindowFlip) {
            updateFirstTimeWindowFlip();
            toggleLeftMenu();
        }
    }

    return (
        <div className={hamburger} onClick={toggleLeftMenu}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
});
