import React from "react";
import { SimpleSurvey } from "@difftone/types";
import { UiStatus } from "@difftone/frontend-common";

import { getLastDateToFill, getPublishedDate } from "@difftone/reducers";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";

import { EndedSurveyIcon } from "./components";

import { eta_box, value_display_box } from "./eta.module.css";

dayjs.extend(duration);
dayjs.extend(utc);

export type ETAProps = {
    survey: SimpleSurvey;
    surveyStatus: UiStatus;
};

export const ETA = (props: ETAProps) => {
    const { survey, surveyStatus } = props;

    // data extraction helpers
    const published_date = getPublishedDate(survey);
    const last_date_to_fill = getLastDateToFill(survey);
    const nowInMiliseconds = dayjs.utc(new Date().getTime()).valueOf();
    const readableETA = dayjs
        .duration(last_date_to_fill! - nowInMiliseconds)
        .format("YYYY MM DD HH mm ss");

    const extractRemainingTime = (etaDate: string) => {
        const etaAsArray = etaDate.split(" ");
        const years = Number(etaAsArray[0]);
        const months = Number(etaAsArray[1]);
        const days = Number(etaAsArray[2]);
        const hours = Number(etaAsArray[3]);
        const minutes = Number(etaAsArray[4]);
        const seconds = Number(etaAsArray[5]);
        if (years) return `${years} years`;
        if (months) return `${months}months`;
        if (days) return `${days} days+`;
        if (hours) return `${hours} hours`;
        if (minutes) return `${minutes} min`;
        if (seconds) return `${seconds} sec`;
    };

    return (
        <div title={readableETA} className={eta_box}>
            {last_date_to_fill &&
            published_date &&
            surveyStatus !== "DRAFT" &&
            surveyStatus !== "ENDED" &&
            surveyStatus !== "COMPLETED" ? (
                <div className={value_display_box}>
                    Time to end: {extractRemainingTime(readableETA)}
                </div>
            ) : (
                <EndedSurveyIcon surveyStatus={surveyStatus} />
            )}
        </div>
    );
};
