import { v4 as uuid } from "uuid";

import { getCurrentTimeInBEFormat } from "@difftone/frontend-common";
import {
    Label,
    Question,
    QuestionToAnswerDescreteQuestion,
    QuestionType,
    UUID,
} from "@difftone/types";
import { categoriesStore, ResultsTable } from "@difftone/stores";
import { getAvgForRatingTypeColumn } from "@difftone/actions";
import { NO_ANSWER_KEY, DATA_ANONYMIZED } from "@difftone/common-constants";
import { UUID_DETECTING_REGEX } from "@difftone/constants";

export type CategoriesResultsRatingMap = Record<
    UUID,
    Record<string, number | typeof DATA_ANONYMIZED>
>;

export type CategoriesQuestionsMap = Record<
    UUID,
    {
        categoryName: string;
        questions: Question<QuestionType>[];
    }
>;

export const extractDisplayNameFromCategory = (
    category: string | UUID,
    allCategories: Label[]
): string => {
    const existingCategory = allCategories.find(
        (_category) => _category.uuid === category
    );

    return existingCategory ? existingCategory.display_name : category;
};

export const formatCategoryObject = (
    organisationUUID: UUID | null,
    userUUID: UUID,
    displayName: string
): Label => ({
    display_name: displayName,
    uuid: uuid(),
    scope_id: organisationUUID || userUUID,
    scope_type: organisationUUID ? "ORGANISATION" : "PRIVATE",
    is_deleted: false,
    archive: false,
    created_at: getCurrentTimeInBEFormat(),
});

export const getSortedCategoriesList = (
    categoriesQuestionsMap: CategoriesQuestionsMap
): UUID[] => {
    const categoriesList: UUID[] = Object.keys(categoriesQuestionsMap);

    const categoriesWithRatingQuestionsOnly: UUID[] = [];
    const categoriesWithMixedQuestions: UUID[] = [];
    const categoriesWithoutRatingQuestions: UUID[] = [];

    categoriesList.forEach((categoryUUID) => {
        const { questions } = categoriesQuestionsMap[categoryUUID];

        const ratingQuestions = questions.filter(
            ({ question_type_name }) => question_type_name === "RATING"
        );

        const hasOnlyRatingQuestions = questions.every(
            ({ question_type_name }) => question_type_name === "RATING"
        );

        if (!ratingQuestions.length) {
            categoriesWithoutRatingQuestions.push(categoryUUID);
            return;
        }

        if (ratingQuestions.length && hasOnlyRatingQuestions) {
            categoriesWithRatingQuestionsOnly.push(categoryUUID);
            return;
        }

        if (ratingQuestions.length && !hasOnlyRatingQuestions) {
            categoriesWithMixedQuestions.push(categoryUUID);
            return;
        }
    });

    return [
        ...categoriesWithRatingQuestionsOnly,
        ...categoriesWithMixedQuestions,
        ...categoriesWithoutRatingQuestions,
    ];
};

export const formatCategoriesQuestionsMap = (
    questions: Question<QuestionType>[]
): CategoriesQuestionsMap => {
    const categoriesQuestionsMap: CategoriesQuestionsMap = {};
    const allCategories = categoriesStore.getCategoriesArray();

    questions.forEach((question) => {
        question.categories.forEach((categoryUUID) => {
            const categoryName = extractDisplayNameFromCategory(
                categoryUUID,
                allCategories
            );

            if (categoriesQuestionsMap.hasOwnProperty(categoryUUID)) {
                categoriesQuestionsMap[categoryUUID].questions.push(question);
            } else {
                const regex = new RegExp(UUID_DETECTING_REGEX);

                if (!regex.test(categoryName)) {
                    categoriesQuestionsMap[categoryUUID] = {
                        categoryName,
                        questions: [question],
                    };
                }
            }
        });
    });

    return categoriesQuestionsMap;
};

export const formatCategoriesResultsRatingMap = (
    categoriesQuestionMap: CategoriesQuestionsMap,
    resultsTable: ResultsTable
): CategoriesResultsRatingMap => {
    const copyResultsRow: ResultsTable = JSON.parse(
        JSON.stringify(resultsTable)
    );

    const categoriesResultsMap: CategoriesResultsRatingMap = {};
    const categoriesList: UUID[] = Object.keys(categoriesQuestionMap);

    categoriesList.forEach((categoryUUID) => {
        const { questions } = categoriesQuestionMap[categoryUUID];
        const ratingQuestions = questions.filter(
            ({ question_type_name }) => question_type_name === "RATING"
        );

        ratingQuestions.forEach((question) => {
            const questionResults = copyResultsRow[question.uuid];
            const resultsColumns = Object.keys(questionResults);
            categoriesResultsMap[categoryUUID] = {};

            resultsColumns.forEach((columnKey) => {
                if (questionResults[columnKey] === DATA_ANONYMIZED) {
                    categoriesResultsMap[categoryUUID][columnKey] =
                        DATA_ANONYMIZED;

                    return categoriesResultsMap;
                }

                let average = 0;

                ratingQuestions.forEach((ratingQuestion) => {
                    const innerQuestionResult =
                        copyResultsRow[ratingQuestion.uuid];
                    const results = innerQuestionResult[
                        columnKey
                    ] as QuestionToAnswerDescreteQuestion;
                    delete results[NO_ANSWER_KEY];

                    if (results) {
                        average += getAvgForRatingTypeColumn(results);
                    }
                });

                if (
                    categoriesResultsMap[categoryUUID][columnKey] ===
                    DATA_ANONYMIZED
                ) {
                    return;
                }

                categoriesResultsMap[categoryUUID][columnKey] = +Number(
                    average / ratingQuestions.length
                ).toFixed(1);
            });
        });
    });

    return categoriesResultsMap;
};

export const surveyCategoriesLength = (questions: Question<QuestionType>[]) => {
    const categoriesQuestionsMap = formatCategoriesQuestionsMap(questions);

    return Object.keys(categoriesQuestionsMap).length;
};
