import type { PublicProfile, User } from "@difftone/types";
import { getApiBaseUrl } from "@difftone/constants";
import { errorHandlerProxy, localstorageUtils } from "@difftone/procedures";
import { createDifftoneAxios } from "@difftone/services";
import { showServerErrorAlert } from "@difftone/actions";

const SERVER_BASE_URL = `${getApiBaseUrl()}/users-service`;

export const getPublicProfileFromApiByUserUuid = async (
    publicProfileUserUuid: string
): Promise<PublicProfile> => {
    const uuidsArray: string[] = [publicProfileUserUuid];
    const publicProfileResponse = await getPublicProfileFromApiByUserUuids(
        uuidsArray
    );

    return publicProfileResponse[0];
};

export const getPublicProfileFromApiByUserUuidsArray = async (
    publicProfileUserUuid: string[]
): Promise<PublicProfile[]> => {
    return await getPublicProfileFromApiByUserUuids(publicProfileUserUuid);
};

export const getUserFromApiByUserAuthProviderUuid = async (
    AuthProviderUuid: string
): Promise<User> => {
    try {
        const currentAuthData =
            await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

        const difftoneAxios = createDifftoneAxios(currentAuthData);

        const response = await difftoneAxios.get(
            `${SERVER_BASE_URL}/user/auth_provider_uuid`,
            {
                params: {
                    value: AuthProviderUuid,
                    issuer: currentAuthData.issuer,
                },
            }
        );

        if (response.data.error) {
            errorHandlerProxy({
                error: response.data.error,
                code: response.data.statusCode,
            });
        }
        const resource = response.data.resources[0];
        return resource;
    } catch (error) {
        throw new Error(`server call failed: ${(error as Error).message}`);
    }
};

export const addUserToApi = async (newUser: User): Promise<User> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.patch(
        `${SERVER_BASE_URL}`,
        {
            new_user: newUser,
        },
        {
            params: {
                issuer: currentAuthData.issuer,
            },
        }
    );

    if (response.data.error) {
        showServerErrorAlert();

        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.result;
    return resource;
};

export const getPublicProfileFromApiByUserUuids = async (
    publicProfileUserUuids: string[]
): Promise<PublicProfile[]> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(
        `${SERVER_BASE_URL}/public-profile/uuid`,
        {
            params: {
                value: JSON.stringify(publicProfileUserUuids),
                issuer: currentAuthData.issuer,
            },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.resources;
    return resource;
};
