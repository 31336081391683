import React from "react";
import { Domain } from "@difftone/types";
import deleteIcon from "@difftone/assets/delete-survey.svg";
import {
    list_domains,
    row_domain,
    remove_domain,
} from "./list-domains.module.css";
import { upsertDomainForDifftone } from "@difftone/features/organizations-domains/services";

export type ListDomainsProps = {
    domains: Domain[];
    setDomains: (domains: Domain[]) => void;
    onRemoveDomainFromAllOrganization: (domain: Domain) => void;
};

export const ListDomains = (props: ListDomainsProps) => {
    const { domains, setDomains, onRemoveDomainFromAllOrganization } = props;
    const _activeDomains = domains.filter(
        (domain) => domain.is_deleted === undefined || !domain.is_deleted
    );

    const onRemoveDomain = (domain: Domain) => {
        domain.is_deleted = true;
        upsertDomainForDifftone(domain);
        onRemoveDomainFromAllOrganization(domain);
        const updatedDomains = domains.filter(
            (domain) => domain.is_deleted === undefined || !domain.is_deleted
        );
        setDomains(updatedDomains);
    };

    return (
        <div>
            <h4>Domains list </h4>
            <div className={list_domains}>
                {_activeDomains.map((domain) => (
                    <div className={row_domain} key={domain.uuid}>
                        <div>{domain.name}</div>
                        <img
                            onClick={() => onRemoveDomain(domain)}
                            className={remove_domain}
                            src={deleteIcon}
                            alt="delete-icon"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
