import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import clsx from "clsx";
import {
    group,
    scalePoint,
    axisLeft,
    scaleOrdinal,
    scaleLinear,
    extent,
    select,
    axisBottom,
    schemeSet3,
} from "d3";

import {
    OptionalPropertiesForGraph,
    GraphLineItem,
    SelectedLegendMap,
    LegendMap,
} from "@difftone/types";
import expandIcon from "@difftone/assets/expand.svg";
import closeIcon from "@difftone/assets/end-ongoing-survey-icon.svg";
import { Circle, TrendLine, Legend } from "./components";
import {
    tooltip_multi_line,
    multi_line_scope,
    multi_line_graph_scope,
    multi_line_expand_graph_scope,
    expand_graph,
    expand_icon,
    close_icon,
    circle_scope,
    line_scope,
    tooltip_scope,
    tooltip_text_scope,
} from "./graph.module.css";

export type GraphProps = {
    dataGraph: GraphLineItem[];
    sizeAxisLeft: number;
};

const MARGIN = {
    TOP: 10,
    RIGHT: 30,
    BOOTOM: 30,
    LEFT: 35,
};

const WIDTH = 900;
const HEIGHT = 350;

const optionalPropertiesForGraph: OptionalPropertiesForGraph = {
    isExpand: false,
    showSelectedDots: true,
    showSelectedTrendLines: true,
    selectAll: true,
    legendMap: {},
    selectedLegendMap: {},
};

export const Graph = observer((props: GraphProps) => {
    const { dataGraph, sizeAxisLeft } = props;

    const width = WIDTH - MARGIN.LEFT - MARGIN.RIGHT,
        height = HEIGHT - MARGIN.TOP - MARGIN.BOOTOM;

    const svgWidth = width + MARGIN.LEFT + MARGIN.RIGHT;
    const svgHeight = height + MARGIN.TOP + MARGIN.BOOTOM;

    const createDataGroups = () => {
        return dataGraph.reduce(
            (groupKey: { [key: string]: GraphLineItem[] }, item) => {
                if (!groupKey[item.legend]) groupKey[item.legend] = [];
                groupKey[item.legend].push(item);
                return groupKey;
            },
            {}
        );
    };

    const dataGroups = createDataGroups();

    const dataGroupsKeys = Object.keys(dataGroups);

    const [generalDataForGraph, setGeneralDataForGraph] = useState(
        optionalPropertiesForGraph
    );

    const initLegendMap = () => {
        const _legendMap: LegendMap = {};

        const _selectedLegendMap: SelectedLegendMap = {};

        dataGroupsKeys.forEach((text) => {
            _legendMap[text] = true;
            _selectedLegendMap[text] = {
                circle: true,
                line: true,
            };
        });

        setGeneralDataForGraph({
            ...generalDataForGraph,
            legendMap: _legendMap,
            selectedLegendMap: _selectedLegendMap,
        });
    };

    useEffect(() => {
        initLegendMap();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGraph]);

    const xScale = scalePoint()
        .domain(dataGraph.map((d) => d.axisBottom))
        .range([0, width]);

    const xTrendLine = scaleLinear()
        .domain(
            extent(dataGraph, (e: GraphLineItem): number => e.xTrendLine) as [
                number,
                number
            ]
        )
        .range([0, width]);

    const yScale = scaleLinear().domain([0, sizeAxisLeft]).range([height, 0]);

    const _axisBottom: any = select("#axis-bottom");
    _axisBottom
        .call(axisBottom(xScale))
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)");

    const _axisLeft: any = select("#axis-left");
    _axisLeft.call(axisLeft(yScale).ticks(5));

    const tooltip = select(`div[data-multi-line-tooltip]`);

    const colors: any = scaleOrdinal()
        .domain(Object.keys(dataGroups))
        .range(schemeSet3);

    const onHideTooltip = () => {
        tooltip
            .interrupt()
            .transition()
            .style("opacity", 0)
            .style("display", "none");
    };

    const onTooltipTemplate = (item: GraphLineItem, index: number) => {
        return `<div class=${tooltip_scope}>
                   <div class=${tooltip_text_scope}>
                     <div style="background:${colors(
                         index
                     )}" class=${circle_scope}></div>
                     <div>${item.legend}</div>
                   </div>
                   <div>${item.axisLeft}</div> 
                </div>`;
    };

    const onTooltipTemplateForLine = (legend: string, index: number) => {
        return `<div class=${tooltip_scope}>
                   <div class=${tooltip_text_scope}>
                     <div style="background:${colors(
                         index
                     )}" class=${line_scope}></div>
                   <div>${legend}</div>
                   </div>
                </div>`;
    };

    const onShowTooltip = (
        event: React.MouseEvent<SVGPathElement, MouseEvent>,
        item: GraphLineItem | string,
        index: number
    ) => {
        tooltip
            .transition()
            .duration(200)
            .style("opacity", 1)
            .style("display", "block");
        tooltip
            .html(
                typeof item === "string"
                    ? onTooltipTemplateForLine(item, index)
                    : onTooltipTemplate(item, index)
            )
            .style("left", `${event.pageX + 10}px`)
            .style("top", `${event.pageY - 12}px`);
    };

    const onToggleExpand = () => {
        setGeneralDataForGraph({
            ...generalDataForGraph,
            isExpand: !generalDataForGraph.isExpand,
        });
    };

    const onUpdateLegendMap = (legend: string) => {
        const _legendMap: LegendMap = generalDataForGraph.legendMap;

        const isSelected = !generalDataForGraph.legendMap[legend];
        _legendMap[legend] = isSelected;

        const _selectedLegendMap: SelectedLegendMap =
            generalDataForGraph.selectedLegendMap;

        _selectedLegendMap[legend] = {
            circle: isSelected,
            line: isSelected,
        };

        if (isSelected) {
            dataGroupsKeys.forEach((text) => {
                if (_legendMap[text])
                    _selectedLegendMap[text] = {
                        circle: isSelected,
                        line: isSelected,
                    };
            });

            setGeneralDataForGraph({
                ...generalDataForGraph,
                legendMap: _legendMap,
                selectedLegendMap: _selectedLegendMap,
                showSelectedDots: isSelected,
                showSelectedTrendLines: isSelected,
            });
            return;
        }

        setGeneralDataForGraph({
            ...generalDataForGraph,
            legendMap: _legendMap,
            selectedLegendMap: _selectedLegendMap,
        });
    };

    const onSelectAll = (selectAll: boolean) => {
        const _legendMap: LegendMap = {};

        const _selectedLegendMap: SelectedLegendMap = {};

        dataGroupsKeys.forEach((key) => {
            _legendMap[key] = selectAll;
            _selectedLegendMap[key] = {
                circle: selectAll,
                line: selectAll,
            };
        });
        setGeneralDataForGraph({
            ...generalDataForGraph,
            legendMap: _legendMap,
            selectedLegendMap: _selectedLegendMap,
            selectAll: selectAll,
            showSelectedDots: true,
            showSelectedTrendLines: true,
        });
    };

    const onShowSelectedDots = (showSelectedDots: boolean) => {
        const _selectedLegendMap = generalDataForGraph.selectedLegendMap;
        dataGroupsKeys.forEach((key) => {
            if (generalDataForGraph.legendMap[key])
                _selectedLegendMap[key] = {
                    ..._selectedLegendMap[key],
                    circle: showSelectedDots,
                };
        });

        setGeneralDataForGraph({
            ...generalDataForGraph,
            selectedLegendMap: _selectedLegendMap,
            showSelectedDots: showSelectedDots,
        });
    };

    const onShowSelectedTrendLines = (showSelectedTrendLines: boolean) => {
        const _selectedLegendMap = generalDataForGraph.selectedLegendMap;
        dataGroupsKeys.forEach((text) => {
            if (generalDataForGraph.legendMap[text])
                _selectedLegendMap[text] = {
                    ..._selectedLegendMap[text],
                    line: showSelectedTrendLines,
                };
        });

        setGeneralDataForGraph({
            ...generalDataForGraph,
            selectedLegendMap: _selectedLegendMap,
            showSelectedTrendLines: showSelectedTrendLines,
        });
    };

    return (
        <div
            className={clsx(
                multi_line_scope,
                generalDataForGraph.isExpand && expand_graph
            )}
        >
            <div data-multi-line-tooltip className={tooltip_multi_line}></div>
            <div
                className={clsx(
                    multi_line_graph_scope,
                    generalDataForGraph.isExpand &&
                        multi_line_expand_graph_scope
                )}
            >
                <svg
                    height="100%"
                    width="100%"
                    viewBox={`0 0 ${svgWidth} ${svgHeight}`}
                >
                    <g transform={`translate(${MARGIN.LEFT},${MARGIN.TOP})`}>
                        {Object.keys(generalDataForGraph.selectedLegendMap)
                            .length !== 0 &&
                            Object.keys(dataGroups).map(
                                (groupKey: string, index: number) => (
                                    <TrendLine
                                        onMouseMove={(
                                            event: React.MouseEvent<
                                                SVGPathElement,
                                                MouseEvent
                                            >
                                        ) =>
                                            onShowTooltip(
                                                event,
                                                groupKey,
                                                index
                                            )
                                        }
                                        onMouseLeave={onHideTooltip}
                                        onMouseOut={onHideTooltip}
                                        key={groupKey}
                                        data={dataGroups[groupKey]}
                                        color={colors(index)}
                                        xScale={xTrendLine}
                                        yScale={yScale}
                                        nameLine={groupKey}
                                        selectedLegendMap={
                                            generalDataForGraph.selectedLegendMap
                                        }
                                    />
                                )
                            )}
                        {Object.keys(generalDataForGraph.selectedLegendMap)
                            .length !== 0 &&
                            Object.keys(dataGroups).map(
                                (groupKey: string, groupIndex: number) =>
                                    dataGroups[groupKey].map(
                                        (
                                            item: GraphLineItem,
                                            index: number
                                        ) => (
                                            <Circle
                                                onMouseMove={(
                                                    event: React.MouseEvent<
                                                        SVGPathElement,
                                                        MouseEvent
                                                    >
                                                ) =>
                                                    onShowTooltip(
                                                        event,
                                                        item,
                                                        groupIndex
                                                    )
                                                }
                                                onMouseLeave={onHideTooltip}
                                                onMouseOut={onHideTooltip}
                                                key={`${index}` + item.legend}
                                                data={item}
                                                color={colors(groupIndex)}
                                                xScale={xScale}
                                                yScale={yScale}
                                                selectedLegendMap={
                                                    generalDataForGraph.selectedLegendMap
                                                }
                                            />
                                        )
                                    )
                            )}
                        <g
                            id={"axis-bottom"}
                            transform={`translate(0, ${height})`}
                        ></g>
                        <g id={"axis-left"}></g>
                    </g>
                </svg>
            </div>

            <Legend
                generalDataForGraph={generalDataForGraph}
                listText={Object.keys(dataGroups)}
                colors={colors}
                onUpdateLegendMap={onUpdateLegendMap}
                onSelectAll={onSelectAll}
                onShowSelectedDots={onShowSelectedDots}
                onShowSelectedTrendLines={onShowSelectedTrendLines}
            />
            <img
                onClick={onToggleExpand}
                className={clsx(
                    expand_icon,
                    generalDataForGraph.isExpand && close_icon
                )}
                src={generalDataForGraph.isExpand ? closeIcon : expandIcon}
            />
        </div>
    );
});
