import { action } from "mobx";

import { PublicProfile, UUID } from "@difftone/types";
import {
    getPublicProfileFromApiByUserUuidsArray,
    getPublicProfileFromApiByUserUuid,
} from "@difftone/services";
import { publicProfileStore } from "@difftone/stores";
import { getUniquePublicProfilesUUIDs } from "@difftone/actions";

export const updatePublicProfiles = async (publicProfilesUuids: UUID[]) => {
    let publicProfilesUuidsToFetch: UUID[] = [];

    publicProfilesUuidsToFetch = [...publicProfilesUuids];

    //Optimization for public profiles in case that there are some missing UUIDs
    if (publicProfileStore.startInitialization) {
        const uniquePublicProfiles = getUniquePublicProfilesUUIDs();

        uniquePublicProfiles.forEach((publicUUID) => {
            if (!publicProfilesUuidsToFetch.includes(publicUUID)) {
                publicProfilesUuidsToFetch.push(publicUUID);
            }
        });
    }

    try {
        const newPublicProfiles: PublicProfile[] =
            await getPublicProfileFromApiByUserUuidsArray(
                publicProfilesUuidsToFetch
            );

        newPublicProfiles.forEach((publicProfile: PublicProfile) => {
            publicProfileStore.setPublicProfileToMap(
                publicProfile.uuid,
                publicProfile
            );
        });
    } catch (error) {
        console.error(error);
        publicProfilesUuidsToFetch.forEach((uuid) => {
            publicProfileStore.setPublicProfileToMap(uuid, null);
        });
    } finally {
        publicProfileStore.storeInitialize = true;
        publicProfileStore.isRequestingSinglePublicProfile = true;
    }
};

export const updatePublicProfile = async (publicProfileUuid: UUID) => {
    try {
        const newPublicProfile = await getPublicProfileFromApiByUserUuid(
            publicProfileUuid
        );
        publicProfileStore.setPublicProfileToMap(
            publicProfileUuid,
            newPublicProfile
        );
    } catch (error) {
        publicProfileStore.setPublicProfileToMap(publicProfileUuid, null);
        console.error(error);
    }
};
