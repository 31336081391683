import React, { ReactNode } from "react";
import { observer } from "mobx-react";
import { setLocation } from "@difftone/actions";

import { RuntimeErrorFallbackPage } from "./components";

export type ErrorBoundaryProps = {
    children: ReactNode;
};

@observer
export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
    state = {
        runtimeError: false,
    };

    componentDidCatch(error: Error) {
        console.error(
            "[ErrorBoundry]:: Difftone error component caught an unhandled error. Runtime Fallback UI was rendered",
            error
        );

        this.setState({ runtimeError: true });
        setLocation("/home");
    }

    render() {
        if (this.state.runtimeError) {
            return <RuntimeErrorFallbackPage />;
        }
        return this.props.children;
    }
}
