import React from "react";
import clsx from "clsx";
import { navigationStore } from "@difftone/stores";

import {
    value_display_box,
    completed_or_ended_time_color_display,
} from "./display-date-and-circle-gray.module.css";

export type DisplayDateAndCircleGrayProps = {};

export const DisplayDateAndCircleGray = (
    props: DisplayDateAndCircleGrayProps
) => {
    const { currentPage } = navigationStore;
    const pageLocation = currentPage.split("/")[1];
    const eta_radius = 13;
    return (
        <>
            {pageLocation === "inbox" && (
                <svg width="30" height="30" viewBox="0 0 30 30">
                    <circle
                        cx={eta_radius + 1}
                        cy={eta_radius + 1}
                        r={eta_radius}
                    />
                </svg>
            )}
            {
                <div
                    className={clsx(
                        value_display_box,
                        completed_or_ended_time_color_display
                    )}
                >
                    00:00
                </div>
            }
        </>
    );
};
