import React from "react";
import { observer } from "mobx-react";

import { setLocation } from "@difftone/actions";
import { PAGE_DISPLAY_NAMES } from "@difftone/constants";
import { inboxDisplayStore, navigationStore } from "@difftone/stores";

import closeIcon from "@difftone/assets/close-datepicker-white.svg";

import { popup_header } from "./popup-header.module.css";

type PopupHeaderProps = {};
export const PopupHeader = observer((props: PopupHeaderProps) => {
    const { currentPage } = navigationStore;
    const namePage = currentPage.split("/")[2];
    const { clearStore } = inboxDisplayStore;
    return (
        <div className={popup_header}>
            <div>Send to new participants</div>
            <div className="">
                <img
                    src={closeIcon}
                    onClick={() => {
                        setLocation(PAGE_DISPLAY_NAMES[namePage]);
                        clearStore();
                    }}
                    alt="close-icon"
                />
            </div>
        </div>
    );
});
