import { UiStatus } from "@difftone/frontend-common";
import React from "react";

import {
    satus_survey_is_active_wrapper,
    satus_survey_is_active,
} from "./status-survey-is-active.module.css";

export type StatusSurveyIsActiveProps = {
    surveyStatus: UiStatus;
};

export const StatusSurveyIsActive = (props: StatusSurveyIsActiveProps) => {
    const { surveyStatus } = props;
    const colorBySurveyStatus = () => {
        switch (surveyStatus) {
            case "ACTIVE":
                return "#28a164";
            case "ENDED":
                return "#B6B8BA";
            default:
                return "#28a164";
        }
    };

    return (
        <div className={satus_survey_is_active_wrapper}>
            <div
                style={{ background: `${colorBySurveyStatus()}` }}
                className={satus_survey_is_active}
            ></div>
        </div>
    );
};
