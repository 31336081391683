import { action } from "mobx";
import {
    templatesStore,
    SortingTemplateProfile,
    templatesDisplayStore,
} from "@difftone/stores";
import { SharedSurvey, UUID } from "@difftone/types";

export const toggleTemplateIsPopupOpenedByUUID = action(
    (templateUUID: UUID) => {
        const copyTemplatesPopupIsOpenedMap: Record<UUID, boolean> = JSON.parse(
            JSON.stringify(templatesDisplayStore.templatesPopupIsOpenedMap)
        );

        copyTemplatesPopupIsOpenedMap[templateUUID] =
            !copyTemplatesPopupIsOpenedMap[templateUUID];

        templatesDisplayStore.templatesPopupIsOpenedMap =
            copyTemplatesPopupIsOpenedMap;
    }
);

export const setTemplateIsIFrameLoadingByUUID = action(
    (templateUUID: UUID, isLoading: boolean) => {
        const copyTemplatesIsIFrameLoadingMap: Record<UUID, boolean> =
            JSON.parse(
                JSON.stringify(
                    templatesDisplayStore.templatesIsIFrameLoadingMap
                )
            );

        copyTemplatesIsIFrameLoadingMap[templateUUID] = isLoading;

        templatesDisplayStore.templatesIsIFrameLoadingMap =
            copyTemplatesIsIFrameLoadingMap;
    }
);

export const setTemplateSortingProfile = action(
    (newTemplateSortingProfile: SortingTemplateProfile) => {
        templatesDisplayStore.sortingTemplateProfile =
            newTemplateSortingProfile;
    }
);

export const showManageTemplatesDialog = action(() => {
    templatesStore.isManageTemplatesDialogOpened = true;
});

export const closeManageTemplatesDialog = action(() => {
    templatesStore.isManageTemplatesDialogOpened = false;
});

export const clearTemplateToEdit = action(() => {
    templatesDisplayStore.templateToEdit = null;
});

export const setTemplateToEditByUUID = action((uuid: UUID) => {
    const template = templatesStore.getTemplateByUUID(uuid);

    if (template) {
        templatesDisplayStore.templateToEdit = JSON.parse(
            JSON.stringify(template)
        );
    }
});

export const updateTemplateToEdit = action((template: SharedSurvey) => {
    templatesDisplayStore.templateToEdit = template;
});

export const removeTemplateFromStore = action(() => {
    templatesStore.removeDeletedTemplate();
});
