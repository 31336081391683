import React, { useRef } from "react";
import { observer } from "mobx-react";
import {
    Answer,
    LongTextAnswer,
    LongTextQuestion,
    Question,
} from "@difftone/types";
import { updateSelectedSurveyNonFilterQuestionResponse } from "@difftone/actions";
import { getAnswerByQuestion } from "@difftone/reducers";
import { surveyResponseStore } from "@difftone/stores";
import { Spinner } from "@difftone/shared-components";

import { expanded_long_text_container } from "./expanded-long-text.module.css";

export type ExpandedLongTextProps = {
    disabled: boolean;
    question: Question<LongTextQuestion>;
    inPreviewMode: boolean;
    isEditableQuestion: boolean;
};

export const ExpandedLongText = observer((props: ExpandedLongTextProps) => {
    const { question, disabled, inPreviewMode, isEditableQuestion } = props;
    const textareaForLongText = useRef<HTMLTextAreaElement>(null);

    let surveyResponseUUID: string | undefined;

    if (inPreviewMode) {
        surveyResponseUUID = "";
    } else {
        const { getSelectedSurveyResponseUUID } = surveyResponseStore;
        surveyResponseUUID = getSelectedSurveyResponseUUID();
    }
    if (surveyResponseUUID === undefined) {
        return <Spinner size="MEDIUM" />;
    }

    const answer = getAnswerByQuestion(
        question,
        surveyResponseUUID
    ) as Answer<LongTextAnswer>;

    if (textareaForLongText && textareaForLongText.current) {
        textareaForLongText.current.style.height = "0px";
        const scrollHeight = textareaForLongText.current.scrollHeight;
        textareaForLongText.current.style.height = `${scrollHeight}px`;
    }

    const getStyle = () => {
        if (textareaForLongText && textareaForLongText.current) {
            return getComputedStyle(textareaForLongText.current);
        }
    };

    const onChange = (event: React.FormEvent<EventTarget>) => {
        const target = event.target as HTMLTextAreaElement;

        if (inPreviewMode) {
            return;
        }

        const maxRows = Object.values(target.attributes).find(
            (el) => el.name === "data-max-rows"
        );

        const maxRowsValue = maxRows?.value || 1;

        const textareaStyles = getStyle();
        const lineHeight = textareaStyles
            ? parseInt(textareaStyles.lineHeight.slice(0, -2))
            : 1;

        const row = Math.floor(target.scrollHeight / lineHeight);

        if (row <= maxRowsValue) {
            target.rows = row;
        }

        answer.content.text =
            target.value.length <= 1 ? target.value.trim() : target.value;
        updateSelectedSurveyNonFilterQuestionResponse(
            answer,
            surveyResponseUUID!
        );
    };

    return (
        <div className={expanded_long_text_container}>
            <textarea
                onChange={onChange}
                value={answer.content.text}
                disabled={disabled || !isEditableQuestion}
                placeholder="write free text"
                rows={1}
                data-max-rows={5}
                ref={textareaForLongText}
            />
        </div>
    );
});
