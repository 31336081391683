import { makeAutoObservable, observable, ObservableMap } from "mobx";
import {
    SimpleResultsMap,
    BaseSurvey,
    SurveyToSimpleResultsMap,
    UUID,
    SharedResult,
} from "@difftone/types";
import { getSimpleResultsMapBySurveyUUIDFromApi } from "./simple-survey-results-map-internal-actions";

export type SimpleResultsMapOrNull = SimpleResultsMap | null;

class SimpleResultsMapStore {
    constructor() {
        makeAutoObservable<
            SimpleResultsMapStore,
            "_fetchedSimpleResultsMapKeys"
        >(this, {
            _fetchedSimpleResultsMapKeys: false,
        });
    }

    private _fetchedSimpleResultsMapKeys: UUID[] = [];

    private _simpleResultsTablesMap: ObservableMap<
        UUID,
        SimpleResultsMap | null
    > = observable.map({});

    public getSimpleResultsMap(): ObservableMap<UUID, SimpleResultsMap | null> {
        return this._simpleResultsTablesMap;
    }

    public setSimpleResultsTableToMap(
        surveyUUID: UUID,
        simepleResultsMap: SimpleResultsMap | null
    ) {
        this._simpleResultsTablesMap.merge({ [surveyUUID]: simepleResultsMap });
    }

    public getSimpleResultsMapTableBySurveyUUID = (
        survey: BaseSurvey,
        sharedResult?: SharedResult
    ) => {
        const simpleResultsTableMap = this._simpleResultsTablesMap.get(
            survey.uuid
        );

        if (
            simpleResultsTableMap === undefined &&
            !this._fetchedSimpleResultsMapKeys.includes(survey.uuid)
        ) {
            this._fetchedSimpleResultsMapKeys.push(survey.uuid);
            getSimpleResultsMapBySurveyUUIDFromApi([survey], sharedResult);
        }

        return simpleResultsTableMap;
    };

    public clearStore = () => {
        this._simpleResultsTablesMap = observable.map({});
    };
}

export const simpleResultsMapStore = new SimpleResultsMapStore();
