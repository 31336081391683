import type { AxiosInstance } from "axios";
import axios, { AxiosRequestConfig } from "axios";
import { AuthData } from "@difftone/types";
import { localstorageUtils } from "@difftone/procedures";

export const createDifftoneAxios = (authData: AuthData): AxiosInstance => {
    const tempDifftoneAxios = new DifftoneAxios(authData);
    const difftoneAxios: unknown = tempDifftoneAxios;

    return difftoneAxios as AxiosInstance;
};

class DifftoneAxios {
    readonly _authData: AuthData;
    constructor(authData: AuthData) {
        this.interrupt = this.interrupt.bind(this);
        this._authData = authData;

        const _axiosClient: AxiosInstance = axios.create();
        _axiosClient.defaults.headers.common["Accept"] = "application/json";
        _axiosClient.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${authData.user_token}`;

        _axiosClient.interceptors.request.use(this.interrupt);

        //@ts-ignore
        return _axiosClient;
    }

    interrupt = async (req: AxiosRequestConfig) => {
        const authData =
            await localstorageUtils.asyncAuthDataFromLocalstorage();

        const newAuthData: AuthData = {
            issuer: "GOOGLE",
            access_token: authData.access_token,
            user_token: authData.user_token,
            refresh_token: this._authData.refresh_token,
        };

        req.headers.common[
            "Authorization"
        ] = `Bearer ${newAuthData.user_token}`;

        return req;
    };
}
