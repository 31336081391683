import { ScoredTextPopularity, AuthData } from "@difftone/types";
import { getApiBaseUrl } from "@difftone/constants";
import { errorHandlerProxy, localstorageUtils } from "@difftone/procedures";
import { AutoCompleteRepository } from "@difftone/stores";
import { createDifftoneAxios } from "@difftone/services";

const MAX_NUMBER_OF_AUTOCOMPLETE_OPTIONS = 10;

const SERVER_BASE_URL = `${getApiBaseUrl()}/text-popularity-service`;

export const getTitleAutoCompleteFromApi = async (
    autoCompleteString: string,
    repositoryType: AutoCompleteRepository
): Promise<string[]> => {
    try {
        const currentAuthData: AuthData =
            await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

        const difftoneAxios = createDifftoneAxios(currentAuthData);

        const response = await difftoneAxios.post(
            `${SERVER_BASE_URL}/${repositoryType}`,
            {
                prefix: autoCompleteString,
            },
            {
                params: {
                    issuer: currentAuthData.issuer,
                    max_num_of_answers: MAX_NUMBER_OF_AUTOCOMPLETE_OPTIONS,
                },
            }
        );

        if (response.data.error) {
            errorHandlerProxy({
                error: response.data.error,
                code: response.data.statusCode,
            });
        }

        const results: ScoredTextPopularity[] = response.data.results;
        return results.map((res) => res.title);
    } catch (error) {
        throw new Error(`server call failed: ${(error as Error).message}`);
    }
};

export const upsertSurveyTitlesToAutoCompleteToApi = async (
    autoCompleteString: string,
    repositoryType: AutoCompleteRepository
) => {
    try {
        const currentAuthData =
            await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

        const difftoneAxios = createDifftoneAxios(currentAuthData);

        const response = await difftoneAxios.patch(
            `${SERVER_BASE_URL}/${repositoryType}`,
            {
                title: autoCompleteString,
            },
            {
                params: {
                    issuer: currentAuthData.issuer,
                },
            }
        );

        if (response.data.error) {
            errorHandlerProxy({
                error: response.data.error,
                code: response.data.statusCode,
            });
        }

        const results: ScoredTextPopularity = response.data.results;
        return results;
    } catch (error) {
        throw new Error(`server call failed: ${(error as Error).message}`);
    }
};
