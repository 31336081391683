import { action } from "mobx";

import {
    Answer,
    AnswerType,
    Question,
    QuestionType,
    RatingAnswer,
    SingleChoiceAnswer,
    EmailAddress,
    SingleChoiceQuestion,
    RatingQuestion,
} from "@difftone/types";
import { groupEmailsStore, resultsDataStore } from "@difftone/stores";
import { showDifftoneAlert } from "../difftone-alert-actions";
import { getSelectedOrganizationOrThrow } from "@difftone/reducers";

export const overallMostPopularAnswerByComparisonsInitiator = (
    question: Question<QuestionType>,
    compares: Question<QuestionType>[]
) => {
    const res: any = {};
    const { surveyResponses } = resultsDataStore;

    surveyResponses.forEach((response) => {
        response.answers.forEach((answer: Answer<AnswerType>) => {
            if (answer.question_uuid === question.uuid) {
                res[question.uuid] = {};
                compares.forEach((compare) => {
                    res[question.uuid][compare.title] = {};
                    switch (compare.question_type_name) {
                        case "MULTIPLE_CHOICE":
                        case "SINGLE_CHOICE":
                            (
                                compare as Question<SingleChoiceQuestion>
                            ).content.choice_options.forEach((option) => {
                                res[question.uuid][compare.title][
                                    option.label
                                ] = {};
                            });
                            break;
                        case "RATING":
                            (
                                compare as Question<RatingQuestion>
                            ).content.rating_options.forEach((option) => {
                                res[question.uuid][compare.title][
                                    option.value
                                ] = {};
                            });
                            break;
                        default:
                            break;
                    }
                });
            }
        });
    });
    surveyResponses.forEach((response) => {
        response.answers.forEach((answer) => {
            if (answer.question_uuid === question.uuid) {
                compares.forEach((compare) => {
                    switch (compare.question_type_name) {
                        case "MULTIPLE_CHOICE":
                        case "SINGLE_CHOICE":
                            let answerValue = "X";
                            switch (answer.answer_type_name) {
                                case "RATING":
                                    answerValue = (
                                        answer as Answer<RatingAnswer>
                                    ).content.value.toString();
                                    break;
                                case "MULTIPLE_CHOICE":
                                case "SINGLE_CHOICE":
                                    answerValue = (
                                        answer as Answer<SingleChoiceAnswer>
                                    ).content.choice;
                                    break;
                                default:
                                    break;
                            }
                            (
                                compare as Question<SingleChoiceQuestion>
                            ).content.choice_options.forEach((option) => {
                                res[question.uuid][compare.title][option.label][
                                    answerValue
                                ] = 0;
                            });
                            break;
                        case "RATING":
                            const ratingAnswerValue = "X";
                            switch (answer.answer_type_name) {
                                case "RATING":
                                    answerValue = (
                                        answer as Answer<RatingAnswer>
                                    ).content.value.toString();
                                    break;
                                case "MULTIPLE_CHOICE":
                                case "SINGLE_CHOICE":
                                    answerValue = (
                                        answer as Answer<SingleChoiceAnswer>
                                    ).content.choice;
                                    break;
                                default:
                                    break;
                            }
                            (
                                compare as Question<RatingQuestion>
                            ).content.rating_options.forEach((option) => {
                                res[question.uuid][compare.title][option.value][
                                    ratingAnswerValue
                                ] = 0;
                            });
                            break;
                        default:
                            break;
                    }
                });
            }
        });
    });

    return res;
};

export const overallMostPopularAnswerByComparisons = (
    question: Question<QuestionType>,
    compares: Question<QuestionType>[]
) => {
    const { surveyResponses } = resultsDataStore;
    const initiatedObject = overallMostPopularAnswerByComparisonsInitiator(
        question,
        compares
    );
    surveyResponses.forEach((response) => {
        response.answers.forEach((answer) => {
            if (answer.question_uuid === question.uuid) {
                compares.forEach((compare) => {
                    const goodResponse = response.attribute_answers.find(
                        (attributeAnswer) =>
                            attributeAnswer.question_uuid === compare.uuid
                    );
                    if (goodResponse) {
                        const goodAnswer = response.answers.find(
                            (answer) => answer.question_uuid === question.uuid
                        );
                        let answerValue = "";
                        let compareValue = "";
                        switch (goodResponse.answer_type_name) {
                            case "MULTIPLE_CHOICE":
                            case "SINGLE_CHOICE":
                                compareValue = (
                                    goodResponse as Answer<SingleChoiceAnswer>
                                ).content.choice;
                                break;
                            case "RATING":
                                compareValue = (
                                    goodResponse as Answer<RatingAnswer>
                                ).content.value.toString();
                                break;
                            default:
                                break;
                        }
                        if (goodAnswer) {
                            switch (goodAnswer.answer_type_name) {
                                case "MULTIPLE_CHOICE":
                                case "SINGLE_CHOICE":
                                    answerValue = (
                                        goodAnswer as Answer<SingleChoiceAnswer>
                                    ).content.choice;
                                    break;
                                case "RATING":
                                    answerValue = (
                                        goodAnswer as Answer<RatingAnswer>
                                    ).content.value.toString();
                                    break;
                                default:
                                    break;
                            }
                        }
                        initiatedObject[question.uuid][compare.title][
                            compareValue
                        ][answerValue] =
                            initiatedObject[question.uuid][compare.title][
                                compareValue
                            ][answerValue] + 1;
                    }
                });
            }
        });
    });

    return initiatedObject;
};

export const addEmailToSharedResult = action((email: EmailAddress) => {
    if (!resultsDataStore.shareResultEmailList.includes(email)) {
        const resolvedEmails = groupEmailsStore.getResolvedEmails(
            email,
            getSelectedOrganizationOrThrow()!
        );
        switch (resolvedEmails) {
            case undefined:
                break;
            case null:
                //TODO: refine and make const
                showDifftoneAlert("Could not resolve email", "FAILURE");
                break;
            default:
                resultsDataStore.addEmailsToSharedResult([email]);
                break;
        }
    }
});

export const addMultipleEmailsToSharedResult = action(
    (emails: EmailAddress[]) => {
        const filteredMailingList = emails.filter(
            (email) => !resultsDataStore.shareResultEmailList.includes(email)
        );
        filteredMailingList.forEach((email) => {
            const resolvedEmails = groupEmailsStore.getResolvedEmails(
                email,
                getSelectedOrganizationOrThrow()!
            );
            switch (resolvedEmails) {
                case undefined:
                    break;
                case null:
                    //TODO: refine and make const
                    showDifftoneAlert("Could not resolve email", "FAILURE");
                    break;
                default:
                    resultsDataStore.addEmailsToSharedResult([email]);
                    break;
            }
        });
    }
);

export const clearEmailToSharedResultList = action(() => {
    resultsDataStore.shareResultEmailList = [];
});

export const removeEmailFromSharedResult = action((email: EmailAddress) => {
    const emailList = [...resultsDataStore.shareResultEmailList];
    const targetIndex = emailList.findIndex((e) => e === email);

    if (targetIndex === -1) {
        return;
    }

    emailList.splice(targetIndex, 1);

    resultsDataStore.shareResultEmailList = emailList;
});
