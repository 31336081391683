import React, { MouseEvent } from "react";
import { observer } from "mobx-react";
import { Draggable } from "react-beautiful-dnd";
import { getSurveyFromStoreByURL } from "@difftone/reducers";
import {
    deleteQuestionFromWizard,
    setFocusedWizardInputCard,
    setWizardAccordionActiveSection,
    debounceUpsertSurveyToApi,
} from "@difftone/actions";

import { LeftMenuListItemWrapper } from "@difftone/shared-components";
import { wizardDisplayStore } from "@difftone/stores";

export const DraggableLeftMenuQuestions = observer(() => {
    const { isEditableSurvey } = wizardDisplayStore;
    const survey = getSurveyFromStoreByURL();

    const focusOnQuestionByIndex = (event: MouseEvent<HTMLDivElement>) => {
        setWizardAccordionActiveSection("QUESTIONS");
        const index = JSON.parse(
            event.currentTarget.getAttribute("data-index") as string
        );
        setTimeout(() => {
            const questionElement: NodeListOf<HTMLDivElement> =
                document.querySelectorAll("div[data-wizard-question-card]");
            setFocusedWizardInputCard(questionElement[index]);
        }, 0);
    };

    const onDelete = (event: MouseEvent<HTMLButtonElement>) => {
        if (!isEditableSurvey) {
            return;
        }
        const index = JSON.parse(
            event.currentTarget.getAttribute("data-index") as string
        );
        const questionToDelete = survey?.questions![index];
        questionToDelete && deleteQuestionFromWizard(questionToDelete);
        setTimeout(() => {
            const allExistingQuestionElements: NodeListOf<HTMLDivElement> =
                document.querySelectorAll("div[data-wizard-question-card]");
            if (index > 0) {
                const previousElement = allExistingQuestionElements[index - 1];
                setFocusedWizardInputCard(previousElement);
            } else {
                allExistingQuestionElements[0]?.focus();
                setFocusedWizardInputCard(allExistingQuestionElements[0]);
            }
            debounceUpsertSurveyToApi();
        }, 0);
    };

    return (
        <div>
            {survey?.questions?.map((question, index) => (
                <Draggable
                    key={question.uuid}
                    draggableId={question.uuid}
                    index={index}
                >
                    {(provided) => (
                        <LeftMenuListItemWrapper
                            dataIndex={index}
                            draggableProps={provided.draggableProps}
                            dragHandleProps={provided.dragHandleProps}
                            ref={provided.innerRef}
                            onFocusClick={focusOnQuestionByIndex}
                            onDelete={onDelete}
                            listItemData={question}
                            questionsLength={survey?.questions.length}
                        />
                    )}
                </Draggable>
            ))}
        </div>
    );
});
