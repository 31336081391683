import React from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import {
    ResultsDisplayView,
    ResultsDisplayType,
    SurveyClasses,
} from "@difftone/types";
import {
    categoriesStore,
    navigationStore,
    publicProfileStore,
    resultsDisplayFilterStore,
    resultsDisplayStore,
    timeRangesStore,
} from "@difftone/stores";
import {
    changeResultsOptionalView,
    displayDropdownList,
    getStatus,
    getSurveyResponseData,
    getTimeUnitFromUrlOrNone,
} from "@difftone/reducers";
import {
    SurveyStatus,
    ResultsAnonymityIndicator,
    ResultsDisplayTypeDropdown,
    DropdownElementsWithIcon,
    Spinner,
} from "@difftone/shared-components";
import {
    changeResultsDisplayState,
    changeResultsDisplayType,
    isGraphDisabledInSharedResults,
    showDifftoneAlert,
} from "@difftone/actions";

import {
    status_class,
    about_survey,
    medium_gray_text_block,
    button_wrapper,
    button_title,
    anonymity_wrapper,
    dropdown_wrapper,
    dropdown_view_wrapper,
    dropdown_type_wrapper,
    initiator_name,
    spinner_wrapper,
    about_spinner_wrapper,
} from "./about-survey.module.css";
import { TIME_FILTER_ID } from "@difftone/constants";

export type AboutSurveyProps = {
    survey: SurveyClasses;
};

export const AboutSurvey = observer((props: AboutSurveyProps) => {
    const { survey } = props;
    const { categoriesQuestionsDisplayModeToggle, displayTypeModeToggle } =
        resultsDisplayStore;
    const { currentPage } = navigationStore;

    const { extractKeyForTimeRange, getTimeRangeByKey } = timeRangesStore;
    const surveyStatus = getStatus(survey);

    const initiatorPublicProfile =
        survey && survey.initiator
            ? publicProfileStore.getPublicProfileByUuid(survey.initiator)
            : null;

    const surveyResponseData = getSurveyResponseData(survey);

    const surveyHasCategories = survey.questions.flatMap(
        (question) => question.categories
    );

    const handleFilterChange = (state: string) => {
        changeResultsDisplayState(state as ResultsDisplayView);
    };

    const currentPageUrl = currentPage.split("/");
    if (currentPageUrl[3]) {
        changeResultsDisplayType(currentPageUrl[3] as ResultsDisplayType);
    }

    if (!surveyHasCategories.length) {
        changeResultsDisplayState("QUESTIONS");
    }

    if (!categoriesStore.isStoreInitialized) {
        categoriesStore.getCategoriesArray();
        return <Spinner size="SMALL" className={spinner_wrapper} />;
    }

    const timeRangeKey = extractKeyForTimeRange(survey.uuid, "MONTHS");

    const timeRanges = getTimeRangeByKey(timeRangeKey);

    if (timeRanges === undefined) {
        return <Spinner size="MEDIUM" className={about_spinner_wrapper} />;
    }

    if (timeRanges === null) {
        showDifftoneAlert(
            "Something went wrong, please reload and try again",
            "FAILURE"
        );
        return null;
    }

    const displayStateDropdownList: DropdownElementsWithIcon[] =
        displayDropdownList(survey);

    const isDisabledLineForSimple = survey.survey_class === "SIMPLE";
    const isDisabledLineForSharedResults =
        isGraphDisabledInSharedResults(currentPage);

    const isDisabledLine =
        isDisabledLineForSimple || isDisabledLineForSharedResults;

    const displayTypeDropdownList: DropdownElementsWithIcon[] = [
        {
            displayValue: "Grid",
            systemValue: "GRID",
            urlValue: "grid",
            iconType: "GRID",
        },
        {
            displayValue: "Favorable",
            systemValue: "FAVORABLE",
            urlValue: "favorable",
            iconType: "FAVORABLE",
        },
        {
            displayValue: "Line",
            systemValue: "LINE",
            urlValue: "line",
            iconType: "LINE",
            isDisabled: isDisabledLine,
        },
    ];

    const handleFilterDisplayTypeChange = (value: string) => {
        const optionalView = value as ResultsDisplayType;
        changeResultsDisplayType(optionalView as ResultsDisplayType);
        changeResultsOptionalView(
            currentPageUrl[1],
            optionalView,
            timeRanges,
            survey
        );
    };

    return survey ? (
        <>
            <div className={about_survey}>
                <div className={status_class}>
                    Status: <SurveyStatus surveyStatus={surveyStatus} />
                </div>
                <div>
                    Initiator:&nbsp;
                    <span className={medium_gray_text_block}>
                        {initiatorPublicProfile?.name}
                    </span>
                </div>
                <div>
                    Response Rate:&nbsp;
                    <span
                        className={clsx(medium_gray_text_block, initiator_name)}
                    >{`${surveyResponseData.percentage}% (${surveyResponseData.completed}/${surveyResponseData.total})`}</span>
                </div>

                <div>
                    Purpose:&nbsp;
                    <span className={medium_gray_text_block}>
                        {survey.purpose?.toLowerCase()}
                    </span>
                </div>
                <ResultsAnonymityIndicator
                    survey={survey}
                    textClassName={medium_gray_text_block}
                    wrapperClass={anonymity_wrapper}
                />
            </div>
            <div className={button_wrapper}>
                <div className={button_title}>View results by:</div>
                <div className={dropdown_wrapper}>
                    <div className={dropdown_view_wrapper}>
                        <ResultsDisplayTypeDropdown
                            onChange={handleFilterChange}
                            defaultSelectedValue={
                                categoriesQuestionsDisplayModeToggle
                            }
                            dropdownOptions={displayStateDropdownList}
                        />
                    </div>
                    <div className={dropdown_type_wrapper}>
                        <ResultsDisplayTypeDropdown
                            onChange={handleFilterDisplayTypeChange}
                            defaultSelectedValue={displayTypeModeToggle}
                            dropdownOptions={displayTypeDropdownList}
                        />
                    </div>
                </div>
            </div>
        </>
    ) : null;
});
