import {
    logoutFromGoogle,
    logoutFromMicrosoft,
    setLocation,
} from "@difftone/actions";
import { AuthData } from "@difftone/types";

export type DifftoneError = {
    error: Error;
    code: number;
};

const logout = () => {
    const authDataAsString = localStorage.getItem("authData");

    if (authDataAsString) {
        const authData: AuthData = JSON.parse(authDataAsString);
        if (authData.issuer === "MICROSOFT") {
            logoutFromMicrosoft();
        } else if (authData.issuer === "GOOGLE") {
            logoutFromGoogle();
        }
    }

    localStorage.removeItem("authData");
    setLocation("/home");
};

export const errorHandlerProxy = (difftoneError: DifftoneError) => {
    switch (difftoneError.code) {
        case 401:
            logout();
            console.error(
                "[tokenExperationErrorHandler]:: An error was captured =>> ",
                difftoneError.error
            );
            return;
        default:
            logout();
            console.error(
                "[errorHandlerProxy]:: An error was captured =>> ",
                difftoneError
            );
    }
};
