import React, {
    useState,
    useRef,
    ChangeEvent,
    Ref,
    SyntheticEvent,
    KeyboardEventHandler,
} from "react";
import { observer } from "mobx-react";
import OutsideClickHandler from "react-outside-click-handler";
import clsx from "clsx";

import { categoriesDisplayStore, categoriesStore } from "@difftone/stores";
import {
    applyCategoryToQuestion,
    setFocusedWizardInputCard,
    validateWizardQuestions,
} from "@difftone/actions";
import {
    extractDisplayNameFromCategory,
    formatCategoryObject,
} from "@difftone/reducers";
import { getUserUuid } from "@difftone/procedures";
import { Question, QuestionType, BaseSurvey, Label } from "@difftone/types";
import { KEYBOARD_EVENT_KEYS_MAP } from "@difftone/constants";
import { preventPropagation } from "@difftone/frontend-common";
import {
    ShowDifftoneTooltip,
    Popper,
    AddBoxShadow,
} from "@difftone/shared-components";
import closeCrossIcon from "@difftone/assets/close-cross-circled.svg";
import successIcon from "@difftone/assets/success-tick-icon.svg";

import {
    category_control_container,
    category_control,
    category_control_center,
    category_control_placeholder,
    category_control_input,
    category_control_popper,
    category_control_popper_root,
    category_control_popper_item,
    category_control_popper_item_chosen,
    popper_wrapper,
    selected_category,
    disabled_category_text,
    category_control_disabled,
    category_control_container_disabled,
} from "./category-control.module.css";

const MIN_CHARS_IN_CATEGORY_DISPLAY = 15;

export type CategoryControlProps = {
    question: Question<QuestionType>;
    categoriesList: string[];
    survey: BaseSurvey;
    isFilterQuestion: boolean;
};

export const CategoryControl = observer((props: CategoryControlProps) => {
    const { question, categoriesList, survey, isFilterQuestion } = props;
    // anchor element for popper
    const anchorRef: Ref<HTMLDivElement> = useRef(null);
    // input for focusing manually
    const inputRef: Ref<HTMLInputElement> = useRef(null);
    // temp input value
    const [value, setValue] = useState("");
    // control mode
    const [isInputMode, setIsInputMode] = useState(false);
    // keyboard focused item index
    const [focusedItemIndex, setFocusedItemIndex] = useState(-1);

    const userUUID = getUserUuid();

    if (!categoriesDisplayStore.customCategories.length) {
        const uuidsOfCategories = categoriesStore
            .getCategoriesArray()
            .map((item) => item.uuid);

        const newCustomCategories = categoriesList?.filter(
            (item) => !uuidsOfCategories.includes(item)
        );

        Array.from(new Set(newCustomCategories)).forEach((item) =>
            categoriesDisplayStore.addCustomCategory(
                formatCategoryObject(null, userUUID, item)
            )
        );
    }

    const categories = [
        ...categoriesStore.getCategoriesArray(),
        ...categoriesDisplayStore.customCategories,
    ];

    const filteredCategories = categories.filter(({ display_name }) =>
        display_name.toLowerCase().startsWith(value.toLocaleLowerCase())
    );

    const publicCategories = filteredCategories.filter(
        ({ scope_type }) => scope_type === "GENERAL"
    );
    const organisationCategories = filteredCategories.filter(
        ({ scope_type }) => scope_type === "ORGANISATION"
    );
    const privateCategories = filteredCategories.filter(
        ({ scope_type }) => scope_type === "PRIVATE"
    );

    const notUniqueDisplayCategories = [
        ...publicCategories,
        ...organisationCategories,
        ...privateCategories,
    ];

    const getUniqueDisplayCategories = (categories: Label[]): Label[] => {
        const uniqueCategoriesNames = new Set(
            categories.map(({ display_name }) => display_name)
        );
        const uniqueCategories = Array.from(uniqueCategoriesNames).map(
            (displayName) =>
                categories.find(
                    ({ display_name }) => display_name === displayName
                )
        );

        return uniqueCategories.filter((category) => category) as Label[];
    };

    const displayCategories = getUniqueDisplayCategories(
        notUniqueDisplayCategories
    );

    const questionCategories =
        question && question.categories ? question.categories : [];

    const chosenCategory = extractDisplayNameFromCategory(
        questionCategories.length ? questionCategories[0] : "",
        categories
    );

    const handleChooseCategory = (uuid: string) => {
        const isCategoryCustom = categoriesDisplayStore.customCategories.find(
            (_category) => _category.uuid === uuid
        );
        let existedCategory = categories.find(
            (categories) => categories.uuid === uuid
        );

        if (!existedCategory) {
            existedCategory = categoriesDisplayStore.customCategories.find(
                (categories) => categories.uuid === uuid
            );
            applyCategoryToQuestion(
                question,
                existedCategory ? existedCategory.display_name : value
            );
        } else {
            applyCategoryToQuestion(
                question,
                isCategoryCustom ? existedCategory.display_name : uuid
            );
        }

        handleClose();

        if (question.title.trim().length) {
            validateWizardQuestions(survey);
        }
    };

    const handleKeyboardChooseCategory = () => {
        const targetCategory = displayCategories[focusedItemIndex];

        if (targetCategory) {
            handleChooseCategory(targetCategory.uuid);
            handleClose();
        }
    };

    const handleClose = () => {
        setIsInputMode(false);
    };

    const focusInput = () => {
        setValue("");

        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 0);
    };

    const handleOpen = (e: SyntheticEvent) => {
        if (isFilterQuestion) {
            return;
        }
        preventPropagation(e);

        const currentElement = document.querySelector(
            `div[data-focus-wizard-input-card-id="${question.uuid}"]`
        );

        setFocusedWizardInputCard(currentElement as HTMLElement);

        if (chosenCategory) {
            setValue(chosenCategory);
        }

        setIsInputMode(true);
        focusInput();
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);

        if (displayCategories.length < focusedItemIndex) {
            setFocusedItemIndex(displayCategories.length - 1);
        }
    };

    const handlePreventBubbling = (e: SyntheticEvent) => {
        preventPropagation(e);
    };

    const handleClearInput = (e: SyntheticEvent) => {
        handlePreventBubbling(e);
        applyCategoryToQuestion(question, "");
        setValue("");
        focusInput();
        validateWizardQuestions(survey);
    };

    const handleEnterPress: KeyboardEventHandler = (e) => {
        preventPropagation(e);
        if (
            e.key === KEYBOARD_EVENT_KEYS_MAP.ENTER &&
            focusedItemIndex === -1
        ) {
            let existedCategoryItem = categories.find(
                (categories) =>
                    categories.display_name.toLocaleLowerCase() ===
                    value.toLocaleLowerCase()
            );

            if (!existedCategoryItem) {
                categoriesDisplayStore.addCustomCategory(
                    formatCategoryObject(null, userUUID, value)
                );
                existedCategoryItem =
                    categoriesDisplayStore.customCategories.find(
                        (categories) => categories.display_name === value
                    );
            }

            applyCategoryToQuestion(
                question,
                existedCategoryItem ? existedCategoryItem.display_name : value
            );
            handleClose();
        } else if (e.key === KEYBOARD_EVENT_KEYS_MAP.ENTER) {
            handleKeyboardChooseCategory();
            setFocusedItemIndex(-1);
        } else if (e.key === KEYBOARD_EVENT_KEYS_MAP.ARROW_DOWN) {
            setFocusedItemIndex((index) =>
                index + 1 > categories.length ? index : index + 1
            );
        } else if (e.key === KEYBOARD_EVENT_KEYS_MAP.ARROW_UP) {
            setFocusedItemIndex((index) => (index - 1 < 0 ? index : index - 1));
        } else if (e.key === KEYBOARD_EVENT_KEYS_MAP.ESCAPE) {
            setFocusedItemIndex(-1);
            handleClose();
        }
    };

    return (
        <OutsideClickHandler onOutsideClick={handleClose}>
            <div
                className={clsx(category_control_container, {
                    [category_control_container_disabled]: isFilterQuestion,
                })}
                ref={anchorRef}
            >
                <div>
                    <div
                        className={clsx(category_control, {
                            [category_control_disabled]: isFilterQuestion,
                            [category_control_center]: !isInputMode,
                        })}
                        onClick={handleOpen}
                    >
                        {!isInputMode && (
                            <span className={category_control_placeholder}>
                                <ShowDifftoneTooltip
                                    tip={chosenCategory}
                                    tooltipPosition="right"
                                    disableHover={
                                        chosenCategory?.length <=
                                            MIN_CHARS_IN_CATEGORY_DISPLAY ||
                                        !chosenCategory
                                    }
                                >
                                    <div
                                        className={clsx(selected_category, {
                                            [disabled_category_text]:
                                                isFilterQuestion,
                                        })}
                                    >
                                        {chosenCategory
                                            ? chosenCategory
                                            : "Add Category"}
                                    </div>
                                </ShowDifftoneTooltip>
                            </span>
                        )}

                        {isInputMode && (
                            <input
                                disabled={isFilterQuestion}
                                ref={inputRef}
                                value={value}
                                onChange={handleInputChange}
                                onKeyDown={handleEnterPress}
                                onClick={handlePreventBubbling}
                                className={category_control_input}
                            />
                        )}
                        {isInputMode && (
                            <img
                                onClick={handleClearInput}
                                src={closeCrossIcon}
                                alt=""
                            />
                        )}
                    </div>
                </div>
            </div>
            {isFilterQuestion ? null : (
                <div className={popper_wrapper}>
                    <AddBoxShadow>
                        <Popper
                            className={category_control_popper_root}
                            contentClassName={category_control_popper}
                            open={isInputMode}
                            anchorEl={anchorRef.current}
                            onClose={() => null}
                            onClick={handlePreventBubbling}
                            placement="bottom"
                            disablePortal
                        >
                            {displayCategories.map((category, index) => (
                                <ShowDifftoneTooltip
                                    key={category.uuid}
                                    tip={category.display_name}
                                    tooltipPosition="right"
                                    disableHover={
                                        category.display_name.length <=
                                        MIN_CHARS_IN_CATEGORY_DISPLAY
                                    }
                                >
                                    <div
                                        className={clsx(
                                            category_control_popper_item,
                                            {
                                                [category_control_popper_item_chosen]:
                                                    category.display_name ===
                                                        chosenCategory ||
                                                    focusedItemIndex === index,
                                            }
                                        )}
                                        onMouseDown={() =>
                                            handleChooseCategory(category.uuid)
                                        }
                                    >
                                        <span>{category.display_name}</span>

                                        {category.display_name ===
                                            chosenCategory && (
                                            <img src={successIcon} alt="" />
                                        )}
                                    </div>
                                </ShowDifftoneTooltip>
                            ))}
                        </Popper>
                    </AddBoxShadow>
                </div>
            )}
        </OutsideClickHandler>
    );
});
