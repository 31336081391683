import { showDifftoneAlert } from "@difftone/actions";
import { localstorageUtils } from "@difftone/procedures";
import {
    checkIfEmailIsGroupByNames,
    upsertSurveyTitlesToAutoCompleteToApi,
} from "@difftone/services";
import { BaseSurvey, EmailAddress, SurveyClasses } from "@difftone/types";

export const sendSurveyTitltesToAutocompleteApi = (survey: SurveyClasses) => {
    survey.questions?.map(
        async (question) =>
            await upsertSurveyTitlesToAutoCompleteToApi(
                question.title,
                "questions"
            )
    );
    if (survey.survey_class !== "ONGOING") {
        survey.survey_events?.map(
            async (surveyEvent) =>
                await upsertSurveyTitlesToAutoCompleteToApi(
                    surveyEvent.title,
                    "dates"
                )
        );
    }
};

export const checkIfEmailIsGroup = async (email: EmailAddress) => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    if (currentAuthData.issuer !== "GOOGLE") return false;

    const emailIsGroup = await checkIfEmailIsGroupByNames(email);
    if (emailIsGroup) {
        showDifftoneAlert(
            `Group emails are not currently supported`,
            "FAILURE"
        );
    }
    return emailIsGroup;
};
