import { action } from "mobx";

import {
    SortingProfile,
    inboxStore,
    InboxFilters,
    FilterTypes,
    inboxDisplayStore,
    groupEmailsStore,
} from "@difftone/stores";
import {
    DisplaySurvey,
    BaseSurvey,
    EmailAddress,
    SurveyClasses,
} from "@difftone/types";
import { setLocation, showDifftoneAlert } from "@difftone/actions";
import {
    PAGE_URLS,
    DISPLAY_SURVEY_ROLES,
    SURVEY_STATUS,
    constantAlertVariants,
    getDynamicAlertVariantForOrganizationEmailValidation,
} from "@difftone/constants";
import {
    getSelectedOrganizationOrThrow,
    getStatus,
    isEmailFromOrganisation,
} from "@difftone/reducers";

export type SurveyLoader = () => void;

export const setInboxActiveFilterFlagNoRedirect = action(
    (newFilterValue: InboxFilters | null) => {
        inboxStore.inboxLastActiveFilter =
            newFilterValue === null ? "ALL" : newFilterValue;
    }
);

export const setInboxActiveFilterFlagAndRedirect = action(
    (newFilterValue: InboxFilters) => {
        inboxStore.inboxLastActiveFilter = newFilterValue;
        const pageUrls = {
            ALL: PAGE_URLS.INBOX_ALL,
            DRAFT: PAGE_URLS.INBOX_DRAFT,
            INITIATOR: PAGE_URLS.INBOX_INITIATOR,
            ONGOING: PAGE_URLS.INBOX_ONGOING,
            NEEDS_ATTENTION: PAGE_URLS.INBOX_NEEDS_ATTENTION,
            NOT_COMPLETED: PAGE_URLS.INBOX_NOT_COMPLETED,
            RESPONDENT: PAGE_URLS.INBOX_RESPONDENT,
            SHARED_WITH_ME: PAGE_URLS.INBOX_SHARED_WITH_ME,
            TEMPLATES: PAGE_URLS.INBOX_TEMPLATES,
            ARCHIVED: PAGE_URLS.INBOX_ARCHIVED,
            SHARED_VIEWS_WITH_ME: PAGE_URLS.INBOX_SHARED_VIEWS_WITH_ME,
        };
        setLocation(pageUrls[newFilterValue] || PAGE_URLS.INBOX_ALL);
    }
);

export const setSortingProfile = action((newSortingProfile: SortingProfile) => {
    inboxStore.sortingProfile = newSortingProfile;
});

// Filters Actions
export const setFilterProfile = action((type: FilterTypes, value: string) => {
    inboxStore.filterProfile[type] = value;
});

export const inboxSpinnerOff = action(() => {
    inboxStore.surveysTableSpinnerActive = false;
});

export const inboxSpinnerOn = action(() => {
    inboxStore.surveysTableSpinnerActive = true;
});

export const filteredDisplaySurveyByActiveFilter = (
    displaySurvey: DisplaySurvey,
    filterOption: InboxFilters
): boolean => {
    const _surveyFromDisplaySurvey = displaySurvey.survey as SurveyClasses;

    const displaySurveyStatus = getStatus(
        displaySurvey.survey,
        displaySurvey.role
    );

    let result: boolean = false;

    switch (filterOption) {
        case "NEEDS_ATTENTION":
            if (displaySurvey.role === DISPLAY_SURVEY_ROLES.INITIATOR) {
                if (displaySurveyStatus === "ACTIVE") {
                    result = true;
                } else {
                    result = false;
                }
            }
            if (displaySurvey.role === DISPLAY_SURVEY_ROLES.RESPONDER) {
                if (displaySurveyStatus === "ACTIVE") {
                    result = true;
                } else {
                    result = false;
                }
            }
            return result;
        case "ALL":
            if (displaySurvey.role === DISPLAY_SURVEY_ROLES.INITIATOR) {
                result = true;
            }
            if (displaySurvey.role === DISPLAY_SURVEY_ROLES.RESPONDER) {
                if (displaySurveyStatus !== null) {
                    result = true;
                } else {
                    result = false;
                }
            }
            if (_surveyFromDisplaySurvey.status === SURVEY_STATUS.DRAFT) {
                result = false;
            }

            return result;
        case "INITIATOR":
            if (displaySurvey.role === DISPLAY_SURVEY_ROLES.INITIATOR) {
                if (_surveyFromDisplaySurvey.status !== SURVEY_STATUS.DRAFT) {
                    result = true;
                }
            }

            return result;

        case "ONGOING":
            if (displaySurvey.role === DISPLAY_SURVEY_ROLES.INITIATOR) {
                if (
                    displaySurvey.survey.survey_class === "ONGOING" &&
                    _surveyFromDisplaySurvey.status !== SURVEY_STATUS.DRAFT
                ) {
                    result = true;
                }
            }

            return result;

        case "ARCHIVED":
            if (displaySurvey.role === DISPLAY_SURVEY_ROLES.INITIATOR) {
                result = true;
            }

            return result;

        case "RESPONDENT":
            if (displaySurvey.role === DISPLAY_SURVEY_ROLES.RESPONDER) {
                if (
                    displaySurveyStatus === "ACTIVE" ||
                    displaySurveyStatus === "COMPLETED" ||
                    displaySurveyStatus === "ENDED"
                ) {
                    result = true;
                }
            }
            return result;
        case "NOT_COMPLETED":
            if (
                displaySurvey.role === DISPLAY_SURVEY_ROLES.RESPONDER &&
                displaySurveyStatus === "ACTIVE"
            ) {
                result = true;
            }
            return result;
        case "DRAFT":
            if (displaySurvey.role === DISPLAY_SURVEY_ROLES.INITIATOR) {
                if (displaySurveyStatus === "DRAFT") {
                    result = true;
                } else {
                    result = false;
                }
            }
            return result;
        default:
            return result;
    }
};

export const updateNewCopySurvey = action(
    (newCopySurvey: BaseSurvey | undefined | null) => {
        inboxDisplayStore.copySurvey = JSON.parse(
            JSON.stringify(newCopySurvey)
        );
    }
);
export const updateRespondentsInCopySurvey = action(
    (emails: EmailAddress[]) => {
        const organizationEmails: EmailAddress[] = [];
        const notOrganizationEmails: EmailAddress[] = [];

        if (inboxDisplayStore.copySurvey) {
            const respondents = inboxDisplayStore.copySurvey.respondents;
            const findRespondent = emails.find((email) =>
                respondents.includes(email)
            );

            if (
                findRespondent &&
                !inboxDisplayStore.newRespondents.includes(findRespondent)
            ) {
                showDifftoneAlert(
                    constantAlertVariants.email_exists.text,
                    constantAlertVariants.email_exists.variant
                );
                return;
            }
        }

        emails.forEach((email) => {
            if (!isEmailFromOrganisation(email)) {
                notOrganizationEmails.push(email);
                return;
            }
            organizationEmails.push(email);
        });

        if (notOrganizationEmails.length) {
            const organization = getSelectedOrganizationOrThrow();
            const organizationName = organization ? organization.name : "";

            showDifftoneAlert(
                getDynamicAlertVariantForOrganizationEmailValidation(
                    organizationName,
                    notOrganizationEmails
                ),
                "FAILURE"
            );
        }

        const resolvedEmails = organizationEmails
            .map((email) => {
                return groupEmailsStore.getResolvedEmails(
                    email,
                    getSelectedOrganizationOrThrow()!
                );
            })
            .flat();

        const nullEmails = resolvedEmails.filter((email) => email === null);
        if (nullEmails.length) {
            showDifftoneAlert(
                `Could not resolve emails: ${nullEmails.join(", ")}`,
                "FAILURE"
            );
        }

        const emailsToAdd = resolvedEmails.filter(
            (email) => email && email.length
        );

        inboxDisplayStore.newRespondents = Array.from(
            new Set([
                ...(emailsToAdd as string[]),
                ...(inboxDisplayStore.newRespondents || []),
            ])
        );

        if (inboxDisplayStore.copySurvey) {
            inboxDisplayStore.copySurvey.respondents = Array.from(
                new Set([
                    ...(emailsToAdd as string[]),
                    ...(inboxDisplayStore.copySurvey.respondents || []),
                ])
            );
        }
    }
);

export const respondentsDeleteInCopySurvey = action(
    (emailToDelete: EmailAddress) => {
        if (inboxDisplayStore.copySurvey) {
            inboxDisplayStore.copySurvey.respondents =
                inboxDisplayStore.copySurvey.respondents.filter(
                    (email) => email !== emailToDelete
                );

            inboxDisplayStore.newRespondents =
                inboxDisplayStore.newRespondents.filter(
                    (email) => email !== emailToDelete
                );
        }
    }
);

export const messageChange = action((value: string) => {
    inboxDisplayStore.messageSurvey = value;
});

export const messageClear = action(() => {
    inboxDisplayStore.messageSurvey = "";
});

export const setConvertingForm = action((value: boolean) => {
    inboxDisplayStore.isImportingForm = value;
});

export const showGoogleFormsDialog = action(async () => {
    inboxDisplayStore.isImportGoogleFormDialogOpened = true;
});

export const closeImportGoogleFormDialog = action(() => {
    inboxDisplayStore.isImportGoogleFormDialogOpened = false;
});
