import React from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import {
    Dialog,
    DialogProps,
    MailingListProducer,
    Spinner,
    ShowDifftoneTooltip,
} from "@difftone/shared-components";
import {
    addEmailsToOrganisationInitiators,
    removeEmailFromOrganisationInitiators,
    showDifftoneAlert,
    changeOrganisationTempMode,
} from "@difftone/actions";
import {
    getAdmins,
    getSelectedOrganizationOrThrow,
    isEmailFromOrganisation,
} from "@difftone/reducers";
import { organizationStore } from "@difftone/stores";
import { EmailAddress, OrganizationSettings } from "@difftone/types";
import {
    constantAlertVariants,
    getDynamicAlertVariantForOrganizationEmailValidation,
} from "@difftone/constants";
import { preventPropagation } from "@difftone/frontend-common";
import { UserIcon } from "@difftone/shared-components";
import infoCircledIcon from "@difftone/assets/info-circled-icon.svg";

import {
    permissions_dialog_content,
    permissions_dialog_header,
    permissions_dialog_hint,
    permissions_dialog_hint_text,
    permissions_dialog_toggler,
    permissions_dialog_toggler_inner,
    permissions_dialog_toggler_inner_active,
    permissions_dialog_mode_controls,
    permissions_dialog_text_active,
    permissions_dialog_text_disabled,
    permissions_dialog_controls_text,
    permissions_dialog_icon,
    permissions_dialog_mailing,
    permissions_dialog_mailing_disabled,
    permissions_dialog_mailing_list_wrap,
    permissions_dialog_button,
    permissions_dialog_paper,
    permissions_dialog_hint_icon,
    permissions_button_wrapper,
} from "./permissions-dialog.module.css";

export type PermissionsDialogProps = DialogProps & {};

export const PermissionsDialog = observer((props: PermissionsDialogProps) => {
    const { onClose, open } = props;
    const sysAdmins = getAdmins();
    const organization = getSelectedOrganizationOrThrow();

    if (!onClose) {
        throw new Error("onClose is not defined");
    }

    const isAllMode = organization?.settings.mode === "ALL";

    const toggleMode = () => {
        changeOrganisationTempMode(
            organization?.settings.mode === "ALL" ? "INITIATORS_LIST" : "ALL"
        );
    };

    const onEmailEnter = (emails: EmailAddress[]) => {
        const notOrganizationEmails: EmailAddress[] = [];
        const organizationEmails: EmailAddress[] = [];
        emails.forEach((email) => {
            if (!isEmailFromOrganisation(email)) {
                notOrganizationEmails.push(email);
                return;
            }
            organizationEmails.push(email);
        });

        if (notOrganizationEmails.length) {
            const organization = getSelectedOrganizationOrThrow();
            const organizationName = organization ? organization.name : "";

            showDifftoneAlert(
                getDynamicAlertVariantForOrganizationEmailValidation(
                    organizationName,
                    emails
                ),
                "FAILURE"
            );
        }

        addEmailsToOrganisationInitiators(organizationEmails);
    };

    const onSave = async () => {
        if (!organization) {
            throw Error("[Difftone]:: connt found organization");
        }

        const settingsToUpdate: OrganizationSettings = {
            initiators: organization?.settings.initiators.filter(
                (initiator) =>
                    !sysAdmins.find((sysAdmin) => initiator === sysAdmin)
            ),
            mode: organization.settings.mode,
        };

        try {
            const organization = getSelectedOrganizationOrThrow();
            const organisationUuid = organization ? organization.uuid : "";

            organizationStore.updateOrganisationSettings(
                settingsToUpdate,
                organisationUuid
            );

            onClose();
            showDifftoneAlert(
                constantAlertVariants.organization_success.text,
                constantAlertVariants.organization_success.variant
            );
        } catch (e) {
            console.error(e);

            onClose();
            showDifftoneAlert(
                constantAlertVariants.general_warning.text,
                constantAlertVariants.general_warning.variant
            );
        }
    };

    const onSaveConfirm = () => {
        showDifftoneAlert(
            constantAlertVariants.organization_notification.text,
            constantAlertVariants.organization_notification.variant,
            onSave,
            () => {}
        );
    };

    const initiators = organization ? organization?.settings.initiators : [];

    const visibleInitiators = Array.from(
        new Set([...sysAdmins, ...initiators])
    );

    return (
        <Dialog
            onClose={onClose}
            open={open}
            fullScreen={false}
            title="Manage permissions"
            className={permissions_dialog_header}
            classes={{
                paper: permissions_dialog_paper,
            }}
        >
            <div
                className={permissions_dialog_content}
                onClick={preventPropagation}
            >
                <>
                    <div className={permissions_dialog_hint}>
                        <span
                            className={clsx(permissions_dialog_hint_text, {
                                [permissions_dialog_text_disabled]: !isAllMode,
                                [permissions_dialog_text_active]: isAllMode,
                            })}
                        >
                            Everyone can initiate
                        </span>

                        <ShowDifftoneTooltip
                            tip="Our default setting, meaning everyone can initiate surveys; Opting out means only added users can initiate surveys"
                            tooltipPosition="top"
                        >
                            <img
                                className={permissions_dialog_hint_icon}
                                src={infoCircledIcon}
                                alt=""
                            />
                        </ShowDifftoneTooltip>
                    </div>

                    <div className={permissions_dialog_mode_controls}>
                        <div
                            className={permissions_dialog_toggler}
                            onClick={toggleMode}
                        >
                            <div
                                className={clsx(
                                    permissions_dialog_toggler_inner,
                                    {
                                        [permissions_dialog_toggler_inner_active]:
                                            !isAllMode,
                                    }
                                )}
                            />
                        </div>

                        <span
                            className={clsx(permissions_dialog_controls_text, {
                                [permissions_dialog_text_disabled]: isAllMode,
                                [permissions_dialog_text_active]: !isAllMode,
                            })}
                        >
                            Initiators
                        </span>

                        <UserIcon
                            color={isAllMode ? "GRAY" : "GREEN"}
                            className={permissions_dialog_icon}
                        />

                        <span
                            className={clsx(permissions_dialog_controls_text, {
                                [permissions_dialog_text_disabled]: isAllMode,
                                [permissions_dialog_text_active]: !isAllMode,
                            })}
                        >
                            ({visibleInitiators.length})
                        </span>
                    </div>
                    <MailingListProducer
                        styleOptions={{
                            customWidth: "327px",
                            overrideClass: clsx(permissions_dialog_mailing, {
                                [permissions_dialog_mailing_disabled]:
                                    isAllMode,
                            }),
                            mailingWrapperClassNameOverride:
                                permissions_dialog_mailing_list_wrap,
                        }}
                        nonDeletebleMailingList={sysAdmins}
                        onEnter={onEmailEnter}
                        onDelete={removeEmailFromOrganisationInitiators}
                        mailingListLocation="share"
                        mailingList={visibleInitiators}
                        disabled={false}
                        readOnlyMalingList={false}
                        inEditMode={false}
                    />
                    <div className={permissions_button_wrapper}>
                        <button
                            className={permissions_dialog_button}
                            onClick={onSaveConfirm}
                        >
                            Save
                        </button>
                    </div>
                </>
            </div>
        </Dialog>
    );
});
