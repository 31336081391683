import React from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import { UiStatus } from "@difftone/frontend-common";

import statusActive from "@difftone/assets/status-active.svg";
import statusCompleted from "@difftone/assets/status-completed.svg";
import statusDraft from "@difftone/assets/status-draft.svg";
import statusEnded from "@difftone/assets/status-ended.svg";
import statusPaused from "@difftone/assets/status-paused.svg";
import statusSaved from "@difftone/assets/status-saved.svg";
import statusOngoingReady from "@difftone/assets/status-ongoing-ready.svg";

import {
    status,
    active,
    scheduled,
    paused,
    ended,
    draft,
    completed,
    status_text,
    icon_position,
    ongoing_ready,
} from "./survey-status.module.css";

export type SurveyStatusProps = {
    surveyStatus: UiStatus;
};

export const SurveyStatus = observer((props: SurveyStatusProps) => {
    const { surveyStatus } = props;

    let textColorClass = "";
    let statusIcon = null;

    switch (surveyStatus) {
        case "ACTIVE":
            textColorClass = active;
            statusIcon = statusActive;
            break;
        case "COMPLETED":
            textColorClass = completed;
            statusIcon = statusCompleted;
            break;
        case "DRAFT":
            textColorClass = draft;
            statusIcon = statusDraft;
            break;
        case "ENDED":
            textColorClass = ended;
            statusIcon = statusEnded;
            break;
        case "PAUSED":
            textColorClass = paused;
            statusIcon = statusPaused;
            break;
        case "SAVED":
            textColorClass = scheduled;
            statusIcon = statusSaved;
            break;
        case "READY":
            textColorClass = ongoing_ready;
            statusIcon = statusOngoingReady;
            break;
        default:
            return null;
    }

    return (
        <div className={status}>
            <div className={icon_position}>
                <img src={statusIcon} />
            </div>
            <span className={clsx(textColorClass, status_text)}>
                {surveyStatus === "SAVED"
                    ? "Scheduled"
                    : surveyStatus?.toLowerCase()}
            </span>
        </div>
    );
});
