import { validateEmailAddresses } from "@difftone/validations";
import {
    EmailDuplicationValidationType,
    ValidationResult,
    ValidationType,
} from "@difftone/types";

export const isEmailUnique = (email: string, restOfEmails: string[]) => {
    if (!email) {
        return false;
    }

    const emailValidationResults: ValidationResult<ValidationType>[] =
        validateEmailAddresses([...restOfEmails, email]);

    const duplicationError = emailValidationResults.find(
        (validationError) =>
            !validationError.is_valid &&
            validationError.validation_error_code ===
                "EMAIL_ADDRESS_DUPLICATION_ERROR"
    );

    if (!duplicationError) {
        return true;
    }

    if (
        (duplicationError as ValidationResult<EmailDuplicationValidationType>)
            ?.validation_error_type!.duplication_indexes[email]?.length > 0
    ) {
        return false;
    }
};
