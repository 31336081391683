import { action } from "mobx";
import { v4 as uuidV4 } from "uuid";
import {
    SharedSurvey,
    SurveyEventAlert,
    OptionalPropertiesForDuplicateSurvey,
    SurveyContainer,
    SurveyClasses,
    OptionalPropertiesForSharedSurvey,
} from "@difftone/types";
import {
    alertsStore,
    organizationStore,
    sharedSurveyStore,
} from "@difftone/stores";

import { duplicateSurvey } from "@difftone/frontend-common";
import { scheduleShareSurveyEmail } from "@difftone/actions";

const MAX_COUNTER_FOR_SURVEY_NAME = 300;

const TEMPLATE_EMAIL_ADDRESS = "templates@difftone.com";

export const onShareSurveySend = action(
    async (
        sharedSurveyToShare: SharedSurvey,
        optionalProperties: OptionalPropertiesForDuplicateSurvey
    ) => {
        if (!optionalProperties) {
            throw Error("[onShareSurveySend]:: Could not find Elements.");
        }

        let duplicatedSurveyAlerts: SurveyEventAlert[] | null;
        switch (sharedSurveyToShare.survey.survey_class) {
            case "ONGOING":
                duplicatedSurveyAlerts = null;
                break;
            case "SIMPLE":
            default:
                duplicatedSurveyAlerts =
                    sharedSurveyToShare.survey.survey_events.flatMap(
                        (surveyEvent) =>
                            surveyEvent.event_alerts.map(
                                (alertUuid) =>
                                    alertsStore.getSurveyAlertCopyByUuid(
                                        alertUuid
                                    ) as SurveyEventAlert
                            )
                    );
                break;
        }

        const surveyContainer: SurveyContainer<SurveyClasses> = {
            survey: sharedSurveyToShare.survey,
            survey_events_alerts: duplicatedSurveyAlerts,
        };

        const _organizationUuid =
            organizationStore.getSelectedOrganizationUUID();

        if (!_organizationUuid) {
            throw Error("[Difftone]:: Can't find an organization uuid");
        }

        const duplicateSurveyContainer = duplicateSurvey(
            surveyContainer,
            _organizationUuid,
            optionalProperties
        );

        sharedSurveyToShare.survey = duplicateSurveyContainer.survey;

        sharedSurveyToShare.survey.survey_name =
            createDefaultSurveyNameToShare(sharedSurveyToShare);

        sharedSurveyToShare.survey_events_alerts =
            duplicateSurveyContainer.survey_events_alerts || [];

        sharedSurveyToShare.optionalProperties =
            optionalProperties as OptionalPropertiesForSharedSurvey;

        const sharedSurveysToAddressee =
            sharedSurveysForAddressees(sharedSurveyToShare);
        sharedSurveyToShare.shared_survey_status = "READY";

        scheduleShareSurveyEmail(sharedSurveyToShare);

        sharedSurveyStore.sendSharedSurveysToApi(
            sharedSurveysToAddressee,
            sharedSurveyToShare
        );
    }
);

const createDefaultSurveyNameToShare = (sharedSurvey: SharedSurvey) => {
    //In case that sharing to templates we save the name without prefixes
    if (sharedSurvey.addressee.includes(TEMPLATE_EMAIL_ADDRESS)) {
        return sharedSurvey.survey.survey_name;
    }

    let namePrefix = "shared";
    let copyCounter: number = 0;
    do {
        const sharedName = `${namePrefix}_${sharedSurvey.survey.survey_name}`;
        if (
            !sharedSurveyStore.sharedSurveysList.find(
                (_sharedSurvey) =>
                    _sharedSurvey.survey.survey_name === sharedName
            )
        ) {
            return sharedName;
        } else {
            copyCounter++;
            namePrefix = `shared(${copyCounter.toString()})`;
        }
    } while (copyCounter < MAX_COUNTER_FOR_SURVEY_NAME);
    return `shared_${Date.now()}_${sharedSurvey.survey.survey_name}`;
};

const sharedSurveysForAddressees = (
    sharedSurvey: SharedSurvey
): SharedSurvey[] => {
    if (!sharedSurvey.addressee.length) {
        return [];
    }

    const sharedSurveysToShare: SharedSurvey[] = sharedSurvey.addressee.map(
        (email) => {
            const readySharedSurvey: SharedSurvey = {
                uuid: uuidV4(),
                deleted: false,
                archive: false,
                survey: sharedSurvey.survey,
                addressee: [email],
                sender: sharedSurvey.sender,
                optionalProperties: sharedSurvey.optionalProperties,
                shared_survey_status: "READY_TO_BE_SEND",
                survey_events_alerts: sharedSurvey.survey_events_alerts,
                message: sharedSurvey.message || "",
                participant_type: "ADDRESEE",
                timestamp: Date.now(),
            };
            return readySharedSurvey;
        }
    );

    return sharedSurveysToShare;
};
