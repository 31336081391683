import { action } from "mobx";
import {
    AccordionTitles,
    EventAlertsDisplay,
    BaseSurvey,
    UUID,
    SurveyEvent,
    SurveyEventAlert,
    SimpleSurvey,
    SurveyClasses,
    SurveyContainer,
} from "@difftone/types";
import { alertsStore, wizardDisplayStore } from "@difftone/stores";
import {
    setFocusedWizardInputCard,
    setWizardAccordionActiveSection,
    showDifftoneAlert,
} from "@difftone/actions";
import {
    getUserEmail,
    getUserFullName,
    setItemVisibleInView,
} from "@difftone/procedures";
import { upsertSurveyToMapAndApiWithoutDebounce } from "@difftone/actions";
import {
    deserializeSurvey,
    serializeSurvey,
    surveyClassConvertor,
} from "@difftone/frontend-common";
import { constantAlertVariants } from "@difftone/constants";

export const setSurveyEventToDisplay = action((eventUUID: UUID | null) => {
    wizardDisplayStore.selectedEventUUID = eventUUID;
});

export const setSelectedInputEventTitle = action((eventUID: UUID | null) => {
    wizardDisplayStore.selectedInputEventTitle = eventUID;
});

export const setSelectedEventAlert = action(
    (eventAlertToDisplay: EventAlertsDisplay | null) => {
        wizardDisplayStore.selectedDisplayEventAlert = eventAlertToDisplay;
    }
);

export const changeCheckboxOfOngoing = action((survey: SurveyClasses) => {
    const newDraftSurvey = surveyClassConvertor(survey);
    upsertSurveyToMapAndApiWithoutDebounce(newDraftSurvey);
});

export const toggleIsOpenSurveyCheckbox = action((survey: SurveyClasses) => {
    const isOpenSurvey = !!survey.is_open_survey;
    survey.is_open_survey = !isOpenSurvey;
    upsertSurveyToMapAndApiWithoutDebounce(survey);
});

export const accordionSectionsController = (
    clickedSectionTitle: AccordionTitles
) => {
    const wrapperItem = document.querySelector(
        `div[data-scroll-element="accordion-wrapper"]`
    ) as HTMLDivElement;

    if (clickedSectionTitle !== "TIME_LINE") {
        setSelectedEventAlert(null);
    }

    switch (clickedSectionTitle) {
        case "DETAILS":
            setWizardAccordionActiveSection(null);
            setFocusedWizardInputCard(null);
            setTimeout(() => {
                const selectedFocusedCard = document.querySelector(
                    `div[data-wizard-details]`
                ) as HTMLDivElement;

                setItemVisibleInView(wrapperItem, selectedFocusedCard);
            });
            break;
        case "QUESTIONS":
            setWizardAccordionActiveSection(clickedSectionTitle);
            break;
        case "PARTICIPANTS":
            setFocusedWizardInputCard(null);
            setWizardAccordionActiveSection(clickedSectionTitle);

            setTimeout(() => {
                const selectedFocusedCard = document.querySelector(
                    `div[data-wizard-participants]`
                ) as HTMLDivElement;

                setItemVisibleInView(wrapperItem, selectedFocusedCard);
            });
            break;
        case "TIME_LINE":
            setFocusedWizardInputCard(null);
            setWizardAccordionActiveSection(clickedSectionTitle);

            setTimeout(() => {
                const selectedFocusedCard = document.querySelector(
                    `div[data-wizard-timeline]`
                ) as HTMLDivElement;

                setItemVisibleInView(wrapperItem, selectedFocusedCard);
            });
            break;
        case null:
            setWizardAccordionActiveSection(null);
            break;
        default:
            break;
    }
};

export const updateSurveyWizardCopy = action(() => {
    const surveyCopyToUpdate: SurveyClasses = JSON.parse(
        JSON.stringify(wizardDisplayStore.surveyCopyToUpdate)
    );

    wizardDisplayStore.surveyCopyToUpdate = surveyCopyToUpdate;
});

export const addWizardCopySurveyEvent = action((surveyEvent: SurveyEvent) => {
    const simpleSurvey =
        wizardDisplayStore.getWizardSurveyCopy() as SimpleSurvey;

    simpleSurvey.survey_events!.push(surveyEvent);
});

export const deleteToUpdateWizardCopySurveyEvent = action(
    (surveyEventToDelete: SurveyEvent) => {
        const survey = wizardDisplayStore.getWizardSurveyCopy() as SimpleSurvey;

        survey.survey_events = survey.survey_events.filter(
            (surveyEvent) => surveyEvent.uuid !== surveyEventToDelete.uuid
        );
    }
);

export const deleteCopyAlert = action((uuidToDelete: string) => {
    const survey = wizardDisplayStore.getWizardSurveyCopy() as SimpleSurvey;

    const surveyEvent = survey!.survey_events.find((_surveyEvent) =>
        _surveyEvent.event_alerts.includes(uuidToDelete)
    );
    const copyOfAlertToRemove: SurveyEventAlert | null =
        wizardDisplayStore.getSurveyCopyAlertCopyByUuid(uuidToDelete);

    if (!copyOfAlertToRemove || !surveyEvent) {
        showDifftoneAlert(
            constantAlertVariants.delete_an_alert.text,
            constantAlertVariants.delete_an_alert.variant
        );
        return;
    }

    copyOfAlertToRemove.is_deleted = true;
    wizardDisplayStore.insertCopyAlertToStore(copyOfAlertToRemove);
    wizardDisplayStore.addSurveyAlertToDeletedList(uuidToDelete);
    surveyEvent.event_alerts = surveyEvent.event_alerts.filter(
        (_alertUUID) => _alertUUID !== uuidToDelete
    );

    updateSurveyWizardCopy();
});

export const finalizeAndSetAlertCopyToStore = action(
    (
        alertToFinalize: SurveyEventAlert,
        survey: BaseSurvey,
        surveyEvent: SurveyEvent
    ) => {
        if (alertToFinalize.alert_type === "PUBLISHED_DATE_ALERT") {
            alertToFinalize.send_to = survey.respondents;
            alertToFinalize.content.message = survey.welcome_message || "";
        }
        if (
            alertToFinalize.alert_type === "ACTIVATION_ALERT" ||
            alertToFinalize.alert_type === "SURVEY_ENDED_NOTIFICATION_ALERT"
        ) {
            alertToFinalize.send_to = [getUserEmail()];
        }
        alertToFinalize.alert_status = "NEWLY_CREATED";
        alertToFinalize.approvers = [getUserEmail()];
        alertToFinalize.content.initiator_name = getUserFullName();
        alertToFinalize.content.initiator_email = getUserEmail();
        alertToFinalize.content.survey_name = survey.survey_name;
        alertToFinalize.event_UUID = surveyEvent.uuid;
        wizardDisplayStore.insertCopyAlertToStore(alertToFinalize);
    }
);

export const setSurveyCopyAlertToStore = action((alert: SurveyEventAlert) => {
    wizardDisplayStore.insertCopyAlertToStore(alert);
});

export const addResolvedEmailsToSurveyWizardCopy = action(
    (emails: string[]) => {
        const surveyCopyToUpdate: SurveyClasses = JSON.parse(
            JSON.stringify(wizardDisplayStore.surveyCopyToUpdate)
        );

        surveyCopyToUpdate.respondents = Array.from(
            new Set([...surveyCopyToUpdate.respondents, ...emails])
        );

        wizardDisplayStore.surveyCopyToUpdate = surveyCopyToUpdate;
    }
);

export const sortWizardCopySurveyEventsByEventNumber = action(() => {
    const survey = wizardDisplayStore.getWizardSurveyCopy() as SimpleSurvey;

    survey.survey_events = survey.survey_events?.sort(
        (a, b) => a.event_number! - b.event_number!
    );
});

export const removeResolvedEmailsFromSurveyWizardCopy = action(
    (email: string) => {
        const surveyCopyToUpdate: SurveyClasses = JSON.parse(
            JSON.stringify(wizardDisplayStore.surveyCopyToUpdate)
        );

        surveyCopyToUpdate.respondents = surveyCopyToUpdate.respondents.filter(
            (newlyAddedEmail) => newlyAddedEmail !== email
        );

        wizardDisplayStore.surveyCopyToUpdate = surveyCopyToUpdate;
    }
);

export const copySelectedSurvey = action((survey: SurveyClasses) => {
    const { surveyCopyToUpdate } = wizardDisplayStore;
    let surveyAlertsOrNull: SurveyEventAlert[] | null = [];
    switch (survey.survey_class) {
        case "ONGOING":
            surveyAlertsOrNull = null;
            break;
        case "SIMPLE":
        default:
            surveyAlertsOrNull = survey.survey_events.flatMap((surveyEvent) =>
                surveyEvent.event_alerts.map(
                    (alertUuid) =>
                        alertsStore.getSurveyAlertCopyByUuid(
                            alertUuid
                        ) as SurveyEventAlert
                )
            );
            break;
    }

    const _surveyContainer: SurveyContainer<SurveyClasses> = {
        survey: survey,
        survey_events_alerts: surveyAlertsOrNull,
    };

    const _serializedSurveyContainer = serializeSurvey(_surveyContainer);
    const surveyContainer = deserializeSurvey(_serializedSurveyContainer);

    if (!surveyCopyToUpdate) {
        wizardDisplayStore.surveyCopyToUpdate = surveyContainer.survey;
        if (surveyContainer.survey_events_alerts) {
            surveyContainer.survey_events_alerts.forEach((eventsAlerts) => {
                wizardDisplayStore.insertCopyAlertToStore(eventsAlerts);
            });
        }
    }
});

export const clearSurveyCopyFromStore = action(() => {
    wizardDisplayStore.surveyCopyToUpdate = null;
    wizardDisplayStore.surveyCopyAlerts = {};
});
