import React from "react";
import { ensureLengthOfValue } from "@difftone/actions";
import {
    first_item_text,
    second_item_text,
    item_text,
} from "./first-value-title.module.css";
const ALLOWED_FIRST_ATTRIBUTE_TITLE_LENGTH = 9;
export type FilterValueTitleProps = {
    filterValuesLength: number;
    title: string;
    isSecondHeader: boolean;
};
export const FirstValueTitle = (props: FilterValueTitleProps) => {
    const { title, isSecondHeader, filterValuesLength } = props;
    const ensureLengthOfFirstAttributeTitleValue = (headerTitle: string) => {
        if (
            headerTitle.length >
            ALLOWED_FIRST_ATTRIBUTE_TITLE_LENGTH * filterValuesLength
        ) {
            return headerTitle
                .slice(0, ALLOWED_FIRST_ATTRIBUTE_TITLE_LENGTH)
                .concat("...");
        }
        return headerTitle;
    };
    return (
        <>
            {isSecondHeader ? (
                <div className={second_item_text}>
                    <span>{ensureLengthOfFirstAttributeTitleValue(title)}</span>
                </div>
            ) : (
                <div className={first_item_text}>
                    <span className={item_text}>
                        {ensureLengthOfValue(title)}
                    </span>
                </div>
            )}
        </>
    );
};
