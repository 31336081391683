import React from "react";
import { observer } from "mobx-react";

import {
    ResultsFilter,
    SurveyClasses,
    TimeCompareUnits,
    TimeRangesContainer,
    UUID,
} from "@difftone/types";
import { TIME_FILTER_ID } from "@difftone/constants";

import { updateSelectedFilters } from "@difftone/actions";

import { FilterPicker, CompareOption } from "@difftone/shared-components";

import {
    left_menu_children,
    left_menu_compare_picker,
    left_menu_filter_picker,
    compare_picker,
    compare_container_rtl,
    compare_content_ltr,
} from "./left-menu-children-for-shared-results-graph.module.css";

export type LeftMenuChildrenForSharedResultsGraphType = {
    activeAsComparesFiltersUUIDs: UUID[];
    selectedFilters: ResultsFilter[];
    selectedSurvey: SurveyClasses;
    selectedResultsTimeCompare: TimeCompareUnits;
    selectedPopupDisplayResultsTimeCompare: TimeCompareUnits;
    getActiveFilters: (filterUuid: string) => boolean[];
    setActiveFilters: (filterUuid: string, newValues: boolean[]) => void;
    setPopupDisplayResultsTimeCompare: (
        newTimeCompare: TimeCompareUnits
    ) => void;
    setSelectedTimeCompareResultsFilterAndTimeUnits: (
        selectedFilter: TimeCompareUnits,
        timeCompareFilterUuid: string,
        timeRangesContainer: TimeRangesContainer | null
    ) => void;
    turnOffResultsFilterAsCompare: (resultsFilter: ResultsFilter) => void;
    turnOnResultsFilterAsCompare: (resultsFilter: ResultsFilter) => void;
    switchResultsFilterAsCompare: () => void;
};

export const LeftMenuChildrenForSharedResultsGraph = observer(
    (props: LeftMenuChildrenForSharedResultsGraphType) => {
        const {
            activeAsComparesFiltersUUIDs,
            selectedFilters,
            selectedSurvey,
            selectedResultsTimeCompare,
            selectedPopupDisplayResultsTimeCompare,
            getActiveFilters,
            setActiveFilters,
            setPopupDisplayResultsTimeCompare,
            setSelectedTimeCompareResultsFilterAndTimeUnits,
            turnOffResultsFilterAsCompare,
            turnOnResultsFilterAsCompare,
            switchResultsFilterAsCompare,
        } = props;

        const _resultsFiltersWithoutTimeFilter = selectedFilters.filter(
            (filter) => filter.filterKey.uuid !== TIME_FILTER_ID
        );

        let displayResultsFilters: ResultsFilter[] = [];
        let displayActiveAsComparesFiltersUUIDs: string[] = [];

        switch (selectedSurvey.survey_class) {
            case "ONGOING":
                displayResultsFilters = selectedFilters;
                displayActiveAsComparesFiltersUUIDs =
                    activeAsComparesFiltersUUIDs;
                break;
            case "SIMPLE":
            default:
                displayResultsFilters = selectedFilters.filter(
                    (filter) => filter.filterKey.uuid !== TIME_FILTER_ID
                );
                displayActiveAsComparesFiltersUUIDs =
                    activeAsComparesFiltersUUIDs.filter(
                        (filterUUID) => !filterUUID.includes(TIME_FILTER_ID)
                    );
        }

        const timeCompareFilter = displayResultsFilters.find(
            (filter) => filter.filterKey.uuid === TIME_FILTER_ID
        );

        if (!timeCompareFilter) {
            throw new Error("[Difftone] :: count find time filter");
        }

        return (
            <ul className={left_menu_children}>
                <li className={left_menu_filter_picker}>
                    <div>Filter by:</div>
                    <FilterPicker
                        resultsFilters={_resultsFiltersWithoutTimeFilter}
                        getActiveFilters={getActiveFilters}
                        setActiveFilters={setActiveFilters}
                        updateSelectedFilters={updateSelectedFilters}
                    />
                </li>
                <li className={left_menu_compare_picker}>
                    <div>Choose a time frame:</div>

                    <div className={compare_picker}>
                        <div className={compare_container_rtl}>
                            <div
                                data-compare-container
                                className={compare_content_ltr}
                            >
                                <CompareOption
                                    isWithoutNonePopupOption={true}
                                    isDisabled={false}
                                    survey={selectedSurvey}
                                    resultsFilter={timeCompareFilter}
                                    activeAsComparesFiltersUUIDs={
                                        displayActiveAsComparesFiltersUUIDs
                                    }
                                    selectedResultsTimeCompare={
                                        selectedResultsTimeCompare
                                    }
                                    selectedPopupDisplayResultsTimeCompare={
                                        selectedPopupDisplayResultsTimeCompare
                                    }
                                    setPopupDisplayResultsTimeCompare={
                                        setPopupDisplayResultsTimeCompare
                                    }
                                    setSelectedTimeCompareResultsFilterAndTimeUnits={
                                        setSelectedTimeCompareResultsFilterAndTimeUnits
                                    }
                                    resultsTurnOnFilterAsCompare={
                                        turnOnResultsFilterAsCompare
                                    }
                                    resultsTurnOffFilterAsCompare={
                                        turnOffResultsFilterAsCompare
                                    }
                                    switchResultsActiveCompareIndex={
                                        switchResultsFilterAsCompare
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        );
    }
);
