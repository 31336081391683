import {
    Anonimity,
    SurveyResponsesSummery,
    SurveyClasses,
} from "@difftone/types";

export const anonymityLimitation: { [key: string]: number } = {
    LOW: 3,
    MEDIUM: 5,
    HIGH: 8,
    NONE: 0,
};

export const isAnonymous = (
    surveyAnonymityLevel: Anonimity,
    populationSize: number
) => {
    const limitationSize = anonymityLimitation[surveyAnonymityLevel];

    switch (surveyAnonymityLevel) {
        case "LOW":
            return populationSize < limitationSize ? true : false;
        case "MEDIUM":
            return populationSize < limitationSize ? true : false;
        case "HIGH":
            return populationSize < limitationSize ? true : false;
        case "NONE":
            return false;
    }
};

export const surveyResponseAnonymity = (
    anonymityLevel: number,
    surveyResponses: SurveyResponsesSummery
) => {
    const numberOfResponses = surveyResponses.public_survey_responses.reduce(
        (accumulator, response) =>
            response.is_submit ? accumulator + 1 : accumulator,
        0
    );

    return numberOfResponses < anonymityLevel;
};

export const isDataPassAnonimityBySurveyAnonymity = (
    survey: SurveyClasses,
    populationSize: number
) => {
    const anonimityLevel = anonymityLimitation[survey.anonimity];

    return populationSize > anonimityLevel;
};
