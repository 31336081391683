import React, { KeyboardEvent, FocusEvent, ChangeEvent } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import {
    BeforeOrAfter,
    SurveyEvent,
    SurveyEventAlert,
    TimeUnits,
} from "@difftone/types";

import { DropdownElements, SimpleDropdown } from "@difftone/shared-components";
import {
    debounceSetAlertToStore,
    debounceUpsertSurveyToApi,
    showDifftoneAlert,
} from "@difftone/actions";

import {
    time_value_input,
    reminder_time_setter_wrapper,
    time_units_input,
    before_after_input,
    disabled_dropdown,
    disabled_text_value,
} from "./reminder-time-setter.module.css";
import {
    getAlertOffsetInMilliSeconds,
    totalTimeInMiliSecondsFromString,
} from "@difftone/time-utils";

const MAX_ALLOWED_ALERT_UNITS = 99;

export type ReminderTimeSetterProps = {
    eventAlert: SurveyEventAlert;
    surveyEvent: SurveyEvent;
    onChangeSurveyEventAlert?: (surveyEventAlert: SurveyEventAlert) => void;
};

export const ReminderTimeSetter = observer((props: ReminderTimeSetterProps) => {
    const { eventAlert, surveyEvent, onChangeSurveyEventAlert } = props;

    const defaultOnChangeEventAlert = (eventAlert: SurveyEventAlert) => {
        debounceSetAlertToStore(eventAlert);
        debounceUpsertSurveyToApi();
    };

    const _onChangeSurveyEventAlert =
        onChangeSurveyEventAlert || defaultOnChangeEventAlert;

    const onFocusWhenValue = (event: FocusEvent<HTMLInputElement>) => {
        if (!Number(event.currentTarget.value)) {
            event.currentTarget.select();
        }
    };

    const onKeydownHandlerWhenValue = (
        event: KeyboardEvent<HTMLInputElement>
    ) => {
        if (!Number(event.currentTarget.value)) {
            event.currentTarget.select();
        }
    };

    const checkIfNewDateisValid = (alert: SurveyEventAlert) => {
        const alertDate =
            totalTimeInMiliSecondsFromString(
                surveyEvent.date_utc!,
                surveyEvent.day_utc_time!
            ) + getAlertOffsetInMilliSeconds(alert);

        return alertDate > Date.now();
    };

    const onChangeWhenValue = (event: ChangeEvent<HTMLInputElement>) => {
        if (Number(event.currentTarget.value) > MAX_ALLOWED_ALERT_UNITS) {
            return;
        }

        const copyOfAlert: SurveyEventAlert = { ...eventAlert };

        copyOfAlert.time_offset = Number(event.currentTarget.value);

        if (!checkIfNewDateisValid(copyOfAlert)) {
            showDifftoneAlert(
                "Cannot set alert to a date in the past",
                "FAILURE"
            );
            return;
        }

        _onChangeSurveyEventAlert(copyOfAlert);
    };

    const onChangeTimeUnit = (selectedValue: string) => {
        const copyOfAlert: SurveyEventAlert = { ...eventAlert };

        const _selectedTimeUnit = selectedValue as TimeUnits;

        copyOfAlert.time_units = _selectedTimeUnit;

        if (!checkIfNewDateisValid(copyOfAlert)) {
            showDifftoneAlert(
                "Cannot set alert to a date in the past",
                "FAILURE"
            );
            return;
        }

        _onChangeSurveyEventAlert(copyOfAlert);
    };

    const onBeforeAfterChange = (updateBeforeAfter: string) => {
        const copyOfAlert: SurveyEventAlert = { ...eventAlert };

        copyOfAlert.beforeAfter = updateBeforeAfter as BeforeOrAfter;

        if (!checkIfNewDateisValid(copyOfAlert)) {
            showDifftoneAlert(
                "Cannot set alert to a date in the past",
                "FAILURE"
            );
            return;
        }
        _onChangeSurveyEventAlert(copyOfAlert);
    };

    const timeUnitsDropdownList: DropdownElements[] = [
        {
            displayValue: "Minutes",
            systemValue: "MINUTES",
        },
        {
            displayValue: "Hours",
            systemValue: "HOURS",
        },
        {
            displayValue: "Days",
            systemValue: "DAYS",
        },
    ];

    const beforeAfterDropdownList: DropdownElements[] = [
        {
            displayValue: "Before",
            systemValue: "BEFORE",
        },
        {
            displayValue: "After",
            systemValue: "AFTER",
        },
    ];

    const disabledTimeUnits = timeUnitsDropdownList.map(
        (option) => option.systemValue
    );

    const disabledBeforeAfter = beforeAfterDropdownList.map(
        (option) => option.systemValue
    );

    const isPastEventAlert = !checkIfNewDateisValid(eventAlert);

    return (
        <div className={reminder_time_setter_wrapper}>
            <input
                disabled={isPastEventAlert}
                onKeyDown={onKeydownHandlerWhenValue}
                onFocus={onFocusWhenValue}
                onChange={onChangeWhenValue}
                value={eventAlert.time_offset}
                className={time_value_input}
                type="number"
            />
            <div className={time_units_input}>
                <SimpleDropdown
                    wrapperClassName={clsx({
                        [disabled_dropdown]: isPastEventAlert,
                    })}
                    textValueClassName={clsx({
                        [disabled_text_value]: isPastEventAlert,
                    })}
                    disabled={isPastEventAlert}
                    dropdownOptions={timeUnitsDropdownList}
                    onChange={onChangeTimeUnit}
                    selectedValue={eventAlert.time_units}
                    disabledOptions={isPastEventAlert ? disabledTimeUnits : []}
                />
            </div>
            <div className={before_after_input}>
                <SimpleDropdown
                    wrapperClassName={clsx({
                        [disabled_dropdown]: isPastEventAlert,
                    })}
                    textValueClassName={clsx({
                        [disabled_text_value]: isPastEventAlert,
                    })}
                    disabled={isPastEventAlert}
                    dropdownOptions={beforeAfterDropdownList}
                    onChange={onBeforeAfterChange}
                    selectedValue={eventAlert.beforeAfter}
                    disabledOptions={
                        isPastEventAlert ? disabledBeforeAfter : []
                    }
                />
            </div>
        </div>
    );
});
