import React from "react";
import clsx from "clsx";
import { BaseSurvey } from "@difftone/types";
import infoGreenCircledIcon from "@difftone/assets/info-circled-green.svg";
import { makeStyles } from "@material-ui/core";
import { MOBILE_WIDTH_BREAKPOINT } from "@difftone/constants";

import {
    info_img,
    indicator_wrapper,
    anonymity_content,
} from "./results-anonymity-indicator.module.css";
import { ShowDifftoneTooltip } from "../show-difftone-tooltip";

const tooltipExtendPosition = makeStyles({
    tooltipRightPosition: {
        bottom: "3px",
    },
});

const extractAnonymityTooltipMessage: { [key: string]: string } = {
    LOW: "Reporting group minimum is 3",
    MEDIUM: "Reporting group minimum is 5",
    HIGH: "Reporting group minimum is 8",
    NONE: "This survey is not anonymous",
};

export type ResultsAnonymityIndicatorProps = {
    survey: BaseSurvey;
    textClassName?: string;
    wrapperClass?: string;
};

export const ResultsAnonymityIndicator = (
    props: ResultsAnonymityIndicatorProps
) => {
    const { survey } = props;

    const eventTooltipPosition = tooltipExtendPosition().tooltipRightPosition;

    const anonymityTooltipMessage: string =
        extractAnonymityTooltipMessage[survey.anonimity] ||
        "This survey is not anonymous";

    const isMobile = window.innerWidth <= MOBILE_WIDTH_BREAKPOINT;

    return (
        <div className={clsx(props.wrapperClass, indicator_wrapper)}>
            Anonymity:&nbsp;
            <div className={anonymity_content}>
                <span className={props.textClassName}>
                    {survey.anonimity?.toLowerCase()}
                </span>
                <ShowDifftoneTooltip
                    tip={anonymityTooltipMessage}
                    tooltipPosition={isMobile ? "left" : "right"}
                    extendTooltipStyle={eventTooltipPosition}
                >
                    <img
                        className={info_img}
                        src={infoGreenCircledIcon}
                        alt="info-icon"
                    />
                </ShowDifftoneTooltip>
            </div>
        </div>
    );
};
