import React, { MouseEvent } from "react";

import {
    auto_complete_item,
    active_auto_complete_item,
} from "./auto-complete-item.module.css";

export type AutoCompleteItemProps = {
    title: string;
    index: number;
    onClick: (event: MouseEvent<HTMLDivElement>) => void;
    onHover: (event: MouseEvent<HTMLDivElement>) => void;
    isActiveTitle: boolean;
};

export const AutoCompleteItem = (props: AutoCompleteItemProps) => {
    const { title, index, onClick, onHover, isActiveTitle } = props;
    return (
        <div
            onMouseDown={onClick}
            onMouseOver={onHover}
            data-autocomplete-title={title}
            data-autocomplete-index={index}
            className={[
                auto_complete_item,
                isActiveTitle && active_auto_complete_item,
            ].join(" ")}
        >
            {title}
        </div>
    );
};
