import React from "react";
import { observer } from "mobx-react";
import {
    simpleSurveyResultsStore,
    TEXT_ANSWER_SYSTEM_HEADER,
} from "@difftone/stores";
import {
    Answer,
    MultipleChoiceAnswer,
    RatingAnswer,
    ResponderToAnswer,
    SimpleResultsFilterProfile,
    SimpleResultsSortingProfile,
    SingleChoiceAnswer,
    BaseSurvey,
    SimpleResultsMap,
} from "@difftone/types";
import { getQuestionOptionsOrEmptyArray } from "@difftone/reducers";
import {
    SimpleResultsFilterButton,
    SimpleResultsSortingActionButton,
} from "@difftone/shared-components";
import {
    setSimpleResultsFitlerProfile,
    setSimpleResultsFitlerProfileFocusedColumn,
    setSimpleResultsSortingProfile,
} from "@difftone/actions";

import { SimpleSurveyHeaderData } from "./components";

import {
    question_options_as_headers,
    no_options_header,
    textual_answers_header,
    sorting_button,
} from "./question-options-as-headers.module.css";

export type QuestionOptionsAsHeadersProps = {
    survey: BaseSurvey;
    responses: SimpleResultsMap;
};

export const QuestionOptionsAsHeaders = observer(
    (props: QuestionOptionsAsHeadersProps) => {
        const { survey, responses } = props;
        const { selectedQuestion, filterProfile, sortingProfile } =
            simpleSurveyResultsStore;

        const onMouseOverHandler = () => {
            setSimpleResultsFitlerProfileFocusedColumn(
                TEXT_ANSWER_SYSTEM_HEADER
            );
        };

        const onMouseLeaveHandler = () => {
            setSimpleResultsFitlerProfileFocusedColumn("");
        };

        const onClickHandler = () => {
            const copyOfFilterProfile: SimpleResultsFilterProfile = {
                ...filterProfile,
            };
            const copyOfSortingProfile: SimpleResultsSortingProfile = {
                ...sortingProfile,
            };

            copyOfFilterProfile.filterdColumnKey =
                copyOfFilterProfile.focusedColumnKey;
            copyOfSortingProfile.sortBy = "TEXT";

            setSimpleResultsFitlerProfile(copyOfFilterProfile);

            if (sortingProfile.direction === "ASCENDING") {
                copyOfSortingProfile.direction = "DESCENDING";
                setSimpleResultsSortingProfile(copyOfSortingProfile);
            } else {
                copyOfSortingProfile.direction = "ASCENDING";
                setSimpleResultsSortingProfile(copyOfSortingProfile);
            }
        };

        const currentQuestion = survey.questions.find(
            (question) => question.uuid === selectedQuestion
        );
        if (!currentQuestion) {
            return null;
        }

        const questionOptions = getQuestionOptionsOrEmptyArray(currentQuestion);

        const currentQuestionResponses: (ResponderToAnswer | null)[] | false =
            responses[currentQuestion.uuid].filter(
                (response) =>
                    response?.answer.question_uuid === currentQuestion.uuid
            );

        const optionsAsAnswerCount: { [key: string]: number } = {};
        questionOptions.forEach((option) => {
            optionsAsAnswerCount[option] = 0;
        });

        if (questionOptions.length > 0) {
            currentQuestionResponses.forEach((response) => {
                questionOptions.forEach((option) => {
                    switch (response?.answer.answer_type_name) {
                        case "RATING":
                            if (
                                (response.answer as Answer<RatingAnswer>)
                                    .content.label === option
                            ) {
                                optionsAsAnswerCount[option] += 1;
                            }
                            break;
                        case "SINGLE_CHOICE":
                            if (
                                (response.answer as Answer<SingleChoiceAnswer>)
                                    .content.choice === option
                            ) {
                                optionsAsAnswerCount[option] += 1;
                            }
                            break;
                        case "MULTIPLE_CHOICE":
                            if (
                                (
                                    response.answer as Answer<MultipleChoiceAnswer>
                                ).content.choices[option]
                            ) {
                                optionsAsAnswerCount[option] += 1;
                            }
                            break;
                        default:
                            break;
                    }
                });
            });
        }

        const _isVisible =
            filterProfile.focusedColumnKey === TEXT_ANSWER_SYSTEM_HEADER;

        const _isActive =
            filterProfile.filterdColumnKey === TEXT_ANSWER_SYSTEM_HEADER;

        return questionOptions.length > 0 ? (
            <div className={question_options_as_headers}>
                {questionOptions.map((option, index) => (
                    <SimpleSurveyHeaderData
                        totalCompleted={currentQuestionResponses.length}
                        optionAsAnswerCount={optionsAsAnswerCount[option]}
                        option={option}
                        key={currentQuestion.uuid + option}
                        isLastHeader={index === questionOptions.length - 1}
                    />
                ))}
            </div>
        ) : (
            <div
                onMouseLeave={onMouseLeaveHandler}
                onMouseOver={onMouseOverHandler}
                className={no_options_header}
            >
                <SimpleResultsFilterButton
                    isVisible={_isVisible || _isActive}
                    columnKey={TEXT_ANSWER_SYSTEM_HEADER}
                    isFiltered={
                        !!filterProfile.filterdProfilesMap[
                            `${selectedQuestion},${TEXT_ANSWER_SYSTEM_HEADER}`
                        ]?.length
                    }
                />
                <div className={sorting_button}>
                    <SimpleResultsSortingActionButton
                        isActive={
                            filterProfile.filterdColumnKey ===
                            TEXT_ANSWER_SYSTEM_HEADER
                        }
                        onClickHandler={onClickHandler}
                        isVisible={_isVisible || _isActive}
                        direction={sortingProfile.direction}
                    />
                </div>
                <div className={textual_answers_header}>Answers</div>
            </div>
        );
    }
);
