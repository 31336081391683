import React from "react";
import savedIcon from "@difftone/assets/saved-header-status.svg";

import {
    saved_status,
    under_hover,
    top_triangle,
} from "./saved-status.module.css";

export const SavedStatus = () => {
    return (
        <div className={saved_status}>
            <img src={savedIcon} alt="saved" />
            <span>Your changes are being saved</span>
            <div className={under_hover}>
                <div className={top_triangle}></div>
                Your changes are currently being automatically saved
            </div>
        </div>
    );
};
