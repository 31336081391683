import React from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import { difftoneAlertStore } from "@difftone/stores";
import successIcon from "@difftone/assets/success-alert-icon.svg";
import failureIcon from "@difftone/assets/failure-alert-icon.svg";
import generalIcon from "@difftone/assets/disabled-alert-icon.svg";
import deleteIcon from "@difftone/assets/delete-icon.svg";
import downloadIcon from "@difftone/assets/download-alert-icon.svg";
import closeButton from "@difftone/assets/close-difftone-alert-icon.svg";
import { clearDifftoneAlertStore, closeDifftoneAlert } from "@difftone/actions";
import { AddBoxShadow } from "@difftone/shared-components";

import { AlertButton } from "./components";

import {
    wrapper,
    popup,
    popup_title,
    message_container,
    buttons_wrapper,
    general_background,
    success_background,
    failure_background,
    popup_header,
    popup_content,
    icon_section,
    message_and_buttons,
    message_section,
    buttons_section,
    object_name_bold,
    mobile_background,
} from "./difftone-alert.module.css";
import { ExternalSharePanel } from "@difftone/shared-components";

export type DifftoneAlertProps = {};

export const DifftoneAlert = observer(() => {
    const {
        alertVariant,
        isVisible,
        onRejectCallback,
        onResolveCallback,
        message,
        objectName,
        resolveButtonText,
        showSharePanel,
    } = difftoneAlertStore;

    let onResolveButtonText = resolveButtonText.length
        ? resolveButtonText
        : "Ok";

    const onRejectButtonText = "Cancel";
    let title = "Notification";
    let icon = "";
    let backgroundColor = "";
    switch (alertVariant) {
        case "GENERAL":
            backgroundColor = general_background;
            title = "Notification";
            icon = generalIcon;
            break;
        case "SUCCESS":
            backgroundColor = success_background;
            icon = successIcon;
            title = "Done";
            break;
        case "FAILURE":
            backgroundColor = failure_background;
            icon = failureIcon;
            title = "Warning";
            break;
        case "PERMISSIONS":
            backgroundColor = failure_background;
            title = "Permissions";
            icon = failureIcon;
            break;
        case "DELETE":
            backgroundColor = general_background;
            icon = deleteIcon;
            onResolveButtonText = "Delete";
            title = "Delete";
            break;
        case "DOWNLOAD":
            backgroundColor = general_background;
            icon = downloadIcon;
            title = "Download";
            break;
        case "MOBILE_SUPORT":
            backgroundColor = mobile_background;
            title = "Mobile";
            break;
        default:
            backgroundColor = general_background;
            title = "Notification";
            icon = generalIcon;
            break;
    }

    const onResolveHandler = () => {
        closeDifftoneAlert();

        const callback = onResolveCallback;
        clearDifftoneAlertStore();

        if (callback) {
            callback();
        }
    };

    const onRejectHandler = () => {
        closeDifftoneAlert();

        if (onRejectCallback) {
            onRejectCallback();
        }
        clearDifftoneAlertStore();
    };

    return isVisible ? (
        <div className={wrapper}>
            <AddBoxShadow>
                <div className={popup}>
                    <div className={clsx(popup_header, backgroundColor)}>
                        <div className={popup_title}>{title}</div>
                        <img
                            onClick={onRejectHandler}
                            src={closeButton}
                            alt="close"
                        />
                    </div>
                    <div className={popup_content}>
                        <div className={message_and_buttons}>
                            <div className={message_container}>
                                {icon && (
                                    <div className={icon_section}>
                                        <img src={icon} alt="icon" />
                                    </div>
                                )}
                                <div
                                    className={message_section}
                                    dangerouslySetInnerHTML={{
                                        __html: `
                                        ${message}
                                        ${
                                            objectName
                                                ? `
                                        <div class=${object_name_bold}>
                                            ${objectName}
                                        </div>`
                                                : ""
                                        }
                                    `,
                                    }}
                                />
                            </div>
                            <div className={buttons_wrapper}>
                                <div className={buttons_section}>
                                    <AlertButton
                                        autofocus={!onRejectCallback}
                                        onClick={onResolveHandler}
                                        variant={alertVariant}
                                        buttonText={onResolveButtonText}
                                    />
                                    {onRejectCallback ? (
                                        <AlertButton
                                            marginRight={20}
                                            onClick={onRejectHandler}
                                            isRejectBtn
                                            variant={alertVariant}
                                            buttonText={onRejectButtonText}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    {showSharePanel ? <ExternalSharePanel /> : null}
                </div>
            </AddBoxShadow>
        </div>
    ) : null;
});
