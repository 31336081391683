import React, { useState } from "react";
import { observer } from "mobx-react";
import {
    resultsMapStore,
    resultsDisplayStore,
    sharedResultStore,
    timeRangesStore,
    resultsDisplayFilterStore,
} from "@difftone/stores";
import { OpenQuestionAnswers, ResultsFilter } from "@difftone/types";
import {
    showDifftoneAlert,
    setLocation,
    setOpenedQuestionUUID,
} from "@difftone/actions";
import {
    getSurveyFromStoreByURL,
    getTimeUnitFromUrlOrNone,
} from "@difftone/reducers";
import { INBOX_URL, warningMessages } from "@difftone/constants";
import {
    OpenQuestionAnswersDialog,
    Spinner,
} from "@difftone/shared-components";

import {
    MobileResultsHeader,
    MobileAboutSurvey,
    MobileResultsQuestion,
} from "./components";

import {
    mobile_results_display_card,
    questions_section,
    green_border,
} from "./mobile-results-display-card.module.css";
//TODO: move to shared components
import { COLUMN_0 } from "@difftone/common-constants";

export type MobileResultsDisplayCardProps = {};

export const MobileResultsDisplayCard = observer(() => {
    const { openedSelectedTextQuestion } = resultsDisplayStore;
    const { extractKeyForTimeRange, getTimeRangeByKey } = timeRangesStore;

    const [mobileProducedFilters, setMobileProducedFilters] = useState<
        ResultsFilter[] | null
    >(null);

    const selectedSurvey = getSurveyFromStoreByURL();

    const timeRangeKey = extractKeyForTimeRange(
        selectedSurvey.uuid,
        getTimeUnitFromUrlOrNone()
    );

    const timeRanges = getTimeRangeByKey(timeRangeKey);

    if (timeRanges === undefined) {
        return (
            <div>
                <Spinner size="X-SMALL" />
            </div>
        );
    }

    if (timeRanges === null) {
        showDifftoneAlert(
            "Something went wrong, please reload and try again",
            "FAILURE"
        );
        return null;
    }

    if (selectedSurvey === undefined) {
        return <Spinner size="LARGE" />;
    }
    if (selectedSurvey === null) {
        setLocation(INBOX_URL);
        showDifftoneAlert(warningMessages.surveyNotFound, "FAILURE");
        return null;
    }

    //TODO: need to switch use case to getResultsTableByResultsTableMapKey
    const resultsRow = resultsMapStore.getResultsTableByFilterPlainNSurvey(
        selectedSurvey,
        resultsDisplayFilterStore.getFiltersNotActiveAsCompares(),
        resultsDisplayFilterStore.getFiltersActiveAsCompares(),
        sharedResultStore.targetSharedResult
    );

    const closeOpenQuestionAnswersDialog = () => {
        setOpenedQuestionUUID(null);
    };

    if (!resultsRow) {
        return <Spinner size="LARGE" />;
    }

    return (
        <div className={mobile_results_display_card}>
            <MobileResultsHeader survey={selectedSurvey} />
            <MobileAboutSurvey survey={selectedSurvey} />
            <div className={green_border}></div>
            <div className={questions_section}>
                {selectedSurvey.questions?.map((_question, index) => {
                    return (
                        <>
                            <MobileResultsQuestion
                                survey={selectedSurvey}
                                index={index}
                                question={_question}
                                key={_question.uuid}
                                digestedResultsRow={resultsRow[_question.uuid]}
                            />
                            {openedSelectedTextQuestion === _question.uuid ? (
                                <OpenQuestionAnswersDialog
                                    survey={selectedSurvey}
                                    question={_question}
                                    onClose={closeOpenQuestionAnswersDialog}
                                    openQuestionAnswers={
                                        resultsRow[_question.uuid][
                                            COLUMN_0
                                        ] as OpenQuestionAnswers
                                    }
                                />
                            ) : null}
                        </>
                    );
                })}
            </div>
        </div>
    );
});
