import React from "react";
import { observer } from "mobx-react";
import { TimelineEvent } from "@difftone/types";
import { alertsStore } from "@difftone/stores";

import { BellEventIcon } from "@difftone/shared-components";

import {
    event_description,
    event_title,
    event_title_text,
    event_bell,
    future_event_bell,
    event_alert_counter,
    event_time_and_date,
} from "./event-description.module.css";

export type EventDescriptionProps = {
    surveyEvent: TimelineEvent;
};

const COLOR_GRAY = "GRAY";

export const EventDescription = observer((props: EventDescriptionProps) => {
    const { surveyEvent } = props;

    const numberOfEventAlerts = surveyEvent.event_alerts.filter(
        (alertUUID) =>
            !alertsStore.getSurveyAlertCopyByUuid(alertUUID)?.alert_type
    ).length;

    return (
        <div className={event_description}>
            <div className={event_title}>
                <div className={event_title_text}>{surveyEvent.title}</div>
                <div className={event_bell}>
                    <BellEventIcon
                        className={future_event_bell}
                        color={COLOR_GRAY}
                    />
                    <div
                        className={event_alert_counter}
                    >{`(${numberOfEventAlerts})`}</div>
                </div>
            </div>

            <div className={event_time_and_date}>
                <span>{"-- --- ----"}</span>
                <span>{"-- : --"}</span>
            </div>
        </div>
    );
});
