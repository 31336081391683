import React from "react";

import { getUserFullName } from "@difftone/procedures";
import { user_label } from "./user-label.module.css";

type UserLabelProps = {
    hideUserDialog: () => void;
    showUserDialog: () => void;
};

export const UserLabel = (props: UserLabelProps) => {
    const { hideUserDialog, showUserDialog } = props;

    const initials = `${getUserFullName().split(" ")[0]?.charAt(0) || "f"}${
        getUserFullName().split(" ")[1]?.charAt(0) || "f"
    }`.toLocaleUpperCase();

    return (
        <div
            tabIndex={0}
            onBlur={hideUserDialog}
            onClick={showUserDialog}
            className={user_label}
        >
            <span>{initials}</span>
        </div>
    );
};
