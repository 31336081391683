import {
    ResultsFilter,
    ResultsMap,
    BaseSurvey,
    SharedResult,
    TimeCompareUnits,
    UUID,
    TimeRangesContainer,
} from "@difftone/types";
import { getApiBaseUrl, CATEGORY_FILTER_ID } from "@difftone/constants";
import { errorHandlerProxy, localstorageUtils } from "@difftone/procedures";
import { createDifftoneAxios } from "@difftone/services";

const SERVER_BASE_URL = `${getApiBaseUrl()}/results-map-service`;

// Category filter needs to be excluded, since it's only frontend side
const excludeCategoryFilterFromRequest = (
    filters: ResultsFilter[]
): ResultsFilter[] => {
    return filters.filter(
        ({ filterKey }) => filterKey.uuid !== CATEGORY_FILTER_ID
    );
};

export const getSharedResultsMapFromApi = async (
    sharedResult: SharedResult,
    filters: any[]
) => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.post(
        `${SERVER_BASE_URL}/shared-result`,
        {
            shared_result: JSON.stringify(sharedResult),
            results_filter: JSON.stringify(
                excludeCategoryFilterFromRequest(filters)
            ),
        },
        {
            params: {
                issuer: currentAuthData.issuer,
            },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = JSON.parse(response.data.resources);

    return resource;
};

export const getResultsMapFromApi = async (
    survey: BaseSurvey,
    filtersArray: ResultsFilter[]
): Promise<ResultsMap> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.post(
        `${SERVER_BASE_URL}/results-map`,
        {
            survey: JSON.stringify(survey),
            results_filter: JSON.stringify(
                excludeCategoryFilterFromRequest(filtersArray)
            ),
        },
        {
            params: {
                issuer: currentAuthData.issuer,
            },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = JSON.parse(response.data.resources);

    return resource;
};

export const getResultsMapFromApiForSharedResults = async (
    sharedResultsUuid: UUID,
    resultsFilters: ResultsFilter[]
): Promise<ResultsMap> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(
        `${SERVER_BASE_URL}/results-map-for-shared-results`,
        {
            params: {
                issuer: currentAuthData.issuer,
                shared_results_uuid: sharedResultsUuid,
                results_filter: JSON.stringify(resultsFilters),
            },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = JSON.parse(response.data.resources);

    return resource;
};

export const getTimeRanges = async (
    surveyUUID: UUID,
    timeUnit: TimeCompareUnits
) => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();
    const difftoneAxios = createDifftoneAxios(currentAuthData);
    const response = await difftoneAxios.get(`${SERVER_BASE_URL}/date-ranges`, {
        params: {
            issuer: currentAuthData.issuer,
            survey_uuid: surveyUUID,
            time_unit: timeUnit,
        },
    });
    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = JSON.parse(response.data.resources);

    const timeRangeContainer: TimeRangesContainer = {
        timeRanges: resource,
        surveyUuid: surveyUUID,
        timeUnit: timeUnit,
    };
    return { [surveyUUID]: timeRangeContainer };
};
