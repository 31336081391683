import { action } from "mobx";
import {
    OptionalPropertiesForDuplicateSurvey,
    SurveyClasses,
    SurveyContainer,
    SurveyEventAlert,
} from "@difftone/types";
import { duplicateSurveyToDraft } from "@difftone/actions";

import { alertsStore, organizationStore } from "@difftone/stores";
import { duplicateSurvey } from "@difftone/frontend-common";

export const finalizeDuplicateSurvey = action(
    (
        surveyClasses: SurveyClasses,
        optionalProperties: OptionalPropertiesForDuplicateSurvey
    ) => {
        if (!optionalProperties) {
            throw Error("[onShareSurveySend]:: Could not find Elements.");
        }

        let duplicatedSurveyAlerts: SurveyEventAlert[] | null;
        switch (surveyClasses.survey_class) {
            case "ONGOING":
                duplicatedSurveyAlerts = null;
                break;
            case "SIMPLE":
            default:
                duplicatedSurveyAlerts = surveyClasses.survey_events.flatMap(
                    (surveyEvent) =>
                        surveyEvent.event_alerts.map(
                            (alertUuid) =>
                                alertsStore.getSurveyAlertCopyByUuid(
                                    alertUuid
                                ) as SurveyEventAlert
                        )
                );
                break;
        }

        const surveyContainer: SurveyContainer<SurveyClasses> = {
            survey: surveyClasses,
            survey_events_alerts: duplicatedSurveyAlerts,
        };

        const _organizationUuid =
            organizationStore.getSelectedOrganizationUUID();

        if (!_organizationUuid) {
            throw Error("[Difftone]:: Can't find an organization uuid");
        }

        const duplicateSurveyContainer = duplicateSurvey(
            surveyContainer,
            _organizationUuid,
            optionalProperties
        );

        duplicateSurveyToDraft(duplicateSurveyContainer);
    }
);
