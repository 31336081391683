import React, { SVGAttributes } from "react";

import ratingIcon from "@difftone/assets/rating.svg";
import singleChoiceIcon from "@difftone/assets/single-choice.svg";
import multipleChoiceIcon from "@difftone/assets/multiple-choice.svg";
import dateIcon from "@difftone/assets/date-icon.svg";
import timeIcon from "@difftone/assets/clock-icon.svg";
import shortTextIcon from "@difftone/assets/short-text.svg";
import longTextIcon from "@difftone/assets/long-text.svg";
import { QuestionTypeName } from "@difftone/types";

const Question_type_to_icon_map: Partial<Record<QuestionTypeName, string>> = {
    RATING: ratingIcon,
    SINGLE_CHOICE: singleChoiceIcon,
    MULTIPLE_CHOICE: multipleChoiceIcon,
    DATE: dateIcon,
    TIME: timeIcon,
    SHORT_TEXT: shortTextIcon,
    LONG_TEXT: longTextIcon,
};

export type QuestionTypeIconProps = SVGAttributes<HTMLOrSVGElement> & {
    questionTypeName: QuestionTypeName;
};

export const QuestionTypeIcon = (props: QuestionTypeIconProps) => {
    const { questionTypeName, ...restProps } = props;

    const icon = Question_type_to_icon_map[questionTypeName];

    return <img alt="" src={icon} {...restProps} />;
};
