import React from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import {
    ResultsFilter,
    SurveyClasses,
    TimeCompareUnits,
    TimeRangesContainer,
    UUID,
} from "@difftone/types";

import { CompareOption } from "@difftone/shared-components";
import {
    compare_picker,
    no_attributes_to_show,
    compare_container_rtl,
    compare_content_ltr,
    compare_picker_disabled,
} from "./compare-picker.module.css";

export type ComparePickerProps = {
    resultsFilters: ResultsFilter[];
    activeAsComparesFiltersUUIDs: UUID[];
    survey: SurveyClasses;
    selectedResultsTimeCompare: TimeCompareUnits;
    selectedPopupDisplayResultsTimeCompare: TimeCompareUnits;
    setPopupDisplayResultsTimeCompare: (
        newTimeCompare: TimeCompareUnits
    ) => void;
    setSelectedTimeCompareResultsFilterAndTimeUnits: (
        selectedFilter: TimeCompareUnits,
        timeCompareFilterUuid: string,
        timeRangesContainer: TimeRangesContainer | null
    ) => void;
    resultsTurnOnFilterAsCompare: (resultsFilter: ResultsFilter) => void;
    resultsTurnOffFilterAsCompare: (resultsFilter: ResultsFilter) => void;
    switchResultsActiveCompareIndex: () => void;
    isDisabled: boolean;
};

export const ComparePicker = observer((props: ComparePickerProps) => {
    const {
        resultsFilters,
        activeAsComparesFiltersUUIDs,
        survey,
        selectedResultsTimeCompare,
        selectedPopupDisplayResultsTimeCompare,
        setPopupDisplayResultsTimeCompare,
        setSelectedTimeCompareResultsFilterAndTimeUnits,
        resultsTurnOnFilterAsCompare,
        resultsTurnOffFilterAsCompare,
        switchResultsActiveCompareIndex,
        isDisabled,
    } = props;

    if (resultsFilters.length === 0) {
        <div className={compare_picker}>
            <div className={compare_container_rtl}>
                <div data-compare-container className={compare_content_ltr}>
                    <span className={no_attributes_to_show}>None</span>
                </div>
            </div>
        </div>;
    }

    return (
        <div
            className={clsx(compare_picker, {
                [compare_picker_disabled]: isDisabled,
            })}
        >
            <div className={compare_container_rtl}>
                <div data-compare-container className={compare_content_ltr}>
                    {resultsFilters.map((filter) => (
                        <CompareOption
                            isDisabled={isDisabled}
                            survey={survey}
                            key={filter.filterKey.uuid}
                            resultsFilter={filter}
                            activeAsComparesFiltersUUIDs={
                                activeAsComparesFiltersUUIDs
                            }
                            selectedResultsTimeCompare={
                                selectedResultsTimeCompare
                            }
                            selectedPopupDisplayResultsTimeCompare={
                                selectedPopupDisplayResultsTimeCompare
                            }
                            setPopupDisplayResultsTimeCompare={
                                setPopupDisplayResultsTimeCompare
                            }
                            setSelectedTimeCompareResultsFilterAndTimeUnits={
                                setSelectedTimeCompareResultsFilterAndTimeUnits
                            }
                            resultsTurnOnFilterAsCompare={
                                resultsTurnOnFilterAsCompare
                            }
                            resultsTurnOffFilterAsCompare={
                                resultsTurnOffFilterAsCompare
                            }
                            switchResultsActiveCompareIndex={
                                switchResultsActiveCompareIndex
                            }
                        />
                    ))}
                </div>
            </div>
        </div>
    );
});
