import React, { CSSProperties } from "react";

import {
    validation_tooltip_right,
    arrow_right,
    validation_tooltip_bottom,
    arrow_bottom,
    validation_tooltip_top,
    arrow_top,
    validation_tooltip_left,
    arrow_left,
} from "./validation-tooltip.module.css";

export type ValidationTooltipProps = {
    message: string;
    position: "BOTTOM" | "LEFT" | "RIGHT" | "TOP";
    tooltipPositionOverride?: CSSProperties;
};

export const ValidationTooltip = (props: ValidationTooltipProps) => {
    const { message, position, tooltipPositionOverride } = props;

    let tooltipClass = "";
    let arrowClass = "";
    switch (position) {
        case "RIGHT":
            tooltipClass = validation_tooltip_right;
            arrowClass = arrow_right;
            break;
        case "LEFT":
            tooltipClass = validation_tooltip_left;
            arrowClass = arrow_left;
            break;
        case "BOTTOM":
            tooltipClass = validation_tooltip_bottom;
            arrowClass = arrow_bottom;
            break;
        case "TOP":
            tooltipClass = validation_tooltip_top;
            arrowClass = arrow_top;
            break;
        default:
            break;
    }

    return message ? (
        <div
            className={tooltipClass}
            style={tooltipPositionOverride && tooltipPositionOverride}
        >
            {message}
            <div className={arrowClass} />
        </div>
    ) : null;
};
