import React, { useEffect } from "react";
import { observer } from "mobx-react";

import { Spinner } from "@difftone/shared-components";
import {
    getFilterQuestionsFromSurvey,
    getSelectedOrganizationOrThrow,
    getSurveyUUIDFromUrl,
    geySurveyForAdminAndInitiatorBySurveyUuid,
} from "@difftone/reducers";
import { INBOX_URL, warningMessages } from "@difftone/constants";
import {
    showDifftoneAlert,
    setLocation,
    clearTargetSharedView,
    setSelectedTimeCompare,
    updateDisplayFilters,
    turnOffAllCompareBy,
} from "@difftone/actions";
import { categoriesStore } from "@difftone/stores";

import { SurveyClasses } from "@difftone/types";

import { SimpleSurveyResults, ComplicatedSurveyResults } from "./components";

export type ResultsProps = {};

export const Results = observer(() => {
    const surveyUuid = getSurveyUUIDFromUrl();

    //UseEffect for clean up when exiting the results page
    useEffect(() => {
        return () => {
            clearTargetSharedView();
            updateDisplayFilters([]);
            turnOffAllCompareBy();
            setSelectedTimeCompare("NONE");
        };
    }, []);

    if (surveyUuid === undefined) {
        return <Spinner size="LARGE" />;
    }

    if (surveyUuid === null) {
        setLocation(INBOX_URL);
        showDifftoneAlert(warningMessages.surveyNotFound, "FAILURE");
        return null;
    }

    const selectedSurvey =
        geySurveyForAdminAndInitiatorBySurveyUuid(surveyUuid);

    if (selectedSurvey === undefined) {
        return <Spinner size="LARGE" />;
    }

    if (selectedSurvey === null) {
        setLocation(INBOX_URL);
        showDifftoneAlert(warningMessages.surveyNotFound, "FAILURE");
        return null;
    }

    const surveyHasCategories = (survey: SurveyClasses) =>
        survey.questions.some((question) => question.categories.length);

    const calculateViewType = (
        survey: SurveyClasses
    ): "COMPLICATED" | "SIMPLE" => {
        const filterQuestions = getFilterQuestionsFromSurvey(survey);
        if (survey.survey_class === "ONGOING") return "COMPLICATED";
        if (
            !filterQuestions.length &&
            survey.anonimity === "NONE" &&
            !surveyHasCategories(survey)
        )
            return "SIMPLE";
        return "COMPLICATED";
    };

    if (!categoriesStore.isStoreInitialized && !categoriesStore.isRequesting) {
        const organization = getSelectedOrganizationOrThrow();
        const organisationUuid = organization ? organization.uuid : null;
        categoriesStore.init(organisationUuid);
        return <Spinner />;
    }

    switch (calculateViewType(selectedSurvey)) {
        case "COMPLICATED":
            return <ComplicatedSurveyResults />;
        case "SIMPLE":
            return <SimpleSurveyResults />;
        default:
            return <ComplicatedSurveyResults />;
    }
});
