import React from "react";
import { setIsFilterDialogOpen } from "@difftone/actions";
import {
    RESPONDENTS_SYSTEM_HEADER,
    TEXT_ANSWER_SYSTEM_HEADER,
} from "@difftone/stores";

import closeIcon from "@difftone/assets/close-datepicker-white.svg";
import { dialog_header, header_text } from "./dialog-header.module.css";

export type DialogHeaderProps = {
    dialogTitle: string;
};

export const DialogHeader = (props: DialogHeaderProps) => {
    const { dialogTitle } = props;

    let _dialogTitle = dialogTitle;
    switch (dialogTitle) {
        case TEXT_ANSWER_SYSTEM_HEADER:
            _dialogTitle = "Answers";
            break;
        case RESPONDENTS_SYSTEM_HEADER:
            _dialogTitle = "Respondents";
            break;
        default:
            break;
    }

    const closeFilterDialog = () => {
        setIsFilterDialogOpen(false);
    };

    return (
        <div className={dialog_header}>
            <div className={header_text}>{_dialogTitle}</div>
            <img src={closeIcon} alt="close" onClick={closeFilterDialog} />
        </div>
    );
};
