import React from "react";
import { observer } from "mobx-react";
import { BaseSurvey } from "@difftone/types";

import {
    response_rate_wrapper,
    response_rate_percent,
    response_rate_completed_total,
} from "./response-rate.module.css";
import { getSurveyResponseData } from "@difftone/reducers";

export type ResponseRateProps = {
    survey: BaseSurvey;
};

export const ResponseRate = observer((props: ResponseRateProps) => {
    const { survey } = props;
    const surveyResponseData = getSurveyResponseData(survey);
    return (
        <div className={response_rate_wrapper}>
            <div
                className={response_rate_percent}
            >{`${surveyResponseData.percentage}%`}</div>
            <div
                className={response_rate_completed_total}
            >{`${surveyResponseData.completed}/${surveyResponseData.total}`}</div>
        </div>
    );
});
