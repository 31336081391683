import React, {
    useEffect,
    useMemo,
    useRef,
    FC,
    Ref,
    PropsWithChildren,
} from "react";
import clsx from "clsx";
import { observer } from "mobx-react";

import moreIcon from "@difftone/assets/more-icon.svg";
import { constantAlertVariants, warningMessages } from "@difftone/constants";
import {
    getQueryParamsFromUrl,
    getSurveyFromStoreByUUID,
} from "@difftone/reducers";
import { Bookmark as BookmarkType, BaseSurvey } from "@difftone/types";
import {
    ExportButton,
    Spinner,
    ActionButton,
} from "@difftone/shared-components";
import {
    userProfileStore,
    resultsDisplayStore,
    navigationStore,
    publicProfileStore,
    resultsMapStore,
    sharedResultStore,
    categoriesStore,
    categoriesDisplayStore,
    resultsDisplayFilterStore,
} from "@difftone/stores";
import {
    toggleBookmarksPopper,
    toggleSharesPopper,
    toggleShareResultDialog,
    toggleActionsPopper,
    setQueryParam,
    setShareResultName,
    goToExportResultsByUrl,
    showDifftoneAlert,
} from "@difftone/actions";

import downloadIcon from "@difftone/assets/download-icon.svg";
import bookmarkIcon from "@difftone/assets/bookmark-icon.svg";
import shareResultsIcon from "@difftone/assets/share-results-colored-icon.svg";

import {
    ShareResultDialog,
    BookmarksPopup,
    SharesPopup,
    ActionsPopup,
} from "./components";

import {
    results_actions,
    results_actions_more,
    results_actions_no_export,
    results_action_export_button_hidden,
} from "./results-actions.module.css";

export interface ResultsActionsProps {
    dataCSV: (string | number)[][];
    isExportHidden?: boolean;
    isSimpleResults?: boolean;
}

const DEFAULT_PUBLIC_PROFILE = { name: "NONE" };
const MINIMUM_AMOUNT_OF_EMPTY_ROWS_AT_CSV = 2;

export const ResultsActions: FC<PropsWithChildren<ResultsActionsProps>> =
    observer(
        ({
            dataCSV,
            isExportHidden = false,
            isSimpleResults = false,
        }: ResultsActionsProps) => {
            const { categoryFilterBy } = categoriesDisplayStore;
            const { isStoreInitialized: isCategoryStoreInitialized } =
                categoriesStore;
            const params = getQueryParamsFromUrl();

            const isSharedMode = useMemo(
                () => !!params.get("sharedView"),
                [params]
            );

            const exportButton: Ref<HTMLButtonElement> = useRef(null);
            // In order to pass anchor to the Popper component
            const anchorElement = useRef(null);
            const {
                bookmarksPopperOpened,
                sharesPopperOpened,
                shareResultDialogOpened,
                actionsPopperOpened,
            } = resultsDisplayStore;
            const { currentPage } = navigationStore;
            const surveyUuid = currentPage.split("/")[2];

            const selectedSurvey: BaseSurvey = getSurveyFromStoreByUUID(
                surveyUuid
            ) as BaseSurvey;

            useEffect(() => {
                void userProfileStore.init();
            }, []);

            const initiatorPublicProfile =
                selectedSurvey && selectedSurvey.initiator
                    ? publicProfileStore.getPublicProfileByUuid(
                          selectedSurvey.initiator
                      ) || DEFAULT_PUBLIC_PROFILE
                    : DEFAULT_PUBLIC_PROFILE;

            const resultsRow =
                resultsMapStore.getResultsMapByRowsWithAnswersOnly(
                    selectedSurvey,
                    resultsDisplayFilterStore.getFiltersNotActiveAsCompares(),
                    resultsDisplayFilterStore.getFiltersActiveAsCompares()
                );

            const allResult = selectedSurvey.questions.map((question) => {
                const answers = resultsRow
                    ? Object.values(resultsRow?.[question.uuid])
                    : [];
                return [...answers];
            });

            const areResultsLoaded =
                allResult.filter((result) => result.length).length > 0;

            const exportFileName = selectedSurvey
                ? selectedSurvey.survey_name
                : "result";

            useEffect(() => {
                const params = getQueryParamsFromUrl();
                const needsExport = params.get("needsExport") === "true";

                // If the data is loaded, but csv is not yet filled with all the data
                // There will be more than 2 (MINIMUM_AMOUNT_OF_EMPTY_ROWS_AT_CSV) empty rows
                // So when there is less or equal to 2 -> we can export correct csv file
                const emptyRowsAtCSV = dataCSV.filter((row) => !row.length);
                const isCSVFilledWithData =
                    emptyRowsAtCSV.length <=
                    MINIMUM_AMOUNT_OF_EMPTY_ROWS_AT_CSV;

                if (
                    needsExport &&
                    categoryFilterBy &&
                    isCategoryStoreInitialized &&
                    areResultsLoaded &&
                    isCSVFilledWithData &&
                    initiatorPublicProfile.name
                ) {
                    setTimeout(() => {
                        if (exportButton.current) {
                            exportButton.current.click();
                            setQueryParam("needsExport", "");
                        }
                    }, 0);
                }
            }, [
                dataCSV,
                areResultsLoaded,
                initiatorPublicProfile.name,
                categoryFilterBy,
                isCategoryStoreInitialized,
            ]);

            const handleExport = () => {
                toggleBookmarksPopper(false);

                showDifftoneAlert(
                    constantAlertVariants.download_notification.text,
                    constantAlertVariants.download_notification.variant,
                    () => {
                        if (exportButton.current) {
                            exportButton.current.click();
                        }
                    },
                    () => {},
                    selectedSurvey.survey_name
                );
            };

            const handleBookmarkExport = ({ url, name }: BookmarkType) => {
                const surveyUUIDWithParams = url.split("/")[2];
                const surveyUUID = surveyUUIDWithParams.split("?")[0];

                const survey = getSurveyFromStoreByUUID(surveyUUID);
                toggleBookmarksPopper(false);

                showDifftoneAlert(
                    constantAlertVariants.download_notification.text,
                    constantAlertVariants.download_notification.variant,
                    () => goToExportResultsByUrl(url),
                    () => {},
                    survey ? survey.survey_name : "Noname survey"
                );
            };

            const handleShareView = (name: string) => {
                setShareResultName(name);
                toggleSharesPopper();
                toggleShareResultDialog();
            };

            const handleBookmarkShareView = (name: string) => {
                setShareResultName(name);
                toggleShareResultDialog();
            };

            if (!sharedResultStore.isInitializingStarted) {
                sharedResultStore.init();
                return <Spinner size="LARGE" />;
            }

            const shareButtonRestrictHandler = () => {
                if (isSharedMode) {
                    showDifftoneAlert(
                        warningMessages.youCannotShareThis,
                        "FAILURE"
                    );
                    return;
                }
                toggleSharesPopper();
            };

            return (
                <>
                    {!isExportHidden && (
                        <ExportButton
                            ref={exportButton}
                            fileName={exportFileName}
                            rootClassName={results_action_export_button_hidden}
                            dataCSV={dataCSV}
                        />
                    )}
                    <div
                        className={clsx(results_actions, {
                            [results_actions_no_export]: isExportHidden,
                        })}
                        ref={anchorElement}
                    >
                        <img
                            className={results_actions_more}
                            alt=""
                            src={moreIcon}
                            onClick={() => toggleActionsPopper()}
                        />

                        <ActionButton
                            title="Bookmark"
                            disabled={isSimpleResults}
                            iconSrc={bookmarkIcon}
                            onClick={() => toggleBookmarksPopper()}
                        />
                        {!isExportHidden && (
                            <ActionButton
                                title="Download"
                                iconSrc={downloadIcon}
                                onClick={handleExport}
                            />
                        )}
                        <ActionButton
                            title="Share"
                            iconSrc={shareResultsIcon}
                            disabled={isSharedMode || isSimpleResults}
                            onClick={shareButtonRestrictHandler}
                        />
                    </div>

                    <BookmarksPopup
                        bookmarks={userProfileStore.bookmarks}
                        anchorEl={anchorElement.current}
                        open={bookmarksPopperOpened}
                        onClose={toggleBookmarksPopper}
                        onShareView={handleBookmarkShareView}
                        onBookmarkExport={handleBookmarkExport}
                    />
                    <SharesPopup
                        shares={sharedResultStore.viewsISharedWith}
                        anchorEl={anchorElement.current}
                        open={sharesPopperOpened}
                        onClose={toggleSharesPopper}
                        onShareView={handleShareView}
                        isShareMode={isSharedMode}
                        isSimpleResults={isSimpleResults}
                    />
                    <ActionsPopup
                        onExport={handleExport}
                        openBookmarkDialog={() => toggleBookmarksPopper()}
                        openShareDialog={shareButtonRestrictHandler}
                        isExportHidden={isExportHidden}
                        isSharedMode={isSharedMode}
                        anchorEl={anchorElement.current}
                        open={actionsPopperOpened}
                        onClose={toggleActionsPopper}
                    />

                    {shareResultDialogOpened ? (
                        <ShareResultDialog
                            onClose={toggleShareResultDialog}
                            open={shareResultDialogOpened}
                        />
                    ) : null}
                </>
            );
        }
    );
