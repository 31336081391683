import {
    Category,
    ValidationResult,
    CategoryIsDeletedValidationType
} from "@difftone/types";

export const validateCategoryIsDeleted = (category: Category): ValidationResult<CategoryIsDeletedValidationType>[] => {
    return (typeof category.is_deleted === 'undefined') ?
        [{
            is_valid: false,
            validation_error_code: "CATEGORY_IS_DELETED_VALIDATION",
            validation_error_type: { category_uuid: category.uuid }
        }] :
        [{
            is_valid: true,
            validation_error_code: "CATEGORY_IS_DELETED_VALIDATION",
            validation_error_type: { category_uuid: category.uuid }
        }];
} 