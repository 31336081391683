import React, {
    ChangeEvent,
    KeyboardEvent,
    createRef,
    Ref,
    useState,
    startTransition,
} from "react";
import { observer } from "mobx-react";

import { inboxStore, navigationStore } from "@difftone/stores";
import { setFilterProfile, setLocation } from "@difftone/actions";
import clearSearchIcon from "@difftone/assets/clear-search-bar-icon.svg";
import { INBOX_URL } from "@difftone/constants";
import { SearchIcon } from "@difftone/shared-components";

import {
    quick_search_bar,
    quick_search_input_filed,
    clear_search_text,
    quick_search_bar_filler,
    search_icon_scope,
} from "./header-quick-search.module.css";

const FILTERS_KEYS = [
    "Name: ",
    "Initiator: ",
    "Issued-date: ",
    "Last-date: ",
    "Status: ",
    "Progress: ",
];

const COLOR_GRAY = "GRAY";

export const HeaderQuickSearch = observer(() => {
    const { currentPage } = navigationStore;
    const { filterProfile } = inboxStore;
    const pageLocation = currentPage.split("/")[1];
    const isOnInboxPage = currentPage.includes(INBOX_URL);
    const [startInputPosition] = useState(FILTERS_KEYS[0].length);
    const inputRef: Ref<HTMLInputElement> = createRef();
    const [quickSearchText, setQuickSearchText] = useState(
        filterProfile.NAME ? `${FILTERS_KEYS[0]}${filterProfile.NAME}` : ""
    );

    const handleSearchIconClicked = () => {
        inputRef.current?.focus();
        const caretPosition = inputRef.current?.selectionStart || 0;

        if (inputRef.current?.value === "") {
            setQuickSearchText(FILTERS_KEYS[0]);
        }
        if (caretPosition <= startInputPosition) {
            inputRef.current?.setSelectionRange(
                startInputPosition,
                startInputPosition
            );
        }
    };
    const handleSearchBarClearText = () => {
        if (quickSearchText.includes(FILTERS_KEYS[0])) {
            setFilterProfile("NAME", "");
            setQuickSearchText("");
        }
    };

    const filterProfileHandler = (value: string) => {
        switch (value) {
            case `${value.startsWith("Name: ") ? value : null}`:
                const splitted = value.split(" ");
                splitted.shift();
                const searchValue = splitted.join(" ") || "";
                setFilterProfile("NAME", searchValue);
                break;
            default:
                return;
        }
    };

    const handleOnChangeUserInput = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const caretPosition = event.target.selectionStart || 0;

        if (caretPosition < startInputPosition) {
            event.target.selectionStart = startInputPosition;
            return;
        }
        if (caretPosition < startInputPosition) {
            event.target.selectionStart = startInputPosition;
        }
        setQuickSearchText(value);
        startTransition(() => {
            filterProfileHandler(value);
        });
    };

    const onKeyDownHandler = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            if (pageLocation !== "inbox") {
                setLocation(INBOX_URL);
            }
            const { value } = event.currentTarget;

            filterProfileHandler(value);
            setQuickSearchText(value);
        }
    };
    const onKeyUpHandler = (event: KeyboardEvent<HTMLInputElement>) => {
        const caretPosition = event.currentTarget.selectionStart || 0;
        if (event.key === "ArrowLeft") {
            if (caretPosition < startInputPosition) {
                event.currentTarget.selectionStart = startInputPosition;
            }
        }
    };

    return isOnInboxPage ? (
        <div className={quick_search_bar}>
            <SearchIcon
                className={search_icon_scope}
                color={COLOR_GRAY}
                onClick={handleSearchIconClicked}
            />
            <input
                autoComplete="off"
                ref={inputRef}
                onClick={handleSearchIconClicked}
                onChange={handleOnChangeUserInput}
                onKeyDown={onKeyDownHandler}
                onKeyUp={onKeyUpHandler}
                value={quickSearchText}
                placeholder="Quick Search"
                type="text"
                className={quick_search_input_filed}
            />
            {quickSearchText ? (
                <img
                    onClick={handleSearchBarClearText}
                    className={clear_search_text}
                    src={clearSearchIcon}
                    alt="clear-text"
                />
            ) : null}
        </div>
    ) : (
        <div className={quick_search_bar_filler}></div>
    );
});
