import React from "react";
import { observer } from "mobx-react";
import {
    Question,
    QuestionToAnswerDescreteQuestion,
    QuestionType,
    ResultsMapAfterDigestion,
    SupportedQuestionResultsMapAfterDigestion,
    SupportedQuestionResultsMapAfterDigestionWithoutNulls,
    SurveyClasses,
} from "@difftone/types";
import {
    ResultsBoxFactoryByQuestionType,
    DetailedResultBoxAnonymousType,
    CategoryDetailedResultBox,
    NumericExpandedQuestion,
} from "@difftone/shared-components";
import { resultsDisplayStore } from "@difftone/stores";
import { COLUMN_0, DATA_ANONYMIZED } from "@difftone/common-constants";

import { CategoryResultBox } from "./components";
import {
    results_answer_row,
    collapsed_section,
} from "./results-answer.module.css";

const CLOSED_QUESTIONS_TYPES_NAMES = [
    "SINGLE_CHOICE",
    "MULTIPLE_CHOICE",
    "RATING",
    "LONG_TEXT",
    "SHORT_TEXT",
    "TIME",
    "DATE",
];

export type ResultsAnswerProps = {
    question: Question<QuestionType>;
    digestedResultsRow: ResultsMapAfterDigestion;
    survey: SurveyClasses;
    questionResultsRow?: ResultsMapAfterDigestion;
    isCategoryAverageRow?: boolean;
    isQuestionInsideCategory?: boolean;
    isLastQuestionInList?: boolean;
};

export const ResultsAnswer = observer((props: ResultsAnswerProps) => {
    const {
        question,
        digestedResultsRow = {},
        questionResultsRow = {},
        isCategoryAverageRow,
        isQuestionInsideCategory,
        survey,
        isLastQuestionInList,
    } = props;
    const { resultsTableQuestions } = resultsDisplayStore;

    // digestedResultsRow can be "NOT_SUPPORTED"
    if (
        digestedResultsRow &&
        digestedResultsRow[COLUMN_0] &&
        (digestedResultsRow[COLUMN_0] as QuestionToAnswerDescreteQuestion)[
            "%QUESTION_NOT_SUPPORTED%"
        ] === 0
    ) {
        return null;
    }

    const nakedDigestedResultsRow: SupportedQuestionResultsMapAfterDigestion =
        {};

    Object.keys(digestedResultsRow).forEach((columnKey) => {
        nakedDigestedResultsRow[columnKey] = JSON.parse(
            JSON.stringify(digestedResultsRow[columnKey])
        );
    });

    const selectedTableQuestion = Object.values(resultsTableQuestions).find(
        (resultsTableQuestion) =>
            resultsTableQuestion.questionUUID === question.uuid
    );

    return (
        <div className={results_answer_row}>
            {Object.keys(nakedDigestedResultsRow)?.map((columnKey, index) => {
                if (columnKey === COLUMN_0) {
                    return null;
                }

                const isAnonymous =
                    nakedDigestedResultsRow[columnKey] === DATA_ANONYMIZED;

                return (
                    <div key={columnKey}>
                        <div>
                            {isCategoryAverageRow ? (
                                <>
                                    {isAnonymous ? (
                                        <DetailedResultBoxAnonymousType
                                            color="primary"
                                            surveyAnonymityLevel={
                                                survey.anonimity
                                            }
                                        />
                                    ) : (
                                        <CategoryResultBox
                                            index={index}
                                            question={question}
                                            digestedResultsRow={
                                                nakedDigestedResultsRow as SupportedQuestionResultsMapAfterDigestionWithoutNulls
                                            }
                                            questionResultsRow={
                                                questionResultsRow as SupportedQuestionResultsMapAfterDigestionWithoutNulls
                                            }
                                            columnKey={columnKey}
                                        />
                                    )}
                                </>
                            ) : (
                                <>
                                    {isQuestionInsideCategory ? (
                                        <CategoryDetailedResultBox
                                            color="secondary"
                                            key={question.uuid}
                                            index={index}
                                            question={question}
                                            digestedResultsRow={
                                                nakedDigestedResultsRow as SupportedQuestionResultsMapAfterDigestionWithoutNulls
                                            }
                                            columnKey={columnKey}
                                            survey={survey}
                                            isLastQuestionInList={
                                                isLastQuestionInList
                                            }
                                        />
                                    ) : (
                                        <ResultsBoxFactoryByQuestionType
                                            question={question}
                                            index={index}
                                            digestedResultsRow={
                                                nakedDigestedResultsRow as SupportedQuestionResultsMapAfterDigestionWithoutNulls
                                            }
                                            columnKey={columnKey}
                                            survey={survey}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                        <div>
                            {selectedTableQuestion?.isOpen ? (
                                <div className={collapsed_section}>
                                    {CLOSED_QUESTIONS_TYPES_NAMES.includes(
                                        question.question_type_name
                                    ) ? (
                                        <NumericExpandedQuestion
                                            columnKey={columnKey}
                                            answersFrequency={
                                                nakedDigestedResultsRow[
                                                    columnKey
                                                ]
                                            }
                                            question={question}
                                            survey={survey}
                                        />
                                    ) : (
                                        <div>
                                            Nothing to display for this type of
                                            question
                                        </div>
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </div>
                );
            })}
        </div>
    );
});
