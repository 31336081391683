// Anonimity level tips
export const ANONIMITY_TIP_NONE = {
    survey: "Your answers and details will be visible to the initiator of this survey",
    wizard: "You will be able to view participants answers and details",
};
export const ANONIMITY_TIP_LOW = {
    survey: "Your answers and details will be hidden to the initiator of this survey. Results will only be used for statistical analysis where the reporting groups are larger than 3",
    wizard: "You will not be able to view participants' answers and details. Results will only be displayed for groups equal or larger than 3",
};
export const ANONIMITY_TIP_MEDIUM = {
    survey: "Your answers and details will be hidden to the initiator of this survey. Results will only be used for statistical analysis where the reporting groups are larger than 5",
    wizard: "You will not be able to view participants' answers and details. Results will only be displayed for groups equal or larger than 5",
};
export const ANONIMITY_TIP_HIGH = {
    survey: "Your answers and details will be hidden to the initiator of this survey. Results will only be used for statistical analysis where the reporting groups are larger than 8",
    wizard: "You will not be able to view participants' answers and details. Results will only be displayed for groups equal or larger than 8",
};
// simple/complicated survey tips
export const SURVEY_TIP_SIMPLE =
    "In case you just want to create a simple straightforward questionnaire.  This choice will not allow anonymity or Features and will only show a summary of the results.";
export const SURVEY_TIP_COMPLICATED =
    "In case you would like to create a survey enabling you to use Features and comparisons or add an anonymity level to it.";

// survey responder premissions tips
export const PERMISSION_TIP_EDIT =
    "Until the scheduled ending of the survey, respondents will be allowed to edit their answers.  They will also be allowed to view their answers after the survey ends.";
export const PERMISSION_TIP_VIEW =
    "Respondents are allowed to view their answers after submitting the survey.";
export const PERMISSION_TIP_DO_NOTHING =
    "Respondents are not allowed to view their answers once they have submitted the survey.";

export const ACCORDION_ATTRIBUTES_TOOLTIP =
    "Features will appear as regular questions in the survey.  They refer to a person’s features such as age, tenure, department etc. and can be compared and filtered by in the results page. Recipients will see this as a required question.";
