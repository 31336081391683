import { v4 as uuid } from "uuid";
import {
    LongTextQuestion,
    MultipleChoiceQuestion,
    OptionalChoiceAnswer,
    Question,
    QuestionType,
    RatingQuestion,
    ShortTextQuestion,
    SingleChoiceQuestion,
} from "@difftone/types";
import {
    createSingleChoiceOption,
    NEW_CHOICE_QUESTION_TEMPLATE,
    NEW_RATING_QUESTION_TEMPLATE,
    NEW_TEXT_QUESTION_TEMPLATE,
} from "@difftone/frontend-common";
import { Schema$Item, Schema$Question } from "./google-form-types";

export const convertGoogleFormsQuestions = (
    items: Schema$Item[]
): Question<QuestionType>[] => {
    const questionsArr: Question<QuestionType>[] = [];
    items.forEach((item: Schema$Item) => {
        if (!item || !item.questionItem) return;
        if (!item.questionItem.question) return;
        const question: Schema$Question = item.questionItem.question;
        if (question.scaleQuestion) {
            const convertedQuestion: Question<RatingQuestion> =
                convertGoogleFormScaleQuestion(item);
            questionsArr.push(convertedQuestion);
        }
        if (question.textQuestion) {
            const convertedQuestion: Question<
                ShortTextQuestion | LongTextQuestion
            > = convertGoogleFormTextQuestion(item);
            questionsArr.push(convertedQuestion);
        }
        if (question.choiceQuestion) {
            switch (question.choiceQuestion.type) {
                case "CHECKBOX": {
                    const convertedQuestion = convertGoogleFormChoiceQuestion(
                        item,
                        "MULTIPLE_CHOICE"
                    );
                    questionsArr.push(convertedQuestion);
                    break;
                }

                case "RADIO":
                case "DROPDOWN":
                default: {
                    const convertedQuestion = convertGoogleFormChoiceQuestion(
                        item,
                        "SINGLE_CHOICE"
                    );
                    questionsArr.push(convertedQuestion);

                    break;
                }
            }
        }
    });
    return handleDuplicateQuestionNames(questionsArr);
};

const handleDuplicateQuestionNames = (
    questionsArr: Question<QuestionType>[]
): Question<QuestionType>[] => {
    const newQuestionsArr: Question<QuestionType>[] = [];
    questionsArr.forEach((question: Question<QuestionType>) => {
        if (
            newQuestionsArr.filter(
                (newQuestion) => question.title === newQuestion.title
            ).length
        ) {
            let count = 1;
            do {
                question.title += ` (${count})`;
                count++;
            } while (
                newQuestionsArr.filter(
                    (newQuestion) => question.title === newQuestion.title
                ).length
            );
            newQuestionsArr.push(question);
        } else {
            newQuestionsArr.push(question);
        }
    });
    return newQuestionsArr;
};

const convertGoogleFormChoiceQuestion = (
    googleFormItem: Schema$Item,
    type: "SINGLE_CHOICE" | "MULTIPLE_CHOICE"
): Question<SingleChoiceQuestion | MultipleChoiceQuestion> => {
    const newEmptyQuestion: Question<
        SingleChoiceQuestion | MultipleChoiceQuestion
    > = JSON.parse(JSON.stringify(NEW_CHOICE_QUESTION_TEMPLATE));
    const question: Schema$Question = googleFormItem.questionItem!.question!;
    newEmptyQuestion.uuid = question.questionId!;
    newEmptyQuestion.required = question.required || false;
    newEmptyQuestion.title = googleFormItem.title || "";

    switch (type) {
        case "MULTIPLE_CHOICE":
            (
                newEmptyQuestion as Question<MultipleChoiceQuestion>
            ).content.choice_options = question.choiceQuestion!.options!.map(
                (option: any) => {
                    if (option.value) {
                        return option.value;
                    }
                    if (option.isOther) {
                        return "Other";
                    }
                    throw Error(
                        `[DIfftone]::convertGoogleFormChoiceQuestion failed converting question with uuid: ${question.questionId}`
                    );
                }
            );
            break;
        case "SINGLE_CHOICE":
        default:
            (
                newEmptyQuestion as Question<SingleChoiceQuestion>
            ).content.choice_options = question.choiceQuestion!.options!.map(
                (option: any) => {
                    if (option.value) {
                        const singleChoiceOption = createSingleChoiceOption();

                        singleChoiceOption.label = option.value;
                        return singleChoiceOption;
                    }
                    if (option.isOther) {
                        const singleChoiceOption = createSingleChoiceOption();

                        singleChoiceOption.label = "Other";
                        return singleChoiceOption;
                    }
                    throw Error(
                        `[DIfftone]::convertGoogleFormChoiceQuestion failed converting question with uuid: ${question.questionId}`
                    );
                }
            );
    }

    newEmptyQuestion.question_type_name = type;
    return newEmptyQuestion;
};

const convertGoogleFormScaleQuestion = (
    googleFormItem: Schema$Item
): Question<RatingQuestion> => {
    const newEmptyQuestion: Question<RatingQuestion> = JSON.parse(
        JSON.stringify(NEW_RATING_QUESTION_TEMPLATE)
    );
    const question: Schema$Question = googleFormItem.questionItem!.question!;
    newEmptyQuestion.uuid = question.questionId!;
    newEmptyQuestion.required = question.required || false;
    newEmptyQuestion.title = googleFormItem.title || "";
    newEmptyQuestion.content.rating_options = [];
    const low = question.scaleQuestion!.low || 1;
    const high = question.scaleQuestion!.high || 5;

    for (let i = low; i <= high; i++) {
        newEmptyQuestion.content.rating_options.push({
            value: i,
            label: `${i}`,
            gotos: [],
            uuid: `${i}`,
        });
    }

    return newEmptyQuestion;
};

export const convertGoogleFormTextQuestion = (
    googleFormItem: Schema$Item
): Question<ShortTextQuestion | LongTextQuestion> => {
    const newEmptyQuestion: Question<ShortTextQuestion | LongTextQuestion> =
        JSON.parse(JSON.stringify(NEW_TEXT_QUESTION_TEMPLATE));
    const question: Schema$Question = googleFormItem.questionItem!.question!;
    newEmptyQuestion.uuid = question.questionId!;
    newEmptyQuestion.required = question.required || false;
    newEmptyQuestion.title = googleFormItem.title || "";
    newEmptyQuestion.question_type_name = question.textQuestion!.paragraph
        ? "LONG_TEXT"
        : "SHORT_TEXT";
    return newEmptyQuestion;
};
