import React from "react";
import dayjs from "dayjs";

import { ShowDifftoneTooltip } from "@difftone/shared-components";

import { table_date } from "./table-date.module.css";

export type TableDateProps = {
    miliseconds: number;
};

export const TableDate = (props: TableDateProps) => {
    const { miliseconds } = props;
    const correctDateFormat = miliseconds
        ? dayjs(miliseconds).format("MMM DD, YYYY")
        : "none";

    const tooltipStringFormat = miliseconds
        ? dayjs(miliseconds).format("MMM DD, YYYY HH:mm")
        : "none";

    return (
        <div className={table_date}>
            <ShowDifftoneTooltip
                tooltipPosition="bottom"
                tip={tooltipStringFormat}
            >
                {correctDateFormat}
            </ShowDifftoneTooltip>
        </div>
    );
};
