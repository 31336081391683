import {
    Category,
    ValidationResult,
    CategoryArchivePropValidationType,
    ValidationErrorCode
} from "@difftone/types";

export const validateCategoryArchiveProp = (category: Category): ValidationResult<CategoryArchivePropValidationType>[] => {
    return (typeof category.archive === 'undefined') ?
        [{
            is_valid: false,
            validation_error_code: "CATEGORY_ARCHIVE_PROP_VALIDATION" as ValidationErrorCode,
            validation_error_type: { category_uuid: category.uuid }
        }] :
        [{
            is_valid: true,
            validation_error_code: "CATEGORY_ARCHIVE_PROP_VALIDATION",
            validation_error_type: { category_uuid: category.uuid }
        }];
} 