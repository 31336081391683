import React from "react";
import { observer } from "mobx-react";
import { TimelineEvent } from "@difftone/types";

import { distancesArray } from "@difftone/reducers";

import { TimelineEventIcon } from "./components";
import { wizard_dates } from "./wizard-dates.module.css";

export type WizardDatesProps = {
    sortedEvents: TimelineEvent[];
};

export const WizardDates = observer((props: WizardDatesProps) => {
    const { sortedEvents } = props;

    const distancesIcons = distancesArray(sortedEvents);

    return (
        <div className={wizard_dates}>
            {sortedEvents.map((surveyEvent, index) => (
                <TimelineEventIcon
                    distanceMargin={distancesIcons[index]}
                    key={surveyEvent.uuid}
                    surveyEvent={surveyEvent}
                />
            ))}
        </div>
    );
});
