import React, { MouseEvent } from "react";
import clsx from "clsx";

import {
    active_style,
    filtered_counter,
    active_counter,
    no_filtered_items,
} from "./left-menu-button.module.css";

export type LeftMenuButtonProp = {
    numberOfFilteredSurveys?: number | null;
    activeStyle?: boolean;
    title: string;
    onClick: (event: MouseEvent) => void;
};

export const LeftMenuButton = (props: LeftMenuButtonProp) => {
    const { onClick, title, activeStyle, numberOfFilteredSurveys } = props;

    const isCountlessButton =
        title === "Templates" || title === "Archived" || title === "Dashboard";

    const isEmptyFilterMenuButton =
        !numberOfFilteredSurveys && !isCountlessButton;

    return (
        <li
            onClick={onClick}
            className={clsx(
                activeStyle && active_style,
                isEmptyFilterMenuButton && no_filtered_items
            )}
        >
            {title}&nbsp;
            {numberOfFilteredSurveys ? (
                <span
                    className={clsx(
                        filtered_counter,
                        activeStyle && active_counter
                    )}
                >
                    ({numberOfFilteredSurveys})
                </span>
            ) : null}
        </li>
    );
};
