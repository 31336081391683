import { makeAutoObservable } from "mobx";

class ScheduleSurveyPopupStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _isOpen: boolean = false;
    get isOpen(): boolean {
        return this._isOpen;
    }
    set isOpen(isOpenStatus: boolean) {
        this._isOpen = isOpenStatus;
    }

    public clearStore = () => {
        this._isOpen = false;
    };
}

export const scheduleSurveyPopupStore = new ScheduleSurveyPopupStore();
