import React, { useState } from "react";
import { surveyStore } from "@difftone/stores";

import { getSurveyFromStoreByUUID } from "@difftone/reducers";
import { OngoingSurvey } from "@difftone/types";
import { upsertSurveyToMapAndApiWithoutDebounce } from "@difftone/actions";

export type AlterSurveyStartDateProps = {};

export const AlterSurveyStartDate = (props: AlterSurveyStartDateProps) => {
    const { surveysList } = surveyStore;
    const [survey, setSurvey] = useState<OngoingSurvey | null>(null);
    const [newStartDate, setNewStartDate] = useState("");

    const onAlterSurveyHandler = () => {
        if (!survey) {
            throw Error("Could not find survey.");
        }

        if (Number(newStartDate) > Date.now()) {
            throw Error(
                `[Difftone Error]:: new start date can not be in the future`
            );
        }

        survey.created_at = Number(newStartDate);

        upsertSurveyToMapAndApiWithoutDebounce(survey).then((response) =>
            alert("date successful")
        );
    };

    // @ts-ignore
    const selectSurveyHandler = (event) => {
        const surveyUuid = document.querySelector(
            "#ongoing-survey-uuid"
            // @ts-ignore
        )?.value;

        const survey = getSurveyFromStoreByUUID(surveyUuid);

        if (survey?.survey_class !== "ONGOING") {
            throw Error(
                `[difftone error]:: Survey type is not supported, survey type ${survey?.survey_class}`
            );
        }

        setSurvey(survey as OngoingSurvey);
    };

    const onChangeHandler = () => {
        const surveyStartDate = //@ts-ignore
            document.querySelector("#survey-start-date")?.value;

        setNewStartDate(surveyStartDate);
    };

    const copyCurrentTimeToClipboard = () => {
        navigator.clipboard.writeText(Date.now().toString());
    };

    const timeAsString = newStartDate ? new Date(Number(newStartDate)) : "";

    return (
        <div>
            <p>Choose Survey From The List:</p>
            <select id="ongoing-survey-uuid" onChange={selectSurveyHandler}>
                {surveysList
                    .filter((_survey) => _survey.survey_class === "ONGOING")
                    .map((survey) => (
                        <option key={survey.uuid} value={survey.uuid}>
                            {survey.survey_name}
                        </option>
                    ))}
            </select>
            <button onClick={copyCurrentTimeToClipboard}>
                copy current time to clipboard
            </button>
            <br />
            <br />
            {survey && (
                <>
                    <input
                        type="number"
                        id="survey-start-date"
                        placeholder={survey.created_at!.toString()}
                        onChange={onChangeHandler}
                        value={newStartDate}
                    />
                    <br />
                    <div>{timeAsString.toString()}</div>
                </>
            )}
            <br />
            <br />
            <button onClick={onAlterSurveyHandler} type="submit" value="Submit">
                Alter date
            </button>
        </div>
    );
};
