import React from "react";
import { observer } from "mobx-react";
import { BaseSurvey } from "@difftone/types";
import { publicProfileStore } from "@difftone/stores";
import {
    ResultsAnonymityIndicator,
    SurveyStatus,
} from "@difftone/shared-components";
import { getStatus, getSurveyResponseData } from "@difftone/reducers";

import {
    mobile_about_survey,
    status,
    purpose,
    owner,
    anonimity,
    participation,
} from "./mobile-about-survey.module.css";

export type MobileAboutSurveyProps = {
    survey: BaseSurvey;
};

export const MobileAboutSurvey = observer((props: MobileAboutSurveyProps) => {
    const { survey } = props;
    const surveyStatus = getStatus(survey);

    const initiatorPublicProfile =
        survey && survey.initiator
            ? publicProfileStore.getPublicProfileByUuid(survey.initiator)
            : null;

    const initiatorName = initiatorPublicProfile
        ? initiatorPublicProfile.name
        : "unknown";

    const surveyResponseData = getSurveyResponseData(survey);

    return (
        <div className={mobile_about_survey}>
            <div className={status}>
                <span>Status:</span>
                <SurveyStatus surveyStatus={surveyStatus} />
            </div>
            <div className={anonimity}>
                <ResultsAnonymityIndicator survey={survey} />
            </div>
            <div className={purpose}>
                <span>Purpose:</span> {survey.purpose.toLowerCase()}
            </div>
            <div className={owner}>
                <span>Owner:</span> {initiatorName}
            </div>
            <div className={participation}>
                <span>Response Rate:</span> {surveyResponseData.percentage}
                %&nbsp; | &nbsp;(
                {surveyResponseData.completed}/{surveyResponseData.percentage})
            </div>
        </div>
    );
});
