import { NO_ANSWER_KEY } from "@difftone/common-constants";
import { TIME_FILTER_ID } from "@difftone/constants";
import {
    ResultsFilter,
    SurveyClasses,
    TimeRange,
    GraphLineItem,
    Question,
    RatingQuestion,
} from "@difftone/types";
import {
    convertTimeRangeToMultiLineDisplay,
    CategoriesResultsRatingMap,
    CategoriesQuestionsMap,
} from "@difftone/reducers";
import { getAvgForRatingTypeColumn } from "@difftone/actions";

const SUMMARY_COLUMN_INDEX = 0;

export const createDataQuestionsToGraph = (
    rowsMap: any,
    timeFilter: ResultsFilter,
    selectedSurvey: SurveyClasses
) => {
    const rowsQuestionUUIDs = Object.keys(rowsMap);
    const dataGraph: GraphLineItem[] = [];
    let sizeAxisLeft: number = 0;

    const timeFilterRanges = timeFilter.filterValues as unknown as TimeRange[];

    rowsQuestionUUIDs.forEach((uuid) => {
        const columns: string[] = Object.keys(rowsMap[uuid]);

        columns.forEach((column: string, columnIndex: number) => {
            if (columnIndex !== SUMMARY_COLUMN_INDEX) {
                const cells = rowsMap[uuid][column];
                const cellsKeys: number[] = Object.values(cells);

                const cellsKeysFilterWithoutNoAnswerKey: {
                    [key: string]: number;
                } = {};

                for (const key in cells) {
                    if (key !== NO_ANSWER_KEY) {
                        cellsKeysFilterWithoutNoAnswerKey[key] = cells[key];
                    }
                }

                const avg = getAvgForRatingTypeColumn(
                    cellsKeysFilterWithoutNoAnswerKey
                );

                sizeAxisLeft =
                    cellsKeys.length > sizeAxisLeft
                        ? cellsKeys.length - 1
                        : sizeAxisLeft;

                if (avg !== 0) {
                    const question = selectedSurvey.questions.find(
                        (question) => question.uuid === uuid
                    );

                    dataGraph.push({
                        axisBottom: convertTimeRangeToMultiLineDisplay(
                            timeFilter,
                            timeFilterRanges[columnIndex - 1]
                        ),
                        xTrendLine: columnIndex,
                        axisLeft: +avg.toFixed(1),
                        legend: question?.title || "",
                    });
                }
            }
        });
    });

    return { dataGraph, sizeAxisLeft };
};

export const createDataCategoriesToGraph = (
    rowsMap: CategoriesResultsRatingMap,
    timeFilter: ResultsFilter,
    categoriesQuestionsMap: CategoriesQuestionsMap
) => {
    const rowsCategoriesUUIDs = Object.keys(rowsMap);
    const dataGraph: GraphLineItem[] = [];
    let sizeAxisLeft: number = 0;

    const timeFilterRanges = timeFilter.filterValues as unknown as TimeRange[];

    rowsCategoriesUUIDs.forEach((uuid) => {
        const columns: number[] = Object.values(rowsMap[uuid]).filter(
            (dotValue) => typeof dotValue === "number"
        ) as unknown as number[];

        const { questions } = categoriesQuestionsMap[uuid];

        const retingQuestions = questions.filter(
            (question) => question.question_type_name === "RATING"
        ) as Question<RatingQuestion>[];

        sizeAxisLeft = retingQuestions.reduce((max, question) => {
            const currenQuestionMax =
                question.content.rating_options.at(-1)?.value;
            if (!currenQuestionMax) {
                return max;
            }

            return Math.max(currenQuestionMax, max);
        }, 0);

        columns.forEach((column: number, columnIndex: number) => {
            if (columnIndex !== SUMMARY_COLUMN_INDEX) {
                dataGraph.push({
                    axisBottom: convertTimeRangeToMultiLineDisplay(
                        timeFilter,
                        timeFilterRanges[columnIndex - 1]
                    ),
                    xTrendLine: columnIndex,
                    axisLeft: column,
                    legend: categoriesQuestionsMap[uuid].categoryName,
                });
            }
        });
    });

    return { dataGraph, sizeAxisLeft };
};

export const getTimeFilterFromFilters = (allFilters: ResultsFilter[]) => {
    const timeFilterOnly = allFilters.find(
        (filter) => filter.filterKey.uuid === TIME_FILTER_ID
    );

    if (!timeFilterOnly) {
        throw Error("[Difftone]: Could not find time filter");
    }

    return timeFilterOnly;
};
