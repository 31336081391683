import { action } from "mobx";
import { EmailAddress, SharedSurvey, UUID } from "@difftone/types";
import {
    checkIfEmailIsGroupByNames,
    getSharedSurveyFromApiByUserEmailAndUUIDs,
    getSharedSurveysByDistributer,
    getSharedSurveysByEmail,
    saveSharedSurveysToApi,
    updateSharedSurveyToApi,
} from "@difftone/services";
import {
    getUserEmail,
    getUserUuid,
    localstorageUtils,
} from "@difftone/procedures";
import { sharedSurveyStore } from "./shared-survey-store";
import { showDifftoneAlert } from "@difftone/actions";

export const sharedSurveyToMapSetter = action(
    (sharedSurveys: SharedSurvey[]) => {
        sharedSurveys.forEach((sharedSurvey) => {
            upsertSharedSurveyToApi(sharedSurvey);
            sharedSurveyStore.setSharedSurveyToMap(
                sharedSurvey.uuid,
                sharedSurvey
            );
        });
    }
);

export const sharedSurveyToMapSetterFromApi = action(
    (sharedSurveys: SharedSurvey[]) => {
        sharedSurveys.forEach((sharedSurvey) => {
            sharedSurveyStore.setSharedSurveyToMap(
                sharedSurvey.uuid,
                sharedSurvey
            );
        });
    }
);

export const postSharedSurveysToApi = action(
    async (
        initiatorSharedSurvey: SharedSurvey,
        sharedSurveys: SharedSurvey[]
    ) => {
        try {
            await saveSharedSurveysToApi(initiatorSharedSurvey, sharedSurveys);

            showDifftoneAlert("Survey was successfully shared", "SUCCESS");
        } catch (error) {
            showDifftoneAlert(
                `Hi! The person you are trying to share this file with is not a registered user`,
                "FAILURE"
            );
            console.error("Could not save shared Surveys.");
        }
    }
);

export const getSharedSurveysByUserUuidNEmail = action(async () => {
    const distributerUUID: string = getUserUuid();
    const email: string = getUserEmail();

    try {
        const distributerSharedSurveys: SharedSurvey[] =
            await getSharedSurveysByDistributer(distributerUUID);

        const emailSharedSurveys: SharedSurvey[] =
            await getSharedSurveysByEmail(email);
        sharedSurveyToMapSetterFromApi([
            ...distributerSharedSurveys,
            ...emailSharedSurveys,
        ]);

        sharedSurveyStore.storeInitialize = true;
    } catch (error) {
        sharedSurveyStore.storeInitialize = true;
        console.error(`Something went wrong with fetching data. ${error}`);
        throw error;
    }
});

export const upsertSharedSurveyToApi = action(
    async (sharedSurvey: SharedSurvey) => {
        try {
            if (!sharedSurvey) {
                return;
            }

            const _sharedSurvey: SharedSurvey = Object.assign({}, sharedSurvey);

            const newUpsertedSharedSurvey: SharedSurvey =
                await updateSharedSurveyToApi(_sharedSurvey);

            return newUpsertedSharedSurvey;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);

export const sharedSurveyFromApiByUUIDs = action(
    async (sharedSurveyUUIDs: UUID[]) => {
        const email = getUserEmail();

        if (!email) {
            throw new Error(`Could not find userEmail`);
        }

        try {
            const fetchedSharedSurveys =
                await getSharedSurveyFromApiByUserEmailAndUUIDs(
                    email,
                    sharedSurveyUUIDs
                );

            if (fetchedSharedSurveys) {
                sharedSurveyToMapSetter(fetchedSharedSurveys);
            } else {
                sharedSurveyUUIDs.forEach((sharedSurveyUUID) => {
                    sharedSurveyStore.setSharedSurveyToMap(
                        sharedSurveyUUID,
                        null
                    );
                });
            }
        } catch (error) {
            console.error("Fetching shared survey failed");

            sharedSurveyUUIDs.forEach((sharedSurveyUUID) => {
                sharedSurveyStore.setSharedSurveyToMap(sharedSurveyUUID, null);
            });
        }
    }
);

export const checkIfEmailIsGroup = action(async (email: EmailAddress) => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    if (currentAuthData.issuer !== "GOOGLE") return false;

    const emailIsGroup = await checkIfEmailIsGroupByNames(email);
    if (emailIsGroup) {
        showDifftoneAlert(
            `Group emails are not currently supported`,
            "FAILURE"
        );
    }
    return emailIsGroup;
});
