import { action } from "mobx";
import {
    MultipleChoiceQuestion,
    Question,
    QuestionType,
    RatingQuestion,
    ResultsFilter,
    SingleChoiceQuestion,
    SurveyClasses,
    TimeCompareUnits,
    TimeRangesContainer,
    UUID,
} from "@difftone/types";
import { createTimeCompareFilter } from "@difftone/frontend-common";
import {
    getTimeUnitFromUrlOrMonths,
    getTimeUnitFromUrlOrNone,
} from "@difftone/reducers";
import { resultsDisplayFilterStore } from "@difftone/stores";

export const updateDisplayFilters = action(
    (displayFilters: ResultsFilter[]) => {
        resultsDisplayFilterStore.displayFilters = displayFilters;
    }
);

export const updateResultsFiltersWithTimeCompare = action(
    (timeFilter: ResultsFilter) => {
        resultsDisplayFilterStore.compareBy = [timeFilter];
    }
);

export const getFilterValuesByQuestionType = (
    question: Question<QuestionType>
) => {
    switch (question.question_type_name) {
        case "MULTIPLE_CHOICE":
            return (question as Question<MultipleChoiceQuestion>).content
                .choice_options;
        case "SINGLE_CHOICE":
            return (
                question as Question<SingleChoiceQuestion>
            ).content.choice_options.map(
                (optionalChoice) => optionalChoice.label
            );
        case "RATING":
            return (
                question as Question<RatingQuestion>
            ).content.rating_options.map((option) => option.value.toString());
        default:
            return [];
    }
};

export const getFilterValuesFromFilterQuestion = (
    question: Question<QuestionType>
) => {
    return (
        question as Question<SingleChoiceQuestion>
    ).content.choice_options.map((optionalChoice) => optionalChoice.label);
};

export const setResultsFilterByWithTimeCompare = action(
    (survey: SurveyClasses, timeRangeContainer: TimeRangesContainer) => {
        const resultFilterByFilters = survey.questions.reduce(
            (filtersArray: ResultsFilter[], question) => {
                if (question.is_filter) {
                    //Default setter for filters
                    const newFilter: ResultsFilter = {
                        filterKey: question,
                        filterValues:
                            getFilterValuesFromFilterQuestion(question),
                        isPartOfComparedPlain: false,
                        indexInComparePlain: 0,
                    };

                    filtersArray.push(newFilter);
                }
                return filtersArray;
            },
            []
        );

        const timeCompareFilter = createTimeCompareFilter(
            getTimeUnitFromUrlOrNone(),
            0,
            timeRangeContainer.timeRanges
        );

        resultFilterByFilters.push(timeCompareFilter);

        resultsDisplayFilterStore.displayFilters = resultFilterByFilters;
        resultsDisplayFilterStore.compareBy =
            checkFilterValuesLengthAndReorderCompare(resultFilterByFilters);
    }
);

const checkFilterValuesLengthAndReorderCompare = (
    resultsFilterBy: ResultsFilter[]
) => {
    const displayedFilters = resultsFilterBy.filter(
        (_filter) => _filter.isPartOfComparedPlain
    );

    if (displayedFilters.length === 2) {
        const firstDisplayedFilter = displayedFilters[0];
        const secondDisplayedFilter = displayedFilters[1];

        if (firstDisplayedFilter.filterValues.length === 1) {
            firstDisplayedFilter.indexInComparePlain = 1;
            secondDisplayedFilter.indexInComparePlain = 2;
        }
        if (secondDisplayedFilter.filterValues.length === 1) {
            secondDisplayedFilter.indexInComparePlain = 1;
            firstDisplayedFilter.indexInComparePlain = 2;
        }
    }

    return resultsFilterBy;
};

export const setDisplayResultsFilters = action(
    (filterUpdate: ResultsFilter[]) => {
        resultsDisplayFilterStore.displayFilters = filterUpdate;
    }
);

export const updateResultsFilters = action(
    (resultsFilterSUpdate: ResultsFilter[]) => {
        resultsDisplayFilterStore.compareBy =
            checkFilterValuesLengthAndReorderCompare(resultsFilterSUpdate);
    }
);

export const updateSingleResultsCompareBy = action(
    (filterToUpdate: ResultsFilter) => {
        const filterToReplace = resultsDisplayFilterStore.compareBy.find(
            (compare) =>
                compare.filterKey.uuid === filterToUpdate.filterKey.uuid
        );

        if (!filterToReplace) {
            throw Error("[Difftone]: Could not find filter to replace");
        }
        const indexOfCompareToReplace =
            resultsDisplayFilterStore.compareBy.indexOf(filterToReplace);

        const copyCompareBy: ResultsFilter[] = JSON.parse(
            JSON.stringify(resultsDisplayFilterStore.compareBy)
        );

        filterToUpdate.indexInComparePlain =
            filterToReplace.indexInComparePlain;
        filterToUpdate.isPartOfComparedPlain =
            filterToReplace.isPartOfComparedPlain;

        copyCompareBy.splice(indexOfCompareToReplace, 1, filterToUpdate);

        resultsDisplayFilterStore.compareBy = copyCompareBy;
    }
);

export const createAndUpdateTimeCompareInGraph = (
    timeRangesContainer: TimeRangesContainer
) => {
    const timeCompareFilter = createTimeCompareFilter(
        getTimeUnitFromUrlOrMonths(),
        1,
        timeRangesContainer.timeRanges
    );

    updateResultsFiltersWithTimeCompare(timeCompareFilter);

    return timeCompareFilter;
};

export const turnOffFilterAsCompare = action((resultsFilter: ResultsFilter) => {
    const { getFiltersActiveAsCompares, compareBy } = resultsDisplayFilterStore;
    const copyCompareBy: ResultsFilter[] = JSON.parse(
        JSON.stringify(compareBy)
    );
    const filterInCompareBy = copyCompareBy.find(
        (_filter) => _filter.filterKey.uuid === resultsFilter.filterKey.uuid
    );
    if (filterInCompareBy) {
        if (getFiltersActiveAsCompares().length === 1) {
            filterInCompareBy.indexInComparePlain = 0;
            filterInCompareBy.isPartOfComparedPlain = false;
        }
        if (getFiltersActiveAsCompares().length === 2) {
            filterInCompareBy.indexInComparePlain = 0;
            filterInCompareBy.isPartOfComparedPlain = false;
            copyCompareBy.find(
                (_filter) =>
                    _filter.filterKey.uuid !==
                        filterInCompareBy.filterKey.uuid &&
                    _filter.isPartOfComparedPlain
            )!.indexInComparePlain = 1;
        }

        resultsDisplayFilterStore.compareBy = copyCompareBy;
    }
});

export const turnOnFilterAsCompare = action((resultsFilter: ResultsFilter) => {
    const { getFiltersActiveAsCompares, compareBy } = resultsDisplayFilterStore;
    const copyOfCompareBy: ResultsFilter[] = JSON.parse(
        JSON.stringify(compareBy)
    );

    const questionInFilterByCopy = copyOfCompareBy.find(
        (_filter) => _filter.filterKey.uuid === resultsFilter.filterKey.uuid
    );

    const isTimeComparePartOfActiveCompares =
        questionInFilterByCopy?.filterKey.question_type_name ===
        "SYSTEM_ANSWER_TIME_FRAME"
            ? getFiltersActiveAsCompares()
                  .map((activeCompare) => activeCompare.filterKey.uuid)
                  .includes(questionInFilterByCopy.filterKey.uuid)
            : false;

    if (questionInFilterByCopy) {
        if (!getFiltersActiveAsCompares().length) {
            questionInFilterByCopy.indexInComparePlain = 1;
            questionInFilterByCopy.isPartOfComparedPlain = true;
        }
        if (
            getFiltersActiveAsCompares().length === 1 &&
            !isTimeComparePartOfActiveCompares
        ) {
            questionInFilterByCopy.indexInComparePlain = 2;
            questionInFilterByCopy.isPartOfComparedPlain = true;
        }
        if (
            getFiltersActiveAsCompares().length === 2 &&
            !isTimeComparePartOfActiveCompares
        ) {
            const filterToReplace = copyOfCompareBy.find(
                (_filter) => _filter.indexInComparePlain === 2
            );
            filterToReplace!.indexInComparePlain = 0;
            filterToReplace!.isPartOfComparedPlain = false;

            questionInFilterByCopy.isPartOfComparedPlain = true;
            questionInFilterByCopy.indexInComparePlain = 2;
        }
        resultsDisplayFilterStore.compareBy =
            checkFilterValuesLengthAndReorderCompare(copyOfCompareBy);
    }
});

export const switchActiveCompareIndex = action(() => {
    const { getFiltersActiveAsCompares, compareBy } = resultsDisplayFilterStore;
    const copyOfCompareBy: ResultsFilter[] = JSON.parse(
        JSON.stringify(compareBy)
    );
    if (getFiltersActiveAsCompares().length === 2) {
        const firstCompare = copyOfCompareBy.find(
            (_filter) => _filter.indexInComparePlain === 1
        );
        const secondCompare = copyOfCompareBy.find(
            (_filter) => _filter.indexInComparePlain === 2
        );
        firstCompare!.indexInComparePlain = 2;
        secondCompare!.indexInComparePlain = 1;

        resultsDisplayFilterStore.compareBy =
            checkFilterValuesLengthAndReorderCompare(copyOfCompareBy);
    }
});

export const setSelectedTimeCompareFilterAndTimeUnits = action(
    (
        selectedFilter: TimeCompareUnits,
        timeCompareFilterUuid: UUID,
        timeRangesContainer: TimeRangesContainer | null
    ) => {
        const { compareBy } = resultsDisplayFilterStore;
        const copyCompareBy: ResultsFilter[] = JSON.parse(
            JSON.stringify(compareBy)
        );

        const timeCompareFromDisplayFilters = copyCompareBy.find(
            (filter) => filter.filterKey.uuid === timeCompareFilterUuid
        );

        if (timeCompareFromDisplayFilters) {
            timeCompareFromDisplayFilters.filterValues = timeRangesContainer
                ? timeRangesContainer.timeRanges
                : [];

            timeCompareFromDisplayFilters.unit = selectedFilter;

            updateResultsFilters([...copyCompareBy]);

            if (selectedFilter === "NONE") {
                turnOffFilterAsCompare(timeCompareFromDisplayFilters);
            } else {
                turnOnFilterAsCompare(timeCompareFromDisplayFilters);
            }
        }

        resultsDisplayFilterStore.selectedTimeCompare = selectedFilter;
    }
);

export const selectDisplayTimeCompareInPopup = action(
    (selectedFilter: TimeCompareUnits) => {
        resultsDisplayFilterStore.popupDisplayTimeCompare = selectedFilter;
    }
);

export const setSelectedTimeCompare = action(
    (timeCompareFilter: TimeCompareUnits) => {
        resultsDisplayFilterStore.selectedTimeCompare = timeCompareFilter;
        selectDisplayTimeCompareInPopup(timeCompareFilter);
    }
);

export const selectAllFilterKeysToActive = action((questionUid: UUID) => {
    const isActiveMapByFilterKey =
        resultsDisplayFilterStore.getIsActiveFilterByUuid(questionUid);
    Object.keys(isActiveMapByFilterKey).forEach((key) => {
        isActiveMapByFilterKey[key] = true;
    });
    resultsDisplayFilterStore.isActiveDisplayFilterMapSetter(
        questionUid,
        isActiveMapByFilterKey
    );
});

export const clearAllFilterKeysToNotActive = action((questionUid: UUID) => {
    const isActiveMapByFilterKey =
        resultsDisplayFilterStore.getIsActiveFilterByUuid(questionUid);
    Object.keys(isActiveMapByFilterKey).forEach((key) => {
        isActiveMapByFilterKey[key] = false;
    });
    resultsDisplayFilterStore.isActiveDisplayFilterMapSetter(
        questionUid,
        isActiveMapByFilterKey
    );
});

export const applyLocalStateChangesToStore = action((questionUuid: UUID) => {
    const currentDisplayFilter = resultsDisplayFilterStore.displayFilters.find(
        (displayFilter) => displayFilter.filterKey.uuid === questionUuid
    );

    if (!currentDisplayFilter) {
        throw Error("[Difftone]: Could not find current display filter");
    }

    const isActiveMapByFilterKey =
        resultsDisplayFilterStore.getIsActiveFilterByUuid(
            currentDisplayFilter.filterKey.uuid
        );

    currentDisplayFilter.filterValues = [];
    Object.keys(isActiveMapByFilterKey).forEach((key) => {
        if (isActiveMapByFilterKey[key]) {
            currentDisplayFilter.filterValues.push(key);
        }
    });

    return currentDisplayFilter;
});

export const turnOffAllCompareBy = action(() => {
    const { compareBy } = resultsDisplayFilterStore;

    const copyOfCompareBy: ResultsFilter[] = JSON.parse(
        JSON.stringify(compareBy)
    );

    copyOfCompareBy.forEach((compare) => {
        compare.indexInComparePlain = 0;
        compare.isPartOfComparedPlain = false;

        if (compare.unit) {
            compare.unit = "NONE";
        }
    });
    resultsDisplayFilterStore.compareBy = copyOfCompareBy;
});
