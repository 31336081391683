import { PAGE_URLS } from "@difftone/constants";
import {
    EmailAddress,
    UUID,
    SurveyResponse,
    SurveyClasses,
} from "@difftone/types";
import { action } from "mobx";

import {
    getUserUuid,
    handleGoogleFormsPermissions,
    localstorageUtils,
} from "@difftone/procedures";
import {
    checkIfEmailIsGroupByNames,
    getFormFromGoogleById,
    getFormResponsesFromGoogleById,
    getArchivedSurveysFromApiByUserUuid,
    saveUpsertedSurveyToApi,
    getSurveysFromApiByInitiatorUuid,
    getSurveysFromApiByParticipantEmail,
    getSurveysFromApiForInitiatorBySurveyUuid,
    getSurveysFromApiForParticipantBySurveyUuid,
    getSurveyFromApiForAdminBySurveyUuid,
    getOpenSurveysFromApiForParticipantBySurveyUuid,
} from "@difftone/services";
import { surveyStore } from "@difftone/stores";
import {
    setConvertingForm,
    setLocation,
    showDifftoneAlert,
    upsertConvertedSurveyResponseToMapAndApi,
    upsertSurveyToMapAndApiWithoutDebounce,
} from "@difftone/actions";
import {
    googleFormConverter,
    googleFormResponseConverter,
} from "@difftone/frontend-common";
import { frontendValidations } from "@difftone/frontend-common/frontend-validations";
import { validateResponse } from "@difftone/validations";

export const setStoreInitialize = action((isInitialize: boolean) => {
    surveyStore.storeInitialize = isInitialize;
});

export const getSurveysByUserUuidNEmail = action(async () => {
    const initiatorSurveys = await getSurveysFromApiByInitiatorUuid();
    const emailSurveys = await getSurveysFromApiByParticipantEmail();

    [...initiatorSurveys, ...emailSurveys].forEach((survey: SurveyClasses) => {
        surveyStore.surveyToMapSetter(survey.uuid, survey);
    });
    setStoreInitialize(true);
});

export const fetchAllArchivedSurveys = action(async () => {
    const archivedSurveys = await getArchivedSurveysFromApiByUserUuid();

    archivedSurveys.forEach((survey: SurveyClasses) => {
        surveyStore.surveyToMapSetter(survey.uuid, survey);
    });

    surveyStore.storeArchivedInitialize = true;
});

export const upsertedSurveyToApi = async (
    surveyToSave: SurveyClasses | null
) => {
    try {
        if (!surveyToSave) {
            return;
        }

        const baseSurvey = Object.assign({}, surveyToSave);

        const newUpsertedSurvey = await saveUpsertedSurveyToApi(baseSurvey);

        return newUpsertedSurvey;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const getSurveysForParticipantsBySurveyUuid = action(
    async (surveyUUID: UUID) => {
        const participantsSurveys =
            await getSurveysFromApiForParticipantBySurveyUuid(surveyUUID);

        return participantsSurveys.length ? participantsSurveys[0] : null;
    }
);

const getSurveysForInitiatorBySurveyUuid = action(async (surveyUUID: UUID) => {
    const initiatorSurveys = await getSurveysFromApiForInitiatorBySurveyUuid(
        surveyUUID
    );

    return initiatorSurveys.length ? initiatorSurveys[0] : null;
});

const getSurveyForAdminBySurveyUuid = action(async (surveyUUID: UUID) => {
    const adminSurveys = await getSurveyFromApiForAdminBySurveyUuid(surveyUUID);

    return adminSurveys.length ? adminSurveys[0] : null;
});

const getOpenSurveysForParticipantsBySurveyUuid = action(
    async (surveyUUID: UUID) => {
        const participantsOpenSurveys =
            await getOpenSurveysFromApiForParticipantBySurveyUuid(surveyUUID);

        return participantsOpenSurveys.length
            ? participantsOpenSurveys[0]
            : null;
    }
);

export const surveyFromApiByUUID = action(async (surveyUUID: UUID) => {
    if (!surveyUUID) {
        throw new Error(`survey UUID is ${surveyUUID}`);
    }

    try {
        const initiatorSurvey = await getSurveysForInitiatorBySurveyUuid(
            surveyUUID
        );

        const participantSurvey = await getSurveysForParticipantsBySurveyUuid(
            surveyUUID
        );

        const adminSurvey = await getSurveyForAdminBySurveyUuid(surveyUUID);

        const fetchedSurveys = [
            initiatorSurvey,
            participantSurvey,
            adminSurvey,
        ].filter((survey) => survey);

        if (fetchedSurveys.length) {
            surveyStore.surveyToMapSetter(surveyUUID, fetchedSurveys[0]);
        } else {
            surveyStore.surveyToMapSetter(surveyUUID, null);
        }
    } catch (error) {
        console.error("Fetching failed", error);
        surveyStore.surveyToMapSetter(surveyUUID, null);
    }
});

export const openSurveyFromApiByUUID = action(async (surveyUUID: UUID) => {
    if (!surveyUUID) {
        throw new Error(`survey UUID is ${surveyUUID}`);
    }

    try {
        const participantOpenSurvey =
            await getOpenSurveysForParticipantsBySurveyUuid(surveyUUID);

        const fetchedSurveys = [participantOpenSurvey].filter(
            (survey) => survey
        );

        if (fetchedSurveys.length) {
            surveyStore.surveyToMapSetter(surveyUUID, fetchedSurveys[0]);
        } else {
            surveyStore.surveyToMapSetter(surveyUUID, null);
        }
    } catch (error) {
        console.error("Fetching failed", error);
        surveyStore.surveyToMapSetter(surveyUUID, null);
    }
});

export const checkIfEmailIsGroup = async (email: EmailAddress) => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    if (currentAuthData.issuer !== "GOOGLE") return false;

    const emailIsGroup = await checkIfEmailIsGroupByNames(email);
    if (emailIsGroup) {
        showDifftoneAlert(
            `Group emails are not currently supported`,
            "FAILURE"
        );
    }
    return emailIsGroup;
};

export const convertAndInsertGoogleFormToStore = async (
    formId: string,
    withResults: boolean
) => {
    try {
        await handleGoogleFormsPermissions();
        const googleFormObject = await getFormFromGoogleById(formId);

        if (googleFormObject) {
            const newConvertedSurvey = googleFormConverter(googleFormObject);
            const { validateSurvey } = frontendValidations;
            const { surveysList } = surveyStore;

            const restOfSurveys = surveysList.filter(
                (_survey) =>
                    _survey.initiator === getUserUuid() &&
                    _survey.uuid !== newConvertedSurvey.uuid &&
                    _survey.status !== "DRAFT"
            );
            const surveyValidationResults = validateSurvey(
                newConvertedSurvey,
                restOfSurveys
            );

            const surveyIsInvalid = surveyValidationResults.find(
                (validation) => !validation.is_valid
            );

            if (surveyIsInvalid) {
                console.error(`Survey is invalid`, surveyValidationResults);
                throw new Error("Survey validation failed");
            } else {
                upsertSurveyToMapAndApiWithoutDebounce(newConvertedSurvey);
            }

            if (withResults) {
                const googleFormResponses =
                    await getFormResponsesFromGoogleById(formId);
                const convertedResponses: SurveyResponse[] =
                    googleFormResponseConverter(
                        googleFormObject,
                        googleFormResponses.responses
                    );
                //TODO: refactor responses upsert chain to take array
                for (const response of convertedResponses) {
                    const validationResult = validateResponse(
                        newConvertedSurvey,
                        response
                    );

                    const responseIsInvalid = validationResult.find(
                        (validation) => !validation.is_valid
                    );

                    if (responseIsInvalid) {
                        console.error(
                            `Survey response is invalid`,
                            validationResult
                        );
                        throw new Error("Survey response validation failed");
                    } else {
                        upsertConvertedSurveyResponseToMapAndApi(response);
                    }
                }
            }
        } else {
            throw new Error("Error getting Google Form data");
        }

        setConvertingForm(false);
        showDifftoneAlert(
            `Your survey has been imported successfully`,
            "SUCCESS",
            () => setLocation(PAGE_URLS.INBOX_ONGOING)
        );
    } catch (err) {
        setConvertingForm(false);
        console.error(err);
        showDifftoneAlert(
            `There was a problem converting your survey:\n ${
                (err as Error).message
            }`,
            "FAILURE"
        );
    }
};
