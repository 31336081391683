import React, {
    forwardRef,
    FC,
    ButtonHTMLAttributes,
    Ref,
    PropsWithChildren,
} from "react";
import clsx from "clsx";
import { CSVLink } from "react-csv";

import { export_button } from "./export-button.module.css";

export interface ExportButtonProps
    extends ButtonHTMLAttributes<HTMLButtonElement> {
    ref?: Ref<HTMLButtonElement>;
    dataCSV: (string | number)[][];
    fileName?: string;
    rootClassName?: string;
}

export const ExportButton: FC<PropsWithChildren<ExportButtonProps>> =
    forwardRef(
        (
            {
                dataCSV,
                fileName,
                rootClassName,
                ...restProps
            }: ExportButtonProps,
            ref: Ref<HTMLButtonElement>
        ) => {
            return (
                <CSVLink
                    className={clsx(export_button, rootClassName)}
                    data={dataCSV}
                    filename={`${fileName}.csv`}
                >
                    <button ref={ref} {...restProps} />
                </CSVLink>
            );
        }
    );
