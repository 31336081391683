import React from "react";

import {
    dashboard_cell_wrapper,
    dashboard_cell_header,
    dashboard_cell_value,
} from "./dashboard-cell.module.css";

export type DashboardCellProps = {
    header: string;
    value: string;
};

export const DashboardCell = (props: DashboardCellProps) => {
    const { header, value } = props;
    return (
        <div className={dashboard_cell_wrapper}>
            <div className={dashboard_cell_header}>{header}</div>
            <div className={dashboard_cell_value}>{value}</div>
        </div>
    );
};
