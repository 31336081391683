import {
    focusedCalendar,
    DashboardFilter,
    UUID,
    DashboardFilterStatus,
    DashboardSortingTypes,
} from "@difftone/types";
import { ViewMode } from "gantt-task-react";
import { makeAutoObservable } from "mobx";

export type DashboardSortingProfile = {
    sortBy: DashboardSortingTypes;
    ascending: boolean;
};

class DashboardDisplayStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _dashboardFocusedCalendar: focusedCalendar | null = null;

    get dashboardFocusedCalendar() {
        return this._dashboardFocusedCalendar;
    }

    public _isShowOnlyActiveSurveys: boolean = false;

    get isShowOnlyActiveSurveys() {
        return this._isShowOnlyActiveSurveys;
    }

    set isShowOnlyActiveSurveys(isActive: boolean) {
        this._isShowOnlyActiveSurveys = isActive;
    }

    set dashboardFocusedCalendar(divElement: focusedCalendar | null) {
        this._dashboardFocusedCalendar = divElement;
    }

    private _isOpenedInitiatorListPopup: boolean = false;

    get isOpenedInitiatorListPopup() {
        return this._isOpenedInitiatorListPopup;
    }

    set isOpenedInitiatorListPopup(isOpen: boolean) {
        this._isOpenedInitiatorListPopup = isOpen;
    }

    private _dashboardDraftFilter: DashboardFilter | null = null;

    get dashboardDraftFilter() {
        return this._dashboardDraftFilter;
    }

    set dashboardDraftFilter(newDashboardDraftFilter: DashboardFilter | null) {
        this._dashboardDraftFilter = newDashboardDraftFilter;
    }

    private _dashboardReadyFilter: DashboardFilter | null = null;

    get dashboardReadyFilter() {
        return this._dashboardReadyFilter;
    }

    set dashboardReadyFilter(newDashboardFilter: DashboardFilter | null) {
        this._dashboardReadyFilter = newDashboardFilter;
    }

    private _filtersStatusMode: DashboardFilterStatus = "DRAFT_MODE";

    get filtersStatusMode() {
        return this._filtersStatusMode;
    }

    set filtersStatusMode(updateMode: DashboardFilterStatus) {
        this._filtersStatusMode = updateMode;
    }

    private _selectedInitiatorsList: UUID[] = [];

    get selectedInitiatorsList() {
        return this._selectedInitiatorsList;
    }

    set selectedInitiatorsList(updatedList: UUID[]) {
        this._selectedInitiatorsList = updatedList;
    }

    private _filterViewMode: ViewMode = ViewMode.Day;

    get filterViewMode() {
        return this._filterViewMode;
    }

    set filterViewMode(newFilterViewMode: ViewMode) {
        this._filterViewMode = newFilterViewMode;
    }

    private _sortingProfile: DashboardSortingProfile = {
        sortBy: "END",
        ascending: true,
    };
    get sortingProfile() {
        return this._sortingProfile;
    }
    set sortingProfile(newSortingProfile: DashboardSortingProfile) {
        this._sortingProfile = newSortingProfile;
    }

    public clearStore() {
        this._dashboardFocusedCalendar = null;
        this._selectedInitiatorsList = [];
        this._sortingProfile = {
            sortBy: "END",
            ascending: false,
        };
    }
}

export const dashboardDisplayStore = new DashboardDisplayStore();
