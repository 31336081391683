import React, { ChangeEvent } from "react";
import {
    Answer,
    DateAnswer,
    LongTextAnswer,
    ResponderToAnswer,
    TimeAnswer,
} from "@difftone/types";
import {
    getLocalDayTimeInMils,
    milisecondsToTimeString,
    millisecondsToDateStringDisplay,
} from "@difftone/time-utils";

import {
    non_binary_options,
    checked_choice_style,
    custom_checkbox_class,
    response_text,
} from "./non-binary-options.module.css";

export type NonBinaryOptionsProps = {
    responses: ResponderToAnswer[];
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    excludedItemsList: string[];
    userSearch: string;
};

export const NonBinaryOptions = (props: NonBinaryOptionsProps) => {
    const { responses, onChange, excludedItemsList, userSearch } = props;

    const extractNonBinaryResponseByAnswerType = (
        response: ResponderToAnswer
    ) => {
        switch (response.answer.answer_type_name) {
            case "LONG_TEXT":
            case "SHORT_TEXT":
                return (response.answer as Answer<LongTextAnswer>).content.text;

            case "DATE":
                const dateAnswerValue = (response.answer as Answer<DateAnswer>)
                    .content.date;
                return millisecondsToDateStringDisplay(dateAnswerValue);

            case "TIME":
                const timeAnswerValue = (response.answer as Answer<TimeAnswer>)
                    .content.time;

                const localDayTime = getLocalDayTimeInMils(timeAnswerValue);
                return milisecondsToTimeString(localDayTime);

            default:
                return "ERR";
        }
    };

    return (
        <div className={non_binary_options}>
            <ul>
                {responses
                    .filter((response) =>
                        extractNonBinaryResponseByAnswerType(response)
                            .toLowerCase()
                            .includes(userSearch.toLowerCase())
                    )
                    .map((responseValue, index) => {
                        const mappedValue =
                            extractNonBinaryResponseByAnswerType(responseValue);
                        const isChecked =
                            !excludedItemsList.includes(mappedValue);

                        return (
                            <li key={mappedValue}>
                                <input
                                    onChange={onChange}
                                    data-id={mappedValue}
                                    id={mappedValue}
                                    type="checkbox"
                                    checked={isChecked}
                                />
                                <label
                                    className={
                                        isChecked
                                            ? checked_choice_style
                                            : custom_checkbox_class
                                    }
                                    htmlFor={mappedValue}
                                ></label>
                                <label
                                    title={mappedValue}
                                    className={response_text}
                                    htmlFor={mappedValue}
                                >
                                    {mappedValue}
                                </label>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};
