import React from "react";
import { observer } from "mobx-react";

import OutsideClickHandler from "react-outside-click-handler";
import { PublicProfile, UUID } from "@difftone/types";
import { AddBoxShadow } from "@difftone/shared-components";
import { addInitiatorsToList } from "@difftone/actions";
import { getUserUuid } from "@difftone/procedures";

import { InitiatorsFilterButton, InitiatorsListItem } from "./components";

import closeIcon from "@difftone/assets/close-datepicker-white.svg";

import {
    initiator_list_checkbox_wrapper,
    initiator_list_header,
    list_checkbox_container,
    selection_buttons,
    list_items_container,
} from "./initiators-list-checkbox.module.css";

export type InitiatorListCheckboxProps = {
    publicProfileList: PublicProfile[];
    hideList: () => void;
};

export const InitiatorListCheckbox = observer(
    (props: InitiatorListCheckboxProps) => {
        const { publicProfileList, hideList } = props;

        //TODO: add to span onCLick https://app.asana.com/0/1201346022025578/1202469043796007/f
        const selectAllInitiators = () => {
            const uuidsList: UUID[] = publicProfileList.map(
                (publicProfile) => publicProfile.uuid
            );
            addInitiatorsToList(uuidsList);
        };

        const userPublicProfile = publicProfileList.find(
            (publicProfile) => publicProfile.uuid === getUserUuid()
        )!;

        return (
            <OutsideClickHandler onOutsideClick={hideList}>
                <AddBoxShadow>
                    <div className={initiator_list_checkbox_wrapper}>
                        <div className={initiator_list_header}>
                            <span>Initiator</span>
                            <img
                                src={closeIcon}
                                onClick={hideList}
                                alt="close-icon"
                            />
                        </div>
                        <div className={list_checkbox_container}>
                            <div className={selection_buttons}>
                                <span>Select All</span>
                                &nbsp;-&nbsp;
                                <span>Clear</span>
                            </div>
                            <div className={list_items_container}>
                                {/* TODO: current use case is only using user public profile. task: https://app.asana.com/0/1201346022025578/1202469043796007/f */}
                                <InitiatorsListItem
                                    key={userPublicProfile.uuid}
                                    publicProfile={userPublicProfile}
                                />
                            </div>
                            <InitiatorsFilterButton onClose={hideList} />
                        </div>
                    </div>
                </AddBoxShadow>
            </OutsideClickHandler>
        );
    }
);
