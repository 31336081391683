import { v4 as uuid } from "uuid";
import {
    Answer,
    AnswerType,
    LongTextAnswer,
    MultipleChoiceAnswer,
    RatingAnswer,
    ShortTextAnswer,
    SingleChoiceAnswer,
} from "@difftone/types";
import {
    Schema$Answer,
    Schema$ChoiceQuestion,
    Schema$Form,
    Schema$Item,
    Schema$Question,
    Schema$TextAnswer,
} from "./google-form-types";

const generateMultipleChoiceAnswerContent = (
    googleFormChoiceQuestion: Schema$ChoiceQuestion,
    googleFormAnswer: Schema$Answer
): MultipleChoiceAnswer => {
    const answersMap: any = {};
    for (const option of googleFormChoiceQuestion.options!) {
        answersMap[option.value!] =
            googleFormAnswer!.textAnswers!.answers!.find(
                (answer: Schema$TextAnswer) => answer.value === option.value
            )
                ? true
                : false;
    }
    return {
        choices: answersMap,
    };
};

const convertGoogleFormChoiceAnswer = (
    googleFormQuestion: Schema$Question,
    googleFormAnswer: Schema$Answer
): Answer<SingleChoiceAnswer | MultipleChoiceAnswer> => {
    const newAnswer: Answer<SingleChoiceAnswer | MultipleChoiceAnswer> = {
        uuid: uuid(),
        content:
            googleFormQuestion.choiceQuestion!.type === "CHECKBOX"
                ? generateMultipleChoiceAnswerContent(
                      googleFormQuestion.choiceQuestion!,
                      googleFormAnswer
                  )
                : {
                      choice: googleFormAnswer!.textAnswers!.answers![0].value!,
                  },

        question_uuid: googleFormQuestion.questionId!,
        answer_type_name:
            googleFormQuestion.choiceQuestion!.type === "CHECKBOX"
                ? "MULTIPLE_CHOICE"
                : "SINGLE_CHOICE",
    };
    return newAnswer;
};

const convertGoogleFormScaleAnswer = (
    googleFormQuestion: Schema$Question,
    googleFormAnswer: Schema$Answer
): Answer<RatingAnswer> => {
    const answerValue = googleFormAnswer.textAnswers!.answers![0].value!;
    const newAnswer: Answer<RatingAnswer> = {
        uuid: uuid(),
        content: {
            value: parseInt(answerValue),
            label: answerValue,
        },
        question_uuid: googleFormQuestion.questionId!,
        answer_type_name: "RATING",
    };
    return newAnswer;
};

const convertGoogleFormTextAnswer = (
    googleFormQuestion: Schema$Question,
    googleFormAnswer: Schema$Answer
) => {
    const newAnswer: Answer<ShortTextAnswer | LongTextAnswer> = {
        uuid: uuid(),
        content: {
            text: googleFormAnswer.textAnswers!.answers![0].value || "",
        },
        question_uuid: googleFormQuestion.questionId!,
        answer_type_name: googleFormQuestion.textQuestion!.paragraph
            ? "LONG_TEXT"
            : "SHORT_TEXT",
    };
    return newAnswer;
};

export const convertGoogleFormAnswers = (
    answersObject: {
        [key: string]: Schema$Answer;
    },
    googleFormData: Schema$Form
): Answer<AnswerType>[] => {
    const answersArray: Answer<AnswerType>[] = [];
    googleFormData.items!.forEach((item: Schema$Item) => {
        if (!item || !item.questionItem || !item.questionItem.question) return;

        const question: Schema$Question = item.questionItem.question;

        if (!question.questionId) return;
        if (question.choiceQuestion && answersObject[question.questionId]) {
            answersArray.push(
                convertGoogleFormChoiceAnswer(
                    question,
                    answersObject[question.questionId]
                )
            );
        }

        if (question.textQuestion && answersObject[question.questionId]) {
            answersArray.push(
                convertGoogleFormTextAnswer(
                    question,
                    answersObject[question.questionId]
                )
            );
        }

        if (
            item.questionItem?.question.scaleQuestion &&
            answersObject[question.questionId]
        ) {
            answersArray.push(
                convertGoogleFormScaleAnswer(
                    question,
                    answersObject[question.questionId]
                )
            );
        }
    });
    return answersArray;
};
