import React from "react";

import { setSimpleResultsSortingProfile } from "@difftone/actions";
import { SimpleResultsSortingOptions } from "@difftone/types";

import { sorting_buttons, sorting_button } from "./sorting-buttons.module.css";

export type SortingButtonsProps = {
    sortingOption: SimpleResultsSortingOptions;
};

export const SortingButtons = (props: SortingButtonsProps) => {
    const { sortingOption } = props;

    const sortAscending = () => {
        setSimpleResultsSortingProfile({
            sortBy: sortingOption,
            direction: "DESCENDING",
        });
    };

    const sortDescending = () => {
        setSimpleResultsSortingProfile({
            sortBy: sortingOption,
            direction: "ASCENDING",
        });
    };

    return (
        <div className={sorting_buttons}>
            <div onClick={sortAscending} className={sorting_button}>
                sort &#8593;
            </div>
            <div onClick={sortDescending} className={sorting_button}>
                sort &#8595;
            </div>
        </div>
    );
};
