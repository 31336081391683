import React from "react";
import dayjs from "dayjs";

import { ShowDifftoneTooltip } from "@difftone/shared-components";
import { SurveyEvent } from "@difftone/types";

import {
    getLocalDateStrFromUtcDateStrToInboxTooltip,
    getLocalDayTimeFromUtcTimeString,
} from "@difftone/time-utils";

export type TableDateProps = {
    miliseconds?: number;
    surveyEvent?: SurveyEvent;
};

export const TableDate = (props: TableDateProps) => {
    const { miliseconds, surveyEvent } = props;
    const correctDateFormat = miliseconds
        ? dayjs(miliseconds).format("MMM DD, YYYY")
        : "none";

    const tooltipStringFormat = miliseconds
        ? dayjs(miliseconds).format("MMM DD, YYYY HH:mm")
        : "none";

    const localDateStringFromUtc: string =
        surveyEvent?.date_utc && surveyEvent?.date_utc
            ? getLocalDateStrFromUtcDateStrToInboxTooltip(
                  surveyEvent.date_utc!,
                  surveyEvent.day_utc_time!
              )
            : "Not Set";

    const localDayTimeString: string =
        surveyEvent?.date_utc && surveyEvent?.date_utc
            ? getLocalDayTimeFromUtcTimeString(
                  surveyEvent.day_utc_time!,
                  surveyEvent.date_utc!
              )
            : "Not Set";

    return (
        <div>
            <ShowDifftoneTooltip
                tooltipPosition="bottom"
                tip={
                    surveyEvent
                        ? `${localDateStringFromUtc} ${localDayTimeString}`
                        : tooltipStringFormat
                }
            >
                {surveyEvent ? localDateStringFromUtc : correctDateFormat}
            </ShowDifftoneTooltip>
        </div>
    );
};
