import { action } from "mobx";
import {
    getAllSurveysResponsesFromApi,
    upsertConvertedSurveyResponseToApi,
    upsertOpenSurveyResponseToApi,
    upsertSurveyResponseToApi,
} from "@difftone/services";
import { SurveyResponse } from "@difftone/types";
import { surveyResponseStore } from "./survey-response-store";

export const getAllSurveysResponses = action(async () => {
    surveyResponseStore.startInitialization = true;
    const surveyResponses = await getAllSurveysResponsesFromApi();
    surveyResponses.map((response) => {
        return surveyResponseStore.setSurveyResponseToMap(
            response.survey_UUID,
            response
        );
    });
    surveyResponseStore.storeInitialize = true;
});

export const saveSurveyResponseToApi = async (
    surveyResponse: SurveyResponse
) => {
    const upsertedSurveyResponse = await upsertSurveyResponseToApi(
        surveyResponse
    );

    return upsertedSurveyResponse;
};

export const saveOpenSurveyResponseToApi = async (
    surveyResponse: SurveyResponse
) => {
    const upsertedSurveyResponse = await upsertOpenSurveyResponseToApi(
        surveyResponse
    );

    return upsertedSurveyResponse;
};

export const saveConvertedSurveyResponseToApi = async (
    surveyResponse: SurveyResponse
) => {
    const upsertedSurveyResponse = await upsertConvertedSurveyResponseToApi(
        surveyResponse
    );

    return upsertedSurveyResponse;
};
