import { makeAutoObservable, observable, ObservableMap } from "mobx";
import { createTimeCompareFilter } from "@difftone/frontend-common";
import {
    Question,
    QuestionType,
    ResultsFilter,
    SingleChoiceQuestion,
    SurveyClasses,
    TimeCompareUnits,
    TimeRangesContainer,
    UUID,
} from "@difftone/types";

export type DisplayFiltersActiveMap = {
    [key: string]: boolean;
};

class ResultsDisplayFilterStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _displayFilters: ResultsFilter[] = [];
    get displayFilters(): ResultsFilter[] {
        return this._displayFilters;
    }
    set displayFilters(newFiltersArray: ResultsFilter[]) {
        this._displayFilters = newFiltersArray;
    }

    private _currentSurveyFilterOptions: ResultsFilter[] = [];

    get currentSurveyFilterOptions(): ResultsFilter[] {
        return this._currentSurveyFilterOptions;
    }

    private _selectedSurveyUUID: UUID = "";

    private _producedFiltersMap: { [x: string]: ResultsFilter[] } = {};

    public getProducedFiltersBySurveyAndTimeRanges(
        survey: SurveyClasses,
        timeRanges: TimeRangesContainer
    ): ResultsFilter[] {
        if (!this._producedFiltersMap[survey.uuid]) {
            this._producedFiltersMap[survey.uuid] =
                this.calculateResultsFilterByWithTimeCompare(
                    survey,
                    timeRanges
                );
        }
        return this._producedFiltersMap[survey.uuid];
    }

    private _compareBy: ResultsFilter[] = [];
    get compareBy(): ResultsFilter[] {
        return this._compareBy;
    }
    set compareBy(newFiltersArray: ResultsFilter[]) {
        this._compareBy = newFiltersArray;
    }

    private _selectedTimeCompare: TimeCompareUnits = "NONE";
    get selectedTimeCompare() {
        return this._selectedTimeCompare;
    }

    set selectedTimeCompare(newTimeCompare: TimeCompareUnits) {
        this._selectedTimeCompare = newTimeCompare;
    }

    private _popupDisplayTimeCompare: TimeCompareUnits = "NONE";
    get popupDisplayTimeCompare() {
        return this._popupDisplayTimeCompare;
    }

    set popupDisplayTimeCompare(newTimeCompare: TimeCompareUnits) {
        this._popupDisplayTimeCompare = newTimeCompare;
    }

    //Init the store
    public initFiltersForSurvey = (
        survey: SurveyClasses,
        timeRanges: TimeRangesContainer
    ) => {
        if (survey.uuid !== this._selectedSurveyUUID) {
            this._selectedSurveyUUID = survey.uuid;
            const producedFiltersForSurvey =
                this.getProducedFiltersBySurveyAndTimeRanges(
                    survey,
                    timeRanges
                );

            producedFiltersForSurvey.forEach((producedFilter) => {
                const initialIsActiveMap =
                    this.createIsActiveDisplayFilterItems(producedFilter);

                this.isActiveDisplayFilterMapSetter(
                    producedFilter.filterKey.uuid,
                    initialIsActiveMap
                );
            });

            this._currentSurveyFilterOptions = producedFiltersForSurvey;
            this._compareBy = producedFiltersForSurvey;
            this._displayFilters = [];
        }
    };

    public getFiltersActiveAsCompares = () => {
        return this._compareBy
            .filter((filter) => filter.isPartOfComparedPlain)
            .sort(
                (filterA, filterB) =>
                    filterA.indexInComparePlain - filterB.indexInComparePlain
            );
    };

    public getFiltersNotActiveAsCompares = () => {
        return this._compareBy.filter(
            (filter) => !filter.isPartOfComparedPlain
        );
    };

    private _isActiveDisplayFiltersMap: ObservableMap<
        UUID,
        DisplayFiltersActiveMap
    > = observable.map({});

    public isActiveDisplayFilterMapSetter = (
        filterKeyUUID: UUID,
        isActiveMap: DisplayFiltersActiveMap
    ) => {
        this._isActiveDisplayFiltersMap.merge({ [filterKeyUUID]: isActiveMap });
    };

    public getIsActiveFilterByUuid = (questionUuid: UUID) => {
        const isActiveMap = this._isActiveDisplayFiltersMap.get(questionUuid);

        if (!isActiveMap) {
            throw Error(
                `[Difftone]: Could not find map by uuid ${questionUuid}`
            );
        }
        return isActiveMap;
    };

    private createIsActiveDisplayFilterItems = (
        producedFilter: ResultsFilter
    ) => {
        const initialMapState: { [key: string]: boolean } = {};

        producedFilter.filterValues.forEach((filterValue) => {
            initialMapState[filterValue.toString()] = true;
        });

        return initialMapState;
    };

    private getFilterValuesFromFilterQuestion = (
        question: Question<QuestionType>
    ) => {
        return (
            question as Question<SingleChoiceQuestion>
        ).content.choice_options.map((optionalChoice) => optionalChoice.label);
    };

    private calculateResultsFilterByWithTimeCompare = (
        survey: SurveyClasses,
        timeRangeContainer: TimeRangesContainer
    ) => {
        const resultFilterByFilters = survey.questions.reduce(
            (filtersArray: ResultsFilter[], question) => {
                if (question.is_filter) {
                    //Default setter for filters
                    const newFilter: ResultsFilter = {
                        filterKey: question,
                        filterValues:
                            this.getFilterValuesFromFilterQuestion(question),
                        isPartOfComparedPlain: false,
                        indexInComparePlain: 0,
                    };

                    filtersArray.push(newFilter);
                }
                return filtersArray;
            },
            []
        );

        const timeCompareFilter = createTimeCompareFilter(
            "NONE",
            0,
            timeRangeContainer.timeRanges
        );

        resultFilterByFilters.push(timeCompareFilter);

        return resultFilterByFilters;
    };

    public clearStore = () => {
        this._compareBy = [];
        this._displayFilters = [];
        this._currentSurveyFilterOptions = [];
        this._selectedTimeCompare = "NONE";
        this._isActiveDisplayFiltersMap = observable.map({});
        this._currentSurveyFilterOptions = [];
        this._selectedSurveyUUID = "";
        this._producedFiltersMap = {};
    };
}

export const resultsDisplayFilterStore = new ResultsDisplayFilterStore();
