import {
    UUID,
    ResultsFilter,
    EmailAddress,
    SharedResult,
    SharedResultWithLink,
} from "@difftone/types";
import { getApiBaseUrl, getWebClientBaseUrl } from "@difftone/constants";
import {
    errorHandlerProxy,
    getUserEmail,
    localstorageUtils,
} from "@difftone/procedures";
import { createDifftoneAxios } from "@difftone/services";

const SHARED_RESULT_SERVICE_URL = `${getApiBaseUrl()}/shared-result-service`;
const LINK_SHARED_RESULT = `${getWebClientBaseUrl()}/shared-results`;

export type ShareResultsPayload = {
    surveyUUID: UUID;
    filters: ResultsFilter[];
    compareBy: string[];
    emails: EmailAddress[];
    name: string;
    surveyName: string;
    surveyInitiator: string;
    createdAt: number;
    message?: string;
};

export const shareResults = async (sharedResult: SharedResult) => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const sharedResultWithLink: SharedResultWithLink = {
        shared_result: sharedResult,
        link_to_shared_result: `${LINK_SHARED_RESULT}/${sharedResult.uuid}`,
    };

    const response = await difftoneAxios.put(
        SHARED_RESULT_SERVICE_URL,
        sharedResultWithLink,
        {
            params: { issuer: currentAuthData.issuer },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    return response.data;
};

export const unshareResults = async (share: SharedResult) => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.put(
        SHARED_RESULT_SERVICE_URL,
        {
            shared_result: {
                ...share,
                is_deleted: true,
            },
        },
        {
            params: { issuer: currentAuthData.issuer },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    return response.data;
};

export const removeMeFromSharedResults = async (share: SharedResult) => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.patch(
        `${SHARED_RESULT_SERVICE_URL}/remove-me`,
        {
            shared_result_uuid: share.uuid,
            user_email: getUserEmail(),
        },
        {
            params: { issuer: currentAuthData.issuer },
        }
    );
    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    return response.data;
};

export const getSharedWithMeResults = async () => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(
        `${SHARED_RESULT_SERVICE_URL}/shared-with-me`,
        {
            params: { issuer: currentAuthData.issuer },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    return response.data.resources;
};

export const getResultISharedWith = async () => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(
        `${SHARED_RESULT_SERVICE_URL}/sharer`,
        {
            params: { issuer: currentAuthData.issuer },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    return response.data.resources;
};
