import { makeAutoObservable } from "mobx";

import { ResultsFilter, UUID, Label } from "@difftone/types";
import { addCategoryToStore } from "./categories-internal-actions";

class CategoriesDisplayStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _categoriesToDelete: UUID[] = [];

    get categoriesToDelete() {
        return this._categoriesToDelete;
    }

    set categoriesToDelete(categoriesToDelete) {
        this._categoriesToDelete = categoriesToDelete;
    }

    private _categoryNameInput = "";

    get categoryNameInput() {
        return this._categoryNameInput;
    }

    set categoryNameInput(name: string) {
        this._categoryNameInput = name;
    }

    private _customCategories: Label[] = [];

    get customCategories() {
        return this._customCategories;
    }

    set customCategories(categories: Label[]) {
        this._customCategories = categories;
    }

    public addCustomCategory = (category: Label) => {
        addCategoryToStore(category);
    };

    public clearCustomCategories = () => {
        this._customCategories = [];
    };

    private _categoryFilterBy: ResultsFilter | null = null;

    get categoryFilterBy() {
        return this._categoryFilterBy;
    }

    set categoryFilterBy(filterBy) {
        this._categoryFilterBy = filterBy;
    }

    private _isStoreInitialized = false;

    get isStoreInitialized() {
        return this._isStoreInitialized;
    }

    set isStoreInitialized(isStoreInitialized) {
        this._isStoreInitialized = isStoreInitialized;
    }

    private _categoriesDisplayMap: Record<UUID, boolean> = {};

    get categoriesDisplayMap() {
        return this._categoriesDisplayMap;
    }

    set categoriesDisplayMap(newCategoriesDisplayMap: Record<UUID, boolean>) {
        this._categoriesDisplayMap = newCategoriesDisplayMap;
    }

    public initCategoriesDisplayMap = (categoryUUIDs: UUID[]) => {
        // By default all categories should be closed

        categoryUUIDs.forEach((categoryUUID) => {
            this.categoriesDisplayMap[categoryUUID] = false;
        });

        this.isStoreInitialized = true;
    };

    public toggleCategoriesDisplayModes = () => {
        Object.keys(this.categoriesDisplayMap).forEach((categoryUUID) => {
            this.categoriesDisplayMap[categoryUUID] = false;
        });
    };

    public getCategoryDisplayByUUID = (categoryUUID: UUID) => {
        return this._categoriesDisplayMap[categoryUUID];
    };

    public getCategoryByDisplayName = (displayName: string) => {
        const categories = categoriesDisplayStore.customCategories;

        return categories.find(
            ({ display_name }) => display_name === displayName
        );
    };

    public clearStore = () => {
        this._categoryFilterBy = null;
        this._customCategories = [];
        this._categoriesDisplayMap = {};
    };
}

export const categoriesDisplayStore = new CategoriesDisplayStore();
