import React, {
    useState,
    useRef,
    FC,
    Ref,
    KeyboardEvent,
    ChangeEvent,
    PropsWithChildren,
} from "react";
import OutsideClickHandler from "react-outside-click-handler";
import clsx from "clsx";

import {
    changeable_text,
    changeable_text_input,
    changeable_wrap,
    input_icon_hover,
} from "./changeable-text-with-input.module.css";
import { preventPropagation } from "@difftone/frontend-common";
import confirmIcon from "@difftone/assets/confirm-edit-question.svg";

type ChangeableTextWithInputClasses = {
    text?: string;
    input?: string;
};

export type ChangeableTextWithInputProps = {
    baseValue: string;
    onValueSave: (value: string) => void;
    classes?: ChangeableTextWithInputClasses;
    inputIcon: string;
    inputIconStyles: string;
    onClose: () => void;
};

const ENTER_KEY_CODE = "Enter";

export const ChangeableTextWithInput: FC<
    PropsWithChildren<ChangeableTextWithInputProps>
> = ({
    baseValue,
    onValueSave,
    inputIcon,
    inputIconStyles,
    onClose,
    classes = {},
}: ChangeableTextWithInputProps) => {
    // ref to store input tag to focus it on isChangeMode change
    const inputRef: Ref<HTMLInputElement> = useRef(null);
    // local variable to store specific Input instance state
    const [isChangeMode, setIsChangeMode] = useState(false);
    // temp variable to store value between saves
    const [value, setValue] = useState("");

    const toggleIsChangeMode = () => {
        if (!isChangeMode) {
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 0);
        }

        setIsChangeMode((state) => !state);
    };

    const handleCleanup = () => {
        setValue("");
        setIsChangeMode(false);
    };

    const handleSave = () => {
        onValueSave(value);
    };

    const handleKeyPress = (e: KeyboardEvent) => {
        if (e.key === ENTER_KEY_CODE && value.length > 0) {
            handleSave();
        }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
        preventPropagation(e);
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const onIconClick = () => {
        handleSave();
        onClose();
    };

    return (
        <>
            <img
                alt=""
                src={inputIcon}
                className={inputIconStyles}
                onClick={onIconClick}
            />

            <div
                onClick={preventPropagation}
                className={changeable_wrap}
                onKeyUp={handleKeyUp}
            >
                <OutsideClickHandler
                    disabled={isChangeMode}
                    onOutsideClick={handleCleanup}
                >
                    {!isChangeMode && (
                        <span
                            className={clsx(changeable_text, classes.text)}
                            onClick={toggleIsChangeMode}
                        >
                            {baseValue}
                        </span>
                    )}
                    {isChangeMode && (
                        <input
                            ref={inputRef}
                            className={clsx(
                                changeable_text_input,
                                changeable_text,
                                classes.input
                            )}
                            value={value}
                            onChange={onChange}
                            onKeyPress={handleKeyPress}
                        />
                    )}
                </OutsideClickHandler>
            </div>
            <img
                alt=""
                src={confirmIcon}
                className={clsx(inputIconStyles, input_icon_hover)}
                onClick={handleSave}
            />
        </>
    );
};
