import React from "react";
import { observer } from "mobx-react";

import { ValidationWrapper } from "@difftone/shared-components";
import { warningMessages } from "@difftone/constants";
import {
    isDashboardFilterStatusReady,
    validateInitiatorList,
} from "@difftone/reducers";

import { DashboardsFilterByGenerator } from "./components";

import filterByIcon from "@difftone/assets/dashboard-filter-by-icon.svg";
import {
    filter_by_selector,
    filter_by_title,
} from "./filter-by-selector.module.css";

export type FilterBySelectorProps = {};

export const FilterBySelector = observer((props: FilterBySelectorProps) => {
    let isValid = true;

    if (isDashboardFilterStatusReady()) {
        isValid = validateInitiatorList().some(
            (validationResult) => validationResult.is_valid
        );
    }

    return (
        <div className={filter_by_selector}>
            <div className={filter_by_title}>
                <img src={filterByIcon} alt="filter-by-icon" />
                <span>Filter by:</span>
            </div>
            <ValidationWrapper
                isValid={isValid}
                errorMessage={warningMessages.dashboardInitiatorsListError}
            >
                <DashboardsFilterByGenerator />
            </ValidationWrapper>
        </div>
    );
});
