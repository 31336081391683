import React from "react";
import { observer } from "mobx-react";
import { ResponderToAnswer, BaseSurvey } from "@difftone/types";
import { INBOX_URL, warningMessages } from "@difftone/constants";
import {
    SurveyStatus,
    Spinner,
    ResultsActions,
    ResultsCardHeader,
} from "@difftone/shared-components";
import {
    publicProfileStore,
    simpleSurveyResultsStore,
    simpleResultsMapStore,
    sharedResultStore,
} from "@difftone/stores";
import { showDifftoneAlert, setLocation } from "@difftone/actions";
import { getStatus } from "@difftone/reducers";

import { QuestionsAndResponses, FilterSorterDialog } from "./components";

import {
    simple_survey_results_card_container,
    simple_survey_results,
    status_and_initiator,
    status,
    initiator,
} from "./simple-survey-results-card.module.css";

export type SimpleSurveyResultsCardProps = { survey: BaseSurvey };

export const SimpleSurveyResultsCard = observer(
    (props: SimpleSurveyResultsCardProps) => {
        const { survey } = props;
        const { targetSharedResult, isTargetSharedResultInitialized } =
            sharedResultStore;
        const { selectedQuestion, isFilterDialogOpen } =
            simpleSurveyResultsStore;
        const { getSimpleResultsMapTableBySurveyUUID } = simpleResultsMapStore;

        if (!targetSharedResult && !isTargetSharedResultInitialized) {
            sharedResultStore.initTargetView();

            return <Spinner size="LARGE" />;
        }

        const surveyStatus = getStatus(survey);

        const initiatorPublicProfile =
            survey && survey.initiator
                ? publicProfileStore.getPublicProfileByUuid(survey.initiator)
                : null;

        const questionToRespondersAnswerMap =
            getSimpleResultsMapTableBySurveyUUID(survey, targetSharedResult);

        if (questionToRespondersAnswerMap === undefined) {
            return <Spinner size="LARGE" />;
        }

        if (questionToRespondersAnswerMap === null) {
            setLocation(INBOX_URL);
            showDifftoneAlert(warningMessages.surveyNotFound, "FAILURE");
            return null;
        }

        const onlyResponsesWithoutNull = selectedQuestion
            ? questionToRespondersAnswerMap[selectedQuestion].filter(
                  (response) => response !== null
              )
            : [];

        return (
            <div className={simple_survey_results_card_container}>
                <div className={simple_survey_results}>
                    <ResultsCardHeader surveyName={survey.survey_name}>
                        <ResultsActions
                            dataCSV={[[], []]}
                            isExportHidden
                            isSimpleResults
                        />
                    </ResultsCardHeader>
                    <div className={status_and_initiator}>
                        <div className={status}>
                            <span>Status:</span>
                            <SurveyStatus surveyStatus={surveyStatus} />
                        </div>
                        <div className={initiator}>
                            Initiator:
                            <span>{initiatorPublicProfile?.name}</span>
                        </div>
                    </div>
                    <QuestionsAndResponses
                        questionToRespondersAnswerMap={
                            questionToRespondersAnswerMap
                        }
                        survey={survey}
                    />
                </div>
                {isFilterDialogOpen ? (
                    <FilterSorterDialog
                        selectedQuestionAnswers={
                            selectedQuestion
                                ? (onlyResponsesWithoutNull as ResponderToAnswer[])
                                : []
                        }
                    />
                ) : null}
            </div>
        );
    }
);
