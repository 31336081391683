import type {
    SurveyEvent,
    SurveyEventInvalidTitleValidationType,
    ValidationResult,
} from "@difftone/types";

export const validateEventTitle = (
    surveyEvent: SurveyEvent
): ValidationResult<SurveyEventInvalidTitleValidationType>[] => {
    try {
        return [
            {
                is_valid: !!surveyEvent.title,
                validation_error_code: "SURVEY_EVENT_NAME_VALIDATION",
                validation_error_type: { event_uuid: surveyEvent.uuid },
            },
        ];
    } catch (error) {
        console.error(
            `validateSurveyDates error:: ${(error as Error).message}`
        );
        return [
            {
                is_valid: false,
                validation_error_code: "EXCEPTION_DURING_VALIDATIONS",
                //TODO: fix type definition
                //@ts-ignore
                validation_error_type: error as Error,
            },
        ];
    }
};
