import React, { MouseEvent, useEffect } from "react";

import { getUserEmail, setItemVisibleInView } from "@difftone/procedures";

import { AutocompleteItem } from "./components";
import {
    mailing_list_autocomplete,
    autocomplete_wrapper,
} from "./mailing-list-autocomplete.module.css";

export type MailingListAutocompleteProps = {
    separatorIndex: number;
    mailingList: string[];
    onMouseClick: (event: MouseEvent<HTMLDivElement>) => void;
    onMouseOver: (event: MouseEvent<HTMLDivElement>) => void;
    onNoMatchFound: () => void;
    activeIndex: number;
    inWizardDetails?: boolean;
};

export const MailingListAutocomplete = (
    props: MailingListAutocompleteProps
) => {
    const {
        mailingList,
        onMouseClick,
        onMouseOver,
        onNoMatchFound,
        activeIndex,
        separatorIndex,
        inWizardDetails,
    } = props;

    //:useEffect for checking if the autocomplete item is in the view and scroll to the item if needed
    useEffect(() => {
        const autoCompleteDropDownItem: HTMLDivElement | null =
            document.querySelector(`div[ data-index="${activeIndex}"]`);
        const autocompleteScrollWrapper: HTMLDivElement | null =
            document.querySelector(`div[data-autocomplete-scroll-wrapper]`);

        mailingList.length > 0 &&
            setItemVisibleInView(
                autocompleteScrollWrapper,
                autoCompleteDropDownItem
            );
    }, [activeIndex, mailingList]);

    if (mailingList.length === 0) {
        onNoMatchFound();
    }

    return mailingList.length > 0 ? (
        <div className={autocomplete_wrapper}>
            <div
                className={mailing_list_autocomplete}
                data-autocomplete-scroll-wrapper
            >
                {mailingList
                    .filter((email) => email !== getUserEmail())
                    .map((email, index) => (
                        <AutocompleteItem
                            key={email}
                            isActiveItem={activeIndex === index}
                            onClick={onMouseClick}
                            onHover={onMouseOver}
                            email={email}
                            index={index}
                            showGroupsSeparator={
                                inWizardDetails
                                    ? false
                                    : separatorIndex === index
                            }
                        />
                    ))}
            </div>
        </div>
    ) : null;
};
