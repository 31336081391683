import React from "react";

import {
    MultipleChoiceQuestion,
    Question,
    QuestionToAnswer,
    QuestionType,
    RatingQuestion,
    SingleChoiceQuestion,
    SurveyClasses,
} from "@difftone/types";
import { NO_ANSWER_KEY } from "@difftone/common-constants";

import { DetailedQuestionOption } from "./components";

import { numeric_expanded_question } from "./numeric-expanded-question.module.css";
import { getSurveyFromStoreByURL } from "@difftone/reducers";

export type NumericExpandedQuestionProps = {
    question: Question<QuestionType>;
    answersFrequency: QuestionToAnswer | null;
    columnKey: string;
    survey?: SurveyClasses;
};

export const NumericExpandedQuestion = (
    props: NumericExpandedQuestionProps
) => {
    const { question, answersFrequency, columnKey, survey } = props;

    const _survey = survey || getSurveyFromStoreByURL();

    const extractQuestionOptionsByType = () => {
        switch (question.question_type_name) {
            case "RATING":
                const answerRatingContent = (
                    question as Question<RatingQuestion>
                ).content.rating_options;

                const noResponseAnswersRating = {
                    value: NO_ANSWER_KEY,
                    label: "No response",
                };

                const ratingAnswers = answerRatingContent.map(
                    (ratingOption) => ({
                        value: ratingOption.value.toString(),
                        label: ratingOption.label,
                    })
                );

                return [...ratingAnswers, noResponseAnswersRating];

            case "SINGLE_CHOICE":
                const answerSingleChoiceContent = (
                    question as Question<SingleChoiceQuestion>
                ).content.choice_options.map(
                    (choiceOption) => choiceOption.label
                );

                const noResponseAnswersSingleChoice = {
                    value: NO_ANSWER_KEY,
                    label: "No response",
                };

                const singleChoiceAnswers = answerSingleChoiceContent.map(
                    (option) => ({
                        value: option,
                        label: option,
                    })
                );
                return [...singleChoiceAnswers, noResponseAnswersSingleChoice];

            case "MULTIPLE_CHOICE":
                const multipleChoiceContent = (
                    question as Question<MultipleChoiceQuestion>
                ).content.choice_options;

                const noResponseAnswersMultipleChoice = {
                    value: NO_ANSWER_KEY,
                    label: "No response",
                };

                const multipleChoiceAnswers = multipleChoiceContent.map(
                    (option) => ({
                        value: option,
                        label: option,
                    })
                );

                return [
                    ...multipleChoiceAnswers,
                    noResponseAnswersMultipleChoice,
                ];

            case "LONG_TEXT":
            case "SHORT_TEXT":
            case "TIME":
            case "DATE":
                const noResponseAnswersOpenQuestions = {
                    value: NO_ANSWER_KEY,
                    label: "No response",
                };
                return [noResponseAnswersOpenQuestions];
            default:
                return [];
        }
    };

    return (
        <div className={numeric_expanded_question}>
            {extractQuestionOptionsByType().map((option) => (
                <DetailedQuestionOption
                    survey={_survey}
                    questionType={question.question_type_name}
                    columnKey={columnKey}
                    answersFrequency={answersFrequency}
                    key={option.value}
                    answerValue={option.value}
                    answerLabel={option.label}
                />
            ))}
        </div>
    );
};
