import React from "react";

import { header } from "./header.module.css";

export type HeaderProps = {};

export const Header = (props: HeaderProps) => {
    return (
        <div className={header}>
            <div className="">My surveys</div>
        </div>
    );
};
