import React from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import {
    acceptSharedSurveyToDraft,
    removeDeletedSharedSurveysFromStore,
    setInboxActiveFilterFlagAndRedirect,
    showDifftoneAlert,
    updateToArchiveSharedSurvey,
    updateToDeleteSharedSurvey,
    openSurveyPreview,
    getUniquePublicProfilesUUIDsInSharedSurveysInbox,
} from "@difftone/actions";
import {
    constantAlertVariants,
    INBOX_MAX_SURVEY_NAME_LENGTH,
} from "@difftone/constants";
import {
    publicProfileStore,
    leftMenuStore,
    sharedSurveyStore,
} from "@difftone/stores";
import {
    HumanizeEmail,
    ShowDifftoneTooltip,
    Spinner,
    SpinnerOrTimeoutMessage,
    TableRow,
    TableRowCell,
    TableDate,
    ActionIconItem,
    NameWithTooltip,
} from "@difftone/shared-components";
import {
    getFilteredSharedSurveys,
    getSortedSharedSurveys,
} from "@difftone/reducers";

import { TableHeader } from "./components";

import { SharedSurvey } from "@difftone/types";
import deleteIcon from "@difftone/assets/delete-survey.svg";
import previewIcon from "@difftone/assets/preview-action-icon.svg";
import acceptIcon from "@difftone/assets/add-action-icon.svg";

import {
    inbox_surveys_table,
    table_content_wrapper,
    overflow_padding_div,
    initiator_class,
    issued_class,
    survey_name_class,
} from "./inbox-shared-surveys-table.module.css";

export type InboxSharedSurveysTableProps = {};

export const InboxSharedSurveysTable = observer(() => {
    const sortedSharedSurveysList = getSortedSharedSurveys();
    const filteredSharedSurveysList = getFilteredSharedSurveys(
        sortedSharedSurveysList
    );

    if (!sharedSurveyStore.startInitialization) {
        sharedSurveyStore.init();
        return <Spinner size="LARGE" />;
    }

    if (!sharedSurveyStore.storeInitialize) {
        return <Spinner />;
    }

    if (!publicProfileStore.startInitialization) {
        const publicProfileUUIDs =
            getUniquePublicProfilesUUIDsInSharedSurveysInbox();

        publicProfileStore.init(publicProfileUUIDs);
    }

    if (!publicProfileStore.storeInitialize) {
        return <Spinner />;
    }

    if (!leftMenuStore.startInitialization) {
        leftMenuStore.init();
        return <Spinner />;
    }

    if (!leftMenuStore.storeInitialize) {
        return <Spinner />;
    }

    const getInitiatorPublicProfile = (sharedSurvey: SharedSurvey) => {
        const { sender } = sharedSurvey;

        const initiatorPublicProfile =
            publicProfileStore.getPublicProfileByUuid(sender);

        return initiatorPublicProfile;
    };

    const onPreview = (sharedSurvey: SharedSurvey) => {
        openSurveyPreview(sharedSurvey.survey);
    };

    const onAccept = (sharedSurvey: SharedSurvey) => {
        updateToArchiveSharedSurvey(sharedSurvey);
        acceptSharedSurveyToDraft(sharedSurvey);
        showDifftoneAlert(
            constantAlertVariants.shared_survey_accept_success.text,
            constantAlertVariants.shared_survey_accept_success.variant
        );
        setInboxActiveFilterFlagAndRedirect("DRAFT");
    };

    const onConfirmAccept = (sharedSurvey: SharedSurvey) => {
        if (sharedSurvey.archive) {
            return;
        }

        showDifftoneAlert(
            constantAlertVariants.shared_survey_accept.text,
            constantAlertVariants.shared_survey_accept.variant,
            () => onAccept(sharedSurvey),
            () => {},
            `${sharedSurvey.survey.survey_name}`
        );
    };

    const onDelete = (sharedSurvey: SharedSurvey) => {
        updateToDeleteSharedSurvey(sharedSurvey);
        removeDeletedSharedSurveysFromStore();
        showDifftoneAlert(
            constantAlertVariants.shared_survey_delete_success.text,
            constantAlertVariants.shared_survey_delete_success.variant
        );
    };

    const onUnconfirmedDelete = (sharedSurvey: SharedSurvey) => {
        showDifftoneAlert(
            constantAlertVariants.shared_survey_delete.text,
            constantAlertVariants.shared_survey_delete.variant,
            () => onDelete(sharedSurvey),
            () => {},
            `${sharedSurvey.survey.survey_name}`
        );
    };

    return (
        <div className={inbox_surveys_table}>
            <TableHeader />
            <div className={table_content_wrapper}>
                {filteredSharedSurveysList.length > 0 ? (
                    filteredSharedSurveysList.map(
                        (sharedSurvey: SharedSurvey) => {
                            const initiatorPubicProfile =
                                getInitiatorPublicProfile(sharedSurvey);

                            const initiatorEmail = initiatorPubicProfile
                                ? initiatorPubicProfile.email
                                : "unknown";

                            return (
                                <TableRow
                                    sharedSurveyIsArchive={sharedSurvey.archive}
                                    key={sharedSurvey.uuid}
                                    body={
                                        <>
                                            <TableRowCell
                                                className={clsx(
                                                    survey_name_class,
                                                    "user_select"
                                                )}
                                                tooltipText={
                                                    sharedSurvey.survey
                                                        .survey_name
                                                }
                                                maxTextLength={
                                                    INBOX_MAX_SURVEY_NAME_LENGTH
                                                }
                                            >
                                                <NameWithTooltip
                                                    text={
                                                        sharedSurvey.survey
                                                            .survey_name
                                                    }
                                                />
                                            </TableRowCell>

                                            <TableRowCell
                                                className={initiator_class}
                                                tooltipText={initiatorEmail}
                                            >
                                                {initiatorPubicProfile ===
                                                undefined ? (
                                                    <Spinner size="X-SMALL" />
                                                ) : (
                                                    <HumanizeEmail
                                                        humanizeEmail={
                                                            initiatorEmail
                                                        }
                                                    />
                                                )}
                                            </TableRowCell>
                                            <TableRowCell
                                                className={issued_class}
                                            >
                                                <TableDate
                                                    miliseconds={
                                                        sharedSurvey.timestamp
                                                    }
                                                />
                                            </TableRowCell>
                                        </>
                                    }
                                    actions={
                                        <>
                                            <ShowDifftoneTooltip
                                                tooltipPosition="bottom"
                                                tip="Preview"
                                            >
                                                <ActionIconItem
                                                    action={() =>
                                                        onPreview(sharedSurvey)
                                                    }
                                                    disabled={false}
                                                    icon={previewIcon}
                                                />
                                            </ShowDifftoneTooltip>
                                            {!sharedSurvey.archive && (
                                                <ShowDifftoneTooltip
                                                    tooltipPosition="bottom"
                                                    tip="Accept"
                                                >
                                                    <ActionIconItem
                                                        action={() =>
                                                            onConfirmAccept(
                                                                sharedSurvey
                                                            )
                                                        }
                                                        disabled={false}
                                                        icon={acceptIcon}
                                                    />
                                                </ShowDifftoneTooltip>
                                            )}
                                            <ShowDifftoneTooltip
                                                tooltipPosition="bottom"
                                                tip="Delete"
                                            >
                                                <ActionIconItem
                                                    action={() =>
                                                        onUnconfirmedDelete(
                                                            sharedSurvey
                                                        )
                                                    }
                                                    disabled={false}
                                                    icon={deleteIcon}
                                                />
                                            </ShowDifftoneTooltip>
                                        </>
                                    }
                                />
                            );
                        }
                    )
                ) : (
                    <SpinnerOrTimeoutMessage
                        spinnerSize="LARGE"
                        spinnerFlag={!sharedSurveyStore.storeInitialize}
                        message="No Shared Surveys To Show"
                    />
                )}
                <div className={overflow_padding_div}></div>
            </div>
        </div>
    );
});
