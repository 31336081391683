import React from "react";
import { observer } from "mobx-react";
import {
    Header,
    LeftMenu,
    MobileHeader,
    ClientWidthRenderPicker,
    Spinner,
} from "@difftone/shared-components";
import { BaseSurvey } from "@difftone/types";
import {
    INBOX_URL,
    warningMessages,
    NO_MOBILE_PLACEHOLDER_TEXT,
} from "@difftone/constants";
import {
    getOrganizations,
    getSelectedOrganizationUUID,
    getSurveyByUuid,
    getSurveyUUIDFromUrl,
} from "@difftone/reducers";
import {
    showDifftoneAlert,
    setLocation,
    setSelectedQuestion,
} from "@difftone/actions";
import { organizationStore, simpleSurveyResultsStore } from "@difftone/stores";

import { LeftMenuChildren, SimpleSurveyResultsCard } from "./components";

import {
    simple_survey_results,
    results_body,
    mobile_wrapper_notice,
} from "./simple-survey-results.module.css";

export type SimpleSurveyResultsProps = {};

export const SimpleSurveyResults = observer(() => {
    const { selectedQuestion } = simpleSurveyResultsStore;
    const surveyUuid = getSurveyUUIDFromUrl();

    if (surveyUuid === null) {
        setLocation(INBOX_URL);
        showDifftoneAlert(
            "Survey url is not valid, please try again later.",
            "FAILURE"
        );
        return null;
    }

    const selectedSurvey = getSurveyByUuid(surveyUuid);

    if (selectedSurvey === undefined) {
        return <Spinner size="LARGE" />;
    }
    if (selectedSurvey === null) {
        setLocation(INBOX_URL);
        showDifftoneAlert(warningMessages.surveyNotFound, "FAILURE");
        return null;
    }

    const selectedSurveyQuestionUUIDs = selectedSurvey.questions.map(
        (question) => question.uuid
    );
    if (!selectedSurveyQuestionUUIDs.includes(selectedQuestion || "")) {
        const firstQuestionInSurvey = selectedSurvey.questions.find(
            (question) => question.number === 1
        );
        firstQuestionInSurvey &&
            setSelectedQuestion(firstQuestionInSurvey.uuid);
    }

    const selectedOrganization = getSelectedOrganizationUUID();
    const organizationsList = getOrganizations();

    let _organization;

    if (selectedOrganization) {
        _organization =
            organizationStore.getOrganizationByUuid(selectedOrganization);
    }

    return (
        <div className={simple_survey_results}>
            <ClientWidthRenderPicker
                desktopComponent={
                    <Header
                        organization={_organization}
                        organizationsList={organizationsList}
                    />
                }
                mobileComponent={<MobileHeader />}
            />
            <div className={results_body}>
                <LeftMenu isHiddenOnMobile>
                    <LeftMenuChildren survey={selectedSurvey as BaseSurvey} />
                </LeftMenu>
                <ClientWidthRenderPicker
                    desktopComponent={
                        <SimpleSurveyResultsCard
                            survey={selectedSurvey as BaseSurvey}
                        />
                    }
                    mobileComponent={
                        <p className={mobile_wrapper_notice}>
                            {NO_MOBILE_PLACEHOLDER_TEXT}
                        </p>
                    }
                />
            </div>
        </div>
    );
});
