import React from "react";
import { observer } from "mobx-react";
import {
    getSurveyForParticipantBySurveyUuid,
    getSurveyUUIDFromUrl,
} from "@difftone/reducers";

import { Spinner, Survey } from "@difftone/shared-components";
import { setLocation, showDifftoneAlert } from "@difftone/actions";
import { INBOX_URL, warningMessages } from "@difftone/constants";

export type FillSurveyProps = {
    isOpenSurvey?: boolean;
};

export const FillSurvey = observer((props: FillSurveyProps) => {
    const _isOpenSurvey = !!props.isOpenSurvey;

    const surveyUuid = getSurveyUUIDFromUrl();

    if (surveyUuid === null) {
        showDifftoneAlert(warningMessages.surveyNotFound, "FAILURE");
        setLocation(INBOX_URL);
        return null;
    }

    const _survey = getSurveyForParticipantBySurveyUuid(
        surveyUuid,
        _isOpenSurvey
    );

    if (_survey === undefined) {
        return <Spinner size="LARGE" />;
    }

    if (_survey === null) {
        showDifftoneAlert(warningMessages.surveyNotFound, "FAILURE");
        setLocation(INBOX_URL);
        return null;
    }

    return (
        <div>
            <Survey
                survey={_survey}
                inPreviewMode={false}
                isOpenSurvey={_isOpenSurvey}
            />
        </div>
    );
});
