import React from "react";

import { GoogleLogout } from "react-google-login";
import {
    showDifftoneAlert,
    logoutFromLocalStorage,
    logoutFromMicrosoft,
} from "@difftone/actions";
import { AuthData } from "@difftone/types";
import { getUserEmail, getUserFullName } from "@difftone/procedures";
import { getGoogleClientId } from "@difftone/constants";

import logOut from "@difftone/assets/log-out.svg";
import {
    mobile_user_dialog,
    user_initials,
    user_name,
    user_email,
    dialog_actions_list,
    special_logout_button,
} from "./mobile-user-dialog.module.css";

export type MobileUserDialogProps = {};

export const MobileUserDialog = () => {
    const initials = `${getUserFullName().split(" ")[0]?.charAt(0) || "f"}${
        getUserFullName().split(" ")[1]?.charAt(0) || "f"
    }`.toLocaleUpperCase();

    const authDataAsString = localStorage.getItem("authData");
    if (!authDataAsString) {
        throw new Error("Cannot read authData from local storage!");
    }
    const authData: AuthData = JSON.parse(authDataAsString);

    const onGoogleLogOutSuccessHandler = () => {
        logoutFromLocalStorage();
    };
    const onGoogleLogOutFailureHandler = () => {
        showDifftoneAlert("logout button failed", "FAILURE");
    };

    const onMicrosoftLogout = () => {
        logoutFromMicrosoft();
    };

    return (
        <div className={mobile_user_dialog}>
            <div className={user_initials}>{initials}</div>
            <div className={user_name}>{getUserFullName()}</div>
            <div className={user_email}>{getUserEmail()}</div>
            <ul className={dialog_actions_list}>
                <li className={special_logout_button}>
                    {authData.issuer === "GOOGLE" ? (
                        <GoogleLogout
                            clientId={getGoogleClientId()}
                            onLogoutSuccess={onGoogleLogOutSuccessHandler}
                            onFailure={onGoogleLogOutFailureHandler}
                            render={(renderProps) => (
                                <>
                                    <img alt="edit" src={logOut} />
                                    <div onMouseDown={renderProps.onClick}>
                                        Log Out
                                    </div>
                                </>
                            )}
                        />
                    ) : (
                        <>
                            <img alt="edit" src={logOut} />
                            <div onMouseDown={onMicrosoftLogout}>Log Out</div>
                        </>
                    )}
                </li>
            </ul>
        </div>
    );
};
