import React from "react";
import {
    MultipleChoiceQuestion,
    Question,
    SupportedQuestionResultsMapAfterDigestionWithoutNulls,
} from "@difftone/types";
import { getMostPopularForSingleOrMultipleChoice } from "@difftone/actions";

import { MobileResultBox } from "@difftone/shared-components";

export type MultipleChoiceTypeProps = {
    question: Question<MultipleChoiceQuestion>;
    digestedResultsRow: SupportedQuestionResultsMapAfterDigestionWithoutNulls;
    index: number;
    columnKey: string;
};

export const MultipleChoiceType = (props: MultipleChoiceTypeProps) => {
    const { question, digestedResultsRow, index, columnKey } = props;

    const mostPopularMultipleChoice = getMostPopularForSingleOrMultipleChoice(
        digestedResultsRow,
        columnKey
    );

    return (
        <MobileResultBox
            key={question.uuid + columnKey + index}
            colorIndicator={"OVERALL"}
            resultValue={
                mostPopularMultipleChoice ? mostPopularMultipleChoice : ""
            }
        />
    );
};
