import React from "react";
import { observer } from "mobx-react";

import {
    finalizeDuplicateSurvey,
    setLocation,
    showDifftoneAlert,
} from "@difftone/actions";
import { getSurveyFromStoreByURL } from "@difftone/reducers";
import { DuplicateSurveyPopup } from "@difftone/shared-components";
import {
    constantAlertVariants,
    INBOX_URL,
    PAGE_URLS,
} from "@difftone/constants";
import {
    OptionalPropertiesForDuplicateSurvey,
    SurveyClasses,
} from "@difftone/types";

export type DuplicateSurveyProps = {};

export const DuplicateSurvey = observer(() => {
    const originalSurvey = getSurveyFromStoreByURL();

    if (originalSurvey === undefined) {
        return null;
    }

    if (!originalSurvey) {
        showDifftoneAlert(
            constantAlertVariants.duplicate_survey_failure.text,
            constantAlertVariants.duplicate_survey_failure.variant
        );
        setLocation(INBOX_URL);
        return null;
    }

    const onDuplicateHandler = (
        optionalProperties: OptionalPropertiesForDuplicateSurvey
    ) => {
        finalizeDuplicateSurvey(
            originalSurvey as SurveyClasses,
            optionalProperties
        );
        showDifftoneAlert(
            constantAlertVariants.duplicate_survey.text,
            constantAlertVariants.duplicate_survey.variant,
            moveToDraftSurveys,
            onClose,
            "",
            "Drafts"
        );
    };

    const onClose = () => {
        setLocation(INBOX_URL);
    };

    const moveToDraftSurveys = () => {
        setLocation(PAGE_URLS.INBOX_DRAFT);
    };

    return (
        <DuplicateSurveyPopup
            onDuplicateHandler={onDuplicateHandler}
            titleName="Duplicate"
            survey={originalSurvey as SurveyClasses}
            buttonText="Duplicate"
        />
    );
});
