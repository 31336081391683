import type {
  SurveyEvent,
  ValidationResult,
  ValidationType,
} from "@difftone/types";

import {
  validateEventTime,
  validateEventTitle,
} from "./properties-validations";
import { validateEventTitleUniqueness } from "./uniqueness-validations";

//SurveyEvent
export const validateSurveyEvent = (surveyEvent: SurveyEvent) => {
  const res: ValidationResult<ValidationType>[] = [];

  res.push(...validateEventTitle(surveyEvent));

  return res;
};

//SurveyEvents
export const validateSurveyEvents = (
  surveyEvents: SurveyEvent[]
): ValidationResult<ValidationType>[] => {
  const res: ValidationResult<ValidationType>[] = [];

  //Descreete validations
  surveyEvents.forEach((surveyEvent) =>
    res.push(...validateSurveyEvent(surveyEvent))
  );

  //Grupe Validations
  res.push(...validateEventTime(surveyEvents));
  res.push(validateEventTitleUniqueness(surveyEvents));
  return res;
};
