import React from "react";
import { getMostPopularForSingleOrMultipleChoice } from "@difftone/actions";
import {
    Question,
    SingleChoiceQuestion,
    SupportedQuestionResultsMapAfterDigestionWithoutNulls,
} from "@difftone/types";

import { ResultBox } from "@difftone/shared-components";

export type SingleChoiceTypeProps = {
    question: Question<SingleChoiceQuestion>;
    digestedResultsRow: SupportedQuestionResultsMapAfterDigestionWithoutNulls;
    columnKey: string;
    index: number;
};

export const SingleChoiceType = (props: SingleChoiceTypeProps) => {
    const { columnKey, digestedResultsRow, index, question } = props;

    const mostPopularSingleChoice = getMostPopularForSingleOrMultipleChoice(
        digestedResultsRow,
        columnKey
    );

    return (
        <ResultBox
            key={question.uuid + columnKey + index}
            colorIndicator={
                index === 0
                    ? mostPopularSingleChoice
                        ? "OVERALL"
                        : "DEFAULT"
                    : "DEFAULT"
            }
            resultValue={mostPopularSingleChoice ? mostPopularSingleChoice : ""}
        />
    );
};
