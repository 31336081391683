import { ResponderToAnswer } from "@difftone/types";
import {
    RESPONDENTS_SYSTEM_HEADER,
    simpleSurveyResultsStore,
} from "@difftone/stores";
import {
    filterBySelectedQuestionKeysInFilterProfile,
    getSortedNonTextualResponsesByAnswerType,
    getSortedTextualResponsesByAnswerType,
    sortByRespondersPublicName,
} from "./utils";

const getSortedResponses = (
    responses: ResponderToAnswer[]
): ResponderToAnswer[] => {
    const { sortingProfile, filterProfile } = simpleSurveyResultsStore;
    const sortedResponses: ResponderToAnswer[] = [...responses];
    const sortBy = sortingProfile.sortBy;
    const isAscending = sortingProfile.direction === "ASCENDING";

    if (!sortBy) {
        return sortedResponses;
    }

    if (filterProfile.filterdColumnKey === RESPONDENTS_SYSTEM_HEADER) {
        return sortByRespondersPublicName(isAscending, sortedResponses);
    }

    switch (sortBy) {
        case "TEXT":
            return getSortedTextualResponsesByAnswerType(
                isAscending,
                sortedResponses
            );
        case "NONTEXT":
            return getSortedNonTextualResponsesByAnswerType(
                isAscending,
                sortedResponses
            );
        default:
            return sortedResponses;
    }
};

const getFiltredResponses = (responses: ResponderToAnswer[]) => {
    const { filterProfile, selectedQuestion } = simpleSurveyResultsStore;
    const filterdResponses: ResponderToAnswer[] = [...responses];

    const currentFiltreKeysForSelectedQuestion = Object.keys(
        filterProfile.filterdProfilesMap
    ).filter((key) => key.includes(selectedQuestion || ""));

    if (!currentFiltreKeysForSelectedQuestion.length) {
        return filterdResponses;
    }

    return filterBySelectedQuestionKeysInFilterProfile(
        filterdResponses,
        currentFiltreKeysForSelectedQuestion
    );
};

export const getSortedAndFilteredResponses = (
    responses: (ResponderToAnswer | null)[]
): ResponderToAnswer[] => {
    const onlyResponsesWithNoNull = responses.filter((response) => response);

    const afterSortingResponses = getSortedResponses(
        onlyResponsesWithNoNull as ResponderToAnswer[]
    );

    const afterSortingAndFilteringResponses = getFiltredResponses(
        afterSortingResponses
    );

    return afterSortingAndFilteringResponses as ResponderToAnswer[];
};
