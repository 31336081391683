import React, { FC, PropsWithChildren, ReactElement } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import {
    QuestionType,
    Question,
    QuestionTypeName,
    UUID,
    SupportedQuestionResultsMapAfterDigestionWithoutNulls,
    BaseSurvey,
} from "@difftone/types";
import {
    CategoryDetailedResultBox,
    DetailedResultBoxAnonymousType,
    QuestionTypeIcon,
    ResultBox,
    ResultsAverage,
} from "@difftone/shared-components";
import { COLUMN_0, DATA_ANONYMIZED } from "@difftone/common-constants";
import { categoriesDisplayStore, ResultsTable } from "@difftone/stores";
import { setCategoriesDisplayMapSetter } from "@difftone/actions";

import { ResultsCategoryQuestion } from "./components";
import arrowCollapseIcon from "@difftone/assets/down-arrow.svg";
import { CategoriesResultsRatingMap } from "@difftone/reducers";

import {
    results_category,
    results_category_wrap,
    results_category_container,
    results_category_end,
    results_category_name,
    questions_counter,
    results_category_types_icons,
    results_category_types_icon,
    results_category_collapse,
    results_category_collapse_toggled,
    results_category_questions,
    results_category_questions_showed,
    results_category_answers,
    results_category_answer_no_rating,
    results_category_answer_no_rating_stab,
} from "./favorable-category-display.module.css";

export type FavorableCategoryDisplayProps = {
    categoryName: string;
    categoryUUID: UUID;
    questions: Question<QuestionType>[];
    categoriesResultsRatingMap: CategoriesResultsRatingMap;
    questionsResults: ResultsTable;
    survey: BaseSurvey;
};

export const FavorableCategoryDisplay: FC<
    PropsWithChildren<FavorableCategoryDisplayProps>
> = observer((props: FavorableCategoryDisplayProps): ReactElement => {
    const {
        categoryName,
        questions,
        categoryUUID,
        categoriesResultsRatingMap,
        questionsResults,
        survey,
    } = props;

    const categoryToggled =
        categoriesDisplayStore.getCategoryDisplayByUUID(categoryUUID);

    const questionsTypeNameMap = new Set(
        questions.map((question) => question.question_type_name)
    );
    const questionTypeNames: QuestionTypeName[] =
        Array.from(questionsTypeNameMap);

    const ratingQuestions = questions.filter(
        ({ question_type_name }) => question_type_name === "RATING"
    );

    const changeCategoryDisplay = () => {
        setCategoriesDisplayMapSetter(categoryUUID, !categoryToggled);
    };

    const isSurveyResponseAnonimity =
        categoriesResultsRatingMap[categoryUUID][COLUMN_0] === DATA_ANONYMIZED;

    return (
        <div className={results_category_container}>
            <div className={results_category_wrap}>
                <div className={results_category}>
                    <div>
                        <span className={results_category_name}>
                            {categoryName}
                        </span>
                        <span
                            className={questions_counter}
                        >{`(${ratingQuestions.length})`}</span>
                    </div>
                    <div className={results_category_end}>
                        <div className={results_category_types_icons}>
                            {questionTypeNames.map((questionTypeName) => (
                                <QuestionTypeIcon
                                    key={questionTypeName}
                                    className={results_category_types_icon}
                                    questionTypeName={questionTypeName}
                                />
                            ))}
                        </div>
                        <img
                            alt=""
                            onClick={changeCategoryDisplay}
                            className={clsx(results_category_collapse, {
                                [results_category_collapse_toggled]:
                                    !categoryToggled,
                            })}
                            src={arrowCollapseIcon}
                        />
                    </div>
                </div>

                <div
                    className={clsx(results_category_questions, {
                        [results_category_questions_showed]: categoryToggled,
                    })}
                >
                    {questions.map((question) => (
                        <ResultsCategoryQuestion
                            question={question}
                            key={question.uuid}
                        />
                    ))}
                </div>
            </div>

            <ResultsAverage questions={questions} />

            <div className={results_category_answers}>
                {ratingQuestions.length ? (
                    <>
                        {isSurveyResponseAnonimity ? (
                            <DetailedResultBoxAnonymousType
                                color="primary"
                                surveyAnonymityLevel={survey.anonimity}
                            />
                        ) : (
                            <ResultBox
                                colorIndicator="OVERALL"
                                resultValue={
                                    categoriesResultsRatingMap[categoryUUID][
                                        COLUMN_0
                                    ]
                                }
                            />
                        )}
                    </>
                ) : (
                    <div className={results_category_answer_no_rating_stab}>
                        <span className={results_category_answer_no_rating}>
                            This category has no rating questions to show
                        </span>
                    </div>
                )}

                {categoryToggled
                    ? questions.map((question, index) => {
                          const lastQuestionInList =
                              questions[questions.length - 1] === question;

                          return (
                              <CategoryDetailedResultBox
                                  color="primary"
                                  key={question.uuid}
                                  index={index}
                                  question={question}
                                  digestedResultsRow={
                                      questionsResults[
                                          question.uuid
                                      ] as SupportedQuestionResultsMapAfterDigestionWithoutNulls
                                  }
                                  columnKey={COLUMN_0}
                                  survey={survey}
                                  isLastQuestionInList={lastQuestionInList}
                                  isFirstItemInRow
                                  isNoRatingQuestionsInCategory={
                                      !ratingQuestions.length
                                  }
                              />
                          );
                      })
                    : null}
            </div>
        </div>
    );
});
