import {
    GoogleContactScopes,
    DifftoneOnlyGoogleContactScopes,
    GoogleFormScopes,
    DifftoneOnlyGoogleFormScopes,
} from "@difftone/constants";
import { getSelectedOrganizationOrThrow } from "@difftone/reducers";

let _authInstance: any;
let _provider: "GOOGLE" | "MICROSOFT";

export const setAuthInstance = (
    newAuthInstance: any,
    provider: "GOOGLE" | "MICROSOFT"
) => {
    _authInstance = newAuthInstance;
    _provider = provider;
};

export const getAuthInstance = () => {
    return _authInstance;
};

export const getAuthProvider = () => {
    return _provider;
};

//TODO: refactor after input from Google
//This is a temporary procedure until Google authorizes our usage of group APIs
//Do not copy this pattern
export const handleGoogleContactsPermissions = async () => {
    const organization = getSelectedOrganizationOrThrow();
    const organizationName = organization ? organization.name : "";

    const requiredScopes = [
        ...GoogleContactScopes,
        ...(organizationName === "Difftone"
            ? DifftoneOnlyGoogleContactScopes
            : []),
    ];

    const googleUserInstance = getAuthInstance();

    const grantedScopes = googleUserInstance.getGrantedScopes().split(" ");

    const scopesToRequest = requiredScopes.filter(
        (scope) => !grantedScopes.includes(scope)
    );

    if (!scopesToRequest.length) return;

    try {
        const options = new gapi.auth2.SigninOptionsBuilder();
        options.setScope(scopesToRequest.join(" "));

        const res = await googleUserInstance.grant(options);
        if (res.error) {
            throw new Error(res.error);
        } else {
            setAuthInstance(res, "GOOGLE");
        }
    } catch (error) {
        console.error("[googleUserInstance.grant]:: failed", error);
    }
};

export const handleGoogleFormsPermissions = async () => {
    const organization = getSelectedOrganizationOrThrow();
    const organizationName = organization ? organization.name : "";

    const requiredScopes = [
        ...GoogleFormScopes,
        ...(organizationName === "Difftone"
            ? DifftoneOnlyGoogleFormScopes
            : []),
    ];

    const googleUserInstance = getAuthInstance();

    const grantedScopes = googleUserInstance.getGrantedScopes().split(" ");

    const scopesToRequest = requiredScopes.filter(
        (scope) => !grantedScopes.includes(scope)
    );

    if (!scopesToRequest.length) return;

    try {
        const options = new gapi.auth2.SigninOptionsBuilder();
        options.setScope(scopesToRequest.join(" "));

        const res = await googleUserInstance.grant(options);
        if (res.error) {
            throw new Error(res.error);
        } else {
            setAuthInstance(res, "GOOGLE");
        }
    } catch (error) {
        console.error("[googleUserInstance.grant]:: failed", error);
    }
};
