import React from "react";
import { SharedSurvey } from "@difftone/types";
import {
    removeDeletedSharedSurveysFromStore,
    showDifftoneAlert,
    updateToDeleteSharedSurvey,
} from "@difftone/actions";
import { ShowDifftoneTooltip } from "@difftone/shared-components";
import { constantAlertVariants } from "@difftone/constants";

import deleteIcon from "@difftone/assets/delete-survey.svg";
import { ActionIconItem } from "./components";

export type DeleteButtonProps = {
    sharedSurvey: SharedSurvey;
};

export const DeleteButton = (props: DeleteButtonProps) => {
    const { sharedSurvey } = props;

    const onDelete = () => {
        updateToDeleteSharedSurvey(sharedSurvey);
        removeDeletedSharedSurveysFromStore();
        showDifftoneAlert(
            constantAlertVariants.shared_survey_delete_success.text,
            constantAlertVariants.shared_survey_delete_success.variant
        );
    };

    const onUnconfirmedDelete = () => {
        showDifftoneAlert(
            constantAlertVariants.shared_survey_delete.text,
            constantAlertVariants.shared_survey_delete.variant,
            onDelete,
            () => {},
            `${sharedSurvey.survey.survey_name}`
        );
    };
    return (
        <div>
            {" "}
            <ShowDifftoneTooltip tooltipPosition="bottom" tip="Delete">
                <ActionIconItem
                    action={onUnconfirmedDelete}
                    disabled={false}
                    icon={deleteIcon}
                />
            </ShowDifftoneTooltip>
        </div>
    );
};
