import { makeAutoObservable } from "mobx";
import { UUID } from "@difftone/types";

class SurveyResponseDisplayStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _focusedSurveyResponseInputCard: UUID | null = null;
    get focusedSurveyResponseInputCard(): UUID | null {
        return this._focusedSurveyResponseInputCard;
    }
    set focusedSurveyResponseInputCard(newFocusedNode: UUID | null) {
        this._focusedSurveyResponseInputCard = newFocusedNode;
    }

    private _wasSurveyResponseOnceSubmitted = false;

    get wasSurveyResponseOnceSubmitted() {
        return this._wasSurveyResponseOnceSubmitted;
    }
    set wasSurveyResponseOnceSubmitted(value) {
        this._wasSurveyResponseOnceSubmitted = value;
    }

    public getFocusedSurveyCardElementByUUID(focusedUUID: UUID) {
        const allSurveyCards = document.querySelectorAll(
            "div[data-survey-focused-card]"
        );
        let cardNode = null;
        for (let i = 0; i < allSurveyCards.length; i++) {
            const _uuid = allSurveyCards[i].getAttribute("uuid");
            if (_uuid === focusedUUID) {
                cardNode = allSurveyCards[i];
            }
        }

        return cardNode;
    }

    public clearStore = () => {
        this._focusedSurveyResponseInputCard = null;
    };
}

export const surveyResponseDisplayStore = new SurveyResponseDisplayStore();
