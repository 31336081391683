import { makeAutoObservable } from "mobx";
import md5 from "md5";

import {
    fetchConnectionsList,
    setConnectionListToMap,
} from "./connection-list-store-internal-actions";

class ConnectionListStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _storeInitialize: boolean = false;
    get storeInitialize(): boolean {
        return this._storeInitialize;
    }
    set storeInitialize(newStoreInitialize: boolean) {
        this._storeInitialize = newStoreInitialize;
    }

    private _startInitialization: boolean = false;
    get startInitialization(): boolean {
        return this._startInitialization;
    }
    set startInitialization(newStartInitialization: boolean) {
        this._startInitialization = newStartInitialization;
    }

    private _connectionListMap: { [x: string]: string | undefined } = {};

    get connectionListMap() {
        return this._connectionListMap;
    }

    set connectionListMap(newConnectionListMap: {
        [x: string]: string | undefined;
    }) {
        this._connectionListMap = newConnectionListMap;
    }

    public addConnectionToList = (newEmailConnection: string) => {
        const emailAsKey = md5(newEmailConnection);
        setConnectionListToMap(emailAsKey, newEmailConnection);
    };

    public getConnectionList = () => {
        return Object.values(this._connectionListMap);
    };

    public init = () => {
        if (!this.startInitialization) {
            fetchConnectionsList();
            this.startInitialization = true;
        }
    };

    public clearStore = () => {
        this._storeInitialize = false;
        this._startInitialization = false;
        this._connectionListMap = {};
    };
}

export const connectionListStore = new ConnectionListStore();
