import React from "react";
import { BaseSurvey } from "@difftone/types";

import { SurveyLeftMenuQuestionList, SurveyLeftMenuHeader } from "./components";

import { survey_left_menu_ul } from "./left-menu-children.module.css";

export type LeftMenuChildrenProps = { survey: BaseSurvey };

export const LeftMenuChildren = (props: LeftMenuChildrenProps) => {
    const { survey } = props;
    return (
        <ul className={survey_left_menu_ul}>
            <SurveyLeftMenuHeader survey={survey} />
            <SurveyLeftMenuQuestionList survey={survey as BaseSurvey} />
        </ul>
    );
};
