import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import {
    Question,
    QuestionToAnswerDescreteQuestion,
    QuestionType,
    ResultsMapAfterDigestion,
    SupportedQuestionResultsMapAfterDigestion,
    SupportedQuestionResultsMapAfterDigestionWithoutNulls,
    BaseSurvey,
} from "@difftone/types";
import dropDownArrow from "@difftone/assets/down-arrow.svg";
import { ShowDifftoneTooltip, Spinner } from "@difftone/shared-components";
import { COLUMN_0 } from "@difftone/common-constants";
import {
    ExpandedResults,
    NotSupportedQuestion,
    ValuesPlain,
    MobileResultBoxFactory,
    MobileQuestionTypeExtarctor,
} from "./components";

import {
    mobile_results_question,
    question_title,
    question_index,
    result_box_wrapper,
    expand_collapse_arrow,
    rotated_arrow,
    question_description,
    type_class,
} from "./mobile-results-question.module.css";

export const MOBILE_MAX_TITLE_LENGTH = 50;

const questionTooltipPosition = makeStyles({
    questionTopTooltip: {
        top: "25px",
    },
});

export type MobileResultsQuestionProps = {
    question: Question<QuestionType>;
    index: number;
    digestedResultsRow: ResultsMapAfterDigestion;
    survey: BaseSurvey;
};

export const MobileResultsQuestion = (props: MobileResultsQuestionProps) => {
    const { question, index, digestedResultsRow } = props;
    const [collapsed, setCollapsed] = useState(true);

    const questionTooltip = questionTooltipPosition().questionTopTooltip;

    if (!digestedResultsRow) {
        return <Spinner size="MEDIUM" />;
    }

    if (
        digestedResultsRow &&
        digestedResultsRow[COLUMN_0] &&
        (digestedResultsRow[COLUMN_0] as QuestionToAnswerDescreteQuestion)[
            "%QUESTION_NOT_SUPPORTED%"
        ] === 0
    ) {
        return <NotSupportedQuestion index={index} question={question} />;
    }

    const nakedDigestedResultsRow: SupportedQuestionResultsMapAfterDigestion =
        {};
    Object.keys(digestedResultsRow).forEach((columnKey) => {
        nakedDigestedResultsRow[columnKey] = JSON.parse(
            JSON.stringify(digestedResultsRow[columnKey])
        );
    });

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    let questionTitle = null;

    if (question.title.length > MOBILE_MAX_TITLE_LENGTH) {
        if (collapsed) {
            questionTitle = (
                <ShowDifftoneTooltip
                    extendTooltipStyle={questionTooltip}
                    tooltipPosition="top"
                    tip={question.title}
                >
                    <>
                        <span className={question_index}>{index + 1}.</span>
                        <span>
                            {question.title
                                .slice(0, MOBILE_MAX_TITLE_LENGTH)
                                .concat("...")}
                        </span>
                    </>
                </ShowDifftoneTooltip>
            );
        } else {
            questionTitle = (
                <>
                    <span className={question_index}>{index + 1}.</span>
                    <span>{question.title}</span>
                </>
            );
        }
    } else {
        questionTitle = (
            <>
                <span className={question_index}>{index + 1}.</span>
                <span>{question.title}</span>
            </>
        );
    }

    return (
        <div className={mobile_results_question}>
            <div className={question_description}>
                <div className={question_title}>
                    {questionTitle}
                    <div className={type_class}>
                        <MobileQuestionTypeExtarctor question={question} />
                    </div>
                </div>
                <div className={result_box_wrapper}>
                    <MobileResultBoxFactory
                        index={0}
                        question={question}
                        columnKey={COLUMN_0}
                        digestedResultsRow={
                            nakedDigestedResultsRow as SupportedQuestionResultsMapAfterDigestionWithoutNulls
                        }
                    />
                    <ValuesPlain
                        resultPopularity={nakedDigestedResultsRow}
                        question={question}
                    />
                </div>
                <img
                    src={dropDownArrow}
                    alt="expand-collapse"
                    onClick={toggleCollapse}
                    className={clsx(
                        expand_collapse_arrow,
                        !collapsed && rotated_arrow
                    )}
                />
            </div>
            {collapsed ? null : (
                <ExpandedResults
                    nakedDigestedResultsRow={nakedDigestedResultsRow}
                    question={question}
                />
            )}
        </div>
    );
};
