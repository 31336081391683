import React from "react";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import clsx from "clsx";
import { TimelineEvent } from "@difftone/types";

import {
    setSelectedEventAlert,
    setSurveyEventToDisplay,
} from "@difftone/actions";

import { alertsStore, wizardDisplayStore } from "@difftone/stores";
import {
    getDayTimeFromTimeString,
    getLocalDateStrFromUtcDateStr,
    getLocalDayTimeInMilsWithDateString,
    milisecondsToTimeString,
} from "@difftone/time-utils";
import {
    ShowDifftoneTooltip,
    BellEventIcon,
} from "@difftone/shared-components";

import {
    event_description,
    passed_event_description,
    passed_event_description_selected,
    future_event_description,
    event_title,
    event_title_text,
    event_bell,
    future_event_bell,
    event_alert_counter,
    event_time_and_date,
    future_selected_event,
} from "./event-description.module.css";

const SURVEY_TITLE_ALLOWED_TEXT_LENGTH = 20;

export type EventDescriptionProps = {
    eventTimelinePosition: "PASSED" | "CURRENT" | "FUTURE" | undefined;
    surveyEvent: TimelineEvent;
};

const COLOR_GREEN = "GREEN";
const COLOR_GRAY = "GRAY";

export const EventDescription = observer((props: EventDescriptionProps) => {
    const { selectedEventUUID, isEditableSurvey } = wizardDisplayStore;
    const { eventTimelinePosition, surveyEvent } = props;

    const numberOfEventAlerts = surveyEvent.event_alerts.filter((alertUUID) =>
        isEditableSurvey
            ? !alertsStore.getSurveyAlertCopyByUuid(alertUUID)?.alert_type
            : !wizardDisplayStore.getSurveyCopyAlertCopyByUuid(alertUUID)
                  ?.alert_type
    ).length;

    //TODO: Add null handling for function and function return
    const eventLocalDateStr = getLocalDateStrFromUtcDateStr(
        surveyEvent.date_utc!,
        surveyEvent.day_utc_time!
    );

    //TODO: Add null handling for function and function return
    const dayUtcTimeStr = getDayTimeFromTimeString(surveyEvent.day_utc_time!);

    const localDayTime = getLocalDayTimeInMilsWithDateString(
        dayUtcTimeStr,
        eventLocalDateStr
    );

    const localDateDisplayFormat =
        dayjs(eventLocalDateStr).format("DD MMM YYYY");

    const onClickSelectEventToDisplay = () => {
        setSelectedEventAlert(null);
        setSurveyEventToDisplay(surveyEvent.uuid);
    };

    let eventStyle = null;

    switch (eventTimelinePosition) {
        case "PASSED":
            eventStyle = clsx(
                passed_event_description,
                selectedEventUUID === surveyEvent.uuid &&
                    passed_event_description_selected
            );
            break;
        case "FUTURE":
            eventStyle = clsx(
                future_event_description,
                selectedEventUUID === surveyEvent.uuid && future_selected_event
            );
            break;
    }

    let surveyEventTitle = null;

    if (surveyEvent.title.length > SURVEY_TITLE_ALLOWED_TEXT_LENGTH) {
        surveyEventTitle = (
            <ShowDifftoneTooltip tip={surveyEvent.title} tooltipPosition="top">
                <div className={event_title}>
                    <div className={event_title_text}>{surveyEvent.title}</div>
                    <div className={event_bell}>
                        <BellEventIcon
                            className={future_event_bell}
                            color={
                                eventTimelinePosition === "FUTURE"
                                    ? COLOR_GREEN
                                    : COLOR_GRAY
                            }
                        />
                        <div
                            className={event_alert_counter}
                        >{`(${numberOfEventAlerts})`}</div>
                    </div>
                </div>
            </ShowDifftoneTooltip>
        );
    } else {
        surveyEventTitle = (
            <div className={event_title}>
                <div className={event_title_text}>{surveyEvent.title}</div>
                <div className={event_bell}>
                    <BellEventIcon
                        className={future_event_bell}
                        color={
                            eventTimelinePosition === "FUTURE"
                                ? COLOR_GREEN
                                : COLOR_GRAY
                        }
                    />
                    <div
                        className={event_alert_counter}
                    >{`(${numberOfEventAlerts})`}</div>
                </div>
            </div>
        );
    }

    return (
        <div
            onClick={onClickSelectEventToDisplay}
            className={clsx(event_description, eventStyle)}
        >
            {surveyEventTitle}

            <div className={event_time_and_date}>
                <span>{localDateDisplayFormat}</span>
                <span>{milisecondsToTimeString(localDayTime)}</span>
            </div>
        </div>
    );
});
