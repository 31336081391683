import React from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import { TimelineEvent } from "@difftone/types";

import {
    calculateTotalTimeInMilliseconds,
    getDateAtMidnightFromDateString,
    getDayTimeFromTimeString,
} from "@difftone/time-utils";
import { distancesArray } from "@difftone/reducers";

import { TimelineEventIcon } from "./components";
import {
    wizard_dates,
    current_time_as_first_event,
} from "./wizard-dates.module.css";

export type WizardDatesProps = {
    sortedEvents: TimelineEvent[];
};

export const WizardDates = observer((props: WizardDatesProps) => {
    const { sortedEvents } = props;

    const iconTypeDisplay = (eventTime: number) => {
        const currentTimePosition = new Date().getTime();

        if (currentTimePosition > eventTime) {
            return "PASSED";
        }
        if (currentTimePosition < eventTime) {
            return "FUTURE";
        }
    };

    const distancesIcons = distancesArray(sortedEvents);

    const firstEvent = sortedEvents[0];

    return (
        <div
            className={clsx(
                wizard_dates,
                firstEvent?.isCurrentTime && current_time_as_first_event
            )}
        >
            {sortedEvents.map((surveyEvent, index) => (
                <TimelineEventIcon
                    distanceMargin={distancesIcons[index]}
                    key={surveyEvent.uuid}
                    surveyEvent={surveyEvent}
                    eventTimelinePosition={
                        surveyEvent.isCurrentTime
                            ? "CURRENT"
                            : iconTypeDisplay(
                                  calculateTotalTimeInMilliseconds(
                                      //TODO: Add null handling for function and function return
                                      getDateAtMidnightFromDateString(
                                          surveyEvent.date_utc!
                                      ),
                                      //TODO: Add null handling for function and function return
                                      getDayTimeFromTimeString(
                                          surveyEvent.day_utc_time!
                                      )
                                  )
                              )
                    }
                />
            ))}
        </div>
    );
});
