import React, { FC, PropsWithChildren, ReactElement, useCallback } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import clsx from "clsx";
import MUIDialog, {
    DialogProps as MUIDialogProps,
} from "@material-ui/core/Dialog";
import MUIDialogContent from "@material-ui/core/DialogContent";

import closeIcon from "@difftone/assets/close-datepicker-white.svg";

import {
    dialog_title,
    dialog_close,
    dialog_title_wrap,
    dialog_background,
    dialog_box_shadow,
    children_content,
    dialog_title_tooltip,
    dialog_box_border_radius,
} from "./dialog.module.css";
import { ShowDifftoneTooltip } from "@difftone/shared-components";

const BACKDROP_BACKGROUND = { backgroundColor: "transparent" };

export interface DialogProps extends Omit<MUIDialogProps, "onClose"> {
    onClose?: () => void;
    classes?: MUIDialogProps["classes"];
    className?: string;
    isOutSideClose?: boolean;
    isVisible?: boolean;
    boldTitle?: string;
    italicTitle?: string;
}

const MAX_CHARS_ALLOWED_IN_TITLE = 31;

export const Dialog: FC<
    React.PropsWithChildren<React.PropsWithChildren<DialogProps>>
> = (props: DialogProps): ReactElement => {
    const {
        title,
        boldTitle = "",
        italicTitle = "",
        onClose,
        children,
        classes,
        isOutSideClose = false,
        isVisible,
        className,
        ...restProps
    } = props;

    const Wrapper: FC<PropsWithChildren<unknown>> = useCallback(
        ({ children }) =>
            isOutSideClose && onClose ? (
                <OutsideClickHandler
                    disabled={isVisible}
                    onOutsideClick={onClose}
                >
                    {children}
                </OutsideClickHandler>
            ) : (
                <>{children}</>
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isOutSideClose]
    );

    return (
        <MUIDialog
            fullScreen
            {...restProps}
            classes={{ ...classes, root: dialog_background }}
            BackdropProps={{ style: BACKDROP_BACKGROUND }}
            PaperProps={{
                elevation: 0,
                classes: {
                    elevation0: dialog_box_shadow,
                    rounded: dialog_box_border_radius,
                },
            }}
        >
            {/*@ts-ignore*/}
            <Wrapper>
                <div
                    className={clsx(dialog_title_wrap, className && className)}
                >
                    {title &&
                    `${title} ${boldTitle} ${italicTitle}`.length >
                        MAX_CHARS_ALLOWED_IN_TITLE ? (
                        <ShowDifftoneTooltip
                            tooltipPosition="bottom"
                            tip={`${boldTitle} ${title} ${italicTitle}`}
                        >
                            <h2 className={dialog_title_tooltip}>
                                <strong>{boldTitle}</strong>
                                {` `}
                                {title}
                                {` `}
                                <i>{italicTitle}</i>
                            </h2>
                        </ShowDifftoneTooltip>
                    ) : (
                        <h2 className={dialog_title}>
                            <strong>{boldTitle}</strong>
                            {` `}
                            {title}
                            {` `}
                            <i>{italicTitle}</i>
                        </h2>
                    )}
                    {onClose ? (
                        <img
                            alt=""
                            onClick={onClose}
                            src={closeIcon}
                            className={dialog_close}
                        />
                    ) : null}
                </div>
                <MUIDialogContent classes={{ root: children_content }}>
                    {children}
                </MUIDialogContent>
            </Wrapper>
        </MUIDialog>
    );
};
