import React from "react";
import dayjs from "dayjs";

import { SimpleSurvey } from "@difftone/types";
import { wizardDisplayStore } from "@difftone/stores";
import {
    getDayTimeFromTimeString,
    getLocalDateStrFromUtcDateStr,
    getLocalDayTimeInMilsWithDateString,
    milisecondsToTimeString,
} from "@difftone/time-utils";
import { setSelectedEventAlert, showDifftoneAlert } from "@difftone/actions";

import greenReminderBell from "@difftone/assets/bell-reminder-green-info.svg";
import {
    reminder_info_wrapper,
    event_date_info,
} from "./reminder-info.module.css";

export type ReminderInfoProps = {
    survey: SimpleSurvey;
};

export const ReminderInfo = (props: ReminderInfoProps) => {
    const { selectedEventUUID } = wizardDisplayStore;
    const { survey } = props;

    const surveyEvent = survey.survey_events.find(
        (surveyEvent) => surveyEvent.uuid === selectedEventUUID
    );

    if (!surveyEvent) {
        //TODO: move to alert variant constants
        showDifftoneAlert("Could nor find survey event.", "FAILURE");
        setSelectedEventAlert(null);
        return null;
    }

    //TODO: Add null handling for function and function return
    const eventLocalDateStr = getLocalDateStrFromUtcDateStr(
        surveyEvent.date_utc!,
        surveyEvent.day_utc_time!
    );

    //TODO: Add null handling for function and function return
    const dayUtcTimeStr = getDayTimeFromTimeString(surveyEvent.day_utc_time!);

    const localDayTime = getLocalDayTimeInMilsWithDateString(
        dayUtcTimeStr,
        eventLocalDateStr
    );

    const localDateDisplayFormat =
        dayjs(eventLocalDateStr).format("DD MMM YYYY");

    return (
        <div className={reminder_info_wrapper}>
            <img src={greenReminderBell} alt="green-bell" />
            <div className={event_date_info}>
                <span>{`${localDateDisplayFormat}, ${milisecondsToTimeString(
                    localDayTime
                )}`}</span>
            </div>
        </div>
    );
};
