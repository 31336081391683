import { totalTimeInMiliSecondsFromString } from "@difftone/time-utils";
import {
    SurveyEvent,
    SurveyEventsDateFormatValidationType,
    SurveyEventsDateValidationType,
    UUID,
    ValidationResult,
    ValidationType,
} from "@difftone/types";

const ILLEGAL_DATE_FORMAT = "yyyy-mm-dd";
const INVALID_DATE = "Invalid Date";

export const illegalDateFormatStringCheck: { [key: string]: string } = {
    "yyyy-mm-dd": ILLEGAL_DATE_FORMAT,
    "Invalid Date": INVALID_DATE,
};

export const validateFrontEndSurveyEvents = (
    surveyEvents: SurveyEvent[]
): ValidationResult<ValidationType>[] => {
    const res: ValidationResult<ValidationType>[] = [];

    res.push(...validateEventTime(surveyEvents));
    res.push(...validationEventTimeFormat(surveyEvents));

    return res;
};

const validateEventTime = (
    surveyEvents: SurveyEvent[]
): ValidationResult<SurveyEventsDateValidationType>[] => {
    try {
        const res: UUID[] = [];

        const lastDateToFillSurvey = surveyEvents.find(
            (_surveyEvent: SurveyEvent) =>
                _surveyEvent.system_survey_event_type === "LAST_DATE_TO_FILL"
        );

        const lastDateToFillInMiliSeconds = totalTimeInMiliSecondsFromString(
            lastDateToFillSurvey?.date_utc!,
            lastDateToFillSurvey?.day_utc_time!
        );

        if (lastDateToFillInMiliSeconds <= Date.now()) {
            res.push(lastDateToFillSurvey!.uuid);
        }

        return [
            {
                is_valid: !res.length,
                validation_error_code: "SURVEY_EVENT_ILLEGAL_DATE",
                validation_error_type: { invalid_events_dates_uuids: res },
            },
        ];
    } catch (error) {
        console.error(
            `validateSurveyDates error:: ${(error as Error).message}`
        );
        return [
            {
                is_valid: false,
                validation_error_code: "EXCEPTION_DURING_VALIDATIONS",
                validation_error_type: {
                    invalid_events_dates_uuids: [(error as Error).message],
                },
            },
        ];
    }
};

const validationEventTimeFormat = (
    surveyEvents: SurveyEvent[]
): ValidationResult<SurveyEventsDateFormatValidationType>[] => {
    try {
        //@ts-ignore
        const res: UUID[] = surveyEvents
            .map((surveyEvent) => {
                if (!surveyEvent.date_utc || !surveyEvent.day_utc_time) {
                    return false;
                }
                return surveyEvent.date_utc ===
                    illegalDateFormatStringCheck[surveyEvent.date_utc!]
                    ? surveyEvent.uuid
                    : null;
            })
            .filter((eventUUID) => eventUUID);

        return [
            {
                is_valid: !res.length,
                validation_error_code: "SURVEY_EVENT_ILLEGAL_DATE_FORMAT",
                validation_error_type: { invalid_events_dates_uuids: res },
            },
        ];
    } catch (error) {
        console.error(
            `validateSurveyDates error:: ${(error as Error).message}`
        );
        return [
            {
                is_valid: false,
                validation_error_code: "EXCEPTION_DURING_VALIDATIONS",
                validation_error_type: {
                    invalid_events_dates_uuids: [(error as Error).message],
                },
            },
        ];
    }
};
