import { action } from "mobx";

import { leftMenuStore } from "@difftone/stores";
import { MOBILE_WIDTH_BREAKPOINT } from "@difftone/constants";

export const toggleLeftMenu = action(() => {
    if (window.innerWidth <= MOBILE_WIDTH_BREAKPOINT) {
        leftMenuStore.isMobileOpen = !leftMenuStore.isMobileOpen;
    } else {
        leftMenuStore.isOpen = !leftMenuStore.isOpen;
    }
});

export const updateFirstTimeWindowFlip = action(() => {
    leftMenuStore.firstTimeWindowFlip = false;
});
