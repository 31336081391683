import { makeAutoObservable, observable, ObservableMap } from "mobx";

import { ResultsFilter, SurveyResponsesSummery, UUID } from "@difftone/types";
import { getSurveyResponsesSummaryBySurveyUUID } from "./survey-response-summary-internal-actions";
import md5 from "md5";

class SurveyResponsesSummaryStore {
    constructor() {
        makeAutoObservable<
            SurveyResponsesSummaryStore,
            "_fetchedSurveyResponsesSummeryKeys"
        >(this, {
            _fetchedSurveyResponsesSummeryKeys: false,
        });
    }

    private _fetchedSurveyResponsesSummeryKeys: string[] = [];

    private _surveyResponsesSummeryMap: ObservableMap<
        string,
        SurveyResponsesSummery | null
    > = observable.map({});

    public getSurveyResponsesSummeryMap() {
        return this._surveyResponsesSummeryMap;
    }

    public getSurveyResponsesSummaryByKey(key: string) {
        return this._surveyResponsesSummeryMap.get(key);
    }

    public setSurveyResponseSummaryToMap(
        key: string,
        newSurveyResponseSummery: SurveyResponsesSummery
    ) {
        this._surveyResponsesSummeryMap.merge({
            [key]: newSurveyResponseSummery,
        });
    }

    public extractKeysFromFilters(
        filtersPlain: ResultsFilter[],
        surveyUUID: UUID
    ): string {
        return md5(JSON.stringify(`${filtersPlain}-${surveyUUID}`));
    }

    public getSurveyResponseSummaryByFilterPlain(
        surveyUUID: UUID,
        filterBy: ResultsFilter[]
    ) {
        const summaryByFilterKey = this.extractKeysFromFilters(
            filterBy,
            surveyUUID
        );

        const surveyResponsesSummery =
            this._surveyResponsesSummeryMap.get(summaryByFilterKey);

        if (
            surveyResponsesSummery === undefined &&
            !this._fetchedSurveyResponsesSummeryKeys.includes(
                summaryByFilterKey
            )
        ) {
            this._fetchedSurveyResponsesSummeryKeys.push(summaryByFilterKey);
            getSurveyResponsesSummaryBySurveyUUID(surveyUUID, filterBy);
        }
        return surveyResponsesSummery;
    }

    public getResponsesSummaryMapRow(
        surveyUUID: UUID,
        filtersNotActiveAsCompares: ResultsFilter[],
        filtersActiveAsCompares: ResultsFilter[]
    ) {
        const updatedResponseSummary: (
            | SurveyResponsesSummery
            | null
            | undefined
        )[] = [];
        const responsesSummaryTable = this.getResponsesSummaryMapTable(
            surveyUUID,
            filtersNotActiveAsCompares,
            filtersActiveAsCompares
        );

        if (responsesSummaryTable.includes(null)) {
            return null;
        }
        responsesSummaryTable.forEach((responseSummary, index) => {
            if (index > 0) {
                updatedResponseSummary.push(responseSummary);
            }
        });
        return updatedResponseSummary;
    }

    public clearStore = () => {
        this._surveyResponsesSummeryMap = observable.map({});
    };

    private getResponsesSummaryMapTable(
        surveyUUID: UUID,
        filtersNotActiveAsCompares: ResultsFilter[],
        filtersActiveAsCompares: ResultsFilter[]
    ) {
        switch (filtersActiveAsCompares.length) {
            case 0:
                return [
                    this.getResponsesSummaryMapTableForNoCompareBy(
                        surveyUUID,
                        filtersNotActiveAsCompares
                    ),
                ];
            case 1:
                return this.getResponsesSummaryMapTableSingleCompareBy(
                    surveyUUID,
                    filtersNotActiveAsCompares,
                    filtersActiveAsCompares
                );
            case 2:
                return this.getResponsesSummaryMapTableDoubleCompareBy(
                    surveyUUID,
                    filtersNotActiveAsCompares,
                    filtersActiveAsCompares
                );
            default:
                return [];
        }
    }

    private getResponsesSummaryMapTableForNoCompareBy(
        surveyUUID: UUID,
        filtersNotActiveAsCompares: ResultsFilter[]
    ) {
        return surveyResponsesSummaryStore.getSurveyResponseSummaryByFilterPlain(
            surveyUUID,
            filtersNotActiveAsCompares
        );
    }

    private getResponsesSummaryMapTableSingleCompareBy(
        surveyUUID: UUID,
        filtersNotActiveAsCompares: ResultsFilter[],
        filtersActiveAsCompares: ResultsFilter[]
    ) {
        const overallResponsesSummaryMap =
            surveyResponsesSummaryStore.getSurveyResponseSummaryByFilterPlain(
                surveyUUID,
                [...filtersActiveAsCompares, ...filtersNotActiveAsCompares]
            );

        const comparedByResponsesSummaryMaps =
            filtersActiveAsCompares[0]?.filterValues.map((val1) => {
                const cloneOfFirstComparedBy: ResultsFilter = JSON.parse(
                    JSON.stringify(filtersActiveAsCompares[0])
                );

                cloneOfFirstComparedBy.filterValues = [val1];
                return surveyResponsesSummaryStore.getSurveyResponseSummaryByFilterPlain(
                    surveyUUID,
                    [...filtersNotActiveAsCompares, cloneOfFirstComparedBy]
                );
            });
        return [overallResponsesSummaryMap, ...comparedByResponsesSummaryMaps];
    }

    private getResponsesSummaryMapTableDoubleCompareBy(
        surveyUUID: UUID,
        filtersNotActiveAsCompares: ResultsFilter[],
        filtersActiveAsCompares: ResultsFilter[]
    ) {
        const overallResponsesSummaryMap =
            surveyResponsesSummaryStore.getSurveyResponseSummaryByFilterPlain(
                surveyUUID,
                [...filtersActiveAsCompares, ...filtersNotActiveAsCompares]
            );
        const comparedByResponsesSummaryMaps =
            filtersActiveAsCompares[0]?.filterValues.flatMap((val1) => {
                return filtersActiveAsCompares[1]?.filterValues.map((val2) => {
                    const cloneOfFirstComparedBy: ResultsFilter = JSON.parse(
                        JSON.stringify(filtersActiveAsCompares[0])
                    );
                    const cloneOfSecondComparedBy: ResultsFilter = JSON.parse(
                        JSON.stringify(filtersActiveAsCompares[1])
                    );
                    cloneOfFirstComparedBy.filterValues = [val1];
                    cloneOfSecondComparedBy.filterValues = [val2];

                    const compareByFilters = [
                        cloneOfFirstComparedBy,
                        cloneOfSecondComparedBy,
                    ];
                    return surveyResponsesSummaryStore.getSurveyResponseSummaryByFilterPlain(
                        surveyUUID,
                        [...filtersNotActiveAsCompares, ...compareByFilters]
                    );
                });
            });

        const comparedBySurveySummaryMapOrEmpty = comparedByResponsesSummaryMaps
            ? comparedByResponsesSummaryMaps.concat()
            : [];
        return [
            overallResponsesSummaryMap,
            ...comparedBySurveySummaryMapOrEmpty,
        ];
    }
}

export const surveyResponsesSummaryStore = new SurveyResponsesSummaryStore();
