import { action } from "mobx";

import { getUserUuid } from "@difftone/procedures";
import { categoriesDisplayStore, categoriesStore } from "@difftone/stores";
import {
    getAllAvailableCategories,
    upsertCustomCategory,
} from "@difftone/services";
import { UUID, Label } from "@difftone/types";

export const setCategoriesMapSetter = action((category: Label) => {
    categoriesStore.setCategoryToMap(category.uuid, category);
});

export const setCategoriesStoreInitialized = action(
    (isInitialized: boolean) => {
        categoriesStore.isStoreInitialized = isInitialized;
    }
);

export const setCategoriesStoreIsRequesting = action(
    (isRequesting: boolean) => {
        categoriesStore.isRequesting = isRequesting;
    }
);

export const fetchAvailableCategories = action(
    async (organisationUUID: UUID | null) => {
        const userUUID = getUserUuid();

        if (!userUUID) {
            return [];
        }

        const categories = await getAllAvailableCategories(
            organisationUUID,
            userUUID
        );

        categories.forEach((category) => {
            setCategoriesMapSetter(category);
        });
    }
);

export const createCustomCategoryUpsertToApi = action(
    async (category: Label) => await upsertCustomCategory(category)
);

export const addCategoryToStore = action((category: Label) => {
    categoriesDisplayStore.customCategories.push(category);
});
