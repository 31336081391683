import React from "react";
import { RectangleLabel } from "./components";

import { text_label, label_wrapper } from "./label.module.css";

export type labelProps = {
    color: string;
    text: string;
};

export const Label = (props: labelProps) => {
    const { color, text } = props;
    return (
        <div className={label_wrapper}>
            <RectangleLabel color={color} />
            <div className={text_label}>{text}</div>
        </div>
    );
};
