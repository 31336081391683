import React from "react";

import { Label, ShowOnlyActiveSurveys } from "./components";

import {
    labels_wrapper,
    survey_list_options_wrapper,
} from "./survey-list-options.module.css";

const PINK_COLOR: string = "#F4BFC0";
const GREEN_COLOR: string = "#009788";

export type SurveyListOptionsProps = {};

export const SurveyListOptions = (props: SurveyListOptionsProps) => {
    return (
        <div className={survey_list_options_wrapper}>
            <ShowOnlyActiveSurveys />

            <div className={labels_wrapper}>
                <Label color={PINK_COLOR} text="Not responded" />
                <Label color={GREEN_COLOR} text="Responded" />
            </div>
        </div>
    );
};
