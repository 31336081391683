import React from "react";
import clsx from "clsx";
import {
    Anonimity,
    Question,
    QuestionType,
    RatingQuestion,
    SupportedQuestionResultsMapAfterDigestion,
} from "@difftone/types";

import { getMostPopular, setOpenedQuestionUUID } from "@difftone/actions";
import { ShowDifftoneTooltip } from "@difftone/shared-components";
import {
    COLUMN_0,
    DATA_ANONYMIZED,
    NO_ANSWER_KEY,
} from "@difftone/common-constants";

import {
    values_plain,
    plain_title,
    view_answers,
    open_view_answers,
    range_and_popular,
} from "./values-plain.module.css";

const MAX_CHAR_LENGTH_TITLE = 10;

export type ValuesPlainProps = {
    question: Question<QuestionType>;
    resultPopularity: SupportedQuestionResultsMapAfterDigestion;
    collapsed: boolean;
};

export const ValuesPlain = (props: ValuesPlainProps) => {
    const { question, resultPopularity, collapsed } = props;

    const resultPopularityWithoutNoResponse = JSON.parse(
        JSON.stringify(resultPopularity)
    );

    delete resultPopularityWithoutNoResponse[COLUMN_0][NO_ANSWER_KEY];

    const isAnonymous =
        resultPopularityWithoutNoResponse[COLUMN_0] === DATA_ANONYMIZED;

    let plainTitle = "";
    let plainValue = "";

    switch (question.question_type_name) {
        case "RATING":
            plainTitle = "Avg";
            const firstOption = (
                question as Question<RatingQuestion>
            ).content.rating_options.at(0);

            const lastOption = (
                question as Question<RatingQuestion>
            ).content.rating_options.at(-1);

            if (!firstOption || !lastOption) {
                throw new Error("No options to calculate range");
            }

            plainValue = `${firstOption.value}-${lastOption.value}`;
            break;
        case "SINGLE_CHOICE":
        case "MULTIPLE_CHOICE":
            plainTitle = "Popular";
            const popularAnswer = isAnonymous
                ? "No data"
                : Object.keys(
                      getMostPopular(
                          resultPopularityWithoutNoResponse[COLUMN_0]
                      )
                  )[0];

            plainValue = popularAnswer;
            break;
        case "SHORT_TEXT":
        case "LONG_TEXT":
        case "TIME":
        case "DATE":
            plainTitle = "View Answers";
            break;
        default:
            plainTitle = "DEF";
            plainValue = "def";
            break;
    }

    const slicedPopularAnswer = (popularAnswer: string) => {
        return popularAnswer.slice(0, MAX_CHAR_LENGTH_TITLE).concat("...");
    };

    const selectTextQuestionUuid = () => {
        setOpenedQuestionUUID(question.uuid);
    };

    let popularAnswerTitle = null;
    if (plainValue.length > MAX_CHAR_LENGTH_TITLE) {
        popularAnswerTitle = (
            <ShowDifftoneTooltip tip={plainValue} tooltipPosition="left">
                <div className={range_and_popular}>{`(${slicedPopularAnswer(
                    plainValue
                )})`}</div>
            </ShowDifftoneTooltip>
        );
    } else {
        popularAnswerTitle = (
            <div className={range_and_popular}>{`[${plainValue}]`}</div>
        );
    }

    switch (question.question_type_name) {
        case "RATING":
        case "SINGLE_CHOICE":
        case "MULTIPLE_CHOICE":
            return (
                <div className={values_plain}>
                    <div className={plain_title}>{plainTitle}</div>
                    {popularAnswerTitle}
                </div>
            );

        case "SHORT_TEXT":
        case "LONG_TEXT":
        case "DATE":
        case "TIME":
            return (
                <div className={values_plain}>
                    <div
                        className={clsx(view_answers, {
                            [open_view_answers]: collapsed && !isAnonymous,
                        })}
                        onClick={
                            isAnonymous ? () => {} : selectTextQuestionUuid
                        }
                    >
                        {isAnonymous ? "No available answers" : plainTitle}
                    </div>
                </div>
            );
        default:
            return (
                <div className={values_plain}>
                    <div className={plain_title}>{plainTitle}</div>
                    {popularAnswerTitle}
                </div>
            );
    }
};
