import React from "react";
import { observer } from "mobx-react";

import {
    OpenQuestionAnswers,
    UUID,
    ResultsMapAfterDigestion,
    ResultsMap,
} from "@difftone/types";
import {
    resultsDisplayStore,
    categoriesDisplayStore,
    surveyResponsesSummaryStore,
    sharedResultsDisplayStore,
    ResultsTable,
} from "@difftone/stores";
import {
    getCategoryFilterValuesAndActiveStatus,
    getNonFilterQuestionsFromSurvey,
    getResultsMapsBySharedResultsUuidAndCompare,
    selectedFiltersActiveAsCompare,
    selectedFiltersNotActiveAsCompare,
    sortQuestionsByType,
} from "@difftone/reducers";
import {
    showDifftoneAlert,
    setLocation,
    addQuestionToResultsTable,
    setOpenedQuestionUUID,
} from "@difftone/actions";
import { constantAlertVariants, PAGE_URLS } from "@difftone/constants";
import {
    formatCategoriesQuestionsMap,
    formatCategoriesResultsRatingMap,
    getSortedCategoriesList,
} from "@difftone/reducers";
import { warningMessages } from "@difftone/constants";
import { COLUMN_0 } from "@difftone/common-constants";
import {
    anonymityLimitation,
    surveyResponseAnonymity,
} from "@difftone/anonymity-util";
import {
    OpenQuestionAnswersDialog,
    Spinner,
    ResultsQuestion,
    ResultsCategory,
    ResultsAnswer,
    ResultsTableHeader,
} from "@difftone/shared-components";

import {
    results_spreadsheet,
    results_questions,
    results_table_wrapper,
    results_row_spinner,
    results_questions_and_answers,
    results_questions_answer_stub,
} from "./results-spreadsheet.module.css";
import { createResultsTableFromResultsMaps } from "@difftone/frontend-common";

export type ResultsSpreadsheetProps = {};

export const ResultsSpreadsheet = observer((props: ResultsSpreadsheetProps) => {
    const {
        resultsTableQuestions,
        openedSelectedTextQuestion,
        categoriesQuestionsDisplayModeToggle,
    } = resultsDisplayStore;

    const {
        selectedSharedResults,
        selectedSurvey,
        getFiltersActiveAsCompares,
        getFiltersNotActiveAsCompares,
    } = sharedResultsDisplayStore;

    const _selectedSurvey = selectedSurvey!;

    const surveyResponses =
        surveyResponsesSummaryStore.getSurveyResponseSummaryByFilterPlain(
            _selectedSurvey.uuid,
            []
        );

    if (surveyResponses === undefined) {
        return <Spinner size="LARGE" />;
    }

    if (surveyResponses === null) {
        setLocation(PAGE_URLS.INBOX_ALL);
        showDifftoneAlert(warningMessages.surveyResponseNotFound, "FAILURE");
        return null;
    }

    const filterAsActive = selectedFiltersActiveAsCompare(
        getFiltersActiveAsCompares()
    );
    const filterNotActive = selectedFiltersNotActiveAsCompare(
        getFiltersNotActiveAsCompares()
    );

    const resultsMaps = getResultsMapsBySharedResultsUuidAndCompare(
        selectedSharedResults!.uuid,
        filterAsActive,
        filterNotActive
    );

    const _resultsMaps = resultsMaps as (null | undefined | ResultsMap)[];

    if (_resultsMaps.includes(undefined)) {
        return (
            <div className={results_row_spinner}>
                <Spinner size="LARGE" />
            </div>
        );
    }

    if (_resultsMaps.includes(null)) {
        setLocation(PAGE_URLS.INBOX_ALL);
        showDifftoneAlert(
            constantAlertVariants.survey_results_failure.text,
            constantAlertVariants.survey_results_failure.variant
        );
        return null;
    }

    const safeResultsMap = _resultsMaps as ResultsMap[];

    const resultsTable = createResultsTableFromResultsMaps(safeResultsMap);

    const closeOpenQuestionAnswersDialog = () => {
        setOpenedQuestionUUID(null);
    };

    const sortedNonFilterQuestions = sortQuestionsByType(
        getNonFilterQuestionsFromSurvey(_selectedSurvey)
    );

    if (sortedNonFilterQuestions) {
        sortedNonFilterQuestions.forEach((question) => {
            if (!Object.keys(resultsTableQuestions).includes(question.uuid)) {
                addQuestionToResultsTable({
                    isOpen: false,
                    questionUUID: question.uuid,
                });
            }
        });
    }

    const categoriesQuestionsMap = formatCategoriesQuestionsMap(
        _selectedSurvey.questions || []
    );

    const categoriesList: UUID[] = getSortedCategoriesList(
        categoriesQuestionsMap
    );

    if (!categoriesDisplayStore.isStoreInitialized) {
        categoriesDisplayStore.initCategoriesDisplayMap(categoriesList);
        return <Spinner size="LARGE" />;
    }

    const _resultsTable = resultsTable as ResultsTable;

    const categoriesResultsMap = formatCategoriesResultsRatingMap(
        categoriesQuestionsMap,
        _resultsTable
    );

    const { categoryFilterValues, isCategoryFilterActive } =
        getCategoryFilterValuesAndActiveStatus();

    const isQuestionsTogglerView =
        categoriesQuestionsDisplayModeToggle === "QUESTIONS";

    return (
        <div className={results_spreadsheet}>
            <div className={results_table_wrapper}>
                <ResultsTableHeader
                    filtersActiveAsCompares={selectedFiltersActiveAsCompare(
                        getFiltersActiveAsCompares()
                    )}
                />
                <div className={results_questions_and_answers}>
                    <div className={results_questions}>
                        {isQuestionsTogglerView &&
                            sortedNonFilterQuestions.map((question, index) => {
                                return (
                                    <ResultsQuestion
                                        questionIndex={index}
                                        key={question.uuid}
                                        question={question}
                                        digestedResultsRow={
                                            _resultsTable[question.uuid]
                                        }
                                        survey={_selectedSurvey}
                                    />
                                );
                            })}

                        {!isQuestionsTogglerView &&
                            categoriesList.map((categoryUUID) => {
                                const { categoryName, questions } =
                                    categoriesQuestionsMap[categoryUUID];

                                if (
                                    !categoryFilterValues.includes(
                                        categoryName
                                    ) &&
                                    isCategoryFilterActive
                                ) {
                                    return null;
                                }

                                return (
                                    <ResultsCategory
                                        key={categoryUUID}
                                        categoryUUID={categoryUUID}
                                        categoryName={categoryName}
                                        questions={sortQuestionsByType(
                                            questions
                                        )}
                                        categoriesResultsRatingMap={
                                            categoriesResultsMap
                                        }
                                        questionsResults={_resultsTable}
                                        survey={_selectedSurvey}
                                    />
                                );
                            })}
                    </div>
                    <div>
                        {isQuestionsTogglerView &&
                            sortedNonFilterQuestions.map((question) => {
                                return (
                                    <div key={question.uuid}>
                                        <ResultsAnswer
                                            key={question.uuid}
                                            question={question}
                                            digestedResultsRow={
                                                _resultsTable[question.uuid]
                                            }
                                            survey={_selectedSurvey}
                                        />
                                        {openedSelectedTextQuestion ===
                                        question.uuid ? (
                                            <OpenQuestionAnswersDialog
                                                survey={_selectedSurvey}
                                                question={question}
                                                onClose={
                                                    closeOpenQuestionAnswersDialog
                                                }
                                                openQuestionAnswers={
                                                    _resultsTable[
                                                        question.uuid
                                                    ][
                                                        COLUMN_0
                                                    ] as OpenQuestionAnswers
                                                }
                                            />
                                        ) : null}
                                    </div>
                                );
                            })}

                        {!isQuestionsTogglerView &&
                            categoriesList.map((categoryUUID) => {
                                const { questions, categoryName } =
                                    categoriesQuestionsMap[categoryUUID];
                                const categoryToggled =
                                    categoriesDisplayStore.getCategoryDisplayByUUID(
                                        categoryUUID
                                    );

                                const sortedQuestions =
                                    sortQuestionsByType(questions);

                                if (
                                    !categoryFilterValues.includes(
                                        categoryName
                                    ) &&
                                    isCategoryFilterActive
                                ) {
                                    return null;
                                }

                                const ratingQuestions = questions.filter(
                                    (question) =>
                                        question.question_type_name === "RATING"
                                );

                                return (
                                    <div key={categoryUUID}>
                                        <ResultsAnswer
                                            key={categoryUUID}
                                            question={questions[0]}
                                            digestedResultsRow={
                                                categoriesResultsMap[
                                                    categoryUUID
                                                ] as unknown as ResultsMapAfterDigestion
                                            }
                                            questionResultsRow={
                                                _resultsTable[
                                                    ratingQuestions[0]?.uuid
                                                ]
                                            }
                                            isCategoryAverageRow
                                            survey={_selectedSurvey}
                                        />
                                        {sortQuestionsByType(questions).map(
                                            (question) => {
                                                if (!categoryToggled) {
                                                    return null;
                                                }

                                                const isLastQuestionInList =
                                                    sortedQuestions[
                                                        sortedQuestions.length -
                                                            1
                                                    ] === question;

                                                return (
                                                    <div key={question.uuid}>
                                                        {!ratingQuestions.length &&
                                                        categoryToggled ? (
                                                            <div
                                                                className={
                                                                    results_questions_answer_stub
                                                                }
                                                            />
                                                        ) : null}

                                                        <ResultsAnswer
                                                            key={question.uuid}
                                                            question={question}
                                                            digestedResultsRow={
                                                                _resultsTable[
                                                                    question
                                                                        .uuid
                                                                ]
                                                            }
                                                            isQuestionInsideCategory
                                                            survey={
                                                                _selectedSurvey
                                                            }
                                                            isLastQuestionInList={
                                                                isLastQuestionInList
                                                            }
                                                        />
                                                        {openedSelectedTextQuestion ===
                                                        question.uuid ? (
                                                            <OpenQuestionAnswersDialog
                                                                survey={
                                                                    _selectedSurvey
                                                                }
                                                                question={
                                                                    question
                                                                }
                                                                onClose={
                                                                    closeOpenQuestionAnswersDialog
                                                                }
                                                                openQuestionAnswers={
                                                                    _resultsTable[
                                                                        question
                                                                            .uuid
                                                                    ][
                                                                        COLUMN_0
                                                                    ] as OpenQuestionAnswers
                                                                }
                                                            />
                                                        ) : null}
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
});
