const EXTRA_DISTANCE_FROM_BUTTON_WINDOW_POSITION = 10;
const EXTRA_DISTANCE_FROM_BUTTON_WRAPPER = 40;

export const isFullyVisible = (el: HTMLDivElement, parentEl: HTMLElement) => {
    const elRect = el.getBoundingClientRect();
    const elemTop = elRect.top;
    const elemBottom = elRect.bottom;

    const parentRect = parentEl.getBoundingClientRect();
    const parentTop = parentRect.top;
    const parentBottom = parentRect.bottom;

    const isVisible = elemTop >= parentTop && elemBottom <= parentBottom;

    return isVisible;
};

export const setItemVisibleInView = (
    parentWrapper: HTMLDivElement | null,
    childElement: HTMLDivElement | null
) => {
    if (childElement && parentWrapper) {
        if (!isFullyVisible(childElement, parentWrapper)) {
            const childRect = childElement.getBoundingClientRect();
            const parentRect = parentWrapper.getBoundingClientRect();

            if (childRect.top <= parentRect.top) {
                parentWrapper.scrollBy({
                    top: childRect.top - parentRect.top,
                    behavior: "smooth",
                });
            }

            if (childRect.bottom >= parentRect.bottom) {
                parentWrapper.scrollBy({
                    top:
                        childRect.bottom -
                        parentRect.bottom +
                        EXTRA_DISTANCE_FROM_BUTTON_WRAPPER,
                    behavior: "smooth",
                });
            }
        }
    }
};

export const handleIfDropdownFitsViewPort = (
    parentWrapper: HTMLDivElement | null,
    childElement: HTMLDivElement | null
) => {
    if (childElement && parentWrapper) {
        if (!isFullyVisible(childElement, parentWrapper)) {
            const calculateHeightOutOfWindow =
                childElement.getBoundingClientRect().bottom -
                window.innerHeight;

            parentWrapper.scrollBy({
                top:
                    calculateHeightOutOfWindow +
                    EXTRA_DISTANCE_FROM_BUTTON_WINDOW_POSITION,
                behavior: "smooth",
            });
        }
    }
};
