import { action } from "mobx";
import { connectionListStore } from "./connection-list-store";
import { IConnectionListService } from "@difftone/services";
import { ConnectionListService } from "@difftone/services/connection-list-service/connection-list-service";
import {
    handleGoogleContactsPermissions,
    localstorageUtils,
} from "@difftone/procedures";

export const setConnectionListToMap = action(
    (emailAsKey: string, newEmailConnection: string) => {
        const copyConnectionList: { [x: string]: string | undefined } =
            JSON.parse(JSON.stringify(connectionListStore.connectionListMap));

        copyConnectionList[emailAsKey] = newEmailConnection;
        connectionListStore.connectionListMap = copyConnectionList;
    }
);

export const fetchConnectionsList = async () => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();
    if (currentAuthData.issuer === "GOOGLE") {
        await handleGoogleContactsPermissions();
    }

    const { addConnectionToList } = connectionListStore;
    try {
        const connectionListService: IConnectionListService =
            new ConnectionListService();
        const connections =
            await connectionListService.getUserConnectionsEmailsList();
        connections.forEach((connection) => addConnectionToList(connection));

        connectionListStore.storeInitialize = true;
    } catch (error) {
        connectionListStore.storeInitialize = true;
        throw Error("Could not initialize connections list from google.");
    }
};
