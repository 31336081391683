import React from "react";
import { observer } from "mobx-react";

import { showDifftoneAlert } from "@difftone/actions";
import { timeRangesStore } from "@difftone/stores";
import {
    ResultsFilter,
    SurveyClasses,
    TimeCompareUnits,
    TimeRangesContainer,
} from "@difftone/types";
import { getSurveyFromStoreByURL } from "@difftone/reducers";
import { Spinner } from "@difftone/shared-components";

import { CompareButton } from "./components";

import {
    spinner_style,
    spinner_container,
} from "./time-compare-button.module.css";

export type TimeCompareButtonProps = {
    timeCompareFilter: ResultsFilter;
    closePopupHandler: () => void;
    survey?: SurveyClasses;
    selectedPopupDisplayResultsTimeCompare: TimeCompareUnits;
    setSelectedTimeCompareResultsFilterAndTimeUnits: (
        selectedFilter: TimeCompareUnits,
        timeCompareFilterUuid: string,
        timeRangesContainer: TimeRangesContainer | null
    ) => void;
};

export const TimeCompareButton = observer((props: TimeCompareButtonProps) => {
    const { extractKeyForTimeRange, getTimeRangeByKey } = timeRangesStore;
    const {
        timeCompareFilter,
        closePopupHandler,
        survey,
        selectedPopupDisplayResultsTimeCompare,
        setSelectedTimeCompareResultsFilterAndTimeUnits,
    } = props;

    const _survey = survey || getSurveyFromStoreByURL();

    const timeRangeKey = extractKeyForTimeRange(
        _survey.uuid,
        selectedPopupDisplayResultsTimeCompare
    );

    const timeRanges = getTimeRangeByKey(timeRangeKey);

    if (timeRanges === undefined) {
        return (
            <div className={spinner_container}>
                <Spinner className={spinner_style} size="X-SMALL" />
            </div>
        );
    }

    if (timeRanges === null) {
        showDifftoneAlert(
            "Something went wrong, please reload and try again",
            "FAILURE"
        );
        return null;
    }

    const onSelectCompareHandler = () => {
        setSelectedTimeCompareResultsFilterAndTimeUnits(
            selectedPopupDisplayResultsTimeCompare,
            timeCompareFilter.filterKey.uuid,
            selectedPopupDisplayResultsTimeCompare === "NONE"
                ? null
                : timeRanges!
        );
        closePopupHandler();
    };

    return <CompareButton onSelectCompareHandler={onSelectCompareHandler} />;
});
