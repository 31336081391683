import React, { ReactNode } from "react";

import { ShowDifftoneTooltip } from "@difftone/shared-components";

import {
    results_card_header,
    children_class,
    survey_name,
} from "./results-card-header.module.css";

export type ResultsCardHeaderProps = {
    children?: ReactNode;
    surveyName: string;
};

export const ResultsCardHeader = (props: ResultsCardHeaderProps) => {
    const { surveyName, children } = props;
    return (
        <div className={results_card_header}>
            <ShowDifftoneTooltip tooltipPosition="top" tip={surveyName}>
                <div className={survey_name}>{surveyName}</div>
            </ShowDifftoneTooltip>
            <div className={children_class}>{children}</div>
        </div>
    );
};
