import React from "react";
import clsx from "clsx";
import { UiStatus } from "@difftone/frontend-common";
import { getStatus } from "@difftone/reducers";
import ongoingEtaIcon from "@difftone/assets/ongoing-eta-icon.svg";
import { BaseSurvey } from "@difftone/types";

import {
    ongoing_eta,
    value_display_box,
    completed_or_ended_time_color_display,
} from "./ongoing-eta.module.css";

export type OngoingEtaProps = {
    survey: BaseSurvey;
};

export const OngoingEta = (props: OngoingEtaProps) => {
    const { survey } = props;

    const surveyStatus: UiStatus = getStatus(survey);

    const eta_radius = 13;

    switch (surveyStatus) {
        case "ENDED":
            return (
                <div className={ongoing_eta}>
                    <svg width="30" height="30" viewBox="0 0 30 30">
                        <circle
                            cx={eta_radius + 1}
                            cy={eta_radius + 1}
                            r={eta_radius}
                        />
                    </svg>
                    <div
                        className={clsx(
                            value_display_box,
                            completed_or_ended_time_color_display
                        )}
                    >
                        00:00
                    </div>
                </div>
            );
        case "ACTIVE":
            return (
                <div className={ongoing_eta}>
                    <img src={ongoingEtaIcon} alt="ongoing-icon" />
                    <span>Ongoing</span>
                </div>
            );
        default:
            return (
                <div className={ongoing_eta}>
                    <span>Ongoing</span>
                </div>
            );
    }
};
