import React from "react";

import { menu_item_text } from "./generator-menu-item.module.css";

export type GeneratorMenuItemProps = {
    value: string;
};

export const GeneratorMenuItem = (props: GeneratorMenuItemProps) => {
    const { value } = props;
    return <div className={menu_item_text}>{value}</div>;
};
