import { action } from "mobx";

import { formatCategoriesQuestionsMap } from "@difftone/reducers";
import { BaseSurvey, ResultsFilter, UUID } from "@difftone/types";
import { CATEGORY_FILTER_ID, constantAlertVariants } from "@difftone/constants";
import { categoriesDisplayStore, categoriesStore } from "@difftone/stores";
import { showDifftoneAlert } from "@difftone/actions";

export const setCategoriesDisplayMapSetter = action(
    (categoryUUID: UUID, categoryDisplay: boolean) => {
        const copyCategoriesDisplayMap: Record<UUID, boolean> = JSON.parse(
            JSON.stringify(categoriesDisplayStore.categoriesDisplayMap)
        );

        copyCategoriesDisplayMap[categoryUUID] = categoryDisplay;

        categoriesDisplayStore.categoriesDisplayMap = copyCategoriesDisplayMap;
    }
);

export const setCategoryFilterBy = action((survey: BaseSurvey) => {
    const categoriesMap = formatCategoriesQuestionsMap(survey.questions);
    const categoriesNames = Object.keys(categoriesMap).map(
        (categoryUUID) => categoriesMap[categoryUUID].categoryName
    );

    const categoryFilter: ResultsFilter = {
        filterKey: {
            question_type_name: "SINGLE_CHOICE",
            content: {
                text: "",
                choice_options: categoriesNames,
            },
            nickname: "",
            number: 1,
            required: true,
            title: "Category",
            uuid: CATEGORY_FILTER_ID,
            version: "0.0.2",
            categories: [],
            allow_comments: false,
            is_filter: true,
        },
        filterValues: categoriesNames,
        indexInComparePlain: 0,
        isPartOfComparedPlain: false,
    };

    categoriesDisplayStore.categoryFilterBy = categoryFilter;
});

export const setCategoryInputName = action((name: string) => {
    categoriesDisplayStore.categoryNameInput = name;
});

export const clearCategoriesToDelete = action(() => {
    categoriesDisplayStore.categoriesToDelete = [];
});

export const addCategoryUUIDToDeleteList = action((categoryUUID: UUID) => {
    categoriesDisplayStore.categoriesToDelete = [
        ...categoriesDisplayStore.categoriesToDelete,
        categoryUUID,
    ];
});

export const removeCustomCategoryByUUID = action((uuid: UUID) => {
    const tempCustomCategories = [...categoriesDisplayStore.customCategories];
    const categoryToDeleteIndex = tempCustomCategories.findIndex(
        (category) => category.uuid === uuid
    );

    tempCustomCategories.splice(categoryToDeleteIndex, 1);

    categoriesDisplayStore.customCategories = tempCustomCategories;
});

export const finalizeChangesMadeAtCategoriesDialog = action(() => {
    const categoriesToCreate = [...categoriesDisplayStore.customCategories];
    const categoriesToDeleteUUIDs = [
        ...categoriesDisplayStore.categoriesToDelete,
    ];
    const allCategories = categoriesStore.getCategoriesArray();

    try {
        categoriesToDeleteUUIDs.forEach((categoryUUID) => {
            const targetCategory = allCategories.find(
                (category) => category.uuid === categoryUUID
            );

            if (targetCategory) {
                targetCategory.is_deleted = true;

                categoriesStore.addCategoryToAPIWithoutDebounce(targetCategory);
            }
        });

        categoriesToCreate.forEach((category) => {
            categoriesStore.addCategoryToAPIWithoutDebounce(category);
        });

        showDifftoneAlert(
            constantAlertVariants.categories_success.text,
            constantAlertVariants.categories_success.variant
        );
    } catch (e) {
        console.error(e);
    }
});
