import React, {
    ChangeEvent,
    KeyboardEventHandler,
    MouseEvent,
    useEffect,
    useState,
} from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import { DateAndTime, SurveyEvent, SimpleSurvey } from "@difftone/types";
import { KEYBOARD_EVENT_KEYS_MAP } from "@difftone/constants";
import {
    alertsStore,
    autoCompleteStore,
    wizardDisplayStore,
    wizardStore,
} from "@difftone/stores";
import {
    addWizardCopySurveyEvent,
    addWizardSurveyEvent,
    closeAutocompleteDropdown,
    debounceUpsertSurveyToApi,
    deleteToUpdateWizardCopySurveyEvent,
    deleteToUpdateWizardSurveyEventWithoutReset,
    nullifyWizardValidationError,
    openAutocompleteDropdown,
    populateEmptySurveyEvent,
    setSelectedInputEventTitle,
    setSurveyEventToDisplay,
    setVisibilityOfWizardCalendar,
    sortSurveyEventsByEventNumber,
    sortWizardCopySurveyEventsByEventNumber,
    updateSurveyWizardCopy,
    validateWizardSurvey,
} from "@difftone/actions";
import {
    AutoCompleteTitle,
    CustomDateTimePicker,
    ValidationWrapper,
} from "@difftone/shared-components";
import {
    dateStrAndTimeStrToDateObject,
    getDayTimeFromTimeString,
    getLocalDateStrFromUtcDateStr,
    getNormalizeOffsetInMilisecondsFromDateString,
} from "@difftone/time-utils";
import {
    getSurveyFromStoreByURL,
    isDisabledEventCalculation,
    validateEventChange,
} from "@difftone/reducers";
import { warningMessages } from "@difftone/constants";
import { eventValidationResults } from "@difftone/frontend-common/frontend-validations";
import { AddReminderButton, EventAlertBell, ReminderItem } from "./components";
import {
    event_details,
    event_details_title,
    system_date,
    date_picker_container,
    title_as_input,
    selected_title_as_input,
    input_wrapper,
} from "./wizard-event-details.module.css";

const NUMBER_OF_CHARS_ALLOWED_FOR_AUTOCOMPLETE = 1;

export type WizardEventDetailsProps = {
    survey: SimpleSurvey;
    onChangeSurveyEvent?: (surveyEvent: SurveyEvent) => void;
};

export const WizardEventDetails = observer((props: WizardEventDetailsProps) => {
    const { selectedEventUUID, selectedInputEventTitle, isEditableSurvey } =
        wizardDisplayStore;
    const { autoCompleteOptions, isAutocompleteOpen } = autoCompleteStore;
    const { survey, onChangeSurveyEvent } = props;

    const defaultOnChangeSurveyEvent = (surveyEvent: SurveyEvent) => {
        addWizardSurveyEvent(surveyEvent);
        debounceUpsertSurveyToApi();
    };

    const _onChangeSurveyEvent =
        onChangeSurveyEvent || defaultOnChangeSurveyEvent;

    // useState: state for controlling the active index inside the autocomplete dropdown list
    // controlling up/down keystrokes and mouse hover events
    const [autoCompleteListIndex, setAutoCompleteListIndex] = useState(0);

    const _survey = survey || getSurveyFromStoreByURL();

    const surveyEvent = _survey.survey_events.find(
        (surveyEvent) => surveyEvent.uuid === selectedEventUUID
    )!;

    if (!surveyEvent) {
        setSurveyEventToDisplay(null);
    }

    if (surveyEvent.date_utc === null || surveyEvent.day_utc_time === null) {
        populateEmptySurveyEvent(surveyEvent);
    }

    const { isDateValid, isTitleValid } = eventValidationResults(
        surveyEvent.uuid,
        _survey
    );

    const onInputClickHandler = () => {
        openAutocompleteDropdown();
        setSelectedInputEventTitle(surveyEvent.uuid);
    };

    const onBlurTitleHandler = () => {
        closeAutocompleteDropdown();
        setSelectedInputEventTitle(null);
    };

    const onKeyDownHandler: KeyboardEventHandler<HTMLInputElement> = (
        event
    ) => {
        const focusedTitleInAutoComplete = autoCompleteOptions.filter(
            (autoCompleteTitle) =>
                autoCompleteTitle
                    .toLowerCase()
                    .includes(surveyEvent.title.toLowerCase())
        )[autoCompleteListIndex];

        event.stopPropagation();

        if (surveyEvent.title) {
            if (event.key === KEYBOARD_EVENT_KEYS_MAP.ENTER) {
                if (isAutocompleteOpen) {
                    event.preventDefault();
                }
                const copyOfEvent = JSON.parse(JSON.stringify(surveyEvent));
                isEditableSurvey
                    ? deleteToUpdateWizardSurveyEventWithoutReset(surveyEvent)
                    : deleteToUpdateWizardCopySurveyEvent(surveyEvent);
                copyOfEvent.title = focusedTitleInAutoComplete
                    ? focusedTitleInAutoComplete
                    : surveyEvent.title;
                copyOfEvent.event_number = surveyEvent.event_number;
                _onChangeSurveyEvent(copyOfEvent);
                closeAutocompleteDropdown();
            }
        }

        if (surveyEvent.title) {
            if (event.key === KEYBOARD_EVENT_KEYS_MAP.ARROW_DOWN) {
                setAutoCompleteListIndex(
                    (autoCompleteListIndex + 1) %
                        autoCompleteOptions.filter((autoCompleteTitle) =>
                            autoCompleteTitle
                                .toLowerCase()
                                .includes(surveyEvent.title.toLowerCase())
                        ).length
                );
            }
            if (event.key === KEYBOARD_EVENT_KEYS_MAP.ARROW_UP) {
                if (autoCompleteListIndex - 1 < 0) {
                    setAutoCompleteListIndex(
                        autoCompleteOptions.filter((autoCompleteTitle) =>
                            autoCompleteTitle
                                .toLowerCase()
                                .includes(surveyEvent.title.toLowerCase())
                        ).length - 1
                    );
                } else {
                    setAutoCompleteListIndex(
                        (autoCompleteListIndex - 1) %
                            autoCompleteOptions.filter((autoCompleteTitle) =>
                                autoCompleteTitle
                                    .toLowerCase()
                                    .includes(surveyEvent.title.toLowerCase())
                            ).length
                    );
                }
            }
            return;
        }
    };

    const onEventTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        nullifyWizardValidationError();
        const surveyEventCopy = JSON.parse(JSON.stringify(surveyEvent));
        if (
            event.target.value.length >=
            NUMBER_OF_CHARS_ALLOWED_FOR_AUTOCOMPLETE
        ) {
            openAutocompleteDropdown();
        }
        deleteToUpdateWizardSurveyEventWithoutReset(surveyEvent);
        surveyEventCopy.title = event.target.value;
        surveyEventCopy.event_number = surveyEvent.event_number;
        _onChangeSurveyEvent(surveyEventCopy);
    };

    const onDateChange = (dateAndTime: DateAndTime) => {
        const surveyEventCopy: SurveyEvent = JSON.parse(
            JSON.stringify(surveyEvent)
        );

        const isValidEvent = validateEventChange(
            surveyEventCopy,
            survey,
            dateAndTime
        );

        if (!isValidEvent) {
            return;
        }

        const { dateUtcStrAfterFormat, dayUtcTimeStr } =
            dateStrAndTimeStrToDateObject(dateAndTime.date, dateAndTime.time);

        surveyEventCopy.date_utc = dateUtcStrAfterFormat;
        surveyEventCopy.day_utc_time = dayUtcTimeStr;

        surveyEventCopy.utc_offset =
            getNormalizeOffsetInMilisecondsFromDateString(
                dateUtcStrAfterFormat
            );

        surveyEventCopy.event_number = surveyEvent.event_number;

        if (!isEditableSurvey) {
            deleteToUpdateWizardCopySurveyEvent(surveyEventCopy);
            addWizardCopySurveyEvent(surveyEventCopy);
            sortWizardCopySurveyEventsByEventNumber();
            updateSurveyWizardCopy(); //onSaveSurveyClick -> expand to also update survey_events
        } else {
            //TODO: aggregate into a single action
            //https://app.asana.com/0/1201346022025578/1202814583243819/f
            deleteToUpdateWizardSurveyEventWithoutReset(surveyEvent);
            addWizardSurveyEvent(surveyEventCopy);
            sortSurveyEventsByEventNumber();
            debounceUpsertSurveyToApi();
        }
    };

    const openEventsCalendar = () => {
        nullifyWizardValidationError();
        setVisibilityOfWizardCalendar(surveyEvent.uuid);
    };

    const closeEventsCalendar = () => {
        setVisibilityOfWizardCalendar(null);

        if (surveyEvent.system_survey_event_type === "LAST_DATE_TO_FILL") {
            validateWizardSurvey();
        }
    };

    const onAutocompleteTitleHovered = (event: MouseEvent<HTMLDivElement>) => {
        const hoveredTitleIndex = event.currentTarget.getAttribute(
            "data-autocomplete-index"
        );

        if (!hoveredTitleIndex) {
            return;
        }
        setAutoCompleteListIndex(parseInt(hoveredTitleIndex));
    };

    const onAutocompleteTitleClicked = (event: MouseEvent<HTMLDivElement>) => {
        const clickedTitle = event.currentTarget.getAttribute(
            "data-autocomplete-title"
        );
        if (!clickedTitle) {
            return;
        }

        deleteToUpdateWizardSurveyEventWithoutReset(surveyEvent);
        const copyOfEvent: SurveyEvent = JSON.parse(
            JSON.stringify(surveyEvent)
        );
        copyOfEvent.title = clickedTitle ? clickedTitle : surveyEvent.title;
        copyOfEvent.event_number = surveyEvent.event_number;
        _onChangeSurveyEvent(copyOfEvent);
        closeAutocompleteDropdown();
    };

    useEffect(() => {
        if (
            surveyEvent.title.length >= NUMBER_OF_CHARS_ALLOWED_FOR_AUTOCOMPLETE
        ) {
            autoCompleteStore.getTitleAutoCompleteByRepositoryType(
                surveyEvent.title,
                "dates"
            );
        }
    }, [surveyEvent.title]);

    const isFocused = selectedEventUUID === surveyEvent.uuid;

    const eventTitleFocused = selectedInputEventTitle === surveyEvent.uuid;

    const isDisabledEvent = !isEditableSurvey
        ? isDisabledEventCalculation(survey, surveyEvent)
        : false;

    return (
        <div className={event_details}>
            <span className={event_details_title}>Edit Event</span>
            {surveyEvent?.system_survey_event_type ? (
                <input
                    disabled={true}
                    onClick={onInputClickHandler}
                    className={system_date}
                    type="text"
                    onChange={onEventTitleChange}
                    value={surveyEvent.title}
                />
            ) : (
                <div
                    className={clsx(
                        input_wrapper,
                        eventTitleFocused && selected_title_as_input
                    )}
                >
                    <ValidationWrapper
                        isValid={!(isFocused && !isTitleValid)}
                        errorMessage={warningMessages.surveyEventTitleError}
                    >
                        <input
                            data-input-event-uuid={surveyEvent.uuid}
                            autoFocus={isAutocompleteOpen}
                            onClick={onInputClickHandler}
                            onKeyDown={onKeyDownHandler}
                            onBlur={onBlurTitleHandler}
                            placeholder="New Event"
                            className={title_as_input}
                            type="text"
                            onChange={onEventTitleChange}
                            value={surveyEvent.title}
                        />
                    </ValidationWrapper>
                    <AutoCompleteTitle
                        isOpen={
                            isFocused &&
                            surveyEvent.title.length >=
                                NUMBER_OF_CHARS_ALLOWED_FOR_AUTOCOMPLETE &&
                            isAutocompleteOpen
                        }
                        onMouseOver={onAutocompleteTitleHovered}
                        onMouseClick={onAutocompleteTitleClicked}
                        autoCompleteList={autoCompleteOptions.filter((title) =>
                            title
                                .toLowerCase()
                                .includes(surveyEvent.title.toLowerCase())
                        )}
                        activeIndex={autoCompleteListIndex}
                    />
                </div>
            )}
            <div className={date_picker_container}>
                <CustomDateTimePicker
                    disabled={isDisabledEvent}
                    isSystemEvent={surveyEvent.system_survey_event_type}
                    isValid={isDateValid}
                    //TODO: Add null handling for function and function return
                    localDateStr={getLocalDateStrFromUtcDateStr(
                        surveyEvent.date_utc!,
                        surveyEvent.day_utc_time!
                    )}
                    //TODO: Add null handling for function and function return
                    dayUtcTime={getDayTimeFromTimeString(
                        surveyEvent.day_utc_time!
                    )}
                    onChange={onDateChange}
                    isOpen={
                        wizardStore.wizardFocusedCalendar === surveyEvent.uuid
                    }
                    onOpen={openEventsCalendar}
                    onClose={closeEventsCalendar}
                />
                <EventAlertBell surveyEvent={surveyEvent} />
            </div>
            <div>
                {surveyEvent.event_alerts.reduce(
                    (acc: JSX.Element[], alertUUID) => {
                        const selectedAlert = isEditableSurvey
                            ? alertsStore.getSurveyAlertCopyByUuid(alertUUID)
                            : wizardDisplayStore.getSurveyCopyAlertCopyByUuid(
                                  alertUUID
                              );

                        if (!selectedAlert?.alert_type) {
                            acc.push(
                                <ReminderItem
                                    eventAlert={selectedAlert!}
                                    key={alertUUID}
                                    alertIndex={acc.length}
                                    surveyEvent={surveyEvent}
                                />
                            );
                        }
                        return acc;
                    },
                    []
                )}
            </div>
            <AddReminderButton survey={_survey} surveyEvent={surveyEvent} />
        </div>
    );
});
