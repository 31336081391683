import axios, { AxiosError } from "axios";
import type { EmailAddress, Person } from "@difftone/types";
import { IAuthProviderConnectionListService } from "../contracts";

export class GoogleConnectionListService
    implements IAuthProviderConnectionListService
{
    GOOGLE_API_SERVER_BASE_URL: string = "https://people.googleapis.com/v1";

    public async getUserFullConnectionsEmailsList(): Promise<EmailAddress[]> {
        try {
            const accessToken = gapi.client.getToken().access_token;
            const othersContactsList = await this.getOthersContactsList(
                accessToken
            );
            const connectionListContacts = await this.getPeopleConnectionsList(
                accessToken
            );
            const onlyUniqueConnections = new Set([
                ...othersContactsList,
                ...connectionListContacts,
            ]);
            return Array.from(onlyUniqueConnections);
        } catch (error) {
            throw new Error(
                `ConnectionListService.getUserConnectionsEmailsList() error:: ${
                    (error as Error).message
                }`
            );
        }
    }

    private async getOthersContactsList(
        accessToken: string
    ): Promise<EmailAddress[]> {
        try {
            axios.defaults.headers.common["Accept"] = "application/json";
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${accessToken}`;

            const response = await axios.get(
                `${this.GOOGLE_API_SERVER_BASE_URL}/otherContacts`,
                {
                    params: {
                        readMask: "emailAddresses",
                    },
                }
            );

            if (response.data.error) {
                throw response.data.error;
            }

            const resource: Person[] = response.data.otherContacts;

            // @ts-ignore
            const otherContacts: EmailAddress[] = resource
                .map(
                    (person) =>
                        person.emailAddresses.find(
                            (email) => email.metadata.primary
                        )?.value
                )
                .filter((email) => email !== undefined);

            return otherContacts;
        } catch (error) {
            console.error(
                `[Request to Google api failed]: ${(error as Error).message}`
            );
            return [];
        }
    }

    private async getPeopleConnectionsList(
        accessToken: string
    ): Promise<EmailAddress[]> {
        try {
            axios.defaults.headers.common["Accept"] = "application/json";
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${accessToken}`;
            //API: https://people.googleapis.com/v1/people/me/connections?personFields=emailAddresses
            const response = await axios.get(
                `${this.GOOGLE_API_SERVER_BASE_URL}/people/me/connections`,
                {
                    params: {
                        personFields: "emailAddresses",
                        pageSize: 1000,
                    },
                }
            );

            if (response.data.error) {
                throw response.data.error;
            }

            const resource: Person[] = response.data.connections;
            if (!resource) {
                return [];
            }
            // @ts-ignore
            const reducedContacts = resource.reduce(
                (acc: string[], person: Person) => {
                    if (person && person.emailAddresses) {
                        const address = person.emailAddresses.find(
                            (email) => email.metadata.primary
                        )?.value;
                        if (address) acc.push(address);
                    }
                    return acc;
                },
                []
            );

            return reducedContacts;
        } catch (error) {
            const typedError = error as Error | AxiosError;
            if (axios.isAxiosError(typedError)) {
                if (typedError.response?.status === 403) {
                    console.info(
                        "It seems that you have not granted permissions to access your Google contacts\nYou can do this in the login popup next time you log in."
                    );
                }
            }

            console.error(
                `[Request to Google api failed]: ${typedError.message}`
            );
            return [];
        }
    }
}
