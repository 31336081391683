import React from "react";
import {
    addEmptyWizardSurveyEvent,
    deleteWizardSurveyEventWithReset,
    duplicateEventInWizard,
    nullifyWizardValidationError,
    debounceUpsertSurveyToApi,
    validateWizardSurvey,
    setFocusedWizardInputCard,
} from "@difftone/actions";
import { wizardStore } from "@difftone/stores";
import { validateSurveyEvents } from "@difftone/validations";
import { BaseSurvey, SimpleSurvey } from "@difftone/types";
import { getSurveyFromStoreByURL } from "@difftone/reducers";
import { focusFirstInvalidEvent } from "@difftone/frontend-common/frontend-validations";

import dateIcon from "@difftone/assets/date-menu-date-icon.svg";
import duplicateQuestionIcon from "@difftone/assets/duplicate-question-floating-menu-icon.svg";
import deleteQuestionIcon from "@difftone/assets/delete-attribute-floating-menu-icon.svg";
import {
    mobile_dates_menu,
    grayed_out_button,
} from "./mobile-dates-menu.module.css";

export type MobileDatesMenuProps = {
    survey?: BaseSurvey;
};

export const MobileDatesMenu = (props: MobileDatesMenuProps) => {
    const { focusedWizardInputCard } = wizardStore;
    const _survey = props.survey || getSurveyFromStoreByURL();

    if (_survey.survey_class !== "SIMPLE") {
        throw Error(
            "[Difftone]::MobileDatesMenu survey does not have survey events"
        );
    }

    const survey = _survey as SimpleSurvey;

    const onAddDateHandler = () => {
        const datesValidations = validateSurveyEvents(survey.survey_events);

        if (datesValidations.some((validation) => !validation.is_valid)) {
            validateWizardSurvey();
            const invalidEvent = datesValidations.find(
                (validation) => !validation.is_valid
            );
            if (!invalidEvent) {
                return;
            }
            focusFirstInvalidEvent(invalidEvent);
            return;
        }

        addEmptyWizardSurveyEvent();
        nullifyWizardValidationError();

        setTimeout(() => {
            const datePickerItems: NodeListOf<HTMLDivElement> =
                document.querySelectorAll("div[data-date-picker-wrapper]");
            setFocusedWizardInputCard(
                datePickerItems[datePickerItems.length - 1]
            );
            debounceUpsertSurveyToApi();
        }, 0);
    };

    const onDuplicateDateHandler = () => {
        const datesValidations = validateSurveyEvents(survey.survey_events);

        if (datesValidations.some((validation) => !validation.is_valid)) {
            validateWizardSurvey();
            const invalidEvent = datesValidations.find(
                (validation) => !validation.is_valid
            );
            if (!invalidEvent) {
                return;
            }
            focusFirstInvalidEvent(invalidEvent);
            return;
        }

        const eventNumber = focusedWizardInputCard?.getAttribute("index");
        //@ts-ignore
        const eventToCopy = survey?.survey_events![eventNumber];
        eventToCopy && duplicateEventInWizard(eventToCopy);

        setTimeout(() => {
            const allExistingQuestionElements: NodeListOf<HTMLDivElement> =
                document.querySelectorAll("div[data-date-picker-wrapper]");
            setFocusedWizardInputCard(
                allExistingQuestionElements[Number(eventNumber) + 1]
            );
            debounceUpsertSurveyToApi();
        }, 0);
    };

    const onDeleteDateHandler = () => {
        const eventNumber = parseInt(
            focusedWizardInputCard?.getAttribute("index")!
        );
        const eventToDelete = survey?.survey_events![eventNumber];
        eventToDelete && deleteWizardSurveyEventWithReset(eventToDelete);
        setTimeout(() => {
            const allExistingQuestionElements: NodeListOf<HTMLDivElement> =
                document.querySelectorAll("div[data-date-picker-wrapper]");
            if (eventNumber > 0) {
                const previousElement =
                    allExistingQuestionElements[eventNumber - 1];
                setFocusedWizardInputCard(previousElement);
            } else {
                setFocusedWizardInputCard(allExistingQuestionElements[0]);
            }
            debounceUpsertSurveyToApi();
        }, 0);
        validateWizardSurvey();
    };

    const isSystemEvent = () => {
        const eventNumber = parseInt(
            focusedWizardInputCard?.getAttribute("index")!
        );
        const currentEvent = survey?.survey_events![eventNumber];
        if (
            currentEvent?.system_survey_event_type === "LAST_DATE_TO_FILL" ||
            currentEvent?.system_survey_event_type === "PUBLISHED_DATE"
        ) {
            return true;
        }

        return false;
    };

    return (
        <div className={mobile_dates_menu}>
            <button onMouseDown={onAddDateHandler}>
                <img src={dateIcon} alt="add-date" />
            </button>
            <button
                className={isSystemEvent() ? grayed_out_button : ""}
                onMouseDown={onDuplicateDateHandler}
            >
                <img src={duplicateQuestionIcon} alt="duplicate-date" />
            </button>
            <button
                className={isSystemEvent() ? grayed_out_button : ""}
                onMouseDown={onDeleteDateHandler}
            >
                <img src={deleteQuestionIcon} alt="delete-date" />
            </button>
        </div>
    );
};
