import React from "react";
import { observer } from "mobx-react";
import { getSurveyFromStoreByURL } from "@difftone/reducers";
import { AccordionItem } from "@difftone/shared-components";

import { wizardStore } from "@difftone/stores";
import { WizardParticipants } from "./components";
import {
    participants_accordion_item,
    split_line,
} from "./participants-accordion-item.module.css";

export const ParticipantsAccordionItem = observer(() => {
    const { activeAccordionSection } = wizardStore;
    const survey = getSurveyFromStoreByURL();
    return (
        <div className={participants_accordion_item}>
            <AccordionItem
                sectionCounter={survey.respondents.length || 0}
                selectedAccordionItem="PARTICIPANTS"
                accordionTitle="Participants"
                isAccordionSectionOpen={
                    activeAccordionSection === "PARTICIPANTS"
                }
                survey={survey}
            >
                <div className={split_line} />
                <WizardParticipants />
            </AccordionItem>
        </div>
    );
});
