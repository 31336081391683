import React from "react";

import { Checkbox, ShowDifftoneTooltip } from "@difftone/shared-components";
import { changeCheckboxOfOngoing } from "@difftone/actions";
import { SurveyClasses } from "@difftone/types";
import { getStatus } from "@difftone/reducers";
import { UiStatus } from "@difftone/frontend-common";
import { ONGOING_SURVEY_TOOLTIP_INFO } from "@difftone/constants";

import blueIIcon from "@difftone/assets/blue-i-icon.svg";

import {
    checkbox_ongoing_wrapper,
    text_checkbox_wrapper,
    text_checkbox,
    check_box,
    info_wrapper,
    icon_wrapper,
} from "./checkbox-ongoing.module.css";

export type CheckboxOngoingProps = {
    survey: SurveyClasses;
};

export const CheckboxOngoing = (props: CheckboxOngoingProps) => {
    const { survey } = props;

    const surveyStatus: UiStatus = getStatus(survey);

    const onChange = () => {
        if (surveyStatus === "DRAFT") {
            changeCheckboxOfOngoing(survey);
        }
    };

    return (
        <div className={checkbox_ongoing_wrapper}>
            <div className={text_checkbox_wrapper}>
                <div className={check_box}>
                    <Checkbox
                        checked={survey.survey_class === "ONGOING"}
                        onClick={onChange}
                    />
                </div>
                <div className={text_checkbox}>Ongoing survey</div>
            </div>
            <div className={info_wrapper}>
                <ShowDifftoneTooltip
                    tip={ONGOING_SURVEY_TOOLTIP_INFO}
                    tooltipPosition="bottom"
                >
                    <div className={icon_wrapper}>
                        <img src={blueIIcon} alt="info-icon" />
                    </div>
                </ShowDifftoneTooltip>
            </div>
        </div>
    );
};
