import { BaseSurvey, SurveyClasses } from "@difftone/types";
import { getUserEmail, getUserUuid } from "@difftone/procedures";
import { getStatus } from "@difftone/reducers";

export const needsMyAttentionCount = (surveysList: BaseSurvey[]) => {
    const initiatorSurveys = surveysList.filter(
        (_survey) =>
            _survey.initiator === getUserUuid() &&
            getStatus(_survey) === "ACTIVE"
    ).length;

    const responderSurveys = surveysList.filter(
        (_survey) =>
            _survey.respondents?.includes(getUserEmail()) &&
            getStatus(_survey, "RESPONDER") === "ACTIVE"
    ).length;

    return initiatorSurveys + responderSurveys;
};

export const ongoingSurveysCount = (surveysList: BaseSurvey[]): number => {
    const ongoingSurveys = surveysList.filter(
        (survey) =>
            survey.initiator === getUserUuid() &&
            survey.survey_class === "ONGOING" &&
            getStatus(survey, "INITIATOR") !== "DRAFT"
    );

    return ongoingSurveys.length;
};

export const allMySurveysCount = (surveysList: BaseSurvey[]) => {
    const initiatorSurveys = surveysList.filter(
        (_survey) => _survey.initiator === getUserUuid()
    );

    const responderSurveys = surveysList.filter(
        (_survey) =>
            _survey.respondents?.includes(getUserEmail()) &&
            getStatus(_survey, "INITIATOR") !== "DRAFT" &&
            getStatus(_survey, "INITIATOR") !== "SAVED"
    );

    const allSurveys = [...initiatorSurveys, ...responderSurveys].filter(
        (_survey) => getStatus(_survey, "INITIATOR") !== "DRAFT"
    );

    return allSurveys.length;
};

export const imTheInitiatorCount = (surveysList: SurveyClasses[]) => {
    const initiatorSurveys = surveysList.reduce((acc, _survey) => {
        if (_survey.initiator === getUserUuid() && _survey.status !== "DRAFT")
            acc++;
        return acc;
    }, 0);

    return initiatorSurveys;
};

export const imTheRespondentCount = (surveysList: BaseSurvey[]) => {
    const numberOfRespondentActiveSurveys = surveysList.reduce(
        (acc, _survey) => {
            if (
                getStatus(_survey, "RESPONDER") === "ACTIVE" &&
                _survey.respondents?.includes(getUserEmail())
            )
                acc++;
            return acc;
        },
        0
    );

    const numberOfRespondentCompletedSurveys = surveysList.reduce(
        (acc, _survey) => {
            if (
                getStatus(_survey, "RESPONDER") === "COMPLETED" &&
                _survey.respondents?.includes(getUserEmail())
            )
                acc++;
            return acc;
        },
        0
    );

    const numberOfRespondentEndedSurveys = surveysList.reduce(
        (acc, _survey) => {
            if (
                getStatus(_survey, "RESPONDER") === "ENDED" &&
                _survey.respondents?.includes(getUserEmail())
            )
                acc++;
            return acc;
        },
        0
    );

    return (
        numberOfRespondentActiveSurveys +
        numberOfRespondentCompletedSurveys +
        numberOfRespondentEndedSurveys
    );
};

export const notCompletedCount = (surveysList: BaseSurvey[]) => {
    const numberOfNotCompleted = surveysList.reduce((acc, _survey) => {
        if (
            getStatus(_survey, "RESPONDER") === "ACTIVE" &&
            _survey.respondents?.includes(getUserEmail())
        )
            acc++;
        return acc;
    }, 0);
    return numberOfNotCompleted;
};

export const exampleSurveysCount = (surveysList: BaseSurvey[]) => {
    return surveysList.reduce((acc: number, _survey: BaseSurvey) => {
        if (getStatus(_survey, "INITIATOR") === "DRAFT") acc++;
        return acc;
    }, 0);
};
