import { MouseEvent } from "react";
import { makeAutoObservable } from "mobx";
import { AuthData, TokenData, User, UserDataInfo } from "@difftone/types";
import { AuthResponse, UserAgentApplication } from "msal";

import {
    _init,
    googleLoginHandler,
    microsoftLoginHandler,
    setGapiModulesInitialized,
    setIsInitializedLoginStore,
    setStartInitializeLoginStore,
    setIsAuthDataInititalized,
} from "./login-store-internal-actions";

class LoginStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _googleError: Object | null = null;
    get googleError() {
        return this._googleError;
    }
    set googleError(error) {
        this._googleError = error;
    }

    private _isGoogleButtonClicked: boolean = false;
    get isGoogleButtonClicked() {
        return this._isGoogleButtonClicked;
    }
    set isGoogleButtonClicked(boolean) {
        this._isGoogleButtonClicked = boolean;
    }

    private _googleButtonClickEvent: MouseEvent<HTMLButtonElement> | undefined =
        undefined;
    get googleButtonClickEvent() {
        return this._googleButtonClickEvent;
    }
    set googleButtonClickEvent(event) {
        this._googleButtonClickEvent = event;
    }

    private _startInitialize: boolean = false;
    get startInitialize() {
        return this._startInitialize;
    }
    set startInitialize(isInitialized: boolean) {
        this._startInitialize = isInitialized;
    }

    private _isInitialized: boolean = false;
    get isInitialized() {
        return this._isInitialized;
    }
    set isInitialized(isInitialized: boolean) {
        this._isInitialized = isInitialized;
    }

    private _gapiModulesInitialized: boolean = false;
    get gapiModulesInitialized() {
        return this._gapiModulesInitialized;
    }

    set gapiModulesInitialized(isGapiModulesInitialized: boolean) {
        this._gapiModulesInitialized = isGapiModulesInitialized;
    }

    private _authDataInititalized: boolean = false;
    get authDataInititalized() {
        return this._authDataInititalized && !!localStorage.getItem("authData");
    }
    set authDataInititalized(isInitialized: boolean) {
        this._authDataInititalized = isInitialized;
    }

    private _isOrganizationUser: boolean = true;

    get isOrganizationUser() {
        return this._isOrganizationUser;
    }
    set isOrganizationUser(isOrganizationUser: boolean) {
        this._isOrganizationUser = isOrganizationUser;
    }

    private _tokenData?: TokenData | null;
    get tokenData(): TokenData | undefined | null {
        if (this._tokenData === undefined) {
            this.init();
        }

        return this._tokenData;
    }
    set tokenData(newTokenData: TokenData | undefined | null) {
        this._tokenData = newTokenData;
    }

    public async init() {
        setStartInitializeLoginStore(true);
        const tokenData: TokenData | null = await _init();
        setIsInitializedLoginStore(true);
        this._tokenData = tokenData;
    }

    public async initializeGapiModules() {
        try {
            gapi.load("auth2", async () => {
                gapi.load("client", async () => {
                    setGapiModulesInitialized(true);
                });
            });
        } catch (error) {
            console.error("Error loading gapi", (error as Error).message);
        }
    }

    public googleLogin = (googleAuthData: any) => {
        googleLoginHandler(googleAuthData);
    };

    public microsoftLogin = (
        result: AuthResponse | (AuthResponse & User),
        msalInstance: UserAgentApplication
    ) => {
        microsoftLoginHandler(result, msalInstance);
    };

    public getAuthDataFromGoogleAuthData = (googleAuthData: any): AuthData => {
        const authData: AuthData = {
            issuer: "GOOGLE",
            user_token: googleAuthData.tokenId,
            access_token: googleAuthData.accessToken,
        };

        return authData;
    };

    public publicUserInfoData = (userDataPayload: TokenData): UserDataInfo => {
        const userInfo: UserDataInfo = {
            first_name: userDataPayload.first_name?.trim(),
            last_name: userDataPayload.last_name?.trim(),
            full_name: userDataPayload.full_name,
            user_email: userDataPayload.user_email,
        };

        return userInfo;
    };

    public tokenDataAsync() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return new Promise((resolve, reject) => {
            this.init();
            setTimeout(() => {
                resolve(this._tokenData);
            }, 500);
        });
    }

    public clearStore = () => {
        setIsAuthDataInititalized(false);
        setStartInitializeLoginStore(false);
        setIsInitializedLoginStore(false);
    };
}

export const loginStore = new LoginStore();
