import React from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import {
    replaceQuestionsOrderByIndexes,
    setFocusedWizardInputCard,
    toggleLeftMenu,
    debounceUpsertSurveyToApi,
    accordionSectionsController,
} from "@difftone/actions";
import { getSurveyFromStoreByURL } from "@difftone/reducers";
import { MOBILE_WIDTH_BREAKPOINT } from "@difftone/constants";

import { DraggableLeftMenuQuestions } from "./components";

import {
    questions_list,
    section_counter,
    wizard_left_menu_questions,
    active_section,
} from "./wizard-left-menu-questions.module.css";

export type WizardLeftMenuQuestionsProps = {
    isActiveSection: boolean;
};

export const WizardLeftMenuQuestions = observer(
    (props: WizardLeftMenuQuestionsProps) => {
        const { isActiveSection } = props;
        const survey = getSurveyFromStoreByURL();
        const clientWidth = window.innerWidth;
        const questions = survey?.questions;

        const onDragEnd = (result: DropResult) => {
            if (result.source && result.destination) {
                replaceQuestionsOrderByIndexes(
                    result.source.index,
                    result.destination.index
                );
                setFocusedWizardInputCard(null);
                setTimeout(() => {
                    const wizardQuestionNodes = document.querySelectorAll(
                        "div[data-wizard-question-card]"
                    );
                    wizardQuestionNodes.forEach((_nodeCard) => {
                        if (
                            _nodeCard.getAttribute("uuid") ===
                            result.draggableId
                        ) {
                            setFocusedWizardInputCard(_nodeCard as HTMLElement);
                        }
                    });
                }, 0);
                debounceUpsertSurveyToApi();
            }
        };

        const onLeftMenuQuestionsClicked = () => {
            const wizardQuestionNodes = document.querySelectorAll(
                "div[data-wizard-question-card]"
            );
            accordionSectionsController("QUESTIONS");
            setFocusedWizardInputCard(wizardQuestionNodes[0] as HTMLElement);
            if (clientWidth < MOBILE_WIDTH_BREAKPOINT) {
                toggleLeftMenu();
            }
        };

        return (
            <>
                <div
                    className={clsx(
                        wizard_left_menu_questions,
                        isActiveSection && active_section
                    )}
                    onClick={onLeftMenuQuestionsClicked}
                >
                    Questions&nbsp;
                    <span
                        className={section_counter}
                    >{`(${questions?.length})`}</span>
                </div>
                <div className={questions_list}>
                    {isActiveSection ? (
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="characters">
                                {(provided) => (
                                    <ul
                                        className="characters"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        <DraggableLeftMenuQuestions />
                                        {provided.placeholder}
                                    </ul>
                                )}
                            </Droppable>
                        </DragDropContext>
                    ) : null}
                </div>
            </>
        );
    }
);
