import React, { MouseEvent, useEffect } from "react";
import clsx from "clsx";
import closeIcon from "@difftone/assets/close-datepicker-white.svg";

import { ScrollableTimeInputHeaders } from "./components";

import {
    scrollable_time_input,
    minutes_column,
    hours_column,
    time_input_header,
    scrollable_body,
    scrollable_time_value,
    selected_time_value,
    scrollable_minutes_and_hours_wrapper,
} from "./scrollable-time-input.module.css";

type TimeUnits = "HH" | "MM";

const zeroPrefixedTimeValues = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
];

const TOP_POSITION_SCROLL = 80;

export type ScrollableTimeInputProps = {
    closeCalendar: () => void;
    setTime: (newDateString: string, newTimeString: string) => void;
    dateValue: string;
    timeValue: string;
};

export const ScrollableTimeInput = (props: ScrollableTimeInputProps) => {
    const { closeCalendar, setTime, dateValue, timeValue } = props;
    const _hours = timeValue.split(":")[0];
    const _minutes = timeValue.split(":")[1];

    const minutesAsString = [
        ...zeroPrefixedTimeValues,
        ...new Array(50).fill(0).map((value, index) => index + 10),
    ];
    const hoursAsString = [
        ...zeroPrefixedTimeValues,
        ...new Array(14).fill(0).map((value, index) => index + 10),
    ];

    const onTimeChangedHandler = (event: MouseEvent<HTMLDivElement>) => {
        const clikcedUnit: TimeUnits = event.currentTarget.getAttribute(
            "data-unit"
        ) as TimeUnits;

        switch (clikcedUnit) {
            case "HH":
                setTime(
                    dateValue,
                    `${event.currentTarget.getAttribute(
                        "data-hours"
                    )}:${_minutes}`
                );
                return;
            case "MM":
                setTime(
                    dateValue,
                    `${_hours}:${event.currentTarget.getAttribute(
                        "data-minutes"
                    )}`
                );
                return;
            default:
                return;
        }
    };

    const executeTimeScroll = (
        itemWrapper: HTMLElement,
        activeItem: HTMLDivElement
    ) => {
        const activeItemHeight = activeItem.offsetTop;

        activeItem &&
            itemWrapper.scrollTo({
                top: activeItemHeight - TOP_POSITION_SCROLL,
                behavior: "smooth",
            });
    };

    //[useEffect]:: handle the autoscroll of active time values in columns
    useEffect(() => {
        const hoursScrollWrapper = document.querySelector(
            `div[data-calendar-hours]`
        ) as HTMLElement;

        const activeHoursItem = document.querySelector(
            `div[data-hours-active="true"]`
        ) as HTMLDivElement;

        executeTimeScroll(hoursScrollWrapper, activeHoursItem);
    }, [_hours]);

    useEffect(() => {
        const minutesScrollWrapper = document.querySelector(
            `div[data-calendar-minutes]`
        ) as HTMLElement;

        const activeMinutesItem = document.querySelector(
            `div[data-minutes-active="true"]`
        ) as HTMLDivElement;

        executeTimeScroll(minutesScrollWrapper, activeMinutesItem);
    }, [_minutes]);

    return (
        <div className={scrollable_time_input}>
            <div className={time_input_header}>
                <img onClick={closeCalendar} src={closeIcon} alt="close" />
            </div>
            <div className={scrollable_body}>
                <ScrollableTimeInputHeaders />
                <div className={scrollable_minutes_and_hours_wrapper}>
                    <div className={hours_column} data-calendar-hours>
                        {hoursAsString.map((value) => {
                            const isActive = value.toString() === _hours;
                            return (
                                <div
                                    onClick={onTimeChangedHandler}
                                    className={clsx(
                                        scrollable_time_value,
                                        isActive && selected_time_value
                                    )}
                                    key={value}
                                    data-unit="HH"
                                    data-hours={value}
                                    data-hours-active={isActive}
                                >
                                    {value}
                                </div>
                            );
                        })}
                    </div>
                    <div className={minutes_column} data-calendar-minutes>
                        {minutesAsString.map((value) => {
                            const isActive = value.toString() === _minutes;
                            return (
                                <div
                                    onClick={onTimeChangedHandler}
                                    className={clsx(
                                        scrollable_time_value,
                                        isActive && selected_time_value
                                    )}
                                    key={value}
                                    data-unit="MM"
                                    data-minutes={value}
                                    data-minutes-active={isActive}
                                >
                                    {value}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
