import React, { useEffect } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import { BaseSurvey, OngoingSurvey } from "@difftone/types";
import { accordionSectionsController } from "@difftone/actions";
import { leftMenuStore, wizardDisplayStore } from "@difftone/stores";
import {
    OngoingParticipantsAccordionItem,
    ParticipantsAccordionItem,
    QuestionsAccordion,
    TimelineAccordionItem,
    WizardDetails,
} from "./components";
import {
    survey_wizard,
    survey_wizard_left_menu_open,
    empty_div,
} from "./card-factory.module.css";

export type CardFactoryProps = {
    survey: BaseSurvey;
};

export const CardFactory = observer((props: CardFactoryProps) => {
    const { survey } = props;
    const { isEditableSurvey } = wizardDisplayStore;
    const { isOpen } = leftMenuStore;

    //useEffect for the first time render when survey in active mode
    useEffect(() => {
        if (!isEditableSurvey) {
            accordionSectionsController("PARTICIPANTS");
        } else {
            accordionSectionsController("DETAILS");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const simpleSurveyCards = () => {
        return (
            <div
                data-scroll-element="accordion-wrapper"
                className={clsx(
                    survey_wizard,
                    isOpen && survey_wizard_left_menu_open
                )}
            >
                <WizardDetails />
                <QuestionsAccordion />
                <ParticipantsAccordionItem />
                <TimelineAccordionItem />
                <div className={empty_div} />
            </div>
        );
    };

    switch (survey.survey_class) {
        case "ONGOING":
            const ongoingSurvey = survey as OngoingSurvey;
            return (
                <div
                    data-scroll-element="accordion-wrapper"
                    className={clsx(
                        survey_wizard,
                        isOpen && survey_wizard_left_menu_open
                    )}
                >
                    <WizardDetails />
                    <QuestionsAccordion />
                    {ongoingSurvey.status !== "DRAFT" && (
                        <>
                            <OngoingParticipantsAccordionItem />
                        </>
                    )}
                    <div className={empty_div} />
                </div>
            );
        case "SIMPLE":
            return simpleSurveyCards();
        default:
            return simpleSurveyCards();
    }
});
