import { makeAutoObservable } from "mobx";

class SharedSurveyDisplayStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _collapsedDetails: boolean = false;
    get collapsedDetails(): boolean {
        return this._collapsedDetails;
    }
    set collapsedDetails(newCollapsedDetails: boolean) {
        this._collapsedDetails = newCollapsedDetails;
    }

    private _collapsedDates: boolean = false;
    get collapsedDates(): boolean {
        return this._collapsedDates;
    }
    set collapsedDates(newCollapsedDates: boolean) {
        this._collapsedDates = newCollapsedDates;
    }

    private _collapsedAttributes: boolean = false;
    get collapsedAttributes(): boolean {
        return this._collapsedAttributes;
    }
    set collapsedAttributes(newCollapsedAttributes: boolean) {
        this._collapsedAttributes = newCollapsedAttributes;
    }

    private _collapsedQuestions: boolean = false;
    get collapsedQuestions(): boolean {
        return this._collapsedQuestions;
    }
    set collapsedQuestions(newCollapsedQuestions: boolean) {
        this._collapsedQuestions = newCollapsedQuestions;
    }

    public clearStore = () => {
        this._collapsedAttributes = false;
        this._collapsedDates = false;
        this._collapsedDetails = false;
        this._collapsedQuestions = false;
    };
}

export const sharedSurveyDisplayStore = new SharedSurveyDisplayStore();
