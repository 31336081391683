import React from "react";
import { Question, QuestionType } from "@difftone/types";

import { MOBILE_WIDTH_BREAKPOINT } from "@difftone/constants";
import { QuestionTypeIndicator } from "@difftone/shared-components";
import {
    question_title,
    index_class,
    title_class,
    question_title_index,
    question_indicator,
    required_asterisk,
} from "./question-title.module.css";

export type QuestionTitleProps = {
    question: Question<QuestionType>;
    index: number;
};

export const QuestionTitle = (props: QuestionTitleProps) => {
    const { question, index } = props;

    const isMobile = window.innerWidth <= MOBILE_WIDTH_BREAKPOINT;

    return (
        <div className={question_title}>
            <div className={question_title_index}>
                <span className={index_class}>{index + 1}.</span>
                <span className={title_class}>
                    {question.title}
                    {isMobile && question.required ? (
                        <span className={required_asterisk}>*</span>
                    ) : null}
                </span>
            </div>
            <div className={question_indicator}>
                <QuestionTypeIndicator
                    question={question}
                    collapsed={false}
                    disabled={true}
                />
            </div>
        </div>
    );
};
