import React from "react";
import {
    LongTextQuestion,
    Question,
    SupportedQuestionResultsMapAfterDigestionWithoutNulls,
} from "@difftone/types";
import { MobileResultBox } from "@difftone/shared-components";

export type OpenQuestionsTypeProps = {
    question: Question<LongTextQuestion>;
    digestedResultsRow: SupportedQuestionResultsMapAfterDigestionWithoutNulls;
    columnKey: string;
    index: number;
};

export const OpenQuestionsType = (props: OpenQuestionsTypeProps) => {
    const { question, digestedResultsRow, columnKey, index } = props;

    const totalNumberOfAnswers = digestedResultsRow[columnKey].length;

    return (
        <MobileResultBox
            key={question.uuid + columnKey + index}
            colorIndicator={
                index === 0
                    ? totalNumberOfAnswers
                        ? "OVERALL"
                        : "DEFAULT"
                    : "DEFAULT"
            }
            resultValue={totalNumberOfAnswers ? totalNumberOfAnswers : ""}
        />
    );
};
