import React, { useEffect, useState } from "react";

import type { Domain, Organization } from "@difftone/types";
import {
    getAllDomainsForDifftone,
    getAllOrganizationsForDifftone,
    upsertOrganizationForDifftone,
} from "@difftone/features/organizations-domains/services";

import { Domains, Organizations } from "./components";
import {
    organizations_domains,
    red_warning,
} from "./organizations-domains.module.css";

export type organizationsDomainsProps = {};

export const OrganizationsDomains = () => {
    const [domains, setDomains] = useState<Domain[]>([]);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            await getAllDomainsForDifftone().then((items) => setDomains(items));
            await getAllOrganizationsForDifftone().then((items) =>
                setOrganizations(items)
            );
        };
        fetchData();
    }, []);

    const onRemoveDomainFromAllOrganization = (domain: Domain) => {
        const _organizations: Organization[] = [];
        const organizationsToUpdateForDifftone: Organization[] = [];

        organizations.forEach((organization) => {
            const domainsLength = organization.domains.length;

            organization.domains = organization.domains.filter(
                (domainUuid) => domainUuid !== domain.uuid
            );

            if (domainsLength !== organization.domains.length) {
                organizationsToUpdateForDifftone.push(organization);
            }

            _organizations.push(organization);
        });

        setOrganizations(_organizations);
        updateAllOrganization(organizationsToUpdateForDifftone);
    };

    const updateAllOrganization = async (organizations: Organization[]) => {
        if (organizations.length === 0) {
            return;
        }

        for (const org of organizations) {
            await upsertOrganizationForDifftone(org);
        }
    };

    return (
        <div className={organizations_domains}>
            <h1 className={red_warning}>
                This page allows direct access to the DB, there is no undo or
                revert.
            </h1>
            <h1 className={red_warning}>
                Make sure you know what you're doing!
            </h1>
            <hr />
            <Domains
                onRemoveDomainFromAllOrganization={
                    onRemoveDomainFromAllOrganization
                }
                domains={domains}
                setDomains={setDomains}
            />
            <hr />
            <Organizations
                setOrganizations={setOrganizations}
                organizations={organizations}
                domains={domains}
            />
            <hr />
        </div>
    );
};
