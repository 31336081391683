import React from "react";
import { observer } from "mobx-react";
import { getSurveyFromStoreByURL } from "@difftone/reducers";
import { AccordionItem } from "@difftone/shared-components";
import { wizardStore } from "@difftone/stores";

import { WizardTimeline } from "./components";

import {
    ongoing_timeline_accordion_item,
    split_line,
} from "./ongoing-timeline-accordion-item.module.css";

export type OngoingTimelineAccordionItemProps = {};

const TEMPORARY_NUMBER_OF_EVENTS_ONGOING = 2;

export const OngoingTimelineAccordionItem = observer(
    (props: OngoingTimelineAccordionItemProps) => {
        const { activeAccordionSection } = wizardStore;
        const survey = getSurveyFromStoreByURL();
        return (
            <div className={ongoing_timeline_accordion_item}>
                <AccordionItem
                    //TODO :: Adding events to an ongoing https://app.asana.com/0/1201346022025578/1202853257111570/f
                    sectionCounter={TEMPORARY_NUMBER_OF_EVENTS_ONGOING}
                    selectedAccordionItem="TIME_LINE"
                    accordionTitle="Timeline"
                    isAccordionSectionOpen={
                        activeAccordionSection === "TIME_LINE"
                    }
                    survey={survey}
                >
                    <div className={split_line} />
                    <WizardTimeline />
                </AccordionItem>
            </div>
        );
    }
);
