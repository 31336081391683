import React, { useState } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import { IconType } from "@difftone/types";
import dropDownArrowIcon from "@difftone/assets/down-arrow-small.svg";

import { DynamicIcon } from "./components/options-list/components";
import { OptionsList } from "./components";

import {
    results_display_type_dropdown,
    results_display_type_dropdown_focus,
    selected_option,
    select_option_counter,
    drop_down_arrow_icon,
    dynamic_icon_scope,
} from "./results-display-type-dropdown.module.css";

export type DropdownElementsWithIcon = {
    systemValue: string;
    displayValue: string;
    //TODO change the type from string to Type https://app.asana.com/0/1201346022025578/1203264006891799/f
    urlValue: string;
    iconType?: IconType;
    isDisabled?: boolean;
    counterValue?: number;
};

export type ResultsDisplayTypeDropdownProps = {
    defaultSelectedValue: string;
    dropdownOptions: DropdownElementsWithIcon[];
    onChange: (selectedValue: string) => void; //TODO change the type from string to Type https://app.asana.com/0/1201346022025578/1203264006891799/f
};

export const ResultsDisplayTypeDropdown = observer(
    (props: ResultsDisplayTypeDropdownProps) => {
        const { defaultSelectedValue, dropdownOptions, onChange } = props;
        const [showOptions, setShowOptions] = useState(false);

        const _selectedValue = dropdownOptions.find(
            (option) => option.urlValue === defaultSelectedValue
        );

        const [selectOption, setSelectOption] = useState<
            DropdownElementsWithIcon | undefined
        >(_selectedValue);

        if (_selectedValue?.displayValue !== selectOption?.displayValue) {
            setSelectOption(_selectedValue);
        }

        const onShowOptions = () => {
            setShowOptions(!showOptions);
        };

        const hideOptionsHandler = () => {
            setShowOptions(false);
        };

        const onSelectOption = (value: DropdownElementsWithIcon) => {
            setSelectOption(value);
            onChange(value.urlValue);
        };

        return (
            <div
                tabIndex={0}
                className={clsx(
                    results_display_type_dropdown,
                    showOptions && results_display_type_dropdown_focus
                )}
                onClick={onShowOptions}
                onBlur={hideOptionsHandler}
            >
                <div className={selected_option}>
                    {selectOption && selectOption.iconType && (
                        <DynamicIcon
                            classNameOverride={dynamic_icon_scope}
                            iconType={selectOption.iconType}
                            isSelected={false}
                            isMouseHover={false}
                            isDisabled={false}
                        />
                    )}

                    {selectOption?.displayValue
                        ? selectOption.displayValue
                        : "choose your answer"}
                    {selectOption && selectOption.counterValue ? (
                        <div
                            className={select_option_counter}
                        >{`(${selectOption.counterValue})`}</div>
                    ) : null}
                </div>

                <img
                    className={drop_down_arrow_icon}
                    src={dropDownArrowIcon}
                    alt="arrow"
                />

                {showOptions && (
                    <OptionsList
                        onChange={onSelectOption}
                        selectOption={selectOption}
                        options={dropdownOptions}
                    />
                )}
            </div>
        );
    }
);
