import { makeAutoObservable, observable, ObservableMap } from "mobx";
import { getSurveyUUIDFromUrl } from "@difftone/reducers";
import { SurveyResponse, UUID } from "@difftone/types";
import {
    getAllSurveysResponses,
    saveConvertedSurveyResponseToApi,
    saveOpenSurveyResponseToApi,
    saveSurveyResponseToApi,
} from "./survey-response-internal-actions";
import { getCurrentTimeInBEFormat } from "@difftone/frontend-common";

class SurveyResponseStore {
    constructor() {
        makeAutoObservable<SurveyResponseStore, "_fetchedSurveyResponseKeys">(
            this,
            {
                _fetchedSurveyResponseKeys: false,
            }
        );
    }

    private FAKE_SURVEY_RESPONSE: SurveyResponse = {
        uuid: "",
        version: "0.0.1",
        responder: "",
        submission_date: undefined,
        survey_UUID: "",
        survey_initiator: "",
        answers: [],
        attribute_answers: [],
        created_at: getCurrentTimeInBEFormat(),
        is_deleted: false,
    };

    private _storeInitialize: boolean = false;
    get storeInitialize(): boolean {
        return this._storeInitialize;
    }
    set storeInitialize(newStoreInitialize: boolean) {
        this._storeInitialize = newStoreInitialize;
    }

    private _startInitialization: boolean = false;
    get startInitialization(): boolean {
        return this._startInitialization;
    }
    set startInitialization(newStartInitialization: boolean) {
        this._startInitialization = newStartInitialization;
    }

    private _fetchedSurveyResponseBySurveyUuid: UUID[] = [];

    private _surveyResponsesMap: ObservableMap<UUID, SurveyResponse | null> =
        observable.map({});

    public getSurveyResponsesMap = (): ObservableMap<
        UUID,
        SurveyResponse | null
    > => {
        return this._surveyResponsesMap;
    };

    public setSurveyResponseToMap = (
        surveyResponseUUID: UUID,
        surveyResponse: SurveyResponse | null
    ) => {
        this._surveyResponsesMap.merge({
            [surveyResponseUUID]: surveyResponse,
        });
    };

    public addSurveyResponseWithApiPersistance = (
        surveyResponse: SurveyResponse
    ) => {
        saveSurveyResponseToApi(surveyResponse);
        this.setSurveyResponseToMap(surveyResponse.survey_UUID, surveyResponse);
    };

    public addOpenSurveyResponseWithApiPersistance = (
        surveyResponse: SurveyResponse
    ) => {
        saveOpenSurveyResponseToApi(surveyResponse);
        this.setSurveyResponseToMap(surveyResponse.survey_UUID, surveyResponse);
    };

    public addConvertedSurveyResponseWithApiPersistance = (
        surveyResponse: SurveyResponse
    ) => {
        saveConvertedSurveyResponseToApi(surveyResponse);
        this.setSurveyResponseToMap(surveyResponse.survey_UUID, surveyResponse);
    };

    public getSurveyResponseByUuid = (
        surveyResponseUUID: UUID
    ): SurveyResponse | undefined | null => {
        if (surveyResponseUUID === "") return this.FAKE_SURVEY_RESPONSE;
        const fetchedSurveyResponse = [
            ...this._surveyResponsesMap.values(),
        ].find(
            (surveyResponse) =>
                surveyResponse !== null &&
                surveyResponse.uuid === surveyResponseUUID
        );

        if (fetchedSurveyResponse === undefined) {
            throw new Error("SurveyResponse not found");
        }

        return fetchedSurveyResponse;
    };

    private getSurveyResponsesList = (): SurveyResponse[] => {
        const res = [...this._surveyResponsesMap.values()].filter(
            (surveyResponse) => !!surveyResponse
        );
        //@ts-ignore
        return res;
    };

    public getSurveyResponseBySurveyUUID = (
        surveyUUID: UUID
    ): SurveyResponse | null => {
        const response = this._surveyResponsesMap.get(surveyUUID);

        return response ? response : null;
    };

    public getSelectedSurveyResponseUUID = () => {
        return this.getSelectedSurveyResponse()?.uuid;
    };

    public getSelectedSurveyResponse = () => {
        const surveyUuid = getSurveyUUIDFromUrl();
        if (!surveyUuid) {
            console.error(`Could not find SurveyUUID ${surveyUuid}`);
            return null;
        }
        const surveyResponseUUID =
            this.getSurveyResponseUuidBySurveyUuid(surveyUuid);

        if (surveyResponseUUID === undefined) {
            if (!this._fetchedSurveyResponseBySurveyUuid.includes(surveyUuid)) {
                this._fetchedSurveyResponseBySurveyUuid.push(surveyUuid);
                getAllSurveysResponses();
            }
            return surveyResponseUUID;
        }

        if (surveyResponseUUID === null) {
            return surveyResponseUUID;
        }

        return this.getSurveyResponseByUuid(surveyResponseUUID);
    };

    private getSurveyResponseUuidBySurveyUuid = (surveyUUID: UUID) => {
        const existingSurveyResponse = this.getSurveyResponsesList().find(
            (surveyResponse) => surveyResponse.survey_UUID === surveyUUID
        );

        if (this.storeInitialize) {
            return existingSurveyResponse ? existingSurveyResponse.uuid : null;
        }

        if (this.startInitialization) {
            return existingSurveyResponse
                ? existingSurveyResponse.uuid
                : undefined;
        }

        return existingSurveyResponse ? existingSurveyResponse.uuid : undefined;
    };

    public initAllSurveyResponses = () => {
        getAllSurveysResponses();
    };

    public clearStore = () => {
        this._storeInitialize = false;
        this._startInitialization = false;
        this._surveyResponsesMap = observable.map({});
    };
}

export const surveyResponseStore = new SurveyResponseStore();
