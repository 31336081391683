import React from "react";
import {
    Question,
    QuestionType,
    RatingQuestion,
    SupportedQuestionResultsMapAfterDigestion,
} from "@difftone/types";
import { COLUMN_0, NO_ANSWER_KEY } from "@difftone/common-constants";

import { getMostPopular, setOpenedQuestionUUID } from "@difftone/actions";
import { values_plain, view_answers } from "./values-plain.module.css";

export type ValuesPlainProps = {
    question: Question<QuestionType>;
    resultPopularity: SupportedQuestionResultsMapAfterDigestion;
};

export const ValuesPlain = (props: ValuesPlainProps) => {
    const { question, resultPopularity } = props;

    const resultPopularityWithoutNoResponse = JSON.parse(
        JSON.stringify(resultPopularity)
    );

    delete resultPopularityWithoutNoResponse[COLUMN_0][NO_ANSWER_KEY];

    let plainValue = "";
    switch (question.question_type_name) {
        case "RATING":
            const firstOption = (
                question as Question<RatingQuestion>
            ).content.rating_options.at(0);

            const lastOption = (
                question as Question<RatingQuestion>
            ).content.rating_options.at(-1);

            if (!firstOption || !lastOption) {
                throw new Error("No options to calculate range");
            }

            plainValue = `(${firstOption.value}-${lastOption.value})`;
            break;
        case "SINGLE_CHOICE":
        case "MULTIPLE_CHOICE":
            const popularAnswer = resultPopularityWithoutNoResponse[COLUMN_0]
                ? Object.keys(
                      getMostPopular(
                          resultPopularityWithoutNoResponse[COLUMN_0]
                      )
                  )[0]
                : "";
            plainValue = `(${popularAnswer})`;
            break;
        case "SHORT_TEXT":
        case "LONG_TEXT":
        case "DATE":
        case "TIME":
            plainValue = "View Answers";
            break;
        default:
            plainValue = "def";
            break;
    }

    const selectTextQuestionUuid = () => {
        setOpenedQuestionUUID(question.uuid);
    };

    switch (question.question_type_name) {
        case "RATING":
        case "SINGLE_CHOICE":
        case "MULTIPLE_CHOICE":
            return (
                <div className={values_plain}>
                    <div>{plainValue}</div>
                </div>
            );
        case "SHORT_TEXT":
        case "LONG_TEXT":
        case "DATE":
        case "TIME":
            return (
                <div className={values_plain}>
                    <div
                        onClick={selectTextQuestionUuid}
                        className={view_answers}
                    >
                        {plainValue}
                    </div>
                </div>
            );

        default:
            return (
                <div className={values_plain}>
                    <div>{plainValue}</div>
                </div>
            );
    }
};
