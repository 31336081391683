import type {
    SurveyClasses,
    SurveyNameValidationType,
    ValidationResult,
} from "@difftone/types";

export const validateSurveyAdmins = (
    survey: SurveyClasses
): ValidationResult<SurveyNameValidationType>[] => {
    try {
        return [
            {
                is_valid: true,
                validation_error_code: "EMPTY_VALIDATION",
            },
        ];
    } catch (error) {
        console.error(
            `validateSurveyDates error:: ${(error as Error).message}`
        );
        return [
            {
                is_valid: false,
                validation_error_code: "EXCEPTION_DURING_VALIDATIONS",
                //TODO: fix type definition
                //@ts-ignore
                validation_error_type: error as Error,
            },
        ];
    }
};
