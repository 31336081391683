import { action } from "mobx";
import { scheduleSurveyPopupStore } from "@difftone/stores";

export const openScheduleSurveyPopup = action(() => {
    scheduleSurveyPopupStore.isOpen = true;
});

export const closeScheduleSurveyPopup = action(() => {
    scheduleSurveyPopupStore.isOpen = false;
});
