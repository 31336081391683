import React, { SVGAttributes } from "react";

export const DownloadIcon = (props: SVGAttributes<HTMLOrSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="13"
        fill="none"
        viewBox="0 0 11 13"
        {...props}
    >
        <path
            fill="#009788"
            fillRule="evenodd"
            d="M7.62 4.735h1.123c.628 0 .938.763.494 1.207l-3.24 3.24a.703.703 0 01-.996 0l-3.24-3.24c-.444-.444-.127-1.207.502-1.207h1.122v-3.53c0-.387.318-.705.706-.705h2.823c.389 0 .706.318.706.706v3.53zM1.267 12.5a.708.708 0 01-.705-.706c0-.388.317-.706.705-.706h8.471c.388 0 .706.318.706.706a.708.708 0 01-.706.706h-8.47z"
            clipRule="evenodd"
        />
    </svg>
);
