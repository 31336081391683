import type { TokenData, Payload } from "@difftone/types";
import { TokenPayload } from "google-auth-library";

export const decodeGoogleUserToken = (user_token: TokenPayload): TokenData => {
    const payload = user_token;
    const tokenData = {} as TokenData;
    if (!payload) {
        throw new Error(`decodeGoogleUserToken error:: cannot decode payload!`);
    }
    tokenData.issuer = "GOOGLE";
    tokenData.user_email = payload.email || undefined;
    tokenData.first_name = payload.given_name || undefined;
    tokenData.last_name = payload.family_name || undefined;
    tokenData.sub = payload.sub;
    tokenData.exp = payload.exp;
    tokenData.full_name = payload.name || undefined;
    return tokenData as TokenData;
};

export const decodeMicrosoftUserToken = (user_token: any): TokenData => {
    const payload: Payload = user_token as Payload;
    const tokenData = {} as TokenData;
    if (!payload) {
        throw new Error(
            `decodeMicrosoftUserToken error:: cannot decode payload!`
        );
    }

    tokenData.issuer = "MICROSOFT";
    tokenData.user_email = payload.preferred_username || undefined;
    tokenData.first_name = undefined;
    tokenData.last_name = undefined;
    tokenData.sub = payload.sub;
    tokenData.exp = payload.exp;
    tokenData.full_name = payload.name || undefined;
    return tokenData;
};
