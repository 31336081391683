import React from "react";
import clsx from "clsx";
import { TimelineEvent } from "@difftone/types";
import { calculatePassedAndFutureTimeline } from "@difftone/reducers";

import {
    line_wrapper,
    line,
    future_time_line,
    current_time_as_first,
} from "./line.module.css";

export type LineProps = {
    sortedEvents: TimelineEvent[];
};

export const Line = (props: LineProps) => {
    const { sortedEvents } = props;

    const { passedEventsTimelineHeight, futureEventTimelineHeight } =
        calculatePassedAndFutureTimeline(sortedEvents);

    const firstEvent = sortedEvents[0];

    return (
        <div
            className={clsx(
                line_wrapper,
                firstEvent?.isCurrentTime && current_time_as_first
            )}
        >
            <div
                style={{ height: `${passedEventsTimelineHeight}px` }}
                className={line}
            ></div>
            <div
                style={{ height: `${futureEventTimelineHeight}px` }}
                className={future_time_line}
            ></div>
        </div>
    );
};
