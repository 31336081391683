import React from "react";
import { UiStatus } from "@difftone/frontend-common";

import endedSurveyIcon from "@difftone/assets/ended-survey-icon.svg";

import {
    ended_survey_icon,
    ended_survey_icon_information,
} from "./ended-survey-icon.module.css";

type EndedSurveyIconProps = {
    surveyStatus: UiStatus;
    pageLocation: string;
};

export const EndedSurveyIcon = (props: EndedSurveyIconProps) => {
    const { surveyStatus, pageLocation } = props;

    return surveyStatus === "DRAFT" || surveyStatus === "SAVED" ? null : (
        <img
            className={
                pageLocation === "information"
                    ? ended_survey_icon_information
                    : ended_survey_icon
            }
            src={endedSurveyIcon}
            alt=""
        />
    );
};
