import React from "react";
import clsx from "clsx";
import { observer } from "mobx-react";

import { DateAndTime, focusedCalendar } from "@difftone/types";
import {
    CustomDateTimePicker,
    ValidationWrapper,
} from "@difftone/shared-components";
import {
    setDashboardCalendarOpen,
    updateFilterEndDate,
    updateFilterStartDate,
} from "@difftone/actions";
import { dashboardDisplayStore } from "@difftone/stores";
import {
    isDashboardFilterStatusReady,
    validateDashboardEndDate,
} from "@difftone/reducers";
import { warningMessages } from "@difftone/constants";

import {
    date_range_wrapper,
    date_picker_wrapper,
    view_range_from,
    view_range_to,
    text_style,
    opened_date_picker,
} from "./date-range-selector.module.css";

export type DateRangeSelectorProps = {};

export const DateRangeSelector = observer((props: DateRangeSelectorProps) => {
    const { dashboardFocusedCalendar, dashboardDraftFilter } =
        dashboardDisplayStore;

    const startDatePicker: HTMLDivElement | null = document.querySelector(
        `div[data-start-date-calendar]`
    );
    const endDatePicker: HTMLDivElement | null = document.querySelector(
        `div[data-end-date-calendar]`
    );

    let isDateValid = true;

    if (isDashboardFilterStatusReady()) {
        isDateValid = validateDashboardEndDate().some(
            (validationResult) => validationResult.is_valid
        );
    }

    const openFromCalendar = () => {
        setDashboardCalendarOpen({
            calendarElement: startDatePicker,
            isOpen: true,
        } as focusedCalendar);
    };

    const openToCalendar = () => {
        setDashboardCalendarOpen({
            calendarElement: endDatePicker,
            isOpen: true,
        } as focusedCalendar);
    };

    const onCloseCalendar = () => {
        setDashboardCalendarOpen(null);
    };

    const onDateFilterChange = (dateAndTime: DateAndTime) => {
        dashboardFocusedCalendar?.calendarElement === startDatePicker &&
            updateFilterStartDate(dateAndTime.date);

        dashboardFocusedCalendar?.calendarElement === endDatePicker &&
            updateFilterEndDate(dateAndTime.date);
    };

    return (
        <div className={date_range_wrapper}>
            <div className={view_range_from}>
                <div
                    className={clsx(
                        date_picker_wrapper,
                        dashboardFocusedCalendar?.calendarElement ===
                            startDatePicker && opened_date_picker
                    )}
                    data-start-date-calendar
                >
                    <CustomDateTimePicker
                        responseMode={true}
                        showTime={false}
                        isSystemEvent={false}
                        isValid={true}
                        isOnlyDateDisplay={true}
                        disabled={false}
                        onChange={onDateFilterChange}
                        localDateStr={
                            dashboardDraftFilter
                                ? dashboardDraftFilter.startDate
                                : ""
                        }
                        dayUtcTime={0}
                        isOpen={
                            dashboardFocusedCalendar?.calendarElement ===
                                startDatePicker &&
                            dashboardFocusedCalendar.isOpen
                        }
                        onOpen={openFromCalendar}
                        onClose={onCloseCalendar}
                    />
                </div>
            </div>
            <div className={view_range_to}>
                <span className={text_style}>to</span>
                <div
                    className={clsx(
                        date_picker_wrapper,
                        dashboardFocusedCalendar?.calendarElement ===
                            endDatePicker && opened_date_picker
                    )}
                    data-end-date-calendar
                >
                    <ValidationWrapper
                        isValid={isDateValid}
                        errorMessage={
                            warningMessages.dashboardEndDateWarningMessage
                        }
                    >
                        <CustomDateTimePicker
                            responseMode={true}
                            showTime={false}
                            isSystemEvent={false}
                            isValid={true}
                            isOnlyDateDisplay={true}
                            disabled={false}
                            onChange={onDateFilterChange}
                            localDateStr={
                                dashboardDraftFilter
                                    ? dashboardDraftFilter.endDate
                                    : ""
                            }
                            dayUtcTime={0}
                            isOpen={
                                dashboardFocusedCalendar?.calendarElement ===
                                    endDatePicker &&
                                dashboardFocusedCalendar.isOpen
                            }
                            onOpen={openToCalendar}
                            onClose={onCloseCalendar}
                        />
                    </ValidationWrapper>
                </div>
            </div>
        </div>
    );
});
