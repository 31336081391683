import {
    Tag,
    ValidationResult,
    TagTextColorValidationType
} from "@difftone/types";

import { isColorHashValid } from '.';

export const validateTagTextColor = (tag: Tag): ValidationResult<TagTextColorValidationType>[] => {

    return (!tag.text_color || !isColorHashValid(tag.text_color)) ?
        [{
            is_valid: false,
            validation_error_code: "TAG_TEXT_COLOR_VALIDATION",
            validation_error_type: { tag_uuid: tag.uuid }
        }] :
        [{
            is_valid: true,
            validation_error_code: "TAG_TEXT_COLOR_VALIDATION",
            validation_error_type: { tag_uuid: tag.uuid }
        }];
} 