import { showDifftoneAlert } from "@difftone/actions";
import { UUID, Email } from "@difftone/types";
import { makeAutoObservable, observable, ObservableMap } from "mobx";
import {
    emailFromApiByUUID,
    emailToMapSetter,
    upsertEmail,
} from "./emails-store-internal-actions";

export const EMAIL_TEMPLATE: Partial<Email> = {
    recipients: [],
    content: "",
    cancelled: false,
};

export class EmailsStore {
    constructor() {
        makeAutoObservable<EmailsStore, "_fetchedEmailKeys">(this, {
            _fetchedEmailKeys: false,
        });
    }

    private _fetchedEmailKeys: string[] = [];

    private _emailsMap: ObservableMap<UUID, Email | null> = observable.map({});

    public getEmailsMap(): ObservableMap<UUID, Email | null> {
        return this._emailsMap;
    }

    public setEmailToMap(emailUUID: UUID, newEmail: Email | null) {
        this._emailsMap.merge({ [emailUUID]: newEmail });
    }

    public getEmailByUuid = (emailUUID: UUID): Email | undefined | null => {
        const fetchedEmail = this._emailsMap.get(emailUUID);

        if (
            fetchedEmail === undefined &&
            !this._fetchedEmailKeys.includes(emailUUID)
        ) {
            this._fetchedEmailKeys.push(emailUUID);
            emailFromApiByUUID(emailUUID);
        }

        return fetchedEmail;
    };

    public upsertEmail = (email: Email) => {
        emailToMapSetter(email.uuid, email);
        upsertEmail(email).catch((err) => {
            console.error(err);
            showDifftoneAlert(
                "Could not update the email. Please try again later.",
                "FAILURE"
            );
        });
    };

    public clearStore = () => {
        this._emailsMap = observable.map({});
    };
}

export const emailsStore = new EmailsStore();
