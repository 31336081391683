import React, { useState } from "react";
import { OptionalPropertiesForDuplicateSurvey } from "@difftone/types";
import dropDownArrow from "@difftone/assets/down-arrow.svg";

import { DetailsItem } from "./components";
import {
    duplicate_details,
    duplicate_details_header,
    duplicate_details_title,
    text_label,
    collapse_arrow,
    rotated_arrow,
    half_checked_style,
    checked_style,
    custom_checkbox_class,
} from "./duplicate-details.module.css";

export type DuplicateDetailsProps = {
    duplicateSurveyProps: OptionalPropertiesForDuplicateSurvey;
    onToggleAllDetailsSelected: (allSelected: boolean) => void;
    onToggleDetailsItem: (detailsKey: string, selected: boolean) => void;
};

export const DuplicateDetails = (props: DuplicateDetailsProps) => {
    const {
        duplicateSurveyProps,
        onToggleAllDetailsSelected,
        onToggleDetailsItem,
    } = props;

    const [collapsedDetails, setCollapsedDetails] = useState(false);

    const toggleCollapse = () => {
        setCollapsedDetails(!collapsedDetails);
    };

    const _details: { [key: string]: boolean } = {
        purpose: duplicateSurveyProps.purpose,
        anonymity: duplicateSurveyProps.anonymity,
        introduction: duplicateSurveyProps.introduction,
    };

    const detailsKeys = Object.keys(_details);

    const allDetailsSelected = detailsKeys.every((key) => _details[key]);
    const allDetailsNotSelected = detailsKeys.every((key) => !_details[key]);

    const onChange = () => {
        onToggleAllDetailsSelected(!allDetailsSelected);
    };

    let checkedIcon = half_checked_style;

    if (allDetailsSelected) {
        checkedIcon = checked_style;
    }
    if (allDetailsNotSelected) {
        checkedIcon = custom_checkbox_class;
    }

    return (
        <div className={duplicate_details}>
            <div className={duplicate_details_header}>
                <div className={duplicate_details_title}>
                    <label className={checkedIcon}>
                        <input type="checkbox" onChange={onChange} />
                    </label>
                    <label className={text_label}>Details</label>
                </div>
                <img
                    onClick={toggleCollapse}
                    className={
                        collapsedDetails
                            ? [collapse_arrow, rotated_arrow].join(" ")
                            : collapse_arrow
                    }
                    src={dropDownArrow}
                    alt="arrow_collapse_expand"
                />
            </div>
            {collapsedDetails ? (
                <div>
                    <DetailsItem
                        detailsKey={"purpose"}
                        onToggleDetailsItem={onToggleDetailsItem}
                        title={"Purpose"}
                        value={duplicateSurveyProps.purpose}
                    />
                    <DetailsItem
                        detailsKey={"anonymity"}
                        onToggleDetailsItem={onToggleDetailsItem}
                        title={"Anonymity"}
                        value={duplicateSurveyProps.anonymity}
                    />
                    <DetailsItem
                        detailsKey={"introduction"}
                        onToggleDetailsItem={onToggleDetailsItem}
                        title={"Introduction"}
                        value={duplicateSurveyProps.introduction}
                    />
                </div>
            ) : null}
        </div>
    );
};
