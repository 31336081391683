import React from "react";
import {
    Anonimity,
    Question,
    QuestionType,
    RatingQuestion,
    SupportedQuestionResultsMapAfterDigestion,
} from "@difftone/types";

import { getMostPopular, setOpenedQuestionUUID } from "@difftone/actions";
import { ShowDifftoneTooltip } from "@difftone/shared-components";
import { COLUMN_0, NO_ANSWER_KEY } from "@difftone/common-constants";
import { anonymityLimitation } from "@difftone/anonymity-util";

import {
    values_plain,
    plain_title,
    view_answers,
    range_and_popular,
} from "./values-plain.module.css";

const MAX_CHAR_LENGTH_TITLE = 10;

export type ValuesPlainProps = {
    question: Question<QuestionType>;
    resultPopularity: SupportedQuestionResultsMapAfterDigestion;
    surveyAnonymityLevel: Anonimity;
};

export const ValuesPlain = (props: ValuesPlainProps) => {
    const { question, resultPopularity, surveyAnonymityLevel } = props;

    const resultPopularityWithoutNoResponse = JSON.parse(
        JSON.stringify(resultPopularity)
    );

    delete resultPopularityWithoutNoResponse[COLUMN_0][NO_ANSWER_KEY];

    let plainTitle = "";
    let plainValue = "";
    let isAvailableResult: boolean = false;

    switch (question.question_type_name) {
        case "RATING":
            plainTitle = "Avg";
            const firstValue = (
                question as Question<RatingQuestion>
            ).content.rating_options.at(0)!.value;

            const lastValue = (
                question as Question<RatingQuestion>
            ).content.rating_options.at(-1)!.value;

            plainValue = `${firstValue}-${lastValue}`;
            break;
        case "SINGLE_CHOICE":
        case "MULTIPLE_CHOICE":
            plainTitle = "Popular";
            const popularAnswer = resultPopularityWithoutNoResponse[COLUMN_0]
                ? Object.keys(
                      getMostPopular(
                          resultPopularityWithoutNoResponse[COLUMN_0]
                      )
                  )[0]
                : "";
            plainValue = popularAnswer;
            break;
        case "SHORT_TEXT":
        case "LONG_TEXT":
        case "TIME":
        case "DATE":
            const limitationSize = anonymityLimitation[surveyAnonymityLevel];

            isAvailableResult =
                resultPopularityWithoutNoResponse[COLUMN_0].length &&
                resultPopularityWithoutNoResponse[COLUMN_0].length >=
                    limitationSize;

            plainTitle = "View Answers";
            break;
        default:
            plainTitle = "DEF";
            plainValue = "def";
            break;
    }

    const slicedPopularAnswer = (popularAnswer: string) => {
        return popularAnswer.slice(0, MAX_CHAR_LENGTH_TITLE).concat("...");
    };

    const selectTextQuestionUuid = () => {
        setOpenedQuestionUUID(question.uuid);
    };

    let popularAnswerTitle = null;
    if (plainValue.length > MAX_CHAR_LENGTH_TITLE) {
        popularAnswerTitle = (
            <ShowDifftoneTooltip tip={plainValue} tooltipPosition="left">
                <div className={range_and_popular}>{`(${slicedPopularAnswer(
                    plainValue
                )})`}</div>
            </ShowDifftoneTooltip>
        );
    } else {
        popularAnswerTitle = (
            <div className={range_and_popular}>{`[${plainValue}]`}</div>
        );
    }

    switch (question.question_type_name) {
        case "RATING":
        case "SINGLE_CHOICE":
        case "MULTIPLE_CHOICE":
            return (
                <div className={values_plain}>
                    <div className={plain_title}>{plainTitle}</div>
                    {popularAnswerTitle}
                </div>
            );

        case "SHORT_TEXT":
        case "LONG_TEXT":
        case "DATE":
        case "TIME":
            return (
                <div className={values_plain}>
                    <div
                        className={view_answers}
                        onClick={
                            isAvailableResult
                                ? selectTextQuestionUuid
                                : () => {}
                        }
                    >
                        {isAvailableResult
                            ? plainTitle
                            : "No available answers"}
                    </div>
                </div>
            );
        default:
            return (
                <div className={values_plain}>
                    <div className={plain_title}>{plainTitle}</div>
                    {popularAnswerTitle}
                </div>
            );
    }
};
