import React from "react";
import { observer } from "mobx-react";
import {
    EventAlertsDisplay,
    BaseSurvey,
    SurveyEvent,
    SurveyEventAlert,
} from "@difftone/types";

import { alertsStore, wizardDisplayStore } from "@difftone/stores";
import {
    addWizardCopySurveyEvent,
    addWizardSurveyEvent,
    debounceUpsertSurveyToApi,
    deleteToUpdateWizardCopySurveyEvent,
    deleteToUpdateWizardSurveyEventWithoutReset,
    finalizeAndSetAlertCopyToStore,
    getAlertButtonsFactory,
    getNewEmptySurveyEventAlert,
    setSelectedEventAlert,
    updateSurveyWizardCopy,
} from "@difftone/actions";
import { getUserEmail } from "@difftone/procedures";

import reminderBellIcon from "@difftone/assets/bell-reminder-button.svg";

import {
    button_container,
    reminder_button,
} from "./add-reminder-button.module.css";

export type AddReminderButtonProps = {
    survey: BaseSurvey;
    surveyEvent: SurveyEvent;
};

export const AddReminderButton = observer((props: AddReminderButtonProps) => {
    const { survey, surveyEvent } = props;
    const { isEditableSurvey } = wizardDisplayStore;

    //TODO: move to actions
    //https://app.asana.com/0/1201346022025578/1202814583243835/f
    const produceNewSavedAlert = () => {
        const surveyEventCopy: SurveyEvent = JSON.parse(
            JSON.stringify(surveyEvent)
        );

        const alertsLength = surveyEventCopy.event_alerts.filter((alertUUID) =>
            isEditableSurvey
                ? !alertsStore.getSurveyAlertCopyByUuid(alertUUID)?.alert_type
                : wizardDisplayStore.getSurveyCopyAlertCopyByUuid(alertUUID)
                      ?.alert_type
        ).length;

        isEditableSurvey
            ? deleteToUpdateWizardSurveyEventWithoutReset(surveyEvent)
            : deleteToUpdateWizardCopySurveyEvent(surveyEvent);
        const thisEventAlert: SurveyEventAlert = getNewEmptySurveyEventAlert(
            surveyEventCopy,
            [getUserEmail()]
        );

        thisEventAlert.buttons = getAlertButtonsFactory(
            thisEventAlert,
            survey.uuid
        );

        if (isEditableSurvey) {
            alertsStore.debounceInsertAlertToStore(thisEventAlert);
        } else {
            finalizeAndSetAlertCopyToStore(thisEventAlert, survey, surveyEvent);
        }

        surveyEventCopy.event_alerts.push(thisEventAlert.uuid);

        isEditableSurvey
            ? addWizardSurveyEvent(surveyEventCopy)
            : addWizardCopySurveyEvent(surveyEventCopy);

        const eventToDisplay: EventAlertsDisplay = {
            alertNumber: alertsLength + 1,
            eventAlertUUID: thisEventAlert.uuid,
        };

        setSelectedEventAlert(eventToDisplay);

        isEditableSurvey
            ? debounceUpsertSurveyToApi()
            : updateSurveyWizardCopy();
    };

    return (
        <div className={button_container}>
            <button onClick={produceNewSavedAlert} className={reminder_button}>
                <img src={reminderBellIcon} alt="bell" />
                <span>Add Reminder</span>
            </button>
        </div>
    );
});
