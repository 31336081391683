import { makeAutoObservable, observable, ObservableMap } from "mobx";
import { BaseSurvey, UUID } from "@difftone/types";
import { fetchAllArchivedSurveys } from "./archive-internal-actions";

class ArchiveStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _isRequesting = false;

    get isRequesting() {
        return this._isRequesting;
    }

    set isRequesting(bool: boolean) {
        this._isRequesting = bool;
    }

    private _isStoreInitialized = false;

    get isStoreInitialized() {
        return this._isStoreInitialized;
    }

    set isStoreInitialized(bool) {
        this._isStoreInitialized = bool;
    }

    private _archivedSurveysMap: ObservableMap<string, BaseSurvey | null> =
        observable.map({});

    public getArchivedSurveysMap() {
        return this._archivedSurveysMap;
    }

    public setArchivedSurveyToMap(
        surveyUUID: UUID,
        newSurvey: BaseSurvey | null
    ) {
        this._archivedSurveysMap.merge({ [surveyUUID]: newSurvey });
    }

    get archivedSurveysList(): BaseSurvey[] {
        const _archivedSurveysList = Object.values(
            this._archivedSurveysMap
        ).filter((value) => !!value && typeof value === "object");

        if (!this.isStoreInitialized && !!localStorage.getItem("authData")) {
            this.init();
        }
        return _archivedSurveysList;
    }

    public removeUnarchivedSurveys = () => {
        this.archivedSurveysList
            .filter((survey) => !survey.archived)
            .forEach((survey: BaseSurvey) =>
                this._archivedSurveysMap.delete(survey.uuid)
            );
    };

    public removeDeletedSurveys = () => {
        this.archivedSurveysList
            .filter((survey) => survey.deleted)
            .forEach((survey: BaseSurvey) =>
                this._archivedSurveysMap.delete(survey.uuid)
            );
    };

    public addArchivedSurvey = (survey: BaseSurvey) => {
        this._archivedSurveysMap.merge({ [survey.uuid]: survey });
    };

    public init = async () => {
        this._isRequesting = true;

        await fetchAllArchivedSurveys();

        this._isRequesting = false;
    };

    public clearStore = () => {
        this._isRequesting = false;
        this._isStoreInitialized = false;
    };
}

const archiveStore = new ArchiveStore();

export { archiveStore };
