import React from "react";
import { observer } from "mobx-react";

import { EmailAddress, SurveyEventAlert, SurveyEvent } from "@difftone/types";
import {
    addMultipleMailingListItems,
    removeMailingListItem,
} from "@difftone/actions";
import { MailingListProducer } from "@difftone/shared-components";

import recipientsIcon from "@difftone/assets/reminder-recipients-icon.svg";
import {
    recipients_wrapper,
    recipients_title,
    recipients_counter,
    mailing_list_wrapper,
    mailing_producer_input_override,
} from "./reminder-recipients.module.css";
import {
    getAlertOffsetInMilliSeconds,
    totalTimeInMiliSecondsFromString,
} from "@difftone/time-utils";

const LIST_EMAILS_BOX_HEIGHT = 150;

export type ReminderRecipientsProps = {
    eventAlert: SurveyEventAlert;
    surveyEvent: SurveyEvent;
};

export const ReminderRecipients = observer((props: ReminderRecipientsProps) => {
    const { eventAlert, surveyEvent } = props;

    const alertDate =
        totalTimeInMiliSecondsFromString(
            surveyEvent.date_utc!,
            surveyEvent.day_utc_time!
        ) + getAlertOffsetInMilliSeconds(eventAlert);

    const isPastEventAlert = alertDate < Date.now();

    const addMailingItems = (emailsToAdd: EmailAddress[]) => {
        addMultipleMailingListItems(emailsToAdd, eventAlert);
    };

    const removeMailingItem = (emailToDelete: EmailAddress) => {
        removeMailingListItem(emailToDelete, eventAlert);
    };

    return (
        <div className={recipients_wrapper}>
            <div className={recipients_title}>
                <img src={recipientsIcon} alt="recipients-icon" />
                <div className={recipients_counter}>
                    Recipients: {`${eventAlert.send_to.length}`}
                </div>
            </div>
            <div className={mailing_list_wrapper}>
                <MailingListProducer
                    mailingListLocation="reminder"
                    showGroups
                    inEditMode
                    onEnter={addMailingItems}
                    onDelete={removeMailingItem}
                    disabled={isPastEventAlert}
                    readOnlyMalingList={isPastEventAlert}
                    mailingList={eventAlert.send_to}
                    styleOptions={{
                        listBoxHeight: LIST_EMAILS_BOX_HEIGHT,
                        validationTooltipCustomStyle: { top: "40%" },
                        inputStyleOverride: mailing_producer_input_override,
                    }}
                />
            </div>
        </div>
    );
});
