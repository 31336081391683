import React from "react";
import { observer } from "mobx-react";
import {
    inboxStore,
    publicProfileStore,
    sharedSurveyStore,
    surveyStore,
} from "@difftone/stores";
import { Spinner, SpinnerOrTimeoutMessage } from "@difftone/shared-components";
import {
    inboxSpinnerOff,
    inboxSpinnerOn,
    getUniquePublicProfilesUUIDs,
} from "@difftone/actions";
import {
    getInboxActiveFilterFromUrl,
    allMySurveysCount,
    exampleSurveysCount,
    imTheInitiatorCount,
    imTheRespondentCount,
    needsMyAttentionCount,
    notCompletedCount,
    filteredSurveys,
    ongoingSurveysCount,
} from "@difftone/reducers";

import { MobileTableRow } from "./components";

import { surveys_table } from "./mobile-survey-table.module.css";

export const MobileSurveyTable = observer(() => {
    const { surveysList } = surveyStore;
    const { surveysTableSpinnerActive } = inboxStore;
    const publicProfileStoreInitialize = publicProfileStore.storeInitialize;
    const publicProfileStartInitialization =
        publicProfileStore.startInitialization;
    const surveyStoreStoreInitialize = surveyStore.storeInitialize;
    const sortedAndFilteredSurveys = filteredSurveys();

    const surveysCounterByActiveInboxFilter: {
        [activeFilter: string]: Function;
    } = {
        ALL: allMySurveysCount,
        RESPONDENT: imTheRespondentCount,
        ONGOING: ongoingSurveysCount,
        INITIATOR: imTheInitiatorCount,
        NEEDS_ATTENTION: needsMyAttentionCount,
        DRAFT: exampleSurveysCount,
        NOT_COMPLETED: notCompletedCount,
        SHARED_WITH_ME: sharedSurveyStore.readySharedSurveysCount,
    };

    const surveysByActiveFilterCount =
        surveysCounterByActiveInboxFilter[getInboxActiveFilterFromUrl()!];

    if (!surveyStoreStoreInitialize) {
        inboxSpinnerOn();
        return <Spinner size="MEDIUM" />;
    }

    if (surveyStoreStoreInitialize) {
        if (!publicProfileStartInitialization) {
            const publicProfileUUIDs = getUniquePublicProfilesUUIDs();

            publicProfileStore.init(publicProfileUUIDs);
        }
    }

    if (!publicProfileStoreInitialize) {
        inboxSpinnerOn();
        return <Spinner />;
    }
    if (surveyStoreStoreInitialize && publicProfileStartInitialization) {
        inboxSpinnerOff();
    }

    return (
        <div className={surveys_table}>
            {surveysByActiveFilterCount(surveysList) > 0 ? (
                sortedAndFilteredSurveys.map((displaySurvey) => (
                    <MobileTableRow
                        key={displaySurvey.display_surveyUUID}
                        displaySurvey={displaySurvey}
                    />
                ))
            ) : (
                <SpinnerOrTimeoutMessage
                    spinnerSize="MEDIUM"
                    spinnerFlag={surveysTableSpinnerActive}
                    message="No Surveys To Show"
                />
            )}
        </div>
    );
});
