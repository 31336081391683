import { makeAutoObservable } from "mobx";

class CloudsAlertStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _isVisible: boolean = false;
    get isVisible() {
        return this._isVisible;
    }
    set isVisible(visiblityState: boolean) {
        this._isVisible = visiblityState;
    }

    private _message: string = "";
    get message() {
        return this._message;
    }
    set message(newMessage: string) {
        this._message = newMessage;
    }

    public clearStore = () => {
        this._isVisible = false;
        this._message = "";
    };
}

export const cloudsAlertStore = new CloudsAlertStore();
