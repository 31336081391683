import React from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import { UUID } from "@difftone/types";
import { categoriesDisplayStore } from "@difftone/stores";
import {
    addCategoryUUIDToDeleteList,
    removeCustomCategoryByUUID,
} from "@difftone/actions";
import removeItem from "@difftone/assets/remove-from-mailing-list.svg";

import {
    categories_list_item,
    categories_list_item_undeletable,
    categories_list_item_icon,
} from "./category-list-item.module.css";

export type CategoryListItemProps = {
    name: string;
    uuid: UUID;
    isDeletable?: boolean;
};

export const CategoryListItem = observer((props: CategoryListItemProps) => {
    const { name, uuid, isDeletable } = props;

    const customCategories = categoriesDisplayStore.customCategories;

    const onDelete = () => {
        const isCustomCategory = customCategories.find(
            (category) => category.uuid === uuid
        );

        if (isCustomCategory) {
            removeCustomCategoryByUUID(uuid);
        } else {
            addCategoryUUIDToDeleteList(uuid);
        }
    };

    return (
        <div
            className={clsx(categories_list_item, {
                [categories_list_item_undeletable]: !isDeletable,
            })}
        >
            <span>{name}</span>

            {isDeletable && (
                <img
                    onClick={onDelete}
                    className={categories_list_item_icon}
                    src={removeItem}
                    alt="remove"
                />
            )}
        </div>
    );
});
