import React from "react";

import {
    no_data_to_show_wrapper,
    no_data_to_show_icon,
    no_data_to_show_text,
} from "./no-data-to-show.module.css";

export type NoDataToShowProps = {};

export const NoDataToShow = (props: NoDataToShowProps) => {
    return (
        <div className={no_data_to_show_wrapper}>
            <div className={no_data_to_show_icon}>{`(^ - ^)`}</div>
            <div className={no_data_to_show_text}>No data to show</div>
        </div>
    );
};
