import React from "react";

import { scrollable_year_input_headers } from "./scrollable-year-header.module.css";

export type ScrollableYearHeaderProps = {};

export const ScrollableYearHeader = () => {
    return (
        <div className={scrollable_year_input_headers}>
            <div>Year</div>
        </div>
    );
};
