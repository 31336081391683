import {
    Role,
    AnswerTypeName,
    AccordionTitles,
    SimpleSurveyStatus,
    OngoingSurveyStatus,
} from "@difftone/types";

export const accordionTitles: { [x: string]: AccordionTitles } = {
    EVENTS: "TIME_LINE",
    DETAILS: "DETAILS",
    QUESTIONS: "QUESTIONS",
    TIME_LINE: "TIME_LINE",
};
export const accordionSection: { [x: string]: string } = {
    Details: "Details",
    Dates: "Dates",
    Features: "Features",
    Questions: "Questions",
};

export const DISPLAY_SURVEY_ROLES: Record<Role, Role> = {
    INITIATOR: "INITIATOR",
    RESPONDER: "RESPONDER",
    ADMIN: "ADMIN",
};

export const SURVEY_STATUS: Record<
    SimpleSurveyStatus | OngoingSurveyStatus,
    SimpleSurveyStatus | OngoingSurveyStatus
> = {
    DRAFT: "DRAFT",
    PAUSED: "PAUSED",
    READY: "READY",
    ACTIVE: "ACTIVE",
};

export const SURVEY_QUESTION_ANSWER: Record<AnswerTypeName, AnswerTypeName> = {
    DATE: "DATE",
    SINGLE_CHOICE: "SINGLE_CHOICE",
    MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
    RATING: "RATING",
    TIME: "TIME",
    SHORT_TEXT: "SHORT_TEXT",
    LONG_TEXT: "LONG_TEXT",
    BOOKING: "BOOKING",
};

export const surveyPurpose: { [x: string]: string } = {
    DISCUSSION: "Evoke Discussion",
    FEEDBACK: "Feedback",
    ENGAGEMENT: "Engagement",
    BOOKING: "Booking",
    OTHER: "Other",
};

export const surveyAnonimity: { [x: string]: string } = {
    NONE: "None",
    LOW: "Low",
    MEDIUM: "Medium",
    HIGH: "High",
};
