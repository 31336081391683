export const CATEGORIES_QUESTIONS_TOGGLE_BUTTON_TOOLTIP =
    "Toggle between categories & questions";
export const WIZARD_DIFFERENT_RATING_TOOLTIP =
    "For the best analysis, use the same rating scale throughout your survey, and especially in a given category";

export const WIZARD_FILTER_TOOLTIP_INFO =
    "Filters will appear as regular & required questions in the survey, ordered before the rest of the questions. They refer to a person's attributes, such as age, tenure, department etc., are all required and single choice questions and can be compared and filtered by in the results page.";

export const WIZARD_REQUIRED_TOOLTIP_INFO =
    "Participants will not be able to complete the survey without filling this question";

export const ONGOING_SURVEY_TOOLTIP_INFO = `An ongoing survey (e.g. exit, recruitment etc.) can be sent to one or more participants each time using the "ongoing surveys" filter in the inbox.
                        In ongoing surveys, Participants and Timeline boxes are deactivated.`;
