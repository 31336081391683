import React from "react";
import { observer } from "mobx-react";

import {
    Header,
    MobileHeader,
    LeftMenu,
    ClientWidthRenderPicker,
    Spinner,
    DialogBackground,
    AddBoxShadow,
    PreviewSurvey,
} from "@difftone/shared-components";
import {
    alertsStore,
    leftMenuStore,
    navigationStore,
    surveyResponseStore,
    surveyStore,
    sharedResultStore,
    previewSurveyStore,
    inboxStore,
    organizationStore,
} from "@difftone/stores";
import {
    getInboxActiveFilterFromUrl,
    getQueryParamsFromUrl,
    getSelectedOrganizationUUID,
    getOrganizations,
    getSurveyByUuid,
} from "@difftone/reducers";
import {
    sendSurvey,
    setInboxActiveFilterFlagAndRedirect,
    setLocation,
    showDifftoneAlert,
    showDifftoneAlertWithSharePanel,
} from "@difftone/actions";
import {
    ACTIVATE_FROM_EMAIL_PARAM,
    ACTIVATION_URL_PARM,
    constantAlertVariants,
    MOBILE_WIDTH_BREAKPOINT,
    NO_MOBILE_PLACEHOLDER_TEXT,
    PAGE_URLS,
} from "@difftone/constants";

import {
    InboxSurveysTable,
    MobileSurveyTable,
    MobileFloatingAddButton,
    LeftMenuChildren,
    InboxSharedSurveysTable,
    InboxTemplatesTable,
    SharedSurvey,
    DuplicateSurvey,
    MobileSharedSurveyTable,
    InboxDraftsTable,
    ViewsSharedWithMeSection,
    InboxArchivedSurveysTable,
} from "./components";

import {
    inbox,
    inbox_body,
    hidden_overflow,
    survey_table_wrapper,
    mobile_survey_table_wrapper,
    inbox_body_mobile_container,
    survey_table_wrapper_left_menu_closed,
    survey_table_wrapper_notice,
} from "./inbox.module.css";
import { PopupNewParticipants } from "./components/inbox-surveys-table/components";

const SHARED_SECTIONS_ROUTES = {
    sharedSurveys: "sharedsurveys",
    sharedViewsWithMe: "sharedviewswithme",
    viewsISharedWith: "viewsIsharedwith",
    templates: "templates",
    archived: "archived",
};

export type InboxProp = {};

export const Inbox = observer(() => {
    const { isOpen } = leftMenuStore;
    const { inboxLastActiveFilter } = inboxStore;
    const { currentPage, currentSearch } = navigationStore;
    const sharedSurveyPage = currentPage.split("/")[2];
    const surveyLocation = currentPage.split("/")[3];
    const { inPreviewMode } = previewSurveyStore;

    if (getInboxActiveFilterFromUrl() === null) {
        setInboxActiveFilterFlagAndRedirect(inboxLastActiveFilter);
        return <Spinner size="LARGE" />;
    }

    if (!sharedResultStore.isInitializingStarted) {
        sharedResultStore.init();
        return <Spinner size="LARGE" />;
    }

    if (sharedResultStore.isStoreInitializing) {
        return <Spinner size="LARGE" />;
    }

    if (!surveyResponseStore.startInitialization) {
        surveyResponseStore.initAllSurveyResponses();
    }

    if (!surveyStore.startInitialization) {
        surveyStore.init();
        return <Spinner size="LARGE" />;
    }

    if (!surveyStore.storeInitialize) {
        return <Spinner size="LARGE" />;
    }

    if (!alertsStore.startInitialization) {
        alertsStore.init();
    }

    if (!alertsStore.storeInitialize) {
        return <Spinner size="LARGE" />;
    }

    if (currentSearch.includes(ACTIVATE_FROM_EMAIL_PARAM)) {
        const surveyToActivateUUID = getQueryParamsFromUrl().get(
            ACTIVATE_FROM_EMAIL_PARAM
        );
        if (surveyToActivateUUID) {
            const surveyToActivate = getSurveyByUuid(surveyToActivateUUID);
            if (surveyToActivate) {
                sendSurvey(surveyToActivate);
                showDifftoneAlertWithSharePanel(
                    constantAlertVariants.survey_sent.text,
                    constantAlertVariants.survey_sent.variant,
                    surveyToActivate.uuid,
                    "Additionally you can send the survey via",
                    surveyToActivate.is_open_survey
                );
            } else {
                showDifftoneAlert(
                    constantAlertVariants.survey_id_failure.text,
                    constantAlertVariants.survey_id_failure.variant
                );
            }
        }
    }

    if (currentSearch) {
        const activatedSurveyUuid =
            getQueryParamsFromUrl().get(ACTIVATION_URL_PARM);

        const activatedSurvey = getSurveyByUuid(activatedSurveyUuid!);

        activatedSurveyUuid &&
            showDifftoneAlertWithSharePanel(
                constantAlertVariants.survey_activation_success.text,
                constantAlertVariants.survey_activation_success.variant,
                activatedSurveyUuid,
                "Additionally you can send the survey via",
                activatedSurvey?.is_open_survey
            );
        setLocation(PAGE_URLS.INBOX_ALL);
    }

    const selectedOrganization = getSelectedOrganizationUUID();
    const organizationsList = getOrganizations();

    let _organization;

    if (selectedOrganization) {
        _organization =
            organizationStore.getOrganizationByUuid(selectedOrganization);
    }

    if (window.innerWidth <= MOBILE_WIDTH_BREAKPOINT) {
        showDifftoneAlert(
            constantAlertVariants.disabled_inbox_mobile_mode.text,
            constantAlertVariants.disabled_inbox_mobile_mode.variant
        );
    }

    //Need to re render on url changes use key
    return (
        <div key={window.location.pathname} className={inbox}>
            <ClientWidthRenderPicker
                desktopComponent={
                    <Header
                        organization={_organization}
                        organizationsList={organizationsList}
                    />
                }
                mobileComponent={<MobileHeader />}
            />
            <div className={inbox_body_mobile_container}>
                <div
                    className={
                        !isOpen
                            ? [inbox_body, hidden_overflow].join(" ")
                            : inbox_body
                    }
                >
                    <ClientWidthRenderPicker
                        desktopComponent={
                            <LeftMenu>
                                <LeftMenuChildren />
                            </LeftMenu>
                        }
                        mobileComponent={null}
                    />

                    <ClientWidthRenderPicker
                        desktopComponent={
                            <div
                                className={
                                    isOpen
                                        ? [
                                              survey_table_wrapper,
                                              survey_table_wrapper_left_menu_closed,
                                          ].join(" ")
                                        : survey_table_wrapper
                                }
                            >
                                {Object.values(SHARED_SECTIONS_ROUTES).includes(
                                    sharedSurveyPage
                                ) ? (
                                    <>
                                        {sharedSurveyPage ===
                                            "sharedsurveys" && (
                                            <InboxSharedSurveysTable />
                                        )}
                                        {sharedSurveyPage ===
                                            "sharedviewswithme" && (
                                            <ViewsSharedWithMeSection />
                                        )}
                                        {sharedSurveyPage === "templates" && (
                                            <InboxTemplatesTable />
                                        )}
                                        {sharedSurveyPage === "archived" && (
                                            <InboxArchivedSurveysTable />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {currentPage.includes("/draft") ? (
                                            <InboxDraftsTable />
                                        ) : (
                                            <InboxSurveysTable />
                                        )}
                                    </>
                                )}
                            </div>
                        }
                        mobileComponent={
                            <div className={mobile_survey_table_wrapper}>
                                <p className={survey_table_wrapper_notice}>
                                    {NO_MOBILE_PLACEHOLDER_TEXT}
                                </p>
                            </div>
                        }
                    />
                </div>
            </div>
            {surveyLocation === "share" ? <SharedSurvey /> : null}
            {surveyLocation === "duplicate" ? <DuplicateSurvey /> : null}
            {inPreviewMode ? (
                <DialogBackground>
                    <AddBoxShadow>
                        <PreviewSurvey className="" />
                    </AddBoxShadow>
                </DialogBackground>
            ) : null}
            {surveyLocation === "sendongoing" ? <PopupNewParticipants /> : null}
            <ClientWidthRenderPicker
                mobileComponent={<MobileFloatingAddButton />}
                desktopComponent={null}
            />
        </div>
    );
});
