import React, { useEffect } from "react";
import { observer } from "mobx-react";

import { SimpleSurvey, SurveyEvent, SurveyEventAlert } from "@difftone/types";
import { wizardDisplayStore, alertsStore } from "@difftone/stores";
import {
    AddBoxShadow,
    Spinner,
    BellReminderIcon,
} from "@difftone/shared-components";
import { setSelectedEventAlert, showDifftoneAlert } from "@difftone/actions";
import { setItemVisibleInView } from "@difftone/procedures";
import closeIcon from "@difftone/assets/close-datepicker-white.svg";
import {
    AlertMessageInput,
    ReminderInfo,
    ReminderRecipients,
    ReminderTimeSetter,
} from "./components";
import {
    reminder_popup,
    reminder_header,
    header_title,
    close_icon,
    reminder_container,
    bell_reminder_icon_scope,
} from "./reminder-details-popup.module.css";
import { SurveyEventBell } from "../header/components";

export type ReminderDetailsPopupProps = {
    survey: SimpleSurvey;
    onChangeSurveyEventAlert?: (surveyEventAlert: SurveyEventAlert) => void;
};

const COLOR_WHITE = "WHITE";

export const ReminderDetailsPopup = observer(
    (props: ReminderDetailsPopupProps) => {
        const { selectedDisplayEventAlert, isEditableSurvey } =
            wizardDisplayStore;
        const { survey, onChangeSurveyEventAlert } = props;

        if (!selectedDisplayEventAlert) {
            //TODO: move message to constants
            showDifftoneAlert(
                "There is no survey event alert to display. Please selected one",
                "FAILURE"
            );
            return null;
        }

        const eventAlert: SurveyEventAlert | null | undefined = isEditableSurvey
            ? alertsStore.getSurveyAlertCopyByUuid(
                  selectedDisplayEventAlert.eventAlertUUID
              )
            : wizardDisplayStore.getSurveyCopyAlertCopyByUuid(
                  selectedDisplayEventAlert.eventAlertUUID
              );

        if (eventAlert === undefined) {
            return <Spinner size="MEDIUM" />;
        }

        if (!eventAlert) {
            showDifftoneAlert("Could not find event alert", "FAILURE");
            setSelectedEventAlert(null);
            return null;
        }

        const selectedSurveyEvent = survey.survey_events.find(
            (surveyEvent: SurveyEvent) =>
                surveyEvent.uuid === eventAlert.event_UUID
        );

        if (!selectedSurveyEvent) {
            //TODO: move message to constants
            showDifftoneAlert(
                "There is no survey event for this alert",
                "FAILURE"
            );
            return null;
        }

        const closeEventAlertPopup = () => {
            setSelectedEventAlert(null);
        };

        //UseEffect for reminder popup if popup is open and the popup not in full view it will scroll
        //to full popup view
        useEffect(() => {
            const wrapperItem = document.querySelector(
                `div[data-scroll-element="accordion-wrapper"]`
            ) as HTMLDivElement;

            const selectedElement = document.querySelector(
                `div[data-wizard-reminder-popup]`
            ) as HTMLDivElement;
            setTimeout(() => {
                setItemVisibleInView(wrapperItem, selectedElement);
            });
        }, []);

        return (
            <AddBoxShadow>
                <div className={reminder_popup} data-wizard-reminder-popup>
                    <div className={reminder_header}>
                        <div className={header_title}>
                            <BellReminderIcon
                                className={bell_reminder_icon_scope}
                                color={COLOR_WHITE}
                            />
                            <span>
                                {`Reminder ${selectedDisplayEventAlert.alertNumber}`}
                            </span>
                        </div>
                        <img
                            onClick={closeEventAlertPopup}
                            className={close_icon}
                            src={closeIcon}
                            alt="close"
                        />
                    </div>
                    <div className={reminder_container}>
                        <ReminderInfo survey={survey} />
                        <ReminderTimeSetter
                            eventAlert={eventAlert}
                            onChangeSurveyEventAlert={onChangeSurveyEventAlert}
                            surveyEvent={selectedSurveyEvent}
                        />
                        <ReminderRecipients
                            eventAlert={eventAlert}
                            surveyEvent={selectedSurveyEvent}
                        />
                        <AlertMessageInput
                            eventAlert={eventAlert}
                            onChangeSurveyEventAlert={onChangeSurveyEventAlert}
                            surveyEvent={selectedSurveyEvent}
                        />
                    </div>
                </div>
            </AddBoxShadow>
        );
    }
);
