import React from "react";

import { rectangle_label } from "./rectangle-label.module.css";

export type rectangleLabelProps = {
    color: string;
};

export const RectangleLabel = (props: rectangleLabelProps) => {
    const { color } = props;
    return (
        <div
            className={rectangle_label}
            style={{ backgroundColor: color }}
        ></div>
    );
};
