import React, { SVGAttributes } from "react";

export const DeleteIcon = (props: SVGAttributes<HTMLOrSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 7 6"
        {...props}
    >
        <path
            fill="#7FA6B7"
            d="M6.367.138a.453.453 0 00-.642 0L3.5 2.358 1.275.133a.453.453 0 10-.642.642L2.858 3 .633 5.225a.453.453 0 10.642.642L3.5 3.642l2.225 2.225a.453.453 0 10.642-.642L4.142 3 6.367.775a.456.456 0 000-.637z"
        />
    </svg>
);
