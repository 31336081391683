import {
    MultipleChoiceQuestion,
    Question,
    QuestionsDuplicationValidationType,
    ValidationResult,
    ValidationType,
} from "@difftone/types";

//MultipleChoiceQuestion
export const validateMultipleChoiceQuestion = (
    multipleChoiceQuestion: Question<MultipleChoiceQuestion>
): ValidationResult<ValidationType>[] => {
    const result: ValidationResult<ValidationType>[] = [];
    result.push(...validateChoiceOptions(multipleChoiceQuestion));

    return result;
};

const validateChoiceOptions = (
    multipleChoiceQuestion: Question<MultipleChoiceQuestion>
): ValidationResult<QuestionsDuplicationValidationType>[] => {
    try {
        const optionsAsMap: { [x: string]: number[] } = {};
        multipleChoiceQuestion.content.choice_options.forEach(
            (option, index) => {
                if (typeof option !== "string") {
                    throw Error(
                        `[Difftone]:: validateMultipleChoiceOptions option should be string.`
                    );
                }

                if (!optionsAsMap[option]) {
                    optionsAsMap[option] = [index];
                } else {
                    optionsAsMap[option].push(index);
                }
            }
        );

        for (const key in optionsAsMap) {
            if (optionsAsMap[key].length === 1) {
                delete optionsAsMap[key];
            }
        }

        return [
            {
                is_valid: Object.keys(optionsAsMap).length > 0 ? false : true,
                validation_error_code: "QUESTION_OPTION_NAMES_DUPLICATION",
                validation_error_type: {
                    duplication_indexes: optionsAsMap,
                    question_id: multipleChoiceQuestion.uuid,
                },
            },
        ];
    } catch (error) {
        console.error(`error:: ${(error as Error).message}`);
        return [
            {
                is_valid: false,
                validation_error_code: "EXCEPTION_DURING_VALIDATIONS",
                //TODO: fix type definition
                //@ts-ignore
                validation_error_type: error as Error,
            },
        ];
    }
};
