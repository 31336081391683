import { surveyStore } from "@difftone/stores";
import {
    QuestionInvalidNameValidationType,
    QuestionInvalidNicknameValidationType,
    QuestionsDuplicationValidationType,
    BaseSurvey,
    SurveyEventInvalidTitleValidationType,
    SurveyEventsDateValidationType,
    SurveyEventTitleDuplicationValidationType,
    ValidationResult,
    ValidationType,
    SurveyClasses,
} from "@difftone/types";
import {
    getValidationErrosByErrorCode,
    setFocusedWizardInputCard,
    validateWizardQuestions,
    validateWizardDates,
    setSurveyEventToDisplay,
    accordionSectionsController,
} from "@difftone/actions";
import { getUserUuid } from "@difftone/procedures";
import { getValidationErrorsFromStoreByUUID } from "@difftone/reducers";
import { frontendValidations } from "@difftone/frontend-common/frontend-validations";

export const focusFirstInvalidQuestion = (
    questionValidationError: ValidationResult<ValidationType>,
    surveyQuestionsUUIDs: string[]
) => {
    let invalidQuestionUUID: string | undefined = undefined;
    let invalidQuestionAsHTMLElement: HTMLElement | null = null;

    switch (questionValidationError.validation_error_code) {
        case "QUESTION_NAME_VALIDATION":
            invalidQuestionUUID = (
                questionValidationError as ValidationResult<QuestionInvalidNameValidationType>
            ).validation_error_type?.question_id;
            invalidQuestionAsHTMLElement = document.querySelector(
                `div[data-focus-wizard-input-card-id="${invalidQuestionUUID}"]`
            );
            accordionSectionsController("QUESTIONS");
            setFocusedWizardInputCard(
                invalidQuestionAsHTMLElement as HTMLElement
            );
            break;
        case "QUESTION_NAME_DUPLICATION_VALIDATION":
        case "QUESTION_EMPTY_NICKNAME_VALIDATION":
            invalidQuestionUUID = (
                questionValidationError as ValidationResult<QuestionInvalidNameValidationType>
            ).validation_error_type?.question_id;
            invalidQuestionAsHTMLElement = document.querySelector(
                `div[data-focus-wizard-input-card-id="${invalidQuestionUUID}"]`
            );
            accordionSectionsController("QUESTIONS");
            setFocusedWizardInputCard(
                invalidQuestionAsHTMLElement as HTMLElement
            );
            break;
        case "QUESTION_NICKNAME_DUPLICATION_VALIDATION":
            invalidQuestionUUID = (
                questionValidationError as ValidationResult<QuestionInvalidNicknameValidationType>
            ).validation_error_type?.question_id;
            invalidQuestionAsHTMLElement = document.querySelector(
                `div[data-focus-wizard-input-card-id="${invalidQuestionUUID}"]`
            );
            accordionSectionsController("QUESTIONS");
            setFocusedWizardInputCard(
                invalidQuestionAsHTMLElement as HTMLElement
            );
            break;
        case "QUESTION_OPTION_NAMES_DUPLICATION":
            invalidQuestionUUID = (
                questionValidationError as ValidationResult<QuestionsDuplicationValidationType>
            ).validation_error_type?.question_id;
            invalidQuestionAsHTMLElement = document.querySelector(
                `div[data-focus-wizard-input-card-id="${invalidQuestionUUID}"]`
            );
            accordionSectionsController("QUESTIONS");
            setFocusedWizardInputCard(
                invalidQuestionAsHTMLElement as HTMLElement
            );

            break;
        default:
            break;
    }
};

export const focusFirstInvalidEvent = (
    eventValidationError: ValidationResult<ValidationType>
) => {
    let invalidEventUUID: string | undefined = undefined;

    switch (eventValidationError.validation_error_code) {
        case "SURVEY_EVENT_ILLEGAL_DATE":
            invalidEventUUID = (
                eventValidationError as ValidationResult<SurveyEventsDateValidationType>
            ).validation_error_type?.invalid_events_dates_uuids.shift();

            accordionSectionsController("TIME_LINE");
            setSurveyEventToDisplay(invalidEventUUID as string);
            break;
        case "SURVEY_EVENT_NAME_VALIDATION":
            invalidEventUUID = (
                eventValidationError as ValidationResult<SurveyEventInvalidTitleValidationType>
            ).validation_error_type?.event_uuid;
            accordionSectionsController("TIME_LINE");

            setSurveyEventToDisplay(invalidEventUUID as string);
            break;
        case "SURVEY_EVENT_NAME_DUPLICATION_VALIDATION":
            const invalidEventDuplicatedUUIDs = Object.values(
                (
                    eventValidationError as ValidationResult<SurveyEventTitleDuplicationValidationType>
                ).validation_error_type?.duplication_indexes!
            )[0];

            accordionSectionsController("TIME_LINE");
            setSurveyEventToDisplay(
                invalidEventDuplicatedUUIDs.pop() as string
            );
            break;
        default:
            break;
    }
};

export const navigateToSurveyFormValidationError = (survey: BaseSurvey) => {
    const surveyValidationResults = getValidationErrorsFromStoreByUUID(
        survey.uuid,
        survey
    );
    const surveyNameValidation = [
        ...getValidationErrosByErrorCode(
            "SURVEY_NAME_VALIDATION",
            surveyValidationResults
        ),
        ...getValidationErrosByErrorCode(
            "SURVEY_NAME_DUPLICATION",
            surveyValidationResults
        ),
    ];
    const surveyRespondentsValidation = getValidationErrosByErrorCode(
        "NUM_OF_PARTICIPANTS_VALIDATION",
        surveyValidationResults
    );
    const surveyQuestionsValidation = getValidationErrosByErrorCode(
        "NUM_OF_QUESTIONS_VALIDATION",
        surveyValidationResults
    );

    if (surveyNameValidation.length > 0) {
        accordionSectionsController("DETAILS");
        return;
    }

    if (surveyRespondentsValidation.length > 0) {
        accordionSectionsController("PARTICIPANTS");

        return;
    }

    if (surveyQuestionsValidation.length > 0) {
        accordionSectionsController("QUESTIONS");
        return;
    }
};

export const navigateToWizardValidationError = (survey: SurveyClasses) => {
    const { surveysList } = surveyStore;
    const { validateSurvey } = frontendValidations;

    const restOfSurveys = surveysList.filter(
        (_survey) =>
            _survey.initiator === getUserUuid() &&
            _survey.uuid !== survey.uuid &&
            _survey.status !== "DRAFT"
    );
    const discreetSurveyValidationResults = validateSurvey(
        survey,
        restOfSurveys
    );

    const surveyQuestionsUUIDs = survey.questions.map(
        (question) => question.uuid
    );

    const discreetSurveyValidationError = discreetSurveyValidationResults.find(
        (validation) => !validation.is_valid
    );

    if (discreetSurveyValidationError) {
        if (
            discreetSurveyValidationError.validation_error_code.includes(
                "EVENT"
            )
        ) {
            validateWizardDates(survey);
            setTimeout(() => {
                focusFirstInvalidEvent(discreetSurveyValidationError);
            }, 0);
            return;
        }

        if (
            discreetSurveyValidationError.validation_error_code.includes(
                "QUESTION"
            )
        ) {
            surveyQuestionsUUIDs.length && validateWizardQuestions(survey);
            setTimeout(() => {
                focusFirstInvalidQuestion(
                    discreetSurveyValidationError,
                    surveyQuestionsUUIDs
                );
            }, 0);

            return;
        }
    }

    navigateToSurveyFormValidationError(survey);
};
