import React, { useEffect } from "react";
import { observer } from "mobx-react";
import {
    Header,
    LeftMenu,
    MobileHeader,
    ClientWidthRenderPicker,
    Spinner,
} from "@difftone/shared-components";
import {
    scheduleSurveyPopupStore,
    categoriesStore,
    wizardDisplayStore,
    alertsStore,
    organizationStore,
} from "@difftone/stores";
import {
    showDifftoneAlert,
    setLocation,
    setWizardAccordionActiveSection,
    setSurveyEventToDisplay,
    copySelectedSurvey,
    clearSurveyCopyFromStore,
} from "@difftone/actions";
import {
    getSurveyForInitiatorBySurveyUuid,
    getSelectedOrganizationOrThrow,
    getSurveyUUIDFromUrl,
    isEditableSurveyByStatus,
    getSelectedOrganizationUUID,
    getOrganizations,
} from "@difftone/reducers";
import { SurveyClasses } from "@difftone/types";

import {
    INBOX_URL,
    MOBILE_WIDTH_BREAKPOINT,
    NO_MOBILE_PLACEHOLDER_TEXT,
} from "@difftone/constants";

import {
    SendSurveyButton,
    WizardLeftMenuQuestions,
    WizardContainer,
} from "./components";

import {
    survey_wizard,
    wizard_body,
    backdrop,
    wizard_left_menu_ul,
    wizard_mobile_notice,
} from "./survey-wizard.module.css";

export type SurveyWizardProp = {};

export const SurveyWizard = observer(() => {
    const { isOpen } = scheduleSurveyPopupStore;
    const { selectedDisplayEventAlert } = wizardDisplayStore;
    const surveyUUIDFromURL = getSurveyUUIDFromUrl();

    if (!surveyUUIDFromURL) {
        setLocation(INBOX_URL);
        throw Error("Something went wrong with survey reducer");
    }

    //[useEffect]:: operations performed after leaving wizard page
    useEffect(() => {
        return () => {
            setWizardAccordionActiveSection("DETAILS");
            setSurveyEventToDisplay(null);
            clearSurveyCopyFromStore();
        };
        //eslint-disable-next-line
    }, []);

    const survey = getSurveyForInitiatorBySurveyUuid(surveyUUIDFromURL);

    if (survey === undefined) {
        return <Spinner size="LARGE" />;
    }

    if (survey === null) {
        showDifftoneAlert(`Could not find survey`, "FAILURE");
        setLocation(INBOX_URL);
        return null;
    }

    if (!alertsStore.storeInitialize) {
        alertsStore.init();
        return <Spinner size="LARGE" />;
    }

    if (!categoriesStore.isStoreInitialized && !categoriesStore.isRequesting) {
        categoriesStore.init(
            getSelectedOrganizationOrThrow()
                ? getSelectedOrganizationOrThrow()!.uuid
                : null
        );

        return <Spinner />;
    }

    wizardDisplayStore.isEditableSurvey = isEditableSurveyByStatus(survey);

    if (!wizardDisplayStore.isEditableSurvey) {
        copySelectedSurvey(survey as SurveyClasses);
    }

    const isMobile = window.innerWidth <= MOBILE_WIDTH_BREAKPOINT;

    const selectedOrganization = getSelectedOrganizationUUID();
    const organizationsList = getOrganizations();

    let _organization;

    if (selectedOrganization) {
        _organization =
            organizationStore.getOrganizationByUuid(selectedOrganization);
    }

    return (
        <>
            <div className={survey_wizard}>
                <ClientWidthRenderPicker
                    mobileComponent={<MobileHeader />}
                    desktopComponent={
                        <Header
                            organization={_organization}
                            organizationsList={organizationsList}
                        />
                    }
                />
                {isMobile ? (
                    <p className={wizard_mobile_notice}>
                        {NO_MOBILE_PLACEHOLDER_TEXT}
                    </p>
                ) : (
                    <div className={wizard_body}>
                        <LeftMenu>
                            <ul className={wizard_left_menu_ul}>
                                <li data-title="QUESTIONS">
                                    <WizardLeftMenuQuestions
                                        isActiveSection={true}
                                    />
                                </li>
                            </ul>
                        </LeftMenu>
                        {!selectedDisplayEventAlert ? (
                            <SendSurveyButton />
                        ) : null}
                        <WizardContainer />
                    </div>
                )}
            </div>
            {isOpen ? <div className={backdrop}></div> : null}
        </>
    );
});
