import React, { useState, MouseEvent } from "react";
import { observer } from "mobx-react";

import { TableHeaderCell, FilterTypes } from "./components";

import {
    table_header,
    survey_name_class,
    status_class,
    initiator_class,
    issued_class,
    last_date_class,
    time_left_class,
    progress_class,
} from "./table-header.module.css";

export const TableHeader = observer(() => {
    const [activeFilter, setActiveFilter] = useState<FilterTypes | null>("END");

    const setFilter = (event: MouseEvent<HTMLDivElement>) => {
        const filterType = event.currentTarget.getAttribute(
            "data-filter"
        ) as FilterTypes;
        if (filterType) {
            setActiveFilter(filterType);
        }
    };

    return (
        <div className={table_header}>
            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "NAME"}
                filterType="NAME"
                text="Name"
                className={survey_name_class}
            />
            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "STATUS"}
                filterType="STATUS"
                text="Status"
                className={status_class}
            />
            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "INITIATOR"}
                filterType="INITIATOR"
                text="Initiator"
                className={initiator_class}
            />
            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "START"}
                filterType="START"
                text="Start"
                className={issued_class}
            />
            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "END"}
                filterType="END"
                text="End"
                className={last_date_class}
            />
            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "TIME_LEFT"}
                filterType="TIME_LEFT"
                text="Time Left"
                className={time_left_class}
            />
            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "PROGRESS"}
                filterType="PROGRESS"
                text="Progress"
                className={progress_class}
            />
        </div>
    );
});
