import { getUserEmail } from "@difftone/procedures";
import { organizationStore } from "@difftone/stores";
import { EmailAddress } from "@difftone/types";
import { getAdmins, getSettingsOrThrow } from "@difftone/reducers";

type EmailType = "PUBLIC" | "ORGANISATION";
type UserType = "ADMIN" | "INITIATOR" | "DEFAULT";

const ALERT_EMAIL_GROUPS = ["FILLED", "NOT_FILLED", "ALL"];

export const getUserPermissionsType = (): UserType => {
    const userEmail = getUserEmail();
    const sysAdmins = getAdmins();
    const settings = getSettingsOrThrow();
    const { mode, initiators } = settings;

    let userType: UserType = "DEFAULT";

    if (sysAdmins.includes(userEmail)) {
        userType = "ADMIN";
    } else if (initiators.includes(userEmail) || mode === "ALL") {
        userType = "INITIATOR";
    }

    return userType;
};

export const getUserEmailType = (): EmailType => {
    const userEmail = getUserEmail();
    const publicEmailList = organizationStore.getPublicDomains();
    const emailDomain = userEmail.split("@")[1];

    const publicEmailNames = publicEmailList.map((obj) => obj.name);

    const emailType: EmailType = publicEmailNames.includes(emailDomain)
        ? "PUBLIC"
        : "ORGANISATION";

    return emailType;
};

export const isEmailFromOrganisation = (email: EmailAddress) => {
    const domains = organizationStore.domains;
    const publicEmailList = organizationStore.getPublicDomains();
    const emailDomain = email.split("@")[1];

    if (publicEmailList.map((obj) => obj.name).includes(emailDomain)) {
        return true;
    }

    return domains.map((obj) => obj.name).includes(emailDomain);
};

export const isAlertEmailGroup = (alertEmailGroupe: string) => {
    return ALERT_EMAIL_GROUPS.includes(alertEmailGroupe);
};
