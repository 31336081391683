import React, { PropsWithChildren } from "react";
import { observer } from "mobx-react";
import { wizardStore } from "@difftone/stores";
import { ValidationWrapper } from "@difftone/shared-components";
import { warningMessages } from "@difftone/constants";
import { AccordionTitles, BaseSurvey } from "@difftone/types";
import {
    accordionSectionsController,
    getValidationErrosByErrorCode,
} from "@difftone/actions";
import {
    getSurveyFromStoreByURL,
    getValidationErrorsFromStoreByUUID,
} from "@difftone/reducers";

import downArrow from "@difftone/assets/down-arrow.svg";
import {
    accordion_item_focused,
    accordion_item,
    accordion_children,
    accordion_title,
    rotated_arrow,
    un_rotated_arrow,
    section_counter,
    not_active_item,
} from "./accordion-item.module.css";

export type AccordionItemProps = {
    accordionTitle: "Timeline" | "Participants" | null;
    selectedAccordionItem: AccordionTitles;
    isAccordionSectionOpen: boolean;
    sectionCounter?: number;
    survey?: BaseSurvey;
};

export const AccordionItem = observer(
    (props: PropsWithChildren<AccordionItemProps>) => {
        const {
            sectionCounter,
            accordionTitle,
            isAccordionSectionOpen,
            children,
            selectedAccordionItem,
            survey,
        } = props;
        const { activeAccordionSection } = wizardStore;

        const clientWidth = window.innerWidth;

        const _survey = survey || getSurveyFromStoreByURL();

        const surveyValidationResults = getValidationErrorsFromStoreByUUID(
            _survey.uuid,
            _survey
        );

        const numberOfParticipantsValidationErrors =
            getValidationErrosByErrorCode(
                "NUM_OF_PARTICIPANTS_VALIDATION",
                surveyValidationResults
            );

        const onAccordionItemClicked = () => {
            isAccordionSectionOpen
                ? accordionSectionsController(null)
                : accordionSectionsController(selectedAccordionItem);
        };

        let isValidAccordionItem = true;
        let warningMessage = "";

        switch (activeAccordionSection) {
            case "PARTICIPANTS":
                const isParticipantsAccordion =
                    accordionTitle === "Participants";

                const isNumOfParticipantsValid =
                    !isParticipantsAccordion ||
                    !(numberOfParticipantsValidationErrors.length > 0);
                isValidAccordionItem =
                    isNumOfParticipantsValid || _survey.respondents.length > 0;

                warningMessage = warningMessages.surveyNumberOfRespondentsError;
                break;
            case "TIME_LINE":
                break;
            default:
                isValidAccordionItem = true;
        }

        return (
            <>
                <div
                    data-accordion-item={`${accordionTitle}`}
                    className={
                        isAccordionSectionOpen
                            ? accordion_item_focused
                            : not_active_item
                    }
                >
                    <div
                        className={accordion_item}
                        onClick={onAccordionItemClicked}
                    >
                        <ValidationWrapper
                            isValid={isValidAccordionItem}
                            errorMessage={warningMessage}
                            tooltipPositionOverride={{
                                left: "calc(100% + 665px)",
                            }}
                        >
                            <div className={accordion_title}>
                                {accordionTitle}&nbsp;
                                <div className={section_counter}>
                                    {`(${sectionCounter})`}
                                </div>
                            </div>
                        </ValidationWrapper>
                    </div>
                    <img
                        onClick={onAccordionItemClicked}
                        className={
                            isAccordionSectionOpen
                                ? rotated_arrow
                                : un_rotated_arrow
                        }
                        src={downArrow}
                        alt="show-hide-accordion"
                    />
                </div>
                {isAccordionSectionOpen ? (
                    <div className={accordion_children}>{children}</div>
                ) : null}
            </>
        );
    }
);
