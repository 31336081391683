import { OngoingSurvey, SimpleSurvey, SurveyClasses } from "@difftone/types";
import { getLastDateToFill } from "@difftone/reducers";
import { surveyResponseStatusStore } from "@difftone/stores";
import { getNumbersPercentage } from "@difftone/procedures";

export const sortDashboardSurveysBySurveyName = (
    ascending: boolean,
    displaySurveys: SurveyClasses[]
) => {
    return ascending
        ? displaySurveys.sort((displaySurveyA, displaySurveyB) =>
              displaySurveyA.survey_name?.toLowerCase()! >=
              displaySurveyB.survey_name?.toLowerCase()!
                  ? 1
                  : -1
          )
        : displaySurveys.sort((displaySurveyA, displaySurveyB) =>
              displaySurveyA.survey_name?.toLowerCase()! <=
              displaySurveyB.survey_name?.toLowerCase()!
                  ? 1
                  : -1
          );
};

export const sortDashboardSurveysByEndData = (
    ascending: boolean,
    surveyList: SurveyClasses[]
) => {
    return ascending
        ? ascendingSortSurveysByEndData(surveyList)
        : descendingSortSurveysByEndData(surveyList);
};

const ascendingSortSurveysByEndData = (surveyList: SurveyClasses[]) => {
    return surveyList.sort((displaySurveyA, displaySurveyB) => {
        const surveyA = displaySurveyA as SurveyClasses;
        const surveyB = displaySurveyB as SurveyClasses;

        let surveyAEndDate: number = 0;
        let surveyBEndDate: number = 0;

        switch (surveyA.survey_class) {
            case "ONGOING":
                surveyAEndDate = (surveyA as OngoingSurvey).manually_ended
                    ? (surveyA as OngoingSurvey).manually_ended!
                    : Infinity;

                break;
            case "SIMPLE":
            default:
                surveyAEndDate = getLastDateToFill(surveyA as SimpleSurvey)!;
        }

        switch (surveyB.survey_class) {
            case "ONGOING":
                surveyBEndDate = (surveyB as OngoingSurvey).manually_ended
                    ? (surveyB as OngoingSurvey).manually_ended!
                    : Infinity;
                break;
            case "SIMPLE":
            default:
                surveyBEndDate = getLastDateToFill(surveyB as SimpleSurvey)!;
        }

        return surveyAEndDate - surveyBEndDate;
    });
};

const descendingSortSurveysByEndData = (surveyList: SurveyClasses[]) => {
    return surveyList.sort((displaySurveyA, displaySurveyB) => {
        const surveyA = displaySurveyA as SurveyClasses;
        const surveyB = displaySurveyB as SurveyClasses;

        let surveyAEndDate: number = 0;
        let surveyBEndDate: number = 0;

        switch (surveyA.survey_class) {
            case "ONGOING":
                surveyAEndDate = (surveyA as OngoingSurvey).manually_ended
                    ? (surveyA as OngoingSurvey).manually_ended!
                    : Infinity;

                break;
            case "SIMPLE":
            default:
                surveyAEndDate = getLastDateToFill(surveyA as SimpleSurvey)!;
        }

        switch (surveyB.survey_class) {
            case "ONGOING":
                surveyBEndDate = (surveyB as OngoingSurvey).manually_ended
                    ? (surveyB as OngoingSurvey).manually_ended!
                    : Infinity;
                break;
            case "SIMPLE":
            default:
                surveyBEndDate = getLastDateToFill(surveyB as SimpleSurvey)!;
        }

        return surveyBEndDate - surveyAEndDate;
    });
};

export const sortDashboardSurveysByResponseRate = (
    ascending: boolean,
    surveyList: SurveyClasses[]
) => {
    return ascending
        ? ascendingSortSurveysByResponseRate(surveyList)
        : descendingSortSurveysByResponseRate(surveyList);
};

const ascendingSortSurveysByResponseRate = (surveyList: SurveyClasses[]) => {
    return surveyList.sort(
        (surveyA, surveyB) =>
            getDashboardSurveyProgressSortBy(surveyA) -
            getDashboardSurveyProgressSortBy(surveyB)
    );
};
const descendingSortSurveysByResponseRate = (surveyList: SurveyClasses[]) => {
    return surveyList.sort(
        (surveyA, surveyB) =>
            getDashboardSurveyProgressSortBy(surveyB) -
            getDashboardSurveyProgressSortBy(surveyA)
    );
};

const getDashboardSurveyProgressSortBy = (survey: SurveyClasses) => {
    const completed = surveyResponseStatusStore.getStatusBySurveyUuid(
        survey.uuid!
    ).length;
    const total = survey!.respondents!.length;

    return getNumbersPercentage(completed, total);
};
