import { makeAutoObservable } from "mobx";
import { v4 as uuid } from "uuid";

import { getUserUuid } from "@difftone/procedures";
import { Bookmark } from "@difftone/types";
import { updateProfile, getProfile } from "@difftone/services";
import { getCurrentTimeInBEFormat } from "@difftone/frontend-common";
import { setBookmarks, setUuid } from "./user-profile-internal-actions";

class UserProfileStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _bookmarks: Bookmark[] = [];
    private _uuid: string = "";

    get uuid() {
        return this._uuid;
    }

    set uuid(newUuid: string) {
        this._uuid = newUuid;
    }

    get bookmarks() {
        return this._bookmarks;
    }

    set bookmarks(bookmarks: Bookmark[]) {
        this._bookmarks = bookmarks;
    }

    public async init() {
        // response could be response with profile or error code 404 if there is no profile in DB
        const response = await getProfile();

        if (response === 404) {
            const profileRes = await this.createUserProfile();

            setBookmarks(profileRes.bookmarks);
            setUuid(profileRes.uuid);

            return;
        }

        setBookmarks(response.data.resources.bookmarks);
        setUuid(response.data.resources.uuid);
    }

    public async addBookmark(bookmark: Bookmark) {
        const bookmarks = this._bookmarks;

        if (bookmarks.find((x) => x.name === bookmark.name)) {
            return;
        }

        bookmarks.push(bookmark);

        const response = await updateProfile({
            user_uuid: getUserUuid(),
            uuid: this._uuid,
            bookmarks,
            is_deleted: false,
            created_at: getCurrentTimeInBEFormat(),
        });

        setBookmarks(response.bookmarks);
    }

    public async removeBookmark(bookmark: Bookmark) {
        const bookmarks = [...this._bookmarks];
        const targetIndex = bookmarks.findIndex(
            (bk) => bk.uuid === bookmark.uuid
        );

        if (targetIndex === -1) {
            return;
        }

        bookmarks.splice(targetIndex, 1);

        const response = await updateProfile({
            user_uuid: getUserUuid(),
            uuid: this._uuid,
            bookmarks,
            is_deleted: false,
            created_at: getCurrentTimeInBEFormat(),
        });

        setBookmarks(response.bookmarks);
    }

    public async editBookmarkName({
        name,
        uuid,
    }: {
        name: string;
        uuid: string;
    }) {
        const bookmarks = [...this._bookmarks];
        const targetIndex = bookmarks.findIndex((bk) => bk.uuid === uuid);

        if (targetIndex === -1) {
            return;
        }

        const bookmark = bookmarks.find((bk) => bk.uuid === uuid);
        bookmark!.name = name;

        const response = await updateProfile({
            user_uuid: getUserUuid(),
            uuid: this._uuid,
            bookmarks,
            is_deleted: false,
            created_at: getCurrentTimeInBEFormat(),
        });

        setBookmarks(response.bookmarks);
    }

    private async createUserProfile() {
        const newUserProfile = {
            user_uuid: getUserUuid(),
            uuid: uuid(),
            bookmarks: [],
            is_deleted: false,
            created_at: getCurrentTimeInBEFormat(),
        };

        return updateProfile(newUserProfile);
    }

    public clearStore = () => {
        this._bookmarks = [];
        this._uuid = "";
    };
}

export const userProfileStore = new UserProfileStore();
