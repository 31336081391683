import React, { forwardRef, MouseEvent, Ref, useState } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import {
    DraggableProvidedDraggableProps,
    DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd";

import { Question, QuestionType } from "@difftone/types";
import { ShowDifftoneTooltip } from "@difftone/shared-components";
import dragAndDrop from "@difftone/assets/drag-drop.svg";
import deleteIcon from "@difftone/assets/left-menu-delete-icon.svg";
import deleteIconGray from "@difftone/assets/left-menu-delete-icon-gray.svg";
import { wizardDisplayStore } from "@difftone/stores";

import {
    list_item_wrapper,
    title_class,
    list_item_container,
    not_visible,
    visible_dnd,
    index_style,
    delete_button,
    list_item_wrapper_not_editable,
} from "./left-menu-list-item-wrapper.module.css";

const MAX_ALLOWED_CHARS_TITLE = 27;
const MIN_NUMBER_OF_QUESTIONS = 1;
const modifyTitleForTooltip = (title: string) => {
    return title.slice(0, MAX_ALLOWED_CHARS_TITLE).concat("...");
};

export type LeftMenuListItemWrapperProps = {
    questionsLength: number;
    draggableProps: DraggableProvidedDraggableProps;
    dragHandleProps: DraggableProvidedDragHandleProps | undefined;
    dataIndex: number;
    listItemData: Question<QuestionType>;
    onDelete: (event: MouseEvent<HTMLButtonElement>) => void;
    onFocusClick: (event: MouseEvent<HTMLDivElement>) => void;
};

export const LeftMenuListItemWrapper = observer(
    forwardRef(
        (props: LeftMenuListItemWrapperProps, ref: Ref<HTMLLIElement>) => {
            const {
                questionsLength,
                dataIndex,
                listItemData,
                onDelete,
                draggableProps,
                dragHandleProps,
                onFocusClick,
            } = props;

            const { isEditableSurvey } = wizardDisplayStore;

            const [isMouseOverListItem, setIsMouseOverListItem] =
                useState(false);

            const toggleHoveredStatus = () => {
                setIsMouseOverListItem(!isMouseOverListItem);
            };

            let itemTitle = null;

            if (listItemData.title.length > MAX_ALLOWED_CHARS_TITLE) {
                itemTitle = (
                    <ShowDifftoneTooltip
                        tooltipPosition="right"
                        tip={listItemData.title}
                    >
                        <div title="" className={title_class}>
                            {modifyTitleForTooltip(listItemData.title)}
                        </div>
                    </ShowDifftoneTooltip>
                );
            } else {
                itemTitle = (
                    <div title="" className={title_class}>
                        {listItemData.title}
                    </div>
                );
            }

            return (
                <li
                    className={clsx(list_item_wrapper, {
                        [list_item_wrapper_not_editable]: !isEditableSurvey,
                    })}
                    {...draggableProps}
                    {...dragHandleProps}
                    ref={ref}
                >
                    <div
                        onMouseOut={toggleHoveredStatus}
                        onMouseOver={toggleHoveredStatus}
                        className={list_item_container}
                        onClick={onFocusClick}
                        data-index={dataIndex}
                    >
                        {isEditableSurvey ? (
                            <img
                                className={
                                    isMouseOverListItem
                                        ? visible_dnd
                                        : not_visible
                                }
                                src={dragAndDrop}
                                alt="drag-and-drop"
                            />
                        ) : null}
                        <div className={index_style}>{dataIndex + 1}.</div>
                        {itemTitle}
                    </div>
                    {questionsLength > MIN_NUMBER_OF_QUESTIONS ? (
                        <button
                            onMouseOut={toggleHoveredStatus}
                            onMouseOver={toggleHoveredStatus}
                            className={delete_button}
                            data-index={dataIndex}
                            onClick={onDelete}
                        >
                            {isEditableSurvey ? (
                                <img
                                    src={
                                        isMouseOverListItem
                                            ? deleteIcon
                                            : deleteIconGray
                                    }
                                    alt="delete"
                                />
                            ) : null}
                        </button>
                    ) : null}
                </li>
            );
        }
    )
);
