import {
    Question,
    QuestionInvalidNicknameValidationType,
    QuestionType,
    ValidationResult,
} from "@difftone/types";

export const validateQuestionNickname = (
    question: Question<QuestionType>
): ValidationResult<QuestionInvalidNicknameValidationType>[] => {
    try {
        const res = question.nickname ? true : false;

        return [
            {
                is_valid: res,
                validation_error_code:
                    "QUESTION_NICKNAME_DUPLICATION_VALIDATION",
                validation_error_type: {
                    question_id: !res ? question.uuid : "",
                },
            },
        ];
    } catch (error) {
        console.error(
            `validateQuestionNickname error:: ${(error as Error).message}`
        );
        return [
            {
                is_valid: false,
                validation_error_code: "EXCEPTION_DURING_VALIDATIONS",
                //TODO: fix type definition
                //@ts-ignore
                validation_error_type: error as Error,
            },
        ];
    }
};

export const validateQuestionEmptyNickname = (
    question: Question<QuestionType>
): ValidationResult<QuestionInvalidNicknameValidationType>[] => {
    try {
        let res = true;
        if (!question.nickname.trim().length) {
            res = false;
        }

        return [
            {
                is_valid: res,
                validation_error_code: "QUESTION_EMPTY_NICKNAME_VALIDATION",
                validation_error_type: {
                    question_id: !res ? question.uuid : "",
                },
            },
        ];
    } catch (error) {
        console.error(
            `validateQuestionNickname error:: ${(error as Error).message}`
        );
        return [
            {
                is_valid: false,
                validation_error_code: "EXCEPTION_DURING_VALIDATIONS",
                //TODO: fix type definition
                //@ts-ignore
                validation_error_type: error as Error,
            },
        ];
    }
};
