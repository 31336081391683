import type {
    SurveyClasses,
    SurveyNameValidationType,
    ValidationResult,
} from "@difftone/types";

export const validateSurveyName = (
    survey: SurveyClasses
): ValidationResult<SurveyNameValidationType>[] => {
    return survey.survey_name === survey.default_survey_name ||
        !survey.survey_name ||
        !survey.survey_name.trim()
        ? [
              {
                  is_valid: false,
                  validation_error_code: "SURVEY_NAME_VALIDATION",
                  validation_error_type: { survey_uuid: survey.uuid },
              },
          ]
        : [
              {
                  is_valid: true,
                  validation_error_code: "SURVEY_NAME_VALIDATION",
                  validation_error_type: { survey_uuid: survey.uuid },
              },
          ];
};
