import React, { SyntheticEvent } from "react";
import clsx from "clsx";
import { debounceUpsertSurveyToApi } from "@difftone/actions";
import { wizardDisplayStore } from "@difftone/stores";

import { AnonimityLevelButton } from "./components";

import {
    anonimity_container,
    anonimity_button_group,
    component_header,
    disabled_component_header,
    anonimity_button_group_disabled,
} from "./anonimity-level.module.css";
import { BaseSurvey } from "@difftone/types";

export type AnonimityLevelType = {
    survey: BaseSurvey;
};

export const AnonimityLevel = (props: AnonimityLevelType) => {
    const { survey } = props;
    const { isEditableSurvey } = wizardDisplayStore;

    const onClick = (event: SyntheticEvent) => {
        if (!isEditableSurvey) {
            return;
        }
        const key = "anonimity";
        const value = event.currentTarget.getAttribute("data-value");

        if (value) {
            debounceUpsertSurveyToApi(key, value);
        }
    };

    return (
        <div className={anonimity_container}>
            <span
                className={clsx(component_header, {
                    [disabled_component_header]: !isEditableSurvey,
                })}
            >
                Anonymity Level
            </span>
            <div>
                <div
                    tabIndex={0}
                    className={
                        isEditableSurvey
                            ? anonimity_button_group
                            : anonimity_button_group_disabled
                    }
                >
                    <AnonimityLevelButton
                        disabled={!isEditableSurvey}
                        onClick={onClick}
                        label="None"
                        isSelected={survey.anonimity === "NONE"}
                    />
                    <AnonimityLevelButton
                        disabled={!isEditableSurvey}
                        onClick={onClick}
                        label="Low"
                        isSelected={survey.anonimity === "LOW"}
                    />
                    <AnonimityLevelButton
                        disabled={!isEditableSurvey}
                        onClick={onClick}
                        label="Medium"
                        isSelected={survey.anonimity === "MEDIUM"}
                    />
                    <AnonimityLevelButton
                        disabled={!isEditableSurvey}
                        onClick={onClick}
                        label="High"
                        isSelected={survey.anonimity === "HIGH"}
                    />
                </div>
            </div>
        </div>
    );
};
