import type {
    SurveyClasses,
    ValidationResult,
    ValidationType,
} from "@difftone/types";
import { validateEmailAddresses } from "../../shared-validations-utils";

export const validateSurveyRespondants = (
    survey: SurveyClasses
): ValidationResult<ValidationType>[] => {
    try {
        const surveyRespondants = survey.respondents;
        if (!surveyRespondants || surveyRespondants.length < 1) {
            return [
                {
                    is_valid: false,
                    validation_error_code: "NUM_OF_PARTICIPANTS_VALIDATION",
                    validation_error_type: { survey_uuid: survey.uuid },
                },
            ];
        }

        const emailsValidation = validateEmailAddresses(surveyRespondants);
        if (emailsValidation.some((email) => !email.is_valid)) {
            return emailsValidation;
        }

        return [
            {
                is_valid: true,
                validation_error_code: "NUM_OF_PARTICIPANTS_VALIDATION",
                validation_error_type: { survey_uuid: survey.uuid },
            },
        ];
    } catch (error) {
        console.error(
            `validateSurveyRespondants error:: ${(error as Error).message}`
        );
        return [
            {
                is_valid: false,
                validation_error_code: "EXCEPTION_DURING_VALIDATIONS",
                validation_error_type: error as Error,
            },
        ];
    }
};
