import React, { useState, ReactNode } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";

import {
    table_row,
    table_row_confirm,
    hovered_menu,
    hovered_menu_shown,
    table_row_is_archive,
    hovered_menu_archive,
    respondents_progress_bar,
} from "./table-row.module.css";

export type TableRowProps = {
    onBodyClick?: (x: any) => void;
    body: ReactNode;
    progressBar?: ReactNode;
    actions?: ReactNode;
    actionsClassName?: string;
    sharedSurveyIsArchive?: boolean;
};

export const TableRow = observer(
    ({
        actions,
        body,
        onBodyClick,
        actionsClassName,
        sharedSurveyIsArchive,
        progressBar,
    }: TableRowProps) => {
        // temp variable to show/hide hovered_menu
        const [isMenuShown, setIsMenuShown] = useState(false);

        const onMouseEnterHover = () => {
            setIsMenuShown(true);
        };

        const onMouseLeaveHover = () => {
            setIsMenuShown(false);
        };

        return (
            <>
                <div
                    className={
                        sharedSurveyIsArchive
                            ? clsx(table_row, table_row_is_archive)
                            : table_row
                    }
                    onMouseEnter={onMouseEnterHover}
                    onMouseLeave={onMouseLeaveHover}
                >
                    <div className={table_row_confirm} onClick={onBodyClick}>
                        {body}
                        {progressBar ? (
                            <div className={respondents_progress_bar}>
                                {isMenuShown ? null : progressBar}
                            </div>
                        ) : null}
                    </div>
                    {actions && (
                        <div
                            className={clsx(hovered_menu, actionsClassName, {
                                [sharedSurveyIsArchive
                                    ? clsx(
                                          hovered_menu_shown,
                                          hovered_menu_archive
                                      )
                                    : hovered_menu_shown]: isMenuShown,
                            })}
                        >
                            {actions}
                        </div>
                    )}
                </div>
            </>
        );
    }
);
