import React from "react";
import { observer } from "mobx-react";
import { getSurveyFromStoreByURL } from "@difftone/reducers";
import { wizardDisplayStore } from "@difftone/stores";
import {
    addWizardCopySurveyEvent,
    addWizardSurveyEvent,
    debounceSetAlertToStore,
    debounceUpsertSurveyToApi,
    getNewEmptySurveyEvent,
    setSurveyCopyAlertToStore,
    setSurveyEventToDisplay,
    updateSurveyWizardCopy,
} from "@difftone/actions";
import {
    ReminderDetailsPopup,
    Spinner,
    WizardFloatingActions,
} from "@difftone/shared-components";
import { SimpleSurvey, SurveyEvent, SurveyEventAlert } from "@difftone/types";

import { Timeline, WizardEventDetails } from "./components";
import {
    wizard_timeline,
    right_floating_style,
} from "./wizard-timeline.module.css";

export const WizardTimeline = observer(() => {
    const { selectedEventUUID, selectedDisplayEventAlert } = wizardDisplayStore;
    const { isEditableSurvey, getWizardSurveyCopy } = wizardDisplayStore;

    const survey = !isEditableSurvey
        ? getWizardSurveyCopy()
        : getSurveyFromStoreByURL();

    //Old templets surveys don't have a class when creating new survey
    if (survey.survey_class && survey.survey_class !== "SIMPLE") {
        throw Error(
            "[Difftone]:: WizardTimeline survey must be a simple survey"
        );
    }

    const simpleSurvey = survey as SimpleSurvey;

    if (selectedEventUUID === null || selectedEventUUID === undefined) {
        setSurveyEventToDisplay(simpleSurvey.survey_events[0].uuid);
        return <Spinner size="MEDIUM" />;
    }

    const onChangeSurveyEventAlert = (surveyEventAlert: SurveyEventAlert) => {
        if (isEditableSurvey) {
            debounceSetAlertToStore(surveyEventAlert);
            debounceUpsertSurveyToApi();
        } else {
            setSurveyCopyAlertToStore(surveyEventAlert);
            updateSurveyWizardCopy();
        }
    };

    const onChangeSurveyEvent = (surveyEvent: SurveyEvent) => {
        if (isEditableSurvey) {
            addWizardSurveyEvent(surveyEvent);
            debounceUpsertSurveyToApi();
        } else {
            addWizardCopySurveyEvent(surveyEvent);
            updateSurveyWizardCopy();
        }
    };

    return (
        <div className={wizard_timeline} data-wizard-timeline>
            <Timeline survey={simpleSurvey} />
            {selectedEventUUID && (
                <WizardEventDetails
                    survey={simpleSurvey}
                    onChangeSurveyEvent={onChangeSurveyEvent}
                />
            )}
            {isEditableSurvey && !selectedDisplayEventAlert ? (
                <div className={right_floating_style}>
                    <WizardFloatingActions />
                </div>
            ) : null}
            {selectedDisplayEventAlert ? (
                <ReminderDetailsPopup
                    survey={simpleSurvey}
                    onChangeSurveyEventAlert={onChangeSurveyEventAlert}
                />
            ) : null}
        </div>
    );
});
