import React from "react";
import { colorFactoryByScoreResult } from "@difftone/frontend-common";
import { ResultsScoreBox } from "./components";

import { favorable_question_score } from "./favorable-score.module.css";

export type FavorableScoreProps = {
    scoreBarSize: "SMALL" | "MEDIUM";
    scoreResults: number[];
    tooltipGroups?: { [key: number]: string[] };
};

export const FavorableScore = (props: FavorableScoreProps) => {
    const { scoreResults, scoreBarSize, tooltipGroups } = props;

    const colorPalette = colorFactoryByScoreResult(scoreResults.length);

    const colorElements = scoreResults.map((width, index) => {
        return (
            <ResultsScoreBox
                tooltipDisplayGroupe={
                    tooltipGroups ? tooltipGroups[index] : null
                }
                lastScoreBoxPosition={scoreResults.length - 1}
                index={index}
                resultsScoreSize={scoreBarSize}
                key={colorPalette[index]}
                scoreColor={colorPalette[index]}
                width={width}
            />
        );
    });

    return <div className={favorable_question_score}>{colorElements}</div>;
};
