export const warningMessages = {
    surveyNumberOfRespondentsError: "A survey must have participants",
    surveyNumberOfQuestionsError: "A survey must have questions",
    surveyNumberOfAttributesError: "A survey must have Features",
    surveyTitleEmpty: "You must have a name for your survey",
    surveyTitleNotUniquee: "This survey name already exists",
    surveyCannotShareInitiator: "Can not share survey with initiator",
    youCannotShareThis:
        "You do not have permissions to share this view. Please contact the survey's initiator",
    surveyCannotShareWithoutAdding:
        "Please add someone to share with in order to share survey",
    lastDateToFillEventTimeError: `"Complete survey deadline" must be later than "Date to be sent out"`,
    lastDateToFillGreaterThenNowError: `"Complete survey deadline" cannot be earlier than now`,
    publishDateTimeError: `Survey publish date and time must be greater than "Now"`,
    emailInvalidError: "This is not a valid email address",
    emailNotUniqueError: "This is not a unique email address",
    attributeTitleError: "Features need to have a unique name",
    attributeNickNameError: "A feature must have a label",
    questionNickNameError: "A question must have a label",
    questionOptionsNotUniqueError: "Options need to have a unique name",
    questionTitleError: "Questions need to have a unique name",
    surveyEventTitleError: "Events need to have a unique name",
    bookingDateToFillEventTimeError: `Booking slot date and time must be greater than "Now"`,
    alertParticipantsError:
        "Cannot add participants that are not in Survey participants list. Please add participant to list before adding to event",
    alertCouldNotFindError: "Could not find selected alert",
    questionRatingCategory:
        "We recommend using the same scale for rating questions throughout your survey and categories",
    questionMissingCategory: "Please choose category for a question",
    eventIllegalDateFormat: "Please change the date before proceeding",
    organizationMessage: "Email should be with organization's domain.",
    surveyNotFound:
        "This survey was not found, It might have been deleted. Please make sure you are logged in using the correct profile.",
    sharedSurveyUuidNotFound: "This shared survey id was not found",
    surveyResponseNotFound: "Could not find survey responses.",
    dashboardEndDateWarningMessage: "End date can not be before start date.",
    dashboardInitiatorsListError: "Please add at list one initiator.",
    removeParticipantsFromActiveSurveyFailure:
        "Users cannot be removed from an active survey",
    goBackWithNoWizardChanges:
        "Going back to the inbox will cancel all of your changes",
    alreadyAnsweredOngoingSurvey: "You have already responded to this survey",
};
