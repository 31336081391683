import React from "react";
import { observer } from "mobx-react";
import {
    ResultsFilter,
    SurveyClasses,
    TimeCompareUnits,
    TimeRangesContainer,
    UUID,
} from "@difftone/types";
import { RESULTS_DISPLAY_TYPE, TIME_FILTER_ID } from "@difftone/constants";
import { navigationStore } from "@difftone/stores";

import {
    sharedResultsTurnOffFilterAsCompare,
    sharedResultsTurnOnFilterAsCompare,
    switchSharedResultsActiveCompareIndex,
    updateSelectedFilters,
} from "@difftone/actions";

import { ComparePicker, FilterPicker } from "@difftone/shared-components";

import {
    left_menu_children,
    selected_options_counter,
    left_menu_compare_picker,
    left_menu_filter_picker,
} from "./results-left-menu-children.module.css";

const MIN_COMPARES_ALLOWED = 1;
const MAX_COMPARES_ALLOWED = 2;

export type LeftMenuChildrenType = {
    activeAsComparesFiltersUUIDs: UUID[];
    selectedFilters: ResultsFilter[];
    selectedSurvey: SurveyClasses;
    getActiveFilters: (filterUuid: string) => boolean[];
    setActiveFilters: (filterUuid: string, newValues: boolean[]) => void;
    selectedResultsTimeCompare: TimeCompareUnits;
    selectedPopupDisplayResultsTimeCompare: TimeCompareUnits;
    setPopupDisplayResultsTimeCompare: (
        newTimeCompare: TimeCompareUnits
    ) => void;
    setSelectedTimeCompareResultsFilterAndTimeUnits: (
        selectedFilter: TimeCompareUnits,
        timeCompareFilterUuid: string,
        timeRangesContainer: TimeRangesContainer | null
    ) => void;
};

export const ResultsLeftMenuChildren = observer(
    (props: LeftMenuChildrenType) => {
        const {
            activeAsComparesFiltersUUIDs,
            selectedFilters,
            selectedSurvey,
            getActiveFilters,
            setActiveFilters,
            selectedResultsTimeCompare,
            selectedPopupDisplayResultsTimeCompare,
            setPopupDisplayResultsTimeCompare,
            setSelectedTimeCompareResultsFilterAndTimeUnits,
        } = props;

        const { currentPage } = navigationStore;

        const _resultsFiltersWithoutTimeFilter = selectedFilters.filter(
            (filter) => filter.filterKey.uuid !== TIME_FILTER_ID
        );

        let displayResultsFilters: ResultsFilter[] = [];
        let displayActiveAsComparesFiltersUUIDs: string[] = [];

        switch (selectedSurvey.survey_class) {
            case "ONGOING":
                displayResultsFilters = selectedFilters;
                displayActiveAsComparesFiltersUUIDs =
                    activeAsComparesFiltersUUIDs;
                break;
            case "SIMPLE":
            default:
                displayResultsFilters = selectedFilters.filter(
                    (filter) => filter.filterKey.uuid !== TIME_FILTER_ID
                );
                displayActiveAsComparesFiltersUUIDs =
                    activeAsComparesFiltersUUIDs.filter(
                        (filterUUID) => !filterUUID.includes(TIME_FILTER_ID)
                    );
        }

        const allowedCompares =
            displayResultsFilters.length === MIN_COMPARES_ALLOWED
                ? MIN_COMPARES_ALLOWED
                : MAX_COMPARES_ALLOWED;

        const isCompareByDisabled = currentPage.includes(
            RESULTS_DISPLAY_TYPE.FAVORABLE
        );

        return (
            <ul className={left_menu_children}>
                <li className={left_menu_filter_picker}>
                    <div>Filter by:</div>
                    <FilterPicker
                        resultsFilters={_resultsFiltersWithoutTimeFilter}
                        getActiveFilters={getActiveFilters}
                        setActiveFilters={setActiveFilters}
                        updateSelectedFilters={updateSelectedFilters}
                    />
                </li>
                <li className={left_menu_compare_picker}>
                    <div>
                        Compare by:
                        <span
                            className={selected_options_counter}
                            title="maximum number of compares"
                        >
                            ({displayActiveAsComparesFiltersUUIDs.length}/
                            {allowedCompares})
                        </span>
                    </div>
                    <ComparePicker
                        isDisabled={isCompareByDisabled}
                        survey={selectedSurvey}
                        resultsFilters={displayResultsFilters}
                        activeAsComparesFiltersUUIDs={
                            displayActiveAsComparesFiltersUUIDs
                        }
                        selectedResultsTimeCompare={selectedResultsTimeCompare}
                        selectedPopupDisplayResultsTimeCompare={
                            selectedPopupDisplayResultsTimeCompare
                        }
                        setPopupDisplayResultsTimeCompare={
                            setPopupDisplayResultsTimeCompare
                        }
                        setSelectedTimeCompareResultsFilterAndTimeUnits={
                            setSelectedTimeCompareResultsFilterAndTimeUnits
                        }
                        resultsTurnOnFilterAsCompare={
                            sharedResultsTurnOnFilterAsCompare
                        }
                        resultsTurnOffFilterAsCompare={
                            sharedResultsTurnOffFilterAsCompare
                        }
                        switchResultsActiveCompareIndex={
                            switchSharedResultsActiveCompareIndex
                        }
                    />
                </li>
            </ul>
        );
    }
);
