import { action } from "mobx";

import { getQueryParamsFromUrl } from "@difftone/reducers";
import {
    getSharedWithMeResults,
    getResultISharedWith,
} from "@difftone/services";
import { sharedResultStore } from "./shared-result-store";

export const getViewsSharedWithMe = action(async () => {
    const viewsSharedWithMe = await getSharedWithMeResults();
    const viewsISharedWith = await getResultISharedWith();

    if (viewsISharedWith.length) {
        sharedResultStore.viewsISharedWith = viewsISharedWith;
    }

    if (viewsSharedWithMe.length) {
        sharedResultStore.sharedViews = viewsSharedWithMe;
    }

    setIsStoreInitializing(false);
});

export const initTargetSharedViewFromUrl = action(() => {
    const sharedView = getQueryParamsFromUrl().get("sharedView");

    if (sharedView) {
        sharedResultStore.targetSharedResult = JSON.parse(sharedView);
    }

    sharedResultStore.isTargetSharedResultInitialized = true;
});

export const setIsStoreInitializing = action((isStoreInitializing: boolean) => {
    sharedResultStore.isStoreInitializing = isStoreInitializing;
});
