import type {
    InvalidValuesIndexesInArray,
    InvalidValuesUUIDsInArray,
    SurveyEvent,
    SurveyEventTitleDuplicationValidationType,
    ValidationResult,
} from "@difftone/types";

export const validateEventTitleUniqueness = (
    surveyEvents: SurveyEvent[]
): ValidationResult<SurveyEventTitleDuplicationValidationType> => {
    try {
        const duplicatedNamesMap: InvalidValuesUUIDsInArray = {};

        surveyEvents.forEach((surveyEvent, index) => {
            if (!duplicatedNamesMap[surveyEvent.title]) {
                duplicatedNamesMap[surveyEvent.title] = [surveyEvent.uuid];
            } else {
                duplicatedNamesMap[surveyEvent.title].push(surveyEvent.uuid);
            }
        });

        for (let element in duplicatedNamesMap) {
            if (duplicatedNamesMap[element].length === 1) {
                delete duplicatedNamesMap[element];
            }
        }

        return Object.keys(duplicatedNamesMap).length === 0
            ? {
                  is_valid: true,
                  validation_error_code:
                      "SURVEY_EVENT_NAME_DUPLICATION_VALIDATION",
                  validation_error_type: {
                      duplication_indexes: duplicatedNamesMap,
                  },
              }
            : {
                  is_valid: false,
                  validation_error_code:
                      "SURVEY_EVENT_NAME_DUPLICATION_VALIDATION",
                  validation_error_type: {
                      duplication_indexes: duplicatedNamesMap,
                  },
              };
    } catch (error) {
        console.error(
            `validateSurveyDates error:: ${(error as Error).message}`
        );
        return {
            is_valid: false,
            validation_error_code: "EXCEPTION_DURING_VALIDATIONS",
            //TODO: fix type definition
            //@ts-ignore
            validation_error_type: error as Error,
        };
    }
};
