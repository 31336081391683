import { action } from "mobx";
import { Bookmark } from "@difftone/types";
import { userProfileStore } from "@difftone/stores";

export const setBookmarks = action((bookmarks: Bookmark[]) => {
    userProfileStore.bookmarks = bookmarks;
});

export const setUuid = action((newUuid: string) => {
    userProfileStore.uuid = newUuid;
});
