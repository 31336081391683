import React from "react";
import { ensureLengthOfValue } from "@difftone/actions";
import {
    text_wrapper,
    text_position,
    text_wrapper_last_child_border,
} from "./second-value-title.module.css";

export type SecondValueTitleProps = {
    title: string;
    isSecondHeader?: boolean;
    isLastInHeadersBlock: boolean;
};

export const SecondValueTitle = (props: SecondValueTitleProps) => {
    const { title, isSecondHeader, isLastInHeadersBlock } = props;

    return (
        <div
            className={
                isLastInHeadersBlock
                    ? [text_wrapper, text_wrapper_last_child_border].join(" ")
                    : text_wrapper
            }
        >
            <div className={text_position}>
                {isSecondHeader ? title : ensureLengthOfValue(title)}
            </div>
        </div>
    );
};
