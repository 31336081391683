import React from "react";
import * as d3 from "d3";
import { DataPieChart } from "@difftone/types";

import { tooltip_pie_chart, path_arc_pie_chart } from "./pie-chart.module.css";

const WIDTH_PIE_CHART: number = 360;
const HEIGHT_PIE_CHART: number = 360;
const RADIUS_PIE_CHART: number = 2;
const MARGIN_PIE_CHART: number = 20;
const POSITION_TRANSLATE: number = 2;

export type PieChartProps = {
    dataPieChart: DataPieChart[];
};

export const PieChart = (props: PieChartProps) => {
    const { dataPieChart } = props;

    const width: number = WIDTH_PIE_CHART;
    const height: number = HEIGHT_PIE_CHART;
    const margin: number = MARGIN_PIE_CHART;
    const radius: number = Math.min(width, height) / RADIUS_PIE_CHART - margin;

    const calculationTranslatePosition: number = POSITION_TRANSLATE;
    const tooltip = d3.select(`div[data-pie-chart-tooltip]`);

    const createPie: any = d3
        .pie()
        .value((d: any) => d.value)
        .sort(null);
    const createArc: any = d3.arc().innerRadius(110).outerRadius(radius);
    const data = createPie(dataPieChart);

    const onHideTooltip = () => {
        tooltip
            .interrupt()
            .transition()
            .style("opacity", 0)
            .style("display", "none");
    };

    const onTooltipTemplate = (item: DataPieChart) => {
        return `<div style="padding: 8px 0;"> ${item.value} </div>`;
    };

    const onShowTooltip = (
        event: React.MouseEvent<SVGPathElement, MouseEvent>,
        item: { data: DataPieChart }
    ) => {
        tooltip
            .transition()
            .duration(200)
            .style("opacity", 1)
            .style("display", "block");
        tooltip
            .html(onTooltipTemplate(item.data))
            .style("left", `${event.pageX + 10}px`)
            .style("top", `${event.pageY - 12}px`);
    };

    return (
        <>
            <div data-pie-chart-tooltip className={tooltip_pie_chart}></div>
            <svg height="100%" width="100%" viewBox={`0 0 ${width} ${height}`}>
                <g
                    transform={`translate(${
                        width / calculationTranslatePosition
                    } ${height / calculationTranslatePosition})`}
                >
                    {data.map((item: { data: DataPieChart }) => (
                        <path
                            key={item.data.lable}
                            className={path_arc_pie_chart}
                            onMouseMove={(
                                event: React.MouseEvent<
                                    SVGPathElement,
                                    MouseEvent
                                >
                            ) => onShowTooltip(event, item)}
                            onMouseLeave={onHideTooltip}
                            onMouseOut={onHideTooltip}
                            d={createArc(item)}
                            fill={item.data.color}
                        />
                    ))}
                </g>
            </svg>
        </>
    );
};
