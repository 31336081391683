import React from "react";

import { scrollable_time_input_headers } from "./scrollable-time-column-headers.module.css";

export type ScrollableTimeInputHeadersProps = {};

export const ScrollableTimeInputHeaders = () => {
    return (
        <div className={scrollable_time_input_headers}>
            <div>hh</div>
            <div>:</div>
            <div>mm</div>
        </div>
    );
};
