import React from "react";
import clsx from "clsx";
import { observer } from "mobx-react";

import { dashboardDisplayStore } from "@difftone/stores";
import { PublicProfile } from "@difftone/types";
import { getUserUuid } from "@difftone/procedures";

import {
    placeholder,
    selected_values_placeholder,
    no_selected_values_placeholder,
} from "./filter-by-display-picker.module.css";

export type FilterByDisplayPickerProps = {
    publicProfileList: PublicProfile[];
    openInitiatorList: () => void;
};

export const FilterByDisplayPicker = observer(
    (props: FilterByDisplayPickerProps) => {
        const { dashboardDraftFilter } = dashboardDisplayStore;
        const { publicProfileList, openInitiatorList } = props;

        const getFirstUserNameInList = (userUuid: string) => {
            const publicProfileDisplay = publicProfileList.find(
                (publicProfile) => publicProfile.uuid === userUuid
            );

            if (publicProfileDisplay?.uuid === getUserUuid()) {
                return "Me";
            }
            return publicProfileDisplay?.name;
        };

        return (
            <div>
                {dashboardDraftFilter?.initiators.length ? (
                    <div
                        className={clsx(
                            placeholder,
                            selected_values_placeholder
                        )}
                    >
                        <span onClick={openInitiatorList}>
                            {`Initiator:
                        ${getFirstUserNameInList(
                            dashboardDraftFilter?.initiators[0]
                        )}
                        ${
                            dashboardDraftFilter?.initiators.length > 1
                                ? `+${
                                      dashboardDraftFilter?.initiators.length -
                                      1
                                  }`
                                : ""
                        }`}
                        </span>
                        {/* TODO: bring it back when we will have sys admins https://app.asana.com/0/1201346022025578/1202469043796007/f */}
                        {/* <svg
                            onMouseDown={clearInitiatorsFilter}
                            className={remove_filter}
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="9.5" cy="9.5" r="9" fill="#035375" />
                            <path
                                d="M13.3225 5.68353C13.2092 5.56992 13.0553 5.50607 12.8948 5.50607C12.7343 5.50607 12.5804 5.56992 12.467 5.68353L9.5 8.64448L6.53299 5.67747C6.41963 5.56385 6.26573 5.5 6.10523 5.5C5.94474 5.5 5.79083 5.56385 5.67747 5.67747C5.44084 5.9141 5.44084 6.29635 5.67747 6.53298L8.64449 9.5L5.67747 12.467C5.44084 12.7036 5.44084 13.0859 5.67747 13.3225C5.91411 13.5592 6.29636 13.5592 6.53299 13.3225L9.5 10.3555L12.467 13.3225C12.7036 13.5592 13.0859 13.5592 13.3225 13.3225C13.5592 13.0859 13.5592 12.7036 13.3225 12.467L10.3555 9.5L13.3225 6.53298C13.5531 6.30242 13.5531 5.9141 13.3225 5.68353Z"
                                fill="#EBF5F9"
                            />
                        </svg> */}
                    </div>
                ) : (
                    <span
                        onClick={openInitiatorList}
                        className={clsx(
                            placeholder,
                            no_selected_values_placeholder
                        )}
                    >
                        Initiator
                    </span>
                )}
            </div>
        );
    }
);
