import {
    RequiredNotAnsweredValidationType,
    SurveyClasses,
    SurveyResponse,
    ValidationResult,
    ValidationType,
} from "@difftone/types";

export const validateComplicatedResponseRequiredAnswers = (
    survey: SurveyClasses,
    surveyResponse: SurveyResponse
): ValidationResult<ValidationType>[] => {
    const res: ValidationResult<RequiredNotAnsweredValidationType>[] = [];

    const filterQuestions = survey.questions.filter(
        (question) => question.is_filter
    );
    const nonFilterQuestions = survey.questions.filter(
        (question) => !question.is_filter
    );

    filterQuestions.forEach((question) => {
        const answer = surveyResponse.attribute_answers.find(
            (attributeAnswer) => attributeAnswer.question_uuid === question.uuid
        );

        const validationResult: ValidationResult<RequiredNotAnsweredValidationType> =
            {
                is_valid: !!answer,
                validation_error_code: "REQUIRED_NOT_ANSWERED_VALIDATION",
                validation_error_type: { question_uuid: question.uuid },
            };
        res.push(validationResult);
    });

    nonFilterQuestions.forEach((question) => {
        if (question.required) {
            const answer = surveyResponse.answers.find(
                (answer) => answer.question_uuid === question.uuid
            );

            const validationResult: ValidationResult<RequiredNotAnsweredValidationType> =
                {
                    is_valid: !!answer,
                    validation_error_code: "REQUIRED_NOT_ANSWERED_VALIDATION",
                    validation_error_type: { question_uuid: question.uuid },
                };
            res.push(validationResult);
        }
    });

    return res;
};

export const validateSimpleResponseRequiredAnswers = (
    survey: SurveyClasses,
    surveyResponse: SurveyResponse
): ValidationResult<ValidationType>[] => {
    const res: ValidationResult<RequiredNotAnsweredValidationType>[] = [];

    survey.questions.forEach((question) => {
        if (question.required) {
            const answer = surveyResponse.answers.find(
                (answer) => answer.question_uuid === question.uuid
            );

            const validationResult: ValidationResult<RequiredNotAnsweredValidationType> =
                {
                    is_valid: !!answer,
                    validation_error_code: "REQUIRED_NOT_ANSWERED_VALIDATION",
                    validation_error_type: { question_uuid: question.uuid },
                };
            res.push(validationResult);
        }
    });

    return res;
};
