import { QuestionTypeName } from "@difftone/types";
import clsx from "clsx";
import React from "react";

import {
    row_container,
    row_container_disabled_text,
    disabled_image,
} from "./icon-select-row.module.css";

export type IconSelectRowProps = {
    questionTypeName: QuestionTypeName;
    iconUrl: string;
    disabled?: boolean;
};

export const IconSelectRow = (props: IconSelectRowProps) => {
    const { questionTypeName, iconUrl, disabled } = props;

    const getName = () => {
        switch (questionTypeName) {
            case "RATING":
                return "rating";
            case "SINGLE_CHOICE":
                return "single choice";
            case "MULTIPLE_CHOICE":
                return "multiple choice";
            case "DATE":
                return "date";
            case "TIME":
                return "time";
            case "SHORT_TEXT":
                return "short text";
            case "LONG_TEXT":
                return "long text";
            default:
                return null;
        }
    };

    return (
        <div
            className={clsx(
                row_container,
                disabled && row_container_disabled_text
            )}
        >
            <img
                alt="type-icon"
                className={disabled ? disabled_image : ""}
                src={iconUrl}
            />
            <span>{getName()}</span>
        </div>
    );
};
