export const environment = () => {
    switch (window.location.hostname) {
        case "www.difftone.dev":
            return "DEV";
        case "www.difftone.app":
            return "APP";
        case "www.difftone.com":
            return "COM";
        default:
            return null;
    }
};

export const getVersion = () => {
    return import.meta.env.PACKAGE_VERSION || "";
};
