import React from "react";

import mulitColorFavorableIcon from "@difftone/assets/mulit-color-favorable-icon.svg";
import grayFavorableIcon from "@difftone/assets/gray-favorable-icon.svg";
import greenFavorableIcon from "@difftone/assets/green-favorable-icon.svg";
import blackFavorableIcon from "@difftone/assets/black-favorable-icon.svg";

export type FavorableIconProps = {
    isSelected: boolean;
    isMouseHover: boolean;
    isDisabled: boolean;
    classNameOverride?: string;
};

export const FavorableIcon = (props: FavorableIconProps) => {
    const { isSelected, isMouseHover, isDisabled, classNameOverride } = props;

    if (isDisabled) {
        return (
            <img className={classNameOverride || ""} src={grayFavorableIcon} />
        );
    }

    if (isMouseHover) {
        return (
            <img className={classNameOverride || ""} src={greenFavorableIcon} />
        );
    }

    if (isSelected) {
        return (
            <img className={classNameOverride || ""} src={blackFavorableIcon} />
        );
    }

    return (
        <img
            className={classNameOverride || ""}
            src={mulitColorFavorableIcon}
        />
    );
};
