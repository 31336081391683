import { SurveyResponseStatus, UUID } from "@difftone/types";
import { objectsAreEqual } from "@difftone/frontend-common";
import { getSurveysResponsesStatusFromApiBySurveyUuid } from "@difftone/services";
import { surveyResponseStatusStore } from "@difftone/stores";
import { surveyResponseStatusMapSetter } from "@difftone/actions";

export const updateStatuses = async (surveyUuid: UUID) => {
    const updatedStatuses: SurveyResponseStatus[] =
        await getSurveysResponsesStatusFromApiBySurveyUuid(surveyUuid!);

    if (
        !objectsAreEqual(
            surveyResponseStatusStore.surveyResponseStatusesMap[surveyUuid],
            updatedStatuses
        )
    ) {
        surveyResponseStatusMapSetter(surveyUuid, updatedStatuses);
    }
};
