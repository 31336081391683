import React, {
    FC,
    useState,
    useRef,
    SyntheticEvent,
    Ref,
    PropsWithChildren,
} from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import { Bookmark as BookmarkType } from "@difftone/types";
import bookmarkIcon from "@difftone/assets/bookmark-icon-new.svg";
import deleteIcon from "@difftone/assets/delete-icon.svg";
import resultsIcon from "@difftone/assets/survey-results.svg";
import shareIcon from "@difftone/assets/share-survey.svg";
import { ShowDifftoneTooltip, Spinner } from "@difftone/shared-components";
import {
    userProfileStore,
    timeRangesStore,
    resultsDisplayFilterStore,
} from "@difftone/stores";
import { setShareResultCompareBy, showDifftoneAlert } from "@difftone/actions";
import { getSurveyByUuid, getTimeUnitFromUrlOrNone } from "@difftone/reducers";
import { getUserUuid } from "@difftone/procedures";
import { preventPropagation } from "@difftone/frontend-common";

import {
    bookmark,
    bookmark_icon,
    bookmark_actions,
    bookmark_actions_simple,
    bookmark_actions_icon,
    bookmark_actions_shown,
    bookmark_name,
    bookmark_name_inner,
    bookmark_actions_icon_export,
    bookmark_actions_icon_export_disabled,
    spinner_style,
} from "./bookmark.module.css";

export type BookmarkProps = BookmarkType & {
    onExport: (bookmark: BookmarkType) => void;
    onClose: () => void;
    onShareView: (name: string) => void;
    surveyName: string;
    isSimpleResults?: boolean;
};

export const Bookmark: FC<PropsWithChildren<BookmarkProps>> = observer(
    (props: BookmarkProps) => {
        const { extractKeyForTimeRange, getTimeRangeByKey } = timeRangesStore;
        const {
            url,
            name,
            uuid,
            onExport,
            onClose,
            onShareView,
            isSimpleResults,
        } = props;

        const { compareBy } = resultsDisplayFilterStore;

        // temp variable to store menu visibility state
        const [isMenuShown, setIsMenuShown] = useState(false);
        // ref to store a tag for navigation purposes
        const linkRef: Ref<HTMLAnchorElement> = useRef(null);
        const surveyUUIDWithParams = url.split("/")[2] || "";
        const surveyUUID = surveyUUIDWithParams.split("?")[0];

        if (!surveyUUID) {
            return null;
        }

        const survey = getSurveyByUuid(surveyUUID);

        if (!survey) {
            return null;
        }

        const timeRageKey = extractKeyForTimeRange(
            survey.uuid,
            getTimeUnitFromUrlOrNone()
        );

        const timeRages = getTimeRangeByKey(timeRageKey);

        if (timeRages === undefined) {
            return <Spinner className={spinner_style} size="X-SMALL" />;
        }

        if (timeRages === null) {
            showDifftoneAlert(
                "Something went wrong, please reload and try again",
                "FAILURE"
            );
            return null;
        }

        const surveyName = survey ? survey.survey_name : "";
        const isInitiator = survey?.initiator === getUserUuid();

        const toggleActionsMenu = () => setIsMenuShown((state) => !state);

        const handleExport = (e: SyntheticEvent) => {
            preventPropagation(e);
            if (onExport) {
                onExport({ url, name, uuid });
            }
        };

        const handleDelete = (e: SyntheticEvent) => {
            preventPropagation(e);

            onClose();
            showDifftoneAlert(
                "Are you sure you want to permanently delete",
                "DELETE",
                async () => {
                    await userProfileStore.removeBookmark({ url, name, uuid });
                    showDifftoneAlert(
                        `${name} has been deleted successfully!`,
                        "SUCCESS"
                    );
                },
                () => {},
                name
            );
        };

        const handleGoToView = (e: SyntheticEvent) => {
            preventPropagation(e);

            if (linkRef.current) {
                linkRef.current.click();
            }
        };

        const handleShare = (e: SyntheticEvent) => {
            preventPropagation(e);

            if (!isInitiator) {
                return;
            }

            setShareResultCompareBy(compareBy);
            onShareView(name);
        };

        return (
            <a
                ref={linkRef}
                key={name}
                className={bookmark}
                href={url}
                onMouseEnter={toggleActionsMenu}
                onMouseLeave={toggleActionsMenu}
            >
                <img alt="" src={bookmarkIcon} className={bookmark_icon} />

                <span className={bookmark_name}>
                    <ShowDifftoneTooltip
                        tooltipPosition="top"
                        tip={`${surveyName} / ${name}`}
                    >
                        <span className={bookmark_name_inner}>
                            {`${surveyName} / ${name}`}
                        </span>
                    </ShowDifftoneTooltip>
                </span>

                <div
                    className={clsx(bookmark_actions, {
                        [bookmark_actions_shown]: isMenuShown,
                        [bookmark_actions_simple]: isSimpleResults,
                    })}
                >
                    <img alt="" src={resultsIcon} onClick={handleGoToView} />

                    <img
                        onClick={handleShare}
                        alt=""
                        src={shareIcon}
                        className={clsx(bookmark_actions_icon_export, {
                            [bookmark_actions_icon_export_disabled]:
                                !isInitiator,
                        })}
                    />

                    <img
                        onClick={handleDelete}
                        alt=""
                        src={deleteIcon}
                        className={bookmark_actions_icon}
                    />
                </div>
            </a>
        );
    }
);
