import React, { useState } from "react";
import { observer } from "mobx-react";
import { ResultsFilter } from "@difftone/types";
import { selectedFiltersActiveAsCompare } from "@difftone/reducers";

import { OptionsCheckboxList, FilterChip } from "./components";

import {
    filter_option,
    key_text,
    no_values_selected_placeholder,
} from "./filter-option.module.css";

export type FilterOptionProps = {
    filter: ResultsFilter;
    index: number;
    getActiveFilters: (filterUuid: string) => boolean[];
    setActiveFilters: (filterUuid: string, newValues: boolean[]) => void;
};

export const FilterOption = observer((props: FilterOptionProps) => {
    const { filter, getActiveFilters, setActiveFilters } = props;
    const [showOptions, setShowOptions] = useState(false);

    //display the selected filter values for shared results
    const displayedFilterChip = selectedFiltersActiveAsCompare([filter])[0];

    const selectedOptionsCounter = displayedFilterChip.filterValues.length || 0;

    const openOptionsList = () => {
        setShowOptions(true);
    };

    const closeOptionsList = () => {
        setShowOptions(false);
    };

    const assignTypeAndPassToOptionsList = () => {
        switch (filter.filterKey.question_type_name) {
            case "SINGLE_CHOICE":
                return (
                    <OptionsCheckboxList
                        filter={filter}
                        hideList={closeOptionsList}
                        getActiveFilters={getActiveFilters}
                        setActiveFilters={setActiveFilters}
                    />
                );
            case "MULTIPLE_CHOICE":
                return (
                    <OptionsCheckboxList
                        filter={filter}
                        hideList={closeOptionsList}
                        getActiveFilters={getActiveFilters}
                        setActiveFilters={setActiveFilters}
                    />
                );
            case "RATING":
                return (
                    <OptionsCheckboxList
                        filter={filter}
                        hideList={closeOptionsList}
                        getActiveFilters={getActiveFilters}
                        setActiveFilters={setActiveFilters}
                    />
                );
            case "DATE":
            case "TIME":
            case "SHORT_TEXT":
            case "LONG_TEXT":
                return (
                    <OptionsCheckboxList
                        filter={filter}
                        hideList={closeOptionsList}
                        getActiveFilters={getActiveFilters}
                        setActiveFilters={setActiveFilters}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div
            title={filter.filterKey.title}
            data-filter-option={filter.filterKey.uuid}
            className={filter_option}
        >
            <div onClick={openOptionsList} className={key_text}>
                <span>{filter.filterKey.nickname}</span>:
                {selectedOptionsCounter === 0 ? (
                    <div className={no_values_selected_placeholder}>Pick</div>
                ) : (
                    <>
                        <FilterChip filter={displayedFilterChip} />
                    </>
                )}
            </div>
            {showOptions ? assignTypeAndPassToOptionsList() : null}
        </div>
    );
});
