import React from "react";
import { observer } from "mobx-react";
import { ResultsFilter } from "@difftone/types";

import {
    filters_list_placeholder,
    first_filter,
} from "./filter-chip.module.css";

export type FiltersListPlaceholderProps = {
    filter: ResultsFilter;
};

export const FilterChip = observer((props: FiltersListPlaceholderProps) => {
    const { filter } = props;

    if (!filter.filterValues.length) {
        return null;
    }

    return (
        <div className={filters_list_placeholder}>
            &nbsp;
            <div className={first_filter}>
                {filter.filterValues[0].toString()}
            </div>
            &nbsp;
            {filter.filterValues.length > 1 ? (
                <span>+{filter.filterValues.length - 1}</span>
            ) : null}
        </div>
    );
});
