import React from "react";
import clsx from "clsx";
import { observer } from "mobx-react";

import { leftMenuStore } from "@difftone/stores";

import { MobileBackdrop } from "./components";

import {
    left_side_drawer,
    left_side_drawer_desktop,
    left_side_drawer_mobile,
    left_side_drawer_hidden,
    drawer_items,
    left_side_drawer_wrap,
} from "./left-menu.module.css";

export type LeftMenuProp = {
    isHiddenOnMobile?: boolean;
};

export const LeftMenu = observer(
    (props: React.PropsWithChildren<LeftMenuProp>) => {
        const { children, isHiddenOnMobile } = props;
        const { isMobileOpen, isOpen } = leftMenuStore;

        return (
            <>
                <div
                    className={clsx(left_side_drawer_desktop, {
                        [left_side_drawer_hidden]: !isOpen,
                    })}
                >
                    <MobileBackdrop />
                    <div className={left_side_drawer}>
                        <div className={drawer_items}>{children}</div>
                    </div>
                </div>

                <div
                    className={clsx(left_side_drawer_mobile, {
                        [left_side_drawer_wrap]: isHiddenOnMobile,
                        [left_side_drawer_hidden]: !isMobileOpen,
                    })}
                >
                    <MobileBackdrop />
                    <div className={left_side_drawer}>
                        <div className={drawer_items}>{children}</div>
                    </div>
                </div>
            </>
        );
    }
);
