import React from "react";

import { line_wrapper, line, future_time_line } from "./line.module.css";

export type LineProps = {};

export const Line = (props: LineProps) => {
    return (
        <div className={line_wrapper}>
            <div className={line}></div>
            <div className={future_time_line}></div>
        </div>
    );
};
