import React from "react";
import { observer } from "mobx-react";
import {
    leftMenuStore,
    publicProfileStore,
    sharedSurveyStore,
} from "@difftone/stores";
import { getUniquePublicProfilesUUIDsInSharedSurveysInbox } from "@difftone/actions";
import { getSortedSharedSurveys } from "@difftone/reducers";
import { Spinner, SpinnerOrTimeoutMessage } from "@difftone/shared-components";

import { MobileSharedTableRow } from "./components";
import { shared_surveys_table } from "./mobile-shared-survey-table.module.css";

export type MobileSharedSurveyTableProps = {};

export const MobileSharedSurveyTable = observer(() => {
    const sortedSharedSurveysList = getSortedSharedSurveys();

    if (!sharedSurveyStore.startInitialization) {
        sharedSurveyStore.init();
        return <Spinner size="LARGE" />;
    }

    if (!sharedSurveyStore.storeInitialize) {
        return <Spinner />;
    }

    if (!publicProfileStore.startInitialization) {
        const publicProfileUUIDs =
            getUniquePublicProfilesUUIDsInSharedSurveysInbox();

        publicProfileStore.init(publicProfileUUIDs);
    }

    if (!publicProfileStore.storeInitialize) {
        return <Spinner />;
    }

    if (!leftMenuStore.startInitialization) {
        leftMenuStore.init();
        return <Spinner />;
    }

    if (!leftMenuStore.storeInitialize) {
        return <Spinner />;
    }

    return (
        <div className={shared_surveys_table}>
            {sortedSharedSurveysList.length > 0 ? (
                sortedSharedSurveysList.map((sharedSurvey) => (
                    <MobileSharedTableRow
                        key={sharedSurvey.uuid}
                        sharedSurvey={sharedSurvey}
                    />
                ))
            ) : (
                <SpinnerOrTimeoutMessage
                    spinnerSize="LARGE"
                    spinnerFlag={!sharedSurveyStore.storeInitialize}
                    message="No Shared Surveys To Show"
                />
            )}
        </div>
    );
});
