import React from "react";
import { observer } from "mobx-react";
import {
    setDraftFiltersToReady,
    setFilterStatusModeToReady,
} from "@difftone/actions";
import { validateDashboardFilter } from "@difftone/reducers";

import { filter_buttons, apply_button } from "./filter-buttons.module.css";

export type FilterButtonsProps = {};

export const FilterButtons = observer((props: FilterButtonsProps) => {
    const applyFiltersHandler = () => {
        setFilterStatusModeToReady();

        const filterValidationResult = validateDashboardFilter();

        if (
            filterValidationResult.some(
                (validationResult) => !validationResult.is_valid
            )
        ) {
            return;
        }

        setDraftFiltersToReady();
    };

    return (
        <div className={filter_buttons}>
            <button onClick={applyFiltersHandler} className={apply_button}>
                Apply
            </button>
        </div>
    );
});
