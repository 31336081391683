import { makeAutoObservable } from "mobx";

import { SharedResult, UUID } from "@difftone/types";

import {
    getViewsSharedWithMe,
    initTargetSharedViewFromUrl,
    setIsStoreInitializing,
} from "./shared-result-internal-actions";

export type SharedViewsWithMeFilters =
    | "NAME"
    | "SHARED_BY"
    | "SURVEY_NAME"
    | "INITIATOR"
    | "CREATED"
    | null;

export type SortingSharedViewsWithMeProfile = {
    sortBy: SharedViewsWithMeFilters;
    ascending: boolean;
};

class SharedResultStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _sortingSharedResultProfile: SortingSharedViewsWithMeProfile = {
        sortBy: "NAME",
        ascending: false,
    };

    get sortingSharedResultProfile() {
        return this._sortingSharedResultProfile;
    }

    set sortingSharedResultProfile(
        newSortingSharedSurveyProfile: SortingSharedViewsWithMeProfile
    ) {
        this._sortingSharedResultProfile = newSortingSharedSurveyProfile;
    }

    private _isInitializingStarted: boolean = false;

    get isInitializingStarted(): boolean {
        return this._isInitializingStarted;
    }

    set isInitializingStarted(isInitializingStarted: boolean) {
        this._isInitializingStarted = isInitializingStarted;
    }

    private _isStoreInitializing: boolean = false;

    get isStoreInitializing(): boolean {
        return this._isStoreInitializing;
    }

    set isStoreInitializing(isStoreInitializing: boolean) {
        this._isStoreInitializing = isStoreInitializing;
    }

    private _isTargetSharedResultInitialized = false;

    get isTargetSharedResultInitialized() {
        return this._isTargetSharedResultInitialized;
    }

    set isTargetSharedResultInitialized(isInitialized) {
        this._isTargetSharedResultInitialized = isInitialized;
    }

    private _targetSharedResult: SharedResult | undefined;

    get targetSharedResult(): SharedResult | undefined {
        return this._targetSharedResult;
    }

    set targetSharedResult(target: SharedResult | undefined) {
        this._targetSharedResult = target;
    }

    private _viewsISharedWith: SharedResult[] = [];

    get viewsISharedWith(): SharedResult[] {
        return this._viewsISharedWith;
    }

    set viewsISharedWith(views: SharedResult[]) {
        this._viewsISharedWith = views;
    }

    private _sharedViews: SharedResult[] = [];

    get sharedViews(): SharedResult[] {
        return this._sharedViews;
    }

    set sharedViews(views: SharedResult[]) {
        this._sharedViews = views;
    }

    public initTargetView = () => {
        initTargetSharedViewFromUrl();
    };
    public getSharedResultsSharedWithMeByUuid = (sharedResultsUuid: UUID) => {
        return this._sharedViews.find(
            (sharedResult) => sharedResult.uuid === sharedResultsUuid
        );
    };

    public init = () => {
        this.isInitializingStarted = true;
        this.isStoreInitializing = true;
        getViewsSharedWithMe();
    };

    public clearStore = () => {
        this._isTargetSharedResultInitialized = false;
        setIsStoreInitializing(false);
        this._isInitializingStarted = false;
        this._sortingSharedResultProfile = {
            sortBy: "NAME",
            ascending: false,
        };
        this._targetSharedResult = undefined;
        this._viewsISharedWith = [];
        this._sharedViews = [];
    };
}

export const sharedResultStore = new SharedResultStore();
