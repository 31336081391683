import {
    QuestionType,
    Question,
    SingleChoiceQuestion,
    MultipleChoiceQuestion,
    RatingQuestion,
} from "@difftone/types";

export const getQuestionOptionsOrEmptyArray = (
    question: Question<QuestionType>
) => {
    let res: string[] = [];

    switch (question.question_type_name) {
        case "SINGLE_CHOICE":
            res = (
                question as Question<SingleChoiceQuestion>
            ).content.choice_options.map((choiceOption) => choiceOption.label);
            break;
        case "MULTIPLE_CHOICE":
            res = (question as Question<MultipleChoiceQuestion>).content
                .choice_options;
            break;
        case "RATING":
            res = (
                question as Question<RatingQuestion>
            ).content.rating_options.map((ratingOption) => ratingOption.label);
            break;
        default:
            break;
    }

    return res;
};
