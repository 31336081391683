import { action } from "mobx";

import { clearCategoriesToDelete } from "@difftone/actions";
import {
    organizationDisplayStore,
    organizationStore,
    categoriesDisplayStore,
} from "@difftone/stores";
import { EmailAddress, OrganizationMode } from "@difftone/types";
import { getSelectedOrganizationOrThrow } from "@difftone/reducers";
import { getUserEmail } from "@difftone/procedures";

export const showPermissionsDialog = action(() => {
    organizationDisplayStore.isPermissionsDialogOpened = true;
});

export const closePermissionsDialog = action(() => {
    organizationDisplayStore.isPermissionsDialogOpened = false;
});

export const showCategoriesDialog = action(() => {
    organizationDisplayStore.isCategoriesDialogOpened = true;

    clearCategoriesToDelete();
    categoriesDisplayStore.clearCustomCategories();
});

export const closeCategoriesDialog = action(() => {
    organizationDisplayStore.isCategoriesDialogOpened = false;
});

export const addEmailsToOrganisationInitiators = action(
    (emails: EmailAddress[]) => {
        const _org = getSelectedOrganizationOrThrow();
        const _userEmail = getUserEmail();

        if (!_org) {
            throw Error("[Difftone]:: connt found organization");
        }

        emails.forEach((email) => {
            _org?.settings.initiators.push(email);
        });

        organizationStore.setOrganizationToMap(_userEmail, _org);
    }
);

export const removeEmailFromOrganisationInitiators = action(
    (email: EmailAddress) => {
        const _org = getSelectedOrganizationOrThrow();
        const _userEmail = getUserEmail();
        if (!_org) {
            throw Error("[Difftone]:: connt found organization");
        }

        const targetIndex = _org?.settings.initiators.findIndex(
            (e) => e === email
        );

        if (targetIndex === -1) {
            return;
        }

        _org?.settings.initiators.splice(targetIndex, 1);

        organizationStore.setOrganizationToMap(_userEmail, _org);
    }
);

export const changeOrganisationTempMode = action((mode: OrganizationMode) => {
    const _org = getSelectedOrganizationOrThrow();
    const _userEmail = getUserEmail();
    if (!_org) {
        throw Error("[Difftone]:: connt found organization");
    }

    _org.settings.mode = mode;
    organizationStore.setOrganizationToMap(_userEmail, _org);
});
