import React, { ChangeEvent, useState } from "react";
import { observer } from "mobx-react";

import { ResultsFilter } from "@difftone/types";
import { GeneratorMenu } from "./components";

import {
    filter_generator,
    input_filed,
    clear_input,
} from "./filter-generator.module.css";

export type FilterGeneratorProps = {
    addFilter: (newFilter: ResultsFilter) => void;
    selectedFilters: ResultsFilter[];
};

const DEFAULT_WIDTH = 8;

export const FilterGenerator = observer((props: FilterGeneratorProps) => {
    const { addFilter, selectedFilters } = props;
    const [inputText, setInputText] = useState("");
    const [isInputFocused, setIsInputFocused] = useState(false);

    //TODO set input width to expand dynamically according to input
    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setInputText(event.target.value);
    };

    const clearInputText = () => {
        setInputText("");
    };

    const filteredByInputText = selectedFilters.filter((_filter) =>
        _filter.filterKey.title.toLowerCase().includes(inputText.toLowerCase())
    );

    const showMenu = () => {
        setIsInputFocused(true);
    };
    const hideMenu = () => {
        setIsInputFocused(false);
    };

    return (
        <div className={filter_generator}>
            <input
                onFocus={showMenu}
                onBlur={hideMenu}
                className={input_filed}
                style={{ width: `${inputText.length * DEFAULT_WIDTH}px` }}
                onChange={onChangeHandler}
                type="text"
                value={inputText}
                placeholder="Add filter here"
            />
            {inputText ? (
                <div className={clear_input}>
                    <svg
                        onClick={clearInputText}
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="9.5" cy="9.5" r="9" fill="#035375" />
                        <path
                            d="M13.3225 5.68353C13.2092 5.56992 13.0553 5.50607 12.8948 5.50607C12.7343 5.50607 12.5804 5.56992 12.467 5.68353L9.5 8.64448L6.53299 5.67747C6.41963 5.56385 6.26573 5.5 6.10523 5.5C5.94474 5.5 5.79083 5.56385 5.67747 5.67747C5.44084 5.9141 5.44084 6.29635 5.67747 6.53298L8.64449 9.5L5.67747 12.467C5.44084 12.7036 5.44084 13.0859 5.67747 13.3225C5.91411 13.5592 6.29636 13.5592 6.53299 13.3225L9.5 10.3555L12.467 13.3225C12.7036 13.5592 13.0859 13.5592 13.3225 13.3225C13.5592 13.0859 13.5592 12.7036 13.3225 12.467L10.3555 9.5L13.3225 6.53298C13.5531 6.30242 13.5531 5.9141 13.3225 5.68353Z"
                            fill="#EBF5F9"
                        />
                    </svg>
                </div>
            ) : null}
            {isInputFocused ? (
                <GeneratorMenu
                    addFilter={addFilter}
                    menuOptions={filteredByInputText}
                />
            ) : null}
        </div>
    );
});
