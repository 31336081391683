import React, { FocusEvent, ChangeEvent, useRef, useEffect } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import {
    CustomCheckboxInput,
    ValidationWrapper,
} from "@difftone/shared-components";
import {
    getValidationErrosByErrorCode,
    updateQuestionInWizard,
    validateWizardSurvey,
} from "@difftone/actions";
import {
    MultipleChoiceQuestion,
    Question,
    QuestionsDuplicationValidationType,
    SingleChoiceQuestion,
    ValidationResult,
} from "@difftone/types";
import { wizardStore } from "@difftone/stores";
import {
    getSurveyFromStoreByURL,
    getValidationErrorsFromStoreByUUID,
} from "@difftone/reducers";
import { warningMessages } from "@difftone/constants";

import {
    multiple_choice_option,
    choice_edit_input,
    other_choice_class,
    invalid_color_input,
} from "./multiple-choice-option.module.css";

const MAX_INPUT_LENGTH = 50;

export type MultipleChoiceOptionProps = {
    option: string;
    index: number;
    isLastOption: boolean;
    item: Question<MultipleChoiceQuestion>;
    onItemKeyDown: (event: KeyboardEvent, index: number) => void;
    addRef: (ref: any, index: number) => void;
};

export const MultipleChoiceOption = observer(
    (props: MultipleChoiceOptionProps) => {
        const { focusedWizardInputCard } = wizardStore;
        const { index, isLastOption, item, option, onItemKeyDown, addRef } =
            props;

        const inputRef: React.MutableRefObject<HTMLInputElement | null> =
            useRef(null);

        const survey = getSurveyFromStoreByURL();

        const isFocusedWizardCardItem =
            focusedWizardInputCard?.getAttribute("uuid") === item.uuid;

        const itemValidationErrors = getValidationErrorsFromStoreByUUID(
            item.uuid,
            survey
        );

        const itemOptionsUniquenessValidations = getValidationErrosByErrorCode(
            "QUESTION_OPTION_NAMES_DUPLICATION",
            itemValidationErrors
        ) as ValidationResult<QuestionsDuplicationValidationType>[];

        const isValid = !(
            itemOptionsUniquenessValidations.length > 0 &&
            itemOptionsUniquenessValidations[0]?.validation_error_type
                ?.duplication_indexes[option] &&
            itemOptionsUniquenessValidations[0]?.validation_error_type
                ?.duplication_indexes[option].length > 0 &&
            itemOptionsUniquenessValidations[0]?.validation_error_type
                ?.duplication_indexes[option][0] === index
        );

        let optionClassName = choice_edit_input;

        if (!isValid && isFocusedWizardCardItem) {
            optionClassName = [choice_edit_input, invalid_color_input].join(
                " "
            );
        }

        const onFocusHandler = (event: FocusEvent<HTMLInputElement>) => {
            event.target.select();
        };

        const onOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
            const indexToUpate = JSON.parse(
                event.currentTarget.getAttribute("data-index") as string
            );
            const copyOfCurrentQuestionChoices = [
                ...item.content.choice_options,
            ];
            copyOfCurrentQuestionChoices[indexToUpate] = event.target.value;
            const newContent = {
                ...item.content,
                choice_options: copyOfCurrentQuestionChoices,
            };

            updateQuestionInWizard(
                item as Question<MultipleChoiceQuestion>,
                "content",
                newContent
            );

            validateWizardSurvey();
        };

        const onBlurHandler = () => {
            validateWizardSurvey();
        };

        addRef(inputRef, index);

        //Use effect used to focus option if it is newly added.
        //We have no other way to get a newly added option becuase the new option is added to the survey object and then the element renders
        useEffect(() => {
            if (isLastOption && inputRef.current && index > 1) {
                inputRef.current.focus();
            }
        }, [index, isLastOption]);

        return (
            <div
                className={multiple_choice_option}
                onKeyDown={(e: any) => onItemKeyDown(e, index)}
            >
                <CustomCheckboxInput disabled={true} id={""} />
                <ValidationWrapper
                    isValid={isValid || !isFocusedWizardCardItem}
                    errorMessage={warningMessages.questionOptionsNotUniqueError}
                    tooltipPosition="RIGHT"
                    tooltipPositionOverride={
                        option.length > 0
                            ? { left: `calc(30px + ${option.length * 7}px)` }
                            : { left: "95px" }
                    }
                    mobileTooltipPosition={
                        option.length > 0
                            ? { left: `calc(12px + ${option.length * 6}px)` }
                            : { left: "40px" }
                    }
                >
                    <input
                        ref={inputRef}
                        maxLength={MAX_INPUT_LENGTH}
                        style={
                            option.length > 0
                                ? { width: `${option.length * 10}px` }
                                : { width: "200px" }
                        }
                        className={
                            option === "Other"
                                ? clsx(optionClassName, other_choice_class)
                                : optionClassName
                        }
                        data-index={index}
                        onBlur={onBlurHandler}
                        onChange={onOptionChange}
                        onFocus={onFocusHandler}
                        value={option}
                        placeholder={`Option ${index + 1}`}
                        type="text"
                    />
                </ValidationWrapper>
            </div>
        );
    }
);
