import React, { SyntheticEvent } from "react";
import { observer } from "mobx-react";
import {
    Answer,
    Question,
    RatingAnswer,
    RatingQuestion,
} from "@difftone/types";
import {
    surveyResponseDisplayStore,
    surveyResponseStore,
} from "@difftone/stores";
import {
    setFocusedSurveyResponseInputCard,
    updateSelectedSurveyNonFilterQuestionResponse,
} from "@difftone/actions";
import { Spinner } from "@difftone/shared-components";
import { getAnswerByQuestion } from "@difftone/reducers";

import {
    expanded_rating_container,
    select_rating_button,
    selected,
    rating_option_index,
} from "./mobile-survey-expanded-rating.module.css";

export type MobileSurveyExpandedRatingProps = {
    disabled: boolean;
    question: Question<RatingQuestion>;
    inPreviewMode: boolean;
};

export const MobileSurveyExpandedRating = observer(
    (props: MobileSurveyExpandedRatingProps) => {
        const { getFocusedSurveyCardElementByUUID } =
            surveyResponseDisplayStore;
        const { question, disabled, inPreviewMode } = props;

        let surveyResponseUUID: string | undefined;
        let surveyResponseInputCard: string | null;

        if (inPreviewMode) {
            surveyResponseUUID = "";
            surveyResponseInputCard = "";
        } else {
            const { getSelectedSurveyResponseUUID } = surveyResponseStore;
            const { focusedSurveyResponseInputCard } =
                surveyResponseDisplayStore;

            surveyResponseUUID = getSelectedSurveyResponseUUID();
            surveyResponseInputCard = focusedSurveyResponseInputCard;
        }

        if (surveyResponseUUID === undefined) {
            return <Spinner size="MEDIUM" />;
        }

        const answer = getAnswerByQuestion(
            question,
            surveyResponseUUID
        ) as Answer<RatingAnswer>;

        const onClick = (event: SyntheticEvent) => {
            if (inPreviewMode) {
                return;
            }
            const allExistingListElements: NodeListOf<HTMLDivElement> =
                document.querySelectorAll("div[data-survey-focused-card]");

            if (question.uuid !== surveyResponseInputCard) {
                setFocusedSurveyResponseInputCard(question.uuid);
                // @ts-ignore
                const value = JSON.parse(event.target.value);
                answer.content = value;
                updateSelectedSurveyNonFilterQuestionResponse(
                    answer,
                    surveyResponseUUID!
                );

                const currentElementIndex = Number(
                    document
                        .querySelector(
                            `div[data-survey-focused-card-uuid="${question.uuid}"]`
                        )
                        ?.getAttribute("index")
                );

                setFocusedNextElement(
                    currentElementIndex,
                    allExistingListElements
                );
                event.stopPropagation();
            } else {
                // @ts-ignore
                const value = JSON.parse(event.target.value);
                answer.content = value;
                updateSelectedSurveyNonFilterQuestionResponse(
                    answer,
                    surveyResponseUUID!
                );

                const currentElement = Number(
                    surveyResponseInputCard &&
                        getFocusedSurveyCardElementByUUID(
                            surveyResponseInputCard
                        )?.getAttribute("index")
                );
                setFocusedNextElement(currentElement, allExistingListElements);
                event.stopPropagation();
            }
        };

        const setFocusedNextElement = (
            currentIndexElement: number,
            allListOfElements: NodeListOf<HTMLDivElement>
        ) => {
            if (inPreviewMode) {
                return;
            }
            const nextElement = allListOfElements[currentIndexElement + 1];
            if (currentIndexElement < allListOfElements.length - 1) {
                setFocusedSurveyResponseInputCard(
                    nextElement.getAttribute("uuid")
                );
            } else if (currentIndexElement === allListOfElements.length - 1) {
                (
                    document.querySelector(
                        "button[data-submit-survey-button]"
                    ) as HTMLButtonElement
                ).focus();
                return;
            }
        };

        return (
            <div className={expanded_rating_container}>
                {question.content.rating_options.map((value, index) => {
                    const firstElementInOptions =
                        value === question.content.rating_options[0];
                    const lastElementInOptions =
                        value ===
                        question.content.rating_options[
                            question.content.rating_options.length - 1
                        ];

                    return (
                        <button
                            onClick={onClick}
                            value={JSON.stringify(value)}
                            disabled={disabled}
                            className={`${select_rating_button} ${
                                value.label === answer.content.label
                                    ? selected
                                    : ""
                            }`}
                            key={value.value}
                        >
                            {value.label}
                            <div className={rating_option_index}>
                                {value.value}
                                {firstElementInOptions ? <div>low</div> : null}
                                {lastElementInOptions ? <div>high</div> : null}
                            </div>
                        </button>
                    );
                })}
            </div>
        );
    }
);
