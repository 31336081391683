import React from "react";
import { observer } from "mobx-react";

import {
    OpenQuestionAnswers,
    UUID,
    ResultsMapAfterDigestion,
    SurveyClasses,
} from "@difftone/types";
import {
    resultsMapStore,
    resultsDisplayStore,
    sharedResultStore,
    categoriesDisplayStore,
    surveyResponsesSummaryStore,
    resultsDisplayFilterStore,
} from "@difftone/stores";
import {
    getCategoryFilterValuesAndActiveStatus,
    getNonFilterQuestionsFromSurvey,
    getSurveyFromStoreByUUID,
    getSurveyUUIDFromUrl,
    sortQuestionsByType,
} from "@difftone/reducers";
import {
    showDifftoneAlert,
    setLocation,
    addQuestionToResultsTable,
    setOpenedQuestionUUID,
} from "@difftone/actions";
import { constantAlertVariants, PAGE_URLS } from "@difftone/constants";
import {
    formatCategoriesQuestionsMap,
    formatCategoriesResultsRatingMap,
    getSortedCategoriesList,
} from "@difftone/reducers";
import { warningMessages } from "@difftone/constants";
import { COLUMN_0 } from "@difftone/common-constants";
import {
    anonymityLimitation,
    surveyResponseAnonymity,
} from "@difftone/anonymity-util";
import {
    OpenQuestionAnswersDialog,
    Spinner,
    ResultsQuestion,
    ResultsCategory,
    ResultsAnswer,
    ResultsTableHeader,
} from "@difftone/shared-components";

import {
    results_spreadsheet,
    results_questions,
    results_table_wrapper,
    results_row_spinner,
    results_questions_and_answers,
    results_questions_answer_stub,
} from "./results-spreadsheet.module.css";

export type ResultsSpreadsheetProps = {
    survey: SurveyClasses;
};

export const ResultsSpreadsheet = observer((props: ResultsSpreadsheetProps) => {
    const { survey } = props;
    const {
        resultsTableQuestions,
        openedSelectedTextQuestion,
        categoriesQuestionsDisplayModeToggle,
    } = resultsDisplayStore;

    const { getFiltersActiveAsCompares, getFiltersNotActiveAsCompares } =
        resultsDisplayFilterStore;
    const { targetSharedResult } = sharedResultStore;
    const { getResultsTableByResultsTableMapKey, extractKeyForTable } =
        resultsMapStore;

    const surveyUuid = getSurveyUUIDFromUrl();

    if (surveyUuid === null) {
        setLocation(PAGE_URLS.INBOX_ALL);
        showDifftoneAlert(
            constantAlertVariants.survey_url_failure.text,
            constantAlertVariants.survey_url_failure.variant
        );
        return null;
    }

    const selectedSurvey = survey || getSurveyFromStoreByUUID(surveyUuid);

    if (selectedSurvey === undefined) {
        return <Spinner size="LARGE" />;
    }

    if (selectedSurvey === null) {
        setLocation(PAGE_URLS.INBOX_ALL);
        showDifftoneAlert(warningMessages.surveyNotFound, "FAILURE");
        return null;
    }

    const surveyResponses =
        surveyResponsesSummaryStore.getSurveyResponseSummaryByFilterPlain(
            selectedSurvey.uuid,
            []
        );

    if (surveyResponses === undefined) {
        return <Spinner size="LARGE" />;
    }

    if (surveyResponses === null) {
        setLocation(PAGE_URLS.INBOX_ALL);
        showDifftoneAlert(warningMessages.surveyResponseNotFound, "FAILURE");
        return null;
    }

    const _keyForTable = extractKeyForTable(
        selectedSurvey,
        getFiltersNotActiveAsCompares(),
        getFiltersActiveAsCompares(),
        targetSharedResult
    );

    const resultsRow = getResultsTableByResultsTableMapKey(_keyForTable);

    if (resultsRow === undefined) {
        return (
            <div className={results_row_spinner}>
                <Spinner size="LARGE" />
            </div>
        );
    }

    if (resultsRow === null) {
        setLocation(PAGE_URLS.INBOX_ALL);
        showDifftoneAlert(
            constantAlertVariants.survey_results_failure.text,
            constantAlertVariants.survey_results_failure.variant
        );
        return null;
    }

    const closeOpenQuestionAnswersDialog = () => {
        setOpenedQuestionUUID(null);
    };

    const sortedNonFilterQuestions = sortQuestionsByType(
        getNonFilterQuestionsFromSurvey(selectedSurvey)
    );

    if (sortedNonFilterQuestions) {
        sortedNonFilterQuestions.forEach((question) => {
            if (!Object.keys(resultsTableQuestions).includes(question.uuid)) {
                addQuestionToResultsTable({
                    isOpen: false,
                    questionUUID: question.uuid,
                });
            }
        });
    }

    const categoriesQuestionsMap = formatCategoriesQuestionsMap(
        selectedSurvey.questions || []
    );

    const categoriesList: UUID[] = getSortedCategoriesList(
        categoriesQuestionsMap
    );

    if (!categoriesDisplayStore.isStoreInitialized) {
        categoriesDisplayStore.initCategoriesDisplayMap(categoriesList);
        return <Spinner size="LARGE" />;
    }

    const categoriesResultsMap = formatCategoriesResultsRatingMap(
        categoriesQuestionsMap,
        resultsRow
    );

    const { categoryFilterValues, isCategoryFilterActive } =
        getCategoryFilterValuesAndActiveStatus();

    const isQuestionsTogglerView =
        categoriesQuestionsDisplayModeToggle === "QUESTIONS";

    return (
        <div className={results_spreadsheet}>
            <div className={results_table_wrapper}>
                <ResultsTableHeader
                    filtersActiveAsCompares={getFiltersActiveAsCompares()}
                />
                <div className={results_questions_and_answers}>
                    <div className={results_questions}>
                        {isQuestionsTogglerView &&
                            sortedNonFilterQuestions.map((question, index) => {
                                return (
                                    <ResultsQuestion
                                        questionIndex={index}
                                        key={question.uuid}
                                        question={question}
                                        digestedResultsRow={
                                            resultsRow[question.uuid]
                                        }
                                        survey={selectedSurvey}
                                    />
                                );
                            })}

                        {!isQuestionsTogglerView &&
                            categoriesList.map((categoryUUID) => {
                                const { categoryName, questions } =
                                    categoriesQuestionsMap[categoryUUID];

                                if (
                                    !categoryFilterValues.includes(
                                        categoryName
                                    ) &&
                                    isCategoryFilterActive
                                ) {
                                    return null;
                                }

                                return (
                                    <ResultsCategory
                                        key={categoryUUID}
                                        categoryUUID={categoryUUID}
                                        categoryName={categoryName}
                                        questions={sortQuestionsByType(
                                            questions
                                        )}
                                        categoriesResultsRatingMap={
                                            categoriesResultsMap
                                        }
                                        questionsResults={resultsRow}
                                        survey={selectedSurvey}
                                    />
                                );
                            })}
                    </div>
                    <div>
                        {isQuestionsTogglerView &&
                            sortedNonFilterQuestions.map((question) => {
                                return (
                                    <div key={question.uuid}>
                                        <ResultsAnswer
                                            key={question.uuid}
                                            question={question}
                                            digestedResultsRow={
                                                resultsRow[question.uuid]
                                            }
                                            survey={selectedSurvey}
                                        />
                                        {openedSelectedTextQuestion ===
                                        question.uuid ? (
                                            <OpenQuestionAnswersDialog
                                                survey={selectedSurvey}
                                                question={question}
                                                onClose={
                                                    closeOpenQuestionAnswersDialog
                                                }
                                                openQuestionAnswers={
                                                    resultsRow[question.uuid][
                                                        COLUMN_0
                                                    ] as OpenQuestionAnswers
                                                }
                                            />
                                        ) : null}
                                    </div>
                                );
                            })}

                        {!isQuestionsTogglerView &&
                            categoriesList.map((categoryUUID) => {
                                const { questions, categoryName } =
                                    categoriesQuestionsMap[categoryUUID];
                                const categoryToggled =
                                    categoriesDisplayStore.getCategoryDisplayByUUID(
                                        categoryUUID
                                    );

                                const sortedQuestions =
                                    sortQuestionsByType(questions);

                                if (
                                    !categoryFilterValues.includes(
                                        categoryName
                                    ) &&
                                    isCategoryFilterActive
                                ) {
                                    return null;
                                }

                                const ratingQuestions = questions.filter(
                                    (question) =>
                                        question.question_type_name === "RATING"
                                );

                                return (
                                    <div key={categoryUUID}>
                                        <ResultsAnswer
                                            key={categoryUUID}
                                            question={questions[0]}
                                            digestedResultsRow={
                                                categoriesResultsMap[
                                                    categoryUUID
                                                ] as unknown as ResultsMapAfterDigestion
                                            }
                                            questionResultsRow={
                                                resultsRow[
                                                    ratingQuestions[0]?.uuid
                                                ]
                                            }
                                            isCategoryAverageRow
                                            survey={selectedSurvey}
                                        />
                                        {sortQuestionsByType(questions).map(
                                            (question) => {
                                                if (!categoryToggled) {
                                                    return null;
                                                }

                                                const isLastQuestionInList =
                                                    sortedQuestions[
                                                        sortedQuestions.length -
                                                            1
                                                    ] === question;

                                                return (
                                                    <div key={question.uuid}>
                                                        {!ratingQuestions.length &&
                                                        categoryToggled ? (
                                                            <div
                                                                className={
                                                                    results_questions_answer_stub
                                                                }
                                                            />
                                                        ) : null}

                                                        <ResultsAnswer
                                                            key={question.uuid}
                                                            question={question}
                                                            digestedResultsRow={
                                                                resultsRow[
                                                                    question
                                                                        .uuid
                                                                ]
                                                            }
                                                            isQuestionInsideCategory
                                                            survey={
                                                                selectedSurvey
                                                            }
                                                            isLastQuestionInList={
                                                                isLastQuestionInList
                                                            }
                                                        />
                                                        {openedSelectedTextQuestion ===
                                                        question.uuid ? (
                                                            <OpenQuestionAnswersDialog
                                                                survey={
                                                                    selectedSurvey
                                                                }
                                                                question={
                                                                    question
                                                                }
                                                                onClose={
                                                                    closeOpenQuestionAnswersDialog
                                                                }
                                                                openQuestionAnswers={
                                                                    resultsRow[
                                                                        question
                                                                            .uuid
                                                                    ][
                                                                        COLUMN_0
                                                                    ] as OpenQuestionAnswers
                                                                }
                                                            />
                                                        ) : null}
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
});
