import React from "react";
import { observer } from "mobx-react";
import { v4 as uuid } from "uuid";
import clsx from "clsx";
import dayjs from "dayjs";

import {
    inboxStore,
    surveyStore,
    publicProfileStore,
    navigationStore,
} from "@difftone/stores";
import {
    inboxSpinnerOff,
    inboxSpinnerOn,
    deleteAllAlertsOnDeleteSurvey,
    onSharedSurveyClick,
    removeDeletedSurveysFromStore,
    setLocation,
    showDifftoneAlert,
    getUniquePublicProfilesUUIDs,
    upsertSurveyToMapAndApiWithDebounce,
    cancelEmail,
} from "@difftone/actions";
import {
    Spinner,
    SpinnerOrTimeoutMessage,
    TableRowCell,
    TableRow,
    SurveyStatus,
    ShowDifftoneTooltip,
    ActionIconItem,
    NameWithTooltip,
    TableDateBySurveyClass,
} from "@difftone/shared-components";
import { sendEvent } from "@difftone/procedures";
import {
    exampleSurveysCount,
    filteredSurveys,
    getStatus,
} from "@difftone/reducers";
import {
    inbox_surveys_table,
    table_content_wrapper,
    overflow_padding_div,
    last_date_class,
    issued_class,
    survey_name_class,
    actions_menu,
    purpose_class,
    created_time,
    ended_survey_text_color,
} from "./inbox-drafts-table.module.css";
import { DisplaySurvey, SurveyClasses } from "@difftone/types";
import { constantAlertVariants } from "@difftone/constants";

import editIcon from "@difftone/assets/edit-survey.svg";
import duplicateIcon from "@difftone/assets/duplicate-survey.svg";
import resultsIcon from "@difftone/assets/survey-results.svg";
import shareIcon from "@difftone/assets/share-survey.svg";
import archiveIcon from "@difftone/assets/archive.svg";
import sendIcon from "@difftone/assets/send-survey.svg";
import deleteIcon from "@difftone/assets/delete-survey.svg";

import { TableHeader } from "./components";

export const InboxDraftsTable = observer(() => {
    const { currentPage } = navigationStore;
    const { surveysList } = surveyStore;
    const { surveysTableSpinnerActive } = inboxStore;
    const publicProfileStartInitialization =
        publicProfileStore.startInitialization;
    const sortedAndFilteredSurveys = filteredSurveys();

    if (!surveyStore.storeInitialize) {
        inboxSpinnerOn();
        return <Spinner />;
    }

    if (surveyStore.storeInitialize) {
        if (!publicProfileStartInitialization) {
            const uniquePublicProfiles = getUniquePublicProfilesUUIDs();

            publicProfileStore.init(uniquePublicProfiles);
        }
    }

    if (surveyStore.storeInitialize && publicProfileStartInitialization) {
        inboxSpinnerOff();
    }

    const onEdit = (survey: SurveyClasses) => {
        if (survey.status === "DRAFT") {
            setLocation(`/survey-wizard/${survey.uuid}`);
        }
    };

    const onDelete = async (survey: SurveyClasses) => {
        survey.deleted = true;
        upsertSurveyToMapAndApiWithDebounce(survey);

        if (survey.survey_class !== "ONGOING") {
            deleteAllAlertsOnDeleteSurvey(survey);
            survey.survey_events.forEach((surveyEvent) => {
                surveyEvent.event_emails?.forEach((emailUUID) => {
                    cancelEmail(emailUUID);
                });
            });
        }

        removeDeletedSurveysFromStore();
        showDifftoneAlert(
            `"${survey.survey_name}" ${constantAlertVariants.survey_deleted.text}`,
            constantAlertVariants.survey_deleted.variant
        );
        sendEvent("DELETE_SURVEY_BUTTON_CLICK", survey.uuid);
        return;
    };

    const onUnconfirmedDelete = (survey: SurveyClasses) => {
        const surveyStatus = getStatus(survey, "INITIATOR");
        if (surveyStatus === "ACTIVE") {
            showDifftoneAlert(
                constantAlertVariants.survey_warning_delete.text,
                constantAlertVariants.survey_warning_delete.variant,
                () => onDelete(survey),
                () => {},
                survey.survey_name
            );
            return;
        }
        showDifftoneAlert(
            constantAlertVariants.survey_delete.text,
            constantAlertVariants.survey_delete.variant,
            () => onDelete(survey),
            () => {},
            survey.survey_name
        );
    };

    const onDuplicate = (survey: SurveyClasses) => {
        setLocation(`${currentPage}/duplicate/${survey.uuid}`);
    };

    const onShare = (survey: SurveyClasses) => {
        const sharedSurveyUUID = uuid();
        onSharedSurveyClick(survey, sharedSurveyUUID);
        setLocation(`${currentPage}/share/${sharedSurveyUUID}`);
    };

    const initiatorActions = {
        share: onShare,
        delete: onUnconfirmedDelete,
        edit: onEdit,
        duplicate: onDuplicate,
        noAction: () => {},
    };

    return (
        <div className={inbox_surveys_table}>
            <TableHeader />
            <div className={table_content_wrapper}>
                {exampleSurveysCount(surveysList) > 0 ? (
                    sortedAndFilteredSurveys.map(
                        (displaySurvey: DisplaySurvey) => {
                            const survey =
                                displaySurvey.survey as SurveyClasses;
                            const surveyStatus = getStatus(
                                displaySurvey.survey,
                                displaySurvey.role
                            );

                            const purpose =
                                displaySurvey.survey.purpose.toLocaleLowerCase();

                            const createdAtDataFormat = displaySurvey.survey
                                .created_at
                                ? dayjs(displaySurvey.survey.created_at).format(
                                      "MMM DD, YYYY"
                                  )
                                : "none";

                            return (
                                <TableRow
                                    key={displaySurvey.display_surveyUUID}
                                    actionsClassName={actions_menu}
                                    actions={
                                        <>
                                            <ShowDifftoneTooltip
                                                tooltipPosition="bottom"
                                                tip="Edit"
                                            >
                                                <ActionIconItem
                                                    action={() =>
                                                        initiatorActions.edit(
                                                            survey
                                                        )
                                                    }
                                                    disabled={false}
                                                    icon={editIcon}
                                                />
                                            </ShowDifftoneTooltip>
                                            <ShowDifftoneTooltip
                                                tooltipPosition="bottom"
                                                tip="Send now"
                                            >
                                                <ActionIconItem
                                                    action={
                                                        initiatorActions.noAction
                                                    }
                                                    disabled
                                                    icon={sendIcon}
                                                />
                                            </ShowDifftoneTooltip>
                                            <ShowDifftoneTooltip
                                                tooltipPosition="bottom"
                                                tip="Results"
                                            >
                                                <ActionIconItem
                                                    action={
                                                        initiatorActions.noAction
                                                    }
                                                    icon={resultsIcon}
                                                    disabled
                                                />
                                            </ShowDifftoneTooltip>
                                            <ShowDifftoneTooltip
                                                tooltipPosition="bottom"
                                                tip="Share"
                                            >
                                                <ActionIconItem
                                                    action={() =>
                                                        initiatorActions.share(
                                                            survey
                                                        )
                                                    }
                                                    disabled={false}
                                                    icon={shareIcon}
                                                />
                                            </ShowDifftoneTooltip>
                                            <ShowDifftoneTooltip
                                                tooltipPosition="bottom"
                                                tip="Duplicate"
                                            >
                                                <ActionIconItem
                                                    action={() =>
                                                        initiatorActions.duplicate(
                                                            survey
                                                        )
                                                    }
                                                    disabled={false}
                                                    icon={duplicateIcon}
                                                />
                                            </ShowDifftoneTooltip>
                                            <ShowDifftoneTooltip
                                                tooltipPosition="bottom"
                                                tip="Archive"
                                            >
                                                <ActionIconItem
                                                    action={
                                                        initiatorActions.noAction
                                                    }
                                                    disabled
                                                    icon={archiveIcon}
                                                />
                                            </ShowDifftoneTooltip>
                                            <ShowDifftoneTooltip
                                                tooltipPosition="bottom"
                                                tip="Delete"
                                            >
                                                <ActionIconItem
                                                    action={() =>
                                                        initiatorActions.delete(
                                                            survey
                                                        )
                                                    }
                                                    disabled={false}
                                                    icon={deleteIcon}
                                                />
                                            </ShowDifftoneTooltip>
                                        </>
                                    }
                                    body={
                                        <>
                                            <TableRowCell
                                                className={clsx(
                                                    survey_name_class,
                                                    surveyStatus === "ENDED" &&
                                                        ended_survey_text_color,
                                                    "user_select"
                                                )}
                                            >
                                                <NameWithTooltip
                                                    text={survey.survey_name}
                                                />
                                            </TableRowCell>
                                            <TableRowCell
                                                className={purpose_class}
                                                tooltipText={purpose}
                                            >
                                                {purpose}
                                            </TableRowCell>
                                            <TableRowCell
                                                className={created_time}
                                            >
                                                <div>{createdAtDataFormat}</div>
                                            </TableRowCell>
                                            <TableRowCell
                                                className={clsx(
                                                    issued_class,
                                                    surveyStatus === "ENDED" &&
                                                        ended_survey_text_color
                                                )}
                                            >
                                                <TableDateBySurveyClass
                                                    survey={survey}
                                                    dateType="START_DATE"
                                                />
                                            </TableRowCell>
                                            <TableRowCell
                                                className={clsx(
                                                    last_date_class,
                                                    surveyStatus === "ENDED" &&
                                                        ended_survey_text_color
                                                )}
                                            >
                                                <TableDateBySurveyClass
                                                    survey={survey}
                                                    dateType="LAST_DATE"
                                                />
                                            </TableRowCell>
                                        </>
                                    }
                                />
                            );
                        }
                    )
                ) : (
                    <SpinnerOrTimeoutMessage
                        spinnerSize="LARGE"
                        spinnerFlag={surveysTableSpinnerActive}
                        message="No Surveys To Show"
                    />
                )}
                <div className={overflow_padding_div} />
            </div>
        </div>
    );
});
