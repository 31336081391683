import React from "react";

import { duplicate_header } from "./duplicate-header.module.css";

export type DuplicateHeaderProps = {};

export const DuplicateHeader = () => {
    return (
        <div className={duplicate_header}>
            <span>Elements to duplicate:</span>
        </div>
    );
};
