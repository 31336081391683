import React from "react";
import {
    Question,
    QuestionType,
    RatingQuestion,
    SupportedQuestionResultsMapAfterDigestionWithoutNulls,
} from "@difftone/types";
import { NO_ANSWER_KEY } from "@difftone/common-constants";
import { CategoryRatingTypeBox } from "./components";

export type CategoryResultBoxProps = {
    digestedResultsRow: SupportedQuestionResultsMapAfterDigestionWithoutNulls;
    questionResultsRow: SupportedQuestionResultsMapAfterDigestionWithoutNulls;
    columnKey: string;
    question: Question<QuestionType>;
    index: number;
};

export const CategoryResultBox = (props: CategoryResultBoxProps) => {
    const {
        digestedResultsRow,
        questionResultsRow,
        columnKey,
        question,
        index,
    } = props;

    const copyQuestionResultsRow = JSON.parse(
        JSON.stringify(questionResultsRow)
    );

    Object.keys(copyQuestionResultsRow).forEach((column) => {
        delete copyQuestionResultsRow[column][NO_ANSWER_KEY];
    });

    switch (question.question_type_name) {
        case "RATING":
            return (
                <CategoryRatingTypeBox
                    columnKey={columnKey}
                    index={index}
                    question={question as Question<RatingQuestion>}
                    questionResultsRow={copyQuestionResultsRow}
                    resultValue={Number(digestedResultsRow[columnKey])}
                />
            );
        default:
            return (
                <CategoryRatingTypeBox
                    columnKey={columnKey}
                    index={index}
                    question={question as Question<RatingQuestion>}
                    questionResultsRow={copyQuestionResultsRow}
                    resultValue={Number(digestedResultsRow[columnKey])}
                />
            );
    }
};
