import {
    DateAndTime,
    BaseSurvey,
    SurveyEvent,
    TimelineEvent,
    SimpleSurvey,
} from "@difftone/types";
import {
    getLastDateToFill,
    getPublishedDate,
    getStatus,
} from "@difftone/reducers";
import { UiStatus } from "@difftone/frontend-common";
import {
    getNormalizeOffsetInMilisecondsFromDateString,
    totalTimeInMiliSecondsFromString,
} from "@difftone/time-utils";
import { showDifftoneAlert } from "@difftone/actions";
import { warningMessages } from "@difftone/constants";
import { wizardDisplayStore } from "@difftone/stores";

const DEFAULT_DISTANCE_BETWEEN_EVENTS = 85;
const ICON_HEIGHT = 14;
const LAST_INDEX_ITEM = -1;
const CURRENT_EVENT_DISTANCE = 35;
const LAST_ITEM_DISTANCE = 43;
const FIRST_FUTURE_ITEM_DISTANCE = 28;

export const distancesArray = (sortedEvents: TimelineEvent[]) => {
    return sortedEvents.map((surveyEvent, index) => {
        const nextEvent = sortedEvents[index + 1];

        if (nextEvent?.isCurrentTime) {
            return CURRENT_EVENT_DISTANCE;
        }
        if (surveyEvent.isCurrentTime) {
            return CURRENT_EVENT_DISTANCE;
        }
        if (surveyEvent === sortedEvents.at(LAST_INDEX_ITEM))
            return LAST_ITEM_DISTANCE;

        return DEFAULT_DISTANCE_BETWEEN_EVENTS;
    });
};

export const distancesArrayFutureEvents = (sortedEvents: TimelineEvent[]) => {
    return sortedEvents.map((surveyEvent) => {
        if (surveyEvent.isCurrentTime) {
            return FIRST_FUTURE_ITEM_DISTANCE;
        }
        if (surveyEvent === sortedEvents.at(LAST_INDEX_ITEM)) return 0;

        return DEFAULT_DISTANCE_BETWEEN_EVENTS;
    });
};

export const timelineHeight = (sortedEvents: TimelineEvent[]) => {
    const iconsHeight = sortedEvents.length * ICON_HEIGHT;

    const distances = distancesArray(sortedEvents);

    const timeLineHeight = distances.reduce(
        (totalHeight, currentHeight) => totalHeight + currentHeight,
        0
    );

    return timeLineHeight + iconsHeight;
};

export const calculatePassedAndFutureTimeline = (
    sortedEvents: TimelineEvent[]
) => {
    const copySortedEvents: TimelineEvent[] = JSON.parse(
        JSON.stringify(sortedEvents)
    );
    const currentTimeEventIndex = copySortedEvents.findIndex(
        (surveyEvent) => surveyEvent.isCurrentTime
    );

    const prevEvents = copySortedEvents.slice(0, currentTimeEventIndex);
    const futureEvents = copySortedEvents.splice(currentTimeEventIndex);

    const passedEventsTimelineHeight = timelineHeight(prevEvents);
    const futureEvent: number[] = distancesArrayFutureEvents(futureEvents);

    const iconHeights = futureEvents.length * ICON_HEIGHT;

    const futureEventTimelineHeight =
        futureEvent.reduce((sum, nexValue) => sum + nexValue, 0) + iconHeights;

    return { passedEventsTimelineHeight, futureEventTimelineHeight };
};

export const isDisabledEventCalculation = (
    survey: BaseSurvey,
    surveyEvent: SurveyEvent
) => {
    const surveyStatus = getStatus(survey);

    let disabledEvent: boolean = false;

    switch (surveyEvent.system_survey_event_type) {
        case "LAST_DATE_TO_FILL":
            disabledEvent = surveyStatus === "ENDED";
            break;
        case "PUBLISHED_DATE":
            disabledEvent =
                surveyStatus === "ACTIVE" ||
                surveyStatus === "ENDED" ||
                surveyStatus === "COMPLETED";
            break;
        default:
            disabledEvent = false;
    }

    return disabledEvent;
};

export const validateEventChange = (
    surveyEvent: SurveyEvent,
    survey: SimpleSurvey,
    dateAndTime: DateAndTime
): boolean => {
    let isValid: boolean = true;

    const publishDateInMiliseconds = getPublishedDate(survey);
    const surveyStatus = getStatus(survey);

    if (wizardDisplayStore.isEditableSurvey && surveyStatus === "DRAFT") {
        return isValid;
    }

    const selectedDateAndTimeInMiliSeconds =
        totalTimeInMiliSecondsFromString(dateAndTime.date, dateAndTime.time) -
        getNormalizeOffsetInMilisecondsFromDateString(dateAndTime.date);

    if (
        !publishDateInMiliseconds ||
        (selectedDateAndTimeInMiliSeconds < publishDateInMiliseconds &&
            surveyEvent.system_survey_event_type !== "PUBLISHED_DATE")
    ) {
        return (isValid = false);
    }
    if (
        surveyEvent.system_survey_event_type === "PUBLISHED_DATE" &&
        surveyStatus === "SAVED"
    ) {
        const lastDateToFill = getLastDateToFill(survey);
        if (
            lastDateToFill &&
            selectedDateAndTimeInMiliSeconds > lastDateToFill
        ) {
            showDifftoneAlert(
                "Please extend complete survey deadline event before changing time to send out",
                "GENERAL"
            );

            return (isValid = false);
        }
    }

    if (surveyEvent.system_survey_event_type === "LAST_DATE_TO_FILL") {
        if (selectedDateAndTimeInMiliSeconds < Date.now()) {
            showDifftoneAlert(
                warningMessages.lastDateToFillGreaterThenNowError,
                "FAILURE"
            );
            return (isValid = false);
        }
    }

    return isValid;
};

export const isEditableSurveyByStatus = (survey: BaseSurvey): boolean => {
    const surveyStatus: UiStatus = getStatus(survey);

    let isEditableSurvey = false;

    switch (surveyStatus) {
        case "DRAFT":
            isEditableSurvey = true;
            break;
        case "SAVED":
        case "COMPLETED":
        case "ACTIVE":
        case "PAUSED":
        case "ENDED":
        case "FETCHING_STATUS":
            isEditableSurvey = false;
            break;
        default:
            isEditableSurvey = false;
    }

    return isEditableSurvey;
};
