import React from "react";
import timeIcon from "@difftone/assets/clock-icon.svg";

import { expanded_time_container } from "./expanded-time.module.css";

export type ExpandedTimeProps = {
    disabled: boolean;
};

export const ExpandedTime = (props: ExpandedTimeProps) => {
    return (
        <div className={expanded_time_container}>
            <input disabled={props.disabled} type="time" />
            <img alt="time" src={timeIcon} />
        </div>
    );
};
