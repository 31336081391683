import React, { ChangeEvent, Ref, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { SurveyEventAlert, SurveyEvent } from "@difftone/types";
import {
    debounceSetAlertToStore,
    debounceUpsertSurveyToApi,
} from "@difftone/actions";

import eventMessage from "@difftone/assets/message-icon.svg";
import {
    alert_message_input,
    message_wrapper,
} from "./alert-message-input.module.css";
import {
    getAlertOffsetInMilliSeconds,
    totalTimeInMiliSecondsFromString,
} from "@difftone/time-utils";

export type AlertMessageInputProps = {
    eventAlert: SurveyEventAlert;
    surveyEvent: SurveyEvent;
    onChangeSurveyEventAlert?: (surveyEventAlert: SurveyEventAlert) => void;
};

export const AlertMessageInput = observer((props: AlertMessageInputProps) => {
    const { eventAlert, surveyEvent, onChangeSurveyEventAlert } = props;

    const defaultOnChangeEventAlert = (eventAlert: SurveyEventAlert) => {
        debounceSetAlertToStore(eventAlert);
        debounceUpsertSurveyToApi();
    };

    const _onChangeSurveyEventAlert =
        onChangeSurveyEventAlert || defaultOnChangeEventAlert;

    const componentRef: Ref<HTMLTextAreaElement> = useRef(null);

    const optionalMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        eventAlert.content.message = event.target.value;
        _onChangeSurveyEventAlert(eventAlert);
    };

    const alertDate =
        totalTimeInMiliSecondsFromString(
            surveyEvent.date_utc!,
            surveyEvent.day_utc_time!
        ) + getAlertOffsetInMilliSeconds(eventAlert);

    const isPastEventAlert = alertDate < Date.now();

    // Used for input height change on every new / removed line inside alert message
    useEffect(() => {
        if (componentRef && componentRef.current) {
            componentRef.current.style.height = "0px";
            const scrollHeight = componentRef.current.scrollHeight;
            componentRef.current.style.height = `${scrollHeight}px`;
        }
    }, [eventAlert.content.message]);

    return (
        <div className={message_wrapper}>
            <img src={eventMessage} alt="message" />
            <textarea
                disabled={isPastEventAlert}
                ref={componentRef}
                value={eventAlert.content.message}
                onChange={optionalMessageChange}
                wrap="hard"
                placeholder="Include message (optional)"
                className={alert_message_input}
            />
        </div>
    );
});
