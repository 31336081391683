import React from "react";
import { BaseSurvey } from "@difftone/types";

import {
    mobile_results_header,
    mobile_header_wrapper,
} from "./mobile-results-header.module.css";

export type MobileResultsHeaderProps = {
    survey: BaseSurvey;
};

export const MobileResultsHeader = (props: MobileResultsHeaderProps) => {
    const { survey } = props;

    return (
        <div className={mobile_header_wrapper}>
            <div className={mobile_results_header}>{survey.survey_name}</div>
        </div>
    );
};
