import React, { useState, FocusEvent, KeyboardEvent } from "react";
import ReactOutsideClickHandler from "react-outside-click-handler";
import { observer } from "mobx-react";
import clsx from "clsx";
import { Question, RatingQuestion } from "@difftone/types";

import {
    constantAlertVariants,
    WIZARD_DIFFERENT_RATING_TOOLTIP,
} from "@difftone/constants";
import { wizardStore, wizardDisplayStore } from "@difftone/stores";
import {
    showDifftoneAlert,
    updateQuestionInWizard,
    validateWizardSurvey,
    setRatingQuestionAutoScaleRange,
    setWasDifferentRatingScaleTooltipAlreadyShown,
    resetQuestionRatingContent,
} from "@difftone/actions";
import { ShowDifftoneTooltip } from "@difftone/shared-components";

import addRating from "@difftone/assets/add-rating-icon.svg";
import addRatingDisabled from "@difftone/assets/add-rating-icon-disabled.svg";
import removeRating from "@difftone/assets/remove-rating-icon.svg";
import removeRatingDisabled from "@difftone/assets/remove-rating-icon-disabled.svg";
import editQuestionIcon from "@difftone/assets/mobile-edit-rating-question-icon-enabled.svg";

import {
    expanded_rating_container,
    expanded_rating_container_not_edit_mode,
    select_rating_button,
    rating_add_button,
    enabled,
    disabled_style,
    selected,
    rating_input_field,
    rating_remove_button,
    edit_mode_button,
    rating_options_wrapper,
    rating_options_wrapper_edit_mode,
    edit_mode_active,
    edit_mode_tick_left,
    edit_mode_ticks_right,
    rating_remove_button_disabled,
    rating_add_button_disabled,
    index_label,
    edit_mode_button_text,
    edit_mode_button_text_active,
    reset_button,
    edit_rating_question_buttons,
} from "./mobile-expanded-rating.module.css";

const MAX_RATING_LABEL_LENGTH = 20;
const MIN_NUMBER_OF_OPTIONS = 3;
const MAX_NUMBER_OF_OPTIONS = 10;
const MAX_VALUE_LENGTH_OF_TEXT_IN_BUTTON = 13;

export type MobileExpandedRatingProps = {
    disabled: boolean;
    ratingObject: Question<RatingQuestion>;
    questionNumber?: number;
};

export const MobileExpandedRating = observer(
    (props: MobileExpandedRatingProps) => {
        const { disabled, ratingObject, questionNumber = 0 } = props;
        const { ratingQuestionAutoScaleRange } = wizardStore;
        const { wasDifferentRatingScaleTooltipAlreadyShown } =
            wizardDisplayStore;
        //UseState for only internal changes
        const [questionEditMode, setQuestionEditMode] = useState(false);
        //UseState for only internal changes
        const [isTooltipVisible, setIsTooltipVisible] = useState(false);

        if (
            ratingObject.content.rating_options.length ===
                ratingQuestionAutoScaleRange &&
            isTooltipVisible
        ) {
            setIsTooltipVisible(false);
        } else if (
            ratingObject.content.rating_options.length !==
                ratingQuestionAutoScaleRange &&
            !isTooltipVisible
        ) {
            setIsTooltipVisible(true);
        }

        const [selectedRatingStyle, setSelectedRatingStyle] = useState(
            disabled
                ? new Array(ratingObject.content.rating_options.length).fill(
                      clsx(select_rating_button, disabled_style)
                  )
                : new Array(ratingObject.content.rating_options.length).fill(
                      select_rating_button
                  )
        );

        const onAddRatingOption = () => {
            if (
                ratingObject.content.rating_options.length <
                MAX_NUMBER_OF_OPTIONS
            ) {
                const copyOfContent = {
                    ...ratingObject.content,
                    rating_options: [
                        ...ratingObject.content.rating_options,
                        {
                            value:
                                ratingObject.content.rating_options.length + 1,
                            label: `${
                                ratingObject.content.rating_options.length + 1
                            }`,
                        },
                    ],
                };

                if (questionNumber === 0) {
                    setRatingQuestionAutoScaleRange(
                        copyOfContent.rating_options.length
                    );
                }

                updateQuestionInWizard(
                    ratingObject as Question<RatingQuestion>,
                    "content",
                    copyOfContent
                );

                setSelectedRatingStyle(
                    disabled
                        ? new Array(
                              ratingObject.content.rating_options.length + 1
                          ).fill(clsx(select_rating_button, disabled_style))
                        : new Array(
                              ratingObject.content.rating_options.length + 1
                          ).fill(select_rating_button)
                );
            } else {
                showDifftoneAlert(
                    constantAlertVariants.rating_limit_reached.text,
                    constantAlertVariants.rating_limit_reached.variant
                );
            }

            validateWizardSurvey();

            if (
                questionNumber > 0 &&
                ratingObject.content.rating_options.length + 1 !==
                    ratingQuestionAutoScaleRange
            ) {
                setIsTooltipVisible(true);
            } else {
                setIsTooltipVisible(false);
            }
        };

        const onDeleteRatingOption = () => {
            if (
                ratingObject.content.rating_options.length >
                MIN_NUMBER_OF_OPTIONS
            ) {
                const copyOfRatingOptions = [
                    ...ratingObject.content.rating_options,
                ];
                copyOfRatingOptions.pop();
                const copyOfContent = {
                    ...ratingObject.content,
                    rating_options: copyOfRatingOptions,
                };

                if (questionNumber === 0) {
                    setRatingQuestionAutoScaleRange(copyOfRatingOptions.length);
                }

                updateQuestionInWizard(
                    ratingObject as Question<RatingQuestion>,
                    "content",
                    copyOfContent
                );

                setSelectedRatingStyle(
                    disabled
                        ? new Array(
                              ratingObject.content.rating_options.length
                          ).fill(clsx(select_rating_button, disabled_style))
                        : new Array(
                              ratingObject.content.rating_options.length
                          ).fill(select_rating_button)
                );

                validateWizardSurvey();

                if (
                    questionNumber > 0 &&
                    ratingObject.content.rating_options.length - 1 !==
                        ratingQuestionAutoScaleRange
                ) {
                    setIsTooltipVisible(true);
                } else {
                    setIsTooltipVisible(false);
                }
            } else {
                return;
            }
        };

        const onSelectedRating = (selectedIndex: number) => {
            if (!disabled) {
                const copy = [...selectedRatingStyle];
                const mapped = copy.map((item, index) => {
                    if (index === selectedIndex) {
                        return clsx(item, selected);
                    } else {
                        return disabled
                            ? clsx(select_rating_button, disabled_style)
                            : clsx(select_rating_button, enabled);
                    }
                });
                setSelectedRatingStyle(mapped);
            }
        };

        const onChangeEditModeQuestions = () => {
            setQuestionEditMode((prevMode) => !prevMode);
        };

        const onChangeRatingText = (
            event: React.ChangeEvent<HTMLTextAreaElement>
        ) => {
            const index = JSON.parse(
                event.currentTarget.getAttribute("data-index") as string
            );
            const copyOfRatingOptions = [
                ...ratingObject.content.rating_options,
            ];
            copyOfRatingOptions[index].label = event.target.value;
            const copyOfContent = {
                ...ratingObject.content,
                rating_options: copyOfRatingOptions,
            };
            updateQuestionInWizard(
                ratingObject as Question<RatingQuestion>,
                "content",
                copyOfContent
            );
        };

        const resetQuestionHandler = () => {
            showDifftoneAlert(
                "Are you sure that you want to reset current question?, The data will be lost",
                "GENERAL",
                () => resetQuestionRatingContent(ratingObject),
                () => {}
            );
        };

        const inputFocusedHandler = (
            event: FocusEvent<HTMLTextAreaElement>
        ) => {
            event.target.select();
        };

        const overrideWhiteSpacesInput = (
            event: KeyboardEvent<HTMLTextAreaElement>
        ) => {
            if (event.key === " ") {
                event.preventDefault();
                event.currentTarget.value = event.currentTarget.value + " ";
            }
        };

        const isRemoveButtonDisabled =
            ratingObject.content.rating_options.length <= MIN_NUMBER_OF_OPTIONS;

        const isAddButtonDisabled =
            ratingObject.content.rating_options.length >= MAX_NUMBER_OF_OPTIONS;

        const containerStyle = questionEditMode
            ? expanded_rating_container
            : expanded_rating_container_not_edit_mode;

        return (
            <div className={containerStyle}>
                {disabled && questionEditMode ? (
                    <button
                        disabled={isRemoveButtonDisabled}
                        onClick={onDeleteRatingOption}
                        className={
                            isRemoveButtonDisabled
                                ? clsx(
                                      rating_remove_button_disabled,
                                      rating_remove_button
                                  )
                                : rating_remove_button
                        }
                    >
                        <img
                            src={
                                isRemoveButtonDisabled
                                    ? removeRatingDisabled
                                    : removeRating
                            }
                            alt="remove rating"
                        />
                    </button>
                ) : null}
                <div
                    className={
                        questionEditMode
                            ? clsx(
                                  rating_options_wrapper,
                                  rating_options_wrapper_edit_mode
                              )
                            : rating_options_wrapper
                    }
                >
                    {ratingObject.content.rating_options.map((val, index) => {
                        const firstElementInOptions =
                            val === ratingObject.content.rating_options[0];

                        const lastElementInOptions =
                            val ===
                            ratingObject.content.rating_options[
                                ratingObject.content.rating_options.length - 1
                            ];

                        if (
                            lastElementInOptions &&
                            questionNumber !== 0 &&
                            !wasDifferentRatingScaleTooltipAlreadyShown
                        ) {
                            return (
                                <button
                                    onClick={() => onSelectedRating(index)}
                                    className={selectedRatingStyle[index]}
                                    key={val.value}
                                >
                                    {questionEditMode ? (
                                        <span className={edit_mode_tick_left}>
                                            "
                                        </span>
                                    ) : null}

                                    <ReactOutsideClickHandler
                                        onOutsideClick={() => {
                                            if (isTooltipVisible) {
                                                setWasDifferentRatingScaleTooltipAlreadyShown(
                                                    true
                                                );
                                            }
                                        }}
                                    >
                                        <ShowDifftoneTooltip
                                            tip={
                                                WIZARD_DIFFERENT_RATING_TOOLTIP
                                            }
                                            tooltipPosition="bottom"
                                            alwaysVisible={isTooltipVisible}
                                            disableHover
                                        >
                                            <textarea
                                                style={{
                                                    paddingTop:
                                                        val.label.length >
                                                        MAX_VALUE_LENGTH_OF_TEXT_IN_BUTTON
                                                            ? "0px"
                                                            : "20px",
                                                }}
                                                onFocus={inputFocusedHandler}
                                                onKeyDown={
                                                    overrideWhiteSpacesInput
                                                }
                                                disabled={
                                                    questionEditMode
                                                        ? !disabled
                                                        : disabled
                                                }
                                                data-index={index}
                                                maxLength={
                                                    MAX_RATING_LABEL_LENGTH
                                                }
                                                onChange={onChangeRatingText}
                                                className={rating_input_field}
                                                value={val.label}
                                                rows={2}
                                            />
                                        </ShowDifftoneTooltip>
                                    </ReactOutsideClickHandler>

                                    {questionEditMode ? (
                                        <span className={edit_mode_ticks_right}>
                                            "
                                        </span>
                                    ) : null}
                                    <div className={index_label}>
                                        <span>{index + 1}</span>
                                        {firstElementInOptions ? (
                                            <div>low</div>
                                        ) : null}
                                        {lastElementInOptions ? (
                                            <div>high</div>
                                        ) : null}
                                    </div>
                                </button>
                            );
                        }

                        return (
                            <button
                                onClick={() => onSelectedRating(index)}
                                className={selectedRatingStyle[index]}
                                key={val.value}
                            >
                                {questionEditMode ? (
                                    <span className={edit_mode_tick_left}>
                                        "
                                    </span>
                                ) : null}

                                <textarea
                                    style={{
                                        paddingTop:
                                            val.label.length >
                                            MAX_VALUE_LENGTH_OF_TEXT_IN_BUTTON
                                                ? "0px"
                                                : "20px",
                                    }}
                                    onFocus={inputFocusedHandler}
                                    onKeyDown={overrideWhiteSpacesInput}
                                    disabled={
                                        questionEditMode ? !disabled : disabled
                                    }
                                    data-index={index}
                                    maxLength={MAX_RATING_LABEL_LENGTH}
                                    onChange={onChangeRatingText}
                                    className={rating_input_field}
                                    value={val.label}
                                    rows={2}
                                />
                                {questionEditMode ? (
                                    <span className={edit_mode_ticks_right}>
                                        "
                                    </span>
                                ) : null}
                                <div className={index_label}>
                                    <span>{index + 1}</span>
                                    {firstElementInOptions ? (
                                        <div>low</div>
                                    ) : null}
                                    {lastElementInOptions ? (
                                        <div>high</div>
                                    ) : null}
                                </div>
                            </button>
                        );
                    })}
                </div>
                {disabled && questionEditMode ? (
                    <button
                        onClick={onAddRatingOption}
                        className={clsx(
                            rating_add_button,
                            isAddButtonDisabled && rating_add_button_disabled
                        )}
                    >
                        <img
                            alt="plus icon"
                            src={
                                isAddButtonDisabled
                                    ? addRatingDisabled
                                    : addRating
                            }
                        />
                    </button>
                ) : null}
                {disabled ? (
                    <div className={edit_rating_question_buttons}>
                        {questionEditMode ? (
                            <button
                                className={reset_button}
                                onClick={resetQuestionHandler}
                            >
                                Reset
                            </button>
                        ) : null}
                        <button
                            className={
                                questionEditMode
                                    ? clsx(edit_mode_active, edit_mode_button)
                                    : edit_mode_button
                            }
                            onClick={onChangeEditModeQuestions}
                        >
                            {questionEditMode ? null : (
                                <img
                                    alt="edit-mode-icon"
                                    src={editQuestionIcon}
                                />
                            )}
                            <span
                                className={clsx(
                                    edit_mode_button_text,
                                    questionEditMode &&
                                        edit_mode_button_text_active
                                )}
                            >
                                {questionEditMode
                                    ? "Save changes"
                                    : "Edit scale"}
                            </span>
                        </button>
                    </div>
                ) : null}
            </div>
        );
    }
);
