import React from "react";
import { TimelineEvent } from "@difftone/types";

import { EventDescription } from "./components";

import passedEventIcon from "@difftone/assets/wizard-event-icon-passed.svg";
import { event_container, icon } from "./time-line-event-icon.module.css";

export type TimelineEventIconProps = {
    surveyEvent: TimelineEvent;
    distanceMargin: number;
};

export const TimelineEventIcon = (props: TimelineEventIconProps) => {
    const { surveyEvent, distanceMargin } = props;

    return (
        <div className={event_container}>
            <EventDescription surveyEvent={surveyEvent} />

            <div
                style={{
                    marginBottom: `${distanceMargin}px`,
                }}
                className={icon}
            >
                <img src={passedEventIcon} alt="passed" />
            </div>
        </div>
    );
};
