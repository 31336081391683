import React from "react";

import {
    result_box,
    default_class,
    results_overall,
    result_box_content,
} from "./mobile-result-box.module.css";

type PalletObject = {
    [x: string]: string;
};

export type MobileResultBoxProps = {
    colorIndicator: string;
    resultValue: string | number;
};

export const MobileResultBox = (props: MobileResultBoxProps) => {
    const { resultValue, colorIndicator } = props;
    const colorPallet: PalletObject = {
        OVERALL: results_overall,
        DEFAULT: default_class,
    };

    if (Number(resultValue) === 0) {
        return (
            <div className={result_box}>
                <div
                    className={[
                        result_box_content,
                        colorPallet[colorIndicator],
                    ].join(" ")}
                >
                    {resultValue}
                </div>
            </div>
        );
    }

    return (
        <div className={result_box}>
            <div
                className={[
                    result_box_content,
                    colorPallet[colorIndicator],
                ].join(" ")}
            >
                {resultValue ? resultValue : "N/A"}
            </div>
        </div>
    );
};
