import { action } from "mobx";
import { v4 as uuid } from "uuid";
import {
    Answer,
    AnswerType,
    SurveyClasses,
    SurveyResponse,
    UUID,
} from "@difftone/types";
import { surveyResponseStore } from "@difftone/stores";
import {
    getSurveyFromStoreByUUID,
    getUUidAndEmailFromUrl,
} from "@difftone/reducers";
import { isFullyVisible } from "@difftone/procedures";

const SURVEY_RESPONSE_TEMPLET: Partial<SurveyResponse> = {
    uuid: undefined,
    version: "0.0.1",
    responder: undefined,
    submission_date: undefined,
    survey_UUID: "",
    survey_initiator: "",
    answers: [],
    attribute_answers: [],
    is_deleted: false,
};

export const getNewSurveyResponse = (): SurveyResponse => {
    const newSurveyResponse = JSON.parse(
        JSON.stringify(SURVEY_RESPONSE_TEMPLET)
    );
    newSurveyResponse.uuid = uuid();
    return newSurveyResponse;
};

export const saveSelectedSurveyResponse = action(
    async (surveyResponseUUID: UUID) => {
        const selectedSurveyResponse =
            surveyResponseStore.getSurveyResponseByUuid(surveyResponseUUID);

        if (!selectedSurveyResponse) {
            //TODO: implement with logout procedure
            console.error(
                "[saveSelectedSurveyResponse]:: Could not find survey response!"
            );
            throw Error(
                "[saveSelectedSurveyResponse]:: Could not find survey response!"
            );
        }

        const _surveyResponse: SurveyResponse = JSON.parse(
            JSON.stringify(selectedSurveyResponse)
        );
        _surveyResponse.submission_date = Date.now();

        return surveyResponseStore.addSurveyResponseWithApiPersistance(
            _surveyResponse
        );
    }
);

export const saveSelectedOpenSurveyResponse = action(
    async (surveyResponseUUID: UUID) => {
        const selectedSurveyResponse =
            surveyResponseStore.getSurveyResponseByUuid(surveyResponseUUID);

        if (!selectedSurveyResponse) {
            //TODO: implement with logout procedure
            console.error(
                "[saveSelectedSurveyResponse]:: Could not find survey response!"
            );
            throw Error(
                "[saveSelectedSurveyResponse]:: Could not find survey response!"
            );
        }

        const _surveyResponse: SurveyResponse = JSON.parse(
            JSON.stringify(selectedSurveyResponse)
        );
        _surveyResponse.submission_date = Date.now();

        return surveyResponseStore.addOpenSurveyResponseWithApiPersistance(
            _surveyResponse
        );
    }
);

export const upsertSurveyResponseToMapAndApi = action(
    (surveyResponse: SurveyResponse) => {
        surveyResponseStore.addSurveyResponseWithApiPersistance(surveyResponse);
    }
);

export const upsertConvertedSurveyResponseToMapAndApi = action(
    (surveyResponse: SurveyResponse) => {
        surveyResponseStore.addConvertedSurveyResponseWithApiPersistance(
            surveyResponse
        );
    }
);

export const updateSelectedSurveyNonFilterQuestionResponse = action(
    (answer: Answer<AnswerType>, surveyResponseUUID: UUID) => {
        const selectedSurveyResponse =
            surveyResponseStore.getSurveyResponseByUuid(surveyResponseUUID);

        if (selectedSurveyResponse && selectedSurveyResponse.answers) {
            selectedSurveyResponse.answers =
                selectedSurveyResponse.answers.filter(
                    (_answer: Answer<AnswerType>) =>
                        _answer.uuid !== answer.uuid
                );
            selectedSurveyResponse.answers.push(answer);
        }

        return;
    }
);

export const updateSelectedSurveyFilterQuestionResponse = action(
    (answer: Answer<AnswerType>, surveyResponseUUID: UUID) => {
        const selectedSurveyResponse =
            surveyResponseStore.getSurveyResponseByUuid(surveyResponseUUID);

        if (
            selectedSurveyResponse &&
            selectedSurveyResponse.attribute_answers
        ) {
            selectedSurveyResponse.attribute_answers =
                selectedSurveyResponse.attribute_answers.filter(
                    (_answer: Answer<AnswerType>) =>
                        _answer.uuid !== answer.uuid
                );
            selectedSurveyResponse.attribute_answers.push(answer);
        }

        return;
    }
);

export const executeScrollOnfocus = (
    containerWrapper: HTMLElement,
    currentElement: HTMLDivElement,
    offsetHeight: number,
    disableSmooth?: boolean,
    disableFullVisibilityCheck?: boolean
) => {
    if (
        disableFullVisibilityCheck ||
        !isFullyVisible(currentElement, containerWrapper)
    ) {
        const questionPosition = currentElement!.offsetTop;
        containerWrapper?.scrollTo({
            top:
                containerWrapper.getAttribute("data-scroll-element") ===
                "survey-wrapper"
                    ? questionPosition - offsetHeight
                    : questionPosition + offsetHeight,
            ...(!disableSmooth ? { behavior: "smooth" } : {}),
        });
    }
};

export const createNewSurveyResponseByUserUuid = (
    userUUID: UUID,
    survey?: SurveyClasses
): SurveyResponse | null => {
    const pathName = window.location.pathname;
    const encodedParams = pathName.split("/").pop()?.toString();
    const urlParams = getUUidAndEmailFromUrl(encodedParams!);

    if (survey) {
        const newSurveyResponse = getNewSurveyResponse();
        newSurveyResponse.responder = userUUID;
        newSurveyResponse.survey_UUID = survey.uuid;
        newSurveyResponse.survey_initiator = survey.initiator;
        return newSurveyResponse;
    }
    if (!urlParams) {
        throw new Error(`Could not find SurveyUUID ${urlParams}`);
    }

    const _survey = getSurveyFromStoreByUUID(urlParams.surveyUuid);

    if (_survey === undefined) {
        throw new Error(`Could not find Survey ${urlParams}`);
    }

    if (_survey === null) {
        return null;
    }

    const newSurveyResponse = getNewSurveyResponse();
    newSurveyResponse.responder = userUUID;
    newSurveyResponse.survey_UUID = _survey.uuid;
    newSurveyResponse.survey_initiator = _survey.initiator;
    return newSurveyResponse;
};
