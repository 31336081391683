import React from "react";

import { getSurveyFromStoreByURL } from "@difftone/reducers";
import { SurveyIntroduction, SurveyName } from "@difftone/shared-components";
import { wizardDisplayStore } from "@difftone/stores";

import {
    AnonimityLevel,
    CheckboxIsOpenSurvey,
    CheckboxOngoing,
    Purpose,
} from "./components";
import {
    wizard_details,
    purpose_and_anonimity,
    checkboxes_container,
} from "./wizard-details.module.css";
import { accordionSectionsController } from "@difftone/actions";

export const WizardDetails = () => {
    const { isEditableSurvey } = wizardDisplayStore;
    const survey = getSurveyFromStoreByURL();

    return (
        <div
            className={wizard_details}
            data-wizard-details
            onClick={() => accordionSectionsController("DETAILS")}
        >
            <SurveyName disabled={!isEditableSurvey} survey={survey} />
            <SurveyIntroduction disabled={!isEditableSurvey} survey={survey} />
            <div className={purpose_and_anonimity}>
                <Purpose survey={survey} />
                <AnonimityLevel survey={survey} />
            </div>
            <div className={checkboxes_container}>
                <CheckboxOngoing survey={survey} />
                <CheckboxIsOpenSurvey survey={survey} />
            </div>
        </div>
    );
};
