import { sharedResultsResultsMapStore } from "@difftone/stores";
import { getResultsMapFromApiForSharedResults } from "@difftone/services";
import { ResultsFilter, UUID, ResultsMap } from "@difftone/types";

export const getSharedResultsResultsMapFromApi = async (
    sharedSurveyUuid: UUID,
    resultsFilters: ResultsFilter[]
) => {
    const sharedResultsResultsMap = await getResultsMapFromApiForSharedResults(
        sharedSurveyUuid,
        resultsFilters
    );

    const _keyString = sharedResultsResultsMapStore.extractKey(
        sharedSurveyUuid,
        resultsFilters
    );

    sharedResultsResultsMapStore.setSharedResultsToMap(
        _keyString,
        sharedResultsResultsMap
    );
};
