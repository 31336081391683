import React from "react";
import clsx from "clsx";

import { ShowDifftoneTooltip } from "@difftone/shared-components";
import {
    results_score_box,
    results_score_box_color_size_small,
    results_score_box_color_size_medium,
    results_score_display_text,
    first_score_box,
    last_score_box,
} from "./results-score-box.module.css";

const FIRST_SCORE_BOX = 0;
const MIN_BOX_SIZE = 3;

export type ResultsScoreBoxProps = {
    scoreColor: String;
    width: number;
    resultsScoreSize: "SMALL" | "MEDIUM";
    lastScoreBoxPosition: number;
    index: number;
    tooltipDisplayGroupe: string[] | null;
};

export const ResultsScoreBox = (props: ResultsScoreBoxProps) => {
    const {
        scoreColor,
        width,
        resultsScoreSize,
        lastScoreBoxPosition,
        index,
        tooltipDisplayGroupe,
    } = props;

    let resultsScoreSizeDisplay = null;

    switch (resultsScoreSize) {
        case "SMALL":
            resultsScoreSizeDisplay = results_score_box_color_size_small;
            break;
        case "MEDIUM":
            resultsScoreSizeDisplay = results_score_box_color_size_medium;
            break;
        default:
            resultsScoreSizeDisplay = results_score_box_color_size_medium;
    }

    const boxSize = width > MIN_BOX_SIZE ? width : MIN_BOX_SIZE;

    return (
        <div className={results_score_box} style={{ width: `${boxSize}%` }}>
            <div
                style={{ background: `${scoreColor}` }}
                className={clsx(resultsScoreSizeDisplay, {
                    [first_score_box]: index === FIRST_SCORE_BOX,
                    [last_score_box]: index === lastScoreBoxPosition,
                })}
            >
                <ShowDifftoneTooltip
                    disableHover={!tooltipDisplayGroupe}
                    tip={`Scores included in this group are: ${tooltipDisplayGroupe}`}
                    tooltipPosition="top"
                >
                    <div
                        className={results_score_display_text}
                    >{`${width.toFixed(0)}%`}</div>
                </ShowDifftoneTooltip>
            </div>
        </div>
    );
};
