import React from "react";

import { Question, QuestionType } from "@difftone/types";

import { collapsed_container } from "./collapsed-style.module.css";

export type CollapsedStyleProps = {
    question: Question<QuestionType>;
    icon: any;
};

export const CollapsedStyle = (props: CollapsedStyleProps) => {
    const { question, icon } = props;

    return (
        <div className={collapsed_container}>
            {question.question_type_name}
            <img alt={question.question_type_name} src={icon} />
        </div>
    );
};
