import React from "react";
import { observer } from "mobx-react";

import { SurveyClasses } from "@difftone/types";
import { getStatus } from "@difftone/reducers";
import { UiStatus } from "@difftone/frontend-common";
import { Checkbox } from "@difftone/shared-components";
import { toggleIsOpenSurveyCheckbox } from "@difftone/actions";
import {
    checkbox_is_open_survey,
    text_and_checkbox_wrapper,
    check_box,
    text_checkbox,
} from "./checkbox-is-open-survey.module.css";

export type CheckboxIsOpenSurveyProps = {
    survey: SurveyClasses;
};

export const CheckboxIsOpenSurvey = observer(
    (props: CheckboxIsOpenSurveyProps) => {
        const { survey } = props;

        const surveyStatus: UiStatus = getStatus(survey);

        const onChange = () => {
            if (surveyStatus === "DRAFT") {
                toggleIsOpenSurveyCheckbox(survey);
            }
        };

        const isOpenSurvey =
            survey.is_open_survey === undefined ? false : survey.is_open_survey;

        return (
            <div className={checkbox_is_open_survey}>
                <div className={text_and_checkbox_wrapper}>
                    <div className={check_box}>
                        <Checkbox checked={isOpenSurvey} onClick={onChange} />
                    </div>
                    <div className={text_checkbox}>Open survey</div>
                </div>
            </div>
        );
    }
);
