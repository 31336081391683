import { Email, UUID } from "@difftone/types";
import { getApiBaseUrl } from "@difftone/constants";
import { errorHandlerProxy, localstorageUtils } from "@difftone/procedures";
import { createDifftoneAxios } from "@difftone/services";

const EMAIL_BY_UUID_URL = `${getApiBaseUrl()}/emails-service-v2/get-email-by-uuid`;
const UPSERT_EMAIL_URL = `${getApiBaseUrl()}/emails-service-v2`;

export const getEmail = async (
    emailUUID: UUID
): Promise<Email | undefined | null> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(EMAIL_BY_UUID_URL, {
        params: {
            issuer: currentAuthData.issuer,
            email_uuid: emailUUID,
        },
    });

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    return response.data.resource;
};

export const upsertEmailToApi = async (email: Email): Promise<Email> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.post(
        `${UPSERT_EMAIL_URL}`,
        { email },
        { params: { issuer: currentAuthData.issuer } }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.results;
    return resource;
};
