import {
    Category,
    ValidationResult,
    CategoryNameValidationType
} from "@difftone/types";

export const validateCategoryName = (category: Category): ValidationResult<CategoryNameValidationType>[] => {
    return (!category.display_name || !category.display_name.trim()) ?
        [{
            is_valid: false,
            validation_error_code: "CATEGORY_NAME_VALIDATION",
            validation_error_type: { category_uuid: category.uuid }
        }] :
        [{
            is_valid: true,
            validation_error_code: "CATEGORY_NAME_VALIDATION",
            validation_error_type: { category_uuid: category.uuid }
        }];
} 