import React from "react";
import { observer } from "mobx-react";
import { v4 as uuid } from "uuid";
import clsx from "clsx";
import { Buffer } from "buffer";
import {
    inboxStore,
    surveyStore,
    publicProfileStore,
    navigationStore,
    categoriesDisplayStore,
    sharedSurveyStore,
} from "@difftone/stores";
import {
    clearResultsTableQuestions,
    inboxSpinnerOff,
    inboxSpinnerOn,
    deleteAllAlertsOnDeleteSurvey,
    onSharedSurveyClick,
    removeDeletedSurveysFromStore,
    setLocation,
    showDifftoneAlert,
    changeResultsDisplayState,
    getUniquePublicProfilesUUIDs,
    setWasSurveyResponseOnceSubmitted,
    sendSurvey,
    upsertSurveyToMapAndApiWithDebounce,
    upsertSurveyToMapAndApiWithoutDebounce,
    cancelEmail,
    showDifftoneAlertWithSharePanel,
} from "@difftone/actions";
import {
    Spinner,
    SpinnerOrTimeoutMessage,
    TableRowCell,
    TableRow,
    SurveyStatus,
    HumanizeEmail,
    ETA,
    ProgressBar,
    ShowDifftoneTooltip,
    ActionIconItem,
    NameWithTooltip,
    OngoingEta,
    TableDateBySurveyClass,
} from "@difftone/shared-components";
import { getUserEmail, sendEvent } from "@difftone/procedures";

import {
    getInboxActiveFilterFromUrl,
    imTheInitiatorCount,
    imTheRespondentCount,
    needsMyAttentionCount,
    notCompletedCount,
    allMySurveysCount,
    exampleSurveysCount,
    filteredSurveys,
    getLastDateToFill,
    getStatus,
    ongoingSurveysCount,
    getSurveyResponseData,
} from "@difftone/reducers";

import { UiStatus } from "@difftone/frontend-common";
import {
    BaseSurvey,
    DisplaySurvey,
    OngoingSurvey,
    SimpleSurvey,
    SurveyClasses,
} from "@difftone/types";
import { constantAlertVariants } from "@difftone/constants";
import editIcon from "@difftone/assets/edit-survey.svg";
import duplicateIcon from "@difftone/assets/duplicate-survey.svg";
import resultsIcon from "@difftone/assets/survey-results.svg";
import shareIcon from "@difftone/assets/share-survey.svg";
import archiveIcon from "@difftone/assets/archive.svg";
import deleteIcon from "@difftone/assets/delete-survey.svg";

import { TableHeader, DisplayActionsBySurveyClass } from "./components";
import {
    inbox_surveys_table,
    table_content_wrapper,
    overflow_padding_div,
    progress_class,
    time_left_class,
    last_date_class,
    issued_class,
    initiator_class,
    status_class,
    survey_name_class,
    actions_menu,
    progress_bar,
} from "./inbox-surveys-table.module.css";

export const InboxSurveysTable = observer(() => {
    const { currentPage } = navigationStore;
    const { surveysList } = surveyStore;
    const { surveysTableSpinnerActive } = inboxStore;
    const publicProfileStartInitialization =
        publicProfileStore.startInitialization;
    const sortedAndFilteredSurveys = filteredSurveys();

    const surveysCounterByActiveInboxFilter: {
        [activeFilter: string]: Function;
    } = {
        ALL: allMySurveysCount,
        RESPONDENT: imTheRespondentCount,
        INITIATOR: imTheInitiatorCount,
        ONGOING: ongoingSurveysCount,
        NEEDS_ATTENTION: needsMyAttentionCount,
        DRAFT: exampleSurveysCount,
        NOT_COMPLETED: notCompletedCount,
        SHARED_WITH_ME: sharedSurveyStore.readySharedSurveysCount,
    };

    const surveysByActiveFilterCount =
        surveysCounterByActiveInboxFilter[getInboxActiveFilterFromUrl()!];

    if (!surveyStore.storeInitialize) {
        inboxSpinnerOn();
        return <Spinner />;
    }

    if (surveyStore.storeInitialize) {
        if (!publicProfileStartInitialization) {
            const uniquePublicProfiles = getUniquePublicProfilesUUIDs();

            publicProfileStore.init(uniquePublicProfiles);
        }
    }

    if (surveyStore.storeInitialize && publicProfileStartInitialization) {
        inboxSpinnerOff();
    }

    const onSendNow = (survey: BaseSurvey) => {
        sendSurvey(survey);

        switch (survey.survey_class) {
            case "ONGOING":
                showDifftoneAlert(
                    constantAlertVariants.ongoing_survey_send.text,
                    constantAlertVariants.ongoing_survey_send.variant
                );
                break;
            case "SIMPLE":
            default:
                showDifftoneAlertWithSharePanel(
                    constantAlertVariants.survey_sent.text,
                    constantAlertVariants.survey_sent.variant,
                    survey.uuid,
                    "Additionally you can send the survey via",
                    survey.is_open_survey
                );
        }
    };

    const onEdit = (survey: BaseSurvey) => {
        setLocation(`/survey-wizard/${survey.uuid}`);
    };

    const onResults = (survey: BaseSurvey) => {
        clearResultsTableQuestions();
        changeResultsDisplayState("CATEGORIES");
        categoriesDisplayStore.toggleCategoriesDisplayModes();
        setLocation(`/results/${survey.uuid!}/grid`);
    };

    const onDelete = async (survey: SurveyClasses) => {
        survey.deleted = true;
        upsertSurveyToMapAndApiWithDebounce(survey);

        if (survey.survey_class !== "ONGOING") {
            deleteAllAlertsOnDeleteSurvey(survey);
            survey.survey_events.forEach((surveyEvent) => {
                surveyEvent.event_emails?.forEach((emailUUID) => {
                    cancelEmail(emailUUID);
                });
            });
        }

        removeDeletedSurveysFromStore();
        showDifftoneAlert(
            `"${survey.survey_name}" ${constantAlertVariants.survey_deleted.text}`,
            constantAlertVariants.survey_deleted.variant
        );
        sendEvent("DELETE_SURVEY_BUTTON_CLICK", survey.uuid);
        return;
    };

    const onArchive = async (survey: SurveyClasses) => {
        survey.archived = true;
        upsertSurveyToMapAndApiWithDebounce(survey as SurveyClasses);
        showDifftoneAlert(
            `"${survey.survey_name}" ${constantAlertVariants.survey_archived.text}`,
            constantAlertVariants.survey_archived.variant
        );
        return;
    };

    const onUnconfirmedDelete = (survey: SurveyClasses) => {
        const surveyStatus = getStatus(survey, "INITIATOR");
        if (surveyStatus === "ACTIVE") {
            showDifftoneAlert(
                constantAlertVariants.survey_warning_delete.text,
                constantAlertVariants.survey_warning_delete.variant,
                () => onDelete(survey),
                () => {},
                survey.survey_name
            );
            return;
        }
        showDifftoneAlert(
            constantAlertVariants.survey_delete.text,
            constantAlertVariants.survey_delete.variant,
            () => onDelete(survey),
            () => {},
            survey.survey_name
        );
    };

    const onDuplicate = (survey: BaseSurvey) => {
        setLocation(`${currentPage}/duplicate/${survey.uuid}`);
    };

    const onShare = (survey: SurveyClasses) => {
        const sharedSurveyUUID = uuid();
        onSharedSurveyClick(survey, sharedSurveyUUID);
        setLocation(`${currentPage}/share/${sharedSurveyUUID}`);
    };

    const onActivateOngoing = (survey: BaseSurvey) => {
        onSendNow(survey);
    };

    const onEndOngoing = (survey: OngoingSurvey) => {
        showDifftoneAlert(
            constantAlertVariants.end_ongoing_survey.text,
            constantAlertVariants.end_ongoing_survey.variant,
            () => endSurvey(survey),
            () => {},
            survey.survey_name
        );
    };

    const endSurvey = async (survey: OngoingSurvey) => {
        survey.manually_ended = Date.now();
        upsertSurveyToMapAndApiWithoutDebounce(survey);

        showDifftoneAlert(
            `"${survey.survey_name}" has ended. Go to results?`,
            constantAlertVariants.survey_deleted.variant,
            () => setLocation(`/results/${survey.uuid}/grid`)
        );
        sendEvent("END_SURVEY_BUTTON_CLICK", survey.uuid);
        return;
    };

    const onSendOngoingSurvey = (survey: OngoingSurvey) => {
        setLocation(`${currentPage}/sendongoing/${survey.uuid}`);
    };

    const initiatorActions = {
        results: onResults,
        share: onShare,
        send: onSendNow,
        sendOngoing: onSendOngoingSurvey,
        activateOngoing: onActivateOngoing,
        endOngoingSurvey: onEndOngoing,
        archive: onArchive,
        delete: onUnconfirmedDelete,
        edit: onEdit,
        duplicate: onDuplicate,
        noAction: () => {},
    };

    const goToSurvey = (displaySurvey: DisplaySurvey) => {
        const entireParams = `${displaySurvey.survey.uuid}/${getUserEmail()}`;
        const base64Params = Buffer.from(entireParams).toString("base64");

        setWasSurveyResponseOnceSubmitted(false);
        setLocation(`/survey/${base64Params}`);
    };

    const responderClickHandler = (displaySurvey: DisplaySurvey) => {
        switch (displaySurvey.survey.survey_class) {
            case "ONGOING":
                const onGoingSurvey = displaySurvey.survey as OngoingSurvey;
                if (onGoingSurvey.manually_ended) {
                    return;
                }
                return goToSurvey(displaySurvey);

            case "SIMPLE":
            default:
                const lastDateToFill = getLastDateToFill(
                    displaySurvey.survey as SimpleSurvey
                );
                const canFillSurvey = lastDateToFill
                    ? lastDateToFill > Date.now()
                    : false;

                if (!canFillSurvey) {
                    return;
                }
                return goToSurvey(displaySurvey);
        }
    };

    const onResponderFill = (displaySurvey: DisplaySurvey) => {
        if (displaySurvey.role === "INITIATOR") {
            return;
        }

        responderClickHandler(displaySurvey);
    };

    return (
        <div className={inbox_surveys_table}>
            <TableHeader />
            <div className={table_content_wrapper}>
                {surveysByActiveFilterCount(surveysList) > 0 ? (
                    sortedAndFilteredSurveys.map((displaySurvey) => {
                        const survey = displaySurvey.survey as SurveyClasses;
                        const surveyStatus: UiStatus = getStatus(
                            displaySurvey.survey,
                            displaySurvey.role
                        );

                        const initiatorPublicProfile =
                            publicProfileStore.getPublicProfileByUuid(
                                survey.initiator
                            );

                        const initiatorEmailTemp = initiatorPublicProfile
                            ? initiatorPublicProfile.email
                            : "unknown";

                        const surveyResponseData =
                            getSurveyResponseData(survey);

                        return (
                            <TableRow
                                key={displaySurvey.display_surveyUUID}
                                actionsClassName={actions_menu}
                                onBodyClick={() =>
                                    onResponderFill(displaySurvey)
                                }
                                actions={
                                    displaySurvey.role !== "RESPONDER" ? (
                                        <>
                                            {displaySurvey.role ===
                                                "INITIATOR" && (
                                                <>
                                                    <ShowDifftoneTooltip
                                                        tooltipPosition="bottom"
                                                        tip="Edit"
                                                    >
                                                        <ActionIconItem
                                                            action={() =>
                                                                initiatorActions.edit(
                                                                    survey
                                                                )
                                                            }
                                                            disabled={
                                                                surveyStatus ===
                                                                "ENDED"
                                                            }
                                                            icon={editIcon}
                                                        />
                                                    </ShowDifftoneTooltip>
                                                    <DisplayActionsBySurveyClass
                                                        displaySurvey={
                                                            displaySurvey
                                                        }
                                                        initiatorActions={
                                                            initiatorActions
                                                        }
                                                    />
                                                    <ShowDifftoneTooltip
                                                        tooltipPosition="bottom"
                                                        tip="Results"
                                                    >
                                                        <ActionIconItem
                                                            action={
                                                                surveyStatus ===
                                                                "DRAFT"
                                                                    ? initiatorActions.noAction
                                                                    : () =>
                                                                          initiatorActions.results(
                                                                              survey
                                                                          )
                                                            }
                                                            disabled={
                                                                !surveyResponseData.completed ||
                                                                surveyStatus ===
                                                                    "DRAFT" ||
                                                                surveyStatus ===
                                                                    "SAVED" ||
                                                                surveyStatus ===
                                                                    "READY"
                                                            }
                                                            icon={resultsIcon}
                                                        />
                                                    </ShowDifftoneTooltip>
                                                    <ShowDifftoneTooltip
                                                        tooltipPosition="bottom"
                                                        tip="Share"
                                                    >
                                                        <ActionIconItem
                                                            action={() =>
                                                                initiatorActions.share(
                                                                    survey
                                                                )
                                                            }
                                                            disabled={false}
                                                            icon={shareIcon}
                                                        />
                                                    </ShowDifftoneTooltip>
                                                    <ShowDifftoneTooltip
                                                        tooltipPosition="bottom"
                                                        tip="Duplicate"
                                                    >
                                                        <ActionIconItem
                                                            action={() =>
                                                                initiatorActions.duplicate(
                                                                    survey
                                                                )
                                                            }
                                                            disabled={false}
                                                            icon={duplicateIcon}
                                                        />
                                                    </ShowDifftoneTooltip>
                                                    <ShowDifftoneTooltip
                                                        tooltipPosition="bottom"
                                                        tip="Archive"
                                                    >
                                                        <ActionIconItem
                                                            action={() =>
                                                                initiatorActions.archive(
                                                                    survey
                                                                )
                                                            }
                                                            disabled={
                                                                surveyStatus !==
                                                                    "DRAFT" &&
                                                                surveyStatus !==
                                                                    "ENDED"
                                                            }
                                                            icon={archiveIcon}
                                                        />
                                                    </ShowDifftoneTooltip>
                                                    <ShowDifftoneTooltip
                                                        tooltipPosition="bottom"
                                                        tip="Delete"
                                                    >
                                                        <ActionIconItem
                                                            action={() =>
                                                                initiatorActions.delete(
                                                                    survey
                                                                )
                                                            }
                                                            disabled={false}
                                                            icon={deleteIcon}
                                                        />
                                                    </ShowDifftoneTooltip>
                                                </>
                                            )}
                                        </>
                                    ) : null
                                }
                                body={
                                    <>
                                        <TableRowCell
                                            className={clsx(
                                                survey_name_class,
                                                "user_select"
                                            )}
                                        >
                                            <NameWithTooltip
                                                text={survey.survey_name}
                                            />
                                        </TableRowCell>
                                        <TableRowCell className={status_class}>
                                            <SurveyStatus
                                                surveyStatus={surveyStatus}
                                            />
                                        </TableRowCell>
                                        <TableRowCell
                                            className={initiator_class}
                                            tooltipText={
                                                initiatorPublicProfile
                                                    ? initiatorEmailTemp
                                                    : ""
                                            }
                                        >
                                            {initiatorPublicProfile ===
                                            undefined ? (
                                                <Spinner size="X-SMALL" />
                                            ) : (
                                                <HumanizeEmail
                                                    humanizeEmail={
                                                        initiatorEmailTemp
                                                    }
                                                />
                                            )}
                                        </TableRowCell>
                                        <TableRowCell className={issued_class}>
                                            <TableDateBySurveyClass
                                                survey={survey}
                                                dateType="START_DATE"
                                            />
                                        </TableRowCell>
                                        <TableRowCell
                                            className={last_date_class}
                                        >
                                            <TableDateBySurveyClass
                                                survey={survey}
                                                dateType="LAST_DATE"
                                            />
                                        </TableRowCell>
                                        <TableRowCell
                                            className={time_left_class}
                                        >
                                            {displaySurvey.survey
                                                .survey_class === "ONGOING" ? (
                                                <OngoingEta
                                                    survey={
                                                        displaySurvey.survey
                                                    }
                                                />
                                            ) : (
                                                <ETA
                                                    survey={
                                                        survey as SimpleSurvey
                                                    }
                                                    surveyStatus={surveyStatus}
                                                />
                                            )}
                                        </TableRowCell>
                                    </>
                                }
                                progressBar={
                                    <TableRowCell className={progress_class}>
                                        {displaySurvey.role === "INITIATOR" && (
                                            <div className={progress_bar}>
                                                <ProgressBar
                                                    survey={
                                                        displaySurvey.survey
                                                    }
                                                    surveyStatus={surveyStatus}
                                                />
                                            </div>
                                        )}
                                    </TableRowCell>
                                }
                            />
                        );
                    })
                ) : (
                    <SpinnerOrTimeoutMessage
                        spinnerSize="LARGE"
                        spinnerFlag={surveysTableSpinnerActive}
                        message="No Surveys To Show"
                    />
                )}
                <div className={overflow_padding_div} />
            </div>
        </div>
    );
});
