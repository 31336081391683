import clsx from "clsx";
import React, { ReactNode, useState } from "react";

import { toast, toast_container, top, bottom } from "./toast.module.css";

export type ToastPosition = "bottom" | "top" | undefined;

export type ToastProps = {
    text: string;
    displayTimeMS: number;
    children: ReactNode;
    toastPosition?: ToastPosition;
    extendToastStyle?: string;
};

export const Toast = (props: ToastProps) => {
    const { text, displayTimeMS, children, extendToastStyle, toastPosition } =
        props;

    const [showSelf, setShowSelf] = useState(false);

    const onContentClick = () => {
        setShowSelf(true);
        setTimeout(() => {
            setShowSelf(false);
        }, displayTimeMS);
    };

    let toastPositionClass = null;
    switch (toastPosition) {
        case "bottom":
            toastPositionClass = bottom;
            break;
        case "top":
        default:
            toastPositionClass = top;
            break;
    }

    return (
        <div className={toast_container} onClick={onContentClick}>
            {showSelf ? (
                <div
                    className={clsx(
                        toast,
                        extendToastStyle,
                        toastPositionClass
                    )}
                >
                    {text}
                </div>
            ) : null}
            {children}
        </div>
    );
};
