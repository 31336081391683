import { SurveyClasses } from "@difftone/types";
import { makeAutoObservable } from "mobx";

class PreviewSurveyStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _inPreviewMode: boolean = false;
    private _activePreviewSurvey: SurveyClasses | null = null;
    get inPreviewMode(): boolean {
        return this._inPreviewMode;
    }
    set inPreviewMode(inPreview: boolean) {
        this._inPreviewMode = inPreview;
    }
    get activePreviewSurvey(): SurveyClasses | null {
        return this._activePreviewSurvey;
    }
    set activePreviewSurvey(previewSurvey: SurveyClasses | null) {
        this._activePreviewSurvey = previewSurvey;
    }

    public clearStore = () => {
        this._inPreviewMode = false;
        this._activePreviewSurvey = null;
    };
}

export const previewSurveyStore = new PreviewSurveyStore();
