import React, { SyntheticEvent, CSSProperties } from "react";
import { observer } from "mobx-react";
import { Draggable } from "react-beautiful-dnd";
import clsx from "clsx";

import dragDrop from "@difftone/assets/drag-drop-new-icon.svg";
import { preventPropagation } from "@difftone/frontend-common";
import { MultipleChoiceQuestion, Question } from "@difftone/types";
import { updateQuestionInWizard } from "@difftone/actions";

import {
    drag_drop,
    drag_wrapper,
    drag_wrapper_visible,
    drag_delete_option,
    draggable_option,
    draggable_option_dragging,
} from "./draggable-options.module.css";
import { MultipleChoiceOption, DeleteIcon } from "./components";

export type DraggableOptionsProps = {
    item: Question<MultipleChoiceQuestion>;
    addNewChoice: () => void;
};

export const DraggableOptions = observer((props: DraggableOptionsProps) => {
    const { item, addNewChoice } = props;

    const choicesRefs: any[] = [];

    const handleKeydown = (e: KeyboardEvent, index: number) => {
        if (e.key === "ArrowUp") {
            preventPropagation(e);
            if (index > 0) choicesRefs[index - 1].current?.select();
        } else if (e.key === "ArrowDown" || e.key === "Enter") {
            preventPropagation(e);
            const optionsList = Array.from(item.content.choice_options);
            if (index < optionsList.length - 1)
                choicesRefs[index + 1].current?.select();
            else if (e.key === "Enter") {
                preventPropagation(e);
                if (index === optionsList.length - 1) {
                    addNewChoice();
                } else {
                    choicesRefs[index].current?.blur();
                }
            }
        }
    };

    const handleNewOptionRef = (ref: any, index: number) => {
        choicesRefs[index] = ref;
    };

    const onRemoveChoice = (event: SyntheticEvent) => {
        const index = JSON.parse(
            event.currentTarget.getAttribute("data-index") as string
        );
        const copyOfCurrentQuestionChoices = [...item.content.choice_options];
        copyOfCurrentQuestionChoices.splice(index, 1);
        const newContent = {
            ...item.content,
            choice_options: copyOfCurrentQuestionChoices,
        };

        updateQuestionInWizard(
            item as Question<MultipleChoiceQuestion>,
            "content",
            newContent
        );
    };

    const choicesWithIDs = item.content.choice_options.map((choice, index) => {
        return { id: item.uuid + index, option: choice };
    });

    return (
        <>
            {choicesWithIDs.map((choice, index) => {
                return (
                    <Draggable
                        key={choice.id}
                        draggableId={choice.id + index}
                        index={index}
                    >
                        {(provided, snapshot) => {
                            const myStyle: CSSProperties = {
                                ...provided.draggableProps.style,
                                cursor: "default",
                                height: "24px",
                            };

                            if (index === 0) {
                                myStyle.top = 0;
                                myStyle.left = 0;
                            } else {
                                myStyle.top =
                                    Number(myStyle.height) * index || 0;
                                myStyle.left = 0;
                            }

                            return (
                                <li
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={{ ...myStyle, position: "relative" }}
                                    className={clsx(draggable_option, {
                                        [draggable_option_dragging]:
                                            snapshot.isDragging,
                                    })}
                                >
                                    <div
                                        className={clsx(drag_wrapper, {
                                            [drag_wrapper_visible]:
                                                snapshot.isDragging,
                                        })}
                                    >
                                        <span
                                            className={drag_drop}
                                            {...provided.dragHandleProps}
                                        >
                                            <img
                                                alt="drag drop"
                                                src={dragDrop}
                                            />
                                        </span>
                                    </div>
                                    <MultipleChoiceOption
                                        item={item}
                                        option={choice.option}
                                        index={index}
                                        onItemKeyDown={handleKeydown}
                                        addRef={handleNewOptionRef}
                                        isLastOption={
                                            index ===
                                            item.content.choice_options.length -
                                                1
                                        }
                                    />
                                    {index === 0 || index === 1 ? null : (
                                        <button
                                            className={drag_delete_option}
                                            data-index={index}
                                            onClick={onRemoveChoice}
                                        >
                                            <DeleteIcon />
                                        </button>
                                    )}
                                </li>
                            );
                        }}
                    </Draggable>
                );
            })}
        </>
    );
});
