import React, { MouseEvent } from "react";
import { AddBoxShadow } from "@difftone/shared-components";

import {
    about_us_dialog,
    login_card,
    top_centered_line,
    about_us_title,
    about_us_text,
} from "./about-us-dialog.module.css";

export type AboutUsDialogProps = { close: () => void };

export const AboutUsDialog = (props: AboutUsDialogProps) => {
    const { close } = props;

    const preventDialogClosing = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };
    return (
        <div onClick={close} className={about_us_dialog}>
            <AddBoxShadow>
                <div onClick={preventDialogClosing} className={login_card}>
                    <span className={top_centered_line}></span>
                    <div className={about_us_title}>About us</div>
                    <div className={about_us_text}>
                        In today’s business environment, where the way we work
                        is rapidly evolving - people centered culture and team
                        connectivity are critical for an organization’s ability
                        to adapt to changes.
                        <br />
                        <br /> Successful companies focus on measuring
                        employees’ engagement, assessing their satisfaction and
                        evaluating related challenges.
                        <br />
                        <br />
                        Difftone’s machine learning powered survey, with its
                        intuitive user experience and design, helps capture
                        those learnings and leverage them in creating data
                        driven conclusions which drive impact.
                        <br />
                        <br /> Difftone’s goal is to provide organizations,
                        leaderships and other stakeholders with actionable
                        business insights.
                    </div>
                </div>
            </AddBoxShadow>
        </div>
    );
};
