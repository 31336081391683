import React from "react";
import { observer } from "mobx-react";
import {
    GoogleLogin,
    GoogleLoginResponse,
    GoogleLoginResponseOffline,
} from "react-google-login";

import { loginStore } from "@difftone/stores";
import { setGoogleError } from "@difftone/actions";
import { getGoogleClientId, GoogleLoginScopes } from "@difftone/constants";

import { GoogleStyle } from "./components";

export type GoogleButtonProps = {};

export const GoogleButton = observer(() => {
    const { googleError } = loginStore;

    const onAuthSuccessHandler = (
        data: GoogleLoginResponse | GoogleLoginResponseOffline
    ) => {
        if (!data) {
            console.error("GOOGLE LOGIN ERROR :: error in fetching the code");
            return;
        }

        loginStore.googleLogin(data);
    };

    const authErrHandler = (err: any) => {
        setGoogleError(err);
        console.error("authErrHandler:", err);
    };

    return (
        <GoogleLogin
            clientId={getGoogleClientId()}
            scope={GoogleLoginScopes.join(" ")}
            onSuccess={onAuthSuccessHandler}
            onFailure={(failure) => authErrHandler(failure)}
            isSignedIn={true}
            render={(renderProps) => {
                return (
                    <GoogleStyle
                        googleErr={googleError}
                        googleRenderPropsObject={renderProps}
                    />
                );
            }}
        />
    );
});
