import React, { MouseEvent } from "react";
import {
    DIFFTONE_PRIVACY_POLICY_URL,
    DIFFTONE_TERMS_OF_USE_URL,
} from "@difftone/constants";

import {
    info_and_legal_dialog,
    dialog_list_item,
} from "./info-and-legal-dialog.module.css";

export type InfoAndLegalDialogProps = {
    closeDialog: () => void;
    openContactInfo: () => void;
    className: string;
};

export const InfoAndLegalDialog = (props: InfoAndLegalDialogProps) => {
    const { closeDialog, openContactInfo } = props;

    const onPrivacyPolicyClicked = () => {
        window.open(DIFFTONE_PRIVACY_POLICY_URL);
    };
    const onTermsOfUseClicked = () => {
        window.open(DIFFTONE_TERMS_OF_USE_URL);
    };

    const dialogActionsByClickedItem: { [x: string]: () => void } = {
        Privacy: onPrivacyPolicyClicked,
        "Terms and conditions": onTermsOfUseClicked,
        "Contact us": openContactInfo,
    };

    const onClickHandler = (event: MouseEvent<HTMLDivElement>) => {
        const clickedItem = event.currentTarget.getAttribute("data-item");
        if (!clickedItem) {
            throw Error("[InfoAndLegalDialog]:: Somthing went wrong!");
        }

        dialogActionsByClickedItem[clickedItem]();

        closeDialog();
    };

    return (
        <div className={[info_and_legal_dialog, props.className].join(" ")}>
            {Object.keys(dialogActionsByClickedItem).map((listItem) => {
                return (
                    <div
                        data-item={listItem}
                        onClick={onClickHandler}
                        className={dialog_list_item}
                        key={listItem}
                    >
                        {listItem}
                    </div>
                );
            })}
        </div>
    );
};
