import {
    Answer,
    DateAnswer,
    LongTextAnswer,
    MultipleChoiceAnswer,
    PublicProfile,
    RatingAnswer,
    ResponderToAnswer,
    ShortTextAnswer,
    SingleChoiceAnswer,
    TimeAnswer,
} from "@difftone/types";
import {
    publicProfileStore,
    simpleSurveyResultsStore,
    RESPONDENTS_SYSTEM_HEADER,
    TEXT_ANSWER_SYSTEM_HEADER,
} from "@difftone/stores";

const nonTextualSingleChoiceSort = (
    filteredColumnKey: string,
    isAscending: boolean,
    responseA: ResponderToAnswer,
    responseB: ResponderToAnswer
) => {
    const responseAasSingleChoice =
        responseA.answer as Answer<SingleChoiceAnswer>;
    const responseBasSingleChoice =
        responseB.answer as Answer<SingleChoiceAnswer>;

    const isResponseAchecked =
        responseAasSingleChoice.content.choice === filteredColumnKey ? 1 : 0;
    const isResponseBchecked =
        responseBasSingleChoice.content.choice === filteredColumnKey ? 1 : 0;

    const res = isResponseAchecked - isResponseBchecked >= 0 ? 1 : -1;

    return isAscending ? res : res * -1;
};

const nonTextualRatingSort = (
    filteredColumnKey: string,
    isAscending: boolean,
    responseA: ResponderToAnswer,
    responseB: ResponderToAnswer
) => {
    const responseAasRating = responseA.answer as Answer<RatingAnswer>;
    const responseBasRating = responseB.answer as Answer<RatingAnswer>;

    const isResponseAchecked =
        responseAasRating.content.label === filteredColumnKey ? 1 : 0;
    const isResponseBchecked =
        responseBasRating.content.label === filteredColumnKey ? 1 : 0;

    const res = isResponseAchecked - isResponseBchecked >= 0 ? 1 : -1;

    return isAscending ? res : res * -1;
};

const nonTextualMultipleChoiceSort = (
    filteredColumnKey: string,
    isAscending: boolean,
    responseA: ResponderToAnswer,
    responseB: ResponderToAnswer
) => {
    const responseAasMultipleChoice =
        responseA.answer as Answer<MultipleChoiceAnswer>;
    const responseBasMultipleChoice =
        responseB.answer as Answer<MultipleChoiceAnswer>;

    const isResponseAchecked = responseAasMultipleChoice.content.choices[
        filteredColumnKey
    ]
        ? 1
        : 0;
    const isResponseBchecked = responseBasMultipleChoice.content.choices[
        filteredColumnKey
    ]
        ? 1
        : 0;

    const res = isResponseAchecked - isResponseBchecked >= 0 ? 1 : -1;

    return isAscending ? res : res * -1;
};

export const getSortedTextualResponsesByAnswerType = (
    isAscending: boolean,
    textualResponses: ResponderToAnswer[]
): ResponderToAnswer[] => {
    return isAscending
        ? textualResponses.sort((responseA, responseB) => {
              switch (responseA.answer.answer_type_name) {
                  case "SHORT_TEXT":
                  case "LONG_TEXT":
                      return (
                          responseA.answer as Answer<LongTextAnswer>
                      ).content.text.toLowerCase() >=
                          (
                              responseB.answer as Answer<LongTextAnswer>
                          ).content.text.toLowerCase()
                          ? 1
                          : -1;
                  case "DATE":
                      return (responseA.answer as Answer<DateAnswer>).content
                          .date >=
                          (responseB.answer as Answer<DateAnswer>).content.date
                          ? 1
                          : -1;
                  case "TIME":
                      return (responseA.answer as Answer<TimeAnswer>).content
                          .time >=
                          (responseB.answer as Answer<TimeAnswer>).content.time
                          ? 1
                          : -1;
                  default:
                      return 1;
              }
          })
        : textualResponses.sort((responseA, responseB) => {
              switch (responseA.answer.answer_type_name) {
                  case "SHORT_TEXT":
                  case "LONG_TEXT":
                      return (
                          responseA.answer as Answer<LongTextAnswer>
                      ).content.text.toLowerCase() <
                          (
                              responseB.answer as Answer<LongTextAnswer>
                          ).content.text.toLowerCase()
                          ? 1
                          : -1;
                  case "DATE":
                      return (responseA.answer as Answer<DateAnswer>).content
                          .date <
                          (responseB.answer as Answer<DateAnswer>).content.date
                          ? 1
                          : -1;
                  case "TIME":
                      return (responseA.answer as Answer<TimeAnswer>).content
                          .time <
                          (responseB.answer as Answer<TimeAnswer>).content.time
                          ? 1
                          : -1;
                  default:
                      return -1;
              }
          });
};

export const getSortedNonTextualResponsesByAnswerType = (
    isAscending: boolean,
    nontTextualResponses: ResponderToAnswer[]
): ResponderToAnswer[] => {
    const { filterProfile } = simpleSurveyResultsStore;

    return nontTextualResponses.sort((responseA, responseB) => {
        const filteredColumnKey = filterProfile.filterdColumnKey;

        switch (responseA.answer.answer_type_name) {
            case "SINGLE_CHOICE":
                return nonTextualSingleChoiceSort(
                    filteredColumnKey,
                    isAscending,
                    responseA,
                    responseB
                );
            case "RATING":
                return nonTextualRatingSort(
                    filteredColumnKey,
                    isAscending,
                    responseA,
                    responseB
                );
            case "MULTIPLE_CHOICE":
                return nonTextualMultipleChoiceSort(
                    filteredColumnKey,
                    isAscending,
                    responseA,
                    responseB
                );
            default:
                return 1;
        }
    });
};

export const sortByRespondersPublicName = (
    isAscending: boolean,
    responses: ResponderToAnswer[]
): ResponderToAnswer[] => {
    return responses.sort((responseA, responseB) => {
        let respondersPublicProfiles: (PublicProfile | undefined | null)[] = [];
        const responderUUIDs = responses.map(
            (response) => response.responderUUID
        );

        respondersPublicProfiles =
            publicProfileStore.getPublicProfilesByUuids(responderUUIDs);

        const responderApublicProfile = respondersPublicProfiles.find(
            (publicProfile) => publicProfile?.uuid === responseA.responderUUID
        );
        const responderBpublicProfile = respondersPublicProfiles.find(
            (publicProfile) => publicProfile?.uuid === responseB.responderUUID
        );

        if (!responderApublicProfile?.name || !responderBpublicProfile?.name) {
            return 0;
        }

        const res =
            responderApublicProfile.name >= responderBpublicProfile.name
                ? 1
                : -1;

        return isAscending ? res : res * -1;
    });
};

export const filterTextualByAnswerType = (
    responses: ResponderToAnswer[],
    valuesToEcludeFromResponses: string[]
) => {
    return responses.filter((response) => {
        switch (response.answer.answer_type_name) {
            case "DATE":
                return !valuesToEcludeFromResponses.includes(
                    (
                        response.answer as Answer<DateAnswer>
                    ).content.date.toString()
                );
            case "LONG_TEXT":
                return !valuesToEcludeFromResponses.includes(
                    (response.answer as Answer<LongTextAnswer>).content.text
                );
            case "SHORT_TEXT":
                return !valuesToEcludeFromResponses.includes(
                    (response.answer as Answer<ShortTextAnswer>).content.text
                );
            case "TIME":
                return !valuesToEcludeFromResponses.includes(
                    (
                        response.answer as Answer<TimeAnswer>
                    ).content.time.toString()
                );
            default:
                throw Error(
                    "[filterTextualByAnswerType]:: Somthing went wrong!"
                );
        }
    });
};

export const filterNonTextualByAnswerType = (
    responses: ResponderToAnswer[],
    valuesToEcludeFromResponses: string[],
    filtredColumnFromKeyInProfile: string
) => {
    return responses.filter((response) => {
        switch (response.answer.answer_type_name) {
            case "SINGLE_CHOICE":
                if (
                    valuesToEcludeFromResponses.includes("CHECKED") &&
                    (response.answer as Answer<SingleChoiceAnswer>).content
                        .choice === filtredColumnFromKeyInProfile
                )
                    return false;

                if (
                    valuesToEcludeFromResponses.includes("UNCHECKED") &&
                    (response.answer as Answer<SingleChoiceAnswer>).content
                        .choice !== filtredColumnFromKeyInProfile
                )
                    return false;

                return true;
            case "RATING":
                if (
                    valuesToEcludeFromResponses.includes("CHECKED") &&
                    (response.answer as Answer<RatingAnswer>).content.label ===
                        filtredColumnFromKeyInProfile
                )
                    return false;

                if (
                    valuesToEcludeFromResponses.includes("UNCHECKED") &&
                    (response.answer as Answer<RatingAnswer>).content.label !==
                        filtredColumnFromKeyInProfile
                )
                    return false;

                return true;
            case "MULTIPLE_CHOICE":
                if (
                    valuesToEcludeFromResponses.includes("CHECKED") &&
                    (response.answer as Answer<MultipleChoiceAnswer>).content
                        .choices[filtredColumnFromKeyInProfile]
                )
                    return false;

                if (
                    valuesToEcludeFromResponses.includes("UNCHECKED") &&
                    !(response.answer as Answer<MultipleChoiceAnswer>).content
                        .choices[filtredColumnFromKeyInProfile]
                )
                    return false;

                return true;
            default:
                throw Error(
                    "[filterNonTextualByAnswerType]:: Somthing went wrong!"
                );
        }
    });
};

export const filterByRespondersPublicName = (
    responses: ResponderToAnswer[],
    valuesToEcludeFromResponses: string[]
) => {
    return [...responses].filter((response) => {
        let respondersPublicProfile: (PublicProfile | undefined | null)[] = [];

        respondersPublicProfile = publicProfileStore.getPublicProfilesByUuids([
            response.responderUUID,
        ]);
        if (!respondersPublicProfile[0]) {
            return false;
        }

        return !valuesToEcludeFromResponses.includes(
            respondersPublicProfile[0].name
        );
    });
};

export const filterBySelectedQuestionKeysInFilterProfile = (
    responses: ResponderToAnswer[],
    selectedQuestionKeysInFilterProfile: string[]
) => {
    const { filterProfile, selectedQuestion } = simpleSurveyResultsStore;
    let filtredResponses = [...responses];

    selectedQuestionKeysInFilterProfile.forEach((keyInFilterProfile) => {
        const filtredColumnFromKeyInProfile = keyInFilterProfile.split(",")[1];
        const valuesToEcludeFromResponses: string[] =
            filterProfile.filterdProfilesMap[
                `${selectedQuestion},${filtredColumnFromKeyInProfile}`
            ] || [];

        switch (filtredColumnFromKeyInProfile) {
            case RESPONDENTS_SYSTEM_HEADER:
                filtredResponses = filterByRespondersPublicName(
                    filtredResponses,
                    valuesToEcludeFromResponses
                );
                break;
            case TEXT_ANSWER_SYSTEM_HEADER:
                filtredResponses = filterTextualByAnswerType(
                    filtredResponses,
                    valuesToEcludeFromResponses
                );
                break;
            default:
                filtredResponses = filterNonTextualByAnswerType(
                    filtredResponses,
                    valuesToEcludeFromResponses,
                    filtredColumnFromKeyInProfile
                );
                break;
        }
    });

    return filtredResponses;
};
