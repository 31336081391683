import React from "react";
import { observer } from "mobx-react";
import { Draggable } from "react-beautiful-dnd";

import { getSurveyFromStoreByURL } from "@difftone/reducers";
import { RATING_DEFAULT_SCALE } from "@difftone/constants";
import { wizardStore } from "@difftone/stores";
import { Question, RatingQuestion } from "@difftone/types";
import { setRatingQuestionAutoScaleRange } from "@difftone/actions";

import { SurveyQuestion } from "./components";

import { draggable_questions } from "./draggable-questions.module.css";

export type DraggableQuestionsProp = {};

export const DraggableQuestions = observer(() => {
    const { ratingQuestionAutoScaleRange } = wizardStore;
    const survey = getSurveyFromStoreByURL();

    const ratingQuestions: Question<RatingQuestion>[] =
        survey!.questions.filter(
            ({ question_type_name }) => question_type_name === "RATING"
        ) as Question<RatingQuestion>[];

    if (!ratingQuestionAutoScaleRange) {
        if (ratingQuestions.length <= 1) {
            setRatingQuestionAutoScaleRange(RATING_DEFAULT_SCALE);
        }

        if (ratingQuestions.length) {
            const firstRatingQuestion = ratingQuestions[0];
            setRatingQuestionAutoScaleRange(
                firstRatingQuestion.content.rating_options.length
            );
        }
    }

    return (
        <div className={draggable_questions}>
            {survey.questions.map((question, index) => (
                <Draggable
                    key={question.uuid}
                    draggableId={question.uuid}
                    index={index}
                >
                    {(provided) => {
                        // dnd library css override
                        const myStyle = {
                            ...provided.draggableProps.style,
                            cursor: "default",
                        };

                        return (
                            <>
                                <li
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={myStyle}
                                >
                                    <SurveyQuestion
                                        question={question}
                                        questionNumber={index + 1}
                                        collapsed={false}
                                        survey={survey}
                                        dragHandlerProps={
                                            provided.dragHandleProps
                                        }
                                    />
                                </li>
                            </>
                        );
                    }}
                </Draggable>
            ))}
        </div>
    );
});
