import React from "react";
import { observer } from "mobx-react";

import { AccordionItem } from "@difftone/shared-components";
import { getSurveyFromStoreByURL } from "@difftone/reducers";
import { wizardStore } from "@difftone/stores";
import { SimpleSurvey } from "@difftone/types";

import { WizardTimeline } from "./components";
import {
    timeline_accordion_item,
    split_line,
} from "./timeline-accordion-item.module.css";

export const TimelineAccordionItem = observer(() => {
    const { activeAccordionSection } = wizardStore;
    const survey = getSurveyFromStoreByURL();

    //Old templets surveys don't have a class when creating new survey
    if (survey.survey_class && survey.survey_class !== "SIMPLE") {
        throw Error(
            "[Difftone]:: WizardTimeline survey must be a simple survey"
        );
    }

    const simpleSurvey = survey as SimpleSurvey;

    return (
        <div className={timeline_accordion_item}>
            <AccordionItem
                sectionCounter={simpleSurvey.survey_events.length || 0}
                selectedAccordionItem="TIME_LINE"
                accordionTitle="Timeline"
                isAccordionSectionOpen={activeAccordionSection === "TIME_LINE"}
                survey={survey}
            >
                <div className={split_line} />
                <WizardTimeline />
            </AccordionItem>
        </div>
    );
});
