import React from "react";
import { observer } from "mobx-react";
import { Question, QuestionType, BaseSurvey } from "@difftone/types";
import {
    CategoriesIcon,
    CategoriesIconColor,
} from "@difftone/shared-components";
import { getSurveyFromStoreByURL } from "@difftone/reducers";

import { CategoryControl } from "./components";
import {
    categories_wrapper,
    categories_icon,
} from "./question-categories.module.css";

export type QuestionCategoriesProps = {
    question: Question<QuestionType>;
    survey: BaseSurvey;
};
const COLOR_BLUE = "BLUE";
const COLOR_LIGHT_BLUE = "LIGHT_BLUE";
const COLOR_GRAY = "GRAY";

export const QuestionCategories = observer((props: QuestionCategoriesProps) => {
    const { question, survey } = props;

    const _survey = survey || getSurveyFromStoreByURL();

    const categoriesArraysList: Array<string[]> = survey.questions.map(
        (item) => item.categories
    );

    const categoriesList: string[] = categoriesArraysList.length
        ? categoriesArraysList.flatMap((categories) => categories)
        : [];

    let categoryIcon: CategoriesIconColor = COLOR_BLUE;

    if (!question.is_filter && question.categories.length) {
        categoryIcon = COLOR_BLUE;
    }

    if (!question.is_filter && !question.categories.length) {
        categoryIcon = COLOR_LIGHT_BLUE;
    }

    if (question.is_filter) {
        categoryIcon = COLOR_GRAY;
    }

    return (
        <div className={categories_wrapper}>
            <CategoriesIcon className={categories_icon} color={categoryIcon} />
            <CategoryControl
                isFilterQuestion={question.is_filter}
                question={question}
                categoriesList={categoriesList}
                survey={_survey}
            />
        </div>
    );
});
