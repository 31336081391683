import { getApiBaseUrl } from "@difftone/constants";
import { Tag, UUID } from "@difftone/types";
import { errorHandlerProxy, localstorageUtils } from "@difftone/procedures";
import { createDifftoneAxios } from "@difftone/services";

const SERVER_BASE_URL = `${getApiBaseUrl()}/tag-service`;

export const getTagByUUID = async (tagUUID: UUID): Promise<Tag> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(`${SERVER_BASE_URL}/${tagUUID}`, {
        params: { issuer: currentAuthData.issuer },
    });

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    return response.data.resources;
};
