import React from "react";
import clsx from "clsx";

import { EmailAddress } from "@difftone/types";
import { getUserEmail } from "@difftone/procedures";
import { NameWithTooltip } from "@difftone/shared-components";
import { alertsMailingListGroupTitles } from "@difftone/constants";

import { user_email_wrapper } from "./humanize-email.module.css";

export type HumanizeEmailProps = {
    humanizeEmail: EmailAddress;
};

export const HumanizeEmail = (props: HumanizeEmailProps) => {
    const { humanizeEmail } = props;
    let humanized = humanizeEmail;

    switch (humanizeEmail) {
        case getUserEmail():
            humanized = "Me";
            break;
        case "ALL":
            humanized = alertsMailingListGroupTitles.ALL;
            break;
        case "NOT_FILLED":
            humanized = alertsMailingListGroupTitles.NOT_FILLED;
            break;
        case "FILLED":
            humanized = alertsMailingListGroupTitles.FILLED;
            break;
        default:
            break;
    }

    return (
        <span className={clsx("user_select", user_email_wrapper)}>
            <NameWithTooltip text={humanized} />
        </span>
    );
};
