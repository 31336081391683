import React from "react";

type Color = "WHITE" | "GREEN" | "GRAY";

export type BellReminderIconProps = {
    color: Color;
    className?: "";
};

export const BellReminderIcon = (props: BellReminderIconProps) => {
    const { color, className } = props;

    const colors = {
        WHITE: "white",
        GREEN: "#009788",
        GRAY: "#858B8E",
    };

    return (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.9 22 12 22ZM18 16V11C18 7.93 16.37 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.64 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16ZM16 17H8V11C8 8.52 9.51 6.5 12 6.5C14.49 6.5 16 8.52 16 11V17ZM7.58 4.08L6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5H4.03C4.18 7.85 5.54 5.53 7.58 4.08ZM19.97 10.5H21.97C21.82 7.3 20.24 4.48 17.85 2.65L16.43 4.08C18.45 5.53 19.82 7.85 19.97 10.5Z"
                fill={colors[color]}
            />
        </svg>
    );
};
