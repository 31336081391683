import React from "react";
import { observer } from "mobx-react";
import { resultsDisplayFilterStore, timeRangesStore } from "@difftone/stores";

import {
    CompareOption,
    Spinner,
    ResultsFilterPicker,
} from "@difftone/shared-components";
import {
    getSurveyFromStoreByURL,
    getTimeUnitFromUrlOrMonths,
} from "@difftone/reducers";

import {
    createAndUpdateTimeCompareInGraph,
    selectDisplayTimeCompareInPopup,
    setSelectedTimeCompareFilterAndTimeUnits,
    showDifftoneAlert,
    switchActiveCompareIndex,
    turnOffFilterAsCompare,
    turnOnFilterAsCompare,
} from "@difftone/actions";
import { TIME_FILTER_ID } from "@difftone/constants";

import {
    left_menu_children,
    left_menu_compare_picker,
    left_menu_filter_picker,
    filter_picker_spinner,
    compare_picker,
    compare_container_rtl,
    compare_content_ltr,
} from "./left-menu-children-for-graph.module.css";

export const LeftMenuChildrenForGraph = observer(() => {
    const {
        getFiltersActiveAsCompares,
        compareBy,
        popupDisplayTimeCompare,
        selectedTimeCompare,
    } = resultsDisplayFilterStore;
    const { extractKeyForTimeRange, getTimeRangeByKey } = timeRangesStore;

    const selectedSurvey = getSurveyFromStoreByURL();

    if (selectedSurvey === undefined) {
        return <Spinner className={filter_picker_spinner} size="SMALL" />;
    }

    if (selectedSurvey === null) {
        return null;
    }

    const filterActiveAsCompareUuids = getFiltersActiveAsCompares().map(
        (filter) => filter.filterKey.uuid
    );

    const timeRangeKey = extractKeyForTimeRange(
        selectedSurvey.uuid,
        getTimeUnitFromUrlOrMonths()
    );

    const timeRanges = getTimeRangeByKey(timeRangeKey);

    if (timeRanges === undefined) {
        return <Spinner className={filter_picker_spinner} size="SMALL" />;
    }

    if (timeRanges === null) {
        showDifftoneAlert(
            "Something went wrong, please reload and try again",
            "FAILURE"
        );
        return null;
    }

    let timeCompareFilter = compareBy.find((filter) =>
        filter.filterKey.uuid.includes(TIME_FILTER_ID)
    );

    if (!timeCompareFilter) {
        timeCompareFilter = createAndUpdateTimeCompareInGraph(timeRanges);
    }

    return (
        <ul className={left_menu_children}>
            <li className={left_menu_filter_picker}>
                <div>Filter by:</div>
                <ResultsFilterPicker />
            </li>
            <li className={left_menu_compare_picker}>
                <div>Choose a time frame:</div>

                <div className={compare_picker}>
                    <div className={compare_container_rtl}>
                        <div
                            data-compare-container
                            className={compare_content_ltr}
                        >
                            <CompareOption
                                isWithoutNonePopupOption={true}
                                isDisabled={false}
                                survey={selectedSurvey}
                                resultsFilter={timeCompareFilter}
                                activeAsComparesFiltersUUIDs={
                                    filterActiveAsCompareUuids
                                }
                                selectedResultsTimeCompare={selectedTimeCompare}
                                selectedPopupDisplayResultsTimeCompare={
                                    popupDisplayTimeCompare
                                }
                                setPopupDisplayResultsTimeCompare={
                                    selectDisplayTimeCompareInPopup
                                }
                                setSelectedTimeCompareResultsFilterAndTimeUnits={
                                    setSelectedTimeCompareFilterAndTimeUnits
                                }
                                resultsTurnOnFilterAsCompare={
                                    turnOnFilterAsCompare
                                }
                                resultsTurnOffFilterAsCompare={
                                    turnOffFilterAsCompare
                                }
                                switchResultsActiveCompareIndex={
                                    switchActiveCompareIndex
                                }
                            />
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    );
});
