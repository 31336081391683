import React from "react";
import blueIIcon from "@difftone/assets/blue-i-icon.svg";

import { info_timeline, info_icon } from "./info-timeline.module.css";
const INFO_TIMELINE = `In ongoing surveys, “time to send out” & “complete survey deadline” are deactivated.`;
export type InfoTimelineProps = {};

export const InfoTimeline = (props: InfoTimelineProps) => {
    return (
        <div className={info_timeline}>
            <img className={info_icon} src={blueIIcon} alt="info-icon" />

            <div>{INFO_TIMELINE}</div>
        </div>
    );
};
