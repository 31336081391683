import { SharedResult, SurveyToSimpleResultsMap, UUID } from "@difftone/types";
import { getApiBaseUrl } from "@difftone/constants";
import { errorHandlerProxy, localstorageUtils } from "@difftone/procedures";
import { createDifftoneAxios } from "@difftone/services";

const SERVER_BASE_URL = `${getApiBaseUrl()}/simple-results-map-service`;

export const getSimpleResultsMapFromApi = async (
    surveysUUIDs: UUID[]
): Promise<SurveyToSimpleResultsMap[]> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.post(
        `${SERVER_BASE_URL}`,
        {
            surveys_uuids: surveysUUIDs,
        },
        {
            params: {
                issuer: currentAuthData.issuer,
            },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const results = JSON.parse(response.data.results);
    return results;
};

export const getSimpleSharedResultsMapFromApi = async (
    sharedResult: SharedResult
): Promise<SurveyToSimpleResultsMap[]> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.post(
        `${SERVER_BASE_URL}/shared-result`,
        {
            shared_result: JSON.stringify(sharedResult),
            results_filter: JSON.stringify([]),
        },
        {
            params: {
                issuer: currentAuthData.issuer,
            },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = JSON.parse(response.data.results);
    return resource;
};
