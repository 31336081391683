import { makeAutoObservable } from "mobx";

class OrganizationDisplayStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _isCategoriesDialogOpened = false;

    get isCategoriesDialogOpened() {
        return this._isCategoriesDialogOpened;
    }

    set isCategoriesDialogOpened(bool: boolean) {
        this._isCategoriesDialogOpened = bool;
    }

    private _isPermissionsDialogOpened = false;

    get isPermissionsDialogOpened() {
        return this._isPermissionsDialogOpened;
    }

    set isPermissionsDialogOpened(bool: boolean) {
        this._isPermissionsDialogOpened = bool;
    }

    public clearStore = () => {
        this._isPermissionsDialogOpened = false;
    };
}

export const organizationDisplayStore = new OrganizationDisplayStore();
