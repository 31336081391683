import React from "react";
import { BaseSurvey, OngoingSurvey, SimpleSurvey } from "@difftone/types";
import { OngoingSurveyDate, TableDate } from "@difftone/shared-components";
import {
    getLastDateToFillDateEvent,
    getPublishedDateEvent,
} from "@difftone/reducers";

export type TableDateBySurveyClassProps = {
    dateType: "LAST_DATE" | "START_DATE";
    survey: BaseSurvey;
};

export const TableDateBySurveyClass = (props: TableDateBySurveyClassProps) => {
    const { dateType, survey } = props;

    const getTableLastDateComponent = (survey: BaseSurvey) => {
        switch (survey.survey_class) {
            case "ONGOING":
                const ongoingSurvey = survey as OngoingSurvey;
                return (
                    <OngoingSurveyDate
                        miliseconds={ongoingSurvey.manually_ended}
                        survey={ongoingSurvey}
                        isManuallyEnded={true}
                    />
                );
            case "SIMPLE":
            default:
                const simpleSurvey = survey as SimpleSurvey;
                const lastDateToFillDateEvent =
                    getLastDateToFillDateEvent(simpleSurvey);

                return <TableDate surveyEvent={lastDateToFillDateEvent} />;
        }
    };

    const getTablePublishedDateComponent = (survey: BaseSurvey) => {
        switch (survey.survey_class) {
            case "ONGOING":
                const ongoingSurvey = survey as OngoingSurvey;
                return (
                    <OngoingSurveyDate
                        miliseconds={ongoingSurvey.created_at}
                        survey={ongoingSurvey}
                        isManuallyEnded={false}
                    />
                );
            case "SIMPLE":
            default:
                const simpleSurvey = survey as SimpleSurvey;
                const publishedDateEvent = getPublishedDateEvent(simpleSurvey);

                return <TableDate surveyEvent={publishedDateEvent} />;
        }
    };

    switch (dateType) {
        case "START_DATE":
            return getTablePublishedDateComponent(survey);
        case "LAST_DATE":
            return getTableLastDateComponent(survey);
        default:
            throw Error(
                "[Difftone]::TableDateBySurveyClass date type must be included."
            );
    }
};
