import { makeAutoObservable } from "mobx";

import { fetchAutoCompleteTextByRepositoryType } from "./auto-complete-internal-actions";

export type AutoCompleteRepository = "attributes" | "dates" | "questions";

class AutoCompleteStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _autoCompleteOptions: string[] = [];
    get autoCompleteOptions() {
        return this._autoCompleteOptions;
    }
    set autoCompleteOptions(autocompleteList: string[]) {
        this._autoCompleteOptions = autocompleteList;
    }

    private _isAutocompleteOpen: boolean = false;
    get isAutocompleteOpen() {
        return this._isAutocompleteOpen;
    }
    set isAutocompleteOpen(newState: boolean) {
        this._isAutocompleteOpen = newState;
    }

    public getTitleAutoCompleteByRepositoryType = (
        prefix: string,
        repo: AutoCompleteRepository
    ) => {
        fetchAutoCompleteTextByRepositoryType(prefix, repo);
    };

    public clearStore = () => {
        this._isAutocompleteOpen = false;
        this._autoCompleteOptions = [];
    };
}

export const autoCompleteStore = new AutoCompleteStore();
