import React from "react";
import { wizardStore } from "@difftone/stores";
import { BaseSurvey } from "@difftone/types";

import { DatesMenu } from "./components";

export type WizardFloatingActionsProps = { survey?: BaseSurvey };

export const WizardFloatingActions = (props: WizardFloatingActionsProps) => {
    const { survey } = props;
    const { activeAccordionSection } = wizardStore;

    switch (activeAccordionSection) {
        case "TIME_LINE":
            return <DatesMenu survey={survey} />;
        default:
            return null;
    }
};
