import React, { ReactElement } from "react";
import { observer } from "mobx-react";

import {
    Question,
    QuestionType,
    BaseSurvey,
    ResultsMapAfterDigestion,
    QuestionToAnswerDescreteQuestion,
} from "@difftone/types";
import { categoriesStore, surveyResponseStatusStore } from "@difftone/stores";
import { extractDisplayNameFromCategory } from "@difftone/reducers";
import { COLUMN_0, NO_ANSWER_KEY } from "@difftone/common-constants";

import {
    results_question_details,
    results_question_details_item,
} from "./question-details.module.css";

export type QuestionDetailsProps = {
    question: Question<QuestionType>;
    survey: BaseSurvey;
    resultsMap: ResultsMapAfterDigestion;
};

export const QuestionDetails = observer(
    (props: QuestionDetailsProps): ReactElement => {
        const { question, survey, resultsMap } = props;
        const { categories } = question;
        const allCategories = categoriesStore.getCategoriesArray();
        const results: QuestionToAnswerDescreteQuestion = (resultsMap[
            COLUMN_0
        ] || {}) as QuestionToAnswerDescreteQuestion;

        const categoryNames = categories
            .map(
                (categoryUUID) =>
                    extractDisplayNameFromCategory(
                        categoryUUID,
                        allCategories
                    ) || ""
            )
            .join(", ");
        const notAnsweredParticipants = results[NO_ANSWER_KEY] || 0;
        const totalNumberOfCompleted =
            surveyResponseStatusStore.getStatusBySurveyUuid(survey.uuid).length;
        const answeredParticipants =
            totalNumberOfCompleted - notAnsweredParticipants;

        let showAnsweredParticipantsIndicator = null;

        switch (question.question_type_name) {
            case "DATE":
            case "TIME":
            case "SHORT_TEXT":
            case "LONG_TEXT":
                showAnsweredParticipantsIndicator = null;
                break;
            default:
                showAnsweredParticipantsIndicator = (
                    <div className={results_question_details}>
                        {categories.length ? (
                            <span className={results_question_details_item}>
                                Category: {categoryNames}
                            </span>
                        ) : null}
                        <span className={results_question_details_item}>
                            Answered: {answeredParticipants}/
                            {totalNumberOfCompleted}
                        </span>
                    </div>
                );
        }

        return <div>{showAnsweredParticipantsIndicator}</div>;
    }
);
