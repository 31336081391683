import React, { ChangeEvent, Ref, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { BaseSurvey, SurveyClasses } from "@difftone/types";
import { upsertSurveyToMapAndApiWithDebounce } from "@difftone/actions";

import { survey_introduction_textarea } from "./survey-introduction.module.css";

const SURVEY_INTRODUCTION_MAX_LENGTH = 450;

export type SurveyIntroductionProps = {
    survey: SurveyClasses;
    disabled: boolean;
};

export const SurveyIntroduction = observer((props: SurveyIntroductionProps) => {
    const { survey, disabled } = props;

    const componentRef: Ref<HTMLTextAreaElement> = useRef(null);

    const handleIntroductionChange = (
        event: ChangeEvent<HTMLTextAreaElement>
    ) => {
        const value: ValueOf<BaseSurvey> = event.target.value;
        survey.participant_introduction = value;
        upsertSurveyToMapAndApiWithDebounce(survey);
    };

    // Used for input height change on every new / removed line inside introduction message
    useEffect(() => {
        if (componentRef && componentRef.current) {
            componentRef.current.style.height = "0px";
            const scrollHeight = componentRef.current.scrollHeight;
            componentRef.current.style.height = `${scrollHeight}px`;
        }
    }, [survey.participant_introduction]);

    return (
        <div>
            <textarea
                disabled={disabled}
                ref={componentRef}
                onChange={handleIntroductionChange}
                value={survey.participant_introduction || ""}
                placeholder="Introduce your survey (optional)"
                maxLength={SURVEY_INTRODUCTION_MAX_LENGTH}
                className={survey_introduction_textarea}
            />
        </div>
    );
});
