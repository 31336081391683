import { makeAutoObservable } from "mobx";
import { SurveyResponseStatus, UUID } from "@difftone/types";
import { updateStatuses } from "./survey-response-status-internal-actions";
import { getUserUuid } from "@difftone/procedures";
import { surveyResponseStatusMapSetter } from "@difftone/actions";

class SurveyResponseStatusStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _surveyResponseStatusesMap: {
        [x: string]: SurveyResponseStatus[];
    } = {};
    get surveyResponseStatusesMap() {
        return this._surveyResponseStatusesMap;
    }
    set surveyResponseStatusesMap(newSurveyResponseStatusesMap) {
        this._surveyResponseStatusesMap = newSurveyResponseStatusesMap;
    }

    public getStatusBySurveyUuid = (
        surveyUuid: UUID
    ): SurveyResponseStatus[] => {
        if (!this._surveyResponseStatusesMap[surveyUuid]) {
            updateStatuses(surveyUuid);
            surveyResponseStatusMapSetter(surveyUuid, []);
        }
        return this._surveyResponseStatusesMap[surveyUuid];
    };

    public getSurveyStatusByResponder = (
        surveyUuid: UUID
    ): SurveyResponseStatus | null => {
        const response = this.getStatusBySurveyUuid(surveyUuid).find(
            (status) => status.responder === getUserUuid()
        );
        return response ? response : null;
    };

    public clearStore = () => {
        this._surveyResponseStatusesMap = {};
    };
}

export const surveyResponseStatusStore = new SurveyResponseStatusStore();
