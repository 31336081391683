import React from "react";
import { observer } from "mobx-react";
import {
    clearSurveyStore,
    setFilterProfile,
    setLocation,
    showDifftoneAlert,
    WizardActiveModeClickBrowserBackButton,
} from "@difftone/actions";
import { navigationStore, wizardDisplayStore } from "@difftone/stores";
import { DifftoneButton } from "@difftone/shared-components";
import { INBOX_URL, warningMessages } from "@difftone/constants";
import { objectsAreEqual } from "@difftone/frontend-common";
import { getSurveyFromStoreByURL } from "@difftone/reducers";
import arrow from "@difftone/assets/down-arrow-white.svg";

import {
    return_to_inbox_button,
    return_to_inbox_button_icon,
} from "./return-to-inbox-button.module.css";

export type ReturnToInboxButtonProps = {
    withClearSurveyStore?: Boolean;
};

export const ReturnToInboxButton = observer(
    (props: ReturnToInboxButtonProps) => {
        const { withClearSurveyStore } = props;
        const { currentPage } = navigationStore;
        const { surveyCopyToUpdate } = wizardDisplayStore;
        const { isEditableSurvey } = wizardDisplayStore;
        const survey = getSurveyFromStoreByURL();

        const _withClearSurveyStore =
            withClearSurveyStore === undefined ? true : withClearSurveyStore;

        const isInWizardPage = currentPage.includes("survey-wizard");

        const returnToInboxHandler = () => {
            if (_withClearSurveyStore) {
                clearSurveyStore();
            }

            setFilterProfile("NAME", "");

            if (isInWizardPage && !isEditableSurvey) {
                if (
                    surveyCopyToUpdate &&
                    !objectsAreEqual(survey, surveyCopyToUpdate)
                ) {
                    showDifftoneAlert(
                        warningMessages.goBackWithNoWizardChanges,
                        "FAILURE",
                        () => setLocation(INBOX_URL)
                    );
                    return;
                }
            }
            setLocation(INBOX_URL);
        };

        if (isInWizardPage && !isEditableSurvey) {
            WizardActiveModeClickBrowserBackButton(currentPage, survey);
        }

        return (
            <DifftoneButton
                buttonText="Inbox"
                icon={arrow}
                classNameOverride={return_to_inbox_button}
                iconClassName={return_to_inbox_button_icon}
                onClick={returnToInboxHandler}
            />
        );
    }
);
