import { action } from "mobx";
import { getTitleAutoCompleteFromApi } from "@difftone/services";

import { AutoCompleteRepository, autoCompleteStore } from "@difftone/stores";

export const fetchAutoCompleteTextByRepositoryType = action(
    async (prefix: string, repo: AutoCompleteRepository) => {
        try {
            const autoCompleteOptions = await getTitleAutoCompleteFromApi(
                prefix,
                repo
            );

            const autoCompleteOptionsAfterTrimming = autoCompleteOptions.reduce(
                (acc: string[], curr) =>
                    acc.includes(curr.trim()) ? acc : [...acc, curr.trim()],
                []
            );

            autoCompleteStore.autoCompleteOptions =
                autoCompleteOptionsAfterTrimming;
        } catch (error) {
            throw Error("Could not get auto complete list");
        }
    }
);
