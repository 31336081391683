import React from "react";
import { observer } from "mobx-react";

import { SurveyClasses } from "@difftone/types";

import { RESULTS_DISPLAY_TYPE } from "@difftone/constants";
import {
    ResultsActions,
    ResultsCardHeader,
    AboutSurvey,
} from "@difftone/shared-components";
import { dataCategoriesCSV, dataCSV } from "@difftone/reducers";
import { navigationStore, resultsDisplayStore } from "@difftone/stores";

import {
    ResultsFavorableSpreadsheet,
    ResultsGraphSpreadsheet,
    ResultsSpreadsheet,
} from "./components";

import {
    results_display_card,
    card_div,
} from "./results-display-card.module.css";

export type ResultDisplayCardProps = {
    selectedSurvey: SurveyClasses;
};

export const ResultsDisplayCard = observer((props: ResultDisplayCardProps) => {
    const { selectedSurvey } = props;
    const { categoriesQuestionsDisplayModeToggle } = resultsDisplayStore;

    const { currentPage } = navigationStore;

    const resultsDisplayType = () => {
        switch (currentPage) {
            case `${
                currentPage.includes(RESULTS_DISPLAY_TYPE.GRID)
                    ? currentPage
                    : null
            }`:
                return <ResultsSpreadsheet survey={selectedSurvey} />;
            case `${
                currentPage.includes(RESULTS_DISPLAY_TYPE.FAVORABLE)
                    ? currentPage
                    : null
            }`:
                return <ResultsFavorableSpreadsheet survey={selectedSurvey} />;
            case `${
                currentPage.includes(RESULTS_DISPLAY_TYPE.LINE)
                    ? currentPage
                    : null
            }`:
                return (
                    <ResultsGraphSpreadsheet selectedSurvey={selectedSurvey} />
                );
            default:
                return <ResultsSpreadsheet survey={selectedSurvey} />;
        }
    };
    return (
        <div className={results_display_card}>
            <div className={card_div}>
                <ResultsCardHeader surveyName={selectedSurvey.survey_name}>
                    <ResultsActions
                        dataCSV={
                            categoriesQuestionsDisplayModeToggle ===
                            "CATEGORIES"
                                ? dataCategoriesCSV(
                                      selectedSurvey as SurveyClasses
                                  )
                                : dataCSV(selectedSurvey as SurveyClasses)
                        }
                    />
                </ResultsCardHeader>
                <AboutSurvey survey={selectedSurvey} />
                {resultsDisplayType()}
            </div>
        </div>
    );
});
