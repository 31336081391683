import React from "react";

import { expanded_short_text_container } from "./expanded-short-text.module.css";

export type ExpandedShortTextProps = {
    disabled: boolean;
};

export const ExpandedShortText = (props: ExpandedShortTextProps) => {
    return (
        <div className={expanded_short_text_container}>
            <textarea
                disabled={props.disabled}
                placeholder="write short text"
                rows={1}
                maxLength={80}
            />
        </div>
    );
};
