import React from "react";
import { observer } from "mobx-react";
import {
    setVisibilityOfWizardCalendar,
    updateSelectedSurveyNonFilterQuestionResponse,
} from "@difftone/actions";
import { getAnswerByQuestion } from "@difftone/reducers";
import {
    getDateAtMidnightFromDateString,
    milisecondsToDateString,
} from "@difftone/time-utils";
import { surveyResponseStore, wizardStore } from "@difftone/stores";
import { CustomDateTimePicker, Spinner } from "@difftone/shared-components";
import {
    Answer,
    DateAndTime,
    DateAnswer,
    DateQuestion,
    Question,
} from "@difftone/types";

import { survey_expanded_date_wrapper } from "./expanded-date.module.css";

export type ExpandedDateProps = {
    disabled: boolean;
    question: Question<DateQuestion>;
    inPreviewMode: boolean;
    isEditableQuestion: boolean;
};

export const ExpandedDate = observer((props: ExpandedDateProps) => {
    const { question, disabled, inPreviewMode, isEditableQuestion } = props;

    let surveyResponseUUID: string | undefined;
    if (inPreviewMode) {
        surveyResponseUUID = "";
    } else {
        const { getSelectedSurveyResponseUUID } = surveyResponseStore;
        surveyResponseUUID = getSelectedSurveyResponseUUID();
    }
    if (surveyResponseUUID === undefined) {
        return <Spinner size="MEDIUM" />;
    }

    const answer = getAnswerByQuestion(
        question,
        surveyResponseUUID
    ) as Answer<DateAnswer>;

    const onChange = (dateAndTime: DateAndTime) => {
        if (inPreviewMode) {
            return;
        }
        const selectedDateInMiliseconds = getDateAtMidnightFromDateString(
            dateAndTime.date
        );
        answer.content.date = selectedDateInMiliseconds;
        updateSelectedSurveyNonFilterQuestionResponse(
            answer,
            surveyResponseUUID!
        );
    };

    const openEventsCalendar = () => {
        setVisibilityOfWizardCalendar(question.uuid);
    };

    const closeEventsCalendar = () => {
        setVisibilityOfWizardCalendar(null);
    };

    return (
        <div className={survey_expanded_date_wrapper}>
            <CustomDateTimePicker
                responseMode={true}
                localDateStr={
                    answer.content.date !== 0
                        ? milisecondsToDateString(answer.content.date)
                        : ""
                }
                dayUtcTime={0}
                onChange={onChange}
                disabled={disabled || !isEditableQuestion}
                isSystemEvent={false}
                isValid={true}
                isOpen={wizardStore.wizardFocusedCalendar === question.uuid}
                onOpen={openEventsCalendar}
                onClose={closeEventsCalendar}
                showTime={false}
            />
        </div>
    );
});
