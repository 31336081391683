import React from "react";
import { observer } from "mobx-react";
import { getFilterValuesByQuestionType } from "@difftone/actions";
import { ResultsFilter } from "@difftone/types";
import { FilterOption, FilterGenerator } from "./components";

import {
    filter_picker,
    filter_picker_wrapper,
    no_filters_to_generate,
} from "./filter-picker.module.css";

export type FilterPickerProps = {
    resultsFilters: ResultsFilter[];
    getActiveFilters: (filterUuid: string) => boolean[];
    setActiveFilters: (filterUuid: string, newValues: boolean[]) => void;
    updateSelectedFilters: (selectedFilters: ResultsFilter[]) => void;
};

export const FilterPicker = observer((props: FilterPickerProps) => {
    const {
        resultsFilters,
        getActiveFilters,
        setActiveFilters,
        updateSelectedFilters,
    } = props;

    const addFilter = (newSelectedFilter: ResultsFilter) => {
        const copyOfSelectedFilters = JSON.parse(
            JSON.stringify(resultsFilters)
        );

        const copyNewSelectedFilter: ResultsFilter = JSON.parse(
            JSON.stringify(newSelectedFilter)
        );

        const doesNewElementAlreadyExists = resultsFilters.find(
            (existingFilter) =>
                existingFilter.filterKey.uuid ===
                newSelectedFilter.filterKey.uuid
        );

        if (!doesNewElementAlreadyExists) {
            copyNewSelectedFilter.filterValues = getFilterValuesByQuestionType(
                newSelectedFilter.filterKey
            );

            copyOfSelectedFilters.push(copyNewSelectedFilter);
            updateSelectedFilters(copyOfSelectedFilters);
        }
    };

    return (
        <div className={filter_picker_wrapper}>
            <div data-filter-picker className={filter_picker}>
                {resultsFilters.length > 0 ? (
                    <FilterGenerator
                        selectedFilters={resultsFilters}
                        addFilter={addFilter}
                    />
                ) : (
                    <span className={no_filters_to_generate}>None</span>
                )}
                {resultsFilters.map((filter, index) => (
                    <FilterOption
                        filter={filter}
                        index={index}
                        key={filter.filterKey.uuid}
                        getActiveFilters={getActiveFilters}
                        setActiveFilters={setActiveFilters}
                    />
                ))}
            </div>
        </div>
    );
});
