import React from "react";
import { observer } from "mobx-react";

import { changeShowOnlyActiveSurveys } from "@difftone/actions";
import { Checkbox } from "@difftone/shared-components";
import { dashboardDisplayStore } from "@difftone/stores";

import {
    show_only_active_surveys_wrapper,
    show_only_active_surveys_text,
} from "./show-only-active-surveys.module.css";

export type ShowOnlyActiveSurveysProps = {};

export const ShowOnlyActiveSurveys = observer(
    (props: ShowOnlyActiveSurveysProps) => {
        const onChange = () => {
            changeShowOnlyActiveSurveys();
        };

        return (
            <div className={show_only_active_surveys_wrapper}>
                <Checkbox
                    checked={dashboardDisplayStore.isShowOnlyActiveSurveys}
                    onClick={onChange}
                />
                <div className={show_only_active_surveys_text}>
                    Show only active surveys
                </div>
            </div>
        );
    }
);
