import { Question, QuestionType, RatingQuestion } from "@difftone/types";
import React from "react";

import {
    avg_question_range,
    avg_title,
    avg_range,
} from "./avg-question-range.module.css";

export type AvgQuestionRangeProps = {
    question: Question<QuestionType>;
};

export const AvgQuestionRange = (props: AvgQuestionRangeProps) => {
    const { question } = props;

    const lastIndexInQuestionRange = (question as Question<RatingQuestion>)
        .content.rating_options.length;

    return (
        <div className={avg_question_range}>
            <div className={avg_title}>Avg</div>
            <div className={avg_range}>{`[1-${lastIndexInQuestionRange}]`}</div>
        </div>
    );
};
