import React, { FC, PropsWithChildren, useState } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import { SharedResult } from "@difftone/types";
import {
    removeShare,
    showDifftoneAlert,
    getResultPageSearchParams,
    setLocation,
} from "@difftone/actions";
import { timeRangesStore } from "@difftone/stores";
import { getTimeUnitFromUrlOrNone } from "@difftone/reducers";
import { ShowDifftoneTooltip, Spinner } from "@difftone/shared-components";
import deleteIcon from "@difftone/assets/delete-icon.svg";
import shareIcon from "@difftone/assets/share-survey.svg";
import resultsIcon from "@difftone/assets/survey-results.svg";

import {
    share,
    share_name,
    share_name_inner,
    share_icon,
    share_actions,
    share_actions_simple,
    share_actions_icon,
    share_actions_shown,
    share_actions_icon_export,
    spinner_style,
} from "./share.module.css";

export type ShareProps = SharedResult & {
    isSimpleResults?: boolean;
};

export const Share: FC<PropsWithChildren<ShareProps>> = observer(
    (props: ShareProps) => {
        const { extractKeyForTimeRange, getTimeRangeByKey } = timeRangesStore;
        const { isSimpleResults, ...sharedResult } = props;
        const {
            share_name: shareName,
            uuid,
            filters,
            survey_uuid,
            compare_by,
            survey_name,
            ...rest
        } = sharedResult;

        // temp variable to store menu visibility state
        const [isMenuShown, setIsMenuShown] = useState(false);

        const timeRageKey = extractKeyForTimeRange(
            survey_uuid,
            getTimeUnitFromUrlOrNone()
        );

        const timeRagesContainer = getTimeRangeByKey(timeRageKey);

        if (timeRagesContainer === undefined) {
            return <Spinner className={spinner_style} size="X-SMALL" />;
        }

        if (timeRagesContainer === null) {
            showDifftoneAlert(
                "Something went wrong, please reload and try again",
                "FAILURE"
            );
            return null;
        }

        const toggleActionsMenu = () => setIsMenuShown((state) => !state);

        const goToResults = () => {
            setLocation(
                `/results/${
                    sharedResult.survey_uuid
                }?${getResultPageSearchParams(sharedResult)}`
            );
        };

        const handleDelete = () => {
            showDifftoneAlert(
                "Are you sure you want to permanently delete",
                "DELETE",
                async () => {
                    await removeShare({
                        share_name: shareName,
                        uuid,
                        filters,
                        survey_uuid,
                        compare_by,
                        survey_name,
                        ...rest,
                    });
                    showDifftoneAlert(
                        `${shareName} has been deleted successfully!`,
                        "SUCCESS"
                    );
                },
                () => {},
                shareName
            );
        };

        return (
            <div
                key={uuid}
                className={share}
                onMouseEnter={toggleActionsMenu}
                onMouseLeave={toggleActionsMenu}
            >
                <img alt="" src={shareIcon} className={share_icon} />

                <span
                    onClick={goToResults}
                    className={share_name}
                    title={`${survey_name} / ${shareName}`}
                >
                    <ShowDifftoneTooltip
                        tooltipPosition="top"
                        tip={`${survey_name} / ${shareName}`}
                    >
                        <span className={share_name_inner}>
                            {survey_name} / {shareName}
                        </span>
                    </ShowDifftoneTooltip>
                </span>

                <div
                    className={clsx(share_actions, {
                        [share_actions_shown]: isMenuShown,
                        [share_actions_simple]: isSimpleResults,
                    })}
                >
                    {!isSimpleResults && (
                        <img
                            onClick={goToResults}
                            alt=""
                            src={resultsIcon}
                            className={share_actions_icon_export}
                        />
                    )}

                    <img
                        onClick={handleDelete}
                        alt=""
                        src={deleteIcon}
                        className={share_actions_icon}
                    />
                </div>
            </div>
        );
    }
);
