import React from "react";
import addIcon from "@difftone/assets/mobile-floating-add-button-icon.svg";
import { showDifftoneAlert } from "@difftone/actions";
import { constantAlertVariants } from "@difftone/constants";

import { mobile_floating_add_button } from "./mobile-floating-add-button.module.css";

export const MobileFloatingAddButton = () => {
    const onNewSurveyHandler = () => {
        showDifftoneAlert(
            constantAlertVariants.no_mobile_notification.text,
            constantAlertVariants.no_mobile_notification.variant
        );
    };

    return (
        <button
            onClick={onNewSurveyHandler}
            className={mobile_floating_add_button}
        >
            <img alt="add-action" src={addIcon} />
        </button>
    );
};
