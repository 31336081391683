import React from "react";

import {
    LongTextQuestion,
    MultipleChoiceQuestion,
    Question,
    QuestionType,
    RatingQuestion,
    SingleChoiceQuestion,
    SupportedQuestionResultsMapAfterDigestionWithoutNulls,
    BaseSurvey,
} from "@difftone/types";
import { NO_ANSWER_KEY, DATA_ANONYMIZED } from "@difftone/common-constants";
import {
    ResultBox,
    DetailedResultBoxAnonymousType,
} from "@difftone/shared-components";
import { getMostPopularForSingleOrMultipleChoice } from "@difftone/actions";

import {
    MultipleChoiceType,
    OpenQuestionsType,
    RatingType,
    SingleChoiceType,
} from "./components";

export type ResultsBoxFactoryByQuestionTypeProps = {
    question: Question<QuestionType>;
    index: number;
    digestedResultsRow: SupportedQuestionResultsMapAfterDigestionWithoutNulls;
    columnKey: string;
    survey: BaseSurvey;
};

export const ResultsBoxFactoryByQuestionType = (
    props: ResultsBoxFactoryByQuestionTypeProps
) => {
    const { question, index, digestedResultsRow, columnKey, survey } = props;

    const digestedResultsRowWithoutNoResponse = JSON.parse(
        JSON.stringify(digestedResultsRow)
    );

    Object.keys(digestedResultsRowWithoutNoResponse).forEach((column) => {
        delete digestedResultsRowWithoutNoResponse[column][NO_ANSWER_KEY];
    });

    let populationSizeForAnonymity = null;

    if (digestedResultsRowWithoutNoResponse["Column_0"] === DATA_ANONYMIZED) {
        populationSizeForAnonymity = 0;
    } else {
        switch (question.question_type_name) {
            case "RATING":
                populationSizeForAnonymity = Object.values(
                    digestedResultsRowWithoutNoResponse[columnKey]
                )?.reduce(
                    (sum: number, currentValue) => sum + Number(currentValue),
                    0
                );
                break;
            case "SINGLE_CHOICE":
            case "MULTIPLE_CHOICE":
                populationSizeForAnonymity =
                    getMostPopularForSingleOrMultipleChoice(
                        digestedResultsRowWithoutNoResponse,
                        columnKey
                    );
                break;
            case "DATE":
            case "TIME":
            case "SHORT_TEXT":
            case "LONG_TEXT":
                populationSizeForAnonymity =
                    digestedResultsRowWithoutNoResponse[columnKey].length;
                break;
            default:
                populationSizeForAnonymity = Object.values(
                    digestedResultsRowWithoutNoResponse[columnKey]
                )?.reduce(
                    (sum: number, currentValue) => sum + Number(currentValue),
                    0
                );
        }
    }

    const isBoxItemAnonymous =
        digestedResultsRowWithoutNoResponse[columnKey] === DATA_ANONYMIZED;

    switch (question.question_type_name) {
        case "RATING":
            return isBoxItemAnonymous ? (
                <DetailedResultBoxAnonymousType
                    color="primary"
                    surveyAnonymityLevel={survey.anonimity}
                />
            ) : (
                <RatingType
                    question={question as Question<RatingQuestion>}
                    index={index}
                    digestedResultsRow={
                        digestedResultsRowWithoutNoResponse as SupportedQuestionResultsMapAfterDigestionWithoutNulls
                    }
                    columnKey={columnKey}
                />
            );
        case "SINGLE_CHOICE":
            return isBoxItemAnonymous ? (
                <DetailedResultBoxAnonymousType
                    color="primary"
                    surveyAnonymityLevel={survey.anonimity}
                />
            ) : (
                <SingleChoiceType
                    question={question as Question<SingleChoiceQuestion>}
                    index={index}
                    digestedResultsRow={
                        digestedResultsRowWithoutNoResponse as SupportedQuestionResultsMapAfterDigestionWithoutNulls
                    }
                    columnKey={columnKey}
                />
            );
        case "MULTIPLE_CHOICE":
            return isBoxItemAnonymous ? (
                <DetailedResultBoxAnonymousType
                    color="primary"
                    surveyAnonymityLevel={survey.anonimity}
                />
            ) : (
                <MultipleChoiceType
                    question={question as Question<MultipleChoiceQuestion>}
                    index={index}
                    digestedResultsRow={
                        digestedResultsRowWithoutNoResponse as SupportedQuestionResultsMapAfterDigestionWithoutNulls
                    }
                    columnKey={columnKey}
                />
            );
        case "SHORT_TEXT":
        case "LONG_TEXT":
        case "DATE":
        case "TIME":
            return isBoxItemAnonymous ? (
                <DetailedResultBoxAnonymousType
                    color="primary"
                    surveyAnonymityLevel={survey.anonimity}
                />
            ) : (
                <OpenQuestionsType
                    question={question as Question<LongTextQuestion>}
                    index={index}
                    digestedResultsRow={
                        digestedResultsRowWithoutNoResponse as SupportedQuestionResultsMapAfterDigestionWithoutNulls
                    }
                    columnKey={columnKey}
                />
            );
        default:
            return (
                <ResultBox
                    key={question.uuid + index}
                    colorIndicator={index === 0 ? "OVERALL" : "DEFAULT"}
                    resultValue={"err"}
                />
            );
    }
};
