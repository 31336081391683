import React from "react";

import { eta_svg_circle_color } from "./display-active-circle.module.css";

export type etaDynamicStyle = {
    strokeDasharray: string;
    strokeDashoffset: number;
};

export type DisplayActiveCircleProps = {
    strokeDasharray: string;
    strokeDashoffset: number;
};

export const DisplayActiveCircle = (props: DisplayActiveCircleProps) => {
    const { strokeDasharray, strokeDashoffset } = props;
    const style = {
        strokeDasharray,
        strokeDashoffset,
    };
    const eta_radius = 13;
    return (
        <svg width="30" height="30" viewBox="0 0 30 30">
            <circle cx={eta_radius + 1} cy={eta_radius + 1} r={eta_radius} />
            <circle
                className={eta_svg_circle_color}
                style={style}
                cx={eta_radius + 1}
                cy={eta_radius + 1}
                r={eta_radius}
            />
        </svg>
    );
};
