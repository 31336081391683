import { action } from "mobx";
import {
    loginStore,
    inboxStore,
    wizardStore,
    userStore,
    surveyStore,
    surveyResponsesSummaryStore,
    surveyResponseStore,
    surveyResponseStatusStore,
    alertsStore,
    autoCompleteStore,
    simpleSurveyResultsStore,
    simpleResultsMapStore,
    sharedSurveyStore,
    sharedSurveyDisplayStore,
    scheduleSurveyPopupStore,
    resultsMapStore,
    leftMenuStore,
    navigationStore,
    cloudsAlertStore,
    resultsDataStore,
    difftoneAlertStore,
    publicProfileStore,
    connectionListStore,
    resultsDisplayStore,
    previewSurveyStore,
    userProfileStore,
    sharedResultStore,
    categoriesStore,
    surveyResponseDisplayStore,
    templatesStore,
    wizardDisplayStore,
    dashboardDisplayStore,
    templatesDisplayStore,
    groupEmailsStore,
    sharedResultsDisplayStore,
    organizationStore,
    sharedResultsResultsMapStore,
    resultsDisplayFilterStore,
} from "@difftone/stores";
import { setLocation } from "@difftone/actions";
import { getAuthInstance } from "@difftone/procedures";
import { MouseEvent } from "react";

const clearAllDataInStores = action(() => {
    loginStore.clearStore();
    inboxStore.clearStore();
    wizardStore.clearStore();
    userStore.clearStore();
    surveyStore.clearStore();
    surveyResponsesSummaryStore.clearStore();
    surveyResponseStore.clearStore();
    surveyResponseDisplayStore.clearStore();
    surveyResponseStatusStore.clearStore();
    alertsStore.clearStore();
    autoCompleteStore.clearStore();
    simpleSurveyResultsStore.clearStore();
    simpleResultsMapStore.clearStore();
    sharedSurveyStore.clearStore();
    sharedSurveyDisplayStore.clearStore();
    scheduleSurveyPopupStore.clearStore();
    resultsMapStore.clearStore();
    leftMenuStore.clearStore();
    navigationStore.clearStore();
    cloudsAlertStore.clearStore();
    resultsDataStore.clearStore();
    difftoneAlertStore.clearStore();
    publicProfileStore.clearStore();
    connectionListStore.clearStore();
    resultsDisplayStore.clearStore();
    previewSurveyStore.clearStore();
    userProfileStore.clearStore();
    sharedResultStore.clearStore();
    categoriesStore.clearStore();
    templatesStore.clearStore();
    templatesDisplayStore.clearStore();
    wizardDisplayStore.clearStore();
    dashboardDisplayStore.clearStore();
    groupEmailsStore.clearStore();
    sharedResultsDisplayStore.clearStore();
    organizationStore.clearStore();
    sharedResultsResultsMapStore.clearStore();
    resultsDisplayFilterStore.clearStore();
});

export const logoutFromLocalStorage = action(() => {
    localStorage.removeItem("authData");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("clientVersion");
    localStorage.removeItem("selectedOrganization");
    clearAllDataInStores();
    setLocation("/home");
});

export const logoutFromMicrosoft = () => {
    getAuthInstance()?.logout();
    logoutFromLocalStorage();
};

export const logoutFromGoogle = async () => {
    try {
        const auth2 = gapi.auth2.getAuthInstance();
        await auth2.signOut();
    } catch (error) {
        console.error("Failed to sign out from Google");
    }
    logoutFromLocalStorage();
};

const logOut = (ref?: string) => {
    logoutFromLocalStorage();
    const locationURL = ref ? `/home?ref=${ref}` : `/home`;
    setLocation(locationURL);
};

export const isLoggedIn = () => {
    return !!localStorage.getItem("authData");
};

export const setGoogleError = action((error: Object | null) => {
    loginStore.googleError = error;
});

export const setGoogleButtonClickEvent = action(
    (event: MouseEvent<HTMLButtonElement>) => {
        loginStore.googleButtonClickEvent = event;
    }
);

export const setIsGoogleButtonClicked = action((isClicked: boolean) => {
    loginStore.isGoogleButtonClicked = isClicked;
});

export const setIsOrganizationUser = action((isClicked: boolean) => {
    loginStore.isOrganizationUser = isClicked;
});
