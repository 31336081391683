import React, { Ref, useRef } from "react";
import { observer } from "mobx-react";

import { EmailAddress, BaseSurvey, SimpleSurvey } from "@difftone/types";
import {
    debounceUpsertSurveyToApi,
    handleMultipleResondentsChange,
    removeEmailFromAlertsInWizard,
    removeResolvedEmailsFromSurveyWizardCopy,
} from "@difftone/actions";
import { wizardDisplayStore } from "@difftone/stores";
import { getSurveyFromStoreByURL } from "@difftone/reducers";
import { MailingListProducer } from "@difftone/shared-components";
import {
    wizard_participants,
    participants_permissions,
    mailing_list_producer_wrapper,
    show_explanation,
} from "./wizard-participants.module.css";

const ONGOING_EXPLANATION: string =
    "Survey participants will not be allowed to edit their responses after submitting them";

export const WizardParticipants = observer(() => {
    const { activeMailingList, firstClickOnMailingType, surveyCopyToUpdate } =
        wizardDisplayStore;
    const { isEditableSurvey } = wizardDisplayStore;

    const inputRef: Ref<HTMLInputElement> = useRef(null);

    const survey = getSurveyFromStoreByURL();

    const focusParticipantsBox = () => {
        setTimeout(() => {
            if (inputRef && inputRef.current) {
                inputRef.current.focus();
            }
        }, 0);
    };

    const handleRespondentsDelete = async (emailToDelete: EmailAddress) => {
        if (!isEditableSurvey) {
            removeResolvedEmailsFromSurveyWizardCopy(emailToDelete);
            return;
        }

        if (!survey) {
            throw Error(
                "[Difftone]:: handleRespondentsDelete was called on null survey"
            );
        }

        const value: ValueOf<BaseSurvey> = survey.respondents.filter(
            (email: EmailAddress) => email !== emailToDelete
        );

        const key: keyof BaseSurvey =
            activeMailingList === "ADMINS" ? "admins" : "respondents";
        debounceUpsertSurveyToApi(key, value);

        if (survey.survey_class === "SIMPLE") {
            await removeEmailFromAlertsInWizard(
                survey as SimpleSurvey,
                emailToDelete
            );
        }
    };

    const onRespondentsChange = (emails: EmailAddress[]) => {
        handleMultipleResondentsChange(
            emails,
            survey,
            activeMailingList === "ADMINS" ? "ADMINS" : "RESPONDENTS",
            !isEditableSurvey
        );
        focusParticipantsBox();
    };

    const mailingListParticipants = Array.from(
        new Set([
            ...survey.respondents,
            ...(surveyCopyToUpdate?.respondents || []),
        ])
    );

    //TODO: use case of same component in ongoing and simple survey https://app.asana.com/0/1201346022025578/1202834935064685/f
    return (
        <div className={wizard_participants} data-wizard-participants>
            <div className={mailing_list_producer_wrapper}>
                <MailingListProducer
                    inWizardDetails
                    ref={inputRef}
                    firstClickOnMailingType={firstClickOnMailingType}
                    activeMailingListType={activeMailingList}
                    disabled={true}
                    styleOptions={{
                        listBoxHeight: 380,
                    }}
                    readOnlyMalingList={isEditableSurvey}
                    mailingList={mailingListParticipants}
                    onEnter={onRespondentsChange}
                    onDelete={handleRespondentsDelete}
                    nonDeletebleMailingList={
                        !isEditableSurvey ? survey.respondents : []
                    }
                />
            </div>
            <div className={participants_permissions}>
                <span className={show_explanation}>{ONGOING_EXPLANATION}</span>
            </div>
        </div>
    );
});
