import { BaseSurvey, SimpleSurvey, TimelineEvent } from "@difftone/types";
import { v4 as uuid } from "uuid";

import {
    calculateTotalTimeInMilliseconds,
    getDateAtMidnightFromDateString,
    getDayTimeFromTimeString,
    getNormalizeOffsetInMiliseconds,
    getUtcDateAtMidnightInMils,
    getUTCDayTimeInMils,
    milisecondsToDateString,
    milisecondsToTimeString,
} from "@difftone/time-utils";
import { populateEmptySurveyEvent } from "@difftone/actions";

export const getSortedEvents = (survey: SimpleSurvey) => {
    if (!survey) {
        return;
    }
    return getSortedSurveyEventsCopyWithCurrentTime(survey);
};

export const getSortedSurveyEventsCopy = (
    survey: SimpleSurvey
): TimelineEvent[] => {
    if (!survey.survey_events.length) {
        return [];
    }

    const surveyEventsCopy: TimelineEvent[] = JSON.parse(
        JSON.stringify(survey.survey_events)
    );

    const sortedEvents = surveyEventsCopy.sort(
        (surveyEventA, surveyEventB) =>
            calculateTotalTimeInMilliseconds(
                //TODO: Add null handling for function and function return
                getDateAtMidnightFromDateString(surveyEventA.date_utc!),
                //TODO: Add null handling for function and function return
                getDayTimeFromTimeString(surveyEventA.day_utc_time!)
            ) -
            calculateTotalTimeInMilliseconds(
                //TODO: Add null handling for function and function return
                getDateAtMidnightFromDateString(surveyEventB.date_utc!),
                //TODO: Add null handling for function and function return
                getDayTimeFromTimeString(surveyEventB.day_utc_time!)
            )
    );
    return sortedEvents;
};

const getSortedSurveyEventsCopyWithCurrentTime = (
    survey: SimpleSurvey
): TimelineEvent[] => {
    if (!survey.survey_events.length) {
        return [];
    }

    const surveyEventsCopy: TimelineEvent[] = JSON.parse(
        JSON.stringify(survey.survey_events)
    );

    const currentDateUtc = getUtcDateAtMidnightInMils();
    const dayUtcTime = getUTCDayTimeInMils();

    surveyEventsCopy.forEach((surveyEvent) => {
        if (
            surveyEvent.date_utc === null ||
            surveyEvent.day_utc_time === null
        ) {
            populateEmptySurveyEvent(surveyEvent);
        }
    });

    const currentTimeEvent: TimelineEvent = {
        uuid: uuid(),
        title: "current time",
        version: "0.0.3",
        isCurrentTime: true,
        system_survey_event_type: false,
        utc_offset: getNormalizeOffsetInMiliseconds(),
        date_utc: milisecondsToDateString(currentDateUtc),
        day_utc_time: milisecondsToTimeString(dayUtcTime),
        event_number: null,
        event_alerts: [],
        event_emails: [],
    };
    surveyEventsCopy.push(currentTimeEvent);

    const sortedEvents = surveyEventsCopy.sort(
        (surveyEventA, surveyEventB) =>
            calculateTotalTimeInMilliseconds(
                //TODO: Add null handling for function and function return
                getDateAtMidnightFromDateString(surveyEventA.date_utc!),
                //TODO: Add null handling for function and function return
                getDayTimeFromTimeString(surveyEventA.day_utc_time!)
            ) -
            calculateTotalTimeInMilliseconds(
                //TODO: Add null handling for function and function return
                getDateAtMidnightFromDateString(surveyEventB.date_utc!),
                //TODO: Add null handling for function and function return
                getDayTimeFromTimeString(surveyEventB.day_utc_time!)
            )
    );

    return sortedEvents;
};
