import React, { useState } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import OutsideClickHandler from "react-outside-click-handler";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import { illegalDateFormatStringCheck } from "@difftone/frontend-common/frontend-validations/survey-validations";
import {
    CalendarTimeDropdown,
    ValidationWrapper,
} from "@difftone/shared-components";
import {
    warningMessages,
    SMALL_MOBILE_WIDTH_BREAKPOINT,
} from "@difftone/constants";
import { DateAndTime, SystemSurveyEventType } from "@difftone/types";
import {
    calculateTotalTimeInMilliseconds,
    getDateAtMidnightFromDateString,
    getLocalDayTimeInMilsWithDateString,
    getNormalizeOffsetInMiliseconds,
    milisecondsToDateString,
    milisecondsToTimeString,
} from "@difftone/time-utils";

import { SelectedDateTimeDisplayBox } from "./components";

import {
    custom_date_time_picker,
    toggle_calendar_display,
    invalid_date_border,
    custom_date_time_picker_no_border,
    date_time_picker_opened_calendar,
    custom_date_time_picker_wrapper,
    custom_date_time_picker_wrapper_opened_calendar,
    opened_calendar_response_mode,
    open_calendar_edit_mode,
} from "./custom-date-time-picker.module.css";

dayjs.extend(duration);
dayjs.extend(utc);

export type CustomDateTimePickerProps = {
    isSystemEvent: SystemSurveyEventType;
    isValid: boolean;
    localDateStr: string;
    dayUtcTime: number;
    onChange: (dateTime: DateAndTime) => void;
    disabled: boolean;
    inEditMode?: boolean;
    isOpen?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    showTime?: boolean;
    timeSchedule?: string;
    isNoBorder?: boolean;
    isDisableIcon?: boolean;
    responseMode?: boolean;
    isReSize?: boolean;
    isOnlyDateDisplay?: boolean;
};

export const CustomDateTimePicker = observer(
    (props: CustomDateTimePickerProps) => {
        const {
            disabled,
            inEditMode,
            localDateStr,
            dayUtcTime,
            onChange,
            isValid,
            isSystemEvent,
            isOpen,
            onOpen = () => {},
            onClose = () => {},
            showTime = true,
            timeSchedule,
            isNoBorder,
            isDisableIcon,
            responseMode,
            isReSize,
            isOnlyDateDisplay,
        } = props;

        const [isCalendarOpen, setIsCalendarOpen] = useState(false);

        const localDayTime = getLocalDayTimeInMilsWithDateString(
            dayUtcTime,
            localDateStr
        );
        const dateInMiliSeconds = getDateAtMidnightFromDateString(localDateStr);

        const openCalendar = () => {
            if (disabled) {
                setIsCalendarOpen(false);
                return;
            }
            isOpen === undefined ? setIsCalendarOpen(true) : onOpen();
        };

        const closeCalendar = () => {
            setIsCalendarOpen(false);
            isOpen === undefined ? setIsCalendarOpen(false) : onClose();
        };

        const onSetEventTimeHandler = (
            newDateString: string,
            newTimeString: string
        ) => {
            const dateAndTime: DateAndTime = {
                date: newDateString,
                time: newTimeString,
            };

            onChange(dateAndTime);
        };

        const _isOpen = isOpen === undefined ? isCalendarOpen : isOpen;

        const validationErrorMessageByEventType = () => {
            if (localDateStr === illegalDateFormatStringCheck[localDateStr]) {
                return warningMessages.eventIllegalDateFormat;
            }

            switch (isSystemEvent) {
                case "LAST_DATE_TO_FILL":
                    const eventTimeInMiliSeconds =
                        calculateTotalTimeInMilliseconds(
                            dateInMiliSeconds,
                            localDayTime
                        ) - getNormalizeOffsetInMiliseconds();

                    if (!inEditMode) {
                        if (eventTimeInMiliSeconds <= Date.now()) {
                            return warningMessages.lastDateToFillGreaterThenNowError;
                        }
                    }
                    return warningMessages.lastDateToFillEventTimeError;
                case "PUBLISHED_DATE":
                    return warningMessages.publishDateTimeError;
                case "BOOKING_DATE":
                    return warningMessages.bookingDateToFillEventTimeError;
                default:
                    return "";
            }
        };

        const validationWrapperTooltipPosition = inEditMode ? "TOP" : "RIGHT";

        return isSystemEvent ? (
            <ValidationWrapper
                isValid={inEditMode ? isValid : _isOpen || isValid}
                errorMessage={validationErrorMessageByEventType()}
                inEditMode={inEditMode}
                tooltipPosition={
                    window.innerWidth < SMALL_MOBILE_WIDTH_BREAKPOINT
                        ? "BOTTOM"
                        : validationWrapperTooltipPosition
                }
                tooltipPositionOverride={{ left: "calc(100% + 20px)" }}
            >
                <div
                    className={clsx(
                        custom_date_time_picker_wrapper,
                        _isOpen &&
                            clsx(
                                custom_date_time_picker_wrapper_opened_calendar,
                                inEditMode && open_calendar_edit_mode
                            )
                    )}
                >
                    <div
                        className={
                            inEditMode
                                ? isValid
                                    ? clsx(
                                          custom_date_time_picker,
                                          _isOpen &&
                                              date_time_picker_opened_calendar
                                      )
                                    : clsx(
                                          custom_date_time_picker,
                                          invalid_date_border
                                      )
                                : _isOpen || isValid
                                ? clsx(
                                      custom_date_time_picker,
                                      _isOpen &&
                                          date_time_picker_opened_calendar
                                  )
                                : clsx(
                                      custom_date_time_picker,
                                      invalid_date_border
                                  )
                        }
                    >
                        <div
                            onClick={openCalendar}
                            className={toggle_calendar_display}
                        ></div>
                        <SelectedDateTimeDisplayBox
                            disabled={disabled}
                            openCalendar={openCalendar}
                            timeValue={milisecondsToTimeString(localDayTime)}
                            dateValue={localDateStr}
                        />
                        {_isOpen ? (
                            <OutsideClickHandler onOutsideClick={closeCalendar}>
                                <CalendarTimeDropdown
                                    inEditMode={inEditMode}
                                    timeValue={milisecondsToTimeString(
                                        localDayTime
                                    )}
                                    dateValue={
                                        localDateStr.length
                                            ? localDateStr
                                            : milisecondsToDateString(
                                                  Date.now()
                                              )
                                    }
                                    onSetEventTime={onSetEventTimeHandler}
                                    onBlur={closeCalendar}
                                    showTime={showTime}
                                />
                            </OutsideClickHandler>
                        ) : null}
                    </div>
                </div>
            </ValidationWrapper>
        ) : (
            <ValidationWrapper
                errorMessage={validationErrorMessageByEventType()}
                tooltipPosition={
                    window.innerWidth < SMALL_MOBILE_WIDTH_BREAKPOINT
                        ? "BOTTOM"
                        : "RIGHT"
                }
                isValid={_isOpen || isValid}
            >
                <div
                    className={clsx(
                        custom_date_time_picker_wrapper,
                        _isOpen &&
                            clsx(
                                custom_date_time_picker_wrapper_opened_calendar,
                                responseMode && opened_calendar_response_mode,
                                inEditMode && open_calendar_edit_mode
                            )
                    )}
                >
                    <div
                        className={
                            isNoBorder
                                ? custom_date_time_picker_no_border
                                : _isOpen || isValid
                                ? clsx(
                                      custom_date_time_picker,
                                      _isOpen &&
                                          date_time_picker_opened_calendar
                                  )
                                : clsx(
                                      custom_date_time_picker,
                                      invalid_date_border
                                  )
                        }
                    >
                        <div
                            onClick={openCalendar}
                            className={toggle_calendar_display}
                        ></div>
                        <SelectedDateTimeDisplayBox
                            isOnlyDateDisplay={isOnlyDateDisplay}
                            disabled={disabled}
                            openCalendar={openCalendar}
                            timeValue={milisecondsToTimeString(localDayTime)}
                            dateValue={localDateStr}
                            showTime={showTime}
                            timeSchedule={
                                timeSchedule &&
                                `${milisecondsToTimeString(
                                    localDayTime
                                )} - ${timeSchedule}`
                            }
                            isDisableIcon={isDisableIcon}
                            isReSize={isReSize}
                        />
                        {_isOpen ? (
                            <OutsideClickHandler onOutsideClick={closeCalendar}>
                                <CalendarTimeDropdown
                                    responseMode={responseMode}
                                    inEditMode={inEditMode}
                                    timeValue={milisecondsToTimeString(
                                        localDayTime
                                    )}
                                    dateValue={
                                        localDateStr.length
                                            ? localDateStr
                                            : milisecondsToDateString(
                                                  Date.now()
                                              )
                                    }
                                    onSetEventTime={onSetEventTimeHandler}
                                    onBlur={closeCalendar}
                                    showTime={showTime}
                                />
                            </OutsideClickHandler>
                        ) : null}
                    </div>
                </div>
            </ValidationWrapper>
        );
    }
);
