import React from "react";

import {
    selection_answer_box,
    selected_answer_dot,
} from "./selection-answer-box.module.css";

export type SelectionAnswerBoxProps = {
    isSelected: boolean;
};

export const SelectionAnswerBox = (props: SelectionAnswerBoxProps) => {
    const { isSelected } = props;
    return (
        <div className={selection_answer_box}>
            {isSelected ? <div className={selected_answer_dot}></div> : null}
        </div>
    );
};
