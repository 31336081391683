import React from "react";
import clsx from "clsx";
import { SimpleSurvey } from "@difftone/types";
import { UiStatus } from "@difftone/frontend-common";

import { getLastDateToFill, getPublishedDate } from "@difftone/reducers";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";

import {
    DisplayActiveCircle,
    DisplayDateAndCircleGray,
    EndedSurveyIcon,
} from "./components";
import { ShowDifftoneTooltip } from "@difftone/shared-components";

import {
    eta_box,
    value_display_box,
    eta_box_information,
    completed_or_ended_time_wrapper,
    completed_or_ended_time_color_display,
} from "./eta.module.css";
import { navigationStore } from "@difftone/stores";

dayjs.extend(duration);
dayjs.extend(utc);

const ONE_MONTH_IN_DAYS = 30;
const TWO_MONTHS_IN_DAYS = 61;

export type ETAProps = {
    survey: SimpleSurvey;
    surveyStatus: UiStatus;
    show?: "DURATION" | "ETA";
};

export const ETA = (props: ETAProps) => {
    const { currentPage } = navigationStore;
    const pageLocation = currentPage.split("/")[1];

    const isInformationPage = currentPage.split("/")[2];

    const { survey, surveyStatus, show } = props;

    let strokeDashCalculation = 0;
    let strokeDasharray = "";
    let strokeDashoffset = 0;
    const eta_radius = 13;
    const twoPieRadius = 2 * Math.PI * eta_radius;

    const published_date = getPublishedDate(survey);
    const last_date_to_fill = getLastDateToFill(survey);
    const nowInMilSeconds = dayjs.utc(new Date().getTime()).valueOf();

    const readableETA = dayjs
        .duration(last_date_to_fill! - nowInMilSeconds)
        .format("YYYY MM DD HH mm ss");

    const durationTime = dayjs
        .duration(last_date_to_fill! - published_date!)
        .format("YYYY MM DD HH mm ss");

    if (
        last_date_to_fill &&
        published_date &&
        (surveyStatus === "ACTIVE" || surveyStatus === "COMPLETED")
    ) {
        const progressPercent =
            ((nowInMilSeconds - published_date) /
                (last_date_to_fill - published_date)) *
            100;

        strokeDashCalculation =
            twoPieRadius - (twoPieRadius * progressPercent) / 100;
        strokeDasharray = `${twoPieRadius}`;
        strokeDashoffset = strokeDashCalculation;
    }

    const extractRemainingTime = (etaDate: string) => {
        const etaAsArray = etaDate.split(" ");
        const years = Number(etaAsArray[0]);
        const months = Number(etaAsArray[1]);
        const days = Number(etaAsArray[2]);
        const hours = Number(etaAsArray[3]);
        const minutes = Number(etaAsArray[4]);
        const seconds = Number(etaAsArray[5]);
        if (years) return `${years} Years`;
        if (months >= 3) return `${months} Months`;
        else {
            // TODO: ensure exact month length 30?31?28?
            if (months === 1)
                return {
                    short: `${ONE_MONTH_IN_DAYS + days} Days`,
                    long: `${ONE_MONTH_IN_DAYS + days} Days, ${
                        hours >= 10 ? hours : `0${hours}`
                    }:${minutes >= 10 ? minutes : `0${minutes}`}`,
                };
            if (months === 2)
                return {
                    short: `${TWO_MONTHS_IN_DAYS + days} Days`,
                    long: `${TWO_MONTHS_IN_DAYS + days} Days, ${
                        hours >= 10 ? hours : `0${hours}`
                    }:${minutes >= 10 ? minutes : `0${minutes}`}`,
                };
        }
        if (days >= 3)
            return {
                short: `${days} Days`,
                long: `${days} Days, ${hours >= 10 ? hours : `0${hours}`}:${
                    minutes >= 10 ? minutes : `0${minutes}`
                }`,
            };
        else {
            if (days === 1)
                return {
                    short: `1 Day`,
                    long: `1 Day, ${hours}:${
                        minutes >= 10 ? minutes : `0${minutes}`
                    }`,
                };
            if (days === 2)
                return {
                    short: `2 Days`,
                    long: `2 Days, ${hours}:${
                        minutes >= 10 ? minutes : `0${minutes}`
                    }`,
                };
        }
        if (hours)
            return {
                short: `${hours} Hours`,
                long: `${hours >= 10 ? hours : `0${hours}`}:${
                    minutes >= 10 ? minutes : `0${minutes}`
                }`,
            };
        if (minutes)
            return {
                short: `${minutes} Minutes`,
                long: `00:${minutes >= 10 ? minutes : `0${minutes}`}`,
            };
        if (seconds) return `${seconds} Seconds`;
    };

    const remainingTime =
        show === "DURATION"
            ? extractRemainingTime(durationTime)
            : extractRemainingTime(readableETA);

    const remainingTimeDisplay = remainingTime ? (
        typeof remainingTime === "string" ? (
            <div className={value_display_box}>{remainingTime}</div>
        ) : (
            <ShowDifftoneTooltip tooltipPosition="top" tip={remainingTime.long}>
                <div className={value_display_box}>{remainingTime.short}</div>
            </ShowDifftoneTooltip>
        )
    ) : null;

    const isTimeRemainingCircleShown =
        last_date_to_fill &&
        published_date &&
        surveyStatus !== "SAVED" &&
        surveyStatus !== "ENDED" &&
        surveyStatus !== "DRAFT";

    return (
        <div
            className={pageLocation === "inbox" ? eta_box : eta_box_information}
        >
            {isTimeRemainingCircleShown ? (
                surveyStatus === "COMPLETED" &&
                last_date_to_fill < nowInMilSeconds ? (
                    <DisplayDateAndCircleGray />
                ) : (
                    <>
                        {pageLocation === "inbox" && (
                            <DisplayActiveCircle
                                strokeDasharray={strokeDasharray}
                                strokeDashoffset={strokeDashoffset}
                            />
                        )}
                        {remainingTimeDisplay}
                    </>
                )
            ) : (
                <>
                    {surveyStatus === "ENDED" ? (
                        <DisplayDateAndCircleGray />
                    ) : (
                        <div className={completed_or_ended_time_wrapper}>
                            <EndedSurveyIcon
                                surveyStatus={surveyStatus}
                                pageLocation={isInformationPage}
                            />
                            {last_date_to_fill &&
                            last_date_to_fill > nowInMilSeconds &&
                            surveyStatus !== "DRAFT" &&
                            remainingTimeDisplay ? (
                                <div>{remainingTimeDisplay}</div>
                            ) : (
                                <>
                                    {surveyStatus === "DRAFT" ? null : (
                                        <div
                                            className={clsx(
                                                value_display_box,
                                                completed_or_ended_time_color_display
                                            )}
                                        >
                                            00:00
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
