import React from "react";

import { AddBoxShadow } from "@difftone/shared-components";

import {
    wrapper,
    popup,
    popup_header,
    popup_title,
    popup_sub_title,
    body_popup,
} from "./user-not-exist-dialog.module.css";

export type UserNotExistDialogProps = {};

export const UserNotExistDialog = (props: UserNotExistDialogProps) => {
    return (
        <div className={wrapper}>
            <AddBoxShadow>
                <div className={popup}>
                    <div className={popup_header}>
                        <div className={popup_title}>Welcome to Difftone!</div>
                    </div>

                    <div className={body_popup}>
                        <div className={popup_sub_title}>
                            Hi there! Your organisation is not registered to
                            Difftone.
                        </div>
                        <div className={popup_sub_title}>
                            <div>
                                To start using Difftone be sure to contact us
                                at:
                            </div>
                            <div>Email : support@difftone.com</div>
                            <div>Phone : +972 54 4228432</div>
                        </div>
                    </div>
                </div>
            </AddBoxShadow>
        </div>
    );
};
