import React from "react";
import { observer } from "mobx-react";
import { accordionSectionsController } from "@difftone/actions";
import { wizardDisplayStore } from "@difftone/stores";

import { GreyedOutQuestions, WizardQuestions } from "./components";
import { questions_container } from "./questions-accordion.module.css";

export const QuestionsAccordion = observer(() => {
    const { isEditableSurvey } = wizardDisplayStore;
    return (
        <div
            className={questions_container}
            onClick={() => accordionSectionsController("QUESTIONS")}
        >
            {isEditableSurvey ? <WizardQuestions /> : <GreyedOutQuestions />}
        </div>
    );
});
