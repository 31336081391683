import { EmailAddress, UUID, AuthData } from "@difftone/types";
import { getApiBaseUrl } from "@difftone/constants";

import axios from "axios";
const API_URL = `${getApiBaseUrl()}/internal-features`;

export const deleteAllMocksApi = async (): Promise<any> => {
    const authDataAsString = localStorage.getItem("authData");
    if (!authDataAsString) {
        throw new Error("Cannot read authData from local storage!");
    }
    const authData: AuthData = JSON.parse(authDataAsString);
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common[
        "Authorization"
    ] = `Bearer ${authData.user_token}`;

    const response = await axios.delete(`${API_URL}/all`, {
        params: {
            issuer: authData.issuer,
        },
    });
    return response;
};

export const deleteMocksApiByNamespace = async (
    namespace: string
): Promise<any> => {
    const authDataAsString = localStorage.getItem("authData");
    if (!authDataAsString) {
        throw new Error("Cannot read authData from local storage!");
    }
    const authData: AuthData = JSON.parse(authDataAsString);
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common[
        "Authorization"
    ] = `Bearer ${authData.user_token}`;

    const response = await axios.delete(`${API_URL}/survey-namespce`, {
        params: {
            name_space: namespace,
            issuer: authData.issuer,
        },
    });
    return response;
};

export const autoFillSurveyApi = (
    numOfUsers: number,
    baseEmail: EmailAddress,
    surveyUuid: UUID
): Promise<any> => {
    const authDataAsString = localStorage.getItem("authData");
    if (!authDataAsString) {
        throw new Error("Cannot read authData from local storage!");
    }
    const authData: AuthData = JSON.parse(authDataAsString);
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common[
        "Authorization"
    ] = `Bearer ${authData.user_token}`;

    const response = axios.post(
        `${API_URL}/survey-autofill`,
        {},
        {
            params: {
                issuer: authData.issuer,
                survey_uuid: surveyUuid,
                base_email: baseEmail,
                num_of_users: numOfUsers,
            },
        }
    );

    return response;
};
