import { action } from "mobx";
import { getTimeRanges } from "@difftone/services";
import { TimeRangesContainer, TimeCompareUnits, UUID } from "@difftone/types";
import { timeRangesStore } from "./time-ranges-store";

export const timeRangesBySurveyUuidAndUnits = action(
    async (surveyUuid: UUID, timeUnit: TimeCompareUnits) => {
        if (timeUnit === "NONE") {
            const noneTimeRangeContainer: TimeRangesContainer = {
                timeRanges: [],
                surveyUuid: surveyUuid,
                timeUnit: timeUnit,
            };
            timeRangesStore.addTimeRangesContainer(noneTimeRangeContainer);
            return;
        }

        const timeRangesContainer: { [key: UUID]: TimeRangesContainer } =
            await getTimeRanges(surveyUuid, timeUnit);

        timeRangesStore.addTimeRangesContainer(timeRangesContainer[surveyUuid]);
    }
);
