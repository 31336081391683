import React from "react";

import {
    home_page_footer,
    all_rights_reserved,
} from "./home-page-footer.module.css";

export type HomePageFooterProps = {};

export const HomePageFooter = () => {
    return (
        <div className={home_page_footer}>
            <div className={all_rights_reserved}>
                © 2021 difftone | All Rights Reserved
            </div>
        </div>
    );
};
