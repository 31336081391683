import React, { SyntheticEvent, useState } from "react";
import clsx from "clsx";

import { Question, QuestionType, QuestionTypeName } from "@difftone/types";
import { AddBoxShadow } from "@difftone/shared-components";

import ratingIcon from "@difftone/assets/rating.svg";
import singleChoiceIcon from "@difftone/assets/single-choice.svg";
import multipleChoiceIcon from "@difftone/assets/multiple-choice.svg";
import dateIcon from "@difftone/assets/date-icon.svg";
import timeIcon from "@difftone/assets/clock-icon.svg";
import shortTextIcon from "@difftone/assets/short-text.svg";
import longTextIcon from "@difftone/assets/long-text.svg";

import { IconSelectRow } from "./components";

import {
    expanded_container,
    drop_down_div,
    select_div,
    select_div_no_arrow,
    expanded_container_disabled,
} from "./expanded-style.module.css";

export type ExpandedStyleProps = {
    onSelectedItemChange: (value: QuestionTypeName) => void;
    question: Question<QuestionType>;
    disabled?: boolean;
};

export const ExpandedStyle = (props: ExpandedStyleProps) => {
    const { question, onSelectedItemChange, disabled } = props;

    const [showMenu, setShowMenu] = useState(false);
    const toggleDropDownMenu = () => {
        setShowMenu(!showMenu);
    };

    const onListItemClicked = (event: SyntheticEvent) => {
        // @ts-ignore
        onSelectedItemChange(event.currentTarget.dataset.id);
        toggleDropDownMenu();
    };

    const onBlurHandler = () => {
        if (showMenu) {
            setShowMenu(false);
        }
    };

    const selectDivIconRender = () => {
        switch (question.question_type_name) {
            case "RATING":
                return ratingIcon;
            case "SINGLE_CHOICE":
                return singleChoiceIcon;
            case "MULTIPLE_CHOICE":
                return multipleChoiceIcon;
            case "DATE":
                return dateIcon;
            case "TIME":
                return timeIcon;
            case "SHORT_TEXT":
                return shortTextIcon;
            case "LONG_TEXT":
                return longTextIcon;
            default:
                return null;
        }
    };

    return (
        <div
            tabIndex={0}
            onBlur={onBlurHandler}
            className={clsx(
                expanded_container,
                disabled && expanded_container_disabled
            )}
        >
            <div
                onClick={toggleDropDownMenu}
                className={clsx(select_div, disabled && select_div_no_arrow)}
            >
                <IconSelectRow
                    iconUrl={selectDivIconRender()}
                    questionTypeName={question.question_type_name}
                    disabled={disabled}
                />
            </div>
            {disabled ? null : (
                <AddBoxShadow>
                    <div
                        style={{ display: showMenu ? "block" : "none" }}
                        className={drop_down_div}
                    >
                        <ul>
                            <li onClick={onListItemClicked} data-id="RATING">
                                <IconSelectRow
                                    iconUrl={ratingIcon}
                                    questionTypeName={"RATING"}
                                />
                            </li>
                            <li
                                onClick={onListItemClicked}
                                data-id="SINGLE_CHOICE"
                            >
                                <IconSelectRow
                                    iconUrl={singleChoiceIcon}
                                    questionTypeName={"SINGLE_CHOICE"}
                                />
                            </li>
                            <li
                                onClick={onListItemClicked}
                                data-id="MULTIPLE_CHOICE"
                            >
                                <IconSelectRow
                                    iconUrl={multipleChoiceIcon}
                                    questionTypeName={"MULTIPLE_CHOICE"}
                                />
                            </li>
                            <li onClick={onListItemClicked} data-id="DATE">
                                <IconSelectRow
                                    iconUrl={dateIcon}
                                    questionTypeName={"DATE"}
                                />
                            </li>
                            <li onClick={onListItemClicked} data-id="TIME">
                                <IconSelectRow
                                    iconUrl={timeIcon}
                                    questionTypeName={"TIME"}
                                />
                            </li>
                            <li
                                onClick={onListItemClicked}
                                data-id="SHORT_TEXT"
                            >
                                <IconSelectRow
                                    iconUrl={shortTextIcon}
                                    questionTypeName={"SHORT_TEXT"}
                                />
                            </li>
                            <li onClick={onListItemClicked} data-id="LONG_TEXT">
                                <IconSelectRow
                                    iconUrl={longTextIcon}
                                    questionTypeName={"LONG_TEXT"}
                                />
                            </li>
                        </ul>
                    </div>
                </AddBoxShadow>
            )}
        </div>
    );
};
