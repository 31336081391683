import React, { useState, ReactNode } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useStyles } from "./difftone-tooltip-style";
export type TooltipPosition =
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"
    | undefined;
export type ShowDifftoneTooltipProps = {
    children: ReactNode;
    tip: string;
    tooltipPosition: TooltipPosition;
    disableHover?: boolean;
    extendTooltipStyle?: string;
    clickableTooltip?: boolean;
    alwaysVisible?: boolean;
    hideTooltip?: boolean;
};
export const ShowDifftoneTooltip = (props: ShowDifftoneTooltipProps) => {
    const {
        children,
        tip,
        tooltipPosition,
        disableHover,
        extendTooltipStyle,
        clickableTooltip,
        alwaysVisible,
        hideTooltip,
    } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [closed, setClosed] = useState(false);
    const handleMouseLeave = () => {
        setClosed(false);
        setOpen(false);
    };
    const handleInnerContentClick = () => {
        setClosed(true);
    };
    let tooltipStyleDirection = null;
    let tooltipArrowStyleDirection = null;
    switch (tooltipPosition) {
        case "top":
            tooltipArrowStyleDirection = classes.topTooltipArrow;
            if (extendTooltipStyle) {
                tooltipStyleDirection = extendTooltipStyle;
            }
            break;
        case "right":
            tooltipArrowStyleDirection = classes.rightTooltipArrow;
            if (extendTooltipStyle) {
                tooltipStyleDirection = extendTooltipStyle;
            }
            break;
        case "left":
            tooltipArrowStyleDirection = classes.leftTooltipArrow;
            if (extendTooltipStyle) {
                tooltipStyleDirection = extendTooltipStyle;
            }
            break;
        case "bottom":
            tooltipArrowStyleDirection = classes.bottomTooltipArrow;
            if (extendTooltipStyle) {
                tooltipStyleDirection = extendTooltipStyle;
            }
            break;
        default:
            return null;
    }
    return !hideTooltip ? (
        <Tooltip
            title={tip}
            placement={tooltipPosition}
            arrow
            classes={{
                tooltip: [classes.topTooltip, tooltipStyleDirection].join(" "),
                arrow: tooltipArrowStyleDirection,
            }}
            open={alwaysVisible || (!closed && open)}
            disableHoverListener={disableHover}
        >
            <div
                onClick={clickableTooltip ? handleInnerContentClick : () => {}}
                onMouseLeave={handleMouseLeave}
                onMouseEnter={() => {
                    if (!disableHover) {
                        setOpen(true);
                    }
                }}
            >
                {children}
            </div>
        </Tooltip>
    ) : (
        <div>{children}</div>
    );
};
