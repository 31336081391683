import type {
    Question,
    QuestionType,
    QuestionCategoriesPropValidationType,
    ValidationResult,
} from "@difftone/types";

export const validateCategoryProp = (
    question: Question<QuestionType>
): ValidationResult<QuestionCategoriesPropValidationType>[] => {
    try {
        const check_valid = question.categories;
        return [
            {
                is_valid: !!check_valid,
                validation_error_code: "QUESTION_CATEGORY_PROP_VALIDATION",
                validation_error_type: { question_id: question.uuid },
            },
        ];
    } catch (error) {
        console.error(
            `validateCategoryProp error:: ${(error as Error).message}`
        );
        return [
            {
                is_valid: false,
                validation_error_code: "EXCEPTION_DURING_VALIDATIONS",
                //TODO: fix type definition
                //@ts-ignore
                validation_error_type: error as Error,
            },
        ];
    }
};
