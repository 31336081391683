import React from "react";
import { observer } from "mobx-react";

import {
    NoDataToShow,
    Spinner,
    TableDateBySurveyClass,
    TableRowCell,
} from "@difftone/shared-components";
import { surveyStore } from "@difftone/stores";
import { SurveyClasses } from "@difftone/types";
import { TableHeader, TableRow } from "./components";
import { getStatus } from "@difftone/reducers";
import {
    OverallResponsesBar,
    ResponseRate,
    StatusSurveyIsActive,
} from "./components/table-row/components";
import { UiStatus } from "@difftone/frontend-common";

import {
    table_content_wrapper,
    table_rows_content_wrapper,
    last_date_class,
    table_header_cell_overall_responses_bar,
    table_header_cell_response_rate_bar,
    table_header_cell_name,
} from "./dashboard-surveys-table.module.css";

const MIN_CHARS_IN_SURVEY_NAME_TOOLTIP = 25;

export type DashboardSurveysTableProps = {
    surveys: SurveyClasses[];
};

export const DashboardSurveysTable = observer(
    (props: DashboardSurveysTableProps) => {
        const { surveys } = props;

        if (!surveyStore.storeInitialize) {
            surveyStore.init();
            return <Spinner />;
        }

        const sortedAndFilteredSurveys = surveys;

        return (
            <div className={table_content_wrapper}>
                <TableHeader />
                <div className={table_rows_content_wrapper}>
                    {sortedAndFilteredSurveys.length > 0 ? (
                        sortedAndFilteredSurveys.map((survey) => {
                            const surveyStatus: UiStatus = getStatus(survey);

                            return (
                                <TableRow
                                    key={survey.uuid}
                                    body={
                                        <>
                                            <StatusSurveyIsActive
                                                surveyStatus={surveyStatus}
                                            />
                                            <TableRowCell
                                                className={
                                                    table_header_cell_name
                                                }
                                                maxTextLength={
                                                    MIN_CHARS_IN_SURVEY_NAME_TOOLTIP
                                                }
                                                tooltipText={survey.survey_name}
                                            >
                                                {survey.survey_name}
                                            </TableRowCell>
                                            <TableRowCell
                                                className={last_date_class}
                                            >
                                                <TableDateBySurveyClass
                                                    dateType="LAST_DATE"
                                                    survey={survey}
                                                />
                                            </TableRowCell>
                                            <TableRowCell
                                                className={
                                                    table_header_cell_overall_responses_bar
                                                }
                                            >
                                                <OverallResponsesBar
                                                    survey={survey}
                                                />
                                            </TableRowCell>
                                            <TableRowCell
                                                className={
                                                    table_header_cell_response_rate_bar
                                                }
                                            >
                                                <ResponseRate survey={survey} />
                                            </TableRowCell>
                                        </>
                                    }
                                />
                            );
                        })
                    ) : (
                        <NoDataToShow />
                    )}
                </div>
            </div>
        );
    }
);
