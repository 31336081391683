import { makeAutoObservable } from "mobx";
import { SurveyFilterProfile } from "@difftone/types";

export type SortingTypes =
    | "NAME"
    | "INITIATOR"
    | "ISSUED"
    | "LAST"
    | "CREATED"
    | "START"
    | "END"
    | "STATUS"
    | "TIME LEFT"
    | "PURPOSE"
    | "PROGRESS";

export type FilterTypes = "ALL" | "NAME";

export type SortingProfile = {
    sortBy: SortingTypes;
    ascending: boolean;
};

export type InboxFilters =
    | "ALL"
    | "RESPONDENT"
    | "INITIATOR"
    | "NEEDS_ATTENTION"
    | "DRAFT"
    | "NOT_COMPLETED"
    | "SHARED_WITH_ME"
    | "TEMPLATES"
    | "ARCHIVED"
    | "ONGOING"
    | "SHARED_VIEWS_WITH_ME";

class InboxStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _surveysTableSpinnerActive: boolean = true;
    get surveysTableSpinnerActive(): boolean {
        return this._surveysTableSpinnerActive;
    }
    set surveysTableSpinnerActive(isActive: boolean) {
        this._surveysTableSpinnerActive = isActive;
    }

    private _chunkSize = 10;
    get chunkSize(): number {
        return this._chunkSize;
    }

    private _inboxLastActiveFilter: InboxFilters = "ALL";
    get inboxLastActiveFilter() {
        return this._inboxLastActiveFilter;
    }
    set inboxLastActiveFilter(newActiveFilter: InboxFilters) {
        this._inboxLastActiveFilter = newActiveFilter;
    }

    private _currentChunkIndex = 0;
    get currentChunkIndex(): number {
        return this._currentChunkIndex;
    }

    set currentChunkIndex(newChunkIndex: number) {
        this._currentChunkIndex = newChunkIndex;
    }

    private _filterProfile: SurveyFilterProfile = {};
    get filterProfile() {
        return this._filterProfile;
    }
    set filterProfile(newFilterProfile: SurveyFilterProfile) {
        this._filterProfile = newFilterProfile;
    }

    private _sortingProfile: SortingProfile = {
        sortBy: "END",
        ascending: true,
    };
    get sortingProfile() {
        return this._sortingProfile;
    }
    set sortingProfile(newSortingProfile: SortingProfile) {
        this._sortingProfile = newSortingProfile;
    }

    public clearStore = () => {
        this._surveysTableSpinnerActive = true;
        this._filterProfile = {};
        this._sortingProfile = {
            sortBy: "END",
            ascending: false,
        };
    };
}

export const inboxStore = new InboxStore();
