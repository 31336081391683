import React, {
    useState,
    ChangeEvent,
    useRef,
    Ref,
    useEffect,
    KeyboardEventHandler,
    MouseEvent,
} from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import {
    nullifyWizardValidationError,
    updateQuestionInWizard,
    closeAutocompleteDropdown,
    openAutocompleteDropdown,
} from "@difftone/actions";
import { Question, QuestionType } from "@difftone/types";
import { questionTitleValidation } from "@difftone/frontend-common/frontend-validations";
import { MOBILE_WIDTH_BREAKPOINT, warningMessages } from "@difftone/constants";
import {
    ValidationWrapper,
    AutoCompleteTitle,
} from "@difftone/shared-components";
import { autoCompleteStore, wizardStore } from "@difftone/stores";
import { getSurveyFromStoreByURL } from "@difftone/reducers";

import {
    question_title,
    required_question_title,
    invalid_title_input,
} from "./question-title.module.css";

const NUMBER_OF_CHARS_ALLOWED_FOR_AUTOCOMPLETE = 1;

const OVERRIDE_VALIDATION_TOOLTIP_POSITION = {
    left: "calc(100% + 20px)",
    top: "calc(50% + -2px)",
};

export type QuestionTitleProps = {
    question: Question<QuestionType>;
    collapsed: boolean;
};

export const QuestionTitle = observer((props: QuestionTitleProps) => {
    const { question } = props;
    const { autoCompleteOptions, isAutocompleteOpen } = autoCompleteStore;
    const { focusedWizardInputCard } = wizardStore;
    //useState: state for controlling the active index inside the autocomplete dropdown list
    // controlling up/down keystrokes and mouse hover events
    const [autoCompleteListIndex, setAutoCompleteListIndex] = useState(0);

    const survey = getSurveyFromStoreByURL();

    const componentRef: Ref<HTMLTextAreaElement> = useRef(null);

    const isPartOfFocusedCard =
        focusedWizardInputCard?.getAttribute("uuid") === question.uuid;

    const { isValid } = questionTitleValidation(question.uuid, survey);

    const titleClass =
        isValid || !isPartOfFocusedCard ? "" : invalid_title_input;

    const onKeyDownHandler: KeyboardEventHandler<HTMLTextAreaElement> = (
        event
    ) => {
        const focusedTitleInAutoComplete = autoCompleteOptions.filter(
            (autoCompleteTitle) =>
                autoCompleteTitle
                    .toLowerCase()
                    .includes(question.title.toLowerCase())
        )[autoCompleteListIndex];

        event.stopPropagation();

        if (question.title) {
            if (event.key === "Enter") {
                if (isAutocompleteOpen) {
                    event.preventDefault();
                }
                updateQuestionInWizard(
                    question,
                    "title",
                    focusedTitleInAutoComplete
                        ? focusedTitleInAutoComplete
                        : question.title
                );

                closeAutocompleteDropdown();
            }
        }

        if (question.title) {
            if (event.key === "ArrowDown") {
                setAutoCompleteListIndex(
                    (autoCompleteListIndex + 1) %
                        autoCompleteOptions.filter((autoCompleteTitle) =>
                            autoCompleteTitle
                                .toLowerCase()
                                .includes(question.title.toLowerCase())
                        ).length
                );
            }
            if (event.key === "ArrowUp") {
                if (autoCompleteListIndex - 1 < 0) {
                    setAutoCompleteListIndex(
                        autoCompleteOptions.filter((autoCompleteTitle) =>
                            autoCompleteTitle
                                .toLowerCase()
                                .includes(question.title.toLowerCase())
                        ).length - 1
                    );
                } else {
                    setAutoCompleteListIndex(
                        (autoCompleteListIndex - 1) %
                            autoCompleteOptions.filter((autoCompleteTitle) =>
                                autoCompleteTitle
                                    .toLowerCase()
                                    .includes(question.title.toLowerCase())
                            ).length
                    );
                }
            }
            return;
        }
    };

    const onAutocompleteTitleClicked = (event: MouseEvent<HTMLDivElement>) => {
        const clickedTitle = event.currentTarget.getAttribute(
            "data-autocomplete-title"
        );
        if (!clickedTitle) {
            return;
        }

        updateQuestionInWizard(question, "title", clickedTitle);

        closeAutocompleteDropdown();
    };

    const onAutocompleteTitleHovered = (event: MouseEvent<HTMLDivElement>) => {
        const hoveredTitleIndex = event.currentTarget.getAttribute(
            "data-autocomplete-index"
        );

        if (!hoveredTitleIndex) {
            return;
        }
        setAutoCompleteListIndex(parseInt(hoveredTitleIndex));
    };

    const onFocusTitleHandler = () => {
        openAutocompleteDropdown();
    };

    const onBlurTitleHandler = () => {
        closeAutocompleteDropdown();
    };

    const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        nullifyWizardValidationError();
        const value = event.target.value;

        if (value.length >= NUMBER_OF_CHARS_ALLOWED_FOR_AUTOCOMPLETE) {
            openAutocompleteDropdown();
        }

        updateQuestionInWizard(
            question,
            "title",
            value.length > 1 ? value : value.trim()
        );
    };

    // Used for input height change on every new / removed line inside question title
    useEffect(() => {
        if (componentRef && componentRef.current) {
            componentRef.current.style.height = "0px";
            const scrollHeight = componentRef.current.scrollHeight;
            componentRef.current.style.height = `${scrollHeight}px`;
        }
    }, [question.title]);

    useEffect(() => {
        if (question.title.length >= NUMBER_OF_CHARS_ALLOWED_FOR_AUTOCOMPLETE) {
            autoCompleteStore.getTitleAutoCompleteByRepositoryType(
                question.title,
                question.is_filter ? "attributes" : "questions"
            );
        }
    }, [question.title, question.is_filter]);

    const errorMessage = warningMessages.questionTitleError;

    const isMobile = window.innerWidth <= MOBILE_WIDTH_BREAKPOINT;

    return (
        <>
            <ValidationWrapper
                isValid={isValid || !isPartOfFocusedCard}
                errorMessage={errorMessage}
                tooltipPositionOverride={OVERRIDE_VALIDATION_TOOLTIP_POSITION}
            >
                <textarea
                    autoFocus={isAutocompleteOpen}
                    ref={componentRef}
                    wrap="hard"
                    data-textarea-question-input-title={question.uuid}
                    onChange={onChange}
                    onKeyDown={onKeyDownHandler}
                    onFocus={onFocusTitleHandler}
                    onBlur={onBlurTitleHandler}
                    value={question.title}
                    placeholder="Please enter a question"
                    className={
                        question.required
                            ? clsx(
                                  isMobile
                                      ? question_title
                                      : required_question_title,
                                  titleClass
                              )
                            : clsx(question_title, titleClass)
                    }
                />
                <AutoCompleteTitle
                    isOpen={
                        isPartOfFocusedCard &&
                        question.title.length >=
                            NUMBER_OF_CHARS_ALLOWED_FOR_AUTOCOMPLETE &&
                        isAutocompleteOpen
                    }
                    activeIndex={autoCompleteListIndex}
                    autoCompleteList={autoCompleteOptions.filter((title) =>
                        title
                            .toLowerCase()
                            .includes(question.title.toLowerCase())
                    )}
                    onMouseClick={onAutocompleteTitleClicked}
                    onMouseOver={onAutocompleteTitleHovered}
                />
            </ValidationWrapper>
        </>
    );
});
