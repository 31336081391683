import { makeAutoObservable } from "mobx";

import { SharedSurvey, UUID } from "@difftone/types";

export type TemplateSortingTypes = "NAME" | "OWNER" | "PURPOSE";

export type SortingTemplateProfile = {
    sortBy: TemplateSortingTypes;
    ascending: boolean;
};

class TemplatesDisplayStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _templatesPopupIsOpenedMap: Record<UUID, boolean> = {};

    get templatesPopupIsOpenedMap() {
        return this._templatesPopupIsOpenedMap;
    }

    set templatesPopupIsOpenedMap(
        newTemplatesPopupIsOpenedMap: Record<UUID, boolean>
    ) {
        this._templatesPopupIsOpenedMap = newTemplatesPopupIsOpenedMap;
    }

    private _templatesIsIFrameLoadingMap: Record<UUID, boolean> = {};

    get templatesIsIFrameLoadingMap() {
        return this._templatesIsIFrameLoadingMap;
    }

    set templatesIsIFrameLoadingMap(
        newTemplatesIsIFrameLoadingMap: Record<UUID, boolean>
    ) {
        this._templatesIsIFrameLoadingMap = newTemplatesIsIFrameLoadingMap;
    }

    private _sortingTemplateProfile: SortingTemplateProfile = {
        sortBy: "NAME",
        ascending: true,
    };

    get sortingTemplateProfile() {
        return this._sortingTemplateProfile;
    }

    set sortingTemplateProfile(
        newSortingSharedSurveyProfile: SortingTemplateProfile
    ) {
        this._sortingTemplateProfile = newSortingSharedSurveyProfile;
    }

    public getTemplateIsPopupOpenedByUUID = (templateUUID: UUID) => {
        return this._templatesPopupIsOpenedMap[templateUUID];
    };

    public getTemplateIsIFrameLoadingByUUID = (templateUUID: UUID) => {
        return this._templatesIsIFrameLoadingMap[templateUUID];
    };

    private _templateUUIDToEdit: UUID | null = null;

    get templateUUIDToEdit() {
        return this._templateUUIDToEdit;
    }

    set templateUUIDToEdit(uuid) {
        this._templateUUIDToEdit = uuid;
    }

    private _templateToEditTags: UUID[] = [];

    get templateToEditTags() {
        return this._templateToEditTags;
    }

    set templateToEditTags(tags) {
        this._templateToEditTags = tags;
    }

    private _templateToEdit: SharedSurvey | null = null;

    get templateToEdit() {
        return this._templateToEdit;
    }

    set templateToEdit(template) {
        this._templateToEdit = template;
    }

    public clearStore = () => {
        this._sortingTemplateProfile = {
            sortBy: "NAME",
            ascending: false,
        };
    };
}

export const templatesDisplayStore = new TemplatesDisplayStore();
