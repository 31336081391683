import React from "react";
import { observer } from "mobx-react";
import { simpleSurveyResultsStore } from "@difftone/stores";
import {
    ResponderToAnswer,
    BaseSurvey,
    SimpleResultsMap,
} from "@difftone/types";
import { Spinner } from "@difftone/shared-components";

import { RespondersList } from "./components";

import { responders_and_answers } from "./responders-and-answers.module.css";

export type RespondersAndAnswersProps = {
    survey: BaseSurvey;
    responses: SimpleResultsMap | null;
};

export const RespondersAndAnswers = observer(
    (props: RespondersAndAnswersProps) => {
        const { selectedQuestion } = simpleSurveyResultsStore;
        const { survey, responses } = props;

        if (!responses) {
            return <Spinner size="MEDIUM" />;
        }

        const selectedQuestionAnswers: (ResponderToAnswer | null)[] =
            (selectedQuestion && responses[selectedQuestion]) || [];

        return (
            <div className={responders_and_answers}>
                <RespondersList
                    responses={selectedQuestionAnswers}
                    survey={survey}
                />
            </div>
        );
    }
);
