import React, { ReactElement, useCallback } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import dayjs from "dayjs";

import {
    setLocation,
    setTargetSharedView,
    removeMeFromShare,
    showDifftoneAlert,
} from "@difftone/actions";
import { sharedResultStore } from "@difftone/stores";
import { SharedResult } from "@difftone/types";
import {
    getFilteredSharedViews,
    getSortedSharedViews,
} from "@difftone/reducers";
import { getUserEmail } from "@difftone/procedures";
import { constantAlertVariants } from "@difftone/constants";
import {
    HumanizeEmail,
    SpinnerOrTimeoutMessage,
    TableHeader,
    TableRow,
    TableRowCell,
    TableDate,
    Spinner,
    NameWithTooltip,
} from "@difftone/shared-components";

import resultsIcon from "@difftone/assets/survey-results.svg";
import deleteIcon from "@difftone/assets/delete-survey.svg";

import {
    inbox_surveys_table,
    table_content_wrapper,
    overflow_padding_div,
    created_class,
    share_name_class,
    shared_by_class,
    survey_name_class,
    initiator_class,
} from "./views-shared-with-me-section.module.css";

const DEFAULT_NAME = "Share_survey_name";

export type ViewsSharedWithMeSectionProps = {};

export const ViewsSharedWithMeSection = observer((): ReactElement => {
    const sortedSharedViews = getSortedSharedViews();
    const filteredSharedViews = getFilteredSharedViews(sortedSharedViews);
    const userEmail = getUserEmail();

    if (!sharedResultStore.isInitializingStarted) {
        sharedResultStore.init();
        return <Spinner size="LARGE" />;
    }

    if (sharedResultStore.isStoreInitializing) {
        return <Spinner size="LARGE" />;
    }

    const goToShareView = (sharedResults: SharedResult) => {
        if (userEmail !== sharedResults.survey_initiator) {
            setTargetSharedView(sharedResults);
        }

        setLocation(`/shared-results/${sharedResults.uuid}/grid`);
    };

    const handleDelete = (share: SharedResult) => {
        showDifftoneAlert(
            constantAlertVariants.delete_shared_view.text,
            constantAlertVariants.delete_shared_view.variant,
            async () => {
                await removeMeFromShare(share);
                showDifftoneAlert(
                    `${share.survey_name} has been deleted successfully!`,
                    "SUCCESS"
                );
            },
            () => {},
            `${share.survey_name}`
        );
    };

    const getShareViewName = (share: SharedResult) => {
        if (share.share_name.trim()) {
            return share.share_name;
        }

        return `${DEFAULT_NAME}_${dayjs(share.created_at).format(
            "MMM_DD_YYYY_HH:mm:ss"
        )}`;
    };

    return (
        <div className={inbox_surveys_table}>
            <TableHeader />
            <div className={table_content_wrapper}>
                {filteredSharedViews.length > 0 ? (
                    filteredSharedViews.map((share) => {
                        const sharedViewName = getShareViewName(share);

                        return (
                            <TableRow
                                key={share.uuid}
                                actions={
                                    <>
                                        <button
                                            onClick={() => goToShareView(share)}
                                        >
                                            <img alt="" src={resultsIcon} />
                                        </button>
                                        <button
                                            onClick={() => handleDelete(share)}
                                        >
                                            <img alt="" src={deleteIcon} />
                                        </button>
                                    </>
                                }
                                body={
                                    <>
                                        <TableRowCell
                                            className={clsx(
                                                share_name_class,
                                                "user_select"
                                            )}
                                        >
                                            <NameWithTooltip
                                                text={sharedViewName}
                                            />
                                        </TableRowCell>

                                        <TableRowCell
                                            className={shared_by_class}
                                        >
                                            <HumanizeEmail
                                                humanizeEmail={
                                                    share.sharer_email
                                                }
                                            />
                                        </TableRowCell>

                                        <TableRowCell
                                            className={clsx(
                                                survey_name_class,
                                                "user_select"
                                            )}
                                        >
                                            <NameWithTooltip
                                                text={share.survey_name}
                                            />
                                        </TableRowCell>

                                        <TableRowCell
                                            className={initiator_class}
                                        >
                                            <HumanizeEmail
                                                humanizeEmail={
                                                    share.survey_initiator
                                                }
                                            />
                                        </TableRowCell>

                                        <TableRowCell className={created_class}>
                                            <TableDate
                                                miliseconds={share.created_at}
                                            />
                                        </TableRowCell>
                                    </>
                                }
                            />
                        );
                    })
                ) : (
                    <SpinnerOrTimeoutMessage
                        spinnerSize="LARGE"
                        spinnerFlag={sharedResultStore.isStoreInitializing}
                        message="No Shared Views To Show"
                    />
                )}
                <div className={overflow_padding_div} />
            </div>
        </div>
    );
});
