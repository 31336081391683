import React from "react";
import { observer } from "mobx-react";

import { SharedSurvey } from "@difftone/types";
import { templatesStore, templatesDisplayStore } from "@difftone/stores";
import {
    setTemplateIsIFrameLoadingByUUID,
    toggleTemplateIsPopupOpenedByUUID,
} from "@difftone/actions";
import {
    DialogBackground,
    AddBoxShadow,
    PreviewSurvey,
    DifftoneButton,
    Spinner,
} from "@difftone/shared-components";

import durationIcon from "@difftone/assets/duration-icon.svg";
import plusIcon from "@difftone/assets/new-survey-button-plus.svg";
import previewIcon from "@difftone/assets/preview-active-icon.svg";

import {
    template_row,
    template_button_eye_icon,
    template_popup_background,
    template_popup_root,
    template_popup_title,
    template_popup,
    template_row_info,
    template_row_info_title,
    template_row_info_text,
    template_row_info_button,
    template_row_time,
    template_row_time_title,
    template_row_time_wrap,
    template_tags,
    template_tags_title,
    template_tags_wrap,
    template_tags_wrap_item,
    template_actions,
    template_button,
    template_button_plus_icon,
    template_button_outlined,
} from "./template-row.module.css";
import clsx from "clsx";

const MAX_MINUTES_TIME = 100;
const MINUTES_IN_HOUR = 60;

const convertMinutesToCorrectTime = (minutes: number): string => {
    let time: number | string = minutes;
    let label = "minutes";

    if (minutes >= MAX_MINUTES_TIME) {
        time = (time / MINUTES_IN_HOUR).toFixed(1);
        label = "hours";
    }

    return `${time} ${label}`;
};

export type TemplateRowProps = {
    template: SharedSurvey;
    onPreview: (template: SharedSurvey) => void;
    onConfirmAccept: (template: SharedSurvey) => void;
};

export const TemplateRow = observer((props: TemplateRowProps) => {
    const { template, onPreview, onConfirmAccept } = props;
    const { short_description, long_description, survey, uuid } = template;
    const { survey_name, response_duration } = survey;
    const tags = templatesStore.getTagsByTagsUUIDs(survey.tags || []);

    const isPopupOpened =
        templatesDisplayStore.getTemplateIsPopupOpenedByUUID(uuid);
    const isIFrameLoading =
        templatesDisplayStore.getTemplateIsIFrameLoadingByUUID(uuid);

    const toggleLearnMorePopup = () => {
        if (!isPopupOpened) {
            setTemplateIsIFrameLoadingByUUID(uuid, true);
        }

        toggleTemplateIsPopupOpenedByUUID(uuid);
    };

    return (
        <div className={template_row}>
            {isPopupOpened && (
                <DialogBackground className={template_popup_background}>
                    <AddBoxShadow>
                        <PreviewSurvey
                            className={template_popup_root}
                            onClose={toggleLearnMorePopup}
                            title={
                                <span className={template_popup_title}>
                                    {survey_name}
                                </span>
                            }
                        >
                            {isIFrameLoading && <Spinner size="MEDIUM" />}
                            <iframe
                                title={survey_name}
                                className={template_popup}
                                src={long_description}
                                onLoad={() =>
                                    setTemplateIsIFrameLoadingByUUID(
                                        uuid,
                                        false
                                    )
                                }
                                // eslint-disable-next-line react/no-unknown-property
                                allowTransparency
                            />
                        </PreviewSurvey>
                    </AddBoxShadow>
                </DialogBackground>
            )}

            <div className={template_row_info}>
                <h4 className={template_row_info_title}>{survey_name}</h4>
                <span className={template_row_info_text}>
                    {short_description}
                </span>
                <button
                    onClick={toggleLearnMorePopup}
                    className={template_row_info_button}
                    disabled={!long_description || !long_description.length}
                >
                    Learn more
                </button>
            </div>

            <div className={template_row_time}>
                <span className={template_row_time_title}>Time to Fill</span>

                <div className={template_row_time_wrap}>
                    <img src={durationIcon} alt="duration" />
                    <span>
                        {response_duration
                            ? convertMinutesToCorrectTime(response_duration)
                            : "None"}
                    </span>
                </div>
            </div>

            <div className={template_tags}>
                <span className={template_tags_title}>Tags</span>

                <div className={template_tags_wrap}>
                    {tags.map((tag) => (
                        <span
                            key={tag.uuid}
                            className={template_tags_wrap_item}
                        >
                            {tag.name}
                        </span>
                    ))}
                </div>
            </div>

            <div className={template_actions}>
                <DifftoneButton
                    onClick={() => onConfirmAccept(template)}
                    buttonText="Create"
                    classNameOverride={template_button}
                    iconClassName={template_button_plus_icon}
                    icon={plusIcon}
                />
                <DifftoneButton
                    onClick={() => onPreview(template)}
                    buttonText="Preview"
                    classNameOverride={template_button_outlined}
                    iconClassName={template_button_eye_icon}
                    icon={previewIcon}
                />
            </div>
        </div>
    );
});
