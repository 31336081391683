import React from "react";

import { SyncingStatus, SavedStatus, OfflineStatus } from "./components";

import { status_container } from "./overall-status-bar.module.css";

type OverallStatusType = "saved" | "syncing" | "offline";

export type OverallStatusProps = {
    currentStatus: OverallStatusType;
};

export const OverallStatus = (props: OverallStatusProps) => {
    const { currentStatus } = props;
    const statusComponent = () => {
        switch (currentStatus) {
            case "offline":
                return <OfflineStatus />;
            case "saved":
                return <SavedStatus />;
            case "syncing":
                return <SyncingStatus />;
            default:
                return null;
        }
    };
    return <div className={status_container}>{statusComponent()}</div>;
};
