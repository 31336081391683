import React from "react";
import { observer } from "mobx-react";
import OutsideClickHandler from "react-outside-click-handler";
import {
    MultipleChoiceQuestion,
    Question,
    ResultsFilter,
    SurveyClasses,
    TimeCompareUnits,
    TimeRangesContainer,
} from "@difftone/types";
import { AddBoxShadow, WrapperXComponent } from "@difftone/shared-components";

import { CompareTimeItem, TimeCompareButton } from "./components";
import {
    compare_time_popup,
    compare_time_popup_container,
} from "./compare-time-popup.module.css";

const EXTRA_DISTANCE_FROM_OPTION_ITEM = 9;

export type CompareTimePopupProps = {
    timeCompareFilter: ResultsFilter;
    closePopupHandler: () => void;
    survey?: SurveyClasses;
    selectedPopupDisplayResultsTimeCompare: TimeCompareUnits;
    setPopupDisplayResultsTimeCompare: (
        newTimeCompare: TimeCompareUnits
    ) => void;
    setSelectedTimeCompareResultsFilterAndTimeUnits: (
        selectedFilter: TimeCompareUnits,
        timeCompareFilterUuid: string,
        timeRangesContainer: TimeRangesContainer | null
    ) => void;
    isWithoutNonePopupOption?: boolean;
};

export const CompareTimePopup = observer((props: CompareTimePopupProps) => {
    const {
        timeCompareFilter,
        closePopupHandler,
        survey,
        selectedPopupDisplayResultsTimeCompare,
        setPopupDisplayResultsTimeCompare,
        setSelectedTimeCompareResultsFilterAndTimeUnits,
        isWithoutNonePopupOption,
    } = props;

    const closeTimeCompareHandler = () => {
        closePopupHandler();
    };

    const setTimeComparePopupPosition = () => {
        const compareWrapper: HTMLDivElement | null = document.querySelector(
            "div[data-compare-container]"
        );

        const timeCompareItem: HTMLDivElement | null = document.querySelector(
            `div[data-time-compare="${timeCompareFilter.filterKey.uuid}"]`
        );

        const parentTopPosition =
            compareWrapper?.getBoundingClientRect().top || 0;
        const timeCompareItemBottomPosition =
            timeCompareItem?.getBoundingClientRect().bottom || 0;

        return (
            timeCompareItemBottomPosition -
            parentTopPosition +
            EXTRA_DISTANCE_FROM_OPTION_ITEM
        );
    };

    const timeCompareOptions = (
        timeCompareFilter.filterKey as Question<MultipleChoiceQuestion>
    ).content.choice_options;

    const displayedTimeCompareOptions = isWithoutNonePopupOption
        ? timeCompareOptions.filter((timeOption) => timeOption !== "NONE")
        : timeCompareOptions;

    return (
        <OutsideClickHandler onOutsideClick={closeTimeCompareHandler}>
            <AddBoxShadow>
                <div
                    style={{
                        top: `${setTimeComparePopupPosition()}px`,
                    }}
                    className={compare_time_popup}
                >
                    <WrapperXComponent
                        title="Compare by time"
                        onclick={closeTimeCompareHandler}
                    >
                        <div className={compare_time_popup_container}>
                            {displayedTimeCompareOptions.map((filterOption) => (
                                <CompareTimeItem
                                    key={filterOption}
                                    filterValue={
                                        filterOption as TimeCompareUnits
                                    }
                                    selectedPopupDisplayResultsTimeCompare={
                                        selectedPopupDisplayResultsTimeCompare
                                    }
                                    setPopupDisplayResultsTimeCompare={
                                        setPopupDisplayResultsTimeCompare
                                    }
                                />
                            ))}
                        </div>
                        <TimeCompareButton
                            survey={survey}
                            closePopupHandler={closePopupHandler}
                            timeCompareFilter={timeCompareFilter}
                            selectedPopupDisplayResultsTimeCompare={
                                selectedPopupDisplayResultsTimeCompare
                            }
                            setSelectedTimeCompareResultsFilterAndTimeUnits={
                                setSelectedTimeCompareResultsFilterAndTimeUnits
                            }
                        />
                    </WrapperXComponent>
                </div>
            </AddBoxShadow>
        </OutsideClickHandler>
    );
});
