import { action } from "mobx";

import {
    getSurveyAverageFillTimeByUUID,
    getSurveyMedianFillTimeByUUID,
    getSurveyAverageFillTimeSansPByUUID,
} from "@difftone/services";
import { UUID } from "@difftone/types";

export const getAverageForSurvey = action(
    async (surveyUUID: UUID): Promise<number | null> => {
        const surveyAverage = await getSurveyAverageFillTimeByUUID(surveyUUID);
        return surveyAverage || null;
    }
);

export const getMedianForSurvey = action(
    async (surveyUUID: UUID): Promise<number | null> => {
        const surveyAverage = await getSurveyMedianFillTimeByUUID(surveyUUID);
        return surveyAverage || null;
    }
);

export const getAverageSansPForSurvey = action(
    async (surveyUUID: UUID): Promise<number | null> => {
        const surveyAverage = await getSurveyAverageFillTimeSansPByUUID(
            surveyUUID
        );
        return surveyAverage || null;
    }
);
