import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import { EmailAddress } from "@difftone/types";
import { alertsMailingListGroupTitles } from "@difftone/constants";
import { groupEmailsStore } from "@difftone/stores";
import { showDifftoneAlert } from "@difftone/actions";

import { DeletebleEmailWrapper, NonDeletebleEmailWrapper } from "./components";

import {
    mailing_list_wrapper,
    mailing_list,
    complicated_state_length,
    long_mailing_list,
    mailing_list_disabled,
} from "./mailing-list-wrapper.module.css";
import { getSelectedOrganizationOrThrow } from "@difftone/reducers";

export type MailingListWrapperProps = {
    listBoxHeight: number | null;
    mailingList: EmailAddress[];
    removeListItem: (email: EmailAddress) => void;
    nonDeletebleMailingList: EmailAddress[];
    disabled: boolean;
    mailingWrapperClassName?: string;
    emailsBeingResolved?: EmailAddress[];
};

export const MailingListWrapper = observer((props: MailingListWrapperProps) => {
    const {
        mailingList,
        removeListItem,
        listBoxHeight,
        nonDeletebleMailingList,
        disabled,
        mailingWrapperClassName,
        emailsBeingResolved,
    } = props;

    const [scrollMailingList, setScrollMailingList] = useState(false);

    const mailingListElement: HTMLElement =
        document.getElementById("mailing_list")!;

    const mailingItems: NodeListOf<HTMLDivElement> =
        document.querySelectorAll("#list_items");

    const getEmailsBeingValidated = () => {
        if (emailsBeingResolved) {
            return emailsBeingResolved.map((email, index) => (
                <DeletebleEmailWrapper
                    isGroupEmail={!!alertsMailingListGroupTitles[email]}
                    key={email}
                    disabled={disabled}
                    email={email}
                    removeListItem={removeListItem}
                    autofocus={index === mailingList.length - 1}
                    emailBeingValidated={true}
                />
            ));
        } else {
            return null;
        }
    };

    useEffect(() => {
        if (
            mailingListElement?.scrollHeight! >
            mailingListElement?.clientHeight!
        ) {
            setScrollMailingList(true);
        } else {
            setScrollMailingList(false);
        }
    }, [
        mailingItems,
        mailingListElement?.clientHeight,
        mailingListElement?.scrollHeight,
    ]);

    return (
        <div
            style={listBoxHeight ? { height: `${listBoxHeight}px` } : {}}
            className={clsx(
                mailing_list_wrapper,
                complicated_state_length,
                disabled && mailing_list_disabled,
                mailingWrapperClassName
            )}
        >
            {!disabled && (
                <div
                    id="mailing_list"
                    className={clsx(
                        mailing_list,
                        scrollMailingList && long_mailing_list
                    )}
                >
                    {/*TODO: resolve this email list in the parent context*/}
                    {mailingList.map((email, index) => {
                        const resolvedEmails =
                            groupEmailsStore.getResolvedEmails(
                                email,
                                getSelectedOrganizationOrThrow()!
                            );
                        //TODO: resolved email here is read from data object so can only have one email
                        //TODO: if there are multiple emails this is a problem with the data, add handling
                        switch (resolvedEmails) {
                            case undefined:
                                return (
                                    <DeletebleEmailWrapper
                                        isGroupEmail={
                                            !!alertsMailingListGroupTitles[
                                                email
                                            ]
                                        }
                                        key={email}
                                        disabled={disabled}
                                        email={email}
                                        removeListItem={removeListItem}
                                        autofocus={
                                            index === mailingList.length - 1
                                        }
                                        emailBeingValidated={true}
                                    />
                                );
                            case null:
                                //TODO: refine and make const
                                showDifftoneAlert(
                                    "Could not resolve email",
                                    "FAILURE"
                                );
                                break;
                            default:
                                if (resolvedEmails.length > 1) return null;
                                return nonDeletebleMailingList.includes(
                                    email
                                ) ? (
                                    <NonDeletebleEmailWrapper
                                        isGroupEmail={
                                            !!alertsMailingListGroupTitles[
                                                resolvedEmails[0]
                                            ]
                                        }
                                        key={resolvedEmails[0]}
                                        email={resolvedEmails[0]}
                                    />
                                ) : (
                                    <DeletebleEmailWrapper
                                        isGroupEmail={
                                            !!alertsMailingListGroupTitles[
                                                resolvedEmails[0]
                                            ]
                                        }
                                        key={resolvedEmails[0]}
                                        disabled={disabled}
                                        email={resolvedEmails[0]}
                                        removeListItem={removeListItem}
                                        autofocus={
                                            index === mailingList.length - 1
                                        }
                                    />
                                );
                        }
                    })}
                    {getEmailsBeingValidated()}
                </div>
            )}
        </div>
    );
});
