import React, { useCallback } from "react";
import { observer } from "mobx-react";
import {
    setInboxActiveFilterFlagAndRedirect,
    setLocation,
    showDifftoneAlert,
    toggleLeftMenu,
} from "@difftone/actions";
import {
    sharedSurveyStore,
    surveyStore,
    sharedResultStore,
} from "@difftone/stores";
import { LeftMenuButton } from "@difftone/shared-components";
import {
    constantAlertVariants,
    MOBILE_WIDTH_BREAKPOINT,
    PAGE_URLS,
} from "@difftone/constants";
import {
    getInboxActiveFilterFromUrl,
    allMySurveysCount,
    exampleSurveysCount,
    imTheInitiatorCount,
    imTheRespondentCount,
    needsMyAttentionCount,
    notCompletedCount,
    ongoingSurveysCount,
} from "@difftone/reducers";

import {
    inbox_left_menu_ul,
    inbox_left_menu_underline,
} from "./left-menu-children.module.css";

export const LeftMenuChildren = observer(() => {
    const { sharedViews } = sharedResultStore;
    const { surveysList } = surveyStore;
    const { sharedSurveysToDisplay } = sharedSurveyStore;

    const clientWidth = window.innerWidth;

    const handleNeedsMyAttention = () => {
        setInboxActiveFilterFlagAndRedirect("NEEDS_ATTENTION");

        if (clientWidth < MOBILE_WIDTH_BREAKPOINT) {
            toggleLeftMenu();
        }
    };
    const handleAllMySurveys = () => {
        setInboxActiveFilterFlagAndRedirect("ALL");

        if (clientWidth < MOBILE_WIDTH_BREAKPOINT) {
            toggleLeftMenu();
        }
    };
    const handleImTheInitiator = () => {
        setInboxActiveFilterFlagAndRedirect("INITIATOR");

        if (clientWidth < MOBILE_WIDTH_BREAKPOINT) {
            toggleLeftMenu();
        }
    };
    const handleImTheRespondent = () => {
        setInboxActiveFilterFlagAndRedirect("RESPONDENT");

        if (clientWidth < MOBILE_WIDTH_BREAKPOINT) {
            toggleLeftMenu();
        }
    };
    const handleNotCompleted = () => {
        setInboxActiveFilterFlagAndRedirect("NOT_COMPLETED");

        if (clientWidth < MOBILE_WIDTH_BREAKPOINT) {
            toggleLeftMenu();
        }
    };
    const handleSurveysSharedWithMe = () => {
        setInboxActiveFilterFlagAndRedirect("SHARED_WITH_ME");

        if (clientWidth < MOBILE_WIDTH_BREAKPOINT) {
            toggleLeftMenu();
        }
    };

    const handleTemplates = () => {
        if (clientWidth < MOBILE_WIDTH_BREAKPOINT) {
            toggleLeftMenu();
            showDifftoneAlert(
                constantAlertVariants.no_mobile_notification.text,
                constantAlertVariants.no_mobile_notification.variant
            );
            return;
        } else {
            setInboxActiveFilterFlagAndRedirect("TEMPLATES");
        }
    };

    const handleArchived = () => {
        if (clientWidth < MOBILE_WIDTH_BREAKPOINT) {
            toggleLeftMenu();
            showDifftoneAlert(
                constantAlertVariants.no_mobile_notification.text,
                constantAlertVariants.no_mobile_notification.variant
            );
            return;
        } else {
            setInboxActiveFilterFlagAndRedirect("ARCHIVED");
        }
    };

    const handleSharedViewsWithMeClick = useCallback(() => {
        setInboxActiveFilterFlagAndRedirect("SHARED_VIEWS_WITH_ME");

        if (clientWidth < MOBILE_WIDTH_BREAKPOINT) {
            toggleLeftMenu();
        }
    }, [clientWidth]);

    const handleDraft = () => {
        setInboxActiveFilterFlagAndRedirect("DRAFT");
        if (clientWidth < MOBILE_WIDTH_BREAKPOINT) {
            toggleLeftMenu();
        }
    };

    const handleOngoingSurveys = () => {
        setInboxActiveFilterFlagAndRedirect("ONGOING");

        if (clientWidth < MOBILE_WIDTH_BREAKPOINT) {
            toggleLeftMenu();
        }
    };

    const HandleDashboard = () => {
        if (clientWidth < MOBILE_WIDTH_BREAKPOINT) {
            toggleLeftMenu();
            showDifftoneAlert(
                constantAlertVariants.no_mobile_notification.text,
                constantAlertVariants.no_mobile_notification.variant
            );
            return;
        } else {
            setLocation(PAGE_URLS.DASHBOARD);
        }
    };

    const needsMyAttentionCountHandler = () => {
        return needsMyAttentionCount(surveysList);
    };

    const allMySurveysCountHandler = () => {
        return allMySurveysCount(surveysList);
    };

    const ongoingSurveysCountHandler = () => {
        return ongoingSurveysCount(surveysList);
    };

    const imTheInitiatorCountHandler = () => {
        return imTheInitiatorCount(surveysList);
    };
    const imTheRespondentCountHandler = () => {
        return imTheRespondentCount(surveysList);
    };

    const notCompletedCountHandler = () => {
        return notCompletedCount(surveysList);
    };

    const exampleSurveysCountHandler = () => {
        return exampleSurveysCount(surveysList);
    };

    const _inboxActiveFilter = getInboxActiveFilterFromUrl();

    return (
        <ul className={inbox_left_menu_ul}>
            <LeftMenuButton
                numberOfFilteredSurveys={needsMyAttentionCountHandler()}
                activeStyle={_inboxActiveFilter === "NEEDS_ATTENTION"}
                title="Needs my attention"
                onClick={handleNeedsMyAttention}
            />
            <LeftMenuButton
                numberOfFilteredSurveys={allMySurveysCountHandler()}
                activeStyle={_inboxActiveFilter === "ALL"}
                title="All my surveys"
                onClick={handleAllMySurveys}
            />
            <LeftMenuButton
                numberOfFilteredSurveys={imTheInitiatorCountHandler()}
                activeStyle={_inboxActiveFilter === "INITIATOR"}
                title="I'm the initiator"
                onClick={handleImTheInitiator}
            />
            <LeftMenuButton
                numberOfFilteredSurveys={ongoingSurveysCountHandler()}
                activeStyle={_inboxActiveFilter === "ONGOING"}
                title="Ongoing surveys"
                onClick={handleOngoingSurveys}
            />
            <LeftMenuButton
                numberOfFilteredSurveys={imTheRespondentCountHandler()}
                activeStyle={_inboxActiveFilter === "RESPONDENT"}
                title="I'm the respondent"
                onClick={handleImTheRespondent}
            />
            <LeftMenuButton
                numberOfFilteredSurveys={notCompletedCountHandler()}
                activeStyle={_inboxActiveFilter === "NOT_COMPLETED"}
                title="I've not completed"
                onClick={handleNotCompleted}
            />
            <LeftMenuButton
                numberOfFilteredSurveys={exampleSurveysCountHandler()}
                activeStyle={_inboxActiveFilter === "DRAFT"}
                title="Drafts"
                onClick={handleDraft}
            />
            <LeftMenuButton
                numberOfFilteredSurveys={sharedSurveysToDisplay().length}
                activeStyle={_inboxActiveFilter === "SHARED_WITH_ME"}
                title="Surveys shared with me"
                onClick={handleSurveysSharedWithMe}
            />
            <hr className={inbox_left_menu_underline} />
            <LeftMenuButton
                numberOfFilteredSurveys={sharedViews.length}
                activeStyle={_inboxActiveFilter === "SHARED_VIEWS_WITH_ME"}
                title="Views shared with me"
                onClick={handleSharedViewsWithMeClick}
            />
            <LeftMenuButton
                activeStyle={_inboxActiveFilter === "TEMPLATES"}
                title="Templates"
                onClick={handleTemplates}
            />
            <LeftMenuButton
                activeStyle={_inboxActiveFilter === "ARCHIVED"}
                title="Archived"
                onClick={handleArchived}
            />
            <hr className={inbox_left_menu_underline} />
            <LeftMenuButton title="Dashboard" onClick={HandleDashboard} />
        </ul>
    );
});
