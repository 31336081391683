import React, { ChangeEvent, useState } from "react";
import { v4 as uuid } from "uuid";

import { Organization, Domain } from "@difftone/types";
import { upsertOrganizationForDifftone } from "@difftone/features/organizations-domains/services";

import { ListOrganizations, ListDomains } from "./components";

import {
    organizations_card_scope,
    but,
    input_container,
} from "./organizations.module.css";

export type OrganizationsProps = {
    domains: Domain[];
    organizations: Organization[];
    setOrganizations: (organization: Organization[]) => void;
};

export const Organizations = (props: OrganizationsProps) => {
    const { domains, organizations, setOrganizations } = props;
    const [organization, setOrganization] = useState<Organization>({
        uuid: uuid(),
        name: "",
        domains: [],
        sys_admins: [],
        settings: { mode: "ALL", initiators: [] },
        created_at: Date.now(),
        is_deleted: false,
    });

    const [currentAdminInputValue, setCurrentAdminInputValue] =
        useState<string>("");

    const [isNewOrganization, setIsNewOrganization] = useState<boolean>(true);

    const [selectOrganization, setSelectOrganization] =
        useState<Organization | null>(null);

    const onChangeOrganizationName = (event: ChangeEvent<HTMLInputElement>) => {
        setOrganization({
            ...organization,
            name: event.target.value,
        });
    };

    const handleAdminInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentAdminInputValue(event.target.value);
    };

    const onAddSysAdmin = () => {
        if (currentAdminInputValue) {
            setOrganization({
                ...organization,
                sys_admins: [
                    ...organization.sys_admins,
                    currentAdminInputValue,
                ],
            });
            setCurrentAdminInputValue("");
        }
    };

    const createNewOrganization = async () => {
        const results = await upsertOrganizationForDifftone(organization);
        if (results === null) {
            window.alert(`Error! Something went wrong!`);
        } else {
            if (isNewOrganization) {
                setOrganizations([...organizations, organization]);
            }

            window.alert(
                `Successfully added ${organization.name} organization`
            );
        }
    };

    const onSelectDomains = (selectDomains: Domain[]) => {
        if (selectDomains.length > 0) {
            const uuids = selectDomains.map((domain) => domain.uuid);
            if (selectOrganization) {
                setOrganization({
                    ...organization,
                    domains: [...uuids, ...selectOrganization.domains],
                });
            } else {
                setOrganization({
                    ...organization,
                    domains: uuids,
                });
            }
        } else {
            if (selectOrganization) {
                setOrganization({
                    ...organization,
                    domains: selectOrganization.domains,
                });
            }
        }
    };

    const onSelectOrganization = (organization: Organization) => {
        setOrganization(organization);
        setCurrentAdminInputValue("");
        setIsNewOrganization(false);
        setSelectOrganization(organization);
    };

    return (
        <div>
            <h1>Organizations</h1>
            <div className={organizations_card_scope}>
                <div>
                    <div>
                        <div>Name Organization:</div>
                        <input
                            type={"text"}
                            value={organization.name}
                            onChange={onChangeOrganizationName}
                        />
                    </div>
                    <br />
                    <div>
                        <div> Email address:</div>
                        <div className={input_container}>
                            <input
                                onChange={handleAdminInputChange}
                                placeholder="New sys admin email address"
                                type={"text"}
                            />
                            <button className={but} onClick={onAddSysAdmin}>
                                Add
                            </button>
                        </div>

                        <h4>Current sys admins:</h4>
                        <ul>
                            {organization.sys_admins.map((sys_admin) => {
                                return <li key={sys_admin}>{sys_admin}</li>;
                            })}
                        </ul>
                    </div>
                </div>
                <ListOrganizations
                    onSelectOrganization={onSelectOrganization}
                    organizations={organizations}
                />
                <ListDomains
                    selectOrganization={selectOrganization}
                    onSelectDomains={onSelectDomains}
                    domains={domains}
                />
            </div>
            <button className={but} onClick={createNewOrganization}>
                {isNewOrganization ? "Save" : "Update"}
            </button>
        </div>
    );
};
