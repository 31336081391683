import React from "react";
import { SurveyClasses } from "@difftone/types";

import { Header, DashboardSurveysTable, SurveyListOptions } from "./components";

import {
    survey_list_wrapper,
    survey_list_display_form,
} from "./survey-list-display.module.css";

export type SurveyListDisplayProps = {
    surveys: SurveyClasses[];
};

export const SurveyListDisplay = (props: SurveyListDisplayProps) => {
    const { surveys } = props;
    return (
        <div className={survey_list_wrapper}>
            <div className={survey_list_display_form}>
                <Header />
                <DashboardSurveysTable surveys={surveys} />
                <SurveyListOptions />
            </div>
        </div>
    );
};
