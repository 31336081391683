import React, { ChangeEvent, useState } from "react";

import { v4 as uuid } from "uuid";
import { Domain } from "@difftone/types";
import { upsertDomainForDifftone } from "@difftone/features/organizations-domains/services";
import { ListDomains } from "./components";

import { domains_scope_card, but } from "./domains.module.css";

export type DomainsProps = {
    domains: Domain[];
    setDomains: (domains: Domain[]) => void;
    onRemoveDomainFromAllOrganization: (domain: Domain) => void;
};

export const Domains = (props: DomainsProps) => {
    const { domains, setDomains, onRemoveDomainFromAllOrganization } = props;
    const [domain, setDomain] = useState<Domain>({
        uuid: uuid(),
        name: "",
        type: "PRIVATE_REGISTER",
        is_deleted: false,
    });

    const addDomain = async () => {
        const results = await upsertDomainForDifftone(domain);

        if (results === null) {
            window.alert(`Error! Something went wrong!`);
        } else {
            setDomains([...domains, domain]);
            window.alert(`Successfully added ${domain.name} domain`);
        }
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDomain({
            uuid: domain.uuid,
            name: event.target.value,
            type: domain.type,
            is_deleted: false,
        });
    };

    return (
        <div>
            <h1>Domains </h1>
            <div className={domains_scope_card}>
                <div>
                    <h3>Name Domain:</h3>
                    <input
                        type={"text"}
                        value={domain.name}
                        onChange={onChange}
                    />
                </div>
                <div>
                    <ListDomains
                        domains={domains}
                        setDomains={setDomains}
                        onRemoveDomainFromAllOrganization={
                            onRemoveDomainFromAllOrganization
                        }
                    />
                </div>
            </div>
            <button className={but} onClick={addDomain}>
                Save
            </button>
        </div>
    );
};
