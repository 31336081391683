import React from "react";

import mulitColorGridIcon from "@difftone/assets/mulit-color-grid-icon.svg";
import grayGridIcon from "@difftone/assets/gray-grid-icon.svg";
import greenGridIcon from "@difftone/assets/green-grid-icon.svg";
import blackGridIcon from "@difftone/assets/black-grid-icon.svg";

export type GridIconProps = {
    isSelected: boolean;
    isMouseHover: boolean;
    isDisabled: boolean;
    classNameOverride?: string;
};

export const GridIcon = (props: GridIconProps) => {
    const { isSelected, isMouseHover, isDisabled, classNameOverride } = props;

    if (isDisabled) {
        return <img className={classNameOverride || ""} src={grayGridIcon} />;
    }

    if (isMouseHover) {
        return <img className={classNameOverride || ""} src={greenGridIcon} />;
    }

    if (isSelected) {
        return <img className={classNameOverride || ""} src={blackGridIcon} />;
    }

    return <img className={classNameOverride || ""} src={mulitColorGridIcon} />;
};
