import { action } from "mobx";
import {
    BaseSurvey,
    SurveyToSimpleResultsMap,
    SharedResult,
    UUID,
} from "@difftone/types";
import { simpleResultsMapStore } from "@difftone/stores";
import {
    getSimpleSharedResultsMapFromApi,
    getSimpleResultsMapFromApi,
} from "@difftone/services";

const simpleResultsMapSetter = action(
    (
        surveysUUIDs: UUID[],
        fetchedSimpleResultsMap: SurveyToSimpleResultsMap[]
    ) => {
        fetchedSimpleResultsMap.forEach((surveyToSimpleResultsMap, index) => {
            if (!surveyToSimpleResultsMap) {
                simpleResultsMapStore.setSimpleResultsTableToMap(
                    surveysUUIDs[index],
                    null
                );
                return;
            }

            simpleResultsMapStore.setSimpleResultsTableToMap(
                surveyToSimpleResultsMap.surveyUUID,
                surveyToSimpleResultsMap.simpleResultsMap
            );
        });
    }
);

export const getSimpleResultsMapBySurveyUUIDFromApi = action(
    async (surveys: BaseSurvey[], sharedResult?: SharedResult) => {
        const surveysUUIDs = surveys.map((survey) => survey.uuid);

        try {
            let fetchedSimpleResultsMap: SurveyToSimpleResultsMap[];

            if (sharedResult) {
                fetchedSimpleResultsMap =
                    await getSimpleSharedResultsMapFromApi(sharedResult);
            } else {
                fetchedSimpleResultsMap = await getSimpleResultsMapFromApi(
                    surveysUUIDs
                );
            }

            simpleResultsMapSetter(surveysUUIDs, fetchedSimpleResultsMap);
        } catch (error) {
            console.error(`FETCH ERROR:: fetching survey  failed`, error);
            surveysUUIDs.forEach((uuid) => {
                simpleResultsMapStore.setSimpleResultsTableToMap(uuid, null);
            });
        }
    }
);
