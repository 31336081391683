import {
    getDataPieChart,
    getOrganizations,
    getSelectedOrganizationUUID,
} from "@difftone/reducers";
import { NoDataToShow, ShowDifftoneTooltip } from "@difftone/shared-components";
import { organizationStore } from "@difftone/stores";
import { DataPieChart, BaseSurvey } from "@difftone/types";
import React from "react";
import { Header, PieChart } from "./components";
import { Label } from "./components";

import {
    survey_purpose_wrapper,
    pie_chart_wrapper,
    pie_chart_and_label_wrapper,
    labels_wrapper,
    no_data_to_show_wrapper,
} from "./survey-filter-by-purpose.module.css";

export type SurveyFilterByPurposeProps = {
    surveys: BaseSurvey[];
};

export const SurveyFilterByPurpose = (props: SurveyFilterByPurposeProps) => {
    const { surveys } = props;
    const dataPieChart: DataPieChart[] = getDataPieChart(surveys);

    const selectedOrganization = getSelectedOrganizationUUID();
    const organizationsList = getOrganizations();

    let _organization;

    if (selectedOrganization) {
        _organization =
            organizationStore.getOrganizationByUuid(selectedOrganization);
    }

    return (
        <div className={survey_purpose_wrapper}>
            <div className={pie_chart_wrapper}>
                <Header />
                <div className={pie_chart_and_label_wrapper}>
                    {dataPieChart.length ? (
                        <>
                            <PieChart dataPieChart={dataPieChart} />
                            <div className={labels_wrapper}>
                                {dataPieChart.map((item: DataPieChart) => (
                                    <React.Fragment key={item.lable}>
                                        <ShowDifftoneTooltip
                                            tooltipPosition="bottom"
                                            tip={item.lable}
                                        >
                                            <Label
                                                color={item.color}
                                                text={item.lable}
                                            />
                                        </ShowDifftoneTooltip>
                                    </React.Fragment>
                                ))}
                            </div>
                        </>
                    ) : (
                        <div className={no_data_to_show_wrapper}>
                            <NoDataToShow />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
