import {
    InvalidValuesIndexesInArray,
    BaseSurvey,
    SurveyNameValidationType,
    ValidationResult,
} from "@difftone/types";

export const validateSurveyNameUniqueness = (
    survey: BaseSurvey,
    restOfSurveys: BaseSurvey[]
): ValidationResult<SurveyNameValidationType> => {
    const duplicatedNamesMap: InvalidValuesIndexesInArray = {};

    [survey, ...restOfSurveys].forEach((survey, index) => {
        if (!duplicatedNamesMap[survey.survey_name]) {
            duplicatedNamesMap[survey.survey_name] = [index];
        } else {
            duplicatedNamesMap[survey.survey_name].push(index);
        }
    });

    for (const element in duplicatedNamesMap) {
        if (duplicatedNamesMap[element].length === 1) {
            delete duplicatedNamesMap[element];
        }
    }

    return !Object.keys(duplicatedNamesMap).includes(survey.survey_name)
        ? {
              is_valid: true,
              validation_error_code: "SURVEY_NAME_DUPLICATION",
              validation_error_type: { survey_uuid: survey.uuid },
          }
        : {
              is_valid: false,
              validation_error_code: "SURVEY_NAME_DUPLICATION",
              validation_error_type: { survey_uuid: survey.uuid },
          };
};
