import { action } from "mobx";
import {
    BaseSurvey,
    SurveyClasses,
    SurveyContainer,
    SurveyEvent,
    SurveyEventAlert,
    UUID,
} from "@difftone/types";
import { alertsStore, surveyStore } from "@difftone/stores";
import {
    showDifftoneAlert,
    getNewEmptySurveyEventAlert,
    getAlertButtonsFactory,
} from "@difftone/actions";
import { constantAlertVariants } from "@difftone/constants";

const MAX_COUNTER_FOR_SURVEY_NAME = 300;

const duplicateSurveyName = action((survey: BaseSurvey) => {
    let namePrefix = "copy";
    let copyCounter: number = 0;
    do {
        const copyName = `${namePrefix}_${survey.survey_name}`;
        if (
            !surveyStore.surveysList.find(
                (survey) => survey?.survey_name === copyName
            )
        ) {
            return copyName;
        } else {
            copyCounter++;
            namePrefix = `copy(${copyCounter.toString()})`;
        }
    } while (copyCounter < MAX_COUNTER_FOR_SURVEY_NAME); // TODO: think of a better stop criteria!
    return `copy_${Date.now()}_${survey.survey_name}`;
});

export const duplicateSurveyEventAlerts = action(
    (
        oldAlerts: SurveyEventAlert[],
        alertsToDuplicate: string[],
        duplicationOfSurveyEvent: SurveyEvent,
        surveyName: string,
        surveyUuid: UUID
    ): string[] => {
        let newDuplicatedUUIDs: string[] = [];

        newDuplicatedUUIDs = alertsToDuplicate.map((_oldAlertUUID) => {
            const alertInstance = oldAlerts.find(
                (_alert) => _alert.uuid === _oldAlertUUID
            );
            if (!alertInstance) {
                console.error("Somthing is wrong with alert duplication");
                return "err";
            }

            const duplicationOfAlert = getNewEmptySurveyEventAlert(
                duplicationOfSurveyEvent,
                alertInstance?.approvers,
                alertInstance?.send_to,
                alertInstance?.alert_type
            );

            duplicationOfAlert.buttons = getAlertButtonsFactory(
                duplicationOfAlert,
                surveyUuid
            );

            duplicationOfAlert.time_units = alertInstance.time_units;
            duplicationOfAlert.time_offset = alertInstance.time_offset;
            duplicationOfAlert.beforeAfter = alertInstance.beforeAfter;

            duplicationOfAlert.content = alertInstance.content;

            duplicationOfAlert.content.survey_name = surveyName;
            duplicationOfAlert.is_paused = false;
            duplicationOfAlert.event_UUID = duplicationOfSurveyEvent.uuid;

            alertsStore.InsertAlertToStoreWithoutDebounce(duplicationOfAlert);

            return duplicationOfAlert.uuid;
        });

        return newDuplicatedUUIDs;
    }
);

export const duplicateSurveyToDraft = action(
    (surveyToDuplicateContainer: SurveyContainer<SurveyClasses>) => {
        const _surveyToDuplicate = surveyToDuplicateContainer.survey;
        _surveyToDuplicate.survey_name =
            duplicateSurveyName(_surveyToDuplicate);
        _surveyToDuplicate.status = "DRAFT";

        if (_surveyToDuplicate.survey_class === "SIMPLE") {
            _surveyToDuplicate.survey_events.forEach((surveyEvent) => {
                surveyEvent.event_emails = [];
            });
        }

        _surveyToDuplicate.created_at = Date.now();

        surveyStore.addSurveyToMapWithoutDebounce(_surveyToDuplicate);
        if (surveyToDuplicateContainer.survey_events_alerts) {
            surveyToDuplicateContainer.survey_events_alerts.forEach(
                (duplicatedSurveyEventAlert) => {
                    alertsStore.InsertAlertToStoreWithoutDebounce(
                        duplicatedSurveyEventAlert
                    );
                }
            );
        }
        showDifftoneAlert(
            `"${_surveyToDuplicate.survey_name}" ${constantAlertVariants.duplicate_survey.text}`,
            constantAlertVariants.duplicate_survey.variant
        );
        return;
    }
);
