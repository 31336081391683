import {
    getAuthInstance,
    localstorageUtils,
    setAuthInstance,
} from "@difftone/procedures";
import { createDifftoneAxios } from "@difftone/services";
import { EmailAddress } from "@difftone/types";

const GOOGLE_CLOUD_API_BASE_URL = `https://cloudidentity.googleapis.com/v1`;
const MICROSOFT_GROUPS_API_BASE_URL = `https://graph.microsoft.com/v1.0/groups`;
const GOOGLE_API_SERVER_BASE_URL = `https://people.googleapis.com/v1`;

export const getEmailGroupMembersFromGoogle = async (
    emailToCheck: EmailAddress
): Promise<EmailAddress[] | null> => {
    const REQUIRED_SCOPE =
        "https://www.googleapis.com/auth/cloud-identity.groups.readonly";
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    if (currentAuthData.issuer !== "GOOGLE") return null;

    const googleUserInstance = getAuthInstance();

    const grantedScopes = googleUserInstance.getGrantedScopes().split(" ");

    if (!grantedScopes.includes(REQUIRED_SCOPE)) {
        try {
            const options = new gapi.auth2.SigninOptionsBuilder();
            options.setScope(REQUIRED_SCOPE);

            const res = await googleUserInstance.grant(options);
            if (res.error) {
                throw new Error(res.error);
            } else {
                setAuthInstance(res, "GOOGLE");
            }
        } catch (error) {
            console.error("[googleUserInstance.grant]:: failed", error);
            return null;
        }
    }

    try {
        const groupIdResponse = await difftoneAxios.get(
            `${GOOGLE_CLOUD_API_BASE_URL}/groups:lookup`,
            {
                headers: {
                    Authorization: `Bearer ${
                        gapi.client.getToken().access_token
                    }`,
                },
                params: {
                    "groupKey.id": emailToCheck,
                },
            }
        );

        if (groupIdResponse.data.error || !groupIdResponse.data.name)
            return null;

        const groupId = groupIdResponse.data.name;

        const groupMembersResponse = await difftoneAxios.get(
            `${GOOGLE_CLOUD_API_BASE_URL}/${groupId}/memberships`,
            {
                headers: {
                    Authorization: `Bearer ${
                        gapi.client.getToken().access_token
                    }`,
                },
            }
        );

        if (
            groupMembersResponse.data.error ||
            !groupMembersResponse.data.memberships
        )
            return null;

        const groupMembersEmails: EmailAddress[] =
            groupMembersResponse.data.memberships.map(
                (member: any) => member.preferredMemberKey.id
            );

        return groupMembersEmails;
    } catch (error) {
        return null;
    }
};

export const getEmailGroupMembersFromMicrosoft = async (
    emailToCheck: EmailAddress
): Promise<EmailAddress[] | null> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    if (currentAuthData.issuer !== "MICROSOFT") return null;

    try {
        const groupMembersResponse = await difftoneAxios.get(
            `${MICROSOFT_GROUPS_API_BASE_URL}/?$filter=mail+eq+'${emailToCheck}'&$expand=members`,
            {
                headers: {
                    Authorization: `Bearer ${currentAuthData.access_token}`,
                },
            }
        );

        if (
            groupMembersResponse.data.error ||
            !groupMembersResponse.data.value.length ||
            !groupMembersResponse.data.value[0].members.length
        )
            return null;

        const groupMembersEmails: EmailAddress[] =
            groupMembersResponse.data.value[0].members.map(
                (member: any) => member.mail
            );

        return groupMembersEmails;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const checkIfEmailIsGroupByNames = async (
    emailToCheck: EmailAddress
): Promise<boolean> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    try {
        const queryResponse = await difftoneAxios.get(
            `${GOOGLE_API_SERVER_BASE_URL}/otherContacts:search`,
            {
                headers: {
                    Authorization: `Bearer ${
                        gapi.client.getToken().access_token
                    }`,
                },
                params: {
                    query: emailToCheck,
                    readMask: "names",
                },
            }
        );

        if (queryResponse.data.error || !queryResponse.data.results.length)
            return false;

        const nameData = queryResponse.data.results[0].person.names[0];

        if (nameData?.givenName === nameData?.displayName) return true;
        return false;
    } catch (error) {
        return false;
    }
};
