import React, { MouseEvent, KeyboardEvent, useState, useEffect } from "react";
import clsx from "clsx";
import { handleIfDropdownFitsViewPort } from "@difftone/procedures";
import { AddBoxShadow, ShowDifftoneTooltip } from "@difftone/shared-components";

import successTick from "@difftone/assets/success-tick-no-circle.svg";
import dropDownArrowIcon from "@difftone/assets/drop-down-arrow.svg";
import greyDropDownArrow from "@difftone/assets/drop-down-arrow-grey.svg";

import {
    dropdown,
    dropdown_options_wrapper,
    dropdown_options,
    selected_option,
    dropdown_option,
    dropdown_option_active,
    dropdown_option_disabled,
    dropdown_small,
    dropdown_options_small_wrapper,
    dropdown_option_small,
} from "./simple-dropdown.module.css";

export type DropdownElements = {
    systemValue: string;
    displayValue: string;
};

type SizeDropdown = "normal" | "small";

export type SimpleDropdownProps = {
    selectedValue: string;
    dropdownOptions: DropdownElements[];
    onChange: (selectedValue: string) => void;
    disabled?: boolean;
    disabledOptions?: string[];
    inSurveyMode?: boolean;
    wrapperClassName?: string;
    textValueClassName?: string;
    sizeDropdown?: SizeDropdown;
};

const MAX_ALLOWED_CHARS_IN_ANSWER = 42;

export const SimpleDropdown = (props: SimpleDropdownProps) => {
    const {
        selectedValue,
        dropdownOptions,
        onChange,
        disabled,
        disabledOptions,
        inSurveyMode,
        wrapperClassName,
        textValueClassName,
        sizeDropdown,
    } = props;

    //state for inner toggling of dropdown options
    const [showOptions, setShowOptions] = useState(false);
    //state for the display of active row style in the dropdown by index
    const [activeOption, setActiveOption] = useState<number>(-1);

    const isArrowVisible = !disabled;

    const _selectedValue = dropdownOptions.find(
        (option) => option.systemValue === selectedValue
    );

    const _selectedValueIndex = dropdownOptions.findIndex(
        (option) => option.systemValue === selectedValue
    );

    const showOptionsHandler = () => {
        setShowOptions(!showOptions);
    };
    const hideOptionsHandler = () => {
        setShowOptions(false);
        setActiveOption(-1);
    };

    const onOptionClicked = (event: MouseEvent<HTMLLIElement>) => {
        const selectedItem = event.currentTarget.getAttribute("data-value");
        if (disabledOptions?.includes(selectedItem!)) return;
        selectedItem && onChange(selectedItem);
        showOptionsHandler();
    };

    const onMouseOverHandler = (event: MouseEvent<HTMLLIElement>) => {
        const hoveredIndex = event.currentTarget.getAttribute("data-index");
        hoveredIndex && setActiveOption(parseInt(hoveredIndex));
    };

    const onMouseOutHandler = () => {
        setActiveOption(-1);
    };

    const onKeyStrokeHandler = (event: KeyboardEvent<HTMLDivElement>) => {
        switch (event.key) {
            case "Escape":
                setShowOptions(false);
                return;
            case "ArrowUp":
                if (activeOption === -1) {
                    setActiveOption(0);
                } else if (activeOption === 0) {
                    setActiveOption(dropdownOptions.length - 1);
                } else {
                    setActiveOption(activeOption - 1);
                }
                event.preventDefault();
                return;
            case "ArrowDown":
                if (activeOption === -1) {
                    setActiveOption(0);
                } else {
                    setActiveOption(
                        (activeOption + 1) % dropdownOptions.length
                    );
                }
                event.preventDefault();
                return;
            case "Enter":
                const selectedItem =
                    dropdownOptions[activeOption] &&
                    dropdownOptions[activeOption].systemValue;
                if (
                    disabledOptions &&
                    disabledOptions.includes(
                        dropdownOptions[activeOption].systemValue
                    )
                )
                    return;
                hideOptionsHandler();
                selectedItem && onChange(selectedItem);
                return;
            default:
                // TODO: add list traverse with chars pressed https://trello.com/c/ENIbtfNH
                return;
        }
    };

    //useEffect:: call handleIfDropdownFitsViewPort when dropdown is opened and the list has been changed
    useEffect(() => {
        const dropDownComponent: HTMLDivElement | null = document.querySelector(
            "div[data-dropdown-wrapper]"
        );
        const surveyWrapper: HTMLDivElement | null = document.querySelector(
            `div[data-scroll-element="survey-wrapper"]`
        );
        showOptions &&
            handleIfDropdownFitsViewPort(surveyWrapper, dropDownComponent);
    }, [showOptions]);

    return (
        <div
            tabIndex={0}
            onClick={showOptionsHandler}
            onBlur={hideOptionsHandler}
            onKeyDown={onKeyStrokeHandler}
            className={clsx(
                wrapperClassName,
                sizeDropdown === "normal" || !sizeDropdown
                    ? dropdown
                    : dropdown_small
            )}
        >
            <div
                className={clsx(
                    selectedValue && selected_option,
                    "user_select",
                    textValueClassName
                )}
            >
                {_selectedValue
                    ? _selectedValue.displayValue
                    : "choose your answer"}
            </div>

            {isArrowVisible && (
                <img
                    src={
                        disabledOptions?.length === dropdownOptions.length
                            ? greyDropDownArrow
                            : dropDownArrowIcon
                    }
                    alt="arrow"
                />
            )}

            {showOptions && !disabled ? (
                <AddBoxShadow>
                    <div
                        data-dropdown-wrapper
                        className={
                            sizeDropdown === "normal" || !sizeDropdown
                                ? dropdown_options_wrapper
                                : dropdown_options_small_wrapper
                        }
                    >
                        <ul className={dropdown_options}>
                            {dropdownOptions.map((option, index) => {
                                return (
                                    <li
                                        className={clsx(
                                            dropdown_option,
                                            {
                                                [dropdown_option_active]:
                                                    activeOption === index &&
                                                    !disabledOptions?.includes(
                                                        option.systemValue
                                                    ),
                                                [dropdown_option_disabled]:
                                                    disabledOptions?.includes(
                                                        option.systemValue
                                                    ),
                                            },
                                            sizeDropdown === "small" &&
                                                dropdown_option_small
                                        )}
                                        onMouseDown={onOptionClicked}
                                        onMouseOver={onMouseOverHandler}
                                        onMouseOut={onMouseOutHandler}
                                        data-value={option.systemValue}
                                        data-index={index}
                                        key={option.systemValue}
                                    >
                                        {option.displayValue.length >=
                                        MAX_ALLOWED_CHARS_IN_ANSWER ? (
                                            <ShowDifftoneTooltip
                                                tip={option.displayValue}
                                                tooltipPosition="right"
                                            >
                                                {option.displayValue}
                                            </ShowDifftoneTooltip>
                                        ) : (
                                            <div>{option.displayValue}</div>
                                        )}
                                        {inSurveyMode &&
                                            _selectedValueIndex === index && (
                                                <img alt="" src={successTick} />
                                            )}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </AddBoxShadow>
            ) : null}
        </div>
    );
};
