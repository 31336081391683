import React from "react";
import { QuestionTypeName } from "@difftone/types";

import {
    box_with_option_stats,
    frequency_class,
    frequency_as_percentage_class,
} from "./mobile-detailed-option-result-box.module.css";

export type MobileDetailedOptionResultBoxProps = {
    totalResponseSummary: number;
    questionType: QuestionTypeName;
    frequency: number;
    populationSize: number;
};

export const MobileDetailedOptionResultBox = (
    props: MobileDetailedOptionResultBoxProps
) => {
    const { totalResponseSummary, questionType, frequency, populationSize } =
        props;

    let frequencyInPercentage = null;
    let frequencyItem = null;
    switch (questionType) {
        case "RATING":
        case "SINGLE_CHOICE":
        case "MULTIPLE_CHOICE":
            frequencyInPercentage = (
                (frequency / totalResponseSummary) *
                100
            ).toFixed(0);
            frequencyItem = frequency;
            break;
        case "LONG_TEXT":
        case "SHORT_TEXT":
        case "DATE":
        case "TIME":
            const noResponseFrequency = populationSize - frequency;

            frequencyInPercentage = (
                (noResponseFrequency / totalResponseSummary) *
                100
            ).toFixed(0);
            frequencyItem = noResponseFrequency;
            break;
        default:
            frequencyInPercentage = (
                (frequency / populationSize) *
                100
            ).toFixed(0);
            frequencyItem = frequency;
            break;
    }

    return (
        <div className={box_with_option_stats}>
            <div className={frequency_class}>
                {frequencyItem ? frequencyItem : 0}
            </div>
            <div className={frequency_as_percentage_class}>
                {frequencyInPercentage !== "NaN"
                    ? `${frequencyInPercentage}%`
                    : ""}
            </div>
        </div>
    );
};
