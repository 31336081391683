import React, { MouseEvent, useState } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import { DashboardSortingTypes } from "@difftone/types";
import {
    dashboardDisplayStore,
    DashboardSortingProfile,
} from "@difftone/stores";
import { setDashboardSortingProfile } from "@difftone/actions";

import {
    table_header_cell,
    active_filter,
    up_arrow_direction,
} from "./table-header-cell.module.css";
import { TableHeaderArrowIcon } from "@difftone/shared-components";

export type FilterTypes =
    | "NAME"
    | "END"
    | "OVERALL_RESPONSES"
    | "RESPONSE_RATE"
    | null;

export type TableHeaderCellProps = {
    className?: string;
    text: string;
    filterType: FilterTypes;
    isFilterActive: boolean;
    setFilter: (event: MouseEvent<HTMLDivElement>) => void;
};

const COLOR_BLACK = "BLACK";
const COLOR_GRAY = "GRAY";

export const TableHeaderCell = observer((props: TableHeaderCellProps) => {
    const { className, text, filterType, isFilterActive, setFilter } = props;
    const { sortingProfile } = dashboardDisplayStore;

    const [isArrowUp, setIsArrowUp] = useState(
        isFilterActive ? sortingProfile.ascending : true
    );

    const filter = filterType ? filterType : "";
    const headerText = text === "blank" ? "" : text;

    const toggleArrowDirection = () => {
        setIsArrowUp(!isArrowUp);
    };

    const handleOnClick = (event: MouseEvent<HTMLDivElement>) => {
        toggleArrowDirection();
        const newSortingProfile: DashboardSortingProfile = {
            ascending: !isArrowUp,
            sortBy: filter.toUpperCase() as DashboardSortingTypes,
        };
        setDashboardSortingProfile(newSortingProfile);
        setFilter(event);
    };

    return (
        <div
            onClick={handleOnClick}
            data-filter={filter}
            className={clsx(
                table_header_cell,
                className || "",
                isFilterActive && active_filter
            )}
        >
            {text !== "blank" ? (
                <TableHeaderArrowIcon
                    className={isArrowUp ? up_arrow_direction : ""}
                    color={isFilterActive ? COLOR_BLACK : COLOR_GRAY}
                />
            ) : null}
            <span>{headerText}</span>
        </div>
    );
});
