import React from "react";
import { surveyResponseStore } from "@difftone/stores";
import { showDifftoneAlert } from "@difftone/actions";
import { BaseSurvey } from "@difftone/types";
import { Spinner } from "@difftone/shared-components";

import { SurveyLeftMenuListItem } from "./components";

import { survey_left_menu_button } from "./survey-left-menu-button.module.css";

export type SurveyLeftMenuButtonProps = {
    survey: BaseSurvey;
};

export const SurveyLeftMenuQuestionList = (
    props: SurveyLeftMenuButtonProps
) => {
    const { getSelectedSurveyResponse } = surveyResponseStore;
    const { survey } = props;
    const selectedSurveyResponse = getSelectedSurveyResponse();

    if (selectedSurveyResponse === undefined) {
        return <Spinner size="MEDIUM" />;
    }

    if (selectedSurveyResponse === null) {
        showDifftoneAlert("could not find survey response", "FAILURE");
        return null;
    }

    const leftMenuQuestions = survey.questions;

    return (
        <div className={survey_left_menu_button}>
            <div>
                {leftMenuQuestions?.map((surveyCard, index) => (
                    <SurveyLeftMenuListItem
                        key={surveyCard.uuid}
                        surveyCard={surveyCard}
                        index={index + 1}
                    />
                ))}
            </div>
        </div>
    );
};
