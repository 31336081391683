import React, { MouseEvent, useEffect } from "react";
import clsx from "clsx";

import closeIcon from "@difftone/assets/close-datepicker-white.svg";
import { ScrollableYearHeader } from "./components";

import {
    scrollable_year_input,
    year_column,
    year_input_header,
    scrollable_body,
    scrollable_year_value,
    selected_year_value,
    scrollable_years_wrapper,
} from "./scrollable-year-input.module.css";

const FIRST_YEAR_OF_YEARS = 1930;

const NUMBERS_OF_YEARS_IN_DROP_DOWN = 170;

const TOP_POSITION_SCROLL = 80;

export type ScrollableYearInputProps = {
    closeCalendar: () => void;
    setTime: (newDateString: string, newTimeString: string) => void;
    dateValue: string;
    timeValue: string;
};

export const ScrollableYearInput = (props: ScrollableYearInputProps) => {
    const { closeCalendar, setTime, dateValue, timeValue } = props;
    const _year = dateValue.split("-")[0];

    const yearsAsString = new Array(NUMBERS_OF_YEARS_IN_DROP_DOWN)
        .fill(0)
        .map((value, index) => index + FIRST_YEAR_OF_YEARS);

    const executeTimeScroll = (
        itemWrapper: HTMLElement,
        activeItem: HTMLDivElement
    ) => {
        const activeItemHeight = activeItem.offsetTop;

        activeItem &&
            itemWrapper.scrollTo({
                top: activeItemHeight - TOP_POSITION_SCROLL,
                behavior: "smooth",
            });
    };

    const onYearChangedHandler = (event: MouseEvent<HTMLDivElement>) => {
        const selectedYear = event.currentTarget.getAttribute("data-years")!;

        const newDateValue = dateValue.split("-");
        newDateValue[0] = selectedYear;
        const updatedDateValue = newDateValue.join("-");

        setTime(updatedDateValue, timeValue);
    };

    //[useEffect]:: handle the auto scroll of active year values in columns
    useEffect(() => {
        const yearsScrollWrapper = document.querySelector(
            `div[data-calendar-years]`
        ) as HTMLElement;

        const activeYearItem = document.querySelector(
            `div[data-years-active="true"]`
        ) as HTMLDivElement;

        executeTimeScroll(yearsScrollWrapper, activeYearItem);
    }, [_year]);

    return (
        <div className={scrollable_year_input}>
            <div className={year_input_header}>
                <img onClick={closeCalendar} src={closeIcon} alt="close" />
            </div>
            <div className={scrollable_body}>
                <ScrollableYearHeader />
                <div className={scrollable_years_wrapper}>
                    <div className={year_column} data-calendar-years>
                        {yearsAsString.map((value) => {
                            const isActive = value.toString() === _year;
                            return (
                                <div
                                    onClick={onYearChangedHandler}
                                    className={clsx(
                                        scrollable_year_value,
                                        isActive && selected_year_value
                                    )}
                                    key={value}
                                    data-unit="YYYY"
                                    data-years={value}
                                    data-years-active={isActive}
                                >
                                    {value}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
