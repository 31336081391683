import React, { useState, MouseEvent } from "react";

import { SharedViewsWithMeFilters } from "@difftone/stores";

import { TableHeaderCell } from "./components";

import {
    table_header,
    share_name_class,
    shared_by_class,
    survey_name_class,
    initiator_class,
    created_class,
} from "./table-header.module.css";

//TODO: change the name for Views shared with me table header
export const TableHeader = () => {
    // temp variable to store activeFilter's name
    const [activeFilter, setActiveFilter] =
        useState<SharedViewsWithMeFilters | null>("NAME");

    const setFilter = (event: MouseEvent<HTMLDivElement>) => {
        const filterType = event.currentTarget.getAttribute(
            "data-filter"
        ) as SharedViewsWithMeFilters;

        if (filterType) {
            setActiveFilter(filterType);
        }
    };

    return (
        <div className={table_header}>
            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "NAME"}
                filterType="NAME"
                text="Name"
                className={share_name_class}
            />

            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "SHARED_BY"}
                filterType="SHARED_BY"
                text="Shared By"
                className={shared_by_class}
            />

            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "SURVEY_NAME"}
                filterType="SURVEY_NAME"
                text="Survey Name"
                className={survey_name_class}
            />

            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "INITIATOR"}
                filterType="INITIATOR"
                text="Initiator"
                className={initiator_class}
            />

            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "CREATED"}
                filterType="CREATED"
                text="Created"
                className={created_class}
            />
        </div>
    );
};
