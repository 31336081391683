import { getPublicProfileEmail } from "@difftone/actions";
import {
    DisplaySurvey,
    OngoingSurvey,
    SimpleSurvey,
    SurveyClasses,
} from "@difftone/types";
import {
    getLastDateToFill,
    getOngoingSurveyTimeLeftSortBy,
    getPublishedDate,
    getStatus,
    getSurveyProgressSortBy,
    getSurveyTimeLeftSortBy,
} from "@difftone/reducers";

export const sortSurveysBySurveyName = (
    ascending: boolean,
    displaySurveys: DisplaySurvey[]
) => {
    return ascending
        ? displaySurveys.sort((displaySurveyA, displaySurveyB) =>
              displaySurveyA.survey.survey_name?.toLowerCase()! >=
              displaySurveyB.survey.survey_name?.toLowerCase()!
                  ? 1
                  : -1
          )
        : displaySurveys.sort((displaySurveyA, displaySurveyB) =>
              displaySurveyA.survey.survey_name?.toLowerCase()! <=
              displaySurveyB.survey.survey_name?.toLowerCase()!
                  ? 1
                  : -1
          );
};

export const sortSurveysByInitiator = (
    ascending: boolean,
    displaySurveys: DisplaySurvey[]
) => {
    return ascending
        ? displaySurveys.sort((displaySurveyA, displaySurveyB) =>
              getPublicProfileEmail(
                  displaySurveyA.survey.initiator
              )?.toLowerCase()! >=
              getPublicProfileEmail(
                  displaySurveyB.survey.initiator
              )?.toLowerCase()!
                  ? 1
                  : -1
          )
        : displaySurveys.sort((displaySurveyA, displaySurveyB) =>
              getPublicProfileEmail(
                  displaySurveyA.survey.initiator
              )?.toLowerCase()! <=
              getPublicProfileEmail(
                  displaySurveyB.survey.initiator
              )?.toLowerCase()!
                  ? 1
                  : -1
          );
};

export const sortSurveysByStartDate = (
    ascending: boolean,
    displaySurveys: DisplaySurvey[]
) => {
    return ascending
        ? ascendingSortSurveysByStartDate(displaySurveys)
        : descendingSortSurveysByStartDate(displaySurveys);
};

const ascendingSortSurveysByStartDate = (displaySurveys: DisplaySurvey[]) => {
    return displaySurveys.sort((displaySurveyA, displaySurveyB) => {
        const surveyA = displaySurveyA.survey as SurveyClasses;
        const surveyB = displaySurveyB.survey as SurveyClasses;

        let surveyAStartDate: number = 0;
        let surveyBStartDate: number = 0;

        switch (surveyA.survey_class) {
            case "ONGOING":
                surveyAStartDate = surveyA.created_at;

                break;
            case "SIMPLE":
            default:
                surveyAStartDate = getPublishedDate(surveyA as SimpleSurvey)!;
        }

        switch (surveyB.survey_class) {
            case "ONGOING":
                surveyBStartDate = surveyB.created_at;
                break;
            case "SIMPLE":
            default:
                surveyBStartDate = getPublishedDate(surveyB as SimpleSurvey)!;
        }

        return surveyAStartDate - surveyBStartDate;
    });
};

const descendingSortSurveysByStartDate = (displaySurveys: DisplaySurvey[]) => {
    return displaySurveys.sort((displaySurveyA, displaySurveyB) => {
        const surveyA = displaySurveyA.survey as SurveyClasses;
        const surveyB = displaySurveyB.survey as SurveyClasses;

        let surveyAStartDate: number = 0;
        let surveyBStartDate: number = 0;

        switch (surveyA.survey_class) {
            case "ONGOING":
                surveyAStartDate = surveyA.created_at;

                break;
            case "SIMPLE":
            default:
                surveyAStartDate = getPublishedDate(surveyA as SimpleSurvey)!;
        }

        switch (surveyB.survey_class) {
            case "ONGOING":
                surveyBStartDate = surveyB.created_at;
                break;
            case "SIMPLE":
            default:
                surveyBStartDate = getPublishedDate(surveyB as SimpleSurvey)!;
        }

        return surveyBStartDate - surveyAStartDate;
    });
};

export const sortSurveysByIssuedDate = (
    ascending: boolean,
    displaySurveys: DisplaySurvey[]
) => {
    return ascending
        ? displaySurveys.sort(
              (displaySurveyA, displaySurveyB) =>
                  displaySurveyA.survey.created_at -
                  displaySurveyB.survey.created_at
          )
        : displaySurveys.sort(
              (displaySurveyA, displaySurveyB) =>
                  displaySurveyB.survey.created_at -
                  displaySurveyA.survey.created_at
          );
};

export const sortSurveysByEndData = (
    ascending: boolean,
    displaySurveys: DisplaySurvey[]
) => {
    return ascending
        ? ascendingSortSurveysByEndData(displaySurveys)
        : descendingSortSurveysByEndData(displaySurveys);
};

const ascendingSortSurveysByEndData = (displaySurveys: DisplaySurvey[]) => {
    return displaySurveys.sort((displaySurveyA, displaySurveyB) => {
        const surveyA = displaySurveyA.survey as SurveyClasses;
        const surveyB = displaySurveyB.survey as SurveyClasses;

        let surveyAEndDate: number = 0;
        let surveyBEndDate: number = 0;

        switch (surveyA.survey_class) {
            case "ONGOING":
                surveyAEndDate = (surveyA as OngoingSurvey).manually_ended
                    ? (surveyA as OngoingSurvey).manually_ended!
                    : Infinity;

                break;
            case "SIMPLE":
            default:
                surveyAEndDate = getLastDateToFill(surveyA as SimpleSurvey)!;
        }

        switch (surveyB.survey_class) {
            case "ONGOING":
                surveyBEndDate = (surveyB as OngoingSurvey).manually_ended
                    ? (surveyB as OngoingSurvey).manually_ended!
                    : Infinity;
                break;
            case "SIMPLE":
            default:
                surveyBEndDate = getLastDateToFill(surveyB as SimpleSurvey)!;
        }

        return surveyAEndDate - surveyBEndDate;
    });
};

const descendingSortSurveysByEndData = (displaySurveys: DisplaySurvey[]) => {
    return displaySurveys.sort((displaySurveyA, displaySurveyB) => {
        const surveyA = displaySurveyA.survey as SurveyClasses;
        const surveyB = displaySurveyB.survey as SurveyClasses;

        let surveyAEndDate: number = 0;
        let surveyBEndDate: number = 0;

        switch (surveyA.survey_class) {
            case "ONGOING":
                surveyAEndDate = (surveyA as OngoingSurvey).manually_ended
                    ? (surveyA as OngoingSurvey).manually_ended!
                    : Infinity;

                break;
            case "SIMPLE":
            default:
                surveyAEndDate = getLastDateToFill(surveyA as SimpleSurvey)!;
        }

        switch (surveyB.survey_class) {
            case "ONGOING":
                surveyBEndDate = (surveyB as OngoingSurvey).manually_ended
                    ? (surveyB as OngoingSurvey).manually_ended!
                    : Infinity;
                break;
            case "SIMPLE":
            default:
                surveyBEndDate = getLastDateToFill(surveyB as SimpleSurvey)!;
        }

        return surveyBEndDate - surveyAEndDate;
    });
};

export const sortSurveysByCreated = (
    ascending: boolean,
    displaySurveys: DisplaySurvey[]
) => {
    const noneSurveys = displaySurveys.filter(
        ({ survey }) => typeof survey.created_at !== "number"
    );
    const createdAtSurveys = displaySurveys.filter(
        ({ survey }) => typeof survey.created_at === "number"
    );

    const sorted = ascending
        ? createdAtSurveys.sort(
              (displaySurveyA, displaySurveyB) =>
                  displaySurveyB.survey.created_at! -
                  displaySurveyA.survey.created_at!
          )
        : createdAtSurveys.sort(
              (displaySurveyA, displaySurveyB) =>
                  displaySurveyA.survey.created_at! -
                  displaySurveyB.survey.created_at!
          );

    return [...sorted, ...noneSurveys];
};

export const sortSurveysByLast = (
    ascending: boolean,
    displaySurveys: DisplaySurvey[]
) => {
    const listOfOngoingSurveys: DisplaySurvey[] = [];
    const listOfRestSurveys: DisplaySurvey[] = [];

    displaySurveys.forEach((displaySurvey) => {
        if (displaySurvey.survey.survey_class === "ONGOING") {
            listOfOngoingSurveys.push(displaySurvey);
        } else {
            listOfRestSurveys.push(displaySurvey);
        }
    });

    return ascending
        ? [
              ...listOfOngoingSurveys.sort((displaySurveyA, displaySurveyB) => {
                  const simpleSurveyA = displaySurveyA.survey as OngoingSurvey;
                  const simpleSurveyB = displaySurveyB.survey as OngoingSurvey;
                  if (!simpleSurveyA.manually_ended) {
                      return 1;
                  }
                  if (!simpleSurveyB.manually_ended) {
                      return 1;
                  }

                  return (
                      simpleSurveyA.manually_ended -
                      simpleSurveyB.manually_ended
                  );
              }),
              ...listOfRestSurveys.sort((displaySurveyA, displaySurveyB) => {
                  const simpleSurveyA = displaySurveyA.survey as SimpleSurvey;
                  const simpleSurveyB = displaySurveyB.survey as SimpleSurvey;
                  return (
                      getLastDateToFill(simpleSurveyB)! -
                      getLastDateToFill(simpleSurveyA)!
                  );
              }),
          ]
        : [
              ...listOfRestSurveys.sort((displaySurveyA, displaySurveyB) => {
                  const simpleSurveyA = displaySurveyA.survey as SimpleSurvey;
                  const simpleSurveyB = displaySurveyB.survey as SimpleSurvey;
                  return (
                      getLastDateToFill(simpleSurveyA)! -
                      getLastDateToFill(simpleSurveyB)!
                  );
              }),
              ...listOfOngoingSurveys.sort((displaySurveyA, displaySurveyB) => {
                  const simpleSurveyA = displaySurveyA.survey as OngoingSurvey;
                  const simpleSurveyB = displaySurveyB.survey as OngoingSurvey;
                  if (!simpleSurveyA.manually_ended) {
                      return 1;
                  }
                  if (!simpleSurveyB.manually_ended) {
                      return 1;
                  }

                  return (
                      simpleSurveyB.manually_ended -
                      simpleSurveyA.manually_ended
                  );
              }),
          ];
};

export const sortSurveysByStatus = (
    ascending: boolean,
    displaySurveys: DisplaySurvey[]
) => {
    return ascending
        ? displaySurveys.sort((displaySurveyA, displaySurveyB) =>
              getStatus(displaySurveyA.survey, displaySurveyA.role)! >=
              getStatus(displaySurveyB.survey, displaySurveyB.role)!
                  ? 1
                  : -1
          )
        : displaySurveys.sort((displaySurveyA, displaySurveyB) =>
              getStatus(displaySurveyA.survey, displaySurveyA.role)! <=
              getStatus(displaySurveyB.survey, displaySurveyB.role)!
                  ? 1
                  : -1
          );
};
export const sortByTimeLeft = (
    ascending: boolean,
    displaySurveys: DisplaySurvey[]
) => {
    return ascending
        ? ascendingSortByTimeLeft(displaySurveys)
        : descendingSortByTimeLeft(displaySurveys);
};

export const ascendingSortByTimeLeft = (displaySurveys: DisplaySurvey[]) => {
    return displaySurveys.sort((displaySurveyA, displaySurveyB) => {
        const surveyA = displaySurveyA.survey as SurveyClasses;
        const surveyB = displaySurveyB.survey as SurveyClasses;

        let surveyATimeLeft: number = 0;
        let surveyBTimeLeft: number = 0;

        switch (surveyA.survey_class) {
            case "ONGOING":
                surveyATimeLeft = getOngoingSurveyTimeLeftSortBy(
                    surveyA as OngoingSurvey
                );
                break;
            case "SIMPLE":
            default:
                surveyATimeLeft = getSurveyTimeLeftSortBy(
                    surveyA as SimpleSurvey
                )!;
        }

        switch (surveyB.survey_class) {
            case "ONGOING":
                surveyBTimeLeft = getOngoingSurveyTimeLeftSortBy(
                    surveyB as OngoingSurvey
                );
                break;
            case "SIMPLE":
            default:
                surveyBTimeLeft = getSurveyTimeLeftSortBy(
                    surveyB as SimpleSurvey
                )!;
        }
        return surveyATimeLeft - surveyBTimeLeft;
    });
};

export const descendingSortByTimeLeft = (displaySurveys: DisplaySurvey[]) => {
    return displaySurveys.sort((displaySurveyA, displaySurveyB) => {
        const surveyA = displaySurveyA.survey as SurveyClasses;
        const surveyB = displaySurveyB.survey as SurveyClasses;

        let surveyATimeLeft: number = 0;
        let surveyBTimeLeft: number = 0;

        switch (surveyA.survey_class) {
            case "ONGOING":
                surveyATimeLeft = getOngoingSurveyTimeLeftSortBy(
                    surveyA as OngoingSurvey
                );
                break;
            case "SIMPLE":
            default:
                surveyATimeLeft = getSurveyTimeLeftSortBy(
                    surveyA as SimpleSurvey
                )!;
        }

        switch (surveyB.survey_class) {
            case "ONGOING":
                surveyBTimeLeft = getOngoingSurveyTimeLeftSortBy(
                    surveyB as OngoingSurvey
                );
                break;
            case "SIMPLE":
            default:
                surveyBTimeLeft = getSurveyTimeLeftSortBy(
                    surveyB as SimpleSurvey
                )!;
        }

        return surveyBTimeLeft - surveyATimeLeft;
    });
};

export const sortSurveysByProgress = (
    ascending: boolean,
    displaySurveys: DisplaySurvey[]
) => {
    const res = [
        ...displaySurveys.filter(
            (displaySurvey) => displaySurvey.role === "INITIATOR"
        ),
        ...displaySurveys.filter(
            (displaySurvey) => displaySurvey.role === "RESPONDER"
        ),
    ];
    return ascending
        ? res.sort(
              (displaySurveyA, displaySurveyB) =>
                  getSurveyProgressSortBy(displaySurveyA) -
                  getSurveyProgressSortBy(displaySurveyB)
          )
        : res.sort(
              (displaySurveyA, displaySurveyB) =>
                  getSurveyProgressSortBy(displaySurveyB) -
                  getSurveyProgressSortBy(displaySurveyA)
          );
};

export const sortSurveysByPurpose = (
    ascending: boolean,
    displaySurveys: DisplaySurvey[]
) => {
    return ascending
        ? displaySurveys.sort((displaySurveyA, displaySurveyB) =>
              displaySurveyA.survey.purpose?.toLowerCase()! >=
              displaySurveyB.survey.purpose?.toLowerCase()!
                  ? 1
                  : -1
          )
        : displaySurveys.sort((displaySurveyA, displaySurveyB) =>
              displaySurveyA.survey.purpose?.toLowerCase()! <=
              displaySurveyB.survey.purpose?.toLowerCase()!
                  ? 1
                  : -1
          );
};
