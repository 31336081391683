import { action } from "mobx";
import { v4 as uuid } from "uuid";

import {
    wizardStore,
    alertsStore,
    categoriesStore,
    wizardDisplayStore,
    categoriesDisplayStore,
} from "@difftone/stores";
import {
    BaseSurvey,
    SurveyEvent,
    Question,
    QuestionType,
    UUID,
    Label,
    RatingQuestion,
    AccordionTitles,
    SingleChoiceQuestion,
    SimpleSurvey,
    OngoingSurvey,
    SurveyClasses,
} from "@difftone/types";
import {
    fetchAllSurveyAlerts,
    duplicateSurveyEventAlerts,
    getNewEmptySurveyEvent,
    debounceUpsertSurveyToApi,
    upsertSurveyToMapAndApiWithoutDebounce,
    DEFAULT_CREATE_EVENT_TIME,
    setSurveyEventToDisplay,
    updateAlertTime,
    showDifftoneAlert,
    scheduleSendOutSurveyReminderEmail,
    scheduleOrUpdateSurveyEndedEmail,
    scheduleNewRecipientsEmail,
    scheduleOrUpdateCustomAlertEmail,
    cancelEmail,
} from "@difftone/actions";
import {
    getUserEmail,
    getUserFullName,
    getUserUuid,
} from "@difftone/procedures";
import {
    dateStrAndTimeStrToDateObject,
    getNormalizeOffsetInMiliseconds,
    getUtcDateAtMidnightInMils,
    getUTCDayTimeInMils,
    milisecondsToDateString,
    milisecondsToTimeString,
    totalTimeInMiliSecondsFromString,
    getAlertOffsetInMilliSeconds,
} from "@difftone/time-utils";
import {
    getSurveyFromStoreByURL,
    formatCategoryObject,
    getPublishedDate,
    getSelectedOrganizationOrThrow,
} from "@difftone/reducers";
import {
    getNewEmptyQuestion,
    NEW_RATING_QUESTION_CONTENT,
    NEW_SINGLE_CHOICE_QUESTION_CONTENT,
} from "@difftone/frontend-common";
import { constantAlertVariants } from "@difftone/constants";

export const setWizardAccordionActiveSection = action(
    (newAccordionTitle: AccordionTitles) => {
        wizardStore.activeAccordionSection = newAccordionTitle;
    }
);

export const setWasDifferentRatingScaleTooltipAlreadyShown = action(
    (value: boolean) => {
        wizardDisplayStore.wasDifferentRatingScaleTooltipAlreadyShown = value;
    }
);

export const setFocusedWizardInputCard = action(
    (newFocusedCard: HTMLElement | null) => {
        wizardStore.focusedWizardInputCard = newFocusedCard;
    }
);

export const setIsInitialQuestionsSectionScroll = action(
    (isInitialQuestionsSectionScroll: boolean) => {
        wizardDisplayStore.isInitialQuestionsSectionScroll =
            isInitialQuestionsSectionScroll;
    }
);

export const finalizeSurveyAlertBeforeSurveySent = action(
    async (survey?: SurveyClasses) => {
        const surveyToFinalize = survey || getSurveyFromStoreByURL();

        switch (surveyToFinalize.survey_class) {
            case "ONGOING":
                throw Error(
                    "[Difftone]:: Ongoing survey do not contain alerts."
                );
            case "SIMPLE":
            default:
                const simpleSurvey = surveyToFinalize as SimpleSurvey;
                return finalizeSimpleSurveyAlertBeforeSurveySent(simpleSurvey);
        }
    }
);

export const finalizeSurveyAlertChangesBeforeSave = action(
    (survey: SimpleSurvey) => {
        const deletedAlerts = wizardDisplayStore.getSurveyAlertsDeleted();
        deletedAlerts.forEach((alertUUID) => {
            const _deletedAlertCopy =
                wizardDisplayStore.getSurveyCopyAlertCopyByUuid(alertUUID);
            if (_deletedAlertCopy && _deletedAlertCopy.is_deleted) {
                cancelEmail(_deletedAlertCopy.email_uuid);
            }
        });
        survey.survey_events.forEach((surveyEvent) => {
            surveyEvent.event_alerts.forEach((alertUUID) => {
                const _alertCopy =
                    wizardDisplayStore.getSurveyCopyAlertCopyByUuid(alertUUID);
                if (_alertCopy && !_alertCopy.alert_type) {
                    const sendDate =
                        totalTimeInMiliSecondsFromString(
                            surveyEvent.date_utc!,
                            surveyEvent.day_utc_time!
                        ) + getAlertOffsetInMilliSeconds(_alertCopy);

                    scheduleOrUpdateCustomAlertEmail(
                        survey,
                        _alertCopy,
                        sendDate
                    );
                }
            });
        });
    }
);

const finalizeSimpleSurveyAlertBeforeSurveySent = action(
    async (survey: SimpleSurvey) => {
        const savingEventAlertPromisees = survey.survey_events.map(
            (surveyEvent) => {
                const savingAlertPromisees = surveyEvent.event_alerts.map(
                    async (alertUUID) => {
                        const _alertCopy =
                            await alertsStore.asyncGetSurveyAlertCopy(
                                alertUUID
                            );

                        if (_alertCopy.alert_type === "PUBLISHED_DATE_ALERT") {
                            _alertCopy.send_to = survey.respondents;
                            _alertCopy.content.message =
                                survey.welcome_message || "";
                        }
                        if (
                            _alertCopy.alert_type === "ACTIVATION_ALERT" ||
                            _alertCopy.alert_type ===
                                "SURVEY_ENDED_NOTIFICATION_ALERT"
                        ) {
                            _alertCopy.send_to = [getUserEmail()];
                        }

                        if (!_alertCopy.alert_type) {
                            const sendDate =
                                totalTimeInMiliSecondsFromString(
                                    surveyEvent.date_utc!,
                                    surveyEvent.day_utc_time!
                                ) + getAlertOffsetInMilliSeconds(_alertCopy);

                            scheduleOrUpdateCustomAlertEmail(
                                survey,
                                _alertCopy,
                                sendDate
                            );
                        }

                        _alertCopy.alert_status = "NEWLY_CREATED";
                        _alertCopy.approvers = [getUserEmail()];
                        _alertCopy.content.initiator_name = getUserFullName();
                        _alertCopy.content.initiator_email = getUserEmail();
                        _alertCopy.content.survey_name = survey.survey_name;
                        _alertCopy.event_UUID = surveyEvent.uuid;
                        return alertsStore.InsertAlertToStoreWithoutDebounce(
                            _alertCopy
                        );
                    }
                );
                return Promise.all(savingAlertPromisees);
            }
        );
        return Promise.all(savingEventAlertPromisees);
    }
);

export const setVisibilityOfWizardCalendar = action((uuid: UUID | null) => {
    wizardStore.wizardFocusedCalendar = uuid;
});

export const applyCategoryToQuestion = (
    question: Question<QuestionType>,
    category: string | UUID
) => {
    const surveyInWizard: BaseSurvey = JSON.parse(
        JSON.stringify(getSurveyFromStoreByURL())
    );

    const questionToUpdate: Question<QuestionType> | undefined =
        surveyInWizard.questions.find(
            (_question) => _question.uuid === question.uuid
        );

    if (!questionToUpdate) {
        throw Error("[updateQuestionInWizard]:: Somthing went wrong!");
    }

    if (category) {
        questionToUpdate.categories = [category];
    } else {
        questionToUpdate.categories = [];
    }

    debounceUpsertSurveyToApi("questions", surveyInWizard.questions);
};

export const finalizeCategoriesAtNewSurvey = action((survey: SurveyClasses) => {
    const userUUID = getUserUuid();

    const existingCategories = categoriesStore.getCategoriesArray();
    const organization = getSelectedOrganizationOrThrow();
    const organisationUuid = organization ? organization.uuid : null;

    const categories = survey.questions.flatMap(
        (question) => question.categories
    );

    const uniqueCategories = new Set(categories);
    const existingCategoriesUUIDs = existingCategories.map(({ uuid }) => uuid);
    const newCategoriesNames = Array.from(uniqueCategories).filter(
        (category) => !existingCategoriesUUIDs.includes(category)
    );
    const newCategories: Label[] = newCategoriesNames
        .map((newCategoryName) => {
            const category =
                categoriesDisplayStore.getCategoryByDisplayName(
                    newCategoryName
                );

            return category
                ? formatCategoryObject(
                      organisationUuid,
                      userUUID,
                      category.display_name
                  )
                : false;
        })
        .filter((category) => category) as Label[];

    newCategories.forEach((newCategory) => {
        categoriesStore.addCategoryToAPIWithoutDebounce(newCategory);
    });

    newCategories.forEach((category) => {
        survey.questions.forEach((question) => {
            question.categories.forEach((questionCategory, index) => {
                if (questionCategory === category.display_name) {
                    question.categories[index] = category.uuid;
                }
            });
        });
    });
    categoriesDisplayStore.clearCustomCategories();

    upsertSurveyToMapAndApiWithoutDebounce(survey);
});

export const setRatingQuestionAutoScaleRange = action(
    (autoScaleRange: number) => {
        wizardStore.ratingQuestionAutoScaleRange = autoScaleRange;
    }
);

export const updateQuestionInWizard = (
    question: Question<QuestionType>,
    key: keyof Question<QuestionType>,
    value: ValueOf<Question<QuestionType>>
) => {
    const surveyInWizard: BaseSurvey = JSON.parse(
        JSON.stringify(getSurveyFromStoreByURL())
    );

    const questionToUpdate: Question<QuestionType> | undefined =
        surveyInWizard.questions.find(
            (_question) => _question.uuid === question.uuid
        );

    if (!questionToUpdate) {
        throw Error("[updateQuestionInWizard]:: Something went wrong!");
    }

    questionToUpdate[key] = value as never;

    if (questionToUpdate.question_type_name !== "SINGLE_CHOICE") {
        questionToUpdate.is_filter = false;
    }

    debounceUpsertSurveyToApi("questions", surveyInWizard.questions);
};

export const addNewEmptyQuestionToWizard = action(
    (autoScaleRange?: number | null, targetQuestionIndex?: number | null) => {
        const survey = getSurveyFromStoreByURL();

        const questionNumber = targetQuestionIndex
            ? targetQuestionIndex
            : survey.questions.length + 1;

        const newQuestion = getNewEmptyQuestion(
            questionNumber,
            survey,
            autoScaleRange
        );
        const newQuestionList = [
            ...survey.questions.slice(0, questionNumber),
            newQuestion,
            ...survey.questions.slice(questionNumber),
        ];
        survey.questions = newQuestionList;

        _resetQuestionsNumbers();
    }
);

export const replaceQuestionsOrderByIndexes = action(
    (sourceIndex: number, destinationIndex: number) => {
        const survey = getSurveyFromStoreByURL();
        const questionsList = Array.from(survey.questions);
        const [reorderedQuestion] = questionsList.splice(sourceIndex, 1);
        questionsList.splice(destinationIndex, 0, reorderedQuestion);

        survey.questions = questionsList;

        _resetQuestionsNumbers();
    }
);

export const sortSurveyEventsByEventNumber = action(() => {
    const survey = getSurveyFromStoreByURL();

    switch (survey.survey_class) {
        case "ONGOING":
            throw Error(
                "[Difftone]:: Ongoing survey does not include survey events."
            );
        case "SIMPLE":
        default:
            const simpleSurvey = survey as SimpleSurvey;
            return sortSimpleSurveyEventsByEventNumber(simpleSurvey);
    }
});

const sortSimpleSurveyEventsByEventNumber = action((survey: SimpleSurvey) => {
    survey.survey_events = survey.survey_events?.sort(
        (a, b) => a.event_number! - b.event_number!
    );
});

export const resetEventNumbers = action(() => {
    const survey = getSurveyFromStoreByURL();

    switch (survey.survey_class) {
        case "ONGOING":
            throw Error("[Difftone]:: Ongoing survey does not include events.");
        case "SIMPLE":
        default:
            const simpleSurvey = survey as SimpleSurvey;
            return resetSimpleSurveyEventNumbers(simpleSurvey);
    }
});

const resetSimpleSurveyEventNumbers = action((survey: SimpleSurvey) => {
    const eventsList = Array.from(survey.survey_events!);
    eventsList.forEach((event, index) => {
        event.event_number = index;
    });

    survey.survey_events = eventsList;
});

export const toggleWizardQuestionIsRequired = action(
    (questionToUpdate: Question<QuestionType>) => {
        const selectedQuestion = getSurveyFromStoreByURL().questions.find(
            (surveyQuestion) => questionToUpdate.uuid === surveyQuestion.uuid
        );

        if (!selectedQuestion) {
            throw Error(
                "[toggleWizardQuestionIsRequired]:: Something went wrong!"
            );
        }

        selectedQuestion.required = !selectedQuestion.required;

        if (!selectedQuestion.required && selectedQuestion.is_filter) {
            selectedQuestion.is_filter = false;
        }

        debounceUpsertSurveyToApi();
    }
);

export const toggleWizardQuestionSetAsFilter = action(
    (questionToUpdate: Question<QuestionType>) => {
        const selectedQuestion = getSurveyFromStoreByURL().questions.find(
            (surveyQuestion) => questionToUpdate.uuid === surveyQuestion.uuid
        );

        if (!selectedQuestion) {
            throw Error(
                "[toggleWizardQuestionSetAsFilter]:: Something went wrong!"
            );
        }
        selectedQuestion.is_filter = !selectedQuestion.is_filter;

        const newSingleChoiceContent: SingleChoiceQuestion = JSON.parse(
            JSON.stringify(NEW_SINGLE_CHOICE_QUESTION_CONTENT)
        );

        if (selectedQuestion.is_filter) {
            if (questionToUpdate.question_type_name === "SINGLE_CHOICE") {
                selectedQuestion.content = questionToUpdate.content;
            } else {
                selectedQuestion.question_type_name = "SINGLE_CHOICE";
                selectedQuestion.content = newSingleChoiceContent;
            }

            selectedQuestion.categories = [];
            selectedQuestion.required = true;
        }

        debounceUpsertSurveyToApi();
    }
);

export const deleteQuestionFromWizard = action(
    (questionToDelete: Question<QuestionType>) => {
        const survey = getSurveyFromStoreByURL();

        survey.questions = survey.questions!.filter(
            (question) => question.uuid !== questionToDelete.uuid
        );

        _resetQuestionsNumbers();
    }
);

export const resetQuestionRatingContent = (
    ratingObject: Question<RatingQuestion>
) => {
    const survey: BaseSurvey = JSON.parse(
        JSON.stringify(getSurveyFromStoreByURL())
    );

    const emptyRatingContent: RatingQuestion = JSON.parse(
        JSON.stringify(NEW_RATING_QUESTION_CONTENT)
    );

    const selectedQuestion: Question<QuestionType> | undefined =
        survey.questions.find(
            (question) => question.uuid === ratingObject.uuid
        );

    if (!selectedQuestion) {
        throw Error("[resetQuestionRatingContent]:: Something went wrong");
    }

    selectedQuestion.content = emptyRatingContent;
    debounceUpsertSurveyToApi("questions", survey.questions);
};

export const duplicateQuestionInWizard = action(
    (questionToDuplicate: Question<QuestionType>) => {
        const survey = getSurveyFromStoreByURL();

        const indexToCopy = survey.questions?.findIndex(
            (question) => question.uuid === questionToDuplicate.uuid
        );
        const copyOfQuestion = JSON.parse(
            JSON.stringify(survey.questions![indexToCopy!])
        );
        copyOfQuestion.uuid = uuid();
        survey.questions?.splice(indexToCopy! + 1, 0, copyOfQuestion);

        _resetQuestionsNumbers();
    }
);

export const setSurveyNameFromDefaultSurveyNameIfNotExist = action(() => {
    let survey = getSurveyFromStoreByURL();

    if (!survey.survey_name) {
        const copyOfSurvey: SurveyClasses = JSON.parse(JSON.stringify(survey));
        copyOfSurvey.survey_name = copyOfSurvey.default_survey_name;

        survey = copyOfSurvey;
    }
});

export const duplicateEventInWizard = action(
    (eventToDuplicate: SurveyEvent) => {
        const survey = getSurveyFromStoreByURL();
        switch (survey.survey_class) {
            case "ONGOING":
                throw Error(
                    "[Difftone]:: Ongoing survey doesn't have survey events."
                );
            case "SIMPLE":
            default:
                const simpleSurvey = survey as SimpleSurvey;
                return duplicateSimpleSurveyEventInWizard(
                    simpleSurvey,
                    eventToDuplicate
                );
        }
    }
);

const duplicateSimpleSurveyEventInWizard = action(
    (survey: SimpleSurvey, eventToDuplicate: SurveyEvent) => {
        survey.survey_events = survey.survey_events.sort(
            (eventA, eventB) => eventA.event_number! - eventB.event_number!
        );

        const indexToCopy = survey.survey_events?.findIndex(
            (_surveyEvent) => _surveyEvent.uuid === eventToDuplicate.uuid
        );
        const copyOfEvent: SurveyEvent = JSON.parse(
            JSON.stringify(survey.survey_events![indexToCopy!])
        );
        copyOfEvent.uuid = uuid();
        copyOfEvent.event_number = copyOfEvent.event_number! + 1;

        fetchAllSurveyAlerts(survey).then((allEventAlerts) => {
            if (allEventAlerts.includes(null)) {
                console.error(
                    "[duplicateEventInWizard]:: could not find event alerts",
                    allEventAlerts
                );
                throw Error(
                    "[duplicateEventInWizard]:: could not find event alerts"
                );
            }

            copyOfEvent.event_alerts = duplicateSurveyEventAlerts(
                //@ts-ignore
                allEventAlerts,
                copyOfEvent.event_alerts,
                copyOfEvent,
                survey.survey_name,
                survey.uuid
            );
            survey.survey_events.splice(indexToCopy! + 1, 0, copyOfEvent);

            const eventsList = Array.from(survey.survey_events!);
            eventsList.forEach((event, index) => {
                event.event_number = index;
            });

            survey.survey_events = eventsList;
        });
    }
);

export const addWizardSurveyEvent = action((surveyEvent: SurveyEvent) => {
    const survey = getSurveyFromStoreByURL();
    switch (survey.survey_class) {
        case "ONGOING":
            throw Error(
                "[Difftone]:: Ongoing survey doesn't have events to add."
            );
        case "SIMPLE":
        default:
            const simpleSurvey = survey as SimpleSurvey;
            return addWizardSimpleSurveyEvent(simpleSurvey, surveyEvent);
    }
});

const addWizardSimpleSurveyEvent = action(
    (survey: SimpleSurvey, surveyEvent: SurveyEvent) => {
        survey.survey_events!.push(surveyEvent);
    }
);

export const addEmptyWizardSurveyEvent = action(() => {
    const survey = getSurveyFromStoreByURL();
    switch (survey.survey_class) {
        case "ONGOING":
            throw Error(
                "[Difftone]:: Ongoing survey doesn't have events to add."
            );
        case "SIMPLE":
        default:
            const simpleSurvey = survey as SimpleSurvey;
            return addEmptyWizardSimpleSurveyEvent(simpleSurvey);
    }
});

const addEmptyWizardSimpleSurveyEvent = action((survey: SimpleSurvey) => {
    const eventNumber = survey.survey_events.length;
    const surveyEvent = getNewEmptySurveyEvent(eventNumber || 0);

    const dateUtcString = survey
        ? getPublishedDate(survey)!
        : getUtcDateAtMidnightInMils();

    surveyEvent.date_utc = milisecondsToDateString(dateUtcString);

    const { dayUtcTimeStr } = dateStrAndTimeStrToDateObject(
        milisecondsToDateString(getUtcDateAtMidnightInMils()),
        DEFAULT_CREATE_EVENT_TIME
    );
    surveyEvent.day_utc_time = dayUtcTimeStr;

    survey.survey_events.push(surveyEvent);
    setSurveyEventToDisplay(surveyEvent.uuid);
    return surveyEvent.uuid;
});

export const deleteWizardSurveyEventWithReset = action(
    (surveyEventToDelete: SurveyEvent) => {
        const survey = getSurveyFromStoreByURL();
        switch (survey.survey_class) {
            case "ONGOING":
                throw Error(
                    "[Difftone]:: Ongoing survey doesn't have events to reset."
                );
            case "SIMPLE":
            default:
                const simpleSurvey = survey as SimpleSurvey;
                return deleteWizardSimpleSurveyEventWithReset(
                    simpleSurvey,
                    surveyEventToDelete
                );
        }
    }
);

const deleteWizardSimpleSurveyEventWithReset = action(
    (survey: SimpleSurvey, surveyEventToDelete: SurveyEvent) => {
        survey.survey_events = survey.survey_events!.filter(
            (surveyEvent) => surveyEvent.uuid !== surveyEventToDelete.uuid
        );

        sortSurveyEventsByEventNumber();
        resetEventNumbers();
    }
);

export const deleteToUpdateWizardSurveyEventWithoutReset = action(
    (surveyEventToDelete: SurveyEvent) => {
        const survey = getSurveyFromStoreByURL();

        switch (survey.survey_class) {
            case "ONGOING":
                throw Error(
                    "[Difftone]:: Ongoing survey doesn't have events to delete."
                );
            case "SIMPLE":
            default:
                const simpleSurvey = survey as SimpleSurvey;
                return deleteToUpdateWizardSimpleSurveyEventWithoutReset(
                    simpleSurvey,
                    surveyEventToDelete
                );
        }
    }
);

const deleteToUpdateWizardSimpleSurveyEventWithoutReset = action(
    (survey: SimpleSurvey, surveyEventToDelete: SurveyEvent) => {
        survey.survey_events = survey.survey_events.filter(
            (surveyEvent) => surveyEvent.uuid !== surveyEventToDelete.uuid
        );
    }
);

export const finalizeOngoingSurvey = action((survey: OngoingSurvey) => {
    finalizeCategoriesAtNewSurvey(survey);
    //TODO: can be removed after creating a generic draft survey https://app.asana.com/0/1201346022025578/1202876528794835/f
    delete (survey as any).survey_events;
    survey.respondents = [];
    upsertSurveyToMapAndApiWithoutDebounce(survey);
    wizardStore.finalizeSurveyTitlesToAutocompleteApi(survey);
});

export const scheduleSurvey = action(
    async (survey: SimpleSurvey, isImmediateActivation?: boolean) => {
        !isImmediateActivation && scheduleSendOutSurveyReminderEmail(survey);
        scheduleOrUpdateSurveyEndedEmail(survey);
        finalizeCategoriesAtNewSurvey(survey);
        await finalizeSurveyAlertBeforeSurveySent();
        wizardStore.finalizeSurveyTitlesToAutocompleteApi(survey);
    }
);

export const activateSurveyImmediately = action(
    async (survey: SimpleSurvey) => {
        await scheduleSurvey(survey, true);
        await sendSurvey(survey);
    }
);

export const sendSurvey = action(async (survey: BaseSurvey) => {
    switch (survey.survey_class) {
        case "ONGOING":
            const ongoingSurvey = survey as OngoingSurvey;
            return activateOngoingSurvey(ongoingSurvey);
        case "SIMPLE":
        default:
            const simpleSurvey = survey as SimpleSurvey;
            return sendSimpleSurvey(simpleSurvey);
    }
});

const activateOngoingSurvey = action((survey: OngoingSurvey) => {
    survey.status = "ACTIVE";
    upsertSurveyToMapAndApiWithoutDebounce(survey);
});

const sendSimpleSurvey = action(async (survey: SimpleSurvey) => {
    const offsetInMils = getNormalizeOffsetInMiliseconds();
    const dateUtc = getUtcDateAtMidnightInMils();
    const dayUtcTime = getUTCDayTimeInMils();

    const publishDateEvent = survey.survey_events.find(
        (event) => event.system_survey_event_type === "PUBLISHED_DATE"
    );

    const alertsPromises = survey
        .survey_events!.find(
            (event) => event.system_survey_event_type === "PUBLISHED_DATE"
        )!
        .event_alerts.map(async (alertUUID) =>
            alertsStore.getSurveyAlertCopyByUuid(alertUUID)
        );

    const alerts = await Promise.all(alertsPromises);
    const publishedDateAlert = alerts.find(
        (alert) => alert?.alert_type === "PUBLISHED_DATE_ALERT"
    );

    const activationAlert = alerts.find(
        (alert) => alert?.alert_type === "ACTIVATION_ALERT"
    );

    if (publishedDateAlert && activationAlert && publishDateEvent) {
        publishDateEvent.utc_offset = offsetInMils;
        publishDateEvent.date_utc = milisecondsToDateString(dateUtc);
        publishDateEvent.day_utc_time = milisecondsToTimeString(dayUtcTime);

        updateAlertTime(publishedDateAlert);

        survey.status = "ACTIVE";

        scheduleNewRecipientsEmail(survey);

        upsertSurveyToMapAndApiWithoutDebounce(survey);
    } else {
        showDifftoneAlert(
            constantAlertVariants.activation_publish_alert.text,
            constantAlertVariants.activation_publish_alert.variant
        );
    }
});

// Helper Functions

const _resetQuestionsNumbers = () => {
    getSurveyFromStoreByURL().questions.forEach((question, index) => {
        question.number = index + 1;
    });
};

export const setActiveMailingList = action(
    (list: "PARTICIPANTS" | "ADMINS") => {
        wizardDisplayStore.activeMailingList = list;
    }
);
