import React from "react";
import { observer } from "mobx-react";

import {
    Header,
    LeftMenu,
    MobileHeader,
    ClientWidthRenderPicker,
    ResultsLeftMenuChildren,
} from "@difftone/shared-components";
import {
    NO_MOBILE_PLACEHOLDER_TEXT,
    PAGE_URLS,
    RESULTS_DISPLAY_TYPE,
    warningMessages,
} from "@difftone/constants";
import {
    organizationStore,
    sharedResultsDisplayStore,
    navigationStore,
} from "@difftone/stores";

import {
    setLocation,
    setSelectedTimeCompareSharedResultsFilterAndTimeUnits,
    showDifftoneAlert,
    sharedResultsTurnOffFilterAsCompare,
    sharedResultsTurnOnFilterAsCompare,
    switchSharedResultsActiveCompareIndex,
} from "@difftone/actions";
import {
    getOrganizations,
    getSelectedOrganizationUUID,
} from "@difftone/reducers";

import {
    LeftMenuChildrenForSharedResultsGraph,
    ResultsDisplayCard,
} from "./components";

import {
    results,
    results_body,
    mobile_wrapper_notice,
} from "./complicated-survey-shared-results.module.css";

export type ComplicatedSurveyResultsProps = {};

export const ComplicatedSurveySharedResults = observer(
    (props: ComplicatedSurveyResultsProps) => {
        const {
            getActiveAsComparesFiltersUUIDs,
            getSelectedFilters,
            selectedSurvey,
            getActiveFilters,
            setActiveFilters,
            getSelectedSharedResultsTimeCompare,
            getPopupDisplaySharedResultsTimeCompare,
            setPopupDisplaySharedResultsTimeCompare,
        } = sharedResultsDisplayStore;

        const { currentPage } = navigationStore;

        const selectedOrganization = getSelectedOrganizationUUID();
        const organizationsList = getOrganizations();

        let _organization;

        if (selectedOrganization) {
            _organization =
                organizationStore.getOrganizationByUuid(selectedOrganization);
        }

        if (!selectedSurvey) {
            showDifftoneAlert(warningMessages.surveyNotFound, "FAILURE");
            setLocation(PAGE_URLS.INBOX_ALL);
            return null;
        }

        //TODO:: make create route component
        const resultsPage = `/${currentPage.split("/").at(-1)}`;
        let resultsPageCompereByComponent;

        switch (resultsPage) {
            case RESULTS_DISPLAY_TYPE.LINE:
                resultsPageCompereByComponent = (
                    <LeftMenuChildrenForSharedResultsGraph
                        activeAsComparesFiltersUUIDs={getActiveAsComparesFiltersUUIDs()}
                        selectedFilters={getSelectedFilters()}
                        selectedSurvey={selectedSurvey}
                        getActiveFilters={getActiveFilters}
                        setActiveFilters={setActiveFilters}
                        selectedResultsTimeCompare={getSelectedSharedResultsTimeCompare()}
                        selectedPopupDisplayResultsTimeCompare={getPopupDisplaySharedResultsTimeCompare()}
                        setPopupDisplayResultsTimeCompare={
                            setPopupDisplaySharedResultsTimeCompare
                        }
                        setSelectedTimeCompareResultsFilterAndTimeUnits={
                            setSelectedTimeCompareSharedResultsFilterAndTimeUnits
                        }
                        turnOffResultsFilterAsCompare={
                            sharedResultsTurnOffFilterAsCompare
                        }
                        turnOnResultsFilterAsCompare={
                            sharedResultsTurnOnFilterAsCompare
                        }
                        switchResultsFilterAsCompare={
                            switchSharedResultsActiveCompareIndex
                        }
                    />
                );
                break;
            case RESULTS_DISPLAY_TYPE.GRID:
            case RESULTS_DISPLAY_TYPE.FAVORABLE:
            default:
                resultsPageCompereByComponent = (
                    <ResultsLeftMenuChildren
                        activeAsComparesFiltersUUIDs={getActiveAsComparesFiltersUUIDs()}
                        selectedFilters={getSelectedFilters()}
                        selectedSurvey={selectedSurvey}
                        getActiveFilters={getActiveFilters}
                        setActiveFilters={setActiveFilters}
                        selectedResultsTimeCompare={getSelectedSharedResultsTimeCompare()}
                        selectedPopupDisplayResultsTimeCompare={getPopupDisplaySharedResultsTimeCompare()}
                        setPopupDisplayResultsTimeCompare={
                            setPopupDisplaySharedResultsTimeCompare
                        }
                        setSelectedTimeCompareResultsFilterAndTimeUnits={
                            setSelectedTimeCompareSharedResultsFilterAndTimeUnits
                        }
                    />
                );
        }

        return (
            <div className={results}>
                <ClientWidthRenderPicker
                    desktopComponent={
                        <Header
                            organization={_organization}
                            organizationsList={organizationsList}
                        />
                    }
                    mobileComponent={<MobileHeader />}
                />
                <div className={results_body}>
                    <LeftMenu isHiddenOnMobile>
                        {resultsPageCompereByComponent}
                    </LeftMenu>
                    <ClientWidthRenderPicker
                        desktopComponent={<ResultsDisplayCard />}
                        mobileComponent={
                            <p className={mobile_wrapper_notice}>
                                {NO_MOBILE_PLACEHOLDER_TEXT}
                            </p>
                        }
                    />
                </div>
            </div>
        );
    }
);
