import React from "react";
import { observer } from "mobx-react";
import * as d3 from "d3";
import { ScaleLinear } from "d3";

import { GraphSelectedLegend, GraphLineItem } from "@difftone/types";

export type TrendLineProps = {
    key: string;
    nameLine: string;
    data: GraphLineItem[];
    yScale: ScaleLinear<number, number>;
    xScale: ScaleLinear<number, number>;
    color: string;
    onMouseMove: (event: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
    onMouseLeave: () => void;
    onMouseOut: () => void;
    selectedLegendMap: {
        [key: string]: GraphSelectedLegend;
    };
};

const STROKE_WIDTH = 1.5;
const COLOR_GRAY = "gray";

export const TrendLine = observer((props: TrendLineProps) => {
    const {
        key,
        nameLine,
        data,
        xScale,
        yScale,
        color,
        onMouseMove,
        onMouseLeave,
        onMouseOut,
        selectedLegendMap,
    } = props;

    const leastSquaresequation = (xAxisData: number[], yAxisdata: number[]) => {
        const reduceAddition = (prev: number, cur: number) => {
            return prev + cur;
        };

        // finding the mean of Xaxis data
        const xBar =
            (xAxisData.reduce(reduceAddition) * 1.0) / xAxisData.length;

        // finding the mean of Yxis data
        const yBar =
            (yAxisdata.reduce(reduceAddition) * 1.0) / yAxisdata.length;

        const squareXX = xAxisData
            .map((d) => Math.pow(d - xBar, 2))
            .reduce(reduceAddition);

        const meanDiffXY = xAxisData
            .map((d, i) => (d - xBar) * (yAxisdata[i] - yBar))
            .reduce(reduceAddition);

        const slope = meanDiffXY / squareXX;

        const intercept = yBar - xBar * slope;

        // returning regression function
        return (x: number) => x * slope + intercept;
    };

    const xAxisData = data.map((d: GraphLineItem) => +d.xTrendLine);
    const yAxisData = data.map((d: GraphLineItem) => +d.axisLeft);

    const regression = leastSquaresequation(xAxisData, yAxisData);

    const line: any = d3
        .line()
        .x((d: any) => xScale(d.xTrendLine))
        .y((d: any) => yScale(regression(+d.axisLeft)));

    const createPath = line(data);

    return (
        selectedLegendMap[nameLine] && (
            <g
                key={key}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
                onMouseOut={onMouseOut}
                style={{
                    display: selectedLegendMap[nameLine].line
                        ? "block"
                        : "none",
                }}
            >
                <path
                    fill="none"
                    stroke={color || COLOR_GRAY}
                    strokeWidth={STROKE_WIDTH}
                    d={createPath || ""}
                />
            </g>
        )
    );
});
