import React from "react";
import { observer } from "mobx-react";
import {
    PAGE_URLS,
    RESULTS_DISPLAY_TYPE,
    warningMessages,
} from "@difftone/constants";
import { ResultsCardHeader, AboutSurvey } from "@difftone/shared-components";

import { setLocation, showDifftoneAlert } from "@difftone/actions";

import {
    ResultsSpreadsheet,
    SharedResultsFavorableSpreadsheet,
    SharedResultsGraphSpreadsheet,
} from "./components";
import { navigationStore, sharedResultsDisplayStore } from "@difftone/stores";

import {
    results_display_card,
    card_div,
} from "./results-display-card.module.css";
export type ResultDisplayCardProps = {};

export const ResultsDisplayCard = observer(() => {
    const { selectedSurvey } = sharedResultsDisplayStore;
    const { currentPage } = navigationStore;

    if (selectedSurvey === null) {
        setLocation(PAGE_URLS.INBOX_ALL);
        showDifftoneAlert(warningMessages.surveyNotFound, "FAILURE");
        return null;
    }

    const resultsDisplayType = () => {
        switch (currentPage) {
            case `${
                currentPage.includes(RESULTS_DISPLAY_TYPE.GRID)
                    ? currentPage
                    : null
            }`:
                return <ResultsSpreadsheet />;
            case `${
                currentPage.includes(RESULTS_DISPLAY_TYPE.FAVORABLE)
                    ? currentPage
                    : null
            }`:
                return <SharedResultsFavorableSpreadsheet />;
            case `${
                currentPage.includes(RESULTS_DISPLAY_TYPE.LINE)
                    ? currentPage
                    : null
            }`:
                return <SharedResultsGraphSpreadsheet />;
            default:
                return <ResultsSpreadsheet />;
        }
    };

    return (
        <div className={results_display_card}>
            <div className={card_div}>
                <ResultsCardHeader
                    surveyName={selectedSurvey!.survey_name}
                ></ResultsCardHeader>
                <AboutSurvey survey={selectedSurvey!} />
                {resultsDisplayType()}
            </div>
        </div>
    );
});
