import React, { useState } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { ResultsFilter, TimeCompareUnits } from "@difftone/types";
import compareSwitchIcon from "@difftone/assets/results-compare-switch-icon.svg";
import { timeCompareFiltersKeyValues } from "@difftone/constants";

import {
    option_wrapper,
    compare_option,
    active_class,
    compare_text,
    active_compare_text,
    compare_option_hover,
    enabled_compare_option,
    disabled_compare_option,
    disabled_compare_text,
} from "./time-compare-item.module.css";

export type TimeCompareItemProps = {
    isSelected: boolean;
    timeFilterCompare: ResultsFilter;
    switchComparesPositions: () => void;
    openPopupHandler: () => void;
    selectedResultsTimeCompare: TimeCompareUnits;
    isDisabled: boolean;
};

export const TimeCompareItem = observer((props: TimeCompareItemProps) => {
    const {
        isSelected,
        timeFilterCompare,
        switchComparesPositions,
        openPopupHandler,
        selectedResultsTimeCompare,
        isDisabled,
    } = props;

    const [isHovered, setIsHovered] = useState(false);

    const openTimeComparePopupHandler = () => {
        if (isDisabled) {
            return;
        }
        openPopupHandler();
    };

    const onHoverHandler = () => {
        setIsHovered(true);
    };

    const onLeaveHandler = () => {
        setIsHovered(false);
    };

    const timeCompareTextDisplay = isDisabled
        ? "Disabled"
        : `${timeFilterCompare.filterKey.nickname}: ${timeCompareFiltersKeyValues[selectedResultsTimeCompare]}`;

    return (
        <div
            className={option_wrapper}
            data-time-compare={timeFilterCompare.filterKey.uuid}
        >
            <div
                onMouseEnter={onHoverHandler}
                onMouseLeave={onLeaveHandler}
                onClick={openTimeComparePopupHandler}
                className={clsx(compare_option, {
                    [enabled_compare_option]: !isDisabled,
                    [disabled_compare_option]: isDisabled,
                    [active_class]: !isDisabled && isSelected,
                    [compare_option_hover]: !isDisabled && isHovered,
                })}
            >
                <div
                    className={clsx(compare_text, {
                        [active_compare_text]: !isDisabled && isSelected,
                        [disabled_compare_text]: isDisabled,
                    })}
                >
                    {isSelected
                        ? `${timeFilterCompare?.indexInComparePlain}. `
                        : null}
                    {timeCompareTextDisplay}
                </div>
            </div>
            {isSelected ? (
                <img
                    onClick={switchComparesPositions}
                    src={compareSwitchIcon}
                    alt="compare-icon"
                />
            ) : null}
        </div>
    );
});
