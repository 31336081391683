import { getPublicSurveyResponsesFromApi } from "@difftone/services";
import { ResultsFilter, SurveyResponsesSummery, UUID } from "@difftone/types";
import { action } from "mobx";
import { objectsAreEqual } from "@difftone/frontend-common";
import { surveyResponsesSummaryStore } from "./survey-responses-summary-store";
import md5 from "md5";

export const getSurveyResponsesSummaryBySurveyUUID = async (
    surveyUUID: UUID,
    currentFilters: ResultsFilter[]
) => {
    const currentFilterKey = md5(
        JSON.stringify(`${currentFilters}-${surveyUUID}`)
    );
    const surveyResponseSummary: SurveyResponsesSummery =
        await getPublicSurveyResponsesFromApi(surveyUUID, currentFilters);

    const surveyResponsesFromStore =
        surveyResponsesSummaryStore.getSurveyResponsesSummaryByKey(
            currentFilterKey
        );

    //NEED TO CHECK !
    if (!objectsAreEqual(surveyResponsesFromStore!, surveyResponseSummary)) {
        surveyResponsesSummaryStore.setSurveyResponseSummaryToMap(
            currentFilterKey,
            surveyResponseSummary
        );
    }
};
