import { observer } from "mobx-react";
import React from "react";
import { BaseSurvey, SimpleResultsMap } from "@difftone/types";

import {
    RespondersHeader,
    QuestionOptionsAsHeaders,
    RespondersAndAnswers,
} from "./components";

import {
    questions_and_responses,
    static_page_content,
} from "./questions-and-responses.module.css";

export type QuestionsAndResponsesProps = {
    survey: BaseSurvey;
    questionToRespondersAnswerMap: SimpleResultsMap;
};

export const QuestionsAndResponses = observer(
    (props: QuestionsAndResponsesProps) => {
        const { survey, questionToRespondersAnswerMap } = props;

        return (
            <div className={questions_and_responses}>
                <div className={static_page_content}>
                    <RespondersHeader />
                    <QuestionOptionsAsHeaders
                        responses={questionToRespondersAnswerMap}
                        survey={survey}
                    />
                </div>
                <RespondersAndAnswers
                    responses={questionToRespondersAnswerMap}
                    survey={survey}
                />
            </div>
        );
    }
);
