import {
    getUserUuid,
    getUserEmail,
    getUserFullName,
    environment,
} from "@difftone/procedures";
import { getUserEmailType } from "@difftone/reducers";

export const segmentUserIdentify = () => {
    //'analytics' variable defined by script loaded in html file.
    //@ts-ignore
    analytics.identify(getUserUuid(), {
        name: getUserFullName(),
        email: getUserEmail(),
        userType: getUserEmailType(),
    });
};

export const sendEvent = (eventType: string, event?: any) => {
    //'analytics' variable defined by script loaded in html file.
    //@ts-ignore
    analytics.track(eventType, {
        userId: getUserUuid(),
        title: eventType,
        env: environment(),
        properties: event,
    });
};
