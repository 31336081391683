import React from "react";
import { BaseSurvey } from "@difftone/types";
import { publicProfileStore } from "@difftone/stores";

import {
    survey_left_menu_header,
    survey_name,
    survey_initiator,
} from "./survey-left-menu-header.module.css";

export type SurveyLeftMenuHeaderProps = { survey: BaseSurvey };

export const SurveyLeftMenuHeader = (props: SurveyLeftMenuHeaderProps) => {
    const { survey } = props;

    const initiatorsPublicProfile = publicProfileStore.getPublicProfileByUuid(
        survey.initiator!
    );
    return (
        <div className={survey_left_menu_header}>
            <div className={survey_name}>
                {survey.survey_name || survey.default_survey_name}
            </div>
            <div className={survey_initiator}>
                {initiatorsPublicProfile?.name || ""}
            </div>
        </div>
    );
};
