import React from "react";

import { sharePanelStore } from "@difftone/stores";
import { addLinkToClipboard } from "@difftone/procedures";

import whatsappIcon from "@difftone/assets/ext-share-icon-whatsapp.svg";
import slackIcon from "@difftone/assets/ext-share-icon-slack.svg";
import { OPEN_SURVEY, getWebClientBaseUrl } from "@difftone/constants";
import { Toast } from "@difftone/shared-components";

import {
    panel_container,
    panel_title,
    share_options_list,
    share_option,
} from "./external-share-panel.module.css";

export type ExternalShareOptions = Record<
    string,
    {
        name: string;
        icon: string;
        urlTemplate: string;
    }
>;

const ExternalShareOptions: ExternalShareOptions = {
    Whatsapp: {
        name: "Whatsapp",
        icon: whatsappIcon,
        urlTemplate: "https://api.whatsapp.com/send?text=",
    },
    Slack: {
        name: "Slack",
        icon: slackIcon,
        urlTemplate: "slack://open",
    },
};
const ToastDisplayTimeMS = 5000;
const ToastText = "Link copied";

export const ExternalSharePanel = () => {
    const { activeSurveyUUID, title, isOpenSurvey } = sharePanelStore;

    const externalSurveyLink: string = isOpenSurvey
        ? encodeURI(
              `${getWebClientBaseUrl()}/survey/${OPEN_SURVEY}/${activeSurveyUUID}`
          )
        : encodeURI(`${getWebClientBaseUrl()}/survey/${activeSurveyUUID}`);

    const handleShareOptionClick = (optionName: string) => {
        switch (optionName) {
            case "Whatsapp":
                window.open(
                    ExternalShareOptions.Whatsapp.urlTemplate +
                        externalSurveyLink,
                    "_blank"
                );
                break;
            case "Slack":
                addLinkToClipboard(externalSurveyLink, () => {
                    window.open(ExternalShareOptions.Slack.urlTemplate);
                });
                break;
            default:
                addLinkToClipboard(externalSurveyLink);
                break;
        }
    };

    return (
        <div className={panel_container}>
            <div className={panel_title}>{title}</div>
            <div className={share_options_list}>
                {Object.keys(ExternalShareOptions).map((optionName) =>
                    optionName === "Slack" ? (
                        <div key={optionName}>
                            <Toast
                                text={ToastText}
                                displayTimeMS={ToastDisplayTimeMS}
                            >
                                <div
                                    className={share_option}
                                    onClick={() =>
                                        handleShareOptionClick(optionName)
                                    }
                                >
                                    <img
                                        src={
                                            ExternalShareOptions[optionName]
                                                .icon
                                        }
                                    />
                                    <div>
                                        {ExternalShareOptions[optionName].name}
                                    </div>
                                </div>
                            </Toast>
                        </div>
                    ) : (
                        <div
                            key={optionName}
                            className={share_option}
                            onClick={() => handleShareOptionClick(optionName)}
                        >
                            <img src={ExternalShareOptions[optionName].icon} />
                            <div>{ExternalShareOptions[optionName].name}</div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
};
