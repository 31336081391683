import React, { ChangeEvent } from "react";
import { makeStyles } from "@material-ui/core";
import {
    DuplicateDateEventByType,
    OptionalPropertiesForDuplicateSurvey,
    SurveyEvent,
} from "@difftone/types";
import { ShowDifftoneTooltip } from "@difftone/shared-components";

import { EventDate } from "./components";
import {
    date_item_title,
    checked_style,
    custom_checkbox_class,
    text_label,
} from "./dates-item.module.css";

const SYSTEM_TOOLTIP_MESSAGE = "This is a system event and must be duplicated";

const tooltipExtendPosition = makeStyles({
    tooltipLeftPosition: {
        bottom: "3px",
        width: "120px",
    },
});

export type DatesItemProps = {
    duplicateSurveyProps: OptionalPropertiesForDuplicateSurvey;
    title: string;
    surveyEvent: SurveyEvent;
    onToggleItemByEventType: (
        eventUuid: string,
        selected: boolean,
        eventType: DuplicateDateEventByType
    ) => void;
};

export const DatesItem = (props: DatesItemProps) => {
    const {
        duplicateSurveyProps,
        title,
        surveyEvent,
        onToggleItemByEventType,
    } = props;

    const eventTooltipPosition = tooltipExtendPosition().tooltipLeftPosition;

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        onToggleItemByEventType(surveyEvent.uuid, isChecked, "title");
    };

    const eventProperties = duplicateSurveyProps.dates.find(
        (date) => date[surveyEvent.uuid]
    );

    const isChecked = eventProperties
        ? eventProperties[surveyEvent.uuid].with_date
        : false;

    let eventInput = null;

    if (surveyEvent.system_survey_event_type) {
        eventInput = (
            <ShowDifftoneTooltip
                tooltipPosition="left"
                tip={SYSTEM_TOOLTIP_MESSAGE}
                extendTooltipStyle={eventTooltipPosition}
            >
                <input
                    type="checkbox"
                    onChange={onChange}
                    checked={eventProperties ? true : false}
                />
            </ShowDifftoneTooltip>
        );
    } else {
        eventInput = (
            <input
                type="checkbox"
                onChange={onChange}
                checked={eventProperties ? true : false}
            />
        );
    }

    return (
        <>
            <div className={date_item_title}>
                <label
                    className={
                        eventProperties ? checked_style : custom_checkbox_class
                    }
                >
                    {eventInput}
                </label>
                <label className={text_label}>{title}</label>
            </div>
            {
                //TODO: Re-implement EventDate checkbox in next sprint after addressing:
                //https://app.asana.com/0/1202552118909725/1203292606909630/f
            }
        </>
    );
};
