import React from "react";
import { observer } from "mobx-react";

import { publicProfileStore, surveyStore } from "@difftone/stores";
import { getUniquePublicProfilesUUIDs } from "@difftone/actions";
import { Spinner } from "@difftone/shared-components";

import {
    DateRangeSelector,
    FilterBySelector,
    FilterButtons,
} from "./components";
import {
    dashboard_filter_by_wrapper,
    date_selector_and_clear,
    dashboard_filter_spinner,
} from "./dashboard-filter-by.module.css";

export type DashboardsFilterByProps = {};

export const DashboardFilterBy = observer((props: DashboardsFilterByProps) => {
    if (!surveyStore.storeInitialize) {
        surveyStore.init();
        return <Spinner className={dashboard_filter_spinner} size="MEDIUM" />;
    }

    if (surveyStore.storeInitialize) {
        if (!publicProfileStore.startInitialization) {
            const uniquePublicProfiles = getUniquePublicProfilesUUIDs();

            publicProfileStore.init(uniquePublicProfiles);
        }
    }

    return (
        <div className={dashboard_filter_by_wrapper}>
            <FilterBySelector />
            <div className={date_selector_and_clear}>
                <DateRangeSelector />
                <FilterButtons />
            </div>
        </div>
    );
});
