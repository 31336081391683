import React from "react";
import { observer } from "mobx-react";
import { GoogleLogout } from "react-google-login";

import { AuthData } from "@difftone/types";
import {
    logoutFromLocalStorage,
    logoutFromMicrosoft,
    setLocation,
    showDifftoneAlert,
} from "@difftone/actions";
import { getGoogleClientId } from "@difftone/constants";
import { getUserEmail } from "@difftone/procedures";
import {
    getSelectedOrganizationOrThrow,
    getUserPermissionsType,
} from "@difftone/reducers";

import pencilIcon from "@difftone/assets/user-menu-pencil.svg";
import settingsIcon from "@difftone/assets/settings-icon.svg";
import logOut from "@difftone/assets/log-out.svg";

import {
    PermissionsButton,
    ManageCategoriesButton,
    ManageTemplatesButton,
    ImportGoogleFormButton,
} from "./components";

import {
    user_menu,
    user_menu_permissions,
    logout_button,
    edit_profile,
    logout_button_wrapper,
    admins_sections,
} from "./user-menu.module.css";

export type UserMenuProps = {
    className: string;
};

export const UserMenu = observer((props: UserMenuProps) => {
    const userEmail = getUserEmail();

    const userType = getUserPermissionsType();

    const authDataAsString = localStorage.getItem("authData");
    if (!authDataAsString) {
        throw new Error("Cannot read authData from local storage!");
    }
    const authData: AuthData = JSON.parse(authDataAsString);

    const onGoogleLogOutSuccessHandler = () => {
        logoutFromLocalStorage();
    };
    const onGoogleLogOutFailureHandler = () => {
        showDifftoneAlert(
            "Logging out failed. Please try again later.",
            "FAILURE"
        );
    };
    const onMicrosoftLogout = () => {
        logoutFromMicrosoft();
    };

    const goToOrganizationsDomains = () => {
        setLocation("/internal-features/organizations-domains");
    };

    const goToSurveyAutofill = () => {
        setLocation("/internal-features/survey-autofill");
    };

    const organization = getSelectedOrganizationOrThrow();
    const organizationName = organization ? organization.name : "";

    return (
        <div
            className={(userType !== "ADMIN"
                ? [user_menu, props.className]
                : [user_menu_permissions, user_menu, props.className]
            ).join(" ")}
        >
            <ul>
                {/* TODO: CSS Fixes & move to separate component */}
                {userEmail.endsWith("@difftone.com") &&
                    !userEmail.includes("demo") && (
                        <>
                            <li>
                                <div
                                    onClick={goToOrganizationsDomains}
                                    className={admins_sections}
                                >
                                    <img alt="edit" src={settingsIcon} />
                                    Organizations
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={goToSurveyAutofill}
                                    className={admins_sections}
                                >
                                    <img alt="edit" src={settingsIcon} />
                                    Autofill
                                </div>
                            </li>
                            <ManageTemplatesButton />
                        </>
                    )}
                <li className={edit_profile}>
                    <img alt="edit" src={pencilIcon} />
                    Edit Profile
                </li>
                {userType === "ADMIN" && organizationName === "Difftone" && (
                    <ImportGoogleFormButton />
                )}
                {userType === "ADMIN" && (
                    <>
                        <ManageCategoriesButton />
                        <PermissionsButton />
                    </>
                )}
                <li>
                    {authData.issuer === "GOOGLE" ? (
                        <GoogleLogout
                            clientId={getGoogleClientId()}
                            onLogoutSuccess={onGoogleLogOutSuccessHandler}
                            onFailure={onGoogleLogOutFailureHandler}
                            render={(renderProps) => (
                                <div className={logout_button_wrapper}>
                                    <img alt="edit" src={logOut} />
                                    <button
                                        className={logout_button}
                                        onMouseDown={renderProps.onClick}
                                    >
                                        Log Out
                                    </button>
                                </div>
                            )}
                        />
                    ) : (
                        <div className={logout_button_wrapper}>
                            <img alt="edit" src={logOut} />
                            <button
                                className={logout_button}
                                onMouseDown={onMicrosoftLogout}
                            >
                                Log Out
                            </button>
                        </div>
                    )}
                </li>
            </ul>
        </div>
    );
});

//TODO: refactor to components
