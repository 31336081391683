import axios, { AxiosInstance } from "axios";
import { getApiBaseUrl, OPEN_SURVEY } from "@difftone/constants";
import {
    errorHandlerProxy,
    getUserUuid,
    localstorageUtils,
} from "@difftone/procedures";
import { createDifftoneAxios } from "@difftone/services";
import {
    Answer,
    AuthData,
    BookingAnswer,
    ResultsFilter,
    SurveyResponse,
    SurveyResponsesSummery,
    SurveyResponseStatus,
} from "@difftone/types";

import md5 from "md5";

const SERVER_BASE_URL = `${getApiBaseUrl()}/surveys-response-service`;

export const getSurveysResponsesStatusFromApiBySurveyUuid = async (
    surveyUuid: string
): Promise<SurveyResponseStatus[]> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(
        `${SERVER_BASE_URL}/status/survey/${surveyUuid}`,
        {
            params: { issuer: currentAuthData.issuer },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    // @ts-ignores
    const resource = response.data.resources.map((status) => {
        status.id = md5(JSON.stringify(status));

        return status;
    });
    return resource;
};

export const getPublicSurveyResponsesFromApi = async (
    surveyUuid: string,
    filtersArray: ResultsFilter[]
): Promise<SurveyResponsesSummery> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(
        `${SERVER_BASE_URL}/get-public-responses-by-filters/survey/${surveyUuid}`,
        {
            params: {
                issuer: currentAuthData.issuer,
                filtersArray: JSON.stringify(filtersArray),
            },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.resources;
    return resource;
};

export const getSurveysResponsesFromApiBySurveyUUID = async (
    surveyUuid: string
): Promise<SurveyResponse[]> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(
        `${SERVER_BASE_URL}/response/survey-uuid/${surveyUuid}`,
        {
            params: { issuer: currentAuthData.issuer },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.resources;

    return resource;
};

export const getAllSurveysResponsesFromApi = async (): Promise<
    SurveyResponse[]
> => {
    const currentAuthData: AuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(
        `${SERVER_BASE_URL}/response/responder/${getUserUuid()}`,
        {
            params: { issuer: currentAuthData.issuer },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    // TODO: remove temporary patch
    for (let i = 0; i < response.data.resources.length; i++) {
        if (!response.data.resources[i].attribute_answers) {
            response.data.resources[i].attribute_answers = [];
        }
    }

    const resource = response.data.resources;
    return resource;
};

export const upsertConvertedSurveyResponseToApi = async (
    surveyResponse: SurveyResponse
) => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.put(
        `${SERVER_BASE_URL}/`,
        { updated_survey_response: surveyResponse },
        { params: { issuer: currentAuthData.issuer } }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.results;
    return resource;
};

export const upsertSurveyResponseToApi = async (
    surveyResponse: SurveyResponse
) => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.patch(
        `${SERVER_BASE_URL}/`,
        { updated_survey_response: surveyResponse },
        { params: { issuer: currentAuthData.issuer } }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.results;
    return resource;
};

export const upsertOpenSurveyResponseToApi = async (
    surveyResponse: SurveyResponse
) => {
    const _axiosClient: AxiosInstance = axios.create();
    _axiosClient.defaults.headers.common["Accept"] = "application/json";

    console.log("12");

    const response = await _axiosClient.patch(
        `${SERVER_BASE_URL}/${OPEN_SURVEY}`,
        {
            updated_survey_response: surveyResponse,
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.results;
    return resource;
};
