import { SurveyEventAlert, UUID, EmailAddress } from "@difftone/types";
import { getApiBaseUrl } from "@difftone/constants";
import { showDifftoneAlert } from "@difftone/actions";
import { errorHandlerProxy, localstorageUtils } from "@difftone/procedures";
import { createDifftoneAxios } from "@difftone/services";

const SERVER_BASE_URL = `${getApiBaseUrl()}/survey-event-alert-service`;

export const getEventAlertsFromApiByUUID = async (
    uuidsList: UUID[]
): Promise<SurveyEventAlert[]> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const UUIDsListStr = JSON.stringify(uuidsList);
    const response = difftoneAxios.get(
        `${SERVER_BASE_URL}/all-alerts?&issuer=${currentAuthData.issuer}&ids=${UUIDsListStr}`
    );

    return response
        .then((response) => {
            if (response.data.error) {
                errorHandlerProxy({
                    error: response.data.error,
                    code: response.data.statusCode,
                });
            } else return response.data.responseBody;
        })
        .catch((err) => {
            showDifftoneAlert(err.message, "FAILURE");
            return null;
        });
};

export const saveEventAlertToApiByUUID = async (
    alert: SurveyEventAlert
): Promise<any> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = difftoneAxios.put(
        `${SERVER_BASE_URL}/survey-event-alert-service`,
        alert,
        {
            params: { issuer: currentAuthData.issuer },
        }
    );

    return response
        .then((response) => {
            if (response.data.error) {
                errorHandlerProxy({
                    error: response.data.error,
                    code: response.data.statusCode,
                });
            }
            return response.data.results;
        })
        .catch((err) => {
            showDifftoneAlert("[alerts service]:" + err.message, "FAILURE");
        });
};

export const getAllAlertsFromApiByInitiatorEmail = async (
    initiatorEmail: EmailAddress
): Promise<SurveyEventAlert[]> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(
        `${SERVER_BASE_URL}/all-alerts-by-initiator-email`,
        {
            params: {
                issuer: currentAuthData.issuer,
                initiatorEmail: initiatorEmail,
            },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.responseBody;
    return resource;
};
