import React from "react";
import {
    MultipleChoiceQuestion,
    Question,
    QuestionType,
    RatingQuestion,
    SingleChoiceQuestion,
    SupportedQuestionResultsMapAfterDigestion,
} from "@difftone/types";
import { COLUMN_0, NO_ANSWER_KEY } from "@difftone/common-constants";

import { QuestionOptionResultBox } from "./components";

import { expanded_results } from "./expanded-results.module.css";

export type ExpandedResultsProps = {
    question: Question<QuestionType>;
    nakedDigestedResultsRow: SupportedQuestionResultsMapAfterDigestion;
};

export const ExpandedResults = (props: ExpandedResultsProps) => {
    const { question, nakedDigestedResultsRow } = props;

    const extractQuestionOptionsByType = () => {
        switch (question.question_type_name) {
            case "RATING":
                const answerRatingContent = (
                    question as Question<RatingQuestion>
                ).content.rating_options;

                const noResponseAnswersRating = {
                    value: NO_ANSWER_KEY,
                    label: "No response",
                };

                const ratingAnswers = answerRatingContent.map(
                    (ratingOption) => ({
                        value: ratingOption.value.toString(),
                        label: ratingOption.label,
                    })
                );

                return [...ratingAnswers, noResponseAnswersRating];

            case "SINGLE_CHOICE":
                const answerSingleChoiceContent = (
                    question as Question<SingleChoiceQuestion>
                ).content.choice_options.map(
                    (choiceOption) => choiceOption.label
                );

                const noResponseAnswersSingleChoice = {
                    value: NO_ANSWER_KEY,
                    label: "No response",
                };

                const singleChoiceAnswers = answerSingleChoiceContent.map(
                    (option) => ({
                        value: option,
                        label: option,
                    })
                );
                return [...singleChoiceAnswers, noResponseAnswersSingleChoice];

            case "MULTIPLE_CHOICE":
                const multipleChoiceContent = (
                    question as Question<MultipleChoiceQuestion>
                ).content.choice_options;

                const noResponseAnswersMultipleChoice = {
                    value: NO_ANSWER_KEY,
                    label: "No response",
                };

                const multipleChoiceAnswers = multipleChoiceContent.map(
                    (option) => ({
                        value: option,
                        label: option,
                    })
                );

                return [
                    ...multipleChoiceAnswers,
                    noResponseAnswersMultipleChoice,
                ];

            case "LONG_TEXT":
            case "SHORT_TEXT":
            case "TIME":
            case "DATE":
                const noResponseAnswersOpenQuestions = {
                    value: NO_ANSWER_KEY,
                    label: "No response",
                };
                return [noResponseAnswersOpenQuestions];

            default:
                return [];
        }
    };
    return (
        <div className={expanded_results}>
            {extractQuestionOptionsByType().map((option, index) => {
                return (
                    <QuestionOptionResultBox
                        questionType={question.question_type_name}
                        answerLabel={option.label}
                        answerValue={option.value}
                        key={question.uuid + option}
                        answersFrequency={nakedDigestedResultsRow[COLUMN_0]}
                    />
                );
            })}
        </div>
    );
};
