import React from "react";
import clsx from "clsx";
import { ResultsFilter } from "@difftone/types";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core";

import { ALLOWED_ATTRIBUTE_TITLE_LENGTH } from "@difftone/stores";
import { ShowDifftoneTooltip } from "@difftone/shared-components";
import { timeCompareFiltersKeyValues } from "@difftone/constants";

import { FirstAttributeRow, SecondAttributeRow } from "./components";

import {
    results_table_header,
    header_titles,
    header_content_rows,
    first_attribute_header,
    second_attribute_header,
    first_attribute_header_border,
    second_attribute_header_title,
    first_attribute_rotation,
} from "./results-table-header.module.css";

const extendTooltipStyle = makeStyles({
    tooltipFirstHeader: {
        top: "20px",
        left: "10px",
    },
    tooltipSecondHeader: {
        top: "0px",
        left: "0px",
    },
});

export type ResultsTableHeaderProps = {
    filtersActiveAsCompares: ResultsFilter[];
};

export const ResultsTableHeader = observer((props: ResultsTableHeaderProps) => {
    const { filtersActiveAsCompares } = props;

    const firstHeader = filtersActiveAsCompares.find(
        (_filter) => _filter.indexInComparePlain === 1
    );
    const secondHeader = filtersActiveAsCompares.find(
        (_filter) => _filter.indexInComparePlain === 2
    );

    const tooltipFirstHeader = extendTooltipStyle().tooltipFirstHeader;
    const tooltipSecondHeader = extendTooltipStyle().tooltipSecondHeader;

    let firstHeaderTitleAsFirstDisplay = null;
    let firstHeaderTitleDisplay = null;
    let secondHeaderTitle = null;

    if (firstHeader) {
        firstHeaderTitleAsFirstDisplay = firstHeader.unit
            ? `${firstHeader.filterKey.nickname}: ${
                  timeCompareFiltersKeyValues[firstHeader.unit]
              }`
            : firstHeader.filterKey.nickname;

        firstHeaderTitleDisplay = firstHeaderTitleAsFirstDisplay;

        if (firstHeaderTitleDisplay.length > ALLOWED_ATTRIBUTE_TITLE_LENGTH) {
            firstHeaderTitleDisplay = (
                <ShowDifftoneTooltip
                    extendTooltipStyle={
                        secondHeader ? tooltipSecondHeader : tooltipFirstHeader
                    }
                    tip={firstHeaderTitleDisplay}
                    tooltipPosition={secondHeader ? "left" : "top"}
                >
                    <div>
                        {firstHeaderTitleDisplay
                            .slice(0, ALLOWED_ATTRIBUTE_TITLE_LENGTH)
                            .concat("...")}
                    </div>
                </ShowDifftoneTooltip>
            );
        }
    }

    if (secondHeader) {
        const secondHeaderTitleValue = secondHeader.unit
            ? `${secondHeader.filterKey.nickname}: ${
                  timeCompareFiltersKeyValues[secondHeader.unit]
              }`
            : secondHeader.filterKey.nickname;

        if (secondHeaderTitleValue.length > ALLOWED_ATTRIBUTE_TITLE_LENGTH) {
            secondHeaderTitle = (
                <div className={second_attribute_header}>
                    <ShowDifftoneTooltip
                        extendTooltipStyle={tooltipSecondHeader}
                        tip={secondHeaderTitleValue}
                        tooltipPosition="top"
                    >
                        <div className={second_attribute_header_title}>
                            {secondHeaderTitleValue
                                .slice(0, ALLOWED_ATTRIBUTE_TITLE_LENGTH)
                                .concat("...")}
                        </div>
                    </ShowDifftoneTooltip>
                </div>
            );
        } else {
            secondHeaderTitle = (
                <div className={second_attribute_header}>
                    <div className={second_attribute_header_title}>
                        {secondHeaderTitleValue}
                    </div>
                </div>
            );
        }
    }

    return (
        <div className={results_table_header}>
            <div className={header_titles}>
                {firstHeader ? (
                    <div
                        className={
                            secondHeader
                                ? first_attribute_header
                                : clsx(
                                      first_attribute_header_border,
                                      first_attribute_rotation
                                  )
                        }
                    >
                        {secondHeader
                            ? firstHeaderTitleAsFirstDisplay
                            : firstHeaderTitleDisplay}
                    </div>
                ) : null}
                {secondHeader ? secondHeaderTitle : null}
            </div>
            <div className={header_content_rows}>
                {firstHeader ? (
                    <FirstAttributeRow
                        filtersActiveAsCompares={filtersActiveAsCompares}
                    />
                ) : null}
                {secondHeader ? (
                    <SecondAttributeRow
                        filtersActiveAsCompares={filtersActiveAsCompares}
                    />
                ) : null}
            </div>
        </div>
    );
});
