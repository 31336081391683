import React from "react";

import { header_wrapper, header_text } from "./header.module.css";

export type headerProps = {};

export const Header = (props: headerProps) => {
    return (
        <div className={header_wrapper}>
            <div className={header_text}>Surveys by purpose</div>
        </div>
    );
};
