import React from "react";
import { BaseSurvey, SimpleSurvey } from "@difftone/types";
import { getSortedEvents } from "@difftone/reducers";

import { Line, WizardDates } from "./components";
import { timeline } from "./timeline.module.css";

export type TimelineProps = {
    survey: BaseSurvey;
};

export const Timeline = (props: TimelineProps) => {
    const { survey } = props;

    if (survey.survey_class === "ONGOING") {
        throw Error(
            "[Difftone Timeline]:: Ongoing survey does not have survey events."
        );
    }

    const simpleSurvey = survey as SimpleSurvey;

    const sortedEvents = getSortedEvents(simpleSurvey) || [];

    return (
        <div className={timeline}>
            <Line sortedEvents={sortedEvents} />
            <WizardDates sortedEvents={sortedEvents} />
        </div>
    );
};
