import React, { ChangeEvent, MouseEvent, useState } from "react";
import clsx from "clsx";

import { EmailAddress } from "@difftone/types";

import emptyMessageIcon from "@difftone/assets/empty-message-icon.svg";
import filledMessageIcon from "@difftone/assets/filled-message-icon.svg";
import {
    MailingListPicker,
    MailingListProducer,
} from "@difftone/shared-components";

import {
    shared_with_wrapper,
    shared_with_list_type,
    pick_message_or_mailing_list,
    active_message_button,
    message_indicator,
    pick_message,
    mailing_producer_input_override,
} from "./shared-with.module.css";

export type SharedWithProps = {
    addressees: EmailAddress[];
    handleMultipleAddresseesChange: (emails: EmailAddress[]) => void;
    handleRespondentsDelete: (email: EmailAddress) => void;
    handleMessageChange: (message: string) => void;
};

export const SharedWith = (props: SharedWithProps) => {
    const {
        addressees,
        handleMultipleAddresseesChange,
        handleRespondentsDelete,
        handleMessageChange,
    } = props;

    const [mailingListOrMessage, setMailingListOrMessage] = useState<
        "MAIL" | "MESSAGE"
    >("MAIL");
    const [message, setMessage] = useState<string>("");

    const toggleMailingListOrMessage = (event: MouseEvent<HTMLDivElement>) => {
        const messageDivFlag = event.currentTarget.getAttribute("data-flag");
        if (messageDivFlag) {
            setMailingListOrMessage("MESSAGE");
        } else {
            setMailingListOrMessage("MAIL");
        }
    };

    const onMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
        handleMessageChange(event.target.value);
    };

    const handleRespondentsChange = (emails: EmailAddress[]) => {
        handleMultipleAddresseesChange(emails);
    };

    const onRespondentsDelete = (emailToDelete: EmailAddress) => {
        handleRespondentsDelete(emailToDelete);
    };

    return (
        <div className={shared_with_wrapper}>
            <div
                className={clsx(
                    shared_with_list_type,
                    pick_message_or_mailing_list
                )}
            >
                <MailingListPicker
                    listName="Share with"
                    counter={addressees.length}
                    isActive={mailingListOrMessage === "MAIL"}
                    onClick={toggleMailingListOrMessage}
                />
                <div
                    className={
                        mailingListOrMessage === "MESSAGE"
                            ? [active_message_button, pick_message].join(" ")
                            : pick_message
                    }
                    data-flag="MESSAGE"
                    onClick={toggleMailingListOrMessage}
                >
                    Message
                    <span className={message_indicator}>
                        <img
                            src={
                                message === ""
                                    ? emptyMessageIcon
                                    : filledMessageIcon
                            }
                            alt="message"
                        />
                    </span>
                </div>
            </div>
            {mailingListOrMessage === "MESSAGE" ? (
                <textarea
                    aria-label="message-text-area"
                    onChange={onMessageChange}
                    value={message ? message : ""}
                    placeholder="Optional"
                />
            ) : (
                <div>
                    <MailingListProducer
                        mailingListLocation="share"
                        disabled={false}
                        readOnlyMalingList={false}
                        inEditMode={false}
                        styleOptions={{
                            listBoxHeight: 210,
                            inputStyleOverride: mailing_producer_input_override,
                        }}
                        onEnter={handleRespondentsChange}
                        onDelete={onRespondentsDelete}
                        mailingList={addressees}
                        hideMeEmailOption
                    />
                </div>
            )}
        </div>
    );
};
