import React, { useState } from "react";

import {
    DuplicateDateEventByType,
    OptionalPropertiesForDuplicateSurvey,
    SimpleSurvey,
} from "@difftone/types";

import dropDownArrow from "@difftone/assets/down-arrow.svg";

import { DatesItem } from "./components";
import {
    duplicate_dates,
    duplicate_dates_header,
    duplicate_dates_title,
    text_label,
    half_checked_style,
    checked_style,
    custom_checkbox_class,
    collapse_arrow,
    rotated_arrow,
} from "./duplicate-dates.module.css";

export type DuplicateDatesProps = {
    survey: SimpleSurvey;
    duplicateSurveyProps: OptionalPropertiesForDuplicateSurvey;
    onToggleAllDatesSelected: (allSelected: boolean) => void;
    onToggleItemByEventType: (
        eventTitle: string,
        selected: boolean,
        eventType: DuplicateDateEventByType
    ) => void;
};

export const DuplicateDates = (props: DuplicateDatesProps) => {
    const {
        survey,
        duplicateSurveyProps,
        onToggleAllDatesSelected,
        onToggleItemByEventType,
    } = props;
    const [collapsedDates, setCollapsedDates] = useState(false);

    const toggleCollapse = () => {
        setCollapsedDates(!collapsedDates);
    };

    const allDatesSelected =
        duplicateSurveyProps.dates.length === survey.survey_events.length;

    const allDatesNotSelected = duplicateSurveyProps.dates.length === 0;

    const onChange = () => {
        onToggleAllDatesSelected(!allDatesSelected);
    };

    let checkedIcon = half_checked_style;

    if (allDatesSelected) {
        checkedIcon = checked_style;
    }
    if (allDatesNotSelected) {
        checkedIcon = custom_checkbox_class;
    }

    return (
        <div className={duplicate_dates}>
            <div className={duplicate_dates_header}>
                <div className={duplicate_dates_title}>
                    <label className={checkedIcon}>
                        <input type="checkbox" onChange={onChange} />
                    </label>
                    <label className={text_label}>
                        Timeline
                        <span>{`(${survey.survey_events.length})`}</span>
                    </label>
                </div>
                <img
                    onClick={toggleCollapse}
                    className={
                        collapsedDates
                            ? [collapse_arrow, rotated_arrow].join(" ")
                            : collapse_arrow
                    }
                    src={dropDownArrow}
                    alt="arrow_collapse_expand"
                />
            </div>
            {collapsedDates ? (
                <div>
                    {survey.survey_events.map((surveyEvent) => (
                        <DatesItem
                            onToggleItemByEventType={onToggleItemByEventType}
                            surveyEvent={surveyEvent}
                            key={surveyEvent.uuid}
                            title={surveyEvent.title}
                            duplicateSurveyProps={duplicateSurveyProps}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    );
};
