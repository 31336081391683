import {
    BaseSurvey,
    SurveyEventInvalidTitleValidationType,
    SurveyEventsDateFormatValidationType,
    SurveyEventsDateValidationType,
    SurveyEventTitleDuplicationValidationType,
    UUID,
    ValidationResult,
} from "@difftone/types";
import { getValidationErrorsFromStoreByUUID } from "@difftone/reducers";
import { getValidationErrosByErrorCode } from "@difftone/actions";

export const eventValidationResults = (
    surveyEventUUID: UUID,
    survey: BaseSurvey
) => {
    const surveyEventValidationErrors = getValidationErrorsFromStoreByUUID(
        surveyEventUUID,
        survey
    );

    const eventTimeValidations = getValidationErrosByErrorCode(
        "SURVEY_EVENT_ILLEGAL_DATE",
        surveyEventValidationErrors
    ) as ValidationResult<SurveyEventsDateValidationType>[];

    const eventTimeFormatValidations = getValidationErrosByErrorCode(
        "SURVEY_EVENT_ILLEGAL_DATE_FORMAT",
        surveyEventValidationErrors
    ) as ValidationResult<SurveyEventsDateFormatValidationType>[];

    const surveyEventNameValidations = getValidationErrosByErrorCode(
        "SURVEY_EVENT_NAME_VALIDATION",
        surveyEventValidationErrors
    ) as ValidationResult<SurveyEventInvalidTitleValidationType>[];

    const surveyEventNameUniquenessValidations = getValidationErrosByErrorCode(
        "SURVEY_EVENT_NAME_DUPLICATION_VALIDATION",
        surveyEventValidationErrors
    ) as ValidationResult<SurveyEventTitleDuplicationValidationType>[];

    const isTitleValid = !(
        surveyEventNameValidations.length > 0 ||
        surveyEventNameUniquenessValidations.length > 0
    );

    const isDateValid = !(
        eventTimeValidations.length > 0 || eventTimeFormatValidations.length > 0
    );

    return { isTitleValid, isDateValid };
};
