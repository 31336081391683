import React from "react";
import {
    DateQuestion,
    LongTextQuestion,
    MultipleChoiceQuestion,
    Question,
    QuestionType,
    RatingQuestion,
    ShortTextQuestion,
    SingleChoiceQuestion,
    TimeQuestion,
} from "@difftone/types";

import {
    ClientWidthRenderPicker,
    MobileSurveyExpandedRating,
} from "@difftone/shared-components";

import {
    ExpandedRating,
    ExpandedShortText,
    ExpandedLongText,
    SurveySingleChoiceElement,
    SurveyMultipleChoiceElement,
    ExpandedTime,
    ExpandedDate,
} from "./components";

export type QuestionBodyProps = {
    question: Question<QuestionType>;
    disabled: boolean;
    inPreviewMode: boolean;
    isEditableQuestion: boolean;
};

export const QuestionBody = (props: QuestionBodyProps) => {
    const { question, disabled, inPreviewMode, isEditableQuestion } = props;

    switch (question.question_type_name) {
        case "DATE":
            return (
                <ExpandedDate
                    isEditableQuestion={isEditableQuestion}
                    inPreviewMode={inPreviewMode}
                    question={question as Question<DateQuestion>}
                    disabled={disabled}
                />
            );
        case "TIME":
            return (
                <ExpandedTime
                    isEditableQuestion={isEditableQuestion}
                    inPreviewMode={inPreviewMode}
                    question={question as Question<TimeQuestion>}
                    disabled={disabled}
                />
            );
        case "SINGLE_CHOICE":
            return (
                <SurveySingleChoiceElement
                    isEditableQuestion={isEditableQuestion}
                    inPreviewMode={inPreviewMode}
                    question={question as Question<SingleChoiceQuestion>}
                    disabled={disabled}
                />
            );
        case "MULTIPLE_CHOICE":
            return (
                <SurveyMultipleChoiceElement
                    isEditableQuestion={isEditableQuestion}
                    inPreviewMode={inPreviewMode}
                    question={question as Question<MultipleChoiceQuestion>}
                    disabled={disabled}
                />
            );
        case "RATING":
            return (
                <ClientWidthRenderPicker
                    desktopComponent={
                        <ExpandedRating
                            isEditableQuestion={isEditableQuestion}
                            inPreviewMode={inPreviewMode}
                            question={question as Question<RatingQuestion>}
                            disabled={disabled}
                        />
                    }
                    mobileComponent={
                        // TODO: add support for editable question in mobile
                        <MobileSurveyExpandedRating
                            inPreviewMode={inPreviewMode}
                            question={question as Question<RatingQuestion>}
                            disabled={disabled}
                        />
                    }
                />
            );
        case "SHORT_TEXT":
            return (
                <ExpandedShortText
                    isEditableQuestion={isEditableQuestion}
                    inPreviewMode={inPreviewMode}
                    question={question as Question<ShortTextQuestion>}
                    disabled={disabled}
                />
            );
        case "LONG_TEXT":
            return (
                <ExpandedLongText
                    isEditableQuestion={isEditableQuestion}
                    inPreviewMode={inPreviewMode}
                    question={question as Question<LongTextQuestion>}
                    disabled={disabled}
                />
            );
        default:
            return null;
    }
};
