import React from "react";
import { observer } from "mobx-react";
import { Question, QuestionType } from "@difftone/types";

import { WIZARD_REQUIRED_TOOLTIP_INFO } from "@difftone/constants";
import { Checkbox, ShowDifftoneTooltip } from "@difftone/shared-components";
import { toggleWizardQuestionIsRequired } from "@difftone/actions";

import blueIIcon from "@difftone/assets/blue-i-icon.svg";
import {
    required_question,
    filter_text,
} from "./required-indicator.module.css";

export type RequiredIndicatorProps = {
    question: Question<QuestionType>;
};

export const RequiredIndicator = observer((props: RequiredIndicatorProps) => {
    const { question } = props;

    const onToggleRequired = () => {
        toggleWizardQuestionIsRequired(question);
    };

    return (
        <div className={required_question}>
            <Checkbox checked={question.required} onClick={onToggleRequired} />
            <div className={filter_text}>
                <label>Required</label>
                <ShowDifftoneTooltip
                    tip={WIZARD_REQUIRED_TOOLTIP_INFO}
                    tooltipPosition="bottom"
                >
                    <img src={blueIIcon} alt="info-icon" />
                </ShowDifftoneTooltip>
            </div>
        </div>
    );
});
