import { makeAutoObservable } from "mobx";
import { v4 as uuid } from "uuid";
import {
    DateQuestion,
    QuestionType,
    QuestionTypeName,
    SystemSurveyEventType,
    SurveyEvent,
    UUID,
    ValidationResult,
    ValidationType,
    AccordionTitles,
    SurveyClasses,
} from "@difftone/types";

import { sendSurveyTitltesToAutocompleteApi } from "./wizard-store-internal-actions";
import {
    NEW_DATE_QUESTION_CONTENT,
    NEW_MULTIPLE_CHOICE_QUESTION_CONTENT,
    NEW_RATING_QUESTION_CONTENT,
    NEW_SINGLE_CHOICE_QUESTION_CONTENT,
    SURVEY_EVENT_TEMPLET,
} from "@difftone/frontend-common";

class WizardStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _ratingQuestionAutoScaleRange: number | null = null;
    get ratingQuestionAutoScaleRange() {
        return this._ratingQuestionAutoScaleRange;
    }

    set ratingQuestionAutoScaleRange(scaleRange) {
        this._ratingQuestionAutoScaleRange = scaleRange;
    }

    private _wizardFocusedCalendar: UUID | null = null;
    get wizardFocusedCalendar() {
        return this._wizardFocusedCalendar;
    }
    set wizardFocusedCalendar(uuid: UUID | null) {
        this._wizardFocusedCalendar = uuid;
    }

    private _focusedWizardInputCardID: string | null = null;
    get focusedWizardInputCard(): HTMLElement | null {
        return document.querySelector(
            `div[data-focus-wizard-input-card-id="${this._focusedWizardInputCardID}"]`
        );
    }
    set focusedWizardInputCard(newFocusedNode: HTMLElement | null) {
        if (!newFocusedNode) {
            this._focusedWizardInputCardID = null;
            return;
        }

        this._focusedWizardInputCardID =
            newFocusedNode.dataset.focusWizardInputCardId!;
    }

    private _activeAccordionSection: AccordionTitles = "DETAILS";
    get activeAccordionSection(): AccordionTitles {
        return this._activeAccordionSection;
    }
    set activeAccordionSection(newAccordionTitle: AccordionTitles) {
        this._activeAccordionSection = newAccordionTitle;
    }

    private _wizardValidationErrors: ValidationResult<ValidationType>[] = [];
    get wizardValidationErrors() {
        return this._wizardValidationErrors;
    }
    set wizardValidationErrors(
        validationErrors: ValidationResult<ValidationType>[]
    ) {
        this._wizardValidationErrors = validationErrors;
    }

    private _positionActiveTimeSlot: number = 0;
    get positionActiveTimeSlot() {
        return this._positionActiveTimeSlot;
    }
    set positionActiveTimeSlot(position: number) {
        this._positionActiveTimeSlot = position;
    }

    public finalizeSurveyTitlesToAutocompleteApi = (survey: SurveyClasses) => {
        sendSurveyTitltesToAutocompleteApi(survey);
    };

    public getNewQuestionContent(newType: QuestionTypeName): QuestionType {
        switch (newType) {
            case "DATE":
                const newDateContent: DateQuestion = JSON.parse(
                    JSON.stringify(NEW_DATE_QUESTION_CONTENT)
                );
                return newDateContent;
            case "TIME":
                const newTimeContent = JSON.parse(
                    JSON.stringify(NEW_DATE_QUESTION_CONTENT)
                );
                return newTimeContent;
            case "LONG_TEXT":
            case "SHORT_TEXT":
                const newTextContent = JSON.parse(
                    JSON.stringify(NEW_DATE_QUESTION_CONTENT)
                );
                return newTextContent;
            case "MULTIPLE_CHOICE":
                const newMultipleChoiceContent = JSON.parse(
                    JSON.stringify(NEW_MULTIPLE_CHOICE_QUESTION_CONTENT)
                );
                return newMultipleChoiceContent;
            case "SINGLE_CHOICE":
                const newSingleChoiceContent = JSON.parse(
                    JSON.stringify(NEW_SINGLE_CHOICE_QUESTION_CONTENT)
                );
                return newSingleChoiceContent;
            case "RATING":
                const newRatingContent = JSON.parse(
                    JSON.stringify(NEW_RATING_QUESTION_CONTENT)
                );
                return newRatingContent;
            default:
                return { text: "" };
        }
    }

    public getNewEmptySurveyEvent = (
        eventNumber: number,
        alertsUUIDs: UUID[] = [],
        system_survey_event_type?: SystemSurveyEventType,
        title?: string
    ): SurveyEvent => {
        const newEmptySurveyEvent = JSON.parse(
            JSON.stringify(SURVEY_EVENT_TEMPLET)
        );
        newEmptySurveyEvent.uuid = uuid();
        newEmptySurveyEvent.title = title || "";
        newEmptySurveyEvent.event_number = eventNumber;
        newEmptySurveyEvent.event_alerts = alertsUUIDs;
        newEmptySurveyEvent.system_survey_event_type =
            system_survey_event_type ||
            newEmptySurveyEvent.system_survey_event_type;
        return newEmptySurveyEvent;
    };

    public clearStore = () => {
        this._activeAccordionSection = null;
        this._focusedWizardInputCardID = null;
        this._wizardFocusedCalendar = null;
        this._wizardValidationErrors = [];
        this.positionActiveTimeSlot = 0;
    };
}

export const wizardStore = new WizardStore();
