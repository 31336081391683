import React from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import { showPermissionsDialog } from "@difftone/actions";
import { getUserPermissionsType } from "@difftone/reducers";
import settingsIcon from "@difftone/assets/settings-icon.svg";

import {
    permissions_button,
    permissions_button_disabled,
    permissions_button_icon,
} from "./permissions-button.module.css";

export type PermissionsButtonProps = {};

export const PermissionsButton = observer(() => {
    const userType = getUserPermissionsType();

    return (
        <li
            className={clsx(permissions_button, {
                [permissions_button_disabled]: userType !== "ADMIN",
            })}
            onClick={showPermissionsDialog}
        >
            <img
                src={settingsIcon}
                alt=""
                className={permissions_button_icon}
            />
            Permissions
        </li>
    );
});
