import React, { useEffect } from "react";
import { observer } from "mobx-react";

import { Spinner } from "@difftone/shared-components";
import {
    getFilterQuestionsFromSurvey,
    getSelectedOrganizationOrThrow,
    getSharedResultsUuidFromUrl,
    getSurveyForAdminBySurveyUuid,
} from "@difftone/reducers";
import { SurveyClasses } from "@difftone/types";
import {
    clearTargetSharedView,
    setLocation,
    showDifftoneAlert,
} from "@difftone/actions";
import { INBOX_URL, warningMessages } from "@difftone/constants";
import {
    categoriesStore,
    sharedResultsDisplayStore,
    sharedResultStore,
} from "@difftone/stores";

import {
    ComplicatedSurveySharedResults,
    SimpleSurveyResults,
} from "./components";

export type SharedResultsProps = {};

export const SharedResults = observer((props: SharedResultsProps) => {
    const sharedResultsUuid = getSharedResultsUuidFromUrl();

    //UseEffect for clean up when exiting the shared results page
    useEffect(() => {
        return () => {
            sharedResultsDisplayStore.clearStore();
            clearTargetSharedView();
        };
    }, []);

    if (sharedResultsUuid === null) {
        setLocation(INBOX_URL);
        showDifftoneAlert(warningMessages.sharedSurveyUuidNotFound, "FAILURE");
        return null;
    }

    if (!sharedResultStore.isInitializingStarted) {
        sharedResultStore.init();
        return <Spinner size="LARGE" />;
    }

    if (sharedResultStore.isStoreInitializing) {
        return <Spinner size="LARGE" />;
    }

    const _sharedResults =
        sharedResultStore.getSharedResultsSharedWithMeByUuid(sharedResultsUuid);

    if (!_sharedResults) {
        setLocation(INBOX_URL);
        showDifftoneAlert(warningMessages.sharedSurveyUuidNotFound, "FAILURE");
        return null;
    }

    sharedResultsDisplayStore.selectedSharedResults = _sharedResults;

    const _selectedSurvey = getSurveyForAdminBySurveyUuid(
        _sharedResults.survey_uuid
    );

    if (_selectedSurvey === undefined) {
        return <Spinner size="LARGE" />;
    }

    if (_selectedSurvey === null) {
        setLocation(INBOX_URL);
        showDifftoneAlert(warningMessages.surveyNotFound, "FAILURE");
        return null;
    }

    sharedResultsDisplayStore.selectedSurvey = _selectedSurvey;

    const surveyHasCategories = (survey: SurveyClasses) =>
        survey.questions.some((question) => question.categories.length);

    const calculateViewType = (
        survey: SurveyClasses
    ): "COMPLICATED" | "SIMPLE" => {
        const filterQuestions = getFilterQuestionsFromSurvey(survey);
        if (survey.survey_class === "ONGOING") return "COMPLICATED";
        if (
            !filterQuestions.length &&
            survey.anonimity === "NONE" &&
            !surveyHasCategories(survey)
        )
            return "SIMPLE";
        return "COMPLICATED";
    };

    if (!categoriesStore.isStoreInitialized && !categoriesStore.isRequesting) {
        const organization = getSelectedOrganizationOrThrow();
        const organisationUuid = organization ? organization.uuid : null;
        categoriesStore.init(organisationUuid);
        return <Spinner />;
    }

    switch (calculateViewType(_selectedSurvey)) {
        case "COMPLICATED":
            return <ComplicatedSurveySharedResults />;
        case "SIMPLE":
            return <SimpleSurveyResults />;
        default:
            return <ComplicatedSurveySharedResults />;
    }
});
