import React from "react";
import clsx from "clsx";

import { Question, QuestionType } from "@difftone/types";
import { MAX_CHARACTERS_IN_QUESTION_TITLE } from "@difftone/constants";
import { ShowDifftoneTooltip } from "@difftone/shared-components";

import ratingIcon from "@difftone/assets/collapsed-rating.svg";
import singleChoiceIcon from "@difftone/assets/collapsed-single-choice.svg";
import multiChoiceIcon from "@difftone/assets/collapsed-multiple-choice.svg";
import dateIcon from "@difftone/assets/collapsed-date.svg";
import timeIcon from "@difftone/assets/collapsed-clock.svg";
import longTextIcon from "@difftone/assets/collapsed-long-text.svg";
import shortTextIcon from "@difftone/assets/collapsed-short-text.svg";
import dropDownArrow from "@difftone/assets/down-arrow.svg";
import bookingIcon from "@difftone/assets/booking-icon.svg";

import {
    question_description,
    question_description_wrapper,
    question_text,
    question_text_inner,
    question_text_inner_collapsed,
    question_index,
    collapse_arrow,
    rotated_arrow,
    icon_container,
    long_question_text_open,
    open_question_description_wrapper,
} from "./question-description.module.css";

export type QuestionDescriptionProps = {
    question: Question<QuestionType>;
    questionIndex: number;
    toggleCollapse: () => void;
    collapsed: boolean;
};

export const QuestionDescription = (props: QuestionDescriptionProps) => {
    const { question, questionIndex, toggleCollapse, collapsed } = props;

    let questionTypeIcon = null;
    switch (question.question_type_name) {
        case "RATING":
            questionTypeIcon = ratingIcon;
            break;
        case "SINGLE_CHOICE":
            questionTypeIcon = singleChoiceIcon;
            break;
        case "DATE":
            questionTypeIcon = dateIcon;
            break;
        case "TIME":
            questionTypeIcon = timeIcon;
            break;
        case "MULTIPLE_CHOICE":
            questionTypeIcon = multiChoiceIcon;
            break;
        case "SHORT_TEXT":
            questionTypeIcon = shortTextIcon;
            break;
        case "LONG_TEXT":
            questionTypeIcon = longTextIcon;
            break;
        default:
            break;
    }

    let questionTitle = null;

    if (question.title.length > MAX_CHARACTERS_IN_QUESTION_TITLE) {
        if (!collapsed) {
            questionTitle = (
                <ShowDifftoneTooltip tip={question.title} tooltipPosition="top">
                    <div className={question_text}>
                        <div className={question_text_inner}>
                            {question.title}
                        </div>
                    </div>
                </ShowDifftoneTooltip>
            );
        } else {
            questionTitle = (
                <div className={`${question_text} ${long_question_text_open}`}>
                    <div className={question_text_inner_collapsed}>
                        {question.title}
                    </div>
                </div>
            );
        }
    } else {
        questionTitle = <div className={question_text}>{question.title}</div>;
    }

    const questionDescriptionWrapper = collapsed
        ? `${question_description_wrapper} ${open_question_description_wrapper}`
        : question_description_wrapper;

    return (
        <div className={questionDescriptionWrapper}>
            <div className={question_description}>
                {question.question_type_name !== "BOOKING" ? (
                    <div className={question_index}>{questionIndex + 1}.</div>
                ) : (
                    <img src={bookingIcon} alt="icon" />
                )}
                {questionTitle}
                {questionTypeIcon ? (
                    <div className={icon_container}>
                        <img src={questionTypeIcon} alt="icon" />
                    </div>
                ) : null}

                {question.question_type_name !== "BOOKING" && (
                    <img
                        onClick={toggleCollapse}
                        className={clsx(collapse_arrow, {
                            [rotated_arrow]: !collapsed,
                        })}
                        src={dropDownArrow}
                        alt="arrow_collapse_expand"
                    />
                )}
            </div>
        </div>
    );
};
