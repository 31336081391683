import React from "react";

import clsx from "clsx";
import { OptionalPropertiesForDuplicateSurvey } from "@difftone/types";

import {
    duplicate_participants,
    duplicate_participants_header,
    duplicate_participants_title,
    text_label,
    checked_style,
    custom_checkbox_class,
} from "./duplicate-participants.module.css";

export type DuplicateParticipantsProps = {
    duplicateSurveyProps: OptionalPropertiesForDuplicateSurvey;
    onToggleParticipants: (selected: boolean) => void;
};

export const DuplicateParticipants = (props: DuplicateParticipantsProps) => {
    const { duplicateSurveyProps, onToggleParticipants } = props;

    const onChange = () => {
        onToggleParticipants(!duplicateSurveyProps.participants);
    };

    return (
        <div className={duplicate_participants}>
            <div className={duplicate_participants_header}>
                <div className={duplicate_participants_title}>
                    <label
                        className={clsx(
                            duplicateSurveyProps.participants && checked_style,
                            !duplicateSurveyProps.participants &&
                                custom_checkbox_class
                        )}
                    >
                        <input type="checkbox" onChange={onChange} />
                    </label>
                    <label className={text_label}>Participants</label>
                </div>
            </div>
        </div>
    );
};
