import React from "react";
import savedIcon from "@difftone/assets/mobile-header-saved-status.svg";
import offlineIcon from "@difftone/assets/mobile-header-offline-status.svg";
import syncingIcon from "@difftone/assets/mobile-header-syncing-status.svg";

import { overall_status_bar } from "./overall-status-bar.module.css";

type OverallStatusType = "SAVED" | "SYNCING" | "OFFLINE";

export type OverallStatusProps = {
    currentStatus: OverallStatusType;
};

export const OverallStatusBar = (props: OverallStatusProps) => {
    const { currentStatus } = props;
    let currentStatusImg = null;
    switch (currentStatus) {
        case "OFFLINE":
            currentStatusImg = <img src={offlineIcon} alt="offline" />;
            break;
        case "SAVED":
            currentStatusImg = <img src={savedIcon} alt="saved" />;
            break;
        case "SYNCING":
            currentStatusImg = <img src={syncingIcon} alt="syncing" />;
            break;
        default:
            break;
    }

    return <div className={overall_status_bar}>{currentStatusImg}</div>;
};
