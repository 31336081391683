import React, { useState } from "react";
import clsx from "clsx";

import {
    difftone_button_container,
    difftone_button_container_extra_options,
    difftone_button_container_disabled,
    difftone_button,
    difftone_button_extra_options,
    extra_options_button,
    extra_options_dropdown,
    extra_options_option,
} from "./difftone-button.module.css";

import dropDownArrowIcon from "@difftone/assets/drop-down-arrow-white.svg";
import OutsideClickHandler from "react-outside-click-handler";

export type DifftoneButtonOption = {
    icon?: string;
    text: string;
    onClick: () => void;
};

export type DifftoneButtonProps = {
    icon?: string;
    classNameOverride?: string;
    iconClassName?: string;
    inlineStyle?: Object;
    buttonText: string;
    onClick: () => void;
    disabled?: boolean;
    extraOptions?: DifftoneButtonOption[];
};

export const DifftoneButton = (props: DifftoneButtonProps) => {
    const {
        icon,
        buttonText,
        classNameOverride,
        iconClassName,
        onClick,
        inlineStyle,
        disabled,
        extraOptions,
    } = props;

    //useState and useEffect are for managing the state of the dropdown if there are extra options
    const [dropdownOpen, setDropdownOpen] = useState(false);

    return (
        <div
            className={clsx(
                difftone_button_container,
                extraOptions?.length && difftone_button_container_extra_options,
                disabled && difftone_button_container_disabled,
                classNameOverride && classNameOverride
            )}
        >
            <OutsideClickHandler
                onOutsideClick={() => setDropdownOpen(false)}
                display={"flex"}
            >
                <div
                    style={inlineStyle ? inlineStyle : {}}
                    onClick={onClick}
                    className={clsx(
                        difftone_button,
                        extraOptions?.length && difftone_button_extra_options
                    )}
                >
                    {icon ? (
                        <img
                            className={iconClassName}
                            alt="button-icon"
                            src={icon}
                        />
                    ) : null}
                    <span>{buttonText}</span>
                </div>
                {extraOptions?.length ? (
                    <div
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                        className={extra_options_button}
                    >
                        <img src={dropDownArrowIcon} />
                    </div>
                ) : null}
                {extraOptions?.length && dropdownOpen ? (
                    <div className={extra_options_dropdown}>
                        {extraOptions.map((option) => (
                            <div
                                key={option.text}
                                className={extra_options_option}
                                onClick={option.onClick}
                            >
                                <img src={option.icon} />
                                <div>{option.text}</div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </OutsideClickHandler>
        </div>
    );
};
