import React, { CSSProperties, ReactNode } from "react";

import { MOBILE_WIDTH_BREAKPOINT } from "@difftone/constants";

import { ValidationTooltip } from "./components";

import {
    validation_wrapper,
    validation_wrapper_mobile,
} from "./validation-wrapper.module.css";

export type ValidationTooltipPosition = "BOTTOM" | "LEFT" | "RIGHT" | "TOP";

export type ValidationWrapperProps = {
    children: ReactNode;
    isValid: boolean;
    errorMessage: string;
    inEditMode?: boolean | undefined;
    mailingListLocation?: "information" | "share" | "reminder" | undefined;
    tooltipPosition?: ValidationTooltipPosition;
    tooltipPositionOverride?: CSSProperties;
    mobileTooltipPosition?: CSSProperties;
};

export const ValidationWrapper = (props: ValidationWrapperProps) => {
    const {
        children,
        isValid,
        errorMessage,
        inEditMode,
        mailingListLocation,
        tooltipPosition,
        tooltipPositionOverride,
        mobileTooltipPosition,
    } = props;

    const clientWidth = window.innerWidth;
    const isDesktop = clientWidth > MOBILE_WIDTH_BREAKPOINT;

    let validationTooltipPosition: ValidationTooltipPosition = "RIGHT";

    switch (mailingListLocation) {
        case "information":
            validationTooltipPosition = "BOTTOM";
            break;
        case "share":
            validationTooltipPosition = "BOTTOM";
            break;
        case "reminder":
            validationTooltipPosition = "LEFT";
            break;
        default:
            isDesktop
                ? (validationTooltipPosition = "RIGHT")
                : (validationTooltipPosition = "BOTTOM");
    }

    if (tooltipPosition) {
        isDesktop
            ? (validationTooltipPosition = tooltipPosition)
            : (validationTooltipPosition = "BOTTOM");
    }

    return (
        <div
            className={
                inEditMode ? validation_wrapper_mobile : validation_wrapper
            }
        >
            {children}
            {isValid ? null : (
                <ValidationTooltip
                    position={validationTooltipPosition}
                    message={errorMessage}
                    tooltipPositionOverride={
                        isDesktop
                            ? tooltipPositionOverride
                            : mobileTooltipPosition
                    }
                />
            )}
        </div>
    );
};
