import React from "react";
import { WEB_CLIENT_VERSION } from "@difftone/constants";

import { versions } from "./versions.module.css";

export type versionsProps = {};

export const Versions = () => {
    return (
        <div className={versions}>
            <h3>Versions:</h3>
            <h5> - Web Client Version: {WEB_CLIENT_VERSION}</h5>
        </div>
    );
};
