import React from "react";

import {
    action_item_icon,
    grayed_out,
    disabled_action_item,
} from "./action-icon-item.module.css";

export type ActionIconItemProps = {
    disabled: boolean;
    icon: string;
    action: () => void;
};

export const ActionIconItem = (props: ActionIconItemProps) => {
    const { action, disabled, icon } = props;
    return (
        <div
            onClick={disabled ? () => {} : action}
            className={
                disabled
                    ? [action_item_icon, disabled_action_item].join(" ")
                    : action_item_icon
            }
        >
            <img
                className={disabled ? grayed_out : ""}
                src={icon}
                alt="action-icon"
            />
        </div>
    );
};
