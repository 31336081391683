import React from "react";

export type CategoriesIconColor = "BLUE" | "LIGHT_BLUE" | "GRAY";

export type CategoriesIconProps = {
    color: CategoriesIconColor;
    className?: "";
};

export const CategoriesIcon = (props: CategoriesIconProps) => {
    const { color, className } = props;

    const colors = {
        BLUE: "#035375",
        LIGHT_BLUE: "#7FA6B7",
        GRAY: "#B6B8BA",
    };

    return (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="1.75"
                y="1.75"
                width="20.5"
                height="20.5"
                rx="5.25"
                stroke={colors[color]}
                strokeWidth="1.5"
            />
            <path
                d="M11.0164 5.58962L8.38434 9.89149C8.09425 10.3585 8.43387 10.967 8.98576 10.967H14.2428C14.7947 10.967 15.1343 10.3585 14.8442 9.89149L12.2192 5.58962C11.9433 5.13679 11.2924 5.13679 11.0164 5.58962Z"
                fill={colors[color]}
            />
            <path
                d="M15.5096 18.75C17.2681 18.75 18.6936 17.3245 18.6936 15.566C18.6936 13.8076 17.2681 12.3821 15.5096 12.3821C13.7512 12.3821 12.3257 13.8076 12.3257 15.566C12.3257 17.3245 13.7512 18.75 15.5096 18.75Z"
                fill={colors[color]}
            />
            <path
                d="M5.95754 18.3962H10.2028C10.592 18.3962 10.9104 18.0778 10.9104 17.6886V13.4434C10.9104 13.0542 10.592 12.7358 10.2028 12.7358H5.95754C5.56839 12.7358 5.25 13.0542 5.25 13.4434V17.6886C5.25 18.0778 5.56839 18.3962 5.95754 18.3962Z"
                fill={colors[color]}
            />
        </svg>
    );
};
