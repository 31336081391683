import React from "react";
import { observer } from "mobx-react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";

import { Question, SingleChoiceQuestion } from "@difftone/types";
import { updateQuestionInWizard } from "@difftone/actions";
import { createSingleChoiceOption } from "@difftone/frontend-common";

import { DraggableOptions } from "./components";

import {
    expanded_single_choice_container,
    add_option_or_other,
    add_option_button,
    disabled_other_option,
} from "./expanded-single-choice.module.css";

const MAX_ALLOWED_CHOICE_OPTIONS = 10;

export type ExpandedSingleChoiceProps = {
    disabled: boolean;
    item: Question<SingleChoiceQuestion>;
};

const getNewOptionIndex = (currentOptions: string[]) => {
    let max = 0;
    currentOptions.forEach((option) => {
        if (option.startsWith("Option")) {
            const optionIndex = option.split(" ").pop();
            if (optionIndex && parseInt(optionIndex) > max) {
                max = parseInt(optionIndex);
            }
        }
    });
    return max + 1;
};

export const ExpandedSingleChoice = observer(
    (props: ExpandedSingleChoiceProps) => {
        const { item } = props;
        const isChoiceOptionsBiggerThenAllowed =
            item.content.choice_options.length >= MAX_ALLOWED_CHOICE_OPTIONS;

        const onAddNewChoice = () => {
            const choiceOptionsStingArray = item.content.choice_options.map(
                (choiceOption) => choiceOption.label
            );
            const newChoiceOption = createSingleChoiceOption();
            newChoiceOption.label = `Option ${getNewOptionIndex(
                choiceOptionsStingArray
            )}`;

            const copyOfCurrentQuestionChoices = [
                ...item.content.choice_options,
                newChoiceOption,
            ];
            const newContent = {
                ...item.content,
                choice_options: copyOfCurrentQuestionChoices,
            };

            updateQuestionInWizard(
                item as Question<SingleChoiceQuestion>,
                "content",
                newContent
            );
        };

        const onAddOtherAsChoice = () => {
            const newChoiceOption = createSingleChoiceOption();

            newChoiceOption.label = "Other";

            const copyOfCurrentQuestionChoices = [
                ...item.content.choice_options,
                newChoiceOption,
            ];
            const newContent = {
                ...item.content,
                choice_options: copyOfCurrentQuestionChoices,
            };

            updateQuestionInWizard(
                item as Question<SingleChoiceQuestion>,
                "content",
                newContent
            );
        };

        const isOtherOptionWasSelected = () => {
            return item.content.choice_options.filter((choiceOption) =>
                choiceOption.label.startsWith("Other")
            ).length > 0
                ? true
                : false;
        };

        const updateItemsOrder = (oldIndex: number, newIndex: number) => {
            const optionsList = Array.from(item.content.choice_options);
            const [reorderedOption] = optionsList.splice(oldIndex, 1);
            optionsList.splice(newIndex, 0, reorderedOption);

            const newContent = {
                ...item.content,
                choice_options: optionsList,
            };

            updateQuestionInWizard(
                item as Question<SingleChoiceQuestion>,
                "content",
                newContent
            );
        };

        const onDragEnd = (result: DropResult) => {
            if (result.source && result.destination) {
                updateItemsOrder(result.source.index, result.destination.index);
            }
        };

        return (
            <div className={expanded_single_choice_container}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="options">
                        {(provided) => (
                            <ul
                                className="options"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{ display: "inline" }}
                            >
                                <DraggableOptions
                                    addNewChoice={onAddNewChoice}
                                    item={item}
                                />

                                {!isChoiceOptionsBiggerThenAllowed && (
                                    <li className={add_option_or_other}>
                                        Add&nbsp;
                                        <span
                                            className={add_option_button}
                                            onClick={onAddNewChoice}
                                        >
                                            Option
                                        </span>
                                        {isOtherOptionWasSelected() ? null : (
                                            <>
                                                &nbsp;&nbsp;or
                                                <span
                                                    onClick={onAddOtherAsChoice}
                                                    className={
                                                        isOtherOptionWasSelected()
                                                            ? disabled_other_option
                                                            : add_option_button
                                                    }
                                                >
                                                    "Other"
                                                </span>
                                            </>
                                        )}
                                    </li>
                                )}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    }
);
