import React from "react";

import {
    ClientWidthRenderPicker,
    ExpandedDate,
    ExpandedLongText,
    ExpandedMultipleChoice,
    ExpandedRating,
    ExpandedShortText,
    ExpandedSingleChoice,
    ExpandedTime,
    MobileExpandedRating,
} from "@difftone/shared-components";
import {
    MultipleChoiceQuestion,
    Question,
    QuestionType,
    RatingQuestion,
    SingleChoiceQuestion,
    BaseSurvey,
} from "@difftone/types";

import { expanded_question_body_container } from "./expanded-question-body.module.css";

export type ExpandedQuestionBodyProps = {
    question: Question<QuestionType>;
    questionNumber: number;
    isDisabled: boolean;
    survey: BaseSurvey;
};

export const ExpandedQuestionBody = (props: ExpandedQuestionBodyProps) => {
    const { isDisabled, question, questionNumber, survey } = props;

    const renderBodyByQuestionType = () => {
        switch (question.question_type_name) {
            case "DATE":
                return <ExpandedDate disabled={isDisabled} />;
            case "LONG_TEXT":
                return <ExpandedLongText disabled={isDisabled} />;
            case "MULTIPLE_CHOICE":
                return (
                    <ExpandedMultipleChoice
                        item={question as Question<MultipleChoiceQuestion>}
                        disabled={isDisabled}
                    />
                );
            case "RATING":
                return (
                    <ClientWidthRenderPicker
                        desktopComponent={
                            <ExpandedRating
                                ratingObject={
                                    question as Question<RatingQuestion>
                                }
                                disabled={isDisabled}
                                questionNumber={questionNumber}
                                survey={survey}
                            />
                        }
                        mobileComponent={
                            <MobileExpandedRating
                                ratingObject={
                                    question as Question<RatingQuestion>
                                }
                                disabled={isDisabled}
                                questionNumber={questionNumber}
                            />
                        }
                    />
                );
            case "SHORT_TEXT":
                return <ExpandedShortText disabled={isDisabled} />;
            case "SINGLE_CHOICE":
                return (
                    <ExpandedSingleChoice
                        item={question as Question<SingleChoiceQuestion>}
                        disabled={isDisabled}
                    />
                );
            case "TIME":
                return <ExpandedTime disabled={isDisabled} />;
            default:
                return null;
        }
    };

    return (
        <div className={expanded_question_body_container}>
            {renderBodyByQuestionType()}
        </div>
    );
};
