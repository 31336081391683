import React from "react";
import MicrosoftLogin from "react-microsoft-login";
import type { AuthError, AuthResponse, UserAgentApplication } from "msal";

import { MicrosoftStyle } from "./components";
import {
    getMicrosoftClientId,
    MicrosoftLoginScopes,
} from "@difftone/constants";
import type { User } from "@microsoft/microsoft-graph-types";
import { loginStore } from "@difftone/stores";

const REDIRECT_URI = `${window.location.origin}/home`;

export type MicrosoftLoginButtonProps = {};

export const MicrosoftLoginButton = () => {
    const authHandler = (
        error: AuthError | null,
        result?: AuthResponse | (AuthResponse & User),
        msalInstance?: UserAgentApplication
    ) => {
        if (error) {
            console.error(error);
            return;
        } else if (!result) {
            console.error("error: no result");
            return;
        } else {
            loginStore.microsoftLogin(result, msalInstance!);
        }
    };

    const microsoftClientId: string = getMicrosoftClientId();

    return (
        //TODO - Resolve react 18 type issues
        //@ts-ignore
        <MicrosoftLogin
            clientId={microsoftClientId}
            authCallback={authHandler}
            redirectUri={REDIRECT_URI}
            withUserData={true}
            graphScopes={MicrosoftLoginScopes}
        >
            <MicrosoftStyle />
        </MicrosoftLogin>
    );
};
