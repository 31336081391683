import React from "react";
import clsx from "clsx";
import {
    Question,
    QuestionToAnswerDescreteQuestion,
    QuestionType,
    SupportedQuestionResultsMapAfterDigestionWithoutNulls,
    BaseSurvey,
} from "@difftone/types";
import { DATA_ANONYMIZED, NO_ANSWER_KEY } from "@difftone/common-constants";
import {
    DetailedResultBoxAnonymousType,
    ResultBox,
} from "@difftone/shared-components";
import {
    getAvgForRatingTypeColumn,
    getMostPopularForSingleOrMultipleChoice,
    setOpenedQuestionUUID,
} from "@difftone/actions";
import { isAnonymous } from "@difftone/anonymity-util";

import {
    detailed_option_box,
    result_box,
    secondary_result_box,
    option_count,
    empty_box,
    box_border_bottom,
    results_category_question_view_answers_button,
    results_category_question_view_answers_wrap,
} from "./category-detailed-result-box.module.css";

export type CategoryDetailedResultBoxProps = {
    digestedResultsRow: SupportedQuestionResultsMapAfterDigestionWithoutNulls;
    columnKey: string;
    survey: BaseSurvey;
    question: Question<QuestionType>;
    index: number;
    color: "primary" | "secondary";
    isLastQuestionInList?: boolean;
    isFirstItemInRow?: boolean;
    isNoRatingQuestionsInCategory?: boolean;
};

export const CategoryDetailedResultBox = (
    props: CategoryDetailedResultBoxProps
) => {
    const {
        digestedResultsRow,
        columnKey,
        survey,
        question,
        index,
        color,
        isLastQuestionInList,
        isFirstItemInRow,
        isNoRatingQuestionsInCategory,
    } = props;

    const digestedResultsRowWithoutNoResponse = JSON.parse(
        JSON.stringify(digestedResultsRow)
    );

    Object.keys(digestedResultsRowWithoutNoResponse).forEach((column) => {
        delete digestedResultsRowWithoutNoResponse[column][NO_ANSWER_KEY];
    });

    let populationSizeForAnonymity = null;

    if (digestedResultsRowWithoutNoResponse["Column_0"] === DATA_ANONYMIZED) {
        populationSizeForAnonymity = 0;
    } else {
        switch (question.question_type_name) {
            case "RATING":
                populationSizeForAnonymity = Object.values(
                    digestedResultsRowWithoutNoResponse[columnKey]
                )?.reduce(
                    (sum: number, currentValue) => sum + Number(currentValue),
                    0
                );
                break;
            case "SINGLE_CHOICE":
            case "MULTIPLE_CHOICE":
                populationSizeForAnonymity =
                    getMostPopularForSingleOrMultipleChoice(
                        digestedResultsRowWithoutNoResponse,
                        columnKey
                    );
                break;
            case "DATE":
            case "TIME":
            case "SHORT_TEXT":
            case "LONG_TEXT":
                populationSizeForAnonymity =
                    digestedResultsRowWithoutNoResponse[columnKey].length;
                break;
            default:
                populationSizeForAnonymity = Object.values(
                    digestedResultsRowWithoutNoResponse[columnKey]
                )?.reduce(
                    (sum: number, currentValue) => sum + Number(currentValue),
                    0
                );
        }
    }

    const isBoxItemAnonymous =
        digestedResultsRowWithoutNoResponse[columnKey] === DATA_ANONYMIZED;

    switch (question.question_type_name) {
        case "RATING":
            const averageResultBox = getAvgForRatingTypeColumn(
                digestedResultsRowWithoutNoResponse[
                    columnKey
                ] as QuestionToAnswerDescreteQuestion
            );
            return isBoxItemAnonymous ? (
                <DetailedResultBoxAnonymousType
                    color="secondary"
                    surveyAnonymityLevel={survey.anonimity}
                />
            ) : (
                <div className={detailed_option_box}>
                    <div
                        className={
                            color === "primary"
                                ? result_box
                                : secondary_result_box
                        }
                    >
                        <div className={option_count}>
                            {averageResultBox.toFixed(1)}
                        </div>
                    </div>
                </div>
            );
        case "SINGLE_CHOICE":
        case "MULTIPLE_CHOICE":
            const mostPopularOptionCount =
                getMostPopularForSingleOrMultipleChoice(
                    digestedResultsRowWithoutNoResponse,
                    columnKey
                );

            return isBoxItemAnonymous ? (
                <DetailedResultBoxAnonymousType
                    color="secondary"
                    surveyAnonymityLevel={survey.anonimity}
                />
            ) : (
                <div className={detailed_option_box}>
                    <div
                        className={
                            color === "primary"
                                ? result_box
                                : secondary_result_box
                        }
                    >
                        <div className={option_count}>
                            {mostPopularOptionCount !== 0
                                ? mostPopularOptionCount
                                : ""}
                        </div>
                    </div>
                </div>
            );

        case "SHORT_TEXT":
        case "LONG_TEXT":
        case "DATE":
        case "TIME":
            return isFirstItemInRow ? (
                <div
                    className={clsx(
                        results_category_question_view_answers_wrap,
                        isLastQuestionInList && box_border_bottom
                    )}
                >
                    <span
                        className={
                            results_category_question_view_answers_button
                        }
                        onClick={
                            isBoxItemAnonymous
                                ? () => {}
                                : () => setOpenedQuestionUUID(question.uuid)
                        }
                    >
                        {isBoxItemAnonymous
                            ? "No available answers"
                            : "View Answers"}
                    </span>
                </div>
            ) : (
                <div
                    className={clsx(
                        empty_box,
                        isLastQuestionInList && box_border_bottom
                    )}
                />
            );

        default:
            return (
                <ResultBox
                    key={question.uuid + index}
                    colorIndicator={index === 0 ? "OVERALL" : "DEFAULT"}
                    resultValue={"err"}
                />
            );
    }
};
