import React from "react";
import { Anonimity } from "@difftone/types";
import { anonymityLimitation } from "@difftone/anonymity-util";

import {
    detailed_option_result_box_wrapper,
    result_box,
    secondary_result_box,
    option_count,
} from "./detailed-result-box-anonymous-type.module.css";

export type DetailedResultBoxAnonymousTypeProps = {
    color: "primary" | "secondary";
    surveyAnonymityLevel: Anonimity;
    frequencyInPercentage?: number;
};

export const DetailedResultBoxAnonymousType = (
    props: DetailedResultBoxAnonymousTypeProps
) => {
    const { surveyAnonymityLevel, color } = props;

    return (
        <div className={detailed_option_result_box_wrapper}>
            <div
                className={
                    color === "primary" ? result_box : secondary_result_box
                }
            >
                <div className={option_count}>
                    {`< ${anonymityLimitation[surveyAnonymityLevel]}`}
                </div>
            </div>
        </div>
    );
};
