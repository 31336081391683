import { Question, QuestionType } from "@difftone/types";

type SortingItem = {
    surveyName: string;
    [key: string]: any;
};

export const sortBySurveyName = (items: SortingItem[], surveyName: string) => {
    const finalItems: SortingItem[] = [];
    const tempItems: SortingItem[] = items
        .map((item) => {
            if (item.surveyName.includes(surveyName)) {
                finalItems.push(item);
                return false;
            }

            return item;
        })
        .filter((x) => !!x) as SortingItem[];

    finalItems.sort((a, b) => {
        if (a.surveyName < b.surveyName) return -1;
        if (a.surveyName > b.surveyName) return 1;

        return 0;
    });

    tempItems.sort((a, b) => {
        if (a.surveyName < b.surveyName) return -1;
        if (a.surveyName > b.surveyName) return 1;

        return 0;
    });

    return [...finalItems, ...tempItems];
};

export const sortQuestionsByType = (
    questions: Question<QuestionType>[]
): Question<QuestionType>[] => {
    const ratingQuestions: Question<QuestionType>[] = [];
    const singleQuestions: Question<QuestionType>[] = [];
    const multipleQuestions: Question<QuestionType>[] = [];
    const datesQuestions: Question<QuestionType>[] = [];
    const timeQuestions: Question<QuestionType>[] = [];
    const shortQuestions: Question<QuestionType>[] = [];
    const longQuestions: Question<QuestionType>[] = [];

    questions.forEach((question) => {
        switch (question.question_type_name) {
            case "RATING":
                ratingQuestions.push(question);
                break;
            case "SINGLE_CHOICE":
                singleQuestions.push(question);
                break;
            case "MULTIPLE_CHOICE":
                multipleQuestions.push(question);
                break;
            case "DATE":
                datesQuestions.push(question);
                break;
            case "TIME":
                timeQuestions.push(question);
                break;
            case "SHORT_TEXT":
                shortQuestions.push(question);
                break;
            case "LONG_TEXT":
                longQuestions.push(question);
                break;
        }
    });

    return [
        ...ratingQuestions,
        ...singleQuestions,
        ...multipleQuestions,
        ...datesQuestions,
        ...timeQuestions,
        ...shortQuestions,
        ...longQuestions,
    ];
};
