import React from "react";
import clsx from "clsx";

import {
    Question,
    QuestionToAnswer,
    QuestionToAnswerDescreteQuestion,
    QuestionType,
} from "@difftone/types";
import { ResultsTable } from "@difftone/stores";
import { calculateFavorableCategoryScore } from "@difftone/reducers";
import { FavorableScore } from "@difftone/shared-components";
import { COLUMN_0 } from "@difftone/common-constants";

import {
    results_favorable_categories,
    favorable_anonimity,
    favorable_anonimity_no_data,
} from "./results-favorable-categories.module.css";

const DATA_ANONYMIZED = "%DATA_ANONYMIZED%";

export type ResultsFavorableCategoriesProps = {
    questions: Question<QuestionType>[];
    resultsRow: ResultsTable;
    scoreBarSize: "SMALL" | "MEDIUM";
};

export const ResultsFavorableCategories = (
    props: ResultsFavorableCategoriesProps
) => {
    const { questions, resultsRow, scoreBarSize } = props;

    const resultsByQuestionUuidArray = questions.map(
        (question) => resultsRow[question.uuid][COLUMN_0]
    ) as QuestionToAnswer[];

    const ratingQuestionsLength = questions.length;

    const resultsCategoryScore = calculateFavorableCategoryScore(
        resultsByQuestionUuidArray as QuestionToAnswerDescreteQuestion[]
    );

    const isAnonymous = resultsByQuestionUuidArray.includes(DATA_ANONYMIZED);

    return (
        <>
            {ratingQuestionsLength ? (
                <div
                    className={clsx(results_favorable_categories, {
                        [favorable_anonimity]: isAnonymous,
                    })}
                >
                    {isAnonymous ? (
                        <span className={favorable_anonimity_no_data}>
                            Data cannot be shown due to anonymity limitations
                        </span>
                    ) : (
                        <FavorableScore
                            scoreResults={resultsCategoryScore}
                            scoreBarSize={scoreBarSize}
                        />
                    )}
                </div>
            ) : null}
        </>
    );
};
