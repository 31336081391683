import type {
    SurveyClasses,
    SurveyNumOfQuestionsValidationType,
    ValidationResult,
} from "@difftone/types";

export const validateQuestionsCount = (
    survey: SurveyClasses
): ValidationResult<SurveyNumOfQuestionsValidationType>[] => {
    try {
        const check_valid = survey.questions.length;
        return [
            {
                is_valid: !!check_valid,
                validation_error_code: "NUM_OF_QUESTIONS_VALIDATION",
                validation_error_type: { survey_uuid: survey.uuid },
            },
        ];
    } catch (error) {
        console.error(
            `validateSurveyDates error:: ${(error as Error).message}`
        );
        return [
            {
                is_valid: false,
                validation_error_code: "EXCEPTION_DURING_VALIDATIONS",
                //TODO: fix type definition
                //@ts-ignore
                validation_error_type: error as Error,
            },
        ];
    }
};
