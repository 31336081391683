import React, { useState } from "react";
import { observer } from "mobx-react";

import {
    Dialog,
    DialogProps,
    DifftoneButton,
    DropdownElements,
    SimpleDropdown,
} from "@difftone/shared-components";

import { preventPropagation } from "@difftone/frontend-common";

import { organizationStore } from "@difftone/stores";

import {
    dialog_content,
    dialog_header,
    dialog_paper,
    organization_dropdown_wrapper,
    buttons_container,
    info_wrapper,
} from "./organization-selection-dialog.module.css";

import iconConvert from "@difftone/assets/activate-pay-icon.svg";
import { Organization, UUID } from "@difftone/types";

export type OrganizationSelectionDialogProps = DialogProps & {
    organizationsList: Organization[];
};

export const OrganizationSelectionDialog = observer(
    (props: OrganizationSelectionDialogProps) => {
        const { open, organizationsList } = props;

        const [
            currentlySelectedOrganization,
            setCurrentlySelectedOrganization,
        ] = useState<UUID | null>(null);

        const onChange = (newValue: UUID) => {
            setCurrentlySelectedOrganization(newValue);
        };

        const onSubmit = () => {
            if (currentlySelectedOrganization)
                organizationStore.setSelectedOrganizationUUID(
                    currentlySelectedOrganization
                );
        };

        const dropdownList: DropdownElements[] = organizationsList.map(
            (organization) => {
                return {
                    displayValue: organization.name,
                    systemValue: organization.uuid,
                };
            }
        );

        return (
            <Dialog
                open={open}
                fullScreen={false}
                title="Select Organization"
                className={dialog_header}
                classes={{
                    paper: dialog_paper,
                }}
            >
                <div className={dialog_content} onClick={preventPropagation}>
                    <>
                        <div className={info_wrapper}>
                            The email account you are using belongs to more than
                            one organization, please select which one you want
                            to use.
                        </div>
                        <div className={organization_dropdown_wrapper}>
                            <SimpleDropdown
                                onChange={onChange}
                                selectedValue={
                                    currentlySelectedOrganization || ""
                                }
                                dropdownOptions={dropdownList}
                            />
                        </div>
                        {currentlySelectedOrganization && (
                            <div className={buttons_container}>
                                <DifftoneButton
                                    onClick={() => onSubmit()}
                                    icon={iconConvert}
                                    buttonText={"Confirm Selection"}
                                />
                            </div>
                        )}
                    </>
                </div>
            </Dialog>
        );
    }
);
