import dayjs from "dayjs";
import {
    AuthResponse,
    InteractionRequiredAuthError,
    UserAgentApplication,
} from "msal";

import type {
    AuthData,
    EmailAddress,
    TokenData,
    UserDataInfo,
} from "@difftone/types";
import jwt_decode from "jwt-decode";

import { logoutFromLocalStorage, showDifftoneAlert } from "@difftone/actions";
import {
    getGoogleClientId,
    getMicrosoftClientId,
    GoogleLoginScopes,
    MicrosoftLoginScopes,
    WEB_CLIENT_VERSION,
} from "@difftone/constants";

import { errorHandlerProxy } from "./error-handling";
import {
    decodeGoogleUserToken,
    decodeMicrosoftUserToken,
} from "@difftone/frontend-common";

class LocalstorageUtils {
    private _authDataFromLocalstorageStrPromise: Promise<AuthData> | undefined =
        undefined;

    private _authUserInfoFromLocalStorage: UserDataInfo | undefined;

    get authUserInfoFromLocalStorage() {
        if (this._authUserInfoFromLocalStorage === undefined) {
            const userAuthFromLocalStorage = localStorage.getItem("userInfo");
            this._authUserInfoFromLocalStorage = userAuthFromLocalStorage
                ? JSON.parse(userAuthFromLocalStorage)
                : undefined;
        }
        return this._authUserInfoFromLocalStorage || undefined;
    }

    set authUserInfoFromLocalStorage(newUserAuth: UserDataInfo | undefined) {
        if (!newUserAuth) {
            localStorage.removeItem("userInfo");
            this._authUserInfoFromLocalStorage = undefined;
            return;
        }

        localStorage.setItem("userInfo", JSON.stringify(newUserAuth));
        this._authUserInfoFromLocalStorage = newUserAuth;
    }

    private _authDataFromLocalstorage: AuthData | undefined | null = undefined;

    get authDataFromLocalstorage() {
        if (this._authDataFromLocalstorage === undefined) {
            const authDataFromLocalStorage = localStorage.getItem("authData");
            this._authDataFromLocalstorage = authDataFromLocalStorage
                ? JSON.parse(authDataFromLocalStorage)
                : undefined;
        }
        return this._authDataFromLocalstorage || undefined;
    }

    set authDataFromLocalstorage(newAuthData: AuthData | undefined | null) {
        if (!newAuthData) {
            localStorage.removeItem("authData");
            this._authDataFromLocalstorage = undefined;
            return;
        }

        localStorage.setItem("authData", JSON.stringify(newAuthData));
        localStorage.setItem("clientVersion", WEB_CLIENT_VERSION);
        this._authDataFromLocalstorage = newAuthData;
    }

    //Cases of dealing the promise
    //1.undefined means that waiting for the request to finish or returns the valid authData
    //2.null means that the request failed => needs to login again
    asyncAuthDataFromLocalstorage = async (): Promise<AuthData> => {
        if (this._authDataFromLocalstorage === undefined) {
            const authDataFromLocalStorage = localStorage.getItem("authData");

            this._authDataFromLocalstorage = authDataFromLocalStorage
                ? JSON.parse(authDataFromLocalStorage)
                : null;
        }

        if (this._authDataFromLocalstorage === null) {
            throw Error("[AuthData]:: was not found");
        }

        const authData: AuthData = this._authDataFromLocalstorage!;

        const payload: TokenData = jwt_decode(authData.user_token);
        const isExpired = dayjs.unix(payload.exp).diff(dayjs()) < 1;

        if (isExpired) {
            // Checking if there is no promise in progress of fetching the new authData
            if (!this._authDataFromLocalstorageStrPromise) {
                this._authDataFromLocalstorageStrPromise =
                    authData.issuer === "GOOGLE"
                        ? getGoogleAccessTokenFromGapi()
                        : getMicrosoftAccessTokenFromMsal();

                // On resolve returns the new updated authData
                // and sets _authDataFromLocalstorageStrPromise = undefined for next time the token is expired
                return this._authDataFromLocalstorageStrPromise
                    .then((resAuthData) => {
                        this._authDataFromLocalstorageStrPromise = undefined;
                        return resAuthData;
                    })
                    .catch((err) => {
                        throw new Error(
                            `[asyncAuthDataFromLocalstorage]::${err}`
                        );
                    });
            }
            return this._authDataFromLocalstorageStrPromise;
        }

        //Case that the authData is not expired
        this._authDataFromLocalstorageStrPromise = undefined;
        return Promise.resolve(authData);
    };

    asyncAuthDataFromLocalstorageAuthData = async (): Promise<AuthData> => {
        return this.asyncAuthDataFromLocalstorage()
            .then((authData) => {
                return authData;
            })
            .catch((err) => {
                logoutFromLocalStorage();
                throw err;
            });
    };
}

export const localstorageUtils = new LocalstorageUtils();

export const getUserUuid = () => {
    try {
        const authData = localstorageUtils.authDataFromLocalstorage;

        if (authData === null) {
            throw new Error("Cannot read authData from local storage!");
        }

        if (authData === undefined) {
            return "";
        }

        const payload: TokenData = jwt_decode(authData.user_token);

        return payload.sub;
    } catch (error) {
        errorHandlerProxy({
            error: Error("[getUserUuid]:: logout performed"),
            code: 0,
        });
        showDifftoneAlert(
            "Something went wrong, please log in again.",
            "FAILURE"
        );
        return "err";
    }
};

export const getUserEmail = (): EmailAddress => {
    try {
        const authData = localstorageUtils.authDataFromLocalstorage;

        if (authData === null) {
            throw new Error("Cannot read authData from local storage!");
        }

        if (authData === undefined) {
            return "";
        }

        const payload: TokenData =
            authData.issuer === "GOOGLE"
                ? decodeGoogleUserToken(jwt_decode(authData.user_token))
                : decodeMicrosoftUserToken(jwt_decode(authData.user_token));

        return payload.user_email || "Unknown email address";
    } catch (error) {
        return "";
    }
};

export const getUserFullName = (): string => {
    try {
        const authUserInfo = localstorageUtils.authUserInfoFromLocalStorage;

        if (authUserInfo === null) {
            throw new Error("Cannot read authData from local storage!");
        }

        if (authUserInfo === undefined) {
            return "";
        }
        let fullName = "John Doe";
        if (authUserInfo.first_name && authUserInfo.last_name) {
            fullName = `${authUserInfo.first_name} ${authUserInfo.last_name}`;
        } else if (authUserInfo.full_name) {
            fullName = authUserInfo.full_name;
        } else {
            fullName = "";
        }

        return fullName;
    } catch (error) {
        errorHandlerProxy({
            error: Error("[getUserFullName]:: logout performed"),
            code: 0,
        });
        showDifftoneAlert(
            "Something went wrong, please log in again.",
            "FAILURE"
        );
        return "err";
    }
};

export const getUserAuthProvider = (): string => {
    try {
        const authData = localstorageUtils.authDataFromLocalstorage;

        if (authData === null) {
            throw new Error("Cannot read authData from local storage!");
        }

        if (authData === undefined) {
            return "";
        }

        return authData.issuer;
    } catch (error) {
        errorHandlerProxy({
            error: Error("[getUserAuthProvider]:: logout performed"),
            code: 0,
        });
        showDifftoneAlert(
            "Something went wrong, please log in again.",
            "FAILURE"
        );
        return "err";
    }
};

const getGoogleAccessTokenFromGapi = async () => {
    try {
        await gapi.client.init({
            clientId: getGoogleClientId(),
            scope: GoogleLoginScopes.join(" "),
        });
        const authInstance = await gapi.auth2.getAuthInstance();
        if (!authInstance.isSignedIn.get()) {
            await authInstance.signIn();
        }
        const authUser = authInstance.currentUser.get();
        const authData = authUser.getAuthResponse();
        const newAuthData: AuthData = {
            issuer: "GOOGLE",
            access_token: authData.access_token,
            user_token: authData.id_token!,
        };

        localstorageUtils.authDataFromLocalstorage = newAuthData;

        return newAuthData;
    } catch (error) {
        console.error(
            `[getGoogleAccessTokenFromGapi]:: failed`,
            (error as Error).message
        );
        throw error;
    }
};

const getMicrosoftAccessTokenFromMsal = async () => {
    try {
        const msalInstance = new UserAgentApplication({
            auth: { clientId: getMicrosoftClientId() },
        });

        let tokenData: AuthResponse | undefined;
        try {
            tokenData = await msalInstance.acquireTokenSilent({
                scopes: MicrosoftLoginScopes,
            });
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                tokenData = await msalInstance.acquireTokenPopup({
                    scopes: MicrosoftLoginScopes,
                });
            }
        }

        if (tokenData) {
            const newAuthData: AuthData = {
                issuer: "MICROSOFT",
                access_token: tokenData.accessToken,
                user_token: tokenData.idToken.rawIdToken,
            };

            localstorageUtils.authDataFromLocalstorage = newAuthData;

            return newAuthData;
        } else {
            throw new Error(`Error getting Microsoft Access Token`);
        }
    } catch (error) {
        console.error(
            `[getMicrosoftAccessTokenFromMsal]:: failed`,
            (error as Error).message
        );
        throw error;
    }
};
