import { action } from "mobx";
import { upsertEmailToApi, getEmail } from "@difftone/services";
import { Email, UUID } from "@difftone/types";
import { emailsStore } from "@difftone/stores";

export const emailToMapSetter = action(
    (emailUUID: UUID, email: null | Email) => {
        emailsStore.setEmailToMap(emailUUID, email);
    }
);

export const emailFromApiByUUID = action(async (emailUUID: UUID) => {
    try {
        const fetchedEmail: Email | null | undefined = await getEmail(
            emailUUID
        );

        if (fetchedEmail) {
            emailToMapSetter(emailUUID, fetchedEmail);
        }
        if (!fetchedEmail) {
            emailToMapSetter(emailUUID, null);
        }
    } catch (error) {
        console.error("emailsFromApiByUUIDs::Fetching failed");
        emailToMapSetter(emailUUID, null);
    }
});

export const upsertEmail = async (emailToSave: Email | null) => {
    try {
        if (!emailToSave) {
            return;
        }

        const email = Object.assign({}, emailToSave);

        const newUpsertedEmail = await upsertEmailToApi(email);

        return newUpsertedEmail;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
