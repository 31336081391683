import { UUID } from "@difftone/types";
import { getApiBaseUrl } from "@difftone/constants";
import { errorHandlerProxy, localstorageUtils } from "@difftone/procedures";
import { createDifftoneAxios } from "@difftone/services";

const SERVER_AVG_FILL_TIME_BY_UUID_URL = `${getApiBaseUrl()}/statistics-service-v2/survey-fill-time-average-by-uuid`;
const SERVER_MED_FILL_TIME_BY_UUID_URL = `${getApiBaseUrl()}/statistics-service-v2/survey-fill-time-median-by-uuid`;
const SERVER_AVG_FILL_TIME_BY_UUID_SANS_P_URL = `${getApiBaseUrl()}/statistics-service-v2/survey-fill-time-average-by-uuid-sans-p`;

export const getSurveyAverageFillTimeByUUID = async (
    surveyUUID: UUID
): Promise<number> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(SERVER_AVG_FILL_TIME_BY_UUID_URL, {
        params: {
            issuer: currentAuthData.issuer,
            survey_uuid: surveyUUID,
        },
    });

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    return response.data.resources;
};

export const getSurveyMedianFillTimeByUUID = async (
    surveyUUID: UUID
): Promise<number> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(SERVER_MED_FILL_TIME_BY_UUID_URL, {
        params: {
            issuer: currentAuthData.issuer,
            survey_uuid: surveyUUID,
        },
    });

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    return response.data.resources;
};

export const getSurveyAverageFillTimeSansPByUUID = async (
    surveyUUID: UUID
): Promise<number> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(
        SERVER_AVG_FILL_TIME_BY_UUID_SANS_P_URL,
        {
            params: {
                issuer: currentAuthData.issuer,
                survey_uuid: surveyUUID,
            },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    return response.data.resources;
};
