import React, { ChangeEvent, FocusEvent, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import {
    Question,
    QuestionsDuplicationValidationType,
    SingleChoiceQuestion,
    ValidationResult,
} from "@difftone/types";
import {
    getValidationErrosByErrorCode,
    updateQuestionInWizard,
    validateWizardSurvey,
} from "@difftone/actions";
import { wizardStore } from "@difftone/stores";
import {
    getSurveyFromStoreByURL,
    getValidationErrorsFromStoreByUUID,
} from "@difftone/reducers";
import { ValidationWrapper } from "@difftone/shared-components";
import { warningMessages } from "@difftone/constants";

import {
    single_choice_option,
    option_index,
    choice_edit_input,
    other_choice_class,
    invalid_color_input,
} from "./single-choice-option.module.css";

const MAX_ALLOWED_SINGLE_CHOICE_QUESTION_OPTION_LENGTH = 50;

export type SingleChoiceOptionProps = {
    option: string;
    index: number;
    isLastOption: boolean;
    item: Question<SingleChoiceQuestion>;
    onItemKeyDown: (event: KeyboardEvent, index: number) => void;
    addRef: (ref: any, index: number) => void;
};

export const SingleChoiceOption = observer((props: SingleChoiceOptionProps) => {
    const { focusedWizardInputCard } = wizardStore;
    const { index, isLastOption, option, item, onItemKeyDown, addRef } = props;

    const inputRef: React.MutableRefObject<HTMLInputElement | null> =
        useRef(null);

    const survey = getSurveyFromStoreByURL();

    const isFocusedWizardCardItem =
        focusedWizardInputCard?.getAttribute("uuid") === item.uuid;

    const itemValidationErrors = getValidationErrorsFromStoreByUUID(
        item.uuid,
        survey
    );

    const itemOptionsUniquenessValidations = getValidationErrosByErrorCode(
        "QUESTION_OPTION_NAMES_DUPLICATION",
        itemValidationErrors
    ) as ValidationResult<QuestionsDuplicationValidationType>[];

    const isValid = !(
        itemOptionsUniquenessValidations.length > 0 &&
        itemOptionsUniquenessValidations[0]?.validation_error_type
            ?.duplication_indexes[option] &&
        itemOptionsUniquenessValidations[0]?.validation_error_type
            ?.duplication_indexes[option].length > 0 &&
        itemOptionsUniquenessValidations[0]?.validation_error_type
            ?.duplication_indexes[option][0] === index
    );

    let optionClassName = choice_edit_input;
    if (!isValid && isFocusedWizardCardItem) {
        optionClassName = clsx(choice_edit_input, invalid_color_input);
    }

    const onFocusHandler = (event: FocusEvent<HTMLInputElement>) => {
        event.target.select();
    };

    const onBlurHandler = () => {
        validateWizardSurvey();
    };

    const onOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        const indexToUpate = JSON.parse(
            event.currentTarget.getAttribute("data-index") as string
        );
        const copyOfCurrentQuestionChoices = [...item.content.choice_options];
        copyOfCurrentQuestionChoices[indexToUpate].label = event.target.value;
        const newContent = {
            ...item.content,
            choice_options: copyOfCurrentQuestionChoices,
        };

        updateQuestionInWizard(
            item as Question<SingleChoiceQuestion>,
            "content",
            newContent
        );

        validateWizardSurvey();
    };

    addRef(inputRef, index);

    //Use effect used to focus option if it is newly added.
    //We have no other way to get a newly added option because the new option is added to the survey object and then the element renders
    useEffect(() => {
        if (isLastOption && inputRef.current && index > 1) {
            inputRef.current.focus();
        }
    }, [index, isLastOption]);

    return (
        <div
            className={single_choice_option}
            onKeyDown={(e: any) => onItemKeyDown(e, index)}
        >
            <span className={option_index}>{index + 1}</span>
            <ValidationWrapper
                errorMessage={warningMessages.questionOptionsNotUniqueError}
                isValid={isValid || !isFocusedWizardCardItem}
                tooltipPosition="RIGHT"
                tooltipPositionOverride={
                    option.length > 0
                        ? { left: `calc(30px + ${option.length * 7}px)` }
                        : { left: "95px" }
                }
                mobileTooltipPosition={
                    option.length > 0
                        ? { left: `calc(12px + ${option.length * 6}px)` }
                        : { left: "40px" }
                }
            >
                <input
                    ref={inputRef}
                    maxLength={MAX_ALLOWED_SINGLE_CHOICE_QUESTION_OPTION_LENGTH}
                    style={
                        option.length > 0
                            ? { width: `${option.length * 11.5}px` }
                            : { width: "200px" }
                    }
                    onBlur={onBlurHandler}
                    onFocus={onFocusHandler}
                    onChange={onOptionChange}
                    data-index={index}
                    className={
                        option === "Other"
                            ? clsx(optionClassName, other_choice_class)
                            : optionClassName
                    }
                    type="text"
                    placeholder={`Option ${index + 1}`}
                    value={option}
                />
            </ValidationWrapper>
        </div>
    );
});
