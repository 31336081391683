import React from "react";
import { EmailAddress } from "@difftone/types";
import userNonDeletedMailingIcon from "@difftone/assets/nondeleted-user-mailing-icon.svg";
import { alertsMailingListGroupTitles } from "@difftone/constants";
import { HumanizeEmail } from "@difftone/shared-components";

import {
    mailing_list_item,
    credentials,
    user_email,
} from "./nondeleteble-email-wrapper.module.css";

export type NonDeletebleEmailWrapperProps = {
    email: EmailAddress;
    isGroupEmail: boolean;
};

export const NonDeletebleEmailWrapper = (
    props: NonDeletebleEmailWrapperProps
) => {
    const { email, isGroupEmail } = props;

    const _email = alertsMailingListGroupTitles[email] || email;

    return (
        <div id="list_items" className={mailing_list_item}>
            {isGroupEmail ? null : (
                <div className={credentials}>
                    <img src={userNonDeletedMailingIcon} alt="user-icon" />
                </div>
            )}
            <div className={user_email}>
                <HumanizeEmail humanizeEmail={_email} />
            </div>
        </div>
    );
};
