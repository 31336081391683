import React, { useState } from "react";
import clsx from "clsx";
import { IconType } from "@difftone/types";
import { AddBoxShadow, ShowDifftoneTooltip } from "@difftone/shared-components";
import successTick from "@difftone/assets/success-tick-no-circle.svg";
import successTickBlack from "@difftone/assets/success-tick-black.svg";

import { DynamicIcon } from "./components";
import {
    dropdown_options_wrapper,
    dropdown_options,
    dropdown_option,
    selected_option,
    success_tick,
    unselected_option,
    hover_option,
    dropdown_counter,
    selected_counter,
    hover_counter,
    unselected_counter,
    text_scope,
    display_value,
    dynamic_icon_scope,
} from "./options-list.module.css";

export type Option = {
    systemValue: string;
    displayValue: string;
    urlValue: string;
    iconType?: IconType;
    isDisabled?: boolean;
    counterValue?: number;
};

export type OptionsListProps = {
    options: Option[];
    selectOption: Option | undefined;
    onChange: (selectedValue: Option) => void;
};
const MAX_ALLOWED_CHARS_IN_ANSWER = 42;

export const OptionsList = (props: OptionsListProps) => {
    const { options, selectOption, onChange } = props;

    const [mouseHoverOption, setMouseHoverOption] = useState<string>("");

    if (!selectOption) {
        return null;
    }

    const onMouseDown = (option: Option) => {
        if (!option.isDisabled) {
            onChange(option);
            setMouseHoverOption("");
        }
    };
    const onMouseOver = (option: Option) => {
        setMouseHoverOption(option.displayValue);
    };
    const onMouseOut = (option: Option) => {
        setMouseHoverOption("");
    };

    return (
        <AddBoxShadow>
            <div className={dropdown_options_wrapper}>
                <ul className={dropdown_options}>
                    {options.map((option, index) => {
                        return (
                            <li
                                className={clsx(
                                    dropdown_option,
                                    option.displayValue ===
                                        selectOption.displayValue &&
                                        selected_option,
                                    option.displayValue === mouseHoverOption &&
                                        hover_option,
                                    option.isDisabled && unselected_option
                                )}
                                onMouseDown={() => onMouseDown(option)}
                                onMouseOver={() => onMouseOver(option)}
                                onMouseOut={() => onMouseOut(option)}
                                data-value={option.urlValue}
                                data-index={index}
                                key={option.urlValue}
                            >
                                {option.iconType && (
                                    <DynamicIcon
                                        classNameOverride={dynamic_icon_scope}
                                        iconType={option.iconType}
                                        isSelected={
                                            option.displayValue ===
                                            selectOption.displayValue
                                        }
                                        isMouseHover={
                                            option.displayValue ===
                                            mouseHoverOption
                                        }
                                        isDisabled={option.isDisabled || false}
                                    />
                                )}

                                <div className={text_scope}>
                                    {option.displayValue.length >=
                                    MAX_ALLOWED_CHARS_IN_ANSWER ? (
                                        <ShowDifftoneTooltip
                                            tip={option.displayValue}
                                            tooltipPosition="right"
                                        >
                                            {option.displayValue}
                                        </ShowDifftoneTooltip>
                                    ) : (
                                        <div className={display_value}>
                                            {option.displayValue}
                                        </div>
                                    )}
                                    {option.counterValue ? (
                                        <div
                                            className={clsx(
                                                dropdown_counter,
                                                option.displayValue ===
                                                    selectOption.displayValue &&
                                                    selected_counter,
                                                option.displayValue ===
                                                    mouseHoverOption &&
                                                    hover_counter,
                                                option.isDisabled &&
                                                    unselected_counter
                                            )}
                                        >{`(${option.counterValue})`}</div>
                                    ) : null}
                                </div>
                                {option.displayValue ===
                                    selectOption.displayValue && (
                                    <img
                                        className={success_tick}
                                        alt=""
                                        src={
                                            option.displayValue ===
                                            mouseHoverOption
                                                ? successTick
                                                : successTickBlack
                                        }
                                    />
                                )}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </AddBoxShadow>
    );
};
