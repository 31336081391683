export const addLinkToClipboard = (
    link: string,
    callback?: () => void
): void => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(encodeURI(link)).then(
            () => {
                if (callback) callback();
            },
            (err) => {
                console.error("Async: Could not copy to clipboard: ", err);
            }
        );
    } else console.error("Clipboard API is not available");
};
