import React, { useState } from "react";
import { observer } from "mobx-react";
import {
    Answer,
    Question,
    ShortTextAnswer,
    ShortTextQuestion,
} from "@difftone/types";
import { surveyResponseStore } from "@difftone/stores";
import { updateSelectedSurveyNonFilterQuestionResponse } from "@difftone/actions";
import { getAnswerByQuestion } from "@difftone/reducers";
import { Spinner } from "@difftone/shared-components";

import {
    expanded_short_text_container,
    characters_counter,
} from "./expanded-short-text.module.css";

const MAX_CHARACTERS_ALLOWED = 80;

export type ExpandedShortTextProps = {
    disabled: boolean;
    question: Question<ShortTextQuestion>;
    inPreviewMode: boolean;
    isEditableQuestion: boolean;
};

export const ExpandedShortText = observer((props: ExpandedShortTextProps) => {
    const { question, disabled, inPreviewMode, isEditableQuestion } = props;
    const [charactersCounter, setCharactersCounter] = useState(
        MAX_CHARACTERS_ALLOWED
    );
    let surveyResponseUUID: string | undefined;

    if (inPreviewMode) {
        surveyResponseUUID = "";
    } else {
        const { getSelectedSurveyResponseUUID } = surveyResponseStore;
        surveyResponseUUID = getSelectedSurveyResponseUUID();
    }

    if (surveyResponseUUID === undefined) {
        return <Spinner size="MEDIUM" />;
    }

    const answer = getAnswerByQuestion(
        question,
        surveyResponseUUID
    ) as Answer<ShortTextAnswer>;

    const onChange = (event: React.FormEvent<EventTarget>) => {
        const target = event.target as HTMLTextAreaElement;

        if (inPreviewMode) {
            return;
        }

        answer.content.text =
            target.value.length <= 1 ? target.value.trim() : target.value;
        setCharactersCounter(MAX_CHARACTERS_ALLOWED - target.value.length);
        updateSelectedSurveyNonFilterQuestionResponse(
            answer,
            surveyResponseUUID!
        );
    };

    return (
        <div className={expanded_short_text_container}>
            <textarea
                disabled={disabled || !isEditableQuestion}
                onChange={onChange}
                placeholder="write free text"
                value={answer.content.text}
                rows={1}
                maxLength={MAX_CHARACTERS_ALLOWED}
            />
            <div className={characters_counter}> {charactersCounter} </div>
        </div>
    );
});
