import React from "react";

import { difftone_about, title } from "./difftone-about.module.css";

export type DifftoneAboutProps = { open: () => void };

export const DifftoneAbout = (props: DifftoneAboutProps) => {
    const { open } = props;
    const onClickHandler = () => {
        open();
    };
    return (
        <div onClick={onClickHandler} className={difftone_about}>
            <div className={title}>About Us</div>
        </div>
    );
};
