import React, {
    ChangeEvent,
    KeyboardEvent,
    createRef,
    Ref,
    useState,
} from "react";

import { inboxStore, navigationStore } from "@difftone/stores";
import { setFilterProfile, setLocation } from "@difftone/actions";
import { INBOX_URL } from "@difftone/constants";

import clearSearchIcon from "@difftone/assets/clear-search-bar-icon.svg";
import {
    mobile_searcbar,
    clear_search_text,
} from "./mobile-search-bar.module.css";

const FILTERS_KEYS = [
    "Name: ",
    "Initiator: ",
    "Issued-date: ",
    "Last-date: ",
    "Status: ",
    "Progress: ",
];

export const MobileSearchBar = () => {
    const { currentPage } = navigationStore;
    const { filterProfile } = inboxStore;
    const pageLocation = currentPage.split("/")[1];

    const [startInputPosition] = useState(FILTERS_KEYS[0].length);
    const inputRef: Ref<HTMLInputElement> = createRef();
    const [quickSearchText, setQuickSearchText] = useState(
        filterProfile.survey_name
            ? `${FILTERS_KEYS[0]}${filterProfile.survey_name}`
            : ""
    );

    const handleSearchIconClicked = () => {
        inputRef.current?.focus();
        const caretPosition = inputRef.current?.selectionStart || 0;

        if (inputRef.current?.value === "") {
            setQuickSearchText(FILTERS_KEYS[0]);
        }
        if (caretPosition <= startInputPosition) {
            inputRef.current?.setSelectionRange(
                startInputPosition,
                startInputPosition
            );
        }
    };

    const handleSearchBarClearText = () => {
        if (quickSearchText.includes(FILTERS_KEYS[0])) {
            setFilterProfile("NAME", "");
            setQuickSearchText("");
        }
    };

    const filterProfileHandler = (value: string) => {
        switch (value) {
            case `${value.startsWith("Name: ") ? value : null}`:
                const splitted = value.split(" ");
                splitted.shift();
                const searchValue = splitted.join(" ") || "";
                setFilterProfile("NAME", searchValue);
                break;
            default:
                return;
        }
    };

    const handleOnChangeUserInput = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const caretPosition = event.target.selectionStart || 0;

        if (caretPosition < startInputPosition) {
            event.target.selectionStart = startInputPosition;
            return;
        }
        if (caretPosition < startInputPosition) {
            event.target.selectionStart = startInputPosition;
        }
        filterProfileHandler(value);
        setQuickSearchText(value);
    };

    const onKeyDownHandler = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            if (pageLocation !== "inbox") {
                setLocation(INBOX_URL);
            }
            const { value } = event.currentTarget;

            filterProfileHandler(value);
            setQuickSearchText(value);
        }
    };
    const onKeyUpHandler = (event: KeyboardEvent<HTMLInputElement>) => {
        const caretPosition = event.currentTarget.selectionStart || 0;
        if (event.key === "ArrowLeft") {
            if (caretPosition < startInputPosition) {
                event.currentTarget.selectionStart = startInputPosition;
            }
        }
    };

    return (
        <div className={mobile_searcbar}>
            <input
                autoComplete="off"
                onClick={handleSearchIconClicked}
                onChange={handleOnChangeUserInput}
                onKeyDown={onKeyDownHandler}
                onKeyUp={onKeyUpHandler}
                value={quickSearchText}
                ref={inputRef}
                placeholder="Quick Search"
                type="text"
            />
            <img
                onClick={handleSearchBarClearText}
                className={clear_search_text}
                src={clearSearchIcon}
                alt="clear-text"
            />
        </div>
    );
};
