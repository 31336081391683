import React from "react";

import { IsChecked } from "./components";
import { checkbox_wrapper } from "./checkbox.module.css";

const BACKGROUND_CHECKED: string = "#edf1f3";
const BACKGROUND_UNCHECKED: string = "#fff";

const BORDER_COLOR_DISABLED: string = "#B6B8BA";
const BORDER_COLOR_UNDISABLED: string = "#035375";

export type CheckboxProps = {
    onClick: (checked: boolean) => void;
    checked: boolean;
    disabled?: boolean;
};

export const Checkbox = (props: CheckboxProps) => {
    const { disabled, checked, onClick } = props;

    return (
        <div
            onClick={() => !disabled && onClick(checked)}
            style={{
                background: checked ? BACKGROUND_CHECKED : BACKGROUND_UNCHECKED,
                borderColor: disabled
                    ? BORDER_COLOR_DISABLED
                    : BORDER_COLOR_UNDISABLED,
            }}
            className={checkbox_wrapper}
        >
            {checked && <IsChecked disabled={disabled} />}
        </div>
    );
};
