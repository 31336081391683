import React, { MouseEvent } from "react";

import mailingListIcon from "@difftone/assets/nondeleted-user-mailing-icon.svg";
import { alertsMailingListGroupTitles } from "@difftone/constants";

import {
    mailing_item,
    active_mailing_list_item,
    groups_separator_line,
} from "./autocomplete-item.module.css";

export type AutocompleteItemProps = {
    showGroupsSeparator: boolean;
    email: string;
    onClick: (event: MouseEvent<HTMLDivElement>) => void;
    onHover: (event: MouseEvent<HTMLDivElement>) => void;
    isActiveItem: boolean;
    index: number;
};

export const AutocompleteItem = (props: AutocompleteItemProps) => {
    const {
        email,
        onClick,
        onHover,
        isActiveItem,
        index,
        showGroupsSeparator,
    } = props;

    const _email = alertsMailingListGroupTitles[email] || email;

    const isGroupItem = !!alertsMailingListGroupTitles[email];

    return (
        <>
            <div
                onMouseDown={onClick}
                onMouseOver={onHover}
                data-autocomplete-mail={email}
                data-index={index}
                className={[
                    mailing_item,
                    isActiveItem && active_mailing_list_item,
                ].join(" ")}
            >
                {isGroupItem ? null : (
                    <img src={mailingListIcon} alt="email-icon" />
                )}
                <div>{_email}</div>
            </div>
            {showGroupsSeparator && <div className={groups_separator_line} />}
        </>
    );
};
