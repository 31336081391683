import React, { useState, MouseEvent } from "react";

import { TableHeaderCell, FilterTypes } from "./components";

import {
    table_header,
    table_header_name,
    table_header_end,
    table_header_overall_responses_bar,
    table_header_response_rate,
} from "./table-header.module.css";

export const TableHeader = () => {
    //TODO : only for internal component state
    const [activeFilter, setActiveFilter] = useState<FilterTypes | null>(
        "NAME"
    );

    const setFilter = (event: MouseEvent<HTMLDivElement>) => {
        const filterType = event.currentTarget.getAttribute(
            "data-filter"
        ) as FilterTypes;
        if (filterType) {
            setActiveFilter(filterType);
        }
    };

    return (
        <div className={table_header}>
            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "NAME"}
                filterType="NAME"
                text="Name"
                className={table_header_name}
            />
            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "END"}
                filterType="END"
                text="Date"
                className={table_header_end}
            />
            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "OVERALL_RESPONSES"}
                filterType="OVERALL_RESPONSES"
                text="Overall responses"
                className={table_header_overall_responses_bar}
            />
            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "RESPONSE_RATE"}
                filterType="RESPONSE_RATE"
                text="Response rate"
                className={table_header_response_rate}
            />
        </div>
    );
};
