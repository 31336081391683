import React from "react";
import { Question, QuestionType } from "@difftone/types";

import { not_supported } from "./not-supported-question.module.css";

export type NotSupportedQuestionProps = {
    question: Question<QuestionType>;
};

export const NotSupportedQuestion = (props: NotSupportedQuestionProps) => {
    const { question } = props;
    const displayedTypeName = question.question_type_name
        .toLowerCase()
        .split("_")
        .join(" ");

    return (
        <div className={not_supported}>
            '{displayedTypeName} Question' is not supported
        </div>
    );
};
