import React from "react";
import { observer } from "mobx-react";

import {
    closeInitiatorListPopup,
    openInitiatorListPopup,
} from "@difftone/actions";
import { dashboardDisplayStore, publicProfileStore } from "@difftone/stores";
import { Spinner } from "@difftone/shared-components";
import { FilterByDisplayPicker, InitiatorListCheckbox } from "./components";
import {
    filter_by_generator_wrapper,
    extend_spinner,
} from "./dashboard-filter-by-generator.module.css";

export type DashboardsFilterByGeneratorProps = {};

export const DashboardsFilterByGenerator = observer(
    (props: DashboardsFilterByGeneratorProps) => {
        const { isOpenedInitiatorListPopup } = dashboardDisplayStore;
        const { publicProfileList } = publicProfileStore;

        if (!publicProfileList.length) {
            return <Spinner className={extend_spinner} size="X-SMALL" />;
        }

        const openInitiatorList = () => {
            openInitiatorListPopup();
        };

        const closeInitiatorList = () => {
            closeInitiatorListPopup();
        };

        return (
            <div className={filter_by_generator_wrapper}>
                <FilterByDisplayPicker
                    openInitiatorList={openInitiatorList}
                    publicProfileList={publicProfileList}
                />
                {isOpenedInitiatorListPopup ? (
                    <InitiatorListCheckbox
                        hideList={closeInitiatorList}
                        publicProfileList={publicProfileList}
                    />
                ) : null}
            </div>
        );
    }
);
