import React from "react";

type Color = "GREEN" | "GRAY" | "BRIGHT_GREEN";

export type UserIconProps = {
    color: Color;
    className?: "";
};

export const UserIcon = (props: UserIconProps) => {
    const { color, className } = props;

    const colors = {
        GREEN: "#009788",
        GRAY: "#B2C0C8",
        BRIGHT_GREEN: "#80CBC4",
    };

    return (
        <svg
            className={className}
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.50016 8.61107C10.5223 8.61107 12.1668 6.96657 12.1668 4.9444V4.33329C12.1668 2.31113 10.5223 0.666626 8.50016 0.666626C6.478 0.666626 4.8335 2.31113 4.8335 4.33329V4.9444C4.8335 6.96657 6.478 8.61107 8.50016 8.61107Z"
                fill={colors[color]}
            />
            <path
                d="M12.9746 10.4329C10.0999 9.63662 6.90078 9.63662 4.0255 10.4329C2.70244 10.7996 1.77783 12.0114 1.77783 13.3809V15.3334H15.2223V13.3809C15.2223 12.0114 14.2977 10.7996 12.9746 10.4329Z"
                fill={colors[color]}
            />
        </svg>
    );
};
