import React, { Ref, useEffect, useRef } from "react";
import clsx from "clsx";

import { AlertVariants } from "@difftone/types";

import {
    alert_button,
    success,
    failure,
    general,
    general_reject,
    failure_reject,
    success_reject,
} from "./alert-button.module.css";

export type AlertButtonProps = {
    variant: AlertVariants;
    buttonText: string;
    onClick: () => void;
    autofocus?: boolean;
    marginRight?: number;
    isRejectBtn?: boolean;
};

export const AlertButton = (props: AlertButtonProps) => {
    const { buttonText, variant, onClick, marginRight, isRejectBtn } = props;
    // In order to store button element for focusing it
    const buttonRef: Ref<HTMLButtonElement> = useRef(null);

    let classByVariant = "";
    let rejectBtnClass = "";
    switch (variant) {
        case "SUCCESS":
        case "MOBILE_SUPORT":
            classByVariant = success;
            rejectBtnClass = success_reject;
            break;
        case "FAILURE":
            classByVariant = failure;
            rejectBtnClass = failure_reject;
            break;
        case "GENERAL":
        case "DELETE":
            classByVariant = general;
            rejectBtnClass = general_reject;
            break;
        case "PERMISSIONS":
            classByVariant = failure;
            rejectBtnClass = failure_reject;
            break;
        default:
            classByVariant = general;
            rejectBtnClass = general_reject;
            break;
    }

    const handleOnKeyPress = () => {
        onClick();
    };

    useEffect(() => {
        // Timeout is needed so that button will be focused after all key events happened
        setTimeout(() => {
            if (buttonRef.current) {
                buttonRef.current.focus();
            }
        }, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buttonRef.current]);

    return (
        <button
            ref={buttonRef}
            onKeyPress={handleOnKeyPress}
            onClick={onClick}
            style={marginRight ? { marginRight: `${marginRight}px` } : {}}
            className={clsx(
                alert_button,
                classByVariant,
                isRejectBtn && rejectBtnClass
            )}
        >
            {buttonText}
        </button>
    );
};
