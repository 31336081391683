import React from "react";
import clsx from "clsx";
import { observer } from "mobx-react";

import {
    Question,
    QuestionToAnswerDescreteQuestion,
    QuestionType,
    ResultsMapAfterDigestion,
    SupportedQuestionResultsMapAfterDigestion,
    SupportedQuestionResultsMapAfterDigestionWithoutNulls,
    SurveyClasses,
} from "@difftone/types";
import { changeQuestionResultsTableIsOpen } from "@difftone/actions";
import { resultsDisplayStore } from "@difftone/stores";
import {
    Spinner,
    ResultsBoxFactoryByQuestionType,
    NumericExpandedQuestion,
} from "@difftone/shared-components";
import { COLUMN_0 } from "@difftone/common-constants";

import {
    QuestionDescription,
    NotSupportedQuestion,
    ValuesPlain,
    QuestionDetails,
} from "./components";

import {
    results_question,
    border_spacer,
    collapsed_section,
    result_question_wrapper,
    not_supported_info_wrapper,
    not_supported_results_question,
    open_results_question,
    open_border_spacer,
    results_question_content_toggled,
    results_question_content,
} from "./results-question.module.css";

// TODO: move the common
const CLOSED_QUESTIONS_TYPES_NAMES = [
    "SINGLE_CHOICE",
    "MULTIPLE_CHOICE",
    "RATING",
    "LONG_TEXT",
    "SHORT_TEXT",
    "TIME",
    "DATE",
];

export type ResultsQuestionProps = {
    question: Question<QuestionType>;
    questionIndex: number;
    digestedResultsRow: ResultsMapAfterDigestion;
    survey: SurveyClasses;
};

export const ResultsQuestion = observer((props: ResultsQuestionProps) => {
    const { question, questionIndex, digestedResultsRow, survey } = props;
    const { resultsTableQuestions } = resultsDisplayStore;

    const collapsedChangeToggler = () => {
        changeQuestionResultsTableIsOpen(
            question.uuid,
            !selectedTableQuestion.isOpen
        );
    };

    const selectedTableQuestion = Object.values(resultsTableQuestions).find(
        (resultsTableQuestion) =>
            resultsTableQuestion.questionUUID === question.uuid
    )!;

    if (!digestedResultsRow) {
        return <Spinner size="MEDIUM" />;
    }

    //digestedResultsRow can be "NOT_SUPPORTED"
    if (
        digestedResultsRow &&
        digestedResultsRow[COLUMN_0] &&
        (digestedResultsRow[COLUMN_0] as QuestionToAnswerDescreteQuestion)[
            "%QUESTION_NOT_SUPPORTED%"
        ] === 0
    ) {
        return (
            <div>
                <div className={not_supported_results_question}>
                    <QuestionDescription
                        toggleCollapse={collapsedChangeToggler}
                        collapsed={selectedTableQuestion.isOpen}
                        questionIndex={questionIndex}
                        question={question}
                    />
                    <div className={not_supported_info_wrapper}>
                        <NotSupportedQuestion question={question} />
                    </div>
                </div>
            </div>
        );
    }

    const nakedDigestedResultsRow: SupportedQuestionResultsMapAfterDigestion =
        {};
    Object.keys(digestedResultsRow).forEach((columnKey) => {
        nakedDigestedResultsRow[columnKey] = JSON.parse(
            JSON.stringify(digestedResultsRow[columnKey])
        );
    });

    const resultsQuestion = selectedTableQuestion.isOpen
        ? `${results_question} ${open_results_question}`
        : results_question;

    const borderSpace = selectedTableQuestion.isOpen
        ? `${border_spacer} ${open_border_spacer}`
        : border_spacer;

    return (
        <div className={result_question_wrapper}>
            <div className={resultsQuestion}>
                <div
                    className={clsx(results_question_content, {
                        [results_question_content_toggled]:
                            selectedTableQuestion.isOpen,
                    })}
                >
                    <QuestionDescription
                        toggleCollapse={collapsedChangeToggler}
                        collapsed={selectedTableQuestion.isOpen}
                        questionIndex={questionIndex}
                        question={question}
                    />
                    <div className={borderSpace}>
                        <ValuesPlain
                            question={question}
                            resultPopularity={nakedDigestedResultsRow}
                            collapsed={selectedTableQuestion.isOpen}
                        />
                    </div>
                </div>

                {selectedTableQuestion.isOpen && (
                    <QuestionDetails
                        question={question}
                        survey={survey}
                        resultsMap={digestedResultsRow}
                    />
                )}
            </div>

            {Object.keys(nakedDigestedResultsRow)?.map((columnKey, index) => {
                if (columnKey !== COLUMN_0) {
                    return null;
                }

                return (
                    <div key={columnKey}>
                        <div>
                            <ResultsBoxFactoryByQuestionType
                                question={question}
                                index={index}
                                digestedResultsRow={
                                    nakedDigestedResultsRow as SupportedQuestionResultsMapAfterDigestionWithoutNulls
                                }
                                columnKey={columnKey}
                                survey={survey}
                            />
                        </div>
                        <div>
                            {selectedTableQuestion.isOpen ? (
                                <div className={collapsed_section}>
                                    {CLOSED_QUESTIONS_TYPES_NAMES.includes(
                                        question.question_type_name
                                    ) ? (
                                        <NumericExpandedQuestion
                                            columnKey={columnKey}
                                            answersFrequency={
                                                nakedDigestedResultsRow[
                                                    columnKey
                                                ]
                                            }
                                            question={question}
                                            survey={survey}
                                        />
                                    ) : (
                                        <div>
                                            Nothing to display for this type of
                                            question
                                        </div>
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </div>
                );
            })}
        </div>
    );
});
