import { action } from "mobx";
import { v4 as uuid } from "uuid";
import {
    EmailAddress,
    BaseSurvey,
    SurveyEvent,
    SurveyEventAlert,
    SimpleSurvey,
} from "@difftone/types";
import { ChangeEvent } from "react";
import {
    alertsStore,
    groupEmailsStore,
    wizardDisplayStore,
} from "@difftone/stores";
import {
    debounceUpsertSurveyToApi,
    showDifftoneAlert,
    cancelEmail,
} from "@difftone/actions";
import {
    constantAlertVariants,
    getDynamicAlertVariantForOrganizationEmailValidation,
} from "@difftone/constants";
import {
    getSelectedOrganizationOrThrow,
    getSurveyFromStoreByURL,
    isAlertEmailGroup,
    isEmailFromOrganisation,
} from "@difftone/reducers";

export const debounceSetAlertToStore = action((alert: SurveyEventAlert) => {
    alertsStore.debounceInsertAlertToStore(alert);
});

export const updateAlertTime = (alert: SurveyEventAlert) => {
    const alertCopy: SurveyEventAlert = JSON.parse(JSON.stringify(alert));
    alertCopy.time_offset = 0;
    alertsStore.InsertAlertToStoreWithoutDebounce(alertCopy);
};

export const optionalMessageChangeHandler = action(
    (event: ChangeEvent<HTMLTextAreaElement>, alert: SurveyEventAlert) => {
        alert.content.message = event.target.value;
        alertsStore.debounceInsertAlertToStore(alert);
        debounceUpsertSurveyToApi();
    }
);

export const removeEmailFromAlertsInWizard = action(
    async (survey: SimpleSurvey, emailToDelete: EmailAddress) => {
        survey.survey_events.forEach((surveyEvent) => {
            surveyEvent.event_alerts.forEach(async (alertUUID) => {
                const alert = await alertsStore.asyncGetSurveyAlertCopy(
                    alertUUID
                );

                if (!alert) {
                    showDifftoneAlert(
                        constantAlertVariants.find_alert.text,
                        constantAlertVariants.find_alert.variant
                    );
                    return;
                }

                if (alert.send_to.includes(emailToDelete)) {
                    alert.send_to = alert.send_to.filter(
                        (email) => email !== emailToDelete
                    );
                    if (!alert.alert_type && !alert.send_to.length) {
                        deleteAlert(alert.uuid);
                    }
                    alertsStore.debounceInsertAlertToStore(alert);
                }
            });
        });
    }
);

export const addEmailsToSurveySystemAlerts = action(
    (emailsToAdd: EmailAddress[], survey: SimpleSurvey) => {
        const publishedDateEvent: SurveyEvent | undefined =
            survey.survey_events.find(
                (surveyEvent) =>
                    surveyEvent.system_survey_event_type === "PUBLISHED_DATE"
            );

        if (!publishedDateEvent) {
            showDifftoneAlert(
                constantAlertVariants.survey_event.text,
                constantAlertVariants.survey_event.variant
            );
            return;
        }
        const publishedDateAlertCopy: SurveyEventAlert | null | undefined =
            publishedDateEvent.event_alerts
                .map((alertUUID) =>
                    alertsStore.getSurveyAlertCopyByUuid(alertUUID)
                )
                .find(
                    (eventAlert) =>
                        eventAlert?.alert_type === "PUBLISHED_DATE_ALERT"
                );

        if (!publishedDateAlertCopy) {
            showDifftoneAlert(
                constantAlertVariants.update_an_alert.text,
                constantAlertVariants.update_an_alert.variant
            );
            return;
        }
        if (publishedDateAlertCopy.alert_status === "APPROVED_BY_INITIATOR") {
            publishedDateAlertCopy.uuid = uuid();
            publishedDateAlertCopy.alert_type = "EXTEND_PUBLISHED_DATE_ALERT";
            publishedDateAlertCopy.alert_status = "NEWLY_CREATED";
            delete publishedDateAlertCopy.alert_email_send_status;
            publishedDateAlertCopy.send_to = emailsToAdd;
            publishedDateEvent.event_alerts.push(publishedDateAlertCopy.uuid);
        } else {
            publishedDateAlertCopy.send_to = [
                ...publishedDateAlertCopy.send_to,
                ...emailsToAdd,
            ];
        }
        alertsStore.InsertAlertToStoreWithoutDebounce(publishedDateAlertCopy);
    }
);

export const removeMailingListItem = action(
    (emailToDelete: EmailAddress, alert: SurveyEventAlert) => {
        alert.send_to = alert.send_to.filter(
            (email: EmailAddress) => email !== emailToDelete
        );
        wizardDisplayStore.isEditableSurvey
            ? alertsStore.debounceInsertAlertToStore(alert)
            : wizardDisplayStore.insertCopyAlertToStore(alert);
    }
);

export const addMultipleMailingListItems = action(
    (emails: EmailAddress[], alert: SurveyEventAlert) => {
        const organizationEmails: EmailAddress[] = [];
        const notOrganizationEmails: EmailAddress[] = [];

        emails.forEach((email) => {
            if (!isEmailFromOrganisation(email) && !isAlertEmailGroup(email)) {
                notOrganizationEmails.push(email);
                return;
            }
            organizationEmails.push(email);
        });

        if (notOrganizationEmails.length) {
            const organization = getSelectedOrganizationOrThrow();
            const organizationName = organization ? organization.name : "";
            showDifftoneAlert(
                getDynamicAlertVariantForOrganizationEmailValidation(
                    organizationName,
                    notOrganizationEmails
                ),
                "FAILURE"
            );
        }

        organizationEmails.forEach((email) => {
            const resolvedEmails = groupEmailsStore.getResolvedEmails(
                email,
                getSelectedOrganizationOrThrow()!
            );
            switch (resolvedEmails) {
                case undefined:
                    break;
                case null:
                    //TODO: refine and make const
                    showDifftoneAlert("Could not resolve email", "FAILURE");
                    break;
                default:
                    wizardDisplayStore.isEditableSurvey
                        ? alertsStore.addEmailsToAlert(resolvedEmails, alert)
                        : wizardDisplayStore.addEmailsToAlertCopy(
                              resolvedEmails,
                              alert
                          );
                    break;
            }
        });
    }
);

export const deleteAlert = action((uuidToDelete: string) => {
    const survey = getSurveyFromStoreByURL();

    switch (survey.survey_class) {
        case "ONGOING":
            throw Error("This operation is not available on ongoing survey");
        case "SIMPLE":
        default:
            const surveyEvent = (survey as SimpleSurvey).survey_events.find(
                (_surveyEvent) =>
                    _surveyEvent.event_alerts.includes(uuidToDelete)
            );
            const copyOfAlertToRemove: SurveyEventAlert | undefined | null =
                alertsStore.getSurveyAlertCopyByUuid(uuidToDelete);

            if (!copyOfAlertToRemove || !surveyEvent) {
                showDifftoneAlert(
                    constantAlertVariants.delete_an_alert.text,
                    constantAlertVariants.delete_an_alert.variant
                );
                return;
            }

            copyOfAlertToRemove.is_deleted = true;
            if (copyOfAlertToRemove.email_uuid)
                cancelEmail(copyOfAlertToRemove.email_uuid);
            alertsStore.InsertAlertToStoreWithoutDebounce(copyOfAlertToRemove);
            surveyEvent.event_alerts = surveyEvent.event_alerts.filter(
                (_alertUUID) => _alertUUID !== uuidToDelete
            );

            debounceUpsertSurveyToApi();
            break;
    }
});

export const deleteAllAlertsOnDeleteSurvey = action((survey: BaseSurvey) => {
    switch (survey.survey_class) {
        case "ONGOING":
            throw Error("This operation is not available on ongoing survey");
        case "SIMPLE":
        default:
            (survey as SimpleSurvey).survey_events.forEach((surveyEvent) => {
                surveyEvent.event_alerts.forEach((alertUUID) => {
                    const alertCopyToRemove:
                        | SurveyEventAlert
                        | undefined
                        | null =
                        alertsStore.getSurveyAlertCopyByUuid(alertUUID);

                    if (!alertCopyToRemove || !surveyEvent) {
                        showDifftoneAlert(
                            constantAlertVariants.delete_an_alert.text,
                            constantAlertVariants.delete_an_alert.variant
                        );
                        return;
                    }

                    alertCopyToRemove.is_deleted = true;
                    if (alertCopyToRemove.email_uuid)
                        cancelEmail(alertCopyToRemove.email_uuid);
                    alertsStore.InsertAlertToStoreWithoutDebounce(
                        alertCopyToRemove
                    );
                });
            });
    }
});

export const fetchAllSurveyAlerts = action(async (survey: BaseSurvey) => {
    switch (survey.survey_class) {
        case "ONGOING":
            throw Error("This operation is not available on ongoing survey");
        case "SIMPLE":
        default:
            const alertsUUIDs = (survey as SimpleSurvey).survey_events.flatMap(
                (surveyEvent) => surveyEvent.event_alerts
            );

            return alertsStore.asyncGetEventAlertsByUUIDs(alertsUUIDs);
    }
});
