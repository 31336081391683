import type { UUID, SharedSurvey, BaseSurvey } from "@difftone/types";
import { syncGetEmailByUserUuid } from "@difftone/reducers";
import {
    sharedSurveyStore,
    surveyStore,
    templatesStore,
} from "@difftone/stores";

export const getPublicProfileEmail = (userUuid: UUID) => {
    const publicProfileEmail = syncGetEmailByUserUuid(userUuid);

    return publicProfileEmail;
};

export const getUniquePublicProfilesUUIDs = () => {
    const publicProfileUUIDs: UUID[] = surveyStore.surveysList.reduce(
        (acc: string[], curr: BaseSurvey) =>
            acc.includes(curr.initiator) ? acc : [...acc, curr.initiator],
        []
    );
    return publicProfileUUIDs;
};

export const getUniquePublicProfilesUUIDsInSharedSurveysInbox = () => {
    const publicProfileUUIDsOfSharedSurveys =
        sharedSurveyStore.sharedSurveysList.reduce(
            (acc: string[], curr: SharedSurvey) =>
                acc.includes(curr.sender) ? acc : [...acc, curr.sender],
            []
        );

    const uniquePublicProfilesOfSurveyList = getUniquePublicProfilesUUIDs();

    const uniquePublicProfiles = [
        ...publicProfileUUIDsOfSharedSurveys,
        ...uniquePublicProfilesOfSurveyList,
    ].reduce(
        (acc: string[], curr: string) =>
            acc.includes(curr) ? acc : [...acc, curr],
        []
    );

    return uniquePublicProfiles;
};

export const getUniquePublicProfilesUUIDsInTemplatesInbox = () => {
    const surveysPublicProfileUUIDs = getUniquePublicProfilesUUIDs();

    const templatesPublicProfileUUIDs = templatesStore
        .getTemplatesListAsArray()
        .reduce(
            (acc: string[], curr: SharedSurvey) =>
                acc.includes(curr.sender) ? acc : [...acc, curr.sender],
            []
        );

    const publicProfileUUIDs = [
        ...surveysPublicProfileUUIDs,
        ...templatesPublicProfileUUIDs,
    ].reduce(
        (acc: string[], curr: string) =>
            acc.includes(curr) ? acc : [...acc, curr],
        []
    );

    return publicProfileUUIDs;
};
