import React from "react";
import { observer } from "mobx-react";
import { getSurveyFromStoreByURL } from "@difftone/reducers";
import { wizardDisplayStore } from "@difftone/stores";
import { setSurveyEventToDisplay } from "@difftone/actions";
import { Spinner } from "@difftone/shared-components";
import { SimpleSurvey } from "@difftone/types";
import { Timeline, InfoTimeline } from "./components";
import { wizard_timeline } from "./wizard-timeline.module.css";

export type WizardTimelineProps = {};

export const WizardTimeline = observer((props: WizardTimelineProps) => {
    const { selectedEventUUID } = wizardDisplayStore;

    const survey = getSurveyFromStoreByURL();

    if (survey.survey_class !== "SIMPLE") {
        throw Error(
            "[Difftone]:: WizardTimeline survey must be a simple survey"
        );
    }

    const simpleSurvey = survey as SimpleSurvey;

    if (selectedEventUUID === null || selectedEventUUID === undefined) {
        setSurveyEventToDisplay(simpleSurvey.survey_events[0].uuid);
        return <Spinner size="MEDIUM" />;
    }

    return (
        <div className={wizard_timeline} data-wizard-timeline>
            <InfoTimeline />
            <Timeline />
        </div>
    );
});
