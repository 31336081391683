import React, { ReactElement } from "react";
import clsx from "clsx";

import {
    box_shadow_floating_menu,
    box_shadow_dropdown,
} from "./add-box-shadow.module.css";

const ELEMNT_CHILDREN_MAP_KEY_PREFIX = "add-box-shadow-";

export type AddBoxShadowProps = {
    children: ReactElement | ReactElement[];
    isFloatingMenuBoxShadow?: boolean;
};

export const AddBoxShadow = (props: AddBoxShadowProps) => {
    const { children, isFloatingMenuBoxShadow } = props;

    const modifyChildren = (child: ReactElement, index: number) => {
        if (!child) {
            return null;
        }

        const className = clsx(
            child.props.className,
            isFloatingMenuBoxShadow
                ? box_shadow_floating_menu
                : box_shadow_dropdown
        );
        const props = {
            key: `${ELEMNT_CHILDREN_MAP_KEY_PREFIX}-${index}`,
            className,
        };

        return React.cloneElement(child, props);
    };

    const _children = Array.isArray(children) ? children : [children];

    return <>{_children.map((child, index) => modifyChildren(child, index))}</>;
};
