import React from "react";
import { Hamburger, ShowDifftoneTooltip } from "@difftone/shared-components";
import { setLocation } from "@difftone/actions";
import { INBOX_URL } from "@difftone/constants";

import {
    header_logo_wrapper,
    logo,
    organization_display,
} from "./header-logo.module.css";
import blueIIcon from "@difftone/assets/blue-i-icon.svg";
import { observer } from "mobx-react";
import { Organization } from "@difftone/types";

export type HeaderLogoProps = {
    organization: Organization | null | undefined;
    organizationsList: (Organization | null)[] | null;
};

export const HeaderLogo = observer((props: HeaderLogoProps) => {
    const onInboxClick = () => {
        setLocation(INBOX_URL);
    };

    const { organization, organizationsList } = props;

    return (
        <div className={header_logo_wrapper}>
            <Hamburger />
            <div onClick={onInboxClick} className={logo}>
                difftone
            </div>
            {organization &&
                organizationsList &&
                organizationsList?.length > 1 && (
                    <ShowDifftoneTooltip
                        tip={
                            "You have selected this organization to work with. To change the selection please log out and log back in."
                        }
                        tooltipPosition="bottom"
                    >
                        <div className={organization_display}>
                            {organization.name}{" "}
                            <img src={blueIIcon} alt="info-icon" />
                        </div>
                    </ShowDifftoneTooltip>
                )}
        </div>
    );
});
