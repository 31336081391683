import React, { ButtonHTMLAttributes } from "react";
import clsx from "clsx";

import {
    action_button,
    action_button_content,
    action_button_icon,
} from "./action-button.module.css";

export type ActionButtonProps = {
    title: string;
    iconSrc: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ActionButton = (props: ActionButtonProps) => {
    const { title, iconSrc, className, ...restProps } = props;

    return (
        <button className={clsx(action_button, className)} {...restProps}>
            <div className={action_button_content}>
                <img alt="" src={iconSrc} className={action_button_icon} />

                <span>{title}</span>
            </div>
        </button>
    );
};
