import React from "react";
import { observer } from "mobx-react";
import { getUserEmail, getUserUuid } from "@difftone/procedures";
import { setInboxActiveFilterFlagAndRedirect } from "@difftone/actions";
import {
    navigationStore,
    surveyStore,
    wizardDisplayStore,
} from "@difftone/stores";
import { getStatus } from "@difftone/reducers";
import { INBOX_URL } from "@difftone/constants";

import {
    HeaderLogo,
    HeaderQuickSearch,
    NewSurveyButton,
    SurveyEventBell,
    UserLabel,
    ReturnToInboxButton,
    OverallStatus,
    DifftoneInfoAndLegal,
} from "./components";

import { header_wrapper, user_div } from "./header.module.css";
import { Organization } from "@difftone/types";

export type HeaderProps = {
    organization: Organization | null | undefined;
    organizationsList: (Organization | null)[] | null;
};

export const Header = observer((props: HeaderProps) => {
    const { currentPage } = navigationStore;
    const { surveysList } = surveyStore;

    const { organization, organizationsList } = props;

    const needsMyAttentionBadge = () => {
        const initiatorSurveys = surveysList.filter(
            (_survey) =>
                _survey.initiator === getUserUuid() &&
                getStatus(_survey) === "ACTIVE"
        ).length;

        const responderSurveys = surveysList.filter(
            (_survey) =>
                _survey.respondents?.includes(getUserEmail()) &&
                getStatus(_survey, "RESPONDER") === "ACTIVE"
        ).length;

        return initiatorSurveys + responderSurveys;
    };

    const showNeedMyAttention = () => {
        setInboxActiveFilterFlagAndRedirect("NEEDS_ATTENTION");
    };

    return (
        <>
            <div className={header_wrapper}>
                <HeaderLogo
                    organization={organization}
                    organizationsList={organizationsList}
                />
                <HeaderQuickSearch />
                {currentPage.includes(INBOX_URL) ? (
                    <NewSurveyButton />
                ) : (
                    <>
                        {currentPage.includes("/survey-wizard") &&
                            wizardDisplayStore.isEditableSurvey && (
                                <OverallStatus currentStatus="syncing" />
                            )}
                        <ReturnToInboxButton withClearSurveyStore={false} />
                    </>
                )}
                <div className={user_div}>
                    <SurveyEventBell
                        showNeedMyAttention={showNeedMyAttention}
                        needAttention={needsMyAttentionBadge()}
                    />
                    <DifftoneInfoAndLegal />
                    <hr />
                    <UserLabel />
                </div>
            </div>
        </>
    );
});
