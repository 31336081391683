import React from "react";
import { observer } from "mobx-react";
import { SendLightningAirplaneIcon } from "@difftone/shared-components";
import { inboxDisplayStore, navigationStore } from "@difftone/stores";
import { SurveyClasses } from "@difftone/types";
import {
    scheduleNewOngoingRecipientsEmail,
    setLocation,
    showDifftoneAlertWithSharePanel,
    upsertSurveyToMapAndApiWithoutDebounce,
} from "@difftone/actions";
import { constantAlertVariants, PAGE_DISPLAY_NAMES } from "@difftone/constants";

import {
    popup_button_scope,
    popup_button,
    popup_button_icon,
} from "./popup-button.module.css";

type PopupButtonProps = {};
export const PopupButton = observer((props: PopupButtonProps) => {
    const { currentPage } = navigationStore;
    const namePage = currentPage.split("/")[2];
    const { copySurvey, newRespondents, clearStore, messageSurvey } =
        inboxDisplayStore;

    const sendSurvey = () => {
        if (copySurvey) {
            upsertSurveyToMapAndApiWithoutDebounce(copySurvey as SurveyClasses);
            //Only schedule an email if there are new respondents
            if (newRespondents.length) {
                scheduleNewOngoingRecipientsEmail(
                    newRespondents,
                    copySurvey,
                    Date.now(),
                    messageSurvey
                );
            }
            clearStore();
            setLocation(PAGE_DISPLAY_NAMES[namePage]);

            showDifftoneAlertWithSharePanel(
                constantAlertVariants.ongoing_survey_new_participants.text,
                constantAlertVariants.ongoing_survey_new_participants.variant,
                copySurvey.uuid,
                "Additionally you can send the survey via",
                copySurvey.is_open_survey
            );
        }
    };

    return (
        <div className={popup_button_scope}>
            <div className={popup_button} onClick={sendSurvey}>
                <div className={popup_button_icon}>
                    <SendLightningAirplaneIcon color="#fff" />
                </div>
                <div>Send</div>
            </div>
        </div>
    );
});
