import {
    ResultsFilter,
    TimeRange,
    UUID,
    TimeCompareUnits,
    SurveyClasses,
    QuestionToAnswerDescreteQuestion,
} from "@difftone/types";
import { resultsDisplayFilterStore } from "@difftone/stores";
import { Buffer } from "buffer";
import {
    getFilterQuestionsFromSurvey,
    getNonFilterQuestionsFromSurvey,
    getQueryParamsFromUrl,
    surveyCategoriesLength,
} from "@difftone/reducers";
import {
    halfYearDisplay,
    halfYearDisplayToMultiLine,
    MONTHS,
    questerDisplay,
    questerDisplayToMultiLine,
    TIME_FILTER_ID,
} from "@difftone/constants";
import { createTimeFilterAsQuestion } from "@difftone/frontend-common";
import { NO_ANSWER_KEY } from "@difftone/common-constants";

const ALLOWED_ATTRIBUTE_TITLE_LENGTH = 10;

const NUMBER_OF_BUCKETS = 3;

const CUT_YEAR_DISPLAY = -2;

export const getNickNameByQuestionUUID = (
    questionUUID: UUID
): string | null => {
    if (!questionUUID) {
        return null;
    }

    const filter = resultsDisplayFilterStore.compareBy.find(
        (filter) => filter.filterKey.uuid === questionUUID
    );

    if (!filter) {
        return null;
    }

    if (filter.filterKey.nickname) {
        return filter.filterKey.nickname;
    } else {
        return filter.filterKey.title.slice(0, ALLOWED_ATTRIBUTE_TITLE_LENGTH);
    }
};

export const getFilterParams = (copyOfProducedFilters: ResultsFilter[]) =>
    copyOfProducedFilters
        .map((filter: ResultsFilter) => {
            const encodedFilterFormat = JSON.stringify([
                filter.filterKey.uuid,
                filter.filterValues,
            ]);

            return Buffer.from(encodedFilterFormat).toString("base64");
        })
        .join();

export const getFilterQuestionsForResultsCompareBy = (
    survey: SurveyClasses
) => {
    const filterQuestions = getFilterQuestionsFromSurvey(survey);

    switch (survey.survey_class) {
        case "ONGOING":
            return [...filterQuestions, createTimeFilterAsQuestion()];
        case "SIMPLE":
        default:
            return filterQuestions;
    }
};

export const convertTimeRangeToHeaderDisplay = (
    resultsFilter: ResultsFilter,
    timeRange: TimeRange
) => {
    const timeFrameDate = new Date(timeRange.rangeStart);
    const timeFrameYear = timeFrameDate.getFullYear();

    switch (resultsFilter.unit) {
        case "MONTHS":
            return `${MONTHS[timeFrameDate.getMonth()]} - ${timeFrameYear}`;
        case "QUARTERS":
            const monthInQuarter = timeFrameDate.getMonth();
            const quarter = monthInQuarter / 3 + 1;
            return `${questerDisplay[quarter]} - ${timeFrameYear}`;
        case "HALF_YEAR":
            const monthInHalfYear = timeFrameDate.getMonth();
            const halfYear = monthInHalfYear / 6 + 1;
            return `${halfYearDisplay[halfYear]} - ${timeFrameYear}`;
        default:
            return "No time frame to display";
    }
};

export const convertTimeRangeToMultiLineDisplay = (
    resultsFilter: ResultsFilter,
    timeRange: TimeRange
) => {
    const timeFrameDate = new Date(timeRange.rangeStart);
    const timeFrameYear = timeFrameDate.getFullYear();
    const year = timeFrameYear.toString().substr(CUT_YEAR_DISPLAY);

    switch (resultsFilter.unit) {
        case "MONTHS":
            return `${MONTHS[timeFrameDate.getMonth()]} - ${year}`;
        case "QUARTERS":
            const monthInQuarter = timeFrameDate.getMonth();
            const quarter = monthInQuarter / 3 + 1;
            return `${questerDisplayToMultiLine[quarter]} - ${year}`;
        case "HALF_YEAR":
            const monthInHalfYear = timeFrameDate.getMonth();
            const halfYear = monthInHalfYear / 6 + 1;
            return `${halfYearDisplayToMultiLine[halfYear]} - ${year}`;
        default:
            return "No time frame to display";
    }
};

export const getTimeUnitFromUrlOrNone = () => {
    const compareParams =
        getQueryParamsFromUrl().get("compare")?.split(",") || [];

    const timeCompareFilterValue = compareParams.find((compareParam) =>
        compareParam.includes(TIME_FILTER_ID)
    );

    return (
        (timeCompareFilterValue?.split("=")[1] as TimeCompareUnits) || "NONE"
    );
};

export const getTimeUnitFromUrlOrMonths = () => {
    const compareParams =
        getQueryParamsFromUrl().get("compare")?.split(",") || [];

    const timeCompareFilterValue = compareParams.find((compareParam) =>
        compareParam.includes(TIME_FILTER_ID)
    );

    return (
        (timeCompareFilterValue?.split("=")[1] as TimeCompareUnits) || "MONTHS"
    );
};

export const getNormalizedArrayForTooltip = (
    questionResults: QuestionToAnswerDescreteQuestion
) => {
    const questionResultsWithoutNoResponses =
        getResultsWithoutNoResponses(questionResults);

    const resultsValuesArray = Object.values(questionResultsWithoutNoResponses);

    const resultsKeysArray = Object.keys(questionResultsWithoutNoResponses);

    const groupeSizes = splitGroupSizeToThreeGroupSize(
        resultsValuesArray.length
    );

    const groupKeysForTooltip = splitKeysToGroupsForTooltip(
        resultsKeysArray,
        groupeSizes
    );

    return groupKeysForTooltip;
};

const splitKeysToGroupsForTooltip = (
    resultsKeysArray: string[],
    groupeSize: number[]
) => {
    const tooltipGroups: { [key: number]: string[] } = {};

    let _indexPosition = 0;
    const indexTable = groupeSize.map((groupSize) => {
        return Array(groupSize)
            .fill(0)
            .map((num) => {
                const groupeIndex = _indexPosition;
                _indexPosition++;
                return groupeIndex;
            });
    });

    indexTable.forEach((colorGroupe, index) => {
        const key = index;
        const groups: string[] = [];
        colorGroupe.forEach((groupIndex) => {
            groups.push(resultsKeysArray[groupIndex]);
        });
        tooltipGroups[key] = groups;
    });

    const reverseTooltipGroups = Object.keys(tooltipGroups)
        .sort((a, b) => Number(b) - Number(a))
        .map((key, index) => {
            return tooltipGroups[Number(key)];
        });

    return reverseTooltipGroups;
};

const normalizeResultsArrayToThree = (
    questionResultsWithoutNoResponses: QuestionToAnswerDescreteQuestion
) => {
    const valuesArray = Object.values(questionResultsWithoutNoResponses);

    const deviationSizes = splitGroupSizeToThreeGroupSize(valuesArray.length);

    const results = [0, 0, 0];

    valuesArray.forEach((value, index) => {
        if (index < deviationSizes[0]) {
            results[0] += value;
            return;
        }

        if (index < deviationSizes[0] + deviationSizes[1]) {
            results[1] += value;
            return;
        }
        results[2] += value;
    });

    return results;
};

const splitGroupSizeToThreeGroupSize = (groupSize: number) => {
    const res: number[] = new Array(NUMBER_OF_BUCKETS).fill(0);

    for (let i = groupSize; i > 0; ) {
        switch (i) {
            case 1:
                res[1]++;
                i--;
                break;
            case 2:
                res[0]++;
                res[2]++;
                i -= 2;
                break;
            default:
                res[0]++;
                res[1]++;
                res[2]++;
                i -= 3;
        }
    }

    return res;
};

const calculateFavorableScore = (normalizedResultsArray: number[]) => {
    const totalSum = normalizedResultsArray.reduce(
        (sum, current) => (sum += current),
        0
    );

    if (!totalSum) {
        return normalizedResultsArray;
    }

    const resultArray = normalizedResultsArray
        .map((resultOptionValue) => {
            const calculatedResult = (resultOptionValue / totalSum) * 100;

            return Number(calculatedResult.toFixed(1));
        })
        .reverse();

    return resultArray;
};

const normalizedResultsArray = (
    questionResults: QuestionToAnswerDescreteQuestion
) => {
    const questionResultsWithoutNoResponses =
        getResultsWithoutNoResponses(questionResults);

    return normalizeResultsArrayToThree(questionResultsWithoutNoResponses);
};

//This returns a new array after normalization
const calculateCategoriesResultsArray = (
    resultQuestionsArray: QuestionToAnswerDescreteQuestion[]
) => {
    const resultQuestionsArrayWithoutNoResponses = resultQuestionsArray.map(
        (resultsQuestion) => getResultsWithoutNoResponses(resultsQuestion)
    );

    const _normalizedQuestionsArray =
        resultQuestionsArrayWithoutNoResponses.map((resultsQuestion) =>
            normalizeResultsArrayToThree(resultsQuestion)
        );

    const result: number[] = new Array(NUMBER_OF_BUCKETS).fill(0);

    _normalizedQuestionsArray.forEach((normalizedQuestion) => {
        normalizedQuestion.forEach((value, index) => {
            result[index] += value;
        });
    });

    return result;
};

const getResultsWithoutNoResponses = (
    questionResults: QuestionToAnswerDescreteQuestion
) => {
    const questionResultsWithoutNoResponses: QuestionToAnswerDescreteQuestion =
        JSON.parse(JSON.stringify(questionResults));

    delete questionResultsWithoutNoResponses[NO_ANSWER_KEY];

    return questionResultsWithoutNoResponses;
};

export const calculateFavorableQuestionScore = (
    questionResults: QuestionToAnswerDescreteQuestion
) => {
    const questionResultsWithoutNoResponses =
        getResultsWithoutNoResponses(questionResults);

    const normalizedResults: number[] = normalizedResultsArray(
        questionResultsWithoutNoResponses
    );

    return calculateFavorableScore(normalizedResults);
};

export const calculateFavorableCategoryScore = (
    resultQuestionsArray: QuestionToAnswerDescreteQuestion[]
) => {
    const categoriesResultsArray =
        calculateCategoriesResultsArray(resultQuestionsArray);

    return calculateFavorableScore(categoriesResultsArray);
};

export const displayDropdownList = (survey: SurveyClasses) => {
    const numberOfNonFIlterQuestions =
        getNonFilterQuestionsFromSurvey(survey).length;
    const numberOfCategories = surveyCategoriesLength(survey.questions);

    return [
        {
            displayValue: `Categories`,
            systemValue: "CATEGORIES",
            urlValue: "CATEGORIES",
            isDisabled: !numberOfCategories,
            counterValue: numberOfCategories,
        },
        {
            displayValue: `Questions`,
            systemValue: "QUESTIONS",
            urlValue: "QUESTIONS",
            counterValue: numberOfNonFIlterQuestions,
        },
    ];
};

export const favorableScoreAnonimityCheck = (
    anonymityLevel: number,
    questionResults: QuestionToAnswerDescreteQuestion
) => {
    const normalizedArray = normalizedResultsArray(questionResults);

    const isAnonymous = normalizedArray.some((group) => group < anonymityLevel);

    return isAnonymous;
};

export const favorableCategoriesScoreAnonimityCheck = (
    anonymityLevel: number,
    resultQuestionsArray: QuestionToAnswerDescreteQuestion[]
) => {
    const categoriesResultsNormalizedArray =
        calculateCategoriesResultsArray(resultQuestionsArray);

    const isAnonymous = categoriesResultsNormalizedArray.some(
        (group) => group < anonymityLevel
    );

    return isAnonymous;
};
