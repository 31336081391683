import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
    topTooltip: {
        minWidth: "25px",
        width: "max-content",
        border: "1px solid #273238",
        background: "#e6eef2",
        color: "#273238",
        fontFamily: "Rubik",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.02em",
        textAlign: "left",
        borderRadius: "4px",
        padding: "5px 9px",
    },

    topTooltipArrow: {
        color: "#e6eef2",
        "&::before": {
            color: "#e6eef2",
            borderRight: "1px solid #273238",
            borderBottom: "1px solid #273238",
        },
    },
    leftTooltipArrow: {
        color: "#e6eef2",
        "&::before": {
            color: "#e6eef2",
            borderRight: "1px solid  #273238",
            borderTop: "1px solid #273238",
        },
    },
    bottomTooltipArrow: {
        color: "#e6eef2",
        "&::before": {
            color: "#e6eef2",
            borderTop: "1px solid #273238",
            borderLeft: "1px solid #273238",
            borderRight: "1px solid #273238",
            borderBottom: "1px solid #273238",
        },
    },
    rightTooltipArrow: {
        color: "#e6eef2",
        "&::before": {
            color: "#e6eef2",
            borderLeft: "1px solid #273238",
            borderBottom: "1px solid #273238",
        },
    },
});
