import React, { useRef } from "react";
import { observer } from "mobx-react";

import {
    Dialog,
    DialogProps,
    Spinner,
    DifftoneButton,
    ShowDifftoneTooltip,
} from "@difftone/shared-components";

import { preventPropagation } from "@difftone/frontend-common";

import {
    dialog_content,
    dialog_header,
    dialog_paper,
    dialog_input,
    buttons_container,
    submit_button,
    info_wrapper,
    icon_wrapper,
} from "./import-google-form-dialog.module.css";

import iconConvert from "@difftone/assets/activate-pay-icon.svg";
import blueIIcon from "@difftone/assets/blue-i-icon.svg";

import { inboxDisplayStore } from "@difftone/stores";
import {
    convertGoogleFormToOnGoingSurvey,
    setConvertingForm,
} from "@difftone/actions";

export type ImportGoogleFormDialogProps = DialogProps & {};

const OAUTH_INFO_TEXT = `
    This functionality requires access to the forms on your Google account.
    If you have not used it before then it will open an authorization window
    to grant the needed permissions.
`;

export const ImportGoogleFormDialog = observer(
    (props: ImportGoogleFormDialogProps) => {
        const { onClose, open } = props;
        const { isImportingForm } = inboxDisplayStore;

        const inputRef = useRef<HTMLInputElement>(null);

        const onSubmit = (withResults: boolean) => {
            const formId = inputRef.current?.value;

            if (formId && formId.length) {
                setConvertingForm(true);
                convertGoogleFormToOnGoingSurvey(formId, withResults);
            }
        };

        return (
            <Dialog
                onClose={onClose}
                open={open}
                fullScreen={false}
                title="Import google form"
                className={dialog_header}
                classes={{
                    paper: dialog_paper,
                }}
            >
                <div className={dialog_content} onClick={preventPropagation}>
                    {!isImportingForm ? (
                        <>
                            <div className={info_wrapper}>
                                Import a survey from google forms
                                <ShowDifftoneTooltip
                                    tip={OAUTH_INFO_TEXT}
                                    tooltipPosition="bottom"
                                >
                                    <div className={icon_wrapper}>
                                        <img src={blueIIcon} alt="info-icon" />
                                    </div>
                                </ShowDifftoneTooltip>
                            </div>
                            <input
                                className={dialog_input}
                                placeholder="ID of form to import"
                                type="text"
                                name="form_id"
                                ref={inputRef}
                            />
                            <div className={buttons_container}>
                                <DifftoneButton
                                    onClick={() => onSubmit(false)}
                                    classNameOverride={submit_button}
                                    icon={iconConvert}
                                    buttonText={"Convert survey only"}
                                    extraOptions={[
                                        {
                                            icon: iconConvert,
                                            text: "Convert survey & results",
                                            onClick: () => onSubmit(true),
                                        },
                                    ]}
                                />
                            </div>
                        </>
                    ) : (
                        <Spinner />
                    )}
                </div>
            </Dialog>
        );
    }
);
