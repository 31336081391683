import React from "react";
import { observer } from "mobx-react";
import { ResultsFilter } from "@difftone/types";
import {
    options_list_item,
    checked_choice_style,
    custom_checkbox_class,
} from "./options-list-item.module.css";

export type OptionsListItemProps = {
    isActive: boolean;
    index: number;
    filter: ResultsFilter;
    addFilter: (index: number) => void;
    removeFilter: (index: number) => void;
};

export const OptionsListItem = observer((props: OptionsListItemProps) => {
    const { index, filter, addFilter, removeFilter, isActive } = props;

    const toggleActiveState = () => {
        if (!isActive) {
            addFilter(index);
        } else {
            removeFilter(index);
        }
    };

    const optionValue = filter.filterValues[index] as string;

    return (
        <div className={options_list_item}>
            <input
                data-id={optionValue}
                id={optionValue as string}
                type="checkbox"
                checked={isActive}
                onChange={toggleActiveState}
            />
            <label
                className={
                    isActive ? checked_choice_style : custom_checkbox_class
                }
                htmlFor={optionValue}
            ></label>
            <label htmlFor={optionValue}>{optionValue}</label>
        </div>
    );
});
