import React, { FocusEvent, useEffect } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import type {
    SurveyResponse,
    ValidationResult,
    RequiredNotAnsweredValidationType,
    SurveyClasses,
} from "@difftone/types";
import {
    navigationStore,
    organizationStore,
    surveyResponseDisplayStore,
    surveyResponseStore,
} from "@difftone/stores";
import {
    constantAlertVariants,
    INBOX_URL,
    warningMessages,
} from "@difftone/constants";
import {
    showDifftoneAlert,
    logoutFromLocalStorage,
    saveSelectedSurveyResponse,
    setLocation,
    createNewSurveyResponseByUserUuid,
    openSuccessAlertWithClouds,
    setFocusedSurveyResponseInputCard,
    setWasSurveyResponseOnceSubmitted,
    saveSelectedOpenSurveyResponse,
} from "@difftone/actions";
import { getUserEmail, getUserUuid, sendEvent } from "@difftone/procedures";
import { validateResponse } from "@difftone/validations";
import {
    ClientWidthRenderPicker,
    Header,
    LeftMenu,
    MobileHeader,
    Spinner,
} from "@difftone/shared-components";
import { UiStatus } from "@difftone/frontend-common";
import {
    getOrganizations,
    getSelectedOrganizationUUID,
    getStatus,
    getSurveyForParticipantBySurveyUuid,
    getSurveyUUIDFromUrl,
    getUUidAndEmailFromUrl,
} from "@difftone/reducers";

import {
    SurveyWelcomeCard,
    SurveyIntroductionCard,
    SurveyProgressBar,
    LeftMenuChildren,
    SurveyQuestions,
} from "./components";
import { getValidResponsesCounter } from "./utils";

import {
    survey_wrapper,
    submit_survey_button,
    left_menu_wrapper,
    survey_wrapper_preview_mode,
    disabled_submit_survey_button,
    open_survey_wrapper,
} from "./survey.module.css";

export type SurveyProps = {
    inPreviewMode: boolean;
    survey?: SurveyClasses | null;
    isOpenSurvey?: boolean;
};

export const Survey = observer((props: SurveyProps) => {
    const { inPreviewMode, survey, isOpenSurvey } = props;
    const { wasSurveyResponseOnceSubmitted } = surveyResponseDisplayStore;
    const {
        getSelectedSurveyResponse,
        getSurveyResponseByUuid,
        getSurveyResponseBySurveyUUID,
        setSurveyResponseToMap,
    } = surveyResponseStore;
    const { currentPage } = navigationStore;

    const surveyUuid = getSurveyUUIDFromUrl();

    if (!survey && !surveyUuid) {
        throw Error(`Could not find a survey`);
    }

    const currentSurvey = survey
        ? survey
        : getSurveyForParticipantBySurveyUuid(surveyUuid!);

    if (currentSurvey === undefined) {
        return <Spinner size="LARGE" />;
    }

    if (currentSurvey === null) {
        showDifftoneAlert(warningMessages.surveyNotFound, "FAILURE");
        setLocation(INBOX_URL);
        return null;
    }

    //[useEffect]:: survey page cleanup function
    useEffect(() => {
        if (currentSurvey) sendEvent("USER_STARTED_SURVEY", currentSurvey.uuid);
        return () => {
            setFocusedSurveyResponseInputCard(null);
        };
    }, [currentSurvey]);

    const encodedParams = currentPage.split("/").pop()?.toString();

    const urlParams = getUUidAndEmailFromUrl(encodedParams!);

    if (!urlParams && !inPreviewMode) {
        showDifftoneAlert(warningMessages.surveyNotFound, "FAILURE");
        setLocation(INBOX_URL);
        return null;
    }

    if (!isOpenSurvey && !surveyResponseStore.storeInitialize) {
        surveyResponseStore.initAllSurveyResponses();
        return <Spinner size="LARGE" />;
    }

    let surveyResponse: SurveyResponse | null | undefined = null;

    if (inPreviewMode) {
        surveyResponse = getSurveyResponseByUuid("");
    } else if (isOpenSurvey) {
        surveyResponse = getSurveyResponseBySurveyUUID(surveyUuid!);
    } else {
        surveyResponse = getSelectedSurveyResponse();
    }

    if (surveyResponse === undefined) {
        return <Spinner size="LARGE" />;
    }

    if (surveyResponse === null) {
        if (isOpenSurvey) {
            surveyResponse = createNewSurveyResponseByUserUuid(
                "ANONYMOUS_USER_UUID",
                currentSurvey
            );
            setSurveyResponseToMap(surveyResponse!.survey_UUID, surveyResponse);
        } else {
            const _newSurveyResponse = createNewSurveyResponseByUserUuid(
                getUserUuid()
            );

            if (_newSurveyResponse === null) {
                throw Error(
                    "Could not create survey response due to to survey fetching error"
                );
            }

            surveyResponseStore.addSurveyResponseWithApiPersistance(
                _newSurveyResponse
            );

            surveyResponse = getSurveyResponseByUuid(_newSurveyResponse.uuid);
        }
    }

    if (surveyResponse === undefined) {
        return <Spinner size="LARGE" />;
    }

    if (surveyResponse === null) {
        return <Spinner size="LARGE" />;
    }

    let surveyStatus: UiStatus = undefined;

    if (!inPreviewMode) {
        surveyStatus = getStatus(currentSurvey, "RESPONDER");
    }

    if (
        currentSurvey.survey_class === "ONGOING" &&
        surveyStatus === "COMPLETED"
    ) {
        showDifftoneAlert(
            warningMessages.alreadyAnsweredOngoingSurvey,
            "FAILURE"
        );
        setLocation(INBOX_URL);
        return null;
    }

    const userEMailFromUrl = inPreviewMode ? getUserEmail() : urlParams!.email;

    if (
        !isOpenSurvey &&
        getUserEmail() !== userEMailFromUrl &&
        !currentSurvey.respondents.includes(getUserEmail())
    ) {
        const isConfirmed = window.confirm(
            `You are currently logged in as ${getUserEmail()}.\nWould you like to log in as ${userEMailFromUrl}?`
        );
        if (isConfirmed) {
            logoutFromLocalStorage();
            window.location.reload();
        } else {
            setLocation(INBOX_URL);
        }
        return null;
    }

    const validationResult = inPreviewMode
        ? []
        : validateResponse(currentSurvey as SurveyClasses, surveyResponse);
    const canSubmit = validationResult.every(
        (validationResult) => validationResult.is_valid
    );
    const errors = wasSurveyResponseOnceSubmitted
        ? validationResult.filter(({ is_valid }) => !is_valid)
        : [];

    const saveSurveyResponse = () => {
        if (inPreviewMode) {
            return;
        }

        if (!canSubmit) {
            const targetError = validationResult.filter(
                ({ is_valid }) => !is_valid
            )[0] as ValidationResult<RequiredNotAnsweredValidationType>;

            setWasSurveyResponseOnceSubmitted(true);
            // refreshing focused card to force scroll when we set focused card
            // in case card with error has been already focused before
            setFocusedSurveyResponseInputCard(null);
            setFocusedSurveyResponseInputCard(
                targetError.validation_error_type!.question_uuid
            );

            showDifftoneAlert(
                "Hi, a survey cannot be submitted when there are unanswered required questions",
                "GENERAL"
            );

            return;
        }
        if (currentSurvey)
            sendEvent("USER_SUBMITTED_SURVEY", currentSurvey.uuid);
        if (isOpenSurvey) {
            saveSelectedOpenSurveyResponse(surveyResponse!.uuid);
        } else {
            saveSelectedSurveyResponse(surveyResponse!.uuid);
        }
        openSuccessAlertWithClouds(
            constantAlertVariants.answers_submitted.text
        );
        setLocation(INBOX_URL);
    };

    if (!isOpenSurvey && !getUserEmail()) {
        setLocation(`/home?ref=/survey/${encodedParams}`);
        return null;
    }

    const onSubmitButtonFocusHandler = (
        event: FocusEvent<HTMLButtonElement>
    ) => {
        if (inPreviewMode) {
            return;
        }
        if (!canSubmit) {
            event.preventDefault();
        }
    };

    const totalNumberOfQuestions = currentSurvey.questions.length;

    const selectedOrganization = isOpenSurvey
        ? null
        : getSelectedOrganizationUUID();

    const organizationsList = isOpenSurvey ? null : getOrganizations();

    let _organization;

    if (selectedOrganization) {
        _organization =
            organizationStore.getOrganizationByUuid(selectedOrganization);
    }

    return (
        <div>
            {inPreviewMode || isOpenSurvey ? null : (
                <ClientWidthRenderPicker
                    desktopComponent={
                        <Header
                            organization={_organization}
                            organizationsList={organizationsList}
                        />
                    }
                    mobileComponent={<MobileHeader />}
                />
            )}
            <div
                className={clsx(survey_wrapper, {
                    [survey_wrapper_preview_mode]: inPreviewMode,
                    [open_survey_wrapper]: isOpenSurvey,
                })}
                data-scroll-element="survey-wrapper"
            >
                {inPreviewMode || isOpenSurvey ? null : (
                    <div className={left_menu_wrapper}>
                        <LeftMenu>
                            <LeftMenuChildren survey={currentSurvey} />
                        </LeftMenu>
                    </div>
                )}
                <SurveyWelcomeCard survey={currentSurvey} />

                {currentSurvey.participant_introduction ? (
                    <SurveyIntroductionCard
                        surveyIntroduction={
                            currentSurvey.participant_introduction
                        }
                    />
                ) : null}

                <SurveyQuestions
                    inPreviewMode={inPreviewMode}
                    survey={currentSurvey}
                    errors={errors}
                />

                {inPreviewMode ? null : (
                    <SurveyProgressBar
                        numberOfQuestions={totalNumberOfQuestions}
                        answeredQuestionsCounter={getValidResponsesCounter(
                            surveyResponse
                        )}
                    >
                        <button
                            onFocus={onSubmitButtonFocusHandler}
                            data-submit-survey-button
                            className={clsx(
                                submit_survey_button,
                                !canSubmit && disabled_submit_survey_button
                            )}
                            onClick={saveSurveyResponse}
                        >
                            Submit Answers
                        </button>
                    </SurveyProgressBar>
                )}
            </div>
        </div>
    );
});
