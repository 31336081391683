import React from "react";
import { observer } from "mobx-react";

import {
    DragDropContext,
    Droppable,
    DroppableProvided,
    DropResult,
} from "react-beautiful-dnd";
import {
    replaceQuestionsOrderByIndexes,
    setFocusedWizardInputCard,
    debounceUpsertSurveyToApi,
} from "@difftone/actions";
import { wizardStore } from "@difftone/stores";
import { getSurveyFromStoreByURL } from "@difftone/reducers";
import { KEYBOARD_EVENT_KEYS_MAP } from "@difftone/constants";
import { preventPropagation } from "@difftone/frontend-common";

import { DraggableQuestions, QuestionsMenu } from "./components";

import {
    wizard_questions,
    wizard_questions_ul,
    right_floating_style,
} from "./wizard-questions.module.css";

export type WizardQuestionsProp = {};

export const WizardQuestions = observer(() => {
    const { focusedWizardInputCard } = wizardStore;

    const survey = getSurveyFromStoreByURL();

    const onKeyDown = (e: any) => {
        if (focusedWizardInputCard) {
            const currentItemIndex = Number(
                focusedWizardInputCard?.getAttribute("index")
            );
            if (
                e.key === KEYBOARD_EVENT_KEYS_MAP.ARROW_UP &&
                currentItemIndex > 0
            ) {
                preventPropagation(e);
                setFocusedWizardInputCard(
                    document.querySelector(
                        `div[data-wizard-question-card][index='${(
                            currentItemIndex - 1
                        ).toString()}']`
                    )
                );
            } else if (
                e.key === KEYBOARD_EVENT_KEYS_MAP.ARROW_DOWN &&
                currentItemIndex < survey.questions.length - 1
            ) {
                preventPropagation(e);
                setFocusedWizardInputCard(
                    document.querySelector(
                        `div[data-wizard-question-card][index='${(
                            currentItemIndex + 1
                        ).toString()}']`
                    )
                );
            }
        }
    };

    const updateItemsOrder = (
        oldIndex: number,
        newIndex: number,
        itemUUID: string
    ) => {
        replaceQuestionsOrderByIndexes(oldIndex, newIndex);
        setFocusedWizardInputCard(null);
        setTimeout(() => {
            //TODO: replace with refs
            const wizardQuestionNodes = document.querySelectorAll(
                "div[data-wizard-question-card]"
            );
            wizardQuestionNodes.forEach((_nodeCard) => {
                if (_nodeCard.getAttribute("uuid") === itemUUID) {
                    //Gets the input title in order to select the value on drop
                    const inputQuestionTitle = document.querySelector(
                        `textarea[data-textarea-question-input-title="${_nodeCard.getAttribute(
                            "uuid"
                        )}"]`
                    ) as HTMLTextAreaElement;

                    inputQuestionTitle.select();
                    setFocusedWizardInputCard(_nodeCard as HTMLElement);
                }
            });
        }, 0);

        debounceUpsertSurveyToApi();
    };

    const onDragEnd = (result: DropResult) => {
        if (result.source && result.destination) {
            updateItemsOrder(
                result.source.index,
                result.destination.index,
                result.draggableId
            );
        }
    };

    return (
        <div onKeyDown={onKeyDown} className={wizard_questions}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="characters">
                    {(provided: DroppableProvided) => (
                        <ul
                            className={`characters ${wizard_questions_ul}`}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            <DraggableQuestions />
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
            <div
                style={{
                    top: `${
                        focusedWizardInputCard &&
                        focusedWizardInputCard?.offsetTop
                    }px`,
                }}
                className={right_floating_style}
            >
                <QuestionsMenu survey={survey} />
            </div>
        </div>
    );
});
