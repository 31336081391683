const DEV_GOOGLE_CLIENT_ID = process.env
    .REACT_APP_DEV_GOOGLE_CLIENT_ID as string;
const COM_GOOGLE_CLIENT_ID = process.env
    .REACT_APP_COM_GOOGLE_CLIENT_ID as string;

const DEV_MICROSOFT_CLIENT_ID = process.env
    .REACT_APP_DEV_MICROSOFT_CLIENT_ID as string;
const APP_MICROSOFT_CLIENT_ID = process.env
    .REACT_APP_APP_MICROSOFT_CLIENT_ID as string;
const COM_MICROSOFT_CLIENT_ID = process.env
    .REACT_APP_COM_MICROSOFT_CLIENT_ID as string;

const DEV_GOOGLE_CLIENT_SECRET = process.env
    .REACT_APP_DEV_GOOGLE_CLIENT_SECRET as string;
const COM_GOOGLE_CLIENT_SECRET = process.env
    .REACT_APP_COM_GOOGLE_CLIENT_SECRET as string;

const DEV_MICROSOFT_CLIENT_SECRET = process.env
    .REACT_APP_DEV_MICROSOFT_CLIENT_SECRET as string;
const APP_MICROSOFT_CLIENT_SECRET = process.env
    .REACT_APP_APP_MICROSOFT_CLIENT_SECRET as string;
const COM_MICROSOFT_CLIENT_SECRET = process.env
    .REACT_APP_COM_MICROSOFT_CLIENT_SECRET as string;

const DEV_SEGMENT_ID = process.env.REACT_APP_DEV_SEGMENT_ID as string;
const APP_SEGMENT_ID = process.env.REACT_APP_APP_SEGMENT_ID as string;
const COM_SEGMENT_ID = process.env.REACT_APP_COM_SEGMENT_ID as string;

export const getApiBaseUrl = () => {
    if (process.env.USE_LOCAL_SERVER === "true") return "http://localhost:4000";
    switch (window.location.hostname) {
        case "localhost":
        case "www.difftone.dev":
            return "https://api.difftone.dev";
        case "www.difftone.app":
            return "https://api.difftone.app";
        default:
            return "https://api.difftone.com";
    }
};

export const getWebClientBaseUrl = () => {
    switch (window.location.hostname) {
        case "localhost":
            return "http://localhost:3000";

        case "www.difftone.dev":
            return "https://www.difftone.dev";

        case "www.difftone.app":
            return "https://www.difftone.app";

        default:
            return "https://www.difftone.com";
    }
};

export const getGoogleClientId = () => {
    switch (window.location.hostname) {
        case "localhost":
        case "www.difftone.dev":
        case "www.difftone.app":
            return DEV_GOOGLE_CLIENT_ID;
        default:
            return COM_GOOGLE_CLIENT_ID;
    }
};

export const getMicrosoftClientId = () => {
    switch (window.location.hostname) {
        case "localhost":
        case "www.difftone.dev":
            return DEV_MICROSOFT_CLIENT_ID;
        case "www.difftone.app":
            return APP_MICROSOFT_CLIENT_ID;
        default:
            return COM_MICROSOFT_CLIENT_ID;
    }
};

export const getGoogleClientSecret = () => {
    switch (window.location.hostname) {
        case "localhost":
        case "www.difftone.dev":
        case "www.difftone.app":
            return DEV_GOOGLE_CLIENT_SECRET;
        default:
            return COM_GOOGLE_CLIENT_SECRET;
    }
};

export const getMicrosoftClientSecret = () => {
    switch (window.location.hostname) {
        case "localhost":
        case "www.difftone.dev":
            return DEV_MICROSOFT_CLIENT_SECRET;
        case "www.difftone.app":
            return APP_MICROSOFT_CLIENT_SECRET;
        default:
            return COM_MICROSOFT_CLIENT_SECRET;
    }
};

export const getSegmentId = () => {
    switch (window.location.hostname) {
        case "localhost":
        case "www.difftone.dev":
            return DEV_SEGMENT_ID;
        case "www.difftone.app":
            return APP_SEGMENT_ID;
        default:
            return COM_SEGMENT_ID;
    }
};
