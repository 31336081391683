import React from "react";

import { time_compare_button } from "./compare-button.module.css";

export type CompareButtonProps = {
    onSelectCompareHandler: () => void;
};

export const CompareButton = (props: CompareButtonProps) => {
    const { onSelectCompareHandler } = props;
    return (
        <div className={time_compare_button}>
            <button onClick={onSelectCompareHandler}>Apply</button>
        </div>
    );
};
