import React from "react";
import { observer } from "mobx-react";
import { SharedSurvey } from "@difftone/types";
import { constantAlertVariants } from "@difftone/constants";
import {
    publicProfileStore,
    leftMenuStore,
    templatesStore,
    categoriesStore,
} from "@difftone/stores";
import { Spinner, SpinnerOrTimeoutMessage } from "@difftone/shared-components";
import { sendEvent } from "@difftone/procedures";
import { getSortedTemplates, getUserPermissionsType } from "@difftone/reducers";
import {
    acceptSharedSurveyToDraft,
    showDifftoneAlert,
    openSurveyPreview,
    setLocation,
    getUniquePublicProfilesUUIDsInTemplatesInbox,
    showPermissionsAlert,
} from "@difftone/actions";

import { TemplateRow } from "./components";
import {
    inbox_templates_table,
    table_content_wrapper,
    overflow_padding_div,
} from "./inbox-templates-table.module.css";

export type InboxTemplatesTableProps = {};

export const InboxTemplatesTable = observer(() => {
    const allCategories = categoriesStore.getCategoriesArray();
    const userType = getUserPermissionsType();

    const sortedTemplatesList = getSortedTemplates(allCategories);

    if (!templatesStore.startInitialization) {
        templatesStore.init();
        return <Spinner size="LARGE" />;
    }

    if (!templatesStore.storeInitialize) {
        return <Spinner />;
    }

    if (!publicProfileStore.startInitialization) {
        const publicProfileUUIDs =
            getUniquePublicProfilesUUIDsInTemplatesInbox();
        publicProfileStore.init(publicProfileUUIDs);
    }

    if (!publicProfileStore.storeInitialize) {
        return <Spinner />;
    }

    if (!leftMenuStore.startInitialization) {
        leftMenuStore.init();
        return <Spinner />;
    }

    if (!leftMenuStore.storeInitialize) {
        return <Spinner />;
    }

    const onPreview = (template: SharedSurvey) => {
        openSurveyPreview(template.survey);
    };

    const onAccept = (template: SharedSurvey) => {
        const newSurvey = acceptSharedSurveyToDraft(template, template.uuid);
        setLocation(`/survey-wizard/${newSurvey.uuid}`);
    };

    const onConfirmAccept = (template: SharedSurvey) => {
        if (userType === "DEFAULT") {
            showPermissionsAlert();
            return;
        }

        showDifftoneAlert(
            constantAlertVariants.create_survey_templates.text,
            constantAlertVariants.create_survey_templates.variant,
            () => onAccept(template),
            () => {},
            `${template.survey.survey_name}`
        );
        sendEvent(
            "CREATE_FROM_TEMPLATE_SURVEY_BUTTON_CLICK",
            template.survey.survey_name
        );
    };

    return (
        <div className={inbox_templates_table}>
            <div className={table_content_wrapper}>
                {sortedTemplatesList.length > 0 ? (
                    sortedTemplatesList.map((template: SharedSurvey) => (
                        <TemplateRow
                            key={template.uuid}
                            onPreview={onPreview}
                            onConfirmAccept={onConfirmAccept}
                            template={template}
                        />
                    ))
                ) : (
                    <SpinnerOrTimeoutMessage
                        spinnerSize="LARGE"
                        spinnerFlag={!templatesStore.storeInitialize}
                        message="No Templates To Show"
                    />
                )}
                <div className={overflow_padding_div}></div>
            </div>
        </div>
    );
});
