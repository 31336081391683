import React from "react";
import { observer } from "mobx-react";
import { simpleSurveyResultsStore } from "@difftone/stores";
import { Question, QuestionType } from "@difftone/types";
import { setSelectedQuestion } from "@difftone/actions";
import { ShowDifftoneTooltip } from "@difftone/shared-components";

import {
    left_menu_question,
    selected_question_index,
    selected_question,
    question_index,
    title,
    no_overflow_ellipsis,
} from "./left-menu-question.module.css";

const MAX_CHARACTERS_IN_QUESTION = 27;

export type LeftMenuQuestionProps = {
    question: Question<QuestionType>;
    index: number;
};

export const LeftMenuQuestion = observer((props: LeftMenuQuestionProps) => {
    const { selectedQuestion } = simpleSurveyResultsStore;
    const { question, index } = props;
    //TODO: get from simple survey results store
    const isSelectedQuestion = question.uuid === selectedQuestion;

    const onClickHandler = () => {
        setSelectedQuestion(question.uuid);
    };

    const surveyQuestionWithTt = (
        <div className={title}>
            {question.title.length > MAX_CHARACTERS_IN_QUESTION ? (
                <ShowDifftoneTooltip tip={question.title} tooltipPosition="top">
                    <div className={no_overflow_ellipsis}>{question.title}</div>
                </ShowDifftoneTooltip>
            ) : (
                question.title
            )}
        </div>
    );

    return (
        <div
            onMouseDown={onClickHandler}
            className={
                isSelectedQuestion
                    ? [left_menu_question, selected_question].join(" ")
                    : left_menu_question
            }
        >
            <div
                className={
                    isSelectedQuestion
                        ? selected_question_index
                        : question_index
                }
            >
                {index + 1}.
            </div>
            {surveyQuestionWithTt}
        </div>
    );
});
