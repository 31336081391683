import React from "react";

import {
    survey_event_bell_wrapper,
    badge,
} from "./survey-event-bell.module.css";

export type BellProps = {
    needAttention: number;
    showNeedMyAttention: () => void;
};

export const SurveyEventBell = (props: BellProps) => {
    const { needAttention, showNeedMyAttention } = props;

    return (
        <div
            onClick={showNeedMyAttention}
            className={survey_event_bell_wrapper}
        >
            {Boolean(needAttention) && <div className={badge} />}
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M17.0021 9.80085C17.0021 8.52759 16.4963 7.30647 15.596 6.40614C14.6957 5.5058 13.4745 5 12.2013 5C10.928 5 9.7069 5.5058 8.80656 6.40614C7.90623 7.30647 7.40043 8.52759 7.40043 9.80085C7.40043 15.4018 5 17.0021 5 17.0021H19.4026C19.4026 17.0021 17.0021 15.4018 17.0021 9.80085Z"
                    stroke="#435863"
                    strokeWidth="1.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.5856 20.2027C13.4449 20.4452 13.243 20.6465 13 20.7864C12.7571 20.9264 12.4817 21 12.2013 21C11.921 21 11.6455 20.9264 11.4026 20.7864C11.1597 20.6465 10.9578 20.4452 10.8171 20.2027"
                    stroke="#435863"
                    strokeWidth="1.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};
