import * as commonValidations from "@difftone/validations";
import { frontendValidateSurvey } from "./survey-validations";
export * from "./utils";
export * from "./events-validation-result-util";
export * from "./question-validation-result";

//TODO: fix all import to work currently with @difftone/frontend-common https://app.asana.com/0/1201346022025578/1202797176912029/f
export const frontendValidations = {
    ...commonValidations,
    validateSurvey: frontendValidateSurvey,
};
