import React, { SyntheticEvent } from "react";
import clsx from "clsx";
import { ShowDifftoneTooltip } from "@difftone/shared-components";
import {
    ANONIMITY_TIP_HIGH,
    ANONIMITY_TIP_LOW,
    ANONIMITY_TIP_MEDIUM,
    ANONIMITY_TIP_NONE,
} from "@difftone/frontend-common";

import {
    selected,
    anonimity_label,
    selected_disabled,
} from "./anonimity-level-button.module.css";

export type AnonimityLevelButtonProp = {
    onClick: (event: SyntheticEvent) => void;
    label: "Low" | "None" | "Medium" | "High";
    isSelected: boolean;
    disabled?: boolean;
};
export const AnonimityLevelButton = (props: AnonimityLevelButtonProp) => {
    const { label, onClick, isSelected, disabled } = props;
    let anonimityTip = "";
    if (!disabled) {
        switch (label) {
            case "None":
                anonimityTip = ANONIMITY_TIP_NONE.wizard;
                break;
            case "Low":
                anonimityTip = ANONIMITY_TIP_LOW.wizard;
                break;
            case "Medium":
                anonimityTip = ANONIMITY_TIP_MEDIUM.wizard;
                break;
            case "High":
                anonimityTip = ANONIMITY_TIP_HIGH.wizard;
                break;
            default:
                break;
        }
    }

    return (
        <div
            data-value={label.toUpperCase()}
            onClick={onClick}
            className={clsx(
                isSelected && {
                    [selected_disabled]: disabled,
                    [selected]: !disabled,
                }
            )}
        >
            <ShowDifftoneTooltip
                clickableTooltip={true}
                tooltipPosition="bottom"
                tip={anonimityTip}
            >
                <div title="" className={anonimity_label}>
                    {label}
                </div>
            </ShowDifftoneTooltip>
        </div>
    );
};
