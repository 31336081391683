import React, { useEffect } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import { surveyStore, wizardStore } from "@difftone/stores";
import duplicateQuestionIcon from "@difftone/assets/duplicate-question-floating-menu-icon.svg";
import deleteQuestionIcon from "@difftone/assets/delete-attribute-floating-menu-icon.svg";
import addQuestionIcon from "@difftone/assets/add-question-floating-menu-icon.svg";

import {
    addNewEmptyQuestionToWizard,
    deleteQuestionFromWizard,
    duplicateQuestionInWizard,
    debounceUpsertSurveyToApi,
    validateWizardQuestions,
    setFocusedWizardInputCard,
    setRatingQuestionAutoScaleRange,
} from "@difftone/actions";
import { AddBoxShadow } from "@difftone/shared-components";
import { validateQuestions } from "@difftone/validations";
import { SurveyClasses } from "@difftone/types";
import { getUserUuid } from "@difftone/procedures";
import { RATING_DEFAULT_SCALE } from "@difftone/constants";
import {
    focusFirstInvalidQuestion,
    frontendValidations,
} from "@difftone/frontend-common/frontend-validations";
import { getSurveyFromStoreByURL } from "@difftone/reducers";

import {
    questions_menu,
    add_button,
    duplicate_button,
    delete_button,
    grayed_delete_button,
} from "./questions-menu.module.css";

const MIN_QUESTIONS_IN_WIZARD = 1;

export type QuestionsMenuProps = {
    survey?: SurveyClasses;
};

export const QuestionsMenu = observer((props: QuestionsMenuProps) => {
    const { focusedWizardInputCard, ratingQuestionAutoScaleRange } =
        wizardStore;
    const { surveysList } = surveyStore;
    const { validateSurvey } = frontendValidations;
    const survey = props.survey || getSurveyFromStoreByURL();

    const targetQuestionIndex =
        Number(focusedWizardInputCard?.getAttribute("index")) || 0;

    const surveyQuestionsUUIDs = survey.questions.map(
        (question) => question.uuid
    );

    const restOfSurveys = surveysList.filter(
        (_survey) =>
            _survey.initiator === getUserUuid() &&
            _survey.uuid !== survey.uuid &&
            _survey.status !== "DRAFT"
    );

    const addQuestion = () => {
        const attributesAndQuestionsNameUniquenessValidations = validateSurvey(
            survey,
            restOfSurveys
        ).filter(
            (validationTResult) =>
                validationTResult.validation_error_code ===
                "QUESTION_NAME_DUPLICATION_VALIDATION"
        );
        const questionsValidations = validateQuestions(survey.questions);

        const allQuestionsValidations = [
            ...attributesAndQuestionsNameUniquenessValidations,
            ...questionsValidations,
        ];

        if (
            allQuestionsValidations.some((validation) => !validation.is_valid)
        ) {
            validateWizardQuestions(survey);
            const invalidQuestion = allQuestionsValidations.find(
                (validation) => !validation.is_valid
            );

            if (!invalidQuestion) {
                return;
            }
            focusFirstInvalidQuestion(invalidQuestion, surveyQuestionsUUIDs);
            return;
        }
        const targetQuestionIndexToAdd = targetQuestionIndex + 1;

        addNewEmptyQuestionToWizard(
            ratingQuestionAutoScaleRange,
            targetQuestionIndexToAdd
        );
        setTimeout(() => {
            const allExistingQuestionElements: NodeListOf<HTMLDivElement> =
                document.querySelectorAll("div[data-wizard-question-card]");
            const lastAddedQuestion =
                allExistingQuestionElements[
                    allExistingQuestionElements.length > 1
                        ? targetQuestionIndexToAdd
                        : targetQuestionIndex
                ];
            setFocusedWizardInputCard(lastAddedQuestion);

            const inputTextArea = document.querySelector(
                `textarea[data-textarea-question-input-title="${lastAddedQuestion.getAttribute(
                    "uuid"
                )}"]`
            ) as HTMLTextAreaElement;
            inputTextArea?.select();

            debounceUpsertSurveyToApi();
        }, 0);
    };

    const duplicateAttribute = () => {
        const attributesAndQuestionsNameUniquenessValidations = validateSurvey(
            survey,
            restOfSurveys
        ).filter(
            (validationTResult) =>
                validationTResult.validation_error_code ===
                "QUESTION_NAME_DUPLICATION_VALIDATION"
        );
        const questionsValidations = validateQuestions(survey.questions);

        const allQuestionsValidations = [
            ...attributesAndQuestionsNameUniquenessValidations,
            ...questionsValidations,
        ];

        if (
            allQuestionsValidations.some((validation) => !validation.is_valid)
        ) {
            validateWizardQuestions(survey);
            const invalidQuestion = allQuestionsValidations.find(
                (validation) => !validation.is_valid
            );
            if (!invalidQuestion) {
                return;
            }
            focusFirstInvalidQuestion(invalidQuestion, surveyQuestionsUUIDs);
            return;
        }

        const questionNumber = focusedWizardInputCard?.getAttribute("index");
        //@ts-ignore
        const questionToCopy = survey?.questions![questionNumber];
        questionToCopy && duplicateQuestionInWizard(questionToCopy);

        setTimeout(() => {
            const allExistingQuestionElements: NodeListOf<HTMLDivElement> =
                document.querySelectorAll("div[data-wizard-question-card]");
            setFocusedWizardInputCard(
                allExistingQuestionElements[Number(questionNumber) + 1]
            );
            debounceUpsertSurveyToApi();
        }, 0);
    };

    const deleteQuestion = () => {
        if (surveyQuestionsUUIDs.length === MIN_QUESTIONS_IN_WIZARD) {
            return;
        }
        const questionNumberUUID = focusedWizardInputCard?.getAttribute("uuid");
        const questionNumber = survey?.questions!.findIndex(
            (question) => question.uuid === questionNumberUUID
        );
        const questionToDelete = survey?.questions!.filter(
            (question) => question.uuid === questionNumberUUID
        )[0];

        questionToDelete && deleteQuestionFromWizard(questionToDelete);
        setTimeout(() => {
            const allExistingQuestionElements: NodeListOf<HTMLDivElement> =
                document.querySelectorAll("div[data-wizard-question-card]");

            if (questionNumber > 0) {
                const previousElement =
                    allExistingQuestionElements[questionNumber - 1];
                setFocusedWizardInputCard(previousElement);
            } else {
                setFocusedWizardInputCard(allExistingQuestionElements[0]);
            }
            debounceUpsertSurveyToApi();
        }, 0);
        validateWizardQuestions(survey);

        const ratingQuestionsLength = survey?.questions!.filter(
            ({ question_type_name }) => question_type_name === "RATING"
        ).length;

        if (
            ratingQuestionsLength === 1 &&
            questionToDelete?.question_type_name === "RATING"
        ) {
            setRatingQuestionAutoScaleRange(RATING_DEFAULT_SCALE);
        }
    };

    //UseEffect:: for getting the last input title in the list and selects the input for the user to start typing
    useEffect(() => {
        const inputTextArea = document.querySelector(
            `textarea[data-textarea-question-input-title="${focusedWizardInputCard?.getAttribute(
                "uuid"
            )}"]`
        ) as HTMLTextAreaElement;

        if (inputTextArea) {
            inputTextArea.select();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AddBoxShadow isFloatingMenuBoxShadow={true}>
            <div className={questions_menu}>
                <div className={add_button}>
                    <button onClick={addQuestion}>
                        <img src={addQuestionIcon} alt="add-question" />
                        Question
                    </button>
                </div>
                <div className={duplicate_button}>
                    <button onClick={duplicateAttribute}>
                        <img
                            src={duplicateQuestionIcon}
                            alt="duplicate-question"
                        />
                        Duplicate
                    </button>
                </div>
                <div
                    className={clsx(
                        delete_button,
                        surveyQuestionsUUIDs.length ===
                            MIN_QUESTIONS_IN_WIZARD && grayed_delete_button
                    )}
                >
                    <button onClick={deleteQuestion}>
                        <img src={deleteQuestionIcon} alt="delete-question" />
                        Delete
                    </button>
                </div>
            </div>
        </AddBoxShadow>
    );
});
