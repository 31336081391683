import {
    OngoingSurvey,
    SurveyContainer,
    SimpleSurvey,
    SurveyClasses,
    SurveyEvent,
} from "@difftone/types";
import { v4 as uuid } from "uuid";

export const surveysUuidReplacer = (
    surveyContainer: SurveyContainer<SurveyClasses>
) => {
    switch (surveyContainer.survey.survey_class) {
        case "ONGOING":
            const ongoingSerializedSurvey =
                surveyContainer as SurveyContainer<OngoingSurvey>;

            return ongoingSerializeUuidReplacer(ongoingSerializedSurvey);
        case "SIMPLE":
        default:
            const simpleSerializedSurvey =
                surveyContainer as SurveyContainer<SimpleSurvey>;

            return simpleSerializeUuidReplacer(simpleSerializedSurvey);
    }
};

const ongoingSerializeUuidReplacer = (
    surveyContainer: SurveyContainer<OngoingSurvey>
) => {
    const _serializedSurvey: SurveyContainer<OngoingSurvey> = JSON.parse(
        JSON.stringify(surveyContainer)
    );
    _serializedSurvey.survey.uuid = uuid();
    return _serializedSurvey;
};

const simpleSerializeUuidReplacer = (
    surveyContainer: SurveyContainer<SimpleSurvey>
) => {
    if (!surveyContainer.survey_events_alerts) {
        throw Error("[Difftone]::simple survey must contain alerts");
    }

    surveyContainer.survey.survey_events.forEach((surveyEvent) => {
        eventAlertsUuidsReplacer(surveyEvent, surveyContainer);
    });

    surveyContainer.survey.uuid = uuid();
    return surveyContainer;
};

const eventAlertsUuidsReplacer = (
    surveyEvent: SurveyEvent,
    surveyContainer: SurveyContainer<SimpleSurvey>
) => {
    surveyEvent.event_alerts.forEach((eventAlertUuid, index) => {
        if (!surveyContainer.survey_events_alerts) {
            throw Error("[Difftone]::simple survey must contain alerts");
        }

        const surveyEventAlert = surveyContainer.survey_events_alerts.find(
            (alert) => alert.uuid === eventAlertUuid
        );
        if (surveyEventAlert) {
            const newUuid = uuid();
            surveyEventAlert.uuid = newUuid;
            surveyEvent.event_alerts[index] = surveyEventAlert.uuid;
        } else {
            throw Error("[Difftone]:: Survey event alert is missing a uuid");
        }
    });
};
