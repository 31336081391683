import React, { ChangeEvent } from "react";
import { observer } from "mobx-react";
import { BaseSurvey } from "@difftone/types";
import { simpleSurveyResultsStore } from "@difftone/stores";
import {
    setIsSelectedQuestionInputFocused,
    setSelectedQuestionFilterInputValue,
} from "@difftone/actions";

import ratingIcon from "@difftone/assets/collapsed-rating.svg";
import singleChoiceIcon from "@difftone/assets/collapsed-single-choice.svg";
import multiChoiceIcon from "@difftone/assets/collapsed-multiple-choice.svg";
import dateIcon from "@difftone/assets/collapsed-date.svg";
import timeIcon from "@difftone/assets/collapsed-clock.svg";
import longTextIcon from "@difftone/assets/collapsed-long-text.svg";
import shortTextIcon from "@difftone/assets/collapsed-short-text.svg";

import {
    selected_question,
    question_picker_dropdown,
    question_index,
    question_text,
    question_type,
    questions_serach_input,
} from "./selected-question.module.css";

export type SelectedQuestionProps = {
    survey: BaseSurvey;
};

export const SelectedQuestion = observer((props: SelectedQuestionProps) => {
    const { survey } = props;

    const {
        selectedQuestion,
        selectedQuestionFilterInput,
        isSelectedQuestionInputFocused,
    } = simpleSurveyResultsStore;
    const activeQuestion = survey.questions.find(
        (question) => question.uuid === selectedQuestion
    );

    let questionTypeIcon = null;
    switch (activeQuestion?.question_type_name) {
        case "RATING":
            questionTypeIcon = ratingIcon;
            break;
        case "SINGLE_CHOICE":
            questionTypeIcon = singleChoiceIcon;
            break;
        case "DATE":
            questionTypeIcon = dateIcon;
            break;
        case "TIME":
            questionTypeIcon = timeIcon;
            break;
        case "MULTIPLE_CHOICE":
            questionTypeIcon = multiChoiceIcon;
            break;
        case "SHORT_TEXT":
            questionTypeIcon = shortTextIcon;
            break;
        case "LONG_TEXT":
            questionTypeIcon = longTextIcon;
            break;
        default:
            break;
    }

    const onQuestionPickerClickHandler = () => {
        setIsSelectedQuestionInputFocused(true);
    };

    const onQuestionInputBlurHandler = () => {
        setIsSelectedQuestionInputFocused(false);
        setSelectedQuestionFilterInputValue("");
    };

    const onSelectedQuestionFilterInputChangeHandler = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setSelectedQuestionFilterInputValue(event.target.value);
    };

    return (
        <div className={selected_question}>
            <div
                onFocus={onQuestionPickerClickHandler}
                tabIndex={0}
                className={question_picker_dropdown}
            >
                {selectedQuestion ? (
                    <div className={question_index}>
                        {activeQuestion?.number}.
                    </div>
                ) : null}
                {selectedQuestion ? (
                    <div className={question_text}>{activeQuestion?.title}</div>
                ) : null}
                {selectedQuestion ? null : (
                    <div className={question_text}>Select a question</div>
                )}
                {selectedQuestion ? (
                    <img
                        className={question_type}
                        src={questionTypeIcon}
                        alt="type"
                    />
                ) : null}
                {isSelectedQuestionInputFocused ? (
                    <input
                        autoFocus
                        value={selectedQuestionFilterInput}
                        onChange={onSelectedQuestionFilterInputChangeHandler}
                        onBlur={onQuestionInputBlurHandler}
                        className={questions_serach_input}
                        type="text"
                    />
                ) : null}
            </div>
        </div>
    );
});
