import React from "react";

type Color = "BLUE" | "GREEN";

export type FilterCheckBoxProps = {
    color: Color;
    className?: "";
    onClick?: () => void;
};

export const FilterCheckBox = (props: FilterCheckBoxProps) => {
    const { color, className, onClick } = props;

    const colors = {
        BLUE: "#035375",
        GREEN: "#7FA6B7",
    };

    const onClickHandler = () => {
        if (!onClick) {
            return;
        }
        onClick();
    };

    return (
        <svg
            onClick={onClickHandler}
            className={className}
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="1.75"
                y="1.75"
                width="20.5"
                height="20.5"
                rx="5.25"
                stroke={colors[color]}
                strokeWidth="1.5"
            />
            <path
                d="M6 9.75C6 9.33579 6.33579 9 6.75 9H17.25C17.6642 9 18 9.33579 18 9.75C18 10.1642 17.6642 10.5 17.25 10.5H6.75C6.33579 10.5 6 10.1642 6 9.75Z"
                fill={colors[color]}
            />
            <path
                d="M8 12.5C8 12.0858 8.33579 11.75 8.75 11.75H15.25C15.6642 11.75 16 12.0858 16 12.5C16 12.9142 15.6642 13.25 15.25 13.25H8.75C8.33579 13.25 8 12.9142 8 12.5Z"
                fill={colors[color]}
            />
            <path
                d="M10.5 15.25C10.5 14.8358 10.8358 14.5 11.25 14.5H12.75C13.1642 14.5 13.5 14.8358 13.5 15.25C13.5 15.6642 13.1642 16 12.75 16H11.25C10.8358 16 10.5 15.6642 10.5 15.25Z"
                fill={colors[color]}
            />
        </svg>
    );
};
