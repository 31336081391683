import React from "react";
import { observer } from "mobx-react";

import {
    Header,
    LeftMenu,
    MobileHeader,
    ClientWidthRenderPicker,
    Spinner,
} from "@difftone/shared-components";
import {
    constantAlertVariants,
    NO_MOBILE_PLACEHOLDER_TEXT,
    PAGE_URLS,
    RESULTS_DISPLAY_TYPE,
    warningMessages,
} from "@difftone/constants";

import {
    getOrganizations,
    getSelectedOrganizationUUID,
    getSurveyFromStoreByUUID,
    getSurveyUUIDFromUrl,
    getTimeUnitFromUrlOrNone,
} from "@difftone/reducers";
import {
    navigationStore,
    organizationStore,
    resultsDisplayFilterStore,
    timeRangesStore,
} from "@difftone/stores";

import {
    ResultsDisplayCard,
    LeftMenuChildren,
    LeftMenuChildrenForGraph,
} from "./components";

import {
    results,
    results_body,
    mobile_wrapper_notice,
} from "./complicated-survey-results.module.css";
import { setLocation, showDifftoneAlert } from "@difftone/actions";

export type ComplicatedSurveyResultsProps = {};

export const ComplicatedSurveyResults = observer(
    (props: ComplicatedSurveyResultsProps) => {
        const { currentPage } = navigationStore;
        const { initFiltersForSurvey } = resultsDisplayFilterStore;
        const { extractKeyForTimeRange, getTimeRangeByKey } = timeRangesStore;

        const selectedOrganization = getSelectedOrganizationUUID();
        const organizationsList = getOrganizations();

        let _organization;

        if (selectedOrganization) {
            _organization =
                organizationStore.getOrganizationByUuid(selectedOrganization);
        }

        const surveyUuid = getSurveyUUIDFromUrl();

        if (surveyUuid === null) {
            setLocation(PAGE_URLS.INBOX_ALL);
            showDifftoneAlert(
                constantAlertVariants.survey_url_failure.text,
                constantAlertVariants.survey_url_failure.variant
            );
            return null;
        }

        const selectedSurvey = getSurveyFromStoreByUUID(surveyUuid);

        if (selectedSurvey === undefined) {
            return <Spinner size="LARGE" />;
        }

        if (selectedSurvey === null) {
            setLocation(PAGE_URLS.INBOX_ALL);
            showDifftoneAlert(warningMessages.surveyNotFound, "FAILURE");
            return null;
        }

        const timeRangeKey = extractKeyForTimeRange(
            selectedSurvey.uuid,
            getTimeUnitFromUrlOrNone()
        );

        const timeRanges = getTimeRangeByKey(timeRangeKey);

        if (timeRanges === undefined) {
            return <Spinner size="SMALL" />;
        }

        if (timeRanges === null) {
            showDifftoneAlert(
                "Something went wrong, please reload and try again",
                "FAILURE"
            );
            return null;
        }

        //Initialization of filters
        initFiltersForSurvey(selectedSurvey, timeRanges);

        //TODO:: Create a router https://app.asana.com/0/1201346022025578/1203264006891803/f
        const resultPage = `/${currentPage.split("/").at(-1)}`;
        let resultPageCompereByComponent;

        switch (resultPage) {
            case RESULTS_DISPLAY_TYPE.GRID:
                resultPageCompereByComponent = <LeftMenuChildren />;
                break;
            case RESULTS_DISPLAY_TYPE.FAVORABLE:
                resultPageCompereByComponent = <LeftMenuChildren />;
                break;
            case RESULTS_DISPLAY_TYPE.LINE:
                resultPageCompereByComponent = <LeftMenuChildrenForGraph />;
                break;
            default:
                resultPageCompereByComponent = <LeftMenuChildren />;
        }

        return (
            <div className={results}>
                <ClientWidthRenderPicker
                    desktopComponent={
                        <Header
                            organization={_organization}
                            organizationsList={organizationsList}
                        />
                    }
                    mobileComponent={<MobileHeader />}
                />
                <div className={results_body}>
                    <LeftMenu isHiddenOnMobile>
                        {resultPageCompereByComponent}
                    </LeftMenu>
                    <ClientWidthRenderPicker
                        desktopComponent={
                            <ResultsDisplayCard
                                selectedSurvey={selectedSurvey}
                            />
                        }
                        mobileComponent={
                            <p className={mobile_wrapper_notice}>
                                {NO_MOBILE_PLACEHOLDER_TEXT}
                            </p>
                        }
                    />
                </div>
            </div>
        );
    }
);
