import React, { MouseEvent } from "react";
import { observer } from "mobx-react";
import {
    DIFFTONE_PRIVACY_POLICY_URL,
    DIFFTONE_TERMS_OF_USE_URL,
} from "@difftone/constants";
import { AddBoxShadow } from "@difftone/shared-components";

import {
    ButtonsSeperator,
    GoogleButton,
    MicrosoftLoginButton,
} from "./components";

import {
    login_dialog,
    login_card,
    top_centered_line,
    difftone_header,
    terms_of_usage,
    links_div,
} from "./login-dialog.module.css";

export type LoginDialogProps = {
    closeDialog: () => void;
};

export const LoginDialog = observer((props: LoginDialogProps) => {
    const { closeDialog } = props;

    const onPrivacyPolicyClicked = () => {
        window.open(DIFFTONE_PRIVACY_POLICY_URL);
    };

    const onTermsOfUseClicked = () => {
        window.open(DIFFTONE_TERMS_OF_USE_URL);
    };

    const preventDialogClosing = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    return (
        <div onClick={closeDialog} className={login_dialog}>
            <AddBoxShadow>
                <div onClick={preventDialogClosing} className={login_card}>
                    <span className={top_centered_line}></span>
                    <div className={difftone_header}>difftone</div>
                    <GoogleButton />
                    <ButtonsSeperator />
                    <MicrosoftLoginButton />
                    <div className={terms_of_usage}>
                        You are agreeing to Difftone’s
                    </div>
                    <div className={links_div}>
                        <div onClick={onTermsOfUseClicked}>Terms of Use</div>{" "}
                        and{" "}
                        <div onClick={onPrivacyPolicyClicked}>
                            Privacy Policy
                        </div>
                    </div>
                </div>
            </AddBoxShadow>
        </div>
    );
});
