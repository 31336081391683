import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { observer } from "mobx-react";

import { ResultsFilter } from "@difftone/types";
import { AddBoxShadow } from "@difftone/shared-components";
import { getNickNameByQuestionUUID } from "@difftone/reducers";

import { OptionsListItem } from "./components";
import {
    options_list,
    list_header,
    list_body,
    list_body_options,
    selection_shortcut_buttons,
    apply_changes_to_store,
} from "./options-checkbox-list.module.css";

const EXTRA_DISTANCE_FROM_OPTION_ITEM = 3;

export type OptionsCheckboxListProps = {
    hideList: () => void;
    filter: ResultsFilter;
    getActiveFilters: (filterUuid: string) => boolean[];
    setActiveFilters: (filterUuid: string, newValues: boolean[]) => void;
};

export const OptionsCheckboxList = observer(
    (props: OptionsCheckboxListProps) => {
        const { hideList, filter, getActiveFilters, setActiveFilters } = props;

        const [selectedValues, setSelectedValues] = useState(
            getActiveFilters(filter.filterKey.uuid)
        );

        const setFilterOptionsCheckboxListPosition = () => {
            const filterPickerNodeWrapper: HTMLDivElement | null =
                document.querySelector("div[data-filter-picker]");

            const filterOptionItem: HTMLDivElement | null =
                document.querySelector(
                    `div[data-filter-option="${filter.filterKey.uuid}"]`
                );

            const parentTopPosition =
                filterPickerNodeWrapper?.getBoundingClientRect().top || 0;
            const filterOptionItemBottomPosition =
                filterOptionItem?.getBoundingClientRect().bottom || 0;

            return (
                filterOptionItemBottomPosition -
                parentTopPosition +
                EXTRA_DISTANCE_FROM_OPTION_ITEM
            );
        };

        const removeFilterHandler = (index: number) => {
            const copySelectedValues: boolean[] = JSON.parse(
                JSON.stringify(selectedValues)
            );
            copySelectedValues[index] = false;
            setSelectedValues(copySelectedValues);
        };

        const addFilterHandler = (index: number) => {
            const copySelectedValues: boolean[] = JSON.parse(
                JSON.stringify(selectedValues)
            );
            copySelectedValues[index] = true;
            setSelectedValues(copySelectedValues);
        };

        const applyLocalStateChangesToStore = () => {
            const copySelectedValues: boolean[] = JSON.parse(
                JSON.stringify(selectedValues)
            );

            setActiveFilters(filter.filterKey.uuid, copySelectedValues);

            hideList();
        };

        const selectAllHandler = () => {
            const copySelectedFilterValues: boolean[] = JSON.parse(
                JSON.stringify(selectedValues)
            );

            const selectAllSelectedFilterValues = copySelectedFilterValues.map(
                (filterValue) => {
                    return true;
                }
            );
            setSelectedValues(selectAllSelectedFilterValues);
        };

        const clearAllHandler = () => {
            const copySelectedFilterValues: boolean[] = JSON.parse(
                JSON.stringify(selectedValues)
            );

            const selectAllSelectedFilterValues = copySelectedFilterValues.map(
                (filterValue) => {
                    return false;
                }
            );
            setSelectedValues(selectAllSelectedFilterValues);
        };

        return (
            <OutsideClickHandler onOutsideClick={hideList}>
                <AddBoxShadow>
                    <div
                        style={{
                            top: `${setFilterOptionsCheckboxListPosition()}px`,
                        }}
                        className={options_list}
                    >
                        <div className={list_header}>
                            <span>
                                {getNickNameByQuestionUUID(
                                    filter.filterKey.uuid
                                )}
                            </span>
                            <svg
                                onClick={hideList}
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11.7403 0.7753C11.5745 0.604877 11.3494 0.509101 11.1147 0.509101C10.8799 0.509101 10.6549 0.604877 10.4891 0.7753L6.14981 5.21672L1.81056 0.766199C1.64477 0.595775 1.41969 0.5 1.18496 0.5C0.950233 0.5 0.725151 0.595775 0.559361 0.766199C0.213286 1.12115 0.213286 1.69453 0.559361 2.04948L4.89862 6.49999L0.559361 10.9505C0.213286 11.3055 0.213286 11.8788 0.559361 12.2338C0.905437 12.5887 1.46448 12.5887 1.81056 12.2338L6.14981 7.78327L10.4891 12.2338C10.8351 12.5887 11.3942 12.5887 11.7403 12.2338C12.0863 11.8788 12.0863 11.3055 11.7403 10.9505L7.40101 6.49999L11.7403 2.04948C12.0775 1.70363 12.0775 1.12115 11.7403 0.7753Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        <div className={list_body}>
                            <div className={selection_shortcut_buttons}>
                                <span onClick={selectAllHandler}>
                                    Select All
                                </span>
                                &nbsp;-&nbsp;
                                <span onClick={clearAllHandler}>Clear</span>
                            </div>
                            <div className={list_body_options}>
                                {(filter.filterValues as string[]).map(
                                    (option, index) => {
                                        if (!option) {
                                            return null;
                                        }

                                        return (
                                            <div key={option}>
                                                <OptionsListItem
                                                    addFilter={addFilterHandler}
                                                    removeFilter={
                                                        removeFilterHandler
                                                    }
                                                    filter={filter}
                                                    index={index}
                                                    isActive={
                                                        selectedValues[index]
                                                    }
                                                />
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                            <div
                                onClick={applyLocalStateChangesToStore}
                                className={apply_changes_to_store}
                            >
                                Apply
                            </div>
                        </div>
                    </div>
                </AddBoxShadow>
            </OutsideClickHandler>
        );
    }
);
