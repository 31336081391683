import { EmailAddress, SurveyEventAlert, UUID } from "@difftone/types";
import { makeAutoObservable, observable, ObservableMap } from "mobx";

import {
    putEventAlertToApi,
    debounceUpsertedAlertToApi,
    getSingleAlertByUUIDFromApi,
    getAllAlertsByInitiatorEmail,
    getEventAlertsByUUIDs,
    setAlertToStoreSetter,
    checkIfEmailIsGroup,
} from "./alerts-store-internal-actions";
import { getUserAuthProvider } from "@difftone/procedures";
import { getEventAlertsFromApiByUUID } from "@difftone/services";
import { showDifftoneAlert } from "@difftone/actions";

class AlertsStore {
    constructor() {
        makeAutoObservable<AlertsStore, "_fetchedAlertsKeys">(this, {
            _fetchedAlertsKeys: false,
        });
    }

    private _storeInitialize: boolean = false;
    get storeInitialize(): boolean {
        return this._storeInitialize;
    }
    set storeInitialize(newStoreInitialize: boolean) {
        this._storeInitialize = newStoreInitialize;
    }

    private _startInitialization: boolean = false;
    get startInitialization(): boolean {
        return this._startInitialization;
    }
    set startInitialization(newStartInitialization: boolean) {
        this._startInitialization = newStartInitialization;
    }

    public init = () => {
        getAllAlertsByInitiatorEmail();
        this.startInitialization = true;
    };

    private _fetchedAlertsKeys: UUID[] = [];

    private _surveyAlerts: ObservableMap<UUID, SurveyEventAlert | null> =
        observable.map({});

    public getAllSurveyAlerts() {
        return this._surveyAlerts;
    }

    public setAllSurveyAlerts(newAlerts: {
        [key: string]: SurveyEventAlert | null;
    }) {
        this._surveyAlerts = observable.map(newAlerts);
    }

    public setSingleSurveyAlert(newAlert: {
        [key: string]: SurveyEventAlert | null;
    }) {
        this._surveyAlerts.merge(newAlert);
    }

    public async addEmailsToAlert(
        emails: EmailAddress[],
        alert: SurveyEventAlert
    ) {
        const alertToUpdate = this._surveyAlerts.get(alert.uuid);
        const emailsToAdd = await this.getEmailsToAddToAlert(emails);
        if (!emailsToAdd) {
            showDifftoneAlert(
                "Unable to retrieve group email members",
                "FAILURE"
            );
            return;
        } else if (!alertToUpdate) {
            showDifftoneAlert("Invalid alert", "FAILURE");
            return;
        }

        alertToUpdate.send_to = Array.from(
            new Set([...alertToUpdate.send_to, ...emailsToAdd])
        );
        await putEventAlertToApi(alertToUpdate);
    }

    private async getEmailsToAddToAlert(
        emails: EmailAddress[]
    ): Promise<EmailAddress[] | null> {
        const processedEmailPromises = [];
        const processEmail = async (email: EmailAddress) => {
            const isGroupEmail = await checkIfEmailIsGroup(email);
            if (isGroupEmail) {
                return [];
            } else {
                return [email];
            }
        };
        for (const email of emails) {
            processedEmailPromises.push(processEmail(email));
        }

        const processedEmails = await Promise.all(processedEmailPromises);
        return processedEmails.flat();
    }

    public debounceInsertAlertToStore(alert: SurveyEventAlert) {
        const authProviderIssuer = getUserAuthProvider();

        if (!authProviderIssuer) {
            throw Error("No user found");
        }

        debounceUpsertedAlertToApi(alert, authProviderIssuer);
        setAlertToStoreSetter(alert);
    }

    public InsertAlertToStoreWithoutDebounce = async (
        alert: SurveyEventAlert
    ) => {
        setAlertToStoreSetter(alert);
        return await putEventAlertToApi(alert);
    };

    public getSurveyAlertCopyByUuid(
        alertUUID: UUID
    ): SurveyEventAlert | undefined | null {
        if (!this.startInitialization) {
            getAllAlertsByInitiatorEmail();
            this.startInitialization = true;
            return undefined;
        }

        const surveyAlert = this._surveyAlerts.get(alertUUID);

        if (
            surveyAlert === undefined &&
            !this._fetchedAlertsKeys.includes(alertUUID)
        ) {
            this._fetchedAlertsKeys.push(alertUUID);
            getSingleAlertByUUIDFromApi(alertUUID);
        }

        return surveyAlert;
    }

    public syncGetEventAlertsByUUIDs = (uuids: UUID[]) => {
        if (!this.startInitialization) {
            getAllAlertsByInitiatorEmail();
            this.startInitialization = true;
            return [undefined];
        }

        const uuidsForFetch: UUID[] = [];

        const results: (SurveyEventAlert | null | undefined)[] = uuids.map(
            (alertUUID) => {
                const surveyAlert = this._surveyAlerts.get(alertUUID);

                if (
                    surveyAlert === undefined &&
                    !this._fetchedAlertsKeys.includes(alertUUID)
                ) {
                    this._fetchedAlertsKeys.push(alertUUID);
                    getSingleAlertByUUIDFromApi(alertUUID);
                }

                return surveyAlert;
            }
        );

        if (uuidsForFetch.length) {
            getEventAlertsByUUIDs(uuidsForFetch);
        }

        return results;
    };

    public async asyncGetEventAlertsByUUIDs(
        uuids: UUID[]
    ): Promise<(SurveyEventAlert | null)[]> {
        const alertsNotInStoreUUIDs = uuids
            .map((uuid) => (this._surveyAlerts.get(uuid) ? undefined : uuid))
            .filter((uuid) => uuid !== undefined);

        let alertsFromAPI: SurveyEventAlert[] = [];

        if (alertsNotInStoreUUIDs.length > 0) {
            alertsFromAPI = await getEventAlertsFromApiByUUID(
                //@ts-ignore
                alertsNotInStoreUUIDs
            );
        }

        if (alertsFromAPI) {
            alertsFromAPI.forEach((alert) => {
                setAlertToStoreSetter(alert);
            });
        }

        const alertsToReturn = uuids.map((uuid) =>
            this.getSurveyAlertCopyByUuid(uuid)
        );

        //@ts-ignore
        return alertsToReturn;
    }

    public async asyncGetSurveyAlertCopy(
        uuid: UUID
    ): Promise<SurveyEventAlert> {
        const alertFormStore = this._surveyAlerts.get(uuid);

        if (!alertFormStore) {
            const alertFormApi = await getSingleAlertByUUIDFromApi(uuid);
            return alertFormApi || Promise.reject(alertFormApi);
        }

        return Promise.resolve(JSON.parse(JSON.stringify(alertFormStore)));
    }

    public clearStore = () => {
        this._startInitialization = false;
        this._storeInitialize = false;
        this._surveyAlerts = observable.map({});
    };
}

export const alertsStore = new AlertsStore();
