import {
    OngoingSurvey,
    SurveyClasses,
    SimpleSurvey,
    SurveyResponse,
} from "@difftone/types";
import { getUserUuid } from "@difftone/procedures";
import {
    convertGoogleFormAnswers,
    convertGoogleFormsQuestions,
    generateConvertedSurveyUUID,
    generateResponderForConvertedResponse,
    Schema$Form,
    Schema$FormResponse,
    Schema$Info,
} from "../../conversion-helpers";

import { createOngoingSurvey } from "@difftone/frontend-common";
import { createDefaultEmptySystemEvent } from "./factory";
import { getSelectedOrganizationOrThrow } from "@difftone/reducers";

const convertSimpleToOnGoingSurvey = (
    simpleSurvey: SimpleSurvey
): OngoingSurvey => {
    //Preserves previous data
    const simpleSurveyAsOngoing = simpleSurvey as unknown as OngoingSurvey;

    return {
        ...simpleSurvey,
        survey_class: "ONGOING",
        manually_ended: simpleSurveyAsOngoing.manually_ended || null,
    };
};

const convertOnGoingToSimpleSurvey = (
    ongoingSurvey: OngoingSurvey
): SimpleSurvey => {
    //Preserves previous data
    const ongoingSurveyAsSimple = ongoingSurvey as unknown as SimpleSurvey;

    if (ongoingSurvey.status === "ACTIVE") {
        throw Error(
            "[Difftone]:: Cannot convert ongoing survey with active survey status."
        );
    }
    return {
        ...ongoingSurvey,
        survey_class: "SIMPLE",
        survey_events:
            ongoingSurveyAsSimple.survey_events ||
            createDefaultEmptySystemEvent(ongoingSurveyAsSimple.uuid),
        status: ongoingSurveyAsSimple.status,
    };
};

const convertGoogleFormToOnGoingSurvey = (
    googleFormData: Schema$Form
): SurveyClasses => {
    const _organization = getSelectedOrganizationOrThrow();

    if (!_organization) {
        throw Error(
            `[Difftone]:: Could not create a survey with organization ${_organization}`
        );
    }
    const newSurvey = createOngoingSurvey(_organization.uuid);

    if (!googleFormData) throw new Error("No google form data to convert");
    if (
        !googleFormData.formId ||
        !googleFormData.revisionId ||
        !googleFormData.info ||
        !googleFormData.items ||
        !googleFormData.items.length
    )
        throw new Error("Google form is missing required data");

    const googleFormInfo: Schema$Info = googleFormData.info;

    try {
        newSurvey.status = "ACTIVE";
        newSurvey.uuid = generateConvertedSurveyUUID(
            googleFormData.formId,
            googleFormData.revisionId
        );

        newSurvey.survey_name =
            googleFormInfo.title || googleFormInfo.documentTitle || "";
        newSurvey.default_survey_name = `default-${
            googleFormInfo.title || googleFormInfo.documentTitle
        }`;
        newSurvey.title = googleFormInfo.documentTitle || "";
        newSurvey.welcome_message = googleFormInfo.description || "";
        newSurvey.questions = convertGoogleFormsQuestions(googleFormData.items);
        newSurvey.is_imported = true;
        return newSurvey;
    } catch (error) {
        throw new Error("Error converting survey");
    }
};

const convertGoogleFormResponsesToSurveyResponses = (
    googleFormData: Schema$Form,
    googleFormResponsesData: Schema$FormResponse[]
): SurveyResponse[] => {
    if (!googleFormData) throw new Error("No google form data to convert");
    if (
        !googleFormData.formId ||
        !googleFormData.revisionId ||
        !googleFormData.info ||
        !googleFormData.items ||
        !googleFormData.items.length
    )
        throw new Error("Google form is missing required data");
    if (!googleFormResponsesData.length)
        throw new Error("No google form response data to convert");
    return googleFormResponsesData.map(
        (googleFormResponse: Schema$FormResponse): SurveyResponse => {
            if (
                !googleFormResponse.responseId ||
                !googleFormResponse.lastSubmittedTime ||
                !googleFormResponse.createTime ||
                !googleFormResponse.answers
            )
                throw new Error("Google form is missing required data");
            const newSurveyResponse = {
                uuid: googleFormResponse.responseId,
                version: "0.0.1",
                responder: generateResponderForConvertedResponse(
                    googleFormResponse.responseId
                ),
                submission_date: new Date(
                    googleFormResponse.lastSubmittedTime
                ).getTime(),
                survey_UUID: generateConvertedSurveyUUID(
                    googleFormData.formId!,
                    googleFormData.revisionId!
                ),
                survey_initiator: getUserUuid(),
                answers: convertGoogleFormAnswers(
                    googleFormResponse.answers,
                    googleFormData
                ),
                attribute_answers: [],
                is_deleted: false,
                created_at: new Date(googleFormResponse.createTime).getTime(),
                is_imported: true,
            };

            return newSurveyResponse;
        }
    );
};

export const copySurvey = (baseSurvey: SurveyClasses): SurveyClasses => {
    const copySurvey: SurveyClasses = JSON.parse(JSON.stringify(baseSurvey));

    copySurvey.uuid = setCopyUUID(copySurvey.uuid);

    copySurvey.questions.forEach((question) => {
        question.uuid = setCopyUUID(question.uuid);
    });

    switch (copySurvey.survey_class) {
        case "ONGOING":
            break;
        case "SIMPLE":
        default:
            (copySurvey as SimpleSurvey).survey_events.forEach(
                (surveyEvent) => {
                    surveyEvent.uuid = setCopyUUID(surveyEvent.uuid);
                }
            );
    }

    return copySurvey;
};

const setCopyUUID = (uuid: string) => {
    return `COPY-${uuid}`;
};

export const surveyClassConvertor = (
    baseSurvey: SurveyClasses
): SurveyClasses => {
    const copyBaseSurvey = JSON.parse(JSON.stringify(baseSurvey));
    switch (baseSurvey.survey_class) {
        case "SIMPLE":
            return convertSimpleToOnGoingSurvey(copyBaseSurvey);
        case "ONGOING":
            return convertOnGoingToSimpleSurvey(copyBaseSurvey);
        default:
            return convertOnGoingToSimpleSurvey(copyBaseSurvey);
    }
};

export const googleFormConverter = (googleForm: any): SurveyClasses => {
    return convertGoogleFormToOnGoingSurvey(googleForm);
};

export const googleFormResponseConverter = (
    googleForm: any,
    googleFormResponses: any[]
): SurveyResponse[] => {
    return convertGoogleFormResponsesToSurveyResponses(
        googleForm,
        googleFormResponses
    );
};
