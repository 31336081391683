import type {
    SurveyEventAlert,
    ValidationResult,
    ValidationType,
} from "@difftone/types";
import { validateSendTo } from "./properties-validations";

//SurveyEventAlert
export const validateSurveyEventAlert = (
    surveyEventAlert: SurveyEventAlert
): ValidationResult<ValidationType>[] => {
    try {
        const result: ValidationResult<ValidationType>[] = [];

        result.push(...validateSendTo(surveyEventAlert));

        return result;
    } catch (error) {
        console.error(
            `validateSurveyDates error:: ${(error as Error).message}`
        );
        return [
            {
                is_valid: false,
                validation_error_code: "EXCEPTION_DURING_VALIDATIONS",
                validation_error_type: error as Error,
            },
        ];
    }
};

//SurveyEventAlerts
export const validateSurveyEventAlerts = (
    SurveyEventAlerts: SurveyEventAlert[]
): ValidationResult<ValidationType>[] => {
    try {
        return [
            {
                is_valid: true,
                validation_error_code: "EMPTY_VALIDATION",
            },
        ];
    } catch (error) {
        console.error(
            `validateSurveyDates error:: ${(error as Error).message}`
        );
        return [
            {
                is_valid: false,
                validation_error_code: "EXCEPTION_DURING_VALIDATIONS",
                validation_error_type: error as Error,
            },
        ];
    }
};
