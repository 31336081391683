import React from "react";
import { v4 as uuid } from "uuid";

import {
    MultipleChoiceQuestion,
    Question,
    QuestionType,
    QuestionTypeName,
    SingleChoiceQuestion,
} from "@difftone/types";
import {
    nullifyWizardValidationError,
    updateQuestionInWizard,
} from "@difftone/actions";
import { wizardStore } from "@difftone/stores";

import collapsedSingleChoice from "@difftone/assets/collapsed-single-choice.svg";
import collapsedMultipleChoice from "@difftone/assets/collapsed-multiple-choice.svg";
import collapsedRating from "@difftone/assets/collapsed-rating.svg";
import collapsedDate from "@difftone/assets/collapsed-date.svg";
import collapsedTime from "@difftone/assets/clock-icon.svg";
import collapsedShortText from "@difftone/assets/collapsed-short-text.svg";
import collapsedLongText from "@difftone/assets/collapsed-long-text.svg";

import { CollapsedStyle, ExpandedStyle } from "./components";

import { type_wrapper } from "./question-type-indicator.module.css";

export type QuestionTypeIndicatorProps = {
    question: Question<QuestionType>;
    collapsed: boolean;
    disabled?: boolean;
};

export const QuestionTypeIndicator = (props: QuestionTypeIndicatorProps) => {
    const { question, collapsed, disabled } = props;

    const onSelectedItemChange = (value: QuestionTypeName) => {
        nullifyWizardValidationError();

        if (question.question_type_name === value) return;

        updateQuestionInWizard(question, "question_type_name", value);

        switch (value) {
            case "RATING":
                updateQuestionInWizard(
                    question,
                    "content",
                    wizardStore.getNewQuestionContent("RATING")
                );

                break;
            case "DATE":
                updateQuestionInWizard(
                    question,
                    "content",
                    wizardStore.getNewQuestionContent("DATE")
                );

                break;
            case "TIME":
                updateQuestionInWizard(
                    question,
                    "content",
                    wizardStore.getNewQuestionContent("TIME")
                );

                break;
            case "LONG_TEXT":
            case "SHORT_TEXT":
                updateQuestionInWizard(
                    question,
                    "content",
                    wizardStore.getNewQuestionContent("SHORT_TEXT")
                );

                break;
            case "MULTIPLE_CHOICE": {
                const newContent = wizardStore.getNewQuestionContent(
                    "MULTIPLE_CHOICE"
                ) as MultipleChoiceQuestion;
                if (question.question_type_name === "SINGLE_CHOICE") {
                    const singleChoiceQuestion =
                        question as Question<SingleChoiceQuestion>;
                    newContent.text = singleChoiceQuestion.content.text;
                    newContent.choice_options =
                        singleChoiceQuestion.content.choice_options.map(
                            (option) => option.label
                        );
                }

                updateQuestionInWizard(question, "content", newContent);

                break;
            }
            case "SINGLE_CHOICE": {
                const newContent = wizardStore.getNewQuestionContent(
                    "SINGLE_CHOICE"
                ) as SingleChoiceQuestion;
                if (question.question_type_name === "MULTIPLE_CHOICE") {
                    const multipleChoiceQuestion =
                        question as Question<MultipleChoiceQuestion>;
                    newContent.text = multipleChoiceQuestion.content.text;
                    newContent.choice_options =
                        multipleChoiceQuestion.content.choice_options.map(
                            (option) => ({
                                uuid: uuid(),
                                version: "0.0.1",
                                label: option,
                                gotos: [],
                            })
                        );
                }

                updateQuestionInWizard(question, "content", newContent);

                break;
            }
            default:
                break;
        }
    };

    const renderQuestionIndicator = () => {
        if (collapsed) {
            switch (question.question_type_name) {
                case "SINGLE_CHOICE":
                    return (
                        <CollapsedStyle
                            icon={collapsedSingleChoice}
                            question={question}
                        />
                    );
                case "MULTIPLE_CHOICE":
                    return (
                        <CollapsedStyle
                            icon={collapsedMultipleChoice}
                            question={question}
                        />
                    );
                case "RATING":
                    return (
                        <CollapsedStyle
                            icon={collapsedRating}
                            question={question}
                        />
                    );
                case "DATE":
                    return (
                        <CollapsedStyle
                            icon={collapsedDate}
                            question={question}
                        />
                    );
                case "TIME":
                    return (
                        <CollapsedStyle
                            icon={collapsedTime}
                            question={question}
                        />
                    );
                case "SHORT_TEXT":
                    return (
                        <CollapsedStyle
                            icon={collapsedShortText}
                            question={question}
                        />
                    );
                case "LONG_TEXT":
                    return (
                        <CollapsedStyle
                            icon={collapsedLongText}
                            question={question}
                        />
                    );
                case "BOOKING":
                    return (
                        <CollapsedStyle
                            icon={collapsedMultipleChoice}
                            question={question}
                        />
                    );
                default:
                    return "err";
            }
        } else {
            return (
                <ExpandedStyle
                    onSelectedItemChange={onSelectedItemChange}
                    question={question}
                    disabled={disabled}
                />
            );
        }
    };

    return <div className={type_wrapper}>{renderQuestionIndicator()}</div>;
};
