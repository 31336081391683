import React from "react";

import settingsIcon from "@difftone/assets/settings-icon.svg";
import { showGoogleFormsDialog } from "@difftone/actions";

import { button, button_icon } from "./import-google-form-button.module.css";

export const ImportGoogleFormButton = () => (
    <li className={button} onClick={showGoogleFormsDialog}>
        <img src={settingsIcon} alt="" className={button_icon} />
        Import
    </li>
);
