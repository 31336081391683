import React, { MouseEvent, useEffect } from "react";
import { AddBoxShadow } from "@difftone/shared-components";
import { handleIfDropdownFitsViewPort } from "@difftone/procedures";

import { AutoCompleteItem } from "./components";

import {
    auto_complete_title,
    auto_complete_title_wrapper,
} from "./auto-complete-title.module.css";

export type AutoCompleteTitleProps = {
    activeIndex: number;
    autoCompleteList: string[];
    onMouseClick: (event: MouseEvent<HTMLDivElement>) => void;
    onMouseOver: (event: MouseEvent<HTMLDivElement>) => void;
    isOpen: boolean;
};

export const AutoCompleteTitle = (props: AutoCompleteTitleProps) => {
    const { autoCompleteList, activeIndex, onMouseClick, onMouseOver, isOpen } =
        props;

    //useEffect:: call handleIfDropdownFitsViewPort when auto complete is opened and the list has been changed
    useEffect(() => {
        const autoCompleteWrapper: HTMLDivElement | null =
            document.querySelector("div[data-auto-complete-wrapper]");
        const accordionWrapper: HTMLDivElement | null = document.querySelector(
            `div[data-scroll-element="accordion-wrapper"]`
        );
        isOpen &&
            handleIfDropdownFitsViewPort(accordionWrapper, autoCompleteWrapper);
    }, [isOpen, autoCompleteList.length]);

    return autoCompleteList.length > 0 && isOpen ? (
        <AddBoxShadow>
            <div
                data-auto-complete-wrapper
                className={auto_complete_title_wrapper}
            >
                <div className={auto_complete_title}>
                    {autoCompleteList.map((title, index) => (
                        <AutoCompleteItem
                            onHover={onMouseOver}
                            key={title}
                            isActiveTitle={activeIndex === index}
                            title={title}
                            index={index}
                            onClick={onMouseClick}
                        />
                    ))}
                </div>
            </div>
        </AddBoxShadow>
    ) : null;
};
