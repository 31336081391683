import React, { useState } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import deleteGreenIcon from "@difftone/assets/delete-green-icon.svg";
import { BellReminderIcon } from "@difftone/shared-components";
import {
    deleteAlert,
    deleteCopyAlert,
    setSelectedEventAlert,
} from "@difftone/actions";
import { wizardDisplayStore } from "@difftone/stores";
import {
    EventAlertsDisplay,
    SurveyEvent,
    SurveyEventAlert,
} from "@difftone/types";
import {
    reminder_item,
    reminder_text_bell,
    buttons_container,
    delete_icon,
    reminder_item_selected,
    reminder_item_container,
    reminder_item_active,
    reminder_item_disabled,
    reminder_text_bell_active,
    reminder_text_bell_disabled,
    reminder_item_selected_disabled,
    bell_reminder_icon_scope,
} from "./reminder-item.module.css";
import {
    getAlertOffsetInMilliSeconds,
    totalTimeInMiliSecondsFromString,
} from "@difftone/time-utils";

export type ReminderItemProps = {
    alertIndex: number;
    eventAlert: SurveyEventAlert;
    surveyEvent: SurveyEvent;
};

const COLOR_GRAY = "GRAY";
const COLOR_GREEN = "GREEN";

export const ReminderItem = observer((props: ReminderItemProps) => {
    const { selectedDisplayEventAlert, isEditableSurvey } = wizardDisplayStore;
    const { alertIndex, eventAlert, surveyEvent } = props;

    console.log("surveyEvent", surveyEvent);

    const [isHovered, setIsHovered] = useState(false);

    const onMouseEnter = () => {
        setIsHovered(true);
    };

    const onMouseLeave = () => {
        setIsHovered(false);
    };

    const deleteAlertFromEvent = () => {
        selectedDisplayEventAlert?.eventAlertUUID === eventAlert.uuid &&
            setSelectedEventAlert(null);
        isEditableSurvey
            ? deleteAlert(eventAlert.uuid)
            : deleteCopyAlert(eventAlert.uuid);
    };

    const selectDisplayEventHandler = () => {
        const alertToDisplay: EventAlertsDisplay = {
            alertNumber: alertIndex + 1,
            eventAlertUUID: eventAlert.uuid,
        };
        setSelectedEventAlert(alertToDisplay);
    };

    const alertDate =
        totalTimeInMiliSecondsFromString(
            surveyEvent.date_utc!,
            surveyEvent.day_utc_time!
        ) + getAlertOffsetInMilliSeconds(eventAlert);

    const isPastEventAlert = alertDate < Date.now();

    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={clsx(
                reminder_item,
                {
                    [reminder_item_active]: !isPastEventAlert,
                    [reminder_item_disabled]: isPastEventAlert,
                },
                selectedDisplayEventAlert?.eventAlertUUID ===
                    eventAlert?.uuid && {
                    [reminder_item_selected]: !isPastEventAlert,
                    [reminder_item_selected_disabled]: isPastEventAlert,
                }
            )}
        >
            <div
                className={reminder_item_container}
                onClick={selectDisplayEventHandler}
            >
                <div
                    className={clsx(reminder_text_bell, {
                        [reminder_text_bell_active]: !isPastEventAlert,
                        [reminder_text_bell_disabled]: isPastEventAlert,
                    })}
                >
                    <BellReminderIcon
                        className={bell_reminder_icon_scope}
                        color={isPastEventAlert ? COLOR_GRAY : COLOR_GREEN}
                    />
                    <span>{`Reminder ${alertIndex + 1}`}</span>
                </div>
            </div>
            {isHovered && !isPastEventAlert ? (
                <div className={buttons_container}>
                    <img
                        onClick={deleteAlertFromEvent}
                        className={delete_icon}
                        src={deleteGreenIcon}
                    />
                </div>
            ) : null}
        </div>
    );
});
