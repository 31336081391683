import {
    MultipleChoiceQuestion,
    Question,
    QuestionType,
    SingleChoiceQuestion,
    ValidationResult,
    ValidationType,
} from "@difftone/types";

import {
    validateQuestionEmptyNickname,
    validateQuestionTitle,
} from "./properties-validations";
import { validateCategoryProp } from "./properties-validations/validate-category-prop";
import {
    validateMultipleChoiceQuestion,
    validateSingleChoiceQuestion,
} from "./type-specific-question-validations";
import {
    validateQuestionNameUniqueness,
    validateQuestionNicknameUniqueness,
} from "./uniqueness-validations";
import {
    CategoriesRatingMap,
    formatCategoriesRatingMap,
} from "./categories-validations";

//QUESTION
export const validateQuestion = (
    question: Question<QuestionType>,
    categoriesRatingMap: CategoriesRatingMap
): ValidationResult<ValidationType>[] => {
    const res: ValidationResult<ValidationType>[] = [];

    res.push(...validateQuestionTitle(question));
    res.push(...validateCategoryProp(question));

    switch (question.question_type_name) {
        case "SINGLE_CHOICE":
            res.push(
                ...validateSingleChoiceQuestion(
                    question as Question<SingleChoiceQuestion>
                )
            );
            break;
        case "MULTIPLE_CHOICE":
            res.push(
                ...validateMultipleChoiceQuestion(
                    question as Question<MultipleChoiceQuestion>
                )
            );
            break;
        default:
            break;
    }

    return res;
};

export const validateQuestionsUniqueness = (
    questions: Question<QuestionType>[]
) => {
    const res: ValidationResult<ValidationType>[] = [];

    questions.forEach((question) =>
        res.push(
            ...validateQuestionNameUniqueness(
                question,
                questions.filter(
                    (_question) => _question.uuid !== question.uuid
                )
            )
        )
    );

    questions.forEach((question) => {
        if (question.is_filter) {
            res.push(
                ...validateQuestionNicknameUniqueness(
                    question,
                    questions.filter(
                        (_question) => _question.uuid !== question.uuid
                    )
                )
            );
        }
    });
    questions.forEach((question) => {
        if (question.is_filter) {
            res.push(...validateQuestionEmptyNickname(question));
        }
    });

    return res;
};

//QUESTIONS
export const validateQuestions = (
    questions: Question<QuestionType>[]
): ValidationResult<ValidationType>[] => {
    const res: ValidationResult<ValidationType>[] = [];

    const categoriesRatingMap = formatCategoriesRatingMap(questions);

    questions.forEach((question) =>
        res.push(...validateQuestion(question, categoriesRatingMap))
    );

    res.push(...validateQuestionsUniqueness(questions));

    return res;
};
