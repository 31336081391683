import React from "react";
import dayjs from "dayjs";
import { ShowDifftoneTooltip } from "@difftone/shared-components";

import { OngoingSurvey } from "@difftone/types";

import { UiStatus } from "@difftone/frontend-common";

export type OngoingSurveyDateProps = {
    isManuallyEnded: boolean;
    survey: OngoingSurvey;
    miliseconds?: number | null;
};

export const OngoingSurveyDate = (props: OngoingSurveyDateProps) => {
    const { miliseconds, isManuallyEnded, survey } = props;

    const displayTextDate = () => {
        const correctDateFormat = miliseconds
            ? dayjs(miliseconds).format("MMM DD, YYYY")
            : "none";

        const tooltipStringFormat = miliseconds
            ? dayjs(miliseconds).format("MMM DD, YYYY HH:mm")
            : "none";

        return (
            <div>
                <ShowDifftoneTooltip
                    tooltipPosition="bottom"
                    tip={tooltipStringFormat}
                >
                    {correctDateFormat}
                </ShowDifftoneTooltip>
            </div>
        );
    };

    if (isManuallyEnded) {
        if (!survey.manually_ended) return <div>-- --- ----</div>;
        return displayTextDate();
    } else {
        return displayTextDate();
    }
};
