import type {
    SurveyEventAlert,
    ValidationResult,
    ValidationType,
} from "@difftone/types";
import { validateEmailAddresses } from "../../shared-validations-utils";

export const validateSendTo = (
    surveyEventAlert: SurveyEventAlert
): ValidationResult<ValidationType>[] => {
    try {
        const result: ValidationResult<ValidationType>[] = [];

        result.push(...validateEmailAddresses(surveyEventAlert.send_to));

        return result;
    } catch (error) {
        console.error(
            `validateSurveyDates error:: ${(error as Error).message}`
        );
        return [
            {
                is_valid: false,
                validation_error_code: "EXCEPTION_DURING_VALIDATIONS",
                validation_error_type: error as Error,
            },
        ];
    }
};
