import { action } from "mobx";

import { EmailAddress, UUID } from "@difftone/types";

import {
    autoFillSurveyApi,
    deleteAllMocksApi,
    deleteMocksApiByNamespace,
} from "../services";

export const deleteAllMocksFromDB = action(async (): Promise<any> => {
    return deleteAllMocksApi();
});

export const deleteAllMocksByNamespaceFromDB = action(
    async (namespace: string): Promise<any> => {
        return deleteMocksApiByNamespace(namespace);
    }
);

export const autoFillSurvey = action(
    (
        numOfUsers: number,
        baseEmail: EmailAddress,
        surveyUuid: UUID
    ): Promise<any> => {
        return autoFillSurveyApi(numOfUsers, baseEmail, surveyUuid);
    }
);
