import { action } from "mobx";
import { v4 as uuid } from "uuid";
import {
    getUtcDateFromLocalDateAndTime,
    milisecondsToDateString,
    dateStrAndTimeStrToDateObject,
    getNormalizeOffsetInMiliseconds,
} from "@difftone/time-utils";

import {
    EmailAddress,
    SurveyEvent,
    SurveyEventAlert,
    SystemSurveyAlertType,
    SystemSurveyEventType,
    UUID,
    SimpleSurvey,
} from "@difftone/types";
import { wizardStore, organizationStore } from "@difftone/stores";
import {
    getLocalDateInMilsByEventType,
    getSelectedOrganizationOrThrow,
    getUtcDateAtMidnightInMilsByEventType,
} from "@difftone/reducers";
import {
    createDefaultEmptySystemEvent,
    createSimpleSurvey,
    EVENT_ALERT_TEMPLET,
    getNewEmptyQuestion,
    SURVEY_EVENT_TEMPLET,
} from "@difftone/frontend-common";

export const DEFAULT_CREATE_EVENT_TIME = "8:00";

export const getNewEmptySurveyEvent = action(
    (
        eventNumber: number,
        alertsUUIDs: UUID[] = [],
        system_survey_event_type?: SystemSurveyEventType,
        title?: string
    ): SurveyEvent => {
        const newEmptySurveyEvent: SurveyEvent = JSON.parse(
            JSON.stringify(SURVEY_EVENT_TEMPLET)
        );

        //Gets the utc day time string after setting local time at 8:00
        const { dayUtcTimeStr } = dateStrAndTimeStrToDateObject(
            milisecondsToDateString(
                getUtcDateAtMidnightInMilsByEventType(system_survey_event_type)
            ),
            DEFAULT_CREATE_EVENT_TIME
        );

        const localDateStr = milisecondsToDateString(
            getLocalDateInMilsByEventType(system_survey_event_type)
        );

        newEmptySurveyEvent.uuid = uuid();
        newEmptySurveyEvent.title = title || "";

        newEmptySurveyEvent.date_utc = getUtcDateFromLocalDateAndTime(
            localDateStr,
            DEFAULT_CREATE_EVENT_TIME
        );

        newEmptySurveyEvent.day_utc_time = dayUtcTimeStr;
        newEmptySurveyEvent.utc_offset = getNormalizeOffsetInMiliseconds();
        newEmptySurveyEvent.event_number = eventNumber;
        newEmptySurveyEvent.event_alerts = alertsUUIDs;
        newEmptySurveyEvent.system_survey_event_type =
            system_survey_event_type ||
            newEmptySurveyEvent.system_survey_event_type;
        return newEmptySurveyEvent;
    }
);

export const getNewEmptySurveyEventAlert = action(
    (
        surveyEvent: SurveyEvent,
        approvers: EmailAddress[] = [],
        respondents: EmailAddress[] = [],
        alertType?: SystemSurveyAlertType
    ): SurveyEventAlert => {
        const newEmptySurveyEventAlert: SurveyEventAlert = JSON.parse(
            JSON.stringify(EVENT_ALERT_TEMPLET)
        );
        newEmptySurveyEventAlert.uuid = uuid();

        newEmptySurveyEventAlert.approvers = approvers;
        newEmptySurveyEventAlert.send_to = respondents;
        newEmptySurveyEventAlert.content.button_title = "SEND";
        newEmptySurveyEventAlert.event_UUID = surveyEvent.uuid;
        if (alertType) {
            newEmptySurveyEventAlert.alert_type = alertType;
        }
        return newEmptySurveyEventAlert;
    }
);

export const getNewEmptySurveyFromTemplate = action((): SimpleSurvey => {
    const _organization = getSelectedOrganizationOrThrow();

    if (!_organization) {
        throw Error(
            `[Difftone]:: Could not create a survey with organization ${_organization}`
        );
    }

    const newSimpleSurvey: SimpleSurvey = createSimpleSurvey(
        [],
        _organization.uuid
    );

    newSimpleSurvey.status = "DRAFT";

    newSimpleSurvey.survey_events = createDefaultEmptySystemEvent(
        newSimpleSurvey.uuid
    );

    const newQuestion = getNewEmptyQuestion(1);
    newSimpleSurvey.questions.push(newQuestion);

    return newSimpleSurvey;
});
