import React, { ReactNode } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import { ShowDifftoneTooltip } from "@difftone/shared-components";

import {
    table_row_cell,
    table_row_cell_ellipsis,
} from "./table-row-cell.module.css";

export type TableRowCellProps = {
    children: ReactNode;
    className?: string;
    tooltipText?: string;
    maxTextLength?: number;
};

const DEFAULT_MAX_TEXT_LENGTH = 35;

export const TableRowCell = observer((props: TableRowCellProps) => {
    const {
        children,
        className,
        tooltipText,
        maxTextLength = DEFAULT_MAX_TEXT_LENGTH,
    } = props;

    return (
        <div
            className={clsx(table_row_cell, className, {
                [table_row_cell_ellipsis]: tooltipText,
            })}
        >
            {tooltipText && tooltipText.length >= maxTextLength ? (
                <ShowDifftoneTooltip tip={tooltipText} tooltipPosition="top">
                    {children}
                </ShowDifftoneTooltip>
            ) : (
                <>{children}</>
            )}
        </div>
    );
});
