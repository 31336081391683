import { makeAutoObservable } from "mobx";

import { User } from "@difftone/types";
import {
    getUserAuthProvider,
    getUserEmail,
    getUserFullName,
    getUserUuid,
} from "@difftone/procedures";
import { addUserToApi } from "@difftone/services";
import { getCurrentTimeInBEFormat } from "@difftone/frontend-common";

class UserStore {
    constructor() {
        makeAutoObservable(this);
    }

    public upsertUserToApi = async () => {
        const user: User = {
            uuid: getUserUuid(),
            auth_provider_uuid: getUserUuid(),
            name: getUserFullName(),
            version: "0.0.2",
            auth_provider: getUserAuthProvider(),
            email: getUserEmail(),
            country: "",
            is_deleted: false,
            created_at: getCurrentTimeInBEFormat(),
        };

        return await addUserToApi(user);
    };

    public clearStore = () => {};
}

export const userStore = new UserStore();
