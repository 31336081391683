import React from "react";
import { observer } from "mobx-react";

import { showManageTemplatesDialog } from "@difftone/actions";
import settingsIcon from "@difftone/assets/settings-icon.svg";

import {
    templates_button,
    templates_button_icon,
} from "./manage-templates-button.module.css";

export type ManageTemplatesButtonProps = {};

export const ManageTemplatesButton = observer(() => {
    return (
        <li className={templates_button} onClick={showManageTemplatesDialog}>
            <img src={settingsIcon} alt="" className={templates_button_icon} />
            Templates
        </li>
    );
});
