import { action } from "mobx";
import { getUserUuid } from "@difftone/procedures";

import { SharedSurvey, UUID, SurveyClasses } from "@difftone/types";
import { sharedSurveyStore } from "@difftone/stores";
import { getSharedSurveyFromStoreByURL } from "@difftone/reducers";
import { debounceFunction } from "@difftone/frontend-common";

export const updateSharedSurvey = action((sharedSurvey: SharedSurvey) => {
    sharedSurveyStore.addSharedSurvey([sharedSurvey]);
});

export const updateSharedSurveyToApi = (
    key?: keyof SharedSurvey,
    value?: ValueOf<SharedSurvey>
) => {
    let sharedSurvey = JSON.parse(
        JSON.stringify(getSharedSurveyFromStoreByURL())
    );
    const update = key && value ? { [key]: value } : {};
    sharedSurvey = Object.assign({}, sharedSurvey, update);

    updateSharedSurvey(sharedSurvey);
};

export const debounceUpdateSharedSurveyToApi = debounceFunction(
    updateSharedSurveyToApi,
    500
);

export const createSharedSurveyToStore = action(
    (survey: SurveyClasses, sharedSurveyUUID: UUID) => {
        const sharedSurvey: SharedSurvey = {
            uuid: sharedSurveyUUID,
            survey: survey,
            deleted: false,
            archive: false,
            shared_survey_status: "DRAFT",
            addressee: [],
            sender: getUserUuid(),
            survey_events_alerts: [],
            participant_type: "DISTRIBUTER",
            timestamp: Date.now(),
        };

        sharedSurveyStore.addSharedSurvey([sharedSurvey]);

        return sharedSurvey;
    }
);

export const onSharedSurveyClick = action(
    async (surveyToShare: SurveyClasses, sharedSurveyUUID: UUID) => {
        const copySharedSurvey: SurveyClasses = JSON.parse(
            JSON.stringify(surveyToShare)
        );

        const sharedSurvey = createSharedSurveyToStore(
            surveyToShare,
            sharedSurveyUUID
        );

        copySharedSurvey.initiator = "";
        copySharedSurvey.participant_introduction =
            surveyToShare.participant_introduction;

        sharedSurvey.survey = copySharedSurvey;

        sharedSurveyStore.addSharedSurvey([sharedSurvey]);
    }
);
