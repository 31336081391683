import React from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import { Popper, PopperProps } from "@difftone/shared-components";

import {
    popper_actions_results_root,
    actions_popper,
    actions_popper_no_export,
    actions_item,
    actions_item_icon,
} from "./actions-popup.module.css";
import { DownloadIcon, ShareIcon, BookmarkIcon } from "./components";

export type ActionsPopupProps = {
    openBookmarkDialog: () => void;
    openShareDialog: () => void;
    onExport: () => void;
    anchorEl: PopperProps["anchorEl"];
    open: boolean;
    onClose: () => void;
    isExportHidden?: boolean;
    isSharedMode?: boolean;
};

export const ActionsPopup = observer((props: ActionsPopupProps) => {
    const {
        open,
        anchorEl,
        onClose,
        isExportHidden,
        isSharedMode,
        openBookmarkDialog,
        openShareDialog,
        onExport,
    } = props;

    return (
        <Popper
            className={popper_actions_results_root}
            contentClassName={clsx(actions_popper, {
                [actions_popper_no_export]: isExportHidden,
            })}
            onClose={onClose}
            open={open}
            anchorEl={anchorEl}
        >
            <button className={actions_item} onClick={openBookmarkDialog}>
                <BookmarkIcon className={actions_item_icon} />

                <span>Bookmark</span>
            </button>
            {!isExportHidden && (
                <button className={actions_item} onClick={onExport}>
                    <DownloadIcon className={actions_item_icon} />

                    <span>Download</span>
                </button>
            )}
            <button
                className={actions_item}
                disabled={isSharedMode}
                onClick={openShareDialog}
            >
                <ShareIcon className={actions_item_icon} />

                <span>Share</span>
            </button>
        </Popper>
    );
});
