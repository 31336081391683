import { action } from "mobx";
import { SurveyResponseStatus, UUID } from "@difftone/types";
import { surveyResponseStatusStore } from "@difftone/stores";

export const surveyResponseStatusMapSetter = action(
    (surveyUUID: UUID, surveyResponseStatuses: SurveyResponseStatus[]) => {
        const copyOfStatusesMap: {
            [x: string]: SurveyResponseStatus[];
        } = JSON.parse(
            JSON.stringify(surveyResponseStatusStore.surveyResponseStatusesMap)
        );

        copyOfStatusesMap[surveyUUID] = surveyResponseStatuses;

        surveyResponseStatusStore.surveyResponseStatusesMap = copyOfStatusesMap;
    }
);
