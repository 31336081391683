//These are the scopes that we request at user login
export const GoogleLoginScopes = [
    "https://www.googleapis.com/auth/userinfo.profile",
];

//These are the scopes that we request when the user does something that requires contacts
export const GoogleContactScopes = [
    "https://www.googleapis.com/auth/contacts.readonly",
    "https://www.googleapis.com/auth/contacts.other.readonly",
    "https://www.googleapis.com/auth/cloud-identity.groups.readonly",
];

export const GoogleFormScopes = [];

//These are the scopes that we only request for Difftone
export const DifftoneOnlyGoogleContactScopes = [];

//These are the scopes that we only request for Difftone
export const DifftoneOnlyGoogleFormScopes = [
    "https://www.googleapis.com/auth/forms.body.readonly",
    "https://www.googleapis.com/auth/forms.responses.readonly",
];
