import type {
    SurveyEvent,
    SurveyEventsDateValidationType,
    UUID,
    ValidationResult,
} from "@difftone/types";
import { totalTimeInMiliSecondsFromString } from "@difftone/time-utils";

export const validateEventTime = (
    surveyEvents: SurveyEvent[]
): ValidationResult<SurveyEventsDateValidationType>[] => {
    try {
        const res: UUID[] = [];

        const surveyPublishedDate = surveyEvents.find(
            (_surveyEvent: SurveyEvent) =>
                _surveyEvent.system_survey_event_type === "PUBLISHED_DATE"
        );

        const lastDateToFillSurvey = surveyEvents.find(
            (_surveyEvent: SurveyEvent) =>
                _surveyEvent.system_survey_event_type === "LAST_DATE_TO_FILL"
        );

        const publishDateInMiliSeconds = totalTimeInMiliSecondsFromString(
            surveyPublishedDate?.date_utc!,
            surveyPublishedDate?.day_utc_time!
        );

        const lastDateToFillInMiliSeconds = totalTimeInMiliSecondsFromString(
            lastDateToFillSurvey?.date_utc!,
            lastDateToFillSurvey?.day_utc_time!
        );

        if (lastDateToFillInMiliSeconds <= publishDateInMiliSeconds) {
            res.push(lastDateToFillSurvey!.uuid);
        }

        return [
            {
                is_valid: !res.length,
                validation_error_code: "SURVEY_EVENT_ILLEGAL_DATE",
                validation_error_type: { invalid_events_dates_uuids: res },
            },
        ];
    } catch (error) {
        console.error(
            `validateSurveyDates error:: ${(error as Error).message}`
        );
        return [
            {
                is_valid: false,
                validation_error_code: "EXCEPTION_DURING_VALIDATIONS",
                validation_error_type: {
                    invalid_events_dates_uuids: [(error as Error).message],
                },
            },
        ];
    }
};
