import axios from "axios";
import type { EmailAddress } from "@difftone/types";
import { IAuthProviderConnectionListService } from "../contracts";
import { localstorageUtils } from "@difftone/procedures";

export class MicrosoftConnectionListService
    implements IAuthProviderConnectionListService
{
    MICROSOFT_GRAPH_URL = "https://graph.microsoft.com/v1.0";

    public async getUserFullConnectionsEmailsList(): Promise<EmailAddress[]> {
        try {
            const currentAuthData =
                await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

            const accessToken = currentAuthData.access_token;
            const msContacts = await this.getContactsFromMicrosoft(accessToken);
            return msContacts;
        } catch (error) {
            throw error;
        }
    }

    private async getContactsFromMicrosoft(
        accessToken: string
    ): Promise<EmailAddress[]> {
        try {
            axios.defaults.headers.common["Accept"] = "application/json";
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${accessToken}`;

            const response = await axios.get(
                `${this.MICROSOFT_GRAPH_URL}/me/contacts`,
                {}
            );

            if (response.data.error) {
                throw response.data.error;
            }

            const resource: any[] = response.data.value;

            // @ts-ignore
            const otherContacts: EmailAddress[] = resource
                .map((person) => person.emailAddresses[0].name)
                .filter((email) => email !== undefined);

            return otherContacts;
        } catch (error) {
            console.error(
                `[Request to microsoft api failed]: ${(error as Error).message}`
            );
            return [];
        }
    }
}
