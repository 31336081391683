import { inboxStore, sharedResultStore } from "@difftone/stores";
import { SharedResult } from "@difftone/types";

const compareAccordingToAscending = (
    a: any,
    b: any,
    ascending: boolean
): number => {
    if (ascending) {
        return a >= b ? 1 : -1;
    }

    return a < b ? 1 : -1;
};

export const getFilteredSharedViews = (
    sortedSharedViews: SharedResult[]
): SharedResult[] => {
    if (!inboxStore.filterProfile) {
        return sortedSharedViews;
    }

    let results = [...sortedSharedViews];
    const filter = Object.assign({}, inboxStore.filterProfile);
    for (const filterType in filter) {
        let key = "" as keyof SharedResult;
        switch (filterType) {
            case "NAME":
                key = "share_name";
                break;
            default:
                return results;
        }
        results = results.filter((sharedView) => {
            // String
            if (
                typeof filter[filterType] === "string" &&
                key === "share_name"
            ) {
                return sharedView[key]
                    .toLowerCase()
                    .includes(filter[filterType].toLowerCase());
            }
            return filter[filterType] === sharedView["share_name"];
        });
    }
    return results;
};

export const getSortedSharedViews = (): SharedResult[] => {
    const { sharedViews, sortingSharedResultProfile } = sharedResultStore;
    const { sortBy, ascending } = sortingSharedResultProfile;

    const sortedSharedViews = [...sharedViews];

    switch (sortBy) {
        case "NAME":
            return sortedSharedViews.sort((sharedViewA, sharedViewB) =>
                compareAccordingToAscending(
                    sharedViewA.share_name.toLowerCase(),
                    sharedViewB.share_name.toLowerCase(),
                    ascending
                )
            );
        case "SHARED_BY":
            return sortedSharedViews.sort((sharedViewA, sharedViewB) =>
                compareAccordingToAscending(
                    sharedViewA.sharer_email.toLowerCase(),
                    sharedViewB.sharer_email.toLowerCase(),
                    ascending
                )
            );
        case "SURVEY_NAME":
            return sortedSharedViews.sort((sharedViewA, sharedViewB) =>
                compareAccordingToAscending(
                    sharedViewA.survey_name.toLowerCase(),
                    sharedViewB.survey_name.toLowerCase(),
                    ascending
                )
            );
        case "INITIATOR":
            return sortedSharedViews.sort((sharedViewA, sharedViewB) =>
                compareAccordingToAscending(
                    sharedViewA.survey_initiator.toLowerCase(),
                    sharedViewB.survey_initiator.toLowerCase(),
                    ascending
                )
            );
        case "CREATED":
            return ascending
                ? sortedSharedViews.sort(
                      (sharedViewA, sharedViewB) =>
                          sharedViewA.created_at - sharedViewB.created_at
                  )
                : sortedSharedViews.sort(
                      (sharedViewA, sharedViewB) =>
                          sharedViewB.created_at - sharedViewA.created_at
                  );
        default:
            return sortedSharedViews;
    }
};
