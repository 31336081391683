import { OPEN_SURVEY } from "./../constants/emails";
import { createDifftoneAxios } from "@difftone/services";
import axios, { AxiosInstance } from "axios";
import { BaseSurvey, UUID, SurveyClasses } from "@difftone/types";
import { getApiBaseUrl } from "@difftone/constants";
import { errorHandlerProxy, localstorageUtils } from "@difftone/procedures";

const SERVER_BASE_URL = `${getApiBaseUrl()}/surveys-service`;

export const getSurveysFromApiByParticipantEmail = async (): Promise<
    SurveyClasses[]
> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(`${SERVER_BASE_URL}/respondents`, {
        params: { issuer: currentAuthData.issuer },
    });

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.resources;
    return resource;
};

export const getSurveysFromApiForParticipantBySurveyUuid = async (
    surveyUUID: UUID
): Promise<SurveyClasses[]> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(`${SERVER_BASE_URL}/respondents`, {
        params: {
            issuer: currentAuthData.issuer,
            survey_UUID: surveyUUID,
        },
    });

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.resources;
    return resource;
};

export const getSurveysFromApiByInitiatorUuid = async (): Promise<
    SurveyClasses[]
> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(`${SERVER_BASE_URL}/initiator`, {
        params: {
            issuer: currentAuthData.issuer,
        },
    });

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.resources;
    return resource;
};

export const getSurveysFromApiForInitiatorBySurveyUuid = async (
    surveyUUID: UUID
): Promise<SurveyClasses[]> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(`${SERVER_BASE_URL}/initiator`, {
        params: {
            issuer: currentAuthData.issuer,
            survey_UUID: surveyUUID,
        },
    });

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.resources;
    return resource;
};

export const getArchivedSurveysFromApiByUserUuid = async (): Promise<
    SurveyClasses[]
> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(`${SERVER_BASE_URL}/archived`, {
        params: {
            issuer: currentAuthData.issuer,
        },
    });

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.resources;
    return resource;
};

//This end point currently not in use
export const getSurveysFromApiByAdminUserEmail = async (): Promise<
    SurveyClasses[]
> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(`${SERVER_BASE_URL}/admin`, {
        params: {
            issuer: currentAuthData.issuer,
        },
    });

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.resources;
    return resource;
};

export const getSurveyFromApiForAdminBySurveyUuid = async (
    surveyUUID: UUID
): Promise<SurveyClasses[]> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(`${SERVER_BASE_URL}/admin`, {
        params: {
            issuer: currentAuthData.issuer,
            survey_UUID: surveyUUID,
        },
    });

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.resources;
    return resource;
};

export const getOpenSurveysFromApiForParticipantBySurveyUuid = async (
    surveyUUID: UUID
): Promise<SurveyClasses[]> => {
    const _axiosClient: AxiosInstance = axios.create();
    _axiosClient.defaults.headers.common["Accept"] = "application/json";

    const response = await _axiosClient.get(
        `${SERVER_BASE_URL}/${OPEN_SURVEY}`,
        {
            params: {
                survey_UUID: surveyUUID,
            },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.resources;
    return resource;
};

export const saveNewSurveyToApi = async (
    survey: BaseSurvey
): Promise<BaseSurvey> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.post(
        `${SERVER_BASE_URL}/`,
        { new_survey: survey },
        { params: { issuer: currentAuthData.issuer } }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.results;
    return resource;
};

export const saveUpdatedSurveyToApi = async (
    survey: SurveyClasses
): Promise<SurveyClasses> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.patch(
        `${SERVER_BASE_URL}/`,
        { updated_survey: survey },
        { params: { issuer: currentAuthData.issuer } }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resources = response.data.results;
    return resources;
};

export const saveUpsertedSurveyToApi = async (
    survey: SurveyClasses
): Promise<SurveyClasses> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.put(
        `${SERVER_BASE_URL}/`,
        { upserted_survey: survey },
        { params: { issuer: currentAuthData.issuer } }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resources = response.data.results;
    return resources;
};
