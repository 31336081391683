import React, { ChangeEvent, useEffect } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { ValidationWrapper } from "@difftone/shared-components";
import { BaseSurvey, SurveyClasses } from "@difftone/types";
import { wizardStore } from "@difftone/stores";

import {
    debounceUpsertSurveyToApi,
    getValidationErrosByErrorCode,
    nullifyWizardValidationError,
} from "@difftone/actions";
import { warningMessages } from "@difftone/constants";
import { getValidationErrorsFromStoreByUUID } from "@difftone/reducers";

import {
    survey_name_input,
    existing_survey_name,
} from "./survey-name.module.css";

export type SurveyNameProps = {
    survey: SurveyClasses;
    disabled: boolean;
};

export const SurveyName = observer((props: SurveyNameProps) => {
    const { survey, disabled } = props;
    const { activeAccordionSection } = wizardStore;

    const isDefaultName = survey.survey_name === survey.default_survey_name;

    const surveyValidationErrors = getValidationErrorsFromStoreByUUID(
        survey.uuid,
        survey
    );
    const surveyNameValidations = getValidationErrosByErrorCode(
        "SURVEY_NAME_VALIDATION",
        surveyValidationErrors
    );
    const surveyNameDuplicationValidations = getValidationErrosByErrorCode(
        "SURVEY_NAME_DUPLICATION",
        surveyValidationErrors
    );

    const isTitleEmpty = surveyNameValidations.length > 0;

    const isUnique = surveyNameDuplicationValidations.length === 0;

    const isTitleValid = !isTitleEmpty && isUnique;

    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        nullifyWizardValidationError();

        const value: ValueOf<BaseSurvey> = event.target.value;
        const key: keyof BaseSurvey = "survey_name";
        debounceUpsertSurveyToApi(key, value);
    };

    //UseEffect for selecting the survey name input on entering wizard
    useEffect(() => {
        if (activeAccordionSection === "DETAILS") {
            const surveyTitle = document.querySelector(
                `input[data-survey-title]`
            ) as HTMLInputElement;

            setTimeout(() => {
                surveyTitle?.select();
            }, 0);
        }
    }, [activeAccordionSection]);

    return (
        <div>
            <ValidationWrapper
                errorMessage={
                    isUnique
                        ? warningMessages.surveyTitleEmpty
                        : warningMessages.surveyTitleNotUniquee
                }
                isValid={isTitleValid}
            >
                <input
                    disabled={disabled}
                    data-survey-title
                    className={clsx(
                        survey_name_input,
                        !isTitleValid && existing_survey_name
                    )}
                    onChange={onChangeHandler}
                    value={isDefaultName ? "" : survey.survey_name}
                    type="text"
                    placeholder="Survey Name"
                />
            </ValidationWrapper>
        </div>
    );
});
