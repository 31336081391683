import React, { ReactNode } from "react";

import completeIcon from "@difftone/assets/complete-progress-bar-icon.svg";

import {
    survey_progress_bar,
    progress_track,
    progress,
    answerers_wrapper,
    complete_icon,
    answers_result_wrapper,
} from "./survey-progress-bar.module.css";

export type SurveyProgressBarProps = {
    numberOfQuestions: number;
    answeredQuestionsCounter: number;
    children: ReactNode;
};

export const SurveyProgressBar = (props: SurveyProgressBarProps) => {
    const { answeredQuestionsCounter, numberOfQuestions, children } = props;

    return (
        <div className={survey_progress_bar}>
            <div className={answers_result_wrapper}>
                <div className={answerers_wrapper}>
                    <span>{`${answeredQuestionsCounter} of ${numberOfQuestions} answered`}</span>
                    {numberOfQuestions === answeredQuestionsCounter && (
                        <div className={complete_icon}>
                            <img src={completeIcon} alt="complete" />
                        </div>
                    )}
                </div>
                <div className={progress_track}>
                    <div
                        className={progress}
                        style={{
                            width: `${Math.floor(
                                (answeredQuestionsCounter * 100) /
                                    numberOfQuestions
                            )}%`,
                        }}
                    ></div>
                </div>
            </div>
            {children}
        </div>
    );
};
