import React from "react";
import { observer } from "mobx-react";

import { Anonimity, BaseSurvey } from "@difftone/types";
import {
    ShowDifftoneTooltip,
    NameWithTooltip,
} from "@difftone/shared-components";
import tolltipIcon from "@difftone/assets/exclamation-icon.svg";
import {
    ANONIMITY_TIP_HIGH,
    ANONIMITY_TIP_LOW,
    ANONIMITY_TIP_MEDIUM,
    ANONIMITY_TIP_NONE,
} from "@difftone/frontend-common";
import { previewSurveyStore, navigationStore } from "@difftone/stores";

import {
    survey_welcome_card,
    survey_name,
    anonimity_disclosure,
    anonimity_tooltip,
} from "./survey-welcome-card.module.css";

export type SurveyWelcomeCardProps = {
    survey: BaseSurvey;
};

export const SurveyWelcomeCard = observer((props: SurveyWelcomeCardProps) => {
    const { survey } = props;
    const { inPreviewMode } = previewSurveyStore;
    const surveyAnonimityLevel: Anonimity =
        survey.anonimity!.toUpperCase() as Anonimity;
    const { currentPage } = navigationStore;
    const page = currentPage.startsWith("/survey-wizard");

    let anonimityTip = "";
    switch (surveyAnonimityLevel) {
        case "NONE":
            anonimityTip = page
                ? ANONIMITY_TIP_NONE.wizard
                : ANONIMITY_TIP_NONE.survey;
            break;
        case "LOW":
            anonimityTip = page
                ? ANONIMITY_TIP_LOW.wizard
                : ANONIMITY_TIP_LOW.survey;
            break;
        case "MEDIUM":
            anonimityTip = page
                ? ANONIMITY_TIP_MEDIUM.wizard
                : ANONIMITY_TIP_MEDIUM.survey;
            break;
        case "HIGH":
            anonimityTip = page
                ? ANONIMITY_TIP_HIGH.wizard
                : ANONIMITY_TIP_HIGH.survey;
            break;
        default:
            break;
    }

    return (
        <div
            className={survey_welcome_card}
            style={inPreviewMode ? { width: "100%" } : {}}
        >
            <div className={survey_name}>
                <NameWithTooltip text={survey.survey_name} noWrap={false} />
            </div>
            <div className={anonimity_disclosure}>
                Anonymity:
                <div>&nbsp;{surveyAnonimityLevel}&nbsp;</div>
                <ShowDifftoneTooltip
                    tooltipPosition="bottom"
                    tip={anonimityTip}
                >
                    <img
                        className={anonimity_tooltip}
                        src={tolltipIcon}
                        alt=""
                    />
                </ShowDifftoneTooltip>
            </div>
        </div>
    );
});
