import React, { Ref, useRef } from "react";
import { observer } from "mobx-react";
import { Gantt, ViewMode } from "gantt-task-react";

import {
    surveyStore,
    publicProfileStore,
    dashboardDisplayStore,
} from "@difftone/stores";
import {
    getUniquePublicProfilesUUIDs,
    setViewFilterViewMode,
} from "@difftone/actions";

import { generateTasksFromSurveys } from "@difftone/reducers";
import { SurveyClasses } from "@difftone/types";

import {
    DropdownElements,
    NoDataToShow,
    SimpleDropdown,
    Spinner,
} from "@difftone/shared-components";

import { TimelineTooltip } from "./components";
import "gantt-task-react/dist/index.css";
import {
    insight_survey_timeline,
    timeline_dropdown_wrapper,
    gantt_component_wrapper,
    timeline_header_wrapper,
    timeline_header,
    no_data_to_show_wrapper,
} from "./dashboard-surveys-timeline.module.css";

const DAY_COLUMN_WIDTH = 65;
const YEAR_COLUMN_WIDTH = 350;
const WEEK_COLUMN_WIDTH = 250;
const MONTH_COLUMN_WIDTH = 250;

const DISPLAY_3_COLUMNS = 3;
const DISPLAY_8_COLUMNS = 8;

const TOOLTIP_ROW_HEIGHT = 60;

export type DashboardSurveysTimelineProps = {
    surveys: SurveyClasses[];
};

export const DashboardSurveysTimeline = observer(
    (props: DashboardSurveysTimelineProps) => {
        const { surveys } = props;
        const anchorRef: Ref<HTMLDivElement> = useRef(null);
        const { filterViewMode } = dashboardDisplayStore;
        const publicProfileStartInitialization =
            publicProfileStore.startInitialization;

        const sortedAndFilteredSurveys = surveys;

        if (!surveyStore.storeInitialize) {
            return <Spinner />;
        }

        if (!publicProfileStartInitialization) {
            const uniquePublicProfiles: string[] =
                getUniquePublicProfilesUUIDs();

            publicProfileStore.init(uniquePublicProfiles);
        }

        let columnWidth: number = DAY_COLUMN_WIDTH;
        if (filterViewMode === ViewMode.Year) {
            columnWidth = anchorRef.current?.offsetWidth
                ? anchorRef.current?.offsetWidth / DISPLAY_3_COLUMNS
                : YEAR_COLUMN_WIDTH;
        } else if (filterViewMode === ViewMode.Month) {
            columnWidth = anchorRef.current?.offsetWidth
                ? anchorRef.current?.offsetWidth / DISPLAY_8_COLUMNS
                : MONTH_COLUMN_WIDTH;
        } else if (filterViewMode === ViewMode.Week) {
            columnWidth = WEEK_COLUMN_WIDTH;
        }

        const dropdownList: DropdownElements[] = [
            {
                displayValue: "Days",
                systemValue: ViewMode.Day,
            },
            {
                displayValue: "Weeks",
                systemValue: ViewMode.Week,
            },
            {
                displayValue: "Months",
                systemValue: ViewMode.Month,
            },
            {
                displayValue: "Year",
                systemValue: ViewMode.Year,
            },
        ];

        const onChange = (item: string) => {
            setViewFilterViewMode(item as ViewMode);
        };

        return (
            <div className={insight_survey_timeline}>
                <div className={timeline_header_wrapper}>
                    <div className={timeline_header}>Surveys Timeline</div>
                    <div className={timeline_dropdown_wrapper}>
                        <SimpleDropdown
                            onChange={onChange}
                            selectedValue={filterViewMode}
                            dropdownOptions={dropdownList}
                        />
                    </div>
                </div>
                {sortedAndFilteredSurveys.length ? (
                    <div ref={anchorRef} className={gantt_component_wrapper}>
                        <Gantt
                            tasks={generateTasksFromSurveys(
                                sortedAndFilteredSurveys
                            )}
                            viewMode={filterViewMode}
                            listCellWidth={""}
                            columnWidth={columnWidth}
                            barProgressColor={"#009788"}
                            barBackgroundColor={"#B6B8BA"}
                            TooltipContent={(props) => (
                                <TimelineTooltip
                                    surveyList={sortedAndFilteredSurveys}
                                    {...props}
                                />
                            )}
                            rowHeight={TOOLTIP_ROW_HEIGHT}
                            fontFamily={"Rubik"}
                        />
                    </div>
                ) : (
                    <div className={no_data_to_show_wrapper}>
                        <NoDataToShow />
                    </div>
                )}
            </div>
        );
    }
);
