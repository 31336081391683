import { action } from "mobx";
import { EmailAddress, SurveyEventAlert, UUID } from "@difftone/types";
import { alertsStore } from "@difftone/stores";
import {
    checkIfEmailIsGroupByNames,
    getAllAlertsFromApiByInitiatorEmail,
    getEventAlertsFromApiByUUID,
    saveEventAlertToApiByUUID,
} from "@difftone/services";
import { showDifftoneAlert } from "@difftone/actions";
import { debounceFunction } from "@difftone/frontend-common";
import { getUserEmail, localstorageUtils } from "@difftone/procedures";

export const setAlertToStoreSetter = action((alert: SurveyEventAlert) => {
    alertsStore.setSingleSurveyAlert({ [alert.uuid]: alert });
});

export const getSingleAlertByUUIDFromApi = action(async (uuid: UUID) => {
    const alertsResponse = await getEventAlertsFromApiByUUID([uuid]);

    let currentAlert = null;

    if (!alertsResponse || !alertsResponse.length || !alertsResponse[0]) {
        showDifftoneAlert(
            "something went wrong with alert fetching",
            "FAILURE"
        );
        console.error("alert UUID", uuid);
    } else {
        currentAlert = alertsResponse[0];
    }

    if (alertsStore.getSurveyAlertCopyByUuid(uuid) !== currentAlert) {
        alertsStore.setSingleSurveyAlert({ [uuid]: currentAlert });
    }
    return currentAlert;
});

export const getEventAlertsByUUIDs = action(async (uuids: UUID[]) => {
    const alertsResponse = await getEventAlertsFromApiByUUID(uuids);
    if (!alertsResponse) {
        console.error("[getEventAlertsByUUIDs]:: Could not find alerts");
        throw Error("[getEventAlertsByUUIDs]:: Could not find alerts");
    }

    alertsResponse.forEach((alert) => {
        if (alert)
            return alertsStore.setSingleSurveyAlert({ [alert.uuid]: alert });
    });
});

export const getAllAlertsByInitiatorEmail = action(async () => {
    const email = getUserEmail();
    if (!email) {
        throw new Error(`Could not find userEmail`);
    }

    let allAlertsResponse = await getAllAlertsFromApiByInitiatorEmail(email);

    allAlertsResponse = allAlertsResponse || [];

    allAlertsResponse.map((alert) => {
        return alertsStore.setSingleSurveyAlert({ [alert.uuid]: alert });
    });
    alertsStore.storeInitialize = true;
});

export const checkIfEmailIsGroup = async (email: EmailAddress) => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    if (currentAuthData.issuer !== "GOOGLE") return false;

    const emailIsGroup = await checkIfEmailIsGroupByNames(email);
    if (emailIsGroup) {
        showDifftoneAlert(
            `Group emails are not currently supported`,
            "FAILURE"
        );
    }
    return emailIsGroup;
};

export const putEventAlertToApi = async (newEventAlert: SurveyEventAlert) => {
    const response = await saveEventAlertToApiByUUID(newEventAlert);
    return response;
};

export const debounceUpsertedAlertToApi = debounceFunction(
    putEventAlertToApi,
    500
);
