import React, { useState } from "react";
import md5 from "md5";

import { OpenQuestionAnswers } from "@difftone/types";
import { ExportButton } from "@difftone/shared-components";

import exportButton from "@difftone/assets/export-icon.svg";
import copyToClipBoard from "@difftone/assets/duplicate-survey.svg";
import {
    selection_shortcut_buttons,
    answers_option_wrapper,
    option_buttons,
    export_question_answers,
    blink_copy_to_clipboard,
} from "./answers-options.module.css";

export type AnswersOptionsProps = {
    openQuestionAnswers: OpenQuestionAnswers;
    setSelectedAnswers: (answers: string[]) => void;
    dataCSV: (string | number)[][];
    CSVFileName: string;
    copyToClipboard: () => void;
    selectedAnswers: string[];
};

export const AnswersOptions = (props: AnswersOptionsProps) => {
    const {
        openQuestionAnswers,
        setSelectedAnswers,
        selectedAnswers,
        dataCSV,
        CSVFileName,
        copyToClipboard,
    } = props;

    //useState for clicking copy to clipboard
    const [isClickedToCopy, setIsClickedToCopy] = useState(false);

    const selectAll = () => {
        const selectedAnswersKey = openQuestionAnswers.map(
            (displayAnswer, index) => {
                return md5(`${displayAnswer}-${index}`);
            }
        );
        const updateAnswers = Array.from(new Set([...selectedAnswersKey]));

        setSelectedAnswers(updateAnswers);
    };

    const clearAll = () => {
        setSelectedAnswers([]);
    };

    const copyToClipboardHandler = () => {
        if (!selectedAnswers.length) {
            return;
        }
        copyToClipboard();
        setIsClickedToCopy(true);

        //For resting after click copy to clipboard
        setTimeout(() => {
            setIsClickedToCopy(false);
        }, 1000);
    };

    return (
        <div className={answers_option_wrapper}>
            <div className={selection_shortcut_buttons}>
                <span onClick={selectAll}>Select All</span>&nbsp;-&nbsp;
                <span onClick={clearAll}>Clear</span>
            </div>
            <div className={option_buttons}>
                <ExportButton
                    className={export_question_answers}
                    dataCSV={dataCSV}
                    fileName={CSVFileName}
                >
                    <img alt="export" src={exportButton} />
                </ExportButton>
                <img
                    alt="copy"
                    className={isClickedToCopy ? blink_copy_to_clipboard : ""}
                    src={copyToClipBoard}
                    onClick={copyToClipboardHandler}
                />
            </div>
        </div>
    );
};
