import React, { MouseEvent } from "react";
import { showDifftoneAlert } from "@difftone/actions";
import { ResultsFilter } from "@difftone/types";
import { ALLOWED_ATTRIBUTE_TITLE_LENGTH } from "@difftone/stores";
import { AddBoxShadow } from "@difftone/shared-components";
import { TIME_FILTER_ID } from "@difftone/constants";

import { GeneratorMenuItem } from "./components";
import {
    generator_menu,
    options_wrapper,
    menu_item_wrapper,
} from "./generator-menu.module.css";

export type GeneratorMenuProps = {
    menuOptions: ResultsFilter[];
    addFilter: (newFilter: ResultsFilter) => void;
};

export const GeneratorMenu = (props: GeneratorMenuProps) => {
    const { menuOptions, addFilter } = props;

    const handleFilterSelection = (event: MouseEvent<HTMLDivElement>) => {
        const selectedFilterUUID =
            event.currentTarget.getAttribute("data-uuid");
        const filter = menuOptions.find(
            (option) => option.filterKey.uuid === selectedFilterUUID
        );

        if (filter) {
            addFilter(filter);
        } else {
            showDifftoneAlert("try again, something went wrong", "FAILURE");
        }
    };

    return (
        <AddBoxShadow>
            <div className={generator_menu}>
                <div className={options_wrapper}>
                    {menuOptions.map((option) => {
                        return (
                            <div key={option.filterKey.uuid}>
                                {option.filterKey.uuid !== TIME_FILTER_ID ? (
                                    <div
                                        className={menu_item_wrapper}
                                        onMouseDown={handleFilterSelection}
                                        data-uuid={option.filterKey.uuid}
                                    >
                                        <GeneratorMenuItem
                                            value={
                                                option.filterKey.nickname
                                                    ? option.filterKey.nickname
                                                    : option.filterKey.title.slice(
                                                          0,
                                                          ALLOWED_ATTRIBUTE_TITLE_LENGTH
                                                      )
                                            }
                                        />
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}
                </div>
            </div>
        </AddBoxShadow>
    );
};
