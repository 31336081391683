import {
    Question,
    QuestionInvalidNameValidationType,
    QuestionInvalidNicknameValidationType,
    QuestionType,
    ValidationResult,
} from "@difftone/types";

export const validateQuestionNameUniqueness = (
    question: Question<QuestionType>,
    restOfQuestions: Question<QuestionType>[]
): ValidationResult<QuestionInvalidNameValidationType>[] => {
    try {
        let res = true;
        restOfQuestions.forEach((_question) => {
            if (question.title === _question.title) {
                res = false;
            }
        });

        return [
            {
                is_valid: res,
                validation_error_code: "QUESTION_NAME_DUPLICATION_VALIDATION",
                validation_error_type: {
                    question_id: !res ? question.uuid : "",
                },
            },
        ];
    } catch (error) {
        console.error(
            `validateSurveyDates error:: ${(error as Error).message}`
        );
        return [
            {
                is_valid: false,
                validation_error_code: "EXCEPTION_DURING_VALIDATIONS",
                //TODO: fix type definition
                //@ts-ignore
                validation_error_type: error as Error,
            },
        ];
    }
};

export const validateQuestionNicknameUniqueness = (
    question: Question<QuestionType>,
    restOfQuestions: Question<QuestionType>[]
): ValidationResult<QuestionInvalidNicknameValidationType>[] => {
    try {
        let res = true;
        restOfQuestions.forEach((_question) => {
            if (
                (question.nickname === _question.nickname &&
                    question.nickname !== "") ||
                (question.nickname === _question.title &&
                    question.nickname !== "")
            ) {
                res = false;
                console.log(_question, question);
            }
        });

        return [
            {
                is_valid: res,
                validation_error_code:
                    "QUESTION_NICKNAME_DUPLICATION_VALIDATION",
                validation_error_type: {
                    question_id: !res ? question.uuid : "",
                },
            },
        ];
    } catch (error) {
        console.error(
            `validateSurveyDates error:: ${(error as Error).message}`
        );
        return [
            {
                is_valid: false,
                validation_error_code: "EXCEPTION_DURING_VALIDATIONS",
                //TODO: fix type definition
                //@ts-ignore
                validation_error_type: error as Error,
            },
        ];
    }
};
