import React from "react";
import {
    Question,
    QuestionToAnswerDescreteQuestion,
    RatingQuestion,
    SupportedQuestionResultsMapAfterDigestionWithoutNulls,
} from "@difftone/types";
import { getAvgForRatingTypeColumn } from "@difftone/actions";
import { getNormalizedDeviation } from "@difftone/frontend-common";
import { ResultBox } from "@difftone/shared-components";

export type CategoryRatingTypeBoxProps = {
    question: Question<RatingQuestion>;
    questionResultsRow: SupportedQuestionResultsMapAfterDigestionWithoutNulls;
    columnKey: string;
    index: number;
    resultValue: number;
};

export const CategoryRatingTypeBox = (props: CategoryRatingTypeBoxProps) => {
    const { question, questionResultsRow, columnKey, index, resultValue } =
        props;

    const averageForEachPopulation = Object.values(questionResultsRow).map(
        (questionToAnswer) =>
            getAvgForRatingTypeColumn(
                questionToAnswer as QuestionToAnswerDescreteQuestion
            )
    );

    const colorIndicator = getNormalizedDeviation(
        averageForEachPopulation,
        resultValue
    );

    if (isNaN(colorIndicator)) {
        return (
            <ResultBox
                key={question.uuid + columnKey + index}
                colorIndicator={"DEFAULT"}
                resultValue=""
            />
        );
    }

    if (isNaN(resultValue)) {
        return (
            <ResultBox
                key={question.uuid + columnKey + index}
                colorIndicator={"DEFAULT"}
                resultValue=""
            />
        );
    }

    const safeColorIndicator =
        index === 0
            ? "OVERALL"
            : isNaN(resultValue)
            ? "DEFAULT"
            : colorIndicator.toString();

    return (
        <ResultBox
            key={question.uuid + columnKey + index}
            colorIndicator={safeColorIndicator}
            resultValue={resultValue.toFixed(1)}
        />
    );
};
