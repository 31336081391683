import { EmailButtons, SurveyEventAlert, UUID } from "@difftone/types";
import {
    ACTIVATE_FROM_EMAIL_PARAM,
    getWebClientBaseUrl,
} from "@difftone/constants";

const getAlertButtonsForActivationAlert = (
    surveyUuid: UUID
): EmailButtons[] => {
    return [
        {
            title: "Send out Survey",
            redirect_uri: ``,
            on_click_uri: `${getWebClientBaseUrl()}/inbox/all?${ACTIVATE_FROM_EMAIL_PARAM}=${surveyUuid}`,
            respondent_click_uri: `${getWebClientBaseUrl()}/survey/${surveyUuid}`,
        },
    ];
};

// Backend side will add the participants email to the link with the survey uuid
const getAlertButtonsForPublishedDateAlert = (
    surveyUuid: UUID
): EmailButtons[] => {
    return [
        {
            title: "Go to Survey",
            redirect_uri: ``,
            on_click_uri: `${getWebClientBaseUrl()}/survey/${surveyUuid}`,
            respondent_click_uri: ``,
        },
    ];
};

// Backend side will add the participants email to the link with the survey uuid
const getAlertButtonsForExtendPublishedDateAlert = (
    surveyUuid: UUID
): EmailButtons[] => {
    return [
        {
            title: "Go to Survey",
            redirect_uri: ``,
            on_click_uri: `${getWebClientBaseUrl()}/survey/${surveyUuid}`,
            respondent_click_uri: ``,
        },
    ];
};

const getAlertButtonsForSurveyEndedNotificationAlert = (
    surveyUuid: UUID
): EmailButtons[] => {
    return [
        {
            title: "Go to Results",
            redirect_uri: ``,
            on_click_uri: `${getWebClientBaseUrl()}/results/${surveyUuid}`,
            respondent_click_uri: ``,
        },
    ];
};

const getAlertButtonsForCustomAlert = (surveyUuid: UUID): EmailButtons[] => {
    return [
        {
            title: "Go to Survey",
            redirect_uri: ``,
            on_click_uri: `${getWebClientBaseUrl()}/survey/${surveyUuid}`,
            respondent_click_uri: ``,
        },
    ];
};

export const getAlertButtonsFactory = (
    surveyAlert: SurveyEventAlert,
    surveyUuid: UUID
): EmailButtons[] => {
    switch (surveyAlert.alert_type) {
        case "ACTIVATION_ALERT":
            return getAlertButtonsForActivationAlert(surveyUuid);
        case "PUBLISHED_DATE_ALERT":
            return getAlertButtonsForPublishedDateAlert(surveyUuid);
        case "EXTEND_PUBLISHED_DATE_ALERT":
            return getAlertButtonsForExtendPublishedDateAlert(surveyUuid);
        case "SURVEY_ENDED_NOTIFICATION_ALERT":
            return getAlertButtonsForSurveyEndedNotificationAlert(surveyUuid);
        case false:
            return getAlertButtonsForCustomAlert(surveyUuid);

        default:
            return [];
    }
};
