import React, { ReactNode } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import OutsideClickHandler from "react-outside-click-handler";
import { closeSurveyPreview } from "@difftone/actions";
import { Survey } from "@difftone/shared-components";
import { previewSurveyStore } from "@difftone/stores";

import closePreviewIcon from "@difftone/assets/close-preview-button.svg";
import {
    preview_survey,
    close_button,
    preview_container,
} from "./preview-survey.module.css";

export type PreviewSurveyProps = {
    className: string;
    children?: ReactNode;
    title?: ReactNode;
    onClose?: () => void;
};

export const PreviewSurvey = observer((props: PreviewSurveyProps) => {
    const { children, onClose, title } = props;
    const { inPreviewMode, activePreviewSurvey } = previewSurveyStore;

    const closePreview = () => {
        closeSurveyPreview();
        if (onClose) {
            onClose();
        }
    };

    return (
        <OutsideClickHandler onOutsideClick={closePreview}>
            <div className={clsx(preview_survey, props.className)}>
                {title}
                <div className={preview_container}>
                    <img
                        alt="close-preview"
                        className={close_button}
                        src={closePreviewIcon}
                        onClick={closePreview}
                    />
                    {children ? (
                        children
                    ) : (
                        <Survey
                            inPreviewMode={inPreviewMode}
                            survey={activePreviewSurvey}
                        />
                    )}
                </div>
            </div>
        </OutsideClickHandler>
    );
});
