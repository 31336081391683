import React from "react";
import { observer } from "mobx-react";

import {
    UUID,
    SurveyClasses,
    QuestionToAnswerDescreteQuestion,
} from "@difftone/types";
import {
    resultsMapStore,
    resultsDisplayStore,
    sharedResultStore,
    categoriesDisplayStore,
    surveyResponsesSummaryStore,
    resultsDisplayFilterStore,
} from "@difftone/stores";
import {
    getCategoryFilterValuesAndActiveStatus,
    getNonFilterQuestionsFromSurvey,
    getSurveyFromStoreByUUID,
    getSurveyUUIDFromUrl,
    sortQuestionsByType,
} from "@difftone/reducers";
import { showDifftoneAlert, setLocation } from "@difftone/actions";
import { constantAlertVariants, PAGE_URLS } from "@difftone/constants";
import {
    formatCategoriesQuestionsMap,
    formatCategoriesResultsRatingMap,
    getSortedCategoriesList,
} from "@difftone/reducers";
import { warningMessages } from "@difftone/constants";
import { COLUMN_0 } from "@difftone/common-constants";

import {
    Spinner,
    FavorableQuestionDisplay,
    FavorableCategoryDisplay,
    ResultsFavorableQuestion,
    ResultsFavorableCategories,
} from "@difftone/shared-components";

import {
    results_questions,
    results_table_wrapper,
    results_row_spinner,
    results_questions_and_answers,
    results_favorable_spreadsheet,
    favorable_question_wrapper,
} from "./results-favorable-spreadsheet.module.css";

export type ResultsFavorableSpreadsheetProps = {
    survey: SurveyClasses;
};

export const ResultsFavorableSpreadsheet = observer(
    (props: ResultsFavorableSpreadsheetProps) => {
        const { survey } = props;
        const { categoriesQuestionsDisplayModeToggle } = resultsDisplayStore;
        const { getFiltersActiveAsCompares, getFiltersNotActiveAsCompares } =
            resultsDisplayFilterStore;

        const { targetSharedResult } = sharedResultStore;
        const { getResultsTableByResultsTableMapKey, extractKeyForTable } =
            resultsMapStore;

        const surveyUuid = getSurveyUUIDFromUrl();

        if (surveyUuid === null) {
            setLocation(PAGE_URLS.INBOX_ALL);
            showDifftoneAlert(
                constantAlertVariants.survey_url_failure.text,
                constantAlertVariants.survey_url_failure.variant
            );
            return null;
        }

        const selectedSurvey = survey || getSurveyFromStoreByUUID(surveyUuid);

        if (selectedSurvey === undefined) {
            return <Spinner size="LARGE" />;
        }

        if (selectedSurvey === null) {
            setLocation(PAGE_URLS.INBOX_ALL);
            showDifftoneAlert(warningMessages.surveyNotFound, "FAILURE");
            return null;
        }

        const surveyResponses =
            surveyResponsesSummaryStore.getSurveyResponseSummaryByFilterPlain(
                selectedSurvey.uuid,
                []
            );

        if (surveyResponses === undefined) {
            return <Spinner size="LARGE" />;
        }

        if (surveyResponses === null) {
            setLocation(PAGE_URLS.INBOX_ALL);
            showDifftoneAlert(
                warningMessages.surveyResponseNotFound,
                "FAILURE"
            );
            return null;
        }

        const _keyForTable = extractKeyForTable(
            selectedSurvey,
            getFiltersNotActiveAsCompares(),
            getFiltersActiveAsCompares(),
            targetSharedResult
        );

        const resultsRow = getResultsTableByResultsTableMapKey(_keyForTable);

        if (resultsRow === undefined) {
            return (
                <div className={results_row_spinner}>
                    <Spinner size="LARGE" />
                </div>
            );
        }

        if (resultsRow === null) {
            setLocation(PAGE_URLS.INBOX_ALL);
            showDifftoneAlert(
                constantAlertVariants.survey_results_failure.text,
                constantAlertVariants.survey_results_failure.variant
            );
            return null;
        }

        const sortedNonFilterRatingQuestions = sortQuestionsByType(
            getNonFilterQuestionsFromSurvey(selectedSurvey)
        ).filter((question) => question.question_type_name === "RATING");

        const categoriesQuestionsMap = formatCategoriesQuestionsMap(
            selectedSurvey.questions || []
        );

        const categoriesList: UUID[] = getSortedCategoriesList(
            categoriesQuestionsMap
        );

        if (!categoriesDisplayStore.isStoreInitialized) {
            categoriesDisplayStore.initCategoriesDisplayMap(categoriesList);
            return <Spinner size="LARGE" />;
        }

        const categoriesResultsMap = formatCategoriesResultsRatingMap(
            categoriesQuestionsMap,
            resultsRow
        );

        const { categoryFilterValues, isCategoryFilterActive } =
            getCategoryFilterValuesAndActiveStatus();

        const isQuestionsTogglerView =
            categoriesQuestionsDisplayModeToggle === "QUESTIONS";

        return (
            <div className={results_favorable_spreadsheet}>
                <div className={results_table_wrapper}>
                    <div className={results_questions_and_answers}>
                        <div className={results_questions}>
                            {isQuestionsTogglerView &&
                                sortedNonFilterRatingQuestions.map(
                                    (question, index) => {
                                        return (
                                            <FavorableQuestionDisplay
                                                questionIndex={index}
                                                key={question.uuid}
                                                question={question}
                                                digestedResultsRow={
                                                    resultsRow[question.uuid]
                                                }
                                                survey={selectedSurvey}
                                            />
                                        );
                                    }
                                )}

                            {!isQuestionsTogglerView &&
                                categoriesList.map((categoryUUID) => {
                                    const { categoryName, questions } =
                                        categoriesQuestionsMap[categoryUUID];

                                    const ratingQuestions = questions.filter(
                                        (question) =>
                                            question.question_type_name ===
                                            "RATING"
                                    );
                                    if (
                                        !categoryFilterValues.includes(
                                            categoryName
                                        ) &&
                                        isCategoryFilterActive
                                    ) {
                                        return null;
                                    }

                                    return (
                                        <FavorableCategoryDisplay
                                            key={categoryUUID}
                                            categoryUUID={categoryUUID}
                                            categoryName={categoryName}
                                            questions={ratingQuestions}
                                            categoriesResultsRatingMap={
                                                categoriesResultsMap
                                            }
                                            questionsResults={resultsRow}
                                            survey={selectedSurvey}
                                        />
                                    );
                                })}
                        </div>
                        <div className={favorable_question_wrapper}>
                            {isQuestionsTogglerView &&
                                sortedNonFilterRatingQuestions.map(
                                    (question) => {
                                        return (
                                            <ResultsFavorableQuestion
                                                key={question.uuid}
                                                questionResults={
                                                    resultsRow[question.uuid][
                                                        COLUMN_0
                                                    ] as QuestionToAnswerDescreteQuestion
                                                }
                                                scoreBarSize="MEDIUM"
                                            />
                                        );
                                    }
                                )}

                            {!isQuestionsTogglerView &&
                                categoriesList.map((categoryUUID) => {
                                    const { questions, categoryName } =
                                        categoriesQuestionsMap[categoryUUID];
                                    const categoryToggled =
                                        categoriesDisplayStore.getCategoryDisplayByUUID(
                                            categoryUUID
                                        );

                                    if (
                                        !categoryFilterValues.includes(
                                            categoryName
                                        ) &&
                                        isCategoryFilterActive
                                    ) {
                                        return null;
                                    }

                                    const ratingQuestions = questions.filter(
                                        (question) =>
                                            question.question_type_name ===
                                            "RATING"
                                    );

                                    return (
                                        <div key={categoryUUID}>
                                            <ResultsFavorableCategories
                                                questions={ratingQuestions}
                                                resultsRow={resultsRow}
                                                scoreBarSize="MEDIUM"
                                            />
                                            {ratingQuestions.map((question) => {
                                                if (!categoryToggled) {
                                                    return null;
                                                }

                                                return (
                                                    <ResultsFavorableQuestion
                                                        scoreBarSize="SMALL"
                                                        key={question.uuid}
                                                        questionResults={
                                                            resultsRow[
                                                                question.uuid
                                                            ][
                                                                COLUMN_0
                                                            ] as QuestionToAnswerDescreteQuestion
                                                        }
                                                    />
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);
