import React, { MouseEvent } from "react";
import { AddBoxShadow } from "@difftone/shared-components";

import {
    about_us_dialog,
    login_card,
    top_centered_line,
    contact_info_text,
} from "./contact-info-dialog.module.css";

export type ConatcInfoDialogProps = {
    close: () => void;
};

export const ConatcInfoDialog = (props: ConatcInfoDialogProps) => {
    const { close } = props;

    const preventDialogClosing = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    return (
        <div onClick={close} className={about_us_dialog}>
            <AddBoxShadow>
                <div onClick={preventDialogClosing} className={login_card}>
                    <span className={top_centered_line}></span>
                    <div className={contact_info_text}>
                        <div className={"user_select"}>DIFFTONE.COM LTD</div>
                        <div className={"user_select"}>
                            <span>Email :</span> support@difftone.com
                        </div>
                        <div className={"user_select"}>
                            <span>Phone :</span> +972 54 4228432
                        </div>
                    </div>
                </div>
            </AddBoxShadow>
        </div>
    );
};
