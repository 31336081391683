import React, { Ref, useRef } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import { surveyResponseDisplayStore } from "@difftone/stores";
import {
    setFocusedSurveyResponseInputCard,
    executeScrollOnfocus,
} from "@difftone/actions";
import {
    QuestionType,
    Question,
    ValidationResult,
    RequiredNotAnsweredValidationType,
} from "@difftone/types";

import { QuestionTitle, QuestionBody } from "./components";

import {
    question_container,
    focused_question_container,
    question_body_wrapper,
    required_marker,
    required_marker_error,
} from "./question-component.module.css";

const FIRST_ELEMENT_IN_SURVEY_SCROLL = 300;
const DEFAULT_ELEMENT_IN_SURVEY_SCROLL = 70;

export type QuestionProps = {
    isHidden: boolean;
    question: Question<QuestionType>;
    index: number;
    errors: ValidationResult<RequiredNotAnsweredValidationType>[];
    inPreviewMode: boolean;
    isEditableQuestion: boolean;
};

export const QuestionComponent = observer((props: QuestionProps) => {
    const {
        index,
        question,
        errors,
        inPreviewMode,
        isEditableQuestion,
        isHidden,
    } = props;
    const { focusedSurveyResponseInputCard } = surveyResponseDisplayStore;
    const componentRef: Ref<HTMLDivElement> = useRef(null);

    const isFocused = inPreviewMode
        ? false
        : question.uuid === focusedSurveyResponseInputCard;

    const isError = errors.find(
        ({ validation_error_type }) =>
            (validation_error_type as RequiredNotAnsweredValidationType)
                .question_uuid === question.uuid
    );

    if (!inPreviewMode) {
        if (componentRef && isFocused) {
            const surveyContainer = document.querySelector(
                `div[data-scroll-element="survey-wrapper"]`
            ) as HTMLElement;

            if (index === 0) {
                surveyContainer &&
                    executeScrollOnfocus(
                        surveyContainer!,
                        componentRef.current!,
                        FIRST_ELEMENT_IN_SURVEY_SCROLL
                    );
            } else {
                surveyContainer &&
                    executeScrollOnfocus(
                        surveyContainer!,
                        componentRef.current!,
                        DEFAULT_ELEMENT_IN_SURVEY_SCROLL
                    );
            }
        }

        if (index === 0 && !focusedSurveyResponseInputCard) {
            setFocusedSurveyResponseInputCard(question.uuid);
        }
    }

    const onClickHandler = () => {
        if (inPreviewMode) {
            return;
        }
        setFocusedSurveyResponseInputCard(question.uuid);
    };

    const focusTrackerIndexProps = { index: index, uuid: question.uuid };

    if (isHidden) {
        return null;
    }

    return (
        <div
            {...focusTrackerIndexProps}
            data-survey-focused-card
            data-survey-focused-card-uuid={question.uuid}
            ref={componentRef}
            onClick={onClickHandler}
            className={[
                question_container,
                isFocused && focused_question_container,
            ].join(" ")}
        >
            <QuestionTitle index={index} question={question} />
            <div className={question_body_wrapper}>
                <QuestionBody
                    isEditableQuestion={isEditableQuestion}
                    inPreviewMode={inPreviewMode}
                    question={question}
                    disabled={false}
                />
            </div>
            {question.required ? (
                <div
                    className={clsx(required_marker, {
                        [required_marker_error]: isError,
                    })}
                >
                    * This is a required question
                </div>
            ) : null}
        </div>
    );
});
