import React, { Ref, useRef, useState } from "react";
import Draggable from "react-draggable";
import { observer } from "mobx-react";
import md5 from "md5";
import {
    OpenQuestionAnswers,
    Question,
    QuestionType,
    BaseSurvey,
} from "@difftone/types";
import { MOBILE_WIDTH_BREAKPOINT } from "@difftone/constants";
import { AddBoxShadow } from "@difftone/shared-components";
import {
    milisecondsToDateStringResults,
    milisecondsToTimeString,
} from "@difftone/time-utils";

import {
    AnswersContent,
    AnswersOptions,
    OpenAnswersHeader,
} from "./components";

import {
    dialog_wrapper,
    drag_box,
    popup_wrapper,
} from "./open-question-answers-dialog.module.css";

const selectAllAnswersAsDefault = (
    openQuestionAnswers: OpenQuestionAnswers
) => {
    const selectedAnswersKey = openQuestionAnswers.map(
        (displayAnswer, index) => {
            return md5(`${displayAnswer}-${index}`);
        }
    );
    return Array.from(new Set([...selectedAnswersKey]));
};

export type OpenQuestionAnswersDialogProps = {
    question: Question<QuestionType>;
    openQuestionAnswers: OpenQuestionAnswers;
    onClose: () => void;
    survey: BaseSurvey;
};

export const OpenQuestionAnswersDialog = observer(
    (props: OpenQuestionAnswersDialogProps) => {
        const { onClose, openQuestionAnswers, question, survey } = props;

        //Will store the selected answers and index md5
        const [selectedAnswers, setSelectedAnswers] = useState<string[]>(
            selectAllAnswersAsDefault(openQuestionAnswers)
        );

        const nodeRef: Ref<HTMLDivElement> = useRef(null);

        const surveyNameToExport = survey.survey_name.split(" ").join("_");
        const csvFileName = `${surveyNameToExport}_question_no_${question.number}`;

        const getAnswersFromAnswerKeys = () => {
            return openQuestionAnswers
                .map((displayAnswer, index) => {
                    const answerKey = md5(`${displayAnswer}-${index}`);

                    if (selectedAnswers.includes(answerKey)) {
                        return displayAnswer;
                    }
                    return null;
                })
                .filter((answers) => answers);
        };

        const questionAnswersByQuestionType = (): (string | null)[] => {
            const questionAnswers = getAnswersFromAnswerKeys();

            let finalAnswers: (string | null)[] = [];

            switch (question.question_type_name) {
                case "LONG_TEXT":
                case "SHORT_TEXT":
                    finalAnswers = questionAnswers;
                    break;
                case "DATE":
                    const datesInStr = questionAnswers.map((date) =>
                        milisecondsToDateStringResults(Number(date))
                    );
                    finalAnswers = datesInStr;
                    break;
                case "TIME":
                    const timeInStr = questionAnswers.map((time) =>
                        milisecondsToTimeString(Number(time))
                    );
                    finalAnswers = timeInStr;
                    break;
                default:
                    return [];
            }
            return finalAnswers;
        };

        const dataCSV = () => {
            const finalAnswersToExport = questionAnswersByQuestionType();

            const questionTitle = [`${question.title}`];

            const resultsData = finalAnswersToExport.map((answer, index) => {
                return answer ? ["", index + 1, answer] : [];
            });

            return [[], questionTitle, ...resultsData];
        };

        const copyToClipBoard = () => {
            const finalSelectedAnswers = questionAnswersByQuestionType();
            navigator.clipboard.writeText(finalSelectedAnswers.join("\n"));
        };

        const disabledDraggableInMobile =
            window.innerWidth <= MOBILE_WIDTH_BREAKPOINT;

        return (
            <div className={popup_wrapper}>
                {/*TODO - Resolve react 18 type issues*/}
                {/*@ts-ignore*/}
                <Draggable
                    nodeRef={nodeRef}
                    disabled={disabledDraggableInMobile}
                    bounds="parent"
                    handle={`div[data-draggable-header]`}
                >
                    <div className={drag_box} ref={nodeRef}>
                        <AddBoxShadow>
                            <div className={dialog_wrapper}>
                                <OpenAnswersHeader
                                    ref={nodeRef}
                                    headertitle="Responses"
                                    onClose={onClose}
                                />
                                <AnswersOptions
                                    dataCSV={dataCSV()}
                                    copyToClipboard={copyToClipBoard}
                                    CSVFileName={csvFileName}
                                    setSelectedAnswers={setSelectedAnswers}
                                    selectedAnswers={selectedAnswers}
                                    openQuestionAnswers={openQuestionAnswers}
                                />
                                <AnswersContent
                                    question={question}
                                    setSelectedAnswers={setSelectedAnswers}
                                    openQuestionAnswers={openQuestionAnswers}
                                    selectedAnswers={selectedAnswers}
                                />
                            </div>
                        </AddBoxShadow>
                    </div>
                </Draggable>
            </div>
        );
    }
);
