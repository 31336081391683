import React, { MouseEvent, useState } from "react";
import { observer } from "mobx-react";
import {
    FILTER_DIALOG_WIDTH_IN_PX,
    simpleSurveyResultsStore,
} from "@difftone/stores";
import {
    setIsFilterDialogOpen,
    setSimpleResultsFitlerProfile,
    setSimpleResultsSortingProfile,
} from "@difftone/actions";
import {
    ResponderToAnswer,
    SimpleResultsFilterProfile,
    SimpleResultsSortingOptions,
} from "@difftone/types";
import { AddBoxShadow } from "@difftone/shared-components";
import { getSurveyFromStoreByURL } from "@difftone/reducers";

import { DialogHeader, SortingButtons, ColumnFilterValues } from "./components";

import {
    filter_sorter_dialog,
    apply_state_changes_button,
    transparent_background,
} from "./filter-sorter-dialog.module.css";

const DIALOG_OFFSET_TOP_PADDING = 10;

export type FilterSorterDialogProps = {
    selectedQuestionAnswers: ResponderToAnswer[];
};

export const FilterSorterDialog = observer((props: FilterSorterDialogProps) => {
    const {
        filterDialogPosition,
        sortingProfile,
        filterProfile,
        selectedQuestion,
    } = simpleSurveyResultsStore;
    const { selectedQuestionAnswers } = props;
    //[useState]:: represents user local changes in UI only.
    //after "Apply" button is clicked, this local store is passed to the store.
    const [filterdColumnExludedItems, setFilterdColumnExludedItems] = useState<
        string[]
    >(
        filterProfile.filterdProfilesMap[
            `${selectedQuestion},${filterProfile.filterdColumnKey}`
        ] || []
    );
    //[useState]:: holds the search input typed inside the dialog.
    //value is used to filter the list inside the dialog
    const [userSearchInput] = useState("");

    const currentKeyInFilterMap = `${selectedQuestion},${filterProfile.filterdColumnKey}`;

    const getSortingOptionByQuestionType = (): SimpleResultsSortingOptions => {
        if (!selectedQuestion) {
            throw Error(
                "[getSortingOptionByQuestionType]:: Somthing went wrong!"
            );
        }

        const currentQuestion = getSurveyFromStoreByURL().questions.find(
            (question) => question.uuid === selectedQuestion
        );
        if (!currentQuestion) {
            throw Error(
                "[getSortingOptionByQuestionType]:: Somthing went wrong!"
            );
        }

        switch (currentQuestion.question_type_name) {
            case "DATE":
            case "TIME":
            case "LONG_TEXT":
            case "SHORT_TEXT":
                return "TEXT";
            case "MULTIPLE_CHOICE":
            case "RATING":
            case "SINGLE_CHOICE":
            case "BOOKING":
            case "SYSTEM_ANSWER_TIME_FRAME":
                return "NONTEXT";
        }
    };

    const onClickOnBackgroundHandler = () => {
        setIsFilterDialogOpen(false);
    };

    const preventDefaultHandler = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    const applyLocalStateToStore = () => {
        const copyOfFilterProfile: SimpleResultsFilterProfile = JSON.parse(
            JSON.stringify(filterProfile)
        );
        copyOfFilterProfile.filterdProfilesMap[currentKeyInFilterMap] =
            filterdColumnExludedItems;
        setSimpleResultsFitlerProfile(copyOfFilterProfile);
        setIsFilterDialogOpen(false);
    };

    if (
        sortingProfile.sortBy === null ||
        sortingProfile.sortBy !== getSortingOptionByQuestionType()
    ) {
        setSimpleResultsSortingProfile({
            sortBy: getSortingOptionByQuestionType(),
            direction: sortingProfile.direction,
        });
    }

    if (!filterProfile.filterdProfilesMap[currentKeyInFilterMap]) {
        const copyOfFilterProfile: SimpleResultsFilterProfile = JSON.parse(
            JSON.stringify(filterProfile)
        );
        copyOfFilterProfile.filterdProfilesMap[currentKeyInFilterMap] = [];

        setSimpleResultsFitlerProfile(copyOfFilterProfile);
    }

    return (
        <div
            onClick={onClickOnBackgroundHandler}
            className={transparent_background}
        >
            <AddBoxShadow>
                <div
                    onClick={preventDefaultHandler}
                    style={{
                        left: `${
                            filterDialogPosition.offsetLeft -
                            FILTER_DIALOG_WIDTH_IN_PX
                        }px`,
                        top: `${
                            filterDialogPosition.offsetTop +
                            DIALOG_OFFSET_TOP_PADDING
                        }px `,
                    }}
                    className={filter_sorter_dialog}
                >
                    <DialogHeader
                        dialogTitle={filterProfile.filterdColumnKey}
                    />
                    <SortingButtons sortingOption={sortingProfile.sortBy!} />
                    <ColumnFilterValues
                        userSearch={userSearchInput}
                        excludedItemsList={filterdColumnExludedItems}
                        onChange={setFilterdColumnExludedItems}
                        responses={selectedQuestionAnswers}
                    />
                    <div
                        onClick={applyLocalStateToStore}
                        className={apply_state_changes_button}
                    >
                        Apply
                    </div>
                </div>
            </AddBoxShadow>
        </div>
    );
});
