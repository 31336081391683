import React from "react";
import { observer } from "mobx-react";
import { navigationStore, resultsDisplayFilterStore } from "@difftone/stores";

import {
    ComparePicker,
    Spinner,
    ResultsFilterPicker,
} from "@difftone/shared-components";
import { getSurveyFromStoreByURL } from "@difftone/reducers";

import { ResultsFilter } from "@difftone/types";
import {
    selectDisplayTimeCompareInPopup,
    setSelectedTimeCompareFilterAndTimeUnits,
    switchActiveCompareIndex,
    turnOffFilterAsCompare,
    turnOnFilterAsCompare,
} from "@difftone/actions";
import { RESULTS_DISPLAY_TYPE, TIME_FILTER_ID } from "@difftone/constants";

import {
    left_menu_children,
    selected_options_counter,
    left_menu_compare_picker,
    left_menu_filter_picker,
    filter_picker_spinner,
} from "./left-menu-children.module.css";

const MIN_COMPARES_ALLOWED = 1;
const MAX_COMPARES_ALLOWED = 2;

export const LeftMenuChildren = observer(() => {
    const {
        getFiltersActiveAsCompares,
        compareBy,
        popupDisplayTimeCompare,
        selectedTimeCompare,
    } = resultsDisplayFilterStore;

    const { currentPage } = navigationStore;

    const selectedSurvey = getSurveyFromStoreByURL();

    if (selectedSurvey === undefined) {
        return <Spinner className={filter_picker_spinner} size="SMALL" />;
    }

    if (selectedSurvey === null) {
        return null;
    }

    const filterActiveAsCompareUuids = getFiltersActiveAsCompares().map(
        (filter) => filter.filterKey.uuid
    );

    let displayResultsFilters: ResultsFilter[] = [];
    let displayActiveAsComparesFiltersUUIDs: string[] = [];

    switch (selectedSurvey.survey_class) {
        case "ONGOING":
            displayResultsFilters = compareBy;
            displayActiveAsComparesFiltersUUIDs = filterActiveAsCompareUuids;
            break;
        case "SIMPLE":
        default:
            displayResultsFilters = compareBy.filter(
                (filter) => filter.filterKey.uuid !== TIME_FILTER_ID
            );
            displayActiveAsComparesFiltersUUIDs =
                filterActiveAsCompareUuids.filter(
                    (filterUUID) => !filterUUID.includes(TIME_FILTER_ID)
                );
    }

    const allowedCompares =
        displayResultsFilters.length === MIN_COMPARES_ALLOWED
            ? MIN_COMPARES_ALLOWED
            : MAX_COMPARES_ALLOWED;

    const isCompareByDisabled = currentPage.includes(
        RESULTS_DISPLAY_TYPE.FAVORABLE
    );

    return (
        <ul className={left_menu_children}>
            <li className={left_menu_filter_picker}>
                <div>Filter by:</div>
                <ResultsFilterPicker />
            </li>
            <li className={left_menu_compare_picker}>
                <div>
                    Compare by:
                    <span
                        className={selected_options_counter}
                        title="maximum number of compares"
                    >
                        ({getFiltersActiveAsCompares().length}/{allowedCompares}
                        )
                    </span>
                </div>
                <ComparePicker
                    isDisabled={isCompareByDisabled}
                    survey={selectedSurvey}
                    resultsFilters={displayResultsFilters}
                    activeAsComparesFiltersUUIDs={
                        displayActiveAsComparesFiltersUUIDs
                    }
                    selectedResultsTimeCompare={selectedTimeCompare}
                    selectedPopupDisplayResultsTimeCompare={
                        popupDisplayTimeCompare
                    }
                    setPopupDisplayResultsTimeCompare={
                        selectDisplayTimeCompareInPopup
                    }
                    setSelectedTimeCompareResultsFilterAndTimeUnits={
                        setSelectedTimeCompareFilterAndTimeUnits
                    }
                    resultsTurnOnFilterAsCompare={turnOnFilterAsCompare}
                    resultsTurnOffFilterAsCompare={turnOffFilterAsCompare}
                    switchResultsActiveCompareIndex={switchActiveCompareIndex}
                />
            </li>
        </ul>
    );
});
