import React, { PropsWithChildren } from "react";
import { Task } from "gantt-task-react";
import clsx from "clsx";

import { SurveyClasses } from "@difftone/types";
import { AddBoxShadow } from "@difftone/shared-components";
import { getSurveyTooltipDataByUUIDForDashboardTimeline } from "@difftone/reducers";

import {
    tool_tip_content,
    tool_tip_title,
    tool_tip_email,
    tool_tip_dot,
    tool_tip_dot_active,
    tool_tip_dot_inactive,
    tool_tip_dot_scheduled,
    tool_tip_dates,
    tool_tip_progress_text,
    tool_tip_progress_bar,
} from "./timeline-tooltip.module.css";

export type TooltipProps = {
    task: Task;
    fontSize: string;
    fontFamily: string;
    surveyList: SurveyClasses[];
};

export const TimelineTooltip: React.FC<PropsWithChildren<TooltipProps>> = (
    props: TooltipProps
) => {
    const { task, surveyList } = props;

    const selectedSurveyData = getSurveyTooltipDataByUUIDForDashboardTimeline(
        surveyList,
        task.id
    );

    let tooltipStatusColor = null;

    switch (selectedSurveyData.status) {
        case "ACTIVE":
            tooltipStatusColor = tool_tip_dot_active;
            break;
        case "SAVED":
            tooltipStatusColor = tool_tip_dot_scheduled;
            break;
        default:
            tooltipStatusColor = tool_tip_dot_inactive;
    }

    return (
        <AddBoxShadow>
            <div className={tool_tip_content}>
                <div className={clsx(tool_tip_dot, tooltipStatusColor)}></div>
                <div className={tool_tip_title}>{selectedSurveyData.name}</div>
                <div className={tool_tip_email}>{selectedSurveyData.email}</div>
                <div className={tool_tip_dates}>
                    {selectedSurveyData.startDate} -{" "}
                    {selectedSurveyData.endDate}
                </div>
                <div className={tool_tip_progress_text}>
                    {selectedSurveyData.percent?.toFixed(0)}% (
                    {selectedSurveyData.completed}/{selectedSurveyData.total})
                </div>
                <div className={tool_tip_progress_bar}>
                    <div
                        style={{ width: `${selectedSurveyData.percent}%` }}
                    ></div>
                </div>
            </div>
        </AddBoxShadow>
    );
};
