import { getApiBaseUrl } from "@difftone/constants";
import { localstorageUtils } from "@difftone/procedures";
import { createDifftoneAxios } from "@difftone/services";
import { Label, UUID } from "@difftone/types";

const SERVER_CATEGORIES_URL = `${getApiBaseUrl()}/categories-service`;
const SERVER_CATEGORIES_LIST_URL = `${SERVER_CATEGORIES_URL}/scope`;

export const getAllAvailableCategories = async (
    organisationUUID: UUID | null,
    userUUID: UUID
): Promise<Label[]> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(
        `${SERVER_CATEGORIES_LIST_URL}/${
            organisationUUID ? organisationUUID : userUUID
        }`,
        {
            params: { issuer: currentAuthData.issuer },
        }
    );

    return response.data.resources.map((res: { data: Label }) => res.data);
};

export const upsertCustomCategory = async (label: Label): Promise<Label> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    await difftoneAxios.post(
        SERVER_CATEGORIES_URL,
        {
            category: label,
        },
        {
            params: { issuer: currentAuthData.issuer },
        }
    );

    return label;
};
