import React from "react";
import { observer } from "mobx-react";

import type { State } from "@difftone/stores";
import { reducer } from "@difftone/reducers";
import { testAction } from "@difftone/actions";

import { graph } from "./graph.module.css";

export type graphProps = {
    initSatat: State;
};

export const Graph = observer((props: graphProps) => {
    const { initSatat } = props;

    const _state = reducer("state");
    console.log("initSatat: ", initSatat);
    console.log("_state: ", _state);

    return (
        <div className={graph}>
            <span>{_state}</span>
            <span>{initSatat}</span>
            <button
                onClick={() => {
                    testAction("S3");
                }}
            >
                Click
            </button>
        </div>
    );
});
