import React, { ReactNode } from "react";
import { observer } from "mobx-react";
import { MOBILE_WIDTH_BREAKPOINT } from "@difftone/constants";

export type ClientWidthRenderPickerProps = {
    mobileComponent: ReactNode;
    desktopComponent: ReactNode;
};

export const ClientWidthRenderPicker = observer(
    (props: ClientWidthRenderPickerProps) => {
        const { mobileComponent, desktopComponent } = props;

        const isMobile = () => {
            return window.innerWidth <= MOBILE_WIDTH_BREAKPOINT;
        };

        return isMobile() ? <>{mobileComponent}</> : <>{desktopComponent}</>;
    }
);
