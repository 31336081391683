import md5 from "md5";
import {
    publicProfileStore,
    surveyStore,
    inboxStore,
    InboxFilters,
} from "@difftone/stores";
import {
    filteredDisplaySurveyByActiveFilter,
    roleChecker,
    setInboxActiveFilterFlagNoRedirect,
} from "@difftone/actions";
import {
    getStatus,
    sortSurveysBySurveyName,
    sortSurveysByInitiator,
    sortSurveysByStartDate,
    sortSurveysByIssuedDate,
    sortSurveysByEndData,
    sortSurveysByCreated,
    sortSurveysByLast,
    sortSurveysByStatus,
    sortByTimeLeft,
    sortSurveysByProgress,
    sortSurveysByPurpose,
} from "@difftone/reducers";
import {
    DisplaySurvey,
    Role,
    BaseSurvey,
    SurveyClasses,
    SurveyFilterProfile,
} from "@difftone/types";

const extractKeyForDisplaySurvey = (survey: BaseSurvey, role: Role): string => {
    const surveyKey = JSON.stringify(survey);
    return md5(`${role}-${surveyKey}`);
};

const surveyListToDisplayList = (surveyList: SurveyClasses[]) => {
    const displaySurveyTable: DisplaySurvey[] = [];

    surveyList.forEach((_survey: SurveyClasses) => {
        const surveyRoles: Role[] = roleChecker(_survey);

        surveyRoles.forEach((_role: Role) => {
            const displaySurvey: DisplaySurvey = {
                display_surveyUUID: extractKeyForDisplaySurvey(_survey, _role),
                survey: _survey,
                role: _role,
            };

            if (_role === "RESPONDER") {
                const status = getStatus(_survey, _role);
                if (
                    status === "ACTIVE" ||
                    status === "COMPLETED" ||
                    status === "ENDED"
                ) {
                    displaySurveyTable.push(displaySurvey);
                }
            } else {
                displaySurveyTable.push(displaySurvey);
            }
        });
    });
    return displaySurveyTable;
};

const getFilteredSurveys = (): DisplaySurvey[] => {
    let displaySurveys: DisplaySurvey[] = surveyListToDisplayList(
        inboxStore.inboxLastActiveFilter === "ARCHIVED"
            ? surveyStore.archivedSurveysList
            : surveyStore.surveysList
    );

    displaySurveys = displaySurveys.filter((displaySurvey: DisplaySurvey) =>
        filteredDisplaySurveyByActiveFilter(
            displaySurvey,
            inboxStore.inboxLastActiveFilter
        )
    );

    if (!inboxStore.filterProfile) {
        return displaySurveys;
    }
    let results = [...displaySurveys];
    const filter: SurveyFilterProfile = Object.assign(
        {},
        inboxStore.filterProfile
    );
    for (const filterType in filter) {
        if (filterType) {
            let key = "" as keyof SurveyClasses;
            switch (filterType) {
                case "NAME":
                    key = "survey_name";
                    break;
                default:
                    return results;
            }
            results = results.filter((displaySurvey) => {
                // Array
                if (Array.isArray(filter[filterType])) {
                    if (!displaySurvey.survey[key]) {
                        return true;
                    }
                    const respondentPublicProfile =
                        publicProfileStore.getPublicProfileByUuid(
                            filter[filterType][0]
                        );
                    // @ts-ignore - TODO: to remove
                    return displaySurvey.survey[key].some(
                        // @ts-ignore - TODO: to remove
                        (s) => s === respondentPublicProfile?.email
                    );
                }

                // String
                if (typeof filter[filterType] === "string") {
                    if (key === "survey_name") {
                        return displaySurvey.survey[key]
                            .toLowerCase()
                            .includes(filter[filterType].toLowerCase());
                    }
                }
                return filter[filterType] === displaySurvey.survey[key];
            });
        } else {
            return results;
        }
    }
    return results;
};

const getSortedSurveys = (
    displaySurveysList: DisplaySurvey[]
): DisplaySurvey[] => {
    const displaySUrveys = [...displaySurveysList];
    const sortBy = inboxStore.sortingProfile.sortBy;
    const ascending = inboxStore.sortingProfile.ascending;

    switch (sortBy) {
        case "NAME":
            return sortSurveysBySurveyName(ascending, displaySUrveys);
        case "INITIATOR":
            return sortSurveysByInitiator(ascending, displaySUrveys);
        case "START":
            return sortSurveysByStartDate(ascending, displaySUrveys);
        case "ISSUED":
            return sortSurveysByIssuedDate(ascending, displaySUrveys);
        case "END":
            return sortSurveysByEndData(ascending, displaySUrveys);
        case "CREATED":
            return sortSurveysByCreated(ascending, displaySUrveys);
        case "LAST":
            return sortSurveysByLast(ascending, displaySUrveys);
        case "STATUS":
            return sortSurveysByStatus(ascending, displaySUrveys);
        case "TIME LEFT":
            return sortByTimeLeft(ascending, displaySUrveys);
        case "PROGRESS":
            return sortSurveysByProgress(ascending, displaySUrveys);
        case "PURPOSE":
            return sortSurveysByPurpose(ascending, displaySUrveys);
        default:
            return displaySUrveys;
    }
};

export const filteredSurveys = (): DisplaySurvey[] => {
    const filteredList: DisplaySurvey[] = getFilteredSurveys();
    const sortedFilteredList: DisplaySurvey[] = getSortedSurveys(filteredList);
    return sortedFilteredList;
};

export const getInboxActiveFilterFromUrl = (): InboxFilters | null => {
    const { inboxLastActiveFilter } = inboxStore;
    const activeFilterInLeftMenu =
        window.location.pathname.split("/")[2] || null;
    let newFilter: InboxFilters | null = null;
    switch (activeFilterInLeftMenu) {
        case "need-attention":
            newFilter = "NEEDS_ATTENTION";
            break;
        case "all":
            newFilter = "ALL";
            break;
        case "initiator":
            newFilter = "INITIATOR";
            break;
        case "ongoing":
            newFilter = "ONGOING";
            break;
        case "respondent":
            newFilter = "RESPONDENT";
            break;
        case "not-completed":
            newFilter = "NOT_COMPLETED";
            break;
        case "sharedsurveys":
            newFilter = "SHARED_WITH_ME";
            break;
        case "templates":
            newFilter = "TEMPLATES";
            break;
        case "archived":
            newFilter = "ARCHIVED";
            break;
        case "sharedviewswithme":
            newFilter = "SHARED_VIEWS_WITH_ME";
            break;
        case "draft":
            newFilter = "DRAFT";
            break;
        default:
            newFilter = null;
            break;
    }
    if (newFilter !== null && newFilter !== inboxLastActiveFilter) {
        setInboxActiveFilterFlagNoRedirect(newFilter);
    }
    return newFilter;
};

export const getAllSimpleSurvey = (): BaseSurvey[] => {
    const surveys = surveyStore.surveysList.filter(
        (baseSurvey: BaseSurvey) => baseSurvey.survey_class === "SIMPLE"
    );
    return surveys;
};

export const getAllOngoingSurvey = (): BaseSurvey[] => {
    const surveys = surveyStore.surveysList.filter(
        (baseSurvey: BaseSurvey) => baseSurvey.survey_class === "ONGOING"
    );
    return surveys;
};
