import { action } from "mobx";
import semver from "semver";
import jwt_decode from "jwt-decode";
import { TokenPayload } from "google-auth-library";
import { AuthData, TokenData, UserDataInfo } from "@difftone/types";
import { loginStore } from "@difftone/stores";
import {
    logoutFromGoogle,
    logoutFromMicrosoft,
    showDifftoneAlert,
    showServerErrorAlert,
} from "@difftone/actions";
import {
    localstorageUtils,
    segmentUserIdentify,
    sendEvent,
    setAuthInstance,
} from "@difftone/procedures";
import {
    AuthResponse,
    InteractionRequiredAuthError,
    UserAgentApplication,
} from "msal";
import { User } from "@microsoft/microsoft-graph-types";
import {
    getGoogleClientId,
    getMicrosoftClientId,
    GoogleLoginScopes,
    MicrosoftLoginScopes,
    WEB_CLIENT_VERSION,
} from "@difftone/constants";
import {
    decodeGoogleUserToken,
    decodeMicrosoftUserToken,
} from "@difftone/frontend-common";

export const GOOGLE_JWT_URI = "https://www.googleapis.com/oauth2/v3/certs";
export const MICROSOFT_JWT_URI =
    "https://login.microsoftonline.com/common/discovery/v2.0/keys";

export const setGapiModulesInitialized = action(
    (isModulesInitialized: boolean) => {
        loginStore.gapiModulesInitialized = isModulesInitialized;
    }
);

export const setIsInitializedLoginStore = action((isInitialized: boolean) => {
    loginStore.isInitialized = isInitialized;
});

export const setStartInitializeLoginStore = action(
    (isStartInitialized: boolean) => {
        loginStore.startInitialize = isStartInitialized;
    }
);

export const setIsAuthDataInititalized = action(
    (isAuthDataInititalized: boolean) => {
        loginStore.authDataInititalized = isAuthDataInititalized;
    }
);

export const _init = async () => {
    checkVersion();

    const authDataAsString = localStorage.getItem("authData");
    if (!authDataAsString) {
        return null;
    }

    try {
        const authData: AuthData = JSON.parse(authDataAsString);
        if (authData.issuer === "GOOGLE") {
            const googleUserInstance = await getGoogleUserInstance();
            setAuthInstance(googleUserInstance, "GOOGLE");
            const jwtPayload: TokenPayload = await jwt_decode(
                googleUserInstance.getAuthResponse().id_token
            );
            const decodedGoogleUserToken = decodeGoogleUserToken(jwtPayload);
            return decodedGoogleUserToken;
        } else if (authData.issuer === "MICROSOFT") {
            const msalInstance = new UserAgentApplication({
                auth: { clientId: getMicrosoftClientId() },
            });
            let tokenData: AuthResponse | undefined;
            try {
                tokenData = await msalInstance.acquireTokenSilent({
                    scopes: MicrosoftLoginScopes,
                });
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    tokenData = await msalInstance.acquireTokenPopup({
                        scopes: MicrosoftLoginScopes,
                    });
                } else {
                    throw new Error(`Error authenticating`);
                }
            }
            setAuthInstance(msalInstance, "MICROSOFT");
            const jwtPayload: TokenData = await jwt_decode(
                tokenData.idToken.rawIdToken
            );
            const decodedMicrosoftUserToken =
                decodeMicrosoftUserToken(jwtPayload);
            return decodedMicrosoftUserToken;
        }

        return null;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const getGoogleUserInstance = async () => {
    //need to check if client has already been initialized by login button
    if (!gapi.client.getToken()) {
        await gapi.client.init({
            clientId: getGoogleClientId(),
            scope: GoogleLoginScopes.join(" "),
        });
    }
    //Ignore because default type doesn't support await but it is wrapped in a promise
    const authInstance = await gapi.auth2.getAuthInstance();
    if (!authInstance.isSignedIn.get()) {
        await authInstance.signIn();
    }
    return authInstance.currentUser.get();
};

export const googleLoginHandler = async (googleAuthData: any) => {
    try {
        setAuthInstance(googleAuthData, "GOOGLE");

        if (!googleAuthData) {
            showServerErrorAlert();
            throw Error("Error when fetching offline auth data");
        }

        const authData: AuthData =
            loginStore.getAuthDataFromGoogleAuthData(googleAuthData);

        const userDataPayload: TokenData = decodeGoogleUserToken(
            jwt_decode(authData.user_token)
        );
        const userInfo: UserDataInfo =
            loginStore.publicUserInfoData(userDataPayload);

        localstorageUtils.authUserInfoFromLocalStorage = userInfo;

        localstorageUtils.authDataFromLocalstorage = authData;

        segmentUserIdentify();

        sendEvent("SUCCESSFUL_GOOGLE_LOGIN");

        setIsAuthDataInititalized(true);
    } catch (error) {
        showDifftoneAlert("Could not initialize user AuthData", "FAILURE");
        console.error("Could not initialize auth data in local storage");
    }
};

export const microsoftLoginHandler = async (
    data: AuthResponse | (AuthResponse & User),
    msalInstance: UserAgentApplication
) => {
    setAuthInstance(msalInstance, "MICROSOFT");
    const authData: AuthData = {
        issuer: "MICROSOFT",
        user_token: data.idToken.rawIdToken,
        access_token: data.accessToken,
    };

    const userDataPayload: TokenData = decodeMicrosoftUserToken(
        jwt_decode(authData.user_token)
    );

    const userInfo: UserDataInfo =
        loginStore.publicUserInfoData(userDataPayload);

    localstorageUtils.authUserInfoFromLocalStorage = userInfo;

    localstorageUtils.authDataFromLocalstorage = authData;

    segmentUserIdentify();

    sendEvent("SUCCESSFUL_MICROSOFT_LOGIN");

    setIsAuthDataInititalized(true);
};

const checkVersion = action(() => {
    const authDataAsString = localStorage.getItem("authData");
    const savedVersion = localStorage.getItem("clientVersion");

    if (!authDataAsString || !savedVersion) {
        return;
    }

    if (semver.gt(WEB_CLIENT_VERSION, savedVersion)) {
        const authData: AuthData = JSON.parse(authDataAsString);

        showDifftoneAlert(
            "The app has been updated, please reload and login again for the latest version.",
            "GENERAL",
            async () => {
                if (authData.issuer === "GOOGLE") {
                    logoutFromGoogle();
                } else {
                    logoutFromMicrosoft();
                }
            }
        );
    }
});
