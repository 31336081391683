import React from "react";

import { showCategoriesDialog } from "@difftone/actions";
import settingsIcon from "@difftone/assets/settings-icon.svg";

import {
    manage_categories_button,
    manage_categories_button_icon,
} from "./manage-categories-button.module.css";

export type ManageCategoriesButtonProps = {};

export const ManageCategoriesButton = () => (
    <li className={manage_categories_button} onClick={showCategoriesDialog}>
        <img
            src={settingsIcon}
            alt=""
            className={manage_categories_button_icon}
        />
        Categories
    </li>
);
