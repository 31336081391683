import { UiStatus } from "@difftone/frontend-common";
import {
    OngoingSurvey,
    SimpleSurvey,
    SurveyClasses,
    SurveyEvent,
    UUID,
} from "@difftone/types";
import { Task } from "gantt-task-react";
import { getStatus } from "@difftone/reducers";
import {
    publicProfileStore,
    surveyResponseStatusStore,
} from "@difftone/stores";
import {
    getLocalDateStrFromUtcDateStrToInboxTooltip,
    millisecondsToDateStringDisplay,
    totalTimeInMiliSecondsFromString,
} from "@difftone/time-utils";

export type SurveyStartAndEndString = {
    startDate: string;
    endDate: string;
};

const ADD_TWO_YEARS = 2;

const ACTIVE_BACKGROUND_COLOR = "rgba(40, 161, 100, 0.2)";
const ACTIVE_PROGRESS_COLOR = "#28A164";

const SAVED_BACKGROUND_COLOR = "rgba(53, 151, 212, 0.2)";
const SAVED_PROGRESS_COLOR = "#3597D4";

const ENDED_BACKGROUND_COLOR = "rgba(182, 184, 186, 0.2)";
const ENDED_PROGRESS_COLOR = "#B6B8BA";

export const generateTasksFromSurveys = (surveys: SurveyClasses[]): Task[] => {
    return surveys.map((survey) => {
        const status: UiStatus = getStatus(survey, "INITIATOR");
        const completed: number =
            surveyResponseStatusStore.getStatusBySurveyUuid(
                survey.uuid!
            ).length;
        const total: number = survey!.respondents!.length;
        const percent: number = (completed * 100) / total || 0;

        let startDate: Date | null = null;
        let endDate: Date | null = null;

        switch (survey.survey_class) {
            case "ONGOING":
                startDate = calculateOngoingSurveyStartDate(survey);
                endDate = calculateOngoingSurveyEndDate(survey);
                break;
            case "SIMPLE":
            default:
                startDate = calculateSurveyStart(survey);
                endDate = calculateSurveyEnd(survey);
        }

        const surveyInfoTask: Task = {
            start: startDate,
            end: endDate,
            name: survey.survey_name,
            id: survey.uuid,
            type: "task",
            progress: percent,
            isDisabled: false,
            styles: getColorByStatus(status),
        };

        return surveyInfoTask;
    });
};

const calculateSurveyStart = (survey: SimpleSurvey) => {
    const startEvent: SurveyEvent | undefined = survey.survey_events.find(
        (event) => event.system_survey_event_type === "PUBLISHED_DATE"
    );
    const startTimeInMiliseconds: number = totalTimeInMiliSecondsFromString(
        startEvent?.date_utc!,
        startEvent?.day_utc_time!
    );
    return new Date(startTimeInMiliseconds);
};

const calculateOngoingSurveyStartDate = (survey: OngoingSurvey) => {
    return new Date(survey.created_at);
};

const calculateOngoingSurveyEndDate = (survey: OngoingSurvey) => {
    if (survey.manually_ended) {
        return new Date(survey.manually_ended);
    }

    const todaysDate = new Date();
    return addTwoYearsToDate(todaysDate);
};

const addTwoYearsToDate = (date: Date) => {
    date.setFullYear(date.getFullYear() + ADD_TWO_YEARS);
    return date;
};

const calculateSurveyEnd = (survey: SimpleSurvey) => {
    const endEvent: SurveyEvent | undefined = survey.survey_events.find(
        (event) => event.system_survey_event_type === "LAST_DATE_TO_FILL"
    );
    const endTimeInMiliseconds: number = totalTimeInMiliSecondsFromString(
        endEvent?.date_utc!,
        endEvent?.day_utc_time!
    );
    return new Date(endTimeInMiliseconds);
};

const getColorByStatus = (status: UiStatus) => {
    switch (status) {
        case "ACTIVE":
            return {
                backgroundColor: ACTIVE_BACKGROUND_COLOR,
                progressColor: ACTIVE_PROGRESS_COLOR,
            };
        case "SAVED":
            return {
                backgroundColor: SAVED_BACKGROUND_COLOR,
                progressColor: SAVED_PROGRESS_COLOR,
            };
        case "ENDED":
            return {
                backgroundColor: ENDED_BACKGROUND_COLOR,
                progressColor: ENDED_PROGRESS_COLOR,
            };
        default:
            return {
                backgroundColor: ENDED_BACKGROUND_COLOR,
                progressColor: ENDED_PROGRESS_COLOR,
            };
    }
};

export const getSurveyTooltipDataByUUIDForDashboardTimeline = (
    surveyList: SurveyClasses[],
    uuid: UUID
) => {
    const selectedSurvey = surveyList.find((survey) => survey.uuid === uuid);
    if (!selectedSurvey) return {};
    const initiatorPublicProfile = publicProfileStore.getPublicProfileByUuid(
        selectedSurvey.initiator
    );
    const completed = surveyResponseStatusStore.getStatusBySurveyUuid(
        selectedSurvey.uuid!
    ).length;
    const total = selectedSurvey!.respondents!.length;
    const percent: number = (completed * 100) / total || 0;

    const startAndEndDateOrString =
        startAndEndTimeStringBySurveyClass(selectedSurvey);

    return {
        name: selectedSurvey.survey_name,
        purpose: selectedSurvey.purpose,
        initiator: initiatorPublicProfile?.name,
        email: initiatorPublicProfile?.email,
        startDate: startAndEndDateOrString.startDate,
        endDate: startAndEndDateOrString.endDate,
        completed,
        total,
        percent,
        status: getStatus(selectedSurvey, "INITIATOR"),
    };
};

const startAndEndTimeStringBySurveyClass = (
    selectedSurvey: SurveyClasses
): SurveyStartAndEndString => {
    switch (selectedSurvey.survey_class) {
        case "ONGOING":
            return {
                startDate: millisecondsToDateStringDisplay(
                    calculateOngoingSurveyStartDate(selectedSurvey).getTime()
                ),
                endDate: millisecondsToDateStringDisplay(
                    calculateOngoingSurveyEndDate(selectedSurvey).getTime()
                ),
            };

        case "SIMPLE":
        default:
            const startSurveyEvent = selectedSurvey.survey_events.find(
                (event) => event.system_survey_event_type === "PUBLISHED_DATE"
            );

            const endSurveyEvent = selectedSurvey.survey_events.find(
                (event) =>
                    event.system_survey_event_type === "LAST_DATE_TO_FILL"
            );

            if (!startSurveyEvent || !endSurveyEvent) {
                throw Error(
                    "[difftone]::startAndEndEventsBySurveyClass could not find survey events."
                );
            }
            return {
                startDate: getLocalDateStrFromUtcDateStrToInboxTooltip(
                    startSurveyEvent.date_utc!,
                    startSurveyEvent.day_utc_time!
                ),
                endDate: getLocalDateStrFromUtcDateStrToInboxTooltip(
                    endSurveyEvent.date_utc!,
                    endSurveyEvent.day_utc_time!
                ),
            };
    }
};
