import { action } from "mobx";
import { previewSurveyStore } from "@difftone/stores";
import { SurveyClasses } from "@difftone/types";

export const openSurveyPreview = action((previewSurvey?: SurveyClasses) => {
    previewSurveyStore.inPreviewMode = true;
    if (previewSurvey) previewSurveyStore.activePreviewSurvey = previewSurvey;
});

export const closeSurveyPreview = action(() => {
    previewSurveyStore.inPreviewMode = false;
    previewSurveyStore.activePreviewSurvey = null;
});
