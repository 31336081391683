import type {
    Tag,
    ValidationResult,
    ValidationType
} from "@difftone/types";
import {
    validateTagName,
    validateTagTextColor,
    validateTagBackgroundColor
} from "./properties-validations";
import { validateTagNameUniqueness } from "./uniqueness-validations";

// Tag
export const validateTag = (
    tag: Tag
): ValidationResult<ValidationType>[] => {
    const res: ValidationResult<ValidationType>[] = [];

    res.push(
        ...validateTagName(tag),
        ...validateTagTextColor(tag),
        ...validateTagBackgroundColor(tag)
    );

    return res;
};

export const validateTagsUniqueness = (
    tags: Tag[]
): ValidationResult<ValidationType>[] => {
    return [
        ...validateTagNameUniqueness(tags)
    ];
};

export const validateTags = (
    tags: Tag[]
): ValidationResult<ValidationType>[] => {
    const res: ValidationResult<ValidationType>[] = [];

    tags.forEach(
        (tag) => res.push(...validateTag(tag))
    );

    res.push(...validateTagNameUniqueness(tags));
    
    return res;
};