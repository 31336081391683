import React, { ChangeEvent, useState } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import {
    closeScheduleSurveyPopup,
    debounceUpsertSurveyToApi,
} from "@difftone/actions";
import { BaseSurvey } from "@difftone/types";
import { DifftoneButton, PopupSurveyInfo } from "@difftone/shared-components";

import messageIcon from "@difftone/assets/message-icon-black.svg";
import trashIcon from "@difftone/assets/trash-icon-black.svg";
import closeIcon from "@difftone/assets/close-difftone-alert-icon.svg";

import {
    popup_container,
    popup_title_bar,
    popup_content,
    message_container,
    message_toggle_bar,
    cancel_button,
    submit_button,
    buttons_container,
    highlight,
    underline,
} from "./schedule-survey-popup.module.css";

export type DifftoneButtonOption = {
    icon?: string;
    text: string;
    onClick: () => void;
};

export type ScheduleSurveyPopupProps = {
    survey: BaseSurvey;
    extraOptions: DifftoneButtonOption[];
    onSubmit: () => void;
    mainButtonText: string;
    mainIconButton: string;
};

export const ScheduleSurveyPopup = observer(
    (props: ScheduleSurveyPopupProps) => {
        const {
            survey,
            extraOptions,
            onSubmit,
            mainButtonText,
            mainIconButton,
        } = props;

        //use state only used for internal component state
        const [messageInputOpen, setMessageInputOpen] = useState(false);

        const onSurveyWelcomeMessageChange = (
            event: ChangeEvent<HTMLTextAreaElement>
        ) => {
            const value: ValueOf<BaseSurvey> = event.target.value;
            const key: keyof BaseSurvey = "welcome_message";
            debounceUpsertSurveyToApi(key, value);
        };

        const handleMessageClear = () => {
            setMessageInputOpen(false);
            const key: keyof BaseSurvey = "welcome_message";
            debounceUpsertSurveyToApi(key, "");
        };

        return (
            <div className={popup_container}>
                <div className={popup_title_bar}>
                    <div>Send out survey</div>
                    <img src={closeIcon} onClick={closeScheduleSurveyPopup} />
                </div>
                <div className={popup_content}>
                    <PopupSurveyInfo survey={survey} />
                    <div className={clsx(message_container)}>
                        <div
                            className={message_toggle_bar}
                            onClick={() =>
                                setMessageInputOpen(!messageInputOpen)
                            }
                        >
                            <img src={messageIcon} />
                            <span>
                                <span
                                    className={clsx(
                                        highlight,
                                        !messageInputOpen && underline
                                    )}
                                >
                                    Include message
                                </span>{" "}
                                (optional)
                            </span>
                            {messageInputOpen ? (
                                <img
                                    onClick={handleMessageClear}
                                    src={trashIcon}
                                />
                            ) : null}
                        </div>
                        {messageInputOpen ? (
                            <textarea
                                onChange={onSurveyWelcomeMessageChange}
                                placeholder="Message to be sent with survey"
                                value={survey.welcome_message}
                            />
                        ) : null}
                    </div>
                    <div className={buttons_container}>
                        <DifftoneButton
                            onClick={closeScheduleSurveyPopup}
                            classNameOverride={cancel_button}
                            buttonText="Cancel"
                        />
                        <DifftoneButton
                            onClick={onSubmit}
                            classNameOverride={submit_button}
                            icon={mainIconButton}
                            buttonText={mainButtonText}
                            extraOptions={extraOptions}
                        />
                    </div>
                </div>
            </div>
        );
    }
);
