import { makeAutoObservable } from "mobx";
import { AlertVariants, UUID } from "@difftone/types";

class DifftoneAlertStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _isVisible: boolean = false;
    get isVisible() {
        return this._isVisible;
    }
    set isVisible(visibilityState: boolean) {
        this._isVisible = visibilityState;
    }

    private _alertVariant: AlertVariants = "GENERAL";
    get alertVariant() {
        return this._alertVariant;
    }
    set alertVariant(variant: AlertVariants) {
        this._alertVariant = variant;
    }

    private _message: string = "";
    get message() {
        return this._message;
    }
    set message(newMessage: string) {
        this._message = newMessage;
    }
    private _resolveButtonText: string = "";
    get resolveButtonText() {
        return this._resolveButtonText;
    }

    set resolveButtonText(newTextButton: string) {
        this._resolveButtonText = newTextButton;
    }

    private _showSharePanel: boolean = false;
    get showSharePanel() {
        return this._showSharePanel;
    }

    set showSharePanel(newValue: boolean) {
        this._showSharePanel = newValue;
    }

    private _onResolveCallback: Function | (() => Promise<any>) | undefined =
        undefined;
    get onResolveCallback() {
        return this._onResolveCallback;
    }
    set onResolveCallback(
        onResolve: Function | (() => Promise<any>) | undefined
    ) {
        this._onResolveCallback = onResolve;
    }

    private _onRejectCallback: Function | undefined = undefined;
    get onRejectCallback() {
        return this._onRejectCallback;
    }
    set onRejectCallback(onResolve: Function | undefined) {
        this._onRejectCallback = onResolve;
    }

    private _objectName: string = "";
    get objectName() {
        return this._objectName;
    }
    set objectName(displayObjectName: string) {
        this._objectName = displayObjectName;
    }

    public clearStore = () => {
        this._isVisible = false;
        this._message = "";
        this._alertVariant = "GENERAL";
        this._onRejectCallback = undefined;
        this._onResolveCallback = undefined;
        this._objectName = "";
        this._resolveButtonText = "";
        this._showSharePanel = false;
    };
}

export const difftoneAlertStore = new DifftoneAlertStore();
