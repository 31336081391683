import { TimeCompareUnits, UUID } from "@difftone/types";

export const TIME_FILTER_ID: UUID = "TIME_COMPARE_FILTER_ID";

export type ResultsDisplayType = "GRID" | "FAVORABLE" | "LINE";

export const TIME_COMPARE_FILTERS: TimeCompareUnits[] = [
    "NONE",
    "MONTHS",
    "QUARTERS",
    "HALF_YEAR",
];

export const timeCompareFiltersKeyValues: Record<TimeCompareUnits, string> = {
    NONE: "None",
    MONTHS: "Months",
    QUARTERS: "Quarters",
    HALF_YEAR: "Half-year",
};

export const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export const questerDisplay: { [key: number]: string } = {
    1: "Q1 (Jan-Mar)",
    2: "Q2 (Apr-Jun)",
    3: "Q3 (Jul-Sep)",
    4: "Q4 (Oct-Dec)",
};

export const halfYearDisplay: { [key: number]: string } = {
    1: "H1 (Jan-Jun)",
    2: "H2 (Jul-Dec)",
};

export const questerDisplayToMultiLine: { [key: number]: string } = {
    1: "Q1",
    2: "Q2",
    3: "Q3",
    4: "Q4",
};

export const halfYearDisplayToMultiLine: { [key: number]: string } = {
    1: "H1",
    2: "H2",
};

export const RESULTS_DISPLAY_TYPE: Record<ResultsDisplayType, string> = {
    GRID: "/grid",
    FAVORABLE: "/favorable",
    LINE: "/line",
};
