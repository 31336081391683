import React, { MouseEvent, useState } from "react";
import { observer } from "mobx-react";
import { TableHeaderArrowIcon } from "@difftone/shared-components";

import {
    TemplateSortingTypes,
    SortingTemplateProfile,
    templatesDisplayStore,
} from "@difftone/stores";
import { setTemplateSortingProfile } from "@difftone/actions";

import {
    table_header_cell,
    active_filter,
    up_arrow_direction,
} from "./table-header-cell.module.css";

export type FilterTypes = "NAME" | "OWNER" | "PURPOSE" | null;

export type TableHeaderCellProps = {
    className?: string;
    text: string;
    filterType: FilterTypes;
    isFilterActive: boolean;
    setFilter: (event: MouseEvent<HTMLDivElement>) => void;
};

const COLOR_BLACK = "BLACK";
const COLOR_GRAY = "GRAY";

export const TableHeaderCell = observer((props: TableHeaderCellProps) => {
    const { className, text, filterType, isFilterActive, setFilter } = props;
    const { sortingTemplateProfile } = templatesDisplayStore;

    const [isArrowUp, setIsArrowUp] = useState(
        isFilterActive ? sortingTemplateProfile.ascending : true
    );

    let customClassName = className
        ? [table_header_cell, className].join(" ")
        : table_header_cell;
    const filter = filterType ? filterType : "";
    const headerText = text === "blank" ? "" : text;

    if (isFilterActive) {
        customClassName = [customClassName, active_filter].join(" ");
    }

    const toggleArrowDirection = () => {
        setIsArrowUp(!isArrowUp);
    };

    const handleOnClick = (event: MouseEvent<HTMLDivElement>) => {
        toggleArrowDirection();
        const newSharedSurveySortingProfile: SortingTemplateProfile = {
            ascending: !isArrowUp,
            sortBy: text.toUpperCase() as TemplateSortingTypes,
        };
        setTemplateSortingProfile(newSharedSurveySortingProfile);
        setFilter(event);
    };

    return (
        <div
            onClick={handleOnClick}
            data-filter={filter}
            className={customClassName}
        >
            {text !== "blank" ? (
                <TableHeaderArrowIcon
                    className={isArrowUp ? up_arrow_direction : ""}
                    color={isFilterActive ? COLOR_BLACK : COLOR_GRAY}
                />
            ) : null}
            <span>{headerText}</span>
        </div>
    );
});
