import React from "react";
import { getSortedEvents, getSurveyFromStoreByURL } from "@difftone/reducers";

import { Line, WizardDates } from "./components";
import { timeline } from "./timeline.module.css";
import { SimpleSurvey } from "@difftone/types";

export type TimelineProps = {};

export const Timeline = (props: TimelineProps) => {
    const survey = getSurveyFromStoreByURL();

    if (survey.survey_class === "ONGOING") {
        throw Error(
            "[Difftone Timeline]:: Ongoing survey does not have survey events."
        );
    }

    const simpleSurvey = survey as SimpleSurvey;

    const sortedEvents = getSortedEvents(simpleSurvey) || [];

    return (
        <div className={timeline}>
            <Line />
            <WizardDates sortedEvents={sortedEvents} />
        </div>
    );
};
