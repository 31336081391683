import React, { Ref, useRef, useEffect, useState } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import { ShowDifftoneTooltip } from "@difftone/shared-components";

import {
    name_with_tooltip,
    name_hidden,
    name_with_tooltip_text,
    name_with_tooltip_text_nowrap,
} from "./name-with-tooltip.module.css";

export type TextWithTooltipProps = {
    text: string;
    noWrap?: boolean;
};

export const NameWithTooltip = observer((props: TextWithTooltipProps) => {
    const { text, noWrap = true } = props;
    // ref to store container of the text ( to compare width from )
    const containerRef: Ref<HTMLDivElement> = useRef(null);
    // ref to store hidden text element ( to calculate width from )
    const textRef: Ref<HTMLDivElement> = useRef(null);
    // value to store after ref update is catch by useEffect
    const [isTextOverflowsContainer, setIsTextOverflowsContainer] =
        useState(false);

    const setIsTooltipVisible = () => {
        if (containerRef.current && textRef.current) {
            if (
                containerRef.current.offsetWidth < textRef.current.offsetWidth
            ) {
                setIsTextOverflowsContainer(true);
            } else {
                setIsTextOverflowsContainer(false);
            }
        }
    };

    // useEffect needed to catch the update of ref, since ref change doesn't rerender component ( discussed with Shane )
    useEffect(() => {
        setIsTooltipVisible();
        window.addEventListener("resize", setIsTooltipVisible);

        return () => {
            window.removeEventListener("resize", setIsTooltipVisible);
        };
    }, []);

    return (
        <div className={name_with_tooltip} ref={containerRef}>
            <span ref={textRef} className={name_hidden}>
                {text}
            </span>
            {isTextOverflowsContainer ? (
                <ShowDifftoneTooltip tip={text} tooltipPosition="top">
                    <div
                        className={clsx(name_with_tooltip_text, {
                            [name_with_tooltip_text_nowrap]: noWrap,
                        })}
                    >
                        {text}
                    </div>
                </ShowDifftoneTooltip>
            ) : (
                <div>{text}</div>
            )}
        </div>
    );
});
