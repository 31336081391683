import React from "react";
import { observer } from "mobx-react";

import { EmailAddress } from "@difftone/types";
import { getSurveyFromStoreByUUID } from "@difftone/reducers";
import { constantAlertVariants, PAGE_URLS } from "@difftone/constants";
import {
    respondentsDeleteInCopySurvey,
    setLocation,
    showDifftoneAlert,
    updateNewCopySurvey,
    updateRespondentsInCopySurvey,
} from "@difftone/actions";
import { navigationStore, inboxDisplayStore } from "@difftone/stores";
import {
    AddBoxShadow,
    DialogBackground,
    MailingListProducer,
    PopupSurveyInfo,
} from "@difftone/shared-components";
import recipientsIcon from "@difftone/assets/reminder-recipients-icon.svg";
import { PopupButton, PopupHeader, PopupMessage } from "./components";

import {
    popup_new_participants,
    popup,
    mailing_list_producer_scope,
    popup_body_scope,
    recipients_title,
    header_send_to,
} from "./popup-new-participants.module.css";
type PopupNewParticipantsProps = {};

export const PopupNewParticipants = observer(
    (props: PopupNewParticipantsProps) => {
        const { currentPage } = navigationStore;
        const { copySurvey, newRespondents } = inboxDisplayStore;
        const surveyUuid = currentPage.split("/")[4];

        if (surveyUuid === null) {
            setLocation(PAGE_URLS.INBOX_ALL);
            showDifftoneAlert(
                constantAlertVariants.survey_url_failure.text,
                constantAlertVariants.survey_url_failure.variant
            );
            return null;
        }

        const survey = getSurveyFromStoreByUUID(surveyUuid);

        if (!inboxDisplayStore.copySurvey) updateNewCopySurvey(survey);

        const handleRespondentsChange = (emails: EmailAddress[]) => {
            updateRespondentsInCopySurvey(emails);
        };

        const handleRespondentsDelete = (emailToDelete: EmailAddress) => {
            respondentsDeleteInCopySurvey(emailToDelete);
        };

        return (
            <DialogBackground>
                <AddBoxShadow>
                    <div className={popup_new_participants}>
                        <div className={popup}>
                            <PopupHeader />
                            <div className={popup_body_scope}>
                                {survey && <PopupSurveyInfo survey={survey} />}
                                <div className={recipients_title}>
                                    <img
                                        src={recipientsIcon}
                                        alt="recipients-icon"
                                    />
                                    <div className={header_send_to}>
                                        Send to :
                                    </div>
                                </div>
                                <div className={mailing_list_producer_scope}>
                                    {copySurvey && (
                                        <MailingListProducer
                                            disabled={false}
                                            readOnlyMalingList={false}
                                            inEditMode={false}
                                            styleOptions={{
                                                customWidth: "287px",
                                                listBoxHeight: 150,
                                            }}
                                            onEnter={handleRespondentsChange}
                                            onDelete={handleRespondentsDelete}
                                            mailingList={newRespondents}
                                        />
                                    )}
                                </div>
                                <PopupMessage />
                                <PopupButton />
                            </div>
                        </div>
                    </div>
                </AddBoxShadow>
            </DialogBackground>
        );
    }
);
