import React, { ReactNode } from "react";
import { ResultsFilter } from "@difftone/types";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core";
import { ALLOWED_ATTRIBUTE_TITLE_LENGTH } from "@difftone/stores";
import { convertTimeRangeToHeaderDisplay } from "@difftone/reducers";
import { ShowDifftoneTooltip } from "@difftone/shared-components";

import { SecondValueTitle } from "./components";
import {
    item_text,
    list_item,
    second_attribute_row,
} from "./second-attribute-row.module.css";

const extendTooltipStyle = makeStyles({
    topTooltipResultsSecondHeaderRow: {
        top: "-45px",
        left: "40px",
    },
});

export type SecondAttributeRowProps = {
    filtersActiveAsCompares: ResultsFilter[];
};

//TODO: move to shared component
export const SecondAttributeRow = observer((props: SecondAttributeRowProps) => {
    const { filtersActiveAsCompares } = props;
    const firstHeader = filtersActiveAsCompares.find(
        (_filter) => _filter.indexInComparePlain === 1
    );
    const numberOfIterations = firstHeader?.filterValues.length || 0;
    const secondRowHeaders: ReactNode[] = [];

    const tooltipSecondHeader =
        extendTooltipStyle().topTooltipResultsSecondHeaderRow;

    const returnSingleList = () => {
        return filtersActiveAsCompares[1]?.filterValues
            .map((item) => {
                if (typeof item === "object") {
                    const timeFrameHeaderValue =
                        convertTimeRangeToHeaderDisplay(
                            filtersActiveAsCompares[1],
                            item
                        );
                    return timeFrameHeaderValue;
                }
                return item;
            })
            .map((questionTitle, index) => {
                const isLastInHeadersBlock =
                    index ===
                    filtersActiveAsCompares[1]?.filterValues.length - 1;
                return (
                    <ShowDifftoneTooltip
                        extendTooltipStyle={tooltipSecondHeader}
                        tooltipPosition="top"
                        tip={questionTitle}
                        key={questionTitle}
                        disableHover={
                            questionTitle.length <
                            ALLOWED_ATTRIBUTE_TITLE_LENGTH
                        }
                    >
                        <div key={questionTitle} className={list_item}>
                            <div className={item_text}>
                                <SecondValueTitle
                                    title={questionTitle}
                                    isLastInHeadersBlock={isLastInHeadersBlock}
                                />
                            </div>
                        </div>
                    </ShowDifftoneTooltip>
                );
            });
    };

    for (let i = 0; i < numberOfIterations; i++) {
        secondRowHeaders.push(returnSingleList());
    }
    return <div className={second_attribute_row}>{secondRowHeaders}</div>;
});
