import { action } from "mobx";
import { autoCompleteStore } from "@difftone/stores";

export const openAutocompleteDropdown = action(() => {
    autoCompleteStore.isAutocompleteOpen = true;
});

export const closeAutocompleteDropdown = action(() => {
    autoCompleteStore.isAutocompleteOpen = false;
});
