import React from "react";

import { Question, QuestionType } from "@difftone/types";
import {
    QuestionTypeIcon,
    ShowDifftoneTooltip,
} from "@difftone/shared-components";
import { MAX_CHARACTERS_IN_FAVORABLE_QUESTION_TITLE } from "@difftone/constants";

import { AvgQuestionRange } from "./components";
import {
    category_question,
    category_question_icon,
    category_question_name,
    category_question_wrapper,
    question_index,
    question_index_and_title,
} from "./results-category-question.module.css";

export type ResultsCategoryQuestionProps = {
    question: Question<QuestionType>;
};

export const ResultsCategoryQuestion = (
    props: ResultsCategoryQuestionProps
) => {
    const { question } = props;

    let questionTitle = null;

    if (question.title.length > 45) {
        questionTitle = (
            <div className={category_question_wrapper}>
                <ShowDifftoneTooltip tip={question.title} tooltipPosition="top">
                    <span className={category_question_name}>
                        {question.title
                            .slice(
                                0,
                                MAX_CHARACTERS_IN_FAVORABLE_QUESTION_TITLE
                            )
                            .concat("...")}
                    </span>
                </ShowDifftoneTooltip>
            </div>
        );
    } else {
        questionTitle = (
            <span className={category_question_name}>{question.title}</span>
        );
    }

    return (
        <div className={category_question}>
            <div className={question_index_and_title}>
                <span className={question_index}>{`${question.number}.`}</span>
                {questionTitle}
            </div>
            <QuestionTypeIcon
                className={category_question_icon}
                questionTypeName={question.question_type_name}
            />
            <AvgQuestionRange question={question} />
        </div>
    );
};
