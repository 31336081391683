import { anonymityLimitation } from "@difftone/anonymity-util";
import {
    publicProfileStore,
    resultsDisplayStore,
    resultsMapStore,
    sharedResultStore,
    surveyResponsesSummaryStore,
    resultsDisplayFilterStore,
} from "@difftone/stores";
import { AnswersWithQuestionType, SurveyClasses, UUID } from "@difftone/types";

import {
    formatCategoriesQuestionsMap,
    formatCategoriesResultsRatingMap,
    getSortedCategoriesList,
    getSurveyResponseData,
    sortQuestionsByType,
} from "@difftone/reducers";
import {
    CSVHeaders,
    readyCategoriesResultsDataExportToCSV,
    readyResultsDataExportToCSV,
} from "./results-export-csv-reducer";

export const dataCSV = (
    selectedSurvey: SurveyClasses
): (string | number)[][] => {
    const { getFiltersActiveAsCompares, getFiltersNotActiveAsCompares } =
        resultsDisplayFilterStore;

    const { targetSharedResult } = sharedResultStore;

    const firstHeader = getFiltersActiveAsCompares().find(
        (_filter) => _filter.indexInComparePlain === 1
    );

    const secondHeader = getFiltersActiveAsCompares().find(
        (_filter) => _filter.indexInComparePlain === 2
    );

    const initiatorPublicProfile =
        selectedSurvey && selectedSurvey.initiator
            ? publicProfileStore.getPublicProfileByUuid(
                  selectedSurvey.initiator
              )
            : null;

    const resultsRow = resultsMapStore.getResultsTableByFilterPlainNSurvey(
        selectedSurvey,
        getFiltersNotActiveAsCompares(),
        getFiltersActiveAsCompares(),
        targetSharedResult
    );

    const surveyResponses =
        surveyResponsesSummaryStore.getSurveyResponseSummaryByFilterPlain(
            selectedSurvey.uuid,
            []
        );

    const sortedQuestionsByType = sortQuestionsByType(selectedSurvey.questions);

    const allResultWithNonFilterQuestionType = sortedQuestionsByType.reduce(
        (answersArray: AnswersWithQuestionType[], question) => {
            if (!question.is_filter) {
                const resultsQuestion: AnswersWithQuestionType = {
                    questionType: question.question_type_name,
                    answers: resultsRow
                        ? Object.values(resultsRow?.[question.uuid])
                        : [],
                };
                answersArray.push(resultsQuestion);
            }
            return answersArray;
        },
        []
    );

    const surveyResultData = readyResultsDataExportToCSV(
        allResultWithNonFilterQuestionType,
        selectedSurvey
    );

    const surveyResponseData = getSurveyResponseData(selectedSurvey);

    const selectedSurveyResult = surveyResultData.reduce((acc, rec) => {
        return [...acc, ...rec];
    }, []);

    return [
        [],
        [selectedSurvey.survey_name],
        [
            `Status: ${selectedSurvey.status}`,
            `Purpose ${selectedSurvey.purpose}`,
        ],
        [
            `Initiator ${initiatorPublicProfile?.name}`,
            `Anonymity ${selectedSurvey.anonimity}`,
        ],
        [
            `Response rate ${surveyResponseData.percentage}% (${surveyResponseData.completed}/${surveyResponseData.total})`,
        ],
        [],
        ...CSVHeaders(firstHeader, secondHeader),
        ...selectedSurveyResult,
    ];
};

export const dataCategoriesCSV = (
    selectedSurvey: SurveyClasses
): (string | number)[][] => {
    const { getFiltersActiveAsCompares, getFiltersNotActiveAsCompares } =
        resultsDisplayFilterStore;

    const { targetSharedResult } = sharedResultStore;

    const firstHeader = getFiltersActiveAsCompares().find(
        (_filter) => _filter.indexInComparePlain === 1
    );

    const secondHeader = getFiltersActiveAsCompares().find(
        (_filter) => _filter.indexInComparePlain === 2
    );

    const initiatorPublicProfile =
        selectedSurvey && selectedSurvey.initiator
            ? publicProfileStore.getPublicProfileByUuid(
                  selectedSurvey.initiator
              )
            : null;

    const resultsRow = resultsMapStore.getResultsTableByFilterPlainNSurvey(
        selectedSurvey,
        getFiltersNotActiveAsCompares(),
        getFiltersActiveAsCompares(),
        targetSharedResult
    );

    const surveyResponseData = getSurveyResponseData(selectedSurvey);

    if (!resultsRow) {
        return [];
    }

    const anonymityLevel = anonymityLimitation[selectedSurvey.anonimity];

    const categoriesQuestionsMap = formatCategoriesQuestionsMap(
        selectedSurvey?.questions || []
    );

    const categoriesList: UUID[] = getSortedCategoriesList(
        categoriesQuestionsMap
    );

    const categoriesResultsMap = formatCategoriesResultsRatingMap(
        categoriesQuestionsMap,
        resultsRow
    );

    const categoriesResultsDataMap = readyCategoriesResultsDataExportToCSV(
        categoriesList,
        categoriesQuestionsMap,
        categoriesResultsMap,
        resultsRow,
        selectedSurvey
    );

    const categoriesResultsData = categoriesResultsDataMap.length
        ? categoriesResultsDataMap.reduce((start = [], item = []) => [
              ...start,
              ...item,
          ])
        : [];

    return [
        [],
        [selectedSurvey.survey_name, "", ""],
        [
            `Status: ${selectedSurvey.status}`,
            `Purpose ${selectedSurvey.purpose}`,
        ],
        [
            `Initiator ${initiatorPublicProfile?.name}`,
            `Anonymity ${selectedSurvey.anonimity}`,
        ],
        [
            `Response rate ${surveyResponseData.percentage}% (${surveyResponseData.completed}/${surveyResponseData.total})`,
        ],
        [],
        ...CSVHeaders(firstHeader, secondHeader),
        ...categoriesResultsData,
    ];
};
