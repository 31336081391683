import {
    QuestionInvalidNameValidationType,
    QuestionInvalidNicknameValidationType,
    BaseSurvey,
    UUID,
    ValidationResult,
} from "@difftone/types";
import { getValidationErrorsFromStoreByUUID } from "@difftone/reducers";
import { getValidationErrosByErrorCode } from "@difftone/actions";

export const questionTitleValidation = (
    questionUUID: UUID,
    survey: BaseSurvey
) => {
    const questionValidationErrors = getValidationErrorsFromStoreByUUID(
        questionUUID,
        survey
    );

    const questionsNameValidations = getValidationErrosByErrorCode(
        "QUESTION_NAME_VALIDATION",
        questionValidationErrors
    ) as ValidationResult<QuestionInvalidNameValidationType>[];
    const questionsNameUniquenessValidations = getValidationErrosByErrorCode(
        "QUESTION_NAME_DUPLICATION_VALIDATION",
        questionValidationErrors
    ) as ValidationResult<QuestionInvalidNameValidationType>[];

    const isValid = !(
        questionsNameValidations.length > 0 ||
        questionsNameUniquenessValidations.length > 0
    );

    return { isValid };
};

export const filterQuestionNickNameValidation = (
    questionUUID: UUID,
    survey: BaseSurvey
) => {
    const questionValidationErrors = getValidationErrorsFromStoreByUUID(
        questionUUID,
        survey
    );

    const questionNickNameValidation = getValidationErrosByErrorCode(
        "QUESTION_EMPTY_NICKNAME_VALIDATION",
        questionValidationErrors
    ) as ValidationResult<QuestionInvalidNicknameValidationType>[];

    const questionNicknameUniquenessValidations = getValidationErrosByErrorCode(
        "QUESTION_NICKNAME_DUPLICATION_VALIDATION",
        questionValidationErrors
    ) as ValidationResult<QuestionInvalidNicknameValidationType>[];

    const isNicknameValid = !(
        questionNicknameUniquenessValidations.length > 0 ||
        questionNickNameValidation.length > 0
    );

    return {
        isNicknameValid,
        questionNicknameUniquenessValidations,
        questionNickNameValidation,
    };
};
