import { getUserUuid, localstorageUtils } from "@difftone/procedures";
import { getApiBaseUrl } from "@difftone/constants";
import { errorHandlerProxy } from "@difftone/procedures";
import type { UserProfile } from "@difftone/types";
import { createDifftoneAxios } from "@difftone/services";

const SERVER_USER_PROFILE_SERVICE_URL = `${getApiBaseUrl()}/user-profile-service`;

export const updateProfile = async (
    profileBody: UserProfile & { user_uuid: string }
) => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.put(
        SERVER_USER_PROFILE_SERVICE_URL,
        {
            user_profile: profileBody,
        },
        {
            params: { issuer: currentAuthData.issuer },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    return response.data.results;
};

export const getProfile = async () => {
    const userUIID = getUserUuid();

    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios
        .get(`${SERVER_USER_PROFILE_SERVICE_URL}/user-profile`, {
            params: {
                user_id: userUIID,
            },
        })
        .catch((e) => {
            return e.response.status;
        });

    return response;
};
