import React from "react";
import { Question, QuestionType } from "@difftone/types";

import {
    mobile_results_question,
    question_description,
    question_title,
    question_index,
    not_supported_desclaimer,
} from "./not-supported-question.module.css";

export type NotSupportedQuestionProps = {
    question: Question<QuestionType>;
    index: number;
};

export const NotSupportedQuestion = (props: NotSupportedQuestionProps) => {
    const { question, index } = props;
    return (
        <div className={mobile_results_question}>
            <div className={question_description}>
                <div className={question_title}>
                    <span className={question_index}>{index + 1}.</span>
                    <span>{question.title}</span>
                </div>
            </div>
            <div className={not_supported_desclaimer}>
                "{question.question_type_name}
                &nbsp;Not Supported Yet"
            </div>
        </div>
    );
};
