import React from "react";
import { observer } from "mobx-react";

import { getSharedSurveyFromStoreByURL } from "@difftone/reducers";
import {
    constantAlertVariants,
    INBOX_URL,
    warningMessages,
} from "@difftone/constants";
import { sendEvent } from "@difftone/procedures";

import {
    DuplicateSurveyPopup,
    Spinner,
    SharedWith,
} from "@difftone/shared-components";
import {
    debounceUpdateSharedSurveyToApi,
    handleMultipleAddresseesChange,
    onShareSurveySend,
    setLocation,
    showDifftoneAlert,
    updateSharedSurvey,
} from "@difftone/actions";

import {
    EmailAddress,
    OptionalPropertiesForDuplicateSurvey,
} from "@difftone/types";

export type SharedSurveyProps = {};

export const SharedSurvey = observer(() => {
    const sharedSurvey = getSharedSurveyFromStoreByURL();

    if (sharedSurvey === undefined) {
        return <Spinner size="MEDIUM" />;
    }

    if (sharedSurvey === null) {
        showDifftoneAlert(
            constantAlertVariants.survey_to_share_failure.text,
            constantAlertVariants.survey_to_share_failure.variant
        );
        setLocation(INBOX_URL);
        return null;
    }

    const onMultipleAddresseesChange = (emails: EmailAddress[]) => {
        handleMultipleAddresseesChange(emails, sharedSurvey);
    };

    const onRespondentsDelete = (emailToDelete: EmailAddress) => {
        sharedSurvey.addressee = sharedSurvey.addressee.filter(
            (email) => email !== emailToDelete
        );
        updateSharedSurvey(sharedSurvey);
    };

    const onMessageChange = (message: string) => {
        const value = message;
        const key = "message";

        debounceUpdateSharedSurveyToApi(key, value);
    };

    const onShareHandler = async (
        optionalProperties: OptionalPropertiesForDuplicateSurvey
    ) => {
        if (!sharedSurvey.addressee.length) {
            showDifftoneAlert(
                warningMessages.surveyCannotShareWithoutAdding,
                "FAILURE"
            );
            return;
        }

        await onShareSurveySend(sharedSurvey, optionalProperties);
        setLocation(INBOX_URL);

        sendEvent("SHARE_SURVEY_BUTTON_CLICK", sharedSurvey.uuid);
    };

    return (
        <DuplicateSurveyPopup
            onDuplicateHandler={onShareHandler}
            titleName="Share"
            survey={sharedSurvey.survey}
            buttonText="Send"
        >
            <SharedWith
                addressees={sharedSurvey.addressee}
                handleMultipleAddresseesChange={onMultipleAddresseesChange}
                handleRespondentsDelete={onRespondentsDelete}
                handleMessageChange={onMessageChange}
            />
        </DuplicateSurveyPopup>
    );
});
