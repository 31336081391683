import { HtmlTemplate } from "@difftone/types";

export const surveyEndedEmailTemplate: HtmlTemplate = `<!DOCTYPE html>
<html>
    <head>
        <style>
            * {
                box-sizing: border-box;
            }
            a {
              color:inherit;
            }
            .mail_body {
                font-family: sans-serif;
                font-style: normal;
                text-align: center;
                background-color: #e6ecec;
                height: 100%;
                margin: 50px auto;
                padding: 67px;
                position: relative;
                width: 530px;
                color: #035375;
                font-size: 14px;
                letter-spacing: 0.015em;
            }
            .logo_container img{
              height: 20px;
            }
            .main_card {
              background: #ffffff;
              padding: 30px 25px;
              border-radius: 8px;
            }
            .green_text {
              color:#009788;
              line-height: 18px;
            }
            .green_text a {
              color:inherit;
              text-transform: capitalize;
              font-weight: bold;
            }
            .greeting {
              margin: 0;
              line-height: 20px;
              letter-spacing: 0.025em;
            }
            .greeting a{
              color: #035375;
             }
            .spacer_15 {
              width: 100%;
              height: 15px;
            }
            .spacer_20 {
              width: 100%;
              height: 15px;
            }
            .submit_button {
              cursor: pointer;
              width: 160px;
              height: 40px;
              margin: 0 auto;
              padding: 0;
              background-color: transparent;
              font-weight: bold;
              line-height: 35px;
              letter-spacing: 0.025em;
              border: 2.5px solid #035375;
              box-shadow: 0px 4px 8px -4px rgba(27, 46, 94, 0.03), 0px 2px 8px -6px rgba(27, 46, 94, 0.03);
               border-radius: 8px;
            }
            .submit_button a {
              text-decoration: none;
            }
            .title {
			  overflow: hidden;
			  text-overflow: ellipsis;
              font-size: 24px;
              font-weight: bold;
              line-height: 31px;
            }
        </style>
    </head>
    <body>
        <div class="mail_body">
          <div class="logo_container">
            <img
              src="https://difftone-images.s3.eu-central-1.amazonaws.com/logo.png"  
              alt=""
            />
        </div>
        <div class="spacer_15"></div>
        <div class="main_card">
            <p class="greeting">
                Hello <b>{{initiator_name}}</b>
                <br />
                The following survey has ended:
            </p>
            {{#if survey_name}}
            <div class="spacer_20"></div>
            <div class="title" title="{{survey_name}}">{{survey_name}}</div>
            {{/if}}
            {{#if welcome_message}}
            <div class="spacer_15"></div>
            <div class="green_text">
              <p style="white-space: pre-line;">
                {{welcome_message}}  
              </p>
            </div>
            {{/if}}
            <div class="spacer_20"></div>
            <div class="submit_button">
              <a style="color: #035375;" clicktracking="off" href="{{link_url}}">{{button_title}}</a>
            </div>
        </div>
        </div>
    </body>
</html>`;
