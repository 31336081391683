const NUMBER_OF_MONTHS = 3;

import { action } from "mobx";
import {
    dashboardDisplayStore,
    DashboardSortingProfile,
} from "@difftone/stores";
import {
    milisecondsToDateString,
    getUtcDateAtMidnightInMilsWithDecreasingMonths,
    getUtcDateAtMidnightInMils,
} from "@difftone/time-utils";
import { focusedCalendar, DashboardFilter, UUID } from "@difftone/types";
import { getUserUuid } from "@difftone/procedures";
import { ViewMode } from "gantt-task-react";

export const setDashboardCalendarOpen = action(
    (focusedCalendarElement: focusedCalendar | null) => {
        dashboardDisplayStore.dashboardFocusedCalendar = focusedCalendarElement;

        dashboardDisplayStore.filtersStatusMode = "DRAFT_MODE";
    }
);

export const setDefaultDashboardFilterBy = action(() => {
    const defaultFilters: DashboardFilter = {
        initiators: [],
        startDate: milisecondsToDateString(
            getUtcDateAtMidnightInMilsWithDecreasingMonths(NUMBER_OF_MONTHS)
        ),
        endDate: milisecondsToDateString(getUtcDateAtMidnightInMils()),
    };

    defaultFilters.initiators.push(getUserUuid());

    dashboardDisplayStore.dashboardReadyFilter = defaultFilters;
    dashboardDisplayStore.dashboardDraftFilter = defaultFilters;
});

export const updateFilterStartDate = action((startDate: string) => {
    const copyDashboardDraftFilter: DashboardFilter = JSON.parse(
        JSON.stringify(dashboardDisplayStore.dashboardDraftFilter)
    );

    copyDashboardDraftFilter.startDate = startDate;

    dashboardDisplayStore.dashboardDraftFilter = copyDashboardDraftFilter;
});

export const updateFilterEndDate = action((endDate: string) => {
    const copyDashboardDraftFilter: DashboardFilter = JSON.parse(
        JSON.stringify(dashboardDisplayStore.dashboardDraftFilter)
    );

    copyDashboardDraftFilter.endDate = endDate;

    dashboardDisplayStore.dashboardDraftFilter = copyDashboardDraftFilter;
});

export const addInitiatorToList = action((initiatorUUID: UUID) => {
    dashboardDisplayStore.filtersStatusMode = "DRAFT_MODE";

    dashboardDisplayStore.selectedInitiatorsList.push(initiatorUUID);
});

export const removeInitiatorFromList = action((initiatorUUID: UUID) => {
    dashboardDisplayStore.filtersStatusMode = "DRAFT_MODE";

    dashboardDisplayStore.selectedInitiatorsList =
        dashboardDisplayStore.selectedInitiatorsList.filter(
            (_initiatorUUID) => _initiatorUUID !== initiatorUUID
        );
});

export const updateSelectedUuidsToDashboardFilter = action(
    (updatedInitiatorsList: UUID[]) => {
        const copyDashboardDraftFilter: DashboardFilter = JSON.parse(
            JSON.stringify(dashboardDisplayStore.dashboardDraftFilter)
        );

        dashboardDisplayStore.filtersStatusMode = "DRAFT_MODE";

        copyDashboardDraftFilter.initiators = updatedInitiatorsList;
        dashboardDisplayStore.dashboardDraftFilter = copyDashboardDraftFilter;
    }
);

export const addInitiatorsToList = action((uuidsList: UUID[]) => {
    const copyDashboardSelectedUuids: UUID[] = JSON.parse(
        JSON.stringify(dashboardDisplayStore.selectedInitiatorsList)
    );

    uuidsList.forEach((_uuid) => {
        if (!copyDashboardSelectedUuids.includes(_uuid)) {
            copyDashboardSelectedUuids.push(_uuid);
        }
    });

    dashboardDisplayStore.filtersStatusMode = "DRAFT_MODE";

    dashboardDisplayStore.selectedInitiatorsList = copyDashboardSelectedUuids;
});

export const clearAllInitiatorsFromList = action(() => {
    dashboardDisplayStore.selectedInitiatorsList = [];
});

export const clearInitiatorsFilter = action(() => {
    const copyDashboardDraftFilter: DashboardFilter = JSON.parse(
        JSON.stringify(dashboardDisplayStore.dashboardDraftFilter)
    );

    dashboardDisplayStore.filtersStatusMode = "DRAFT_MODE";
    copyDashboardDraftFilter.initiators = [];

    dashboardDisplayStore.selectedInitiatorsList = [];
    dashboardDisplayStore.dashboardDraftFilter = copyDashboardDraftFilter;
});

export const setFilterStatusModeToReady = action(() => {
    dashboardDisplayStore.filtersStatusMode = "READY_MODE";
});

export const openInitiatorListPopup = action(() => {
    const { dashboardDraftFilter } = dashboardDisplayStore;

    if (dashboardDraftFilter?.initiators.length) {
        addInitiatorsToList(dashboardDraftFilter?.initiators);
    }

    dashboardDisplayStore.isOpenedInitiatorListPopup = true;
});

export const closeInitiatorListPopup = action(() => {
    dashboardDisplayStore.isOpenedInitiatorListPopup = false;
});

export const setDraftFiltersToReady = action(() => {
    const { dashboardDraftFilter } = dashboardDisplayStore;

    const copyDashboardReadyFilter: DashboardFilter = JSON.parse(
        JSON.stringify(dashboardDisplayStore.dashboardReadyFilter)
    );

    if (dashboardDraftFilter) {
        copyDashboardReadyFilter.initiators = dashboardDraftFilter.initiators;
        copyDashboardReadyFilter.startDate = dashboardDraftFilter.startDate;
        copyDashboardReadyFilter.endDate = dashboardDraftFilter.endDate;
    }

    dashboardDisplayStore.dashboardReadyFilter = copyDashboardReadyFilter;
});

export const setViewFilterViewMode = action((newViewMode: ViewMode) => {
    dashboardDisplayStore.filterViewMode = newViewMode;
});

export const setDashboardSortingProfile = action(
    (newSortingProfile: DashboardSortingProfile) => {
        dashboardDisplayStore.sortingProfile = newSortingProfile;
    }
);

export const changeShowOnlyActiveSurveys = action(() => {
    dashboardDisplayStore.isShowOnlyActiveSurveys =
        !dashboardDisplayStore.isShowOnlyActiveSurveys;
});
