import React from "react";
import { observer } from "mobx-react";

import { updateSelectedUuidsToDashboardFilter } from "@difftone/actions";
import { dashboardDisplayStore } from "@difftone/stores";

import { filter_button } from "./initiators-filter-button.module.css";

export type InitiatorsFilterButtonProps = {
    onClose: () => void;
};

export const InitiatorsFilterButton = observer(
    (props: InitiatorsFilterButtonProps) => {
        const { selectedInitiatorsList } = dashboardDisplayStore;
        const { onClose } = props;

        const applyChangesToFilter = () => {
            updateSelectedUuidsToDashboardFilter(selectedInitiatorsList);
            onClose();
        };

        return (
            <div className={filter_button}>
                <button onClick={applyChangesToFilter}>Apply</button>
            </div>
        );
    }
);
