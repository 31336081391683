import {
    Tag,
    ValidationResult,
    TagNameValidationType
} from "@difftone/types";

export const validateTagName = (tag: Tag): ValidationResult<TagNameValidationType>[] => {
    return (!tag.name || !tag.name.trim()) ?
        [{
            is_valid: false,
            validation_error_code: "TAG_NAME_VALIDATION",
            validation_error_type: { tag_uuid: tag.uuid }
        }] :
        [{
            is_valid: true,
            validation_error_code: "TAG_NAME_VALIDATION",
            validation_error_type: { tag_uuid: tag.uuid }
        }];
} 