import {
    Organization,
    Domain,
    UUID,
    EmailAddress,
    OrganizationSettings,
} from "@difftone/types";
import { getApiBaseUrl } from "@difftone/constants";
import { errorHandlerProxy, localstorageUtils } from "@difftone/procedures";
import { createDifftoneAxios } from "@difftone/services";

const SERVER_DOMAIN_BY_UUID_URL = `${getApiBaseUrl()}/organizations-service/domains-by-uuids`;
const SERVER_PUBLIC_DOMAIN_URL = `${getApiBaseUrl()}/organizations-service/all-public-domains`;
const SERVER_ORGANIZATION_BY_EMAIL_URL = `${getApiBaseUrl()}/organizations-service/email`;
const SERVER_ORGANIZATION_SETTINGS_URL = `${getApiBaseUrl()}/organizations-service/update-organization-settings`;

export const getDomainsByUuidsFromApi = async (
    domainUUIDs: UUID[]
): Promise<Domain[]> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(SERVER_DOMAIN_BY_UUID_URL, {
        params: {
            issuer: currentAuthData.issuer,
            domain_uuids: JSON.stringify(domainUUIDs),
        },
    });

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    return response.data.resources;
};

export const getPublicDomainsFromApi = async () => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(SERVER_PUBLIC_DOMAIN_URL, {
        params: { issuer: currentAuthData.issuer },
    });
    return response;
};

export const getUserOrganizations = async (): Promise<Organization[]> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(SERVER_ORGANIZATION_BY_EMAIL_URL, {
        params: {
            issuer: currentAuthData.issuer,
        },
    });

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    return response.data.resources;
};

export const updateOrganisationSettingsInternalService = async (
    settings: OrganizationSettings,
    uuid: UUID
): Promise<Organization> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.put(
        SERVER_ORGANIZATION_SETTINGS_URL,
        {
            organization_uuid: uuid,
            organization_settings: settings,
        },
        {
            params: { issuer: currentAuthData.issuer },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    return response.data.resources;
};
