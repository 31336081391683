import { UUID } from "@difftone/types";
import { makeAutoObservable } from "mobx";

class SharePanelStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _activeSurveyUUID: UUID | null = null;
    get activeSurveyUUID(): UUID | null {
        return this._activeSurveyUUID;
    }
    set activeSurveyUUID(newValue: UUID | null) {
        this._activeSurveyUUID = newValue;
    }

    private _isOpenSurvey: boolean = false;
    get isOpenSurvey() {
        return this._isOpenSurvey;
    }

    set isOpenSurvey(isOpen: boolean) {
        this._isOpenSurvey = isOpen;
    }

    private _title: string | null = null;
    get title(): string | null {
        return this._title;
    }
    set title(newValue: string | null) {
        this._title = newValue;
    }

    public clearStore = () => {
        this._activeSurveyUUID = null;
        this._title = null;
        this._isOpenSurvey = false;
    };
}

export const sharePanelStore = new SharePanelStore();
