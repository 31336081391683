import React from "react";

import { SurveyClasses } from "@difftone/types";
import { UiStatus } from "@difftone/frontend-common";
import { getUserEmail } from "@difftone/procedures";

import fillSurveyIcon from "@difftone/assets/fill-survey-icon.svg";

import { fill_icon_wrapper, opacity } from "./permissions-status.module.css";

export type PermissionsStatusProps = {
    survey: SurveyClasses;
    surveyStatus: UiStatus;
};

export const PermissionsStatus = (props: PermissionsStatusProps) => {
    const { survey, surveyStatus } = props;

    if (
        survey.respondents.includes(getUserEmail()) &&
        survey.status !== "DRAFT"
    ) {
        return (
            <div
                className={
                    surveyStatus === "ENDED"
                        ? [fill_icon_wrapper, opacity].join(" ")
                        : fill_icon_wrapper
                }
            >
                <img src={fillSurveyIcon} alt="fill" />
            </div>
        );
    } else return null;
};
