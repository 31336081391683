import React from "react";

import { getUserFullName } from "@difftone/procedures";
import { NameWithTooltip } from "@difftone/shared-components";
import { BaseSurvey } from "@difftone/types";

import {
    popup_survey_info,
    survey_name_container,
    highlight,
    survey_name,
} from "./popup-survey-info.module.css";

export type PopupSurveyInfoProps = {
    survey: BaseSurvey;
};

export const PopupSurveyInfo = (props: PopupSurveyInfoProps) => {
    const { survey } = props;
    return (
        <div className={popup_survey_info}>
            <div className={survey_name_container}>
                <span className={highlight}>Survey name:&nbsp;</span>
                <div className={survey_name}>
                    <NameWithTooltip text={survey.survey_name} />
                </div>
            </div>
            <div>
                <span className={highlight}>Initiator: </span>
                {getUserFullName()}
            </div>
            <div>
                <span className={highlight}>Purpose: </span>
                {survey.purpose.toLowerCase()}
            </div>
            <div>
                <span className={highlight}>Anonymity: </span>
                {survey.anonimity.toLowerCase()}
            </div>
        </div>
    );
};
