import { action } from "mobx";
import { sharedSurveyDisplayStore } from "@difftone/stores";

export const setCollapsedDetails = action(() => {
    const { collapsedDetails } = sharedSurveyDisplayStore;
    sharedSurveyDisplayStore.collapsedDetails = !collapsedDetails;
});

export const setCollapsedDates = action(() => {
    const { collapsedDates } = sharedSurveyDisplayStore;
    sharedSurveyDisplayStore.collapsedDates = !collapsedDates;
});

export const setCollapsedQuestions = action(() => {
    const { collapsedQuestions } = sharedSurveyDisplayStore;
    sharedSurveyDisplayStore.collapsedQuestions = !collapsedQuestions;
});
