import {
    Answer,
    AnswerType,
    DateAnswer,
    LongTextAnswer,
    MultipleChoiceAnswer,
    RatingAnswer,
    RepsonseAnswersWithValidContentValidationType,
    ShortTextAnswer,
    SingleChoiceAnswer,
    SurveyClasses,
    SurveyResponse,
    TimeAnswer,
    ValidationResult,
    ValidationType,
} from "@difftone/types";

import {
    validateComplicatedResponseRequiredAnswers,
    validateSimpleResponseRequiredAnswers,
} from "./required-answer-validations";

const MINIMUM_RATING_VALUE = 0;

//ANSWER
export const validateAnswer = (
    survey: SurveyClasses,
    answer: Answer<AnswerType>
): ValidationResult<RepsonseAnswersWithValidContentValidationType>[] => {
    let res = true;

    const question = survey.questions.find(
        (question) => question.uuid === answer.question_uuid
    );

    if (question && question.required) {
        switch (answer.answer_type_name) {
            case "DATE":
                const dateAnswer = answer as Answer<DateAnswer>;
                if (!dateAnswer.content.date) {
                    res = false;
                }
                break;
            case "TIME":
                const timeAnswer = answer as Answer<TimeAnswer>;
                if (!timeAnswer.content.time) {
                    res = false;
                }
                break;
            case "LONG_TEXT":
                const longTextAnswer = answer as Answer<LongTextAnswer>;
                if (!longTextAnswer.content.text) {
                    res = false;
                }
                break;
            case "SHORT_TEXT":
                const shortTextAnswer = answer as Answer<ShortTextAnswer>;
                if (!shortTextAnswer.content.text) {
                    res = false;
                }
                break;
            case "MULTIPLE_CHOICE":
                const multipleChoiceAnswer =
                    answer as Answer<MultipleChoiceAnswer>;
                const choices = multipleChoiceAnswer.content.choices;
                res = Object.keys(choices).some(
                    (choiceAsKey) => choices[choiceAsKey]
                );
                break;
            case "SINGLE_CHOICE":
                const singleChoiceAnswer = answer as Answer<SingleChoiceAnswer>;
                if (!singleChoiceAnswer.content.choice) {
                    res = false;
                }
                break;
            case "RATING":
                const ratingAnswer = answer as Answer<RatingAnswer>;
                if (
                    !ratingAnswer.content.label ||
                    ratingAnswer.content.value < MINIMUM_RATING_VALUE
                ) {
                    res = false;
                }
                break;
            default:
                break;
        }
    }

    return [
        {
            is_valid: res,
            validation_error_code: "RESPONSE_ANSWER_CONTENT_VALIDATION",
            validation_error_type: { question_uuid: answer.question_uuid },
        },
    ];
};

export const validateNonRequiredQuestionAnswers = (
    survey: SurveyClasses,
    answer: Answer<AnswerType>
) => {
    let res = true;

    const question = survey.questions.find(
        (question) => question.uuid === answer.question_uuid
    );

    if (question && !question.required) {
        switch (answer.answer_type_name) {
            case "DATE":
                const dateAnswer = answer as Answer<DateAnswer>;
                if (!dateAnswer.content.date) {
                    res = false;
                }
                break;
            case "TIME":
                const timeAnswer = answer as Answer<TimeAnswer>;
                if (!timeAnswer.content.time) {
                    res = false;
                }
                break;
            case "LONG_TEXT":
                const longTextAnswer = answer as Answer<LongTextAnswer>;
                if (!longTextAnswer.content.text) {
                    res = false;
                }
                break;
            case "SHORT_TEXT":
                const shortTextAnswer = answer as Answer<ShortTextAnswer>;
                if (!shortTextAnswer.content.text) {
                    res = false;
                }
                break;
            case "MULTIPLE_CHOICE":
                const multipleChoiceAnswer =
                    answer as Answer<MultipleChoiceAnswer>;
                const choices = multipleChoiceAnswer.content.choices;
                res = Object.keys(choices).some(
                    (choiceAsKey) => choices[choiceAsKey]
                );
                break;
            case "SINGLE_CHOICE":
                const singleChoiceAnswer = answer as Answer<SingleChoiceAnswer>;
                if (!singleChoiceAnswer.content.choice) {
                    res = false;
                }
                break;
            case "RATING":
                const ratingAnswer = answer as Answer<RatingAnswer>;
                if (
                    !ratingAnswer.content.label ||
                    ratingAnswer.content.value < MINIMUM_RATING_VALUE
                ) {
                    res = false;
                }
                break;
            default:
                break;
        }
    }

    return [
        {
            is_valid: res,
            validation_error_code: "RESPONSE_ANSWER_CONTENT_VALIDATION",
            validation_error_type: { question_uuid: answer.question_uuid },
        },
    ];
};

//FILTER QUESTION ANSWER
export const validateFilterQuestionAnswer = (
    answer: Answer<AnswerType>
): ValidationResult<RepsonseAnswersWithValidContentValidationType>[] => {
    let res = true;

    switch (answer.answer_type_name) {
        case "DATE":
            const dateAnswer = answer as Answer<DateAnswer>;
            if (!dateAnswer.content.date) {
                res = false;
            }
            break;
        case "TIME":
            const timeAnswer = answer as Answer<TimeAnswer>;
            if (!timeAnswer.content.time) {
                res = false;
            }
            break;
        case "LONG_TEXT":
            const longTextAnswer = answer as Answer<LongTextAnswer>;
            if (!longTextAnswer.content.text) {
                res = false;
            }
            break;
        case "SHORT_TEXT":
            const shortTextAnswer = answer as Answer<ShortTextAnswer>;
            if (!shortTextAnswer.content.text) {
                res = false;
            }
            break;
        case "MULTIPLE_CHOICE":
            const multipleChoiceAnswer = answer as Answer<MultipleChoiceAnswer>;
            const choices = multipleChoiceAnswer.content.choices;
            res = Object.keys(choices).some(
                (choiceAsKey) => choices[choiceAsKey]
            );
            break;
        case "SINGLE_CHOICE":
            const singleChoiceAnswer = answer as Answer<SingleChoiceAnswer>;
            if (!singleChoiceAnswer.content.choice) {
                res = false;
            }
            break;
        case "RATING":
            const ratingAnswer = answer as Answer<RatingAnswer>;
            if (
                !ratingAnswer.content.label ||
                ratingAnswer.content.value < MINIMUM_RATING_VALUE
            ) {
                res = false;
            }
            break;
        default:
            break;
    }

    return [
        {
            is_valid: res,
            validation_error_code: "RESPONSE_ANSWER_CONTENT_VALIDATION",
            validation_error_type: { question_uuid: answer.question_uuid },
        },
    ];
};

//ANSWERS
export const validateComplicatedSurveyResponseAnswers = (
    survey: SurveyClasses,
    surveyResponse: SurveyResponse
): ValidationResult<ValidationType>[] => {
    const res: ValidationResult<ValidationType>[] = [];

    surveyResponse.answers.forEach((answer) =>
        res.push(...validateAnswer(survey, answer))
    );

    surveyResponse.attribute_answers.forEach((filterQuestionAnswer) =>
        res.push(...validateFilterQuestionAnswer(filterQuestionAnswer))
    );

    return res;
};

export const validateSimpleSurveyResponseAnswers = (
    survey: SurveyClasses,
    surveyResponse: SurveyResponse
): ValidationResult<ValidationType>[] => {
    const res: ValidationResult<ValidationType>[] = [];

    surveyResponse.answers.forEach((answer) =>
        res.push(...validateAnswer(survey, answer))
    );

    return res;
};

//RESPONSE
export const validateResponse = (
    survey: SurveyClasses,
    surveyResponse: SurveyResponse
): ValidationResult<ValidationType>[] => {
    const res: ValidationResult<ValidationType>[] = [];

    const filterQuestions = survey.questions.filter(
        (question) => question.is_filter
    );

    if (filterQuestions.length) {
        res.push(
            ...validateComplicatedSurveyResponseAnswers(survey, surveyResponse)
        );
        res.push(
            ...validateComplicatedResponseRequiredAnswers(
                survey,
                surveyResponse
            )
        );
    } else {
        res.push(
            ...validateSimpleSurveyResponseAnswers(survey, surveyResponse)
        );
        res.push(
            ...validateSimpleResponseRequiredAnswers(survey, surveyResponse)
        );
    }

    return res;
};
