import { makeAutoObservable } from "mobx";

class NavigationStore {
    constructor() {
        makeAutoObservable(this);
        window.addEventListener("popstate", this.onOpopstate);
    }

    private _protocol: string = "";
    private _port: string = "";
    private _host: string = "";
    private _pathname: string = "";
    private _search: string = "";

    get url() {
        return `${this._protocol}${this._host}${this._pathname}`;
    }

    get currentSearch() {
        return this._search;
    }

    set currentSearch(newSearch: string) {
        this._search = newSearch ? `?${newSearch}` : "";
    }

    get currentPage() {
        return this._pathname;
    }

    set currentPage(newPageName: string) {
        this._pathname = newPageName;
        this.updateBrowserUrl();
    }

    // Break lazy loading
    public init() {
        this.loadUrlFromBrowser();
    }

    public clearStore = () => {};

    private updateBrowserUrl() {
        window.history.pushState(
            {},
            this._pathname,
            `${this._pathname}${this._search}`
        );
    }

    private loadUrlFromBrowser = () => {
        this._protocol = window.location.protocol;
        this._port = window.location.port;
        this._host = window.location.host;
        this._pathname = window.location.pathname;
        this._search = window.location.search;
    };

    // Utils
    private onOpopstate = () => {
        this.loadUrlFromBrowser();

        // if in includes /information, than there was an attempt to go back
        // but right menu window was opened, so should go back twice
        // ( first one removes /right-menu-section route, second goes back from /information )
        if (window.location.pathname.includes("/information")) {
            window.history.go(-2);
        }
    };
}

export const navigationStore = new NavigationStore();
