import React, { useState } from "react";
import { observer } from "mobx-react";

import {
    inboxDisplayStore,
    organizationDisplayStore,
    templatesStore,
} from "@difftone/stores";
import {
    closePermissionsDialog,
    closeCategoriesDialog,
    closeManageTemplatesDialog,
    closeImportGoogleFormDialog,
} from "@difftone/actions";
import { getUserEmail, getUserFullName } from "@difftone/procedures";
import { AddBoxShadow } from "@difftone/shared-components";

import {
    UserMenu,
    PermissionsDialog,
    ManageCategoriesDialog,
    ManageTemplatesDialog,
    ImportGoogleFormDialog,
} from "./components";

import {
    user_label,
    user_acronym,
    name_and_email,
    full_name,
    email_span,
} from "./user-label.module.css";

export const UserLabel = observer(() => {
    const { isPermissionsDialogOpened, isCategoriesDialogOpened } =
        organizationDisplayStore;
    const { isManageTemplatesDialogOpened } = templatesStore;
    const { isImportGoogleFormDialogOpened } = inboxDisplayStore;
    const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);

    const fullName = getUserFullName().split(" ");

    const givenNameInitial: string = fullName[0]?.charAt(0) || "f";
    const familyNameInitial: string = fullName[1]?.charAt(0) || "f";

    const initials =
        `${givenNameInitial}${familyNameInitial}`.toLocaleUpperCase();

    const handleShowUserMenu = () => {
        if (!isPermissionsDialogOpened && !isCategoriesDialogOpened) {
            setIsUserMenuVisible(true);
        }
    };

    const handleHideUserMenu = () => {
        setIsUserMenuVisible(false);
    };

    return (
        <div
            tabIndex={0}
            onBlur={handleHideUserMenu}
            onClick={handleShowUserMenu}
            className={user_label}
        >
            <div className={user_acronym}>{initials}</div>
            <div className={name_and_email}>
                <span className={full_name}>{getUserFullName()}</span>
                <span className={email_span}>{getUserEmail()}</span>
            </div>
            {isUserMenuVisible ? (
                <AddBoxShadow>
                    <UserMenu className="" />
                </AddBoxShadow>
            ) : null}

            <PermissionsDialog
                open={isPermissionsDialogOpened}
                onClose={closePermissionsDialog}
            />
            <ImportGoogleFormDialog
                open={isImportGoogleFormDialogOpened}
                onClose={closeImportGoogleFormDialog}
            />

            <ManageCategoriesDialog
                open={isCategoriesDialogOpened}
                onClose={closeCategoriesDialog}
            />
            <ManageTemplatesDialog
                onClose={closeManageTemplatesDialog}
                open={isManageTemplatesDialogOpened}
            />
        </div>
    );
});
