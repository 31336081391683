import { getDateAtMidnightFromDateString } from "@difftone/time-utils";
import {
    BaseSurvey,
    PieChartPurpose,
    SimpleSurvey,
    SurveyClasses,
} from "@difftone/types";
import {
    getStatus,
    getSurveyResponseData,
    sortDashboardSurveysByEndData,
    sortDashboardSurveysByResponseRate,
    sortDashboardSurveysBySurveyName,
} from "@difftone/reducers";
import { dashboardDisplayStore, surveyStore } from "@difftone/stores";

const dashboardSurveyListToDisplayList = (
    surveyList: SurveyClasses[]
): SurveyClasses[] => {
    const { dashboardReadyFilter } = dashboardDisplayStore;

    return surveyList.filter((survey) => {
        if (dashboardReadyFilter?.initiators.includes(survey.initiator)) {
            const filterStartDateInMils = getDateAtMidnightFromDateString(
                dashboardReadyFilter.startDate
            );

            const filterEndDateInMils = getDateAtMidnightFromDateString(
                dashboardReadyFilter.endDate
            );

            switch (survey.survey_class) {
                case "ONGOING":
                    if (
                        survey.status === "ACTIVE" &&
                        survey.created_at >= filterStartDateInMils
                    ) {
                        return survey;
                    }
                    break;
                case "SIMPLE":
                default:
                    const surveyPublishedDate = survey.survey_events.find(
                        (surveyEvent) =>
                            surveyEvent.system_survey_event_type ===
                            "PUBLISHED_DATE"
                    );

                    const surveyStartTime = getDateAtMidnightFromDateString(
                        surveyPublishedDate?.date_utc!
                    );
                    if (
                        surveyStartTime >= filterStartDateInMils &&
                        surveyStartTime <= filterEndDateInMils
                    ) {
                        return survey;
                    }
            }
        }
    });
};

export const dashboardFilteredSurveys = () => {
    const dashboardSurveyList = dashboardSurveyListToDisplayList(
        surveyStore.surveysList
    );
    return dashboardSurveyList;
};

export const dashboardTimelineSurveys = (surveyList: SurveyClasses[]) => {
    return surveyList.filter((_survey) => {
        const status = getStatus(_survey);

        if (!dashboardDisplayStore.isShowOnlyActiveSurveys) {
            if (
                status === "ACTIVE" ||
                status === "COMPLETED" ||
                status === "ENDED" ||
                status === "SAVED"
            ) {
                return true;
            }
        } else {
            if (status === "ACTIVE") {
                return true;
            }
        }
    });
};

export const dashboardSurveyTableList = (surveyList: SurveyClasses[]) => {
    return surveyList.filter((_survey) => {
        const status = getStatus(_survey);
        if (!dashboardDisplayStore.isShowOnlyActiveSurveys) {
            if (
                status === "ACTIVE" ||
                status === "COMPLETED" ||
                status === "ENDED"
            ) {
                return true;
            }
        } else {
            if (status === "ACTIVE") {
                return true;
            }
        }
    });
};

export const getCountAllQuestions = (surveyList: BaseSurvey[]): string => {
    const total: number = surveyList.reduce(
        (totalNumOfSurveys: number, survey: BaseSurvey) => {
            return (totalNumOfSurveys += survey.questions.length);
        },
        0
    );

    return total.toString();
};

export const getAverageResponseRate = (surveyList: BaseSurvey[]): string => {
    const totalSurveysLength = surveyList.length;
    const percentOfSurveys = surveyList.reduce(
        (totalResponseRate: number, survey: BaseSurvey) => {
            const surveyResponseData = getSurveyResponseData(survey);
            const averageResponse =
                (surveyResponseData.completed * 100) /
                    surveyResponseData.total || 0;
            totalResponseRate += averageResponse / totalSurveysLength;
            return totalResponseRate;
        },
        0
    );
    return Number.isFinite(percentOfSurveys) && !Number.isNaN(percentOfSurveys)
        ? `${percentOfSurveys.toFixed(0)}%`
        : "0%";
};

export const getAverageNumOfParticipants = (
    surveysList: BaseSurvey[]
): string => {
    const listOfNumRespondents = surveysList.map(
        (survey) => survey.respondents.length
    );
    const average =
        listOfNumRespondents.reduce((a, b) => a + b, 0) /
        listOfNumRespondents.length;

    return Number.isFinite(average) && !Number.isNaN(average)
        ? `${average.toFixed(0)}`
        : "0";
};

const getSortedSurveys = (surveysList: SurveyClasses[]) => {
    const results = [...surveysList];
    const sortBy = dashboardDisplayStore.sortingProfile.sortBy;
    const ascending = dashboardDisplayStore.sortingProfile.ascending;

    switch (sortBy) {
        case "NAME":
            return sortDashboardSurveysBySurveyName(ascending, results);
        case "END":
            return sortDashboardSurveysByEndData(ascending, results);
        case "OVERALL_RESPONSES":
        case "RESPONSE_RATE":
            return sortDashboardSurveysByResponseRate(ascending, results);
        default:
            return results;
    }
};

export const filteredSurveysDashboard = () => {
    const displaySurveys = dashboardSurveyTableList(dashboardFilteredSurveys());
    const sortedFilteredList = getSortedSurveys(displaySurveys);
    return sortedFilteredList;
};

export const getColorAndValue = (key: string): PieChartPurpose => {
    switch (key) {
        case "ENGAGEMENT":
            return {
                value: "Engagement",
                color: "#035375",
            };
        case "FEEDBACK":
            return {
                value: "Feedback",
                color: "#009788",
            };
        case "DISCUSSION":
            return {
                value: "Evoke Discussion",
                color: "#ACD2CE",
            };
        case "OTHER":
            return {
                value: "Other",
                color: "#6DA1DF",
            };
        default:
            return {
                value: "Other",
                color: "#6DA1DF",
            };
    }
};
