import React from "react";
import { observer } from "mobx-react";
import {
    createAndUpdateTimeCompareInGraph,
    selectDisplayTimeCompareInPopup,
    setLocation,
    setSelectedTimeCompareFilterAndTimeUnits,
    showDifftoneAlert,
} from "@difftone/actions";
import {
    constantAlertVariants,
    PAGE_URLS,
    TIME_FILTER_ID,
    warningMessages,
} from "@difftone/constants";
import {
    createDataCategoriesToGraph,
    createDataQuestionsToGraph,
    formatCategoriesQuestionsMap,
    formatCategoriesResultsRatingMap,
    getNonFilterQuestionsFromSurvey,
    getSortedCategoriesList,
    sortQuestionsByType,
} from "@difftone/reducers";
import {
    QuestionToAnswerDescreteQuestion,
    SurveyClasses,
    UUID,
} from "@difftone/types";
import { Spinner, Graph } from "@difftone/shared-components";

import {
    categoriesDisplayStore,
    resultsDisplayStore,
    resultsDisplayFilterStore,
    resultsMapStore,
    sharedResultStore,
    surveyResponsesSummaryStore,
    timeRangesStore,
} from "@difftone/stores";
import { COLUMN_0, DATA_ANONYMIZED } from "@difftone/common-constants";
import { favorable_anonimity_no_data } from "./results-graph-spreadsheet.module.css";

const MESSAGE_ANONYMITY = "Data cannot be shown due to anonymity limitations";

export type ResultsGraphSpreadsheetProps = { selectedSurvey: SurveyClasses };

export const ResultsGraphSpreadsheet = observer(
    (props: ResultsGraphSpreadsheetProps) => {
        const { selectedSurvey } = props;
        const { categoriesQuestionsDisplayModeToggle } = resultsDisplayStore;
        const { compareBy, selectedTimeCompare } = resultsDisplayFilterStore;
        const { targetSharedResult } = sharedResultStore;

        const { getResultsTableByResultsTableMapKey, extractKeyForTable } =
            resultsMapStore;
        const { extractKeyForTimeRange, getTimeRangeByKey } = timeRangesStore;

        const surveyResponses =
            surveyResponsesSummaryStore.getSurveyResponseSummaryByFilterPlain(
                selectedSurvey.uuid,
                []
            );

        if (surveyResponses === undefined) {
            return <Spinner size="LARGE" />;
        }

        if (surveyResponses === null) {
            setLocation(PAGE_URLS.INBOX_ALL);
            showDifftoneAlert(
                warningMessages.surveyResponseNotFound,
                "FAILURE"
            );
            return null;
        }

        const timeRangeKey = extractKeyForTimeRange(
            selectedSurvey.uuid,
            "MONTHS"
        );

        const timeRanges = getTimeRangeByKey(timeRangeKey);

        if (timeRanges === undefined) {
            return <Spinner size="SMALL" />;
        }

        if (timeRanges === null) {
            showDifftoneAlert(
                "Something went wrong, please reload and try again",
                "FAILURE"
            );
            return null;
        }

        let timeFilterOnly = compareBy.find((filter) =>
            filter.filterKey.uuid.includes(TIME_FILTER_ID)
        );

        const filtersNotActiveAsComparesWithoutTimeFilter = compareBy.filter(
            (filter) => filter.filterKey.uuid !== TIME_FILTER_ID
        );

        if (!timeFilterOnly) {
            timeFilterOnly = createAndUpdateTimeCompareInGraph(timeRanges);
        }

        const _keyForTable = extractKeyForTable(
            selectedSurvey,
            filtersNotActiveAsComparesWithoutTimeFilter,
            [timeFilterOnly],
            targetSharedResult
        );

        const resultsRowByTimeFilter =
            getResultsTableByResultsTableMapKey(_keyForTable);

        if (resultsRowByTimeFilter === undefined) {
            return <Spinner size="LARGE" />;
        }

        if (resultsRowByTimeFilter === null) {
            setLocation(PAGE_URLS.INBOX_ALL);
            showDifftoneAlert(
                constantAlertVariants.survey_results_failure.text,
                constantAlertVariants.survey_results_failure.variant
            );
            return null;
        }

        const categoriesQuestionsMap = formatCategoriesQuestionsMap(
            selectedSurvey.questions || []
        );

        const categoriesList: UUID[] = getSortedCategoriesList(
            categoriesQuestionsMap
        );

        if (!categoriesDisplayStore.isStoreInitialized) {
            categoriesDisplayStore.initCategoriesDisplayMap(categoriesList);
            return <Spinner size="LARGE" />;
        }

        const questions = sortQuestionsByType(
            getNonFilterQuestionsFromSurvey(selectedSurvey)
        );

        const resultsByQuestionUuidArray = questions.map(
            (question) => resultsRowByTimeFilter[question.uuid][COLUMN_0]
        ) as QuestionToAnswerDescreteQuestion[];

        const isAnonymous = resultsByQuestionUuidArray.every(
            (resultQuestion) => resultQuestion.toString() === DATA_ANONYMIZED
        );

        const categoriesResultsMap = formatCategoriesResultsRatingMap(
            categoriesQuestionsMap,
            resultsRowByTimeFilter
        );

        const ratingQuestionsUuid = sortQuestionsByType(
            getNonFilterQuestionsFromSurvey(selectedSurvey)
        ).reduce((uuids: UUID[], question) => {
            if (question.question_type_name === "RATING")
                uuids.push(question.uuid);
            return uuids;
        }, []);

        const ratingQuestionsRowsMap: { [key: string]: any } = {};
        ratingQuestionsUuid.forEach((questionUuid) => {
            ratingQuestionsRowsMap[questionUuid] =
                resultsRowByTimeFilter[questionUuid];
        });

        if (selectedTimeCompare === "NONE") {
            selectDisplayTimeCompareInPopup("MONTHS");
            setSelectedTimeCompareFilterAndTimeUnits(
                "MONTHS",
                timeFilterOnly.filterKey.uuid,
                timeRanges
            );
        }

        switch (categoriesQuestionsDisplayModeToggle) {
            case "QUESTIONS":
                const questions = createDataQuestionsToGraph(
                    ratingQuestionsRowsMap,
                    timeFilterOnly,
                    selectedSurvey
                );

                return !isAnonymous ? (
                    <Graph
                        dataGraph={questions.dataGraph}
                        sizeAxisLeft={questions.sizeAxisLeft}
                    />
                ) : (
                    <div className={favorable_anonimity_no_data}>
                        {MESSAGE_ANONYMITY}
                    </div>
                );

            case "CATEGORIES":
            default:
                const categories = createDataCategoriesToGraph(
                    categoriesResultsMap,
                    timeFilterOnly,
                    categoriesQuestionsMap
                );

                return !isAnonymous ? (
                    <Graph
                        dataGraph={categories.dataGraph}
                        sizeAxisLeft={categories.sizeAxisLeft}
                    />
                ) : (
                    <div className={favorable_anonimity_no_data}>
                        {MESSAGE_ANONYMITY}
                    </div>
                );
        }
    }
);
