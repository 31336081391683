import React, { MouseEventHandler, MouseEvent, useEffect } from "react";
import { observer } from "mobx-react";

import { loginStore } from "@difftone/stores";
import {
    setIsGoogleButtonClicked,
    setGoogleButtonClickEvent,
} from "@difftone/actions";

import {
    login_button,
    clicked_button_effect,
} from "./google-design.module.css";

type GooglePropsType = {
    onClick: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean | undefined;
};

export type GoogleButtonProps = {
    googleRenderPropsObject: GooglePropsType;
    googleErr: Object | null;
};

export const GoogleStyle = observer((props: GoogleButtonProps) => {
    const { googleRenderPropsObject, googleErr } = props;
    const { isGoogleButtonClicked, googleButtonClickEvent } = loginStore;
    const { disabled, onClick } = googleRenderPropsObject;

    const onGoogleSignInClicked = (event: MouseEvent<HTMLButtonElement>) => {
        setIsGoogleButtonClicked(true);
        setGoogleButtonClickEvent(event);
        if (!disabled) {
            onClick(event);
        }
    };

    useEffect(() => {
        if (googleErr) {
            setIsGoogleButtonClicked(false);
        }
    }, [googleErr]);

    useEffect(() => {
        if (!disabled && isGoogleButtonClicked && googleButtonClickEvent) {
            onClick(googleButtonClickEvent);
        }
        //eslint-disable-next-line
    }, [disabled]);

    return (
        <button
            onClick={onGoogleSignInClicked}
            className={
                isGoogleButtonClicked
                    ? [clicked_button_effect, login_button].join(" ")
                    : login_button
            }
        >
            <div>
                <svg
                    width="18"
                    height="18"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0)">
                        <path
                            d="M22.9131 11.7152C22.9131 10.7729 22.8368 10.0852 22.6717 9.37215H11.9358V13.6252H18.2375C18.1105 14.6822 17.4244 16.2739 15.8998 17.3435L15.8784 17.4859L19.2729 20.1215L19.5081 20.145C21.6679 18.1458 22.9131 15.2042 22.9131 11.7152Z"
                            fill="#4285F4"
                        />
                        <path
                            d="M11.9359 22.921C15.0232 22.921 17.615 21.9023 19.5082 20.145L15.8999 17.3435C14.9343 18.0184 13.6383 18.4896 11.9359 18.4896C8.91203 18.4896 6.3456 16.4904 5.43073 13.7271L5.29664 13.7385L1.76698 16.4763L1.72083 16.605C3.60118 20.3487 7.46358 22.921 11.9359 22.921Z"
                            fill="#34A853"
                        />
                        <path
                            d="M5.4308 13.7271C5.1894 13.0141 5.0497 12.25 5.0497 11.4605C5.0497 10.6709 5.1894 9.90694 5.4181 9.19385L5.4117 9.04198L1.83782 6.26021L1.72089 6.31596C0.945908 7.86951 0.501221 9.61409 0.501221 11.4605C0.501221 13.3069 0.945908 15.0514 1.72089 16.6049L5.4308 13.7271Z"
                            fill="#FBBC05"
                        />
                        <path
                            d="M11.9358 4.43136C14.083 4.43136 15.5314 5.36093 16.3572 6.13775L19.5843 2.97973C17.6024 1.13332 15.0232 0 11.9358 0C7.46357 0 3.60118 2.57223 1.72083 6.31596L5.41803 9.19386C6.34559 6.43061 8.91203 4.43136 11.9358 4.43136Z"
                            fill="#EB4335"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect
                                width="22.425"
                                height="23"
                                fill="white"
                                transform="translate(0.5)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div>Sign in with Google</div>
        </button>
    );
});
