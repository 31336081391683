import React from "react";
import clsx from "clsx";

import {
    result_box,
    result_box_wrapper,
    default_class,
    results_deviation_color_minus_3,
    results_deviation_color_minus_2,
    results_deviation_color_minus_1,
    results_deviation_color_0,
    results_deviation_color_1,
    results_deviation_color_2,
    results_deviation_color_3,
    results_overall,
    result_box_content,
} from "./result-box.module.css";

type PalleteObject = {
    [x: string]: string;
};

export type ResultBoxProps = {
    colorIndicator: string;
    resultValue: string | number;
};

export const ResultBox = (props: ResultBoxProps) => {
    const { resultValue, colorIndicator } = props;
    const colorPallete: PalleteObject = {
        OVERALL: results_overall,
        DEFAULT: default_class,
        "-3": results_deviation_color_minus_3,
        "-2": results_deviation_color_minus_2,
        "-1": results_deviation_color_minus_1,
        "0": results_deviation_color_0,
        "1": results_deviation_color_1,
        "2": results_deviation_color_2,
        "3": results_deviation_color_3,
    };

    const _resultValueToDisplay =
        resultValue === null || resultValue === undefined ? "" : resultValue;

    return (
        <div className={result_box_wrapper}>
            <div className={result_box}>
                <div
                    className={clsx(
                        result_box_content,
                        colorPallete[colorIndicator]
                    )}
                >
                    {_resultValueToDisplay}
                </div>
            </div>
        </div>
    );
};
