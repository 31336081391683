import React from "react";

import { Organization } from "@difftone/types";

import { upsertOrganizationForDifftone } from "@difftone/features/organizations-domains/services";
import deleteIcon from "@difftone/assets/delete-survey.svg";
import {
    organizations_scope,
    header_organizations,
    list_organizations,
    remove_organization,
    row_organization,
    name_organization,
} from "./list-organizations.module.css";

export type ListOrganizationsProps = {
    organizations: Organization[];
    onSelectOrganization: (organization: Organization) => void;
};

export const ListOrganizations = (props: ListOrganizationsProps) => {
    const { organizations, onSelectOrganization } = props;

    const onRemoveOrganization = (organization: Organization) => {
        organization.is_deleted = true;
        upsertOrganizationForDifftone(organization);
    };

    return (
        <div className={organizations_scope}>
            <div className={header_organizations}>Organizations list </div>
            <div className={list_organizations}>
                {organizations.map((organization) => (
                    <div className={row_organization} key={organization.uuid}>
                        <div
                            className={name_organization}
                            onClick={() => onSelectOrganization(organization)}
                        >
                            {organization.name}
                        </div>
                        <img
                            onClick={() => onRemoveOrganization(organization)}
                            className={remove_organization}
                            src={deleteIcon}
                            alt="delete-icon"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
