import { action, makeAutoObservable } from "mobx";

class LeftMenuStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _storeInitialize: boolean = false;
    get storeInitialize(): boolean {
        return this._storeInitialize;
    }

    private _startInitialization: boolean = false;
    get startInitialization(): boolean {
        return this._startInitialization;
    }

    private _isMobileOpen: boolean = false;

    get isMobileOpen() {
        return this._isMobileOpen;
    }

    set isMobileOpen(isOpen: boolean) {
        this._isMobileOpen = isOpen;
    }

    private _isOpen: boolean = true;
    get isOpen(): boolean {
        return this._isOpen;
    }
    set isOpen(value: boolean) {
        this._isOpen = value;
    }

    private _firstTimeWindowFlip: boolean = true;
    get firstTimeWindowFlip(): boolean {
        return this._firstTimeWindowFlip;
    }
    set firstTimeWindowFlip(value: boolean) {
        this._firstTimeWindowFlip = value;
    }

    public init = action(() => {
        this._startInitialization = true;
        this._storeInitialize = true;
    });

    public clearStore = () => {
        this._isOpen = true;
        this._firstTimeWindowFlip = true;
        this._startInitialization = false;
        this._storeInitialize = false;
    };
}
export const leftMenuStore = new LeftMenuStore();
