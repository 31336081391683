import { showDifftoneAlert } from "@difftone/actions";
import { localstorageUtils } from "@difftone/procedures";
import { groupEmailsStore } from "@difftone/stores";
import {
    checkIfEmailIsGroupByNames,
    getEmailGroupMembersFromGoogle,
    getEmailGroupMembersFromMicrosoft,
} from "@difftone/services";
import { EmailAddress, Organization } from "@difftone/types";
import { action } from "mobx";
import { isAlertEmailGroup } from "@difftone/reducers";

const getGroupEmailMembers = async (email: EmailAddress) => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();
    if (currentAuthData.issuer === "GOOGLE") {
        const groupMembers = await getEmailGroupMembersFromGoogle(email);
        return groupMembers || null;
    } else if (currentAuthData.issuer === "MICROSOFT") {
        const groupMembers = await getEmailGroupMembersFromMicrosoft(email);
        return groupMembers || null;
    } else {
        return null;
    }
};

const checkIfEmailIsGroup = async (email: EmailAddress): Promise<boolean> => {
    const emailIsGroup = await checkIfEmailIsGroupByNames(email);
    if (emailIsGroup) {
        //TODO: export to constants
        showDifftoneAlert(
            "Group emails are not currently supported",
            "FAILURE"
        );
        return true;
    }
    return false;
};

export const getEmailsToAdd = action(
    (emails: EmailAddress[], organization: Organization): void => {
        const processedEmailPromises = [];
        const processEmail = async (email: EmailAddress) => {
            const key = groupEmailsStore.generateKey(email, organization);
            if (isAlertEmailGroup(email)) {
                groupEmailsStore.setGroupEmailsMap(key, [email]);
                return [email];
            }
            const currentAuthData =
                await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();
            if (
                organization.name === "Difftone" ||
                currentAuthData.issuer === "MICROSOFT"
            ) {
                const groupEmailMembers = await getGroupEmailMembers(email);
                groupEmailsStore.setGroupEmailsMap(
                    key,
                    groupEmailMembers ? groupEmailMembers : [email]
                );
                return groupEmailMembers ? groupEmailMembers : [email];
            } else {
                const groupEmailOther = await checkIfEmailIsGroup(email);
                groupEmailsStore.setGroupEmailsMap(
                    key,
                    groupEmailOther ? null : [email]
                );
                return groupEmailOther ? null : [email];
            }
        };
        for (const email of emails) {
            processedEmailPromises.push(processEmail(email));
        }

        Promise.all(processedEmailPromises);
    }
);
