import React, { ReactNode } from "react";

import {
    wrapper_x_component,
    wrapper_x_component_children,
    wrapper_x_component_svg,
    wrapper_x_header,
    wrapper_x_header_title,
} from "./wrapper-x-component.module.css";

export type WrapperXComponentProps = {
    children: ReactNode;
    onclick: () => void;
    title: string;
};

export const WrapperXComponent = (props: WrapperXComponentProps) => {
    const { children, onclick, title } = props;

    const _onClick = () => {
        onclick();
    };

    return (
        <div data-wrapper-x-component-wrapper className={wrapper_x_component}>
            <div className={wrapper_x_header}>
                <div className={wrapper_x_header_title}>{title}</div>
                <svg
                    className={wrapper_x_component_svg}
                    onClick={_onClick}
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M11.7403 0.7753C11.5745 0.604877 11.3494 0.509101 11.1147 0.509101C10.8799 0.509101 10.6549 0.604877 10.4891 0.7753L6.14981 5.21672L1.81056 0.766199C1.64477 0.595775 1.41969 0.5 1.18496 0.5C0.950233 0.5 0.725151 0.595775 0.559361 0.766199C0.213286 1.12115 0.213286 1.69453 0.559361 2.04948L4.89862 6.49999L0.559361 10.9505C0.213286 11.3055 0.213286 11.8788 0.559361 12.2338C0.905437 12.5887 1.46448 12.5887 1.81056 12.2338L6.14981 7.78327L10.4891 12.2338C10.8351 12.5887 11.3942 12.5887 11.7403 12.2338C12.0863 11.8788 12.0863 11.3055 11.7403 10.9505L7.40101 6.49999L11.7403 2.04948C12.0775 1.70363 12.0775 1.12115 11.7403 0.7753Z"
                        fill="white"
                    />
                </svg>
            </div>
            <div className={wrapper_x_component_children}>{children}</div>
        </div>
    );
};
