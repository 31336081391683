import React from "react";
import dayjs from "dayjs";

import datePickerIcon from "@difftone/assets/date-picker-icon.svg";

import {
    selected_date_time_value,
    selected_date_time_value_disabled,
    date_icon,
    date_icon_disabled,
    date_display,
    time_display,
    data_time_start,
    size_custom,
    size_default,
    size_custom_icon,
    size_default_icon,
} from "./selected-date-display-box.module.css";
import clsx from "clsx";

export type SelectedDateTimeDisplayBoxProps = {
    openCalendar: () => void;
    dateValue: string;
    timeValue: string;
    disabled?: boolean;
    showTime?: boolean;
    timeSchedule?: string;
    isDisableIcon?: boolean;
    isReSize?: boolean;
    isOnlyDateDisplay?: boolean;
};

export const SelectedDateTimeDisplayBox = (
    props: SelectedDateTimeDisplayBoxProps
) => {
    const {
        dateValue,
        timeValue,
        openCalendar,
        disabled,
        showTime = true,
        timeSchedule,
        isDisableIcon,
        isReSize,
        isOnlyDateDisplay,
    } = props;

    const getDesignCorrectDateFormat = () => {
        const correctFormat = dayjs(dateValue).format("MMM DD, YYYY");
        return correctFormat !== "Invalid Date" ? correctFormat : "dd/mm/yyyy";
    };

    return (
        <div className={isReSize ? size_custom : size_default}>
            <div className={data_time_start}>
                {!(isDisableIcon || timeSchedule) && (
                    <img
                        onClick={openCalendar}
                        src={datePickerIcon}
                        className={clsx(
                            date_icon,
                            disabled && date_icon_disabled
                        )}
                        alt="date-icon"
                    />
                )}
                <div
                    className={isReSize ? size_custom_icon : size_default_icon}
                >
                    <div
                        className={clsx(
                            selected_date_time_value,
                            disabled && selected_date_time_value_disabled
                        )}
                    >
                        <div className={date_display}>
                            {getDesignCorrectDateFormat()}
                        </div>
                        {isOnlyDateDisplay
                            ? null
                            : showTime && (
                                  <div className={time_display}>
                                      {timeSchedule ? timeSchedule : timeValue}
                                  </div>
                              )}
                    </div>
                </div>
            </div>
        </div>
    );
};
