import { action } from "mobx";
import { v4 as uuid } from "uuid";

import {
    Question,
    QuestionType,
    SharedSurvey,
    SurveyEvent,
    SurveyEventAlert,
    UUID,
    SimpleSurvey,
    SurveyClasses,
} from "@difftone/types";
import { alertsStore, surveyStore } from "@difftone/stores";
import { getUserUuid } from "@difftone/procedures";
import { getCurrentTimeInBEFormat } from "@difftone/frontend-common";
import { getAlertButtonsFactory } from "@difftone/actions";
import { SURVEY_STATUS } from "@difftone/constants";

const MAX_COUNTER_FOR_SURVEY_NAME = 300;
const LAST_ELEMENT_IN_ARRAY_OF_STRINGS = -1;

const TEMPLATE_EMAIL_ADDRESS = "templates@difftone.com";

export const acceptSharedSurveyToDraft = action(
    (sharedSurvey: SharedSurvey, templateUUID?: UUID) => {
        const draftSurvey: SurveyClasses = JSON.parse(
            JSON.stringify(sharedSurvey.survey)
        );

        draftSurvey.uuid = uuid();
        draftSurvey.initiator = getUserUuid();
        draftSurvey.status = SURVEY_STATUS.DRAFT;

        //if shared survey is meant to be a template ignore name prefixing
        if (sharedSurvey.addressee.includes(TEMPLATE_EMAIL_ADDRESS)) {
            draftSurvey.survey_name = sharedSurvey.survey.survey_name;
        } else {
            draftSurvey.survey_name = finalizeCreateNewSurveyName(
                sharedSurvey.survey.survey_name
            );
        }

        draftSurvey.questions = finalizeCreateNewQuestions(
            sharedSurvey.survey.questions
        );
        draftSurvey.created_at = getCurrentTimeInBEFormat();

        if (templateUUID) draftSurvey.from_template = templateUUID;

        if (draftSurvey.survey_class === undefined) {
            (draftSurvey as SimpleSurvey).survey_class = "SIMPLE";
        }

        switch (draftSurvey.survey_class) {
            case "ONGOING":
                surveyStore.addSurveyToMapWithoutDebounce(
                    draftSurvey as SurveyClasses
                );

                return draftSurvey;
            case "SIMPLE":
            default:
                (draftSurvey as SimpleSurvey).survey_events =
                    finalizeCreateNewEvents(sharedSurvey, draftSurvey.uuid);
                surveyStore.addSurveyToMapWithoutDebounce(
                    draftSurvey as SurveyClasses
                );

                return draftSurvey;
        }
    }
);

const finalizeCreateNewSurveyName = (
    surveyName: string,
    counter?: number
): string => {
    let namePrefix = "shared";
    let finalSharedName;

    let sharedCounter: number = counter ? counter : 0;
    let sharedName = surveyName;

    //in case that everything fails
    if (sharedCounter >= MAX_COUNTER_FOR_SURVEY_NAME) {
        return `${Date.now()}_${surveyName}`;
    }

    if (
        !surveyStore.surveysList.find(
            (survey) => survey.survey_name === surveyName
        )
    ) {
        return surveyName;
    } else {
        sharedCounter++;
        namePrefix = `shared(${sharedCounter.toString()})`;

        const prevNamePrefix = `shared(${(sharedCounter - 1).toString()})`;

        if (sharedName.includes(prevNamePrefix)) {
            const splitSurveyName = sharedName.split(`${prevNamePrefix}_`);

            const oldSurveyName = splitSurveyName
                .slice(LAST_ELEMENT_IN_ARRAY_OF_STRINGS)
                .toString();

            sharedName = oldSurveyName;
        }

        finalSharedName = finalizeCreateNewSurveyName(
            `${namePrefix}_${sharedName}`,
            sharedCounter
        );
    }
    return finalSharedName;
};

const finalizeCreateNewEvents = (
    sharedSurvey: SharedSurvey,
    draftSurveyUuid: UUID
): SurveyEvent[] => {
    switch (sharedSurvey.survey.survey_class) {
        case "ONGOING":
            return [];
        case "SIMPLE":
        default:
            const simpleSurvey = sharedSurvey.survey as SimpleSurvey;
            const surveyEvents = simpleSurvey.survey_events.map(
                (SharedEvent) => {
                    const draftEvent: SurveyEvent = JSON.parse(
                        JSON.stringify(SharedEvent)
                    );

                    draftEvent.uuid = uuid();

                    draftEvent.event_alerts = finalizeCreateNewAlerts(
                        sharedSurvey.survey_events_alerts,
                        draftEvent.event_alerts,
                        draftSurveyUuid
                    );

                    return draftEvent;
                }
            );

            return surveyEvents;
    }
};

const finalizeCreateNewAlerts = (
    allSharedSurveyEventsAlerts: SurveyEventAlert[],
    eventAlertsUUIDs: UUID[],
    draftSurveyUuid: UUID
): UUID[] => {
    const surveyEventAlertsUUIDs = eventAlertsUUIDs.map((_alertUUID) => {
        const sharedEventAlert = allSharedSurveyEventsAlerts.find(
            (_EventAlert) => _EventAlert.uuid === _alertUUID
        );

        if (!sharedEventAlert) {
            throw Error("Something went wrong, could not find event alert");
        }

        const copyEventAlert: SurveyEventAlert = JSON.parse(
            JSON.stringify(sharedEventAlert)
        );
        copyEventAlert.uuid = uuid();

        copyEventAlert.buttons = getAlertButtonsFactory(
            copyEventAlert,
            draftSurveyUuid
        );
        alertsStore.InsertAlertToStoreWithoutDebounce(copyEventAlert);

        return copyEventAlert.uuid;
    });

    return surveyEventAlertsUUIDs;
};

const finalizeCreateNewQuestions = (
    sharedSurveyQuestions: Question<QuestionType>[]
): Question<QuestionType>[] => {
    const draftQuestions = sharedSurveyQuestions.map((sharedQuestion) => {
        const copySharedQuestion: Question<QuestionType> = JSON.parse(
            JSON.stringify(sharedQuestion)
        );
        copySharedQuestion.uuid = uuid();

        return copySharedQuestion;
    });

    return draftQuestions;
};
