import React from "react";
import { observer } from "mobx-react";

import {
    addEmptyWizardSurveyEvent,
    deleteWizardSurveyEventWithReset,
    duplicateEventInWizard,
    debounceUpsertSurveyToApi,
    validateWizardSurvey,
    setSurveyEventToDisplay,
} from "@difftone/actions";
import { wizardDisplayStore } from "@difftone/stores";
import { validateSurveyEvents } from "@difftone/validations";
import { BaseSurvey, SimpleSurvey } from "@difftone/types";
import { getSurveyFromStoreByURL } from "@difftone/reducers";
import {
    focusFirstInvalidEvent,
    navigateToWizardValidationError,
} from "@difftone/frontend-common/frontend-validations";
import { AddBoxShadow } from "@difftone/shared-components";

import dateIcon from "@difftone/assets/date-menu-date-icon.svg";
import duplicateQuestionIcon from "@difftone/assets/duplicate-question-floating-menu-icon.svg";
import deleteQuestionIcon from "@difftone/assets/delete-attribute-floating-menu-icon.svg";

import {
    dates_menu,
    grayed_out_button,
    add_button,
    duplicate_button,
    delete_button,
} from "./dates-menu.module.css";

export type DatesMenuProps = {
    survey?: BaseSurvey;
};

export const DatesMenu = observer((props: DatesMenuProps) => {
    const { survey } = props;
    const { selectedEventUUID } = wizardDisplayStore;
    const _survey = survey || getSurveyFromStoreByURL();

    //Old templets surveys don't have a class when creating new survey
    if (_survey.survey_class && _survey.survey_class !== "SIMPLE") {
        throw Error(
            "[Difftone]::DatesMenu This survey is not a simple survey and does not have survey events."
        );
    }

    const simpleSurvey = _survey as SimpleSurvey;

    const addDateHandler = () => {
        const datesValidations = validateSurveyEvents(
            simpleSurvey.survey_events
        );
        if (datesValidations.some((validation) => !validation.is_valid)) {
            validateWizardSurvey();
            const invalidEvent = datesValidations.find(
                (validation) => !validation.is_valid
            );
            if (!invalidEvent) {
                return;
            }
            navigateToWizardValidationError(simpleSurvey);
            return;
        }

        const newEventUuid = addEmptyWizardSurveyEvent();
        debounceUpsertSurveyToApi();
        setTimeout(() => {
            const inputText = document.querySelector(
                `[data-input-event-uuid="${newEventUuid}"]`
            ) as HTMLInputElement;

            inputText?.select();
        }, 0);
    };

    const deleteEvent = () => {
        const eventToDelete = simpleSurvey.survey_events.find(
            (surveyEvent) => surveyEvent.uuid === selectedEventUUID
        );

        eventToDelete && deleteWizardSurveyEventWithReset(eventToDelete);
        setSurveyEventToDisplay(null);
        validateWizardSurvey();
        debounceUpsertSurveyToApi();
    };

    const duplicateEvent = () => {
        const datesValidations = validateSurveyEvents(
            simpleSurvey.survey_events
        );
        if (datesValidations.some((validation) => !validation.is_valid)) {
            validateWizardSurvey();
            const invalidEvent = datesValidations.find(
                (validation) => !validation.is_valid
            );
            if (!invalidEvent) {
                return;
            }
            focusFirstInvalidEvent(invalidEvent);
            return;
        }

        const selectedEventToCopy = simpleSurvey?.survey_events.find(
            (surveyEvent) => surveyEvent.uuid === selectedEventUUID
        );

        selectedEventToCopy && duplicateEventInWizard(selectedEventToCopy);

        setTimeout(() => {
            debounceUpsertSurveyToApi();
        }, 0);
        validateWizardSurvey();
    };

    const isSystemEvent = () => {
        const selectedEvent = simpleSurvey?.survey_events.find(
            (surveyEvent) => surveyEvent.uuid === selectedEventUUID
        );

        if (
            selectedEvent?.system_survey_event_type === "LAST_DATE_TO_FILL" ||
            selectedEvent?.system_survey_event_type === "PUBLISHED_DATE"
        ) {
            return true;
        }

        return false;
    };

    return (
        <AddBoxShadow isFloatingMenuBoxShadow={true}>
            <div className={dates_menu}>
                <div className={add_button}>
                    <button onClick={addDateHandler}>
                        <img src={dateIcon} alt="add-date" />
                        Date
                    </button>
                </div>
                <div className={duplicate_button}>
                    <button
                        className={isSystemEvent() ? grayed_out_button : ""}
                        onClick={duplicateEvent}
                    >
                        <img src={duplicateQuestionIcon} alt="duplicate-date" />
                        Duplicate
                    </button>
                </div>
                <div className={delete_button}>
                    <button
                        className={isSystemEvent() ? grayed_out_button : ""}
                        onClick={deleteEvent}
                    >
                        <img src={deleteQuestionIcon} alt="delete-date" />
                        Delete
                    </button>
                </div>
            </div>
        </AddBoxShadow>
    );
});
