import React from "react";

type Color = "GREEN" | "GRAY";

export type SearchIconProps = {
    color: Color;
    className?: "";
    onClick?: () => void;
};

export const SearchIcon = (props: SearchIconProps) => {
    const { color, className, onClick } = props;

    const colors = {
        GREEN: "#80CBC4",
        GRAY: "#B2C0C8",
    };

    const onClickHandler = () => {
        if (!onClick) {
            return;
        }
        onClick();
    };

    return (
        <svg
            onClick={onClickHandler}
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20 20.2L15.0962 15.2962M15.0962 15.2962C16.2725 14.1199 17 12.4949 17 10.7C17 7.11016 14.0898 4.20001 10.5 4.20001C6.91015 4.20001 4 7.11016 4 10.7C4 14.2899 6.91015 17.2 10.5 17.2C12.2949 17.2 13.9199 16.4725 15.0962 15.2962Z"
                stroke={colors[color]}
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
