import { surveyResponseStatusStore } from "@difftone/stores";
import { BaseSurvey } from "@difftone/types";
import React from "react";
import { observer } from "mobx-react";

import {
    overall_responses_bar,
    overall_responses_bar_wrapper,
} from "./overall-responses-bar.module.css";
import { ShowDifftoneTooltip } from "@difftone/shared-components";

export type OverallResponsesBarProps = {
    survey: BaseSurvey;
};

export const OverallResponsesBar = observer(
    ({ survey }: OverallResponsesBarProps) => {
        const completed = surveyResponseStatusStore.getStatusBySurveyUuid(
            survey.uuid!
        ).length;
        const total = survey!.respondents!.length;
        const percent = ((completed * 100) / total || 0).toFixed(0);
        return (
            <div className={overall_responses_bar_wrapper}>
                <ShowDifftoneTooltip
                    tip={`${percent}% Responded`}
                    tooltipPosition="bottom"
                >
                    <div
                        className={overall_responses_bar}
                        style={{ width: `${percent}%` }}
                    ></div>
                </ShowDifftoneTooltip>
            </div>
        );
    }
);
