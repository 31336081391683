import {
    Category,
    ValidationResult,
    CategoryScopeValidationType
} from "@difftone/types";


export const validateCategoryScope = (category: Category): ValidationResult<CategoryScopeValidationType>[] => {
    let is_valid: boolean;
    switch (category.scope_type) {
        case "GENERAL": {
            is_valid = !category.scope_id;
            break;
        }
        case "ORGANISATION":
        case "PRIVATE": {
            is_valid = !!category.scope_id;
            break;
        }
        default: {
            is_valid = false;
            break;
        }
    }

    return is_valid ?
        [{
            is_valid: true,
            validation_error_code: "CATEGORY_SCOPE_VALIDATION",
            validation_error_type: { category_uuid: category.uuid }
        }] :
        [{
            is_valid: false,
            validation_error_code: "CATEGORY_SCOPE_VALIDATION",
            validation_error_type: { category_uuid: category.uuid }
        }];
} 