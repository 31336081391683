import { getApiBaseUrl } from "@difftone/constants";
import { errorHandlerProxy, localstorageUtils } from "@difftone/procedures";
import { createDifftoneAxios } from "@difftone/services";
import { Domain } from "@difftone/types";

const API_URL = `${getApiBaseUrl()}/internal-features/domains`;

export const getAllDomainsForDifftone = async (): Promise<Domain[]> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(API_URL, {
        params: {
            issuer: currentAuthData.issuer,
        },
    });

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    return response.data.resources;
};

export const upsertDomainForDifftone = async (
    domain: Domain
): Promise<Domain | null> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.post(
        API_URL,
        { new_domain: domain },
        {
            params: {
                issuer: currentAuthData.issuer,
            },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    return response.data.results;
};
