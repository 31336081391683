import { getApiBaseUrl } from "@difftone/constants";
import { SharedSurvey } from "@difftone/types";
import { errorHandlerProxy, localstorageUtils } from "@difftone/procedures";
import { createDifftoneAxios } from "@difftone/services";

const SERVER_BASE_URL = `${getApiBaseUrl()}/templates-service`;

export const updateTemplateToApi = async (
    template: SharedSurvey
): Promise<SharedSurvey> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.put(
        `${SERVER_BASE_URL}/`,
        {
            templates_to_update: [template],
        },
        {
            params: { issuer: currentAuthData.issuer },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resourceData = JSON.parse(response.data.results);

    const resource = resourceData[0].data;

    return resource;
};

export const getAllTemplatesFromApi = async (): Promise<SharedSurvey[]> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(
        `${SERVER_BASE_URL}/get-templates`,
        {
            params: { issuer: currentAuthData.issuer },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.resources;

    const dataArray: SharedSurvey[] = Object.keys(resource).map(
        (key) => resource[key].data
    );

    return dataArray;
};
