import { QuestionTypeName } from "@difftone/types";

export const questionTypes: { [x: string]: string } = {
    multiple_choice: "multiple choice",
    rating: "rating",
    date: "date",
    time: "time",
    short_text: "short text",
    long_text: "long text",
    booking: "booking",
};

export const questionTypeNames: { [x: string]: QuestionTypeName } = {
    SINGLE_CHOICE: "SINGLE_CHOICE",
    MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
    RATING: "RATING",
    DATE: "DATE",
    TIME: "TIME",
    SHORT_TEXT: "SHORT_TEXT",
    LONG_TEXT: "LONG_TEXT",
    BOOKING: "BOOKING",
};

export const RATING_DEFAULT_SCALE = 5;

export const MAX_CHARACTERS_IN_QUESTION_TITLE = 50;

export const MAX_CHARACTERS_IN_FAVORABLE_QUESTION_TITLE = 40;
