import { action } from "mobx";

import { BaseSurvey, ResultsFilter, SharedResult, UUID } from "@difftone/types";
import { navigationStore, wizardDisplayStore } from "@difftone/stores";
import { getQueryParamsFromUrl } from "@difftone/reducers";
import { showDifftoneAlert } from "@difftone/actions";
import { INBOX_URL, warningMessages } from "@difftone/constants";
import { objectsAreEqual } from "@difftone/frontend-common";

export const setLocation = action((newUrl: string) => {
    //REGEX uses capturing parentheses to handle cases where the URL contains
    // more than one '?' character
    const [pathname, search] = newUrl.split(/\?(.*)/s);

    navigationStore.currentSearch = search || "";
    navigationStore.currentPage = pathname;
    //@ts-ignore
    analytics.page();
});

export const setQueryParam = action(
    (searchParam: string, newValue: string | string[]) => {
        const currentSearch = getQueryParamsFromUrl();

        if (!newValue.length) {
            currentSearch.delete(searchParam);
        } else {
            currentSearch.set(searchParam, newValue.toString());
        }

        setLocation(
            `${navigationStore.currentPage}?${currentSearch?.toString()}`
        );
    }
);

export const goToExportResultsByUrl = (url: string) => {
    if (url.includes("?")) {
        window.location.href = `${window.origin}${url}&needsExport=true`;
    } else {
        window.location.href = `${window.origin}${url}?needsExport=true`;
    }
};

export const WizardActiveModeClickBrowserBackButton = (
    currentPage: string,
    survey: BaseSurvey
) => {
    const { surveyCopyToUpdate } = wizardDisplayStore;
    //sets the current page location to popState
    history.pushState(null, "", currentPage);
    //Triggered by click back on browser navigation
    window.onpopstate = () => {
        //sets again the prev location and show alert
        history.pushState(null, "", currentPage);
        if (
            surveyCopyToUpdate &&
            !objectsAreEqual(survey, surveyCopyToUpdate)
        ) {
            showDifftoneAlert(
                warningMessages.goBackWithNoWizardChanges,
                "FAILURE",
                () => {
                    setLocation(INBOX_URL);
                    //Clean up after setting location to inbox
                    window.onpopstate = () => {};
                }
            );
        } else {
            setLocation(INBOX_URL);
        }
    };
};
