import React from "react";
import { observer } from "mobx-react";
import { ErrorBoundary, CloudsSuccessAlert } from "@difftone/shared-components";

import { CurrentPage, DifftoneAlert } from "./components";

// import { Graph } from "@difftone/features";

export const App = () => {
    return (
        <ErrorBoundary>
            {/* <Graph initSatat="S2" /> */}
            <CurrentPage />
            <CloudsSuccessAlert />
            <DifftoneAlert />
        </ErrorBoundary>
    );
};
