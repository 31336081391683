import React from "react";
import { Question, QuestionType } from "@difftone/types";
import ratingIcon from "@difftone/assets/collapsed-rating.svg";
import singleChoiceIcon from "@difftone/assets/collapsed-single-choice.svg";
import multiChoiceIcon from "@difftone/assets/collapsed-multiple-choice.svg";
import dateIcon from "@difftone/assets/collapsed-date.svg";
import timeIcon from "@difftone/assets/collapsed-clock.svg";
import longTextIcon from "@difftone/assets/collapsed-long-text.svg";
import shortTextIcon from "@difftone/assets/collapsed-short-text.svg";

import { mobile_question_type } from "./mobile-question-type-extarctor.module.css";

export type MobileQuestionTypeExtarctorProps = {
    question: Question<QuestionType>;
};

export const MobileQuestionTypeExtarctor = (
    props: MobileQuestionTypeExtarctorProps
) => {
    const { question } = props;
    let questionTypeIcon = null;
    let questionTypeName = null;
    switch (question.question_type_name) {
        case "RATING":
            questionTypeIcon = ratingIcon;
            questionTypeName = "Rating";
            break;
        case "SINGLE_CHOICE":
            questionTypeIcon = singleChoiceIcon;
            questionTypeName = "Single Choice";
            break;
        case "DATE":
            questionTypeIcon = dateIcon;
            questionTypeName = "Date";
            break;
        case "TIME":
            questionTypeIcon = timeIcon;
            questionTypeName = "Time";
            break;
        case "MULTIPLE_CHOICE":
            questionTypeIcon = multiChoiceIcon;
            questionTypeName = "Multiple Choice";
            break;
        case "SHORT_TEXT":
            questionTypeIcon = shortTextIcon;
            questionTypeName = "Short Text";
            break;
        case "LONG_TEXT":
            questionTypeIcon = longTextIcon;
            questionTypeName = "Long Text";
            break;
        default:
            break;
    }
    return (
        <div className={mobile_question_type}>
            <img src={questionTypeIcon} alt={questionTypeName!} />
            <span>{questionTypeName}</span>
        </div>
    );
};
