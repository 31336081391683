import {
    selectDisplayTimeCompareInPopup,
    setLocation,
    setSelectedTimeCompareFilterAndTimeUnits,
    setSelectedTimeCompareSharedResultsFilterAndTimeUnits,
} from "@difftone/actions";
import { TIME_FILTER_ID } from "@difftone/constants";
import {
    sharedResultsDisplayStore,
    resultsDisplayFilterStore,
} from "@difftone/stores";
import {
    ResultsDisplayType,
    SurveyClasses,
    TimeRangesContainer,
} from "@difftone/types";

export const changeResultsOptionalView = (
    pageUrl: string,
    optionalView: ResultsDisplayType,
    timeRanges: TimeRangesContainer,
    survey: SurveyClasses
) => {
    const { compareBy } = resultsDisplayFilterStore;

    const {
        selectedSharedResults,
        setPopupDisplaySharedResultsTimeCompare,
        getSelectedFilters,
    } = sharedResultsDisplayStore;

    switch (pageUrl) {
        case "shared-results":
            const sharedResultsTimeCompare = getSelectedFilters().find(
                (filter) => filter.filterKey.uuid === TIME_FILTER_ID
            );
            switch (optionalView) {
                case "grid":
                    break;
                case "line":
                    if (sharedResultsTimeCompare) {
                        if (sharedResultsTimeCompare.unit === "NONE") {
                            setPopupDisplaySharedResultsTimeCompare("MONTHS");
                            setSelectedTimeCompareSharedResultsFilterAndTimeUnits(
                                "MONTHS",
                                sharedResultsTimeCompare.filterKey.uuid,
                                timeRanges
                            );
                        }
                    }

                    break;

                default:
                    break;
            }
            setLocation(
                `/shared-results/${selectedSharedResults?.uuid}/${optionalView}`
            );

            break;
        case "results":
        default:
            const resultsTimeCompare = compareBy.find(
                (filter) => filter.filterKey.uuid === TIME_FILTER_ID
            );
            switch (optionalView) {
                case "grid":
                    break;
                case "line":
                    if (resultsTimeCompare) {
                        if (resultsTimeCompare.unit === "NONE") {
                            selectDisplayTimeCompareInPopup("MONTHS");
                            setSelectedTimeCompareFilterAndTimeUnits(
                                "MONTHS",
                                resultsTimeCompare.filterKey.uuid,
                                timeRanges
                            );
                        }
                    }

                    break;
                //TODO Change to capital letters https://app.asana.com/0/1201346022025578/1203264006891799/f
                case "favorable":
                    break;

                default:
                    break;
            }
            setLocation(`/results/${survey.uuid}/${optionalView}`);
            break;
    }
};
