import { makeAutoObservable, observable, ObservableMap } from "mobx";
import { PublicProfile, UUID } from "@difftone/types";

import {
    updatePublicProfile,
    updatePublicProfiles,
} from "./public-profile-internal-actions";

class PublicProfileStore {
    constructor() {
        makeAutoObservable<PublicProfileStore, "_fetechedPublicProfileUuids">(
            this,
            {
                _fetechedPublicProfileUuids: false,
            }
        );
    }

    private _storeInitialize: boolean = false;
    get storeInitialize(): boolean {
        return this._storeInitialize;
    }
    set storeInitialize(newStoreInitialize: boolean) {
        this._storeInitialize = newStoreInitialize;
    }

    private _startInitialization: boolean = false;
    get startInitialization(): boolean {
        return this._startInitialization;
    }
    set startInitialization(newStartInitialization: boolean) {
        this._startInitialization = newStartInitialization;
    }

    private _isRequestingSinglePublicProfile: boolean = true;
    get isRequestingSinglePublicProfile(): boolean {
        return this._isRequestingSinglePublicProfile;
    }
    set isRequestingSinglePublicProfile(isRequesting: boolean) {
        this._isRequestingSinglePublicProfile = isRequesting;
    }

    public init = (publicProfileUUIDs: UUID[]) => {
        updatePublicProfiles(publicProfileUUIDs);
        this.startInitialization = true;
        this.isRequestingSinglePublicProfile = false;
    };

    private _fetechedPublicProfileUuids: UUID[] = [];

    private _publicProfilesMap: ObservableMap<UUID, PublicProfile | null> =
        observable.map({});

    public getPublicProfiles(): ObservableMap<UUID, PublicProfile | null> {
        return this._publicProfilesMap;
    }

    public setPublicProfileToMap(
        newPublicProfileUUID: UUID,
        newPublicProfile: PublicProfile | null
    ) {
        this._publicProfilesMap.merge({
            [newPublicProfileUUID]: newPublicProfile,
        });
    }

    get publicProfileList(): PublicProfile[] {
        const _profileList = Array.from(
            this._publicProfilesMap.values()
        ).filter((value) => !!value && typeof value === "object");

        return _profileList as PublicProfile[];
    }

    public getPublicProfileByUuid(
        publicProfileUuid: UUID
    ): PublicProfile | null | undefined {
        const fetchedPublicProfile =
            this._publicProfilesMap.get(publicProfileUuid);

        if (
            fetchedPublicProfile === undefined &&
            !this._fetechedPublicProfileUuids.includes(publicProfileUuid)
        ) {
            this._fetechedPublicProfileUuids.push(publicProfileUuid);
            updatePublicProfile(publicProfileUuid);
        }

        return fetchedPublicProfile;
    }

    public asyncGetPublicProfileByUuid = async (publicProfileUuid: UUID) => {
        const publicProfile = this._publicProfilesMap.get(publicProfileUuid);

        if (!!publicProfile) {
            return publicProfile;
        }

        if (publicProfile === null) {
            return null;
        }

        await updatePublicProfile(publicProfileUuid);
        const publicProfileAfterUpdate =
            this._publicProfilesMap.get(publicProfileUuid);

        return publicProfileAfterUpdate;
    };

    public getPublicProfilesByUuids = (
        publicProfilesUuids: UUID[]
    ): (PublicProfile | null | undefined)[] => {
        const publicProfilesUuidsFetch: UUID[] = [];

        const fetchedPublicProfiles = publicProfilesUuids.map(
            (publicProfileUuid) => {
                const fetchedPublicProfile =
                    this._publicProfilesMap.get(publicProfileUuid);

                if (
                    fetchedPublicProfile === undefined &&
                    !this._fetechedPublicProfileUuids.includes(
                        publicProfileUuid
                    )
                ) {
                    this._fetechedPublicProfileUuids.push(publicProfileUuid);
                    updatePublicProfile(publicProfileUuid);
                }

                return fetchedPublicProfile;
            }
        );

        if (publicProfilesUuidsFetch.length > 0) {
            updatePublicProfiles(publicProfilesUuidsFetch);
        }
        return fetchedPublicProfiles;
    };

    public clearStore = () => {
        this._storeInitialize = false;
        this._startInitialization = false;
        this._publicProfilesMap = observable.map({});
    };
}

export const publicProfileStore = new PublicProfileStore();
