import React, { useState } from "react";
import dropDownArrow from "@difftone/assets/down-arrow.svg";

import {
    OptionalPropertiesForDuplicateSurvey,
    SurveyClasses,
    UUID,
} from "@difftone/types";
import { QuestionItem } from "./components";
import {
    half_checked_style,
    checked_style,
    custom_checkbox_class,
    duplicate_questions,
    duplicate_questions_header,
    duplicate_questions_title,
    text_label,
    collapse_arrow,
    rotated_arrow,
} from "./duplicate-questions.module.css";

export type DuplicateQuestionsProps = {
    duplicateSurveyProps: OptionalPropertiesForDuplicateSurvey;

    survey: SurveyClasses;
    onToggleAllQuestionsSelected: (allSelected: boolean) => void;
    onToggleQuestion: (questionUuid: UUID, selected: boolean) => void;
};

export const DuplicateQuestions = (props: DuplicateQuestionsProps) => {
    const {
        duplicateSurveyProps,
        survey,
        onToggleAllQuestionsSelected,
        onToggleQuestion,
    } = props;
    const [collapsedQuestions, setCollapsedQuestions] = useState(false);

    const toggleCollapse = () => {
        setCollapsedQuestions(!collapsedQuestions);
    };

    const allQuestionsSelected =
        duplicateSurveyProps.questionsUuids.length === survey.questions.length;

    const allQuestionsNotSelected =
        duplicateSurveyProps.questionsUuids.length === 0;

    const onChange = () => {
        onToggleAllQuestionsSelected(!allQuestionsSelected);
    };

    let checkedIcon = half_checked_style;

    if (allQuestionsSelected) {
        checkedIcon = checked_style;
    }
    if (allQuestionsNotSelected) {
        checkedIcon = custom_checkbox_class;
    }

    return (
        <div className={duplicate_questions}>
            <div className={duplicate_questions_header}>
                <div className={duplicate_questions_title}>
                    <label className={checkedIcon}>
                        <input type="checkbox" onChange={onChange} />
                    </label>
                    <label className={text_label}>
                        Questions
                        <span>{`(${survey.questions.length})`}</span>
                    </label>
                </div>
                <img
                    onClick={toggleCollapse}
                    className={
                        collapsedQuestions
                            ? [collapse_arrow, rotated_arrow].join(" ")
                            : collapse_arrow
                    }
                    src={dropDownArrow}
                    alt="arrow_collapse_expand"
                />
            </div>
            {collapsedQuestions ? (
                <div>
                    {survey.questions.map((question, index) => (
                        <QuestionItem
                            onToggleQuestion={onToggleQuestion}
                            key={question.title}
                            questionNumber={index}
                            title={question.title}
                            questionUuid={question.uuid}
                            value={duplicateSurveyProps.questionsUuids.includes(
                                question.uuid
                            )}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    );
};
