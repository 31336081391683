import React from "react";
import { IconType } from "@difftone/types";

import { FavorableIcon, GridIcon, LineIcon } from "./components";

export type DynamicIconProps = {
    iconType: IconType;
    isSelected: boolean;
    isMouseHover: boolean;
    isDisabled: boolean;
    classNameOverride?: string;
};

export const DynamicIcon = (props: DynamicIconProps) => {
    const {
        iconType,
        isSelected,
        isMouseHover,
        isDisabled,
        classNameOverride,
    } = props;
    switch (iconType) {
        case "GRID":
            return (
                <GridIcon
                    classNameOverride={classNameOverride}
                    isSelected={isSelected}
                    isMouseHover={isMouseHover}
                    isDisabled={isDisabled}
                />
            );
        case "FAVORABLE":
            return (
                <FavorableIcon
                    classNameOverride={classNameOverride}
                    isSelected={isSelected}
                    isMouseHover={isMouseHover}
                    isDisabled={isDisabled}
                />
            );
        case "LINE":
            return (
                <LineIcon
                    classNameOverride={classNameOverride}
                    isSelected={isSelected}
                    isMouseHover={isMouseHover}
                    isDisabled={isDisabled}
                />
            );
        default:
            return null;
    }
};
