import React from "react";
import clsx from "clsx";

import {
    QuestionToAnswer,
    QuestionToAnswerDescreteQuestion,
} from "@difftone/types";
import {
    calculateFavorableQuestionScore,
    getNormalizedArrayForTooltip,
} from "@difftone/reducers";
import { FavorableScore } from "@difftone/shared-components";
import { DATA_ANONYMIZED } from "@difftone/common-constants";

import {
    results_favorable_question,
    favorable_question_score_width_small,
    favorable_anonimity,
    favorable_anonimity_no_data,
} from "./results-favorable-question.module.css";

const EMPTY_SCORE_RESULT_VALUE = 0;

export type ResultsFavorableQuestionProps = {
    questionResults: QuestionToAnswer;
    scoreBarSize: "SMALL" | "MEDIUM";
};

export const ResultsFavorableQuestion = (
    props: ResultsFavorableQuestionProps
) => {
    const { questionResults, scoreBarSize } = props;

    const resultsScore = calculateFavorableQuestionScore(
        questionResults as QuestionToAnswerDescreteQuestion
    );

    const groupsForTooltip = getNormalizedArrayForTooltip(
        questionResults as QuestionToAnswerDescreteQuestion
    );

    const isAnonymous = questionResults === DATA_ANONYMIZED;

    const isEmptyResultsScore = resultsScore.every(
        (score) => score === EMPTY_SCORE_RESULT_VALUE
    );

    return (
        <div
            className={clsx(results_favorable_question, {
                [favorable_question_score_width_small]:
                    scoreBarSize === "SMALL",
                [favorable_anonimity]: isAnonymous,
            })}
        >
            {isAnonymous ? (
                <span className={favorable_anonimity_no_data}>
                    Data cannot be shown due to anonymity limitations
                </span>
            ) : (
                <>
                    {isEmptyResultsScore ? null : (
                        <FavorableScore
                            tooltipGroups={groupsForTooltip}
                            scoreResults={resultsScore}
                            scoreBarSize={scoreBarSize}
                        />
                    )}
                </>
            )}
        </div>
    );
};
