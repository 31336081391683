import React from "react";
import { observer } from "mobx-react";

import { Question, QuestionType } from "@difftone/types";
import { WIZARD_FILTER_TOOLTIP_INFO } from "@difftone/constants";
import {
    ShowDifftoneTooltip,
    FilterCheckBox,
} from "@difftone/shared-components";
import { toggleWizardQuestionSetAsFilter } from "@difftone/actions";

import blueIIcon from "@difftone/assets/blue-i-icon.svg";

import {
    set_as_filter,
    filter_text,
    filter_icon,
} from "./set-as-filter-indicator.module.css";

export type SetAsFilterIndicatorProps = {
    question: Question<QuestionType>;
};

const COLOR_BLUE = "BLUE";
const COLOR_GREEN = "GREEN";

export const SetAsFilterIndicator = observer(
    (props: SetAsFilterIndicatorProps) => {
        const { question } = props;

        const onFilterToggle = () => {
            toggleWizardQuestionSetAsFilter(question);
        };

        return (
            <div className={set_as_filter}>
                <FilterCheckBox
                    onClick={onFilterToggle}
                    className={filter_icon}
                    color={question.is_filter ? COLOR_BLUE : COLOR_GREEN}
                />
                <div className={filter_text}>
                    <label>Set as filter</label>
                    <ShowDifftoneTooltip
                        tip={WIZARD_FILTER_TOOLTIP_INFO}
                        tooltipPosition="bottom"
                    >
                        <img src={blueIIcon} alt="info-icon" />
                    </ShowDifftoneTooltip>
                </div>
            </div>
        );
    }
);
