import React from "react";
import { CustomDateTimePicker } from "@difftone/shared-components";

import { expanded_date_wrapper } from "./expanded-date.module.css";

export type ExpandedDateProps = {
    disabled: boolean;
};

export const ExpandedDate = (props: ExpandedDateProps) => {
    const { disabled } = props;
    return (
        <div className={expanded_date_wrapper}>
            <CustomDateTimePicker
                isSystemEvent={false}
                isValid={true}
                isOnlyDateDisplay={true}
                disabled={disabled}
                onChange={() => {}}
                localDateStr={Date.now().toLocaleString()}
                dayUtcTime={0}
            />
        </div>
    );
};
