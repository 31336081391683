import React, { ReactNode } from "react";

import { table_row_wrapper } from "./table-row.module.css";

export type TableRowProps = {
    body: ReactNode;
};

export const TableRow = (props: TableRowProps) => {
    const { body } = props;
    return <div className={table_row_wrapper}>{body}</div>;
};
