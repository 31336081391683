import React, { useState } from "react";
import { observer } from "mobx-react";
import {
    MultipleChoiceQuestion,
    Question,
    QuestionType,
    RatingQuestion,
    ResultsFilter,
    SingleChoiceQuestion,
} from "@difftone/types";
import { getNickNameByQuestionUUID } from "@difftone/reducers";

import { OptionsCheckboxList, FiltersListPlaceholder } from "./components";

import {
    remove_filter,
    filter_option,
    key_text,
    no_values_selected_placeholder,
} from "./filter-option.module.css";

export type FilterOptionProps = {
    question: Question<QuestionType>;
    displayFilters: ResultsFilter[];
    removeFilter: (filterToRemove: ResultsFilter | null) => void;
    index: number;
};

export const FilterOption = observer((props: FilterOptionProps) => {
    const { question, removeFilter, displayFilters } = props;
    const [showOptions, setShowOptions] = useState(false);

    const currentFilter = displayFilters.find(
        (filter) => filter.filterKey.uuid === question.uuid
    );
    const selectedOptionsCounter = currentFilter?.filterValues.length || 0;

    const openOptionsList = () => {
        setShowOptions(true);
    };

    const closeOptionsList = () => {
        setShowOptions(false);
    };

    const removeOptionHandler = () => {
        removeFilter(currentFilter || null);
    };

    const assignTypeAndPassToOptionsList = () => {
        switch (question.question_type_name) {
            case "SINGLE_CHOICE":
                return (
                    <OptionsCheckboxList
                        displayFilters={displayFilters}
                        question={question as Question<SingleChoiceQuestion>}
                        hideList={closeOptionsList}
                    />
                );
            case "MULTIPLE_CHOICE":
                return (
                    <OptionsCheckboxList
                        displayFilters={displayFilters}
                        question={question as Question<MultipleChoiceQuestion>}
                        hideList={closeOptionsList}
                    />
                );
            case "RATING":
                return (
                    <OptionsCheckboxList
                        displayFilters={displayFilters}
                        question={question as Question<RatingQuestion>}
                        hideList={closeOptionsList}
                    />
                );
            case "DATE":
            case "TIME":
            case "SHORT_TEXT":
            case "LONG_TEXT":
                return (
                    <OptionsCheckboxList
                        displayFilters={displayFilters}
                        question={question}
                        hideList={closeOptionsList}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div
            title={question.title}
            data-filter-option={question.uuid}
            className={filter_option}
        >
            <div onClick={openOptionsList} className={key_text}>
                <span>{getNickNameByQuestionUUID(question.uuid)}</span>:
                {selectedOptionsCounter === 0 ? (
                    <div className={no_values_selected_placeholder}>Pick</div>
                ) : (
                    <>
                        <FiltersListPlaceholder
                            displayFilters={displayFilters}
                            question={question}
                        />

                        <svg
                            onMouseDown={removeOptionHandler}
                            className={remove_filter}
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="9.5" cy="9.5" r="9" fill="#035375" />
                            <path
                                d="M13.3225 5.68353C13.2092 5.56992 13.0553 5.50607 12.8948 5.50607C12.7343 5.50607 12.5804 5.56992 12.467 5.68353L9.5 8.64448L6.53299 5.67747C6.41963 5.56385 6.26573 5.5 6.10523 5.5C5.94474 5.5 5.79083 5.56385 5.67747 5.67747C5.44084 5.9141 5.44084 6.29635 5.67747 6.53298L8.64449 9.5L5.67747 12.467C5.44084 12.7036 5.44084 13.0859 5.67747 13.3225C5.91411 13.5592 6.29636 13.5592 6.53299 13.3225L9.5 10.3555L12.467 13.3225C12.7036 13.5592 13.0859 13.5592 13.3225 13.3225C13.5592 13.0859 13.5592 12.7036 13.3225 12.467L10.3555 9.5L13.3225 6.53298C13.5531 6.30242 13.5531 5.9141 13.3225 5.68353Z"
                                fill="#EBF5F9"
                            />
                        </svg>
                    </>
                )}
            </div>
            {showOptions ? assignTypeAndPassToOptionsList() : null}
        </div>
    );
});
