import React, { MouseEvent } from "react";
import { observer } from "mobx-react";
import { v4 as uuid } from "uuid";
import cn from "clsx";

import {
    setLocation,
    removeDeletedSurveysFromStore,
    showDifftoneAlert,
    onSharedSurveyClick,
    sendSurvey,
    upsertSurveyToMapAndApiWithDebounce,
} from "@difftone/actions";
import { getStatus } from "@difftone/reducers";
import { constantAlertVariants } from "@difftone/constants";
import { navigationStore } from "@difftone/stores";
import { BaseSurvey, SurveyClasses } from "@difftone/types";

import { UiStatus } from "@difftone/frontend-common";

import editIcon from "@difftone/assets/edit-survey.svg";
import resultsIcon from "@difftone/assets/survey-results.svg";
import deleteIcon from "@difftone/assets/delete-survey.svg";
import sendIcon from "@difftone/assets/send-survey.svg";
import duplicateIcon from "@difftone/assets/duplicate-survey.svg";
import shareIcon from "@difftone/assets/share-survey.svg";
import archiveIcon from "@difftone/assets/archive.svg";

import {
    options_menu,
    disabled_li,
    options_menu_backdrop,
} from "./user-options-menu.module.css";

export type UserOptionsMenuProps = {
    survey: SurveyClasses;
    surveyStatus: UiStatus;
    closeMenu: () => void;
};

export const UserOptionsMenu = observer((props: UserOptionsMenuProps) => {
    const { survey, surveyStatus, closeMenu } = props;
    const { currentPage } = navigationStore;

    const onSendNow = async () => {
        sendSurvey(survey);
    };

    const onEdit = () => {
        if (survey.status === "DRAFT") {
            showDifftoneAlert(
                constantAlertVariants.no_mobile_notification.text,
                constantAlertVariants.no_mobile_notification.variant
            );
            return;
        }
    };

    const onResults = () => {
        showDifftoneAlert(
            constantAlertVariants.no_mobile_notification.text,
            constantAlertVariants.no_mobile_notification.variant
        );
        return;
    };

    const onDelete = async () => {
        survey.deleted = true;
        upsertSurveyToMapAndApiWithDebounce(survey);
        removeDeletedSurveysFromStore();
        showDifftoneAlert(
            `"${survey.survey_name}" ${constantAlertVariants.survey_deleted.text}`,
            constantAlertVariants.survey_deleted.variant
        );
        return;
    };

    const onUnconfirmedDelete = () => {
        const surveyStatus = getStatus(survey, "INITIATOR");
        if (surveyStatus === "ACTIVE") {
            showDifftoneAlert(
                constantAlertVariants.survey_warning_delete.text,
                constantAlertVariants.survey_warning_delete.variant,
                onDelete,
                () => {},
                survey.survey_name
            );
            return;
        }
        showDifftoneAlert(
            constantAlertVariants.survey_delete.text,
            constantAlertVariants.survey_delete.variant,
            onDelete,
            () => {},
            survey.survey_name
        );
    };

    const onDuplicate = () => {
        setLocation(`${currentPage}/duplicate/${survey.uuid}`);
    };

    const onShare = () => {
        const sharedSurveyUUID = uuid();
        onSharedSurveyClick(survey, sharedSurveyUUID);
        setLocation(`${currentPage}/share/${sharedSurveyUUID}`);
    };

    const onArchive = async () => {
        survey.archived = true;
        upsertSurveyToMapAndApiWithDebounce(survey);
        showDifftoneAlert(
            `"${survey.survey_name}" ${constantAlertVariants.survey_archived.text}`,
            constantAlertVariants.survey_archived.variant
        );

        return;
    };

    const onBackdropClicked = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        closeMenu();
    };

    return (
        <div onClick={onBackdropClicked} className={options_menu_backdrop}>
            <div className={options_menu}>
                <ul>
                    <li onClick={onEdit}>
                        <img alt="edit" src={editIcon} />
                        Edit
                    </li>
                    <li
                        className={
                            surveyStatus === "COMPLETED" ||
                            surveyStatus === "ENDED" ||
                            surveyStatus === "ACTIVE" ||
                            surveyStatus === "PAUSED"
                                ? null
                                : disabled_li
                        }
                        onClick={onResults}
                    >
                        <img alt="results" src={resultsIcon} />
                        Results
                    </li>
                    <li onClick={onDuplicate}>
                        <img alt="send" src={duplicateIcon} />
                        Duplicate
                    </li>
                    <li onClick={onShare}>
                        <img alt="share" src={shareIcon} />
                        Share
                    </li>
                    <li
                        className={cn({
                            [disabled_li]: surveyStatus === "DRAFT",
                        })}
                        onClick={onSendNow}
                    >
                        <img alt="send" src={sendIcon} />
                        Send now
                    </li>
                    <li
                        className={cn({
                            [disabled_li]: surveyStatus === "DRAFT",
                        })}
                        onClick={onArchive}
                    >
                        <img alt="archive" src={archiveIcon} />
                        Archive
                    </li>
                    <li onClick={onUnconfirmedDelete}>
                        <img alt="delete" src={deleteIcon} />
                        Delete
                    </li>
                </ul>
            </div>
        </div>
    );
});
