import React from "react";
import { Spinner } from "@difftone/shared-components";

import { spinner_or_message } from "./spinner-or-timeout-message.module.css";

export type SpinnerOrTimeoutMessageProps = {
    message: string;
    spinnerFlag: boolean;
    spinnerSize?: "LARGE" | "MEDIUM" | "SMALL";
};

export const SpinnerOrTimeoutMessage = (
    props: SpinnerOrTimeoutMessageProps
) => {
    const { message, spinnerFlag, spinnerSize } = props;
    return (
        <div className={spinner_or_message}>
            {spinnerFlag ? (
                <Spinner size={spinnerSize} />
            ) : (
                <span>{message}</span>
            )}
        </div>
    );
};
