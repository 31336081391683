import React from "react";
import { observer } from "mobx-react";
import { leftMenuStore } from "@difftone/stores";

import {
    spinner_wrapper,
    large_loader,
    small_loader,
    xs_small_loader,
    medium_loader,
    left_menu_open,
} from "./spinner.module.css";

export type SpinnerProps = {
    size?: "LARGE" | "MEDIUM" | "SMALL" | "X-SMALL";
    className?: string;
};

export const Spinner = observer((props: SpinnerProps) => {
    const { size, className } = props;
    const { isOpen } = leftMenuStore;

    let loaderSize = null;

    switch (size) {
        case "X-SMALL":
            loaderSize = xs_small_loader;
            break;
        case "SMALL":
            loaderSize = small_loader;
            break;
        case "MEDIUM":
            loaderSize = medium_loader;
            break;
        case "LARGE":
            loaderSize = large_loader;
            break;
        default:
            loaderSize = large_loader;
    }

    return (
        <div
            className={
                isOpen
                    ? [spinner_wrapper, left_menu_open, className].join(" ")
                    : [spinner_wrapper, className].join(" ")
            }
        >
            <div className={loaderSize} />
        </div>
    );
});
