import React from "react";
import { observer } from "mobx-react";

import { previewSurveyStore } from "@difftone/stores";

import {
    survey_introduction_card,
    survey_introduction_card_text,
} from "./survey-introduction-card.module.css";

export type SurveyIntroductionCardProps = {
    surveyIntroduction: string;
};

export const SurveyIntroductionCard = observer(
    (props: SurveyIntroductionCardProps) => {
        const { surveyIntroduction } = props;
        const { inPreviewMode } = previewSurveyStore;

        return (
            <div
                className={survey_introduction_card}
                style={inPreviewMode ? { width: "100%" } : {}}
            >
                <div className={survey_introduction_card_text}>
                    {surveyIntroduction}
                </div>
            </div>
        );
    }
);
