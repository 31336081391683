import { Question, RatingQuestion, QuestionType } from "@difftone/types";

export type CategoriesRatingMap = Record<string, {
	majorityRating: number;
}>;

const getMostFrequentNumberInArray = (numbers: number[]) => {
	let mostFrequent = 1;
	let mostFrequentTemp = 0;
	let item;

	numbers.forEach((number) => {
		numbers.forEach((innerNumber) => {
			if (number == innerNumber) {
				mostFrequentTemp++;
			}

			if (mostFrequent < mostFrequentTemp) {
				item = number;
			}

			mostFrequent = Math.max(mostFrequent, mostFrequentTemp);
		});

		mostFrequentTemp = 0;
	});

	return numbers.length === 1 ? numbers[0] : item;
}

export const formatCategoriesRatingMap = (anyQuestions: Question<QuestionType>[]): CategoriesRatingMap => {
	const questions: Question<RatingQuestion>[] = anyQuestions.filter(({ question_type_name }) => question_type_name === "RATING") as Question<RatingQuestion>[];
	const categoriesRatingPreMap: Record<string, number[]> = {};
	const categoriesRatingMap: CategoriesRatingMap = {};

	questions.forEach((question) => {
		const categoryName = question.categories[0];

		if (!categoriesRatingPreMap.hasOwnProperty(categoryName)) {
			categoriesRatingPreMap[categoryName] = [question.content.rating_options.length];
		} else {
			categoriesRatingPreMap[categoryName].push(question.content.rating_options.length);
		}
	});

	Object.keys(categoriesRatingPreMap).forEach((key) => {
		const array = categoriesRatingPreMap[key];

		categoriesRatingMap[key] = {
			majorityRating: getMostFrequentNumberInArray(array) || 0,
		};
	});

	return categoriesRatingMap;
}
