import React, { ChangeEvent } from "react";
import { binaryFilterOptions } from "@difftone/stores";
import { ResponderToAnswer } from "@difftone/types";

import {
    binary_options,
    checked_choice_style,
    custom_checkbox_class,
    checked_marker,
    unchecked_marker,
    icon_holder_label,
} from "./binary-options.module.css";

export type BinaryOptionsProps = {
    responses: ResponderToAnswer[];
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    excludedItemsList: string[];
};

export const BinaryOptions = (props: BinaryOptionsProps) => {
    const { onChange, excludedItemsList } = props;

    return (
        <div className={binary_options}>
            {
                <ul>
                    {binaryFilterOptions.map((choice) => {
                        const isChecked = !excludedItemsList.includes(choice);
                        return (
                            <li key={choice}>
                                <input
                                    onChange={onChange}
                                    data-id={choice}
                                    id={choice}
                                    type="checkbox"
                                    checked={isChecked}
                                />
                                <label
                                    className={
                                        isChecked
                                            ? checked_choice_style
                                            : custom_checkbox_class
                                    }
                                    htmlFor={choice}
                                ></label>
                                <label
                                    className={icon_holder_label}
                                    htmlFor={choice}
                                >
                                    {choice === "CHECKED" ? (
                                        <div className={checked_marker}></div>
                                    ) : (
                                        <div className={unchecked_marker}></div>
                                    )}
                                </label>
                            </li>
                        );
                    })}
                </ul>
            }
        </div>
    );
};
