export const EPSILON: number = 0.000001;
export const getNormalizedDeviation = (
    numericValues: number[],
    descreteValue: number
): number => {
    numericValues = numericValues?.filter((value) => !isNaN(value));

    if (!numericValues || numericValues.length === 0) {
        return NaN;
    }
    const average: number = getGeometricAverageForNumericArray(numericValues);
    const numerator = descreteValue - average;
    const standardDeviation: number = getStandardDeviation(numericValues);

    if (standardDeviation === 0 && numerator > EPSILON) {
        return NaN;
        // TODO: Think how to deal with this case. This case means that the data is corrupted!
        //       For demo purposes - return NaN
        // throw new Error("getNormalizedDeviation():: standard deviation is 0! Cannot devide by zero!");
    }
    const standardDifference: number =
        standardDeviation <= EPSILON && numerator <= EPSILON
            ? 0
            : numerator / standardDeviation;
    return getNormalizedDeviationByStandardDeviation(standardDifference);
};

export const getStandardDeviation = (numericValues: number[]): number => {
    if (!numericValues || numericValues.length === 0) {
        throw new Error(
            "getStandardDeviation()::Please enter valid array of values!"
        );
    }
    const n = numericValues.length;
    const mean = numericValues.reduce((a, b) => a + b) / n;
    return Math.sqrt(
        numericValues
            .map((x) => Math.pow(x - mean, 2))
            .reduce((a, b) => a + b) / n
    );
};

export const getGeometricAverageForNumericArray = (
    numericValues: number[]
): number => {
    if (!numericValues || numericValues.length === 0) {
        throw new Error(
            "getGeometricAverageForNumericArray()::Please enter valid array of values!"
        );
    }
    return (
        numericValues.reduce((prev, curr) => prev + curr) / numericValues.length
    );
};

const getNormalizedDeviationByStandardDeviation = (
    standardDeviation: number
): number => {
    if (standardDeviation >= 1.5) {
        return 3;
    }
    if (standardDeviation >= 0.75 && standardDeviation < 1.5) {
        return 2;
    }
    if (standardDeviation >= 0.25 && standardDeviation < 0.75) {
        return 1;
    }
    if (standardDeviation > -0.25 && standardDeviation < 0.25) {
        return 0;
    }
    if (standardDeviation > -0.75 && standardDeviation <= -0.25) {
        return -1;
    }
    if (standardDeviation > -1.5 && standardDeviation <= -0.75) {
        return -2;
    }
    // last case: standardDeviation <= -1.5
    return -3;
};
