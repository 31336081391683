import { UUID } from "@difftone/types";
import { makeAutoObservable, observable, ObservableMap } from "mobx";
import {
    getAverageForSurvey,
    getAverageSansPForSurvey,
    getMedianForSurvey,
} from "./statistics-store-internal-actions";

class StatisticsStore {
    constructor() {
        makeAutoObservable<
            StatisticsStore,
            | "_fetchedAverageKeys"
            | "_fetchedMedianKeys"
            | "_fetchedAveagreSansPMKeys"
        >(this, {
            _fetchedAverageKeys: false,
            _fetchedMedianKeys: false,
            _fetchedAveagreSansPMKeys: false,
        });
    }

    private _fetchedAverageKeys: UUID[] = [];

    private _statisticsAverageMap: ObservableMap<UUID, number | null> =
        observable.map({});

    public getAverageBySurveyUUID(surveyUUID: UUID) {
        const results = this._statisticsAverageMap.get(surveyUUID);

        if (
            results === undefined &&
            !this._fetchedAverageKeys.includes(surveyUUID)
        ) {
            this._fetchedAverageKeys.push(surveyUUID);
            getAverageForSurvey(surveyUUID);
        }
        return results;
    }

    public setStatisticsAverageMap(
        surveyUUID: UUID,
        newAverage: number | null
    ) {
        this._statisticsAverageMap.merge({ [surveyUUID]: newAverage });
    }

    private _fetchedMedianKeys: UUID[] = [];

    private _statisticsMedianMap: ObservableMap<UUID, number | null> =
        observable.map({});

    public getMedianBySurveyUUID(surveyUUID: UUID) {
        const results = this._statisticsMedianMap.get(surveyUUID);

        if (
            results === undefined &&
            !this._fetchedMedianKeys.includes(surveyUUID)
        ) {
            this._fetchedMedianKeys.push(surveyUUID);
            getMedianForSurvey(surveyUUID);
        }
        return results;
    }

    public setStatisticsMedianMap(surveyUUID: UUID, newMedian: number | null) {
        this._statisticsMedianMap.merge({ [surveyUUID]: newMedian });
    }

    private _fetchedAveagreSansPMKeys: UUID[] = [];

    private _statisticsAverageSansPMap: ObservableMap<UUID, number | null> =
        observable.map({});

    public getAverageSansPBySurveyUUID(surveyUUID: UUID) {
        const results = this._statisticsAverageSansPMap.get(surveyUUID);

        if (
            results === undefined &&
            !this._fetchedAveagreSansPMKeys.includes(surveyUUID)
        ) {
            this._fetchedAveagreSansPMKeys.push(surveyUUID);
            getAverageSansPForSurvey(surveyUUID);
        }

        return results;
    }

    public setStatisticsAverageSansPMap(
        surveyUUID: UUID,
        newAverageSansP: number | null
    ) {
        this._statisticsAverageSansPMap.merge({
            [surveyUUID]: newAverageSansP,
        });
    }

    public clearStore = () => {
        this._statisticsAverageMap = observable.map({});
        this._statisticsMedianMap = observable.map({});
        this._statisticsAverageSansPMap = observable.map({});
    };
}

export const statisticsStore = new StatisticsStore();
