import React from "react";
import { observer } from "mobx-react";

import {
    getAverageResponseRate,
    getCountAllQuestions,
    dashboardFilteredSurveys,
    dashboardSurveyTableList,
    filteredSurveysDashboard,
    getAverageNumOfParticipants,
    getSelectedOrganizationUUID,
    getOrganizations,
    dashboardTimelineSurveys,
} from "@difftone/reducers";
import {
    dashboardDisplayStore,
    organizationStore,
    surveyStore,
} from "@difftone/stores";
import {
    ClientWidthRenderPicker,
    Header,
    MobileHeader,
    Spinner,
} from "@difftone/shared-components";
import { setDefaultDashboardFilterBy } from "@difftone/actions";
import { NO_MOBILE_PLACEHOLDER_TEXT } from "@difftone/constants";

import {
    DashboardCell,
    DashboardFilterBy,
    DashboardSurveysTimeline,
    SurveyFilterByPurpose,
    SurveyListDisplay,
} from "./components";
import {
    dashboard,
    dashboard_body,
    dashboard_desktop_wrapper,
    dashboard_container,
    dashboard_cells,
    survey_info,
    dashboard_mobile_notice,
} from "./dashboard.module.css";

export type DashboardProps = {};

export const Dashboard = observer((props: DashboardProps) => {
    const dashboardTableSurveyList = dashboardSurveyTableList(
        dashboardFilteredSurveys()
    );

    const timelineDisplaySurveys = dashboardTimelineSurveys(
        dashboardFilteredSurveys()
    );

    const filteredTableSurveys = filteredSurveysDashboard();

    const { dashboardReadyFilter } = dashboardDisplayStore;

    if (dashboardReadyFilter === null) {
        setDefaultDashboardFilterBy();
    }

    const getCountSurveyList = (): string => {
        const count = dashboardTableSurveyList.length;
        return count.toString();
    };

    const countSurveyList: string = getCountSurveyList();
    const countAllQuestions: string = getCountAllQuestions(
        dashboardTableSurveyList
    );
    const averageResponseRate: string = getAverageResponseRate(
        dashboardTableSurveyList
    );
    const averageNumOfParticipants: string = getAverageNumOfParticipants(
        dashboardTableSurveyList
    );

    const selectedOrganization = getSelectedOrganizationUUID();
    const organizationsList = getOrganizations();

    let _organization;

    if (selectedOrganization) {
        _organization =
            organizationStore.getOrganizationByUuid(selectedOrganization);
    }

    return (
        <div key={window.location.pathname} className={dashboard}>
            <ClientWidthRenderPicker
                desktopComponent={
                    <Header
                        organization={_organization}
                        organizationsList={organizationsList}
                    />
                }
                mobileComponent={<MobileHeader />}
            />
            {!surveyStore.storeInitialize ? (
                <Spinner />
            ) : (
                <div className={dashboard_body}>
                    <ClientWidthRenderPicker
                        desktopComponent={
                            <div className={dashboard_desktop_wrapper}>
                                <div className={dashboard_container}>
                                    <DashboardFilterBy />
                                    <div className={dashboard_cells}>
                                        <DashboardCell
                                            header="Surveys sent"
                                            value={countSurveyList}
                                        />
                                        <DashboardCell
                                            header="Questions asked"
                                            value={countAllQuestions}
                                        />
                                        <DashboardCell
                                            header="Average response rate"
                                            value={averageResponseRate}
                                        />
                                        <DashboardCell
                                            header="Average number of participants"
                                            value={`${averageNumOfParticipants}`}
                                        />
                                    </div>
                                    <div className={survey_info}>
                                        <SurveyListDisplay
                                            surveys={filteredTableSurveys}
                                        />
                                        <SurveyFilterByPurpose
                                            surveys={dashboardTableSurveyList}
                                        />
                                    </div>
                                    <DashboardSurveysTimeline
                                        surveys={timelineDisplaySurveys}
                                    />
                                </div>
                            </div>
                        }
                        mobileComponent={
                            <p className={dashboard_mobile_notice}>
                                {NO_MOBILE_PLACEHOLDER_TEXT}
                            </p>
                        }
                    />
                </div>
            )}
        </div>
    );
});
