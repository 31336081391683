import React from "react";
import { observer } from "mobx-react";

import { SharedSurvey } from "@difftone/types";
import { publicProfileStore } from "@difftone/stores";
import {
    acceptSharedSurveyToDraft,
    setLocation,
    showDifftoneAlert,
    updateToArchiveSharedSurvey,
} from "@difftone/actions";
import { HumanizeEmail } from "@difftone/shared-components";
import { constantAlertVariants, PAGE_URLS } from "@difftone/constants";

import { DeleteButton, TableDate } from "./components";
import {
    mobile_shared_table_row,
    shared_survey_name,
    initiator_email_and_time,
    mobile_table_row_is_archive,
    confirm_wrapper,
} from "./mobile-shared-table-row.module.css";

export type MobileSharedTableRowProps = {
    sharedSurvey: SharedSurvey;
};

export const MobileSharedTableRow = observer(
    (props: MobileSharedTableRowProps) => {
        const { sharedSurvey } = props;
        const { survey_name } = sharedSurvey.survey;
        const { sender } = sharedSurvey;

        const initiatorPublicProfile =
            publicProfileStore.getPublicProfileByUuid(sender);

        const initiatorEmail = initiatorPublicProfile
            ? initiatorPublicProfile.email
            : "unknown";

        const sharedSurveyIsArchive = sharedSurvey.archive;

        const onConfirmAccept = () => {
            if (sharedSurvey.archive) {
                return;
            }

            showDifftoneAlert(
                constantAlertVariants.shared_survey_accept.text,
                constantAlertVariants.shared_survey_accept.variant,
                onAccept,
                () => {},
                `${sharedSurvey.survey.survey_name}`
            );
        };

        const onAccept = () => {
            updateToArchiveSharedSurvey(sharedSurvey);
            acceptSharedSurveyToDraft(sharedSurvey);
            showDifftoneAlert(
                constantAlertVariants.shared_survey_accept_success.text,
                constantAlertVariants.shared_survey_accept_success.variant
            );
            setLocation(PAGE_URLS.INBOX_DRAFT);
        };

        return (
            <div
                tabIndex={0}
                className={
                    sharedSurveyIsArchive
                        ? mobile_table_row_is_archive
                        : mobile_shared_table_row
                }
            >
                <div onClick={onConfirmAccept} className={confirm_wrapper}>
                    <div className={shared_survey_name}>{survey_name}</div>
                    <div className={initiator_email_and_time}>
                        <HumanizeEmail humanizeEmail={initiatorEmail} />
                        <TableDate miliseconds={sharedSurvey.timestamp} />
                    </div>
                </div>
                <DeleteButton sharedSurvey={sharedSurvey} />
            </div>
        );
    }
);
