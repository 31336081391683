import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core";

import {
    SimpleResultsFilterButton,
    ShowDifftoneTooltip,
    SimpleResultsSortingActionButton,
} from "@difftone/shared-components";
import { simpleSurveyResultsStore } from "@difftone/stores";
import {
    setSimpleResultsFitlerProfile,
    setSimpleResultsFitlerProfileFocusedColumn,
    setSimpleResultsSortingProfile,
    clearSimpleResultsSortingProfile,
} from "@difftone/actions";
import {
    SimpleResultsFilterProfile,
    SimpleResultsSortingProfile,
} from "@difftone/types";

import {
    header_data,
    options_stats_box,
    option_diagonal_text,
    text_value,
    count,
    percent,
    filter_button,
    last_header_right_border,
    sorter_btton,
} from "./simple-survey-header-data.module.css";

const ALLOWED_HEADER_LENGTH = 10;

const tooltipExtendPosition = makeStyles({
    tooltipExtendedPosition: {
        left: "0",
        top: "20px",
    },
});

export type SimpleSurveyHeaderDataProps = {
    option: string;
    totalCompleted: number;
    optionAsAnswerCount: number;
    isLastHeader: boolean;
};

export const SimpleSurveyHeaderData = observer(
    (props: SimpleSurveyHeaderDataProps) => {
        const { option, totalCompleted, optionAsAnswerCount, isLastHeader } =
            props;
        const { filterProfile, sortingProfile, selectedQuestion } =
            simpleSurveyResultsStore;

        const extendTooltipPosition =
            tooltipExtendPosition().tooltipExtendedPosition;

        const optionWithValidLength =
            option.length > ALLOWED_HEADER_LENGTH
                ? `${option.slice(0, ALLOWED_HEADER_LENGTH)}...`
                : option;

        const asPercent = (optionAsAnswerCount * 100) / totalCompleted || 0;

        const onMouseOverHandler = () => {
            setSimpleResultsFitlerProfileFocusedColumn(option);
        };

        const onMouseLeaveHandler = () => {
            setSimpleResultsFitlerProfileFocusedColumn("");
        };

        const onClickHandler = () => {
            const copyOfFilterProfile: SimpleResultsFilterProfile = {
                ...filterProfile,
            };
            const copyOfSortingProfile: SimpleResultsSortingProfile = {
                ...sortingProfile,
            };

            copyOfFilterProfile.filterdColumnKey =
                copyOfFilterProfile.focusedColumnKey;
            copyOfSortingProfile.sortBy = "NONTEXT";

            // First sort click
            if (filterProfile.filterdColumnKey !== option) {
                copyOfSortingProfile.direction = "ASCENDING";
                setSimpleResultsFitlerProfile(copyOfFilterProfile);
                setSimpleResultsSortingProfile(copyOfSortingProfile);

                return;
            }

            // Second sort click
            if (copyOfSortingProfile.direction === "ASCENDING") {
                copyOfSortingProfile.direction = "DESCENDING";
                setSimpleResultsSortingProfile(copyOfSortingProfile);

                return;
            }

            // Third sort click
            if (copyOfSortingProfile.direction === "DESCENDING") {
                setSimpleResultsFitlerProfile({
                    ...copyOfFilterProfile,
                    filterdColumnKey: "",
                    focusedColumnKey: "",
                });
                clearSimpleResultsSortingProfile();

                return;
            }
        };

        const _isVisible = filterProfile.focusedColumnKey === option;

        const _isActive = filterProfile.filterdColumnKey === option;

        let optionTitle = null;

        if (option.length > ALLOWED_HEADER_LENGTH) {
            optionTitle = (
                <ShowDifftoneTooltip
                    extendTooltipStyle={extendTooltipPosition}
                    tip={option}
                    tooltipPosition="top"
                >
                    {`${option.slice(0, ALLOWED_HEADER_LENGTH)}...`}
                </ShowDifftoneTooltip>
            );
        } else {
            optionTitle = option;
        }

        return (
            <div
                onMouseLeave={onMouseLeaveHandler}
                onMouseOver={onMouseOverHandler}
                className={header_data}
            >
                <div className={option_diagonal_text}>
                    <div className={text_value}>{optionTitle}</div>
                </div>
                <div className={options_stats_box}>
                    <div
                        className={count}
                    >{`${optionAsAnswerCount}/${totalCompleted}`}</div>
                    <div className={percent}>{`${asPercent.toFixed(0)}%`}</div>
                </div>
                <div className={sorter_btton}>
                    <SimpleResultsSortingActionButton
                        isActive={filterProfile.filterdColumnKey === option}
                        isVisible={_isVisible || _isActive}
                        direction={sortingProfile.direction}
                        onClickHandler={onClickHandler}
                    />
                </div>
                <div className={filter_button}>
                    <SimpleResultsFilterButton
                        isVisible={_isVisible || _isActive}
                        columnKey={option}
                        isFiltered={
                            !!filterProfile.filterdProfilesMap[
                                `${selectedQuestion},${option}`
                            ]?.length
                        }
                    />
                </div>
                {isLastHeader ? (
                    <div className={last_header_right_border}></div>
                ) : null}
            </div>
        );
    }
);
