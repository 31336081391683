import { action } from "mobx";

import { archiveStore } from "./archive-store";
import { BaseSurvey, UUID } from "@difftone/types";
import { getArchivedSurveysFromApiByUserUuid } from "@difftone/services";

export const fetchAllArchivedSurveys = action(async () => {
    const archivedSurveys = await getArchivedSurveysFromApiByUserUuid();

    archivedSurveys.forEach((survey: BaseSurvey) => {
        archivedSurveyToMapSetter(survey.uuid, survey);
    });

    archiveStore.isStoreInitialized = true;
});

const archivedSurveyToMapSetter = action(
    (surveyUUID: UUID, survey: null | BaseSurvey) => {
        archiveStore.setArchivedSurveyToMap(surveyUUID, survey);
    }
);
