import { Url } from "@difftone/types";

export const ACTIVATION_URL_PARM = "activated_survey";

export const ACTIVATE_FROM_EMAIL_PARAM = "activate_from_email";

export type Pages =
    | "INBOX_ALL"
    | "INBOX_DRAFT"
    | "INBOX_INITIATOR"
    | "INBOX_ONGOING"
    | "INBOX_NEEDS_ATTENTION"
    | "INBOX_NOT_COMPLETED"
    | "INBOX_RESPONDENT"
    | "INBOX_SHARED_WITH_ME"
    | "INBOX_TEMPLATES"
    | "INBOX_ARCHIVED"
    | "INBOX_SHARED_VIEWS_WITH_ME"
    | "DASHBOARD";

export const INBOX_URL = "/inbox";

export const DASHBOARD = "/dashboard";

export const PAGE_URLS: Record<Pages, Url> = {
    INBOX_ALL: `${INBOX_URL}/all`,
    INBOX_DRAFT: `${INBOX_URL}/draft`,
    INBOX_INITIATOR: `${INBOX_URL}/initiator`,
    INBOX_ONGOING: `${INBOX_URL}/ongoing`,
    INBOX_NEEDS_ATTENTION: `${INBOX_URL}/need-attention`,
    INBOX_NOT_COMPLETED: `${INBOX_URL}/not-completed`,
    INBOX_RESPONDENT: `${INBOX_URL}/respondent`,
    INBOX_SHARED_WITH_ME: `${INBOX_URL}/sharedsurveys`,
    INBOX_TEMPLATES: `${INBOX_URL}/templates`,
    INBOX_ARCHIVED: `${INBOX_URL}/archived`,
    INBOX_SHARED_VIEWS_WITH_ME: `${INBOX_URL}/sharedviewswithme`,
    DASHBOARD: `${DASHBOARD}`,
};

export const PAGE_DISPLAY_NAMES: Record<string, Url> = {
    all: `${INBOX_URL}/all`,
    draft: `${INBOX_URL}/draft`,
    initiator: `${INBOX_URL}/initiator`,
    ongoing: `${INBOX_URL}/ongoing`,
    "need-attention": `${INBOX_URL}/need-attention`,
    "not-completed": `${INBOX_URL}/not-completed`,
    respondent: `${INBOX_URL}/respondent`,
    sharedsurveys: `${INBOX_URL}/sharedsurveys`,
    templates: `${INBOX_URL}/templates`,
    archived: `${INBOX_URL}/archived`,
    sharedviewswithme: `${INBOX_URL}/sharedviewswithme`,
    dashboard: `${DASHBOARD}`,
};
