import React, { useState, MouseEvent } from "react";

import { TableHeaderCell, FilterTypes } from "./components";

import {
    table_header,
    survey_name_class,
    initiator_class,
    issued_class,
} from "./table-header.module.css";

export const TableHeader = () => {
    const [activeFilter, setActiveFilter] = useState<FilterTypes | null>(
        "RECEIVED"
    );

    const setFilter = (event: MouseEvent<HTMLDivElement>) => {
        const filterType = event.currentTarget.getAttribute(
            "data-filter"
        ) as FilterTypes;
        if (filterType) {
            setActiveFilter(filterType);
        }
    };

    return (
        <div className={table_header}>
            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "NAME"}
                filterType="NAME"
                text="Name"
                className={survey_name_class}
            />

            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "FROM"}
                filterType="FROM"
                text="From"
                className={initiator_class}
            />
            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "RECEIVED"}
                filterType="RECEIVED"
                text="Received"
                className={issued_class}
            />
        </div>
    );
};
