import type { EmailAddress } from "@difftone/types";
import { localstorageUtils } from "@difftone/procedures";
import { IConnectionListService, IConnectionListFactory } from "../contracts";
import { ConnectionListFactory } from "./connection-list-factory";

export class ConnectionListService implements IConnectionListService {
    private readonly _connectionListFactory: IConnectionListFactory;
    constructor() {
        this._connectionListFactory = new ConnectionListFactory();
    }

    public async getUserConnectionsEmailsList(): Promise<EmailAddress[]> {
        try {
            const currentAuthData =
                await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

            const connectionListProvider =
                this._connectionListFactory.getConnectionListProviderByAuthProviderName(
                    currentAuthData.issuer
                );
            return await connectionListProvider.getUserFullConnectionsEmailsList();
        } catch (error) {
            throw new Error(
                `ConnectionListService.getUserConnectionsEmailsList() error:: ${
                    (error as Error).message
                }`
            );
        }
    }
}
