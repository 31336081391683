import { makeAutoObservable, observable, ObservableMap } from "mobx";
import {
    EmailAddress,
    GooglePersonNameData,
    BaseSurvey,
    UUID,
    SurveyClasses,
    SimpleSurvey,
} from "@difftone/types";
import {
    addEmailsToSurveySystemAlerts,
    showDifftoneAlert,
} from "@difftone/actions";
import { debounceFunction } from "@difftone/frontend-common";

import {
    checkIfEmailIsGroup,
    convertAndInsertGoogleFormToStore,
    fetchAllArchivedSurveys,
    getSurveysByUserUuidNEmail,
    openSurveyFromApiByUUID,
    setStoreInitialize,
    surveyFromApiByUUID,
    upsertedSurveyToApi,
} from "./survey-store-internal-actions";

export type SurveyFilter = (survey: BaseSurvey) => boolean;

class SurveyStore {
    constructor() {
        makeAutoObservable<SurveyStore, "_fetchedSurveysUuids">(this, {
            _fetchedSurveysUuids: false,
        });
    }

    private _storeInitialize: boolean = false;
    get storeInitialize(): boolean {
        return this._storeInitialize;
    }
    set storeInitialize(newStoreInitialize: boolean) {
        this._storeInitialize = newStoreInitialize;
    }

    private _startInitialization: boolean = false;
    get startInitialization(): boolean {
        return this._startInitialization;
    }
    set startInitialization(newStartInitialization: boolean) {
        this._startInitialization = newStartInitialization;
    }

    private _storeArchivedInitialize: boolean = false;
    get storeArchivedInitialize(): boolean {
        return this._storeArchivedInitialize;
    }
    set storeArchivedInitialize(newStoreArchiveInitialize: boolean) {
        this._storeArchivedInitialize = newStoreArchiveInitialize;
    }

    private _startArchiveInitialization: boolean = false;
    get startArchiveInitialization(): boolean {
        return this._startArchiveInitialization;
    }
    set startArchiveInitialization(newStartArchiveInitialization: boolean) {
        this._startArchiveInitialization = newStartArchiveInitialization;
    }

    public init = () => {
        getSurveysByUserUuidNEmail();
        this.startInitialization = true;
    };

    public loadArchivedSurveys = () => {
        fetchAllArchivedSurveys();
    };

    private _fetchedSurveysUuids: UUID[] = [];

    private _surveysMap: ObservableMap<UUID, SurveyClasses | null> =
        observable.map({});

    public surveyToMapSetter = (
        surveyUuid: UUID,
        survey: SurveyClasses | null
    ) => {
        this._surveysMap.merge({ [surveyUuid]: survey });
    };

    get surveysList(): SurveyClasses[] {
        const _surveysList = [...this._surveysMap.values()].filter(
            (value) => !!value && typeof value === "object" && !value.archived
        );

        if (!this.startInitialization && !!localStorage.getItem("authData")) {
            this.init();
        }
        //@ts-ignore
        return _surveysList;
    }

    get archivedSurveysList(): SurveyClasses[] {
        const _archivedSurveysList = [...this._surveysMap.values()].filter(
            (value) => !!value && typeof value === "object" && value.archived
        );
        //@ts-ignore
        return _archivedSurveysList;
    }

    public debounceAddSurveyToMap = (survey: SurveyClasses) => {
        this.debouncePutSurveyToApi(survey);
        this._surveysMap.merge({ [survey.uuid]: survey });
    };

    public addSurveyToMapWithoutDebounce = (newSurvey: SurveyClasses) => {
        const updateSurveyRespunse = this.putSurveyToApi(newSurvey);
        this._surveysMap.merge({ [newSurvey.uuid]: newSurvey });
        return updateSurveyRespunse;
    };

    public getSurveyByUuidForReducer = (
        surveyUuid: UUID
    ): SurveyClasses | undefined | null => {
        if (!surveyUuid) {
            throw new Error(`surveyUUID is ${surveyUuid}`);
        }

        const fetchedSurvey = this._surveysMap.get(surveyUuid);

        if (
            fetchedSurvey === undefined &&
            !this._fetchedSurveysUuids.includes(surveyUuid)
        ) {
            this._fetchedSurveysUuids.push(surveyUuid);
            surveyFromApiByUUID(surveyUuid);
        }

        return fetchedSurvey;
    };

    public getOpenSurveyByUuidForReducer = (
        surveyUuid: UUID
    ): SurveyClasses | undefined | null => {
        if (!surveyUuid) {
            throw new Error(`surveyUUID is ${surveyUuid}`);
        }

        const fetchedSurvey = this._surveysMap.get(surveyUuid);

        if (
            fetchedSurvey === undefined &&
            !this._fetchedSurveysUuids.includes(surveyUuid)
        ) {
            this._fetchedSurveysUuids.push(surveyUuid);
            openSurveyFromApiByUUID(surveyUuid);
        }

        return fetchedSurvey;
    };

    public removeDeletedSurveys = () => {
        const unifiedList: BaseSurvey[] = [
            ...this.surveysList,
            ...this.archivedSurveysList,
        ];
        unifiedList
            .filter((survey) => survey.deleted)
            .forEach((survey: BaseSurvey) =>
                this._surveysMap.delete(survey.uuid)
            );
    };

    public removeArchivedSurvey = () => {
        this.surveysList
            .filter((survey) => survey.archived)
            .forEach((survey: BaseSurvey) =>
                this._surveysMap.delete(survey.uuid)
            );
    };

    public addUnarchivedSurvey = (survey: SurveyClasses) => {
        this._surveysMap.merge({ [survey.uuid]: survey });
    };

    public putSurveyToApi = (surveyClasses: SurveyClasses) => {
        return upsertedSurveyToApi(surveyClasses).catch((err) => {
            console.error(err);
            showDifftoneAlert(
                "Could not update the survey. Please try again later.",
                "FAILURE"
            );
        });
    };

    public debouncePutSurveyToApi = debounceFunction(this.putSurveyToApi, 500);

    private _emailsMap: { [x: string]: GooglePersonNameData | null } = {};

    get emailsMap() {
        return this._emailsMap;
    }

    public addNameDataToEmailsMap = (
        email: EmailAddress,
        nameData: GooglePersonNameData | null
    ) => {
        this._emailsMap[email] = nameData;
    };

    private _groupEmailsMap: {
        [x: string]: EmailAddress[] | null;
    } = {};

    get groupEmailsMap() {
        return this._groupEmailsMap;
    }

    public addGroupToEmailsMap = (
        groupEmail: EmailAddress,
        members: EmailAddress[] | null
    ) => {
        this._groupEmailsMap[groupEmail] = members;
    };

    public async addEmailsToSurvey(
        emails: EmailAddress[],
        surveyUUID: UUID,
        listToAddTo: "ADMINS" | "RESPONDENTS",
        isEditingActiveSurvey: Boolean
    ) {
        const surveyToUpdate = this._surveysMap.get(surveyUUID);

        const emailsToAdd = await this.getEmailsToAddToSurvey(emails);
        if (!emailsToAdd) {
            showDifftoneAlert(
                "Unable to retrieve group email members",
                "FAILURE"
            );
            return;
        } else if (!surveyToUpdate) {
            showDifftoneAlert("Invalid survey", "FAILURE");
            return;
        }

        if (listToAddTo === "ADMINS") {
            surveyToUpdate.admins = Array.from(
                new Set([...surveyToUpdate.admins, ...emailsToAdd])
            );
        } else {
            surveyToUpdate.respondents = Array.from(
                new Set([...surveyToUpdate.respondents, ...emailsToAdd])
            );
        }
        if (isEditingActiveSurvey) {
            showDifftoneAlert(
                `Email(s) successfully added to the survey's participants.`,
                "SUCCESS"
            );

            if (surveyToUpdate.survey_class === "SIMPLE") {
                addEmailsToSurveySystemAlerts(
                    emailsToAdd,
                    surveyToUpdate as SimpleSurvey
                );
            }
        }
        this.putSurveyToApi(surveyToUpdate);
    }

    private async getEmailsToAddToSurvey(
        emails: EmailAddress[]
    ): Promise<EmailAddress[] | null> {
        const processedEmailPromises = [];
        const processEmail = async (email: EmailAddress) => {
            const isGroupEmail = await checkIfEmailIsGroup(email);
            if (isGroupEmail) {
                return [];
            } else {
                return [email];
            }
        };
        for (const email of emails) {
            processedEmailPromises.push(processEmail(email));
        }

        const processedEmails = await Promise.all(processedEmailPromises);
        return processedEmails.flat();
    }

    public importGoogleForm(googleFormId: string, withResults: boolean) {
        convertAndInsertGoogleFormToStore(googleFormId, withResults);
    }

    public clearStore = () => {
        this._surveysMap = observable.map({});
        this.startInitialization = false;
        setStoreInitialize(false);
        this._groupEmailsMap = {};
    };
}

export const surveyStore = new SurveyStore();
