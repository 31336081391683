import { HtmlTemplate } from "@difftone/types";
import handlebars from "handlebars";

export const replaceEmailTemplateValues = (
    template: HtmlTemplate,
    replacements: { [key: string]: string | null }
): HtmlTemplate => {
    handlebars.registerHelper(
        "helperMissing",
        (token) => "{{" + token.name + "}}"
    );
    const compiledHtmlEmailTemplate = handlebars.compile(template);

    const compiledEmailContent = compiledHtmlEmailTemplate(replacements);

    return compiledEmailContent;
};
