import {
    CATEGORY_FILTER_ID,
    DISPLAY_SURVEY_ROLES,
    SURVEY_STATUS,
} from "@difftone/constants";
import { UiStatus } from "@difftone/frontend-common";
import { Buffer } from "buffer";
import {
    alertsStore,
    navigationStore,
    surveyResponseStatusStore,
    surveyResponseStore,
    surveyStore,
} from "@difftone/stores";
import {
    addDaysToDateInMils,
    getUtcDateAtMidnightInMils,
    getUtcDateAtMidnightInMilsWithAddingDays,
    totalTimeInMiliSecondsFromString,
    SET_ONE_DAY_AHEAD,
    SET_ONE_WEEK_AHEAD,
} from "@difftone/time-utils";
import {
    DisplaySurvey,
    EmailAddress,
    Role,
    BaseSurvey,
    SurveyEventAlert,
    SystemSurveyEventType,
    UUID,
    Question,
    QuestionType,
    SurveyEventAlertStatusType,
    SimpleSurvey,
    OngoingSurvey,
    SurveyClasses,
    RatingQuestion,
} from "@difftone/types";
import { getUserEmail, getUserUuid } from "@difftone/procedures";
import dayjs from "dayjs";

export const getSurveyUUIDFromUrl = (): string | null => {
    const { currentPage } = navigationStore;
    let surveyUuid: UUID | null = null;

    const currentPageUrl = currentPage.split("/");

    switch (currentPageUrl[1]) {
        case "results":
            surveyUuid = currentPageUrl[2];
            break;
        case "inbox":
            surveyUuid = currentPageUrl[4];
            break;
        case "survey-wizard":
            surveyUuid = currentPageUrl[2];
            break;
        case "survey":
            const encodedParams = currentPage.split("/").pop()?.toString();
            surveyUuid =
                getUUidAndEmailFromUrl(encodedParams!)?.surveyUuid || null;
            break;
        case "duplicate":
            surveyUuid = currentPageUrl[4];
            break;
        default:
            surveyUuid = currentPageUrl.pop() || null;
            break;
    }

    return surveyUuid;
};

export const getQueryParamsFromUrl = (): URLSearchParams => {
    const currentSearch = navigationStore.currentSearch;
    return new URLSearchParams(currentSearch);
};

export const getCategoryFilterValuesAndActiveStatus = (): {
    categoryFilterValues: string[];
    isCategoryFilterActive: boolean;
} => {
    let categoryFilterValues: string[] = [];
    const filterParams =
        getQueryParamsFromUrl().get("filter")?.split(",") || [];
    const decodedFiltersParams = filterParams.map((encodedFilter) =>
        JSON.parse(atob(encodedFilter))
    );
    const decodedCategoryFilter = decodedFiltersParams.find(
        (decodedFilter) => decodedFilter[0] === CATEGORY_FILTER_ID
    );

    if (decodedCategoryFilter && decodedCategoryFilter.length) {
        categoryFilterValues = decodedCategoryFilter[1];
    }

    return {
        categoryFilterValues,
        isCategoryFilterActive: decodedCategoryFilter,
    };
};

export const getSurveyByUuid = (
    surveyUUID: UUID
): SurveyClasses | undefined | null => {
    return surveyStore.getSurveyByUuidForReducer(surveyUUID);
};

export const getSurveyForInitiatorBySurveyUuid = (
    surveyUUID: UUID
): SurveyClasses | undefined | null => {
    if (!surveyUUID) {
        throw Error(
            "[getSurveyForInitiatorBySurveyUuid]:: Something went wrong in survey reducer"
        );
    }

    const survey = surveyStore.getSurveyByUuidForReducer(surveyUUID);

    if (survey && survey.initiator !== getUserUuid()) {
        return null;
    }

    return survey;
};

export const getSurveyForParticipantBySurveyUuid = (
    surveyUUID: UUID,
    isOpenSurvey?: boolean
): SurveyClasses | undefined | null => {
    if (!surveyUUID) {
        throw Error(
            "[getSurveyForParticipantBySurveyUuid]:: Something went wrong in survey reducer"
        );
    }

    if (!!isOpenSurvey) {
        return surveyStore.getOpenSurveyByUuidForReducer(surveyUUID);
    }

    const _userEmail = getUserEmail();

    const survey = surveyStore.getSurveyByUuidForReducer(surveyUUID);

    if (survey && !survey.respondents.includes(_userEmail)) {
        return null;
    }

    return survey;
};

export const getSurveyForAdminBySurveyUuid = (
    surveyUUID: UUID
): SurveyClasses | undefined | null => {
    if (!surveyUUID) {
        throw Error(
            "[getSurveyForAdminBySurveyUuid]:: Something went wrong in survey reducer"
        );
    }

    const survey = surveyStore.getSurveyByUuidForReducer(surveyUUID);

    if (survey && !survey.admins.includes(getUserEmail())) {
        return null;
    }

    return survey;
};

export const geySurveyForAdminAndInitiatorBySurveyUuid = (surveyUUID: UUID) => {
    if (!surveyUUID) {
        throw Error(
            "[geySurveyForAdminAndInitiatorBySurveyUuid]:: Something went wrong in survey reducer"
        );
    }

    const survey = surveyStore.getSurveyByUuidForReducer(surveyUUID);

    if (survey === undefined || survey === null) {
        return survey;
    }

    if (survey.admins.includes(getUserEmail())) {
        return survey;
    }

    if (survey.initiator === getUserUuid()) {
        return survey;
    }

    return null;
};

export const getSurveyFromStoreByUUID = (
    surveyUUID: UUID
): SurveyClasses | undefined | null => {
    return getSurveyByUuid(surveyUUID);
};

export const getSurveyFromStoreByURL = (): SurveyClasses => {
    const surveyUUID = getSurveyUUIDFromUrl();

    if (!surveyUUID) {
        throw Error(
            "[getSurveyFromStoreByURL]:: Something went wrong in survey reducer"
        );
    }
    return getSurveyByUuid(surveyUUID) as SurveyClasses;
};

export const getUUidAndEmailFromUrl = (
    urlParam: string
): { surveyUuid: UUID; email: EmailAddress } | null => {
    const emailAndUuid = { surveyUuid: "", email: "" };
    if (isBase64(urlParam)) {
        const decodedParams = Buffer.from(urlParam, "base64").toString("ascii");
        const splitParams = decodedParams.split("/");
        if (splitParams.length !== 2) {
            return null;
        }
        // TODO: validate Email correctness by regex
        emailAndUuid.surveyUuid = splitParams[0];
        emailAndUuid.email = splitParams[1];
    } else if (urlParam.length) {
        emailAndUuid.surveyUuid = urlParam;
    }

    return emailAndUuid;
};

export const getRatingRange = (ratingQuestions: Question<RatingQuestion>[]) => {
    const getMode = (arr: number[]): number | undefined => {
        return arr
            .sort(
                (a, b) =>
                    arr.filter((v) => v === a).length -
                    arr.filter((v) => v === b).length
            )
            .pop();
    };

    const firstValues = ratingQuestions.map(
        ({ content }) => content.rating_options[0].value
    );

    const lastValues = ratingQuestions.map(
        ({ content }) =>
            content.rating_options[content.rating_options.length - 1].value
    );

    const lowerMode = getMode(firstValues);
    const upperMode = getMode(lastValues);

    return `${lowerMode}-${upperMode}`;
};

export const getStatus = (survey: BaseSurvey, role?: Role) => {
    switch (role) {
        case DISPLAY_SURVEY_ROLES.INITIATOR:
            return getStatusForAdmin(survey);
        case DISPLAY_SURVEY_ROLES.RESPONDER:
            return getResponderStatus(survey);
        default:
            return getStatusForAdmin(survey);
    }
};

const isBase64 = (stringToCheck: string): boolean => {
    if (stringToCheck === "" || stringToCheck.trim() === "") {
        return false;
    }
    try {
        return btoa(atob(stringToCheck)) == stringToCheck;
    } catch (err) {
        return false;
    }
};

const getStatusForAdmin = (survey: BaseSurvey): UiStatus => {
    if (!survey) {
        return SURVEY_STATUS.DRAFT as UiStatus;
    }

    switch (survey.survey_class) {
        case "ONGOING":
            const ongoingSurvey = survey as OngoingSurvey;
            return getOngoingSurveyStatusForAdmin(ongoingSurvey);

        case "SIMPLE":
        default:
            const simpleSurvey = survey as SimpleSurvey;
            return getSimpleSurveyStatusForAdmin(simpleSurvey);
    }
};

const getOngoingSurveyStatusForAdmin = (survey: OngoingSurvey): UiStatus => {
    if (survey.manually_ended) {
        return "ENDED";
    }
    switch (survey.status) {
        case "DRAFT":
            return SURVEY_STATUS.DRAFT as UiStatus;
        case "READY":
            return SURVEY_STATUS.READY as UiStatus;
        case "ACTIVE":
            return SURVEY_STATUS.ACTIVE as UiStatus;
    }
};

const getSimpleSurveyStatusForAdmin = (survey: SimpleSurvey): UiStatus => {
    const _lastDateToFill = getLastDateToFill(survey);

    const _isCompleted = getSurveyProgress(survey) === 100 ? true : false;

    if (survey.status === SURVEY_STATUS.DRAFT) {
        return SURVEY_STATUS.DRAFT as UiStatus;
    }

    if (survey.status === SURVEY_STATUS.PAUSED) {
        return SURVEY_STATUS.PAUSED as UiStatus;
    }

    if (
        (survey.status === SURVEY_STATUS.READY ||
            survey.status === SURVEY_STATUS.ACTIVE) &&
        _lastDateToFill! < Date.now()
    ) {
        return "ENDED";
    }

    if (
        (survey.status === SURVEY_STATUS.READY ||
            survey.status === SURVEY_STATUS.ACTIVE) &&
        _isCompleted
    ) {
        return "COMPLETED";
    }

    if (
        survey.status === SURVEY_STATUS.ACTIVE &&
        _lastDateToFill! > Date.now()
    ) {
        return "ACTIVE";
    }

    if (survey.status === SURVEY_STATUS.READY) {
        return "SAVED";
    }
};

export const getSurveyTimeLeftSortBy = (survey: SimpleSurvey) => {
    if (!survey) {
        return 0;
    }

    const lastDateToFill = getLastDateToFill(survey);
    const nowInMilSeconds = dayjs.utc(new Date().getTime()).valueOf();
    const timeLeft = lastDateToFill! - nowInMilSeconds;
    return timeLeft;
};

export const getOngoingSurveyTimeLeftSortBy = (survey: OngoingSurvey) => {
    if (!survey) {
        return 0;
    }

    const timeLeftOngoing = survey.manually_ended
        ? survey.manually_ended
        : Infinity;
    const nowInMilSeconds = dayjs.utc(new Date().getTime()).valueOf();
    const timeLeft = timeLeftOngoing - nowInMilSeconds;

    return timeLeft;
};

const getResponderStatus = (survey: BaseSurvey): UiStatus => {
    switch (survey.survey_class) {
        case "ONGOING":
            const ongoingSurvey = survey as OngoingSurvey;
            return getOngoingSurveyResponderStatus(ongoingSurvey);

        case "SIMPLE":
        default:
            const simpleSurvey = survey as SimpleSurvey;
            return getSimpleSurveyResponderStatus(simpleSurvey);
    }
};

const isRespondentRespond = (surveyUuid: UUID) => {
    const surveyResponse =
        surveyResponseStore.getSurveyResponseBySurveyUUID(surveyUuid);
    const isCompletedResponderSurvey = !!surveyResponse?.submission_date;
    return isCompletedResponderSurvey;
};

const getOngoingSurveyResponderStatus = (survey: OngoingSurvey): UiStatus => {
    const isSurveyRespond = isRespondentRespond(survey.uuid);

    if (isSurveyRespond) {
        return "COMPLETED";
    }

    if (survey.manually_ended) {
        return "ENDED";
    }

    switch (survey.status) {
        case "ACTIVE":
            return SURVEY_STATUS.ACTIVE as UiStatus;
    }
};

const getSimpleSurveyResponderStatus = (survey: SimpleSurvey): UiStatus => {
    if (!survey) {
        return;
    }

    const _lastDateToFill = getLastDateToFill(survey);
    const isSurveyResponse = isRespondentRespond(survey.uuid);

    if (
        (survey.status === SURVEY_STATUS.READY ||
            survey.status === SURVEY_STATUS.ACTIVE) &&
        isSurveyResponse
    ) {
        return "COMPLETED";
    }

    if (
        survey.status === SURVEY_STATUS.ACTIVE &&
        _lastDateToFill! > Date.now()
    ) {
        return "ACTIVE";
    }

    if (
        (survey.status === SURVEY_STATUS.READY ||
            survey.status === SURVEY_STATUS.ACTIVE) &&
        _lastDateToFill! < Date.now()
    ) {
        return "ENDED";
    }
};

export const getLastDateToFill = (survey: SimpleSurvey) => {
    if (!survey) {
        return 0;
    }

    const lastDateToFillEvent = survey.survey_events.find(
        (surveyEvent) =>
            surveyEvent.system_survey_event_type === "LAST_DATE_TO_FILL"
    );

    if (!lastDateToFillEvent?.date_utc || !lastDateToFillEvent.day_utc_time)
        return null;

    //TODO: Add null handling for function and function return
    return lastDateToFillEvent
        ? totalTimeInMiliSecondsFromString(
              lastDateToFillEvent.date_utc!,
              lastDateToFillEvent.day_utc_time!
          )
        : 0;
};

export const getSurveyProgress = (survey: BaseSurvey) => {
    const surveryResponseData = getSurveyResponseData(survey);

    return surveryResponseData.percentage;
};

const getNumbersPercentage = (partial: number, total: number): number => {
    return total > 0 ? +((100 * partial) / total).toFixed(0) : 0;
};

export const getSurveyProgressSortBy = (displaySurvey: DisplaySurvey) => {
    if (displaySurvey.role === DISPLAY_SURVEY_ROLES.RESPONDER) {
        //returns -1 for the sort to be last
        return -1;
    }

    const surveryResponseData = getSurveyResponseData(displaySurvey.survey);

    return surveryResponseData.percentage;
};

export const getSurveyResponseData = (survey: BaseSurvey) => {
    const surveyResponseStatus =
        surveyResponseStatusStore.getStatusBySurveyUuid(survey.uuid!);

    const completed = surveyResponseStatus.length;

    const importedResponsesCount = surveyResponseStatus.filter(
        (surveyResponseStatus) => surveyResponseStatus.is_imported
    ).length;

    const total = importedResponsesCount + survey!.respondents!.length;

    const completedPercentage = getNumbersPercentage(completed, total);

    return {
        completed,
        total,
        percentage: completedPercentage,
    };
};

export const getPublishedDate = (survey: SimpleSurvey) => {
    if (!survey) {
        return 0;
    }

    const publishedDateEvent = survey.survey_events.find(
        (surveyEvent) =>
            surveyEvent.system_survey_event_type === "PUBLISHED_DATE"
    );

    if (!publishedDateEvent?.date_utc || !publishedDateEvent?.day_utc_time)
        return null;

    return publishedDateEvent
        ? totalTimeInMiliSecondsFromString(
              publishedDateEvent.date_utc,
              publishedDateEvent.day_utc_time
          )
        : 0;
};

export const getPublishedDateEvent = (survey: SimpleSurvey) => {
    if (!survey) {
        return;
    }

    const publishedDateEvent = survey.survey_events.find(
        (surveyEvent) =>
            surveyEvent.system_survey_event_type === "PUBLISHED_DATE"
    );

    return publishedDateEvent;
};

export const getLastDateToFillDateEvent = (survey: SimpleSurvey) => {
    if (!survey) {
        return;
    }

    const lastDateToFillDateEvent = survey.survey_events.find(
        (surveyEvent) =>
            surveyEvent.system_survey_event_type === "LAST_DATE_TO_FILL"
    );

    return lastDateToFillDateEvent;
};

export const getUtcDateAtMidnightInMilsByEventType = (
    system_survey_event_type?: SystemSurveyEventType
) => {
    switch (system_survey_event_type) {
        case "PUBLISHED_DATE":
            return getUtcDateAtMidnightInMilsWithAddingDays(SET_ONE_DAY_AHEAD);
        case "LAST_DATE_TO_FILL":
            return getUtcDateAtMidnightInMilsWithAddingDays(SET_ONE_WEEK_AHEAD);
        default:
            return getUtcDateAtMidnightInMils();
    }
};

export const getLocalDateInMilsByEventType = (
    system_survey_event_type?: SystemSurveyEventType
) => {
    switch (system_survey_event_type) {
        case "PUBLISHED_DATE":
            return addDaysToDateInMils(SET_ONE_DAY_AHEAD);
        case "LAST_DATE_TO_FILL":
            return addDaysToDateInMils(SET_ONE_WEEK_AHEAD);
        default:
            return Date.now();
    }
};

export const getFilterQuestionsFromSurvey = (survey: SurveyClasses) => {
    return survey.questions.filter(
        (question: Question<QuestionType>) => question.is_filter
    );
};

export const getNonFilterQuestionsFromSurvey = (survey: SurveyClasses) => {
    return survey.questions.filter(
        (question: Question<QuestionType>) => !question.is_filter
    );
};

export const calculateSurveyType = (
    survey: SurveyClasses
): "COMPLICATED" | "SIMPLE" => {
    const filterQuestions: Question<QuestionType>[] =
        getFilterQuestionsFromSurvey(survey);

    if (!filterQuestions.length) return "SIMPLE";
    return "COMPLICATED";
};
