import React, { FC, PropsWithChildren, useMemo } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import {
    Popper,
    PopperProps,
    ChangeableTextWithInput,
} from "@difftone/shared-components";
import { SharedResult, BaseSurvey } from "@difftone/types";
import { navigationStore } from "@difftone/stores";
import {
    getSurveyByUuid,
    getSurveyFromStoreByUUID,
    sortBySurveyName,
} from "@difftone/reducers";
import shareIcon from "@difftone/assets/share-survey.svg";
import { Share } from "./components";

import {
    popper_shares_results_root,
    shares_popper,
    shares_popper_content,
    results_action_icon,
    shares_popper_item,
    shares_popper_item_disabled,
    share_popper_scrollable,
    popper_shares_results_root_simple,
} from "./shares-popup.module.css";

export type SharesPopupProps = {
    shares: SharedResult[];
    anchorEl: PopperProps["anchorEl"];
    open: boolean;
    onClose: () => void;
    onShareView: (name: string) => void;
    isShareMode: boolean;
    isSimpleResults?: boolean;
};

type DisplaySharedResult = SharedResult & {
    surveyName: string;
};

export const SharesPopup: FC<PropsWithChildren<SharesPopupProps>> = observer(
    ({
        open,
        shares = [],
        anchorEl,
        onClose,
        onShareView,
        isShareMode = false,
        isSimpleResults = false,
    }: SharesPopupProps) => {
        const { currentPage } = navigationStore;

        const selectedSurvey = useMemo<BaseSurvey>(() => {
            const surveyUuid = currentPage.split("/")[2];

            return getSurveyFromStoreByUUID(surveyUuid) as BaseSurvey;
        }, [currentPage]);

        const sharesWithSurveyNames = useMemo<DisplaySharedResult[]>(
            () =>
                shares.map((share) => {
                    const survey = getSurveyByUuid(share.survey_uuid);

                    return {
                        ...share,
                        surveyName: survey ? survey.survey_name : "",
                    };
                }),
            [shares]
        );

        const sortedShares = sortBySurveyName(
            sharesWithSurveyNames,
            selectedSurvey.survey_name
        ) as DisplaySharedResult[];

        return (
            <Popper
                className={clsx(popper_shares_results_root, {
                    [popper_shares_results_root_simple]: isSimpleResults,
                })}
                contentClassName={shares_popper}
                onClose={onClose}
                open={open}
                anchorEl={anchorEl}
            >
                <div
                    className={clsx(shares_popper_content, {
                        [share_popper_scrollable]: sortedShares.length > 3,
                    })}
                >
                    <button
                        className={clsx(shares_popper_item, {
                            [shares_popper_item_disabled]: isShareMode,
                        })}
                        disabled={isShareMode}
                    >
                        <ChangeableTextWithInput
                            baseValue="Share this view"
                            onValueSave={onShareView}
                            inputIcon={shareIcon}
                            inputIconStyles={results_action_icon}
                            onClose={onClose}
                        />
                    </button>

                    {sortedShares.length
                        ? sortedShares.map((share) => (
                              <Share key={share.uuid} {...share} />
                          ))
                        : null}
                </div>
            </Popper>
        );
    }
);
