import React from "react";
import clsx from "clsx";
import { SurveyEvent } from "@difftone/types";
import { alertsStore, wizardDisplayStore, wizardStore } from "@difftone/stores";

import { BellEventIcon } from "@difftone/shared-components";
import {
    event_bell,
    event_bell_margin_top,
    empty_event_alerts_counter,
    event_alerts_counter,
    bell_event_icon_scope,
} from "./event-alert-bell.module.css";

export type EventAlertBellProps = {
    surveyEvent: SurveyEvent;
};

const COLOR_BLACK = "BLACK";

export const EventAlertBell = (props: EventAlertBellProps) => {
    const { wizardFocusedCalendar } = wizardStore;
    const { isEditableSurvey } = wizardDisplayStore;
    const { surveyEvent } = props;

    const numberOfEventAlerts = surveyEvent.event_alerts.filter((alertUUID) =>
        isEditableSurvey
            ? !alertsStore.getSurveyAlertCopyByUuid(alertUUID)?.alert_type
            : !wizardDisplayStore.getSurveyCopyAlertCopyByUuid(alertUUID)
                  ?.alert_type
    ).length;

    const isOpenedCalendar = wizardFocusedCalendar === surveyEvent.uuid;

    return (
        <div
            className={clsx(
                event_bell,
                isOpenedCalendar && event_bell_margin_top
            )}
        >
            <BellEventIcon
                className={bell_event_icon_scope}
                color={COLOR_BLACK}
            />
            <span
                className={clsx(
                    empty_event_alerts_counter,
                    numberOfEventAlerts && event_alerts_counter
                )}
            >{`(${numberOfEventAlerts})`}</span>
        </div>
    );
};
