import React, { ChangeEvent, KeyboardEventHandler } from "react";
import { observer } from "mobx-react";

import { Dialog, DialogProps } from "@difftone/shared-components";
import { preventPropagation } from "@difftone/frontend-common";
import { categoriesStore, categoriesDisplayStore } from "@difftone/stores";
import { KEYBOARD_EVENT_KEYS_MAP } from "@difftone/constants";
import {
    formatCategoryObject,
    getSelectedOrganizationOrThrow,
} from "@difftone/reducers";
import { Label } from "@difftone/types";
import {
    setCategoryInputName,
    showDifftoneAlert,
    closeCategoriesDialog,
    finalizeChangesMadeAtCategoriesDialog,
} from "@difftone/actions";
import { getUserUuid } from "@difftone/procedures";

import { CategoryListItem } from "./components";
import {
    categories_dialog_content,
    categories_dialog_header,
    categories_dialog_paper,
    categories_dialog_input,
    categories_dialog_list,
    categories_dialog_list_wrap,
    categories_dialog_button,
} from "./manage-categories-dialog.module.css";

export type ManageCategoriesDialogProps = DialogProps & {};

export const ManageCategoriesDialog = observer(
    (props: ManageCategoriesDialogProps) => {
        const { onClose, open } = props;
        const userUUID = getUserUuid();
        const { categoryNameInput, categoriesToDelete } =
            categoriesDisplayStore;
        const categories = categoriesStore
            .getCategoriesArray()
            .filter(
                (category) =>
                    !category.is_deleted &&
                    !categoriesToDelete.find((uuid) => category.uuid === uuid)
            );
        const publicCategories = categories.filter(
            ({ scope_type }) => scope_type === "GENERAL"
        );
        const organisationCategories = categories.filter(
            ({ scope_type }) => scope_type === "ORGANISATION"
        );

        const onChange = (e: ChangeEvent<HTMLInputElement>) => {
            setCategoryInputName(e.target.value);
        };

        const onSave = () => {
            closeCategoriesDialog();
            finalizeChangesMadeAtCategoriesDialog();
        };

        const onEnterPress: KeyboardEventHandler = (e) => {
            if (e.key === KEYBOARD_EVENT_KEYS_MAP.ENTER) {
                const isCategoryExist =
                    publicCategories.find(
                        ({ display_name }) =>
                            display_name.toLowerCase() ===
                            categoryNameInput.toLowerCase().trim()
                    ) ||
                    organisationCategories.find(
                        ({ display_name }) =>
                            display_name.toLowerCase() ===
                            categoryNameInput.toLowerCase().trim()
                    );

                if (isCategoryExist) {
                    showDifftoneAlert(
                        "This category already exists",
                        "FAILURE"
                    );

                    return;
                }

                const newCategory = formatCategoryObject(
                    getSelectedOrganizationOrThrow()!.uuid,
                    userUUID,
                    categoryNameInput.trim()
                );

                categoriesDisplayStore.addCustomCategory(newCategory);
                setCategoryInputName("");
            }
        };

        const filterCategoriesByUserInput = (
            categoriesToFilter: Label[]
        ): Label[] => {
            return categoriesToFilter.filter(({ display_name }) =>
                display_name
                    .toLowerCase()
                    .startsWith(categoryNameInput.toLocaleLowerCase())
            );
        };

        const filteredDeletableCategories = filterCategoriesByUserInput([
            ...organisationCategories,
            ...categoriesDisplayStore.customCategories,
        ]);

        const filteredPublicCategories =
            filterCategoriesByUserInput(publicCategories);

        return (
            <Dialog
                onClose={onClose}
                open={open}
                fullScreen={false}
                title="Manage organisation's categories"
                className={categories_dialog_header}
                classes={{
                    paper: categories_dialog_paper,
                }}
            >
                <div
                    className={categories_dialog_content}
                    onClick={preventPropagation}
                >
                    <input
                        value={categoryNameInput}
                        onChange={onChange}
                        onKeyDown={onEnterPress}
                        placeholder="Add a category"
                        className={categories_dialog_input}
                    />

                    <div className={categories_dialog_list}>
                        <div className={categories_dialog_list_wrap}>
                            {filteredPublicCategories.map((category) => (
                                <CategoryListItem
                                    key={category.uuid}
                                    uuid={category.uuid}
                                    name={category.display_name}
                                />
                            ))}

                            {filteredDeletableCategories.map((category) => (
                                <CategoryListItem
                                    key={category.uuid}
                                    uuid={category.uuid}
                                    name={category.display_name}
                                    isDeletable
                                />
                            ))}
                        </div>
                    </div>

                    <button
                        className={categories_dialog_button}
                        onClick={onSave}
                    >
                        Save
                    </button>
                </div>
            </Dialog>
        );
    }
);
