import React, { MouseEvent, useState, useCallback } from "react";
import { observer } from "mobx-react";

import { TableHeaderArrowIcon } from "@difftone/shared-components";
import {
    sharedResultStore,
    SortingSharedViewsWithMeProfile,
    SharedViewsWithMeFilters,
} from "@difftone/stores";
import { setSharedViewsStoreSortingProfile } from "@difftone/actions";

import {
    table_header_cell,
    active_filter,
    up_arrow_direction,
} from "./table-header-cell.module.css";

export type TableHeaderCellProps = {
    className?: string;
    text: string;
    filterType: SharedViewsWithMeFilters;
    isFilterActive: boolean;
    setFilter: (event: MouseEvent<HTMLDivElement>) => void;
};

const COLOR_BLACK = "BLACK";
const COLOR_GRAY = "GRAY";

export const TableHeaderCell = observer(
    ({
        className,
        text,
        filterType,
        isFilterActive,
        setFilter,
    }: TableHeaderCellProps) => {
        const { sortingSharedResultProfile } = sharedResultStore;

        // temp variable to store filter's arrow state
        const [isArrowUp, setIsArrowUp] = useState(
            isFilterActive ? sortingSharedResultProfile.ascending : true
        );

        let customClassName = className
            ? [table_header_cell, className].join(" ")
            : table_header_cell;
        const filter = filterType ? filterType : "";
        const headerText = text === "blank" ? "" : text;

        if (isFilterActive) {
            customClassName = [customClassName, active_filter].join(" ");
        }

        const toggleArrowDirection = useCallback(() => {
            setIsArrowUp((state) => !state);
        }, []);

        const handleOnClick = useCallback(
            (event: MouseEvent<HTMLDivElement>) => {
                toggleArrowDirection();

                const newSharedSurveySortingProfile: SortingSharedViewsWithMeProfile =
                    {
                        ascending: !isArrowUp,
                        sortBy: filterType,
                    };

                setSharedViewsStoreSortingProfile(
                    newSharedSurveySortingProfile
                );
                setFilter(event);
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [toggleArrowDirection, setFilter, isArrowUp]
        );

        return (
            <div
                onClick={handleOnClick}
                data-filter={filter}
                className={customClassName}
            >
                {text !== "blank" ? (
                    <TableHeaderArrowIcon
                        className={isArrowUp ? up_arrow_direction : ""}
                        color={isFilterActive ? COLOR_BLACK : COLOR_GRAY}
                    />
                ) : null}
                <span>{headerText}</span>
            </div>
        );
    }
);
