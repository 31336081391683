import { makeAutoObservable, observable, ObservableMap } from "mobx";

import {
    Organization,
    Domain,
    UUID,
    OrganizationSettings,
} from "@difftone/types";

import {
    fetchUserOrganizations,
    fetchDomains,
    updateOrganisationSettingsToApi,
} from "./organization-internal-actions";
import { organizationDisplayStore } from "./organization-display-store";

class OrganizationStore {
    constructor() {
        makeAutoObservable(this);
    }

    public getDomains = (): Domain[] => this._domains;
    private _domains: Domain[] = [];

    get domains() {
        return this._domains;
    }

    set domains(domains) {
        this._domains = domains;
    }

    public getPublicDomains = (): Domain[] => this._publicDomains;
    private _publicDomains: Domain[] = [];

    get publicDomains() {
        return this._publicDomains;
    }

    set publicDomains(publicDomains) {
        this._publicDomains = publicDomains;
    }

    private _organizationMap: ObservableMap<UUID, Organization | null> =
        observable.map({});

    private _selectedOrganizationUUID: UUID | undefined;

    private _storeInitialized: boolean = false;

    get storeInitialized() {
        return this._storeInitialized;
    }

    public setStoreInitialized(bool: boolean) {
        this._storeInitialized = bool;
    }

    public getSelectedOrganizationUUID() {
        return this._selectedOrganizationUUID;
    }

    public setSelectedOrganizationUUID(uuid: UUID) {
        fetchDomains(this._organizationMap.get(uuid) as Organization);
        localStorage.setItem("selectedOrganization", uuid);
        this._selectedOrganizationUUID = uuid;
    }

    public setOrganizationToMap = (
        uuid: UUID,
        organization: Organization | null
    ) => {
        this._organizationMap.merge({ [uuid]: organization });
    };

    public updateOrganisationSettings = (
        settings: OrganizationSettings,
        organizationUuid: UUID
    ) => {
        updateOrganisationSettingsToApi(settings, organizationUuid);
        const _org = this.getOrganizationByUuid(organizationUuid);

        if (!_org) {
            throw Error(
                `[Difftone]:: Cent find organization with uuid ${organizationUuid}`
            );
        }

        _org.settings = settings;
    };

    public getOrganizationByUuid = (
        organizationUuid: UUID
    ): Organization | undefined | null => {
        if (!organizationUuid) {
            throw new Error(`surveyUUID is ${organizationUuid}`);
        }

        const fetchedOrganization = this._organizationMap.get(organizationUuid);

        if (fetchedOrganization === undefined) {
            fetchUserOrganizations();
        }

        return fetchedOrganization;
    };

    public getOrganizationsListAsArray = () => {
        if (!this._storeInitialized) {
            fetchUserOrganizations();
            return [...this._organizationMap.values()];
        }
        if (![...this._organizationMap.values()].length) {
            return null;
        }
        return [...this._organizationMap.values()];
    };

    public clearStore = () => {
        this._organizationMap.clear();
        this._selectedOrganizationUUID = undefined;
        this._storeInitialized = false;
    };
}

const organizationStore = new OrganizationStore();

export { organizationDisplayStore, organizationStore };
