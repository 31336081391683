import React, { ChangeEvent } from "react";
import { observer } from "mobx-react";

import { Dialog, DialogProps, Spinner } from "@difftone/shared-components";
import {
    setTemplateToEditByUUID,
    updateTemplateToEdit,
    clearTemplateToEdit,
    removeTemplateFromStore,
    showDifftoneAlert,
} from "@difftone/actions";
import {
    templatesStore,
    categoriesStore,
    templatesDisplayStore,
} from "@difftone/stores";
import { preventPropagation } from "@difftone/frontend-common";
import { SharedSurvey } from "@difftone/types";

import {
    templates_dialog_content,
    templates_dialog_header,
    templates_dialog_paper,
    templates_dialog_loading_spinner,
    templates_dialog_loading,
    template_dialog_margin,
    template_dialog_tags,
} from "./manage-templates-dialog.module.css";

export type ManageTemplatesDialogProps = DialogProps & {};

export const ManageTemplatesDialog = observer(
    (props: ManageTemplatesDialogProps) => {
        const { onClose, open } = props;
        const allCategories = categoriesStore.getCategoriesArray();
        const templates = templatesStore.getTemplatesToDisplay(allCategories);
        const allTags = templatesStore.getTagsMapValues();
        const templateToEdit =
            templatesDisplayStore.templateToEdit as SharedSurvey;

        const initialized = templatesStore.startInitialization;

        if (!templatesStore.startInitialization) {
            templatesStore.init();
        }

        if (!onClose) {
            throw new Error("onClose is not defined");
        }

        const handleChooseTemplate = (
            event: ChangeEvent<HTMLSelectElement>
        ) => {
            if (!event.target.value.length) {
                clearTemplateToEdit();

                return;
            }

            setTemplateToEditByUUID(event.target.value);
        };

        const onShortDescriptionChange = (
            e: ChangeEvent<HTMLTextAreaElement>
        ) => {
            const tempTemplate = JSON.parse(JSON.stringify(templateToEdit));

            tempTemplate.short_description = e.target.value;

            updateTemplateToEdit(tempTemplate);
        };

        const onLongDescriptionChange = (
            e: ChangeEvent<HTMLTextAreaElement>
        ) => {
            const tempTemplate = JSON.parse(JSON.stringify(templateToEdit));

            tempTemplate.long_description = e.target.value;

            updateTemplateToEdit(tempTemplate);
        };

        const onResponseDurationChange = (e: ChangeEvent<HTMLInputElement>) => {
            const tempTemplate = JSON.parse(JSON.stringify(templateToEdit));

            if (!Number.isNaN(Number(e.target.value))) {
                tempTemplate.survey.response_duration = Number(e.target.value);
            }

            updateTemplateToEdit(tempTemplate);
        };

        const onTagsChange = (e: ChangeEvent<HTMLInputElement>) => {
            const surveyTags = templateToEdit.survey.tags
                ? templateToEdit.survey.tags
                : [];

            const tempTags = [...surveyTags];

            const tempTemplate = JSON.parse(JSON.stringify(templateToEdit));

            if (!e.target.checked) {
                const targetTagIndex = tempTags.findIndex(
                    (uuid) => e.target.value === uuid
                );

                tempTags.splice(targetTagIndex, 1);
            } else {
                tempTags.push(e.target.value);
            }

            tempTemplate.survey.tags = tempTags;

            updateTemplateToEdit(tempTemplate);
        };

        const saveTemplate = () => {
            templatesStore.addTemplate([templateToEdit]);
            clearTemplateToEdit();
            showDifftoneAlert(
                `"Template survey ${templateToEdit.survey.survey_name}" saved successfully`,
                "SUCCESS"
            );
        };

        const onCloseTemplatesPopupHandler = () => {
            clearTemplateToEdit();
            onClose();
        };

        const deleteTemplate = () => {
            templateToEdit.deleted = true;

            templatesStore.addTemplate([templateToEdit]);
            removeTemplateFromStore();
            clearTemplateToEdit();
            showDifftoneAlert(
                `"Template survey ${templateToEdit.survey.survey_name}" deleted successfully`,
                "SUCCESS"
            );
        };

        return (
            <Dialog
                onClose={onCloseTemplatesPopupHandler}
                open={open}
                fullScreen={false}
                title="Manage templates"
                className={templates_dialog_header}
                classes={{
                    paper: templates_dialog_paper,
                }}
            >
                <div
                    className={templates_dialog_content}
                    onClick={preventPropagation}
                >
                    {!templatesStore.storeInitialize ? (
                        <div className={templates_dialog_loading}>
                            <Spinner
                                className={templates_dialog_loading_spinner}
                                size="LARGE"
                            />
                        </div>
                    ) : (
                        <>
                            <select
                                name="templates"
                                onChange={handleChooseTemplate}
                            >
                                <option value="">
                                    Select template to edit
                                </option>

                                {templates.map((template) => {
                                    return (
                                        <option
                                            value={template.uuid}
                                            key={template.uuid}
                                        >
                                            {template.survey.survey_name}
                                        </option>
                                    );
                                })}
                            </select>

                            {templateToEdit && (
                                <>
                                    <label
                                        htmlFor="short-description"
                                        className={template_dialog_margin}
                                    >
                                        Short description
                                    </label>
                                    <textarea
                                        value={templateToEdit.short_description}
                                        onChange={onShortDescriptionChange}
                                        id="short-description"
                                    />

                                    <label
                                        htmlFor="long-description"
                                        className={template_dialog_margin}
                                    >
                                        Long description
                                    </label>
                                    <textarea
                                        value={templateToEdit.long_description}
                                        onChange={onLongDescriptionChange}
                                        id="long-description"
                                    />

                                    <label
                                        htmlFor="response-duration"
                                        className={template_dialog_margin}
                                    >
                                        Response duration ( number of minutes )
                                    </label>
                                    <input
                                        value={
                                            templateToEdit.survey
                                                .response_duration
                                        }
                                        onChange={onResponseDurationChange}
                                        id="response-duration"
                                    />

                                    <div className={template_dialog_tags}>
                                        {allTags.map((tag, index) => {
                                            const isActive =
                                                templateToEdit.survey.tags?.includes(
                                                    tag.uuid
                                                );

                                            return (
                                                <div key={Math.random()}>
                                                    <label htmlFor={tag.name}>
                                                        {tag.name}
                                                    </label>
                                                    <input
                                                        id={tag.uuid}
                                                        value={tag.uuid}
                                                        type="checkbox"
                                                        onChange={onTagsChange}
                                                        checked={isActive}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>

                                    <div>
                                        <button
                                            onClick={saveTemplate}
                                            className={template_dialog_margin}
                                        >
                                            Save Changes
                                        </button>

                                        <button
                                            onClick={deleteTemplate}
                                            className={template_dialog_margin}
                                        >
                                            Delete Template
                                        </button>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </Dialog>
        );
    }
);
