import {
    BaseSurvey,
    PieChartPurposeCounts,
    DataPieChart,
} from "@difftone/types";
import { getColorAndValue } from "./dashboard-reducer";
export const getDataPieChart = (surveysList: BaseSurvey[]) => {
    const purposeCounts: PieChartPurposeCounts = {};
    surveysList.forEach((survey: BaseSurvey) => {
        if (!purposeCounts[survey.purpose])
            purposeCounts[survey.purpose] = { count: 1 };
        else purposeCounts[survey.purpose].count++;
    });

    const dataPieChart: DataPieChart[] = [];
    Object.keys(purposeCounts).forEach((key) => {
        const colorAndValue = getColorAndValue(key);
        dataPieChart.push({
            lable: colorAndValue.value,
            color: colorAndValue.color,
            value: purposeCounts[key].count,
        });
    });
    return dataPieChart;
};
