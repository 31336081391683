import type {
    Question,
    ValidationResult,
    QuestionType,
    QuestionInvalidNameValidationType,
} from "@difftone/types";

export const validateQuestionTitle = (
    question: Question<QuestionType>
): ValidationResult<QuestionInvalidNameValidationType>[] => {
    try {
        const res = question.title ? true : false;

        return [
            {
                is_valid: res,
                validation_error_code: "QUESTION_NAME_VALIDATION",
                validation_error_type: {
                    question_id: !res ? question.uuid : "",
                },
            },
        ];
    } catch (error) {
        console.error(
            `validateSurveyDates error:: ${(error as Error).message}`
        );
        return [
            {
                is_valid: false,
                validation_error_code: "EXCEPTION_DURING_VALIDATIONS",
                //TODO: fix type definition
                //@ts-ignore
                validation_error_type: error as Error,
            },
        ];
    }
};
