import { v4 as uuid } from "uuid";
import dayjs from "dayjs";
import {
    BaseSurvey,
    EmailAddress,
    OngoingSurvey,
    OptionalChoiceAnswer,
    Question,
    QuestionType,
    RatingQuestion,
    ShortTextQuestion,
    SimpleSurvey,
    SurveyEvent,
    SurveyEventAlert,
    UUID,
} from "@difftone/types";
import {
    BASE_SURVEY_TEMPLATE,
    ONGOING_SURVEY_TEMPLATE,
    SIMPLE_SURVEY_TEMPLET,
    NEW_QUESTION_TEMPLET,
} from "./templates";
import { getUserEmail, getUserUuid } from "@difftone/procedures";
import {
    getAlertButtonsFactory,
    getNewEmptySurveyEvent,
    getNewEmptySurveyEventAlert,
} from "@difftone/actions";
import { alertsStore } from "@difftone/stores";

const createBaseSurvey = (
    organizationUuid: UUID,
    initiatorUuid: UUID,
    questions: Question<QuestionType>[],
    respondents: EmailAddress[]
) => {
    const newBaseSurvey: BaseSurvey = JSON.parse(
        JSON.stringify(BASE_SURVEY_TEMPLATE)
    );

    newBaseSurvey.uuid = uuid();
    newBaseSurvey.issued_date = Date.now();
    newBaseSurvey.last_updated = Date.now();
    newBaseSurvey.created_at = Date.now();
    newBaseSurvey.initiator = initiatorUuid;
    newBaseSurvey.organization = organizationUuid;
    newBaseSurvey.default_survey_name = `${getUserEmail()}_${dayjs().format(
        "MMDD_HH:mm:ss"
    )}`;
    newBaseSurvey.survey_name = newBaseSurvey.default_survey_name;
    newBaseSurvey.questions = questions;
    newBaseSurvey.respondents = respondents;

    return newBaseSurvey;
};

export const createSimpleSurvey = (
    surveyEvents: SurveyEvent[],
    organizationUuid: UUID,
    initiatorUuid: UUID = getUserUuid(),
    questions: Question<QuestionType>[] = [],
    respondents: EmailAddress[] = []
) => {
    const newBaseSurvey = createBaseSurvey(
        organizationUuid,
        initiatorUuid,
        questions,
        respondents
    );

    const newSimpleDiffSurveyCopy: SimpleSurvey = JSON.parse(
        JSON.stringify(SIMPLE_SURVEY_TEMPLET)
    );

    const newSimpleSurvey: SimpleSurvey = Object.assign(
        {},
        newBaseSurvey,
        newSimpleDiffSurveyCopy
    );

    newSimpleSurvey.survey_events = surveyEvents;

    return newSimpleSurvey;
};

export const createOngoingSurvey = (
    organizationUuid: UUID,
    initiatorUuid: UUID = getUserUuid(),
    questions: Question<QuestionType>[] = [],
    respondents: EmailAddress[] = []
) => {
    const newBaseSurvey = createBaseSurvey(
        organizationUuid,
        initiatorUuid,
        questions,
        respondents
    );

    const newOnGoingDiffSurveyCopy: OngoingSurvey = JSON.parse(
        JSON.stringify(ONGOING_SURVEY_TEMPLATE)
    );

    const newOnGoingSurvey: OngoingSurvey = Object.assign(
        {},
        newBaseSurvey,
        newOnGoingDiffSurveyCopy
    );

    return newOnGoingSurvey;
};

//TODO:: move all event creation from actions to factory https://app.asana.com/0/1201346022025578/1202919611737636/f
export const createDefaultEmptySystemEvent = (simpleSurveyUuid: UUID) => {
    const publishedSurveyEvent: SurveyEvent = getNewEmptySurveyEvent(
        0,
        [],
        "PUBLISHED_DATE",
        "Time to send out"
    );

    const lastDateToFillSurveyEvent: SurveyEvent = getNewEmptySurveyEvent(
        1,
        [],
        "LAST_DATE_TO_FILL",
        "Complete survey deadline"
    );

    const publishedSurveyEventAlert: SurveyEventAlert =
        getNewEmptySurveyEventAlert(
            publishedSurveyEvent,
            [],
            [],
            "PUBLISHED_DATE_ALERT"
        );

    publishedSurveyEventAlert.buttons = getAlertButtonsFactory(
        publishedSurveyEventAlert,
        simpleSurveyUuid
    );

    const activationEventAlert: SurveyEventAlert = getNewEmptySurveyEventAlert(
        publishedSurveyEvent,
        [],
        [],
        "ACTIVATION_ALERT"
    );

    activationEventAlert.buttons = getAlertButtonsFactory(
        activationEventAlert,
        simpleSurveyUuid
    );

    const surveyEndedNotificationAlert: SurveyEventAlert =
        getNewEmptySurveyEventAlert(
            lastDateToFillSurveyEvent,
            [],
            [],
            "SURVEY_ENDED_NOTIFICATION_ALERT"
        );

    surveyEndedNotificationAlert.buttons = getAlertButtonsFactory(
        surveyEndedNotificationAlert,
        simpleSurveyUuid
    );

    publishedSurveyEvent.event_alerts.push(
        publishedSurveyEventAlert.uuid,
        activationEventAlert.uuid
    );

    lastDateToFillSurveyEvent.event_alerts.push(
        surveyEndedNotificationAlert.uuid
    );

    alertsStore.InsertAlertToStoreWithoutDebounce(publishedSurveyEventAlert);
    alertsStore.InsertAlertToStoreWithoutDebounce(activationEventAlert);
    alertsStore.InsertAlertToStoreWithoutDebounce(surveyEndedNotificationAlert);
    return [publishedSurveyEvent, lastDateToFillSurveyEvent];
};

export const getNewEmptyQuestion = (
    questionNumber: number,
    survey?: BaseSurvey,
    autoScaleRange?: number | null
): Question<ShortTextQuestion> => {
    const surveyHasFilteredQuestion = survey?.questions.find(
        (question) => question.is_filter
    )?.is_filter;

    const newEmptyQuestion: Question<RatingQuestion> = JSON.parse(
        JSON.stringify(NEW_QUESTION_TEMPLET)
    );

    newEmptyQuestion.uuid = uuid();
    newEmptyQuestion.number = questionNumber;

    if (!surveyHasFilteredQuestion && autoScaleRange) {
        newEmptyQuestion.content.rating_options = Array(autoScaleRange)
            .fill("")
            .map((value, index) => ({
                value: index + 1,
                label: `${index + 1}`,
                gotos: [],
                uuid: `${index + 1}`,
            }));
    }

    return newEmptyQuestion;
};

export const createSingleChoiceOption = (): OptionalChoiceAnswer => {
    return {
        uuid: uuid(),
        version: "0.0.1",
        label: "",
        gotos: [],
    };
};
