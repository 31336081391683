import { SurveyResponse } from "@difftone/types";
import {
    validateAnswer,
    validateFilterQuestionAnswer,
    validateNonRequiredQuestionAnswers,
} from "@difftone/validations";
import {
    calculateSurveyType,
    getSurveyFromStoreByUUID,
} from "@difftone/reducers";

export const getValidResponsesCounter = (surveyResponse: SurveyResponse) => {
    let validAnswers = 0;
    let validAttributeAnswers = 0;

    const survey = getSurveyFromStoreByUUID(surveyResponse.survey_UUID);

    if (!survey) {
        return 0;
    }

    switch (calculateSurveyType(survey)) {
        case "COMPLICATED":
            surveyResponse.answers.forEach((answer) => {
                const question = survey.questions.find(
                    (question) => question.uuid === answer.question_uuid
                );
                if (question?.required) {
                    if (
                        validateAnswer(survey, answer).every(
                            (validationResult) => validationResult.is_valid
                        )
                    ) {
                        validAnswers++;
                    }
                } else {
                    if (
                        validateNonRequiredQuestionAnswers(
                            survey,
                            answer
                        ).every((validationResult) => validationResult.is_valid)
                    ) {
                        validAnswers++;
                    }
                }
            });
            surveyResponse.attribute_answers.forEach((filterQuestionAnswer) => {
                if (
                    validateFilterQuestionAnswer(filterQuestionAnswer).every(
                        (validationResult) => validationResult.is_valid
                    )
                ) {
                    validAttributeAnswers++;
                }
            });
            break;
        case "SIMPLE":
            surveyResponse.answers.forEach((answer) => {
                const question = survey.questions.find(
                    (question) => question.uuid === answer.question_uuid
                );

                if (question?.required) {
                    if (
                        validateAnswer(survey, answer).every(
                            (validationResult) => validationResult.is_valid
                        )
                    ) {
                        validAnswers++;
                    }
                } else {
                    if (
                        validateNonRequiredQuestionAnswers(
                            survey,
                            answer
                        ).every((validationResult) => validationResult.is_valid)
                    ) {
                        validAnswers++;
                    }
                }
            });
            break;
        default:
            break;
    }

    return validAttributeAnswers + validAnswers;
};
