import { templatesDisplayStore, templatesStore } from "@difftone/stores";
import { SharedSurvey, Label } from "@difftone/types";

export const getSortedTemplates = (allCategories: Label[]): SharedSurvey[] => {
    const templatesList = templatesStore.getTemplatesToDisplay(allCategories);
    const sortBy = templatesDisplayStore.sortingTemplateProfile.sortBy;
    const ascending = templatesDisplayStore.sortingTemplateProfile.ascending;

    const sortedTemplates = [...templatesList];

    switch (sortBy) {
        case "NAME":
            return ascending
                ? sortedTemplates.sort((templateA, templateB) =>
                      templateA.survey.survey_name.toLowerCase() >=
                      templateB.survey.survey_name.toLowerCase()
                          ? 1
                          : -1
                  )
                : sortedTemplates.sort((templateA, templateB) =>
                      templateA.survey.survey_name.toLowerCase() <
                      templateB.survey.survey_name.toLowerCase()
                          ? 1
                          : -1
                  );
        case "OWNER":
            return ascending
                ? sortedTemplates.sort((templateA, templateB) =>
                      templateA.sender.toLowerCase() >=
                      templateB.sender.toLowerCase()
                          ? 1
                          : -1
                  )
                : sortedTemplates.sort((templateA, templateB) =>
                      templateA.sender.toLowerCase() <
                      templateB.sender.toLowerCase()
                          ? 1
                          : -1
                  );
        case "PURPOSE":
            return ascending
                ? sortedTemplates.sort((templateA, templateB) =>
                      templateA.survey.purpose.toLowerCase() >=
                      templateB.survey.purpose.toLowerCase()
                          ? 1
                          : -1
                  )
                : sortedTemplates.sort((templateA, templateB) =>
                      templateA.survey.purpose.toLowerCase() <
                      templateB.survey.purpose.toLowerCase()
                          ? 1
                          : -1
                  );
        default:
            return sortedTemplates;
    }
};
