import {
    SimpleSurvey,
    SurveyClasses,
    ValidationResult,
    ValidationType,
} from "@difftone/types";
import { validateSurvey } from "@difftone/validations";

import { validateSurveyNameUniqueness } from "./uniqueness-validations";
import { validateFrontEndSurveyEvents } from "./survey-frontend-event-validation";

export const frontendValidateSurvey = (
    survey: SurveyClasses,
    restOfSurveys: SurveyClasses[]
): ValidationResult<ValidationType>[] => {
    const res: ValidationResult<ValidationType>[] = [];

    switch (survey.survey_class) {
        case "ONGOING":
            return frontendValidateOngoingSurvey(survey, restOfSurveys);
        case "SIMPLE":
        default:
            return frontendValidateSimpleSurvey(survey, restOfSurveys);
    }
};

const frontendValidateSimpleSurvey = (
    survey: SurveyClasses,
    restOfSurveys: SurveyClasses[]
): ValidationResult<ValidationType>[] => {
    const res: ValidationResult<ValidationType>[] = [];

    res.push(...validateSurvey(survey));
    res.push(validateSurveyNameUniqueness(survey, restOfSurveys));

    const simpleSurvey = survey as SimpleSurvey;

    res.push(...validateFrontEndSurveyEvents(simpleSurvey.survey_events));

    return res;
};

const frontendValidateOngoingSurvey = (
    survey: SurveyClasses,
    restOfSurveys: SurveyClasses[]
): ValidationResult<ValidationType>[] => {
    const res: ValidationResult<ValidationType>[] = [];

    res.push(...validateSurvey(survey));
    res.push(validateSurveyNameUniqueness(survey, restOfSurveys));

    return res;
};
