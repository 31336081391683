import { action } from "mobx";

import { surveyStore, wizardStore } from "@difftone/stores";
import { getUserUuid } from "@difftone/procedures";
import { frontendValidations } from "@difftone/frontend-common/frontend-validations";
import { validateQuestions, validateSurveyEvents } from "@difftone/validations";
import {
    ValidationErrorCode,
    ValidationResult,
    ValidationType,
    BaseSurvey,
    SimpleSurvey,
    SurveyClasses,
} from "@difftone/types";
import { getSurveyFromStoreByURL } from "@difftone/reducers";
import { validateFrontEndSurveyEvents } from "@difftone/frontend-common/frontend-validations/survey-validations";

export const validateWizardSurvey = action(() => {
    const { validateSurvey } = frontendValidations;
    const { surveysList } = surveyStore;

    const survey = getSurveyFromStoreByURL();
    wizardStore.wizardValidationErrors = validateSurvey(
        survey,
        surveysList.filter((_surveyFromList) => {
            const _survey = _surveyFromList as SurveyClasses;
            return (
                _survey.initiator === getUserUuid() &&
                _survey.uuid !== survey.uuid &&
                _survey.status !== "DRAFT"
            );
        })
    );
});

export const validateWizardQuestions = action((survey: BaseSurvey) => {
    wizardStore.wizardValidationErrors = validateQuestions(survey.questions);
});

export const validateWizardDates = action((survey: BaseSurvey) => {
    switch (survey.survey_class) {
        case "ONGOING":
            wizardStore.wizardValidationErrors = [];
            break;
        case "SIMPLE":
        default:
            const simpleSurvey = survey as SimpleSurvey;
            wizardStore.wizardValidationErrors = [
                ...validateSurveyEvents(simpleSurvey.survey_events),
                ...validateFrontEndSurveyEvents(simpleSurvey.survey_events),
            ];
    }
});

export const nullifyWizardValidationError = action(() => {
    wizardStore.wizardValidationErrors = [];
});

export const getValidationErrosByErrorCode = action(
    (
        errorCode: ValidationErrorCode,
        validationErros: ValidationResult<ValidationType>[]
    ) => {
        const res: ValidationResult<ValidationType>[] = [];
        validationErros.forEach((validationError) => {
            if (
                validationError.validation_error_code === errorCode &&
                !validationError.is_valid
            ) {
                res.push(validationError);
            }
        });

        return res;
    }
);
