import React from "react";

import {
    getFilterQuestionsFromSurvey,
    getNonFilterQuestionsFromSurvey,
    isQuestionForDisplay,
} from "@difftone/reducers";
import {
    Question,
    QuestionType,
    RequiredNotAnsweredValidationType,
    ValidationResult,
    ValidationType,
    SurveyClasses,
} from "@difftone/types";
import { QuestionComponent } from "@difftone/shared-components";

export type SurveyQuestionsProps = {
    survey: SurveyClasses;
    errors: ValidationResult<ValidationType>[];
    inPreviewMode: boolean;
};

export const SurveyQuestions = (props: SurveyQuestionsProps) => {
    const { survey, errors, inPreviewMode } = props;

    const filterQuestions: Question<QuestionType>[] =
        getFilterQuestionsFromSurvey(survey);
    const nonFilterQuestions: Question<QuestionType>[] =
        getNonFilterQuestionsFromSurvey(survey);
    const questions: Question<QuestionType>[] = [
        ...filterQuestions,
        ...nonFilterQuestions,
    ];

    return (
        <>
            {questions.map(
                (question: Question<QuestionType>, index: number) => {
                    const _isHidden = !isQuestionForDisplay(
                        survey.uuid,
                        question.uuid
                    );

                    return (
                        <QuestionComponent
                            isHidden={_isHidden}
                            isEditableQuestion={true}
                            inPreviewMode={inPreviewMode}
                            key={question.uuid}
                            index={index}
                            question={question}
                            errors={
                                errors as ValidationResult<RequiredNotAnsweredValidationType>[]
                            }
                        />
                    );
                }
            )}
        </>
    );
};
