import React from "react";
import { Question, QuestionType } from "@difftone/types";
import { QuestionComponent } from "@difftone/shared-components";
import {
    getSurveyFromStoreByURL,
    isQuestionForDisplay,
} from "@difftone/reducers";

export type GreyedOutQuestionsProps = {};

export const GreyedOutQuestions = (props: GreyedOutQuestionsProps) => {
    const survey = getSurveyFromStoreByURL();

    return (
        <div>
            {survey.questions.map(
                (question: Question<QuestionType>, index: number) => {
                    const _isHidden = !isQuestionForDisplay(
                        survey.uuid,
                        question.uuid
                    );

                    return (
                        <QuestionComponent
                            isHidden={_isHidden}
                            isEditableQuestion={false}
                            inPreviewMode={true}
                            key={question.uuid}
                            index={index}
                            question={question}
                            errors={[]}
                        />
                    );
                }
            )}
        </div>
    );
};
