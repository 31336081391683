import { action } from "mobx";

import {
    BaseSurvey,
    Role,
    EmailAddress,
    SurveyEvent,
    SurveyClasses,
    SimpleSurvey,
} from "@difftone/types";
import { surveyStore, groupEmailsStore } from "@difftone/stores";
import { getUserEmail, getUserUuid } from "@difftone/procedures";
import {
    getUtcDateAtMidnightInMilsWithAddingDays,
    getUTCDayTimeInMils,
    milisecondsToDateString,
    milisecondsToTimeString,
    SET_ONE_DAY_AHEAD,
    SET_ONE_WEEK_AHEAD,
} from "@difftone/time-utils";
import {
    getSelectedOrganizationOrThrow,
    getSurveyFromStoreByURL,
    isEmailFromOrganisation,
} from "@difftone/reducers";
import { EMAIL_REGEX } from "@difftone/validations";
import {
    getDynamicAlertVariantForOrganizationEmailValidation,
    DISPLAY_SURVEY_ROLES,
} from "@difftone/constants";
import {
    addResolvedEmailsToSurveyWizardCopy,
    addWizardSurveyEvent,
    deleteToUpdateWizardSurveyEventWithoutReset,
    showDifftoneAlert,
} from "@difftone/actions";

export const upsertSurveyToMapAndApiWithoutDebounce = action(
    (baseSurvey: SurveyClasses) => {
        return surveyStore.addSurveyToMapWithoutDebounce(baseSurvey);
    }
);

export const upsertSurveyToMapAndApiWithDebounce = action(
    (baseSurvey: SurveyClasses) => {
        surveyStore.debounceAddSurveyToMap(baseSurvey);
    }
);

export const debounceUpsertSurveyToApi = action(
    (key?: keyof SurveyClasses, value?: ValueOf<SurveyClasses>) => {
        let survey = JSON.parse(JSON.stringify(getSurveyFromStoreByURL()));
        const update = key && value !== undefined ? { [key]: value } : {};
        survey = Object.assign({}, survey, update);
        surveyStore.debounceAddSurveyToMap(survey);

        return survey;
    }
);

export const addAdminToSurvey = action(
    async (survey: SurveyClasses, adminsList: EmailAddress[]) => {
        const admins = [...survey.admins, ...adminsList];

        return surveyStore.putSurveyToApi({
            ...survey,
            admins,
        });
    }
);

export const clearSurveyStore = action(() => {
    surveyStore.clearStore();
});

export const removeDeletedSurveysFromStore = action(() => {
    surveyStore.removeDeletedSurveys();
});

export const roleChecker = (survey: BaseSurvey) => {
    const results: Role[] = [];
    if (survey.initiator === getUserUuid()) {
        results.push(DISPLAY_SURVEY_ROLES.INITIATOR);
    }
    if (survey.respondents.includes(getUserEmail())) {
        results.push(DISPLAY_SURVEY_ROLES.RESPONDER);
    }
    return results;
};

export const updateSurvey = action(
    (
        surveyToSave: BaseSurvey,
        key: keyof BaseSurvey,
        value: ValueOf<BaseSurvey>
    ) => {
        Object.assign(surveyToSave, { [key]: value });
        return;
    }
);

export const handleRespondentsChange = action(
    (
        email: EmailAddress,
        survey: BaseSurvey,
        activeMailingList: "ADMINS" | "RESPONDENTS",
        isEditingActiveSurvey: Boolean = false
    ) => {
        if (!isEmailFromOrganisation(email)) {
            const organization = getSelectedOrganizationOrThrow();
            const organizationName = organization ? organization.name : "";

            showDifftoneAlert(
                getDynamicAlertVariantForOrganizationEmailValidation(
                    organizationName,
                    [email]
                ),
                "FAILURE"
            );

            return;
        }

        if (EMAIL_REGEX.test(email)) {
            const resolvedEmails = groupEmailsStore.getResolvedEmails(
                email,
                getSelectedOrganizationOrThrow()
            );
            switch (resolvedEmails) {
                case undefined:
                    break;
                case null:
                    //TODO: refine and make const
                    showDifftoneAlert("Could not resolve email", "FAILURE");
                    break;
                default:
                    if (!isEditingActiveSurvey) {
                        surveyStore.addEmailsToSurvey(
                            resolvedEmails,
                            survey.uuid,
                            activeMailingList,
                            isEditingActiveSurvey
                        );
                    } else {
                        addResolvedEmailsToSurveyWizardCopy(resolvedEmails);
                    }
                    break;
            }
        }
    }
);

export const handleMultipleResondentsChange = action(
    (
        emails: EmailAddress[],
        survey: BaseSurvey,
        activeMailingList: "ADMINS" | "RESPONDENTS",
        isEditingActiveSurvey: boolean = false
    ) => {
        const organizationEmails: EmailAddress[] = [];
        const notOrganizationEmails: EmailAddress[] = [];

        emails.forEach((email) => {
            if (!isEmailFromOrganisation(email)) {
                notOrganizationEmails.push(email);
                return;
            }
            organizationEmails.push(email);
        });

        if (notOrganizationEmails.length) {
            const organization = getSelectedOrganizationOrThrow();
            const organizationName = organization ? organization.name : "";

            showDifftoneAlert(
                getDynamicAlertVariantForOrganizationEmailValidation(
                    organizationName,
                    notOrganizationEmails
                ),
                "FAILURE"
            );
        }

        const resolvedEmails = organizationEmails
            .map((email) => {
                return groupEmailsStore.getResolvedEmails(
                    email,
                    getSelectedOrganizationOrThrow()!
                );
            })
            .flat();

        const nullEmails = resolvedEmails.filter((email) => email === null);
        if (nullEmails.length) {
            showDifftoneAlert(
                `Could not resolve emails: ${nullEmails.join(", ")}`,
                "FAILURE"
            );
        }

        const emailsToAdd = resolvedEmails.filter(
            (email) => email && email.length
        );

        if (!isEditingActiveSurvey) {
            surveyStore.addEmailsToSurvey(
                emailsToAdd as string[],
                survey.uuid,
                activeMailingList,
                isEditingActiveSurvey
            );
        } else {
            addResolvedEmailsToSurveyWizardCopy(emailsToAdd as string[]);
        }
    }
);

export const populateEmptySurveyEvent = action((surveyEvent: SurveyEvent) => {
    if (
        surveyEvent.system_survey_event_type === "PUBLISHED_DATE" &&
        (surveyEvent.date_utc === null || surveyEvent.day_utc_time === null)
    ) {
        surveyEvent.date_utc = milisecondsToDateString(
            getUtcDateAtMidnightInMilsWithAddingDays(SET_ONE_DAY_AHEAD)
        );
        surveyEvent.day_utc_time = milisecondsToTimeString(
            getUTCDayTimeInMils()
        );
    }

    if (
        surveyEvent.system_survey_event_type === "LAST_DATE_TO_FILL" &&
        (surveyEvent.date_utc === null || surveyEvent.day_utc_time === null)
    ) {
        surveyEvent.date_utc = milisecondsToDateString(
            getUtcDateAtMidnightInMilsWithAddingDays(SET_ONE_WEEK_AHEAD)
        );
        surveyEvent.day_utc_time = milisecondsToTimeString(
            getUTCDayTimeInMils()
        );
    }

    const copyOfEvent = JSON.parse(JSON.stringify(surveyEvent));
    deleteToUpdateWizardSurveyEventWithoutReset(surveyEvent);
    addWizardSurveyEvent(copyOfEvent);
    debounceUpsertSurveyToApi();
});

export const convertGoogleFormToOnGoingSurvey = action(
    (formId: string, withResults: boolean) => {
        surveyStore.importGoogleForm(formId, withResults);
    }
);
