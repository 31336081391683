import {
    AlertVariants,
    ConstantsStringVariantsKeys,
    EmailAddress,
} from "@difftone/types";

type ConstantsVariants = Record<
    ConstantsStringVariantsKeys,
    { text: string; variant: AlertVariants }
>;

export const constantAlertVariants: ConstantsVariants = {
    general_warning: {
        text: "Something went wrong, please try again.",
        variant: "FAILURE",
    },
    delete_an_alert: {
        text: "Reminder could not be deleted. Please try again later.",
        variant: "FAILURE",
    },
    find_alert: {
        text: "Could not find alert",
        variant: "FAILURE",
    },
    activation_publish_alert: {
        text: "Couldn't find published-date or activation alerts",
        variant: "FAILURE",
    },
    last_day_to_fill_event: {
        text: "Couldn't find last day to fill event",
        variant: "FAILURE",
    },
    update_an_alert: {
        text: "Could not update alert",
        variant: "FAILURE",
    },
    survey_event: {
        text: "Could not find current survey event.",
        variant: "FAILURE",
    },
    email_exists: {
        text: "This email address has already received this survey.",
        variant: "FAILURE",
    },
    survey_shared: {
        text: " under drafts.",
        variant: "SUCCESS",
    },
    survey_results_failure: {
        text: "Could not find results for survey",
        variant: "FAILURE",
    },
    survey_scheduled: {
        text: "Survey was scheduled successfully!",
        variant: "SUCCESS",
    },
    survey_id_failure: {
        text: "Survey id not found",
        variant: "FAILURE",
    },
    survey_activation_success: {
        text: "Survey was activated successfully!",
        variant: "SUCCESS",
    },
    update_survey_participants: {
        text: "The new participants were successfully added and will be notified automatically",
        variant: "SUCCESS",
    },
    ongoing_survey_ready: {
        text: "Survey was saved and is ready for activation",
        variant: "SUCCESS",
    },
    ongoing_survey_send: {
        text: "Ongoing survey was successfully activated! In the hovering menu, hit 'Click-to-send' and send it as you go.",
        variant: "SUCCESS",
    },
    end_ongoing_survey: {
        text: "An ended survey cannot be reactivated. Are you sure you want to end this survey?",
        variant: "FAILURE",
    },
    survey_sent: {
        text: "Survey was sent successfully!",
        variant: "SUCCESS",
    },
    ongoing_survey_new_participants: {
        text: "New participants were successfully added and will be notified.",
        variant: "SUCCESS",
    },
    survey_deleted: {
        text: " has been deleted.",
        variant: "SUCCESS",
    },
    survey_archived: {
        text: " has been archived.",
        variant: "SUCCESS",
    },
    survey_unarchived: {
        text: " has been unarchived.",
        variant: "SUCCESS",
    },
    survey_url_failure: {
        text: "Survey url is not valid, please try again later.",
        variant: "FAILURE",
    },
    survey_time_left_notification: {
        text: "This survey ends in under 2 hours! We kindly recommend updating the survey's end date before making changes.",
        variant: "GENERAL",
    },
    answers_submitted: {
        text: "Thanks for submitting your answers!",
        variant: "SUCCESS",
    },
    rating_limit_reached: {
        text: "Rating questions are limited to 10 options.",
        variant: "GENERAL",
    },
    delete_shared_view: {
        text: "Are you sure you want to permanently delete this shared view?",
        variant: "DELETE",
    },
    survey_warning_delete: {
        text: "This Survey is active. Are you sure you want to permanently delete this survey?",
        variant: "FAILURE",
    },
    survey_delete: {
        text: "Are you sure you want to permanently delete this survey?",
        variant: "DELETE",
    },
    survey_to_share_failure: {
        text: "Could not find survey to share",
        variant: "FAILURE",
    },
    shared_survey_accept: {
        text: "Accept shared survey and move to draft?",
        variant: "GENERAL",
    },
    shared_survey_not_found: {
        text: "Could not find this shared survey",
        variant: "FAILURE",
    },
    shared_survey_accept_success: {
        text: "Shared survey was accepted and moved to draft.",
        variant: "SUCCESS",
    },
    shared_survey_delete: {
        text: "Are you sure you want to permanently delete this shared survey?",
        variant: "DELETE",
    },
    shared_survey_delete_success: {
        text: "Shared survey was deleted",
        variant: "SUCCESS",
    },
    create_survey_templates: {
        text: "Would you like to create a survey using?",
        variant: "SUCCESS",
    },
    draft_saved: {
        text: "Draft survey was saved",
        variant: "SUCCESS",
    },
    duplicate_survey: {
        text: "Your survey has been successfully duplicated. Would you like to go to Drafts?",
        variant: "SUCCESS",
    },
    element_failure: {
        text: "Could not find this element",
        variant: "FAILURE",
    },
    duplicate_survey_not_found: {
        text: "Could not find this duplicate survey",
        variant: "FAILURE",
    },
    duplicate_survey_failure: {
        text: "Could not find survey to duplicate",
        variant: "FAILURE",
    },
    organization_notification: {
        text: "Are you sure you want to save these changes?",
        variant: "GENERAL",
    },
    organization_success: {
        text: "Organisation settings were successfully saved.",
        variant: "SUCCESS",
    },
    categories_success: {
        text: "Categories were successfully updated",
        variant: "SUCCESS",
    },
    edit_survey_notification: {
        text: "You can edit only surveys which you created.",
        variant: "GENERAL",
    },
    login_notification: {
        text: "Sorry! Something went wrong. Please try logging in again.",
        variant: "SUCCESS",
    },
    download_notification: {
        text: "Would you like to download the following view to a CSV?",
        variant: "DOWNLOAD",
    },
    no_mobile_notification: {
        text: "Sorry, this feature is currently unavailable for mobile devices",
        variant: "MOBILE_SUPORT",
    },
    disabled_inbox_mobile_mode: {
        text: "Hi! Currently, Difftone users can only reply to surveys using their mobile devices. For all other applications, please connect from your desktop.",
        variant: "SUCCESS",
    },
};

export const alertsMailingListGroupTitles: { [key: string]: string } = {
    ALL: "All Participants",
    FILLED: "Already Responded",
    NOT_FILLED: "Not Yet Responded",
};

export const alertsMailingListGroupSendOutTitles: { [key: string]: string } = {
    ALL: "All Participants",
    NOT_FILLED: "Not Yet Responded",
};

export const getDynamicAlertVariantForOrganizationEmailValidation = (
    organizationName: string | undefined,
    emails: EmailAddress[]
): string => {
    if (organizationName !== "Public") {
        if (emails.length > 1) {
            return `Hi! The following emails ${emails.join(
                ", "
            )} are not within your organization registered domains`;
        }

        return `${emails} is not within your organisations' registered domains`;
    }

    return "While using your private email address, you can send surveys to other private users only, and not to users of an organization";
};
