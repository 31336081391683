import React from "react";
import { observer } from "mobx-react";
import {
    QuestionToAnswer,
    QuestionToAnswerDescreteQuestion,
    QuestionTypeName,
} from "@difftone/types";
import { surveyResponsesSummaryStore } from "@difftone/stores";
import { showDifftoneAlert } from "@difftone/actions";
import { getSurveyFromStoreByURL } from "@difftone/reducers";

import { Spinner } from "@difftone/shared-components";
import {
    question_option_result_box_row,
    option_label_class,
} from "./question-option-result-box.module.css";
import { MobileDetailedOptionResultBox } from "./components";

export type QuestionOptionResultBoxProps = {
    answersFrequency: QuestionToAnswer | null;
    answerValue: string;
    answerLabel: string;
    questionType: QuestionTypeName;
};

export const QuestionOptionResultBox = observer(
    (props: QuestionOptionResultBoxProps) => {
        const { answerValue, answerLabel, answersFrequency, questionType } =
            props;

        const survey = getSurveyFromStoreByURL();

        const surveyResponses =
            surveyResponsesSummaryStore.getSurveyResponseSummaryByFilterPlain(
                survey.uuid,
                []
            );

        if (!answersFrequency) {
            return null;
        }

        if (surveyResponses === undefined) {
            return <Spinner size="MEDIUM" />;
        }

        if (surveyResponses === null) {
            showDifftoneAlert("Could not find Responses", "FAILURE");
            return null;
        }

        let populationSize = null;
        let frequency = null;

        switch (questionType) {
            case "SHORT_TEXT":
            case "LONG_TEXT":
            case "DATE":
            case "TIME":
                //Only in case of open questions calculate no response population and total frequency of no response
                populationSize = surveyResponses.public_survey_responses.filter(
                    (response) => response.is_submit
                ).length;
                frequency = answersFrequency.length;
                break;
            default:
                populationSize = Object.values(
                    answersFrequency as QuestionToAnswer
                ).reduce((a, b) => a + b, 0);
                frequency = (
                    answersFrequency as QuestionToAnswerDescreteQuestion
                )[answerValue];
                break;
        }

        const numberOfAnsweredResponses =
            surveyResponses.public_survey_responses.filter(
                (response) => response.is_submit
            ).length;

        return (
            <div className={question_option_result_box_row}>
                <MobileDetailedOptionResultBox
                    totalResponseSummary={numberOfAnsweredResponses}
                    questionType={questionType}
                    frequency={frequency}
                    populationSize={populationSize}
                />
                <div className={option_label_class}>({answerLabel})</div>
            </div>
        );
    }
);
