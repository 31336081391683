import React from "react";

import { Question, QuestionType } from "@difftone/types";
import {
    QuestionTypeIcon,
    ShowDifftoneTooltip,
} from "@difftone/shared-components";

import {
    category_question,
    category_question_icon,
    category_question_name,
    category_question_wrapper,
} from "./results-category-question.module.css";
import { MAX_CHARACTERS_IN_QUESTION_TITLE } from "@difftone/constants";

export type ResultsCategoryQuestionProps = {
    question: Question<QuestionType>;
};

export const ResultsCategoryQuestion = (
    props: ResultsCategoryQuestionProps
) => {
    const {
        question: { title, question_type_name },
    } = props;

    let quistionTitle = null;

    if (title.length > MAX_CHARACTERS_IN_QUESTION_TITLE) {
        quistionTitle = (
            <div className={category_question_wrapper}>
                <ShowDifftoneTooltip tip={title} tooltipPosition="top">
                    <span className={category_question_name}>
                        {title
                            .slice(0, MAX_CHARACTERS_IN_QUESTION_TITLE)
                            .concat("...")}
                    </span>
                </ShowDifftoneTooltip>
            </div>
        );
    } else {
        quistionTitle = <span className={category_question_name}>{title}</span>;
    }

    return (
        <div className={category_question}>
            <QuestionTypeIcon
                className={category_question_icon}
                questionTypeName={question_type_name}
            />
            {quistionTitle}
        </div>
    );
};
