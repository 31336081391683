import React, { ChangeEvent } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import { Answer, Question, TimeAnswer, TimeQuestion } from "@difftone/types";
import { surveyResponseStore } from "@difftone/stores";
import { updateSelectedSurveyNonFilterQuestionResponse } from "@difftone/actions";
import { getAnswerByQuestion } from "@difftone/reducers";
import { Spinner } from "@difftone/shared-components";
import {
    getDayTimeFromTimeString,
    milisecondsToTimeString,
} from "@difftone/time-utils";

import timeIcon from "@difftone/assets/clock-icon.svg";

import {
    expanded_time_container,
    expanded_time_container_img_active,
    expanded_time_container_img_disabled,
} from "./expanded-time.module.css";

export type ExpandedTimeProps = {
    disabled: boolean;
    question: Question<TimeQuestion>;
    inPreviewMode: boolean;
    isEditableQuestion: boolean;
};

export const ExpandedTime = observer((props: ExpandedTimeProps) => {
    const { question, disabled, inPreviewMode, isEditableQuestion } = props;

    let surveyResponseUUID: string | undefined;
    if (inPreviewMode) {
        surveyResponseUUID = "";
    } else {
        const { getSelectedSurveyResponseUUID } = surveyResponseStore;
        surveyResponseUUID = getSelectedSurveyResponseUUID();
    }

    if (surveyResponseUUID === undefined) {
        return <Spinner size="MEDIUM" />;
    }

    const answer = getAnswerByQuestion(
        question,
        surveyResponseUUID
    ) as Answer<TimeAnswer>;

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (inPreviewMode) {
            return;
        }
        const value = event.target.value;

        answer.content.time = getDayTimeFromTimeString(value);
        updateSelectedSurveyNonFilterQuestionResponse(
            answer,
            surveyResponseUUID!
        );
    };

    return (
        <div className={expanded_time_container}>
            <input
                value={milisecondsToTimeString(answer.content.time) || ""}
                onChange={onChange}
                disabled={disabled || !isEditableQuestion}
                type="time"
            />
            <img
                className={clsx({
                    [expanded_time_container_img_active]: isEditableQuestion,
                    [expanded_time_container_img_disabled]: !isEditableQuestion,
                })}
                alt="time"
                src={timeIcon}
            />
        </div>
    );
});
