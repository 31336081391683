import {
    QuestionToAnswer,
    QuestionToAnswerDescreteQuestion,
    SupportedQuestionResultsMapAfterDigestionWithoutNulls,
} from "@difftone/types";
import { COLUMN_0 } from "@difftone/common-constants";

export const getMostPopularForSingleOrMultipleChoice = (
    digestedResultsRow: SupportedQuestionResultsMapAfterDigestionWithoutNulls,
    columnKey: string
) => {
    const mostPopular = Object.entries(digestedResultsRow[COLUMN_0]).reduce(
        (a, b) => {
            return a[1] >= b[1] ? a : b;
        }
    );
    const overallMostPopular = mostPopular[0];
    return (digestedResultsRow[columnKey] as QuestionToAnswerDescreteQuestion)[
        overallMostPopular
    ];
};

export const getAvgForRatingTypeColumn = (
    ratingColumn: QuestionToAnswerDescreteQuestion
) => {
    let weights = 0;

    Object.values(ratingColumn)?.forEach((keyValue) => {
        weights = weights + Number(keyValue);
    });

    const sum = Object.keys(ratingColumn)?.reduce((sum, answerValue) => {
        return sum + Number(answerValue) * Number(ratingColumn[answerValue]);
    }, 0);

    return weights ? sum / weights : 0;
};

export const getMostPopular = (
    singleChoiceAnswerPopularityMap: QuestionToAnswer
) => {
    const mostPopular = Object.entries(singleChoiceAnswerPopularityMap).reduce(
        (a, b) => {
            return a[1] >= b[1] ? a : b;
        }
    );
    return { [mostPopular[0]]: mostPopular[1] };
};
