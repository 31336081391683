import React, { FC, PropsWithChildren, useMemo } from "react";
import { observer } from "mobx-react";
import { v4 as uuid } from "uuid";
import clsx from "clsx";

import { showDifftoneAlert } from "@difftone/actions";
import {
    Popper,
    PopperProps,
    ChangeableTextWithInput,
} from "@difftone/shared-components";
import { Bookmark as BookmarkType, BaseSurvey } from "@difftone/types";
import { userProfileStore, navigationStore } from "@difftone/stores";
import { getSurveyFromStoreByUUID, sortBySurveyName } from "@difftone/reducers";
import bookmarkCreateIcon from "@difftone/assets/create-bookmark-icon-new.svg";

import { Bookmark } from "./components";

import {
    popper_bookmarks_results_root,
    bookmarks_popper,
    bookmarks_popper_content,
    results_action_icon,
    bookmarks_popper_item,
    bookmark_popper_scrollable,
} from "./bookmarks-popup.module.css";

export type BookmarksPopupProps = {
    bookmarks: BookmarkType[];
    anchorEl: PopperProps["anchorEl"];
    open: boolean;
    onClose: () => void;
    onBookmarkExport: (url: BookmarkType) => void;
    onShareView: (name: string) => void;
};

export type DisplayBookmark = BookmarkType & {
    surveyName: string;
};

export const BookmarksPopup: FC<PropsWithChildren<BookmarksPopupProps>> =
    observer(
        ({
            open,
            bookmarks,
            anchorEl,
            onClose,
            onBookmarkExport,
            onShareView,
        }: BookmarksPopupProps) => {
            const { currentPage } = navigationStore;

            const isNameAvailable = (bookmarkName: string) =>
                !bookmarks.some(({ name }) => name === bookmarkName);

            const selectedSurvey = useMemo<BaseSurvey>(() => {
                const surveyUuid = currentPage.split("/")[2];

                return getSurveyFromStoreByUUID(surveyUuid) as BaseSurvey;
            }, [currentPage]);

            const createBookmark = async (name: string) => {
                if (!name.trim().length) {
                    showDifftoneAlert(
                        "Can't create bookmark with empty name",
                        "FAILURE"
                    );
                    return;
                }

                if (!isNameAvailable(name)) {
                    showDifftoneAlert(
                        "Please provide a unique name",
                        "FAILURE"
                    );
                    return;
                }

                onClose();

                await userProfileStore.addBookmark({
                    name,
                    url: `${window.location.pathname}${window.location.search}`,
                    uuid: uuid(),
                });

                showDifftoneAlert(
                    `${name} has been saved successfully!`,
                    "SUCCESS"
                );
            };

            const bookmarksWithSurveyNames = useMemo<DisplayBookmark[]>(
                () =>
                    bookmarks.map((bookmark) => {
                        const surveyUUIDWithParams =
                            bookmark.url.split("/")[2] || "";
                        const surveyUUID = surveyUUIDWithParams.split("?")[0];

                        if (!surveyUUID) {
                            return {
                                ...bookmark,
                                surveyName: "",
                            };
                        }

                        const survey = getSurveyFromStoreByUUID(surveyUUID);

                        return {
                            ...bookmark,
                            surveyName: survey ? survey.survey_name : "",
                        };
                    }),
                [bookmarks]
            );

            const sortedBookmarks = useMemo<DisplayBookmark[]>(() => {
                return sortBySurveyName(
                    bookmarksWithSurveyNames,
                    selectedSurvey.survey_name
                ) as DisplayBookmark[];
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [bookmarksWithSurveyNames]);

            return (
                <Popper
                    className={popper_bookmarks_results_root}
                    contentClassName={bookmarks_popper}
                    onClose={onClose}
                    open={open}
                    anchorEl={anchorEl}
                >
                    <div
                        className={clsx(bookmarks_popper_content, {
                            [bookmark_popper_scrollable]:
                                sortedBookmarks.length > 3,
                        })}
                    >
                        <div className={bookmarks_popper_item}>
                            <ChangeableTextWithInput
                                baseValue="Bookmark this view"
                                onValueSave={createBookmark}
                                inputIcon={bookmarkCreateIcon}
                                inputIconStyles={results_action_icon}
                                onClose={onClose}
                            />
                        </div>

                        {sortedBookmarks.length
                            ? sortedBookmarks.map((displayBookmark) => (
                                  <Bookmark
                                      key={displayBookmark.uuid}
                                      onExport={onBookmarkExport}
                                      onClose={onClose}
                                      onShareView={onShareView}
                                      {...displayBookmark}
                                  />
                              ))
                            : null}
                    </div>
                </Popper>
            );
        }
    );
