import React from "react";
import { observer } from "mobx-react";
import { ScaleLinear, ScalePoint } from "d3";

import { GraphSelectedLegend, GraphLineItem } from "@difftone/types";

import { circle_scope } from "./circle.module.css";

const RADIUS_OF_CIRCLE = 3;

export type CircleProps = {
    key: string;
    data: GraphLineItem;
    yScale: ScaleLinear<number, number>;
    xScale: ScalePoint<string>;
    color: string;
    onMouseMove: (
        event: React.MouseEvent<SVGPathElement, MouseEvent>,
        item: GraphLineItem
    ) => void;
    onMouseLeave: () => void;
    onMouseOut: () => void;
    selectedLegendMap: {
        [key: string]: GraphSelectedLegend;
    };
};

export const Circle = observer((props: CircleProps) => {
    const {
        key,
        data,
        yScale,
        xScale,
        color,
        onMouseMove,
        onMouseLeave,
        onMouseOut,
        selectedLegendMap,
    } = props;

    return (
        selectedLegendMap[data.legend] && (
            <circle
                onMouseMove={(e) => onMouseMove(e, data)}
                onMouseLeave={onMouseLeave}
                onMouseOut={onMouseOut}
                key={key}
                r={RADIUS_OF_CIRCLE}
                stroke={color}
                fill={color}
                cx={xScale(data.axisBottom)}
                cy={yScale(+data.axisLeft)}
                className={circle_scope}
                style={{
                    display: selectedLegendMap[data.legend].circle
                        ? "block"
                        : "none",
                }}
            ></circle>
        )
    );
});
