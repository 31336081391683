import {
    getAuthInstance,
    localstorageUtils,
    setAuthInstance,
} from "@difftone/procedures";
import { createDifftoneAxios } from "@difftone/services";

const GOOGLE_FORMS_API_BASE_URL = `https://forms.googleapis.com/v1`;

export const getFormFromGoogleById = async (
    formId: string
): Promise<any | null> => {
    const REQUIRED_SCOPE =
        "https://www.googleapis.com/auth/forms.body.readonly";
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    if (currentAuthData.issuer !== "GOOGLE") return null;

    const googleUserInstance = getAuthInstance();

    const grantedScopes = googleUserInstance.getGrantedScopes().split(" ");

    if (!grantedScopes.includes(REQUIRED_SCOPE)) {
        try {
            const options = new gapi.auth2.SigninOptionsBuilder();
            options.setScope(REQUIRED_SCOPE);

            const res = await googleUserInstance.grant(options);
            if (res.error) {
                throw new Error(res.error);
            } else {
                setAuthInstance(res, "GOOGLE");
            }
        } catch (error) {
            console.error("[googleUserInstance.grant]:: failed", error);
            throw new Error(
                `[googleUserInstance.grant]:: failed: ${
                    (error as Error).message
                }`
            );
        }
    }

    try {
        const formResponse = await difftoneAxios.get(
            `${GOOGLE_FORMS_API_BASE_URL}/forms/${formId}`,
            {
                headers: {
                    Authorization: `Bearer ${
                        gapi.client.getToken().access_token
                    }`,
                },
            }
        );

        if (formResponse.data.error || !formResponse.data) return null;

        return formResponse.data;
    } catch (error) {
        throw new Error(
            `Error fetching form from Google:\n ${(error as Error).message}`
        );
    }
};

export const getFormResponsesFromGoogleById = async (
    formId: string
): Promise<any | null> => {
    const REQUIRED_SCOPE =
        "https://www.googleapis.com/auth/forms.responses.readonly";
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    if (currentAuthData.issuer !== "GOOGLE") return null;

    const googleUserInstance = getAuthInstance();

    const grantedScopes = googleUserInstance.getGrantedScopes().split(" ");

    if (!grantedScopes.includes(REQUIRED_SCOPE)) {
        try {
            const options = new gapi.auth2.SigninOptionsBuilder();
            options.setScope(REQUIRED_SCOPE);

            const res = await googleUserInstance.grant(options);
            if (res.error) {
                throw new Error(res.error);
            } else {
                setAuthInstance(res, "GOOGLE");
            }
        } catch (error) {
            console.error("[googleUserInstance.grant]:: failed", error);
            throw new Error(
                `[googleUserInstance.grant]:: failed: ${
                    (error as Error).message
                }`
            );
        }
    }

    try {
        const formResponse = await difftoneAxios.get(
            `${GOOGLE_FORMS_API_BASE_URL}/forms/${formId}/responses`,
            {
                headers: {
                    Authorization: `Bearer ${
                        gapi.client.getToken().access_token
                    }`,
                },
            }
        );

        if (formResponse.data.error || !formResponse.data) return null;

        return formResponse.data;
    } catch (error) {
        throw new Error(
            `Error fetching responses from Google:\n ${
                (error as Error).message
            }`
        );
    }
};
