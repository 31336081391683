import React from "react";

import mulitColorLineIcon from "@difftone/assets/mulit-color-line-icon.svg";
import grayLineIcon from "@difftone/assets/gray-line-icon.svg";
import greenLineIcon from "@difftone/assets/green-line-icon.svg";
import blackLineIcon from "@difftone/assets/black-line-icon.svg";

export type LineIconProps = {
    isSelected: boolean;
    isMouseHover: boolean;
    isDisabled: boolean;
    classNameOverride?: string;
};

export const LineIcon = (props: LineIconProps) => {
    const { isSelected, isMouseHover, isDisabled, classNameOverride } = props;

    if (isDisabled) {
        return <img className={classNameOverride || ""} src={grayLineIcon} />;
    }

    if (isMouseHover) {
        return <img className={classNameOverride || ""} src={greenLineIcon} />;
    }

    if (isSelected) {
        return <img className={classNameOverride || ""} src={blackLineIcon} />;
    }

    return <img className={classNameOverride || ""} src={mulitColorLineIcon} />;
};
