import React, { createRef, useEffect, useState } from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import { getSurveyFromStoreByURL } from "@difftone/reducers";
import { getLocalDateStrFromUtcDateStr } from "@difftone/time-utils";
import closeIcon from "@difftone/assets/close-datepicker-white.svg";

import { ScrollableTimeInput, ScrollableYearInput } from "./components";

import {
    calendar_dropdown_wrapper,
    calendar_time_dropdown,
    day_of_week,
    days_grid,
    calendar_month_header,
    calendar_month_header_selectors,
    today_calendar_marker,
    not_of_current_month,
    selected_date,
    past_unselectable_date,
    other_events_marker,
    calendar_only,
    scrollable_time_input_wrapper,
    calendar_month_header_no_time,
    calendar_month_header_selectors_no_time,
    response_mode_calendar_dropdown_wrapper,
} from "./calendar-time-dropdown.module.css";
import { MOBILE_WIDTH_BREAKPOINT } from "@difftone/constants";
import { AddBoxShadow } from "../add-box-shadow";
import { SimpleSurvey } from "@difftone/types";

const WEEKDAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const TODAY = dayjs().format("YYYY-MM-DD");

const INITIAL_YEAR = dayjs().format("YYYY");
const INITIAL_MONTH = dayjs().format("M");

export type DayOfMonth = {
    date: string;
    dayOfMonth: number;
    isCurrentMonth: boolean;
};

export type CalendarTimeDropdownProps = {
    onBlur: () => void;
    onSetEventTime: (newDateString: string, newTimeString: string) => void;
    timeValue: string;
    dateValue: string;
    inEditMode?: boolean;
    showTime?: boolean;
    className?: string;
    responseMode?: boolean;
};

export const CalendarTimeDropdown = (props: CalendarTimeDropdownProps) => {
    const _survey = getSurveyFromStoreByURL();
    const {
        className = "",
        onBlur,
        onSetEventTime,
        timeValue,
        dateValue,
        inEditMode,
        showTime = true,
        responseMode,
    } = props;

    const [daysOfMonth, setDaysOfMonth] = useState<DayOfMonth[] | null>(null);
    const calendarRef = createRef<HTMLDivElement>();
    let currentMonthDays: DayOfMonth[];

    let previousMonthDays: DayOfMonth[];
    let nextMonthDays: DayOfMonth[];

    const getWeekday = (date: string) => {
        return dayjs(date).day();
    };

    const getNumberOfDaysInMonth = (year: number, month: number) => {
        return dayjs(`${year}-${month}-01`).daysInMonth();
    };

    const createDaysForNextMonth = (year: number, month: number) => {
        const lastDayOfTheMonthWeekday = getWeekday(
            `${year}-${month}-${currentMonthDays.length}`
        );

        const nextMonth = dayjs(`${year}-${month}-01`).add(1, "month");

        const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
            ? 7 - lastDayOfTheMonthWeekday
            : lastDayOfTheMonthWeekday;

        return [...Array(visibleNumberOfDaysFromNextMonth)].map(
            (day, index) => {
                return {
                    date: dayjs(
                        `${nextMonth.year()}-${nextMonth.month() + 1}-${
                            index + 1
                        }`
                    ).format("YYYY-MM-DD"),
                    dayOfMonth: index + 1,
                    isCurrentMonth: false,
                };
            }
        );
    };

    const createDaysForPreviousMonth = (year: number, month: number) => {
        const firstDayOfTheMonthWeekday = getWeekday(currentMonthDays[0].date);
        const previousMonth = dayjs(`${year}-${month}-01`).subtract(1, "month");

        const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
            ? firstDayOfTheMonthWeekday - 1
            : 6;

        const previousMonthLastMondayDayOfMonth = dayjs(
            currentMonthDays[0].date
        )
            .subtract(visibleNumberOfDaysFromPreviousMonth, "day")
            .date();

        return [...Array(visibleNumberOfDaysFromPreviousMonth)].map(
            (day, index) => {
                return {
                    date: dayjs(
                        `${previousMonth.year()}-${previousMonth.month() + 1}-${
                            previousMonthLastMondayDayOfMonth + index
                        }`
                    ).format("YYYY-MM-DD"),
                    dayOfMonth: previousMonthLastMondayDayOfMonth + index,
                    isCurrentMonth: false,
                };
            }
        );
    };

    const createDaysForCurrentMonth = (year: number, month: number) => {
        return [...Array(getNumberOfDaysInMonth(year, month))].map(
            (day, index) => {
                return {
                    date: dayjs(`${year}-${month}-${index + 1}`).format(
                        "YYYY-MM-DD"
                    ),
                    dayOfMonth: index + 1,
                    isCurrentMonth: true,
                };
            }
        );
    };

    const createCalendar = (year = INITIAL_YEAR, month = INITIAL_MONTH) => {
        setDaysOfMonth([]);
        document.querySelector("div[data-selected-month]")!.innerHTML = dayjs(
            new Date(Number(year), Number(month) - 1)
        ).format("MMMM YYYY");

        currentMonthDays = createDaysForCurrentMonth(
            Number(year),
            Number(month)
        );

        previousMonthDays = createDaysForPreviousMonth(
            Number(year),
            Number(month)
        );

        nextMonthDays = createDaysForNextMonth(Number(year), Number(month));

        const days = [
            ...previousMonthDays,
            ...currentMonthDays,
            ...nextMonthDays,
        ];

        setDaysOfMonth(days);
    };

    const initMonthSelectors = (year: string, month: string) => {
        let selectedMonth = dayjs(new Date(Number(year), Number(month) - 1, 1));

        document
            .querySelector("span[data-previous-month-selector]")!
            .addEventListener("click", () => {
                selectedMonth = dayjs(selectedMonth).subtract(1, "month");
                createCalendar(
                    selectedMonth.format("YYYY"),
                    selectedMonth.format("M")
                );
            });
        document
            .querySelector("span[data-present-month-selector]")!
            .addEventListener("click", () => {
                selectedMonth = dayjs(
                    new Date(Number(year), Number(month) - 1, 1)
                );
                createCalendar(
                    selectedMonth.format("YYYY"),
                    selectedMonth.format("M")
                );
            });
        document
            .querySelector("span[data-next-month-selector]")!
            .addEventListener("click", () => {
                selectedMonth = dayjs(selectedMonth).add(1, "month");
                createCalendar(
                    selectedMonth.format("YYYY"),
                    selectedMonth.format("M")
                );
            });
    };

    const getDayClassName = (day: DayOfMonth) => {
        const correctFormat = dayjs(dateValue).format("YYYY-MM-DD");

        if (responseMode) {
            if (correctFormat === day.date) {
                return selected_date;
            }
            if (day.date === TODAY) {
                return today_calendar_marker;
            }
        } else {
            if (correctFormat === day.date) {
                return selected_date;
            }
            if (day.date < TODAY) {
                return past_unselectable_date;
            }
            if (day.date === TODAY) {
                return today_calendar_marker;
            }
            if (!day.isCurrentMonth) {
                return not_of_current_month;
            }
        }
    };

    const addMarkerIfOtherEventsOnThisDate = (calendarDay: string) => {
        if (!_survey) {
            return;
        }

        if (_survey.survey_class === "ONGOING") {
            throw Error(
                "[Difftone]::CalendarTimeDropdown ongoing survey does not have survey events."
            );
        }

        const survey = _survey as SimpleSurvey;
        return (
            survey.survey_events.some(
                (surveyEvent) =>
                    dayjs(
                        //TODO: Add null handling for function and function return
                        getLocalDateStrFromUtcDateStr(
                            surveyEvent.date_utc!,
                            surveyEvent.day_utc_time!
                        )
                    ).format("YYYY-MM-DD") === calendarDay
            ) || false
        );
    };

    const calendarOnlySelectDate = (newDate: string) => {
        onSetEventTime(newDate, timeValue);
    };

    const updateCalendarOnYearChange = (
        updatedDateString: string,
        timeString: string
    ) => {
        const getYear = updatedDateString.split("-")[0];
        const getMonths = updatedDateString.split("-")[1];

        createCalendar(getYear, getMonths);
        initMonthSelectors(getYear, getMonths);
        onSetEventTime(updatedDateString, timeString);
    };

    useEffect(() => {
        calendarRef.current?.focus();
        const yearValue = dateValue.split("-")[0];
        const monthValue = dateValue.split("-")[1];
        createCalendar(yearValue, monthValue);
        initMonthSelectors(
            yearValue ? yearValue : INITIAL_YEAR,
            monthValue ? monthValue : INITIAL_MONTH
        );
        //eslint-disable-next-line
    }, []);

    return (
        <AddBoxShadow>
            <div
                style={
                    inEditMode
                        ? {
                              left:
                                  window.innerWidth < MOBILE_WIDTH_BREAKPOINT
                                      ? "-50px"
                                      : "-40px",
                          }
                        : {}
                }
                className={
                    responseMode
                        ? response_mode_calendar_dropdown_wrapper
                        : calendar_dropdown_wrapper
                }
            >
                <div
                    tabIndex={0}
                    ref={calendarRef}
                    className={
                        showTime || responseMode
                            ? clsx(calendar_time_dropdown, className)
                            : clsx(
                                  calendar_time_dropdown,
                                  className,
                                  calendar_only
                              )
                    }
                >
                    <div>
                        <section
                            className={
                                responseMode
                                    ? calendar_month_header
                                    : clsx(
                                          calendar_month_header,
                                          !showTime &&
                                              calendar_month_header_no_time
                                      )
                            }
                        >
                            <span data-present-month-selector>Date</span>
                            <section
                                className={
                                    responseMode
                                        ? calendar_month_header_selectors
                                        : clsx(
                                              calendar_month_header_selectors,
                                              !showTime &&
                                                  calendar_month_header_selectors_no_time
                                          )
                                }
                            >
                                <span data-previous-month-selector>{"<"}</span>
                                <div data-selected-month></div>
                                <span data-next-month-selector>{">"}</span>
                            </section>
                            {showTime || responseMode ? null : (
                                <img
                                    onClick={onBlur}
                                    src={closeIcon}
                                    alt="close"
                                />
                            )}
                        </section>
                        <ol id="days-of-week" className={day_of_week}>
                            {WEEKDAYS.map((weekDay) => {
                                return <li key={weekDay}>{weekDay}</li>;
                            })}
                        </ol>
                        <ol id="calendar-days" className={days_grid}>
                            {daysOfMonth?.map((day, index) => {
                                return (
                                    <li
                                        onClick={() =>
                                            calendarOnlySelectDate(day.date)
                                        }
                                        className={getDayClassName(day)}
                                        key={day.date}
                                    >
                                        {responseMode ? null : addMarkerIfOtherEventsOnThisDate(
                                              day.date
                                          ) ? (
                                            <div
                                                className={other_events_marker}
                                            ></div>
                                        ) : null}
                                        {day.dayOfMonth}
                                    </li>
                                );
                            })}
                        </ol>
                    </div>
                </div>
                {showTime ? (
                    <div
                        style={inEditMode ? { left: "242px" } : {}}
                        className={scrollable_time_input_wrapper}
                    >
                        <ScrollableTimeInput
                            setTime={onSetEventTime}
                            timeValue={timeValue}
                            dateValue={dateValue}
                            closeCalendar={onBlur}
                        />
                    </div>
                ) : null}
                {responseMode ? (
                    <div
                        style={inEditMode ? { left: "242px" } : {}}
                        className={scrollable_time_input_wrapper}
                    >
                        <ScrollableYearInput
                            setTime={updateCalendarOnYearChange}
                            timeValue={timeValue}
                            dateValue={dateValue}
                            closeCalendar={onBlur}
                        />
                    </div>
                ) : null}
            </div>
        </AddBoxShadow>
    );
};
