import React, { MouseEvent } from "react";
import { UserIcon } from "@difftone/shared-components";

import {
    mailing_list_picker,
    active_style,
    list_counter,
    user_icon_scope,
} from "./mailing-list-picker.module.css";

export type MailingListPickerProps = {
    listName: string;
    isActive: boolean;
    counter: number;
    onClick: (event: MouseEvent<HTMLDivElement>) => void;
};

const COLOR_GREEN = "GREEN";
const COLOR_BRIGHT_GREEN = "BRIGHT_GREEN";

export const MailingListPicker = (props: MailingListPickerProps) => {
    const { listName, isActive, counter, onClick } = props;
    return (
        <div onClick={onClick} className={mailing_list_picker}>
            <div className={isActive ? active_style : ""}>{listName}</div>
            <UserIcon
                color={isActive ? COLOR_GREEN : COLOR_BRIGHT_GREEN}
                className={user_icon_scope}
            />
            <div className={list_counter}>({counter})</div>
        </div>
    );
};
