import React from "react";
import { observer } from "mobx-react";
import { Question, QuestionType } from "@difftone/types";
import { surveyResponseDisplayStore } from "@difftone/stores";
import {
    setFocusedSurveyResponseInputCard,
    toggleLeftMenu,
} from "@difftone/actions";
import { MOBILE_WIDTH_BREAKPOINT } from "@difftone/constants";

import {
    survey_left_menu_list_item,
    active_list_item_title,
    index_class,
} from "./survey-left-menu-list-item.module.css";

export type SurveyLeftMenuListItemProps = {
    index: number;
    surveyCard: Question<QuestionType>;
};

export const SurveyLeftMenuListItem = observer(
    (props: SurveyLeftMenuListItemProps) => {
        const { index, surveyCard } = props;
        const { focusedSurveyResponseInputCard } = surveyResponseDisplayStore;

        const focusCardByIndex = () => {
            const cardElement: NodeListOf<HTMLDivElement> =
                document.querySelectorAll("div[data-survey-focused-card]");
            const uuidToFocus = cardElement[index - 1].getAttribute("uuid");
            setFocusedSurveyResponseInputCard(uuidToFocus);

            if (window.innerWidth < MOBILE_WIDTH_BREAKPOINT) {
                toggleLeftMenu();
            }
        };

        const isListItemActive =
            focusedSurveyResponseInputCard === surveyCard.uuid;

        return (
            <div
                onClick={focusCardByIndex}
                className={survey_left_menu_list_item}
            >
                <div className={index_class}>{index}.</div>
                <div className={isListItemActive ? active_list_item_title : ""}>
                    {surveyCard.title}
                </div>
            </div>
        );
    }
);
