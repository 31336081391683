import { ResultsFilter, UUID } from "@difftone/types";
import { sharedResultsResultsMapStore } from "@difftone/stores";

//Public
export const getResultsMapsBySharedResultsUuidAndCompare = (
    sharedResultsUuid: UUID,
    filtersActiveAsCompares: ResultsFilter[],
    filtersNotActiveAsCompares: ResultsFilter[]
) => {
    return getResultsTableAccordingToNumOfCompares(
        sharedResultsUuid,
        filtersActiveAsCompares,
        filtersNotActiveAsCompares
    );
};

const getResultsTableAccordingToNumOfCompares = (
    sharedResultsUuid: UUID,
    filtersActiveAsCompares: ResultsFilter[],
    filtersNotActiveAsCompares: ResultsFilter[]
) => {
    switch (filtersActiveAsCompares.length) {
        case 0:
            return getResultsMapTableForNoCompare(
                sharedResultsUuid,
                filtersNotActiveAsCompares
            );
        case 1:
            //1
            const firstResultsMaps = getResultsMapTableForNoCompare(
                sharedResultsUuid,
                filtersNotActiveAsCompares
            );

            //rest
            const restResultsMap = getResultsMapTableForSingleCompareBy(
                sharedResultsUuid,
                filtersActiveAsCompares.pop()!,
                filtersNotActiveAsCompares
            );

            return [...firstResultsMaps, ...restResultsMap];
        case 2:
            //1
            const firstResultsMaps1 = getResultsMapTableForNoCompare(
                sharedResultsUuid,
                filtersNotActiveAsCompares
            );
            //rest
            const restOfDoubleResultsMap = getResultsMapTableForDoubleCompareBy(
                sharedResultsUuid,
                filtersActiveAsCompares,
                filtersNotActiveAsCompares
            );
            return [...firstResultsMaps1, ...restOfDoubleResultsMap];

        default:
            throw Error(
                `Cannot support more than 2 compares, found ${filtersActiveAsCompares.length}`
            );
    }
};

const getSingleResultsMap = (
    sharedResultsUuid: UUID,
    resultsFilters: ResultsFilter[]
) => {
    const _key = sharedResultsResultsMapStore.extractKey(
        sharedResultsUuid,
        resultsFilters
    );
    const sharedResultsMap =
        sharedResultsResultsMapStore.getSharedResultsResultsMap(_key);

    return sharedResultsMap;
};

//For 0 compare by
const getResultsMapTableForNoCompare = (
    sharedResultsUuid: UUID,
    filtersNotActiveAsCompares: ResultsFilter[]
) => {
    const sharedResultsMaps = getSingleResultsMap(
        sharedResultsUuid,
        filtersNotActiveAsCompares
    );

    if (sharedResultsMaps === undefined || sharedResultsMaps === null) {
        return [sharedResultsMaps];
    }

    return [sharedResultsMaps];
};

//For 1 compare by
const getResultsMapTableForSingleCompareBy = (
    sharedResultsUuid: UUID,
    filtersActiveAsCompare: ResultsFilter,
    filtersNotActiveAsCompares: ResultsFilter[]
) => {
    const restResultsMaps = filtersActiveAsCompare.filterValues.map(
        (filterValue) => {
            const _currentCompareFilterCopy: ResultsFilter = JSON.parse(
                JSON.stringify(filtersActiveAsCompare)
            );

            _currentCompareFilterCopy.filterValues = [filterValue];

            const _resultsFilters = [
                ...filtersNotActiveAsCompares,
                _currentCompareFilterCopy,
            ];

            return getSingleResultsMap(sharedResultsUuid, _resultsFilters);
        }
    );

    return restResultsMaps;
};

//For 2 compare by
const getResultsMapTableForDoubleCompareBy = (
    sharedResultsUuid: UUID,
    filtersActiveAsCompares: ResultsFilter[],
    filtersNotActiveAsCompares: ResultsFilter[]
) => {
    const restResultsMaps = filtersActiveAsCompares[1].filterValues.flatMap(
        (filterValue) => {
            const _currentCompareFilterCopy: ResultsFilter = JSON.parse(
                JSON.stringify(filtersActiveAsCompares[1])
            );

            _currentCompareFilterCopy.filterValues = [filterValue];

            const _resultsFilters = [
                ...filtersNotActiveAsCompares,
                _currentCompareFilterCopy,
            ];

            return getResultsMapTableForSingleCompareBy(
                sharedResultsUuid,
                filtersActiveAsCompares[0],
                _resultsFilters
            );
        }
    );

    return restResultsMaps;
};
