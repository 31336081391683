import { wizardStore } from "@difftone/stores";
import {
    QuestionInvalidNameValidationType,
    QuestionInvalidNicknameValidationType,
    QuestionsDuplicationValidationType,
    BaseSurvey,
    SurveyEventInvalidTitleValidationType,
    SurveyEventsDateFormatValidationType,
    SurveyEventsDateValidationType,
    SurveyEventTitleDuplicationValidationType,
    SurveyNameValidationType,
    SurveyNumOfParticipantsValidationType,
    SurveyNumOfQuestionsValidationType,
    UUID,
    ValidationResult,
    ValidationType,
    SimpleSurvey,
} from "@difftone/types";

export const getValidationErrorsFromStoreByUUID = (
    objectToValidateUuid: UUID,
    survey: BaseSurvey
): ValidationResult<ValidationType>[] => {
    const res: ValidationResult<ValidationType>[] = [];
    const { wizardValidationErrors } = wizardStore;

    switch (survey.survey_class) {
        case "ONGOING":
            return wizardOngoingSurveyValidationErrors(
                wizardValidationErrors,
                res,
                objectToValidateUuid
            );
        case "SIMPLE":
        default:
            const simpleSurvey = survey as SimpleSurvey;
            return wizardSimpleSurveyValidationErrors(
                wizardValidationErrors,
                res,
                simpleSurvey,
                objectToValidateUuid
            );
    }
};

const wizardSimpleSurveyValidationErrors = (
    wizardValidationErrors: ValidationResult<ValidationType>[],
    res: ValidationResult<ValidationType>[],
    survey: SimpleSurvey,
    objectToValidateUuid: UUID
): ValidationResult<ValidationType>[] => {
    wizardValidationErrors.forEach((validationError) => {
        switch (validationError.validation_error_code) {
            case "SURVEY_NAME_VALIDATION":
                if (
                    (
                        validationError as ValidationResult<SurveyNameValidationType>
                    ).validation_error_type?.survey_uuid ===
                    objectToValidateUuid
                ) {
                    res.push(validationError);
                }
                break;
            case "SURVEY_NAME_DUPLICATION":
                if (
                    (
                        validationError as ValidationResult<SurveyNameValidationType>
                    ).validation_error_type?.survey_uuid ===
                    objectToValidateUuid
                ) {
                    res.push(validationError);
                }
                break;
            case "NUM_OF_QUESTIONS_VALIDATION":
                if (
                    (
                        validationError as ValidationResult<SurveyNumOfQuestionsValidationType>
                    ).validation_error_type?.survey_uuid ===
                    objectToValidateUuid
                ) {
                    res.push(validationError);
                }
                break;
            case "NUM_OF_PARTICIPANTS_VALIDATION":
                if (
                    (
                        validationError as ValidationResult<SurveyNumOfParticipantsValidationType>
                    ).validation_error_type?.survey_uuid ===
                    objectToValidateUuid
                ) {
                    res.push(validationError);
                }
                break;
            case "SURVEY_EVENT_ILLEGAL_DATE":
                if (
                    (
                        validationError as ValidationResult<SurveyEventsDateValidationType>
                    ).validation_error_type?.invalid_events_dates_uuids.includes(
                        objectToValidateUuid
                    )
                ) {
                    res.push(validationError);
                }
                break;
            case "SURVEY_EVENT_ILLEGAL_DATE_FORMAT":
                if (
                    (
                        validationError as ValidationResult<SurveyEventsDateFormatValidationType>
                    ).validation_error_type?.invalid_events_dates_uuids.includes(
                        objectToValidateUuid
                    )
                ) {
                    res.push(validationError);
                }
                break;
            case "SURVEY_EVENT_NAME_VALIDATION":
                if (
                    (
                        validationError as ValidationResult<SurveyEventInvalidTitleValidationType>
                    ).validation_error_type?.event_uuid === objectToValidateUuid
                ) {
                    res.push(validationError);
                }
                break;
            case "SURVEY_EVENT_NAME_DUPLICATION_VALIDATION":
                const surveyEvent = survey.survey_events.find(
                    (event) => event.uuid === objectToValidateUuid
                );
                if (!surveyEvent) {
                    break;
                }
                if (
                    (
                        validationError as ValidationResult<SurveyEventTitleDuplicationValidationType>
                    ).validation_error_type?.duplication_indexes[
                        surveyEvent.title
                    ]
                ) {
                    res.push(validationError);
                }
                break;
            case "QUESTION_NAME_VALIDATION":
                if (
                    (
                        validationError as ValidationResult<QuestionInvalidNameValidationType>
                    ).validation_error_type?.question_id ===
                    objectToValidateUuid
                ) {
                    res.push(validationError);
                }
                break;
            case "QUESTION_NAME_DUPLICATION_VALIDATION":
                if (
                    (
                        validationError as ValidationResult<QuestionInvalidNicknameValidationType>
                    ).validation_error_type?.question_id ===
                    objectToValidateUuid
                ) {
                    res.push(validationError);
                }
                break;
            case "QUESTION_NICKNAME_DUPLICATION_VALIDATION":
                if (
                    (
                        validationError as ValidationResult<QuestionInvalidNicknameValidationType>
                    ).validation_error_type?.question_id ===
                    objectToValidateUuid
                ) {
                    res.push(validationError);
                }
                break;
            case "QUESTION_EMPTY_NICKNAME_VALIDATION":
                if (
                    (
                        validationError as ValidationResult<QuestionInvalidNicknameValidationType>
                    ).validation_error_type?.question_id ===
                    objectToValidateUuid
                ) {
                    res.push(validationError);
                }
                break;
            case "QUESTION_OPTION_NAMES_DUPLICATION":
                if (
                    (
                        validationError as ValidationResult<QuestionsDuplicationValidationType>
                    ).validation_error_type?.question_id ===
                    objectToValidateUuid
                ) {
                    res.push(validationError);
                }
                break;
            case "QUESTION_BOOKING_ILLEGAL_DATE":
                if (
                    (
                        validationError as ValidationResult<SurveyEventsDateValidationType>
                    ).validation_error_type?.invalid_events_dates_uuids.includes(
                        objectToValidateUuid
                    )
                ) {
                    res.push(validationError);
                }
                break;
            default:
                break;
        }
    });
    return res;
};

const wizardOngoingSurveyValidationErrors = (
    wizardValidationErrors: ValidationResult<ValidationType>[],
    res: ValidationResult<ValidationType>[],
    objectToValidateUuid: UUID
): ValidationResult<ValidationType>[] => {
    wizardValidationErrors.forEach((validationError) => {
        switch (validationError.validation_error_code) {
            case "SURVEY_NAME_VALIDATION":
                if (
                    (
                        validationError as ValidationResult<SurveyNameValidationType>
                    ).validation_error_type?.survey_uuid ===
                    objectToValidateUuid
                ) {
                    res.push(validationError);
                }
                break;
            case "SURVEY_NAME_DUPLICATION":
                if (
                    (
                        validationError as ValidationResult<SurveyNameValidationType>
                    ).validation_error_type?.survey_uuid ===
                    objectToValidateUuid
                ) {
                    res.push(validationError);
                }
                break;
            case "NUM_OF_QUESTIONS_VALIDATION":
                if (
                    (
                        validationError as ValidationResult<SurveyNumOfQuestionsValidationType>
                    ).validation_error_type?.survey_uuid ===
                    objectToValidateUuid
                ) {
                    res.push(validationError);
                }
                break;
            case "QUESTION_NAME_VALIDATION":
                if (
                    (
                        validationError as ValidationResult<QuestionInvalidNameValidationType>
                    ).validation_error_type?.question_id ===
                    objectToValidateUuid
                ) {
                    res.push(validationError);
                }
                break;
            case "QUESTION_NAME_DUPLICATION_VALIDATION":
                if (
                    (
                        validationError as ValidationResult<QuestionInvalidNicknameValidationType>
                    ).validation_error_type?.question_id ===
                    objectToValidateUuid
                ) {
                    res.push(validationError);
                }
                break;
            case "QUESTION_NICKNAME_DUPLICATION_VALIDATION":
                if (
                    (
                        validationError as ValidationResult<QuestionInvalidNicknameValidationType>
                    ).validation_error_type?.question_id ===
                    objectToValidateUuid
                ) {
                    res.push(validationError);
                }
                break;
            case "QUESTION_EMPTY_NICKNAME_VALIDATION":
                if (
                    (
                        validationError as ValidationResult<QuestionInvalidNicknameValidationType>
                    ).validation_error_type?.question_id ===
                    objectToValidateUuid
                ) {
                    res.push(validationError);
                }
                break;
            case "QUESTION_OPTION_NAMES_DUPLICATION":
                if (
                    (
                        validationError as ValidationResult<QuestionsDuplicationValidationType>
                    ).validation_error_type?.question_id ===
                    objectToValidateUuid
                ) {
                    res.push(validationError);
                }
                break;
            default:
                break;
        }
    });
    return res;
};
