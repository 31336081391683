import React from "react";
import { Anonimity, QuestionTypeName } from "@difftone/types";
import { isAnonymous } from "@difftone/anonymity-util";
import { DetailedResultBoxAnonymousType } from "@difftone/shared-components";
import { COLUMN_0 } from "@difftone/common-constants";

import {
    detailed_option_result_box_wrapper,
    result_box,
    option_percentage,
    option_count,
    secondary_result_box,
} from "./detailed-option-result-box.module.css";

export type DetailedOptionResultBoxProps = {
    color: "primary" | "secondary";
    frequency: number;
    populationSize: number;
    totalResponseSummary: number;
    questionType: QuestionTypeName;
    surveyAnonymityLevel: Anonimity;
    columnKey: String;
    isAnonymous: boolean;
};

export const DetailedOptionResultBox = (
    props: DetailedOptionResultBoxProps
) => {
    const {
        color,
        frequency,
        populationSize,
        totalResponseSummary,
        questionType,
        surveyAnonymityLevel,
        columnKey,
        isAnonymous,
    } = props;

    let frequencyInPercentage = 0;
    let frequencyItem = null;
    switch (questionType) {
        case "RATING":
        case "SINGLE_CHOICE":
        case "MULTIPLE_CHOICE":
            const totalResponse =
                columnKey === COLUMN_0 ? totalResponseSummary : populationSize;

            frequencyInPercentage = Number(
                ((frequency / totalResponse) * 100).toFixed(0)
            );
            frequencyItem = frequency;
            break;
        case "LONG_TEXT":
        case "SHORT_TEXT":
        case "DATE":
        case "TIME":
            const noResponseFrequency = populationSize - frequency;

            frequencyInPercentage = Number(
                ((noResponseFrequency / totalResponseSummary) * 100).toFixed(0)
            );
            frequencyItem = noResponseFrequency;
            break;
        default:
            frequencyInPercentage = Number(
                ((frequency / populationSize) * 100).toFixed(0)
            );
            frequencyItem = frequency;
            break;
    }

    return isAnonymous ? (
        <DetailedResultBoxAnonymousType
            color="secondary"
            surveyAnonymityLevel={surveyAnonymityLevel}
            frequencyInPercentage={frequencyInPercentage}
        />
    ) : (
        <div className={detailed_option_result_box_wrapper}>
            <div
                className={
                    color === "primary" ? result_box : secondary_result_box
                }
            >
                <div className={option_count}>
                    {frequencyItem ? frequencyItem : ""}
                </div>
                <div className={option_percentage}>
                    {!(
                        isNaN(frequencyInPercentage) ||
                        frequencyInPercentage === 0
                    )
                        ? `${frequencyInPercentage}%`
                        : ""}
                </div>
            </div>
        </div>
    );
};
