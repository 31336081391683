import { observable, ObservableMap } from "mobx";

export type State = "S1" | "S2" | "S3" | "S4";

// Private
const _currentState: ObservableMap<string, State> = observable.map({});

export const store = {
    getStatus: (key: string) => {
        return _currentState.get(key);
    },

    setState: (key: string, newState: State) => {
        _currentState.set(key, newState);
    },
};
