import React from "react";

import {
    simple_results_sorting_action_button,
    active_color,
} from "./simple-results-sorting-action-button.module.css";

export type SimpleResultsSortingActionButtonProps = {
    isVisible: boolean;
    direction: "ASCENDING" | "DESCENDING" | null;
    onClickHandler: () => void;
    isActive: boolean;
};

export const SimpleResultsSortingActionButton = (
    props: SimpleResultsSortingActionButtonProps
) => {
    const { isVisible, direction, onClickHandler, isActive } = props;

    return isVisible ? (
        <div
            onClick={onClickHandler}
            onMouseOver={(event) => event.stopPropagation()}
            className={
                isActive
                    ? [simple_results_sorting_action_button, active_color].join(
                          " "
                      )
                    : simple_results_sorting_action_button
            }
        >
            {!isActive && <span>&#8595;</span>}
            {isActive && direction === "ASCENDING" && <span>&#8595;</span>}
            {isActive && direction === "DESCENDING" && <span>&#8593;</span>}
        </div>
    ) : null;
};
