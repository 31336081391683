import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import { SurveyEventAlert, TimeUnits } from "@difftone/types";

dayjs.extend(duration);

const MINUTES_TO_MILISECONDS_FACTOR = 60000;
const FULL_DAY_IN_MILLISECONDS = 24 * 60 * MINUTES_TO_MILISECONDS_FACTOR;
const TWO_HOURS_IN_MINUTES = 120;

export const SET_ONE_DAY_AHEAD = 1;
export const SET_ONE_WEEK_AHEAD = 8;

export const getNormalizeOffsetInMiliseconds = () => {
    return new Date().getTimezoneOffset() * MINUTES_TO_MILISECONDS_FACTOR * -1;
};

export const getNormalizeOffsetInMilisecondsFromDateString = (date: string) => {
    return (
        new Date(date).getTimezoneOffset() * MINUTES_TO_MILISECONDS_FACTOR * -1
    );
};

//The milliseconds of stat day at 00:00
export const getUtcDateAtMidnightInMils = () => {
    const currentDate = new Date();
    const utc_date_at_midnight = new Date(
        currentDate.getFullYear(),
        currentDate.getUTCMonth(),
        currentDate.getUTCDate()
    );
    return utc_date_at_midnight.getTime();
};

export const getUtcDateFromLocalDateAndTime = (
    localDate: string,
    localDayTime: string
) => {
    if (typeof localDate !== "string") {
        console.error("Date in bad format");
        return "undefined";
    }

    const localTimeMinusOffSetMiliseconds =
        getDayTimeFromTimeString(localDayTime) -
        getNormalizeOffsetInMiliseconds();

    if (localTimeMinusOffSetMiliseconds > 0) {
        return localDate;
    }

    //Changing date
    const localDateInMilSeconds = new Date(localDate);

    const utcDateInMilSeconds = new Date(
        localDateInMilSeconds.getTime() - FULL_DAY_IN_MILLISECONDS
    ).getTime();

    const utcDateStr = milisecondsToDateString(utcDateInMilSeconds);
    return utcDateStr;
};

export const getUTCDayTimeInMils = () => {
    const currentDate = new Date();
    return (
        currentDate.getTime() -
        getNormalizeOffsetInMiliseconds() -
        getUtcDateAtMidnightInMils()
    );
};

export const getDateAtMidnightFromDateString = (date: string) => {
    return new Date(date).getTime();
};

export const getLocalDateStrFromUtcDateStr = (
    utcDate: string,
    utcTime: string
) => {
    if (typeof utcDate !== "string") {
        console.error("Date in bad format");
        return "undefined";
    }

    const utcTimePlusOffSetMiliseconds =
        getDayTimeFromTimeString(utcTime) + getNormalizeOffsetInMiliseconds();

    if (utcTimePlusOffSetMiliseconds < FULL_DAY_IN_MILLISECONDS) {
        return utcDate;
    }

    //Changing date
    const utcDateInMilSeconds = new Date(utcDate);

    const localDateInMilSeconds = new Date(
        utcDateInMilSeconds.getTime() + FULL_DAY_IN_MILLISECONDS
    ).getTime();

    const localDateStr = milisecondsToDateString(localDateInMilSeconds);
    return localDateStr;
};

export const getLocalDateStrFromUtcDateStrToInboxTooltip = (
    utcDate: string,
    utcTime: string
) => {
    if (typeof utcDate !== "string") {
        console.error("Date in bad format");
        return "undefined";
    }

    const utcTimePlusOffSetMiliseconds =
        getDayTimeFromTimeString(utcTime) + getNormalizeOffsetInMiliseconds();

    const utcDateInMilSeconds = new Date(utcDate);

    if (utcTimePlusOffSetMiliseconds < FULL_DAY_IN_MILLISECONDS) {
        return millisecondsToDateStringDisplay(utcDateInMilSeconds.getTime());
    }

    const localDateInMilSeconds = new Date(
        utcDateInMilSeconds.getTime() + FULL_DAY_IN_MILLISECONDS
    ).getTime();

    const localDateStr = millisecondsToDateStringDisplay(localDateInMilSeconds);
    return localDateStr;
};

export const getLocalDayTimeFromUtcTimeString = (
    dayUtcTime: string,
    date: string
) => {
    const utcDayTimeInMilSeconds = getDayTimeFromTimeString(dayUtcTime);

    const localDayTimeInMilSeconds = getLocalDayTimeInMilsWithDateString(
        utcDayTimeInMilSeconds,
        date
    );

    return milisecondsToTimeString(localDayTimeInMilSeconds);
};

export const getDayTimeFromTimeString = (dayTime: string) => {
    const stringHours = dayTime.split(":")[0];
    const stringMinutes = dayTime.split(":")[1];

    const hours = Number(stringHours) * 60 * 60 * 1000;
    const minutes = Number(stringMinutes) * 60 * 1000;
    return hours + minutes;
};

export const getLocalDayTimeInMils = (dayUtcTime: number) => {
    return dayUtcTime + getNormalizeOffsetInMiliseconds();
};

export const getLocalDayTimeInMilsWithDateString = (
    dayUtcTime: number,
    date: string
) => {
    return dayUtcTime + getNormalizeOffsetInMilisecondsFromDateString(date);
};

export const getLocalDateFromUtcDate = (utcDateMidnight: number) => {
    return utcDateMidnight + getNormalizeOffsetInMiliseconds();
};

export const milisecondsToTimeString = (time: number): string => {
    return dayjs.duration(time).format("HH:mm");
};

export const milisecondsToDateString = (date: number): string => {
    return dayjs(date).format("YYYY-MM-DD");
};

export const milisecondsToDateStringResults = (date: number): string => {
    return dayjs(date).format("DD/MM/YYYY");
};

export const millisecondsToDateStringDisplay = (date: number): string => {
    return dayjs(date).format("MMM DD, YYYY");
};

export const calculateTotalTimeInMilliseconds = (
    eventDate: number,
    eventTime: number
) => {
    return eventDate + eventTime;
};

//TODO: move to the generic method => getUtcDateFromLocalDateAndTime
export const dateStrAndTimeStrToDateObject = (date: string, time: string) => {
    const hours = Number(time.split(":")[0]);
    const minutes = Number(time.split(":")[1]);

    const dateObject = new Date(date);
    dateObject.setHours(hours);
    dateObject.setMinutes(minutes);

    const dateUtcStr = `${dateObject.getUTCFullYear()}-${
        dateObject.getUTCMonth() + 1
    }-${dateObject.getUTCDate()}`;

    const dateUtcStrAfterFormat = dayjs(dateUtcStr).format("YYYY-MM-DD");

    const dayUtcTimeStr = `${dateObject.getUTCHours()}:${dateObject.getUTCMinutes()}`;

    return { dateUtcStrAfterFormat, dayUtcTimeStr };
};

export const totalTimeInMiliSecondsFromString = (
    dateUtc: string,
    dayTimeUtc: string
) => {
    const dateUtcInMiliSeconds = getDateAtMidnightFromDateString(dateUtc);
    const dayTimeUtcInMiliseconds = getDayTimeFromTimeString(dayTimeUtc);

    return calculateTotalTimeInMilliseconds(
        dateUtcInMiliSeconds,
        dayTimeUtcInMiliseconds
    );
};

export const getUtcDateAtMidnightInMilsWithAddingDays = (days: number) => {
    const currentDate = new Date();

    currentDate.setDate(currentDate.getDate() + days);

    const utc_date_at_midnight = new Date(
        currentDate.getFullYear(),
        currentDate.getUTCMonth(),
        currentDate.getUTCDate()
    );
    return utc_date_at_midnight.getTime();
};

export const addDaysToDateInMils = (days: number) => {
    const currentDate = new Date();

    currentDate.setDate(currentDate.getDate() + days);

    return currentDate.getTime();
};

export const timeLeftCheck = (endTime: number) => {
    const currentTime = Date.now();
    const twoHoursInMiliseconds =
        TWO_HOURS_IN_MINUTES * MINUTES_TO_MILISECONDS_FACTOR;

    return currentTime > endTime - twoHoursInMiliseconds;
};

export const getUtcDateAtMidnightInMilsWithDecreasingMonths = (
    months: number
) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - months);
    const utc_date_at_midnight = new Date(
        currentDate.getFullYear(),
        currentDate.getUTCMonth(),
        currentDate.getUTCDate()
    );
    return utc_date_at_midnight.getTime();
};

export const getAlertOffsetInMilliSeconds = (
    alert: SurveyEventAlert
): number => {
    const offsetCoefficient = alert.beforeAfter === "BEFORE" ? -1 : 1;
    const timeUnitsSeconds = getTimeUnitSeconds(alert.time_units);
    return 1000 * timeUnitsSeconds * offsetCoefficient * alert.time_offset;
};

const getTimeUnitSeconds = (timeUnits: TimeUnits): number => {
    switch (timeUnits) {
        case "MINUTES":
            return 60;
        case "HOURS":
            return 60 * 60;
        case "DAYS":
            return 60 * 60 * 24;
    }
};
