import React from "react";
import { observer } from "mobx-react";

import {
    categoriesStore,
    categoriesDisplayStore,
    timeRangesStore,
    resultsDisplayFilterStore,
} from "@difftone/stores";
import {
    applyLocalStateChangesToStore,
    getFilterValuesByQuestionType,
    selectAllFilterKeysToActive,
    setCategoryFilterBy,
    showDifftoneAlert,
    updateDisplayFilters,
    updateSingleResultsCompareBy,
} from "@difftone/actions";
import { ResultsFilter } from "@difftone/types";
import {
    getFilterQuestionsFromSurvey,
    getSurveyFromStoreByURL,
    getTimeUnitFromUrlOrNone,
} from "@difftone/reducers";
import { Spinner } from "@difftone/shared-components";

import { FilterOption, FilterGenerator } from "./components";

import {
    filter_picker,
    filter_picker_wrapper,
    no_filters_to_generate,
    filter_picker_spinner,
} from "./results-filter-picker.module.css";

export const ResultsFilterPicker = observer(() => {
    const { categoryFilterBy } = categoriesDisplayStore;
    const { displayFilters } = resultsDisplayFilterStore;
    const { extractKeyForTimeRange, getTimeRangeByKey } = timeRangesStore;

    const selectedSurvey = getSurveyFromStoreByURL();

    if (selectedSurvey === undefined) {
        return <Spinner className={filter_picker_spinner} size="SMALL" />;
    }

    if (selectedSurvey === null) {
        return null;
    }

    const timeRangeKey = extractKeyForTimeRange(
        selectedSurvey.uuid,
        getTimeUnitFromUrlOrNone()
    );

    const timeRanges = getTimeRangeByKey(timeRangeKey);

    if (timeRanges === undefined) {
        return <Spinner className={filter_picker_spinner} size="SMALL" />;
    }

    if (timeRanges === null) {
        showDifftoneAlert(
            "Something went wrong, please reload and try again",
            "FAILURE"
        );
        return null;
    }

    if (!categoriesStore.isStoreInitialized) {
        return <Spinner className={filter_picker_spinner} size="SMALL" />;
    }

    if (!categoryFilterBy) {
        setCategoryFilterBy(selectedSurvey);
        return <Spinner className={filter_picker_spinner} size="SMALL" />;
    }

    const addFilter = (newSelectedFilter: ResultsFilter) => {
        const copyOfDisplayFilters = JSON.parse(JSON.stringify(displayFilters));

        const copyNewSelectedFilter: ResultsFilter = JSON.parse(
            JSON.stringify(newSelectedFilter)
        );

        const doesNewElementAlreadyExists = displayFilters.find(
            (existingFilter) =>
                existingFilter.filterKey.uuid ===
                newSelectedFilter.filterKey.uuid
        );

        if (!doesNewElementAlreadyExists) {
            copyNewSelectedFilter.filterValues = getFilterValuesByQuestionType(
                newSelectedFilter.filterKey
            );

            copyOfDisplayFilters.push(copyNewSelectedFilter);
            updateDisplayFilters(copyOfDisplayFilters);
        }
    };

    const removeFilter = (filterToRemove: ResultsFilter | null) => {
        if (filterToRemove) {
            selectAllFilterKeysToActive(filterToRemove.filterKey.uuid);
            const currentFilter = applyLocalStateChangesToStore(
                filterToRemove.filterKey.uuid
            );

            const copyOfDisplayFilters: ResultsFilter[] = JSON.parse(
                JSON.stringify(displayFilters)
            );

            const updatedFiltersArray = copyOfDisplayFilters.filter(
                (_filter) =>
                    _filter.filterKey.uuid !== currentFilter.filterKey.uuid
            );
            updateDisplayFilters(updatedFiltersArray);
            updateSingleResultsCompareBy(currentFilter);
        }
    };

    const filterQuestions = getFilterQuestionsFromSurvey(selectedSurvey);

    return (
        <div className={filter_picker_wrapper}>
            <div data-filter-picker className={filter_picker}>
                {filterQuestions.length > 0 ? (
                    <FilterGenerator addFilter={addFilter} />
                ) : (
                    <span className={no_filters_to_generate}>None</span>
                )}
                {displayFilters.map((displayFilter, index) => {
                    return (
                        <FilterOption
                            displayFilters={displayFilters}
                            removeFilter={removeFilter}
                            question={displayFilter.filterKey}
                            index={index}
                            key={displayFilter.filterKey.uuid}
                        />
                    );
                })}
            </div>
        </div>
    );
});
