import React from "react";
import clsx from "clsx";
import { ShowDifftoneTooltip } from "@difftone/shared-components";
import { Question, QuestionType, RatingQuestion } from "@difftone/types";
import tooltipIcon from "@difftone/assets/tooltip-icon-outlined.svg";
import {
    results_average,
    results_average_stub,
    results_average_wrap,
    results_average_subtext,
    results_average_text,
    results_average_tooltip,
} from "./results-average.module.css";
import { getRatingRange } from "@difftone/reducers";
const DIFFERENT_QUESTION_TYPES_TOOLTIP =
    "This score applies to rating type questions only";
const DIFFERENT_SCALES_TOOLTIP =
    "This score is a weighted average due to different rating scales";

const isRatingScaleDifferentAmongQuestions = (
    ratingQuestions: Question<RatingQuestion>[]
) => {
    const allScales = ratingQuestions.map(
        ({ content }) => content.rating_options.length
    );
    const uniqueScales = Array.from(new Set(allScales));
    return uniqueScales.length > 1;
};
export type ResultsAverageProps = {
    questions: Question<QuestionType>[];
    className?: string;
};
export const ResultsAverage = (props: ResultsAverageProps) => {
    const { questions, className } = props;
    const ratingQuestions = questions.filter(
        ({ question_type_name }) => question_type_name === "RATING"
    ) as Question<RatingQuestion>[];
    let tooltipMessage = "";

    const ratingRange = ratingQuestions.length
        ? `[${getRatingRange(ratingQuestions)}]`
        : null;
    // if rating questions length is not the same as questions length
    // meaning we should show tooltip for different question types
    if (ratingRange && ratingQuestions.length !== questions.length) {
        tooltipMessage += DIFFERENT_QUESTION_TYPES_TOOLTIP;
    }
    if (isRatingScaleDifferentAmongQuestions(ratingQuestions)) {
        if (tooltipMessage) {
            tooltipMessage += `. ${DIFFERENT_SCALES_TOOLTIP}`;
        } else {
            tooltipMessage += DIFFERENT_SCALES_TOOLTIP;
        }
    }
    return ratingRange ? (
        <div className={clsx(results_average, className)}>
            <div className={results_average_wrap}>
                <span className={results_average_text}>Avg</span>
                {tooltipMessage && (
                    <ShowDifftoneTooltip
                        tip={tooltipMessage}
                        tooltipPosition="top"
                    >
                        <img
                            className={results_average_tooltip}
                            alt=""
                            src={tooltipIcon}
                        />
                    </ShowDifftoneTooltip>
                )}
            </div>
            <span className={results_average_subtext}>{ratingRange}</span>
        </div>
    ) : (
        <div className={results_average_stub} />
    );
};
