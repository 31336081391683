import React, { ChangeEvent, useState } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import { messageChange, messageClear } from "@difftone/actions";
import { inboxDisplayStore } from "@difftone/stores";

import messageIcon from "@difftone/assets/message-icon-black.svg";
import trashIcon from "@difftone/assets/trash-icon-black.svg";
import {
    popup_message,
    message_container,
    message_toggle_bar,
    highlight,
    message_bold,
    message_icon_scope,
    message_optional,
    message_title_scope,
    delete_line,
} from "./popup-message.module.css";
type PopupMessageProps = {};
export const PopupMessage = observer((props: PopupMessageProps) => {
    const { messageSurvey } = inboxDisplayStore;

    //use state only used for internal component state
    const [messageInputOpen, setMessageInputOpen] = useState(false);

    const handleMessageClear = () => {
        messageClear();
    };

    const onSurveyMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const value: string = event.target.value;
        messageChange(value);
    };

    return (
        <div className={popup_message}>
            <div className={message_container}>
                <div
                    className={message_toggle_bar}
                    onClick={() => setMessageInputOpen(!messageInputOpen)}
                >
                    <div className={message_icon_scope}>
                        <img src={messageIcon} />

                        <div
                            className={clsx(
                                message_title_scope,
                                messageInputOpen && delete_line
                            )}
                        >
                            <span
                                className={clsx(
                                    highlight,
                                    messageInputOpen && message_bold
                                )}
                            >
                                Include message
                            </span>

                            <span
                                className={clsx(
                                    highlight,
                                    messageInputOpen && message_optional
                                )}
                            >
                                (optional)
                            </span>
                        </div>
                    </div>

                    {messageInputOpen ? (
                        <img onClick={handleMessageClear} src={trashIcon} />
                    ) : null}
                </div>
                {messageInputOpen ? (
                    <textarea
                        onChange={onSurveyMessageChange}
                        placeholder="Message to be sent with survey"
                        value={messageSurvey}
                    />
                ) : null}
            </div>
        </div>
    );
});
