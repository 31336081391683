import { BaseSurvey, OngoingSurvey, SimpleSurvey } from "@difftone/types";
import {
    activateSurveyImmediately,
    closeScheduleSurveyPopup,
    debounceUpsertSurveyToApi,
    finalizeOngoingSurvey,
    scheduleSurvey,
    setLocation,
    showDifftoneAlert,
    showDifftoneAlertWithSharePanel,
} from "@difftone/actions";
import {
    constantAlertVariants,
    INBOX_URL,
    PAGE_URLS,
} from "@difftone/constants";
import { sendEvent } from "@difftone/procedures";

export const onDraft = () => {
    closeScheduleSurveyPopup();
    setLocation(PAGE_URLS.INBOX_DRAFT);
    showDifftoneAlert(
        constantAlertVariants.draft_saved.text,
        constantAlertVariants.draft_saved.variant
    );
};

export const onSendSimpleSurvey = (survey: SimpleSurvey) => {
    const updatedSurvey = debounceUpsertSurveyToApi("status", "READY");
    activateSurveyImmediately(updatedSurvey);
    closeScheduleSurveyPopup();
    setLocation(PAGE_URLS.INBOX_INITIATOR);
    showDifftoneAlertWithSharePanel(
        constantAlertVariants.survey_sent.text,
        constantAlertVariants.survey_sent.variant,
        survey.uuid,
        "Additionally you can send the survey via",
        survey.is_open_survey
    );
};

export const onSchedule = (survey: BaseSurvey) => {
    const updatedSurvey = debounceUpsertSurveyToApi("status", "READY");
    scheduleSurvey(updatedSurvey);
    closeScheduleSurveyPopup();
    setLocation(PAGE_URLS.INBOX_INITIATOR);
    showDifftoneAlert(
        constantAlertVariants.survey_scheduled.text,
        constantAlertVariants.survey_scheduled.variant
    );

    sendEvent("SCHEDULE_SURVEY_BUTTON_CLICK", survey.uuid);
    return;
};

export const activateOngoingSurvey = () => {
    const updatedSurvey: OngoingSurvey = debounceUpsertSurveyToApi(
        "status",
        "READY"
    );

    finalizeOngoingSurvey(updatedSurvey);
    debounceUpsertSurveyToApi("status", "ACTIVE");
    closeScheduleSurveyPopup();
    setLocation(PAGE_URLS.INBOX_ONGOING);
    showDifftoneAlert(
        constantAlertVariants.ongoing_survey_send.text,
        constantAlertVariants.ongoing_survey_send.variant
    );
};

export const onReadyOngoing = (survey: OngoingSurvey) => {
    const updatedSurvey = debounceUpsertSurveyToApi("status", "READY");

    finalizeOngoingSurvey(updatedSurvey);
    closeScheduleSurveyPopup();
    setLocation(PAGE_URLS.INBOX_INITIATOR);
    showDifftoneAlert(
        constantAlertVariants.ongoing_survey_ready.text,
        constantAlertVariants.ongoing_survey_ready.variant
    );

    sendEvent("SCHEDULE_SURVEY_BUTTON_CLICK", survey.uuid);
    return;
};
