import { v4 as uuid } from "uuid";

import { surveyResponseStore } from "@difftone/stores";
import {
    Answer,
    AnswerType,
    Question,
    QuestionType,
    UUID,
} from "@difftone/types";
import { SURVEY_QUESTION_ANSWER } from "@difftone/constants";

export const getAnswerByQuestion = (
    question: Question<QuestionType>,
    surveyResponseUUID: UUID
): Answer<AnswerType> => {
    const nonFilterQuestionAnswer = surveyResponseStore
        .getSurveyResponseByUuid(surveyResponseUUID)
        ?.answers.find((answer) => answer.question_uuid === question.uuid);

    if (nonFilterQuestionAnswer) return nonFilterQuestionAnswer;

    const filterQuestionAnswer = surveyResponseStore
        .getSurveyResponseByUuid(surveyResponseUUID)
        ?.attribute_answers.find(
            (answer) => answer.question_uuid === question.uuid
        );

    if (filterQuestionAnswer) return filterQuestionAnswer;

    switch (question.question_type_name) {
        case SURVEY_QUESTION_ANSWER.DATE:
            return {
                uuid: uuid(),
                question_uuid: question.uuid,
                answer_type_name: SURVEY_QUESTION_ANSWER.DATE,
                content: {
                    date: 0,
                },
            };
        case SURVEY_QUESTION_ANSWER.SINGLE_CHOICE:
            return {
                uuid: uuid(),
                question_uuid: question.uuid,
                answer_type_name: SURVEY_QUESTION_ANSWER.SINGLE_CHOICE,
                content: {
                    choice: "",
                },
            };
        case SURVEY_QUESTION_ANSWER.MULTIPLE_CHOICE:
            return {
                uuid: uuid(),
                question_uuid: question.uuid,
                answer_type_name: SURVEY_QUESTION_ANSWER.MULTIPLE_CHOICE,
                content: {
                    choices: {},
                },
            };
        case SURVEY_QUESTION_ANSWER.RATING:
            return {
                uuid: uuid(),
                question_uuid: question.uuid,
                answer_type_name: SURVEY_QUESTION_ANSWER.RATING,
                content: { value: 1, label: "" },
            };
        case SURVEY_QUESTION_ANSWER.TIME:
            return {
                uuid: uuid(),
                question_uuid: question.uuid,
                answer_type_name: SURVEY_QUESTION_ANSWER.TIME,
                content: {
                    time: 0,
                },
            };
        case SURVEY_QUESTION_ANSWER.SHORT_TEXT:
            return {
                uuid: uuid(),
                question_uuid: question.uuid,
                answer_type_name: SURVEY_QUESTION_ANSWER.SHORT_TEXT,
                content: {
                    text: "",
                },
            };
        case SURVEY_QUESTION_ANSWER.LONG_TEXT:
            return {
                uuid: uuid(),
                question_uuid: question.uuid,
                answer_type_name: SURVEY_QUESTION_ANSWER.LONG_TEXT,
                content: {
                    text: "",
                },
            };
        default:
            throw new Error("Not Implemented");
    }
};
