import React, {
    ChangeEvent,
    KeyboardEventHandler,
    useState,
    MouseEvent,
    ClipboardEvent,
    Ref,
    useEffect,
    CSSProperties,
    forwardRef,
    RefObject,
} from "react";
import clsx from "clsx";
import { observer } from "mobx-react";

import { EmailAddress, AuthData } from "@difftone/types";
import { Spinner, ValidationWrapper } from "@difftone/shared-components";
import { validateEmailAddresses } from "@difftone/validations";
import { nullifyWizardValidationError } from "@difftone/actions";
import { connectionListStore, groupEmailsStore } from "@difftone/stores";
import {
    warningMessages,
    alertsMailingListGroupTitles,
    alertsMailingListGroupSendOutTitles,
} from "@difftone/constants";
import { getUserEmail } from "@difftone/procedures";
import {
    getSelectedOrganizationOrThrow,
    isAlertEmailGroup,
} from "@difftone/reducers";

import { MailingListWrapper, MailingListAutocomplete } from "./components";
import { isEmailUnique } from "./utils";

import {
    mailing_list_producer,
    producer,
    mailing_list_information_page,
    invalid_producer_value,
    spinner_shown,
    spinner_hidden,
    container,
    input_text_disabled,
} from "./mailing-list-producer.module.css";

const USER_HUMANIZED_EMAIL_VARIANTS = ["Me", "ME", "me"];

const MIN_NUMBER_OF_EMAILS_IN_INPUT = 1;
const MAILING_AUTOCOMPLETE_LIST_MAX_SHOW_COUNT = 10;

export type MailingListProducerProps = {
    onEnter: (emails: EmailAddress[]) => void;
    onDelete: (email: EmailAddress) => void;
    mailingList: EmailAddress[];
    //TODO: replace with style overrides (not this prop is drilled down to ValidationWrapper)
    mailingListLocation?: "information" | "share" | "reminder" | undefined;
    inEditMode?: boolean | undefined;
    disabled: boolean;
    readOnlyMalingList: boolean;
    nonDeletebleMailingList?: EmailAddress[];
    showGroups?: boolean;
    activeMailingListType?: string;
    firstClickOnMailingType?: boolean;
    sendOutAlert?: boolean;
    hideMeEmailOption?: boolean;
    inWizardDetails?: boolean;
    styleOptions?: {
        overrideClass?: string;
        validationTooltipCustomStyle?: CSSProperties;
        mailingWrapperClassNameOverride?: string;
        customWidth?: number | string;
        listBoxHeight?: number;
        inputStyleOverride?: CSSProperties;
    };
};

export const MailingListProducer = observer(
    //forward ref used for focusing input from parent
    forwardRef(
        (props: MailingListProducerProps, ref: Ref<HTMLInputElement>) => {
            const {
                onEnter,
                onDelete,
                activeMailingListType,
                firstClickOnMailingType,
                mailingList,
                mailingListLocation,
                inEditMode,
                nonDeletebleMailingList,
                disabled,
                readOnlyMalingList,
                showGroups,
                styleOptions,
                sendOutAlert,
                hideMeEmailOption,
                inWizardDetails,
            } = props;

            const { getConnectionList } = connectionListStore;

            const [currentTypedInput, _setCurrentTypedInput] = useState("");
            const setCurrentTypedInput = (value: string) => {
                _setCurrentTypedInput(value.toLocaleLowerCase());
            };

            const [autoCompleteListIndex, setAutoCompleteListIndex] =
                useState(0);

            const [isCurrentInputValid, setIsCurrentInputValid] =
                useState(true);

            const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState(false);

            const [showSpinner, setShowSpinner] = useState(false);

            const [emailsBeingResolved, setEmailsBeingResolved] = useState<
                EmailAddress[]
            >([]);

            const removeTypedEmailsFromAutocompleteList = (
                emails: EmailAddress[]
            ): EmailAddress[] =>
                [...emails].filter((email) => {
                    if (email === "Me") {
                        return !mailingList.includes(getUserEmail());
                    }

                    return !mailingList.includes(email);
                });

            const convertTextToLowerCase = (value: string | string[]) => {
                if (Array.isArray(value))
                    return value.map((v: string) =>
                        !isAlertEmailGroup(v) ? v.toLocaleLowerCase() : v
                    );
                return !isAlertEmailGroup(value)
                    ? value.toLocaleLowerCase()
                    : value;
            };

            const onValidEmailsSelected = (emailAddresses: EmailAddress[]) => {
                setEmailsBeingResolved(
                    Array.from(
                        new Set([...emailsBeingResolved, ...emailAddresses])
                    )
                );
                onEnter(convertTextToLowerCase(emailAddresses) as string[]);
                setCurrentTypedInput("");
                setIsCurrentInputValid(true);
                setIsAutoCompleteOpen(false);
                nullifyWizardValidationError();
                setAutoCompleteListIndex(0);
            };

            const onInvalidEmailsSelected = (currentValue?: string) => {
                if (currentValue) setCurrentTypedInput(currentValue);
                setIsCurrentInputValid(false);
                resetAutocomplete();
            };

            const resetAutocomplete = () => {
                setIsAutoCompleteOpen(false);
                setAutoCompleteListIndex(0);
            };

            const onKeyDownHandler: KeyboardEventHandler<HTMLInputElement> = (
                event
            ) => {
                if (isAutoCompleteOpen) {
                    if (event.key === "Escape") {
                        setIsAutoCompleteOpen(false);
                    }

                    if (event.key === "Enter" && !isClickedEmailUnique) {
                        onInvalidEmailsSelected();
                    }

                    if (event.key === "Enter" && isClickedEmailUnique) {
                        if (
                            USER_HUMANIZED_EMAIL_VARIANTS.includes(
                                filteredConnectionsList[autoCompleteListIndex]
                            )
                        ) {
                            const usersEmail = getUserEmail();
                            if (mailingList.includes(usersEmail)) {
                                onInvalidEmailsSelected(usersEmail);
                            } else {
                                onValidEmailsSelected([usersEmail]);
                            }
                            return;
                        } else {
                            onValidEmailsSelected([
                                filteredConnectionsList[autoCompleteListIndex],
                            ]);
                            return;
                        }
                    }
                    if (event.key === "ArrowDown") {
                        if (
                            filteredConnectionsList.length - 1 !==
                            autoCompleteListIndex
                        ) {
                            setAutoCompleteListIndex(autoCompleteListIndex + 1);
                        }
                    }
                    if (event.key === "ArrowUp") {
                        if (autoCompleteListIndex - 1 < 0) {
                            setAutoCompleteListIndex(
                                filteredConnectionsList.length - 1
                            );
                        } else {
                            setAutoCompleteListIndex(autoCompleteListIndex - 1);
                        }
                    }
                    return;
                }

                if (event.key === "Enter") {
                    const value = event.currentTarget.value;

                    const emailFormatMatches: EmailAddress[] =
                        value.match(
                            /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
                        ) || [];

                    const unifiedEmails = new Set([
                        ...emailFormatMatches,
                        ...mailingList,
                    ]);

                    if (
                        emailFormatMatches.length >
                        MIN_NUMBER_OF_EMAILS_IN_INPUT
                    ) {
                        event.preventDefault();

                        const resEmails: EmailAddress[] = [];
                        const nonValidateEmails: EmailAddress[] = [];

                        unifiedEmails.forEach((email) => {
                            const uniquenessStatus = validateEmailAddresses([
                                email,
                                ...mailingList,
                            ])
                                .filter(
                                    (validationResult) =>
                                        validationResult.validation_error_code ===
                                        "EMAIL_ADDRESS_DUPLICATION_ERROR"
                                )
                                .every(
                                    (duplicationResult) =>
                                        duplicationResult.is_valid
                                );

                            if (
                                validateEmailAddresses([email]).every(
                                    (validationResults) =>
                                        validationResults.is_valid
                                ) &&
                                uniquenessStatus
                            ) {
                                resEmails.push(email);
                            } else {
                                if (emailFormatMatches.includes(email)) {
                                    nonValidateEmails.push(email);
                                }
                            }
                        });

                        if (resEmails.length) {
                            onValidEmailsSelected(resEmails);
                        }

                        if (nonValidateEmails.length) {
                            onInvalidEmailsSelected(
                                nonValidateEmails.join(",")
                            );
                        }
                        return;
                    }

                    if (USER_HUMANIZED_EMAIL_VARIANTS.includes(value)) {
                        const usersEmail = getUserEmail();
                        if (mailingList.includes(usersEmail)) {
                            onInvalidEmailsSelected(usersEmail);
                        } else {
                            onValidEmailsSelected([usersEmail]);
                        }
                        return;
                    }

                    if (value && isCurrentTypedInputUnique) {
                        setIsCurrentInputValid(
                            validateEmailAddresses([currentTypedInput]).every(
                                (validationRTesult) =>
                                    validationRTesult.is_valid
                            )
                        );
                        if (!isCurrentInputValid) {
                            setCurrentTypedInput(value);
                        } else {
                            if (
                                validateEmailAddresses([
                                    currentTypedInput,
                                ]).every(
                                    (validationRTesult) =>
                                        validationRTesult.is_valid
                                )
                            ) {
                                onValidEmailsSelected([value]);
                            } else {
                                resetAutocomplete();
                            }
                        }
                    } else {
                        setIsCurrentInputValid(false);
                    }
                }
            };

            const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
                if (event.target.value === "") {
                    resetAutocomplete();
                } else {
                    setIsAutoCompleteOpen(true);
                }
                nullifyWizardValidationError();
                setIsCurrentInputValid(true);
                setCurrentTypedInput(event.target.value);
            };

            const onFocusHandler = () => {
                setShowSpinner(true);
                if (!connectionListStore.startInitialization) {
                    connectionListStore.init();
                }

                if (!currentTypedInput) {
                    setIsCurrentInputValid(true);
                }
            };

            const onBlurHandler = () => {
                resetAutocomplete();
            };

            const onAutocompleteEmailClicked = (
                event: MouseEvent<HTMLDivElement>
            ) => {
                const clickedEmail: string | null =
                    event.currentTarget.getAttribute("data-autocomplete-mail");

                if (!clickedEmail) {
                    return;
                }

                if (
                    USER_HUMANIZED_EMAIL_VARIANTS.includes(
                        filteredConnectionsList[autoCompleteListIndex]
                    )
                ) {
                    const usersEmail = getUserEmail();
                    if (mailingList.includes(usersEmail)) {
                        onInvalidEmailsSelected(usersEmail);
                    } else {
                        onValidEmailsSelected([usersEmail]);
                    }
                    return;
                }

                const isClickedEmailUnique = isEmailUnique(
                    clickedEmail,
                    mailingList
                );

                if (isClickedEmailUnique) {
                    onValidEmailsSelected([clickedEmail]);
                } else {
                    onInvalidEmailsSelected(clickedEmail);
                }
            };

            const onAutocompleteEmailHovered = (
                event: MouseEvent<HTMLDivElement>
            ) => {
                const hoveredEmailIndex =
                    event.currentTarget.getAttribute("data-index");
                if (!hoveredEmailIndex) {
                    return;
                }
                setAutoCompleteListIndex(parseInt(hoveredEmailIndex));
            };

            //TODO: case for when autocomplete has no values, need to update component state
            const onAutoCompleteListEmpty = () => {
                resetAutocomplete();
            };

            const handleOnPaste = (event: ClipboardEvent<HTMLInputElement>) => {
                event.preventDefault();
                const clipboardPastedText = event.clipboardData
                    .getData("text")
                    .toLocaleLowerCase();
                const emailFormatMatches: EmailAddress[] =
                    clipboardPastedText.match(
                        /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
                    ) || [];

                const unifiedEmails = new Set([
                    ...emailFormatMatches,
                    ...mailingList,
                ]);

                const res: EmailAddress[] = [];
                const nonValidateEmails: EmailAddress[] = [];

                unifiedEmails.forEach((email) => {
                    const uniquenessStatus = validateEmailAddresses([
                        email,
                        ...mailingList,
                    ])
                        .filter(
                            (validationResult) =>
                                validationResult.validation_error_code ===
                                "EMAIL_ADDRESS_DUPLICATION_ERROR"
                        )
                        .every(
                            (duplicationResult) => duplicationResult.is_valid
                        );

                    if (
                        validateEmailAddresses([email]).every(
                            (validationResults) => validationResults.is_valid
                        ) &&
                        uniquenessStatus
                    ) {
                        res.push(email);
                    } else {
                        if (emailFormatMatches.includes(email)) {
                            nonValidateEmails.push(email);
                        }
                    }
                });

                if (res.length) {
                    onValidEmailsSelected(res);
                }

                if (nonValidateEmails.length) {
                    onInvalidEmailsSelected(nonValidateEmails.join(","));
                }
            };

            if (connectionListStore.storeInitialize && showSpinner) {
                setShowSpinner(false);
            }

            let isMailingListGroupSelected = false;
            mailingList.forEach((email) => {
                if (Object.keys(alertsMailingListGroupTitles).includes(email)) {
                    isMailingListGroupSelected = true;
                }
            });

            const userHumanizedEmail = hideMeEmailOption
                ? []
                : [USER_HUMANIZED_EMAIL_VARIANTS[0]];

            const participantsEmails: EmailAddress[] = [
                ...userHumanizedEmail,
                ...(getConnectionList() as string[]),
            ].filter(
                (email) =>
                    email
                        .toLowerCase()
                        .startsWith(currentTypedInput.toLowerCase()) ||
                    (email === userHumanizedEmail[0] &&
                        getUserEmail().includes(currentTypedInput))
            );

            const _nonDeletebleMailingList = nonDeletebleMailingList || [];

            const filteredConnectionsList =
                removeTypedEmailsFromAutocompleteList(
                    showGroups && !isMailingListGroupSelected
                        ? [
                              ...Object.keys(
                                  sendOutAlert
                                      ? alertsMailingListGroupSendOutTitles
                                      : alertsMailingListGroupTitles
                              ),
                              ...participantsEmails,
                          ]
                        : [...participantsEmails].splice(
                              0,
                              MAILING_AUTOCOMPLETE_LIST_MAX_SHOW_COUNT
                          )
                );

            const isCurrentTypedInputUnique = validateEmailAddresses([
                currentTypedInput,
                ...mailingList,
            ])
                .filter(
                    (validationResult) =>
                        validationResult.validation_error_code ===
                        "EMAIL_ADDRESS_DUPLICATION_ERROR"
                )
                .every((duplicationResult) => duplicationResult.is_valid);

            const focusedEmailInAutoComplete =
                filteredConnectionsList[autoCompleteListIndex];
            const isClickedEmailUnique = isEmailUnique(
                focusedEmailInAutoComplete,
                mailingList
            );

            //in order to focus the mailing input when clicking the mailing list type (Participants/Admins)
            useEffect(() => {
                if (
                    firstClickOnMailingType &&
                    activeMailingListType &&
                    ref &&
                    (ref as RefObject<HTMLInputElement>).current
                ) {
                    (ref as RefObject<HTMLInputElement>).current?.focus();
                }
            }, [activeMailingListType, firstClickOnMailingType, ref]);

            const getAutocompleteItemsSeparatorIndex = () => {
                let initialSeparatorIndex = sendOutAlert
                    ? Object.keys(alertsMailingListGroupSendOutTitles).length -
                      1
                    : Object.keys(alertsMailingListGroupTitles).length - 1;

                if (filteredConnectionsList.includes(userHumanizedEmail[0])) {
                    initialSeparatorIndex += 1;
                }

                return initialSeparatorIndex;
            };

            let emailsToAddToBeingResolved: string[] = [];

            const emailsToRemove = emailsBeingResolved.reduce(
                (acc: string[], emailBeingResolved) => {
                    const resolvedResults = groupEmailsStore.getResolvedEmails(
                        emailBeingResolved,
                        getSelectedOrganizationOrThrow()!
                    );
                    if (resolvedResults) {
                        emailsToAddToBeingResolved = [...resolvedResults];
                        const newEmails = resolvedResults.filter(
                            (email) => !mailingList.includes(email)
                        );
                        if (newEmails.length) {
                            onEnter(
                                convertTextToLowerCase(newEmails) as string[]
                            );
                        }
                        acc.push(emailBeingResolved);
                    }
                    return acc;
                },
                []
            );

            if (emailsToRemove.length) {
                const newValue = Array.from([
                    ...emailsBeingResolved,
                    ...emailsToAddToBeingResolved,
                ]).filter((email) => !emailsToRemove.includes(email));
                setEmailsBeingResolved(newValue);
            }

            return (
                <div className={container}>
                    <div
                        className={clsx(
                            showSpinner ? spinner_shown : spinner_hidden
                        )}
                    >
                        <Spinner />
                    </div>

                    <div
                        className={clsx(styleOptions?.overrideClass, {
                            [mailing_list_producer]:
                                mailingListLocation === "information",
                            [mailing_list_information_page]:
                                mailingListLocation === "information",
                            [mailing_list_producer]:
                                mailingListLocation !== "information",
                        })}
                    >
                        <ValidationWrapper
                            inEditMode={inEditMode}
                            mailingListLocation={mailingListLocation}
                            errorMessage={
                                isCurrentTypedInputUnique
                                    ? warningMessages.emailInvalidError
                                    : warningMessages.emailNotUniqueError
                            }
                            isValid={isCurrentInputValid}
                            tooltipPositionOverride={
                                styleOptions?.validationTooltipCustomStyle
                            }
                        >
                            <input
                                ref={ref}
                                style={
                                    styleOptions?.customWidth
                                        ? { width: styleOptions.customWidth }
                                        : {}
                                }
                                onPaste={handleOnPaste}
                                disabled={disabled}
                                value={currentTypedInput}
                                onFocus={onFocusHandler}
                                onBlur={onBlurHandler}
                                onChange={onInputChange}
                                onKeyDown={onKeyDownHandler}
                                placeholder={disabled ? "" : "Type an email"}
                                className={clsx(
                                    !isCurrentInputValid &&
                                        invalid_producer_value,
                                    styleOptions?.inputStyleOverride,
                                    producer,
                                    disabled && input_text_disabled
                                )}
                                type="text"
                            />
                        </ValidationWrapper>
                        {isAutoCompleteOpen ? (
                            <MailingListAutocomplete
                                inWizardDetails={inWizardDetails}
                                mailingList={filteredConnectionsList}
                                onMouseClick={onAutocompleteEmailClicked}
                                onMouseOver={onAutocompleteEmailHovered}
                                onNoMatchFound={onAutoCompleteListEmpty}
                                activeIndex={autoCompleteListIndex}
                                separatorIndex={getAutocompleteItemsSeparatorIndex()}
                            />
                        ) : null}
                        <MailingListWrapper
                            disabled={readOnlyMalingList}
                            nonDeletebleMailingList={_nonDeletebleMailingList}
                            listBoxHeight={
                                styleOptions?.listBoxHeight
                                    ? styleOptions.listBoxHeight
                                    : null
                            }
                            removeListItem={onDelete}
                            mailingList={mailingList}
                            mailingWrapperClassName={
                                styleOptions?.mailingWrapperClassNameOverride
                            }
                            emailsBeingResolved={emailsBeingResolved}
                        />
                    </div>
                </div>
            );
        }
    )
);
