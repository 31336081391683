import React, { useState, FocusEvent, KeyboardEvent } from "react";
import ReactOutsideClickHandler from "react-outside-click-handler";
import { observer } from "mobx-react";
import clsx from "clsx";

import addRating from "@difftone/assets/add-rating-icon.svg";
import addRatingDisabled from "@difftone/assets/add-rating-icon-disabled.svg";
import removeRating from "@difftone/assets/remove-rating-icon.svg";
import removeRatingDisabled from "@difftone/assets/remove-rating-icon-disabled.svg";
import editQuestionIcon from "@difftone/assets/edit-rating-question-icon-enabled.svg";
import confirmEditQuestion from "@difftone/assets/confirm-edit-question.svg";
import { Question, RatingQuestion, BaseSurvey } from "@difftone/types";
import {
    constantAlertVariants,
    WIZARD_DIFFERENT_RATING_TOOLTIP,
} from "@difftone/constants";
import { wizardDisplayStore } from "@difftone/stores";
import {
    updateQuestionInWizard,
    validateWizardSurvey,
    setRatingQuestionAutoScaleRange,
    setWasDifferentRatingScaleTooltipAlreadyShown,
} from "@difftone/actions";
import { Checkbox, ShowDifftoneTooltip } from "@difftone/shared-components";

import {
    expanded_rating_container,
    expanded_rating_container_edit_mode,
    select_rating_button,
    rating_add_button,
    enabled,
    disabled_style,
    selected,
    rating_input_field,
    rating_remove_button,
    edit_mode_button,
    rating_options_wrapper,
    rating_options_wrapper_edit_mode,
    edit_mode_active,
    edit_mode_tick_left,
    edit_mode_ticks_right,
    rating_remove_button_disabled,
    rating_add_button_disabled,
    edit_labels,
    index_label,
    index_label_item,
    zero_based_toggle_container,
    zero_based_toggle_container_edit_mode,
} from "./expanded-rating.module.css";

const getAmountOfDifferentRatingScales = (allRatingScales: number[]) => {
    const uniqueScales = Array.from(new Set(allRatingScales));

    return uniqueScales.length;
};

const MAX_RATING_LABEL_LENGTH = 20;
const MIN_NUMBER_OF_OPTIONS = 3;
const MAX_NUMBER_OF_OPTIONS_BASE_1 = 10;
const MAX_NUMBER_OF_OPTIONS_BASE_0 = 11;
const RATING_BUTTON_WIDTH_MULTIPLICATION = 5.5;
const MAX_VALUE_LENGTH_OF_TEXT_IN_BUTTON = 12;

export type ExpandedRatingProps = {
    disabled: boolean;
    ratingObject: Question<RatingQuestion>;
    questionNumber?: number;
    survey: BaseSurvey;
};

export const ExpandedRating = observer((props: ExpandedRatingProps) => {
    const { disabled, ratingObject, questionNumber = 0, survey } = props;
    const { wasDifferentRatingScaleTooltipAlreadyShown } = wizardDisplayStore;
    //UseState for only internal changes
    const [questionEditMode, setQuestionEditMode] = useState(false);
    //UseState for only internal changes
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    //UseState for only internal changes
    const [alertMessage, setAlertMessage] = useState<string>("");

    const ratingQuestions: Question<RatingQuestion>[] = survey.questions.filter(
        ({ question_type_name }) => question_type_name === "RATING"
    ) as Question<RatingQuestion>[];
    const ratingScales = ratingQuestions.map(
        ({ content }) => content.rating_options.length
    );

    const [selectedRatingStyle, setSelectedRatingStyle] = useState(
        disabled
            ? new Array(ratingObject.content.rating_options.length).fill(
                  clsx(select_rating_button, disabled_style)
              )
            : new Array(ratingObject.content.rating_options.length).fill(
                  select_rating_button
              )
    );

    const isZeroBased = ratingObject.content.rating_options[0].value === 0;

    const toggleZeroBased = () => {
        if (isZeroBased) {
            if (
                ratingObject.content.rating_options.length >
                MAX_NUMBER_OF_OPTIONS_BASE_1
            ) {
                ratingObject.content.rating_options.shift();
            }

            const newRatingOptions = ratingObject.content.rating_options.map(
                (ratingOption, index) => ({
                    ...ratingOption,
                    value: index + 1,
                    label:
                        ratingObject.content.rating_options[index].label ===
                        ratingObject.content.rating_options[
                            index
                        ].value.toString()
                            ? (index + 1).toString()
                            : ratingOption.label,
                })
            );

            const copyOfContent = {
                ...ratingObject.content,
                rating_options: newRatingOptions,
            };

            updateQuestionInWizard(
                ratingObject as Question<RatingQuestion>,
                "content",
                copyOfContent
            );
        } else {
            const newRatingOptions = ratingObject.content.rating_options.map(
                (ratingOption, index) => ({
                    ...ratingOption,
                    value: index,
                    label:
                        ratingObject.content.rating_options[index].label ===
                        ratingObject.content.rating_options[
                            index
                        ].value.toString()
                            ? index.toString()
                            : ratingOption.label,
                })
            );

            const copyOfContent = {
                ...ratingObject.content,
                rating_options: newRatingOptions,
            };

            updateQuestionInWizard(
                ratingObject as Question<RatingQuestion>,
                "content",
                copyOfContent
            );
        }
    };

    const onAddRatingOption = () => {
        if (
            ratingObject.content.rating_options.length <
            (isZeroBased
                ? MAX_NUMBER_OF_OPTIONS_BASE_0
                : MAX_NUMBER_OF_OPTIONS_BASE_1)
        ) {
            const oldLastValue = ratingObject.content.rating_options.at(-1);
            const newLastValue = oldLastValue ? oldLastValue.value + 1 : 1;
            const copyOfContent = {
                ...ratingObject.content,
                rating_options: [
                    ...ratingObject.content.rating_options,
                    {
                        value: newLastValue,
                        label: `${newLastValue}`,
                    },
                ],
            };

            if (questionNumber === 0) {
                setRatingQuestionAutoScaleRange(
                    copyOfContent.rating_options.length
                );
            }

            if (
                copyOfContent.rating_options.length ===
                (isZeroBased
                    ? MAX_NUMBER_OF_OPTIONS_BASE_0
                    : MAX_NUMBER_OF_OPTIONS_BASE_1)
            ) {
                setAlertMessage(
                    constantAlertVariants.rating_limit_reached.text
                );
            }

            updateQuestionInWizard(
                ratingObject as Question<RatingQuestion>,
                "content",
                copyOfContent
            );

            setSelectedRatingStyle(
                disabled
                    ? new Array(
                          ratingObject.content.rating_options.length + 1
                      ).fill(clsx(select_rating_button, disabled_style))
                    : new Array(
                          ratingObject.content.rating_options.length + 1
                      ).fill(select_rating_button)
            );
        }
        validateWizardSurvey();

        if (!wasDifferentRatingScaleTooltipAlreadyShown) {
            const ratingScalesWithoutChangedQuestion = [...ratingScales];
            ratingScalesWithoutChangedQuestion.splice(questionNumber, 1);
            const isOtherScalesAreTheSame =
                getAmountOfDifferentRatingScales(
                    ratingScalesWithoutChangedQuestion
                ) === 1;

            if (isOtherScalesAreTheSame) {
                const majorityRatingScaleToCompareWith =
                    ratingScalesWithoutChangedQuestion[0];

                if (
                    ratingObject.content.rating_options.length + 1 !==
                    majorityRatingScaleToCompareWith
                ) {
                    setIsTooltipVisible(true);
                }
            }
        }
    };

    const onDeleteRatingOption = () => {
        if (
            ratingObject.content.rating_options.length > MIN_NUMBER_OF_OPTIONS
        ) {
            const copyOfRatingOptions = [
                ...ratingObject.content.rating_options,
            ];
            copyOfRatingOptions.pop();
            const copyOfContent = {
                ...ratingObject.content,
                rating_options: copyOfRatingOptions,
            };

            if (questionNumber === 0) {
                setRatingQuestionAutoScaleRange(copyOfRatingOptions.length);
            }

            setAlertMessage("");

            updateQuestionInWizard(
                ratingObject as Question<RatingQuestion>,
                "content",
                copyOfContent
            );

            setSelectedRatingStyle(
                disabled
                    ? new Array(
                          ratingObject.content.rating_options.length
                      ).fill(clsx(select_rating_button, disabled_style))
                    : new Array(
                          ratingObject.content.rating_options.length
                      ).fill(select_rating_button)
            );

            validateWizardSurvey();

            if (!wasDifferentRatingScaleTooltipAlreadyShown) {
                const ratingScalesWithoutChangedQuestion = [...ratingScales];
                ratingScalesWithoutChangedQuestion.splice(questionNumber, 1);
                const isOtherScalesAreTheSame =
                    getAmountOfDifferentRatingScales(
                        ratingScalesWithoutChangedQuestion
                    ) === 1;

                if (isOtherScalesAreTheSame) {
                    const majorityRatingScaleToCompareWith =
                        ratingScalesWithoutChangedQuestion[0];

                    if (
                        ratingObject.content.rating_options.length - 1 !==
                        majorityRatingScaleToCompareWith
                    ) {
                        setIsTooltipVisible(true);
                    }
                }
            }
        } else {
            return;
        }
    };

    const onSelectedRating = (selectedIndex: number) => {
        if (!disabled) {
            const copy = [...selectedRatingStyle];
            const mapped = copy.map((item, index) => {
                if (index === selectedIndex) {
                    return clsx(item, selected);
                } else {
                    return disabled
                        ? clsx(select_rating_button, disabled_style)
                        : clsx(select_rating_button, enabled);
                }
            });
            setSelectedRatingStyle(mapped);
        }
    };

    const onChangeEditModeQuestions = () => {
        if (questionEditMode) {
            const copyOfRatingOptions = [
                ...ratingObject.content.rating_options,
            ];

            copyOfRatingOptions.forEach((option) => {
                if (option.label.trim().length === 0) {
                    option.label = `${option.value}`;
                }
            });

            const copyOfContent = {
                ...ratingObject.content,
                rating_options: copyOfRatingOptions,
            };

            updateQuestionInWizard(
                ratingObject as Question<RatingQuestion>,
                "content",
                copyOfContent
            );
        }

        setQuestionEditMode((prevMode) => !prevMode);
        setAlertMessage(() =>
            ratingObject.content.rating_options.length ===
            (isZeroBased
                ? MAX_NUMBER_OF_OPTIONS_BASE_0
                : MAX_NUMBER_OF_OPTIONS_BASE_1)
                ? constantAlertVariants.rating_limit_reached.text
                : ""
        );
    };

    const onChangeRatingText = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const index = JSON.parse(
            event.currentTarget.getAttribute("data-index") as string
        );
        const copyOfRatingOptions = [...ratingObject.content.rating_options];
        copyOfRatingOptions[index].label = event.target.value;
        const copyOfContent = {
            ...ratingObject.content,
            rating_options: copyOfRatingOptions,
        };
        updateQuestionInWizard(
            ratingObject as Question<RatingQuestion>,
            "content",
            copyOfContent
        );
    };

    const inputFocusedHandler = (event: FocusEvent<HTMLTextAreaElement>) => {
        event.target.select();
    };

    const overrideWhiteSpacesInput = (
        event: KeyboardEvent<HTMLTextAreaElement>
    ) => {
        if (event.key === " ") {
            event.preventDefault();
            event.currentTarget.value = event.currentTarget.value + " ";
        }
    };

    const isRemoveButtonDisabled =
        ratingObject.content.rating_options.length <= MIN_NUMBER_OF_OPTIONS;

    const isAddButtonDisabled =
        ratingObject.content.rating_options.length >=
        (isZeroBased
            ? MAX_NUMBER_OF_OPTIONS_BASE_0
            : MAX_NUMBER_OF_OPTIONS_BASE_1);

    return (
        <div
            className={clsx(
                expanded_rating_container,
                questionEditMode && expanded_rating_container_edit_mode
            )}
        >
            {questionEditMode ? (
                <div className={edit_labels}>Edit labels</div>
            ) : null}
            {disabled && questionEditMode ? (
                <button
                    disabled={isRemoveButtonDisabled}
                    onClick={onDeleteRatingOption}
                    className={
                        isRemoveButtonDisabled
                            ? clsx(
                                  rating_remove_button_disabled,
                                  rating_remove_button
                              )
                            : rating_remove_button
                    }
                >
                    <img
                        src={
                            isRemoveButtonDisabled
                                ? removeRatingDisabled
                                : removeRating
                        }
                        alt="remove rating"
                    />
                </button>
            ) : null}
            <div
                className={
                    questionEditMode
                        ? clsx(
                              rating_options_wrapper,
                              rating_options_wrapper_edit_mode
                          )
                        : rating_options_wrapper
                }
            >
                {ratingObject.content.rating_options.map(
                    (ratingOption, index) => {
                        const firstElementInOptions =
                            ratingOption ===
                            ratingObject.content.rating_options[0];

                        const lastElementInOptions =
                            ratingOption ===
                            ratingObject.content.rating_options[
                                ratingObject.content.rating_options.length - 1
                            ];

                        if (
                            lastElementInOptions &&
                            !wasDifferentRatingScaleTooltipAlreadyShown
                        ) {
                            return (
                                <button
                                    style={{
                                        width: `calc(38px + ${
                                            ratingOption.label?.length *
                                            RATING_BUTTON_WIDTH_MULTIPLICATION
                                        }px)`,
                                    }}
                                    onClick={() => onSelectedRating(index)}
                                    className={selectedRatingStyle[index]}
                                    key={ratingOption.value}
                                >
                                    {questionEditMode ? (
                                        <span className={edit_mode_tick_left}>
                                            "
                                        </span>
                                    ) : null}

                                    <ReactOutsideClickHandler
                                        onOutsideClick={() => {
                                            if (isTooltipVisible) {
                                                setWasDifferentRatingScaleTooltipAlreadyShown(
                                                    true
                                                );
                                            }
                                        }}
                                    >
                                        <ShowDifftoneTooltip
                                            tip={
                                                WIZARD_DIFFERENT_RATING_TOOLTIP
                                            }
                                            tooltipPosition="bottom"
                                            alwaysVisible={isTooltipVisible}
                                            disableHover
                                        >
                                            <textarea
                                                style={{
                                                    paddingTop:
                                                        ratingOption.label
                                                            .length >
                                                        MAX_VALUE_LENGTH_OF_TEXT_IN_BUTTON
                                                            ? "0px"
                                                            : "20px",
                                                }}
                                                onFocus={inputFocusedHandler}
                                                onKeyDown={
                                                    overrideWhiteSpacesInput
                                                }
                                                disabled={
                                                    questionEditMode
                                                        ? !disabled
                                                        : disabled
                                                }
                                                data-index={index}
                                                maxLength={
                                                    MAX_RATING_LABEL_LENGTH
                                                }
                                                onChange={onChangeRatingText}
                                                className={rating_input_field}
                                                value={ratingOption.label}
                                                rows={2}
                                            />
                                        </ShowDifftoneTooltip>
                                    </ReactOutsideClickHandler>

                                    {questionEditMode ? (
                                        <span className={edit_mode_ticks_right}>
                                            "
                                        </span>
                                    ) : null}
                                    <div className={index_label}>
                                        {ratingOption.value}
                                    </div>
                                    {firstElementInOptions ? (
                                        <div className={index_label_item}>
                                            low
                                        </div>
                                    ) : null}
                                    {lastElementInOptions ? (
                                        <div className={index_label_item}>
                                            high
                                        </div>
                                    ) : null}
                                </button>
                            );
                        }

                        return (
                            <button
                                style={{
                                    width: `calc(38px + ${
                                        ratingOption.label?.length *
                                        RATING_BUTTON_WIDTH_MULTIPLICATION
                                    }px)`,
                                }}
                                onClick={() => onSelectedRating(index)}
                                className={selectedRatingStyle[index]}
                                key={ratingOption.value}
                            >
                                {questionEditMode ? (
                                    <span className={edit_mode_tick_left}>
                                        "
                                    </span>
                                ) : null}

                                <textarea
                                    style={{
                                        paddingTop:
                                            ratingOption.label.length >
                                            MAX_VALUE_LENGTH_OF_TEXT_IN_BUTTON
                                                ? "0px"
                                                : "20px",
                                    }}
                                    onFocus={inputFocusedHandler}
                                    onKeyDown={overrideWhiteSpacesInput}
                                    disabled={
                                        questionEditMode ? !disabled : disabled
                                    }
                                    data-index={index}
                                    maxLength={MAX_RATING_LABEL_LENGTH}
                                    onChange={onChangeRatingText}
                                    className={rating_input_field}
                                    value={ratingOption.label}
                                    rows={2}
                                />
                                {questionEditMode ? (
                                    <span className={edit_mode_ticks_right}>
                                        "
                                    </span>
                                ) : null}
                                <div className={index_label}>
                                    {ratingOption.value}
                                </div>
                                {firstElementInOptions ? (
                                    <div className={index_label_item}>low</div>
                                ) : null}
                                {lastElementInOptions ? (
                                    <div className={index_label_item}>high</div>
                                ) : null}
                            </button>
                        );
                    }
                )}
            </div>
            {disabled && questionEditMode ? (
                <ShowDifftoneTooltip
                    tip={alertMessage}
                    tooltipPosition="bottom"
                >
                    <button
                        onClick={onAddRatingOption}
                        className={
                            isAddButtonDisabled
                                ? clsx(
                                      rating_add_button,
                                      rating_add_button_disabled
                                  )
                                : rating_add_button
                        }
                    >
                        <img
                            alt="plus icon"
                            src={
                                isAddButtonDisabled
                                    ? addRatingDisabled
                                    : addRating
                            }
                        />
                    </button>
                </ShowDifftoneTooltip>
            ) : null}
            {disabled ? (
                <button
                    className={
                        questionEditMode
                            ? clsx(edit_mode_active, edit_mode_button)
                            : edit_mode_button
                    }
                    onClick={onChangeEditModeQuestions}
                >
                    <img
                        alt="edit-mode-icon"
                        src={
                            questionEditMode
                                ? confirmEditQuestion
                                : editQuestionIcon
                        }
                    />
                </button>
            ) : null}
            <div
                className={clsx(
                    zero_based_toggle_container,
                    questionEditMode && zero_based_toggle_container_edit_mode
                )}
            >
                <Checkbox checked={isZeroBased} onClick={toggleZeroBased} />
                <span>Start at zero</span>
            </div>
        </div>
    );
});
