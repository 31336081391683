import React from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import { alertsMailingListGroupTitles } from "@difftone/constants";
import { HumanizeEmail, Spinner, UserIcon } from "@difftone/shared-components";
import { EmailAddress } from "@difftone/types";

import removeItem from "@difftone/assets/remove-from-mailing-list.svg";
import groupItem from "@difftone/assets/group-mailing-list-icon.svg";

import {
    mailing_list_item,
    mailing_list_item_disabled,
    mailing_list_item_loader,
    user_email,
    credentials,
    user_icon_scope,
} from "./deleteble-email-wrapper.module.css";

export type DeletebleEmailWrapperProps = {
    email: EmailAddress;
    removeListItem: (email: EmailAddress) => void;
    disabled: boolean;
    isGroupEmail: boolean;
    autofocus: boolean;
    emailBeingValidated?: boolean;
};

const COLOR_BRIGHT_GREEN = "BRIGHT_GREEN";
const COLOR_GREEN = "GREEN";

export const DeletebleEmailWrapper = observer(
    (props: DeletebleEmailWrapperProps) => {
        const {
            email,
            removeListItem,
            disabled,
            isGroupEmail,
            emailBeingValidated,
        } = props;
        props;

        const _email = alertsMailingListGroupTitles[email] || email;
        return (
            <div
                tabIndex={0}
                id="list_items"
                className={clsx(
                    mailing_list_item,
                    emailBeingValidated && mailing_list_item_disabled
                )}
            >
                {isGroupEmail ? (
                    <div className={credentials}>
                        <img src={groupItem} alt="group-icon" />
                    </div>
                ) : (
                    <div className={credentials}>
                        <UserIcon
                            color={
                                emailBeingValidated
                                    ? COLOR_BRIGHT_GREEN
                                    : COLOR_GREEN
                            }
                            className={user_icon_scope}
                        />
                    </div>
                )}
                <div className={user_email}>
                    <HumanizeEmail humanizeEmail={_email} />
                </div>
                {emailBeingValidated ? (
                    <Spinner
                        size="X-SMALL"
                        className={mailing_list_item_loader}
                    />
                ) : null}
                {disabled || emailBeingValidated ? null : (
                    <img
                        onClick={() => removeListItem(email)}
                        src={removeItem}
                        alt="remove"
                    />
                )}
            </div>
        );
    }
);
