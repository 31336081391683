import { action } from "mobx";
import { Buffer } from "buffer";
import {
    ResultsFilter,
    SharedResult,
    TimeCompareUnits,
    TimeRangesContainer,
    UUID,
    SurveyClasses,
} from "@difftone/types";
import {
    sharedResultsDisplayStore,
    sharedResultStore,
    SortingSharedViewsWithMeProfile,
} from "@difftone/stores";
import { setQueryParam } from "@difftone/actions";
import {
    removeMeFromSharedResults,
    shareResults,
    unshareResults,
} from "@difftone/services";
import { getQueryParamsFromUrl } from "@difftone/reducers";
import { TIME_FILTER_ID } from "@difftone/constants";

export const setSharedViewsStoreSortingProfile = action(
    (sortingProfile: SortingSharedViewsWithMeProfile) => {
        sharedResultStore.sortingSharedResultProfile = sortingProfile;
    }
);

export const setTargetSharedView = action((target: SharedResult) => {
    sharedResultStore.targetSharedResult = target;
    setQueryParam("sharedView", JSON.stringify(target));
});

export const initTargetSharedViewFromUrl = action(() => {
    const params = getQueryParamsFromUrl();
    const sharedViewQuery = params.get("sharedView");
    let sharedView: SharedResult | undefined = undefined;

    if (sharedViewQuery) {
        sharedView = JSON.parse(sharedViewQuery);
    }

    if (sharedView) {
        sharedResultStore.targetSharedResult = sharedView;
    }

    return sharedView;
});

export const clearTargetSharedView = action(() => {
    sharedResultStore.targetSharedResult = undefined;
    setQueryParam("sharedView", "");
});

export const createShareResults = action(async (sharedResult: SharedResult) => {
    const { results } = await shareResults(sharedResult);

    sharedResultStore.viewsISharedWith = [
        ...sharedResultStore.viewsISharedWith,
        { ...results },
    ];
});

export const removeShare = action(async (share: SharedResult) => {
    const views = [...sharedResultStore.viewsISharedWith];
    const targetIndex = views.findIndex(({ uuid }) => share.uuid === uuid);

    await unshareResults(share);

    views.splice(targetIndex, 1);

    sharedResultStore.viewsISharedWith = views;
});

export const removeMeFromShare = action(async (share: SharedResult) => {
    const views = [...sharedResultStore.sharedViews];
    const targetIndex = views.findIndex(({ uuid }) => share.uuid === uuid);

    await removeMeFromSharedResults(share);

    views.splice(targetIndex, 1);

    sharedResultStore.sharedViews = views;
});

const getFilterParams = (copyOfProducedFilters: ResultsFilter[]) =>
    copyOfProducedFilters
        .map((filter: ResultsFilter) => {
            const encodedFilterFormat = JSON.stringify([
                filter.filterKey.uuid,
                filter.filterValues,
            ]);

            return Buffer.from(encodedFilterFormat).toString("base64");
        })
        .join();

export const getResultPageSearchParams = (
    sharedResults?: SharedResult,
    withWindowLocationSearch?: boolean
) => {
    const search = new URLSearchParams(
        withWindowLocationSearch ? window.location.search : ""
    );

    if (sharedResults) {
        const { filters, compare_by } = sharedResults;

        if (filters.length) {
            search.set("filter", getFilterParams(filters));
        }

        if (compare_by.length) {
            search.set(
                "compare",
                `${compare_by.reduce((acc, value) => `${value},${acc}`)}`
            );
        }

        search.set("sharedView", JSON.stringify(sharedResults));
    }

    return search.toString();
};

export const updateSelectedFilters = action(
    (selectedFilters: ResultsFilter[]) => {
        sharedResultsDisplayStore.setSelectedFilters(selectedFilters);
    }
);

export const updateSharedResultsFilters = action(
    (filterUpdate: ResultsFilter[]) => {
        sharedResultsDisplayStore.setSelectedFilters(
            checkSharedResultsFilterValuesLengthAndReorderCompare(filterUpdate)
        );
    }
);

const checkSharedResultsFilterValuesLengthAndReorderCompare = (
    shareResultsFilters: ResultsFilter[]
) => {
    const displayedFilters = shareResultsFilters.filter(
        (_filter) => _filter.isPartOfComparedPlain
    );

    if (displayedFilters.length === 2) {
        const firstDisplayedFilter = displayedFilters[0];
        const secondDisplayedFilter = displayedFilters[1];

        if (firstDisplayedFilter.filterValues.length === 1) {
            firstDisplayedFilter.indexInComparePlain = 1;
            secondDisplayedFilter.indexInComparePlain = 2;
        }
        if (secondDisplayedFilter.filterValues.length === 1) {
            secondDisplayedFilter.indexInComparePlain = 1;
            firstDisplayedFilter.indexInComparePlain = 2;
        }
    }

    return shareResultsFilters;
};

export const sharedResultsTurnOnFilterAsCompare = action(
    (resultsFilter: ResultsFilter) => {
        const {
            getFiltersActiveAsCompares,
            getSelectedFilters,
            addActiveAsComparesFiltersUUIDs,
            removeActiveCompareFilterFromFilterUUIDs,
        } = sharedResultsDisplayStore;

        const copyOfSelectedFilters: ResultsFilter[] = JSON.parse(
            JSON.stringify(getSelectedFilters())
        );

        const filtersActiveAsCompares = getFiltersActiveAsCompares();

        const filterFromSelectedFilters = copyOfSelectedFilters.find(
            (_filter) => _filter.filterKey.uuid === resultsFilter.filterKey.uuid
        );

        const isTimeComparePartOfActiveCompares =
            filterFromSelectedFilters?.filterKey.question_type_name ===
            "SYSTEM_ANSWER_TIME_FRAME"
                ? filtersActiveAsCompares
                      .map((activeCompare) => activeCompare.filterKey.uuid)
                      .includes(filterFromSelectedFilters.filterKey.uuid)
                : false;

        if (filterFromSelectedFilters) {
            if (!filtersActiveAsCompares.length) {
                filterFromSelectedFilters.indexInComparePlain = 1;
                filterFromSelectedFilters.isPartOfComparedPlain = true;
            }
            if (
                filtersActiveAsCompares.length === 1 &&
                !isTimeComparePartOfActiveCompares
            ) {
                filterFromSelectedFilters.indexInComparePlain = 2;
                filterFromSelectedFilters.isPartOfComparedPlain = true;
            }
            if (
                filtersActiveAsCompares.length === 2 &&
                !isTimeComparePartOfActiveCompares
            ) {
                const filterToReplace = copyOfSelectedFilters.find(
                    (_filter) => _filter.indexInComparePlain === 2
                );
                filterToReplace!.indexInComparePlain = 0;
                filterToReplace!.isPartOfComparedPlain = false;

                filterFromSelectedFilters.isPartOfComparedPlain = true;
                filterFromSelectedFilters.indexInComparePlain = 2;
                removeActiveCompareFilterFromFilterUUIDs(
                    filterToReplace!.filterKey.uuid
                );
            }

            addActiveAsComparesFiltersUUIDs(
                filterFromSelectedFilters.filterKey.uuid
            );

            sharedResultsDisplayStore.setSelectedFilters(
                checkSharedResultsFilterValuesLengthAndReorderCompare(
                    copyOfSelectedFilters
                )
            );
        }
    }
);

export const sharedResultsTurnOffFilterAsCompare = action(
    (resultsFilter: ResultsFilter) => {
        const {
            getFiltersActiveAsCompares,
            getSelectedFilters,
            removeActiveCompareFilterFromFilterUUIDs,
        } = sharedResultsDisplayStore;

        const copyOfSelectedFilters: ResultsFilter[] = JSON.parse(
            JSON.stringify(getSelectedFilters())
        );

        const filtersActiveAsCompares = getFiltersActiveAsCompares();

        const filterFromSelectedFilters = copyOfSelectedFilters.find(
            (_filter) => _filter.filterKey.uuid === resultsFilter.filterKey.uuid
        );

        if (filterFromSelectedFilters) {
            if (filtersActiveAsCompares.length === 1) {
                filterFromSelectedFilters.indexInComparePlain = 0;
                filterFromSelectedFilters.isPartOfComparedPlain = false;
            }
            if (filtersActiveAsCompares.length === 2) {
                filterFromSelectedFilters.indexInComparePlain = 0;
                filterFromSelectedFilters.isPartOfComparedPlain = false;
                copyOfSelectedFilters.find(
                    (_filter) =>
                        _filter.filterKey.uuid !==
                            filterFromSelectedFilters.filterKey.uuid &&
                        _filter.isPartOfComparedPlain
                )!.indexInComparePlain = 1;
            }

            removeActiveCompareFilterFromFilterUUIDs(
                filterFromSelectedFilters.filterKey.uuid
            );

            sharedResultsDisplayStore.setSelectedFilters(copyOfSelectedFilters);
        }
    }
);

export const switchSharedResultsActiveCompareIndex = action(() => {
    const { getFiltersActiveAsCompares, getSelectedFilters } =
        sharedResultsDisplayStore;

    const copyOfSelectedFilter: ResultsFilter[] = JSON.parse(
        JSON.stringify(getSelectedFilters())
    );

    const filtersActiveAsCompares = getFiltersActiveAsCompares();

    if (filtersActiveAsCompares.length === 2) {
        const firstCompare = copyOfSelectedFilter.find(
            (_filter) => _filter.indexInComparePlain === 1
        );
        const secondCompare = copyOfSelectedFilter.find(
            (_filter) => _filter.indexInComparePlain === 2
        );
        firstCompare!.indexInComparePlain = 2;
        secondCompare!.indexInComparePlain = 1;

        sharedResultsDisplayStore.setSelectedFilters(
            checkSharedResultsFilterValuesLengthAndReorderCompare(
                copyOfSelectedFilter
            )
        );
    }
});

export const setSelectedTimeCompareSharedResultsFilterAndTimeUnits = action(
    (
        selectedFilter: TimeCompareUnits,
        timeCompareFilterUuid: UUID,
        timeRangesContainer: TimeRangesContainer | null
    ) => {
        const { getSelectedFilters, setSelectedSharedResultsTimeCompare } =
            sharedResultsDisplayStore;

        const copySelectedFilters: ResultsFilter[] = JSON.parse(
            JSON.stringify(getSelectedFilters())
        );

        const timeCompareFromDisplayFilters = copySelectedFilters.find(
            (filter) => filter.filterKey.uuid === timeCompareFilterUuid
        );

        if (timeCompareFromDisplayFilters) {
            timeCompareFromDisplayFilters.filterValues = timeRangesContainer
                ? timeRangesContainer.timeRanges
                : [];

            timeCompareFromDisplayFilters.unit = selectedFilter;

            sharedResultsDisplayStore.setSelectedFilters(copySelectedFilters);

            sharedResultsDisplayStore.initFilterValues(copySelectedFilters);

            if (selectedFilter === "NONE") {
                sharedResultsTurnOffFilterAsCompare(
                    timeCompareFromDisplayFilters
                );
            } else {
                sharedResultsTurnOnFilterAsCompare(
                    timeCompareFromDisplayFilters
                );
            }
        }

        setSelectedSharedResultsTimeCompare(selectedFilter);
    }
);

export const addTimeCompareFilterToShareResultsBySurveyClass = (
    compareBy: ResultsFilter[],
    displayFilters: ResultsFilter[],
    survey: SurveyClasses
) => {
    let sharedResultDisplayFilters: ResultsFilter[] = [];

    switch (survey.survey_class) {
        case "ONGOING":
            const timeCompare = compareBy.find(
                (compare) => compare.filterKey.uuid === TIME_FILTER_ID
            );

            if (!timeCompare) {
                throw Error("[Difftone]: COuld not find time filter");
            }

            const isTimeCompareNotNone = timeCompare.unit !== "NONE";

            sharedResultDisplayFilters = isTimeCompareNotNone
                ? [...displayFilters, timeCompare]
                : displayFilters;

            break;
        case "SIMPLE":
        default:
            sharedResultDisplayFilters = displayFilters;
    }

    return sharedResultDisplayFilters;
};

export const isGraphDisabledInSharedResults = (currentPage: string) => {
    if (!currentPage.includes("shared-results")) {
        false;
    }

    const { selectedSharedResults } = sharedResultsDisplayStore;

    if (selectedSharedResults === undefined) {
        return false;
    }

    const timeFilter = selectedSharedResults.filters.find(
        (filter) => filter.filterKey.uuid === TIME_FILTER_ID
    );

    return timeFilter ? false : true;
};
