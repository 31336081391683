import React from "react";

type Color = "BLACK" | "GRAY";

export type TableHeaderArrowIconProps = {
    color: Color;
    className?: "";
};

export const TableHeaderArrowIcon = (props: TableHeaderArrowIconProps) => {
    const { color, className } = props;

    const colors = {
        BLACK: "#435863",
        GRAY: "#B2C0C8",
    };
    return (
        <svg
            className={className}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.61979 14C4.55356 14 3.95379 12.7737 4.60839 11.9321L8.98861 6.30036C9.50158 5.64082 10.4984 5.64082 11.0114 6.30036L15.3916 11.9321C16.0462 12.7737 15.4464 14 14.3802 14L5.61979 14Z"
                fill={colors[color]}
            />
        </svg>
    );
};
