import { navigationStore, sharedResultsDisplayStore } from "@difftone/stores";
import { ResultsFilter, UUID } from "@difftone/types";

export const getSharedResultsUuidFromUrl = () => {
    const { currentPage } = navigationStore;

    let shareResultsUuid: UUID | null = null;

    const currentPageUrl = currentPage.split("/");

    switch (currentPageUrl[1]) {
        case "shared-results":
            shareResultsUuid = currentPageUrl[2];
            break;
        default:
            shareResultsUuid = currentPageUrl.pop() || null;
            break;
    }

    return shareResultsUuid;
};

export const selectedFiltersActiveAsCompare = (
    filterAsActive: ResultsFilter[]
) => {
    return filterAsActive.map((filter) => {
        const { getActiveFilters } = sharedResultsDisplayStore;

        const filterCopy: ResultsFilter = JSON.parse(JSON.stringify(filter));
        filterCopy.filterValues = filter.filterValues.filter(
            (filterValue, index) => {
                return getActiveFilters(filter.filterKey.uuid)[index];
            }
        );
        return filterCopy;
    });
};

export const selectedFiltersNotActiveAsCompare = (
    filterNotActive: ResultsFilter[]
) => {
    const { getActiveFilters } = sharedResultsDisplayStore;

    return filterNotActive.map((filter) => {
        const filterCopy: ResultsFilter = JSON.parse(JSON.stringify(filter));
        filterCopy.filterValues = filter.filterValues.filter(
            (filterValue, index) => {
                return getActiveFilters(filter.filterKey.uuid)[index];
            }
        );
        return filterCopy;
    });
};
