import {
    Tag,
    ValidationResult,
    TagBackgroundColorValidationType
} from "@difftone/types";

import { isColorHashValid } from '.';

export const validateTagBackgroundColor = (tag: Tag): ValidationResult<TagBackgroundColorValidationType>[] => {

    return (!tag.background_color || !isColorHashValid(tag.background_color)) ?
        [{
            is_valid: false,
            validation_error_code: "TAG_BACKGROUND_COLOR_VALIDATION",
            validation_error_type: { tag_uuid: tag.uuid }
        }] : [{
            is_valid: true,
            validation_error_code: "TAG_BACKGROUND_COLOR_VALIDATION",
            validation_error_type: { tag_uuid: tag.uuid }
        }];
} 