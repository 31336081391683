import React from "react";
import { login_button } from "./microsoft-design.module.css";

export const MicrosoftStyle = () => {
    return (
        <button className={login_button}>
            <div>
                <svg
                    width="18"
                    height="18"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10.4818 9.97925H0.5V0H10.4818V9.97925Z"
                        fill="#F25022"
                    />
                    <path
                        d="M21.5285 9.97925H11.5468V0H21.5285V9.97925Z"
                        fill="#7FBA00"
                    />
                    <path
                        d="M21.5285 21.0259H11.5468V11.044H21.5285V21.0259Z"
                        fill="#FFB900"
                    />
                    <path
                        d="M10.4818 21.0259H0.5V11.044H10.4818V21.0259Z"
                        fill="#00A4EF"
                    />
                </svg>
            </div>
            <div>Sign in with Microsoft</div>
        </button>
    );
};
