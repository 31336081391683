import React from "react";
import { getMostPopularForSingleOrMultipleChoice } from "@difftone/actions";
import {
    Question,
    SingleChoiceQuestion,
    SupportedQuestionResultsMapAfterDigestionWithoutNulls,
} from "@difftone/types";

import { MobileResultBox } from "@difftone/shared-components";

export type SingleChoiceTypeProps = {
    question: Question<SingleChoiceQuestion>;
    digestedResultsRow: SupportedQuestionResultsMapAfterDigestionWithoutNulls;
    columnKey: string;
    index: number;
};

export const SingleChoiceType = (props: SingleChoiceTypeProps) => {
    const { columnKey, digestedResultsRow, index, question } = props;

    const mostPopularSingleChoice = getMostPopularForSingleOrMultipleChoice(
        digestedResultsRow,
        columnKey
    );

    return (
        <MobileResultBox
            key={question.uuid + columnKey + index}
            colorIndicator={"OVERALL"}
            resultValue={mostPopularSingleChoice ? mostPopularSingleChoice : ""}
        />
    );
};
