import { action } from "mobx";
import { surveyResponseDisplayStore } from "@difftone/stores";
import { UUID } from "@difftone/types";

export const setFocusedSurveyResponseInputCard = action(
    (newFocusedCard: UUID | null) => {
        surveyResponseDisplayStore.focusedSurveyResponseInputCard =
            newFocusedCard;
    }
);

export const setWasSurveyResponseOnceSubmitted = action((value: boolean) => {
    surveyResponseDisplayStore.wasSurveyResponseOnceSubmitted = value;
});
