import React from "react";

import {
    runtime_error_fallback_page,
    error_message_box,
    title,
} from "./runtime-error-fallback-page.module.css";

export type RuntimeErrorFallbackPageProps = {};

export const RuntimeErrorFallbackPage = () => {
    return (
        <div className={runtime_error_fallback_page}>
            <div className={error_message_box}>
                <div className={title}>OOPS !</div>
                <div>Something unexpected happened !</div>
                <div>Please refresh the page</div>
            </div>
        </div>
    );
};
