import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core";
import { ShowDifftoneTooltip } from "@difftone/shared-components";
import { convertTimeRangeToHeaderDisplay } from "@difftone/reducers";

import { FirstValueTitle } from "./components";
import {
    first_attribute_row,
    first_row_attribute_value,
    first_attribute_row_slant,
} from "./first-attribute-row.module.css";
import { ResultsFilter } from "@difftone/types";

const ALLOWED_FIRST_ATTRIBUTE_TITLE_LENGTH = 9;
const BOX_SIZE = 53;
const DEFAULT_FILTER_VALUE_LENGTH = 1;

const extendTooltipStyle = makeStyles({
    tooltipFirstHeaderRow: {
        top: "-40px",
        left: "35px",
    },
});

export type FirstAttributeRowProps = {
    filtersActiveAsCompares: ResultsFilter[];
};

export const FirstAttributeRow = observer((props: FirstAttributeRowProps) => {
    const { filtersActiveAsCompares } = props;
    const firstHeader = filtersActiveAsCompares.find(
        (_filter) => _filter.indexInComparePlain === 1
    );
    const secondHeader = filtersActiveAsCompares.find(
        (_filter) => _filter.indexInComparePlain === 2
    );

    const firstHeaderTooltip = extendTooltipStyle().tooltipFirstHeaderRow;

    return (
        <div className={first_attribute_row}>
            {firstHeader?.filterValues
                .map((item) => {
                    if (typeof item === "object") {
                        const timeFrameHeaderValue =
                            convertTimeRangeToHeaderDisplay(firstHeader, item);
                        return timeFrameHeaderValue;
                    }
                    return item;
                })
                .map((item) => {
                    return (
                        <ShowDifftoneTooltip
                            hideTooltip={
                                item.length <
                                ALLOWED_FIRST_ATTRIBUTE_TITLE_LENGTH *
                                    (secondHeader?.filterValues.length ||
                                        DEFAULT_FILTER_VALUE_LENGTH)
                            }
                            extendTooltipStyle={
                                secondHeader ? " " : firstHeaderTooltip
                            }
                            disableHover={
                                item.length >=
                                ALLOWED_FIRST_ATTRIBUTE_TITLE_LENGTH *
                                    (secondHeader?.filterValues.length ||
                                        DEFAULT_FILTER_VALUE_LENGTH)
                                    ? false
                                    : true
                            }
                            key={item}
                            tooltipPosition="top"
                            tip={item}
                        >
                            <div
                                style={
                                    firstHeader
                                        ? {
                                              width: `${
                                                  BOX_SIZE *
                                                  (secondHeader?.filterValues
                                                      .length ||
                                                      DEFAULT_FILTER_VALUE_LENGTH)
                                              }px`,
                                          }
                                        : {}
                                }
                                className={
                                    secondHeader
                                        ? first_row_attribute_value
                                        : first_attribute_row_slant
                                }
                                key={item}
                            >
                                <FirstValueTitle
                                    filterValuesLength={
                                        secondHeader?.filterValues.length || 1
                                    }
                                    title={item}
                                    isSecondHeader={secondHeader ? true : false}
                                />
                            </div>
                        </ShowDifftoneTooltip>
                    );
                })}
        </div>
    );
});
