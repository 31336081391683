import { action } from "mobx";
import {
    SimpleResultsFilterProfile,
    SimpleResultsSearchProfile,
    SimpleResultsSortingProfile,
    UUID,
} from "@difftone/types";
import {
    simpleSurveyResultsStore,
    FilterDialogPositionOffset,
} from "@difftone/stores";

export const setSelectedQuestion = action((selectedUUID: UUID | null) => {
    simpleSurveyResultsStore.selectedQuestion = selectedUUID;
});

export const setIsFilterDialogOpen = (
    isOpen: boolean,
    clickedPosition?: FilterDialogPositionOffset
) => {
    simpleSurveyResultsStore.isFilterDialogOpen = isOpen;
    simpleSurveyResultsStore.filterDialogPosition = clickedPosition || {
        offsetLeft: 0,
        offsetTop: 0,
    };
};

export const setSimpleResultsSortingProfile = action(
    (newSortingProfile: SimpleResultsSortingProfile) => {
        simpleSurveyResultsStore.sortingProfile = newSortingProfile;
    }
);

export const setSimpleResultsFitlerProfile = action(
    (newFilterProfile: SimpleResultsFilterProfile) => {
        simpleSurveyResultsStore.filterProfile = newFilterProfile;
    }
);

export const clearSimpleResultsSortingProfile = action(() => {
    simpleSurveyResultsStore.sortingProfile = {
        sortBy: null,
        direction: "DESCENDING",
    };
});

export const setSimpleResultsFitlerProfileFocusedColumn = action(
    (columnKey: string) => {
        simpleSurveyResultsStore.filterProfile.focusedColumnKey = columnKey;
    }
);

export const setSelectedQuestionFilterInputValue = action(
    (currentTypedInput: string) => {
        simpleSurveyResultsStore.selectedQuestionFilterInput =
            currentTypedInput;
    }
);

export const setIsSelectedQuestionInputFocused = action(
    (isFocused: boolean) => {
        simpleSurveyResultsStore.isSelectedQuestionInputFocused = isFocused;
    }
);

export const setSearchProfileByKey = action(
    (searchedColumn: string, searchInput: string) => {
        const { searchProfile } = simpleSurveyResultsStore;

        const copyOfSearchProfile: SimpleResultsSearchProfile = JSON.parse(
            JSON.stringify(searchProfile)
        );

        copyOfSearchProfile[searchedColumn] = searchInput;

        simpleSurveyResultsStore.searchProfile = copyOfSearchProfile;
    }
);

export const resetSearchProfile = action(() => {
    const { searchProfile } = simpleSurveyResultsStore;
    const copyOfSearchProfile: SimpleResultsSearchProfile = JSON.parse(
        JSON.stringify(searchProfile)
    );

    const currentProfileKeys = Object.keys(copyOfSearchProfile);

    if (!currentProfileKeys.length) {
        return;
    }

    currentProfileKeys.forEach((key) => delete copyOfSearchProfile[key]);

    simpleSurveyResultsStore.searchProfile = copyOfSearchProfile;
});
