import React from "react";

type Color = "BLACK" | "GREEN" | "GRAY" | "BLUE";

export type BellEventIconProps = {
    color: Color;
    className?: "";
};

export const BellEventIcon = (props: BellEventIconProps) => {
    const { color, className } = props;

    const colors = {
        BLACK: "#565E63",
        GREEN: "#009788",
        GRAY: "#B6B8BA",
        BLUE: "#035375",
    };

    return (
        <svg
            className={className}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.8537 8.95513V13.6346L17.0517 14.8419C17.6413 15.4315 17.2201 16.4423 16.3872 16.4423H4.0614C3.22845 16.4423 2.81666 15.4315 3.40627 14.8419L4.62294 13.6346V8.95513C4.62294 6.07256 6.14845 3.67667 8.83448 3.04026V2.40385C8.83448 1.62705 9.45217 1 10.229 1C11.0058 1 11.6422 1.62705 11.6422 2.40385V3.04026C14.3188 3.67667 15.8537 6.08192 15.8537 8.95513ZM12.1006 17.3782C12.1006 18.4077 11.2583 19.25 10.2288 19.25C9.18998 19.25 8.35703 18.4077 8.35703 17.3782H12.1006ZM5.34357 3.08704C5.73664 2.7314 5.746 2.12307 5.37164 1.74871C5.016 1.39307 4.43575 1.38371 4.07075 1.72999C2.47036 3.18999 1.366 5.1741 1.01036 7.40153C0.92613 7.97243 1.366 8.48717 1.94626 8.48717C2.39549 8.48717 2.78857 8.15961 2.86344 7.71038C3.14421 5.89474 4.04267 4.27563 5.34357 3.08704ZM15.105 1.74871C15.4606 1.39307 16.0409 1.38371 16.4152 1.72999C18.0063 3.19935 19.1013 5.1741 19.4569 7.39217C19.5505 7.96307 19.1013 8.47781 18.5304 8.47781C18.0718 8.47781 17.6787 8.15025 17.6132 7.70102C17.3324 5.88538 16.4246 4.26628 15.1331 3.07769C14.7493 2.72204 14.7306 2.12307 15.105 1.74871Z"
                fill={colors[color]}
            />
        </svg>
    );
};
