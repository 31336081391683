import React from "react";
import {
    MultipleChoiceQuestion,
    Question,
    SupportedQuestionResultsMapAfterDigestionWithoutNulls,
} from "@difftone/types";
import { getMostPopularForSingleOrMultipleChoice } from "@difftone/actions";
import { ResultBox } from "@difftone/shared-components";

export type MultipleChoiceTypeProps = {
    question: Question<MultipleChoiceQuestion>;
    digestedResultsRow: SupportedQuestionResultsMapAfterDigestionWithoutNulls;
    columnKey: string;
    index: number;
};

export const MultipleChoiceType = (props: MultipleChoiceTypeProps) => {
    const { question, digestedResultsRow, columnKey, index } = props;

    const mostPopularMultipleChoice = getMostPopularForSingleOrMultipleChoice(
        digestedResultsRow,
        columnKey
    );
    return (
        <ResultBox
            key={question.uuid + columnKey + index}
            colorIndicator={
                index === 0
                    ? mostPopularMultipleChoice
                        ? "OVERALL"
                        : "DEFAULT"
                    : "DEFAULT"
            }
            resultValue={
                mostPopularMultipleChoice ? mostPopularMultipleChoice : ""
            }
        />
    );
};
