import React from "react";
import { observer } from "mobx-react";
import { simpleSurveyResultsStore } from "@difftone/stores";
import { BaseSurvey } from "@difftone/types";

import { LeftMenuQuestion, SelectedQuestion } from "./components";

import {
    left_menu_children,
    left_menu_title,
    questions_list_wrapper,
    no_mathces,
} from "./left-menu-children.module.css";

export type LeftMenuChildrenProps = { survey: BaseSurvey };

export const LeftMenuChildren = observer((props: LeftMenuChildrenProps) => {
    const { survey } = props;
    const { selectedQuestionFilterInput } = simpleSurveyResultsStore;

    const matchingQuestionsToDisplay = survey.questions.filter((question) =>
        question.title
            .toLowerCase()
            .includes(selectedQuestionFilterInput.toLowerCase())
    );

    return (
        <div className={left_menu_children}>
            <SelectedQuestion survey={survey} />
            <div className={left_menu_title}>Questions:</div>
            <div className={questions_list_wrapper}>
                {matchingQuestionsToDisplay.length ? (
                    matchingQuestionsToDisplay.map((question, index) => (
                        <LeftMenuQuestion
                            key={question.uuid}
                            index={index}
                            question={question}
                        />
                    ))
                ) : (
                    <div className={no_mathces}>No matching question</div>
                )}
            </div>
        </div>
    );
});
