import React, { ReactNode } from "react";

import {
    custom_checkbox,
    disabled_checkbox,
} from "./custom-checkbox-input.module.css";

export type CustomCheckboxInputProps = {
    id: string;
    disabled: boolean;
    children?: ReactNode;
};

export const CustomCheckboxInput = (props: CustomCheckboxInputProps) => {
    const { id, disabled, children } = props;
    const getTempId = () => {
        return JSON.stringify(Math.random());
    };
    const tempID = getTempId();

    return (
        <div
            className={
                disabled
                    ? [custom_checkbox, disabled_checkbox].join(" ")
                    : custom_checkbox
            }
        >
            <label htmlFor={id === "" ? tempID : id}></label>
            {children}
            <input id={id === "" ? tempID : id} type="checkbox" />
        </div>
    );
};
