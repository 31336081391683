import { EmailAddress, OrganizationSettings } from "@difftone/types";
import { organizationStore } from "@difftone/stores";

export const getOrganizations = () => {
    return organizationStore.getOrganizationsListAsArray();
};

export const getSelectedOrganizationUUID = () => {
    return organizationStore.getSelectedOrganizationUUID();
};

export const getSelectedOrganizationOrThrow = () => {
    const selectedOrganizationUUID =
        organizationStore.getSelectedOrganizationUUID();
    const organizationsList = organizationStore.getOrganizationsListAsArray();
    const selectedOrganization = organizationsList?.find(
        (organization) => organization?.uuid === selectedOrganizationUUID
    );
    if (!selectedOrganization) {
        throw new Error("No organization selected");
    }
    return selectedOrganization;
};

export const getSettingsOrThrow = () => {
    const _org = getSelectedOrganizationOrThrow();
    if (!_org) return _org;

    return _org.settings;
};

export const getAdmins = () => {
    const _org = getSelectedOrganizationOrThrow();
    if (!_org) return _org;

    return _org.sys_admins;
};

export const getOrganizationName = () => {
    const _org = getSelectedOrganizationOrThrow();
    if (!_org) return _org;

    return _org.name;
};
