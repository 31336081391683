import { makeAutoObservable } from "mobx";

import {
    ResultsDisplayType,
    ResultsDisplayView,
    ResultsFilter,
    ResultsTableDisplayQuestion,
    TimeCompareUnits,
    UUID,
} from "@difftone/types";

export const ALLOWED_ATTRIBUTE_TITLE_LENGTH = 10;

class ResultsDisplayStore {
    constructor() {
        makeAutoObservable(this);
    }
    private _resultsTableQuestions: {
        [x: string]: ResultsTableDisplayQuestion;
    } = {};
    get resultsTableQuestions() {
        return this._resultsTableQuestions;
    }
    set resultsTableQuestions(newResultsTableQuestions: {
        [x: string]: ResultsTableDisplayQuestion;
    }) {
        this._resultsTableQuestions = newResultsTableQuestions;
    }

    private _openedSelectedTextQuestion: UUID | null = null;

    get openedSelectedTextQuestion() {
        return this._openedSelectedTextQuestion;
    }

    set openedSelectedTextQuestion(newQuestionUUID: UUID | null) {
        this._openedSelectedTextQuestion = newQuestionUUID;
    }

    private _actionsPopperOpened: boolean = false;

    get actionsPopperOpened() {
        return this._actionsPopperOpened;
    }

    set actionsPopperOpened(opened: boolean) {
        this._actionsPopperOpened = opened;
    }

    private _bookmarksPopperOpened: boolean = false;

    get bookmarksPopperOpened(): boolean {
        return this._bookmarksPopperOpened;
    }

    set bookmarksPopperOpened(opened: boolean) {
        this._bookmarksPopperOpened = opened;
    }

    private _sharesPopperOpened: boolean = false;

    get sharesPopperOpened(): boolean {
        return this._sharesPopperOpened;
    }

    set sharesPopperOpened(opened: boolean) {
        this._sharesPopperOpened = opened;
    }

    private _shareResultDialogOpened: boolean = false;

    get shareResultDialogOpened(): boolean {
        return this._shareResultDialogOpened;
    }

    set shareResultDialogOpened(opened: boolean) {
        this._shareResultDialogOpened = opened;
    }

    private _shareResultCompareBy: ResultsFilter[] = [];

    get shareResultCompareBy() {
        return this._shareResultCompareBy;
    }

    set shareResultCompareBy(compareBy) {
        this._shareResultCompareBy = compareBy;
    }

    private _shareResultFilters: ResultsFilter[] = [];

    get shareResultFilters() {
        return this._shareResultFilters;
    }

    set shareResultFilters(filters: ResultsFilter[]) {
        this._shareResultFilters = filters;
    }

    private _shareResultName: string = "";

    get shareResultsName() {
        return this._shareResultName;
    }

    set shareResultsName(name: string) {
        this._shareResultName = name;
    }

    private _categoriesQuestionsDisplayModeToggle: ResultsDisplayView =
        "CATEGORIES";

    get categoriesQuestionsDisplayModeToggle() {
        return this._categoriesQuestionsDisplayModeToggle;
    }

    set categoriesQuestionsDisplayModeToggle(state: ResultsDisplayView) {
        this._categoriesQuestionsDisplayModeToggle = state;
    }

    private _displayTypeModeToggle: ResultsDisplayType = "grid";

    get displayTypeModeToggle() {
        return this._displayTypeModeToggle;
    }

    set displayTypeModeToggle(state: ResultsDisplayType) {
        this._displayTypeModeToggle = state;
    }

    public clearResultsTableQuestions = () => {
        this._resultsTableQuestions = {};
    };

    private _isDatePickerOpenEventAlerts = false;
    get isDatePickerOpenEventAlerts() {
        return this._isDatePickerOpenEventAlerts;
    }
    set isDatePickerOpenEventAlerts(isOpen: boolean) {
        this._isDatePickerOpenEventAlerts = isOpen;
    }

    private _datePickerShowActionsMenu = false;
    get datePickerShowActionsMenu() {
        return this._datePickerShowActionsMenu;
    }
    set datePickerShowActionsMenu(show: boolean) {
        this._datePickerShowActionsMenu = show;
    }

    private _datePickerEventTitle = "";
    get datePickerEventTitle() {
        return this._datePickerEventTitle;
    }
    set datePickerEventTitle(title: string) {
        this._datePickerEventTitle = title;
    }

    private _isDatePickerTitleValid = false;
    get isDatePickerTitleValid() {
        return this._isDatePickerTitleValid;
    }
    set isDatePickerTitleValid(isValid: boolean) {
        this._isDatePickerTitleValid = isValid;
    }

    private _isCurrentDateValid = false;
    get isCurrentDateValid() {
        return this._isCurrentDateValid;
    }
    set isCurrentDateValid(isValid: boolean) {
        this._isCurrentDateValid = isValid;
    }

    private _isDatePickerDisplayValuesInitialized = false;
    get isDatePickerDisplayValuesInitialized() {
        return this._isDatePickerDisplayValuesInitialized;
    }
    set isDatePickerDisplayValuesInitialized(isInitialized: boolean) {
        this._isDatePickerDisplayValuesInitialized = isInitialized;
    }

    public clearStore = () => {
        this._resultsTableQuestions = {};
    };
}

export const resultsDisplayStore = new ResultsDisplayStore();
