import { TIME_FILTER_ID } from "@difftone/constants";
import {
    SharedResult,
    SurveyClasses,
    ResultsFilter,
    UUID,
    TimeCompareUnits,
} from "@difftone/types";
import { makeAutoObservable } from "mobx";

class SharedResultsDisplayStore {
    constructor() {
        makeAutoObservable(this);
    }

    public selectedSharedResults: SharedResult | undefined = undefined;

    public selectedSurvey: SurveyClasses | null = null;

    private _activeFilters: { [filterUuid: UUID]: boolean[] } = {};

    public getActiveFilters = (filterUuid: UUID) => {
        return this._activeFilters[filterUuid];
    };

    public setActiveFilters = (filterUuid: UUID, newValues: boolean[]) => {
        this._activeFilters[filterUuid] = newValues;
    };

    public initFilterValues = (selectedFilters: ResultsFilter[]) => {
        selectedFilters.map((selectedFilter) => {
            this._activeFilters[selectedFilter.filterKey.uuid] =
                selectedFilter.filterValues.map((filterValue) => true);
        });
    };

    //selectedFilters
    private _selectedFilters: ResultsFilter[] | undefined = undefined;
    public getSelectedFilters = () => {
        if (this._selectedFilters === undefined) {
            if (!this.selectedSharedResults) {
                throw Error("Could not find selected shared results");
            }

            this._selectedFilters = this.selectedSharedResults.filters;
        }
        if (!Object.keys(this._activeFilters).length) {
            this.initFilterValues(this._selectedFilters);
        }
        return this._selectedFilters;
    };

    private _activeAsComparesFiltersUUIDs: UUID[] | undefined = undefined;
    public getActiveAsComparesFiltersUUIDs = () => {
        if (this._activeAsComparesFiltersUUIDs === undefined) {
            if (!this.selectedSharedResults) {
                throw Error("Could not find selected shared results");
            }

            this._activeAsComparesFiltersUUIDs =
                this.selectedSharedResults.compare_by;
        }
        return this._activeAsComparesFiltersUUIDs;
    };

    public addActiveAsComparesFiltersUUIDs = (filterUuid: UUID) => {
        this._activeAsComparesFiltersUUIDs?.push(filterUuid);
    };

    public removeActiveCompareFilterFromFilterUUIDs = (filterUuid: UUID) => {
        this._activeAsComparesFiltersUUIDs =
            this._activeAsComparesFiltersUUIDs?.filter(
                (_filterUuid) => _filterUuid !== filterUuid
            );
    };

    public setSelectedFilters = (newResultsFilters: ResultsFilter[]) => {
        this._selectedFilters = newResultsFilters;
    };

    public getFiltersActiveAsCompares = () => {
        return this.getSelectedFilters()
            .filter((filter) =>
                this.getActiveAsComparesFiltersUUIDs().includes(
                    filter.filterKey.uuid
                )
            )
            .sort(
                (filterA, filterB) =>
                    filterA.indexInComparePlain - filterB.indexInComparePlain
            );
    };

    public getFiltersNotActiveAsCompares = () => {
        return this.getSelectedFilters().filter(
            (filter) =>
                !this.getActiveAsComparesFiltersUUIDs().includes(
                    filter.filterKey.uuid
                )
        );
    };

    public updateSelectedFilters = (newFilter: ResultsFilter) => {
        const _filteredFilters = this.getSelectedFilters().filter(
            (filter) => filter.filterKey.uuid !== newFilter.filterKey.uuid
        );
        this._selectedFilters = [..._filteredFilters, newFilter];
    };

    private _selectedSharedResultsTimeCompare: TimeCompareUnits = "NONE";
    public getSelectedSharedResultsTimeCompare = () => {
        const timeFilterFromSelectedFilters = this.getSelectedFilters().find(
            (filter) => filter.filterKey.uuid === TIME_FILTER_ID
        );

        if (
            timeFilterFromSelectedFilters &&
            timeFilterFromSelectedFilters.unit !== "NONE"
        ) {
            this.setSelectedSharedResultsTimeCompare(
                timeFilterFromSelectedFilters.unit!
            );
        }
        return this._selectedSharedResultsTimeCompare;
    };

    public setSelectedSharedResultsTimeCompare = (
        newTimeCompare: TimeCompareUnits
    ) => {
        this._selectedSharedResultsTimeCompare = newTimeCompare;
    };

    private _popupDisplaySharedResultsTimeCompare: TimeCompareUnits = "NONE";

    public getPopupDisplaySharedResultsTimeCompare = () => {
        return this._popupDisplaySharedResultsTimeCompare;
    };

    public setPopupDisplaySharedResultsTimeCompare = (
        newTimeCompare: TimeCompareUnits
    ) => {
        this._popupDisplaySharedResultsTimeCompare = newTimeCompare;
    };

    public clearStore = () => {
        this.selectedSharedResults = undefined;
        this._activeAsComparesFiltersUUIDs = undefined;
        this._selectedFilters = undefined;
        this.selectedSurvey = null;
        this._activeFilters = {};
        this.setPopupDisplaySharedResultsTimeCompare("NONE");
        this.setSelectedSharedResultsTimeCompare("NONE");
    };
}

export const sharedResultsDisplayStore = new SharedResultsDisplayStore();
