import React from "react";
import { observer } from "mobx-react";
import { cloudsAlertStore } from "@difftone/stores";
import { closeAlertWithClouds } from "@difftone/actions";
import successIcon from "@difftone/assets/survey-sent-ok.svg";
import { AddBoxShadow } from "@difftone/shared-components";

import {
    wrapper,
    popup,
    messsage_section,
    ok_button,
} from "./clouds-success-alert.module.css";

export type CloudsSuccessAlertProps = {};

export const CloudsSuccessAlert = observer(() => {
    const { message, isVisible } = cloudsAlertStore;

    return isVisible ? (
        <div className={wrapper}>
            <AddBoxShadow>
                <div className={popup}>
                    <img src={successIcon} alt="ok-icon" />
                    <div className={messsage_section}>
                        {message || "Success!"}
                    </div>
                    <button
                        autoFocus
                        className={ok_button}
                        onClick={closeAlertWithClouds}
                    >
                        OK
                    </button>
                </div>
            </AddBoxShadow>
        </div>
    ) : null;
});
