import { action } from "mobx";
import { EmailAddress, SharedSurvey, UUID } from "@difftone/types";
import {
    groupEmailsStore,
    sharedSurveyStore,
    SortingSharedSurveyProfile,
} from "@difftone/stores";
import { getUserEmail } from "@difftone/procedures";
import {
    getSelectedOrganizationOrThrow,
    getUserPermissionsType,
    isEmailFromOrganisation,
} from "@difftone/reducers";
import { showDifftoneAlert } from "../difftone-alert-actions";
import {
    getDynamicAlertVariantForOrganizationEmailValidation,
    warningMessages,
} from "@difftone/constants";

export const removeDeletedSharedSurveysFromStore = action(() => {
    sharedSurveyStore.removeDeletedSharedSurvey();
});

export const deleteSharedSurveyFromStoreByUUid = action(
    (sharedSurveyUUID: UUID) => {
        sharedSurveyStore.removeSharedSurveyFromStore(sharedSurveyUUID);
    }
);

export const updateToDeleteSharedSurvey = action(
    (sharedSurvey: SharedSurvey) => {
        const copySharedSurvey: SharedSurvey = JSON.parse(
            JSON.stringify(sharedSurvey)
        );
        copySharedSurvey.deleted = true;

        sharedSurveyStore.addSharedSurvey([copySharedSurvey]);
    }
);

export const updateToArchiveSharedSurvey = action(
    (sharedSurvey: SharedSurvey) => {
        const copySharedSurvey: SharedSurvey = JSON.parse(
            JSON.stringify(sharedSurvey)
        );
        copySharedSurvey.archive = true;

        sharedSurveyStore.addSharedSurvey([copySharedSurvey]);
    }
);

export const setSharedSurveySortingProfile = action(
    (newSharedSurveySortingProfile: SortingSharedSurveyProfile) => {
        sharedSurveyStore.sortingSharedSurveyProfile =
            newSharedSurveySortingProfile;
    }
);

export const handleMultipleAddresseesChange = action(
    (emails: EmailAddress[], sharedSurvey: SharedSurvey) => {
        const organizationEmails: EmailAddress[] = [];
        const notOrganizationEmails: EmailAddress[] = [];
        const userEmail = getUserEmail();
        const userType = getUserPermissionsType();

        emails.forEach((email) => {
            if (!isEmailFromOrganisation(email)) {
                notOrganizationEmails.push(email);
                return;
            }

            if (email === userEmail) {
                showDifftoneAlert(
                    warningMessages.surveyCannotShareInitiator,
                    "FAILURE"
                );
                return;
            }

            if (
                userType === "DEFAULT" ||
                getUserPermissionsType() === "DEFAULT"
            ) {
                showDifftoneAlert(
                    `"Surveys cannot be shared with non-initiators (${email}). Please contact _systemadmin_"`,
                    "FAILURE"
                );
                return;
            }

            organizationEmails.push(email);
        });

        if (notOrganizationEmails.length) {
            const organization = getSelectedOrganizationOrThrow();
            const organizationName = organization ? organization.name : "";

            showDifftoneAlert(
                getDynamicAlertVariantForOrganizationEmailValidation(
                    organizationName,
                    notOrganizationEmails
                ),
                "FAILURE"
            );
        }

        organizationEmails.forEach((email) => {
            const resolvedEmails = groupEmailsStore.getResolvedEmails(
                email,
                getSelectedOrganizationOrThrow()!
            );
            switch (resolvedEmails) {
                case undefined:
                    break;
                case null:
                    //TODO: refine and make const
                    showDifftoneAlert("Could not resolve email", "FAILURE");
                    break;
                default:
                    sharedSurveyStore.addEmailsToSharedSurvey(
                        [email],
                        sharedSurvey.uuid
                    );
                    break;
            }
        });
    }
);
