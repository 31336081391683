import { action } from "mobx";
import { SharedSurvey } from "@difftone/types";
import {
    getAllTemplatesFromApi,
    updateTemplateToApi,
    getTagByUUID,
} from "@difftone/services";
import { templatesStore } from "./templates-store";
import { organizationDisplayStore } from "../organization-store";

export const showPermissionsDialog = action(() => {
    organizationDisplayStore.isPermissionsDialogOpened = true;
});

export const closePermissionsDialog = action(() => {
    organizationDisplayStore.isPermissionsDialogOpened = false;
});

export const templateToMapSetterFromApi = action(
    (templates: SharedSurvey[]) => {
        templates.forEach((template) => {
            templatesStore.setTemplateToMap(template.uuid, template);
        });
    }
);

export const fillTagsFromTemplates = action(
    async (templates: SharedSurvey[]) => {
        const surveys = templates.map(({ survey }) => survey);
        const tagsArrays = surveys.map(({ tags }) => tags);
        const tags =
            tagsArrays.reduce((startItem = [], nextItem) =>
                nextItem ? [...startItem].concat(nextItem) : startItem
            ) || [];

        for (const tagUUID of tags) {
            if (!templatesStore.getTagByUUID(tagUUID)) {
                const tag = await getTagByUUID(tagUUID);

                if (tag) {
                    templatesStore.setTagToMap(tag.uuid, tag);
                }
            }
        }
    }
);

export const getAllTemplates = action(async () => {
    try {
        const templates = await getAllTemplatesFromApi();

        await fillTagsFromTemplates(templates);
        templateToMapSetterFromApi(templates);

        templatesStore.storeInitialize = true;
    } catch (error) {
        templatesStore.storeInitialize = true;
        console.error(`Something went wrong with fetching data. ${error}`);
        throw error;
    }
});

export const upsertTemplateToApi = async (template: SharedSurvey) => {
    try {
        if (!template) {
            return;
        }

        const _template = Object.assign({}, template);

        const newUpsertedTemplate = await updateTemplateToApi(_template);

        return newUpsertedTemplate;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
