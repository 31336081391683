import React, { useEffect, useState } from "react";
import clsx from "clsx";

import { Domain, Organization } from "@difftone/types";

import {
    list_domains,
    header_domains,
    row_domain_scope,
    blue_background,
    white_background,
    gray_background,
} from "./list-domains.module.css";

type Color = string;

export type ListDomainsProps = {
    domains: Domain[];
    selectOrganization: Organization | null;
    onSelectDomains: (domain: Domain[]) => void;
};

export const ListDomains = (props: ListDomainsProps) => {
    const { domains, onSelectDomains, selectOrganization } = props;
    const [domainsSelected, setDomainsSelected] = useState<Domain[]>([]);
    const rowBackground: string = white_background;

    useEffect(() => {
        setDomainsSelected([]);
    }, [selectOrganization]);

    const handleSelectDomain = (domain: Domain) => {
        let _domainsSelected = [...domainsSelected];
        const index = _domainsSelected.findIndex((d) => d.name === domain.name);
        if (index === -1) {
            _domainsSelected.push(domain);
        } else {
            _domainsSelected = _domainsSelected.filter(
                (dom) => dom.name !== domain.name
            );
        }
        setDomainsSelected(_domainsSelected);
        onSelectDomains(_domainsSelected);
    };

    const handleIsIncludesInDomainsSelected = (name: string): Color => {
        const findDomain = domainsSelected.find(
            (domain) => domain.name === name
        );
        return findDomain ? blue_background : rowBackground;
    };

    return (
        <div>
            <div className={header_domains}>Domains list </div>
            <div className={list_domains}>
                {domains.map((domain) => {
                    let idIncludeDomainUuid: boolean = false;
                    if (selectOrganization) {
                        idIncludeDomainUuid =
                            selectOrganization.domains.includes(domain.uuid);
                    }

                    return (
                        <div
                            key={domain.uuid}
                            className={clsx(
                                row_domain_scope,
                                idIncludeDomainUuid
                                    ? gray_background
                                    : handleIsIncludesInDomainsSelected(
                                          domain.name
                                      )
                            )}
                            onClick={() =>
                                !idIncludeDomainUuid &&
                                handleSelectDomain(domain)
                            }
                        >
                            <div>{domain.name}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
