import { getApiBaseUrl } from "@difftone/constants";
import { errorHandlerProxy, localstorageUtils } from "@difftone/procedures";
import { createDifftoneAxios } from "@difftone/services";
import { Organization } from "@difftone/types";

const API_URL = `${getApiBaseUrl()}/internal-features/organizations`;

export const getAllOrganizationsForDifftone = async (): Promise<
    Organization[]
> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(API_URL, {
        params: {
            issuer: currentAuthData.issuer,
        },
    });

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    return response.data.resources;
};

export const upsertOrganizationForDifftone = async (
    organization: Organization
): Promise<Organization[]> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.post(
        API_URL,
        { new_organization: organization },
        {
            params: {
                issuer: currentAuthData.issuer,
            },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    return response.data.results;
};
