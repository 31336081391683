import { dashboardDisplayStore } from "@difftone/stores";
import { getDateAtMidnightFromDateString } from "@difftone/time-utils";

export type ErrorCodeInitiatorList = "NUM_OF_INITIATORS_VALIDATION";

export type ErrorCodeDates = "END_DATE_VALIDATION";

export type ErrorCodeDashboard = ErrorCodeInitiatorList | ErrorCodeDates;

export type ValidationResultsDashboard = {
    is_valid: boolean;
    validation_error_code: ErrorCodeDashboard;
    validation_error_type?: Record<string, string>;
};

export const isDashboardFilterStatusReady = () =>
    dashboardDisplayStore.filtersStatusMode === "READY_MODE";

export const validateInitiatorList = (): ValidationResultsDashboard[] => {
    const { dashboardDraftFilter } = dashboardDisplayStore;

    if (dashboardDraftFilter) {
        const initiatorsList = dashboardDraftFilter.initiators;

        if (!initiatorsList.length) {
            return [
                {
                    is_valid: false,
                    validation_error_code: "NUM_OF_INITIATORS_VALIDATION",
                },
            ];
        }
    }

    return [
        {
            is_valid: true,
            validation_error_code: "NUM_OF_INITIATORS_VALIDATION",
        },
    ];
};

export const validateDashboardEndDate = (): ValidationResultsDashboard[] => {
    const { dashboardDraftFilter } = dashboardDisplayStore;

    const res: number[] = [];

    if (dashboardDraftFilter) {
        const startDate = getDateAtMidnightFromDateString(
            dashboardDraftFilter.startDate
        );
        const endDate = getDateAtMidnightFromDateString(
            dashboardDraftFilter.endDate
        );

        if (endDate <= startDate) {
            res.push(endDate);
        }
    }

    return [
        { is_valid: !res.length, validation_error_code: "END_DATE_VALIDATION" },
    ];
};

export const validateDashboardFilter = (): ValidationResultsDashboard[] => {
    const res: ValidationResultsDashboard[] = [];

    res.push(...validateInitiatorList(), ...validateDashboardEndDate());

    return res;
};
