import { cloudsAlertStore } from "@difftone/stores";
import { action } from "mobx";

export const openSuccessAlertWithClouds = action((message: string) => {
    cloudsAlertStore.isVisible = true;
    cloudsAlertStore.message = message;
});

export const closeAlertWithClouds = action(() => {
    cloudsAlertStore.isVisible = false;
    cloudsAlertStore.message = "";
});
