import React, { ChangeEvent } from "react";
import { observer } from "mobx-react";
import { Question, QuestionType, SingleChoiceQuestion } from "@difftone/types";
import {
    options_list_item,
    checked_choice_style,
    custom_checkbox_class,
} from "./options-list-item.module.css";

export type OptionsListItemProps = {
    isActive: boolean;
    index: number;
    question: Question<QuestionType>;
    toggleIsActive: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const OptionsListItem = observer((props: OptionsListItemProps) => {
    const { index, question, toggleIsActive, isActive } = props;

    const toggleActiveState = (event: ChangeEvent<HTMLInputElement>) => {
        toggleIsActive(event);
    };

    const castedQuestion = question as Question<SingleChoiceQuestion>;

    const optionValue = castedQuestion.content.choice_options[index].label;

    return (
        <div className={options_list_item}>
            <input
                data-id={optionValue}
                id={optionValue}
                type="checkbox"
                checked={isActive}
                onChange={toggleActiveState}
            />
            <label
                className={
                    isActive ? checked_choice_style : custom_checkbox_class
                }
                htmlFor={optionValue}
            ></label>
            <label htmlFor={optionValue}>{optionValue}</label>
        </div>
    );
});
