import React from "react";
import { observer } from "mobx-react";

import {
    QuestionToAnswer,
    QuestionToAnswerDescreteQuestion,
    QuestionTypeName,
    SurveyClasses,
} from "@difftone/types";
import { surveyResponsesSummaryStore } from "@difftone/stores";
import { showDifftoneAlert } from "@difftone/actions";
import { COLUMN_0, DATA_ANONYMIZED } from "@difftone/common-constants";
import { ShowDifftoneTooltip, Spinner } from "@difftone/shared-components";
import { DetailedOptionResultBox } from "./components";

import {
    detailed_question_option,
    detailed_option_wrapper,
    detailed_avg,
    detailed_question_option_label,
} from "./detailed-question-option.module.css";

export type DetailedQuestionOptionProps = {
    answerValue: string;
    answerLabel: string;
    answersFrequency: QuestionToAnswer | null;
    columnKey: string;
    questionType: QuestionTypeName;
    survey: SurveyClasses;
};

const ALLOWED_LABEL_LENGTH = 30;

export const DetailedQuestionOption = observer(
    (props: DetailedQuestionOptionProps) => {
        const {
            answerValue,
            answerLabel,
            answersFrequency,
            columnKey,
            questionType,
            survey,
        } = props;

        const surveyResponses =
            surveyResponsesSummaryStore.getSurveyResponseSummaryByFilterPlain(
                survey.uuid,
                []
            );

        if (!answersFrequency) {
            return null;
        }

        if (surveyResponses === undefined) {
            return <Spinner size="MEDIUM" />;
        }

        if (surveyResponses === null) {
            showDifftoneAlert("Could not find Responses", "FAILURE");
            return null;
        }

        let populationSize = null;
        let frequency = null;

        switch (questionType) {
            case "SHORT_TEXT":
            case "LONG_TEXT":
            case "DATE":
            case "TIME":
                //Only in case of open questions calculate no response population and total frequency of no response
                populationSize = surveyResponses.public_survey_responses.filter(
                    (response) => response.is_submit
                ).length;
                frequency = answersFrequency.length;
                break;
            default:
                populationSize = Object.values(
                    answersFrequency as QuestionToAnswer
                ).reduce((a, b) => a + b, 0);
                frequency = (
                    answersFrequency as QuestionToAnswerDescreteQuestion
                )[answerValue];
                break;
        }

        const setLabelLengthValue = (label: string) => {
            return label.slice(0, ALLOWED_LABEL_LENGTH).concat("...");
        };

        let label = null;

        if (answerLabel.length > ALLOWED_LABEL_LENGTH) {
            label = (
                <ShowDifftoneTooltip tip={answerLabel} tooltipPosition="left">
                    <div className={detailed_question_option_label}>
                        {setLabelLengthValue(answerLabel)}
                    </div>
                </ShowDifftoneTooltip>
            );
        } else {
            label = (
                <div className={detailed_question_option_label}>
                    {answerLabel}
                </div>
            );
        }

        const numberOfAnsweredResponses =
            surveyResponses.public_survey_responses.filter(
                (response) => response.is_submit
            ).length;

        const isAnonymous = answersFrequency === DATA_ANONYMIZED;

        return (
            <div className={detailed_option_wrapper}>
                {columnKey === COLUMN_0 ? (
                    <div className={detailed_avg}>
                        <div className={detailed_question_option}>
                            <div>{label}</div>
                        </div>
                        <DetailedOptionResultBox
                            totalResponseSummary={numberOfAnsweredResponses}
                            columnKey={columnKey}
                            questionType={questionType}
                            frequency={frequency}
                            populationSize={populationSize}
                            isAnonymous={isAnonymous}
                            surveyAnonymityLevel={survey.anonimity}
                            color="primary"
                        />
                    </div>
                ) : (
                    <DetailedOptionResultBox
                        totalResponseSummary={numberOfAnsweredResponses}
                        columnKey={columnKey}
                        questionType={questionType}
                        frequency={frequency}
                        populationSize={populationSize}
                        isAnonymous={isAnonymous}
                        surveyAnonymityLevel={survey.anonimity}
                        color="secondary"
                    />
                )}
            </div>
        );
    }
);
