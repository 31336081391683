import React from "react";
import { observer } from "mobx-react";

import {
    loginStore,
    navigationStore,
    organizationStore,
} from "@difftone/stores";
import {
    isLoggedIn,
    logoutFromGoogle,
    logoutFromMicrosoft,
    setIsOrganizationUser,
    setLocation,
} from "@difftone/actions";
import { SurveyAutoFill, OrganizationsDomains } from "@difftone/features";
import { getUserEmail } from "@difftone/procedures";
import { INBOX_URL, OPEN_SURVEY } from "@difftone/constants";

import { Spinner, UserNotExistDialog } from "@difftone/shared-components";
import {
    Inbox,
    SurveyWizard,
    Results,
    Home,
    Dashboard,
    FillSurvey,
    SharedResults,
} from "./components";
import { AuthData, Organization } from "@difftone/types";
import { OrganizationSelectionDialog } from "@difftone/shared-components";

export const CurrentPage = observer(() => {
    const { currentPage, currentSearch } = navigationStore;

    if (currentPage === "/") {
        if (isLoggedIn()) {
            setLocation("/inbox/all");
        } else {
            setLocation("/home");
        }
        return null;
    }

    if (currentPage.includes(OPEN_SURVEY)) {
        return <FillSurvey isOpenSurvey={true} />;
    }

    if (!isLoggedIn() && !currentPage.startsWith("/home")) {
        setLocation(`/home/login?ref=${currentPage + (currentSearch || "")}`);
        return null;
    }

    if (!loginStore.gapiModulesInitialized) {
        loginStore.initializeGapiModules();
        return <Spinner />;
    }

    if (!loginStore.isInitialized && !currentPage.startsWith("/home")) {
        if (!loginStore.startInitialize) loginStore.init();
        return <Spinner />;
    }

    const selectedOrganization =
        organizationStore.getSelectedOrganizationUUID();

    if (!currentPage.startsWith("/home") && !selectedOrganization) {
        const organizationsList =
            organizationStore.getOrganizationsListAsArray();

        if (organizationsList === null) {
            const authDataAsString = localStorage.getItem("authData");
            setIsOrganizationUser(false);
            if (authDataAsString) {
                const authData: AuthData = JSON.parse(authDataAsString);
                if (authData.issuer === "MICROSOFT") {
                    logoutFromMicrosoft();
                } else if (authData.issuer === "GOOGLE") {
                    logoutFromGoogle();
                }
            }
            return <UserNotExistDialog />;
        }

        if (!organizationsList.length) {
            return <Spinner />;
        }

        if (!selectedOrganization) {
            return (
                <>
                    <OrganizationSelectionDialog
                        open={!selectedOrganization}
                        organizationsList={organizationsList as Organization[]}
                    />
                    <Spinner />
                </>
            );
        }
    }

    if (currentPage.startsWith("/home")) {
        loginStore.clearStore();
    }

    switch (currentPage) {
        case `${
            currentPage.startsWith("/internal-features/survey-autofill")
                ? currentPage
                : null
        }`:
            if (getUserEmail().includes("difftone.com")) {
                return <SurveyAutoFill />;
            }
            setLocation(INBOX_URL);
            return null;

        case `${
            currentPage.startsWith("/internal-features/organizations-domains")
                ? currentPage
                : null
        }`:
            if (getUserEmail().includes("difftone.com")) {
                return <OrganizationsDomains />;
            }
            setLocation(INBOX_URL);
            return null;

        case `${currentPage.startsWith("/home") ? currentPage : null}`:
            return <Home />;
        case `${currentPage.startsWith(INBOX_URL) ? currentPage : null}`:
            return <Inbox />;
        case `${currentPage.startsWith("/survey-wizard") ? currentPage : null}`:
            return <SurveyWizard />;
        case `${currentPage.startsWith("/survey") ? currentPage : null}`:
            return <FillSurvey />;
        case `${currentPage.startsWith("/results") ? currentPage : null}`:
            return <Results />;
        case `${
            currentPage.startsWith("/shared-results") ? currentPage : null
        }`:
            return <SharedResults />;
        case `${currentPage.startsWith("/dashboard") ? currentPage : null}`:
            return <Dashboard />;

        default:
            setLocation(INBOX_URL);
            return <Home />;
    }
});
