import React from "react";
import { createRoot } from "react-dom/client";
import { init } from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { hotjar } from "react-hotjar";
import { navigationStore } from "@difftone/stores";
import { environment, getVersion } from "@difftone/procedures";
import { dsn, getSegmentId } from "@difftone/constants";
import { App } from "./components";
import "./index.css";

const ENV = environment();
const container = document.getElementById("root");

if (ENV) {
    init({
        dsn,
        integrations: [new Integrations.BrowserTracing()],
        environment: ENV,
        release: getVersion(),
        tracesSampleRate: 1.0,
    });

    const hjid = 2422274;
    const hjsv = 6;
    hotjar.initialize(hjid, hjsv);
}

const loadSegment = () => {
    //@ts-ignore
    const analytics: any = (window.analytics = window.analytics || []);
    //@ts-ignore
    if (!analytics.initialize)
        if (analytics.invoked)
            //@ts-ignore
            window.console &&
                console.error &&
                console.error("Segment snippet included twice.");
        else {
            //@ts-ignore
            analytics.invoked = !0;
            //@ts-ignore
            analytics.methods = [
                "trackSubmit",
                "trackClick",
                "trackLink",
                "trackForm",
                "pageview",
                "identify",
                "reset",
                "group",
                "track",
                "ready",
                "alias",
                "debug",
                "page",
                "once",
                "off",
                "on",
                "addSourceMiddleware",
                "addIntegrationMiddleware",
                "setAnonymousId",
                "addDestinationMiddleware",
            ];
            //@ts-ignore
            analytics.factory = function (e, ...args) {
                return function () {
                    //@ts-ignore
                    args.unshift(e);
                    //@ts-ignore
                    analytics.push(args);
                    return analytics;
                };
            };
            for (let e = 0; e < analytics.methods.length; e++) {
                const key = analytics.methods[e];
                analytics[key] = analytics.factory(key);
            }
            //@ts-ignore
            analytics.load = function (key, e) {
                const t = document.createElement("script");
                t.type = "text/javascript";
                t.async = !0;
                t.src =
                    "https://cdn.segment.com/analytics.js/v1/" +
                    key +
                    "/analytics.min.js";
                const n = document.getElementsByTagName("script")[0];
                //@ts-ignore
                n.parentNode.insertBefore(t, n);
                analytics._loadOptions = e;
            };
            analytics._writeKey = getSegmentId();
            analytics.SNIPPET_VERSION = "4.13.2";
            analytics.load(getSegmentId());
            analytics.page();
        }
};

loadSegment();

navigationStore.init();

const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
