import { action } from "mobx";
import { organizationStore } from "@difftone/stores";
import {
    getUserOrganizations,
    getDomainsByUuidsFromApi,
    updateOrganisationSettingsInternalService,
    getPublicDomainsFromApi,
} from "@difftone/services";
import {
    Domain,
    Organization,
    OrganizationSettings,
    UUID,
} from "@difftone/types";

export const fetchUserOrganizations = action(async () => {
    try {
        const organizations = await getUserOrganizations();
        organizations.forEach((organization) => {
            organizationStore.setOrganizationToMap(
                organization.uuid,
                organization
            );
        });

        const previouslySelectedOrganization = localStorage.getItem(
            "selectedOrganization"
        );

        if (
            previouslySelectedOrganization &&
            organizations.find(
                (org) => org.uuid === previouslySelectedOrganization
            )
        ) {
            organizationStore.setSelectedOrganizationUUID(
                previouslySelectedOrganization
            );
        } else {
            localStorage.removeItem("selectedOrganization");
        }

        if (organizations.length === 1) {
            organizationStore.setSelectedOrganizationUUID(
                organizations[0].uuid
            );
        }
        organizationStore.setStoreInitialized(true);
    } catch (error) {
        throw new Error(`Error while fetching user organizations: ${error}`);
    }
});

export const fetchDomains = action(async (organisation: Organization) => {
    // get public domains
    const publicDomains = await getPublicDomainsFromApi();
    const publicDomainsData = publicDomains.data.resources.map(
        (item: { data: Domain }) => item.data
    );
    organizationStore.publicDomains = publicDomainsData;
    if (organisation) {
        const domains = await getDomainsByUuidsFromApi(organisation.domains);
        organizationStore.domains = domains;
    }
});

export const updateOrganisationSettingsToApi = action(
    (settings: OrganizationSettings, organizationUuid: UUID) => {
        updateOrganisationSettingsInternalService(settings, organizationUuid);
    }
);
