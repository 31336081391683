import { action } from "mobx";
import {
    ALLOWED_ATTRIBUTE_TITLE_LENGTH,
    resultsDisplayStore,
} from "@difftone/stores";
import {
    ResultsFilter,
    ResultsTableDisplayQuestion,
    UUID,
    ResultsDisplayView,
    ResultsDisplayType,
} from "@difftone/types";

export const changeResultsDisplayState = action((state: ResultsDisplayView) => {
    closeAllQuestions();
    resultsDisplayStore.categoriesQuestionsDisplayModeToggle = state;
});

export const changeResultsDisplayType = action((state: ResultsDisplayType) => {
    resultsDisplayStore.displayTypeModeToggle = state;
});

export const ensureLengthOfValue = (headerTitle: string) => {
    if (headerTitle.length > ALLOWED_ATTRIBUTE_TITLE_LENGTH) {
        return headerTitle
            .slice(0, ALLOWED_ATTRIBUTE_TITLE_LENGTH)
            .concat("...");
    }
    return headerTitle;
};

export const addQuestionToResultsTable = action(
    (questionToDisplay: ResultsTableDisplayQuestion) => {
        const resultsTableQuestionsCopy: {
            [x: string]: ResultsTableDisplayQuestion;
        } = JSON.parse(
            JSON.stringify(resultsDisplayStore.resultsTableQuestions)
        );

        resultsTableQuestionsCopy[questionToDisplay.questionUUID] =
            questionToDisplay;

        resultsDisplayStore.resultsTableQuestions = resultsTableQuestionsCopy;
    }
);

export const changeQuestionResultsTableIsOpen = action(
    (questionUUID: UUID, isOpen: boolean) => {
        const resultsTableQuestionsCopy: {
            [x: string]: ResultsTableDisplayQuestion;
        } = JSON.parse(
            JSON.stringify(resultsDisplayStore.resultsTableQuestions)
        );

        resultsTableQuestionsCopy[questionUUID].isOpen = isOpen;
        resultsDisplayStore.resultsTableQuestions = resultsTableQuestionsCopy;
    }
);

export const setOpenedQuestionUUID = action((questionUUID: UUID | null) => {
    resultsDisplayStore.openedSelectedTextQuestion = questionUUID;
});

export const clearResultsTableQuestions = action(() => {
    resultsDisplayStore.clearResultsTableQuestions();
});

export const closeAllQuestions = action(() => {
    const resultsTableQuestionsCopy: {
        [x: string]: ResultsTableDisplayQuestion;
    } = JSON.parse(JSON.stringify(resultsDisplayStore.resultsTableQuestions));

    Object.keys(resultsTableQuestionsCopy).forEach((key) => {
        resultsTableQuestionsCopy[key].isOpen = false;
    });

    resultsDisplayStore.resultsTableQuestions = resultsTableQuestionsCopy;
});

export const toggleActionsPopper = action((value?: boolean) => {
    if (value !== undefined) {
        resultsDisplayStore.actionsPopperOpened = value;
        return;
    }

    resultsDisplayStore.actionsPopperOpened =
        !resultsDisplayStore.actionsPopperOpened;
});

export const toggleBookmarksPopper = action((value?: boolean) => {
    if (value !== undefined) {
        resultsDisplayStore.bookmarksPopperOpened = value;
        return;
    }

    resultsDisplayStore.bookmarksPopperOpened =
        !resultsDisplayStore.bookmarksPopperOpened;
});

export const toggleSharesPopper = action(() => {
    resultsDisplayStore.sharesPopperOpened =
        !resultsDisplayStore.sharesPopperOpened;
});

export const setShareResultName = action((name: string) => {
    resultsDisplayStore.shareResultsName = name;
});

export const setShareResultCompareBy = action((compareBy: ResultsFilter[]) => {
    resultsDisplayStore.shareResultCompareBy = compareBy;
});

export const toggleShareResultDialog = action(() => {
    resultsDisplayStore.shareResultDialogOpened =
        !resultsDisplayStore.shareResultDialogOpened;
});
