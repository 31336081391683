import React, { MouseEvent } from "react";
import { observer } from "mobx-react";
import {
    setIsFilterDialogOpen,
    setSimpleResultsFitlerProfile,
    setSimpleResultsSortingProfile,
} from "@difftone/actions";
import {
    SimpleResultsFilterProfile,
    SimpleResultsSortingProfile,
} from "@difftone/types";
import { simpleSurveyResultsStore } from "@difftone/stores";

import threeDotsIcon from "@difftone/assets/three-dots-icon.svg";
import filterIcon from "@difftone/assets/filter-icon.svg";

import { filter_button } from "./simple-results-filter-button.module.css";

export type SimpleResultsFilterButtonProps = {
    columnKey: string;
    isVisible: boolean;
    isFiltered: boolean;
};

export const SimpleResultsFilterButton = observer(
    (props: SimpleResultsFilterButtonProps) => {
        const { columnKey, isVisible, isFiltered } = props;
        const { filterProfile, sortingProfile } = simpleSurveyResultsStore;

        const onFilterIconClicked = (event: MouseEvent<HTMLDivElement>) => {
            const copyOfFilterProfile: SimpleResultsFilterProfile = {
                ...filterProfile,
            };
            const copyOfSortingProfile: SimpleResultsSortingProfile = {
                ...sortingProfile,
            };

            copyOfFilterProfile.filterdColumnKey = columnKey;

            if (filterProfile.filterdColumnKey !== columnKey) {
                copyOfSortingProfile.direction = "DESCENDING";
            }

            setSimpleResultsFitlerProfile(copyOfFilterProfile);
            setSimpleResultsSortingProfile(copyOfSortingProfile);

            setIsFilterDialogOpen(true, {
                offsetLeft: event.pageX,
                offsetTop: event.pageY,
            });
        };

        const renderdIcon = isFiltered ? (
            <img src={filterIcon} alt="filter" />
        ) : (
            <img src={threeDotsIcon} alt="filter" />
        );

        return (
            <div onClick={onFilterIconClicked} className={filter_button}>
                {isVisible || isFiltered ? renderdIcon : null}
            </div>
        );
    }
);
