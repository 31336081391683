import React from "react";
import { observer } from "mobx-react";
import { Question, QuestionType, ResultsFilter } from "@difftone/types";

import {
    filters_list_placeholder,
    first_filter,
} from "./filters-list-placeholder.module.css";

export type FiltersListPlaceholderProps = {
    question: Question<QuestionType>;
    displayFilters: ResultsFilter[];
};

export const FiltersListPlaceholder = observer(
    (props: FiltersListPlaceholderProps) => {
        const { question, displayFilters } = props;

        const selectedFilter = displayFilters.find(
            (filter) => filter.filterKey.uuid === question.uuid
        );

        if (!selectedFilter) {
            throw Error("[Difftone]: Could not find filter");
        }

        const selectedFilterValues = selectedFilter.filterValues as string[];

        return (
            <div className={filters_list_placeholder}>
                &nbsp;
                <div className={first_filter}>
                    {selectedFilterValues.length
                        ? selectedFilterValues[0]
                        : "err"}
                </div>
                &nbsp;
                {selectedFilterValues.length > 1 ? (
                    <span>+{selectedFilterValues.length - 1}</span>
                ) : null}
            </div>
        );
    }
);
