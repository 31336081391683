import { makeAutoObservable, computed, observable, ObservableMap } from "mobx";
import md5 from "md5";

import {
    NotSupportedQuestionResultsMapAfterDigestion,
    ResultsFilter,
    ResultsMap,
    SharedResult,
    SupportedQuestionResultsMapAfterDigestion,
    BaseSurvey,
    UUID,
} from "@difftone/types";
import { NO_ANSWER_KEY } from "@difftone/common-constants";
import { getResultsMapByRows } from "./results-map-internal-actions";

//TODO: move to types
export type ResultsTable = {
    [x: string]: SupportedQuestionResultsMapAfterDigestion;
};

export type ResultsMapOrNull = ResultsMap | null;

class ResultsMapStore {
    constructor() {
        makeAutoObservable<ResultsMapStore, "_fetchedResultsTableKeys">(this, {
            _fetchedResultsTableKeys: false,
        });
    }

    private _fetchedResultsTableKeys: UUID[] = [];

    //External-table
    private _resultsTablesMap: ObservableMap<UUID, ResultsTable | null> =
        observable.map({});

    public setResultsTableToMap(
        key: string,
        resultsTable: ResultsTable | null
    ) {
        this._resultsTablesMap.merge({ [key]: resultsTable });
    }

    //Internal columns
    private _resultsMaps: { [key: string]: ResultsMapOrNull } = {};
    get resultsMaps() {
        return this._resultsMaps;
    }
    set resultsMaps(newResultsMap: { [key: string]: ResultsMapOrNull }) {
        this._resultsMaps = newResultsMap;
    }

    public extractKeyForTable = (
        survey: BaseSurvey,
        filtersNotActiveAsCompares: ResultsFilter[],
        filtersActiveAsCompares: ResultsFilter[],
        sharedView?: SharedResult
    ): string => {
        const filtersNotActive = JSON.stringify(filtersNotActiveAsCompares);
        const filtersActive = JSON.stringify(filtersActiveAsCompares);
        const _resultsTableMapKey = md5(
            `${survey.uuid}-${filtersNotActive}-${filtersActive}`
        );

        if (
            this._resultsTablesMap.get(_resultsTableMapKey) === undefined &&
            !this._fetchedResultsTableKeys.includes(_resultsTableMapKey)
        ) {
            getResultsMapByRows(
                survey,
                filtersNotActiveAsCompares,
                filtersActiveAsCompares,
                _resultsTableMapKey,
                sharedView
            );
            this._fetchedResultsTableKeys.push(_resultsTableMapKey);
        }
        return _resultsTableMapKey;
    };

    public extractKeyForColumn = (
        surveyUUID: UUID,
        filtersNotActiveAsCompares: ResultsFilter[]
    ): string => {
        const filtersNotActive = JSON.stringify(filtersNotActiveAsCompares);
        return md5(`${surveyUUID}-${filtersNotActive}`);
    };

    public getResultsTableByResultsTableMapKey = (
        resultsTableMapKey: string
    ) => {
        const resultsTableMap = this._resultsTablesMap.get(resultsTableMapKey);
        if (
            resultsTableMap === undefined &&
            !this._fetchedResultsTableKeys.includes(resultsTableMapKey)
        ) {
            this._fetchedResultsTableKeys.push(resultsTableMapKey);
        }
        return resultsTableMap;
    };

    //Table cache
    public getResultsTableByFilterPlainNSurvey = (
        survey: BaseSurvey,
        filtersNotActiveAsCompares: ResultsFilter[],
        filtersActiveAsCompares: ResultsFilter[],
        sharedView?: SharedResult
    ) => {
        const resultsTableMapKey = this.extractKeyForTable(
            survey,
            filtersNotActiveAsCompares,
            filtersActiveAsCompares,
            sharedView
        );

        const resultsTableMap = this._resultsTablesMap.get(resultsTableMapKey);
        if (
            resultsTableMap === undefined &&
            !this._fetchedResultsTableKeys.includes(resultsTableMapKey)
        ) {
            getResultsMapByRows(
                survey,
                filtersNotActiveAsCompares,
                filtersActiveAsCompares,
                resultsTableMapKey,
                sharedView
            );
            this._fetchedResultsTableKeys.push(resultsTableMapKey);
        }
        return resultsTableMap;
    };

    public getResultsMapByRowsWithAnswersOnly(
        selectedSurvey: BaseSurvey,
        filtersNotActiveAsCompares: ResultsFilter[],
        filtersActiveAsCompares: ResultsFilter[],
        sharedView?: SharedResult
    ) {
        const resultsMapByRows = this.getResultsTableByFilterPlainNSurvey(
            selectedSurvey,
            filtersNotActiveAsCompares,
            filtersActiveAsCompares,
            sharedView
        );

        if (resultsMapByRows === undefined) {
            return undefined;
        }

        if (resultsMapByRows === null) {
            return null;
        }

        const plainResultsMapByRows: {
            [x: string]:
                | SupportedQuestionResultsMapAfterDigestion
                | NotSupportedQuestionResultsMapAfterDigestion;
        } = JSON.parse(JSON.stringify(resultsMapByRows));

        Object.values(plainResultsMapByRows).forEach(
            (
                resultsMapForQuestion:
                    | SupportedQuestionResultsMapAfterDigestion
                    | NotSupportedQuestionResultsMapAfterDigestion
            ) => {
                Object.values(resultsMapForQuestion).forEach(
                    (resultsMapColumn: any) => {
                        delete resultsMapColumn[NO_ANSWER_KEY];
                    }
                );
            }
        );

        return plainResultsMapByRows;
    }

    public clearStore = () => {
        this._resultsMaps = {};
        this._resultsTablesMap = observable.map({});
    };
}

export const resultsMapStore = new ResultsMapStore();
