import { makeAutoObservable, observable, ObservableMap } from "mobx";

import { SharedSurvey, BaseSurvey, Tag, UUID, Label } from "@difftone/types";
import { showDifftoneAlert } from "@difftone/actions";

import {
    getAllTemplates,
    upsertTemplateToApi,
} from "./templates-internal-actions";

class TemplatesStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _isManageTemplatesDialogOpened = false;

    get isManageTemplatesDialogOpened() {
        return this._isManageTemplatesDialogOpened;
    }

    set isManageTemplatesDialogOpened(bool: boolean) {
        this._isManageTemplatesDialogOpened = bool;
    }

    private _storeInitialize: boolean = false;
    get storeInitialize(): boolean {
        return this._storeInitialize;
    }
    set storeInitialize(newStoreInitialize: boolean) {
        this._storeInitialize = newStoreInitialize;
    }

    private _startInitialization: boolean = false;
    get startInitialization(): boolean {
        return this._startInitialization;
    }
    set startInitialization(newStartInitialization: boolean) {
        this._startInitialization = newStartInitialization;
    }

    public init = () => {
        getAllTemplates();
        this.startInitialization = true;
    };

    private _tagsMap: ObservableMap<UUID, Tag> = observable.map({});

    public getTagsMap(): ObservableMap<UUID, Tag> {
        return this._tagsMap;
    }

    public getTagsMapValues = () => {
        return [...this.getTagsMap().values()];
    };

    public getTagByUUID(tagUUID: UUID) {
        return this._tagsMap.get(tagUUID);
    }

    public setTagToMap(tagUUID: UUID, tag: Tag) {
        this._tagsMap.merge({ [tagUUID]: tag });
    }

    public getTagsByTagsUUIDs = (tags: UUID[]): Tag[] => {
        const tagsForSurvey: Tag[] = tags
            .map((tagUUID) => this.getTagByUUID(tagUUID))
            .filter((tag) => tag) as Tag[];

        return tagsForSurvey;
    };

    private _templateMap: ObservableMap<UUID, SharedSurvey | null> =
        observable.map({});

    public getTemplateMap(): ObservableMap<UUID, SharedSurvey | null> {
        return this._templateMap;
    }

    public setTemplateToMap(templateUUID: UUID, template: SharedSurvey | null) {
        this._templateMap.merge({ [templateUUID]: template });
    }

    public getTemplateByUUID(
        templateUUID: UUID
    ): SharedSurvey | undefined | null {
        if (!this.startInitialization) {
            this.init();
            return undefined;
        }

        const fetchedTemplate = this._templateMap.get(templateUUID);

        return fetchedTemplate;
    }

    public getTemplatesListAsArray(): SharedSurvey[] {
        return [...this._templateMap.values()].filter(
            (value) => !!value && typeof value === "object"
        ) as SharedSurvey[];
    }

    private filterOutTemplatesWithIncorrectCategories = (
        templates: SharedSurvey[],
        allCategories: Label[]
    ) => {
        return templates.filter((template) => {
            const { questions } = template.survey;
            let areCategoriesValid = true;

            questions.forEach(({ categories }) => {
                categories.forEach((category) => {
                    if (!allCategories.find(({ uuid }) => category === uuid)) {
                        console.warn(
                            `INCORRECT TEMPLATE'S CATEGORY DETECTED. UUID or NAME OF CATEGORY - ${category}`
                        );
                        areCategoriesValid = false;
                    }
                });
            });

            return areCategoriesValid;
        });
    };

    public getTemplatesToDisplay = (allCategories: Label[]) => {
        return this.filterOutTemplatesWithIncorrectCategories(
            this.getTemplatesListAsArray().filter(
                (templates) => templates.shared_survey_status !== "DRAFT"
            ),
            allCategories
        );
    };

    public addTemplate = (templates: SharedSurvey[]) => {
        templates.forEach((template) => {
            this.updateTemplateToApi(template);
            this.setTemplateToMap(template.uuid, template);
        });
    };

    public removeDeletedTemplate = () => {
        this.getTemplatesListAsArray()
            .filter((template: SharedSurvey) => template.deleted)
            .forEach((template: SharedSurvey) =>
                this._templateMap.delete(template.uuid)
            );
    };

    public updateTemplateToApi = (template: SharedSurvey) => {
        upsertTemplateToApi(template).catch((err) => {
            console.error(err);
            showDifftoneAlert(
                "Could not update the template. Please try again later.",
                "FAILURE"
            );
        });
    };

    private _editingTemplateSurvey: BaseSurvey | null = null;

    get editingTemplateSurvey(): BaseSurvey | null {
        return this._editingTemplateSurvey;
    }
    set editingTemplateSurvey(templateSurvey: BaseSurvey | null) {
        this._editingTemplateSurvey = templateSurvey;
    }

    public clearStore = () => {
        this._storeInitialize = false;
        this._startInitialization = false;
        this._editingTemplateSurvey = null;
        this._templateMap = observable.map({});
        this._tagsMap = observable.map({});
    };
}

export const templatesStore = new TemplatesStore();
