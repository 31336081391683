import React, { ChangeEvent } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import { surveyResponseStore } from "@difftone/stores";
import { updateSelectedSurveyNonFilterQuestionResponse } from "@difftone/actions";
import { getAnswerByQuestion } from "@difftone/reducers";
import {
    Answer,
    MultipleChoiceAnswer,
    Question,
    MultipleChoiceQuestion,
} from "@difftone/types";
import { Spinner } from "@difftone/shared-components";

import {
    text_label,
    checked_choice_style,
    custom_checkbox_class,
    survey_multiple_choice_element_container,
    text_label_active,
    text_label_disabled,
    custom_checkbox_class_active,
    custom_checkbox_class_disabled,
} from "./survey-multiple-choice-element.module.css";

export type SurveyMultipleChoiceElementProps = {
    question: Question<MultipleChoiceQuestion>;
    disabled: boolean;
    inPreviewMode: boolean;
    isEditableQuestion: boolean;
};

export const SurveyMultipleChoiceElement = observer(
    (props: SurveyMultipleChoiceElementProps) => {
        const { question, inPreviewMode, isEditableQuestion } = props;

        let surveyResponseUUID: string | undefined;

        if (inPreviewMode) {
            surveyResponseUUID = "";
        } else {
            const { getSelectedSurveyResponseUUID } = surveyResponseStore;
            surveyResponseUUID = getSelectedSurveyResponseUUID();
        }

        if (surveyResponseUUID === undefined) {
            return <Spinner size="MEDIUM" />;
        }

        const answer = getAnswerByQuestion(
            question,
            surveyResponseUUID
        ) as Answer<MultipleChoiceAnswer>;

        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
            if (inPreviewMode) {
                return;
            }
            const isChecked = event.target.checked;
            const choice = event.currentTarget.dataset.id!;
            answer.content.choices[choice] = isChecked;

            updateSelectedSurveyNonFilterQuestionResponse(
                answer,
                surveyResponseUUID!
            );
        };

        return (
            <div className={survey_multiple_choice_element_container}>
                <ul>
                    {question.content.choice_options.map((choice, index) => {
                        return (
                            <li key={question.uuid + choice}>
                                <input
                                    onChange={onChange}
                                    data-id={choice}
                                    id={question.uuid + "/" + choice}
                                    type="checkbox"
                                    checked={answer.content.choices[choice]}
                                />
                                <label
                                    className={
                                        answer.content.choices[choice]
                                            ? checked_choice_style
                                            : clsx(custom_checkbox_class, {
                                                  [custom_checkbox_class_active]:
                                                      isEditableQuestion,
                                                  [custom_checkbox_class_disabled]:
                                                      !isEditableQuestion,
                                              })
                                    }
                                    htmlFor={question.uuid + "/" + choice}
                                />
                                <label
                                    className={clsx(text_label, {
                                        [text_label_active]: isEditableQuestion,
                                        [text_label_disabled]:
                                            !isEditableQuestion,
                                    })}
                                    htmlFor={question.uuid + "/" + choice}
                                >
                                    {choice}
                                </label>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
);
