import { UUID } from "@difftone/types";
import React, { ChangeEvent } from "react";

import {
    question_title,
    checked_style,
    custom_checkbox_class,
    text_label,
} from "./question-item.module.css";

export type QuestionItemProps = {
    questionNumber: number;
    title: string;
    value: boolean;
    questionUuid: UUID;
    onToggleQuestion: (questionUuid: UUID, selected: boolean) => void;
};

export const QuestionItem = (props: QuestionItemProps) => {
    const { questionNumber, title, value, onToggleQuestion, questionUuid } =
        props;

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        onToggleQuestion(questionUuid, isChecked);
    };

    return (
        <div className={question_title}>
            <label className={value ? checked_style : custom_checkbox_class}>
                <input type="checkbox" onChange={onChange} checked={value} />
            </label>
            <label className={text_label}>{`${
                questionNumber + 1
            }. ${title}`}</label>
        </div>
    );
};
