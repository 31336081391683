import React from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import {
    surveyResponseDisplayStore,
    surveyResponseStore,
} from "@difftone/stores";
import {
    setFocusedSurveyResponseInputCard,
    updateSelectedSurveyNonFilterQuestionResponse,
    updateSelectedSurveyFilterQuestionResponse,
} from "@difftone/actions";
import { getAnswerByQuestion } from "@difftone/reducers";
import {
    DropdownElements,
    SimpleDropdown,
    Spinner,
} from "@difftone/shared-components";

import {
    Question,
    SingleChoiceQuestion,
    SingleChoiceAnswer,
    Answer,
} from "@difftone/types";

import {
    single_choice_drop_down_wrapper,
    disabled_dropdown,
    disabled_text_value,
} from "./survey-single-choice-element.module.css";

export type SurveySingleChoiceElementProps = {
    disabled: boolean;
    question: Question<SingleChoiceQuestion>;
    inPreviewMode: boolean;
    isEditableQuestion: boolean;
};

export const SurveySingleChoiceElement = observer(
    (props: SurveySingleChoiceElementProps) => {
        const { getFocusedSurveyCardElementByUUID } =
            surveyResponseDisplayStore;
        const { question, inPreviewMode, isEditableQuestion } = props;

        let surveyResponseUUID: string | undefined;
        let surveyResponseInputCard: string | null;

        if (inPreviewMode) {
            surveyResponseUUID = "";
            surveyResponseInputCard = "";
        } else {
            const { getSelectedSurveyResponseUUID } = surveyResponseStore;
            const { focusedSurveyResponseInputCard } =
                surveyResponseDisplayStore;
            surveyResponseUUID = getSelectedSurveyResponseUUID();
            surveyResponseInputCard = focusedSurveyResponseInputCard;
        }

        if (surveyResponseUUID === undefined) {
            return <Spinner size="MEDIUM" />;
        }

        const answer = getAnswerByQuestion(
            question,
            surveyResponseUUID
        ) as Answer<SingleChoiceAnswer>;

        const onSelectChoice = () => {
            if (inPreviewMode) {
                return;
            }
            const allExistingListElements: NodeListOf<HTMLDivElement> =
                document.querySelectorAll("div[data-survey-focused-card]");
            const currentElement = Number(
                surveyResponseInputCard &&
                    getFocusedSurveyCardElementByUUID(
                        surveyResponseInputCard
                    )?.getAttribute("index")
            );
            const nextElement = allExistingListElements[currentElement + 1];

            if (currentElement < allExistingListElements.length - 1) {
                const nextElementUUID = nextElement.getAttribute("uuid");
                nextElementUUID &&
                    setFocusedSurveyResponseInputCard(nextElementUUID);
            } else if (currentElement === allExistingListElements.length - 1) {
                (
                    document.querySelector(
                        "button[data-submit-survey-button]"
                    ) as HTMLButtonElement
                ).focus();
                return;
            }
        };

        const onChangeOption = (value: string) => {
            if (inPreviewMode) {
                return;
            }
            answer.content.choice = value;

            if (question.is_filter) {
                updateSelectedSurveyFilterQuestionResponse(
                    answer,
                    surveyResponseUUID!
                );
            } else {
                updateSelectedSurveyNonFilterQuestionResponse(
                    answer,
                    surveyResponseUUID!
                );
            }
            onSelectChoice();
        };

        const singleChoiceOptions: DropdownElements[] =
            question.content.choice_options.map((choiceOption) => {
                const dropdownElementFormat: DropdownElements = {
                    displayValue: choiceOption.label,
                    systemValue: choiceOption.label,
                };

                return dropdownElementFormat;
            });

        const disabledOptions = singleChoiceOptions.map(
            (option) => option.systemValue
        );

        return (
            <div className={single_choice_drop_down_wrapper}>
                <SimpleDropdown
                    wrapperClassName={clsx({
                        [disabled_dropdown]: !isEditableQuestion,
                    })}
                    textValueClassName={clsx({
                        [disabled_text_value]: !isEditableQuestion,
                    })}
                    inSurveyMode={true}
                    selectedValue={
                        answer.content.choice ? answer.content.choice : ""
                    }
                    onChange={onChangeOption}
                    dropdownOptions={singleChoiceOptions}
                    disabledOptions={!isEditableQuestion ? disabledOptions : []}
                />
            </div>
        );
    }
);
