import React from "react";
import { observer } from "mobx-react";
import { PublicProfile, ResponderToAnswer, BaseSurvey } from "@difftone/types";
import userIcon from "@difftone/assets/user-mailing-icon.svg";
import { showDifftoneAlert, setLocation } from "@difftone/actions";
import {
    publicProfileStore,
    simpleSurveyResultsStore,
    RESPONDENTS_SYSTEM_HEADER,
} from "@difftone/stores";
import { getSortedAndFilteredResponses } from "@difftone/reducers";
import { INBOX_URL } from "@difftone/constants";

import { ResponderAnswers } from "./components";

import {
    responders_list,
    responder_list_item,
    responder_name,
    no_responses_text,
} from "./responders-list.module.css";

export type RespondersListProps = {
    survey: BaseSurvey;
    responses: (ResponderToAnswer | null)[];
};

export const RespondersList = observer((props: RespondersListProps) => {
    const { survey, responses } = props;
    const { searchProfile } = simpleSurveyResultsStore;

    if (responses.length === 0) {
        return (
            <div className={no_responses_text}>
                No responses for this question
            </div>
        );
    }

    if (!responses) {
        setLocation(INBOX_URL);
        showDifftoneAlert(
            "Something went wrong in RespondersList component",
            "FAILURE"
        );
    }

    let respondersPublicProfiles: (PublicProfile | undefined | null)[] = [];
    const responderUUIDs = responses.map((response) => response?.responderUUID);
    const onlyUUIDs: string[] = [];
    responderUUIDs.forEach((uuid) => {
        if (uuid !== undefined) {
            onlyUUIDs.push(uuid);
        }
    });
    respondersPublicProfiles =
        publicProfileStore.getPublicProfilesByUuids(onlyUUIDs);

    const sortedAndFilteredResponses = getSortedAndFilteredResponses(responses);

    const responderPrefixSearchFromProfile =
        searchProfile[RESPONDENTS_SYSTEM_HEADER] || "";

    return (
        <div className={responders_list}>
            {sortedAndFilteredResponses.map((response, index) => {
                const responderPublicProfile = respondersPublicProfiles.find(
                    (publicProfile) =>
                        publicProfile?.uuid === response?.responderUUID
                );

                if (
                    !responderPublicProfile?.name
                        .toLowerCase()
                        .includes(
                            responderPrefixSearchFromProfile.toLowerCase()
                        )
                ) {
                    return null;
                }

                return response ? (
                    <div
                        key={response.responderUUID}
                        className={responder_list_item}
                    >
                        <div className={responder_name}>
                            <img src={userIcon} alt="user-icon" />
                            <span>
                                {responderPublicProfile
                                    ? responderPublicProfile.name
                                    : response.responderUUID}
                            </span>
                        </div>
                        <ResponderAnswers
                            isLast={
                                index === sortedAndFilteredResponses.length - 1
                            }
                            responderAnswer={response.answer}
                            survey={survey}
                        />
                    </div>
                ) : null;
            })}
        </div>
    );
});
