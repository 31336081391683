import React from "react";

import { observer } from "mobx-react";
import {
    setLocation,
    setSelectedTimeCompareSharedResultsFilterAndTimeUnits,
    showDifftoneAlert,
} from "@difftone/actions";
import {
    constantAlertVariants,
    PAGE_URLS,
    TIME_FILTER_ID,
    warningMessages,
} from "@difftone/constants";
import {
    createDataCategoriesToGraph,
    createDataQuestionsToGraph,
    formatCategoriesQuestionsMap,
    formatCategoriesResultsRatingMap,
    getNonFilterQuestionsFromSurvey,
    getResultsMapsBySharedResultsUuidAndCompare,
    getSortedCategoriesList,
    getTimeFilterFromFilters,
    selectedFiltersActiveAsCompare,
    selectedFiltersNotActiveAsCompare,
    sortQuestionsByType,
} from "@difftone/reducers";
import {
    QuestionToAnswerDescreteQuestion,
    ResultsMap,
    UUID,
} from "@difftone/types";
import { Spinner, Graph } from "@difftone/shared-components";

import {
    categoriesDisplayStore,
    resultsDisplayStore,
    sharedResultsDisplayStore,
    surveyResponsesSummaryStore,
    timeRangesStore,
} from "@difftone/stores";
import { anonymityLimitation } from "@difftone/anonymity-util";
import { createResultsTableFromResultsMaps } from "@difftone/frontend-common";
import { COLUMN_0, DATA_ANONYMIZED } from "@difftone/common-constants";

import { favorable_anonimity_no_data } from "./shared-results-graph-spreadsheet.module.css";

const MESSAGE_ANONYMITY = "Data cannot be shown due to anonymity limitations";

export type SharedResultsGraphSpreadsheetProps = {};

export const SharedResultsGraphSpreadsheet = observer(
    (props: SharedResultsGraphSpreadsheetProps) => {
        const { categoriesQuestionsDisplayModeToggle } = resultsDisplayStore;

        const {
            selectedSharedResults,
            selectedSurvey,
            getSelectedFilters,
            getSelectedSharedResultsTimeCompare,
            setPopupDisplaySharedResultsTimeCompare,
            getFiltersActiveAsCompares,
            getFiltersNotActiveAsCompares,
        } = sharedResultsDisplayStore;

        const { extractKeyForTimeRange, getTimeRangeByKey } = timeRangesStore;

        const survey = selectedSurvey!;

        const surveyResponses =
            surveyResponsesSummaryStore.getSurveyResponseSummaryByFilterPlain(
                survey.uuid,
                []
            );

        if (surveyResponses === undefined) {
            return <Spinner size="LARGE" />;
        }

        if (surveyResponses === null) {
            setLocation(PAGE_URLS.INBOX_ALL);
            showDifftoneAlert(
                warningMessages.surveyResponseNotFound,
                "FAILURE"
            );
            return null;
        }

        const categoriesQuestionsMap = formatCategoriesQuestionsMap(
            survey.questions || []
        );

        const categoriesList: UUID[] = getSortedCategoriesList(
            categoriesQuestionsMap
        );

        if (!categoriesDisplayStore.isStoreInitialized) {
            categoriesDisplayStore.initCategoriesDisplayMap(categoriesList);
            return <Spinner size="LARGE" />;
        }

        const filterAsActive = selectedFiltersActiveAsCompare(
            getFiltersActiveAsCompares()
        );
        const filterNotActive = selectedFiltersNotActiveAsCompare(
            getFiltersNotActiveAsCompares()
        );

        const allFiltersWithNoTimeCompare = [
            ...filterAsActive,
            ...filterNotActive,
        ].filter((filter) => filter.filterKey.uuid !== TIME_FILTER_ID);

        const allFilters = getSelectedFilters();

        const timeFilterOnly = getTimeFilterFromFilters(allFilters);

        const resultsMapsByTimeFilter =
            getResultsMapsBySharedResultsUuidAndCompare(
                selectedSharedResults!.uuid,
                [timeFilterOnly],
                allFiltersWithNoTimeCompare
            );

        const _resultsMapsByTimeFilter = resultsMapsByTimeFilter as (
            | null
            | undefined
            | ResultsMap
        )[];

        if (_resultsMapsByTimeFilter.includes(undefined)) {
            return (
                <div>
                    <Spinner size="LARGE" />
                </div>
            );
        }

        if (_resultsMapsByTimeFilter.includes(null)) {
            setLocation(PAGE_URLS.INBOX_ALL);
            showDifftoneAlert(
                constantAlertVariants.survey_results_failure.text,
                constantAlertVariants.survey_results_failure.variant
            );
            return null;
        }

        const safeResultsMap = _resultsMapsByTimeFilter as ResultsMap[];

        const resultsTable = createResultsTableFromResultsMaps(safeResultsMap);

        const categoriesResultsMap = formatCategoriesResultsRatingMap(
            categoriesQuestionsMap,
            resultsTable
        );

        const ratingQuestionsUuid = sortQuestionsByType(
            getNonFilterQuestionsFromSurvey(survey)
        ).reduce((uuids: UUID[], question) => {
            if (question.question_type_name === "RATING")
                uuids.push(question.uuid);
            return uuids;
        }, []);

        const questions = sortQuestionsByType(
            getNonFilterQuestionsFromSurvey(survey)
        );

        const resultsByQuestionUuidArray = questions.map(
            (question) => resultsTable[question.uuid][COLUMN_0]
        ) as QuestionToAnswerDescreteQuestion[];

        const isAnonymous = resultsByQuestionUuidArray.every(
            (resultQuestion) => resultQuestion.toString() === DATA_ANONYMIZED
        );

        const ratingQuestionsRowsMap: { [key: string]: any } = {};
        ratingQuestionsUuid.forEach((questionUuid) => {
            ratingQuestionsRowsMap[questionUuid] = resultsTable[questionUuid];
        });

        const timeRangeKey = extractKeyForTimeRange(survey.uuid, "MONTHS");

        const timeRanges = getTimeRangeByKey(timeRangeKey);

        if (timeRanges === undefined) {
            return <Spinner size="SMALL" />;
        }

        if (timeRanges === null) {
            showDifftoneAlert(
                "Something went wrong, please reload and try again",
                "FAILURE"
            );
            return null;
        }

        if (getSelectedSharedResultsTimeCompare() === "NONE") {
            setPopupDisplaySharedResultsTimeCompare("MONTHS");
            setSelectedTimeCompareSharedResultsFilterAndTimeUnits(
                "MONTHS",
                TIME_FILTER_ID,
                timeRanges
            );
        }

        switch (categoriesQuestionsDisplayModeToggle) {
            case "QUESTIONS":
                const questions = createDataQuestionsToGraph(
                    ratingQuestionsRowsMap,
                    timeFilterOnly,
                    survey
                );
                return !isAnonymous ? (
                    <Graph
                        dataGraph={questions.dataGraph}
                        sizeAxisLeft={questions.sizeAxisLeft}
                    />
                ) : (
                    <div className={favorable_anonimity_no_data}>
                        {MESSAGE_ANONYMITY}
                    </div>
                );

            case "CATEGORIES":
            default:
                const categories = createDataCategoriesToGraph(
                    categoriesResultsMap,
                    timeFilterOnly,
                    categoriesQuestionsMap
                );
                return !isAnonymous ? (
                    <Graph
                        dataGraph={categories.dataGraph}
                        sizeAxisLeft={categories.sizeAxisLeft}
                    />
                ) : (
                    <div className={favorable_anonimity_no_data}>
                        {MESSAGE_ANONYMITY}
                    </div>
                );
        }
    }
);
