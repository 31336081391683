import React, { useState } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core";
import {
    ResultsFilter,
    SurveyClasses,
    TimeCompareUnits,
    TimeRangesContainer,
    UUID,
} from "@difftone/types";
import { ShowDifftoneTooltip } from "@difftone/shared-components";
import { ALLOWED_ATTRIBUTE_TITLE_LENGTH } from "@difftone/stores";
import { TIME_FILTER_ID } from "@difftone/constants";

import { CompareItem, CompareTimePopup, TimeCompareItem } from "./components";
import {
    compare_text,
    active_compare_text,
    enabled_compare_text,
    disabled_compare_text,
} from "./compare-option.module.css";

const extendTooltipStyle = makeStyles({
    tooltipCompareByPosition: {
        left: "10px",
    },
});

export type CompareOptionProps = {
    resultsFilter: ResultsFilter;
    activeAsComparesFiltersUUIDs: UUID[];
    survey?: SurveyClasses;
    selectedResultsTimeCompare: TimeCompareUnits;
    selectedPopupDisplayResultsTimeCompare: TimeCompareUnits;
    setPopupDisplayResultsTimeCompare: (
        newTimeCompare: TimeCompareUnits
    ) => void;
    setSelectedTimeCompareResultsFilterAndTimeUnits: (
        selectedFilter: TimeCompareUnits,
        timeCompareFilterUuid: string,
        timeRangesContainer: TimeRangesContainer | null
    ) => void;
    resultsTurnOnFilterAsCompare: (resultsFilter: ResultsFilter) => void;
    resultsTurnOffFilterAsCompare: (resultsFilter: ResultsFilter) => void;
    switchResultsActiveCompareIndex: () => void;
    isDisabled: boolean;
    isWithoutNonePopupOption?: boolean;
};

export const CompareOption = observer((props: CompareOptionProps) => {
    const {
        resultsFilter,
        activeAsComparesFiltersUUIDs,
        survey,
        selectedResultsTimeCompare,
        selectedPopupDisplayResultsTimeCompare,
        setPopupDisplayResultsTimeCompare,
        setSelectedTimeCompareResultsFilterAndTimeUnits,
        resultsTurnOnFilterAsCompare,
        resultsTurnOffFilterAsCompare,
        switchResultsActiveCompareIndex,
        isDisabled,
        isWithoutNonePopupOption,
    } = props;

    //UseState for internal use for the
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const rightTooltipPosition = extendTooltipStyle().tooltipCompareByPosition;

    const isSelected = activeAsComparesFiltersUUIDs.includes(
        resultsFilter.filterKey.uuid
    );

    const addCompareHandler = () => {
        if (isDisabled) {
            return;
        }
        resultsTurnOnFilterAsCompare(resultsFilter);
    };

    const popCompareHandler = () => {
        if (isDisabled) {
            return;
        }
        resultsTurnOffFilterAsCompare(resultsFilter);
    };

    const switchComparesPositions = () => {
        if (isDisabled) {
            return;
        }
        switchResultsActiveCompareIndex();
    };

    const openPopupHandler = () => {
        if (isDisabled) {
            return;
        }
        setIsPopupOpen(true);
    };
    const closePopupHandler = () => {
        if (isDisabled) {
            return;
        }
        setIsPopupOpen(false);
    };

    let compareNickName = null;

    if (
        !resultsFilter.filterKey.nickname &&
        resultsFilter.filterKey.title.length > ALLOWED_ATTRIBUTE_TITLE_LENGTH
    ) {
        compareNickName = (
            <ShowDifftoneTooltip
                extendTooltipStyle={rightTooltipPosition}
                tip={resultsFilter.filterKey.title}
                tooltipPosition="right"
            >
                <div
                    className={clsx(compare_text, {
                        [active_compare_text]: !isDisabled && isSelected,
                        [enabled_compare_text]: !isDisabled,
                        [disabled_compare_text]: isDisabled,
                    })}
                >
                    {isSelected
                        ? `${resultsFilter.indexInComparePlain}. `
                        : null}
                    {resultsFilter.filterKey.nickname}
                </div>
            </ShowDifftoneTooltip>
        );
    } else {
        compareNickName = (
            <div
                className={clsx(compare_text, {
                    [active_compare_text]: !isDisabled && isSelected,
                    [enabled_compare_text]: !isDisabled,
                    [disabled_compare_text]: isDisabled,
                })}
            >
                {isSelected ? `${resultsFilter.indexInComparePlain}. ` : null}
                {resultsFilter.filterKey.nickname}
            </div>
        );
    }

    return (
        <div>
            {resultsFilter.filterKey.uuid !== TIME_FILTER_ID ? (
                <CompareItem
                    isDisabled={isDisabled}
                    isSelected={isSelected}
                    addCompareHandler={addCompareHandler}
                    popCompareHandler={popCompareHandler}
                    switchComparesPositions={switchComparesPositions}
                >
                    {compareNickName}
                </CompareItem>
            ) : (
                <div>
                    <TimeCompareItem
                        isDisabled={isDisabled}
                        openPopupHandler={openPopupHandler}
                        isSelected={isSelected}
                        timeFilterCompare={resultsFilter}
                        switchComparesPositions={switchComparesPositions}
                        selectedResultsTimeCompare={selectedResultsTimeCompare}
                    />
                    {isPopupOpen && (
                        <CompareTimePopup
                            isWithoutNonePopupOption={isWithoutNonePopupOption}
                            setSelectedTimeCompareResultsFilterAndTimeUnits={
                                setSelectedTimeCompareResultsFilterAndTimeUnits
                            }
                            survey={survey}
                            closePopupHandler={closePopupHandler}
                            timeCompareFilter={resultsFilter}
                            selectedPopupDisplayResultsTimeCompare={
                                selectedPopupDisplayResultsTimeCompare
                            }
                            setPopupDisplayResultsTimeCompare={
                                setPopupDisplayResultsTimeCompare
                            }
                        />
                    )}
                </div>
            )}
        </div>
    );
});
