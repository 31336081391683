import React, { useState, MouseEvent } from "react";

import { TableHeaderCell, FilterTypes } from "./components";

import {
    table_header,
    survey_name_class,
    purpose_class,
    issued_class,
    last_date_class,
    created_class,
} from "./table-header.module.css";

export const TableHeader = () => {
    const [activeFilter, setActiveFilter] = useState<FilterTypes | null>(
        "CREATED"
    );

    const setFilter = (event: MouseEvent<HTMLDivElement>) => {
        const filterType = event.currentTarget.getAttribute(
            "data-filter"
        ) as FilterTypes;
        if (filterType) {
            setActiveFilter(filterType);
        }
    };

    return (
        <div className={table_header}>
            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "NAME"}
                filterType="NAME"
                text="Name"
                className={survey_name_class}
            />
            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "PURPOSE"}
                filterType="PURPOSE"
                text="Purpose"
                className={purpose_class}
            />
            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "CREATED"}
                filterType="CREATED"
                text="Created"
                className={created_class}
            />
            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "ISSUED"}
                filterType="ISSUED"
                text="Start"
                className={issued_class}
            />
            <TableHeaderCell
                setFilter={setFilter}
                isFilterActive={activeFilter === "LAST"}
                filterType="LAST"
                text="End"
                className={last_date_class}
            />
        </div>
    );
};
