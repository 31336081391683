import React from "react";

import {
    text_answer_box,
    no_answer_style,
    border_bottom_class,
} from "./text-answer-box.module.css";

export type TextAnswerBoxProps = {
    answer: string;
    borderBottom: boolean;
};

export const TextAnswerBox = (props: TextAnswerBoxProps) => {
    const { answer, borderBottom } = props;
    return (
        <div
            className={[
                text_answer_box,
                borderBottom && border_bottom_class,
            ].join(" ")}
        >
            {answer ? (
                answer
            ) : (
                <div className={no_answer_style}>No asnwer yet</div>
            )}
        </div>
    );
};
