import React, { ChangeEvent } from "react";
import { observer } from "mobx-react";
import { PublicProfile, ResponderToAnswer } from "@difftone/types";
import {
    publicProfileStore,
    RESPONDENTS_SYSTEM_HEADER,
    simpleSurveyResultsStore,
} from "@difftone/stores";

import { BinaryOptions, NonBinaryOptions } from "./components";

import {
    question_filter_values,
    checked_choice_style,
    custom_checkbox_class,
    reponders_name,
} from "./question-filter-values.module.css";

export type QuestionFilterValuesProps = {
    responses: ResponderToAnswer[];
    excludedItemsList: string[];
    onChange: React.Dispatch<React.SetStateAction<string[]>>;
    userSearch: string;
};

export const ColumnFilterValues = observer(
    (props: QuestionFilterValuesProps) => {
        const { responses, onChange, excludedItemsList, userSearch } = props;
        const { sortingProfile, filterProfile } = simpleSurveyResultsStore;
        const showBinaryOptions = sortingProfile.sortBy === "NONTEXT";

        const responderUUIDs = responses.map(
            (response) => response?.responderUUID
        );

        let respondersPublicProfiles: (PublicProfile | undefined | null)[] = [];
        respondersPublicProfiles =
            publicProfileStore.getPublicProfilesByUuids(responderUUIDs);

        let isRespondentsColumn = false;
        if (filterProfile.filterdColumnKey === RESPONDENTS_SYSTEM_HEADER) {
            isRespondentsColumn = true;
        }

        const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
            const clickedValue = event.currentTarget.getAttribute("data-id");
            if (!clickedValue) {
                throw Error("[ColumnFilterValues]:: Somthing went wrong!");
            }

            if (excludedItemsList.includes(clickedValue)) {
                onChange([
                    ...excludedItemsList.filter(
                        (item) => item !== clickedValue
                    ),
                ]);
            } else {
                onChange([...excludedItemsList, clickedValue]);
            }
        };

        return isRespondentsColumn ? (
            <div className={question_filter_values}>
                <ul>
                    {respondersPublicProfiles
                        .filter((publicProfile) =>
                            publicProfile?.name
                                .toLowerCase()
                                .includes(userSearch.toLowerCase())
                        )
                        .map((publicProfile) => {
                            if (!publicProfile) {
                                return <li key={publicProfile}>Loading..</li>;
                            }

                            const isChecked = !excludedItemsList.includes(
                                publicProfile.name
                            );

                            return (
                                <li key={publicProfile.uuid}>
                                    <input
                                        onChange={onChangeHandler}
                                        data-id={publicProfile.name}
                                        id={publicProfile.name}
                                        type="checkbox"
                                        checked={isChecked}
                                    />
                                    <label
                                        className={
                                            isChecked
                                                ? checked_choice_style
                                                : custom_checkbox_class
                                        }
                                        htmlFor={publicProfile.name}
                                    ></label>
                                    <label
                                        title={publicProfile.name}
                                        className={reponders_name}
                                        htmlFor={publicProfile.name}
                                    >
                                        {publicProfile.name}
                                    </label>
                                </li>
                            );
                        })}
                </ul>
            </div>
        ) : (
            <div className={question_filter_values}>
                {showBinaryOptions ? (
                    <BinaryOptions
                        excludedItemsList={excludedItemsList}
                        onChange={onChangeHandler}
                        responses={responses}
                    />
                ) : (
                    <NonBinaryOptions
                        userSearch={userSearch}
                        excludedItemsList={excludedItemsList}
                        onChange={onChangeHandler}
                        responses={responses}
                    />
                )}
            </div>
        );
    }
);
