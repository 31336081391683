/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    AnswersWithQuestionType,
    OpenQuestionAnswers,
    Question,
    QuestionToAnswer,
    QuestionToAnswerDescreteQuestion,
    QuestionTypeName,
    RatingQuestion,
    ResultsFilter,
    BaseSurvey,
    UUID,
    SurveyClasses,
    QuestionType,
} from "@difftone/types";
import { getAvgForRatingTypeColumn, getMostPopular } from "@difftone/actions";
import {
    milisecondsToDateStringResults,
    milisecondsToTimeString,
} from "@difftone/time-utils";
import { NO_ANSWER_KEY, DATA_ANONYMIZED } from "@difftone/common-constants";
import { anonymityLimitation, isAnonymous } from "@difftone/anonymity-util";
import {
    CategoriesQuestionsMap,
    CategoriesResultsRatingMap,
    convertTimeRangeToHeaderDisplay,
    getNonFilterQuestionsFromSurvey,
    getRatingRange,
} from "@difftone/reducers";
import { ResultsTable } from "@difftone/stores";

const QUESTION_TYPE_TO_TYPE_NAME: Record<QuestionTypeName, string> = {
    SINGLE_CHOICE: "Single choice",
    MULTIPLE_CHOICE: "Multiple choice",
    RATING: "",
    DATE: "Date",
    TIME: "Time",
    SHORT_TEXT: "Short text",
    LONG_TEXT: "Long text",
    SYSTEM_ANSWER_TIME_FRAME: "",
    BOOKING: "",
};

const getPopulationSizeFromRow = (answersRow: QuestionToAnswer) => {
    const populationSize = Object.values(answersRow).reduce(
        (sum, answerValue) => {
            return sum + Number(answerValue);
        },
        0
    );
    return populationSize;
};

const getMostPopularSingleAndMultipleChoiceValue = (
    answersRow: QuestionToAnswer,
    mostPopularOption: { [key: string]: string } | null
) => {
    let mostPopularOptionValue: number = 0;

    if (mostPopularOption) {
        const mostPopularOptionKey = Object.keys(mostPopularOption)[0];
        //@ts-ignore
        mostPopularOptionValue = answersRow[mostPopularOptionKey];
    }
    return mostPopularOptionValue;
};

export const CSVHeaders = (
    firstHeader: ResultsFilter | undefined,
    secondHeader: ResultsFilter | undefined
) => {
    let firstData: string[];
    let secondData: string[];
    if (secondHeader) {
        const firstResult =
            firstHeader?.filterValues
                .map((item) => {
                    if (typeof item === "object") {
                        return convertTimeRangeToHeaderDisplay(
                            firstHeader,
                            item
                        );
                    }
                    return item;
                })
                .reduce(
                    (acc, rec) => {
                        const option = Array(
                            secondHeader.filterValues.length
                        ).fill(rec);
                        return [...acc, ...option];
                    },
                    [""]
                ) || [];

        const secondResult =
            firstHeader?.filterValues
                .map((item) => {
                    if (typeof item === "object") {
                        return convertTimeRangeToHeaderDisplay(
                            firstHeader,
                            item
                        );
                    }
                    return item;
                })
                .reduce(
                    (acc) => {
                        return [
                            ...acc,
                            ...secondHeader.filterValues.map((item) => {
                                if (typeof item === "object") {
                                    return convertTimeRangeToHeaderDisplay(
                                        secondHeader,
                                        item
                                    );
                                }
                                return item;
                            }),
                        ];
                    },
                    [""]
                ) || [];

        firstData = [
            "",
            "",
            `${firstHeader?.filterKey.nickname}`,
            ...firstResult.slice(1),
        ];
        secondData = [
            "",
            "",
            `${secondHeader.filterKey.nickname}`,
            ...secondResult.slice(1),
        ];
    } else if (firstHeader) {
        firstData = [
            "",
            "",
            `${firstHeader?.filterKey.nickname}`,
            ...firstHeader.filterValues.map((item) => {
                if (typeof item === "object") {
                    return convertTimeRangeToHeaderDisplay(firstHeader, item);
                }
                return item;
            }),
        ];
    }

    const titleHeader = (): string[][] => {
        if (!secondHeader && !firstHeader) {
            return [];
        }

        if (secondHeader) {
            return [secondData, firstData];
        }
        return [firstData];
    };

    return titleHeader();
};

const averageResultsRow = (
    selectedSurvey: SurveyClasses,
    avgResultQuestions: QuestionToAnswer[]
) => {
    return avgResultQuestions.map((resultRow, index) => {
        //@ts-ignore
        delete avgResultQuestions[index][NO_ANSWER_KEY];
        if (resultRow) {
            const populationSize = getPopulationSizeFromRow(resultRow);

            const isAnonymousAvgResult = isAnonymous(
                selectedSurvey.anonimity,
                populationSize
            );

            if (isAnonymousAvgResult) {
                return "";
            }

            const avg = getAvgForRatingTypeColumn(
                resultRow as QuestionToAnswerDescreteQuestion
            );
            return avg ? avg.toFixed(1) : "";
        }
        return "";
    });
};

const ratingTotalResultsItems = (
    firstValue: string,
    lastValue: string,
    totalLengthOfAvgAnswers: number,
    resultWithQuestionType: AnswersWithQuestionType,
    nonFilterQuestions: Question<QuestionType>[],
    avgResultRow: string[],
    questionIndex: number
) => {
    const totalResultsItem = Array(totalLengthOfAvgAnswers)
        .fill(0)
        .map((_, index) => {
            const result: string[][] = resultWithQuestionType.answers
                ? resultWithQuestionType.answers[0] === DATA_ANONYMIZED
                    ? [["", "Data Anonymized"]]
                    : resultWithQuestionType.answers.map((answer, idx) => {
                          if (answer === DATA_ANONYMIZED) {
                              return ["Data Anonymized"];
                          }

                          const arrAnswer = answer
                              ? Object.entries(answer)
                              : [];

                          if (!idx && arrAnswer[index].length) {
                              return [
                                  "",
                                  arrAnswer[index][0] === NO_ANSWER_KEY
                                      ? "No response"
                                      : arrAnswer[index][0],
                                  `${arrAnswer[index][1]}`,
                              ];
                          }

                          return [`${arrAnswer[index][1]}`];
                      })
                : [];

            return result.reduce((acc, rec) => {
                return [...acc, ...rec];
            }, []);
        });

    const title: string[] = [
        `${questionIndex + 1}. ${nonFilterQuestions[questionIndex].title}`,
        `Avg (${firstValue}-${lastValue})`,
        ...avgResultRow,
    ];
    return [title, ...totalResultsItem];
};

const singleOrMultipleChoiceResult = (
    singleOrMultipleChoiceResultQuestions: QuestionToAnswer[]
) => {
    let mostPopularOption: { [key: string]: string } | null = null;

    return singleOrMultipleChoiceResultQuestions.map((resultRow, index) => {
        //@ts-ignore
        delete singleOrMultipleChoiceResultQuestions[index][NO_ANSWER_KEY];
        if (resultRow) {
            const isAnonymousResult = resultRow === DATA_ANONYMIZED;
            if (!index) {
                if (isAnonymousResult) {
                    return "Data Anonymized";
                }
                mostPopularOption = getMostPopular(
                    resultRow as QuestionToAnswerDescreteQuestion
                );
                return mostPopularOption ? mostPopularOption : "";
            }

            if (isAnonymousResult) {
                return "Data Anonymized";
            }

            const mostPopularOptionValuePopulation =
                getMostPopularSingleAndMultipleChoiceValue(
                    resultRow,
                    mostPopularOption
                );

            return mostPopularOptionValuePopulation
                ? `${mostPopularOptionValuePopulation}`
                : "";
        }
        return "No data";
    });
};

const totalSingleOrMultipleChoiceResultsItems = (
    totalLengthSingleOrMultipleChoiceAnswers: number,
    resultWithQuestionType: AnswersWithQuestionType
) => {
    return Array(totalLengthSingleOrMultipleChoiceAnswers)
        .fill(0)
        .map((_, index) => {
            const result: string[][] = resultWithQuestionType.answers
                ? resultWithQuestionType.answers.map((answer, idx) => {
                      if (answer === DATA_ANONYMIZED) {
                          if (!idx) {
                              return ["", "Data Anonymized"];
                          }

                          return ["Data Anonymized"];
                      }

                      const arrAnswer: [string, any][] = answer
                          ? Object.entries(answer)
                          : [];

                      if (!idx) {
                          return [
                              "",
                              arrAnswer[index][0] === NO_ANSWER_KEY
                                  ? "No response"
                                  : arrAnswer[index][0],
                              `${arrAnswer[index][1]}`,
                          ];
                      }

                      return [`${arrAnswer[index][1]}`];
                  })
                : [];

            return result.reduce((acc, rec) => {
                return [...acc, ...rec];
            }, []);
        });
};

const shortAndLongTextResultItems = (
    resultWithQuestionType: AnswersWithQuestionType,
    nonFilterQuestions: Question<QuestionType>[],
    questionIndex: number
) => {
    let openQuestionsResultLongShortText: string[][] = [];
    const openQuestions = Object.values(
        resultWithQuestionType.answers
    )[0]! as OpenQuestionAnswers;

    const isAnonymousResultLongShortText = openQuestions
        ? openQuestions.includes(DATA_ANONYMIZED)
        : false;

    if (isAnonymousResultLongShortText) {
        openQuestionsResultLongShortText = [["", "No available answer"]];
    } else {
        openQuestionsResultLongShortText = openQuestions
            ? openQuestions.map((answer) => {
                  return ["", `${answer}`];
              })
            : [];
    }

    const openTextQuestionTitle = [
        `${questionIndex + 1}. ${nonFilterQuestions[questionIndex].title}`,
    ];
    return [openTextQuestionTitle, ...openQuestionsResultLongShortText];
};

const dateResultItems = (
    resultWithQuestionType: AnswersWithQuestionType,
    selectedSurvey: SurveyClasses,
    nonFilterQuestions: Question<QuestionType>[],
    questionIndex: number
) => {
    let openQuestionsResultDate: string[][] = [];

    const dateOpenQuestions = Object.values(
        resultWithQuestionType.answers
    )[0]! as OpenQuestionAnswers;

    const isAnonymousResultDate = dateOpenQuestions
        ? dateOpenQuestions.includes(DATA_ANONYMIZED)
        : false;

    if (isAnonymousResultDate) {
        openQuestionsResultDate = [["", "No available answer"]];
    } else {
        openQuestionsResultDate = dateOpenQuestions
            ? dateOpenQuestions.map((date) => {
                  return [
                      "",
                      `${milisecondsToDateStringResults(Number(date))}`,
                  ];
              })
            : [];
    }

    const openDateQuestionTitle = [
        `${questionIndex + 1}. ${nonFilterQuestions[questionIndex].title}`,
    ];
    return [openDateQuestionTitle, ...openQuestionsResultDate];
};

const timeResultItems = (
    resultWithQuestionType: AnswersWithQuestionType,
    selectedSurvey: SurveyClasses,
    nonFilterQuestions: Question<QuestionType>[],
    questionIndex: number
) => {
    let openQuestionsResultTime: string[][] = [];

    const timeOpenQuestions = Object.values(
        resultWithQuestionType.answers
    )[0]! as OpenQuestionAnswers;

    const isAnonymousResultTime = timeOpenQuestions
        ? timeOpenQuestions.includes(DATA_ANONYMIZED)
        : false;

    if (isAnonymousResultTime) {
        openQuestionsResultTime = [["", "No available answer"]];
    } else {
        openQuestionsResultTime = timeOpenQuestions
            ? timeOpenQuestions.map((time) => {
                  return ["", `${milisecondsToTimeString(Number(time))}`];
              })
            : [];
    }

    const openTimeQuestionTitle = [
        `${questionIndex + 1}. ${nonFilterQuestions[questionIndex].title}`,
    ];
    return [openTimeQuestionTitle, ...openQuestionsResultTime];
};

export const readyResultsDataExportToCSV = (
    allResultWithQuestionType: AnswersWithQuestionType[],
    selectedSurvey: SurveyClasses
): string[][][] => {
    const nonFilterQuestions = getNonFilterQuestionsFromSurvey(selectedSurvey);

    return allResultWithQuestionType.map((resultWithQuestionType, index) => {
        switch (resultWithQuestionType.questionType) {
            case "RATING":
                const isAnonymousAnswer =
                    resultWithQuestionType.answers[0] === DATA_ANONYMIZED;

                if (isAnonymousAnswer) {
                    return ratingTotalResultsItems(
                        "0",
                        "1",
                        1,
                        resultWithQuestionType,
                        nonFilterQuestions,
                        [],
                        index
                    );
                }

                const firstValue: string = resultWithQuestionType.answers[0]
                    ? Object.keys(resultWithQuestionType.answers[0])[0]
                    : "0";

                const lastValue = resultWithQuestionType.answers[0]
                    ? Object.keys(resultWithQuestionType.answers[0])[
                          Object.keys(resultWithQuestionType.answers[0])
                              .length - 2
                      ]
                    : "1";

                const totalLengthOfAvgAnswers = resultWithQuestionType
                    .answers[0]
                    ? Object.values(resultWithQuestionType.answers[0]).length
                    : 1;

                const copyAvgResultQuestions: QuestionToAnswer[] = JSON.parse(
                    JSON.stringify(resultWithQuestionType.answers)
                );

                const avgResultRow = averageResultsRow(
                    selectedSurvey,
                    copyAvgResultQuestions
                );

                return ratingTotalResultsItems(
                    firstValue,
                    lastValue,
                    totalLengthOfAvgAnswers,
                    resultWithQuestionType,
                    nonFilterQuestions,
                    avgResultRow,
                    index
                );

            case "SINGLE_CHOICE":
            case "MULTIPLE_CHOICE":
                console.log(resultWithQuestionType);

                const isAnonymousSingleMultipleAnswer =
                    resultWithQuestionType.answers[0] === DATA_ANONYMIZED;

                if (isAnonymousSingleMultipleAnswer) {
                    const SingleOrMultipleChoiceTitle = [
                        `${index + 1}. ${nonFilterQuestions[index].title}`,
                        `[Popular (No Data)]`,
                        "Data Anonymized",
                    ];
                    return [SingleOrMultipleChoiceTitle];
                }

                const totalLengthSingleOrMultipleChoiceAnswers =
                    resultWithQuestionType.answers[0]
                        ? Object.values(resultWithQuestionType.answers[0])
                              .length
                        : 1;
                const copySingleOrMultipleChoiceResultQuestions: QuestionToAnswer[] =
                    JSON.parse(JSON.stringify(resultWithQuestionType.answers));

                const singleOrMultipleChoiceResultRow =
                    singleOrMultipleChoiceResult(
                        copySingleOrMultipleChoiceResultQuestions
                    );

                const totalSingleOrMultipleChoiceResultsItem =
                    totalSingleOrMultipleChoiceResultsItems(
                        totalLengthSingleOrMultipleChoiceAnswers,
                        resultWithQuestionType
                    );

                const singleOrMultipleChoiceMostPopularKeyAndValue =
                    singleOrMultipleChoiceResultRow.map((choice, index) => {
                        if (!index) {
                            const value: number | string =
                                Object.values(choice)[0];
                            const key = Object.keys(choice);
                            return `${key}: ${value}`;
                        }
                        return `${choice}`;
                    });

                const SingleOrMultipleChoiceTitle = [
                    `${index + 1}. ${nonFilterQuestions[index].title}`,
                    `Popular (${singleOrMultipleChoiceMostPopularKeyAndValue[0]})`,
                    ...singleOrMultipleChoiceMostPopularKeyAndValue,
                ];
                return [
                    SingleOrMultipleChoiceTitle,
                    ...totalSingleOrMultipleChoiceResultsItem,
                ];
            case "LONG_TEXT":
            case "SHORT_TEXT":
                return shortAndLongTextResultItems(
                    resultWithQuestionType,
                    nonFilterQuestions,
                    index
                );
            case "DATE":
                return dateResultItems(
                    resultWithQuestionType,
                    selectedSurvey,
                    nonFilterQuestions,
                    index
                );

            case "TIME":
                return timeResultItems(
                    resultWithQuestionType,
                    selectedSurvey,
                    nonFilterQuestions,
                    index
                );
            default:
                return [];
        }
    });
};

export const readyCategoriesResultsDataExportToCSV = (
    categoriesList: UUID[],
    categoriesQuestionsMap: CategoriesQuestionsMap,
    categoriesResultsMap: CategoriesResultsRatingMap,
    resultsRow: ResultsTable,
    selectedSurvey: BaseSurvey
) => {
    const surveyAnonymityLevel = anonymityLimitation[selectedSurvey.anonimity];

    return categoriesList.map((categoryUUID) => {
        const categoryResult = categoriesResultsMap[categoryUUID];
        const { categoryName, questions } =
            categoriesQuestionsMap[categoryUUID];

        const ratingQuestions: Question<RatingQuestion>[] = questions.filter(
            ({ question_type_name }) => question_type_name === "RATING"
        ) as Question<RatingQuestion>[];

        const ratingRage = ratingQuestions.length
            ? `Avg (${getRatingRange(ratingQuestions)})`
            : "";

        const questionsResultData = questions.map((question) => {
            const resultsQuestion: AnswersWithQuestionType = {
                questionType: question.question_type_name,
                answers: resultsRow
                    ? Object.values(resultsRow?.[question.uuid])
                    : [],
            };

            let questionTypeName =
                QUESTION_TYPE_TO_TYPE_NAME[question.question_type_name];

            switch (resultsQuestion.questionType) {
                case "RATING":
                    questionTypeName = ratingRage;
                    const copyResultQuestions: QuestionToAnswer[] = JSON.parse(
                        JSON.stringify(resultsQuestion.answers)
                    );

                    const resultQuestionWithNoAnswerKey =
                        copyResultQuestions.map((resultRow) => {
                            //@ts-ignore
                            delete resultRow[NO_ANSWER_KEY];
                            return resultRow;
                        });

                    const ratingResult = resultQuestionWithNoAnswerKey.map(
                        (questionResult) => {
                            if (questionResult === DATA_ANONYMIZED) {
                                return `< ${surveyAnonymityLevel}`;
                            }

                            const avgResultValue = getAvgForRatingTypeColumn(
                                questionResult as QuestionToAnswerDescreteQuestion
                            );
                            return avgResultValue
                                ? avgResultValue.toFixed(1)
                                : "";
                        }
                    );

                    return [question.title, questionTypeName, ...ratingResult];

                case "SINGLE_CHOICE":
                case "MULTIPLE_CHOICE":
                    const copyResultSingleOrMultipleQuestions: QuestionToAnswer[] =
                        JSON.parse(JSON.stringify(resultsQuestion.answers));

                    const resultSingleOrMultipleQuestionWithNoAnswerKey =
                        copyResultSingleOrMultipleQuestions.map((resultRow) => {
                            //@ts-ignore
                            delete resultRow[NO_ANSWER_KEY];
                            return resultRow;
                        });

                    const singleOrMultipleResults =
                        resultSingleOrMultipleQuestionWithNoAnswerKey.map(
                            (questionResult) => {
                                const mostPopular = getMostPopular(
                                    questionResult as QuestionToAnswer
                                );
                                return mostPopular ? mostPopular : "";
                            }
                        );

                    const mostPopularKey = Object.keys(
                        singleOrMultipleResults[0]
                    )[0];

                    const mostPopularOption = `Popular (${Object.keys(
                        singleOrMultipleResults[0]
                    )}: ${Object.values(singleOrMultipleResults[0])})`;

                    const mostPopularValues: (string | number)[] =
                        resultSingleOrMultipleQuestionWithNoAnswerKey.map(
                            (resultRow) => {
                                if (resultRow === DATA_ANONYMIZED) {
                                    return `< ${surveyAnonymityLevel}`;
                                }

                                //@ts-ignore
                                return resultRow[mostPopularKey];
                            }
                        );

                    return [
                        question.title,
                        mostPopularOption,
                        ...mostPopularValues,
                    ];
                default:
                    return [question.title, questionTypeName];
            }
        });

        const isAnonymityLevel =
            anonymityLimitation[selectedSurvey.anonimity] !== 0;

        const categoriesResultsValue = categoryResult
            ? Object.values(categoryResult).map((resultValue) => {
                  if (resultValue === DATA_ANONYMIZED) {
                      return `< ${surveyAnonymityLevel}`;
                  }
                  return resultValue;
              })
            : [];

        return [
            [categoryName, ratingRage, ...categoriesResultsValue],
            ...questionsResultData,
            [],
        ];
    });
};
