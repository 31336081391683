import React, { SVGAttributes } from "react";

export const BookmarkIcon = (props: SVGAttributes<HTMLOrSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="25"
        fill="none"
        viewBox="0 0 22 25"
        {...props}
    >
        <path
            fill="#009788"
            d="M19.137 24a2.798 2.798 0 01-1.987-.835L11 17.051l-6.15 6.118a2.8 2.8 0 01-3.095.609A2.8 2.8 0 010 21.154V5a5 5 0 015-5h12a5 5 0 015 5v16.154a2.8 2.8 0 01-1.75 2.624c-.353.147-.731.223-1.113.222zM5 2a3 3 0 00-3 3v16.154a.843.843 0 001.437.6l6.863-6.821a1 1 0 011.41 0l6.855 6.819a.842.842 0 001.437-.6V5a3 3 0 00-3-3H5z"
        />
    </svg>
);
