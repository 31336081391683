import type {
    Category,
    ValidationResult,
    ValidationType
} from "@difftone/types";
import {
    validateCategoryName,
    validateCategoryIsDeleted,
    validateCategoryArchiveProp,
    validateCategoryScope,
} from "./properties-validations";
import { validateCategoryNameUniqueness } from "./uniqueness-validations";

// Category
export const validateCategory = (
    category: Category
): ValidationResult<ValidationType>[] => {
    const res: ValidationResult<ValidationType>[] = [];

    res.push(
        ...validateCategoryName(category),
        ...validateCategoryIsDeleted(category),
        ...validateCategoryArchiveProp(category),
        ...validateCategoryScope(category)
    );

    return res;
};

export const validateCategoriesUniqueness = (
    categories: Category[]
): ValidationResult<ValidationType>[] => {
    return [
        ...validateCategoryNameUniqueness(categories)
    ];
};

export const validateCategories = (
    categories: Category[]
): ValidationResult<ValidationType>[] => {
    const res: ValidationResult<ValidationType>[] = [];

    categories.forEach(
        (category) => res.push(...validateCategory(category))
    );

    res.push(...validateCategoriesUniqueness(categories));
    
    return res;
};