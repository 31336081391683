type PalletObject = {
    [x: number]: string;
};

const DARK_GREEN = "#28a164";
const GREEN = "#9cd2b8";
const GRAY = "#f0f3f5";
const PINK = "#f4bfc0";
const RED = "#f35555";

export const colorFactoryByScoreResult = (
    scoreResultsLength: number
): PalletObject => {
    switch (scoreResultsLength) {
        case 2:
            return {
                0: GREEN,
                1: PINK,
            };
        case 3:
            return {
                0: GREEN,
                1: GRAY,
                2: PINK,
            };
        case 4:
            return {
                0: DARK_GREEN,
                1: GREEN,
                2: PINK,
                3: RED,
            };
        case 5:
            return {
                0: DARK_GREEN,
                1: GREEN,
                2: GRAY,
                3: PINK,
                4: RED,
            };
        default:
            throw Error(
                `Number of colors not supported, found ${scoreResultsLength}`
            );
    }
};
