import { MOBILE_WIDTH_BREAKPOINT } from "@difftone/constants";
import React, { ReactNode } from "react";

import {
    wrapper_required_input,
    wrapper_no_required_input,
    required_input,
    not_required_input,
} from "./required-input-wrapper.module.css";

export type RequiredInputWrapperProps = {
    children: ReactNode;
    required: boolean;
};

export const RequiredInputWrapper = (props: RequiredInputWrapperProps) => {
    const { children, required } = props;

    const isMobile = window.innerWidth <= MOBILE_WIDTH_BREAKPOINT;

    return (
        <div
            className={
                required ? wrapper_required_input : wrapper_no_required_input
            }
        >
            {isMobile ? null : (
                <div className={required ? required_input : not_required_input}>
                    Required*
                </div>
            )}
            {children}
        </div>
    );
};
