import React, { ChangeEvent } from "react";
import { observer } from "mobx-react";

import {
    RESPONDENTS_SYSTEM_HEADER,
    simpleSurveyResultsStore,
} from "@difftone/stores";
import {
    clearSimpleResultsSortingProfile,
    resetSearchProfile,
    setSearchProfileByKey,
    setSimpleResultsFitlerProfile,
    setSimpleResultsFitlerProfileFocusedColumn,
    setSimpleResultsSortingProfile,
} from "@difftone/actions";
import {
    SimpleResultsFilterButton,
    SimpleResultsSortingActionButton,
} from "@difftone/shared-components";
import {
    SimpleResultsFilterProfile,
    SimpleResultsSortingProfile,
} from "@difftone/types";
import clearSearchIcon from "@difftone/assets/clear-search-bar-icon.svg";

import {
    responders_header,
    filter_button,
    sorting_button,
    repondents_text,
    respondent_search,
    clear_responder_search,
} from "./responders-header.module.css";

export type RespondersHeaderProps = {};

export const RespondersHeader = observer(() => {
    const { filterProfile, sortingProfile, searchProfile, selectedQuestion } =
        simpleSurveyResultsStore;

    const onMouseOverHandler = () => {
        setSimpleResultsFitlerProfileFocusedColumn(RESPONDENTS_SYSTEM_HEADER);
    };

    const onMouseLeaveHandler = () => {
        setSimpleResultsFitlerProfileFocusedColumn("");
    };

    const onClickHandler = () => {
        const copyOfFilterProfile: SimpleResultsFilterProfile = {
            ...filterProfile,
        };
        const copyOfSortingProfile: SimpleResultsSortingProfile = {
            ...sortingProfile,
        };

        copyOfFilterProfile.filterdColumnKey =
            copyOfFilterProfile.focusedColumnKey;
        copyOfSortingProfile.sortBy = "TEXT";

        // First sort click
        if (sortingProfile.sortBy !== "TEXT") {
            copyOfSortingProfile.direction = "ASCENDING";
            setSimpleResultsFitlerProfile(copyOfFilterProfile);
            setSimpleResultsSortingProfile(copyOfSortingProfile);

            return;
        }

        // Second sort click
        if (copyOfSortingProfile.direction === "ASCENDING") {
            copyOfSortingProfile.direction = "DESCENDING";
            setSimpleResultsSortingProfile(copyOfSortingProfile);

            return;
        }

        // Third sort click
        if (copyOfSortingProfile.direction === "DESCENDING") {
            setSimpleResultsFitlerProfile({
                ...copyOfFilterProfile,
                filterdColumnKey: "",
                focusedColumnKey: "",
            });
            clearSimpleResultsSortingProfile();

            return;
        }
    };

    const onRespondentSearchInputChanged = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setSearchProfileByKey(RESPONDENTS_SYSTEM_HEADER, event.target.value);
    };

    const _isVisible =
        filterProfile.focusedColumnKey === RESPONDENTS_SYSTEM_HEADER;

    const _isActive =
        filterProfile.filterdColumnKey === RESPONDENTS_SYSTEM_HEADER;

    return (
        <div
            onMouseLeave={onMouseLeaveHandler}
            onMouseOver={onMouseOverHandler}
            className={responders_header}
        >
            <div className={repondents_text}>Respondents</div>
            <input
                onChange={onRespondentSearchInputChanged}
                value={searchProfile[RESPONDENTS_SYSTEM_HEADER] || ""}
                placeholder="Find a respondent.."
                className={respondent_search}
                type="text"
            />
            {searchProfile[RESPONDENTS_SYSTEM_HEADER] ? (
                <img
                    onClick={resetSearchProfile}
                    className={clear_responder_search}
                    src={clearSearchIcon}
                    alt=""
                />
            ) : null}
            <div className={sorting_button}>
                <SimpleResultsSortingActionButton
                    onClickHandler={onClickHandler}
                    direction={sortingProfile.direction}
                    isVisible={_isVisible || _isActive}
                    isActive={_isActive}
                />
            </div>
            <div className={filter_button}>
                <SimpleResultsFilterButton
                    isVisible={_isVisible || _isActive}
                    columnKey={RESPONDENTS_SYSTEM_HEADER}
                    isFiltered={
                        !!filterProfile.filterdProfilesMap[
                            `${selectedQuestion},${RESPONDENTS_SYSTEM_HEADER}`
                        ]?.length
                    }
                />
            </div>
        </div>
    );
});
