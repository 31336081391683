import { makeAutoObservable, observable, ObservableMap } from "mobx";
import {
    TimeRangesKey,
    TimeRangesContainer,
    TimeCompareUnits,
    UUID,
} from "@difftone/types";
import { timeRangesBySurveyUuidAndUnits } from "./time-ranges-internal-actions";

class TimeRangesStore {
    constructor() {
        makeAutoObservable<TimeRangesStore, "_fetchedTimeRangesKeys">(this, {
            _fetchedTimeRangesKeys: false,
        });
    }

    private getKey = (
        surveyUuid: UUID,
        timeUnit: TimeCompareUnits
    ): TimeRangesKey => {
        return `${surveyUuid}-${timeUnit}`;
    };

    private _fetchedTimeRangesKeys: TimeRangesKey[] = [];

    private _timeRangesMap: ObservableMap<
        TimeRangesKey,
        TimeRangesContainer | null
    > = observable.map({});

    public extractKeyForTimeRange = (
        surveyUuid: UUID,
        timeUnits: TimeCompareUnits
    ) => {
        const _key = this.getKey(surveyUuid, timeUnits);
        const _timeRangesContainer = this._timeRangesMap.get(_key);
        if (
            _timeRangesContainer === undefined &&
            !this._fetchedTimeRangesKeys.includes(_key)
        ) {
            this._fetchedTimeRangesKeys.push(_key);
            timeRangesBySurveyUuidAndUnits(surveyUuid, timeUnits);
        }

        return _key;
    };

    public getTimeRangeByKey = (timeRangeKey: string) => {
        const _timeRangesContainer = this._timeRangesMap.get(timeRangeKey);
        return _timeRangesContainer;
    };

    public getTimeRangesContainer(
        surveyUuid: UUID,
        timeUnits: TimeCompareUnits
    ) {
        const _key = this.getKey(surveyUuid, timeUnits);

        const _timeRangesContainer = this._timeRangesMap.get(_key);

        if (
            _timeRangesContainer === undefined &&
            !this._fetchedTimeRangesKeys.includes(_key)
        ) {
            this._fetchedTimeRangesKeys.push(_key);
            timeRangesBySurveyUuidAndUnits(surveyUuid, timeUnits);
        }

        return _timeRangesContainer;
    }

    public addTimeRangesContainer(newTimeRangesContainer: TimeRangesContainer) {
        const _key = this.getKey(
            newTimeRangesContainer.surveyUuid,
            newTimeRangesContainer.timeUnit
        );
        this._timeRangesMap.merge({ [_key]: newTimeRangesContainer });
    }
}

export const timeRangesStore = new TimeRangesStore();
