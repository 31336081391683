import { ResultsMap } from "@difftone/types";

//TODO: type should move to the data model
import { ResultsTable } from "@difftone/stores";

export const createResultsTableFromResultsMaps = (
    resultsMaps: ResultsMap[]
) => {
    return convertResultsMapsToResultsTable(resultsMaps);
};

const convertResultsMapsToResultsTable = (resultsMaps: ResultsMap[]) => {
    const tableAsRows: ResultsTable = {};

    resultsMaps.forEach((resultsMap, index) => {
        Object.keys(resultsMap).forEach((questionUUID) => {
            tableAsRows[questionUUID] = tableAsRows[questionUUID] || {};
            const currentResultsMap = resultsMaps[index];

            tableAsRows[questionUUID][`Column_${index}`] =
                currentResultsMap[questionUUID];
        });
    });
    return tableAsRows;
};
