import React from "react";
import {
    DIFFTONE_PRIVACY_POLICY_URL,
    DIFFTONE_TERMS_OF_USE_URL,
} from "@difftone/constants";

import {
    difftone_legal,
    title,
    legal_links,
} from "./difftone-legal.module.css";

export type DifftoneLegalProps = {};

export const DifftoneLegal = () => {
    const onPrivacyPolicyClicked = () => {
        window.open(DIFFTONE_PRIVACY_POLICY_URL);
    };

    const onTermsOfUseClicked = () => {
        window.open(DIFFTONE_TERMS_OF_USE_URL);
    };
    return (
        <div className={difftone_legal}>
            <div className={title}>Legal</div>
            <div className={legal_links}>
                <div onClick={onPrivacyPolicyClicked}>Privacy Policy</div>
                <div onClick={onTermsOfUseClicked}>Terms Of Use</div>
            </div>
        </div>
    );
};
