import { TIME_COMPARE_FILTERS, TIME_FILTER_ID } from "@difftone/constants";
import {
    Question,
    QuestionType,
    ResultsFilter,
    TimeCompareUnits,
    TimeRange,
} from "@difftone/types";

export const createTimeCompareFilter = (
    timeCompareParam: TimeCompareUnits,
    selectedCompareIndexCount: number,
    timeRanges: TimeRange[]
): ResultsFilter => {
    const timeFilter: ResultsFilter = {
        filterKey: createTimeFilterAsQuestion(),
        filterValues: timeRanges,
        indexInComparePlain: selectedCompareIndexCount,
        isPartOfComparedPlain: timeCompareParam !== "NONE" ? true : false,
        unit: timeCompareParam,
    };
    return timeFilter;
};

export const createTimeFilterAsQuestion = () => {
    const timeFilterAsQuestion: Question<QuestionType> = {
        question_type_name: "SYSTEM_ANSWER_TIME_FRAME",
        content: {
            text: "",
            choice_options: TIME_COMPARE_FILTERS,
        },
        nickname: "Time",
        number: 1,
        required: true,
        title: "Time",
        uuid: TIME_FILTER_ID,
        version: "0.0.2",
        categories: [],
        allow_comments: false,
        is_filter: true,
    };

    return timeFilterAsQuestion;
};
