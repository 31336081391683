import React from "react";
import syncingIcon from "@difftone/assets/syncing-header-status.svg";

import {
    syncing_status,
    under_hover,
    top_triangle,
} from "./syncing-status.module.css";

export const SyncingStatus = () => {
    return (
        <div className={syncing_status}>
            <img src={syncingIcon} alt="saved" />
            <span>Your changes are being saved</span>
            <div className={under_hover}>
                <div className={top_triangle}></div>
                Your changes are currently being automatically saved
            </div>
        </div>
    );
};
