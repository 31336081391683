import { makeAutoObservable } from "mobx";
import { BaseSurvey, SurveyResponse, EmailAddress } from "@difftone/types";
import {
    checkIfEmailIsGroup,
    updateSurveyResponses,
} from "./results-store-internal-actions";
import { showDifftoneAlert } from "@difftone/actions";

class ResultsDataStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _surveyResponses: SurveyResponse[] | null = null;
    public getSurveyResponses(survey: BaseSurvey): SurveyResponse[] {
        if (this._surveyResponses === null) {
            updateSurveyResponses(survey);
            this._surveyResponses = [];
        }
        return this._surveyResponses;
    }
    set surveyResponses(newSurveyResponses: SurveyResponse[]) {
        this._surveyResponses = newSurveyResponses;
    }

    private _shareResultEmailList: EmailAddress[] = [];

    get shareResultEmailList(): EmailAddress[] {
        return this._shareResultEmailList;
    }

    set shareResultEmailList(emailList: EmailAddress[]) {
        this._shareResultEmailList = emailList;
    }

    public async addEmailsToSharedResult(emails: EmailAddress[]) {
        const emailsToAdd = await this.getEmailsToAddToSharedResult(emails);
        if (!emailsToAdd) {
            showDifftoneAlert(
                "Unable to retrieve group email members",
                "FAILURE"
            );
            return;
        }

        this._shareResultEmailList = Array.from(
            new Set([...this._shareResultEmailList, ...emailsToAdd])
        );
    }

    private async getEmailsToAddToSharedResult(
        emails: EmailAddress[]
    ): Promise<EmailAddress[] | null> {
        const processedEmailPromises = [];
        const processEmail = async (email: EmailAddress) => {
            const isGroupEmail = await checkIfEmailIsGroup(email);
            if (isGroupEmail) {
                return [];
            } else {
                return [email];
            }
        };
        for (const email of emails) {
            processedEmailPromises.push(processEmail(email));
        }

        const processedEmails = await Promise.all(processedEmailPromises);
        return processedEmails.flat();
    }

    public clearStore = () => {
        this._surveyResponses = null;
        this._shareResultEmailList = [];
    };
}

export const resultsDataStore = new ResultsDataStore();
