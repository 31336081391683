import React, { MouseEvent, useEffect } from "react";
import { observer } from "mobx-react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import {
    setFocusedWizardInputCard,
    executeScrollOnfocus,
    setIsInitialQuestionsSectionScroll,
} from "@difftone/actions";
import {
    MobileWizardActionsMenu,
    QuestionTypeIndicator,
    RequiredInputWrapper,
} from "@difftone/shared-components";
import { Question, QuestionType, BaseSurvey } from "@difftone/types";
import { MOBILE_WIDTH_BREAKPOINT } from "@difftone/constants";
import { wizardStore, wizardDisplayStore } from "@difftone/stores";

import {
    ExpandedQuestionBody,
    QuestionNickname,
    QuestionsOptions,
    QuestionTitle,
} from "./components";

import dragDrop from "@difftone/assets/drag-drop.svg";
import {
    question_wrapper,
    question_header,
    expanded_area,
    mobile_actions_menu_position,
    question_wrapper_focus_line,
    required_question_indicator,
    drag_drop,
} from "./survey-question.module.css";

const OFFSET_TOP_PIXELS_OF_AUTO_SCROLL = 280;

const OFFSET_TOP_BEGINNING = 0;

export type SurveyQuestionProps = {
    questionNumber: number;
    question: Question<QuestionType>;
    collapsed: boolean;
    survey: BaseSurvey;
    dragHandlerProps: DraggableProvidedDragHandleProps | undefined;
};

export const SurveyQuestion = observer((props: SurveyQuestionProps) => {
    const { focusedWizardInputCard } = wizardStore;
    const { isInitialQuestionsSectionScroll } = wizardDisplayStore;
    const { questionNumber, question, collapsed, survey, dragHandlerProps } =
        props;
    const accordionWrapper = document.querySelector(
        `div[data-scroll-element="accordion-wrapper"]`
    ) as HTMLElement;

    const isFocused =
        focusedWizardInputCard?.getAttribute("uuid") === question.uuid;

    //UseEffect for the first focus of the question element
    useEffect(() => {
        if (isFocused && accordionWrapper) {
            executeScrollOnfocus(
                accordionWrapper,
                focusedWizardInputCard as HTMLDivElement,
                OFFSET_TOP_PIXELS_OF_AUTO_SCROLL,
                isInitialQuestionsSectionScroll
            );

            if (isInitialQuestionsSectionScroll) {
                setIsInitialQuestionsSectionScroll(false);
            }
        }
    }, [
        isFocused,
        accordionWrapper,
        focusedWizardInputCard,
        isInitialQuestionsSectionScroll,
    ]);

    const onClickHandler = (event: MouseEvent<HTMLDivElement>) => {
        const currentElement = event.currentTarget;

        if (currentElement && currentElement !== focusedWizardInputCard) {
            setFocusedWizardInputCard(currentElement);
            executeScrollOnfocus(
                accordionWrapper!,
                currentElement,
                OFFSET_TOP_PIXELS_OF_AUTO_SCROLL
            );
        }
    };

    useEffect(() => {
        const currentElement = document.querySelector(
            `div[data-focus-wizard-input-card-id="${question.uuid}"]`
        );
        const inputTextArea = document.querySelector(
            `textarea[data-textarea-question-input-title="${question.uuid}"]`
        ) as HTMLTextAreaElement;

        const inputText = document.querySelector(
            `input[data-textarea-question-booking-input-title="${question.uuid}"]`
        ) as HTMLTextAreaElement;

        if (questionNumber === 1) {
            setFocusedWizardInputCard(currentElement as HTMLElement);
            inputTextArea?.select();
            inputText?.select();
        }

        // eslint-disable-next-line
    }, []);

    const focusedTrackerIndexProps = {
        index: questionNumber - 1,
        uuid: question.uuid,
    };

    const isMobile = window.innerWidth <= MOBILE_WIDTH_BREAKPOINT;

    return (
        <>
            <div
                {...focusedTrackerIndexProps}
                onClick={onClickHandler}
                tabIndex={0}
                data-focus-wizard-input-card-id={question.uuid}
                data-wizard-question-card
                className={question_wrapper}
            >
                {isFocused ? (
                    <div className={question_wrapper_focus_line}></div>
                ) : null}
                <div className={question_header}>
                    <span id="question-index">{`${question.number}.`}</span>

                    <RequiredInputWrapper required={question.required}>
                        <QuestionTitle
                            collapsed={collapsed}
                            question={question}
                        />
                    </RequiredInputWrapper>
                    <QuestionTypeIndicator
                        collapsed={false}
                        question={question}
                    />
                </div>
                {question.is_filter ? (
                    <QuestionNickname question={question} />
                ) : null}
                {!collapsed ? (
                    <div className={expanded_area}>
                        <ExpandedQuestionBody
                            question={question}
                            isDisabled={true}
                            questionNumber={questionNumber}
                            survey={survey}
                        />
                    </div>
                ) : null}
                <QuestionsOptions question={question} survey={survey} />
                {isMobile ? (
                    question.required ? (
                        <div className={required_question_indicator}>
                            * This will be required question
                        </div>
                    ) : null
                ) : null}
                <span className={drag_drop} {...dragHandlerProps}>
                    <img alt="drag drop" src={dragDrop} />
                </span>
            </div>
            {isFocused ? (
                <div className={mobile_actions_menu_position}>
                    <MobileWizardActionsMenu />
                </div>
            ) : null}
        </>
    );
});
