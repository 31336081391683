import React, { ChangeEvent } from "react";
import { DetailsTitle } from "@difftone/types";

import {
    item_title,
    checked_style,
    custom_checkbox_class,
    text_label,
} from "./details-item.module.css";

export type DetailsItemProps = {
    title: DetailsTitle;
    value: boolean;
    detailsKey: string;
    onToggleDetailsItem: (detailsKey: string, selected: boolean) => void;
};

export const DetailsItem = (props: DetailsItemProps) => {
    const { title, value, detailsKey, onToggleDetailsItem } = props;

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        onToggleDetailsItem(detailsKey, isChecked);
    };

    return (
        <div className={item_title}>
            <label className={value ? checked_style : custom_checkbox_class}>
                <input type="checkbox" onChange={onChange} checked={value} />
            </label>
            <label className={text_label}>{title}</label>
        </div>
    );
};
