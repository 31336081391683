import React, { useState } from "react";
import { Hamburger } from "@difftone/shared-components";
import { setLocation } from "@difftone/actions";
import { navigationStore } from "@difftone/stores";
import { INBOX_URL } from "@difftone/constants";
import { SearchIcon } from "@difftone/shared-components";

import searchIcon from "@difftone/assets/mobile-header-search-icon.svg";
import searcIconActive from "@difftone/assets/mobile-header-search-icon-active.svg";

import {
    OverallStatusBar,
    UserLabel,
    MobileSearchBar,
    MobileUserDialog,
} from "./components";

import {
    mobile_header,
    search_icon,
    header_difftone_title,
    collapsed_header_wrapper,
} from "./mobile-header.module.css";

const COLOR_GREEN = "GREEN";
const COLOR_GRAY = "GRAY";

export const MobileHeader = () => {
    const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
    const [isUserDialogOpen, setIsUserDialogOpen] = useState(false);

    const toggleSearchBar = () => {
        setIsSearchBarOpen(!isSearchBarOpen);
    };

    const showUserDialog = () => {
        setIsUserDialogOpen(true);
    };

    const hideUserDialog = () => {
        setIsUserDialogOpen(false);
    };

    const onHeaderLogoClicked = () => {
        setLocation(INBOX_URL);
    };

    return (
        <div className={mobile_header}>
            <div className={collapsed_header_wrapper}>
                <Hamburger />
                {navigationStore.currentPage.includes("inbox") ? (
                    <SearchIcon
                        onClick={toggleSearchBar}
                        color={isSearchBarOpen ? COLOR_GREEN : COLOR_GRAY}
                        className={search_icon}
                    />
                ) : null}
                <span className={header_difftone_title}>difftone</span>
                <OverallStatusBar currentStatus="SAVED" />
                <UserLabel
                    showUserDialog={showUserDialog}
                    hideUserDialog={hideUserDialog}
                />
            </div>
            {isSearchBarOpen ? <MobileSearchBar /> : null}
            {isUserDialogOpen ? <MobileUserDialog /> : null}
        </div>
    );
};
