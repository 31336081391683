import { getApiBaseUrl, getWebClientBaseUrl } from "@difftone/constants";
import {
    EmailAddress,
    SharedSurvey,
    SharedSurveyToSend,
    SharedSurveyWithLink,
    AuthData,
    UUID,
} from "@difftone/types";
import { errorHandlerProxy, localstorageUtils } from "@difftone/procedures";
import { createDifftoneAxios } from "@difftone/services";

const SERVER_BASE_URL = `${getApiBaseUrl()}/shared-survey-service`;
const LINK_SHARED_SURVEY = `${getWebClientBaseUrl()}/inbox/sharedsurveys`;

export const saveSharedSurveysToApi = async (
    senderSharedSurvey: SharedSurvey,
    sharedSurveys: SharedSurvey[]
): Promise<SharedSurvey[]> => {
    const currentAuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const sharedSurveysWithLink = sharedSurveys.map((sharedSurvey) => {
        const surveyToShare: SharedSurveyWithLink = {
            shared_survey: sharedSurvey,
            link_to_shared_survey: LINK_SHARED_SURVEY,
        };
        return surveyToShare;
    });

    const sharedSurveyToSend: SharedSurveyToSend = {
        sender_shared_survey: senderSharedSurvey,
        shared_surveys_to_addresses: sharedSurveysWithLink,
    };

    const response = await difftoneAxios.post(
        `${SERVER_BASE_URL}/`,
        {
            shared_surveys_to_send: [sharedSurveyToSend],
        },
        {
            params: { issuer: currentAuthData.issuer },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.results;
    return resource;
};

export const getSharedSurveysByDistributer = async (
    distributer: UUID
): Promise<SharedSurvey[]> => {
    const currentAuthData: AuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(
        `${SERVER_BASE_URL}/distributer/${distributer}`,
        {
            params: { issuer: currentAuthData.issuer },
        }
    );
    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.resources;
    return resource;
};

export const getSharedSurveysByEmail = async (
    userEmail: EmailAddress
): Promise<SharedSurvey[]> => {
    const currentAuthData: AuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.get(
        `${SERVER_BASE_URL}/addressee/${userEmail}`,
        {
            params: { issuer: currentAuthData.issuer },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.resources;
    return resource;
};

export const updateSharedSurveyToApi = async (
    sharedSurvey: SharedSurvey
): Promise<SharedSurvey> => {
    const currentAuthData: AuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const response = await difftoneAxios.put(
        `${SERVER_BASE_URL}/`,
        {
            shared_surveys: [sharedSurvey],
        },
        {
            params: { issuer: currentAuthData.issuer },
        }
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resourceData = JSON.parse(response.data.results);

    const resource = resourceData[0].data;

    return resource;
};

export const getSharedSurveyFromApiByUserEmailAndUUIDs = async (
    userEmail: EmailAddress,
    sharedSurveyUUIDs: UUID[]
): Promise<SharedSurvey[]> => {
    const currentAuthData: AuthData =
        await localstorageUtils.asyncAuthDataFromLocalstorageAuthData();

    const difftoneAxios = createDifftoneAxios(currentAuthData);

    const UUIDsListStr = JSON.stringify(sharedSurveyUUIDs);

    const response = await difftoneAxios.get(
        `${SERVER_BASE_URL}/sharedSurvey/${userEmail}?issuer=${currentAuthData.issuer}&sharedSurveys_UUIDs=${UUIDsListStr}`
    );

    if (response.data.error) {
        errorHandlerProxy({
            error: response.data.error,
            code: response.data.statusCode,
        });
    }

    const resource = response.data.resources;

    const dataArray: SharedSurvey[] = Object.keys(resource).map(
        (key) => resource[key].data
    );

    return dataArray;
};
