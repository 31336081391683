import React, { useState } from "react";
import clsx from "clsx";

import compareSwitchIcon from "@difftone/assets/results-compare-switch-icon.svg";
import {
    option_wrapper,
    compare_option,
    active_class,
    compare_option_hover,
    enabled_compare_option,
    disabled_compare_option,
} from "./compare-item.module.css";

export type CompareItemProps = {
    isSelected: boolean;
    children: React.ReactNode;
    popCompareHandler: () => void;
    addCompareHandler: () => void;
    switchComparesPositions: () => void;
    isDisabled: boolean;
};

export const CompareItem = (props: CompareItemProps) => {
    const {
        isSelected,
        children,
        popCompareHandler,
        addCompareHandler,
        switchComparesPositions,
        isDisabled,
    } = props;

    const [isHovered, setIsHovered] = useState(false);

    const onHoverHandler = () => {
        setIsHovered(true);
    };

    const onLeaveHandler = () => {
        setIsHovered(false);
    };

    return (
        <div className={option_wrapper}>
            <div
                onMouseEnter={onHoverHandler}
                onMouseLeave={onLeaveHandler}
                onClick={isSelected ? popCompareHandler : addCompareHandler}
                className={clsx(compare_option, {
                    [enabled_compare_option]: !isDisabled,
                    [disabled_compare_option]: isDisabled,
                    [active_class]: !isDisabled && isSelected,
                    [compare_option_hover]: !isDisabled && isHovered,
                })}
            >
                {children}
            </div>
            {isSelected ? (
                <img
                    onClick={switchComparesPositions}
                    src={compareSwitchIcon}
                    alt="compare-icon"
                />
            ) : null}
        </div>
    );
};
