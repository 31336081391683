import {
    OptionalPropertiesForDuplicateSurvey,
    SurveyContainer,
    SurveyClasses,
    OngoingSurvey,
    SimpleSurvey,
    UUID,
    Question,
    QuestionType,
    SurveyEvent,
    EventProperties,
} from "@difftone/types";

import {
    deserializeSurvey,
    serializeSurvey,
    surveysUuidReplacer,
    createOngoingSurvey,
    createSimpleSurvey,
} from "@difftone/frontend-common";

import { createDefaultEmptySystemEvent, getNewEmptyQuestion } from "./factory";

export const duplicateSurvey = (
    surveyContainer: SurveyContainer<SurveyClasses>,
    organizationUuid: UUID,
    duplicateOptionalProperties?: OptionalPropertiesForDuplicateSurvey
): SurveyContainer<SurveyClasses> => {
    const _serializedSurvey = serializeSurvey(surveyContainer);
    const _surveyContainer = deserializeSurvey(_serializedSurvey);
    const _surveyContainerCopy = surveysUuidReplacer(_surveyContainer);

    if (!duplicateOptionalProperties) {
        return _surveyContainerCopy;
    }

    return duplicateSurveyWithOptionalProperties(
        _surveyContainerCopy,
        duplicateOptionalProperties,
        organizationUuid
    );
};

const duplicateSurveyWithOptionalProperties = (
    surveyContainer: SurveyContainer<SurveyClasses>,
    duplicateOptionalProperties: OptionalPropertiesForDuplicateSurvey,
    organizationUuid: UUID
): SurveyContainer<SurveyClasses> => {
    switch (surveyContainer.survey.survey_class) {
        case "ONGOING":
            const ongoingSurveyContainer =
                surveyContainer as SurveyContainer<OngoingSurvey>;
            return duplicateOngoingSurveyWithOptionalProperties(
                ongoingSurveyContainer,
                duplicateOptionalProperties,
                organizationUuid
            );

        case "SIMPLE":
        default:
            const simpleSurveyContainer =
                surveyContainer as SurveyContainer<SimpleSurvey>;
            return duplicateSimpleSurveyWithOptionalProperties(
                simpleSurveyContainer,
                duplicateOptionalProperties,
                organizationUuid
            );
    }
};

const duplicateOngoingSurveyWithOptionalProperties = (
    surveyContainer: SurveyContainer<OngoingSurvey>,
    duplicateOptionalProperties: OptionalPropertiesForDuplicateSurvey,
    organizationUuid: UUID
) => {
    const _organizationUuid = organizationUuid;
    if (!_organizationUuid) {
        throw Error(
            "[Difftone]:: Conn't duplicate survey with out organization uuid"
        );
    }
    const _emptyOngoingSurvey = createOngoingSurvey(_organizationUuid);

    Object.keys(duplicateOptionalProperties).forEach((key) => {
        switch (key) {
            case "purpose":
                surveyContainer.survey.purpose = duplicateOptionalProperties[
                    key
                ]
                    ? surveyContainer.survey.purpose
                    : _emptyOngoingSurvey.purpose;
                return;

            case "anonymity":
                surveyContainer.survey.anonimity = duplicateOptionalProperties[
                    key
                ]
                    ? surveyContainer.survey.anonimity
                    : _emptyOngoingSurvey.anonimity;
                return;
            case "participants":
                surveyContainer.survey.respondents =
                    duplicateOptionalProperties[key]
                        ? surveyContainer.survey.respondents
                        : [];
                return;
            case "introduction":
                surveyContainer.survey.participant_introduction =
                    duplicateOptionalProperties[key]
                        ? surveyContainer.survey.participant_introduction
                        : "";
                return;
            case "questionsUuids":
                surveyContainer.survey.questions = checkQuestionsForDuplication(
                    duplicateOptionalProperties[key],
                    surveyContainer
                );
                return;
            default:
                return;
        }
    });
    return surveyContainer;
};

const checkQuestionsForDuplication = (
    questions: UUID[],
    surveyContainer: SurveyContainer<SurveyClasses>
): Question<QuestionType>[] => {
    const _questions = questions.flatMap((uuid) => {
        return surveyContainer.survey.questions.filter(
            (question) => question.uuid === uuid
        );
    });

    return _questions.length !== 0 ? _questions : [getNewEmptyQuestion(1)];
};

const duplicateSimpleSurveyWithOptionalProperties = (
    surveyContainer: SurveyContainer<SimpleSurvey>,
    duplicateOptionalProperties: OptionalPropertiesForDuplicateSurvey,
    organizationUuid: UUID
) => {
    const _organizationUuid = organizationUuid;
    if (!_organizationUuid) {
        throw Error("[Difftone]:: connt duplicate survey organization uuid");
    }
    const _emptySimpleSurvey = createSimpleSurvey(
        surveyContainer.survey.survey_events,
        _organizationUuid
    );

    Object.keys(duplicateOptionalProperties).forEach((key) => {
        switch (key) {
            case "purpose":
                surveyContainer.survey.purpose = duplicateOptionalProperties[
                    key
                ]
                    ? surveyContainer.survey.purpose
                    : _emptySimpleSurvey.purpose;
                return;

            case "anonymity":
                surveyContainer.survey.anonimity = duplicateOptionalProperties[
                    key
                ]
                    ? surveyContainer.survey.anonimity
                    : _emptySimpleSurvey.anonimity;
                return;
            case "participants":
                surveyContainer.survey.respondents =
                    duplicateOptionalProperties[key]
                        ? surveyContainer.survey.respondents
                        : [];
                return;
            case "introduction":
                surveyContainer.survey.participant_introduction =
                    duplicateOptionalProperties[key]
                        ? surveyContainer.survey.participant_introduction
                        : "";
                return;
            case "questionsUuids":
                surveyContainer.survey.questions = checkQuestionsForDuplication(
                    duplicateOptionalProperties[key],
                    surveyContainer
                );
                return;
            case "dates":
                surveyContainer.survey.survey_events =
                    checkEventsForDuplication(
                        duplicateOptionalProperties,
                        surveyContainer
                    );
                return;

            default:
                return;
        }
    });
    return surveyContainer;
};

const checkEventsForDuplication = (
    duplicateOptionalProperties: OptionalPropertiesForDuplicateSurvey,
    surveyContainer: SurveyContainer<SurveyClasses>
): SurveyEvent[] => {
    const datesUuids = duplicateOptionalProperties.dates;

    switch (surveyContainer.survey.survey_class) {
        case "ONGOING":
            return [];
        case "SIMPLE":
        default:
            const simpleSurveyContainer =
                surveyContainer as SurveyContainer<SimpleSurvey>;

            const simpleSurveyEvent = datesUuids.flatMap((date) => {
                return simpleSurveyContainer.survey.survey_events.filter(
                    (surveyEvent) =>
                        date[surveyEvent.uuid] &&
                        date[surveyEvent.uuid].with_date
                );
            });

            if (simpleSurveyEvent.length === 0) {
                return createDefaultEmptySystemEvent(
                    simpleSurveyContainer.survey.uuid
                );
            }

            return simpleSurveyEvent;
    }
};
