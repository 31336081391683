import { makeAutoObservable, observable, ObservableMap } from "mobx";
import { getUserUuid } from "@difftone/procedures";
import { EmailAddress, SharedSurvey, UUID } from "@difftone/types";

import {
    checkIfEmailIsGroup,
    getSharedSurveysByUserUuidNEmail,
    postSharedSurveysToApi,
    sharedSurveyFromApiByUUIDs,
    sharedSurveyToMapSetter,
    upsertSharedSurveyToApi,
} from "./shared-survey-internal-actions";
import { showDifftoneAlert } from "@difftone/actions";

export type SharedSurveySortingTypes = "NAME" | "FROM" | "RECEIVED";

export type SortingSharedSurveyProfile = {
    sortBy: SharedSurveySortingTypes;
    ascending: boolean;
};

class SharedSurveyStore {
    constructor() {
        makeAutoObservable<SharedSurveyStore, "_fetchedSharedSurveyKeys">(
            this,
            {
                _fetchedSharedSurveyKeys: false,
            }
        );
    }

    private _storeInitialize: boolean = false;
    get storeInitialize(): boolean {
        return this._storeInitialize;
    }
    set storeInitialize(newStoreInitialize: boolean) {
        this._storeInitialize = newStoreInitialize;
    }

    private _startInitialization: boolean = false;
    get startInitialization(): boolean {
        return this._startInitialization;
    }
    set startInitialization(newStartInitialization: boolean) {
        this._startInitialization = newStartInitialization;
    }

    public init = () => {
        getSharedSurveysByUserUuidNEmail();
        this.startInitialization = true;
    };

    private _fetchedSharedSurveyKeys: string[] = [];

    private _sharedSurveyMap: ObservableMap<UUID, SharedSurvey | null> =
        observable.map({});

    get sharedSurveysList(): SharedSurvey[] {
        const _sharedSurveysList = [...this._sharedSurveyMap.values()].filter(
            (value) => !!value && typeof value === "object"
        );

        if (!this.startInitialization && !!localStorage.getItem("authData")) {
            this.init();
        }
        //@ts-ignore
        return _sharedSurveysList;
    }

    public setSharedSurveyToMap = (
        sharedSurveyUUID: UUID,
        newSharedSurvey: SharedSurvey | null
    ) => {
        this._sharedSurveyMap.merge({ [sharedSurveyUUID]: newSharedSurvey });
    };

    private _sortingSharedSurveyProfile: SortingSharedSurveyProfile = {
        sortBy: "RECEIVED",
        ascending: false,
    };

    get sortingSharedSurveyProfile() {
        return this._sortingSharedSurveyProfile;
    }

    set sortingSharedSurveyProfile(
        newSortingSharedSurveyProfile: SortingSharedSurveyProfile
    ) {
        this._sortingSharedSurveyProfile = newSortingSharedSurveyProfile;
    }

    public getSharedSurveyByUuid = (sharedSurveyUuid: UUID) => {
        const fetchedSharedSurvey = this._sharedSurveyMap.get(sharedSurveyUuid);

        if (
            fetchedSharedSurvey === undefined &&
            !this._fetchedSharedSurveyKeys.includes(sharedSurveyUuid)
        ) {
            this._fetchedSharedSurveyKeys.push(sharedSurveyUuid);
            sharedSurveyFromApiByUUIDs([sharedSurveyUuid]);
        }
        return fetchedSharedSurvey;
    };

    public sharedSurveysToDisplay = () => {
        return this.sharedSurveysList.filter(
            (sharedSurveys) =>
                sharedSurveys.shared_survey_status !== "DRAFT" &&
                sharedSurveys.sender !== getUserUuid()
        );
    };

    public readySharedSurveysCount = () => {
        return sharedSurveyStore.sharedSurveysToDisplay().length;
    };

    public addSharedSurvey = (sharedSurveys: SharedSurvey[]) => {
        sharedSurveyToMapSetter(sharedSurveys);
    };

    public sendSharedSurveysToApi = (
        sharedSurveys: SharedSurvey[],
        initiatorSharedSurvey: SharedSurvey
    ) => {
        postSharedSurveysToApi(initiatorSharedSurvey, sharedSurveys);

        this._sharedSurveyMap.merge({
            [initiatorSharedSurvey.uuid]: initiatorSharedSurvey,
        });
        sharedSurveys.forEach((shareSurvey) => {
            this._sharedSurveyMap.merge({
                [shareSurvey.uuid]: shareSurvey,
            });
        });
    };

    public removeSharedSurveyFromStore = (sharedSurveyUUID: UUID) => {
        this._sharedSurveyMap.delete(sharedSurveyUUID);
    };

    public removeDeletedSharedSurvey = () => {
        this.sharedSurveysList
            .filter((sharedSurvey) => sharedSurvey.deleted)
            .forEach((sharedSurvey) =>
                this._sharedSurveyMap.delete(sharedSurvey.uuid)
            );
    };

    public clearStore = () => {
        this._storeInitialize = false;
        this._startInitialization = false;
        this._sortingSharedSurveyProfile = {
            sortBy: "RECEIVED",
            ascending: false,
        };
        this._sharedSurveyMap = observable.map({});
    };

    public async addEmailsToSharedSurvey(
        emails: EmailAddress[],
        sharedSurveyUUID: UUID
    ) {
        const surveyToUpdate = this._sharedSurveyMap.get(sharedSurveyUUID);
        const emailsToAdd = await this.getEmailsToAddToSurvey(emails);
        if (!emailsToAdd) {
            showDifftoneAlert(
                "Unable to retrieve group email members",
                "FAILURE"
            );
            return;
        } else if (!surveyToUpdate) {
            showDifftoneAlert("Invalid survey", "FAILURE");
            return;
        }

        surveyToUpdate.addressee = Array.from(
            new Set([...surveyToUpdate.addressee, ...emailsToAdd])
        );
        this.putSharedSurveyToApi(surveyToUpdate);
    }
    private async getEmailsToAddToSurvey(
        emails: EmailAddress[]
    ): Promise<EmailAddress[] | null> {
        const processedEmailPromises = [];
        const processEmail = async (email: EmailAddress) => {
            const isGroupEmail = await checkIfEmailIsGroup(email);
            if (isGroupEmail) {
                return [];
            } else {
                return [email];
            }
        };
        for (const email of emails) {
            processedEmailPromises.push(processEmail(email));
        }

        const processedEmails = await Promise.all(processedEmailPromises);
        return processedEmails.flat();
    }

    public putSharedSurveyToApi = (sharedSurvey: SharedSurvey) => {
        upsertSharedSurveyToApi(sharedSurvey).catch((err) => {
            console.error(err);
            showDifftoneAlert(
                "Could not update the shared survey. Please try again later.",
                "FAILURE"
            );
        });
    };
}

export const sharedSurveyStore = new SharedSurveyStore();
