import React from "react";
import { observer } from "mobx-react";

import {
    Question,
    QuestionToAnswerDescreteQuestion,
    QuestionType,
    ResultsMapAfterDigestion,
    SupportedQuestionResultsMapAfterDigestion,
    SupportedQuestionResultsMapAfterDigestionWithoutNulls,
    BaseSurvey,
} from "@difftone/types";
import {
    Spinner,
    ResultsBoxFactoryByQuestionType,
} from "@difftone/shared-components";
import { COLUMN_0 } from "@difftone/common-constants";

import {
    QuestionDescription,
    NotSupportedQuestion,
    ValuesPlain,
} from "./components";

import {
    results_question,
    border_spacer,
    result_question_wrapper,
    not_supported_info_wrapper,
    not_supported_results_question,
    results_question_content,
} from "./favorable-question-display.module.css";

export type FavorableQuestionDisplay = {
    question: Question<QuestionType>;
    questionIndex: number;
    digestedResultsRow: ResultsMapAfterDigestion;
    survey: BaseSurvey;
};

export const FavorableQuestionDisplay = observer(
    (props: FavorableQuestionDisplay) => {
        const { question, questionIndex, digestedResultsRow, survey } = props;

        if (!digestedResultsRow) {
            return <Spinner size="MEDIUM" />;
        }

        //digestedResultsRow can be "NOT_SUPPORTED"
        if (
            digestedResultsRow &&
            digestedResultsRow[COLUMN_0] &&
            (digestedResultsRow[COLUMN_0] as QuestionToAnswerDescreteQuestion)[
                "%QUESTION_NOT_SUPPORTED%"
            ] === 0
        ) {
            return (
                <div>
                    <div className={not_supported_results_question}>
                        <QuestionDescription
                            questionIndex={questionIndex}
                            question={question}
                        />
                        <div className={not_supported_info_wrapper}>
                            <NotSupportedQuestion question={question} />
                        </div>
                    </div>
                </div>
            );
        }

        const nakedDigestedResultsRow: SupportedQuestionResultsMapAfterDigestion =
            {};
        Object.keys(digestedResultsRow).forEach((columnKey) => {
            nakedDigestedResultsRow[columnKey] = JSON.parse(
                JSON.stringify(digestedResultsRow[columnKey])
            );
        });

        return (
            <div className={result_question_wrapper}>
                <div className={results_question}>
                    <div className={results_question_content}>
                        <QuestionDescription
                            questionIndex={questionIndex}
                            question={question}
                        />
                        <div className={border_spacer}>
                            <ValuesPlain
                                question={question}
                                resultPopularity={nakedDigestedResultsRow}
                                surveyAnonymityLevel={survey.anonimity}
                            />
                        </div>
                    </div>
                </div>

                {Object.keys(nakedDigestedResultsRow)?.map(
                    (columnKey, index) => {
                        if (columnKey !== COLUMN_0) {
                            return null;
                        }

                        return (
                            <div key={columnKey}>
                                <div>
                                    <ResultsBoxFactoryByQuestionType
                                        question={question}
                                        index={index}
                                        digestedResultsRow={
                                            nakedDigestedResultsRow as SupportedQuestionResultsMapAfterDigestionWithoutNulls
                                        }
                                        columnKey={columnKey}
                                        survey={survey}
                                    />
                                </div>
                            </div>
                        );
                    }
                )}
            </div>
        );
    }
);
