import React, {
    useCallback,
    FC,
    ReactElement,
    PropsWithChildren,
    ReactNode,
} from "react";
import OutsideClickHandler from "react-outside-click-handler";
import clsx from "clsx";
import {
    default as MUIPopper,
    PopperProps as MUIPopperProps,
} from "@material-ui/core/Popper";

import {
    popper_root,
    popper_content,
    popper_content_scrollable,
} from "./popper.module.css";

export interface PopperProps extends MUIPopperProps {
    scrollable?: boolean;
    onClose?: () => void;
    contentClassName?: string;
}

export const Popper: FC<PropsWithChildren<PopperProps>> = (
    props: PopperProps
): ReactElement => {
    const {
        onClose,
        children,
        className,
        contentClassName,
        scrollable,
        ...restProps
    } = props;

    const handleOutsideClick = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    return (
        <MUIPopper className={clsx(popper_root, className)} {...restProps}>
            <OutsideClickHandler onOutsideClick={handleOutsideClick}>
                <div
                    onClick={handleOutsideClick}
                    className={clsx(popper_content, contentClassName, {
                        [popper_content_scrollable]: scrollable,
                    })}
                >
                    {children as ReactNode}
                </div>
            </OutsideClickHandler>
        </MUIPopper>
    );
};
