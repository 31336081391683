import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core";
import { updateQuestionInWizard } from "@difftone/actions";
import { MultipleChoiceQuestion, Question } from "@difftone/types";
import { ShowDifftoneTooltip } from "@difftone/shared-components";

import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";

import { DraggableOptions } from "./components";

import {
    expanded_multiple_choice_container,
    add_option_or_other,
    disabled_other_option,
    add_option_button,
    disabled_new_option,
} from "./expanded-multiple-choice.module.css";

const MAX_ALLOWED_CHOICE_OPTIONS = 10;

const tooltipExtendPosition = makeStyles({
    tooltipPosition: {
        right: "570px",
    },
});

export type ExpandedMultipleChoiceProps = {
    item: Question<MultipleChoiceQuestion>;
    disabled: boolean;
};

const getNewOptionIndex = (currentOptions: string[]) => {
    let max = 0;
    currentOptions.forEach((option) => {
        if (option.startsWith("Option")) {
            const optionIndex = option.split(" ").pop();
            if (optionIndex && parseInt(optionIndex) > max) {
                max = parseInt(optionIndex);
            }
        }
    });
    return max + 1;
};

export const ExpandedMultipleChoice = observer(
    (props: ExpandedMultipleChoiceProps) => {
        const { item } = props;
        const isChoiceOptionsBiggerThenAllowed =
            item.content.choice_options.length >= MAX_ALLOWED_CHOICE_OPTIONS;

        const tooltipExtendStyle = tooltipExtendPosition().tooltipPosition;

        const onAddNewChoice = () => {
            const copyOfCurrentQuestionChoices = [
                ...item.content.choice_options,
                `Option ${getNewOptionIndex(item.content.choice_options)}`,
            ];
            const newContent = {
                ...item.content,
                choice_options: copyOfCurrentQuestionChoices,
            };

            updateQuestionInWizard(
                item as Question<MultipleChoiceQuestion>,
                "content",
                newContent
            );
        };
        const onAddOtherAsChoice = () => {
            const copyOfCurrentQuestionChoices = [
                ...item.content.choice_options,
                "Other",
            ];
            const newContent = {
                ...item.content,
                choice_options: copyOfCurrentQuestionChoices,
            };

            updateQuestionInWizard(
                item as Question<MultipleChoiceQuestion>,
                "content",
                newContent
            );
        };
        const isOtherOptionWasSelected = () => {
            return item.content.choice_options.filter((option) =>
                option.startsWith("Other")
            ).length > 0
                ? true
                : false;
        };

        const updateItemsOrder = (oldIndex: number, newIndex: number) => {
            const optionsList = Array.from(item.content.choice_options);
            const [reorderedOption] = optionsList.splice(oldIndex, 1);
            optionsList.splice(newIndex, 0, reorderedOption);

            const newContent = {
                ...item.content,
                choice_options: optionsList,
            };

            updateQuestionInWizard(
                item as Question<MultipleChoiceQuestion>,
                "content",
                newContent
            );
        };

        const onDragEnd = (result: DropResult) => {
            if (result.source && result.destination) {
                updateItemsOrder(result.source.index, result.destination.index);
            }
        };

        return (
            <div className={expanded_multiple_choice_container}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="options">
                        {(provided) => (
                            <ul
                                className="options"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{ display: "inline" }}
                            >
                                <DraggableOptions
                                    addNewChoice={onAddNewChoice}
                                    item={item}
                                />
                                {isChoiceOptionsBiggerThenAllowed ? (
                                    <ShowDifftoneTooltip
                                        tooltipPosition="right"
                                        tip="Maximum 10 options"
                                        extendTooltipStyle={tooltipExtendStyle}
                                    >
                                        <li className={add_option_or_other}>
                                            Add
                                            <span
                                                className={[
                                                    add_option_button,
                                                    disabled_new_option,
                                                ].join(" ")}
                                                onClick={onAddNewChoice}
                                            >
                                                Option
                                            </span>
                                        </li>
                                    </ShowDifftoneTooltip>
                                ) : (
                                    <li className={add_option_or_other}>
                                        Add&nbsp;
                                        <span
                                            className={add_option_button}
                                            onClick={onAddNewChoice}
                                        >
                                            Option
                                        </span>
                                        {isOtherOptionWasSelected() ? null : (
                                            <>
                                                &nbsp;&nbsp;or
                                                <span
                                                    onClick={onAddOtherAsChoice}
                                                    className={
                                                        isOtherOptionWasSelected()
                                                            ? disabled_other_option
                                                            : add_option_button
                                                    }
                                                >
                                                    "Other"
                                                </span>
                                            </>
                                        )}
                                    </li>
                                )}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    }
);
