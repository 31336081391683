import React from "react";

import { wizardStore } from "@difftone/stores";
import { SurveyClasses } from "@difftone/types";

import { MobileDatesMenu, MobileQuestionsMenu } from "./components";

import { mobile_wizard_actions_menu } from "./mobile-wizard-actions-menu.module.css";

export type MobileWizardActionsMenuProps = {
    survey?: SurveyClasses;
};

export const MobileWizardActionsMenu = (
    props: MobileWizardActionsMenuProps
) => {
    const { activeAccordionSection } = wizardStore;
    const { survey } = props;
    switch (activeAccordionSection) {
        case "TIME_LINE":
            return (
                <div className={mobile_wizard_actions_menu}>
                    <MobileDatesMenu survey={survey} />
                </div>
            );
        case "QUESTIONS":
            return (
                <div className={mobile_wizard_actions_menu}>
                    <MobileQuestionsMenu survey={survey} />
                </div>
            );
        default:
            return null;
    }
};
