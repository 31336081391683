import {
    inboxStore,
    navigationStore,
    sharedSurveyStore,
} from "@difftone/stores";
import { SharedSurvey, SurveyClasses } from "@difftone/types";
import { getPublicProfileEmail } from "@difftone/actions";

export const getSharedSurveyUUIDFromUrl = (): string => {
    const { currentPage } = navigationStore;

    const currentPageUrl = currentPage.split("/");

    const indexOfShareInUrl = currentPageUrl.indexOf("share");

    let sharedSurveyUuid = null;

    if (currentPageUrl[indexOfShareInUrl] === "share") {
        sharedSurveyUuid = currentPageUrl[indexOfShareInUrl + 1];
    } else {
        sharedSurveyUuid = currentPageUrl.pop();
    }

    if (!sharedSurveyUuid) {
        console.error(
            "[getSharedSurveyUUIDFromUrl]:: Could not find shared survey uuid"
        );
        throw Error(
            "[getSharedSurveyUUIDFromUrl]:: Could not find shared survey uuid"
        );
    }

    return sharedSurveyUuid;
};

export const getSharedSurveyFromStoreByURL = (): SharedSurvey => {
    const sharedSurveyUUID = getSharedSurveyUUIDFromUrl();

    if (!sharedSurveyUUID) {
        throw Error(
            "[getSharedSurveyFromStoreByURL]:: Something went wrong in shared survey reducer"
        );
    }

    return sharedSurveyStore.getSharedSurveyByUuid(
        sharedSurveyUUID
    ) as SharedSurvey;
};

export const getFilteredSharedSurveys = (
    sortedSharedSurveys: SharedSurvey[]
): SharedSurvey[] => {
    if (!inboxStore.filterProfile) {
        return sortedSharedSurveys;
    }

    let results = [...sortedSharedSurveys];
    const filter = Object.assign({}, inboxStore.filterProfile);
    for (const filterType in filter) {
        let key = "" as keyof SurveyClasses;
        switch (filterType) {
            case "NAME":
                key = "survey_name";
                break;
            default:
                return results;
        }
        results = results.filter((sharedSurvey) => {
            // String
            if (
                typeof filter[filterType] === "string" &&
                key === "survey_name"
            ) {
                return sharedSurvey.survey[key]
                    .toLowerCase()
                    .includes(filter[filterType].toLowerCase());
            }
            return filter[filterType] === sharedSurvey.survey[key];
        });
    }
    return results;
};

export const getSortedSharedSurveys = (): SharedSurvey[] => {
    const sharedSurveysList = sharedSurveyStore.sharedSurveysToDisplay();
    const sortBy = sharedSurveyStore.sortingSharedSurveyProfile.sortBy;
    const ascending = sharedSurveyStore.sortingSharedSurveyProfile.ascending;

    const sortedSharedSurveys = [...sharedSurveysList];

    switch (sortBy) {
        case "NAME":
            return ascending
                ? sortedSharedSurveys.sort((sharedSurveyA, SharedSurveyB) =>
                      sharedSurveyA.survey.survey_name.toLowerCase() >=
                      SharedSurveyB.survey.survey_name.toLowerCase()
                          ? 1
                          : -1
                  )
                : sortedSharedSurveys.sort((sharedSurveyA, SharedSurveyB) =>
                      sharedSurveyA.survey.survey_name.toLowerCase() <
                      SharedSurveyB.survey.survey_name.toLowerCase()
                          ? 1
                          : -1
                  );
        case "FROM":
            return ascending
                ? sortedSharedSurveys.sort((sharedSurveyA, sharedSurveyB) =>
                      getPublicProfileEmail(
                          sharedSurveyA.sender
                      )?.toLowerCase()! >=
                      getPublicProfileEmail(
                          sharedSurveyB.sender
                      )?.toLowerCase()!
                          ? 1
                          : -1
                  )
                : sortedSharedSurveys.sort((sharedSurveyA, sharedSurveyB) =>
                      getPublicProfileEmail(
                          sharedSurveyA.sender
                      )?.toLowerCase()! <
                      getPublicProfileEmail(
                          sharedSurveyB.sender
                      )?.toLowerCase()!
                          ? 1
                          : -1
                  );
        case "RECEIVED":
            return ascending
                ? sortedSharedSurveys.sort(
                      (sharedSurveyA, sharedSurveyB) =>
                          sharedSurveyA.timestamp - sharedSurveyB.timestamp
                  )
                : sortedSharedSurveys.sort(
                      (sharedSurveyA, sharedSurveyB) =>
                          sharedSurveyB.timestamp - sharedSurveyA.timestamp
                  );
        default:
            return sortedSharedSurveys;
    }
};
