import React from "react";
import { observer } from "mobx-react";
import clsx from "clsx";

import {
    AddBoxShadow,
    DialogBackground,
    DifftoneButton,
    PreviewSurvey,
} from "@difftone/shared-components";
import {
    closeSurveyPreview,
    openSurveyPreview,
    openScheduleSurveyPopup,
    setFocusedWizardInputCard,
    setSurveyNameFromDefaultSurveyNameIfNotExist,
    setWizardAccordionActiveSection,
    showDifftoneAlert,
    validateWizardSurvey,
    setLocation,
    showDifftoneAlertWithSharePanel,
    clearSurveyCopyFromStore,
    addEmailsToSurveySystemAlerts,
    upsertSurveyToMapAndApiWithoutDebounce,
    scheduleOrUpdateSurveyEndedEmail,
    finalizeSurveyAlertChangesBeforeSave,
} from "@difftone/actions";
import {
    alertsStore,
    previewSurveyStore,
    scheduleSurveyPopupStore,
    wizardDisplayStore,
    wizardStore,
} from "@difftone/stores";
import { navigateToWizardValidationError } from "@difftone/frontend-common/frontend-validations";
import { getStatus, getSurveyFromStoreByURL } from "@difftone/reducers";
import scheduleIcon from "@difftone/assets/button_icon_schedule_white.svg";
import previewIcon from "@difftone/assets/preview-survey-icon.svg";
import saveSurveyIcon from "@difftone/assets/save-floppy-icon.svg";
import simpleSurveySendIcon from "@difftone/assets/simple-survey-send-air-plain-icon.svg";
import simpleSurveyGreenSend from "@difftone/assets/send-air-plain-green-icon.svg";
import activatePlayButton from "@difftone/assets/activate-pay-icon.svg";
import saveGreenButton from "@difftone/assets/save-z-green-icon.svg";
import draftSurveyIcon from "@difftone/assets/button_icon_send_green.svg";
import backArrowButton from "@difftone/assets/back-arrow-icon.svg";
import {
    constantAlertVariants,
    INBOX_URL,
    PAGE_URLS,
    warningMessages,
} from "@difftone/constants";
import { objectsAreEqual, UiStatus } from "@difftone/frontend-common";
import {
    onDraft,
    onReadyOngoing,
    onSchedule,
    onSendSimpleSurvey,
    activateOngoingSurvey,
} from "./utils";

import { ScheduleSurveyPopup } from "./components";
import {
    send_survey_button,
    preview_survey_button,
    send_survey_button_icon,
    preview_survey_button_icon,
    save_survey_button,
    save_survey_button_icon,
    send_button_icon,
} from "./send-survey-button.module.css";
import { OngoingSurvey, SimpleSurvey, SurveyClasses } from "@difftone/types";

type submitType = "SCHEDULE" | "SEND" | "DRAFT" | "READY" | "SEND_ONGOING";

export type SendSurveyButtonProps = {};

export const SendSurveyButton = observer((props: SendSurveyButtonProps) => {
    const { isOpen } = scheduleSurveyPopupStore;
    const { inPreviewMode } = previewSurveyStore;
    const { surveyCopyToUpdate } = wizardDisplayStore;

    const survey = getSurveyFromStoreByURL();

    const onScheduleSurveyClick = () => {
        validateWizardSurvey();

        if (
            wizardStore.wizardValidationErrors.some(
                (validationResult) => !validationResult.is_valid
            )
        ) {
            navigateToWizardValidationError(survey as SurveyClasses);
            console.error(
                "[Validation Results]:: ",
                JSON.parse(JSON.stringify(wizardStore.wizardValidationErrors))
            );

            return;
        }

        setSurveyNameFromDefaultSurveyNameIfNotExist();
        setWizardAccordionActiveSection(null);
        setFocusedWizardInputCard(null);
        openScheduleSurveyPopup();
    };

    const onPreviewSurveyClick = () => {
        setSurveyNameFromDefaultSurveyNameIfNotExist();
        if (!survey.questions.length) {
            showDifftoneAlert(
                "There are no questions to preview. Please add some and then try again",
                "SUCCESS"
            );
            closeSurveyPreview();
            return;
        }
        openSurveyPreview(survey);
    };

    const onSaveSurveyClick = () => {
        if (!surveyCopyToUpdate) {
            throw Error(
                "[Difftone]:: onSaveSurveyClick survey copy cannot be null"
            );
        }

        if (surveyCopyToUpdate.survey_class === "SIMPLE")
            finalizeSurveyAlertChangesBeforeSave(surveyCopyToUpdate);

        if (
            survey.respondents.length !== surveyCopyToUpdate.respondents.length
        ) {
            const newlyAddedParticipants =
                surveyCopyToUpdate!.respondents.filter(
                    (email) => !survey.respondents.includes(email)
                );

            if (surveyCopyToUpdate.survey_class === "SIMPLE") {
                const simpleSurveyCopyToUpdate =
                    surveyCopyToUpdate as SimpleSurvey;

                addEmailsToSurveySystemAlerts(
                    newlyAddedParticipants,
                    simpleSurveyCopyToUpdate
                );
            }

            showDifftoneAlertWithSharePanel(
                constantAlertVariants.update_survey_participants.text,
                constantAlertVariants.update_survey_participants.variant,
                survey.uuid,
                "Additionally you can send the survey via",
                survey.is_open_survey
            );
        } else {
            showDifftoneAlert("Changes were successfully saved", "SUCCESS");
        }

        if (Object.keys(wizardDisplayStore.surveyCopyAlerts)) {
            for (const [key, value] of Object.entries(
                wizardDisplayStore.surveyCopyAlerts
            )) {
                if (value) alertsStore.debounceInsertAlertToStore(value);
            }
        }

        if (surveyCopyToUpdate.survey_class === "SIMPLE") {
            scheduleOrUpdateSurveyEndedEmail(surveyCopyToUpdate);
        }

        clearSurveyCopyFromStore();

        upsertSurveyToMapAndApiWithoutDebounce(surveyCopyToUpdate!);
        setLocation(PAGE_URLS.INBOX_ALL);
        return;
    };

    const onClickBack = () => {
        if (
            surveyCopyToUpdate &&
            !objectsAreEqual(survey, surveyCopyToUpdate)
        ) {
            showDifftoneAlert(
                warningMessages.goBackWithNoWizardChanges,
                "FAILURE",
                () => setLocation(INBOX_URL)
            );
            return;
        }
        setLocation(INBOX_URL);
    };

    const onSubmit = (submitType: submitType) => {
        switch (submitType) {
            case "SCHEDULE":
                onSchedule(survey);
                break;
            case "SEND":
                const simpleSurvey = survey as SimpleSurvey;
                onSendSimpleSurvey(simpleSurvey);
                break;
            case "DRAFT":
                onDraft();
                break;
            case "READY":
                const ongoingSurvey = survey as OngoingSurvey;
                onReadyOngoing(ongoingSurvey);
                break;
            case "SEND_ONGOING":
                activateOngoingSurvey();
                break;
        }
    };

    const surveyStatus: UiStatus = getStatus(survey);

    //TODO: fix status calculation after refactoring survey status https://app.asana.com/0/1201346022025578/1202797176829613/f
    const editableSurvey = surveyStatus === "DRAFT" || surveyStatus === "READY";

    const isActiveSurvey =
        surveyStatus === "ACTIVE" ||
        surveyStatus === "COMPLETED" ||
        surveyStatus === "SAVED";

    const isSendButton =
        surveyCopyToUpdate &&
        survey.respondents.length !== surveyCopyToUpdate.respondents.length;

    const simpleSurveyFloatingButtons = () => {
        return (
            <>
                <DifftoneButton
                    onClick={onPreviewSurveyClick}
                    classNameOverride={preview_survey_button}
                    iconClassName={preview_survey_button_icon}
                    icon={previewIcon}
                    buttonText="Preview"
                />
                {editableSurvey ? (
                    <DifftoneButton
                        onClick={onScheduleSurveyClick}
                        classNameOverride={send_survey_button}
                        iconClassName={send_survey_button_icon}
                        icon={scheduleIcon}
                        buttonText={"Schedule"}
                    />
                ) : null}
                {isActiveSurvey ? (
                    <DifftoneButton
                        onClick={onSaveSurveyClick}
                        classNameOverride={save_survey_button}
                        iconClassName={clsx(save_survey_button_icon, {
                            [send_button_icon]: isSendButton,
                        })}
                        icon={
                            isSendButton ? simpleSurveySendIcon : saveSurveyIcon
                        }
                        buttonText={isSendButton ? "Send" : "Save"}
                    />
                ) : null}
                {isOpen ? (
                    <AddBoxShadow>
                        <ScheduleSurveyPopup
                            survey={survey}
                            mainButtonText="Schedule"
                            mainIconButton={scheduleIcon}
                            onSubmit={() => onSubmit("SCHEDULE")}
                            extraOptions={[
                                {
                                    icon: simpleSurveyGreenSend,
                                    text: "Send now",
                                    onClick: () => onSubmit("SEND"),
                                },
                                {
                                    icon: draftSurveyIcon,
                                    text: "Save as Draft",
                                    onClick: () => onSubmit("DRAFT"),
                                },
                            ]}
                        />
                    </AddBoxShadow>
                ) : null}
                {inPreviewMode ? (
                    <DialogBackground>
                        <AddBoxShadow>
                            <PreviewSurvey className="" />
                        </AddBoxShadow>
                    </DialogBackground>
                ) : null}
            </>
        );
    };

    const ongoingSurveyFloatingButtons = () => {
        return (
            <>
                <DifftoneButton
                    onClick={onPreviewSurveyClick}
                    classNameOverride={preview_survey_button}
                    iconClassName={preview_survey_button_icon}
                    icon={previewIcon}
                    buttonText="Preview"
                />
                {editableSurvey ? (
                    <DifftoneButton
                        onClick={onScheduleSurveyClick}
                        classNameOverride={send_survey_button}
                        iconClassName={send_survey_button_icon}
                        icon={activatePlayButton}
                        buttonText={"Activate"}
                    />
                ) : null}
                {isActiveSurvey ? (
                    <DifftoneButton
                        onClick={isSendButton ? onSaveSurveyClick : onClickBack}
                        classNameOverride={save_survey_button}
                        iconClassName={clsx(save_survey_button_icon, {
                            [send_button_icon]: isSendButton,
                        })}
                        icon={
                            isSendButton
                                ? simpleSurveySendIcon
                                : backArrowButton
                        }
                        buttonText={isSendButton ? "Send" : "Back"}
                    />
                ) : null}
                {isOpen ? (
                    <AddBoxShadow>
                        <ScheduleSurveyPopup
                            survey={survey}
                            mainButtonText="Activate"
                            mainIconButton={activatePlayButton}
                            onSubmit={() => onSubmit("SEND_ONGOING")}
                            extraOptions={[
                                {
                                    icon: saveGreenButton,
                                    text: "Save",
                                    onClick: () => onSubmit("READY"),
                                },
                                {
                                    icon: draftSurveyIcon,
                                    text: "Save as draft",
                                    onClick: () => onSubmit("DRAFT"),
                                },
                            ]}
                        />
                    </AddBoxShadow>
                ) : null}
                {inPreviewMode ? (
                    <DialogBackground>
                        <AddBoxShadow>
                            <PreviewSurvey className="" />
                        </AddBoxShadow>
                    </DialogBackground>
                ) : null}
            </>
        );
    };

    switch (survey.survey_class) {
        case "SIMPLE":
            return simpleSurveyFloatingButtons();
        case "ONGOING":
            return ongoingSurveyFloatingButtons();
        default:
            return simpleSurveyFloatingButtons();
    }
});
