import React from "react";
import { Question, QuestionType } from "@difftone/types";
import {
    milisecondsToDateStringResults,
    milisecondsToTimeString,
} from "@difftone/time-utils";
import userIcon from "@difftone/assets/user-icon-text-questions.svg";

import {
    un_selected_checkbox,
    text_answer_item,
    answer_checkbox,
    text_content,
    answer_left_content,
    selected_checkbox,
    date_content,
} from "./text-answer-item.module.css";

export type TextAnswerItemProps = {
    answer: string;
    question: Question<QuestionType>;
    setSelectedAnswers: (answers: string[]) => void;
    selectedAnswers: string[];
    stateKey: string;
};

export const TextAnswerItem = (props: TextAnswerItemProps) => {
    const { answer, setSelectedAnswers, selectedAnswers, stateKey, question } =
        props;

    const isSelected = selectedAnswers.includes(stateKey);

    const onChange = () => {
        const answers = Array.from(new Set([...selectedAnswers]));

        if (isSelected) {
            const updatedSelectedAnswers = answers.filter(
                (answerKey) => answerKey !== stateKey
            );
            setSelectedAnswers(updatedSelectedAnswers);
        } else {
            setSelectedAnswers([...answers, stateKey]);
        }
    };

    let displayAnswer = null;

    switch (question.question_type_name) {
        case "SHORT_TEXT":
        case "LONG_TEXT":
            displayAnswer = <div className={text_content}>{answer}</div>;
            break;
        case "DATE":
            const dateInStringFormat = milisecondsToDateStringResults(
                Number(answer)
            );
            displayAnswer = (
                <div className={date_content}>{dateInStringFormat}</div>
            );
            break;
        case "TIME":
            const timeStringFormat = milisecondsToTimeString(Number(answer));
            displayAnswer = (
                <div className={date_content}>{timeStringFormat}</div>
            );
            break;
        default:
            displayAnswer = <div className={text_content}>{answer}</div>;
    }

    return (
        <div className={text_answer_item}>
            <div className={answer_left_content}>
                <div className={answer_checkbox}>
                    <label
                        className={
                            isSelected
                                ? selected_checkbox
                                : un_selected_checkbox
                        }
                    >
                        <input type="checkbox" onChange={onChange} />
                    </label>
                </div>
                <img alt="user-icon" src={userIcon} />
            </div>
            {displayAnswer}
        </div>
    );
};
