import { makeAutoObservable, observable, ObservableMap } from "mobx";

import { Label, UUID } from "@difftone/types";
import { upsertCustomCategory } from "@difftone/services";
import { UUID_DETECTING_REGEX } from "@difftone/constants";

import {
    fetchAvailableCategories,
    createCustomCategoryUpsertToApi,
    setCategoriesStoreInitialized,
    setCategoriesStoreIsRequesting,
    setCategoriesMapSetter,
} from "./categories-internal-actions";
import { getSelectedOrganizationOrThrow } from "@difftone/reducers";

class CategoriesStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _isRequesting = false;

    get isRequesting() {
        return this._isRequesting;
    }

    set isRequesting(bool: boolean) {
        this._isRequesting = bool;
    }

    private _isStoreInitialized = false;

    get isStoreInitialized() {
        return this._isStoreInitialized;
    }

    set isStoreInitialized(bool) {
        this._isStoreInitialized = bool;
    }

    private _categoriesMap: ObservableMap<UUID, Label | null> = observable.map(
        {}
    );

    public setCategoryToMap = (
        categoryUUID: UUID,
        newCategory: Label | null
    ) => {
        this._categoriesMap.merge({ [categoryUUID]: newCategory });
    };

    public createCustomCategory = async (category: Label): Promise<Label> => {
        return createCustomCategoryUpsertToApi(category);
    };

    public init = async (organisationUUID: UUID | null) => {
        setCategoriesStoreIsRequesting(true);

        await fetchAvailableCategories(organisationUUID);

        setCategoriesStoreInitialized(true);
        setCategoriesStoreIsRequesting(false);
    };

    public addCategoryToAPIWithoutDebounce = (category: Label) => {
        upsertCustomCategory(category);

        setCategoriesMapSetter(category);
    };

    public getCategoriesArray = (): Label[] => {
        if (
            !categoriesStore.isStoreInitialized &&
            !categoriesStore.isRequesting
        ) {
            categoriesStore.init(
                getSelectedOrganizationOrThrow()
                    ? getSelectedOrganizationOrThrow()!.uuid
                    : null
            );
            [...this._categoriesMap.values()].filter(
                (category) => category !== null
            ) as Label[];
        }
        return (
            [...this._categoriesMap.values()].filter(
                (category) => category !== null
            ) as Label[]
        )
            .filter(({ is_deleted }) => !is_deleted)
            .filter(({ display_name }) => {
                const regexp = new RegExp(UUID_DETECTING_REGEX);

                return !regexp.test(display_name);
            });
    };

    public clearStore = () => {
        setCategoriesStoreIsRequesting(false);
        setCategoriesStoreInitialized(false);
        this._categoriesMap = observable.map({});
    };
}

export const categoriesStore = new CategoriesStore();
