import React from "react";
import { observer } from "mobx-react";
import { TimeCompareUnits } from "@difftone/types";
import { timeCompareFiltersKeyValues } from "@difftone/constants";

import selectedRadioButton from "@difftone/assets/radio-selected-button.svg";
import unselectedRadioButton from "@difftone/assets/radio-unselected-button.svg";

import { compare_time_item } from "./compare-time-item.module.css";

export type CompareTimeItemProps = {
    filterValue: TimeCompareUnits;
    selectedPopupDisplayResultsTimeCompare: TimeCompareUnits;
    setPopupDisplayResultsTimeCompare: (
        newTimeCompare: TimeCompareUnits
    ) => void;
};

export const CompareTimeItem = observer((props: CompareTimeItemProps) => {
    const {
        filterValue,
        selectedPopupDisplayResultsTimeCompare,
        setPopupDisplayResultsTimeCompare,
    } = props;

    const isSelectedFilter =
        selectedPopupDisplayResultsTimeCompare === filterValue;

    const onSelectDisplayCompareHandler = () => {
        if (isSelectedFilter) {
            return;
        }
        setPopupDisplayResultsTimeCompare(filterValue);
    };

    return (
        <div className={compare_time_item}>
            <img
                onClick={onSelectDisplayCompareHandler}
                src={
                    isSelectedFilter
                        ? selectedRadioButton
                        : unselectedRadioButton
                }
                alt="radio-button"
            />
            <span>{timeCompareFiltersKeyValues[filterValue]}</span>
        </div>
    );
});
