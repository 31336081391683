import React from "react";
import offlineIcon from "@difftone/assets/offline-header-status.svg";

import {
    offline_status,
    under_hover,
    top_triangle,
} from "./offline-status.module.css";

export const OfflineStatus = () => {
    return (
        <div className={offline_status}>
            <img src={offlineIcon} alt="saved" />
            <span>You are working offline</span>
            <div className={under_hover}>
                <div className={top_triangle}></div>
                You are currently working offline. Please make sure you are
                connected to the internet to save all the changes.
            </div>
        </div>
    );
};
