import {
    OngoingSurvey,
    Serialize,
    SurveyContainer,
    SimpleSurvey,
    SurveyClasses,
    SurveyEventAlert,
} from "@difftone/types";

export const serializeSurvey = (
    surveyContainer: SurveyContainer<SurveyClasses>
): Serialize<SurveyClasses> => {
    switch (surveyContainer.survey.survey_class) {
        case "ONGOING":
            if (surveyContainer.survey_events_alerts) {
                throw Error("[Difftone]::ongoing survey can't have alerts");
            }
            const ongoingSurvey = surveyContainer.survey as OngoingSurvey;
            return serializeOngoingSurvey(ongoingSurvey);
        case "SIMPLE":
        default:
            if (!surveyContainer.survey_events_alerts) {
                throw Error("[Difftone]::simple survey must contain alerts");
            }
            const simpleSurvey = surveyContainer.survey as SimpleSurvey;
            return serializeSimpleSurvey(
                simpleSurvey,
                surveyContainer.survey_events_alerts
            );
    }
};

export const deserializeSurvey = (
    SerializeSurveyStr: Serialize<SurveyClasses>
): SurveyContainer<SurveyClasses> => {
    const _deserializedSurveyObj: SurveyContainer<SurveyClasses> =
        JSON.parse(SerializeSurveyStr);
    switch (_deserializedSurveyObj.survey.survey_class) {
        case "ONGOING":
            return _deserializedSurveyObj as SurveyContainer<OngoingSurvey>;
        case "SIMPLE":
        default:
            return _deserializedSurveyObj as SurveyContainer<SimpleSurvey>;
    }
};

const serializeSimpleSurvey = (
    survey: SimpleSurvey,
    surveyEventsAlerts: SurveyEventAlert[]
): Serialize<SimpleSurvey> => {
    const _serializedSurvey: SurveyContainer<SimpleSurvey> = {
        survey: survey,
        survey_events_alerts: surveyEventsAlerts,
    };

    return JSON.stringify(_serializedSurvey);
};

const serializeOngoingSurvey = (
    survey: OngoingSurvey
): Serialize<OngoingSurvey> => {
    const _serializedSurvey: SurveyContainer<OngoingSurvey> = {
        survey: survey,
        survey_events_alerts: null,
    };
    return JSON.stringify(_serializedSurvey);
};
