import { v4 as uuid } from "uuid";
import {
    DateQuestion,
    LongTextQuestion,
    MultipleChoiceQuestion,
    Question,
    RatingQuestion,
    ShortTextQuestion,
    SingleChoiceQuestion,
    SurveyEvent,
    SurveyEventAlert,
    TimeQuestion,
} from "@difftone/types";
import { getCurrentTimeInBEFormat } from "@difftone/frontend-common";

export const SURVEY_EVENT_TEMPLET: SurveyEvent = {
    version: "0.0.3",
    title: "",
    uuid: "",
    system_survey_event_type: false,
    utc_offset: 0,
    date_utc: "yyyy-mm-dd",
    day_utc_time: "0:0",
    event_number: null,
    event_alerts: [],
    event_emails: [],
};

export const EVENT_ALERT_TEMPLET: SurveyEventAlert = {
    version: "0.0.3",
    is_deleted: false,
    is_paused: false,
    uuid: "",
    event_UUID: "",
    alert_type: false,
    alert_status: "DRAFT",
    time_offset: 0,
    time_units: "DAYS",
    beforeAfter: "BEFORE",
    approvers: [],
    content: {
        initiator_email: "",
        initiator_name: "",
        survey_name: "",
        button_title: "",
        message: "",
        onclick_link: "",
        title: "",
        survey_expiration_date: "DD:MM:YYY",
    },
    send_media: "EMAIL",
    send_to: [],
    buttons: [],
    created_at: getCurrentTimeInBEFormat(),
    email_uuid: "",
};

export const NEW_RATING_ATTRIBUTE_CONTENT: RatingQuestion = {
    text: "",
    rating_options: [
        { value: 1, label: "1", gotos: [], uuid: "1" },
        { value: 2, label: "2", gotos: [], uuid: "2" },
        { value: 3, label: "3", gotos: [], uuid: "3" },
        { value: 4, label: "4", gotos: [], uuid: "4" },
        { value: 5, label: "5", gotos: [], uuid: "5" },
    ],
};

export const NEW_MULTIPLE_CHOICE_ATTRIBUTE_CONTENT: MultipleChoiceQuestion = {
    text: "",
    choice_options: ["Option 1", "Option 2"],
};
export const NEW_DATE_ATTRIBUTE_CONTENT: DateQuestion = {
    allow_multiple: false,
    text: "",
};
export const NEW_TIME_ATTRIBUTE_CONTENT: TimeQuestion = {
    allow_multiple: false,
    text: "",
};
export const NEW_TEXT_ATTRIBUTE_CONTENT: LongTextQuestion | ShortTextQuestion =
    {
        text: "",
    };

// question related tamplates
export const NEW_RATING_QUESTION_CONTENT: RatingQuestion = {
    text: "",
    rating_options: [
        { value: 1, label: "1", gotos: [], uuid: "1" },
        { value: 2, label: "2", gotos: [], uuid: "2" },
        { value: 3, label: "3", gotos: [], uuid: "3" },
        { value: 4, label: "4", gotos: [], uuid: "4" },
        { value: 5, label: "5", gotos: [], uuid: "5" },
    ],
};

export const NEW_QUESTION_TEMPLET: Question<RatingQuestion> = {
    version: "0.0.2",
    uuid: "",
    title: "",
    question_type_name: "RATING",
    content: NEW_RATING_QUESTION_CONTENT,
    categories: [],
    required: false,
    nickname: "",
    allow_comments: false,
    is_filter: false,
};

export const NEW_CHOICE_QUESTION_TEMPLATE: Question<
    MultipleChoiceQuestion | SingleChoiceQuestion
> = {
    version: "0.1.0",
    uuid: "",
    title: "",
    question_type_name: "SINGLE_CHOICE",
    content: { text: "", choice_options: [] },
    categories: [],
    required: false,
    nickname: "",
    allow_comments: false,
    is_filter: false,
};

export const NEW_RATING_QUESTION_TEMPLATE: Question<RatingQuestion> = {
    version: "0.0.2",
    uuid: "",
    title: "",
    question_type_name: "RATING",
    content: { text: "", rating_options: [] },
    categories: [],
    required: false,
    nickname: "",
    allow_comments: false,
    is_filter: false,
};

export const NEW_TEXT_QUESTION_TEMPLATE: Question<
    ShortTextQuestion | LongTextQuestion
> = {
    version: "0.0.2",
    uuid: "",
    title: "",
    question_type_name: "RATING",
    content: { text: "" },
    categories: [],
    required: false,
    nickname: "",
    allow_comments: false,
    is_filter: false,
};

export const NEW_SINGLE_CHOICE_QUESTION_CONTENT: SingleChoiceQuestion = {
    text: "",
    choice_options: [
        { uuid: uuid(), version: "0.0.1", label: "Option 1", gotos: [] },
        { uuid: uuid(), version: "0.0.1", label: "Option 2", gotos: [] },
    ],
};
export const NEW_MULTIPLE_CHOICE_QUESTION_CONTENT: MultipleChoiceQuestion = {
    text: "",
    choice_options: ["Option 1", "Option 2"],
};
export const NEW_DATE_QUESTION_CONTENT: DateQuestion = {
    allow_multiple: false,
    text: "",
};
export const NEW_TIME_QUESTION_CONTENT: TimeQuestion = {
    allow_multiple: false,
    text: "",
};
export const NEW_TEXT_QUESTION_CONTENT: LongTextQuestion | ShortTextQuestion = {
    text: "",
};
