import React, { ReactNode, useEffect, useState } from "react";

import { setLocation } from "@difftone/actions";
import { Dialog, Spinner } from "@difftone/shared-components";
import { INBOX_URL } from "@difftone/constants";
import {
    SurveyClasses,
    OptionalPropertiesForDuplicateSurvey,
    UUID,
    EventProperties,
    DuplicateDateEventByType,
    SimpleSurvey,
} from "@difftone/types";

import {
    DuplicateDates,
    DuplicateDetails,
    DuplicateHeader,
    DuplicateQuestions,
    DuplicateParticipants,
} from "./components";
import {
    duplicate_survey,
    duplicate_survey_card,
    elements_to_duplicate_wrapper,
    duplicate_button,
} from "./duplicate-survey-popup.module.css";

export type DuplicateSurveyPopupProps = {
    titleName: string;
    survey: SurveyClasses;
    buttonText: string;
    onDuplicateHandler: (
        optionalPropertiesForDuplicateSurvey: OptionalPropertiesForDuplicateSurvey
    ) => void;
    children?: ReactNode;
};

//TODO move to factory
const optionalPropertiesForDuplicateSurvey: OptionalPropertiesForDuplicateSurvey =
    {
        purpose: true,
        anonymity: true,
        participants: true,
        introduction: true,
        questionsUuids: [],
        dates: [],
    };

export const DuplicateSurveyPopup = (props: DuplicateSurveyPopupProps) => {
    const { titleName, survey, buttonText, onDuplicateHandler, children } =
        props;

    const [duplicateSurveyProps, setDuplicateSurveyProps] =
        useState<OptionalPropertiesForDuplicateSurvey | null>(null);

    useEffect(() => {
        if (!duplicateSurveyProps) {
            let _dates: EventProperties[] = [];

            switch (survey.survey_class) {
                case "ONGOING":
                    break;
                case "SIMPLE":
                default:
                    _dates = (survey as SimpleSurvey).survey_events.map(
                        (surveyEvent) => {
                            const date: EventProperties = {};
                            date[surveyEvent.uuid] = { with_date: true };
                            return date;
                        }
                    );
                    break;
            }

            const _questionsUuids: UUID[] = survey.questions.map(
                (question) => question.uuid
            );

            setDuplicateSurveyProps({
                ...optionalPropertiesForDuplicateSurvey,
                dates: _dates,
                questionsUuids: _questionsUuids,
            });
        }
    }, [duplicateSurveyProps, survey]);

    if (!duplicateSurveyProps) {
        return <Spinner />;
    }

    const closeDuplicateSurvey = () => {
        setLocation(INBOX_URL);
    };

    const onToggleAllDetailsSelected = (allDetailsSelected: boolean) => {
        const details: { [key: string]: boolean } = {
            purpose: duplicateSurveyProps.purpose,
            anonymity: duplicateSurveyProps.anonymity,
            introduction: duplicateSurveyProps.introduction,
        };

        for (const key in details) {
            details[key] = allDetailsSelected;
        }

        setDuplicateSurveyProps({
            ...duplicateSurveyProps,
            ...details,
        });
    };

    const onToggleDetailsItem = (detailItemKey: string, isChecked: boolean) => {
        const details: { [key: string]: boolean } = {
            purpose: duplicateSurveyProps.purpose,
            anonymity: duplicateSurveyProps.anonymity,
            introduction: duplicateSurveyProps.introduction,
        };

        details[detailItemKey] = isChecked;

        setDuplicateSurveyProps({
            ...duplicateSurveyProps,
            ...details,
        });
    };

    const onToggleAllDatesSelected = (allSelected: boolean) => {
        const _dates: EventProperties[] = [];

        if (allSelected) {
            (survey as SimpleSurvey).survey_events.forEach((surveyEvent) => {
                const date: EventProperties = {};
                date[surveyEvent.uuid] = { with_date: allSelected };
                _dates.push(date);
            });
        }

        setDuplicateSurveyProps({
            ...duplicateSurveyProps,
            dates: _dates,
        });
    };

    const onToggleDateItemByEventType = (
        eventTitle: string,
        selected: boolean,
        eventType: DuplicateDateEventByType
    ) => {
        switch (eventType) {
            case "title":
                onToggleDateItemByTitle(eventTitle, selected);
                break;

            case "alert":
                onToggleDateItemByAlert(eventTitle, selected);
                break;

            case "time":
                onToggleDateItemByTime(eventTitle, selected);
                break;

            default:
                break;
        }
    };

    const onToggleDateItemByTitle = (eventUuid: string, isChecked: boolean) => {
        let datesProps: EventProperties[] = duplicateSurveyProps.dates;

        const survey_events = (survey as SimpleSurvey).survey_events;

        const surveyEventIndex = survey_events.findIndex(
            (event) =>
                event.uuid === eventUuid &&
                (event.system_survey_event_type === "PUBLISHED_DATE" ||
                    event.system_survey_event_type === "LAST_DATE_TO_FILL")
        );

        if (!isChecked && surveyEventIndex === -1) {
            const index = datesProps.findIndex((date) => date[eventUuid]);
            datesProps = datesProps.filter((date, i) => i !== index);
        } else if (surveyEventIndex !== -1) {
            const index = datesProps.findIndex((date) => date[eventUuid]);
            datesProps[index][eventUuid].with_date =
                !datesProps[index][eventUuid].with_date;
        } else {
            const date: EventProperties = {};
            date[eventUuid] = { with_date: isChecked };
            datesProps.push(date);
        }

        setDuplicateSurveyProps({
            ...duplicateSurveyProps,
            dates: datesProps,
        });
    };

    const onToggleDateItemByAlert = (
        duplicateAlertUUID: string,
        isChecked: boolean
    ) => {};

    const onToggleDateItemByTime = (eventUuid: UUID, isChecked: boolean) => {
        const datesProps: EventProperties[] = duplicateSurveyProps.dates;

        const eventProperties = datesProps.find((date) => date[eventUuid]);

        if (!eventProperties) {
            const date: EventProperties = {};
            date[eventUuid] = { with_date: true };
            datesProps.push(date);
        } else {
            eventProperties[eventUuid].with_date = isChecked;
        }

        setDuplicateSurveyProps({
            ...duplicateSurveyProps,
            dates: datesProps,
        });
    };

    const onToggleAllQuestionsSelected = (allSelected: boolean) => {
        let _questionsUuids = duplicateSurveyProps.questionsUuids;

        if (allSelected) {
            _questionsUuids = survey.questions.map((question) => question.uuid);
        } else {
            _questionsUuids = [];
        }

        setDuplicateSurveyProps({
            ...duplicateSurveyProps,
            questionsUuids: _questionsUuids,
        });
    };

    const onToggleParticipants = (isChecked: boolean) => {
        setDuplicateSurveyProps({
            ...duplicateSurveyProps,
            participants: isChecked,
        });
    };

    const onCheckedItemQuestion = (questionUuid: UUID) => {
        const _questionsUuids = duplicateSurveyProps.questionsUuids;
        duplicateSurveyProps.questionsUuids.push(questionUuid);
        setDuplicateSurveyProps({
            ...duplicateSurveyProps,
            questionsUuids: _questionsUuids,
        });
    };

    const onUnCheckedItemQuestion = (uuid: UUID) => {
        const _questionsUuids = duplicateSurveyProps.questionsUuids.filter(
            (questionUuid) => questionUuid !== uuid
        );

        setDuplicateSurveyProps({
            ...duplicateSurveyProps,
            questionsUuids: _questionsUuids,
        });
    };

    const onToggleQuestion = (questionUuid: UUID, isChecked: boolean) => {
        if (isChecked) {
            onCheckedItemQuestion(questionUuid);
        } else {
            onUnCheckedItemQuestion(questionUuid);
        }
    };

    const onDuplicateSendHandler = () => {
        onDuplicateHandler(duplicateSurveyProps);
    };

    return (
        <Dialog
            onClose={closeDuplicateSurvey}
            open={true}
            fullScreen={false}
            boldTitle={titleName}
            title={survey.survey_name}
            classes={{ paper: duplicate_survey }}
        >
            <div className={duplicate_survey_card}>
                {children}
                <div className={elements_to_duplicate_wrapper}>
                    <DuplicateHeader />
                    <DuplicateDetails
                        onToggleDetailsItem={onToggleDetailsItem}
                        onToggleAllDetailsSelected={onToggleAllDetailsSelected}
                        duplicateSurveyProps={duplicateSurveyProps}
                    />
                    <DuplicateQuestions
                        onToggleQuestion={onToggleQuestion}
                        onToggleAllQuestionsSelected={
                            onToggleAllQuestionsSelected
                        }
                        duplicateSurveyProps={duplicateSurveyProps}
                        survey={survey}
                    />
                    <DuplicateParticipants
                        onToggleParticipants={onToggleParticipants}
                        duplicateSurveyProps={duplicateSurveyProps}
                    />
                    {survey.survey_class !== "ONGOING" && (
                        <DuplicateDates
                            survey={survey}
                            duplicateSurveyProps={duplicateSurveyProps}
                            onToggleItemByEventType={
                                onToggleDateItemByEventType
                            }
                            onToggleAllDatesSelected={onToggleAllDatesSelected}
                        />
                    )}
                </div>
            </div>
            <div className={duplicate_button}>
                <button onClick={onDuplicateSendHandler}>{buttonText}</button>
            </div>
        </Dialog>
    );
};
