import React from "react";
import { observer } from "mobx-react";
import { Question, QuestionType, BaseSurvey } from "@difftone/types";

import {
    QuestionCategories,
    RequiredIndicator,
    SetAsFilterIndicator,
} from "./components";
import { question_options_wrapper } from "./question-options.module.css";

export type QuestionsOptionsProps = {
    question: Question<QuestionType>;
    survey: BaseSurvey;
};

export const QuestionsOptions = observer((props: QuestionsOptionsProps) => {
    const { question, survey } = props;

    return (
        <div className={question_options_wrapper}>
            <SetAsFilterIndicator question={question} />
            <RequiredIndicator question={question} />
            <QuestionCategories survey={survey} question={question} />
        </div>
    );
});
