import React from "react";
import { getSurveyFromStoreByURL } from "@difftone/reducers";

import { CardFactory } from "./components";

export const WizardContainer = () => {
    const survey = getSurveyFromStoreByURL();

    return <CardFactory survey={survey} />;
};
