import type {
    SurveyClasses,
    OngoingSurvey,
    SimpleSurvey,
    ValidationResult,
    ValidationType,
} from "@difftone/types";

import { validateQuestions } from "../questions-validations";
import { validateSurveyEvents } from "../survey-events-validations";
import {
    validateSurveyName,
    validateSurveyRespondants,
    validateQuestionsCount,
} from "./properties-validations";

export const validateSurvey = (
    survey: SurveyClasses
): ValidationResult<ValidationType>[] => {
    switch (survey.survey_class) {
        case "ONGOING":
            const ongoingSurvey = survey as OngoingSurvey;
            return validateOngoingSurvey(ongoingSurvey);
        case "SIMPLE":
        default:
            const simpleSurvey = survey as SimpleSurvey;
            return validateSimpleSurvey(simpleSurvey);
    }
};

const validateSimpleSurvey = (
    survey: SimpleSurvey
): ValidationResult<ValidationType>[] => {
    const res: ValidationResult<ValidationType>[] = [];

    res.push(
        ...validateSurveyName(survey),
        ...validateQuestions(survey.questions),
        ...validateQuestionsCount(survey),
        ...validateSurveyRespondants(survey),
        ...validateSurveyEvents(survey.survey_events)
    );

    return res;
};

const validateOngoingSurvey = (
    survey: OngoingSurvey
): ValidationResult<ValidationType>[] => {
    const res: ValidationResult<ValidationType>[] = [];
    res.push(
        ...validateSurveyName(survey),
        ...validateQuestions(survey.questions),
        ...validateQuestionsCount(survey)
    );

    return res;
};
