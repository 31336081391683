import React, { ChangeEvent } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { Question, QuestionType } from "@difftone/types";

import { filterQuestionNickNameValidation } from "@difftone/frontend-common/frontend-validations";
import {
    nullifyWizardValidationError,
    updateQuestionInWizard,
} from "@difftone/actions";
import { wizardStore } from "@difftone/stores";
import { warningMessages } from "@difftone/constants";
import { getSurveyFromStoreByURL } from "@difftone/reducers";
import {
    ShowDifftoneTooltip,
    ValidationWrapper,
} from "@difftone/shared-components";

import exclamationIcon from "@difftone/assets/exclamation-icon.svg";
import {
    question_nickname,
    section_header,
    nickname_input,
    invalid_nickname,
} from "./question-nickname.module.css";

const OVERRIDE_VALIDATION_TOOLTIP_POSITION = {
    left: "343px",
};

const tooltipPosition = makeStyles({
    position: {
        bottom: "2px",
    },
});

export type QuestionNicknameProps = {
    question: Question<QuestionType>;
};

export const QuestionNickname = observer((props: QuestionNicknameProps) => {
    const { focusedWizardInputCard } = wizardStore;
    const { question } = props;

    const survey = getSurveyFromStoreByURL();
    const { nickname } = question;

    const tooltipStylePosition = tooltipPosition().position;

    const isPartOfFocusedCard =
        focusedWizardInputCard?.getAttribute("uuid") === question.uuid;

    const {
        isNicknameValid,
        questionNickNameValidation,
        questionNicknameUniquenessValidations,
    } = filterQuestionNickNameValidation(question.uuid, survey);

    let nickNameValidationErrorMessage = "";

    if (questionNicknameUniquenessValidations.length > 0) {
        nickNameValidationErrorMessage = warningMessages.questionTitleError;
    }
    if (questionNickNameValidation.length > 0) {
        nickNameValidationErrorMessage = warningMessages.questionNickNameError;
    }

    const onNicknameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        nullifyWizardValidationError();
        const value = event.currentTarget.value;

        updateQuestionInWizard(
            question,
            "nickname" as keyof Question<QuestionType>,
            value
        );
    };

    return (
        <div className={question_nickname}>
            <div className={section_header}>
                Filter label
                <ShowDifftoneTooltip
                    tooltipPosition="right"
                    extendTooltipStyle={tooltipStylePosition}
                    tip="This is what you will see in the survey's results"
                >
                    <img src={exclamationIcon} alt="tooltip" />
                </ShowDifftoneTooltip>
            </div>
            <ValidationWrapper
                isValid={isNicknameValid || !isPartOfFocusedCard}
                errorMessage={nickNameValidationErrorMessage}
                tooltipPositionOverride={OVERRIDE_VALIDATION_TOOLTIP_POSITION}
            >
                <input
                    onChange={onNicknameChangeHandler}
                    value={nickname}
                    maxLength={10}
                    placeholder="Label the feature"
                    className={
                        isNicknameValid || !isPartOfFocusedCard
                            ? nickname_input
                            : clsx(nickname_input, invalid_nickname)
                    }
                    type="text"
                />
            </ValidationWrapper>
        </div>
    );
});
