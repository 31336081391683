import React from "react";
import { observer } from "mobx-react";
import dayjs from "dayjs";
import {
    Answer,
    AnswerType,
    DateAnswer,
    LongTextAnswer,
    MultipleChoiceAnswer,
    RatingAnswer,
    ShortTextAnswer,
    SingleChoiceAnswer,
    BaseSurvey,
    TimeAnswer,
} from "@difftone/types";
import { simpleSurveyResultsStore } from "@difftone/stores";
import { getQuestionOptionsOrEmptyArray } from "@difftone/reducers";

import { SelectionAnswerBox, TextAnswerBox } from "./components";

import { responder_answers } from "./responder-answers.module.css";

export type ResponderAnswersProps = {
    survey: BaseSurvey;
    responderAnswer: Answer<AnswerType>;
    isLast: boolean;
};

export const ResponderAnswers = observer((props: ResponderAnswersProps) => {
    const { survey, responderAnswer, isLast } = props;
    const { selectedQuestion } = simpleSurveyResultsStore;
    const currentQuestion = survey.questions.find(
        (question) => question.uuid === selectedQuestion
    );
    if (!currentQuestion) {
        return null;
    }

    const questionWithOptions = getQuestionOptionsOrEmptyArray(currentQuestion);

    let answerComponent = null;

    switch (responderAnswer.answer_type_name) {
        case "RATING":
            const ratingAnswer = (responderAnswer as Answer<RatingAnswer>)
                .content.label;
            answerComponent = questionWithOptions.map((option) => (
                <SelectionAnswerBox
                    isSelected={ratingAnswer === option}
                    key={currentQuestion.uuid + option}
                />
            ));
            break;
        case "SINGLE_CHOICE":
            const singleChoiceAnswer = (
                responderAnswer as Answer<SingleChoiceAnswer>
            ).content.choice;
            answerComponent = questionWithOptions.map((option) => (
                <SelectionAnswerBox
                    isSelected={singleChoiceAnswer === option}
                    key={currentQuestion.uuid + option}
                />
            ));
            break;
        case "MULTIPLE_CHOICE":
            const multipleChoiceAnswer = (
                responderAnswer as Answer<MultipleChoiceAnswer>
            ).content.choices;
            answerComponent = questionWithOptions.map((option) => (
                <SelectionAnswerBox
                    isSelected={multipleChoiceAnswer[option]}
                    key={currentQuestion.uuid + option}
                />
            ));
            break;
        case "LONG_TEXT":
            const longTextAnswer = (responderAnswer as Answer<LongTextAnswer>)
                .content.text;
            answerComponent = (
                <TextAnswerBox borderBottom={isLast} answer={longTextAnswer} />
            );
            break;
        case "SHORT_TEXT":
            const shortTextAnswer = (responderAnswer as Answer<ShortTextAnswer>)
                .content.text;
            answerComponent = (
                <TextAnswerBox borderBottom={isLast} answer={shortTextAnswer} />
            );
            break;
        case "DATE":
            const dateAnswer = (responderAnswer as Answer<DateAnswer>).content
                .date;
            const correctDateFormat = dayjs(dateAnswer).format("MMM DD, YYYY");
            answerComponent = (
                <TextAnswerBox
                    borderBottom={isLast}
                    answer={correctDateFormat.toString()}
                />
            );
            break;
        case "TIME":
            const timeAnswer = (responderAnswer as Answer<TimeAnswer>).content
                .time;
            const correctTimeFormat = dayjs(timeAnswer).format("HH:mm");
            answerComponent = (
                <TextAnswerBox
                    borderBottom={isLast}
                    answer={correctTimeFormat.toString()}
                />
            );
            break;
        default:
            break;
    }

    return <div className={responder_answers}>{answerComponent}</div>;
});
