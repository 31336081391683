import { action } from "mobx";

import { constantAlertVariants } from "@difftone/constants";
import { AlertVariants, AuthData, UUID } from "@difftone/types";
import { logoutFromMicrosoft, logoutFromGoogle } from "@difftone/actions";
import { difftoneAlertStore, sharePanelStore } from "@difftone/stores";
import { getAdmins } from "@difftone/reducers";

export const closeDifftoneAlert = action(() => {
    difftoneAlertStore.isVisible = false;
    difftoneAlertStore.showSharePanel = false;
});

export const clearDifftoneAlertStore = action(() => {
    difftoneAlertStore.clearStore();
});

export const showPermissionsAlert = action(() => {
    const adminEmail = getAdmins()[0];
    const msg = `Hi! This option is not yet available for you. Please contact ${adminEmail} to get permissions`;
    showDifftoneAlert(msg, "PERMISSIONS");
});

export const showDifftoneAlert = action(
    (
        alertMessage: string,
        variant: AlertVariants,
        onResolve?: Function,
        onReject?: Function,
        objectName?: string,
        resolveButtonText?: string,
        showSharePanel?: boolean
    ) => {
        difftoneAlertStore.isVisible = true;
        difftoneAlertStore.alertVariant = variant;
        difftoneAlertStore.message = alertMessage;
        difftoneAlertStore.onResolveCallback = onResolve;
        difftoneAlertStore.onRejectCallback = onReject;
        difftoneAlertStore.objectName = objectName ? objectName : "";
        difftoneAlertStore.resolveButtonText = resolveButtonText
            ? resolveButtonText
            : "";
        difftoneAlertStore.showSharePanel = showSharePanel || false;
    }
);

export const showDifftoneAlertWithSharePanel = action(
    (
        alertMessage: string,
        variant: AlertVariants,
        surveyUUID: UUID,
        title: string,
        isOpenSurvey: boolean | undefined
    ) => {
        difftoneAlertStore.isVisible = true;
        difftoneAlertStore.showSharePanel = true;
        difftoneAlertStore.message = alertMessage;
        difftoneAlertStore.alertVariant = variant;
        sharePanelStore.activeSurveyUUID = surveyUUID;
        sharePanelStore.title = title;
        sharePanelStore.isOpenSurvey = !!isOpenSurvey;
    }
);

export const showServerErrorAlert = action(() => {
    const authDataAsString = localStorage.getItem("authData");

    if (!authDataAsString) {
        return;
    }

    const authData: AuthData = JSON.parse(authDataAsString);

    showDifftoneAlert(
        constantAlertVariants.login_notification.text,
        constantAlertVariants.login_notification.variant,
        () => {
            if (authData.issuer === "GOOGLE") {
                logoutFromGoogle();
            } else {
                logoutFromMicrosoft();
            }
        }
    );
});
