import React from "react";
import { observer } from "mobx-react";

import { PublicProfile } from "@difftone/types";
import { dashboardDisplayStore } from "@difftone/stores";
import { addInitiatorToList, removeInitiatorFromList } from "@difftone/actions";
import { getUserUuid } from "@difftone/procedures";
import { Checkbox } from "@difftone/shared-components";
import { list_item_wrapper } from "./initiators-list-item.module.css";

export type InitiatorsListItemProps = {
    publicProfile: PublicProfile;
};

export const InitiatorsListItem = observer((props: InitiatorsListItemProps) => {
    const { selectedInitiatorsList } = dashboardDisplayStore;
    const { publicProfile } = props;

    //TODO: add to img as onClick https://app.asana.com/0/1201346022025578/1202469043796007/f
    const onToggleChange = () => {
        if (selectedInitiatorsList.includes(publicProfile.uuid)) {
            removeInitiatorFromList(publicProfile.uuid);
        } else {
            addInitiatorToList(publicProfile.uuid);
        }
    };

    const displayPublicProfileName = () => {
        if (publicProfile.uuid === getUserUuid()) {
            return "Me";
        }
        return publicProfile.name;
    };

    return (
        <div className={list_item_wrapper}>
            <Checkbox
                checked={selectedInitiatorsList.includes(publicProfile.uuid)}
                //TODO: Add an onToggleChange function to change selection https://app.asana.com/0/1201346022025578/1202469043796007/f
                onClick={() => {}}
            />
            <span>{displayPublicProfileName()}</span>
        </div>
    );
});
